import { changeTheme } from '@ampli/ui';
import { useMeContext } from '../modules/shared/contexts/me-context';
import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';

const useNavigationPageData = () => {
  const { states: statesMe } = useMeContext();
  const { states: statesCourseEnrollments } = useCourseEnrollmentsContext();

  const {
    data: courseEnrollments,
    loading: courseEnrollmentsLoading,
    error: courseEnrollmentsError,
  } = statesCourseEnrollments.courseEnrollments;

  const { me, loading: meLoading, error: meError } = statesMe.getMe;

  changeTheme();

  const loading = meLoading || courseEnrollmentsLoading;
  const error = Boolean(meError || courseEnrollmentsError);

  return {
    me,
    courseEnrollments,
    loading,
    error,
  };
};

export default useNavigationPageData;
