import React from 'react';

import { generatePath, useHistory } from 'react-router-dom';
import { SvgCommentMessage } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';

import { MessagingRoutesPath } from '../../..';
import { useGetState } from '../../../../state';
import { SimpleCard } from '../../../../components/home/simple-card';

const MessagingHistoricQuickAccess = React.forwardRef(() => {
  const history = useHistory();
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const tutorMessagingClick = () =>
    history.push(
      generatePath(MessagingRoutesPath.historic, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
      })
    );

  return (
    <SimpleCard
      isExternalLink={false}
      onMouseOver={(e: React.MouseEvent) => e.preventDefault()}
      onClick={tutorMessagingClick}
      compact={!isDesktop}
      icon={<SvgCommentMessage fill={theme.colors.icon.black} />}
      title="Mensagens do tutor"
      description="Veja as mensagens que seu tutor enviou pra você."
      id="tutorMessaging"
      minHeight="initial"
    />
  );
});

MessagingHistoricQuickAccess.displayName = 'MessagingHistoricQuickAccess';

export default MessagingHistoricQuickAccess;
