import styled from '@emotion/styled';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
export const Container = styled(Carousel)`
  background-color: ${_ref => {
  let {
    backgroundColor
  } = _ref;
  return backgroundColor;
}};
  color: ${_ref2 => {
  let {
    color
  } = _ref2;
  return color;
}};

  .react-multiple-carousel__arrow::before {
    font-size: 20px;
    color: ${_ref3 => {
  let {
    arrowColor
  } = _ref3;
  return arrowColor;
}};
    display: block;
    text-align: center;
    z-index: 2;
    position: relative;
  }

  .react-multiple-carousel__arrow {
    background: white;
  }

  .react-multiple-carousel__arrow:hover {
    background: ${_ref4 => {
  let {
    backgroundColor
  } = _ref4;
  return backgroundColor;
}};
  }

  &.react-multi-carousel-list {
    width: 100%;

    .react-multiple-carousel__arrow {
      position: absolute;
      outline: 0;
      transition: all 0.5s;
      z-index: 1;
      background: ${_ref5 => {
  let {
    backgroundColor
  } = _ref5;
  return backgroundColor;
}};
      min-width: 43px;
      min-height: 43px;
      opacity: 1;

      &.react-multiple-carousel__arrow--left {
        left: 0px;
      }
      &.react-multiple-carousel__arrow--right {
        right: 0px;
      }
    }

    @media (min-width: 769px) {
      &.react-multi-carousel-list {
        width: 100%;

        .react-multiple-carousel__arrow {
          position: absolute;
          outline: 0;
          transition: all 0.5s;
          z-index: 1;
          background: ${_ref6 => {
  let {
    backgroundColor
  } = _ref6;
  return backgroundColor;
}};
          min-width: 43px;
          min-height: 43px;
          opacity: 1;
        }
      }
    }
  }
`;