export const DEFAULT_RESPONSIVE_CONFIG = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 769
    },
    slidesToSlide: 1,
    items: 3
  },
  mobile: {
    breakpoint: {
      max: 768,
      min: 0
    },
    slidesToSlide: 1,
    items: 1,
    partialVisibilityGutter: 60
  }
};