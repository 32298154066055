const HandleTitle = (value) => {
  switch (value) {
    case 'ava.ampli.com.br':
      document.title = 'Ampli';
      break;

    case 'www.anhanguera.com':
      document.title = 'Anhanguera';
      break;

    case 'www.unopar.com.br':
      document.title = 'Unopar';
      break;

    case 'www.pitagoras.com.br':
      document.title = 'Pitágoras';
      break;

    case 'www.unic.com.br':
      document.title = 'Unic';
      break;

    case 'www.unime.edu.br':
      document.title = 'Unime';
      break;

    case 'www.uniderp.com.br':
      document.title = 'Uniderp';
      break;

    default:
      document.title = 'Ampli';
      break;
  }
};

export default HandleTitle;
