import { matchPath } from 'react-router-dom';
import { routePath } from '../routes';

const courseSelectionGuard = async (to, from, next) => {
  const noAuth = to.meta?.auth?.noAuth;
  if (noAuth) next();
  const selectedCourseEnrollmentId = to.meta?.state?.selectedCourseEnrollmentId;
  const selectedCourseVersionId = to.meta?.state?.selectedCourseVersionId;
  const matchUrl = to.match?.url;
  const isOnboardingPage = to.meta?.isOnboardingPage;
  const isCourseOptional = to.meta?.isCourseOptional;

  try {
    const match = matchPath(matchUrl, {
      path: routePath.courseSelection,
      exact: true,
    });

    if (
      isOnboardingPage ||
      (selectedCourseEnrollmentId && selectedCourseVersionId) ||
      match ||
      noAuth ||
      isCourseOptional
    ) {
      next();
    } else {
      throw new Error('Course is not selected');
    }
  } catch (error) {
    console.warn(error);
    next.redirect(routePath.courseSelection);
  }
};

export default courseSelectionGuard;
