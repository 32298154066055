import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaQuery, convertPXToVH, CircularProgressBar } from '@sofia/ui';

export const ButtonWrapper = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: column-reverse;
  button {
    margin-top: 24px;
    width: 100%;
    padding: 0px;
    display: flex;
    height: 56px;
    align-items: center;
    justify-content: center;
    :disabled {
      opacity: 1;
    }
  }
  ${mediaQuery('desktop')} {
    flex-direction: row;
    button {
      margin-top: 0px;
      margin-left: 24px;
      width: auto;
      padding: 19px 24px;
    }
  }
`;

export const ContentWrapper = styled.div`
  ul {
    margin: ${convertPXToVH(16)} 0;
  }

  li {
    line-height: 24px;

    ${mediaQuery('desktop')} {
      line-height: 30px;
    }
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: end;
  column-gap: 24px;
  margin: 24px 24px 8px;

  > button {
    width: auto;
    padding: 19px 8px;
  }

  ${mediaQuery('desktop')} {
    margin: 40px 32px 32px;
    button {
      padding: 19px 24px;
    }
  }
`;

const loadAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(720deg);
  }
`;

export const LoadingCircle = styled(CircularProgressBar)`
  animation: ${loadAnimation} 1.2s linear infinite;
`;
