import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FormulaAsset,
  TableAsset,
  TextAsset,
  VideoAsset,
} from '../../../../graphql/types';
import { useGetState } from '../../../../../../state';
import {
  ESectionCopilotStudentMoment,
  ISectionCopilotPayload,
} from '../../../../../section/pages/learning-object/types';
import { useSectionContext } from '../../../../../section/pages/learning-object/section-provider';
import { useBreakpoint } from '../../../../hooks';

interface IUseSectionCopilot {
  payload: ISectionCopilotPayload;
  isCopilotOpen: boolean;
  startChatOpened: boolean;
  studentId: string;
}

const getVideoTime = (videoTime: number) => {
  return videoTime > 0
    ? new Date(videoTime * 1000).toISOString().slice(11, 19)
    : '00:00:01';
};

const useSectionCopilot = (): IUseSectionCopilot => {
  const {
    learningObject,
    learningObjects,
    section,
    externalId,
    subjectEnrollment,
    course,
    loading,
    me,
  } = useSectionContext();
  const { isDesktop } = useBreakpoint();
  const history = useHistory();
  const [{ videoTime }] = useGetState();
  const { state: locationState } = useLocation<{
    backIterations: number;
    fromSubjectPage: boolean;
  }>();
  const [payload, setPayload] = React.useState<ISectionCopilotPayload>(
    {} as ISectionCopilotPayload
  );
  const [isCopilotOpen, setIsCopilotOpen] = React.useState<boolean>(true);
  const [content, setContent] = React.useState('');
  const isFirstRender =
    locationState?.backIterations === 0 || locationState.fromSubjectPage;

  React.useEffect(() => {
    if (!isFirstRender && !isDesktop && isCopilotOpen) {
      setIsCopilotOpen(false);
    }

    history.listen((location) => {
      if (history.action === 'POP') {
        location.state = {
          backIterations: locationState.backIterations - 1,
          fromSubjectPage: false,
        };
      }
    });
  }, [
    isFirstRender,
    history,
    isCopilotOpen,
    isDesktop,
    locationState.backIterations,
  ]);

  React.useLayoutEffect(() => {
    if (!content && document.readyState === 'complete' && !loading) {
      setContent(document.getElementById('section-content')?.outerHTML || '');

      const videoObject = (learningObject?.assets?.find(
        (asset) => asset.type === 'VIDEO'
      ) || null) as VideoAsset;
      const isVideoObject = Boolean(videoObject);

      const assets = learningObjects
        ?.map((learningObject) => learningObject.assets)
        .flat();
      const sectionContent = (
        assets?.filter(
          (asset) =>
            asset.type !== 'VIDEO' &&
            asset.type !== 'IMAGE' &&
            asset.type !== 'FILE'
        ) as Array<FormulaAsset | TextAsset | TableAsset>
      )
        ?.map((asset) => asset.content)
        ?.join();

      setPayload({
        studentMoment:
          ESectionCopilotStudentMoment[
            isVideoObject ? 'LEARNING_VIDEO' : 'LEARNING_TEXT'
          ],
        studentName: me.firstName,
        academicRegistration: externalId,
        courseName: course?.name || '',
        subjectName: subjectEnrollment?.subject?.name || '',
        className: section.title,
        classID: '',
        /* TEXT */
        classContent: sectionContent || '',
        classSectionName: learningObject.title,
        classSectionContent:
          document.getElementById('section-content')?.outerHTML || '',
        /* VIDEO  */
        ...(isVideoObject
          ? {
              frameVideoURL: '',
              actualTimeVideo: getVideoTime(videoTime[learningObject.id] || 0),
              videoID: videoObject?.mediastreamId as string,
            }
          : {}),
      });
    }
  }, [
    content,
    course?.name,
    externalId,
    learningObject,
    learningObjects,
    loading,
    me.firstName,
    section,
    subjectEnrollment,
    videoTime,
  ]);

  return {
    payload,
    isCopilotOpen,
    studentId: me.id || '',
    startChatOpened: isFirstRender && isCopilotOpen && isDesktop,
  };
};

export default useSectionCopilot;
