import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const Wrapper = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: ${({ isLive }: { isLive: boolean }) =>
      isLive ? 2 : 1};
    ${mediaQuery('mobile')} {
      -webkit-line-clamp: 2;
    }
    -webkit-box-orient: vertical;
  }
`;
