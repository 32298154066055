import localForage from 'localforage';

import { getGlobal } from './global';

const storage = 'hipotenusa';

export let authStorage: LocalForage | null;
export let dataStorage: LocalForage | null;

export const localStorage =
  typeof window !== 'undefined'
    ? (getGlobal() as Window).localStorage || {}
    : null;

export const initializeStorages = (storeNamePrefix = 'generic'): void => {
  authStorage = localForage.createInstance({
    name: storage,
    storeName: `${storeNamePrefix}-auth`,
  });

  dataStorage = localForage.createInstance({
    name: storage,
    storeName: `${storeNamePrefix}-data`,
  });
};
