import React from 'react';
import { useTheme } from '@emotion/react';
import { ButtonOutlineLong, ButtonPrimaryLong } from '@sofia/ui';
import { Flex, Wrapper } from './pop-up-second-call-exam-release.styles';
import { ImageMoreHorizontal } from '../../../modules/assets/image';
import { IButtonFooterProps } from './pop-up-second-call-exam-release.types';

const ButtonFooter: React.FC<IButtonFooterProps> = ({
  handleConfirm,
  handleHideModal,
  isLoading,
}: IButtonFooterProps) => {
  const theme = useTheme();
  const commonOptionsBtn = {
    width: '100%',
    margin: '0',
    size: 16,
  };

  return (
    <Wrapper.Margin>
      <Flex.FlexColumn>
        <ButtonPrimaryLong
          background={theme.colors.button.primary}
          color={theme.colors.text.white}
          onClick={handleConfirm}
          {...commonOptionsBtn}
        >
          {isLoading ? (
            <ImageMoreHorizontal
              width="25px"
              height="25px"
              fill={theme.colors.text.white}
            />
          ) : (
            'Liberar prova'
          )}
        </ButtonPrimaryLong>
        <ButtonOutlineLong
          borderBoxColor={theme.colors.button.primary}
          color={theme.colors.button.primary}
          onClick={handleHideModal}
          {...commonOptionsBtn}
        >
          Cancelar
        </ButtonOutlineLong>
      </Flex.FlexColumn>
    </Wrapper.Margin>
  );
};

export default ButtonFooter;
