import React from 'react';
const useClickOutside = (initialState, callback) => {
  const [isOutside, setIsOutside] = React.useState(initialState);
  const ref = React.useRef(null);
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOutside(true);
      callback();
    } else {
      setIsOutside(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    ref,
    isOutside
  };
};
export default useClickOutside;