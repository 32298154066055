import React from 'react';
import { func, string } from '@ampli/utils';

import {
  css,
  cx,
  Dialog,
  DialogBackdrop,
  Paragraph,
  Text,
  DialogHeader,
  DialogSection,
  DialogCloseButton,
  DialogFooter,
  Icon,
  getColor,
} from '@ampli/ui';

import { MobileAlertImage } from './image';
import { STORES } from '../constants';

const AppButton = (props) => {
  const brandBase = getColor('brand-base');

  return (
    <a
      {...props}
      className={cx(
        css`
          &:hover {
            cursor: pointer;
          }

          svg {
            path {
              fill: ${brandBase};
            }

            rect {
              stroke: ${brandBase};
            }
          }
        `,
        props.className
      )}
    >
      <Icon.AppleStore />
    </a>
  );
};

AppButton.propTypes = {
  className: string,
};

const UnsupportedMSEModal = (props) => (
  <>
    <DialogBackdrop {...props} />
    <Dialog {...props} aria-labelledby="NotSupportMSEModalTitle">
      <DialogHeader>
        <DialogCloseButton onClick={props.hide} />
        <MobileAlertImage
          className={css`
            display: block;
            margin: 0 auto;
          `}
        />
      </DialogHeader>
      <DialogSection>
        <Paragraph marginSize="xx-small" textAlign="center">
          <Text color="text-dark" as="strong" id="NotSupportMSEModalTitle">
            Vídeo não suportado.
          </Text>
        </Paragraph>
        <Paragraph textAlign="center">
          <Text>
            Infelizmente seu navegador não suporta esse vídeo. Para ter uma boa
            experiência, recomendamos usar o nosso <strong>aplicativo</strong>.
          </Text>
        </Paragraph>
      </DialogSection>
      <DialogFooter>
        <Paragraph textAlign="center">
          <AppButton onClick={() => open(STORES.GOOGLEPLAY.URL)}>
            <Icon.GooglePlay />
          </AppButton>
        </Paragraph>
        <Paragraph textAlign="center">
          <AppButton onClick={() => open(STORES.APPLESTORE.URL)} />
        </Paragraph>
      </DialogFooter>
    </Dialog>
  </>
);

UnsupportedMSEModal.propTypes = {
  hide: func.isRequired,
};

export default UnsupportedMSEModal;
