import React from 'react';

import { Tab, Typography, tabsKeyNavigation } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import * as Styled from './theme-picker-styles';
import {
  FlexGap as StyledFlexGap,
  TabWrapper as StyledTabWrapper,
} from '../../customization-section.styles';

import { THEME_PICKER_OPTIONS } from '../../../../constants';
import { ThemePickerProps } from '../../../../types';

const ThemePicker = ({
  reset,
  onChange,
  defaultValue,
  defaultTemplate,
  showNewTemplateCommunication,
}: ThemePickerProps): React.ReactElement => {
  const theme = useTheme();
  const [selectedTheme, setSelectedTheme] = React.useState(defaultValue);

  React.useEffect(() => {
    if (reset) {
      setSelectedTheme(defaultValue);
    }
  }, [defaultValue, reset]);

  const tabsRef = React.useRef<HTMLDivElement[]>([]);

  const getRef = (el: HTMLDivElement, index: number) =>
    !tabsRef.current[index] && el ? (tabsRef.current[index] = el) : null;

  const onSelect = (id: string) => {
    const selectTheme = THEME_PICKER_OPTIONS.find(
      (theme) => theme.id === parseInt(id)
    );
    if (selectTheme) {
      onChange(selectTheme);
      setSelectedTheme(selectTheme);
    }
  };

  const onKeyDown = (key: number) =>
    tabsKeyNavigation(
      key,
      tabsRef.current.map((tab) => tab?.firstChild),
      onSelect
    );

  return (
    <StyledFlexGap data-testid="theme-picker">
      <Typography size={16} color={theme.colors.text.black} weight={700}>
        Temas de cores
      </Typography>
      <Styled.Grid>
        <Styled.TabList overflowX="unset" aria-label="Abas de seleção de tema">
          {[...THEME_PICKER_OPTIONS, defaultTemplate].map((themeObj, index) => {
            return themeObj ? (
              <StyledTabWrapper
                key={themeObj.id}
                className={showNewTemplateCommunication ? 'animate' : ''}
                ref={(el: HTMLDivElement) => getRef(el, index)}
              >
                <Tab
                  data-testid={`theme-picker-tab-${themeObj.id}`}
                  id={themeObj.id}
                  aria-label={themeObj.ariaLabel}
                  activeTabId={selectedTheme.id}
                  onClick={() => onChange(themeObj)}
                  onSelect={() => setSelectedTheme(themeObj)}
                  onKeyDown={onKeyDown}
                  /* styles props */
                  weight={400}
                  padding="6px"
                  selectedBorderColor={theme.colors.text.primary}
                >
                  <Styled.ThemePreview background={themeObj.background}>
                    <Typography
                      size={16}
                      color={themeObj.color}
                      margin="12px 0"
                    >
                      Aa
                    </Typography>
                  </Styled.ThemePreview>
                </Tab>
                <Typography
                  data-testid={`theme-picker-title-${themeObj.id}`}
                  size={14}
                  color={
                    theme.colors.text[
                      selectedTheme.id === themeObj.id
                        ? 'primary'
                        : 'lightBlack'
                    ]
                  }
                  weight={selectedTheme.id === themeObj.id ? 700 : 400}
                  margin="8px 0 0 0 "
                >
                  {themeObj.title}
                </Typography>
              </StyledTabWrapper>
            ) : null;
          })}
        </Styled.TabList>
      </Styled.Grid>
    </StyledFlexGap>
  );
};

export default ThemePicker;
