import {
  ChangeBillingDayStatusEnum,
  OrderStatusEnum,
} from '../../../types/enums';
import { StatusBillingDay } from '../../../types/interfaces';

const contractMock =
  '1. Declaro que estou ciente que a minha solicitação para alteração da data de vencimento da minha fatura do dia [ ] para o dia [ ] de cada mês, estará condicionada às seguintes regras: (i) a opção da nova data de vencimento possui um determinado dia no mês para o fechamento da fatura, conforme especificado abaixo, e, que poderá ser diferente do vencimento atual; (ii) para nova solicitação de alteração da data de vencimento da fatura deverá ser respeitado prazo mínimo de[] dias; (iii) a alteração não ocorre de imediato, devendo respeitar o fechamento da próxima fatura em aberto; e (iv) os lançamentos de valores a crédito ou a débito poderão ser prorrogados para os 2(dois) meses subsequentes contados da data de alteração. 2. Informações sobre vencimento e fechamento de faturas: Faturas com vencimento dia[] de cada mês, o fechamento ocorre dia[] do mês[mês anterior / em curso]. Confirmo que estou ciente e de acordo com as condições acima apresentadas.';

const messageSuccessMock =
  'A nova data de vencimento de suas faturas é (xx). Lembrando que essa alteração começa a valer a partir de no máximo duas faturas.';

const messageMock =
  'Você poderá alterar a data de vencimento da suas faturas entre a 2º e a 4º fatura de cada semestre. Por favor, aguarde esse período para solicitar a alteração.';

const optionsMock = ['02', '05', '07', '10', '20'];
const warningMock = [
  'Dependendo da data de solicitação pode ser que essa nova data de vencimento seja aplicada só daqui duas faturas;',
  'Você só poderá alterar novamente depois de 180 dias.',
];

const StatusBillingDayBaseMock = {
  contract: {
    htmlDocumentUrl: contractMock,
  },
  current: '10',
  message: messageMock,
  messageSuccess: messageSuccessMock,
  newSelected: '17',
  options: optionsMock,
  requestData: {
    createdDate: new Date().toString(),
    orderId: '123456',
    serviceForecast: '5',
    title: 'title',
    orderStatus: OrderStatusEnum.PROCESSING,
  },
  warning: warningMock,
};

export const StatusBillingDayContractMock = {
  ...StatusBillingDayBaseMock,
  status: ChangeBillingDayStatusEnum.CONTRACT,
};

export const StatusBillingDaySelectDayMock: StatusBillingDay = {
  ...StatusBillingDayBaseMock,
  status: ChangeBillingDayStatusEnum.SELECT_DAY,
};

export const StatusBillingDaySelectedDayMock: StatusBillingDay = {
  ...StatusBillingDayBaseMock,
  status: ChangeBillingDayStatusEnum.SELECTED_DAY,
};

export const StatusBillingDayProcessingMock: StatusBillingDay = {
  ...StatusBillingDayBaseMock,
  status: ChangeBillingDayStatusEnum.PROCESSING,
};

export const StatusBillingDaySuccessMock: StatusBillingDay = {
  ...StatusBillingDayBaseMock,
  status: ChangeBillingDayStatusEnum.SUCCESS,
};

export const StatusBillingDayProcessingModalMock: StatusBillingDay = {
  ...StatusBillingDayBaseMock,
  status: ChangeBillingDayStatusEnum.PROCESSING,
};

export const StatusBillingDayUnavailableModalMock: StatusBillingDay = {
  ...StatusBillingDayBaseMock,
  status: ChangeBillingDayStatusEnum.UNAVAILABLE,
};

export const StatusBillingDaySuccessModalMock: StatusBillingDay = {
  ...StatusBillingDayBaseMock,
  status: ChangeBillingDayStatusEnum.SUCCESS,
};
