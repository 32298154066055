import React from 'react';
import { Switch } from 'react-router-dom';
import { GuardedRoute } from 'react-router-guards';
import { useServiceClient } from '@ampli/services';

import {
  LoginPage,
  NewPasswordPage,
  PasswordPage,
  SelectLoginType,
  ValidateAccountPage,
  TalkToUsLogin,
  DynamicsLoginPage,
  LeadPage,
  LegacyPage,
} from './lazy-routes';
import { useGetState } from '../state';
import {
  newPasswordPageGuard,
  passwordPageGuard,
  validateAccountPageGuard,
} from '../guards';
import { useQueryParams } from '../hooks';
import { StudentContext } from '../contexts/StudentContext';
import routePath from './routes-path';
import loginAuthGuard from '../guards/login-auth-guard';

const LoginRoutes = () => {
  const clients = useServiceClient('all');
  const queryParams = useQueryParams();

  const [state, actions] = useGetState();

  const meta = { clients, actions, state, queryParams };

  const noAuthMeta = {
    ...meta,
    auth: { noAuth: true, redirect: routePath.courseSelection },
  };

  return (
    <Switch>
      <GuardedRoute
        exact
        path={routePath.login}
        meta={noAuthMeta}
        guards={[loginAuthGuard]}
      >
        <LoginPage {...{ StudentContext }} />
      </GuardedRoute>
      <GuardedRoute exact path={routePath.loginDynamics} meta={noAuthMeta}>
        <DynamicsLoginPage {...{ StudentContext }} />
      </GuardedRoute>
      <GuardedRoute exact path={routePath.talkToUs} meta={noAuthMeta}>
        <TalkToUsLogin />
      </GuardedRoute>
      <GuardedRoute
        path={routePath.loginPassword}
        meta={noAuthMeta}
        guards={[passwordPageGuard]}
      >
        <PasswordPage />
      </GuardedRoute>
      <GuardedRoute path={routePath.loginValidateAccount} meta={noAuthMeta}>
        <ValidateAccountPage />
      </GuardedRoute>
      <GuardedRoute
        path={routePath.loginNewPassword}
        meta={noAuthMeta}
        guards={[newPasswordPageGuard]}
      >
        <NewPasswordPage />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={routePath.loginChooseType}
        meta={noAuthMeta}
        guards={[validateAccountPageGuard]}
      >
        <SelectLoginType />
      </GuardedRoute>
      <GuardedRoute path={routePath.candidate} meta={noAuthMeta}>
        <LeadPage />
      </GuardedRoute>
      <GuardedRoute path={routePath.legacy} meta={noAuthMeta}>
        <LegacyPage />
      </GuardedRoute>
    </Switch>
  );
};

export default LoginRoutes;
