import React from 'react';

import useWelcomeTourControls from './welcome-tour/welcome';
import useSubjectTourControls from './subject-tour/subject';
import MultiTour from '../../../../components/tour';

import { generatePath, useHistory } from 'react-router-dom';
import { useMeContext } from '../../contexts/me-context';

import { useNewFeatureControls } from './new-feature/new-feature';
import { useNewChatCopilotSectionTooltip } from './new-feature/new-chat-copilot-section';
import { useNewChatCopilotHomeTooltip } from './new-feature/new-chat-copilot-home';
import { useGetState } from '../../../../state';
import { routePath } from '../../../../routes';

import {
  InauguralSubjectRoutesPath,
  useHandleTrack,
} from '../../../../modules';

import {
  STUDENT_PROFILE_VIEWED_DESCRIPTION,
  STUDENT_PROFILE_VIEWED,
} from '../../utils/communication-constants';

const ToursContainer: React.FC = () => {
  const history = useHistory();

  const [{ selectedCourseVersionId, selectedCourseEnrollmentType }] =
    useGetState();

  const { states } = useMeContext();

  const studentId = states?.getMe?.me?.id;

  const goToPage = () => {
    const goToPreviousPage = () => {
      history.goBack();
    };

    const goToInauguralSubject = () => {
      history.push(
        generatePath(InauguralSubjectRoutesPath.inauguralSubject, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })
      );
    };

    const goToHomePage = () => {
      history.push(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })
      );
    };

    const goToCourseSelection = () => {
      history.push(generatePath(routePath.courseSelection));
    };

    return {
      goToPreviousPage,
      goToInauguralSubject,
      goToHomePage,
      goToCourseSelection,
    };
  };

  const { appendCommunicationOnTrack } = useHandleTrack({
    studentId,
    input: {
      key: STUDENT_PROFILE_VIEWED,
      desc: STUDENT_PROFILE_VIEWED_DESCRIPTION,
    },
  });

  const { Tour: SubjectTour, TourInfo: SubjectTourInfo } =
    useSubjectTourControls({
      goToPage,
    });

  const { Tour: WelcomeTour, TourInfo: WelcomeTourInfo } =
    useWelcomeTourControls({
      goToPage,
      appendCommunicationOnTrack,
    });

  const { Tour: NewProfileTooltip } = useNewFeatureControls({
    appendCommunicationOnTrack,
  });

  const { Tour: NewChatCopilotHome } = useNewChatCopilotHomeTooltip();
  const { Tour: NewChatCopilotSection } = useNewChatCopilotSectionTooltip();

  return (
    <MultiTour
      data={
        [
          WelcomeTour,
          WelcomeTourInfo,
          SubjectTour,
          SubjectTourInfo,
          NewProfileTooltip,
          NewChatCopilotHome,
          NewChatCopilotSection,
        ] as never
      }
    />
  );
};

export default ToursContainer;
