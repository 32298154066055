import styled from '@emotion/styled';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  background: #f3f6f9;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 2;

  padding: 16px 0;
  border-top: 1px solid #b1c2d5;
  > :last-of-type,
  > :first-of-type {
    padding: 0 16px;
  }
  &button {
    height: fit-content;
  }
`;
