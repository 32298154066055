import React from 'react';
import * as Styled from './live-access-card-header.styles';
import { useTheme } from '@emotion/react';
import { getTimeZone, toExtensiveDate } from '../../../../../../section/shared';
import { Typography, SvgCalender } from '@sofia/ui';
import { useGetLiveAccessCardHeaderHook } from './live-access-card-header.logic';
import LiveTheme from '../../theme/live-theme.presentation';
import { ELiveState } from '../../../types';
import { getDiffTimeBetweenStartAndTodayInMin } from '../../../utils';

interface ILiveAccessCardHeader {
  startDate: string;
  beginningTime: string | null;
  state: ELiveState;
}

const LiveAccessCardHeader = ({
  state,
  startDate,
  beginningTime,
}: ILiveAccessCardHeader): React.ReactElement | null => {
  const theme = useTheme();
  const { isLive } = useGetLiveAccessCardHeaderHook({ state });

  return (
    <Styled.Flex data-testid="subject-live-card">
      <LiveTheme state={state} testId="subject-live-card-theme" />

      {startDate ? (
        <>
          {!isLive ? (
            <Styled.Container data-testid="subject-live-card-date">
              <SvgCalender />
              <Typography
                as="span"
                data-testid="subject-live-card-date-txt"
                color={theme.colors.text.black}
                lineHeight="24px"
                margin="-1px 0 0 0"
              >
                {toExtensiveDate({ startDate })}
              </Typography>
            </Styled.Container>
          ) : null}

          <Styled.Container data-testid="subject-live-card-time">
            <Styled.Clock
              fill={theme.colors.text.lightBlack}
              stroke={theme.colors.text[!isLive ? 'black' : 'lightBlack']}
            />
            <Styled.StartAndEndTimeText
              data-testid="subject-live-card-time-txt"
              color={theme.colors.text[!isLive ? 'black' : 'lightBlack']}
            >
              {isLive
                ? getDiffTimeBetweenStartAndTodayInMin(startDate)
                : beginningTime}
            </Styled.StartAndEndTimeText>
            {!isLive ? (
              <Typography
                as="span"
                size={14}
                weight={700}
                margin="0 0 -3px 4px"
                lineHeight="0.5"
              >
                {getTimeZone()}
              </Typography>
            ) : null}
          </Styled.Container>
        </>
      ) : null}
    </Styled.Flex>
  );
};

export default LiveAccessCardHeader;
