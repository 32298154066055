import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
const _excluded = ["loading", "data"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GET_LOGO_COMPANY, GET_COMPANY_NAME } from './queries';
import { useQuery } from '../hooks';
export const useGetLogoCompany = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useQuery(GET_LOGO_COMPANY, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId
    }
  }));
};
export const useGetCompanyName = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery = useQuery(GET_COMPANY_NAME, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        courseEnrollmentId
      }
    })),
    {
      loading,
      data
    } = _useQuery,
    rest = _objectWithoutProperties(_useQuery, _excluded);
  const company = loading || !data ? {} : data.data;
  return _objectSpread({
    data: company,
    loading
  }, rest);
};