import { ponctuationsRegex } from '../constants';

const getTimeZone = (): string => {
  const today = new Date();
  const short = today.toLocaleDateString(undefined);
  const full = today.toLocaleDateString(undefined, { timeZoneName: 'short' });
  const shortIndex = full.indexOf(short);
  let aux = '';
  if (shortIndex >= 0) {
    const trimmed =
      full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    aux = trimmed.replace(ponctuationsRegex, '');
  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    aux = full;
  }
  return aux.replace(' Padrão', '');
};

export default getTimeZone;
