import { applyMask, clear } from './mask-utils';

interface pluralizeStringProps {
  value: number;
  word: string;
  suffix?: string;
}

export const pluralizeString = ({
  value,
  word,
  suffix = 's',
}: pluralizeStringProps): string => {
  return value == 1 ? word : word + suffix;
};

export const formatSupportedDocumentExtension = (
  extensions: string[]
): string => {
  return extensions
    .map((value) => value.split('/').pop()?.toUpperCase())
    .join(', ')
    .replace(/, ([^,]*)$/, ' e $1');
};

export const convertAndApplyMaskNumber = (
  value: string,
  mask: string
): string => {
  const MAX_LENGTH = clear(mask).length;
  const newValue = clear(value);
  const nextLength = newValue.length;

  if (nextLength > MAX_LENGTH) {
    return value.slice(0, mask.length);
  }

  return applyMask(newValue, mask);
};

export const convertTextToPhonelandline = (value: string): string => {
  const MASK = '(99)9999-9999';
  return convertAndApplyMaskNumber(value, MASK);
};

export const convertTextToPhone = (value: string): string => {
  const MASK = '(99)99999-9999';
  return convertAndApplyMaskNumber(value, MASK);
};

export const convertTextToZipCode = (value: string): string => {
  const MASK = '99999-999';
  return convertAndApplyMaskNumber(value, MASK);
};

export const applyCreditCardMask = (creditCardNumber: string): string => {
  const numericValue = creditCardNumber.replace(/\D/g, '');
  const maskedValue = numericValue.slice(0, 16).replace(/(\d{4})/g, '$1-');

  return maskedValue.slice(0, 19);
};

export const applyDateFormat = (value: string): string => {
  const numericValue = value.replace(/\D/g, '');

  if (numericValue.length <= 2) {
    return numericValue.replace(/^(\d{0,2})/, '$1');
  } else if (numericValue.length <= 4) {
    return numericValue.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
  } else {
    return numericValue.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
  }
};

export const addMaskCPF = (val: string): string => {
  let cpf = '';
  cpf = val.replace(/\D/g, '');
  cpf = val.slice(0, 11);
  cpf = val.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = val.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = val.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return cpf;
};

export const convertTextToCNPJ = (value: string): string => {
  const MASK = '99.999.999/9999-99';
  return convertAndApplyMaskNumber(value, MASK);
};

export const dateFormat = (date: string): string => {
  const utcDate = new Date(date);
  return new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
  ).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
};
