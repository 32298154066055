import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const CardContainer = styled.div`
  > div {
    height: 100%;
    display: flex;
    align-items: center;
    min-height: auto;
    padding: 21px 24px;
    cursor: default;
    :focus {
      border: none;
      outline: none;
      background: white;
    }
  }
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  > :first-of-type {
    margin-right: 16px;
  }
  ${mediaQuery('mobile')} {
    max-width: 128px;
    > :first-of-type {
      margin-right: 0;
    }
  }
`;
