import React from 'react';
import * as Styled from './table-asset-style';

import { TableAssetProps } from '../types';

import { CircularProgressBar } from '@sofia/ui';
import { useTheme } from '@emotion/react';

const TableAsset = ({ data, custom }: TableAssetProps): React.ReactElement => {
  const theme = useTheme();
  const tableRef = React.useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = React.useState<number | undefined>();
  const [scrollLeft, setScrollLeft] = React.useState<boolean>(false);
  const [scrollRight, setScrollRight] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);

  const handleTable = (current: HTMLDivElement | null) => {
    if (current) {
      setHeight(current.offsetHeight);
      setScrollRight(
        current.scrollWidth - current.clientWidth > current.scrollLeft
      );
    }
  };

  React.useEffect(() => {
    if (!height || custom?.fontSize) {
      handleTable(tableRef.current);
    }
  }, [height, custom?.fontSize]);

  React.useEffect(() => {
    const current = tableRef.current;
    window.addEventListener('resize', () => handleTable(current));

    return () =>
      window.removeEventListener('resize', () => handleTable(current));
  });

  const onScroll = (target: HTMLElement) => {
    const max = target.scrollWidth - target.clientWidth;
    const left = target.scrollLeft;
    setScrollLeft(left > 0);
    setScrollRight(max > left);
    setProgress((left * 100) / max);
  };

  return (
    <Styled.Wrapper>
      {scrollRight || progress > 0 ? (
        <CircularProgressBar
          progress={progress}
          hasProgressText={false}
          hasAnimation={false}
          size={24}
          track={{
            stroke: theme.colors.tag.primary,
            strokeWidth: 5,
          }}
          indicator={{
            stroke:
              progress < 100
                ? theme.colors.kindle.ternary
                : theme.colors.text.lighterSuccess,
            strokeWidth: 5,
            lineCap: 'round',
          }}
        />
      ) : null}
      {scrollLeft ? (
        <Styled.LeftShadow height={height} themeColor={custom?.color} />
      ) : null}
      <Styled.TableContainer
        data-testid="table-asset"
        ref={tableRef}
        dangerouslySetInnerHTML={{ __html: data.content }}
        onScroll={(event: React.UIEvent<HTMLElement>) =>
          onScroll(event.currentTarget)
        }
        /* styled props */
        scrollBorder={theme.colors.scrollbar.border}
        scrollBackground={theme.colors.scrollbar.background}
      />
      {scrollRight ? (
        <Styled.RightShadow height={height} themeColor={custom?.color} />
      ) : null}
    </Styled.Wrapper>
  );
};

export default TableAsset;
