import React from 'react';
import { useTheme } from '@emotion/react';
import { Typography, Breadcrumbs, Header } from '@sofia/ui';
import { generatePath, Link, useParams, useHistory } from 'react-router-dom';
import { routePath } from '../../../routes';
import { useGetState } from '../../../state';
import { LayoutContent } from '../../layout';
import MandatoryInternshipConfirmCompanyData from './data/mandatory-internship-confirm-company-data';

const MandatoryInternshipConfirmCompany = () => {
  const theme = useTheme();
  const history = useHistory();
  const { subjectEnrollmentId } = useParams();
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const routeParams = {
    courseType: selectedCourseEnrollmentType,
    courseVersionId: selectedCourseVersionId,
    subjectEnrollmentId: subjectEnrollmentId,
  };

  const returnUrl = generatePath(
    routePath.mandatoryInternshipCompanyInputPage,
    routeParams
  );

  const onBack = () => {
    history.replace(returnUrl);
  };

  const onNext = () => {
    history.push(
      generatePath(routePath.mandatoryInternshipInfoPage, routeParams)
    );
  };

  const breadcrumbsComponent = (
    <Breadcrumbs
      secondColor={theme.colors.breadCrumbs.secondary}
      firstColor={theme.colors.breadCrumbs.primary}
      fill={theme.colors.breadCrumbs.primary}
      stroke={theme.colors.breadCrumbs.primary}
      shadow={false}
      backClick={() => history.goBack()}
    >
      <Link
        to={generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })}
      >
        <span>Início</span>
      </Link>
      <Link to={returnUrl}>
        <span>Cadastrar meu estágio</span>
      </Link>
      <Typography as="span" weight={700}>
        Dados da empresa
      </Typography>
    </Breadcrumbs>
  );

  return (
    <>
      <Header
        titleColor={theme.colors.text.secondary}
        descriptionColor={theme.colors.text.light}
        background={theme.colors.banner.primary}
        breadCrumbs={breadcrumbsComponent}
      />
      <LayoutContent>
        <MandatoryInternshipConfirmCompanyData
          onBack={onBack}
          onNext={onNext}
        />
      </LayoutContent>
    </>
  );
};

MandatoryInternshipConfirmCompany.propTypes = {};

export default MandatoryInternshipConfirmCompany;
