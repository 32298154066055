import React from 'react';
import { useTheme } from '@emotion/react';
import { CardButton, Typography, SvgArrowRight, SvgPlus } from '@sofia/ui';
import { NotesMenuSectionCardProps } from '../../../types';
import * as Styled from './notes-menu-section-card.styles';

const NotesMenuSectionCard = ({
  sectionOrder,
  notesSize,
  onSectionClick,
}: NotesMenuSectionCardProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <Styled.Column>
      <CardButton
        borderColor={theme.colors.card.border.primary}
        onClick={onSectionClick}
        {...(notesSize === 0
          ? {
              backgroundColor: theme.colors.background.primary,
              borderType: 'dashed',
              hoverColor: theme.colors.background.secondary,
            }
          : { hoverColor: theme.colors.background.primary })}
      >
        <Styled.Flex>
          <Styled.Column>
            <Typography
              color={theme.colors.text.black}
              size={20}
              weight={700}
              margin="0 0 8px 0"
            >
              AULA {sectionOrder}
            </Typography>
            <Typography
              color={theme.colors.text.lighterBlack}
              size={14}
              weight={700}
            >
              {notesSize === 0 ? 'SEM' : notesSize} ANOTAÇ
              {notesSize > 1 ? 'ÕES' : 'ÃO'}
            </Typography>
          </Styled.Column>

          <Styled.Icon noNotes={notesSize === 0}>
            {notesSize === 0 ? (
              <SvgPlus fill={theme.colors.text.primary} />
            ) : (
              <SvgArrowRight fill={theme.colors.text.primary} />
            )}
          </Styled.Icon>
        </Styled.Flex>
      </CardButton>
    </Styled.Column>
  );
};

NotesMenuSectionCard.defaultProps = {
  notesSize: 0,
};

export default NotesMenuSectionCard;
