import { ELiveState } from '../../../types';

export interface ILiveAccessCardHeader {
  startDate: string;
  beginningTime: string | null;
  state: ELiveState;
}

type TGetLiveAccessCardHeaderHook = ({ state }: { state: ELiveState }) => {
  isLive: boolean;
};

export const useGetLiveAccessCardHeaderHook: TGetLiveAccessCardHeaderHook = ({
  state,
}) => {
  const isLive = state === ELiveState.LIVE;

  return {
    isLive,
  };
};
