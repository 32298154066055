import React from 'react';

import { bool, elementType, func, node, string } from 'prop-types';
import { generatePath, useHistory } from 'react-router-dom';
import { useGetMeLazy } from '@ampli/services';
import { useModal } from '@sofia/ui';

import { useCourseEnrollmentsContext } from '../../modules/shared/contexts/course-enrollments-context';
import { LayoutContent, LogoWrapper } from '../../components';
import { useGetState } from '../../state';
import { routePath } from '../../routes';
import { Terms, useBreakpoint } from '../../modules';

import LayoutWrapper from './layout-wrapper';
import Navigation from '../navigation/navigation.presentation';

const Layout = ({
  isNavigationItemsEnabled,
  userFirstName,
  children,
  onLogoClick,
  showNavigation,
  freeTrialSituation,
  shouldRedirect = true,
  redirectionBlockAction,
  setLastNavigationRoute,
  logo,
}) => {
  const history = useHistory();
  const handleModal = useModal();

  const { isDesktop } = useBreakpoint();

  const { states } = useCourseEnrollmentsContext();

  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const [getMe, getMeMeta] = useGetMeLazy();

  const { data: courseEnrollmentData } = states.courseEnrollments;

  if (!userFirstName) {
    if (!getMeMeta.called) {
      getMe();
    } else {
      userFirstName = getMeMeta.data?.data?.firstName;
    }
  }

  if (!onLogoClick) {
    onLogoClick = () =>
      history.push(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })
      );
  }

  return (
    <LayoutWrapper id="layout-navbar">
      {!!showNavigation && isDesktop ? (
        <LayoutContent>
          <Navigation
            Logo={<LogoWrapper logo={logo} />}
            freeTrialSituation={freeTrialSituation}
            roles={courseEnrollmentData?.roles}
            shouldRedirect={shouldRedirect}
            redirectionBlockAction={redirectionBlockAction}
            setLastNavigationRoute={setLastNavigationRoute}
          />
        </LayoutContent>
      ) : (
        <Navigation
          isNavigationItemsEnabled={isNavigationItemsEnabled}
          Logo={<LogoWrapper logo={logo} />}
          freeTrialSituation={freeTrialSituation}
          roles={courseEnrollmentData?.roles}
          shouldRedirect={shouldRedirect}
          redirectionBlockAction={redirectionBlockAction}
          setLastNavigationRoute={setLastNavigationRoute}
        />
      )}

      {children}

      <Terms.Modal handleModal={handleModal} />
    </LayoutWrapper>
  );
};

Layout.defaultProps = {
  isNavigationItemsEnabled: true,
  showNavigation: true,
  logoAriaLabel: 'Ir para o início',
  logoutAriaLabel: 'Deslogar do ava',
  shouldRedirect: true,
};

Layout.propTypes = {
  userFirstName: string,
  children: node,
  logo: elementType,
  onLogoutClick: func,
  onLogoClick: func,
  showNavigation: bool,
  logoAriaLabel: string,
  logoutAriaLabel: string,
  freeTrialSituation: string,
  shouldRedirect: bool,
  redirectionBlockAction: func,
  setLastNavigationRoute: func,
  isNavigationItemsEnabled: bool,
};

export default Layout;
