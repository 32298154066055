import React from 'react';

import { Tab, Typography, TabList, tabsKeyNavigation } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { FORMAT_FONT_LIST } from '../../../../constants';
import * as Styled from './format-font-styles';
import {
  TabWrapper as StyledTabWrapper,
  FlexGap as StyledFlexGap,
} from '../../customization-section.styles';
import { FormatFontProps } from '../../../../types';

const FormatFont = ({
  reset,
  defaultValue,
  onChange,
}: FormatFontProps): React.ReactElement => {
  const theme = useTheme();
  const [selectedTabState, setSelectedTabState] = React.useState(defaultValue);

  React.useEffect(() => {
    if (reset) {
      setSelectedTabState(defaultValue);
    }
  }, [defaultValue, reset]);

  const onSelect = (id: FormatFontProps['defaultValue']) => {
    onChange(id);
    setSelectedTabState(id);
  };

  const tabsRef = React.useRef<HTMLDivElement[]>([]);

  const onKeyDown = (key: number) =>
    tabsKeyNavigation(
      key,
      tabsRef.current.map((tab) => tab?.firstChild),
      onSelect
    );

  return (
    <StyledFlexGap data-testid="format-font">
      <Typography size={16} color={theme.colors.text.black} weight={700}>
        Tipos de fontes
      </Typography>
      <Styled.Grid>
        <TabList aria-label="Abas de seleção de fonte do texto">
          {FORMAT_FONT_LIST.map((formatFont, index) => {
            return (
              <StyledTabWrapper
                fontFamily={formatFont.value}
                key={formatFont.id}
                ref={(el) =>
                  !tabsRef.current[index] && el
                    ? (tabsRef.current[index] = el)
                    : null
                }
              >
                <Tab
                  data-testid={`format-font-tab-${formatFont.value}`}
                  id={formatFont.value}
                  aria-label={formatFont.ariaLabel}
                  activeTabId={selectedTabState}
                  onClick={() => onChange(formatFont.value)}
                  onSelect={() => setSelectedTabState(formatFont.value)}
                  onKeyDown={onKeyDown}
                  /* styles props */
                  weight={400}
                  padding="16px"
                  color={theme.colors.text.lightBlack}
                  selectedColor={theme.colors.text.black}
                  selectedBorderColor={theme.colors.text.primary}
                >
                  Aa
                </Tab>
                <Typography
                  data-testid={`format-font-title-${formatFont.value}`}
                  size={14}
                  color={
                    theme.colors.text[
                      selectedTabState === formatFont.value
                        ? 'primary'
                        : 'lightBlack'
                    ]
                  }
                  space="nowrap"
                  weight={selectedTabState === formatFont.value ? 700 : 400}
                  margin="8px 0 0 0 "
                >
                  {formatFont.title}
                </Typography>
              </StyledTabWrapper>
            );
          })}
        </TabList>
      </Styled.Grid>
    </StyledFlexGap>
  );
};

export default FormatFont;
