import React, { KeyboardEvent, MouseEvent } from 'react';
import SubjectMenuListItemText from './text/section-menu-list-item-text';
import { KEY_CODES } from '../../../../../../utils/token';

import * as Styled from './section-menu-list-item-styles';
import { SectionMenuListItemProps } from '../types';

const SectionMenuListItem = ({
  onMenuItemClick,
  active,
  activeListItemRef,
  ariaLabelText,
  icon,
  title,
  subTitle,
  pathUrl,
  children,
  open,
  index,
}: SectionMenuListItemProps): React.ReactElement => {
  const onKeyDownEvent = (e: KeyboardEvent) => {
    if (
      !active &&
      [KEY_CODES.enter.keyCode, KEY_CODES.space.keyCode].indexOf(e.keyCode) >= 0
    ) {
      onMenuItemClick(e, { pathUrl });
    }
  };

  return (
    <Styled.MenuListItem
      data-testid={`beta-section-menu-list-item${index + 1}`}
      ref={active ? activeListItemRef : null}
      tabIndex={0}
      role="link"
      onClick={(e: MouseEvent) =>
        !active ? onMenuItemClick(e, { pathUrl }) : null
      }
      onKeyDown={onKeyDownEvent}
      aria-label={ariaLabelText}
      className={`${active ? 'active' : ''} ${
        !open ? 'closed' : ''
      } section-menu-list-item`}
    >
      {!children ? (
        <>
          {icon}
          {open ? (
            <SubjectMenuListItemText
              active={active}
              title={title}
              subTitle={subTitle}
            />
          ) : null}
        </>
      ) : (
        children
      )}
    </Styled.MenuListItem>
  );
};

SectionMenuListItem.defaultProps = {
  subTitle: null,
};

export default SectionMenuListItem;
