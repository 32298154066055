import React from 'react';

import { Slider } from '@sofia/ui';

import { CustomizationSliderProps } from '../../types';

const CustomizationSlider = <SliderType extends unknown>({
  sufix,
  config,
  ariaLabel,
  value,
  onChange,
}: CustomizationSliderProps<SliderType>): React.ReactElement => {
  return (
    <Slider
      data-testid="customization-slider"
      sufix={sufix}
      defaultValue={value}
      max={config.MAX}
      min={config.MIN}
      step={config.STEP}
      aria-label={ariaLabel}
      onChange={(newValue: SliderType) => onChange(newValue)}
    />
  );
};

export default CustomizationSlider;
