import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW, convertPXToREM } from '../../utils';
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${_ref => {
  let {
    padding = 24
  } = _ref;
  return `${convertPXToVH(padding)} ${convertPXToVW(padding)}`;
}};
  gap: ${_ref2 => {
  let {
    gap = 18
  } = _ref2;
  return `${convertPXToVW(gap)}`;
}};
  background: ${_ref3 => {
  let {
    background
  } = _ref3;
  return background;
}};
  border-radius: 8px;
`;
export const WrapperText = styled.div`
  flex-grow: 1;
`;
export const Text = styled.p`
  font-size: ${convertPXToREM(16)};
  font-weight: 400;
  margin: 0;
  color: ${_ref4 => {
  let {
    color
  } = _ref4;
  return color;
}};
`;