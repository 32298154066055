import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';

export const ListContainer = styled.div`
  ${({ last }) => !last && `margin-bottom: 16px`};
`;

export const SectionList = styled.div(
  ({ last }) => `
  ${!last ? 'margin-bottom: 24px;' : ''}
`
);

export const SectionItemList = styled.div`
  ${mediaQuery('desktop')} {
    margin-right: auto;
  }
`;

export const SectionTitleWrapper = styled.div`
  ${mediaQuery('desktop')} {
    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;
