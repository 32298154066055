import React from 'react';
import { useHistory } from 'react-router-dom';
import { bool, func, string } from '@ampli/utils';
import { ColorPrimary500 } from '@ampli/ui';
import {
  Title,
  Description,
  Button,
  ArrowRightIcon,
  CloseIcon,
  StyledPopover,
} from './free-trial-popover.styles';

const FreeTrialPopover = ({
  freeTrialSituation,
  selectedCourseEnrollmentType,
  selectedCourseVersionId,
  closedFreeTrialAdvise,
  ...props
}) => {
  const history = useHistory();

  const onCloseClick = () => {
    props.hide();
    sessionStorage.setItem('closedFreeTrialAdvise', true);
  };

  const title =
    freeTrialSituation === 'STARTED'
      ? 'Esta gostando das aulas?'
      : 'Conclua sua matrícula!';
  const description =
    freeTrialSituation === 'STARTED'
      ? 'Faça o pagamento e tenha acesso a nova disciplina assim que finalizar essa.'
      : 'O seu tempo de teste esta acabando, faça sua matricula e continue estudando.';

  if (closedFreeTrialAdvise) return '';

  return (
    <StyledPopover
      {...props}
      hideOnClickOutside={false}
      hideOnEsc={false}
      aria-label={description}
      background="white"
    >
      <CloseIcon onClick={onCloseClick} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Button
        tabIndex={0}
        onClick={() =>
          history.push(
            `/${selectedCourseEnrollmentType}/${selectedCourseVersionId}/pagamento?utm_source=ava-sofia&utm_medium=aviso-disciplina`
          )
        }
      >
        Renovar <ArrowRightIcon color={ColorPrimary500} />
      </Button>
    </StyledPopover>
  );
};

FreeTrialPopover.propTypes = {
  freeTrialSituation: string,
  closedFreeTrialAdvise: bool,
  selectedCourseEnrollmentType: string,
  selectedCourseVersionId: string,
  hide: func.isRequired,
};

export default FreeTrialPopover;
