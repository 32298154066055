import React from 'react';

type ImageEmptySearchProps = React.SVGProps<SVGSVGElement>;

function ImageEmptySearch(props: ImageEmptySearchProps): React.ReactElement {
  return (
    <svg
      width="192"
      height="124"
      viewBox="0 0 192 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M95.0847 118.661C121.699 118.661 143.274 97.0862 143.274 70.472C143.274 43.8579 121.699 22.2828 95.0847 22.2828C68.4705 22.2828 46.8955 43.8579 46.8955 70.472C46.8955 97.0862 68.4705 118.661 95.0847 118.661Z"
        fill="#C5D2E0"
      />
      <path
        d="M149.848 83.5474C151.242 83.5966 152.614 84.0383 153.702 84.9108C155.077 86.012 155.848 87.5366 155.748 88.9213C155.556 91.5781 152.116 94.266 148.071 93.226L111.813 82.2067L149.848 83.5474Z"
        fill="#343A40"
        stroke="#343A40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.888 67.1724C189.261 72.7981 165.817 79.7485 165.817 79.7485C151.241 84.0749 145.308 84.6642 140.941 84.8203C138.277 84.9159 135.91 84.8556 133.945 84.7397C133.117 85.8871 132.352 87.0792 131.654 88.3105C130.747 89.9087 129.953 91.568 129.277 93.2765L109.347 88.3105C110.921 83.6635 113.085 79.2373 115.784 75.1401C119.244 69.9122 122.502 64.9814 128.36 62.8661C131.291 61.8034 134.031 61.7884 136.257 61.9998C140.024 62.359 143.819 62.288 147.569 61.7884C148.791 61.6343 150.002 61.4038 151.195 61.0983H151.201C153.215 60.5796 155.205 59.8443 160.226 58.5902C172.364 55.5529 176.595 55.9913 179.546 58.1318C180.03 58.4806 180.478 58.8753 180.886 59.3103L180.674 59.3255C182.578 59.2701 184.512 59.391 186.204 59.8392C188.305 60.3932 188.612 61.1789 188.687 61.4761C188.914 62.3978 188.043 63.3346 187.741 63.6367L187.514 63.6468C189.267 63.768 190.324 64.1907 190.798 65.0369C191.266 65.8578 190.984 66.8349 190.888 67.1724Z"
        fill="#DBE3EC"
      />
      <path
        d="M163.161 68.2805C171.623 64.918 180.678 63.3012 189.781 63.527"
        stroke="#343A40"
        strokeMiterlimit="10"
      />
      <path
        d="M158.391 64.8556C166.014 61.6853 174.074 59.6856 182.295 58.9243"
        stroke="#343A40"
        strokeMiterlimit="10"
      />
      <path
        d="M109.347 88.3108C110.919 83.6627 113.082 79.236 115.783 75.1397C119.241 69.9111 122.499 64.9837 128.356 62.8665C132.519 61.3612 136.285 61.9591 138.756 62.3211C145.217 63.2674 145.837 65.6804 152.231 67.7747C163.072 71.3255 171.526 67.74 173.401 71.8547C173.923 72.9998 173.654 74.1232 173.586 74.4051C172.212 80.1396 158.475 81.364 151.873 81.3149C148.476 81.2885 145.091 80.9152 141.771 80.2009"
        fill="#DBE3EC"
      />
      <path
        d="M138.766 62.3218C145.227 63.268 145.847 65.681 152.24 67.7753C163.082 71.3261 171.536 67.7406 173.411 71.8553C173.933 73.0004 173.663 74.1239 173.596 74.4057C172.221 80.1402 158.485 81.3646 151.882 81.3156C148.486 81.2892 145.101 80.9159 141.781 80.2015"
        stroke="#343A40"
        strokeMiterlimit="10"
      />
      <path
        d="M107.256 108.497C106.748 103.394 106.775 96.2192 109.349 88.3116C110.03 86.2219 110.855 84.182 111.818 82.2067C130.542 88.2965 144.489 92.2536 148.076 93.226C149.095 93.5019 150.767 93.688 152.441 92.9366C154.104 92.1896 155.055 90.7836 155.545 89.8865L145.146 123.999"
        fill="#7996B7"
      />
      <path
        d="M152.913 98.5258C148.446 99.9276 143.658 99.936 139.186 98.55L109.068 89.2156"
        stroke="#343A40"
        strokeMiterlimit="10"
      />
      <path
        d="M111.813 82.2067L148.071 93.226C149.518 93.6136 151.053 93.5119 152.436 92.9366C154.309 92.1467 155.248 90.7597 155.558 89.8311C155.552 89.8493 155.546 89.8678 155.54 89.8862"
        stroke="#343A40"
        strokeMiterlimit="10"
      />
      <path
        d="M41.2076 83.5474C39.8134 83.5966 38.4416 84.0383 37.353 84.9108C35.9788 86.012 35.207 87.5366 35.307 88.9213C35.4989 91.5781 38.9393 94.266 42.9839 93.226L79.242 82.2067L41.2076 83.5474Z"
        fill="#343A40"
        stroke="#343A40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.171846 67.1724C1.79867 72.7981 25.2435 79.7485 25.2435 79.7485C39.8193 84.0749 45.7523 84.6642 50.1189 84.8203C52.7832 84.9159 55.1503 84.8556 57.1146 84.7397C57.9432 85.8871 58.7082 87.0792 59.4061 88.3105C60.3129 89.9087 61.1072 91.568 61.7834 93.2765L81.7129 88.3105C80.1386 83.6635 77.9754 79.2373 75.2762 75.1401C71.8162 69.9122 68.5577 64.9814 62.7001 62.8661C59.7688 61.8034 57.0289 61.7884 54.8028 61.9998C51.0364 62.359 47.2413 62.288 43.4909 61.7884C42.2689 61.6343 41.0577 61.4038 39.8646 61.0983H39.8594C37.8446 60.5796 35.8554 59.8443 30.834 58.5902C18.696 55.5529 14.4654 55.9913 11.514 58.1318C11.0305 58.4806 10.5818 58.8753 10.1743 59.3103L10.3858 59.3255C8.48206 59.2701 6.54795 59.391 4.85568 59.8392C2.75546 60.3932 2.44832 61.1789 2.37266 61.4761C2.14611 62.3978 3.01739 63.3346 3.31945 63.6367L3.54607 63.6468C1.79337 63.768 0.735985 64.1907 0.262284 65.0369C-0.205887 65.8578 0.0761822 66.8349 0.171846 67.1724Z"
        fill="#DBE3EC"
      />
      <path
        d="M27.8991 68.2805C19.4366 64.918 10.3816 63.3012 1.27832 63.527"
        stroke="#343A40"
        strokeMiterlimit="10"
      />
      <path
        d="M32.6723 64.8556C25.0484 61.6853 16.9892 59.6856 8.76758 58.9243"
        stroke="#343A40"
        strokeMiterlimit="10"
      />
      <path
        d="M81.7093 88.3108C80.1375 83.6627 77.9744 79.236 75.2734 75.1397C71.8157 69.9111 68.5574 64.9837 62.7009 62.8665C58.5373 61.3612 54.7718 61.9591 52.3006 62.3211C45.8397 63.2674 45.2191 65.6804 38.8258 67.7747C27.9845 71.3255 19.5307 67.74 17.6555 71.8547C17.1336 72.9998 17.4029 74.1232 17.4704 74.4051C18.8449 80.1396 32.5813 81.364 39.1839 81.3149C42.5803 81.2885 45.965 80.9152 49.2855 80.2009"
        fill="#DBE3EC"
      />
      <path
        d="M52.3006 62.3218C45.8397 63.268 45.2191 65.681 38.8258 67.7753C27.9845 71.3261 19.5307 67.7406 17.6555 71.8553C17.1336 73.0004 17.4029 74.1239 17.4704 74.4057C18.8449 80.1402 32.5813 81.3646 39.1839 81.3156C42.5803 81.2892 45.965 80.9159 49.2855 80.2015"
        stroke="#343A40"
        strokeMiterlimit="10"
      />
      <path
        d="M83.8017 108.497C84.3092 103.394 84.2824 96.2192 81.7083 88.3116C81.0275 86.2219 80.2024 84.182 79.239 82.2067C60.5152 88.2965 46.5687 92.2536 42.981 93.226C41.9628 93.5019 40.2902 93.688 38.6169 92.9366C36.9535 92.1896 36.0022 90.7836 35.5122 89.8865L45.9114 123.999"
        fill="#7996B7"
      />
      <path
        d="M38.1538 98.5258C42.621 99.9276 47.409 99.936 51.8811 98.55L81.9994 89.2156"
        stroke="#343A40"
        strokeMiterlimit="10"
      />
      <path
        d="M79.2441 82.2067L42.986 93.226C41.5392 93.6136 40.0048 93.5119 38.6219 92.9366C36.7481 92.1467 35.8094 90.7597 35.4995 89.8311C35.5056 89.8493 35.5113 89.8678 35.5172 89.8862"
        stroke="#343A40"
        strokeMiterlimit="10"
      />
      <path
        d="M128.593 3.54461C129.417 3.09435 130.338 2.84861 131.277 2.82806C132.217 2.80751 133.147 3.01279 133.991 3.42664L133.992 3.42692L154.708 13.5571C151.603 14.9419 144.458 18.8168 140.168 27.3388C136.195 35.2296 136.914 42.6471 137.483 45.883L114.155 34.476C113.623 31.8722 112.439 24.1187 116.611 15.8328L116.165 15.6079L116.611 15.8328C119.972 9.1582 125.15 5.43119 128.593 3.54461Z"
        fill="white"
        stroke="#495057"
      />
      <path
        d="M83.5561 1.48254C77.6586 -0.815299 71.0035 -0.38674 65.149 2.38472L64.8974 2.50053C59.1095 5.16375 54.553 9.88826 52.4939 15.7756C49.5778 24.1139 52.066 33.6415 58.1623 39.5406C63.9591 45.1594 72.6894 47.1616 81.0512 45.0523C84.1927 52.3705 92.6059 72.2805 93.615 74.5052C93.8025 74.9186 94.0721 75.2894 94.4075 75.5952C94.7429 75.901 95.137 76.1354 95.5658 76.284C95.9946 76.4327 96.4492 76.4925 96.9019 76.4599C97.3546 76.4273 97.7959 76.3029 98.199 76.0943L98.2596 76.0628C99.0339 75.662 99.6211 74.9749 99.8962 74.1476C100.171 73.3203 100.113 72.4184 99.7326 71.6337C98.8202 69.7498 89.3942 47.5308 87.0838 42.3079C90.2041 40.1299 92.7654 37.377 94.6149 34.2879C96.9878 30.3241 98.1958 25.8046 97.9173 21.2475C97.4034 12.78 91.7859 4.6924 83.5561 1.48254ZM86.8174 34.0893C85.3328 35.7273 83.5323 37.0479 81.5241 37.972C79.516 38.896 77.3416 39.4043 75.1319 39.4662C68.9716 39.6452 62.1698 36.3938 59.3066 30.0992C57.2161 25.495 57.6913 20.2752 59.6777 16.423C61.3996 13.0804 64.3852 10.448 68.1049 8.80854C71.7744 7.04795 75.7114 6.49495 79.3701 7.36184C83.5926 8.35726 87.8616 11.3937 89.9982 15.9768C92.9209 22.2439 90.9654 29.525 86.8174 34.0893Z"
        fill="#9DB2CA"
      />
      <path
        d="M86.8181 34.091C85.3336 35.729 83.5331 37.0496 81.5249 37.9737C79.5167 38.8977 77.3424 39.406 75.1327 39.4679C68.9724 39.6469 62.1705 36.3955 59.3074 30.1009C57.2168 25.4967 57.6921 20.2769 59.6785 16.4247C61.4004 13.0821 64.386 10.4497 68.1057 8.81023C71.7752 7.04965 75.7121 6.49664 79.3709 7.36353C83.5933 8.35895 87.8623 11.3954 89.999 15.9785C92.9217 22.2456 90.9662 29.5267 86.8181 34.091Z"
        fill="white"
        stroke="#343A40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ImageEmptySearch;
