import React from 'react';

import {
  CustomizationDataProps,
  ITalkTutorInfos,
  MeType,
} from '../../../shared';

import {
  ContentEvaluationDataProps,
  TFindLearningUnitEnrollmentType,
  TSectionForLearningUnitType,
} from '../../types';

import { TLearningObject, TSectionEnrollment } from '../../shared';
import {
  ICourseEnrollmentsData,
  ICourse,
} from '../../../shared/contexts/course-enrollments-context.types';
import { SubjectEnrollment } from '../../../shared/graphql/types';

type TSectionPageLogic = () => {
  selectedCustomization: CustomizationDataProps | null;
  openTalkTutorFrame: boolean;
  sectionNavigationRef: React.RefObject<HTMLDivElement> | undefined;
  learningObjects: TLearningObject[];
  learningObject: TLearningObject | Record<string, never>;
  learningUnit: TFindLearningUnitEnrollmentType | Record<string, never>;
  section: TSectionForLearningUnitType | Record<string, never>;
  subjectId: string;
  loading: boolean;
  pageTitle: string;
  talkTutorInfos: ITalkTutorInfos;
  studentId: string;
  learningObjectPath: string;
  subjectEnrollmentId: string;
  dispatch: React.Dispatch<Partial<SectionPageReducerType>>;
  onCloseCustomization: () => void;
  handleMenuItemClick: THandleMenuItemClick;
  persistData: CustomizationDataProps | null;
  openNotes: boolean;
  openMenu: boolean;
  openCustomization: boolean;
  selectedCourseEnrollmentType: string;
  setOpenMenu: (openMenu: boolean) => void;
  setOpenCustomization: (openCustomization: boolean) => void;
  setOpenNotes: (openNotes: boolean) => void;
  setOpenTalkTutorFrame: (openTalkTutorFrame: boolean) => void;
  setOpenCommunitySection: (openCommunitySection: boolean) => void;
  attendanceErrorMsg: React.MutableRefObject<boolean>;
};

/* COPILOT */
export enum ESectionCopilotStudentMoment {
  LEARNING_TEXT = 'LEARNING_TEXT',
  LEARNING_VIDEO = 'LEARNING_VIDEO',
  LEARNING_QUESTION = 'LEARNING_QUESTION',
}
interface ISectionCopilotLearningText {
  classContent?: string; // Conteúdo da Aula
  classSectionName?: string; // Nome do objecto de aprendizagem
  classSectionContent?: string; // Conteúdo do objecto de aprendizagem
}
interface ISectionCopilotLearningVideo {
  frameVideoURL?: string; // URL do Frame do Video
  actualTimeVideo?: string; // Momento Atual do Vídeo
  videoID?: string; // ID do Vídeo (geralmente o ID do vídeo no MediaStream)
}
interface ISectionCopilotLearningQuestion {
  exerciseQuestion?: string; // Questão do Exercício
  questionDescription?: string; // Descrição do Exercício
  initialInteraction?: boolean; // É ou Não a Interação Inicial (primeira questão)
}
export interface ISectionCopilotPayload
  extends ISectionCopilotLearningText,
    ISectionCopilotLearningVideo,
    ISectionCopilotLearningQuestion {
  studentMoment: ESectionCopilotStudentMoment; // Momento do Aluno
  studentName: string; // Nome do Aluno
  academicRegistration: string; // RA do Aluno
  courseName: string; // Nome do Curso
  subjectName: string; // Nome da Disciplina
  className: string; // Nome da Aula
  classID: string; // ID do Aula (geralmente o ID da aula no Alexandria)
}
/* FIM COPILOT */

type THandleMenuItemClick = (
  event: React.MouseEvent | React.KeyboardEvent,
  path: { pathUrl: string }
) => void;

type LearningObjectDataReturnType = {
  firstLearningObject: TLearningObject | Record<string, never>;
  learningObject: TLearningObject | Record<string, never>;
  learningObjectIndex: number;
  previousLearningObject: TLearningObject | null;
  nextLearningObject: TLearningObject | null;
  isLastLearningObject: boolean;
  isFirstLearningObject: boolean;
  isLastLearningObjectToComplete: boolean;
};

export type CommonSectionPageData = {
  courseEnrollment: ICourseEnrollmentsData;
  externalId: string;
  course: ICourse | null;
  subjectEnrollment: SubjectEnrollment | Record<string, never>;
  me: MeType | Record<string, never>;
  learningUnit: TFindLearningUnitEnrollmentType | Record<string, never>;
  section: TSectionEnrollment | Record<string, never>;
  learningObjects: TLearningObject[];
  loading: boolean;
};

type SectionContextType = CommonSectionPageData & LearningObjectDataReturnType;

type SectionPageReducerType = {
  openTalkTutorFrameDate: string | null;
  selectedCustomization: CustomizationDataProps | null;
  isVideoViewCompleted: boolean | null;
};

type SectionPageDataHook = () => CommonSectionPageData & {
  learningObjectData: LearningObjectDataReturnType;
};

type CreateAttendanceHook = (
  props:
    | {
        attendance: {
          subjectEnrollmentId: string;
          learningUnitId: string;
          sectionId: string;
          learningObjectId: string;
        };
        isSectionNavigationVisible: boolean;
        isVideoType: boolean;
        isVideoViewCompleted?: boolean | null;
        isLearningObjectCompleted: boolean;
        isLastLearningObjectToComplete: boolean;
        isSubjectEnrollmentEnabled: boolean;
        attendanceErrorMsg: React.MutableRefObject<boolean>;
      }
    | Record<string, never>
) => void;

/* components props */
interface FeedbackSentProps {
  onClickReviewSent: () => void;
}

type ContentEvaluationResponseProps = ContentEvaluationDataProps & {
  __typename?: 'ContentEvaluation';
  id: string;
};

interface ContentEvaluationProps {
  contentEvaluation: ContentEvaluationResponseProps;
  onNewContentEvaluation: () => void;
  sendFeedback: () => void;
}

export interface FooterProps {
  learningObjects: TLearningObject[];
}

export type {
  LearningObjectDataReturnType,
  SectionContextType,
  SectionPageReducerType,
  SectionPageDataHook,
  CreateAttendanceHook,
  FeedbackSentProps,
  ContentEvaluationResponseProps,
  ContentEvaluationProps,
  ContentEvaluationDataProps,
  TSectionPageLogic,
  THandleMenuItemClick,
};
