import { ConversationInfo, FormType, Pageable } from '../types';
import { InfosProps } from '../../../shared';

export const PtkPagesOptions = [
  'HOME',
  'CONVERSATION_CHAT',
  'FORM_TYPE_SELECTION',
  'FORM',
  'SUCCESS',
  'ERROR',
  'RATING',
  'HISTORIC',
  'BLOCKED',
] as const;

export type PtkPages = typeof PtkPagesOptions[number];

export type State = {
  isRespondingByHistory: boolean;
  hideAttachmentField: boolean;
  conversation: ConversationInfo | null;
  onCloseClick: () => void;
  isSubmiting: boolean;
  pageable: Pageable;
  formType: typeof FormType[number];
  infos: InfosProps;
  backTo: PtkPages | null;
  page: PtkPages;
};

export type Reducer = React.Reducer<State, Partial<State>>;

export const initialValues = (initialPtkPage: PtkPages): State => ({
  isRespondingByHistory: false,
  hideAttachmentField: false,
  onCloseClick: () => {},
  conversation: null,
  isSubmiting: false,
  formType: 'DUVIDA',
  pageable: { page: 0, size: 99999, sort: ['modifiedAt,DESC'] },
  backTo: null,
  infos: {},
  page: initialPtkPage,
});

export const reducer: Reducer = (state, action) => ({ ...state, ...action });
