import React from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { LayoutContent } from '../../../../../components';
import { NotesBannerProps } from './types';
import * as Styled from './styles';

const NotesBanner = ({
  mobileChildIndex,
  children,
}: NotesBannerProps): React.ReactElement => {
  const history = useHistory();
  const theme = useTheme();

  return (
    <Styled.BannerWrapper background={theme.colors.banner.primary}>
      <LayoutContent padding={['none', 'small']}>
        <Breadcrumbs
          secondColor={theme.colors.breadCrumbs.secondary}
          firstColor={theme.colors.breadCrumbs.primary}
          fill={theme.colors.breadCrumbs.primary}
          mobileChildIndex={mobileChildIndex}
          backClick={() => {
            history.go(-1);
          }}
          shadow={false}
        >
          {children}
        </Breadcrumbs>
      </LayoutContent>
    </Styled.BannerWrapper>
  );
};

NotesBanner.defaultProps = {
  mobileChildIndex: 0,
};

export default NotesBanner;
