import React from 'react';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '@ampli/hooks';
import {
  ButtonGhost,
  ButtonPrimary,
  Input,
  Typography,
  useModal,
  PopUp,
  SvgRedo,
} from '@sofia/ui';
import { useFormik, convertTextToCNPJ } from '@ampli/utils';
import * as Styled from './mandatory-internship-company-input-form.styles';
import { func } from 'prop-types';
import { getCompanyInputSchema } from '../schemas/company-input.schema';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { routePath } from '../../../../routes';
import { useGetState } from '../../../../state';
import { useMandatoryInternshipContext } from '../../../../routes/internship-routes';
import { useCheckCnpj } from '../../services/hooks';

const MandatoryInternshipCompanyInputForm = ({ onSubmit }) => {
  const theme = useTheme();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const errorModal = useModal();
  const { subjectEnrollmentId } = useParams();
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const { company, setCompany } = useMandatoryInternshipContext();

  const { query, data, loading } = useCheckCnpj({
    options: {
      onError: () => errorModal.show(),
      onCompleted: () => {
        if (data) {
          // eslint-disable-next-line no-unused-vars
          const { __typename, ...rest } = data;
          setCompany(rest);
          onSubmit();
        }
      },
    },
  });

  const formik = useFormik({
    initialValues: {
      cnpj: company?.cnpj || '',
    },
    validationSchema: getCompanyInputSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    initialErrors: false,
  });

  const onNextClick = () => {
    query({
      variables: {
        cnpj: formik.values.cnpj,
      },
    });
  };

  const onNoInternshipYetClick = () => {
    history.push(
      generatePath(routePath.mandatoryInternshipGuidePage, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
        subjectEnrollmentId: subjectEnrollmentId,
      })
    );
  };

  return (
    <>
      <Input
        id="cnpj"
        label="CNPJ *"
        aria-label="CNPJ da empresa"
        placeholder="00.000.000/0000-00"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={convertTextToCNPJ(formik?.values?.cnpj) || ''}
        error={Boolean(formik.touched.cnpj && formik.errors.cnpj)}
        infoText={formik.touched.cnpj && formik.errors.cnpj}
        labelWeight="700"
        maxLength={18}
        width="100%"
        required
      />

      <Styled.ButtonsWrapper>
        <ButtonGhost
          hoverColor={theme.colors.button.darkPrimary}
          color={theme.colors.button.primary}
          aria-label="Ainda não tenho um estágio"
          onClick={onNoInternshipYetClick}
        >
          Ainda não tenho um estágio
        </ButtonGhost>
        <ButtonPrimary
          color={theme.colors.text.white}
          background={theme.colors.button.primary}
          hover={theme.colors.button.darkPrimary}
          aria-label="Avançar para próxima etapa"
          width="100%"
          type="submit"
          loading={loading}
          disabled={
            !formik.isValid ||
            Object.keys(formik.errors).length > 0 ||
            !formik.values.cnpj
          }
          onClick={onNextClick}
        >
          {loading ? <SvgRedo id="rotate" /> : 'Avançar'}
        </ButtonPrimary>
      </Styled.ButtonsWrapper>

      <PopUp
        handlePopUp={errorModal}
        isBottomSheet={!isDesktop}
        header={{
          headerText: 'O CNPJ que você digitou não é válido ou não está ativo',
        }}
        footer={{
          buttonText: 'Entendi',
          background: theme.colors.button.primary,
          buttonClick: errorModal.hide,
        }}
        ariaLabel="Ajuda da disciplina optativa"
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          Verifique se preencheu o CNPJ corretamente ou entre em contato com a
          empresa para conseguir um CNPJ válido.
        </Typography>
      </PopUp>
    </>
  );
};

MandatoryInternshipCompanyInputForm.propTypes = {
  onSubmit: func.isRequired,
};

export default MandatoryInternshipCompanyInputForm;
