import React, { forwardRef } from 'react';
import { string, func, array, number, oneOf } from 'prop-types';
import { DateTime, formatIsoDate, intervalInDays } from '@ampli/utils';
import { Card, convertPXToVW, Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { AddIcon, TimerIcon } from '@ampli/icon';
import { useBreakpoint } from '@ampli/hooks';
import { getTruncatedDateTime } from '../../utils';
import { subjectChooseCard } from '../../constants';
import {
  CardWrapper,
  AddWrapper,
  ElectiveTitle,
  FlexWrapperDeadline,
  FlexWrapperTimeLeft,
  CardContainer,
} from './card.styles';

const ElectiveSubjectCard = forwardRef(
  (
    {
      endDateForChoice,
      startDateForChoice,
      onClick,
      id,
      electiveSubject,
      degree,
      order,
      electiveType,
    },
    ref
  ) => {
    const theme = useTheme();
    const breakpoint = useBreakpoint();
    const isDesktop = breakpoint == 'desktop';
    const endDate = DateTime.fromISO(endDateForChoice);
    const startDate = DateTime.fromISO(startDateForChoice);
    const endDateFormatted = formatIsoDate(endDateForChoice);
    const actualDate = getTruncatedDateTime();
    const daysRemaining = intervalInDays(actualDate, endDate);
    const started = startDate <= actualDate && endDate >= actualDate;
    const getPlural = () => (daysRemaining > 1 ? 's' : '');
    const electiveTypeChooseTitle = subjectChooseCard[electiveType].title;

    const chooseElectiveCardTitle = () => {
      if (degree === 'UNDERGRADUATE') {
        return electiveTypeChooseTitle;
      } else if (electiveSubject.length === 1) {
        return 'Veja a disciplina optativa disponível';
      } else {
        return `Veja a disciplina optativa ${order} disponível`;
      }
    };

    return (
      <Card
        id={id}
        minWidth={convertPXToVW(295)}
        minHeight="initial"
        hover={theme.colors.card.color.hover}
        onClick={started ? onClick : null}
        ref={ref}
        borderColor={theme.colors.text.primary}
        borderType={theme.colors.card.border.borderType}
        shadow={false}
      >
        <CardContainer background={theme.colors.background.primary}>
          <CardWrapper>
            {isDesktop ? (
              <AddWrapper background={theme.colors.icon.background}>
                <AddIcon color={theme.colors.text.primary} />
              </AddWrapper>
            ) : null}
            <Typography
              size={isDesktop ? 32 : 20}
              weight="bold"
              color={theme.colors.text.black}
              lineHeight={isDesktop ? '38.4px' : '24px'}
              aria-label={chooseElectiveCardTitle()}
            >
              {chooseElectiveCardTitle()}
            </Typography>
          </CardWrapper>
          <ElectiveTitle>
            <FlexWrapperDeadline>
              <Typography
                as="span"
                size={isDesktop ? 20 : 16}
                color={theme.colors.text.lightBlack}
                aria-label="Você tem até"
                margin={`0px ${convertPXToVW(5)} 0px 0px`}
              >
                Prazo para escolha:
              </Typography>
              <Typography
                as="span"
                color={theme.colors.text.black}
                weight="bold"
                size={isDesktop ? 20 : 16}
                aria-label={`${endDateFormatted} para escolher,`}
              >
                {endDateFormatted}
              </Typography>
            </FlexWrapperDeadline>
            <FlexWrapperTimeLeft>
              <TimerIcon
                color={
                  daysRemaining < 1
                    ? theme.colors.card.color.warning
                    : theme.colors.text.primary
                }
              />
              <Typography
                color={
                  daysRemaining < 1
                    ? theme.colors.card.color.warning
                    : theme.colors.text.primary
                }
                bold
                margin={`0px 0px 0px ${convertPXToVW(8)}`}
                weight="bold"
                size={isDesktop ? 20 : 16}
                aria-label={`faltam ${daysRemaining} ${
                  daysRemaining < 2 ? 'dia' : 'dias'
                }`}
              >
                {daysRemaining < 1
                  ? 'Até hoje'
                  : `${daysRemaining} dia${getPlural()} restante${getPlural()}`}
              </Typography>
            </FlexWrapperTimeLeft>
          </ElectiveTitle>
        </CardContainer>
      </Card>
    );
  }
);

ElectiveSubjectCard.defaultProps = {
  electiveType: 'OPTIONAL',
};

ElectiveSubjectCard.propTypes = {
  endDateForChoice: string.isRequired,
  startDateForChoice: string.isRequired,
  onClick: func.isRequired,
  electiveSubject: array,
  id: string.isRequired,
  order: number,
  degree: string.isRequired,
  electiveType: oneOf(['MANDATORY', 'OPTIONAL']),
};

ElectiveSubjectCard.displayName = 'ElectiveSubjectCard';

export default ElectiveSubjectCard;
