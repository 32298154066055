import React from 'react';

import { useTheme } from '@emotion/react';
import { Tab, Typography, tabsKeyNavigation } from '@sofia/ui';
import { FONT_ALIGN_VALUES } from '../../../../constants';

import * as Styled from './format-align-styles';
import {
  TabWrapper as StyledTabWrapper,
  FlexGap as StyledFlexGap,
} from '../../customization-section.styles';
import { FormatAlignProps } from '../../../../types';

const FormatAlign = ({
  reset,
  defaultValue,
  onChange,
}: FormatAlignProps): React.ReactElement => {
  const theme = useTheme();
  const [selectedTabState, setSelectedTabState] = React.useState(defaultValue);

  React.useEffect(() => {
    if (reset) {
      setSelectedTabState(defaultValue);
    }
  }, [defaultValue, reset]);

  const tabsRef = React.useRef<HTMLDivElement[]>([]);

  const onSelect = (id: FormatAlignProps['defaultValue']) => {
    onChange(id);
    setSelectedTabState(id);
  };

  const onKeyDown = (key: number) =>
    tabsKeyNavigation(
      key,
      tabsRef.current.map((tab) => tab?.firstChild),
      onSelect
    );

  return (
    <StyledFlexGap data-testid="format-align">
      <Typography size={16} color={theme.colors.text.black} weight={700}>
        Alinhamentos de parágrafos
      </Typography>
      <Styled.TabList aria-label="Abas de seleção de alinhamento de texto">
        {FONT_ALIGN_VALUES.map(({ type, label, ariaLabel, icon }, index) => (
          <StyledTabWrapper
            key={type}
            ref={(el) =>
              !tabsRef.current[index] && el
                ? (tabsRef.current[index] = el)
                : null
            }
          >
            <Tab
              data-testid={`format-align-tab-${type}`}
              key={`tab-${type}`}
              id={type}
              aria-label={ariaLabel}
              activeTabId={selectedTabState}
              onClick={() => onChange(type)}
              onSelect={() => setSelectedTabState(type)}
              onKeyDown={onKeyDown}
              /* styles props */
              padding="16px"
              color={theme.colors.text.black}
              selectedColor={theme.colors.text.black}
              selectedBorderColor={theme.colors.text.primary}
            >
              {icon}
            </Tab>
            <Typography
              data-testid={`format-align-title-${type}`}
              size={14}
              color={
                theme.colors.text[
                  selectedTabState === type ? 'primary' : 'lightBlack'
                ]
              }
              weight={selectedTabState === type ? 700 : 400}
              margin="8px 0 0 0 "
            >
              {label}
            </Typography>
          </StyledTabWrapper>
        ))}
      </Styled.TabList>
    </StyledFlexGap>
  );
};

export default FormatAlign;
