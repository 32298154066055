import { changeTheme } from '@ampli/ui';

import useCourseSelectionPageData from './use-course-selection-shared-page-data';

const useSharedPageData = () => {
  const {
    error: courseSelectionError,
    me,
    courseEnrollment,
    ...courseSelectionData
  } = useCourseSelectionPageData();

  const course = courseEnrollment.course || {};

  const subjectEnrollments = courseEnrollment.subjectEnrollments || [];

  const subjectEnrollmentsInProgress =
    courseEnrollment.subjectEnrollmentsInProgress || [];

  const subjectEnrollmentsTaken =
    courseEnrollment.subjectEnrollmentsTaken || [];

  changeTheme(course.courseType?.degree);

  const error = Boolean(courseSelectionError || !subjectEnrollments);

  return {
    ...courseSelectionData,
    me,
    course,
    courseEnrollment,
    subjectEnrollments,
    subjectEnrollmentsInProgress,
    subjectEnrollmentsTaken,
    error,
  };
};

export default useSharedPageData;
