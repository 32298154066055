import styled from '@emotion/styled';
import { css } from '@emotion/css';
export const NavBarWrapper = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;

  background-color: white;
  align-items: center;
  display: flex;

  z-index: 2;
`;
export const Flex = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;

  @media (min-width: 769px) {
    box-shadow: none;
    position: static;
  }
`;
export const Item = styled.li`
  color: ${_ref => {
  let {
    color
  } = _ref;
  return color;
}};

  display: inline-block;
  width: 100%;

  font-size: 0.875rem;
  text-align: center;

  button {
    border: none;
    background: none;
  }

  &:hover {
    text-decoration: none;
  }

  @media (min-width: 769px) {
    svg {
      width: 24px;
    }
  }
`;
export const navActiveButton = _ref2 => {
  let {
    color,
    active
  } = _ref2;
  return css`
    a {
      color: ${color};

      &.actived {
        color: ${active};
      }
    }

    color: ${color};

    & > svg {
      margin-left: 0;

      path[stroke] {
        stroke: ${color};
      }

      path[fill] {
        fill: ${color};
      }
    }

    &.actived {
      color: ${active};

      & > svg {
        path[stroke] {
          stroke: ${active};
        }

        path[fill] {
          fill: ${active};
        }
      }
    }
  `;
};