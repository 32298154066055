import styled from '@emotion/styled';

export const Card = styled.div(
  ({ background, hover }) => `
  min-height: auto;
  width: 100%;
  padding: 21px 24px;
  background: ${background};
  cursor: pointer;
  border-radius: 8px;

  &:focus {
    border: 4px solid ${background};
    background: ${hover};
  }

  &:hover {
    background: ${hover};
  }
`
);

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
    width: 24px;
    path {
      stroke: #fff;
      fill: #fff;
    }
  }
`;
