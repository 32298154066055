/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { generatePath, useHistory } from 'react-router-dom';
import { useMeContext } from '../../../../modules/shared/contexts/me-context';

import { analyticsCreativesEvents } from '../../../shared/utils/communication-constants';
import { useGetTermsById } from '../../services/hooks';
import { useGetState } from '../../../../state';
import { pushEvent } from '../../../../lib/ga';
import { routePath } from '../../../../routes';
import { Creative } from '../../types';

export interface Terms extends Creative {
  infoText: string;
}

type UseCommunicationTermsModal = {
  modal: Terms;
  onCtaClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  refetch: () => void;
};

export const useCommunicationTermsModal = (): UseCommunicationTermsModal => {
  const { states: statesMe } = useMeContext();
  const { me } = statesMe.getMe;

  const history = useHistory();
  const studentId = me?.username;

  const [
    {
      selectedCourseEnrollmentType: courseTypeCode,
      selectedCourseVersionId: courseVersionId,
      selectedCourseId: courseId,
    },
  ] = useGetState();

  const {
    data: [modal],
    refetch,
  } = useGetTermsById<Terms[]>(
    courseTypeCode,
    courseVersionId,
    courseId,
    studentId
  );

  const onCtaClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      if (modal?.ctaLink) {
        localStorage.setItem(
          'termsModal',
          JSON.stringify({ status: true, courseId, accepted: false })
        );

        const [
          courseType,
          courseVersionId,
          termsPath,
          contractPath,
          contractId,
        ] = modal?.ctaLink.replace('/', '').split('/') || [];

        pushEvent(
          'event',
          analyticsCreativesEvents.termsModal.Com_contrato_prestservicos_clique
            .event,
          {
            item: analyticsCreativesEvents.termsModal.Com_contrato_prestservicos_clique.param(
              'cta'
            ),
          }
        );

        return history.push(
          generatePath(routePath.termsAndContractsDetails, {
            courseType,
            courseVersionId,
            contractId,
          })
        );
      }
    },
    [modal?.ctaLink, courseId, history]
  );

  React.useEffect(() => {
    if (modal) {
      pushEvent(
        'event',
        analyticsCreativesEvents.termsModal
          .Com_contrato_prestservicos_visualizacao.event
      );
    }
  }, [modal]);

  return { modal, onCtaClick, refetch };
};
