import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';
import { NavLink } from 'react-router-dom';

export const NavLinkStyled = styled(NavLink)`
  display: flex;
  align-items: end;

  &,
  a {
    align-items: center;
    gap: 8px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
`;

export const WrapperNavegation = styled.div`
  width: 100%;
  max-width: 1064px;
  gap: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0.5rem 0rem;
    box-shadow: 0px -4px 8px rgba(33, 37, 41, 0.05);
  }

  @media (min-width: 1064px) {
    display: flex;
    align-items: center;
  }
`;

export const WrapperAvatar = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${convertPXToREM(32)};
`;

export const MobileAvatarCircle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const AvatarCircle = styled.div<{ background: string }>`
  color: #212529;
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  background: ${({ background }) => background};
  position: relative;

  @media (max-width: 768px) {
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
    padding: 5px 11px;
  }
`;

export const HelpButton = styled.button<{ activeClassName: string }>`
  border: none;
  background: none;
  cursor: pointer;
  color: ${({ color }) => color};
  text-align: center;
  outline: 0;
  text-decoration: initial;
  border-radius: 0;
  padding: 0;
  margin: 0;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const HelpWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;

  @media (max-width: 768px) {
    width: 100vw;
    justify-content: space-evenly;
  }
`;

export const NavigationLogoItemsWrapper = styled.div`
  display: flex;
  gap: 60px;
`;

export const WrapperLogo = styled.div`
  cursor: pointer;
`;

export const WrapperIcon = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const NavItemLabel = styled.span`
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: ${convertPXToREM(14)};
`;
