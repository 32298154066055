const calculateClientRect = (parentDomRef, domRef) => {
  const domClientRect = domRef.current.getBoundingClientRect();
  const parentDomClientRect = parentDomRef.current.getBoundingClientRect();
  return {
    top: domClientRect.top - parentDomClientRect.top,
    left: domClientRect.left - parentDomClientRect.left,
    width: domClientRect.width,
    height: domClientRect.height
  };
};
export default calculateClientRect;