import React from 'react';

import { Typography, Separator, convertPXToVH } from '@sofia/ui';
import { EntitiesLabels } from '../../../../constants';
import { useTheme } from '@emotion/react';

import QuizCorrectionQuestionCorrection from '../question/quiz-correction-question-correction';
import QuizCorrectionNavigation from '../navigation/quiz-correction-navigation';
import QuizCorrectionQuestion from '../question/quiz-correction-question';
import QuizCorrectionLayout from '../layout/quiz-correction-layout';

import {
  answerTagTheme,
  cardChoiceTheme,
  footerButtonsLabel,
  quizCorrectionProps,
} from '../helper';

import * as Styled from './quiz-correction-base.styles';

const QuizCorrectionBase = ({
  quiz,
  attempt,
  assignment,
  fontSize,
  onHelpClick,
  onFontSizeClick,
  onFinishQuizCorrectionClick,
  onCloseQuizCorrectionClick,
  talkTutorFrame,
  stepsControl,
  courseType,
  isResponsive,
  learningUnits,
}) => {
  const theme = useTheme();
  const answerStatusTheme = answerTagTheme(theme);
  const bulletsQuestionsTheme = cardChoiceTheme(theme);

  const { step, toStep, isLastStep, isFirstStep, previousStep, nextStep } =
    stepsControl;

  const question = quiz.questions[step];
  const answers = quiz.answerSheet.answers;
  const numberOfQuestions = quiz.questions.length;

  const answer = answers.find((answer) => answer.questionId === question.id);
  const choice = question.choices.find(
    (choice) => choice.id === answer.choiceId
  );
  const correctAnswer = question.choices.find(
    (anotherChoice) => anotherChoice.id === answer.correctChoice.id
  );
  const answerTheme =
    answerStatusTheme[answer.isCorrect ? 'CORRECT' : 'INCORRECT'];

  const bulletQuestions = quiz.questions.map((question, bullet) => {
    const isCorrect = !!answers.find(
      (answer) => answer.questionId === question.id
    )?.isCorrect;

    const { borderColor, background } =
      bulletsQuestionsTheme[isCorrect ? 'CORRECT' : 'INCORRECT'];

    return {
      bullet,
      theme: { borderColor, background },
    };
  });

  const exerciseScore = Math.ceil(
    (attempt.grade * assignment.config.weight) / learningUnits?.length / 100
  );

  const maximumExerciseScore = assignment.config.weight / learningUnits?.length;

  const entitiesLabelsDictionary = EntitiesLabels(courseType);
  const { ariaLabelPreviousButton, lastStepLabel, ariaLabelNextButton } =
    footerButtonsLabel(step, numberOfQuestions);

  const correctionNavigation = (
    <QuizCorrectionNavigation
      numberOfQuestions={numberOfQuestions}
      punctuation={exerciseScore}
      maxPunctuation={maximumExerciseScore}
      step={step}
      toStep={toStep}
      bulletQuestions={bulletQuestions}
      isResponsive={isResponsive}
    />
  );

  return (
    <QuizCorrectionLayout
      isResponsive={isResponsive}
      title={
        <Styled.QuizTopBar>
          <Typography weight={700} size={isResponsive ? 16 : 20}>
            Correção do{' '}
            {entitiesLabelsDictionary.learningUnitQuiz('', true).toLowerCase()}
          </Typography>
        </Styled.QuizTopBar>
      }
      onHelpClick={onHelpClick}
      talkTutorFrame={talkTutorFrame}
      onFontSizeClick={onFontSizeClick}
      onCloseClick={onCloseQuizCorrectionClick}
    >
      <Styled.QuizCorrectionContent>
        {isResponsive ? correctionNavigation : null}

        <QuizCorrectionQuestion
          step={step}
          fontSize={fontSize}
          question={question}
          answerStatusTheme={answerTheme}
        />

        <Separator
          height="2px"
          color={theme.colors.separator.lightColor}
          margin={`${convertPXToVH(20)} 0`}
          aria-hidden
        />

        <QuizCorrectionQuestionCorrection
          fontSize={fontSize}
          choice={choice}
          answer={answer}
          correctAnswer={correctAnswer}
          answerStatusTheme={answerTheme}
        />
      </Styled.QuizCorrectionContent>

      <Styled.QuizCorrectionMenu sticky={!isResponsive}>
        <div>
          {!isResponsive ? correctionNavigation : null}

          <Styled.QuizNavigationButtons
            onPreviousClick={() => (!isFirstStep ? previousStep() : null)}
            onNextClick={() =>
              !isLastStep ? nextStep() : onFinishQuizCorrectionClick()
            }
            isLastStep={isLastStep}
            lastStepLabel={lastStepLabel}
            ariaLabelPreviousButton={ariaLabelPreviousButton}
            ariaLabelNextButton={
              isLastStep ? lastStepLabel : ariaLabelNextButton
            }
            nextButtonLabel="Próximo"
          />
        </div>
      </Styled.QuizCorrectionMenu>
    </QuizCorrectionLayout>
  );
};

QuizCorrectionBase.defaultProps = {
  fontSize: 'medium',
  isResponsive: false,
};

QuizCorrectionBase.propTypes = quizCorrectionProps;

export default QuizCorrectionBase;
