import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';

export const LayoutWrapper = styled.div`
  > div {
    padding-top: 0;
    padding-bottom: 4rem;
  }
`;

export const LearningUnitCardWrapper = styled.div`
  margin-bottom: 40px;

  ${mediaQuery('desktop')} {
    margin-bottom: 56px;
  }
`;
