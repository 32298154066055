import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ApolloClient, createHttpLink, from } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import fetch from 'cross-fetch';
import { onError } from '@apollo/client/link/error';
import { APIError, compact, GraphQLError, map } from '@ampli/utils';
import { setContext } from '@apollo/client/link/context';
const errorLink = onError(_ref => {
  let {
    graphQLErrors,
    operation,
    networkError
  } = _ref;
  const context = operation.getContext();
  const requestHeaders = context.headers || {};
  const response = context.response || {};
  const responseStatus = response.status;
  const operationName = operation.operationName;
  if (graphQLErrors) {
    map(graphQLErrors, error => {
      console.error(new GraphQLError(`${error.code}: ${error.message} (${error.status})`, {
        requestHeaders,
        responseStatus,
        operationName
      }));
    });
  }
  if (networkError) {
    console.error(new APIError(networkError.message, {
      requestHeaders,
      responseStatus,
      operationName
    }));
  }
});
const headersLink = setContext(_ref2 => {
  let {
    headers = {}
  } = _ref2;
  return {
    headers: _objectSpread({
      authorization: 'Basic cG9ydGFsOnNlY3JldA=='
    }, headers)
  };
});
const ssrClientCreator = _ref3 => {
  let {
    graphqlUrl
  } = _ref3;
  const link = from(compact([errorLink, headersLink, createHttpLink({
    uri: graphqlUrl,
    fetch
  })]));
  return new ApolloClient({
    ssrMode: true,
    link,
    cache: new InMemoryCache()
  });
};
export default ssrClientCreator;