import React from 'react';

import { BaseTextarea } from './auto-growing-textarea.styles';
import useAutoGrowingTextareaLogic from './auto-growing-textarea.logic';
import { IAutoGrowingTextareaProps } from './auto-growing-textarea.types';

const AutoGrowingTextarea: React.FC<IAutoGrowingTextareaProps> = ({
  textareaRef,
  ...props
}: IAutoGrowingTextareaProps): React.ReactElement => {
  const { actions } = useAutoGrowingTextareaLogic({ textareaRef });

  return (
    <BaseTextarea
      ref={textareaRef}
      onInput={actions.handleOnInput}
      {...props}
    />
  );
};

export default AutoGrowingTextarea;
