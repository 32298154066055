import { deburr, memoize, toLower, words, join } from '@ampli/utils';
import { Address } from '../types';

export const defineButtonProps = (
  assert: boolean,
  color: string
): {
  background: string;
  color: string;
  fill: string;
} => {
  return assert
    ? {
        background: color,
        color: 'white',
        fill: 'white',
      }
    : {
        background: 'transparent',
        color: color,
        fill: color,
      };
};

export const sanitizeSearchTerms = (terms?: string): string =>
  join(words(toLower(deburr(terms))), ' ');

export const filterArticles = memoize(
  (searchTerms: string, searchData: { title: string; description: string }[]) =>
    searchData.filter(({ title, description }) =>
      sanitizeSearchTerms(`${title} ${description}`).includes(searchTerms)
    )
);

export const parseAddress = (address: Address): string =>
  `${address ? address?.street?.toLowerCase() + ',' : ''}${
    address?.number ? ' ' + address?.number : ''
  } - ${
    address?.neighbourhood ? address?.neighbourhood?.toLowerCase() + ',' : ''
  } ${address?.city?.name ? address?.city?.name?.toLowerCase() : ''} - ${
    address?.state?.acronym ? address?.state?.acronym?.toUpperCase() : ''
  }`;
