import React from 'react';

import {
  BellNotificationIcon,
  NotificationButton,
  BellIcon,
  Signal,
  IconBellWrapper,
} from './notifications-button.styles';

import { SvgBell } from '@sofia/ui';
import { createPortal } from 'react-dom';
import { useTheme } from '@emotion/react';

import { analyticsCreativesEvents } from '../../../../../shared/utils/communication-constants';
import { NotificationsPopover } from '../notifications-popover';
import { useNotifications } from '../../contexts/notification-context';
import { useUpdateBell } from '../../../../services/hooks';
import { pushEvent } from '../../../../../../lib/ga';
import SettingsCard, {
  Components,
} from '../../../../../shared/components/settings-card';
import { useBreakpoint } from '../../../../../shared';

export interface NotificationsButtonProps {
  isProfileCard?: boolean;
}

const NotificationsButton: React.FC<NotificationsButtonProps> = ({
  isProfileCard = false,
}: NotificationsButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const { isDesktop } = useBreakpoint();

  const theme = useTheme();

  const { dispatch, notification, courseEnrollmentId } = useNotifications();

  const [updateBell] = useUpdateBell(courseEnrollmentId, {}, true);

  const layoutNavbar = document.getElementById('layout-navbar');

  const onOpenNotificationsCenter = () => {
    if (notification.bell) updateBell();
    setOpen(!open);
    return open
      ? pushEvent(
          'event',
          analyticsCreativesEvents.notificationsCenter
            .Com_central_notificacoes_clique_fechar.event
        )
      : pushEvent(
          'event',
          analyticsCreativesEvents.notificationsCenter
            .Com_central_notificacoes_visualizacao.event
        );
  };

  const colorIconDeskp = isDesktop
    ? theme.colors.text.lightBlack
    : theme.colors.text.primary;

  return (
    <>
      {isProfileCard ? (
        <SettingsCard onClick={onOpenNotificationsCenter}>
          <Components.Icon
            icon={
              <IconBellWrapper>
                <SvgBell width={24} height={24} fill={colorIconDeskp} />
                {notification?.bell ? <Signal /> : null}
              </IconBellWrapper>
            }
          />
          <Components.Title title="Notificações" />
          <Components.Description description="Central de notificações" />
        </SettingsCard>
      ) : (
        <NotificationButton
          aria-label="Abrir área de notificações"
          onClick={onOpenNotificationsCenter}
        >
          {notification.bell ? (
            <BellNotificationIcon fill={theme.colors.text.black} />
          ) : (
            <BellIcon fill={theme.colors.text.lightBlack} />
          )}
        </NotificationButton>
      )}

      {layoutNavbar
        ? createPortal(
            open ? (
              <NotificationsPopover
                open={open}
                onClose={() => {
                  setOpen(false);
                  dispatch({ content: 'list', notificationId: null });
                  pushEvent(
                    'event',
                    analyticsCreativesEvents.notificationsCenter
                      .Com_central_notificacoes_clique_fechar.event
                  );
                }}
              />
            ) : null,
            layoutNavbar
          )
        : null}
    </>
  );
};

export default NotificationsButton;
