import styled from '@emotion/styled';
export const TabList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  width: 100%;
  overflow-x: ${_ref => {
  let {
    overflowX
  } = _ref;
  return overflowX || 'auto';
}};
  position: ${_ref2 => {
  let {
    position
  } = _ref2;
  return position;
}};
  ::-webkit-scrollbar {
    height: 2px;
  }
`;