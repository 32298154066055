import { SizeMinWidthScreenDesktop, SizeMaxWidthScreenMobile } from '@ampli/ui';

import {
  convertPXToVH,
  convertPXToVW,
  convertPXToREM,
  ButtonGhost,
} from '@sofia/ui';

import loginImageUrl from '../../assets/mulher-rindo-computador.png';
import styled from '@emotion/styled';

export const LoginImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${loginImageUrl}');
  background-size: cover;
`;

export const Form = styled.form`
  height: 100%;
  overflow: auto;
`;

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;

  margin: 0 auto;
  padding: ${convertPXToVH(90)} ${convertPXToVW(160)};

  @media (max-width: 930px) {
    padding: 32px 24px;
  }

  @media (max-width: 330px) {
    padding: 32px 20px;
  }
`;

export const ButtonGhostLong = styled(ButtonGhost)`
  width: 100%;
  display: flex;
  align-items: center;

  justify-content: ${({ content }) => content || 'center'};
  padding: ${({ padding }) => padding};
  margin-top: 0px;

  :hover {
    background-color: transparent;
  }

  @media (max-width: ${SizeMaxWidthScreenMobile}) {
    a,
    p {
      font-size: ${convertPXToREM(16)};
      font-weight: 700;
    }
  }
`;

export const HelpButton = styled.button`
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
`;

export const HelpButtonWrapper = styled.div`
  display: flex;
  margin-top: ${({ marginTop }) => marginTop || '0px'};
  align-items: center;

  @media (max-width: ${SizeMaxWidthScreenMobile}) {
    a,
    p {
      font-size: ${convertPXToREM(16)};
      font-weight: 700;
    }
  }
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
  cursor: pointer;
  position: absolute;

  @media (max-height: 661px) {
    position: relative;
  }
`;

export const GradientButton = styled.button`
  background: linear-gradient(82.37deg, #3bdbb8 0%, #01b6d0 100%);
  border-radius: 50px;
  border: 1px transparent;
  padding: ${convertPXToVH(16)} ${convertPXToVW(32)};
  cursor: pointer;
  width: ${(props) => props.buttonWidth};
  color: white;

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.25;
      cursor: default;
    `}
`;

export const LoginFooterWrapper = styled.div`
  background: ${({ background }) => background};
  display: ${({ show }) => show};
  padding: ${convertPXToVH(32)} ${convertPXToVW(160)};

  @media (max-width: 930px) {
    padding: 32px 24px;
  }

  @media (max-width: 330px) {
    padding: 32px 20px;
  }
`;

export const LoginFieldsWrapper = styled.div`
  margin-bottom: ${convertPXToVH(32)};
  width: 100%;

  @media (max-width: ${SizeMaxWidthScreenMobile}) {
    margin: ${convertPXToVH(72)} 0 ${convertPXToVH(32)} 0;

    ${({ content }) =>
      (content === 'center' || content === 'start') &&
      `
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    `}
  }
`;

export const Anchor = styled.a`
  color: #01b6d0;
  cursor: pointer;
  font-weight: bold;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: ${({ content }) => content};
`;

export const ContentWrapper = styled.div`
  ${({ content }) =>
    content === 'start' &&
    `
      @media (max-width: 420px) {
        height: 100%;
      }
    `}

  ${({ content }) =>
    content === 'center' &&
    `
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  `}
`;

export const LogoHC = styled.div`
  margin: 0 0 16px 0;
  width: ${convertPXToVW(160)};

  @media (min-width: ${SizeMinWidthScreenDesktop}) {
    margin-bottom: ${({ marginB }) => (marginB ? marginB : convertPXToVH(72))};
    width: ${convertPXToVW(204)};
  }
`;
