import { MouseEvent, KeyboardEvent } from 'react';
import { History } from 'history';
import { pushEvent } from '../../../../lib/ga';

type CreateHandleMenuItemClick = (props: {
  isDesktop: boolean;
  setOpenMenu: (openMenu: boolean) => void;
  history: History;
  locationState: { backIterations?: number };
  backIterations: number;
}) => (event: MouseEvent | KeyboardEvent, path: { pathUrl: string }) => void;

export const createHandleMenuItemClick: CreateHandleMenuItemClick =
  ({ isDesktop, setOpenMenu, history, locationState, backIterations }) =>
  (e, { pathUrl }) => {
    pushEvent('event', 'object_menu_navigation');
    if (!isDesktop) {
      setOpenMenu(false);
    }
    history.push(pathUrl, {
      ...locationState,
      backIterations: backIterations + 1,
    });
  };

export default createHandleMenuItemClick;
