import useSharedPageData from './use-shared-page-data';

const useElectiveSubjectOnboardingPageData = () => {
  const { loading: sharedPageLoading, ...sharedPageData } = useSharedPageData();

  return {
    ...sharedPageData,
    loading: sharedPageLoading,
  };
};

export default useElectiveSubjectOnboardingPageData;
