import React from 'react';

function SvgCpfImage(props) {
  return (
    <svg viewBox="0 0 51 33" width={51} height={33} fill="none" {...props}>
      <path
        d="M.853 3.203A3.204 3.204 0 014.156.1l43.57 1.359a3.204 3.204 0 013.103 3.302l-.754 24.163a3.205 3.205 0 01-3.302 3.103l-43.57-1.359A3.204 3.204 0 01.1 27.366L.853 3.203z"
        fill="#0179D0"
      />
      <path
        d="M3.056 16.99c.02-.622.54-1.11 1.163-1.091l18.787.586a1.128 1.128 0 11-.07 2.254l-18.788-.586a1.128 1.128 0 01-1.092-1.162zM2.88 22.627c.02-.622.54-1.111 1.163-1.092l17.66.55a1.128 1.128 0 11-.07 2.255l-17.66-.55a1.128 1.128 0 01-1.093-1.163z"
        fill="#fff"
        fillOpacity={0.4}
      />
      <path
        d="M9.278 9.187l1.3.437a2.42 2.42 0 01-.446.908c-.197.241-.438.42-.724.537-.283.116-.642.168-1.076.154-.526-.016-.954-.105-1.284-.268-.328-.164-.607-.443-.837-.836-.23-.393-.336-.89-.317-1.49.025-.802.257-1.41.695-1.827.44-.418 1.05-.615 1.83-.59.61.018 1.085.157 1.425.414.343.257.59.644.744 1.16l-1.33.253a1.132 1.132 0 00-.135-.328.861.861 0 00-.703-.379.879.879 0 00-.792.385c-.142.199-.22.515-.234.948-.017.537.053.909.21 1.114.156.203.382.31.677.319.287.009.505-.065.655-.221.153-.156.267-.386.342-.69zM11.402 6.387l2.487.078c.541.016.942.158 1.203.424.263.266.387.637.372 1.112-.015.489-.175.866-.478 1.132-.302.266-.754.39-1.357.371l-.82-.025-.055 1.796-1.503-.047.15-4.841zm1.438 2.11l.367.012c.288.01.492-.034.612-.13a.49.49 0 00.187-.38.538.538 0 00-.14-.391c-.098-.11-.287-.168-.566-.177l-.426-.013-.034 1.08zM16.303 6.54l3.699.115-.033 1.04-2.196-.068-.026.845 1.875.059-.03.977-1.876-.058-.062 1.978-1.502-.047.15-4.841z"
        fill="#fff"
      />
      <path
        d="M45.563 16.812a7.143 7.143 0 11-14.279-.446 7.143 7.143 0 0114.279.446z"
        fill="#000"
        fillOpacity={0.1}
      />
    </svg>
  );
}

export default SvgCpfImage;
