import React from 'react';
import { useGetState } from '../../../../state';
import { ReadingRuler } from './reading-ruler.styles';
import ReadingRulerBtnPresentation from './reading-ruler-btn.presentation';
import { useBreakpoint } from '../../hooks';
import { IUseReadingRuler } from './types';

const useReadingRuler = ({
  assetsRef,
}: {
  assetsRef?: (HTMLDivElement | null)[];
}): IUseReadingRuler => {
  const { isDesktop } = useBreakpoint();
  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const readingRulerRef = React.useRef<HTMLDivElement | null>(null);
  const [touchingDisabledAssets, setTouchingDisabledAssets] =
    React.useState(false);
  const [{ isReadingRulerEnabled }] = useGetState();

  const checkIfElementsCollide = (el1: HTMLDivElement, el2: HTMLDivElement) => {
    const el1OffsetBottom = el1.offsetTop + el1.offsetHeight;
    const el2OffsetBottom = el2.offsetTop + el2.offsetHeight;
    return !(
      el1OffsetBottom < el2.offsetTop || el1.offsetTop > el2OffsetBottom
    );
  };

  const disableRuler = () => {
    const touchingAssets: (HTMLDivElement | null)[] = [];
    if (readingRulerRef.current && assetsRef) {
      assetsRef.forEach((asset) => {
        if (asset === null) return;
        if (
          checkIfElementsCollide(
            readingRulerRef.current as HTMLDivElement,
            asset
          )
        ) {
          touchingAssets.push(asset);
        }
      });

      if (touchingAssets.length > 0) {
        setTouchingDisabledAssets(true);
      } else {
        setTouchingDisabledAssets(false);
      }
    }
  };

  const followCursor = (event: MouseEvent) => {
    if (readingRulerRef.current?.style) {
      readingRulerRef.current.style.top =
        window.scrollY + event.clientY - 64 + 'px';
    }
    disableRuler();
  };

  React.useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement && readingRulerRef.current) {
      contentElement.addEventListener('mousemove', followCursor);
      contentElement.addEventListener('mouseenter', followCursor);
    }
    return () => {
      if (contentElement) {
        contentElement.removeEventListener('mousemove', followCursor);
        contentElement.removeEventListener('mouseenter', followCursor);
      }
    };
  });

  return {
    contentRef,
    readingRulerComponent:
      isReadingRulerEnabled && isDesktop ? (
        <ReadingRuler
          ref={readingRulerRef}
          isCollingForbiddenEl={touchingDisabledAssets}
        />
      ) : null,
    readingRulerBtnHandler: <ReadingRulerBtnPresentation />,
  };
};

export default useReadingRuler;
