import styled from '@emotion/styled';
import { convertPXToVW, convertPXToREM } from '@sofia/ui';

export const Wrapper = styled.div<{ isResponsive?: boolean }>`
  padding: ${({ isResponsive }) => (isResponsive ? '24px' : '0px')};
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const ContentWrapperList = styled.div`
  padding: 40px 16px;
  margin-bottom: 10px;
`;

export const ImageWrapper = styled(ContentWrapper)`
  margin-bottom: 16px;
`;

export const ButtonsWrapper = styled(Column)<{ isResponsive?: boolean }>`
  ${({ isResponsive }) => `
    max-width: ${convertPXToVW(isResponsive ? 312 : 266)};
    row-gap: ${convertPXToREM(isResponsive ? 16 : 10)};
  `}
  margin: 0 auto;

  & > button {
    padding: 19px 0;
    margin: 0;
  }
`;

export const DescriptionWrapper = styled.div`
  max-width: 280px;
  margin: 0 auto;
`;

export const StepWrapper = styled(ContentWrapper)`
  margin: 24px 0;
  > :not(:last-of-type) {
    margin-right: 16px;
  }
`;

export const Step = styled.button<{ current: boolean; background: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  border: none;
  background: ${({ current, background, theme }) =>
    current ? background : theme.colors.slide.dots.inactive};
`;
