import useExamInAnalysisPageData from './use-exam-in-analysis-page-data';

const useExamResultPageData = () => {
  const { loading, ...examInAnalysisPageData } = useExamInAnalysisPageData();

  return {
    ...examInAnalysisPageData,
    loading,
  };
};

export default useExamResultPageData;
