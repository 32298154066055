import React from 'react';
import { useTheme } from '@emotion/react';

import {
  ButtonPrimaryLong,
  ButtonOutlineLong,
  Typography,
  useModal,
} from '@sofia/ui';

import { pushEvent } from '../../../../lib/ga';
import { analyticsCreativesEvents } from '../../utils/communication-constants';
import { SvgComment } from '../../../wisdom/assets/comment.icon';
import * as Styled from './service-channels-content.styles';
import ServiceChannelsModal from './service-channels-modal';

interface ServiceChannelsContentProps {
  affiliateId?: string;
  appearance?: 'blue' | 'white';
  type?: 'modal' | 'popover';
  [x: string]: unknown;
}

const ServiceChannelsContent: React.FC<ServiceChannelsContentProps> = ({
  affiliateId,
  appearance = 'blue',
  type,
  ...props
}: ServiceChannelsContentProps) => {
  const theme = useTheme();

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const modal = useModal();

  const Button = type === 'modal' ? ButtonPrimaryLong : Styled.RoundedButton;
  const CustomButton = appearance === 'blue' ? Button : ButtonOutlineLong;

  const CustomButtonStyle = {
    blue: {
      color: 'white',
      borderBoxColor: '',
      background: theme.colors.button.primary,
      width: '',
    },
    white: {
      color: theme.colors.button.primary,
      borderBoxColor: theme.colors.button.primary,
      background: 'white',
      width: '100%',
    },
  };

  const showServiceChannelsContent = () => {
    modal.show();
    pushEvent(
      'event',
      analyticsCreativesEvents.helpCenter.Com_central_ajuda_canais_atendimento
        .event
    );
  };

  return (
    <div {...props} data-testid="service-channels-content">
      <CustomButton
        type="button"
        {...CustomButtonStyle[appearance]}
        onClick={showServiceChannelsContent}
        ref={buttonRef}
        padding="18px 62px"
      >
        <SvgComment fill={CustomButtonStyle[appearance].color} />

        {type === 'modal' ? (
          <Typography
            margin={appearance === 'white' ? '0 9px' : '0px'}
            data-testid="service-channel-text-cta"
          >
            Canais de atendimento
          </Typography>
        ) : null}
      </CustomButton>

      {modal.state ? (
        <ServiceChannelsModal
          affiliateId={affiliateId}
          type={type}
          modal={modal}
        />
      ) : null}
    </div>
  );
};

export default ServiceChannelsContent;
