import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const Wrapper = styled.div<{ borderColor: string }>`
  border-radius: 8px;
  border: solid 2px ${({ borderColor }) => borderColor};
  padding: 16px;
`;

export const ActionWrapper = styled.div`
  margin-top: 24px;
`;

export const EditButtonsWrapper = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ direction = 'column' }) => direction};
  ${({ direction = 'column' }) =>
    ({
      row: { columnGap: '1rem' },
      column: { rowGap: '1rem' },
    }[direction])}

  & > button {
    padding: unset;
    height: 56px;
  }
`;

export const TextArea = styled.textarea<{ color: string }>`
  min-height: 48px;
  line-height: 150%;
  border-radius: 8px;
  resize: none;
  border: none;
  padding: 4px;
  width: 100%;
  font-size: ${convertPXToREM(16)};
  color: ${({ color }) => color};
  :focus,
  :focus-visible {
    outline: 0;
  }
`;

export const DeleteButton = styled.div`
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    svg {
      margin-right: 12px;
    }
  }
`;

export const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span:first-of-type {
    transition: ease-in-out 250ms color;
  }
`;
