import lottie from 'lottie-web';

import { AK_ENTER, AK_LEFT, AK_RIGHT, AK_SPACE } from '../../../../constants';
import { makeId } from './helpers';
import { StarActionEvent, StarSelectEvent, StarKeyDownEvent } from './types';

export const starActionEvents: StarActionEvent = (
  onStarClick,
  rate,
  { idPrefix, disabled }
) => {
  if (disabled) return;

  for (let k = 1; k < 6; k++) {
    const elementKClassList = document.getElementById(
      makeId(idPrefix, k)
    )?.classList;

    if (k <= rate) {
      if (k === rate) {
        onStarClick(rate);
      }
      lottie.play(makeId(idPrefix, k));
      elementKClassList?.add('active');
      elementKClassList?.add('rated');
      setTimeout(() => {
        lottie.stop(makeId(idPrefix, k));
        elementKClassList?.remove('active');
      }, 420);
    } else if (elementKClassList?.contains('rated')) {
      elementKClassList.remove('rated');
    }
  }
};

export const starSelectEvents: StarSelectEvent = (
  index,
  eventType,
  { idPrefix, disabled }
) => {
  if (disabled) return;

  for (let k = 1; k <= index; k++) {
    const clsList = document.getElementById(makeId(idPrefix, k))?.classList;
    if (
      (eventType === 'mouseleave' || eventType === 'blur') &&
      clsList?.contains('hover')
    ) {
      clsList.remove('hover');
    } else if (
      (eventType === 'mouseenter' || eventType === 'focus') &&
      !clsList?.contains('hover')
    ) {
      clsList?.add('hover');
    }
  }
};

export const starKeyDownEvent: StarKeyDownEvent = (
  onStarClick,
  event,
  index,
  { idPrefix, disabled }
) => {
  if (disabled) return;

  if ([AK_LEFT, AK_RIGHT].indexOf(event.keyCode) >= 0) {
    document
      .getElementById(
        makeId(
          idPrefix,
          event.keyCode === AK_LEFT && index - 1 >= 0
            ? index - 1
            : index + 1 <= 5
            ? index + 1
            : 1
        )
      )
      ?.focus();
  } else if ([AK_ENTER, AK_SPACE].indexOf(event.keyCode) >= 0) {
    starActionEvents(onStarClick, index, { idPrefix, disabled });
  }
};
