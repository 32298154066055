import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { LayoutContent } from '../../../../components/layout';
import { ServiceRequestSkeleton } from '../skeleton';
import CopyToClipboard from '../copy-to-clipboard/copy-to-clipboard';
import { serviceRequestLoading } from '../../constants';
import {
  useLazyGetLastOrderStudent,
  useGetCourseEnrollmentForSecretaryPage,
} from '@ampli/services';

import {
  Container,
  ColLeft,
  ColRight,
  ContainerText,
} from './service-request-loading-styles';
import { useGetState } from '../../../../state';

export default function SecretaryCertificateOfEnrollmentLoading({
  loading,
  typeOfStatus,
  courseEnrollmentId,
  orderSubject,
  setStep,
}) {
  const theme = useTheme();
  const texts = serviceRequestLoading[typeOfStatus];
  const [value, setValue] = useState(null);
  const [callback, { data, error }] = useLazyGetLastOrderStudent();
  const timer = useRef(null);

  const [{ selectedCourseEnrollmentId }] = useGetState();

  const { data: dataCourseEnrollment, loading: loadingCourseEnrollment } =
    useGetCourseEnrollmentForSecretaryPage(selectedCourseEnrollmentId);

  const getLastOrderStudent = useCallback(() => {
    callback({
      variables: {
        courseEnrollmentId: courseEnrollmentId,
        orderSubject: orderSubject,
      },
    });
  }, [courseEnrollmentId, orderSubject, callback]);

  useEffect(() => {
    timer.current = setInterval(() => {
      if (data?.data.orderUrl) setStep('ready');

      getLastOrderStudent();

      data?.data && setValue(data?.data);
    }, 10000);

    return () => {
      clearInterval(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, setStep]);

  return (
    <>
      <LayoutContent>
        {loading || loadingCourseEnrollment ? (
          <ServiceRequestSkeleton />
        ) : (
          <>
            <Container>
              <ColLeft>
                <ContainerText>
                  <Typography
                    margin="0 0 10px 0"
                    weight="700"
                    color={theme.colors.text.black}
                    size={24}
                  >
                    {texts?.titleText}
                  </Typography>
                  <Typography
                    margin="0px"
                    color={theme.colors.text.black}
                    weight="400"
                    size={20}
                  >
                    {texts?.textDesc}
                  </Typography>
                </ContainerText>
              </ColLeft>
              <ColRight>
                <CopyToClipboard
                  valueCopy={value?.ticketNumber}
                  loading={!value?.ticketNumber}
                  typeOfStatus={typeOfStatus}
                  affiliateId={dataCourseEnrollment?.affiliateId}
                />
              </ColRight>
            </Container>
          </>
        )}
      </LayoutContent>
    </>
  );
}

SecretaryCertificateOfEnrollmentLoading.propTypes = {
  loading: PropTypes.bool,
  typeOfStatus: PropTypes.string,
  courseEnrollmentId: PropTypes.string,
  orderSubject: PropTypes.string,
  setStep: PropTypes.func,
};
