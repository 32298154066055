import {
  CHECK_CNPJ,
  GET_INTERNSHIP_COURSE_ENROLLMENT,
  FIND_INTERNSHIP_PRESENTATION_SUBJECT_ENROLLMENT,
} from './queries';
import { useLazyQuery, useQuery } from '@ampli/services';

export const useCheckCnpj = ({ cnpj = '', options = {} }) => {
  const [query, { loading, data }] = useLazyQuery(CHECK_CNPJ, {
    ...options,
    skip: !cnpj,
    variables: {
      cnpj,
    },
  });

  return {
    query,
    data: loading || !data ? {} : data.data,
    loading,
  };
};

export const useGetInternshipCourseEnrollment = (
  courseEnrollmentId,
  options = {}
) => {
  const { data, loading, ...rest } = useQuery(
    GET_INTERNSHIP_COURSE_ENROLLMENT,
    {
      ...options,
      skip: !courseEnrollmentId,
      variables: {
        courseEnrollmentId,
      },
      fetchPolicy: 'no-cache',
    }
  );

  return {
    data: data?.data,
    loading,
    ...rest,
  };
};

export const useFindInternshipPresentationSubjectEnrollment = (
  subjectEnrollmentId,
  options = {}
) => {
  const { data, loading, ...rest } = useQuery(
    FIND_INTERNSHIP_PRESENTATION_SUBJECT_ENROLLMENT,
    {
      ...options,
      skip: !subjectEnrollmentId,
      variables: {
        subjectEnrollmentId,
      },
      fetchPolicy: 'no-cache',
    }
  );

  return {
    data: data?.data,
    loading,
    ...rest,
  };
};
