/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable promise/always-return */
import React from 'react';

import { convertPXToREM, SvgFile, Avatar } from '@sofia/ui';
import { RichText, css, cx } from '@ampli/ui';
import { useTheme } from '@emotion/react';

import { getRelativeDate } from '../../../../../../shared';
import { usePtkFrame } from '../../../../contexts';
import { MessageInfo } from '../../../../types';
import { headers } from '../../../../hooks/use-dynamics-logic';
import { routes } from '../../../../config';

import * as Styled from './message-card.styles';

interface MessageCardProps {
  message?: MessageInfo;
}

const MessageCard: React.FC<MessageCardProps> = ({
  message,
}: MessageCardProps) => {
  const theme = useTheme();

  const { me, tutor } = usePtkFrame();

  const isUserAgent = React.useMemo(
    () => Boolean(message?.agent === 'STUDENT'),
    [message?.agent]
  );

  const isTutorAgent = React.useMemo(
    () => Boolean(message?.agent === 'TUTOR'),
    [message?.agent]
  );

  const date = React.useMemo(
    () => getRelativeDate(message?.creationDateTime),
    [message?.creationDateTime]
  );

  const onDownloadFile = async (fileId: string, fileName: string) => {
    const blob = await fetch(routes.getFile(fileId), {
      method: 'GET',
      headers: await headers({ studentId: me?.id || '' }),
    })
      .then((res) => res.blob())
      // eslint-disable-next-line promise/always-return
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

    // Não sei o motivo, mas o const blob n está sendo usado, se remover e deixar pra executar o fetch sem atribuir variavel tbm funciona.
    // comentei pq tava dando erro de build
    console.log(blob);
  };

  return (
    <Styled.MessageCard
      background={
        isUserAgent
          ? theme.colors.button.lightBlack
          : theme.colors.icon.background
      }
    >
      <Styled.Wrapper>
        {isTutorAgent ? (
          <Avatar
            value={tutor?.tutor.name || 'T'}
            src={tutor?.tutor.picture}
            size={40}
          />
        ) : null}

        {isUserAgent ? <Avatar value={me?.fullName} size={40} /> : null}

        <Styled.TruncateText
          color={theme.colors.text.black}
          size={20}
          weight={700}
          margin="0px 0px 0px 8px"
        >
          {isUserAgent ? me?.fullName : tutor?.tutor.name || 'Tutor'}
        </Styled.TruncateText>
      </Styled.Wrapper>

      <RichText
        className={cx(
          css`
            margin: 16px 0px;
            font-size: ${convertPXToREM(16)};
            font-weight: 400;
            color: ${theme.colors.text.black};
            white-space: pre-wrap;
          `
        )}
      >
        {message?.text}
      </RichText>

      {message?.file ? (
        <Styled.UploadFileContainer
          type="button"
          onClick={() =>
            onDownloadFile(
              message?.file.id,
              `${message.file.name}.${message.file.extension}`
            )
          }
        >
          <Styled.Wrapper>
            <SvgFile />

            <Styled.TruncateText
              size={16}
              color="#212529"
              weight={700}
              margin="0px 0px 0px 8px"
            >
              {message.file.name}.{message.file.extension}
            </Styled.TruncateText>
          </Styled.Wrapper>
        </Styled.UploadFileContainer>
      ) : null}

      <Styled.Time size={14} margin="10px 0px 0px">
        {date}
      </Styled.Time>
    </Styled.MessageCard>
  );
};

export default MessageCard;
