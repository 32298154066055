import styled from '@emotion/styled';
import { Input } from '@sofia/ui';

export const InputDateWrapper = styled.div`
  & > div > div {
    position: relative;
    &::after {
      content: '';
      width: 20%;
      background: #fff;
      position: absolute;
      right: 7px;
      height: calc(100% - 8px);
      top: 4px;
    }
  }
`;

export const InputDate = styled(Input)`
  cursor: text;
  &:not(:focus) {
    opacity: ${({ hasValue }) => (hasValue ? 1 : 0.6)};
  }
`;
