import styled from '@emotion/styled';
import { mediaQuery, convertPXToVH, convertPXToVW } from '@sofia/ui';

export const QuestionBulletWrap = styled.div`
  margin-bottom: ${convertPXToVH(-12)};
  overflow-x: scroll;
  text-align: center;
  white-space: nowrap;

  ${mediaQuery('desktop')} {
    margin-bottom: 0;
    overflow-x: unset;
    white-space: initial;
  }
`;

export const BulletButton = styled.button(
  ({
    resolvedDarkColor,
    resolvedSize,
    resolvedLightColor,
    active,
    complete,
    clickable,
  }) => `
    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    border: 2px solid ${resolvedDarkColor};
    border-radius: 50%;
    height: ${convertPXToVH(resolvedSize)};
    width: ${convertPXToVW(resolvedSize)};

    ${complete ? `background: ${resolvedDarkColor};` : ''}
    ${active && complete ? `background: ${resolvedLightColor};` : ''}
    ${clickable ? `cursor: pointer;` : ''}
`
);

export const QuestionLegend = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${convertPXToVW(4)};
`;

export const QuestionLegendDot = styled.div(
  ({ color, size, active }) => `
  border: 1px solid ${color};
  border-radius: 50%;
  display: inline-block;
  height: ${convertPXToVH(size)};
  width: ${convertPXToVW(size)};

  ${active ? `background-color: ${color};` : ''}
`
);
