import React from 'react';
import { shape, string, bool } from 'prop-types';
import { Card, Typography, Tag } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { TextAsset } from '../../../asset';

import { questionChoicesStripTags } from '../../../../constants';
import { cardChoiceTheme } from '../helper';

import {
  QuestionCardWrapper,
  QuestionHeading,
  QuestionCorrectionContent,
} from './quiz-correction-question.styles';

const QuizCorrectionQuestionCorrection = ({
  choice,
  answer,
  fontSize,
  correctAnswer,
  answerStatusTheme,
}) => {
  const theme = useTheme();
  const cardTheme = cardChoiceTheme(theme);
  const emptyChoice = choice === undefined;

  return (
    <QuestionCorrectionContent>
      {!emptyChoice ? (
        <Card
          noPointer
          minHeight="auto"
          {...cardTheme[answer.isCorrect ? 'CORRECT' : 'INCORRECT']}
        >
          <QuestionCardWrapper>
            <QuestionHeading>
              <Typography size={20} weight={700}>
                Sua resposta
              </Typography>
              <Tag {...answerStatusTheme} />
            </QuestionHeading>
            <TextAsset
              size={fontSize}
              color="text-dark"
              data={{ content: choice.description }}
              stripTags={questionChoicesStripTags}
            />
          </QuestionCardWrapper>
        </Card>
      ) : null}

      {!answer.isCorrect && correctAnswer ? (
        <Card
          noPointer
          minHeight="auto"
          background={theme.colors.card.background.secondary}
          borderColor={theme.colors.card.border.primary}
          hoverBorderColor={theme.colors.card.border.primary}
        >
          <QuestionCardWrapper>
            <Typography size={20} weight={700}>
              Solução esperada
            </Typography>
            <TextAsset
              size={fontSize}
              color="text-dark"
              data={{ content: correctAnswer.description }}
              stripTags={questionChoicesStripTags}
            />
          </QuestionCardWrapper>
        </Card>
      ) : null}

      {choice?.feedback ? (
        <Card
          noPointer
          minHeight="auto"
          borderColor={theme.colors.card.border.primary}
          hoverBorderColor={theme.colors.card.border.primary}
        >
          <QuestionCardWrapper>
            <Typography size={20} weight={700}>
              Comentário
            </Typography>
            <TextAsset
              size={fontSize}
              color="text-dark"
              data={{ content: choice.feedback }}
              stripTags={questionChoicesStripTags}
            />
          </QuestionCardWrapper>
        </Card>
      ) : null}
    </QuestionCorrectionContent>
  );
};

QuizCorrectionQuestionCorrection.defaultProps = {
  fontSize: 'medium',
};

QuizCorrectionQuestionCorrection.propTypes = {
  choice: shape({
    id: string,
    description: string,
    feedback: string,
  }),
  correctAnswer: shape({
    id: string,
    description: string,
    feedback: string,
  }),
  answer: shape({
    choiceId: string.isRequired,
    isCorrect: bool.isRequired,
    correctChoice: shape({
      feedback: string.isRequired,
    }).isRequired,
  }).isRequired,
  fontSize: string,
  answerStatusTheme: shape({
    text: string.isRequired,
    theme: shape({
      color: string.isRequired,
      background: string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default QuizCorrectionQuestionCorrection;
