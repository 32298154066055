import React from 'react';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  Header,
  Typography,
  SvgQuestionCircle,
  useModal,
  Modal,
} from '@sofia/ui';
import { routePath } from '../../../routes';
import { useGetState } from '../../../state';
import { LayoutContent } from '../../layout';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '@ampli/hooks';
import MandatoryInternshipInfoForm from './form/mandatory-internship-info-form';
import * as Styled from './mandatory-internship-info.styles';
import { NewFeatureCommunication } from '../../../modules';

const MandatoryInternshipInfo = () => {
  const theme = useTheme();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const { subjectEnrollmentId } = useParams();
  const [
    { selectedCourseEnrollmentType, selectedCourseVersionId },
    { setAccessInternshipSubject },
  ] = useGetState();

  const routeParams = {
    courseType: selectedCourseEnrollmentType,
    courseVersionId: selectedCourseVersionId,
  };

  const helpModal = useModal();

  const homePath = generatePath(routePath.home, routeParams);

  const onCancel = () => {
    history.push(
      generatePath(routePath.mandatoryInternshipCompanyInputPage, {
        subjectEnrollmentId: subjectEnrollmentId,
        ...routeParams,
      })
    );
  };

  const onSuccess = () => {
    setAccessInternshipSubject(true);
    history.push(homePath);
  };

  const [commsAnchorEl, setCommsAnchorEl] = React.useState();

  const breadcrumbsComponent = (
    <Styled.Wrapper>
      <Breadcrumbs
        secondColor={theme.colors.breadCrumbs.secondary}
        firstColor={theme.colors.breadCrumbs.primary}
        fill={theme.colors.breadCrumbs.primary}
        stroke={theme.colors.breadCrumbs.primary}
        shadow={false}
        backClick={() => history.goBack()}
      >
        <Link to={homePath}>
          <span>Início</span>
        </Link>
        <Link
          to={generatePath(routePath.mandatoryInternshipConfirmCompanyPage, {
            ...routeParams,
            subjectEnrollmentId: subjectEnrollmentId,
          })}
        >
          <span>Dados da empresa</span>
        </Link>
        <Typography as="span" weight={700}>
          Dados do estágio
        </Typography>
      </Breadcrumbs>

      <Styled.IconWrapper
        onClick={() => helpModal.show()}
        ref={(ref) => commsAnchorEl !== null && setCommsAnchorEl(ref)}
      >
        <SvgQuestionCircle fill={theme.colors.text.primary} />
      </Styled.IconWrapper>
      {commsAnchorEl ? (
        <NewFeatureCommunication
          anchorEl={commsAnchorEl}
          shouldCloseOnEsc={false}
          shouldCloseOnBackdrop={false}
          shouldCloseOnClickOutside={false}
          arrowPosition="right"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          backdrop
          actions={{
            close: false,
            understood: true,
          }}
          handleUnderstood={() => setCommsAnchorEl(null)}
          title="Ficou com dúvidas?"
          message="Clique aqui e veja mais informações sobre o cadastro"
        />
      ) : null}
    </Styled.Wrapper>
  );

  return (
    <>
      <Header
        titleColor={theme.colors.text.secondary}
        descriptionColor={theme.colors.text.light}
        background={theme.colors.banner.primary}
        breadCrumbs={breadcrumbsComponent}
      />
      <LayoutContent>
        <Typography
          weight={700}
          size={isDesktop ? 48 : 32}
          margin={`0 0 ${isDesktop ? 56 : 40}px 0`}
          color={theme.colors.text.black}
        >
          {isDesktop ? 'Preencha os dados do estágio' : 'Dados do Estágio'}
        </Typography>
        <MandatoryInternshipInfoForm
          subjectEnrollmentId={subjectEnrollmentId}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      </LayoutContent>

      <Modal handleModal={helpModal} header={null} footer={null}>
        <Typography
          weight={700}
          size={24}
          margin={`32px 0 16px 0`}
          color={theme.colors.text.black}
        >
          Responsável pela empresa
        </Typography>
        <Typography
          size={16}
          margin={`0 0 16px 0`}
          color={theme.colors.text.lightBlack}
        >
          É a pessoa que pode assinar um contrato de estágio em nome da empresa,
          pode ser o diretor, CEO, diretor de RH ou outros cargos. Essa pessoa
          não precisa ser o seu supervisor de campo.
        </Typography>

        <Typography
          weight={700}
          size={24}
          margin={`0 0 16px 0`}
          color={theme.colors.text.black}
        >
          Supervisor de campo
        </Typography>
        <Typography
          size={16}
          margin={`0 0 32px 0`}
          color={theme.colors.text.lightBlack}
        >
          É a pessoa que acompanhará suas atividades no dia-a-dia.
        </Typography>
      </Modal>
    </>
  );
};

MandatoryInternshipInfo.propTypes = {};

export default MandatoryInternshipInfo;
