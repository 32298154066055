import React, { useState, useRef, useEffect, MouseEvent } from 'react';
import { useTheme } from '@emotion/react';
import {
  ButtonGhost,
  ButtonOutline,
  ButtonPrimary,
  Separator,
  Typography,
  SvgTrashAlt,
  convertPXToVH,
  convertPXToVW,
  SvgRedo,
} from '@sofia/ui';

import NotesObjectModalDelete from '../modal-delete';
import { pushAmpliEvent } from '../../../../../../lib/ga';
import { MAX_LENGTH_NOTE } from '../../../../../../constants';
import { checkTextAreaValue, getCounterColor } from '../../../../utils';
import { useCreateNote, useUpdateNote } from '../../../hooks';
import { NotesObjectFormProps, NotesModal } from '../types';
import * as Styled from './styles';

const NotesObjectForm = ({
  editButtonsDirection,
  hideDeleteModal,
  onDelete,
  onBeforeDelete,
  onCancel,
  onSave,
  note,
  title,
  queryOptions,
}: NotesObjectFormProps): React.ReactElement => {
  const theme = useTheme();
  const [disabled, setDisabled] = useState(true);
  const [txtAreaFocus, setTxtAreaFocus] = useState(false);
  const [counter, setCounter] = useState(note?.content?.length || 0);
  const ref = useRef<HTMLTextAreaElement>(null);
  const deleteRef = useRef<NotesModal>(null);
  const [selectedNote, setSelectedNote] = useState(note);

  const saveNoteOptions = {
    onCancel,
    onComplete: () => {
      if (note) {
        const content = ref.current ? ref.current.value : '';
        onSave({ ...note, content });
      }
    },
    ...(queryOptions ? queryOptions : {}),
  };

  const [createNote, createNoteLoading] = useCreateNote(saveNoteOptions);
  const [updateNote, updateNoteLoading] = useUpdateNote(saveNoteOptions);
  const loadingSaveNote = React.useMemo(
    () => createNoteLoading || updateNoteLoading,
    [updateNoteLoading, createNoteLoading]
  );

  const onChange = (value: string) => {
    if (!checkTextAreaValue(value)) {
      setDisabled(false);
      if (ref.current) {
        ref.current.style.height = ref.current.scrollHeight + 'px';
      }
    }
    setCounter(value.length);
  };

  const onSaveNote = () => {
    if (!note) return;
    const content = ref.current ? ref.current.value : '';
    setSelectedNote({ ...note, content });
    if (note.id) {
      if (updateNoteLoading) return;
      updateNote({ id: note.id, content });
      pushAmpliEvent('event', 'notes-form-edit-note-save');
    } else {
      if (createNoteLoading) return;
      createNote({
        ...note,
        content,
        createdDate: new Date().toISOString(),
      });
      pushAmpliEvent('event', 'notes-form-new-note-save');
    }
  };

  useEffect(() => {
    if (note?.id && ref.current) {
      ref.current.style.height = ref.current.scrollHeight + 'px';
    }
  }, [note]);

  return (
    <>
      <Styled.Wrapper
        borderColor={
          txtAreaFocus
            ? theme.colors.text.primary
            : theme.colors.card.border.primary
        }
      >
        <Typography color={theme.colors.text.black} size={16} weight={700}>
          {title}
        </Typography>
        <Separator
          color={theme.colors.separator.color}
          margin={`${convertPXToVH(16)} 0`}
        />
        <Styled.TextArea
          ref={ref}
          defaultValue={selectedNote?.content}
          onChange={(el) => onChange(el.target.value)}
          onFocus={() => setTxtAreaFocus(!txtAreaFocus)}
          onBlur={() => setTxtAreaFocus(!txtAreaFocus)}
          color={theme.colors.text.black}
          placeholder="Comece sua anotação por aqui."
          maxLength={MAX_LENGTH_NOTE}
        />
        <Separator
          color={theme.colors.separator.color}
          margin={`${convertPXToVH(16)} 0`}
        />
        <Styled.Counter>
          <Typography
            as="span"
            color={getCounterColor(theme, counter)}
            size={14}
            weight={700}
            margin={`0 ${convertPXToVW(4)} 0 0`}
          >
            {counter || 0}
          </Typography>
          <Typography
            as="span"
            color={theme.colors.text.black}
            size={14}
            weight={700}
          >
            / {MAX_LENGTH_NOTE}
          </Typography>
        </Styled.Counter>
      </Styled.Wrapper>

      <Styled.ActionWrapper>
        <Styled.EditButtonsWrapper direction={editButtonsDirection}>
          <ButtonPrimary
            color={theme.colors.text.white}
            background={theme.colors.button.primary}
            hover={theme.colors.button.darkPrimary}
            aria-label="Salvar anotação"
            width="100%"
            disabled={disabled || loadingSaveNote}
            onClick={onSaveNote}
          >
            {loadingSaveNote ? <SvgRedo id="rotate" /> : 'Salvar'}
          </ButtonPrimary>
          <ButtonOutline
            borderBoxColor={theme.colors.text.primary}
            color={theme.colors.text.primary}
            width="100%"
            onClick={() => {
              pushAmpliEvent(
                'events',
                `notes-form-${note?.id ? 'edit' : 'new'}-note-cancel`
              );
              onCancel();
            }}
            data-testid="notes-object-form-button-cancel"
          >
            Cancelar
          </ButtonOutline>
        </Styled.EditButtonsWrapper>
        {note?.id ? (
          <Styled.DeleteButton>
            <ButtonGhost
              onClick={(event: MouseEvent<HTMLButtonElement>) =>
                typeof onBeforeDelete === 'function'
                  ? onBeforeDelete(event)
                  : deleteRef.current?.onOpen(event)
              }
              aria-label="Excluir anotação"
              width="100%"
              data-testid="notes-object-form-button-delete"
            >
              <SvgTrashAlt fill={theme.colors.text.danger} />
              <Typography color={theme.colors.text.danger}>
                Excluir anotação
              </Typography>
            </ButtonGhost>
          </Styled.DeleteButton>
        ) : null}
      </Styled.ActionWrapper>

      {!hideDeleteModal && onDelete ? (
        <NotesObjectModalDelete
          ref={deleteRef}
          onCancel={onCancel}
          onDelete={onDelete}
          note={note}
          queryOptions={queryOptions}
        />
      ) : null}
    </>
  );
};

NotesObjectForm.defaultProps = {
  editButtonsDirection: 'column',
  hideDeleteModal: false,
};

export default NotesObjectForm;
