import { useAppendOnTrack, useGetTrack } from '@ampli/services';
import { TrackType } from '../types';

interface IAppendTrack {
  studentId: string;
  input: {
    key: string;
    desc: string;
  };
}

type HandleTrackHook = (props: IAppendTrack) => {
  track: TrackType;
  loading: boolean;
  withoutTrack: boolean;
  appendCommunicationOnTrack: () => void;
};

export const useAppendTrack = ({
  studentId,
  input,
}: IAppendTrack): (() => void) => {
  const [appendOnTrack] = useAppendOnTrack({
    skip: !studentId,
  });

  return () => {
    appendOnTrack({
      variables: {
        request: {
          referenceId: studentId,
          inputs: [
            {
              key: input.key,
              description: input.desc,
              value: 'TRUE',
            },
          ],
        },
      },
    });
  };
};

const useHandleTrack: HandleTrackHook = ({ studentId, input }) => {
  const { data: track, loading } = useGetTrack(studentId);
  const appendCommunicationOnTrack = useAppendTrack({
    studentId,
    input,
  });

  const currentInput =
    track?.inputs?.length > 0 && !loading
      ? track.inputs.find(({ key }: { key: string }) => key === input.key)
      : null;

  const withoutTrack = !currentInput || currentInput?.value === 'FALSE';

  return { track, loading, withoutTrack, appendCommunicationOnTrack };
};

export default useHandleTrack;
