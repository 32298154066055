import { useGetCourseEnrollmentForPages } from '@ampli/services';
import React, { createContext, useContext } from 'react';
import {
  ICourseEnrollmentsProviderProps,
  ICourseEnrollmentsReturn,
} from './course-enrollments-context.types';
import { useGetState } from '../../../state';

const CourseEnrollmentsContext = createContext({} as ICourseEnrollmentsReturn);

const useCourseEnrollmentsProvider = (): ICourseEnrollmentsReturn => {
  const [{ selectedCourseEnrollmentId }] = useGetState();

  const courseEnrollments = useGetCourseEnrollmentForPages(
    selectedCourseEnrollmentId
  );

  return {
    states: {
      courseEnrollments,
    },
  };
};

export const CourseEnrollmentsProvider = ({
  children,
}: ICourseEnrollmentsProviderProps): JSX.Element => {
  const courseEnrollmentsProvider: ICourseEnrollmentsReturn =
    useCourseEnrollmentsProvider();

  return (
    <CourseEnrollmentsContext.Provider value={courseEnrollmentsProvider}>
      {children}
    </CourseEnrollmentsContext.Provider>
  );
};

export const useCourseEnrollmentsContext = (): ICourseEnrollmentsReturn =>
  useContext(CourseEnrollmentsContext);
