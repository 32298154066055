import React from 'react';
import { useTheme } from '@emotion/react';
import { ButtonGhost, ButtonPrimary, SvgExclamationTriangle } from '@sofia/ui';

import { IModalsProps } from './types';
import useDiscardPublicationLogic from './base-modal.logic';
import Icon from './icon-wrapper';
import BaseModal from './base-modal.presentation';
import * as Styles from './modals.styles';

const DiscardPublication: React.FC<IModalsProps> = ({
  modal,
  publishType = 'publication',
}: IModalsProps) => {
  const { actions } = useDiscardPublicationLogic({ modal });
  const theme = useTheme();

  const title = {
    comment: 'Você perderá seu comentário',
    publication: 'Você perderá sua publicação',
  }[publishType];

  const description = {
    comment: 'Caso você feche agora, seu comentário será descartado.',
    publication: 'Caso você feche agora, sua publicação será descartada.',
  }[publishType];

  const Footer: React.FC = () => (
    <Styles.Footer>
      <ButtonPrimary
        onClick={() => actions.handleModal('continue')}
        width="100%"
        background={theme.colors.button.primary}
        color={theme.colors.button.secondary}
      >
        Continuar criando
      </ButtonPrimary>
      <ButtonGhost
        color={theme.colors.text.danger}
        marginTop={0}
        width="100%"
        onClick={() => actions.handleModal('discard')}
        className="discard-publication-button"
        id="discard-publication-button"
        data-testid="discard-publication-button"
      >
        Descartar
      </ButtonGhost>
    </Styles.Footer>
  );

  const ModalIcon = () => (
    <Icon background={theme.colors.card.background.warning}>
      <SvgExclamationTriangle
        fill={theme.colors.background.icon.warning}
        size="30"
      />
    </Icon>
  );

  return (
    <BaseModal
      Footer={Footer}
      Icon={ModalIcon}
      title={title}
      description={description}
      handleModal={modal}
    />
  );
};

export default DiscardPublication;
