import React from 'react';
import {
  Alerts,
  SvgExclamationTriangle,
  SvgInfoCircle,
  convertPXToVH,
} from '@sofia/ui';
import { Wrapper } from './styles';
import getTheme, { AlertInterface } from './utils';

interface AlertProps extends AlertInterface {
  margin?: string;
  shouldInnerHTML?: boolean;
}

/**
 * Alert component displays a message with an icon based on the type prop.
 *
 * @param type - The type of the alert (e.g., 'warning' | 'info' | 'danger';).
 * @param message - The message to be displayed in the alert.
 * @param margin - The margin of the alert component.
 */

const Alert: React.FC<AlertProps> = ({
  type,
  message,
  margin = `0 0 ${convertPXToVH(24)} 0`,
  shouldInnerHTML = false,
}: AlertProps) => {
  const theme = getTheme(type);

  const themeAlert = { color: theme.color, background: theme.background };

  return (
    <Wrapper.Content margin={margin}>
      <Alerts
        iconLeft={
          <Wrapper.Svg>
            {theme.type === 'danger' ? (
              <SvgExclamationTriangle fill={theme.colorIcon} />
            ) : (
              <SvgInfoCircle fill={theme.colorIcon} />
            )}
          </Wrapper.Svg>
        }
        text={message}
        theme={themeAlert}
        shouldInnerHTML={shouldInnerHTML}
      />
    </Wrapper.Content>
  );
};

export default Alert;
