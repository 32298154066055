import React from 'react';
import { MathJax } from 'better-react-mathjax';
import * as Styled from './formula-asset-styles';
import { FormulaAssetProps } from '../types';

const FormulaAsset = ({ data }: FormulaAssetProps): React.ReactElement => {
  return (
    <MathJax>
      <Styled.FormulaContainer
        data-testid="formula-asset"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
    </MathJax>
  );
};

export default FormulaAsset;
