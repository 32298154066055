import { useTheme } from '@emotion/react';
import React from 'react';
import * as Styled from './live-theme.styles';
import { SvgLive, Tag } from '@sofia/ui';
import { ELiveState } from '../../types';

interface ILiveTheme {
  state: ELiveState;
  svgSize?: {
    width: string;
    height: string;
  };
  testId: string;
}

const LiveTheme = ({
  state,
  svgSize,
  testId = 'live-theme-tag',
}: ILiveTheme): React.ReactElement => {
  const theme = useTheme();
  const isLive = state === ELiveState.LIVE;

  return (
    <Styled.Container data-testid={testId}>
      <SvgLive
        fill={isLive ? theme.colors.live.danger : theme.colors.text.black}
        {...svgSize}
      />
      <Tag
        theme={
          isLive
            ? {
                color: theme.colors.live.danger,
                background: theme.colors.live.lightDanger,
              }
            : {
                color: theme.colors.text.black,
                background: theme.colors.background.primary,
              }
        }
        text={
          <Styled.TagWrapper data-testid={`${testId}-tag`}>
            {isLive ? (
              <>
                <Styled.RecordIcon bgcolor={theme.colors.live.danger} />
                AO VIVO
              </>
            ) : (
              'EM BREVE'
            )}
          </Styled.TagWrapper>
        }
      />
    </Styled.Container>
  );
};

export default LiveTheme;
