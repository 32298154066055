import { GroupedObject, NoteType } from '../shared';

function groupObjectByProperty<T = NoteType>(
  array: Array<T>,
  propertyName: keyof T
): GroupedObject<Array<T>> {
  return array?.reduce((object: GroupedObject<Array<T>>, element) => {
    const key = element[propertyName] as string;
    if (!object[key]) {
      object[key] = [];
    }
    object[key].push(element);
    return object;
  }, {});
}

export default groupObjectByProperty;
