import React from 'react';

import { useModal } from '@sofia/ui';

import {
  analyticsCreativesEvents,
  useInternLinksCTA,
  INTERNAL_LINKS,
} from '../../../shared/utils/communication-constants';

import { useListModalById, useUpdateReadCreative } from '../../services/hooks';
import { Creative, parsedCategory } from '../../types';
import {
  UseCommunicationModal,
  UseCommunicationModalParams,
} from './modal.types';
import { useGetState } from '../../../../state';
import { pushEvent } from '../../../../lib/ga';
import { useCreativeContext } from '../creatives-exhibition-controller/creatives-exhibition-controller';

const STORAGE_KEY = 'modal-communication';
const today = new Date().toLocaleDateString();

export const useCommunicationModal: UseCommunicationModal = (
  props?: UseCommunicationModalParams
) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const { currentCreative, markCurrentAsDone, shouldDisplayCreative } =
    useCreativeContext();

  const canShowModal = sessionStorage.getItem(STORAGE_KEY);
  const modalDateControl = localStorage.getItem(STORAGE_KEY);

  const modalHandler = useModal();

  const [{ selectedCourseEnrollmentId }] = useGetState();

  const {
    data: [modal],
    loading,
  } = useListModalById<Creative[]>(selectedCourseEnrollmentId, {
    context: { skipLogout: true },
  });

  const category = parsedCategory(modal?.categoryType);

  const [onUpdateRead] = useUpdateReadCreative(
    selectedCourseEnrollmentId,
    modal?.id
  );

  const callToAction = useInternLinksCTA(modal?.ctaLink as INTERNAL_LINKS, {
    onFinalExameModal: () => props?.selectExamDialog?.show(),
  });

  const events = React.useMemo(() => {
    const triggerEvent = (
      eventKey: keyof typeof analyticsCreativesEvents.modal,
      paramKey?: string
    ) => {
      const event =
        analyticsCreativesEvents.modal[
          eventKey as keyof typeof analyticsCreativesEvents.modal
        ].event;

      const params = paramKey
        ? {
            item: analyticsCreativesEvents.modal[
              eventKey as keyof typeof analyticsCreativesEvents.modal
            ].param(category?.title),
          }
        : undefined;

      pushEvent('event', event, params);
    };

    return {
      close: () => triggerEvent('Com_modal_clique_fechar', 'item'),
      cta: () => triggerEvent('Com_modal_clique_cta', 'item'),
      view: () => triggerEvent('Com_modal_visualizacao'),
    };
  }, [category?.title]);

  const onClose = React.useCallback(
    (closedBy?: 'cta' | 'close') => {
      onUpdateRead();
      modalHandler.hide();
      markCurrentAsDone('modal');

      if (closedBy === 'cta') {
        events.cta();
      } else {
        events.close();
      }
    },
    [events, markCurrentAsDone, modalHandler, onUpdateRead]
  );

  const handleModal = React.useMemo(
    () => ({
      ...modalHandler,
      hide: () => onClose('close'),
    }),
    [modalHandler, onClose]
  );

  const onCallToAction = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      if (typeof callToAction === 'string') {
        window.open(callToAction);
      } else {
        callToAction();
      }

      return onClose('cta');
    },
    [callToAction, onClose]
  );

  const showModal = React.useMemo(
    () => canShowModal !== 'false' || modalDateControl !== today,
    [canShowModal, modalDateControl]
  );

  const businessRules = () => showModal;

  React.useEffect(() => {
    // Sai da função se a query ainda está carregando ou se o criativo não deve ser exibido
    if (
      loading == null ||
      loading ||
      !shouldDisplayCreative({ type: 'modal', businessRules })
    )
      return;

    if (!modal) {
      markCurrentAsDone('modal');
      setIsModalVisible(false);
      return;
    }

    setIsModalVisible(true);
    handleModal.show();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCreative, loading, modal, businessRules]);

  React.useLayoutEffect(() => {
    if (handleModal.state) {
      events.view();
      sessionStorage.setItem(STORAGE_KEY, 'false');
      localStorage.setItem(STORAGE_KEY, today);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleModal.state]);

  return { modal, handleModal, onCallToAction, category, isModalVisible };
};
