/* eslint-disable sonarjs/no-duplicate-string */
import styled from '@emotion/styled';

import { convertPXToREM, ButtonOutline as SofiaButtonOutline } from '@sofia/ui';

interface FlexProps {
  width?: string;
  direction?: 'row' | 'column';
  align?: 'center' | 'flex-start' | 'flex-end';
  padding?: string;
  gap?: number;
  justify?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
}

export const Popover = styled.div<{ buttonHeight?: number }>`
  display: flex;
  width: 100%;
  max-width: 296px;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 37, 41, 0.12);
  background: ${({ theme }) => theme.colors.icon.background};

  position: fixed;
  bottom: ${({ buttonHeight = 0 }) => buttonHeight + 36}px;
  right: 24px;
`;

export const RoundedButton = styled.button`
  width: fit-content;
  padding: 24px;
  background: ${({ theme }) => theme.colors.icon.primary};
  border-radius: 50%;
  border: none;
  position: fixed;
  bottom: 24px;
  right: 24px;
`;

export const Flex = styled.div<FlexProps>`
  width: ${({ width = '100%' }) => width};
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  align-items: ${({ align = 'flex-start' }) => align};
  justify-content: ${({ justify = 'flex-start' }) => justify};
  gap: ${({ gap }) => convertPXToREM(gap)};
  padding: ${({ padding }) => padding};
  text-align: left;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 6px;
  border-radius: 50%;
`;

export const ButtonOutline = styled.button`
  width: 100%;
  padding: 16px 24px;
  background: none;
  border: 2px solid ${({ theme }) => theme.colors.button.primary};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.button.primary};
  font-weight: 700;
`;

export const DivideLine = styled.hr`
  width: 100%;
  margin: 0;
`;

export const CustomButtom = styled(SofiaButtonOutline)<{ isModal?: boolean }>`
  max-width: ${convertPXToREM(160)};
  ${({ isModal, theme }) =>
    !isModal &&
    `
    width: 100%;
    max-width: unset;
    background: transparent !important;
    border: 2px solid ${theme.colors.button.primary};
  `}
`;
