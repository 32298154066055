import React, { useEffect, useCallback } from 'react';
import { string, object, func } from '@ampli/utils';
import {
  css,
  px2grid,
  useDialogState,
  Paragraph,
  Text,
  TextButton,
  Field,
  FileInput,
  Flex,
  Thumbnail,
} from '@ampli/ui';

import { CertificateImage } from '../image';
import OnboardingDocumentsSkipModal from './onboarding-documents-skip-modal';

const OnboardingCertificateDocument = ({
  formik,
  onSkipStepClick,
  ...props
}) => {
  const skipDialog = useDialogState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFieldValue = useCallback(formik.setFieldValue, []);

  useEffect(() => {
    setFieldValue('documentType', 'ACADEMIC_TRANSCRIPT');
  }, [setFieldValue]);

  return (
    <div {...props}>
      <Paragraph marginSize="x-large">
        <Text>
          Precisamos agora que você tire uma foto do seu certificado de
          conclusão do ensino médio
        </Text>
      </Paragraph>
      <Paragraph as="div" marginSize="small">
        <Field
          as={FileInput}
          name="documentFile"
          onChange={(e) => {
            const file = e.target.files[0];
            setFieldValue('documentFile', file);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.documentFile}
          error={formik.touched.documentFile && formik.errors.documentFile}
          accept="image/jpeg,image/png,application/pdf"
        >
          <Flex>
            <div>
              {formik.values.documentFile ? (
                <Thumbnail
                  file={formik.values.documentFile}
                  className={css`
                    margin-right: ${px2grid(16)};
                  `}
                />
              ) : (
                <CertificateImage
                  className={css`
                    margin-right: ${px2grid(16)};
                  `}
                />
              )}
              <Text size="small">Certificado de conclusão do ensino médio</Text>
            </div>
          </Flex>
        </Field>
      </Paragraph>
      <Paragraph marginSize="x-large">
        <TextButton
          color="accent-base"
          size="x-small"
          onClick={skipDialog.show}
        >
          Não tenho o certificado
        </TextButton>
      </Paragraph>
      <OnboardingDocumentsSkipModal
        {...skipDialog}
        title="Não tenho o certificado"
        description={
          <Text>
            Precisamos do seu certificado para que você possa receber seu
            diploma na formatura. Por favor,{' '}
            <strong>ligue para sua escola</strong>, para que possa consegui-lo e
            depois nos envie pelo menu <strong>Aluno</strong>.
          </Text>
        }
        skipLabel="Enviar depois"
        onSkipClick={(e) => {
          skipDialog.hide();
          onSkipStepClick && onSkipStepClick(e);
        }}
      />
    </div>
  );
};

OnboardingCertificateDocument.propTypes = {
  className: string,
  formik: object.isRequired,
  onSkipStepClick: func.isRequired,
};

export default OnboardingCertificateDocument;
