import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const Flex = styled.div`
  display: flex;
`;

export const Field = styled(Flex)`
  width: 100%;
  align-items: center;
  flex-direction: column;

  ${mediaQuery('mobile')} {
    align-items: start;
  }
`;

export const InputsWrapper = styled(Flex)`
  gap: 8px;
  align-items: center;
`;

export const FieldWrapper = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(5, 1fr);

  ${mediaQuery('mobile')} {
    grid-template-columns: 1fr;
  }
`;
