import React from 'react';

import {
  useUpdateReadCreative,
  useListSlidingById,
} from '../../services/hooks';

import {
  analyticsCreativesEvents,
  useInternLinksCTA,
  INTERNAL_LINKS,
} from '../../..';

import { Creative, parsedCategory } from '../../types';
import { UseSliding, UseSlidingParams } from './sliding.types';
import { useGetState } from '../../../../state';
import { pushEvent } from '../../../../lib/ga';
import { useModal } from '@sofia/ui';
import { useCreativeContext } from '../creatives-exhibition-controller/creatives-exhibition-controller';

export const STORAGE_KEY = 'sliding-communication';

const today = new Date().toLocaleDateString();

export const useSliding = (props?: UseSlidingParams): UseSliding => {
  const [isSlidingVisible, setIsSlidingVisible] = React.useState(false);

  const { currentCreative, markCurrentAsDone, shouldDisplayCreative } =
    useCreativeContext();

  const canShowSlidingSession = sessionStorage.getItem(STORAGE_KEY);
  const slidingDateControl = localStorage.getItem(STORAGE_KEY);

  const handler = useModal();

  const [{ selectedCourseEnrollmentId }] = useGetState();

  const {
    data: [sliding],
    loading,
  } = useListSlidingById<Creative[]>(selectedCourseEnrollmentId, {
    context: { skipLogout: true },
  });

  const category = parsedCategory(sliding?.categoryType);

  const [onUpdateRead] = useUpdateReadCreative(
    selectedCourseEnrollmentId,
    sliding?.id
  );

  const callToAction = useInternLinksCTA(sliding?.ctaLink as INTERNAL_LINKS, {
    onFinalExameModal: () => props?.selectExamDialog?.show(),
  });

  const events = React.useMemo(() => {
    const triggerEvent = (eventKey: string, paramKey?: string) => {
      const event =
        analyticsCreativesEvents.sliding[
          eventKey as keyof typeof analyticsCreativesEvents.sliding
        ].event;

      const params = paramKey
        ? {
            item: analyticsCreativesEvents.sliding[
              eventKey as keyof typeof analyticsCreativesEvents.sliding
            ].param(category?.title),
          }
        : undefined;

      pushEvent('event', event, params);
    };

    return {
      close: () => triggerEvent('Com_deslizante_clique_fechar', 'item'),
      cta: () => triggerEvent('Com_deslizante_clique_cta', 'item'),
      view: () => triggerEvent('Com_deslizante_visualizacao'),
    };
  }, [category?.title]);

  const onClose = React.useCallback(
    (closedBy?: 'cta' | 'close') => {
      if (closedBy === 'cta') {
        events.cta();
      } else {
        events.close();
      }
      markCurrentAsDone('sliding');
      onUpdateRead();
      handler.hide();
    },
    [events, handler, markCurrentAsDone, onUpdateRead]
  );

  const handlerPopup = React.useMemo(
    () => ({
      ...handler,
      hide: () => {
        sessionStorage.setItem(STORAGE_KEY, 'false');
        localStorage.setItem(STORAGE_KEY, today);
        onClose('close');
      },
    }),
    [handler, onClose]
  );

  const onCallToAction = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      sessionStorage.setItem(STORAGE_KEY, 'false');
      localStorage.setItem(STORAGE_KEY, today);

      if (typeof callToAction === 'string') {
        window.open(callToAction);
      } else {
        callToAction();
      }

      return onClose('cta');
    },
    [callToAction, onClose]
  );

  const hasSlidingAvailable = !!sliding;

  const showSliding =
    canShowSlidingSession !== 'false' && slidingDateControl !== today;

  const businessRules = () => showSliding;

  React.useEffect(() => {
    // Sai da função se a query ainda está carregando ou se o criativo não deve ser exibido
    if (
      loading == null ||
      loading ||
      !shouldDisplayCreative({ type: 'sliding', businessRules })
    )
      return;

    if (!sliding) {
      markCurrentAsDone('sliding');
      setIsSlidingVisible(false);
      return;
    }

    setIsSlidingVisible(true);
    handlerPopup.show();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCreative, businessRules, loading, sliding]);

  return {
    hasSlidingAvailable,
    onCallToAction,
    handlerPopup,
    sliding,
    isSlidingVisible,
  };
};
