import styled from '@emotion/styled';

import { convertPXToREM, mediaQuery } from '@sofia/ui';
import { CustomSubtitle, getIncreasedFontSize, sizeMap } from './helper';
import { ContentContainerStyledProps } from './types';

export const ContentContainer = styled.div<ContentContainerStyledProps>(
  ({
    theme,
    isDesktop,
    customization,
    defaultFontSize,
    templateSubtitle,
    templateTitleFontColor,
  }) => {
    const customColor = templateTitleFontColor
      ? theme.colors.text.black
      : customization.theme.color;
    const commonCustomProps = `
      font-family: ${customization.formatFont};
      text-align: ${customization.formatAlign};
      line-height: ${customization.betweenLines}%;
      word-spacing: ${customization.betweenWords / 100}em;
      letter-spacing: ${customization.betweenLetters / 100}em;
      word-break: break-word;
    `;

    const mappedHeadings = sizeMap(isDesktop).map((size) => {
      const renderHeadingChildren = (color: string) => `
        p,
        span,
        pre,
        strong {
          color: ${color};
          font-size: ${getIncreasedFontSize(
            size.fontSize,
            customization.fontSize,
            defaultFontSize
          )};
          display: block;
          ${commonCustomProps}
        }
        ${
          size.tag !== 'h1'
            ? `:not(:last-of-type) + *:not(:last-child){
            ${mediaQuery('mobile')} {
              margin-bottom: 48px;
            }
            margin-bottom: 64px;
          }`
            : ''
        }
      `;
      return ` ${size.tag} {
        ${
          size.tag === 'h2' &&
          templateSubtitle &&
          templateSubtitle.fontColor &&
          templateSubtitle.backgroundColor &&
          templateSubtitle.borderStyle
            ? `${CustomSubtitle[templateSubtitle.borderStyle]({
                fontColor: templateSubtitle.fontColor,
                backgroundColor: templateSubtitle.backgroundColor,
              })}
              ${renderHeadingChildren(templateSubtitle.fontColor)}`
            : size.tag === 'h1' && templateTitleFontColor
            ? renderHeadingChildren(templateTitleFontColor)
            : renderHeadingChildren(customColor)
        }
      }`;
    });

    const defaultObject = `
      margin-top: 24px;
      margin-bottom: 0px;
    `;

    return `
      ${mappedHeadings}

      .rich-text {
        p,
        span:not(.no-kindle),
        pre,
        strong,
        math,
        {
          ${defaultObject}
          color: ${customColor};
          font-size: ${convertPXToREM(customization.fontSize)};
          ${commonCustomProps}
        }

        ${mappedHeadings}
      }

      table {
        color: ${customColor};

        th, td{
          color: ${customColor};
          border-color: ${customColor};
          ${
            ['16', '20', '24'].includes(customization.fontSize.toString())
              ? `font-size: ${convertPXToREM(customization.fontSize)};`
              : `font-size: ${convertPXToREM(24)};`
          }
          font-family: ${customization.formatFont};

          ${mediaQuery('mobile')} {
            min-width: ${convertPXToREM(164)};
          }
          min-width: ${convertPXToREM(170)};
          max-width: ${convertPXToREM(340)};
        }
      }

      mjx-container {
        color: ${customColor};
        ${defaultObject}
        font-size: ${convertPXToREM(customization.fontSize)} !important;
        mjx-math {
          font-family: ${customization.formatFont};
        }
      }

      .image-caption{
        color: ${customColor};
      }

      a,
      a > * {
        text-decoration: underline;
        &:not(:hover):not(:focus){
          color: #01a0b7 !important;
        }
        &:hover {
          color: #018296;
        }
        &:focus {
          color: #018296;
          text-decoration: none;
        }
      }
    `;
  }
);

export const SectionContentContainer = styled.div<{
  background?: string | null;
}>(
  ({ background }) => `
    ${
      background &&
      `background${
        background.startsWith('#')
          ? `-color: ${background};`
          : `-image: url(${background});`
      }`
    }
    position: relative;
    z-index: 0;
    padding-bottom: 40px;

    ${mediaQuery('mobile')} {
      padding-bottom: 56px;
    }
  `
);

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${convertPXToREM(712)};
  width: 100%;

  ${mediaQuery('tablet-max')} {
    padding: 0 16px;
  }
`;
