import React from 'react';
import {
  withNotificationsProvider,
  useNotifications,
} from './notifications/contexts/notification-context';

import { withCanDisplayValidation } from '../hocs/withCanDisplayValidation';
import { Notifications } from './notifications';
import { StickyBanner } from './sticky-banner';
import { Sliding } from './sliding';
import { Signal } from './notifications/components/notifications-button/notifications-button.styles';
import { Banner } from './banner';
import { Modal } from './modal';
import { NotificationsButtonProps } from './notifications/components/notifications-button/notifications-button';

export const Creatives = {
  withNotificationsProvider,
  useNotifications,
  Signal,

  // Central de notificações
  Notifications: withCanDisplayValidation(Notifications, {
    display: true,
  }) as React.FC<NotificationsButtonProps>,

  // Comunicação de Ampli by Anhanguera
  Sliding: withCanDisplayValidation(Sliding, { display: true }),

  // Comunicação de rematricula
  StickyBanner: withCanDisplayValidation(StickyBanner, { display: true }),

  // Banner
  Banner: withCanDisplayValidation(Banner, { display: true }),

  // Modal
  Modal: withCanDisplayValidation(Modal, { display: true }),
};
