import { hijackConsole } from '@ampli/logger';

import { name, isProduction, logLevel } from '../config';

hijackConsole({
  appName: name,
  logLevel,
  debugMode: !isProduction,
  isNode: false,
});
