import React from 'react';

import {
  SvgArrowRight,
  CardButton,
  Typography,
  TagList,
  Avatar,
} from '@sofia/ui';

import { useMutation, useQueryClient } from 'react-query';
import { RichText, cx, css } from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';
import { clickByKey } from '@ampli/utils';
import { useTheme } from '@emotion/react';

import { useDynamicsLogic } from '../../hooks/use-dynamics-logic';
import { ConversationInfo } from '../../types';
import { getRelativeDate } from '../../../../shared';
import { usePtkFrame } from '../../contexts';

import * as Styled from './conversation-card.styles';

interface ConversationCardProps {
  conversation: ConversationInfo;
}

const ConversationCard: React.FC<ConversationCardProps> = ({
  conversation,
}: ConversationCardProps) => {
  const theme = useTheme();

  const breakpoint = useBreakpoint();
  const isDestkop = breakpoint === 'desktop';

  const {
    conversation: selectedConversation,
    queryKeys,
    dispatch,
    tutor,
    page,
    me,
  } = usePtkFrame();

  const queryClient = useQueryClient();

  const { readMessage } = useDynamicsLogic();

  const isTutorAgent = React.useMemo(
    () => conversation?.agent === 'TUTOR',
    [conversation?.agent]
  );

  const isStudentAgent = React.useMemo(
    () => conversation?.agent === 'STUDENT',
    [conversation?.agent]
  );

  const isHistoricPage = React.useMemo(() => page === 'HISTORIC', [page]);

  const isUnred = React.useMemo(
    () => conversation?.readStatus === 'UNREAD',
    [conversation?.readStatus]
  );

  const isSelected = React.useMemo(
    () => isHistoricPage && selectedConversation?.id === conversation?.id,
    [conversation?.id, isHistoricPage, selectedConversation?.id]
  );

  const date = React.useMemo(
    () => getRelativeDate(conversation?.updateDateTime),
    [conversation?.updateDateTime]
  );

  const showTutorTag = React.useMemo(
    () => isHistoricPage && isTutorAgent,
    [isHistoricPage, isTutorAgent]
  );

  const tagText = React.useMemo(
    () => (showTutorTag ? 'MENSAGEM DO TUTOR' : conversation?.status),
    [conversation?.status, showTutorTag]
  );

  const tagColor = React.useMemo(
    () =>
      showTutorTag
        ? 'standard'
        : {
            FINALIZADA: 'standard',
            RESPONDIDA: 'warning',
            ENVIADA: 'success',
            DEFAULT: 'standard',
          }[conversation?.status || 'DEFAULT'],
    [conversation?.status, showTutorTag]
  );

  const getTagsList = React.useCallback(() => {
    const tags = [];

    if (conversation?.status || showTutorTag)
      tags.push({ text: tagText, type: tagColor, size: isDestkop ? 16 : 14 });

    return tags;
  }, [conversation?.status, isDestkop, showTutorTag, tagColor, tagText]);

  const readMessageMutation = useMutation(readMessage, {
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries(queryKeys.home),
        queryClient.invalidateQueries(queryKeys.chat),
        queryClient.invalidateQueries(queryKeys.historic),
      ]),
  });

  const onClick = React.useCallback(() => {
    if (isUnred) readMessageMutation.mutateAsync(conversation?.id);
    return isHistoricPage
      ? dispatch({ conversation })
      : dispatch({ conversation, page: 'CONVERSATION_CHAT' });
  }, [conversation, dispatch, isHistoricPage, isUnred, readMessageMutation]);

  React.useEffect(() => {
    if (isSelected && isUnred)
      readMessageMutation.mutateAsync(conversation?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, selectedConversation, isSelected, isUnred]);

  return (
    <Styled.Card isUnred={isUnred} isSelected={isSelected}>
      <CardButton
        borderColor={theme.colors.card.border.primary}
        tabIndex={0}
        onClick={onClick}
        onKeyDown={(evt: React.KeyboardEvent<HTMLButtonElement>) =>
          clickByKey(evt, onClick)
        }
      >
        <Styled.Flex width="100%">
          <Styled.Flex width="100%">
            {isTutorAgent ? (
              <Avatar
                value={tutor?.tutor.name || 'T'}
                src={tutor?.tutor.picture}
                size={40}
              />
            ) : null}

            {isStudentAgent ? <Avatar value={me?.fullName} size={40} /> : null}

            <Styled.Flex
              align="start"
              direction="column"
              margin="0px 0px 0px 8px"
              width="100%"
            >
              <Styled.Flex
                align="center"
                justify="space-between"
                direction="flex"
                width="100%"
              >
                <TagList tagList={getTagsList()} />

                {isHistoricPage ? (
                  <Typography size={14}>{date}</Typography>
                ) : null}
              </Styled.Flex>

              {isHistoricPage && conversation?.class ? (
                <Styled.TruncateText
                  size={14}
                  weight={700}
                  transform="uppercase"
                  margin="8px 0px"
                  color={theme.colors.text.black}
                  spacing="0.25px"
                >
                  {conversation?.class}
                </Styled.TruncateText>
              ) : null}

              <RichText
                className={cx(
                  css(
                    Styled.Preview({
                      isStudentAgent,
                      color: theme.colors.text.lightBlack,
                      isHistoricPage: Boolean(
                        isHistoricPage && conversation?.class
                      ),
                    })
                  )
                )}
              >
                {conversation?.preview}
              </RichText>
            </Styled.Flex>
          </Styled.Flex>

          <Styled.Flex>
            <SvgArrowRight />
          </Styled.Flex>
        </Styled.Flex>
      </CardButton>
    </Styled.Card>
  );
};

export default ConversationCard;
