import styled from '@emotion/styled';

import { SvgBellNotification, SvgBell } from '@sofia/ui';

export const IconBellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NotificationButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  background: none;
  padding: 0;
`;

export const BellNotificationIcon = styled(SvgBellNotification)`
  width: 24px;
`;

export const BellIcon = styled(SvgBell)`
  width: 24px;
`;

export const Signal = styled.div<{ isBadge?: boolean }>`
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.colors.button.danger};
  border-radius: 999px;
  min-height: fit-content !important;

  ${({ isBadge = false }) =>
    isBadge &&
    `
    position: absolute;
    top: 0;
    right: 0;
  `}
`;
