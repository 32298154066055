import { DocumentNode } from 'graphql';
import { MouseEvent } from 'react';
import { Meta } from 'react-router-guards/dist/types';

import { TalkTutorFrameProps } from '../messaging/components/talk-tutor-frame/talk-tutor-frame';

import {
  LazyQueryHookOptions,
  MutationHookOptions,
  QueryHookOptions,
  MutationTuple,
  QueryResult,
  QueryTuple,
  ApolloClient,
  NormalizedCacheObject,
  OperationVariables,
} from '@apollo/client';

export interface RoutesProps {
  guardedRouteMeta?: Meta;
}

export interface ModuleProps {
  routesSettings: RoutesProps;
}

export type GlobalParams = {
  courseType: string;
  courseVersionId: string;
  subjectEnrollmentId: string;
};

export type SimpleMeType = {
  personId: string;
  firstName: string;
};

export type MeType = {
  id: string;
  username: string;
  fullName: string;
  email: string;
  personId: string;
  phoneNumber: {
    countryCode?: string;
    areaCode: string;
    number: string;
  };
  firstName: string;
  lastName: string;
  phoneNumberString: string;
};

export type LoginInfoType = {
  emailMask: string;
  firstName: string;
  username: string;
  id: string;
  forcePasswordReset: boolean;
  phoneNumberMask: string;
};

export type ModalType = {
  show: () => void;
  hide: () => void;
  state: boolean;
};

export type {
  CustomizationDataProps,
  SectionLocationState,
  BeforeVideoRequestOptions,
  TContentTemplate,
} from './components/section/types';

export type MockRequestGraphqlType = {
  query: DocumentNode;
  variables: Record<string, unknown>;
};

export type MockResponseGraphqlType = {
  request: MockRequestGraphqlType;
  result: unknown;
};

export enum EAssignmentStatus {
  AVAILABLE = 'AVAILABLE',
  DONE = 'DONE',
  ONGOING = 'ONGOING',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  DISAPPROVED = 'DISAPPROVED',
}

export type TQueryHook = <TQuery, TVariables>(
  query: DocumentNode,
  options: QueryHookOptions<TQuery, TVariables & OperationVariables>
) => QueryResult<TQuery, TVariables & OperationVariables>;

export type TLazyQueryHook = <TQuery, TVariables>(
  query: DocumentNode,
  options: LazyQueryHookOptions<TQuery, TVariables & OperationVariables>
) => QueryTuple<TQuery, TVariables & OperationVariables>;

export type TDefaultMutationData = {
  data?: Record<string, never> | null;
  errors?: Record<string, never>[] | null;
};

export type TMutationHook = <TMutationData, TVariables>(
  query: DocumentNode,
  options: MutationHookOptions<TMutationData, TVariables>,
  ignoreUseffectError?: boolean
) => MutationTuple<TMutationData, TVariables>;

export type TDefaultServiceClient = ApolloClient<NormalizedCacheObject>;

export interface ModalInterface {
  onOpen: (event?: MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
}

export type TComplementaryFields = {
  name: string;
  value: string;
};

export interface ITalkTutorInfos {
  unity: string;
  unityID: string;
  learningObject: string;
  learningObjectID: string;
  learningUnitEnrollmentId: string;
  class: string;
  classID: string;
  openTalkTutorFrameDate: string;
}

export interface UseGenerateTutorPreviewTokenProps {
  talkTutorInfos?: TalkTutorFrameProps['infos'];
}

export interface UseGenerateTutorPreviewTokenReturn {
  getObjectLearningPath: () => Promise<string>;
}
