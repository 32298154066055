import React from 'react';
import { number } from 'prop-types';

const ImageError404 = ({ width, height, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 172 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M85.9997 200C133.496 200 171.999 161.429 171.999 113.849C171.999 66.2698 133.496 27.699 85.9997 27.699C38.5034 27.699 0 66.2698 0 113.849C0 161.429 38.5034 200 85.9997 200Z"
        fill="#DBE3EC"
      />
      <path
        d="M109.923 156.812C109.662 160.367 108.999 171.465 115.052 180.448C116.063 181.949 118.499 185.487 120.739 185.052C123.488 184.518 124.517 178.315 124.764 175.826C125.556 167.824 124.29 142.425 121.04 108.181C117.374 118.418 111.359 137.291 109.923 156.812Z"
        fill="#081F2D"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.292 166.034C110.552 164.441 98.8939 165.233 91.0471 166.224C79.8747 167.629 69.9606 170.267 56.5142 167.106C44.2268 164.225 43.0857 159.813 42.9059 158.922C42.4296 156.554 43.7418 154.465 44.353 153.483C44.9952 152.473 45.7861 151.565 46.699 150.791C41.4767 150.836 36.7579 149.63 34.6009 146.703C33.2477 144.794 32.6197 142.463 32.8302 140.13C33.2168 136.096 36.1918 133.593 37.0007 132.945C33.7565 131.811 31.2393 130.118 30.1697 127.876C29.6373 126.72 29.435 125.439 29.5854 124.175C30.4392 117.935 40.5151 115.252 41.3511 115.045C36.6323 112.605 33.0548 108.796 32.6415 104.492C32.3629 101.638 33.5222 99.0089 35.0054 96.8929C34.1964 94.714 34.8346 92.5801 34.8884 92.4C37.7018 84.0262 57.0537 82.4325 68.1182 83.0178C73.1872 83.2886 87.8745 84.2423 102.489 93.6154C111.774 99.5672 112.88 103.691 122.92 109.264L125.292 166.034Z"
        fill="#C5D2E0"
      />
      <path
        d="M101.979 137.007C98.0759 135.586 94.4879 133.415 91.4165 130.615C85.25 124.977 82.9671 118.367 82.127 115.324"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M172.001 113.85L167.249 195.969C151.573 191.775 140.5 189.55 133.219 187.933C128.809 186.952 124.422 186 120.059 185.076C120.091 185.078 120.188 185.083 120.29 185.081C120.374 185.086 120.457 185.085 120.54 185.078C120.608 185.074 120.676 185.065 120.742 185.049C123.485 184.52 124.517 178.319 124.764 175.827C125.559 167.828 124.294 142.43 121.045 108.184L172.001 113.85Z"
        fill="#3969EB"
      />
      <path
        d="M131.995 187.661C135.919 182.547 137.992 176.25 137.876 169.801L136.799 109.935"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M115.053 180.448C116.065 181.949 118.5 185.487 120.74 185.052C123.489 184.518 124.518 178.315 124.765 175.826C125.558 167.824 124.291 142.425 121.041 108.181"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M67.2036 7.69253C58.099 0.995111 46.4285 -1.34357 35.0563 0.733497L34.5697 0.81691C23.3617 2.73839 13.3249 8.78801 7.05621 18.0016C-1.82162 31.0515 -1.93369 48.6553 5.85045 61.6629C13.248 74.0477 27.3724 81.5446 42.7609 81.7714C44.796 95.8624 50.1026 134.136 50.8144 138.445C50.9467 139.246 51.2402 140.011 51.6771 140.694C52.1139 141.377 52.6851 141.964 53.3556 142.419C54.0262 142.875 54.7822 143.188 55.5776 143.341C56.373 143.494 57.1912 143.484 57.9824 143.31L58.1018 143.284C59.6216 142.95 60.9513 142.035 61.8077 140.734C62.6641 139.433 62.9795 137.848 62.6868 136.317C61.9839 132.642 55.9982 89.9119 54.4276 79.8238C60.8123 77.5063 66.4987 73.9379 71.1142 69.459C77.0355 63.7123 81.2055 56.4661 82.8304 48.4686C85.8556 33.6086 79.9077 17.0433 67.2036 7.69253ZM57.7645 65.5081C54.4489 67.6496 50.7351 69.0972 46.8466 69.7636C42.958 70.4301 38.9751 70.3018 35.1373 69.3863C24.4354 66.845 14.2123 58.083 12.1841 45.8884C10.7065 36.9703 13.9373 28.1767 19.1407 22.4437C23.6528 17.4681 30.0152 14.304 37.1845 13.194C44.3233 11.8517 51.3654 12.7183 57.2714 15.9081C64.0904 19.5807 70.047 26.7994 71.6134 35.7024C73.7571 47.8772 67.0232 59.5456 57.7645 65.5081Z"
        fill="#E0F0ED"
      />
      <path
        d="M57.7648 65.5077C54.4492 67.6492 50.7354 69.0967 46.8468 69.7632C42.9583 70.4297 38.9753 70.3013 35.1376 69.3859C24.4357 66.8446 14.2126 58.0826 12.1843 45.888C10.7067 36.9698 13.9375 28.1763 19.1409 22.4432C23.6531 17.4677 30.0154 14.3035 37.1848 13.1936C44.3236 11.8512 51.3657 12.7179 57.2717 15.9076C64.0907 19.5802 70.0473 26.799 71.6137 35.702C73.7574 47.8768 67.0234 59.5451 57.7648 65.5077Z"
        fill="#EBF0F5"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M62.0132 162.663C64.2489 161.557 70.5229 158.453 70.6486 153.572C70.7076 151.279 69.3835 149.595 69.2275 149.402C64.7208 143.835 49.3347 145.558 44.3532 153.489C43.8396 154.307 42.3973 156.455 42.9042 158.922C43.5729 162.176 47.1515 163.77 48.1011 164.192C53.7971 166.728 59.7996 163.759 62.0132 162.663Z"
        fill="#C5D2E0"
      />
      <path
        d="M61.8246 131.659C62.8719 131.909 63.8531 132.382 64.7015 133.046C67.3792 135.191 68.3153 139.255 67.797 141.536C65.9274 149.77 40.5594 154.769 34.5982 146.703C33.6301 145.393 32.5483 142.892 32.8239 140.129C33.2112 136.248 36.0891 133.744 37.0008 132.951C43.9439 126.91 54.6615 129.757 61.8246 131.659Z"
        fill="#C5D2E0"
      />
      <path
        d="M59.7889 116.446C62.2626 117.593 65.5427 119.171 66.4457 122.386C67.197 125.061 65.932 127.551 65.6931 127.998C60.8557 137.054 34.3641 136.676 30.1648 127.88C29.6235 126.726 29.4219 125.442 29.5835 124.178C30.3931 118.406 39.3523 115.659 41.3522 115.046C42.0641 114.827 50.8145 112.286 59.7889 116.446Z"
        fill="#C5D2E0"
      />
      <path
        d="M46.338 98.0719C47.5163 97.7334 54.5656 95.83 60.446 100.035C61.0997 100.503 67.1939 104.983 66.1056 110.185C65.1188 114.904 58.9319 116.811 57.9327 117.119C47.9607 120.193 33.5028 113.418 32.6416 104.494C32.5591 103.614 32.6043 102.727 32.7758 101.86C33.0365 100.595 33.4974 99.3799 34.1411 98.2604"
        fill="#C5D2E0"
      />
      <path
        d="M62.0215 162.663C67.1987 160.771 70.5873 157.249 70.657 153.571C70.6626 153.27 70.6722 151.176 69.2359 149.401C68.0028 147.878 65.9553 146.9 63.5295 146.446"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M61.8304 131.658C62.8771 131.91 63.8579 132.382 64.7073 133.045C67.4837 135.242 68.3056 139.323 67.8028 141.535C66.4707 147.402 53.2106 151.626 43.5391 150.653"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M59.7942 116.444C60.5242 116.675 65.33 118.271 66.451 122.384C67.17 125.022 66.0195 127.395 65.6986 127.996C61.6453 135.585 42.3888 136.549 33.7822 131.488"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M63.0373 102.308C64.8298 104.198 66.7647 107.061 66.1114 110.183C65.1246 114.902 58.9377 116.81 57.9384 117.118C51.5329 119.092 43.2764 117.003 37.9619 112.891"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M85.1784 109.747C82.2514 107.091 77.5193 103.605 71.0914 102.364C64.9556 101.181 62.9285 103.134 52.7226 102.783C45.6091 102.538 37.5586 102.166 35.2418 97.4747C34.0762 95.1141 34.8228 92.5999 34.8894 92.4003C37.6998 84.0273 57.0504 82.433 68.1136 83.0219C73.187 83.2919 87.871 84.2488 102.484 93.6157C111.775 99.5718 112.875 103.696 122.913 109.266"
        fill="#C5D2E0"
      />
      <path
        d="M85.1844 109.746C82.2573 107.09 77.5251 103.604 71.0973 102.364C64.9613 101.18 62.9344 103.134 52.7285 102.782C45.6151 102.537 37.5644 102.165 35.2476 97.4738C34.0821 95.1132 34.8286 92.599 34.8953 92.3994"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

ImageError404.defaultProps = {
  width: 172,
  height: 200,
};

ImageError404.propTypes = {
  width: number,
  height: number,
};

export default ImageError404;
