import { authStorage } from '@ampli/utils';

const passwordGuard = async (to, from, next) => {
  try {
    const data = await authStorage.getItem('loginInfo');
    if (!data) throw new Error('Not allowed.');

    const isResetPasswordFlow = data.forcePasswordReset;
    const isValidStatus = data.validation?.status === 'VALID';
    if (isResetPasswordFlow && isValidStatus) {
      next.redirect('/login/crie-uma-senha');
    } else if (!isResetPasswordFlow && data.phoneNumberVerification) {
      next.redirect('/login/validar-conta');
    } else if (!isResetPasswordFlow) {
      next();
    } else {
      throw new Error('Not allowed.');
    }
  } catch (error) {
    console.warn(error);
    await authStorage.removeItem('loginInfo');
    next.redirect('/login');
  }
};

export default passwordGuard;
