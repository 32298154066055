import styled from '@emotion/styled';

export const Text = styled.p`
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
  margin: ${({ margin }) => margin};
  cursor: ${({ cursor }) => cursor};
  overflow: hidden;
  text-overflow: ellipsis;
`;
