import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import * as Yup from 'yup';
const isValidRecipient = () => ({
  name: 'isValidRecipient',
  test: value => {
    const isNumber = /^\d+$/.test(value.replace(/[^\d]+/g, ''));
    return isNumber ? Yup.string().matches(/\(\d\d\)\s9\d{4}-\d{4}/, 'Telefone inválido.') : Yup.string().email('E-mail inválido.');
  }
});
export const contactPermissionSchema = Yup.lazy(obj => Yup.object(Object.entries(obj).reduce((a, _ref) => {
  let [key] = _ref;
  return _objectSpread(_objectSpread({}, a), {}, {
    [key]: Yup.object().shape({
      recipient: Yup.string().test(isValidRecipient()),
      channel: Yup.string().oneOf(['EMAIL', 'SMS', 'WHATSAPP']),
      enabled: Yup.boolean(),
      context: Yup.string().oneOf(['NOTIFICATION_CONTEXT_MARKETING', 'NOTIFICATION_CONTEXT_CRITICAL', 'NOTIFICATION_CONTEXT_INTERNAL'])
    })
  });
}, {})));