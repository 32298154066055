import { dataStorage, omit, isEmpty } from '@ampli/utils';
const STORAGE_QUERY_STRINGS_KEY = 'queryStrings';

/**
 * Save Query Strings from the URL Params in cache to be accessed later
 * @param {string[]} [ignoredKeys=default: Default Blocklist] - List of URL Params to be ignored in the saving process
 */
async function saveQueryStringsOnIndexDB(queryStringFromUrl) {
  let ignoredKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  function generateQueryStringsObject(queryString) {
    const urlSearchParams = new URLSearchParams(queryString);
    return Object.fromEntries(urlSearchParams);
  }
  const queryStrings = generateQueryStringsObject(queryStringFromUrl);
  if (!isEmpty(queryStrings)) {
    const filteredQueryStrings = ignoredKeys.length ? omit(queryStrings, ignoredKeys) : queryStrings;
    if (!isEmpty(filteredQueryStrings)) return dataStorage.setItem(STORAGE_QUERY_STRINGS_KEY, filteredQueryStrings);
  }
  return null;
}

/**
 * Get Query Strings Object from cache
 * @returns {Object} Query Strings from cache in Object format
 */
async function getQueryStringsFromIndexDB() {
  const queryStrings = await dataStorage.getItem(STORAGE_QUERY_STRINGS_KEY);
  return isEmpty(queryStrings) ? {} : queryStrings;
}

/**
 * Clear Query Strings key and all it's contents from cache
 */
async function clearQueryStringsFromIndexDB() {
  return dataStorage.removeItem(STORAGE_QUERY_STRINGS_KEY);
}
export default {
  getQueryStringsFromCache: getQueryStringsFromIndexDB,
  saveQueryStringsToCache: saveQueryStringsOnIndexDB,
  clearQueryStringsFromCache: clearQueryStringsFromIndexDB
};