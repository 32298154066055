import styled from '@emotion/styled';
import { convertPXToREM, mediaQuery } from '@sofia/ui';

export const Wrapper = styled.div`
  ${mediaQuery('mobile')} {
    & button {
      margin-bottom: 0px;
    }
  }
`;

export const CopilotButton = styled.button`
  height: 56px;
  border: none;
  outline: none;
  background: radial-gradient(73.25% 100% at 100% 0%, #7389ff 0%, #004ecc 100%);
  box-shadow: 0px 4px 16px 0px ${({ theme }) => theme.colors.text.black}1f;
  color: ${({ theme }) => theme.colors.icon.background};
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: ${convertPXToREM(14)};
  letter-spacing: -5%;
  line-height: 140%;
  overflow: hidden;
  transition: padding-right cubic-bezier(0.45, 1.45, 0.8, 1) 0.3s;
  border-radius: 8px;
  height: 40px;

  ${mediaQuery('desktop-max')} {
    width: 40px;
    padding: 0;
  }

  padding-right: 16px;

  ${mediaQuery('desktop-max')} {
    bottom: 60px;
  }

  *.MuiPaper-root {
    padding-top: 48px !important;
  }
`;

export const AvatarEdu = styled.img`
  margin-bottom: -1px;
  width: 40px;
  height: 40px;
`;

export const AvatarCommunicationEdu = styled.img`
  position: absolute;
  object-fit: cover;
  object-position: top;
  top: -7px;
  width: 119px;
  height: 104px;
`;
