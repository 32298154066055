import * as types from './action-types';

const resetState = (_, { payload }) => payload;

const createSimpleReducer =
  (field) =>
  (state, { payload }) => ({
    ...state,
    [field]: payload,
  });

const setOnboarding = createSimpleReducer('onboarding');
const setOpenMenu = createSimpleReducer('openMenu');
const setOpenCustomization = createSimpleReducer('openCustomization');
const setOpenNotes = createSimpleReducer('openNotes');
const setOpenCommunitySection = createSimpleReducer('openCommunitySection');
const setOpenTalkTutorFrame = createSimpleReducer('openTalkTutorFrame');
const setFontSize = createSimpleReducer('fontSize');
const setDebugBlockedFeatures = createSimpleReducer('debugBlockedFeatures');
const setShowInauguralSubjectTutorial = createSimpleReducer(
  'showInauguralSubjectTutorial'
);
const setCourseId = createSimpleReducer('selectedCourseId');
const setCourseVersionId = createSimpleReducer('selectedCourseVersionId');
const setCourseEnrollmentId = createSimpleReducer('selectedCourseEnrollmentId');
const setCourseEnrollmentType = createSimpleReducer(
  'selectedCourseEnrollmentType'
);
const setAccessInternshipSubject = createSimpleReducer(
  'accessInternshipSubject'
);
const setHasShownNotesOnboarding = createSimpleReducer(
  'hasShownNotesOnboarding'
);
const setHasShownSectionEduCommunication = createSimpleReducer(
  'hasShownSectionEduCommunication'
);
const setReadingRuler = createSimpleReducer('isReadingRulerEnabled');
const setVideoTime = (state, { payload }) => ({
  ...state,
  videoTime: {
    ...state.videoTime,
    ...payload,
  },
});
const setPersistData = (state, { payload }) => ({
  ...state,
  persistData: {
    ...state.persistData,
    ...payload,
  },
});

export default {
  [types.RESET_STATE]: resetState,
  [types.SET_ONBOARDING]: setOnboarding,
  [types.SET_OPEN_MENU]: setOpenMenu,
  [types.SET_OPEN_CUSTOMIZATION]: setOpenCustomization,
  [types.SET_OPEN_NOTES]: setOpenNotes,
  [types.SET_FONT_SIZE]: setFontSize,
  [types.SET_VIDEO_TIME]: setVideoTime,
  [types.SET_DEBUG_BLOCKED_FEATURES]: setDebugBlockedFeatures,
  [types.SET_SHOW_INAUGURAL_SUBJECT_TUTORIAL]: setShowInauguralSubjectTutorial,
  [types.SET_COURSE_ENROLLMENT_ID]: setCourseEnrollmentId,
  [types.SET_COURSE_ID]: setCourseId,
  [types.SET_COURSE_VERSION_ID]: setCourseVersionId,
  [types.SET_COURSE_ENROLLMENT_TYPE]: setCourseEnrollmentType,
  [types.SET_PERSIST_DATA]: setPersistData,
  [types.SET_ACCESS_INTERNSHIP_SUBJECT]: setAccessInternshipSubject,
  [types.SET_HAS_SHOWN_NOTES_ONBOARDING]: setHasShownNotesOnboarding,
  [types.SET_HAS_SHOWN_SECTION_EDU_COMMUNICATION]:
    setHasShownSectionEduCommunication,
  [types.SET_OPEN_COMMUNITY_SECTION]: setOpenCommunitySection,
  [types.SET_OPEN_TALK_TUTOR_FRAME]: setOpenTalkTutorFrame,
  [types.SET_READING_RULER]: setReadingRuler,
};
