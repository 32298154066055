import styled from '@emotion/styled';
export const Wrapper = styled.div`
  background: ${_ref => {
  let {
    background
  } = _ref;
  return background;
}};
  cursor: ${_ref2 => {
  let {
    noPointer
  } = _ref2;
  return noPointer ? 'auto' : 'pointer';
}};
  border-radius: 8px;
  grid-column: ${_ref3 => {
  let {
    grid
  } = _ref3;
  return grid;
}};
  min-width: ${_ref4 => {
  let {
    minWidth
  } = _ref4;
  return minWidth;
}};
  min-height: ${_ref5 => {
  let {
    minHeight
  } = _ref5;
  return minHeight;
}};
  width: ${_ref6 => {
  let {
    width
  } = _ref6;
  return width;
}};
  max-width: ${_ref7 => {
  let {
    maxWidth
  } = _ref7;
  return maxWidth;
}};
  border: ${_ref8 => {
  let {
    borderSize
  } = _ref8;
  return borderSize;
}} solid
    ${_ref9 => {
  let {
    borderColor
  } = _ref9;
  return borderColor;
}};
  border-style: ${_ref10 => {
  let {
    borderType
  } = _ref10;
  return borderType;
}};
  box-shadow: ${_ref11 => {
  let {
    boxShadow
  } = _ref11;
  return boxShadow ? `0px 2px 7px rgb(0 0 0 / 14%);` : 'none';
}};
  opacity: ${_ref12 => {
  let {
    disabled
  } = _ref12;
  return disabled && 0.5;
}};
  padding: ${_ref13 => {
  let {
    padding
  } = _ref13;
  return padding || 0;
}};
  margin: ${_ref14 => {
  let {
    margin
  } = _ref14;
  return margin || 0;
}};
  float: ${_ref15 => {
  let {
    float
  } = _ref15;
  return float;
}};
  flex: ${_ref16 => {
  let {
    flex
  } = _ref16;
  return flex;
}};

  ${_ref17 => {
  let {
    onClick,
    hover,
    hoverBorderColor,
    hasHoverIcon
  } = _ref17;
  return onClick && `
    cursor: pointer;
    &:hover {
      background-color: ${hover};
      border-color: ${hoverBorderColor};

      ${hasHoverIcon && `
        svg path {
          fill: ${hoverBorderColor};
        }
        `}
    }
  `;
}}

  ${_ref18 => {
  let {
    noHover
  } = _ref18;
  return noHover && `border: none;`;
}}

  &:focus {
    border-width: ${_ref19 => {
  let {
    borderWidth
  } = _ref19;
  return borderWidth;
}};
    border-style: solid;
    border-color: ${_ref20 => {
  let {
    focusColor
  } = _ref20;
  return focusColor;
}};
    outline: none;
    background-color: #f3f6f9;
  }

  @media (max-width: 769px) {
    min-width: ${_ref21 => {
  let {
    minWidthMobile
  } = _ref21;
  return minWidthMobile;
}};
    grid-column: ${_ref22 => {
  let {
    mobileGrid
  } = _ref22;
  return mobileGrid;
}};
    float: unset;
  }
`;