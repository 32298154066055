import { isProduction } from '../config';
import { EXAM_TIME_MINUTES, EXAM_TIME_LEFT_MINUTES } from '../constants';

const useExamTimes = (exam) => {
  const maxDurationInMinutes =
    (isProduction && exam?.maxDurationInMinutes) || EXAM_TIME_MINUTES;
  const timeRunningOutMinutes = maxDurationInMinutes - EXAM_TIME_LEFT_MINUTES;

  return {
    maxDurationInMinutes,
    timeRunningOutMinutes,
  };
};

export default useExamTimes;
