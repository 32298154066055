import React from 'react';
import * as Styled from './feedback-sent-styles';
import { useTheme } from '@emotion/react';
import { FeedbackSentProps } from '../../../../types';

const FeedbackSent = ({
  onClickReviewSent,
}: FeedbackSentProps): React.ReactElement => {
  const theme = useTheme();
  return (
    <Styled.ContainerFeedback>
      <Styled.Text color={theme.colors.text.lightBlack}>
        Sua avaliação foi enviada com sucesso!
      </Styled.Text>
      <Styled.ButtonAnotherReview
        onClick={onClickReviewSent}
        /* styled props */
        color={theme.colors.text.primary}
      >
        Enviar outra avaliação
      </Styled.ButtonAnotherReview>
    </Styled.ContainerFeedback>
  );
};

export default FeedbackSent;
