import React from 'react';

import HistoricSectionEmptyState from './historic-section-empty-state';

import { useBreakpoint } from '@ampli/hooks';
import { useHistory } from 'react-router-dom';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { Tooltip } from '@material-ui/core';

import {
  emptyStudentConversationMock,
  emptyTutorConversationMock,
  filtersLabels,
} from './historic-section.constants';

import { ConversationChatSection } from '../conversation-chat';
import { useHistoricSection } from './historic-section.hook';
import { ConversationCard } from '../../components';
import { LoadingSpinner } from '../../../../../../../../components';
import { usePtkFrame } from '../../contexts';
import { Filters } from './components';

import * as Styled from './historic-section.styles';

const HistoricSection: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const {
    isHistoricChatMobile,
    activeFilterChip,
    activeFilterTab,
    onFilterChange,
    conversations,
    historicData,
    refetching,
  } = useHistoricSection();

  const { conversation, dispatch } = usePtkFrame();

  const showConversationChatView =
    isDesktop || (!isDesktop && conversation?.id);

  const showEmptyState = !refetching && !conversations?.length;

  const showHeaders = isDesktop || (!isDesktop && !conversation?.id);

  return (
    <Styled.Main>
      <Styled.Wrapper>
        <Styled.TopBarContainer>
          <Typography size={20} weight={700} margin="0px 8px 0px 0px">
            Falar com o tutor
          </Typography>

          <Tooltip arrow title={'Fechar aula'}>
            <Styled.IconBtn
              data-testid="close-section"
              id="close-class"
              icon={<Styled.IconX />}
              size="xx-large"
              color="text-dark"
              aria-label={'Fechar aula'}
              onClick={() => {
                history.goBack();
                dispatch({ conversation: null });
              }}
            />
          </Tooltip>
        </Styled.TopBarContainer>

        <Styled.HeaderWrapper>
          {showHeaders ? (
            <Filters
              type="tab"
              filters={['STUDENT', 'TUTOR']}
              activeFilter={activeFilterTab}
              onFilterChange={onFilterChange}
              isRefetching={historicData?.isRefetching}
            />
          ) : null}

          {showHeaders ? (
            <Filters
              type="chip"
              filters={['ALL', 'NOT_READ']}
              activeFilter={activeFilterChip}
              onFilterChange={onFilterChange}
              isRefetching={historicData?.isRefetching}
            />
          ) : null}

          {showHeaders ? (
            <Styled.Flex justify="start" margin="0px 0px 24px">
              <Typography size={20} weight={700} margin="0px 8px 0px 0px">
                {filtersLabels[activeFilterChip]}
              </Typography>

              {refetching ? (
                <LoadingSpinner
                  color={theme.colors.text.primary}
                  height={18}
                  width={18}
                />
              ) : null}
            </Styled.Flex>
          ) : null}
        </Styled.HeaderWrapper>
      </Styled.Wrapper>

      <Styled.ContentWrapper isHistoricChatMobile={isHistoricChatMobile}>
        {!isDesktop && conversation?.id ? null : (
          <Styled.ConversationList>
            {showEmptyState ? (
              <ConversationCard
                conversation={
                  activeFilterTab === 'STUDENT'
                    ? emptyStudentConversationMock
                    : emptyTutorConversationMock
                }
              />
            ) : null}

            {conversations?.map((data) => (
              <ConversationCard key={data.id} conversation={data} />
            ))}
          </Styled.ConversationList>
        )}

        {showEmptyState ? (
          <HistoricSectionEmptyState activeFilterTab={activeFilterTab} />
        ) : showConversationChatView ? (
          <Styled.ConversationView
            paddingRight={!isDesktop && conversation?.id ? 0 : 120}
            paddingLeft={!isDesktop && conversation?.id ? 0 : 24}
          >
            <ConversationChatSection />
          </Styled.ConversationView>
        ) : null}
      </Styled.ContentWrapper>
    </Styled.Main>
  );
};

export default HistoricSection;
