import { PaymentWarningType } from '../constants';

export const getInvoiceHomeModel = {
  data: {
    invoiceSections: [
      {
        title: 'Faturas em aberto',
        invoices: [
          {
            id: '42',
            status: 'PAID',
            shortTitle: 'Invoice 5/7',
            title: 'Sample Invoice A',
            formattedAmount: '$ 800.00',
            currentDueDate: '2023-08-31T23:59:59-03:00',
            details: {
              invoiceAlert: {
                type: 'REMINDER',
                message: 'Please ensure timely payment for continued access.',
              },
              paymentAlert: {
                type: 'URGENT',
                message:
                  'Your account is suspended due to pending payment. Contact support for assistance.',
              },
              info: 'A 5% late fee is applicable for payments made after the due date. An additional 0.5% accrues daily.',
              notes: [
                'Additional charge for late payment: Semester 2 - Invoice 4/6',
                'Late payment fees for Semester 2 - Invoice 5/6',
              ],
              items: [
                {
                  title: 'Tuition Fee',
                  formattedAmount: '$ 400.00',
                  subItems: [],
                },
                {
                  title: 'Additional Charges',
                  formattedAmount: '$ 100.00',
                  subItems: [
                    {
                      title: 'Late Payment',
                      formattedAmount: '$ 30.00',
                    },
                    {
                      title: 'Penalty',
                      formattedAmount: '$ 5.00',
                    },
                  ],
                },
                {
                  title: 'Miscellaneous',
                  formattedAmount: '$ 300.00',
                  subItems: [
                    {
                      title: 'Fine',
                      formattedAmount: '$ 50.00',
                    },
                    {
                      title: 'Interest',
                      formattedAmount: '$ 7.00',
                    },
                  ],
                },
              ],
              allowGetInvoiceDocument: true,
              allowGetInvoiceTaxDocument: true,
            },
            olderInvoicePaymentSuggestion:
              'Consider settling outstanding dues to avoid penalties.',
            externalPaymentLink: 'https://example-payments.com/invoice/42',
            block: 'For inquiries, please contact billing support.',
          },
          {
            id: '3',
            status: 'PENDING',
            shortTitle: 'Invoice 5/10',
            title: 'Mock Invoice 3',
            formattedAmount: '$ 500.00',
            currentDueDate: '2023-05-20T23:59:59-03:00',
            details: {
              warning: {
                type: 'IMPORTANT',
                message:
                  'Please make sure to pay your invoice on time to avoid any late fees.',
              },
              info: {
                type: 'INFO',
                message: 'This is a test invoice for mock purposes 3.',
              },
              notes: ['This is another mock invoice for testing purposes 3.'],
              items: [
                {
                  title: 'Service 3',
                  formattedAmount: '$ 500.00',
                  subItems: [],
                },
              ],
              allowGetInvoiceDocument: true,
              allowGetInvoiceTaxDocument: true,
            },
            olderInvoicePaymentSuggestion:
              'Pay your older invoices to avoid accumulation of dues.',
            externalPaymentLink: 'https://example.com/payment-link',
            block:
              'Your account will be blocked if the invoice is not paid by the due date.',
          },
          {
            id: '5',
            status: 'PAID',
            shortTitle: 'Invoice 1/3',
            title: 'Test Invoice 1',
            formattedAmount: '$ 300.00',
            currentDueDate: '2023-06-01T23:59:59-03:00',
            details: {
              warning: 'Payment Received',
              info: 'Invoice paid successfully.',
              notes: ['This is a test invoice for mock purposes.'],
              items: [
                {
                  title: 'Test Service 1',
                  formattedAmount: '$ 300.00',
                  subItems: [],
                },
              ],
              allowGetInvoiceDocument: true,
              allowGetInvoiceTaxDocument: true,
            },
            olderInvoicePaymentSuggestion:
              'Pay before due date to avoid late fee.',
            externalPaymentLink: 'https://example.com/pay/invoice/5',
            block: null,
          },
          {
            id: '6',
            status: 'OVERDUE',
            shortTitle: 'Invoice 2/3',
            title: 'Test Invoice 2',
            formattedAmount: '$ 150.00',
            currentDueDate: '2023-06-15T23:59:59-03:00',
            details: {
              warning: 'Payment Overdue',
              info: 'Invoice payment is overdue.',
              notes: ['This is another test invoice for mock purposes.'],
              items: [
                {
                  title: 'Test Service 2',
                  formattedAmount: '$ 150.00',
                  subItems: [],
                },
              ],
              allowGetInvoiceDocument: false,
              allowGetInvoiceTaxDocument: false,
            },
            olderInvoicePaymentSuggestion:
              'Pay now to avoid further penalties.',
            externalPaymentLink: null,
            block: {
              type: 'PAYMENT',
              reason: 'Invoice blocked due to non-payment.',
            },
          },
          {
            id: '10',
            status: 'PAID',
            shortTitle: 'Matrícula',
            title: 'Fatura de Exemplo 10',
            formattedAmount: 'R$ 800,00',
            currentDueDate: '2023-06-15T23:59:59-03:00',
            details: {
              warning: 'Pagamento confirmado com sucesso.',
              info: 'Confira os detalhes da fatura abaixo.',
              notes: ['Esta é uma fatura de exemplo para fins de teste.'],
              items: [
                {
                  title: 'Taxa de Matrícula',
                  formattedAmount: 'R$ 800,00',
                  subItems: [],
                },
              ],
              allowGetInvoiceDocument: true,
              allowGetInvoiceTaxDocument: true,
            },
            olderInvoicePaymentSuggestion:
              'Para pagamentos em atraso, entre em contato com o setor financeiro.',
            externalPaymentLink: 'https://www.exemplo.com.br/pagamento',
            block: {
              type: 'SPECIFIC',
              reason:
                'Fatura disponível para pagamento. Acesse o link para realizar o pagamento.',
            },
          },
        ],
      },
      {
        title: 'Negociação em aberto',
        invoices: [
          {
            id: '11',
            status: 'OPEN',
            shortTitle: 'Fatura 3/6',
            title: 'Fatura de Teste 1',
            formattedAmount: 'R$ 500,00',
            currentDueDate: '2023-03-31T23:59:59-03:00',
            details: {
              warning: null,
              info: null,
              notes: ['Esta é uma fatura de teste para fins de mock. 1'],
              items: [
                {
                  title: 'Serviço de Teste',
                  formattedAmount: 'R$ 500,00',
                  subItems: [],
                },
              ],
              allowGetInvoiceDocument: true,
              allowGetInvoiceTaxDocument: true,
            },
            olderInvoicePaymentSuggestion: null,
            externalPaymentLink: null,
            block: null,
          },
        ],
      },
    ],
    block: null,
    billingDay: '12',
    alert: {
      type: PaymentWarningType.warning,
      message:
        'Você não realizou o pagamento da sua rematrícula e seu acesso das disciplinas do próximo semestre foi bloqueado. Qualquer dúvida entre em contato com o atendimento.',
    },
  },
  loading: false,
};

export const getInvoiceHomeModelBlockedGeneric = {
  data: {
    invoiceSections: [
      {
        title: 'Faturas em atraso BloqueadoGenérico',
        invoices: [
          {
            id: '2',
            status: 'OVERDUE',
            shortTitle: 'Fatura 3/6',
            title: 'Fatura de Exemplo 2 BloqueadoGenérico',
            formattedAmount: 'R$ 750,00',
            currentDueDate: '2023-04-15T23:59:59-03:00',
            details: {
              warning: null,
              info: null,
              notes: ['Esta é uma fatura de exemplo para fins de teste.'],
              items: [
                {
                  title: 'Serviço de Exemplo 2',
                  formattedAmount: 'R$ 750,00',
                  subItems: [],
                },
              ],
              allowGetInvoiceDocument: true,
              allowGetInvoiceTaxDocument: true,
            },
            olderInvoicePaymentSuggestion: null,
            externalPaymentLink: null,
            block: null,
          },
        ],
      },
    ],
    block: {
      type: 'GENERIC',
      reason: 'Fatura temporariamente indisponível para atualização, aguarde.',
    },
    billingDay: null,
  },
};

export const getInvoiceHomeModelBlockedDocuments = {
  data: {
    invoiceSections: [
      {
        title: 'Faturas em aberto BlockedGeneric',
        invoices: [
          {
            id: '1',
            status: 'OVERDUE',
            shortTitle: 'Fatura 2/6',
            title: 'Fatura de Teste 1 BlockedGeneric',
            formattedAmount: 'R$ 500,00',
            currentDueDate: '2023-03-30T23:59:59-03:00',
            details: {
              warning: null,
              info: null,
              notes: ['Esta é uma fatura de teste para fins de mock.'],
              items: [
                {
                  title: 'Serviço de Teste 1',
                  formattedAmount: 'R$ 500,00',
                  subItems: [],
                },
              ],
              allowGetInvoiceDocument: true,
              allowGetInvoiceTaxDocument: true,
            },
            olderInvoicePaymentSuggestion: null,
            externalPaymentLink: null,
            block: null,
          },
        ],
      },
    ],
    block: {
      type: 'DOCUMENTS',
      reason: 'Fatura temporariamente indisponível para atualização, aguarde.',
    },
    billingDay: null,
  },
};

export const getInvoiceHomeModelWithoutInvoices = {
  data: {
    invoiceSections: [],
    block: null,
    billingDay: null,
  },
};

export const getFinancialRoles = {
  data: [
    'PAYMENT_BOLETO',
    'PAYMENT_CREDIT_CARD',
    'NEW_FINANCIAL',
    'CHANGE_BILLING_DAY',
    'INCOME_TAX',
    'APPLY_COUPON',
    'SAVE_CREDIT_CARD',
    'CREDIT_CARD_RECURRING',
  ],
};

export const getPaymentCreditCardPublicKey = {
  data: {
    id: '12345',
    publicKey: 'abcde12345',
    paymentClient: 'MUNDIPAGG',
  },
};

export const getAddress = {
  data: {
    street: 'Rua Exemplo',
    number: '123',
    complement: 'Apto 456',
    neighbourhood: 'Bairro Exemplo',
    cityName: 'São Paulo',
    stateAcronym: 'SP',
  },
};

export const payInvoiceByBoleto = {
  data: {
    payInvoiceByBoleto: {
      barcode: '23793.38222 50025.54890 90010.05405 6 82450000096777',
      barcodeImage:
        'https://static.vecteezy.com/system/resources/previews/001/199/358/non_2x/barcode-png.png',
    },
  },
};

export const getInvoiceBoletoPdf = {
  data: {
    getInvoiceBoletoPdf: {
      url: 'https://exemplo.com/boleto/1234567890.pdf',
    },
  },
};

export const payInvoiceByCreditCard = {
  data: {
    payInvoiceByCreditCard: null,
  },
};

export const payInvoiceByCreditCardResult = {
  data: {
    payInvoiceByCreditCardResult: 'PENDING',
  },
};

export const getPaymentStatement = {
  data: [
    {
      id: 'AM123456',
      title: 'Pagamento do curso de matemática',
      paidAt: '2022-03-01T14:30:00Z',
      paidAmount: 'R$ 500,00',
      paymentMethod: 'CREDIT_CARD',
      invoiceId: 'INV123456',
    },
    {
      id: 'DCM654321',
      title: 'Pagamento do curso de história',
      paidAt: '2022-04-01T10:00:00Z',
      paidAmount: 'R$ 750,00',
      paymentMethod: 'BOLETO',
      invoiceId: 'INV654321',
    },
    {
      id: 'DCM65432132',
      title: 'Pagamento do curso de programação',
      paidAt: '2022-04-02T10:00:00Z',
      paidAmount: 'R$ 850,00',
      paymentMethod: 'PIX',
      invoiceId: 'INV654322',
    },
    {
      id: 'DCM6543215',
      title: 'Pagamento do curso de UX/UI',
      paidAt: '2022-05-01T10:00:00Z',
      paidAmount: 'R$ 950,00',
      paymentMethod: 'EXEMPTION',
      invoiceId: 'INV654323',
    },
    {
      id: 'DCM6543216',
      title: 'Pagamento do curso de Sociologia',
      paidAt: '2022-06-01T10:00:00Z',
      paidAmount: 'R$ 950,00',
      paymentMethod: 'OTHER',
      invoiceId: 'INV654324',
    },
  ],
};

export const getInvoice = {
  data: {
    id: '67890',
    status: 'PAID',
    shortTitle: 'Invoice Modified',
    title: 'Course Enrollment Invoice',
    formattedAmount: 'R$ 150,00',
    currentDueDate: '2023-05-15T23:59:59-03:00',
    details: {
      warning: {
        type: 'WARNING',
        message:
          'Lembrete: O pagamento em atraso pode resultar em juros e multas.',
      },
      info: 'Em caso de atraso no pagamento, serão aplicados juros de 2% sobre o valor total da mensalidade + multa de R$ 5,00 por dia de atraso.',
      notes: [
        'Valor adicional referente a reembolso da mensalidade: 2º Semestre - Fatura 1/6',
        'Acréscimos e taxas referentes a mensalidade: 2º Semestre - Fatura 2/6',
      ],
      items: [
        {
          title: 'Taxa de Matrícula',
          formattedAmount: 'R$ 30,00',
          subItems: [],
        },
        {
          title: 'Custos Adicionais',
          formattedAmount: 'R$ 70,00',
          subItems: [
            {
              title: 'Reembolso',
              formattedAmount: 'R$ 25,00',
            },
            {
              title: 'Multa',
              formattedAmount: 'R$ 5,00',
            },
          ],
        },
        {
          title: 'Custos Extras',
          formattedAmount: 'R$ 45,00',
          subItems: [
            {
              title: 'Reembolso',
              formattedAmount: 'R$ 25,00',
            },
            {
              title: 'Taxa de Atraso',
              formattedAmount: 'R$ 5,00',
            },
          ],
        },
      ],
      allowGetInvoiceDocument: true,
      allowGetInvoiceTaxDocument: true,
      associatePayment: [
        {
          id: 'charge_789',
          title: 'Pagamento da mensalidade de abril',
          paidAt: '2022-04-30T10:30:00-03:00',
          paidAmount: 'R$ 1.200,00',
          paymentMethod: 'BOLETO',
          invoiceId: 'invoice_789',
        },
        {
          id: 'charge_790',
          title: 'Pagamento da mensalidade de abril',
          paidAt: '2022-04-30T10:30:00-03:00',
          paidAmount: 'R$ 1.200,00',
          paymentMethod: 'BOLETO',
          invoiceId: 'invoice_789',
        },
      ],
    },
    olderInvoicePaymentSuggestion: 'Pague em dia e evite juros e multas.',
    externalPaymentLink: null,
  },
};

export const invoiceCoupon = {
  data: {
    couponName: 'Desconto de Aniversário',
    instalmentAmount: 'R$ 200,00',
    totalInstalmentAmount: 'R$ 2.000,00',
    startDate: '01/01/2023',
    endDate: '01/12/2023',
    couponAmount: 'R$ 100,00',
    numberOfInstalmentsWithCoupon: 10,
    couponAppliedDate: '01/05/2023',
  },
};

export const changeCycleStatus = {
  data: {
    lastChangeDate: '2023-05-10T12:00:00Z',
    status: 'FINISHED',
    canRequestChangeCycle: false,
    order: {
      id: '123',
      ticketNumber: 'ABC123',
      requestDate: '2023-05-01T09:00:00Z',
      documentValidity: '2023-06-01T00:00:00Z',
      orderUrl: 'https://example.com/orders/1234',
      status: 'PROCESSING',
      subject: 'Example order',
      description: 'This is an example order',
      serviceOrigin: 'Example service',
      serviceForecast: '2023-05-31T12:00:00Z',
      history: [
        {
          description: 'Order created',
          athenasCreatedDate: '2023-05-01T09:00:00Z',
          url: 'https://example.com/orders/123',
          status: 'REQUESTED',
        },
        {
          description: 'Order processing',
          athenasCreatedDate: '2023-05-10T12:00:00Z',
          url: 'https://example.com/orders/123',
          status: 'PROCESSING',
        },
      ],
    },
  },
};
