import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { clientCreator, ServiceProvider } from '@ampli/services';
import { BreakpointProvider } from '@ampli/hooks';
import { ThemeProvider } from '@emotion/react';
import { authStorage } from '@ampli/utils';

import possibleTypes from './fragment-types.json';
import { basename, graphqlUrl } from './config';
import { Routes } from './routes';
import { StateProvider } from './state';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './assets/styles/notification.css';
import NotificationService from './lib/notification-service';
import * as themes from './themes/theme';
import { StudentProvider } from './contexts/StudentContext';
import { installZendeskBot } from './lib/zendesk';
import { composeProviders, host } from './modules';
import HandleTitle from './utils/handle-title';

const privateClient = clientCreator({
  graphqlUrl: graphqlUrl,
  secure: true,
  cacheSettings: {
    possibleTypes,
  },
});
const publicClient = clientCreator({
  graphqlUrl: graphqlUrl,
  secure: false,
  cacheSettings: {
    possibleTypes,
  },
});

const clients = { publicClient, privateClient };

const setDDUser = async () => {
  const student = await authStorage.getItem('loginInfo');
  if (!student) return;
  window.DD_RUM &&
    window.DD_RUM.setUser({
      id: student.id,
      name: student.firstName,
      email: student.emailMask,
    });
  const ViewAs = await authStorage.getItem('ViewAs').catch(() => false);
  window.DD_RUM && window.DD_RUM.setUserProperty('viewAs', ViewAs);
};

const AppContent = () => {
  const path = window.location.href;

  setDDUser();

  window.errorNotifier = (message) => NotificationService.error(message);

  React.useEffect(() => {
    HandleTitle(host);
  }, []);

  React.useEffect(() => {
    if (!path.includes('/login')) installZendeskBot();
  }, [path]);

  return (
    <>
      <Router basename={basename}>
        <Routes />
      </Router>
      <ReactNotification />
    </>
  );
};

const SofiaProvider = composeProviders([
  BreakpointProvider,
  StudentProvider,
  StateProvider,
]);

const App = () => {
  return (
    <ThemeProvider theme={themes.themeWhiteLabel}>
      <ServiceProvider defaultClient="privateClient" clients={clients}>
        <SofiaProvider>
          <AppContent />
        </SofiaProvider>
      </ServiceProvider>
    </ThemeProvider>
  );
};

export default App;
