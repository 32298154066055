import React from 'react';
import { Typography, SvgTimes } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { Tooltip } from '@material-ui/core';
import { CommunityMenuHeaderProps } from '../../../../types';
import * as Styled from './community-menu.styles';

const CommunityMenuHeader = ({
  onToggleMenuClick,
  title,
}: CommunityMenuHeaderProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <Styled.Header>
      <Typography
        as="h1"
        size={24}
        weight={700}
        color={theme.colors.text.black}
      >
        {title}
      </Typography>

      <Tooltip arrow title={'Fechar ' + title}>
        <Styled.IconBtn
          onClick={onToggleMenuClick}
          aria-label={'Fechar ' + title}
        >
          <SvgTimes />
        </Styled.IconBtn>
      </Tooltip>
    </Styled.Header>
  );
};

export default CommunityMenuHeader;
