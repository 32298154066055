import styled from '@emotion/styled';
import { mediaQuery } from '@ampli/ui';

export const InfoCardsButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;

  > :nth-of-type(n) {
    width: 100%;
    padding: 8px;
  }
  > :first-of-type {
    margin-right: 0px;
  }

  ${mediaQuery('desktop')} {
    flex-direction: row;
    margin-bottom: 80px;
    > :nth-of-type(n) {
      width: 50%;
    }
    > :first-of-type {
      margin-bottom: 0px;
    }
  }
`;
