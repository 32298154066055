import React from 'react';
import { tabs } from '../helper';
import { arrayOf, bool, object, string } from 'prop-types';
import { TabPanel, Accordion } from '@sofia/ui';
import SubjectPresentationTextType from './text/subject-presentation-text-type';
import SubjectPresentationContent from './content/subject-presentation-content';
import SubjectTeacherInfo from './teacher/subject-presentation-teacher-info';
import { useTheme } from '@emotion/react';
import * as Styled from './subject-presentation-panel.styles.ts';

const SubjectPresentationPanel = ({
  subject,
  dictionary,
  pathUrl,
  learningUnitId,
  hideStartButton,
  hideTeacherTab,
  learningUnits,
  teacher,
}) => {
  const theme = useTheme();

  const tabsContent = tabs.map((tab) => {
    if (tab.name === 'teacher' && !hideTeacherTab) {
      return {
        content: (
          <SubjectTeacherInfo
            aria-label="Conheça seu professor, botão"
            teacher={teacher}
          />
        ),
        ...tab,
      };
    } else {
      return {
        content: (
          <Styled.Container>
            {subject?.introduction?.length > 0 ? (
              <Accordion
                title={'INTRODUÇÃO'}
                markerColor={theme.colors.text.primary}
                iconColor={theme.colors.text.primary}
                contentMaxHeight="100rem"
              >
                <SubjectPresentationTextType
                  buttonText={dictionary?.subjectPresentationStartButton}
                  content={subject?.introduction}
                  learningUnitId={learningUnitId}
                  pathUrl={pathUrl}
                  hideStartButton={hideStartButton}
                />
              </Accordion>
            ) : null}
            {learningUnits?.length > 0 ? (
              <Accordion
                title={'CONTEÚDO'}
                markerColor={theme.colors.text.primary}
                iconColor={theme.colors.text.primary}
                contentMaxHeight="100rem"
              >
                <SubjectPresentationContent
                  learningUnits={learningUnits}
                  dictionary={dictionary}
                />
              </Accordion>
            ) : null}
            {subject?.methodologicalProposal?.length > 0 ? (
              <Accordion
                title={'PROPOSTA METODOLÓGICA'}
                markerColor={theme.colors.text.primary}
                iconColor={theme.colors.text.primary}
                contentMaxHeight="100rem"
              >
                <SubjectPresentationTextType
                  content={subject?.methodologicalProposal}
                  learningUnitId={learningUnitId}
                  pathUrl={pathUrl}
                  hideStartButton
                />
              </Accordion>
            ) : null}
            {subject?.bibliography?.length > 0 ? (
              <Accordion
                title={'BIBLIOGRAFIA'}
                markerColor={theme.colors.text.primary}
                iconColor={theme.colors.text.primary}
                contentMaxHeight="100rem"
              >
                <SubjectPresentationTextType
                  content={subject?.bibliography}
                  pathUrl={pathUrl}
                  hideStartButton
                />
              </Accordion>
            ) : null}
          </Styled.Container>
        ),
        ...tab,
      };
    }
  });

  return tabsContent.map((tab, index) => {
    return (
      tab?.content && (
        <TabPanel key={index} tabIndex={-1} id={tab.name}>
          {tab.content}
        </TabPanel>
      )
    );
  });
};

SubjectPresentationPanel.propTypes = {
  subject: object.isRequired,
  dictionary: object,
  pathUrl: string,
  learningUnitId: string,
  hideStartButton: bool,
  hideTeacherTab: bool,
  learningUnits: arrayOf(object.isRequired).isRequired,
};

export default SubjectPresentationPanel;
