import convertDateTimeTZ from './convert-date-time-tz';

const isBetweenDates = (startDate: string, endDate: string): boolean => {
  if (
    convertDateTimeTZ(startDate) <= convertDateTimeTZ(new Date()) &&
    convertDateTimeTZ(endDate) >= convertDateTimeTZ(new Date())
  ) {
    return true;
  }

  return false;
};

export default isBetweenDates;
