import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const ButtonsWrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;

  ${mediaQuery('mobile')} {
    flex-direction: row;
    padding: 24px;
    justify-content: flex-end;
  }
`;
