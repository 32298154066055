import {
  onboardingIds,
  STUDENT_PROFILE_VIEWED,
  STUDENT_PROFILE_VIEWED_DESCRIPTION,
} from '../../../utils';
import { useNewFeature } from './new-feature.hooks';

export const useNewProfileFeature = (studentId: string): void => {
  useNewFeature({
    communicationKey: STUDENT_PROFILE_VIEWED,
    communicationDescription: STUDENT_PROFILE_VIEWED_DESCRIPTION,
    localStorageItem: 'showedNewProfileCommunication',
    tourId: onboardingIds.newFeature.id.newProfile,
    studentId,
  });
};
