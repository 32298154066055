import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';

export const SplitScreen = styled.div`
  background: linear-gradient(90deg, #f3f6f9 50%, #fff 50%);
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ImageSide = styled.div`
  width: 50%;
  margin-right: 92px;
`;

export const TextSide = styled.div`
  width: 100%;
  ${mediaQuery('desktop')} {
    width: 50%;
    margin-left: 92px;
  }
`;
