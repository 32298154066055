import React from 'react';
import NotificationService from '../../../../lib/notification-service';
import PropTypes from 'prop-types';
import { SvgCopy, Typography } from '@sofia/ui';
import Card from '../card/card';
import { useTheme } from '@emotion/react';
import Skeleton from '../../../../components/skeleton/skeleton';

import {
  ContainerCardContent,
  ContainerButton,
  CardCopy,
} from './copy-to-clipboard-styles';
import { pushAnhangueraEvent } from '../../../../lib/ga';
import { analyticsEvents } from '../../constants';
import { ServiceChannelsContent } from '../../../../modules/shared/components/service-channels-content';

export default function CopyToClipboard({
  affiliateId,
  valueCopy,
  loading,
  typeOfStatus,
}) {
  const theme = useTheme();

  const copyToClipboard = (params) => {
    var textField = document.createElement('textarea');
    textField.innerText = params;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    pushAnhangueraEvent('event', analyticsEvents[typeOfStatus]?.copyProtocol);
    NotificationService.success('Protocolo copiado!');
  };

  const formatedAriaLabel = () => {
    return `O número do seu protocolo é, ${valueCopy}. Clique para copiar o protocolo de atendimento e em caso de problemas, entre em contato pelo chat. Copiar Protocolo`;
  };

  return (
    <Card noBorder={true}>
      <ContainerCardContent>
        <Typography
          margin="0"
          color={theme.colors.text.black}
          weight="700"
          size={22}
        >
          O número do seu protocolo é:
        </Typography>

        {loading ? (
          <Skeleton height={50} borderRadius={8} margin={'25px 0 0 0'} />
        ) : (
          <CardCopy
            tabIndex={0}
            role={'button'}
            aria-label={formatedAriaLabel()}
            onClick={() => copyToClipboard(valueCopy)}
            onKeyPress={() => copyToClipboard(valueCopy)}
          >
            <SvgCopy />
            <Typography
              margin="0"
              color={theme.colors.text.black}
              weight="500"
              size={22}
            >
              {valueCopy}
            </Typography>
          </CardCopy>
        )}
        <Typography
          margin="10px 0 0 0"
          color={theme.colors.text.black}
          size={16}
          weight="400"
        >
          Clique acima para copiar o protocolo de atendimento e em caso de
          problemas, entre em contato conosco pelos nossos canais de
          atendimento.
        </Typography>

        <ContainerButton>
          <ServiceChannelsContent
            type="modal"
            affiliateId={affiliateId}
            appearance="white"
          />
        </ContainerButton>

        <Typography
          margin="20px 0 0 0"
          color={theme.colors.text.black}
          size={20}
          weight="400"
          aria-label={'Processando... Seu documento está quase pronto'}
        >
          Processando... Seu documento está quase pronto{' '}
          <span
            aria-label="emoji levemente sorrindo"
            alt="emoji levemente sorrindo"
            role="img"
            tabIndex={-1}
          >
            🙂
          </span>
        </Typography>
      </ContainerCardContent>
    </Card>
  );
}

CopyToClipboard.propTypes = {
  affiliateId: PropTypes.string.isRequired,
  valueCopy: PropTypes.string,
  loading: PropTypes.bool,
  typeOfStatus: PropTypes.string,
};
