import React from 'react';
import { FullscreenLoader } from '@ampli/ui';
import { customFullLoaderByPath } from './helper';

interface ScreenFallbackLoaderProps {
  pathname: string;
}

const ScreenFallbackLoader = ({
  pathname,
}: ScreenFallbackLoaderProps): React.ReactElement => {
  const CustomFullLoader = customFullLoaderByPath.find(({ pathRegex }) =>
    RegExp(pathRegex).test(pathname)
  );

  if (CustomFullLoader) {
    return <CustomFullLoader.component />;
  }

  return <FullscreenLoader data-testid="screen-fallback-full-loader" />;
};

export default ScreenFallbackLoader;
