import { loadScript } from '@ampli/utils';
import { watsonChatbot } from '../config';
import { handleCloseWebWidget } from './zendesk';
import { closeChatbot } from './dialogFlowAssistantChat.js';

let instanceWatsonAssistantChat = null;

const customLanguagePack = {
  agent_agentEndedChatNoName:
    'Você encerrou essa conversa. Caso ainda tenha dúvidas, clique no icone de mensagem acima para voltar ao menu anterior',
  agent_agentEndedChat:
    '{personName} encerrou essa conversa. Caso ainda tenha dúvidas, clique no icone de mensagem acima para voltar ao menu anterior',
  agent_youEndedChat:
    'O agente encerrou essa conversa. Caso ainda tenha dúvidas, clique no icone de mensagem acima para voltar ao menu anterior',
};

export const initializeWatsonAssistantChat = (props = {}) => {
  window.watsonAssistantChatOptions = {
    integrationID: watsonChatbot.integrationID,
    region: watsonChatbot.region,
    serviceInstanceID: watsonChatbot.serviceInstanceID,
    openChatByDefault: props.openChatByDefault || false,
    hideCloseButton: props.hideCloseButton || false,
    showLauncher: props.showLauncher || false,
    onLoad: function (instance) {
      instance
        .updateLocale('pt-br')
        .then(() => {
          instance.updateLanguagePack(customLanguagePack);
          instance.render();
          instanceWatsonAssistantChat = instance;
          if (props.userData) {
            addUserDataWatsonAssistantChat(props.userData);
          }
          return null;
        })
        .catch(console.error);
    },
  };
  return loadScript(
    'https://web-chat.global.assistant.watson.appdomain.cloud/loadWatsonAssistantChat.js'
  );
};

export const openWatsonAssistantChat = () => {
  handleCloseWebWidget();
  closeChatbot();
  instanceWatsonAssistantChat?.openWindow();
};

export const closeWatsonAssistantChat = () => {
  instanceWatsonAssistantChat?.closeWindow();
};

export const resetWatsonAssistantChat = (props = {}) => {
  Object.keys(sessionStorage)
    .filter((item) => {
      return item.includes('IBM_WAC_SESSION');
    })
    .forEach((item) => {
      sessionStorage.removeItem(item);
    });
  instanceWatsonAssistantChat?.destroySession();
  instanceWatsonAssistantChat?.destroy();
  initializeWatsonAssistantChat(props);
};

export const addUserDataWatsonAssistantChat = (userData) => {
  function preSendhandler(event) {
    event.data.context.skills['main skill'].user_defined = userData;
  }

  instanceWatsonAssistantChat?.on({
    type: 'pre:send',
    handler: preSendhandler,
  });
};
