import { useGetMe } from '@ampli/services';
import React, { createContext, useContext } from 'react';
import { IMeProviderReturn, IMeProviderProps } from './me-context.types';

const MeContext = createContext({} as IMeProviderReturn);

const useMeProvider = (): IMeProviderReturn => {
  const getMe = useGetMe();

  return {
    states: {
      getMe,
    },
  };
};

export const MeProvider = ({ children }: IMeProviderProps): JSX.Element => {
  const dataProvider: IMeProviderReturn = useMeProvider();

  return (
    <MeContext.Provider value={dataProvider}>{children}</MeContext.Provider>
  );
};

export const useMeContext = (): IMeProviderReturn => useContext(MeContext);
