import styled from '@emotion/styled';
export const Container = styled.div`
  position: relative;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Input = styled.input`
  all: unset;
  width: ${_ref => {
  let {
    size
  } = _ref;
  return size ? size : '20px';
}};
  height: ${_ref2 => {
  let {
    size
  } = _ref2;
  return size ? size : '20px';
}};
  display: inline-block;
  background: #ffffff;
  border: 2px solid ${_ref3 => {
  let {
    borderColor
  } = _ref3;
  return borderColor;
}};
  border-radius: ${_ref4 => {
  let {
    size
  } = _ref4;
  return size ? size : '20px';
}};

  &:checked {
    ~ span {
      width: calc(100% - 60%);
      height: calc(100% - 60%);
      border-radius: 50%;
      background-color: ${_ref5 => {
  let {
    backgroundColor
  } = _ref5;
  return backgroundColor;
}};
      position: absolute;
      visibility: visible;
      pointer-events: none;
    }
  }
  & ~ span {
    position: absolute;
    visibility: hidden;
  }
`;