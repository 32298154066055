const featureToggleGuard = async (to, from, next) => {
  try {
    const toggle = to.meta?.toggle;

    if (toggle === false) {
      throw new Error('Invalid route.');
    }

    next();
  } catch (error) {
    console.warn(error);
    next.redirect('/');
  }
};

export default featureToggleGuard;
