import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import Skeleton from '../../../../skeleton/skeleton';
import PageLoaderLayout from '../layout/page-loader-layout';
import { SubjectPage } from './notes-page-loader.styles';

const NotesSubjectPageLoader = (): React.ReactElement => {
  const isDesktop = useBreakpoint() === 'desktop';

  return (
    <PageLoaderLayout data-testid="notes-subject-page-loader">
      <SubjectPage.Heading>
        <SubjectPage.HeadingText>
          <Skeleton
            height="28px"
            width="106px"
            margin={`0 0 ${isDesktop ? '8px' : '4px'}`}
            data-testid="notes-subject-page-loader-title-skeleton"
          />
          <Skeleton
            height={isDesktop ? '58px' : '38px'}
            width="80%"
            data-testid="notes-subject-page-loader-subtitle-skeleton"
          />
        </SubjectPage.HeadingText>
        {isDesktop ? (
          <Skeleton
            height="68px"
            width="220px"
            borderRadius="8px"
            data-testid="notes-subject-page-loader-button-skeleton"
          />
        ) : null}
      </SubjectPage.Heading>

      <SubjectPage.ContentWrapper>
        {Array.from({ length: 4 }).map((_, index) => (
          <Skeleton key={index} height="148px" borderRadius="8px" />
        ))}
      </SubjectPage.ContentWrapper>

      {!isDesktop ? (
        <Skeleton
          height="56px"
          width="100%"
          borderRadius="8px"
          data-testid="notes-subject-page-loader-mobile-button-skeleton"
        />
      ) : null}
    </PageLoaderLayout>
  );
};

export default NotesSubjectPageLoader;
