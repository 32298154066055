import React from 'react';
import { Typography, PopUp } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '../../../../../modules';
import { object, string } from 'prop-types';

const MandatoryInternshipSubjectContentMessages = ({
  unableToSendmodal,
  alreadySentModal,
  selectedDocument,
}) => {
  const theme = useTheme();

  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <>
      <PopUp
        hideCloseIcon
        handlePopUp={unableToSendmodal}
        isBottomSheet={!isDesktop}
        header={{
          headerText: 'Você ainda não pode enviar esse documento',
        }}
        footer={{
          buttonText: 'Entendi',
          background: theme.colors.button.primary,
          buttonClick: unableToSendmodal.hide,
        }}
        ariaLabel={`Modal informativo que impossibilita o envio do documento ${selectedDocument} por falta de aprovação do termo de compromisso do estágio`}
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          Enquanto o termo de compromisso não estiver aprovado você não pode
          enviar o documento {selectedDocument}
        </Typography>
      </PopUp>

      <PopUp
        hideCloseIcon
        shouldCloseOnClickOutside={false}
        shouldCloseOnEsc={false}
        handlePopUp={alreadySentModal}
        footer={{
          buttonText: 'Entendi',
          background: theme.colors.button.primary,
          buttonClick: alreadySentModal.hide,
        }}
        isBottomSheet={!isDesktop}
        header={{
          headerText: 'Você já enviou o termo para análise',
        }}
        ariaLabel="Modal informando que já foi enviado o termo de compromisso para análise"
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          Aguarde a conclusão da análise das assinaturas, caso seja reprovado
          você será comunicado via e-mail.
        </Typography>
      </PopUp>
    </>
  );
};

MandatoryInternshipSubjectContentMessages.defaultProps = {};

MandatoryInternshipSubjectContentMessages.propTypes = {
  unableToSendmodal: object,
  selectedDocument: string,
  alreadySentModal: object,
};

export default MandatoryInternshipSubjectContentMessages;
