import React, { useEffect, useRef } from 'react';
import {
  map,
  isArray,
  range,
  string,
  number,
  bool,
  arrayOf,
  func,
  any,
  oneOfType,
} from '@ampli/utils';
import {
  cx,
  css,
  px2grid,
  px2rem,
  Text,
  getColor,
  mediaQuery,
  Grid,
} from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';

const QuestionBullet = ({
  size,
  active = false,
  complete = false,
  onClick,
  bullet,
  darkColor = 'brand-base',
  lightColor = 'brand-lighter',
  ...props
}) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const ref = useRef();
  const resolvedLineHeight = px2rem(size);
  const resolvedSize = px2rem(size - 2);
  const resolvedDarkColor = getColor(darkColor);
  const resolvedLightColor = getColor(lightColor);

  useEffect(() => {
    if (active && ref.current) {
      const parent = ref.current.parentNode;
      const x = ref.current.offsetLeft - parent.offsetWidth / 2;
      parent.scrollTo({ top: 0, left: x, behavior: 'smooth' });
    }
  }, [active]);

  return (
    <Text
      as="button"
      ref={ref}
      size={active && !isDesktop ? 'medium' : 'small'}
      color={complete ? 'white' : 'brand-base'}
      lineHeight={resolvedLineHeight}
      onClick={() => onClick(bullet)}
      aria-label={`${active ? 'Você está na questão' : 'Questão'} ${
        bullet + 1
      }. Status: ${complete ? 'Respondida' : 'Sem resposta'}`}
      className={cx(
        'question-bullet',
        css`
          padding: 0;
          box-sizing: content-box;
          background: transparent;
          border: 1px solid ${resolvedDarkColor};
          border-radius: 50%;
          display: inline-block;
          height: ${resolvedSize};
          margin: 0 auto;
          text-align: center;
          vertical-align: middle;
          width: ${resolvedSize};

          &.active {
            background: ${getColor('brand-lighter')};
            line-height: calc(${resolvedLineHeight} * 1.25);
            height: calc(${resolvedSize} * 1.333);
            width: calc(${resolvedSize} * 1.333);
          }
          &.complete {
            background: ${resolvedDarkColor};
          }
          &.clickable {
            cursor: pointer;
          }

          ${mediaQuery('desktop')} {
            margin: ${px2grid(4)} auto;
            &.active {
              border: 4px solid ${resolvedDarkColor};
              line-height: ${resolvedLineHeight};
              height: ${resolvedSize};
              margin: 0 auto;
              width: ${resolvedSize};
              &.complete {
                border-color: ${resolvedLightColor};
              }
            }
          }
        `,
        active && 'active',
        complete && 'complete',
        onClick && 'clickable',
        props.className
      )}
    >
      <div aria-hidden>{bullet + 1}</div>
    </Text>
  );
};
QuestionBullet.propTypes = {
  className: string,
  bullet: number.isRequired,
  active: bool,
  complete: any,
  size: number,
  onClick: func,
  darkColor: string,
  lightColor: string,
};

const QuestionBulletWrap = (props) => (
  <div
    {...props}
    className={cx(
      'question-bullets',
      css`
        margin-bottom: ${px2grid(-12)};
        overflow-x: scroll;
        text-align: center;
        white-space: nowrap;

        ${mediaQuery('desktop')} {
          margin-bottom: 0;
          overflow-x: unset;
          white-space: initial;
        }
      `,
      props.className
    )}
  />
);
QuestionBulletWrap.propTypes = {
  className: string,
};

const QuestionBullets = ({
  complete,
  active,
  bullets,
  onBulletClick,
  darkColor,
  lightColor,
  ...props
}) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <Grid
      columns={isDesktop ? 5 : bullets.length}
      columnGap={isDesktop ? 16 : 8}
      rowGap={16}
      alignItems="center"
    >
      <QuestionBulletWrap {...props}>
        {map(range(bullets), (bullet) => (
          <QuestionBullet
            key={`question-bullet_${bullet}`}
            size={32}
            complete={isArray(complete) ? complete[bullet] : complete}
            active={active === bullet}
            bullet={bullet}
            onClick={onBulletClick}
            darkColor={isArray(darkColor) ? darkColor[bullet] : darkColor}
            lightColor={isArray(lightColor) ? lightColor[bullet] : lightColor}
          />
        ))}
      </QuestionBulletWrap>
    </Grid>
  );
};

QuestionBullets.propTypes = {
  complete: oneOfType([arrayOf(any), bool]).isRequired,
  active: number.isRequired,
  bullets: number.isRequired,
  onBulletClick: func,
  darkColor: oneOfType([arrayOf(string), string]),
  lightColor: oneOfType([arrayOf(string), string]),
};

export default QuestionBullets;
