import React from 'react';

import useTextStepFormLogic from './text-step-form-logic';

import {
  TitleContainer,
  TextContainer,
  Container,
} from './text-step-form.styles';

import { AutoGrowingTextarea } from '../auto-growing-textarea';
import { ITextStepFormProps } from './text-step-form.types';
import { CharacterCounter } from '../../../../../../../../shared/components/character-counter';

const TextStepForm: React.FC<ITextStepFormProps> = ({
  handleSetIsButtonEnabled,
}: ITextStepFormProps): React.ReactElement => {
  const { states, actions } = useTextStepFormLogic({
    handleSetIsButtonEnabled,
  });

  return (
    <Container>
      <TitleContainer>
        <AutoGrowingTextarea
          textareaRef={states.titleTextareaRef}
          placeholder="Título da publicação"
          fontSize="20px"
          fontWeight={700}
          onChange={actions.handleOnChangeTitle}
          value={states.title}
          maxLength={states.charactersLimitTitle}
        />

        <CharacterCounter
          currentCharactersCount={states.titleCount}
          charactersLimit={states.charactersLimitTitle}
          minCharacters={states.minCharacters}
        />
      </TitleContainer>

      <TextContainer>
        <AutoGrowingTextarea
          textareaRef={states.publicationTextareaRef}
          placeholder="Texto da publicação"
          fontFamily="Roboto Slab"
          fontSize="16px"
          fontWeight={400}
          onChange={actions.handleOnChangePublicationText}
          value={states.publicationText}
          maxLength={states.charactersLimitPublicationText}
        />

        <CharacterCounter
          currentCharactersCount={states.publicationTextCount}
          charactersLimit={states.charactersLimitPublicationText}
          minCharacters={states.minCharacters}
        />
      </TextContainer>
    </Container>
  );
};

export default TextStepForm;
