import { generatePath, useHistory } from 'react-router-dom';
import { array, object, string, bool } from 'prop-types';
import { DateTime } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import {
  BottomSheet,
  useBottomSheet,
  Typography,
  ButtonGhost,
  useModal,
} from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { getButtonAriaLabel } from './helper';
import { useGetState } from '../../../state';
import { routePath } from '../../../routes';
import {
  PopUp,
  PracticalActivitiesRoutesPath,
  AssignmentRoutesPath,
} from '../../../modules';

import {
  ASSIGNMENT,
  ASSIGNMENT_DAYS_TO_CORRECTION,
  EXAM,
  SEMESTRAL_EXAM_ONLINE,
} from '../../../constants';

import SubjectExamsAssignmentsButton from './button/subject-exams-assignments-button';

import React from 'react';

import * as Styled from './subject-exams-assignments-styles';
import ButtonExamFlows from './strategy/button-exam-flows';

const SubjectExamsAndAssignments = ({
  subjectName,
  subjectAssignments,
  subjectAssignmentConfigs,
  subjectEnrollment,
  hasSimulators,
  hasPracticalActivities,
}) => {
  const history = useHistory();

  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();
  const bottomSheet = useBottomSheet();
  const invalidAssignmentModal = useModal();
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';

  const [assignmentTittle, setAssignmentTittle] = React.useState();

  const hasAssignment = React.useMemo(
    () =>
      subjectAssignmentConfigs?.some((config) => config.type === ASSIGNMENT),
    [subjectAssignmentConfigs]
  );

  const hasCompletedExams = Boolean(subjectEnrollment?.finishedExams?.[0]);

  const hasCompletedRecoveryExams = Boolean(
    subjectEnrollment?.finishedExams?.[1]
  );

  const dueDate = {
    assignment: subjectAssignments[ASSIGNMENT]
      ? subjectAssignments[ASSIGNMENT][0].dueDate.minus({
          days: ASSIGNMENT_DAYS_TO_CORRECTION,
        })
      : '',
    exam: subjectAssignments[EXAM]
      ? subjectAssignments[EXAM][0].dueDate.toLocaleString(DateTime.DATE_FULL)
      : '',
    semestral_exam_online: subjectAssignments[SEMESTRAL_EXAM_ONLINE]
      ? subjectAssignments[SEMESTRAL_EXAM_ONLINE][0].dueDate.toLocaleString(
          DateTime.DATE_FULL
        )
      : '',
  };

  const isOnGoing = subjectAssignments[ASSIGNMENT]
    ? subjectAssignments[ASSIGNMENT][0].status === 'ONGOING'
    : false;

  const ariaLabelObj = getButtonAriaLabel({
    hasCompletedExams,
    subjectName,
    hasCompletedRecoveryExams,
    examDueDate: dueDate['exam'],
    semestralDueDate: dueDate['semestral_exam_online'],
    assignmentDueDate: dueDate['assignment'],
  });

  const onAssignmentClick = () => {
    if (
      new Date().setHours(0, 0, 0, 0) >=
        new Date(dueDate['assignment']).setHours(23, 59, 59, 0) ||
      isOnGoing
    ) {
      invalidAssignmentModal.show();
    } else {
      onStartExamsAndAssignments('assignment');
    }
  };

  const onStartExamsAndAssignments = (type) => {
    const pathUseCase = {
      simulators: PracticalActivitiesRoutesPath.simulators,
      assignment: AssignmentRoutesPath.onboardingAssignment,
      exam: routePath.exam,
    };

    history.push({
      pathname: generatePath(pathUseCase[type], {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
        subjectEnrollmentId: subjectEnrollment.id,
      }),
      state: { activitiesPath: null, title: assignmentTittle },
    });
  };

  const onGradeRedirect = () => {
    invalidAssignmentModal.hide();
    history.push(
      generatePath(routePath.grade, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
      })
    );
  };

  const onSimulatorsButtonClick = () =>
    isMobile ? bottomSheet.show() : onStartExamsAndAssignments('simulators');

  const getAssignmentName = (type) =>
    subjectAssignmentConfigs?.find((assignment) => assignment.type === type)
      ?.name;

  return (
    <>
      <Styled.Wrapper>
        {hasAssignment && !hasPracticalActivities ? (
          hasSimulators ? (
            <SubjectExamsAssignmentsButton
              aria-label={ariaLabelObj.simulators}
              buttonType="simulators"
              text="Fazer simuladores"
              description="Faça aulas práticas em um simulador de laboratório virtual"
              onClick={onSimulatorsButtonClick}
            />
          ) : (
            <SubjectExamsAssignmentsButton
              aria-label={ariaLabelObj.assignment}
              buttonType="assignment"
              text="Fazer trabalho"
              description={`Prazo para envio: ${dueDate[
                'assignment'
              ].toLocaleString(DateTime.DATE_SHORT)}`}
              onClick={() => onAssignmentClick()}
            />
          )
        ) : null}
        {subjectEnrollment?.assignments && (
          <ButtonExamFlows
            subjectEnrollment={subjectEnrollment}
            onStartExam={() => onStartExamsAndAssignments('exam')}
            setAssignmentTittle={setAssignmentTittle}
            selectedCourseEnrollmentType={selectedCourseEnrollmentType}
            selectedCourseVersionId={selectedCourseVersionId}
          />
        )}
      </Styled.Wrapper>

      <PopUp
        popUpState={invalidAssignmentModal}
        headerText={
          isOnGoing
            ? 'Você já enviou seu trabalho'
            : 'Prazo de realização encerrado'
        }
        isDesktop={!isMobile}
        {...(isOnGoing
          ? {
              footer: (
                <Styled.ButtonsWrapper>
                  <ButtonGhost
                    hoverBackground="transparent"
                    data-testid="assignment-correction-feedbacks-send-job-modal-button"
                    size={16}
                    padding="12px 8px"
                    color={theme.colors.button.primary}
                    onClick={onGradeRedirect}
                  >
                    Notas e correções
                  </ButtonGhost>
                  <ButtonGhost
                    hoverBackground="transparent"
                    size={16}
                    padding="12px 8px"
                    color={theme.colors.button.primary}
                    onClick={invalidAssignmentModal.hide}
                  >
                    Entendi
                  </ButtonGhost>
                </Styled.ButtonsWrapper>
              ),
            }
          : {})}
      >
        {isOnGoing
          ? 'Aguarde a análise do seu tutor. Quando estiver pronta você poderá acompanhar sua nota em Notas e Correções.'
          : `O período de envio do ${getAssignmentName(ASSIGNMENT)} já acabou.`}
      </PopUp>

      <BottomSheet handleBottomSheet={bottomSheet}>
        <Styled.BottomSheetHeading>
          <Typography size="20" weight="bold">
            Faça suas aulas de laboratório diretamente pelo computador!
          </Typography>
          <Typography margin="16px 0 10px">
            Para garantir a melhor experiência para sua aula prática, os
            Simuladores de Laboratório devem ser acessados através do navegador
            do seu computador.
          </Typography>
        </Styled.BottomSheetHeading>
        <Styled.BottomSheetFooter>
          <ButtonGhost
            onClick={bottomSheet.hide}
            color={theme.colors.button.primary}
          >
            Entendi
          </ButtonGhost>
        </Styled.BottomSheetFooter>
      </BottomSheet>
    </>
  );
};

SubjectExamsAndAssignments.propTypes = {
  subjectName: string,
  subjectAssignmentConfigs: array.isRequired,
  subjectAssignments: object.isRequired,
  subjectEnrollment: object,
  hasSimulators: bool,
  hasPracticalActivities: bool,
};

export default SubjectExamsAndAssignments;
