import NotificationsPopoverHeader from './notifications-popover-header';
import React from 'react';

import { NotificationsFilters } from '../notifications-filters';
import { NotificationDetails } from '../notification-details';
import { NotificationsList } from '../notifications-list';
import { useNotifications } from '../../contexts/notification-context';
import { EmptyState } from '../empty-state';

import * as Styles from './notifications-popover.styles';

interface NotificationsPopoverProps {
  onClose: () => void;
  open: boolean;
}

export const ESC_KEY = 'Escape';

const NotificationsPopover: React.FC<NotificationsPopoverProps> = ({
  onClose,
  open,
}: NotificationsPopoverProps) => {
  const { content } = useNotifications();

  const popoverRef: React.LegacyRef<HTMLDivElement> | null = React.useRef(null);

  const handleClose = () => {
    popoverRef.current?.classList.add('leaving');
    setTimeout(() => {
      onClose();
    }, 100);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === ESC_KEY) {
      handleClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  React.useEffect(() => {
    if (open) popoverRef.current?.classList.remove('leaving');
  }, [open]);

  return (
    <Styles.Container ref={popoverRef} aria-label="Popup de notificações">
      <NotificationsPopoverHeader onClose={handleClose} />

      {content === 'details' ? null : <NotificationsFilters />}

      <Styles.Content>
        {
          {
            list: <NotificationsList />,
            details: <NotificationDetails />,
            empty: <EmptyState />,
          }[content]
        }
      </Styles.Content>
    </Styles.Container>
  );
};

export default NotificationsPopover;
