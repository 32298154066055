import React from 'react';
import { bool } from 'prop-types';
import { convertPXToREM, Typography, ButtonGhost } from '@sofia/ui';
import { Alert, Snackbar, useDialogState } from '@ampli/ui';
import * as Styled from './error-dialog.styles';

const ErrorDialog = ({ error }) => {
  const errorMessageDialog = useDialogState();

  React.useEffect(() => {
    if (error && !errorMessageDialog.visible) {
      errorMessageDialog.show();
    }
  }, [error, errorMessageDialog]);

  return (
    <Snackbar
      {...errorMessageDialog}
      hideOnClickOutside={false}
      hideOnEsc={false}
      aria-label="Erro ao carregar dados"
    >
      <Alert severity="error">
        <Styled.Flex>
          <Styled.FlexItem>
            <Typography margin={`0 ${convertPXToREM(16)} 0 0`}>
              Desculpe, tivemos um problema ao carregar seus dados. Por favor,
              tente novamente!
            </Typography>
          </Styled.FlexItem>
          <ButtonGhost
            color="white"
            onClick={() => window.location.reload()}
            hoverBackground="transparent"
            padding="8px"
          >
            Tentar
          </ButtonGhost>
        </Styled.Flex>
      </Alert>
    </Snackbar>
  );
};

ErrorDialog.propTypes = {
  error: bool.isRequired,
};

export default ErrorDialog;
