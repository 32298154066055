import { gql } from 'graphql.macro';

export const GET_PAYMENT_STATEMENT = gql`
  query GetPaymentStatement($courseEnrollmentId: String!) {
    data: getPaymentStatement(courseEnrollmentId: $courseEnrollmentId) {
      id
      title
      paidAt
      paidAmount
      paymentMethod
      invoiceId
      allowGetInvoiceTaxDocument
    }
  }
`;
