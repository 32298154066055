import React from 'react';
import { number } from 'prop-types';

const ImageGuideBook = ({ width, height, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 214 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M92.8674 156.042C130.356 164.413 168.995 145.545 179.17 113.9C189.345 82.2553 167.203 49.8161 129.715 41.4453C92.2263 33.0745 53.5874 51.9419 43.4123 83.5869C33.2372 115.232 55.379 147.671 92.8674 156.042Z"
        fill="#EBF0F5"
      />
      <path
        d="M89.2354 98.8814C91.1866 99.3798 92.9574 100.338 94.1626 101.719C95.6841 103.462 96.1921 105.519 95.5214 107.134C94.2344 110.232 88.3436 112.342 83.0242 109.829C67.3444 101.647 51.6644 93.4644 35.9844 85.2822L89.2354 98.8814Z"
        fill="#081F2D"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.607 92.0475C149.157 98.2232 113.359 99.0875 113.359 99.0875C91.1021 99.6312 82.4901 98.4593 76.2582 97.2666C72.4555 96.5394 69.1326 95.7204 66.4003 94.9627C64.7903 96.0655 63.2531 97.2415 61.7959 98.4853C59.9031 100.099 58.1459 101.822 56.5369 103.64L30.2644 91.445C34.2663 86.4164 39.0163 81.8363 44.3982 77.8166C51.2878 72.6925 57.7788 67.8582 66.8676 67.1919C71.4174 66.8534 75.296 67.7004 78.3618 68.6545C83.5485 70.2702 88.9401 71.3837 94.4325 71.9734C96.2187 72.1759 98.0188 72.2841 99.8222 72.2974L99.8299 72.2991C102.876 72.3182 105.969 72.0718 113.547 72.1655C131.865 72.3852 137.678 74.242 141.031 77.7186C141.581 78.2859 142.065 78.8967 142.475 79.5427L142.17 79.4939C144.882 80.0291 147.57 80.7831 149.79 81.8503C152.547 83.172 152.681 84.203 152.674 84.5802C152.642 85.7477 151.052 86.5866 150.51 86.8505L150.186 86.791C152.617 87.4883 153.95 88.3247 154.296 89.4803C154.644 90.604 153.871 91.6768 153.607 92.0475Z"
        fill="#C5D2E0"
      />
      <path
        d="M111.059 84.1499C124.307 82.823 137.725 83.7585 150.506 86.9002M106.385 79.1212C118.373 77.7579 130.53 77.924 142.442 79.6139"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M89.3567 88.8532C88.8261 88.7346 88.3378 88.5103 87.9331 88.1994C87.5285 87.8884 87.2193 87.4998 87.0317 87.0664L84.4324 81.0204L205.01 49.3336L209.034 53.1308C209.412 53.4875 209.677 53.9184 209.806 54.3843C209.934 54.8501 209.921 55.3361 209.768 55.7978C209.616 56.2595 209.328 56.6824 208.932 57.0277C208.535 57.3731 208.043 57.63 207.499 57.7751L91.2638 88.8136C90.6447 88.9792 89.983 88.9929 89.3567 88.8532Z"
        fill="white"
      />
      <path
        d="M204.874 49.7424L208.717 53.3691C209.052 53.6791 209.287 54.0561 209.4 54.4645C209.512 54.8729 209.499 55.2991 209.361 55.703C209.228 56.1082 208.977 56.4793 208.629 56.7823C208.282 57.0853 207.85 57.3105 207.373 57.4371L91.1375 88.4754C90.4017 88.6715 89.6055 88.6218 88.9149 88.3368C88.2243 88.0518 87.6924 87.5534 87.4301 86.9454L84.9814 81.2492L204.874 49.7424L204.874 49.7424ZM205.145 48.9266L83.8834 80.7932L86.6335 87.1899C86.9698 87.9701 87.6521 88.6097 88.5382 88.9755C89.4244 89.3413 90.446 89.4051 91.3901 89.1534L207.626 58.1149C208.237 57.9518 208.79 57.663 209.236 57.2748C209.681 56.8865 210.005 56.4112 210.176 55.8922C210.348 55.3732 210.362 54.827 210.218 54.3034C210.074 53.7798 209.776 53.2954 209.351 52.8945L205.145 48.9266L205.145 48.9266Z"
        fill="#081F2D"
      />
      <path
        d="M157.437 1.1431C160.349 0.351758 163.494 0.44595 166.322 1.40915C169.149 2.37234 171.478 4.14299 172.899 6.41034L175.176 10.0434L87.1547 20.237L157.437 1.1431Z"
        fill="white"
      />
      <path
        d="M164.667 1.31966C166.338 1.69463 167.899 2.35494 169.251 3.2595C170.602 4.16406 171.717 5.29353 172.525 6.57765L174.521 9.76105L91.8531 19.3344L157.567 1.48147C159.869 0.854286 162.334 0.798094 164.667 1.31966ZM164.888 0.632037C162.398 0.0752669 159.767 0.135235 157.31 0.804734L82.4587 21.14L175.834 10.3266L173.275 6.24379C172.415 4.87144 171.227 3.6645 169.783 2.69877C168.34 1.73304 166.673 1.02938 164.888 0.631947L164.888 0.632037Z"
        fill="#081F2D"
      />
      <path
        d="M83.8827 80.793L81.3749 74.9621C81.1957 74.5456 81.121 74.102 81.1554 73.6593C81.1898 73.2166 81.3325 72.7841 81.5747 72.389C81.8169 71.994 82.1533 71.6448 82.5629 71.3633C82.9725 71.0818 83.4464 70.8741 83.9551 70.7531L190.594 45.3976C192.446 44.9571 194.388 44.8556 196.288 45.1C198.187 45.3444 200 45.9289 201.604 46.8141L204.302 48.3034C207.002 49.7933 206.413 53.2574 203.32 54.0741L90.999 83.7429C89.5846 84.1165 88.0557 84.0184 86.7304 83.469C85.405 82.9196 84.3851 81.9612 83.8827 80.793Z"
        fill="#FFE6C9"
      />
      <path
        d="M83.9561 70.7516L72.7303 34.9956C72.2775 33.5532 72.1705 32.0482 72.4155 30.5681C72.6605 29.0879 73.2527 27.662 74.1578 26.373C75.0628 25.084 76.2626 23.9576 77.6877 23.0591C79.1127 22.1607 80.7346 21.508 82.4592 21.1391L160.536 4.4368C163.512 3.80028 166.652 4.0374 169.435 5.10874C172.218 6.18008 174.476 8.02109 175.834 10.3257C182.487 21.608 185.621 34.1357 184.945 46.7397L83.9561 70.7516Z"
        fill="#FFE6C9"
      />
      <path
        d="M175.336 49.0251C174.31 36.6084 170.501 24.4772 164.11 13.2691M169.323 50.4548C168.297 38.0381 164.489 25.9069 158.097 14.6987M164.309 51.6474C163.283 39.2308 159.474 27.0996 153.083 15.8914M122.563 61.5729C121.537 49.1562 117.728 37.025 111.337 25.8169"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M95.8398 71.999L98.3477 77.8301"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M180.281 47.8501C183.025 49.439 185.096 51.7195 186.196 54.3646"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M83.9534 70.7518L184.942 46.7398"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M30.2673 91.4447C34.2663 86.4141 39.0162 81.8334 44.4003 77.8152C51.2868 72.6896 57.7764 67.8593 66.8643 67.1904C73.3253 66.7147 78.4192 68.614 81.7739 69.8245C90.5449 72.9889 90.4995 76.054 98.736 80.562C112.703 88.2059 126.024 86.6109 127.101 92.0952C127.401 93.6215 126.591 94.8724 126.388 95.1862C122.252 101.571 102.368 98.6912 93.0531 96.5487C88.2624 95.4454 83.6206 93.9332 79.2001 92.0357"
        fill="#C5D2E0"
      />
      <path
        d="M81.7734 69.8237C90.5444 72.9881 90.4991 76.0532 98.7355 80.5612C112.703 88.2051 126.023 86.6101 127.101 92.0944C127.401 93.6207 126.59 94.8716 126.387 95.1854C122.252 101.57 102.368 98.6904 93.0527 96.5479C88.2619 95.4446 83.6201 93.9324 79.1997 92.0349"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M19.5869 114.785C20.8206 108.557 23.6016 100.034 30.2636 91.4446C32.0246 89.1747 33.9705 87.0096 36.0874 84.9648C60.2259 98.1156 78.427 107.223 83.1265 109.511C84.4602 110.161 86.7534 110.91 89.406 110.545C92.0428 110.181 93.925 108.81 94.9606 107.898L67.2187 145.176"
        fill="#3969EB"
      />
      <path
        d="M88.0153 117.389C81.165 117.646 74.3936 116.145 68.6021 113.085L29.5975 92.4799M36.1682 85.0143C51.8479 93.1965 67.5278 101.379 83.208 109.561C85.1049 110.478 87.3127 110.842 89.4874 110.594C92.4381 110.246 94.2952 108.894 95.0883 107.887C95.0728 107.907 95.0577 107.927 95.0423 107.947"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

ImageGuideBook.defaultProps = {
  width: 214,
  height: 159,
};

ImageGuideBook.propTypes = {
  width: number,
  height: number,
};

export default ImageGuideBook;
