import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const WrapperIcon = styled.div`
  margin-bottom: 20px;
  svg {
    width: 32px;
    height: 32px;
  }

  ${mediaQuery('mobile')} {
    margin-bottom: 8px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const CardWrapper = styled.div`
  padding: 24px 12px;
  min-height: 195px;
`;
