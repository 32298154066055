import styled from '@emotion/styled';
import { mediaQuery, convertPXToREM, ButtonGhost } from '@sofia/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
  justify-content: space-between;
  margin-top: 24px;

  gap: 24px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 0;

    gap: 10px;
  }
`;

export const ColCalendar = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
  min-width: 280px;

  @media (max-width: 600px) {
    align-items: center;
    margin-left: 0;
  }
`;

export const FilterButtonMobile = styled(ButtonGhost)``;

export const FilterButtonMobileWrapper = styled.div`
  background: white;
  position: fixed;
  width: 100%;
  cursor: pointer;
  bottom: 0;
  z-index: 888;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 16px 0px rgba(33, 37, 41, 0.12);
`;

export const ColCards = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 264px;
  height: ${convertPXToREM(600)};

  ${mediaQuery('mobile')} {
    margin-top: ${({ isCollapsed }) => (isCollapsed ? '240px' : '460px')};
  }
`;

export const MobileHeaderWrapper = styled.div`
  background: white;
  cursor: pointer;
  z-index: 999999;
  top: 0;

  position: fixed;
  width: 100%;
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  width: 70%;
`;
