import React from 'react';

import { generatePath, Link } from 'react-router-dom';
import { arrayOf, object, bool } from 'prop-types';
import { DateTime, groupBy } from '@ampli/utils';

import { EntitiesLabels } from '../../constants';
import { LayoutContent } from '../layout';
import { useGetState } from '../../state';
import { routePath } from '../../routes';
import { sortObjectsByOrder } from '../../utils';
import { Skeleton } from '../skeleton';

import SubjectExamsAndAssignments from './exams-assignments/subject-exams-assignments';
import SubjectLearningUnitCard from './learning-unit/subject-learning-unit-card';
import SubjectHeader from './header/subject-header';
import SubjectBanner from './banner/subject-banner';
import ContentDownload from './content-download';

import * as Styled from './subject-styles';
import { isDateAhead, sortByDate } from '../../modules';
import { RecordingsAccessPresentation } from '../../modules/recordings';

const Subject = ({ subjectEnrollment, learningUnitEnrollments, loading }) => {
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const entitiesLabelsDictionary = EntitiesLabels(selectedCourseEnrollmentType);

  const subject = subjectEnrollment?.subject || {};
  const hasSimulators = subject?.simulators?.length > 0;

  const subjectAssignmentConfigs =
    subjectEnrollment?.gradingMethod?.assignmentConfigs;

  const subjectAssignments = groupBy(
    subjectEnrollment?.assignments?.map((assignment) => {
      const attempts = assignment?.detail?.attempts || [];
      return {
        id: assignment.id,
        gradeValue: assignment.gradeValue,
        maxGrade: assignment.maxGrade,
        dueDate: assignment.detail?.dueDate
          ? DateTime.fromISO(assignment.detail.dueDate)
          : '',
        startDate: assignment.detail?.startDate
          ? DateTime.fromISO(assignment.detail.startDate)
          : '',
        type: assignment.detail?.config.type,
        status: assignment.status,
        allAttemptsDepleted:
          attempts.length >= assignment?.detail?.config?.numberOfAttempts,
        detail: assignment.detail,
      };
    }),
    'type'
  );

  const hasPracticalActivities = learningUnitEnrollments.some(
    (learningUnit) =>
      learningUnit.practicalActivities || learningUnit.legacyPracticalActivities
  );

  const filterPracticalActivities = (practicalActivities) =>
    practicalActivities?.filter(
      (activity) =>
        activity?.scheduling?.date &&
        isDateAhead({ date: activity.scheduling.date.toString() })
    );

  const getNextPracticalActivity = (practicalActivities) =>
    practicalActivities.sort((a, b) => {
      return new Date(a?.scheduling.date) - new Date(b?.scheduling.date);
    });

  const nextEnrollmentPracticalActivity =
    learningUnitEnrollments.reduce((filtered, learningUnit) => {
      const activities = filterPracticalActivities(
        learningUnit.practicalActivities
      );
      if (activities?.length > 0) {
        filtered.push(activities);
      }

      return sortByDate(filtered.flat(), 'scheduling.date');
    }, [])?.[0] || {};

  return (
    <>
      <SubjectBanner>
        <Link
          data-testid="home-breadcrumb"
          to={generatePath(routePath.home, {
            courseType: selectedCourseEnrollmentType,
            courseVersionId: selectedCourseVersionId,
          })}
        >
          <span>Início</span>
        </Link>
        <b>{entitiesLabelsDictionary?.mySubjectTitle}</b>
      </SubjectBanner>

      <Styled.LayoutWrapper>
        <LayoutContent>
          <SubjectHeader
            loading={loading}
            nextPracticalActivity={nextEnrollmentPracticalActivity}
            subjectEnrollment={subjectEnrollment}
          />

          <ContentDownload
            subjectEnrollmentId={subjectEnrollment?.id}
            subjectName={subject?.name}
          />

          <Styled.LearningUnitCardWrapper>
            {!loading
              ? sortObjectsByOrder(learningUnitEnrollments)?.map(
                  (learningUnit, key) => {
                    return (
                      <SubjectLearningUnitCard
                        key={key}
                        id={`learning-unit-card-${key}`}
                        data-testid={`learning-unit-card-${key}`}
                        learningUnit={learningUnit}
                        entitiesLabelsDictionary={entitiesLabelsDictionary}
                        gradingMethod={subjectEnrollment.gradingMethod}
                        subjectEnrollmentId={subjectEnrollment.id}
                        nextPracticalActivity={
                          learningUnit.legacyPracticalActivities?.lessonPlans
                            ?.length > 0 ||
                          getNextPracticalActivity(
                            filterPracticalActivities(
                              learningUnit.practicalActivities
                            )
                          )?.[0] ||
                          learningUnit.practicalActivities?.[0] ||
                          null
                        }
                      />
                    );
                  }
                )
              : [0, 1, 2, 3].map((i) => (
                  <Skeleton
                    key={i}
                    height={140}
                    borderRadius={6}
                    margin={`0px 0px 24px 0px`}
                  />
                ))}

            <RecordingsAccessPresentation
              subjectRecordings={subjectEnrollment?.lives || []}
            />
          </Styled.LearningUnitCardWrapper>

          <SubjectExamsAndAssignments
            hasSimulators={hasSimulators}
            hasPracticalActivities={hasPracticalActivities}
            subjectName={subject?.name}
            subjectAssignments={subjectAssignments}
            subjectAssignmentConfigs={subjectAssignmentConfigs}
            subjectEnrollment={subjectEnrollment}
          />
        </LayoutContent>
      </Styled.LayoutWrapper>
    </>
  );
};

Subject.propTypes = {
  subjectEnrollment: object.isRequired,
  learningUnitEnrollments: arrayOf(object.isRequired).isRequired,
  loading: bool,
};

export default Subject;
