import React, { useCallback, useEffect, useState, useRef } from 'react';
import { isNil, isBoolean, func, bool, any } from '@ampli/utils';
import { useUserMedia, useNavigatorPermissions } from '@ampli/hooks';
import {
  css,
  px2grid,
  getColor,
  fontSize,
  DialogBackdrop,
  Dialog,
  DialogHeader,
  DialogSection,
  DialogFooter,
  DialogCloseButton,
  Paragraph,
  Text,
  Icon,
  IconButton,
  GradientButton,
  OutlinedButton,
  Loader,
  Flex,
  FlexItem,
  Separator,
} from '@ampli/ui';

import { ButtonOutline } from '@sofia/ui';
import { useTheme } from '@emotion/react';

const VIDEO_WIDTH = 436;
const VIDEO_HEIGHT = 246;

const useTakeScreenshot = ({ visible, video }) => {
  const videoRef = useRef();
  const canvasRef = useRef();
  const [hasVideoPermission] = useNavigatorPermissions({
    name: 'camera',
  });
  const [requestAuthorization, setRequestAuthorization] = useState(false);
  const [hasScreenshot, setHasScreenshot] = useState(false);
  const [mediaStream, mediaError] = useUserMedia({
    requestAuthorization,
    video,
  });
  const requestingAuthorization =
    requestAuthorization && isNil(mediaStream) && isNil(mediaError);

  const takeScreenshot = useCallback(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0);
    setHasScreenshot(true);
  }, []);

  const resetScreenshot = useCallback(() => {
    const canvas = canvasRef.current;
    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    setHasScreenshot(false);
  }, []);

  const confirmRequestAuthorization = useCallback(() => {
    setRequestAuthorization(true);
  }, []);

  const resetStream = useCallback(() => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setRequestAuthorization(false);
      setHasScreenshot(false);
    }
  }, [mediaStream]);

  useEffect(() => {
    if (hasVideoPermission && visible) {
      setRequestAuthorization(true);
    }
  }, [hasVideoPermission, visible]);

  useEffect(() => {
    if (mediaStream && videoRef.current) {
      videoRef.current.srcObject = mediaStream;
    }
  }, [mediaStream]);

  useEffect(() => {
    if (!visible) {
      resetStream();
    }
  }, [resetStream, visible]);

  return {
    videoRef,
    canvasRef,
    requestAuthorization,
    requestingAuthorization,
    hasVideoPermission,
    mediaError,
    mediaStream,
    confirmRequestAuthorization,
    resetStream,
    hasScreenshot,
    takeScreenshot,
    resetScreenshot,
  };
};

const WebcamDialogHeader = ({
  hide,
  hasVideoPermission,
  requestAuthorization,
}) => (
  <DialogHeader>
    {isBoolean(hasVideoPermission) && (
      <Text color="text-dark" size="x-large">
        {!requestAuthorization
          ? 'Precisamos acessar sua câmera'
          : 'Tirar foto pela Webcam'}
      </Text>
    )}
    <DialogCloseButton onClick={hide} />
  </DialogHeader>
);
WebcamDialogHeader.propTypes = {
  hide: func.isRequired,
  hasVideoPermission: bool,
  requestAuthorization: bool,
};

const WebcamDialogFooter = ({
  requestAuthorization,
  mediaError,
  requestingAuthorization,
  onRequestAuthorizationClick,
  onTakeScreenshotClick,
  onResetScreenshotClick,
  onConfirmScreenshotClick,
  hasScreenshot,
}) => {
  const theme = useTheme();
  return (
    <DialogFooter>
      {!requestingAuthorization && !mediaError && (
        <Flex alignItems="center" justifyContent="center">
          <div>
            {!requestAuthorization ? (
              <ButtonOutline
                borderBoxColor={theme.colors.text.primary}
                color={theme.colors.text.primary}
                onClick={onRequestAuthorizationClick}
              >
                Entendi
              </ButtonOutline>
            ) : hasScreenshot ? (
              <>
                <FlexItem flex={1}>
                  <OutlinedButton onClick={onResetScreenshotClick}>
                    Tirar outra foto
                  </OutlinedButton>
                </FlexItem>
                <Separator
                  orientation="vertical"
                  transparent
                  margin="xx-small"
                />
                <FlexItem flex={1}>
                  <GradientButton onClick={onConfirmScreenshotClick}>
                    Confirmar
                  </GradientButton>
                </FlexItem>
              </>
            ) : (
              <GradientButton
                as={IconButton}
                icon={<Icon.Camera />}
                iconSize="x-large"
                onClick={onTakeScreenshotClick}
              >
                Tirar foto
              </GradientButton>
            )}
          </div>
        </Flex>
      )}
    </DialogFooter>
  );
};
WebcamDialogFooter.propTypes = {
  onRequestAuthorizationClick: func.isRequired,
  onTakeScreenshotClick: func.isRequired,
  onResetScreenshotClick: func.isRequired,
  onConfirmScreenshotClick: func.isRequired,
  mediaError: any,
  requestingAuthorization: bool,
  requestAuthorization: bool,
  hasScreenshot: bool,
};

const WebcamDialogVideoSection = ({
  videoRef,
  canvasRef,
  hasVideoPermission,
  requestAuthorization,
  requestingAuthorization,
  mediaError,
  hasScreenshot,
}) => (
  <DialogSection>
    {isNil(hasVideoPermission) ? (
      <Loader fullscreen size={64} />
    ) : !requestAuthorization ? (
      <Paragraph>
        <Text>
          Para tirar as fotos será solicitada a permissão para acessar a sua
          câmera.
        </Text>
      </Paragraph>
    ) : requestingAuthorization ? (
      <Paragraph>
        <Text>Aguardando autorização para utilizar a câmera...</Text>
      </Paragraph>
    ) : mediaError ? (
      <Paragraph>
        <Text>
          Não conseguimos acessar a sua câmera. Ela pode estar bloqueada pelo
          seu navegador ou não está instalada corretamente.
        </Text>
      </Paragraph>
    ) : (
      <>
        <div
          className={css`
            border-radius: ${px2grid(8)};
            overflow: hidden;
            position: relative;
            width: ${px2grid(VIDEO_WIDTH)};
            height: ${px2grid(VIDEO_HEIGHT)};
          `}
        >
          {/* eslint-disable-next-line jsx-a11y/media-has-caption*/}
          <video ref={videoRef} autoPlay />
          <canvas
            ref={canvasRef}
            className={css`
              left: 0;
              position: absolute;
              top: 0;
            `}
          />
        </div>
        {hasScreenshot && (
          <ul
            className={css`
              ${fontSize('small')};

              color: ${getColor('text-dark')};
              margin: ${px2grid(24)} 0 0;
              padding-left: ${px2grid(16)};

              li {
                padding-left: ${px2grid(8)};
                margin-bottom: ${px2grid(8)};
              }
            `}
          >
            <li>Os detalhes estão visíveis?</li>
            <li>A foto não está escura ou borrada?</li>
          </ul>
        )}
      </>
    )}
  </DialogSection>
);
WebcamDialogVideoSection.propTypes = {
  videoRef: any,
  canvasRef: any,
  mediaError: any,
  requestingAuthorization: bool,
  requestAuthorization: bool,
  hasVideoPermission: bool,
  hasScreenshot: bool,
};

const OnboardingDocumentsWebcamModal = ({ onSuccessClick, ...props }) => {
  const visible = props.visible;
  const {
    videoRef,
    canvasRef,
    hasVideoPermission,
    requestAuthorization,
    requestingAuthorization,
    mediaError,
    confirmRequestAuthorization,
    hasScreenshot,
    takeScreenshot,
    resetScreenshot,
  } = useTakeScreenshot({
    visible,
    video: { width: { exact: VIDEO_WIDTH }, height: { exact: VIDEO_HEIGHT } },
  });

  return (
    <>
      <DialogBackdrop {...props} />
      <Dialog {...props} aria-labelledby="webcamModalTitle">
        <WebcamDialogHeader
          id="webcamModalTitle"
          hasVideoPermission={hasVideoPermission}
          requestAuthorization={requestAuthorization}
          hide={props.hide}
        />
        <WebcamDialogVideoSection
          videoRef={videoRef}
          canvasRef={canvasRef}
          hasVideoPermission={hasVideoPermission}
          requestAuthorization={requestAuthorization}
          requestingAuthorization={requestingAuthorization}
          mediaError={mediaError}
          hasScreenshot={hasScreenshot}
        />
        <WebcamDialogFooter
          requestAuthorization={requestAuthorization}
          requestingAuthorization={requestingAuthorization}
          mediaError={mediaError}
          onTakeScreenshotClick={takeScreenshot}
          onResetScreenshotClick={resetScreenshot}
          onConfirmScreenshotClick={(e) => {
            props.hide();
            canvasRef.current.toBlob((blob) => {
              onSuccessClick && onSuccessClick(e, blob);
            });
          }}
          onRequestAuthorizationClick={confirmRequestAuthorization}
          hasScreenshot={hasScreenshot}
        />
      </Dialog>
    </>
  );
};

OnboardingDocumentsWebcamModal.propTypes = {
  hide: func.isRequired,
  visible: bool,
  onSuccessClick: func.isRequired,
};

export default OnboardingDocumentsWebcamModal;
