import React from 'react';

import { TextStepForm, TextStepHeader } from './components';
import { ITextStepProps } from '../../../../../types';
import { Container } from './text-step.styles';

const TextStep: React.FC<ITextStepProps> = ({
  isVisible,
  handleSetIsButtonEnabled,
}: ITextStepProps) => {
  if (!isVisible) return null;

  return (
    <Container>
      <TextStepHeader />

      <TextStepForm handleSetIsButtonEnabled={handleSetIsButtonEnabled} />
    </Container>
  );
};

export default TextStep;
