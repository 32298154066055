import React from 'react';

import { Typography, Modal, SvgCalendarAlt, SvgCheckCircle } from '@sofia/ui';
import { GrayRetangle, ModalTextWrapper } from './grade-styles';
import { useTheme } from '@emotion/react';
import { object } from 'prop-types';

const HowIsTheGradeCalculatedModal = ({ modal, gradingMethod }) => {
  const theme = useTheme();
  const { cutOffScore, assignmentConfigs } = gradingMethod;

  const methodType = {
    ATTENDANCE: 'das aulas assistidas',
    QUIZ: 'dos exercícios de unidade',
    EXAM: 'da prova final',
    SEMESTRAL_EXAM_ONLINE: 'da prova semestral',
    ASSIGNMENT: 'do trabalho',
    PRESENTIAL_EXAM: 'da prova presencial',
  };

  const listAssignments = () => {
    return assignmentConfigs.map(({ type }, index) => {
      if (index + 1 === assignmentConfigs.length - 1) {
        return `${methodType[type]} e `;
      } else if (index === assignmentConfigs.length - 1) {
        return methodType[type];
      } else {
        return `${methodType[type]} `;
      }
    });
  };

  return (
    <Modal
      handleModal={modal}
      header={{
        headerText: 'Como calculamos a pontuação final?',
      }}
      footer={{
        buttonText: 'Entendi',
        buttonClick: () => {
          modal.hide();
        },
      }}
    >
      {assignmentConfigs?.length > 0 ? (
        <>
          <Typography size={16} weight={400} margin="0 0 24px 0">
            A sua pontuação final é somada usando a conquista de pontos{' '}
            {listAssignments()}
          </Typography>
          {assignmentConfigs.map(({ weight, name, id, type }) => {
            return (
              <ModalTextWrapper key={id} flex marginB="16px">
                <GrayRetangle />
                <Typography margin="0 0 0 16px" size={16} weight={400}>
                  Conquista de até {weight} pontos{' '}
                  {methodType[type] || name.toLowerCase()}
                </Typography>
              </ModalTextWrapper>
            );
          })}
          <ModalTextWrapper flex marginB="24px">
            <div>
              <SvgCheckCircle fill={theme.colors.text.black} size={20} />
            </div>
            <Typography margin="0 0 0 16px" size={16} weight={400}>
              Para ser aprovado é necessário ter {cutOffScore} pontos somando a
              pontuação {listAssignments()}.
            </Typography>
          </ModalTextWrapper>
          <ModalTextWrapper flex marginB="0">
            <div>
              <SvgCalendarAlt size={20} />
            </div>
            <Typography margin="0 0 0 16px" size={16} weight={400}>
              Sua nota de presença é considerada até a data de fechamento de
              provas e envio de trabalhos.
            </Typography>
          </ModalTextWrapper>
        </>
      ) : null}
    </Modal>
  );
};

HowIsTheGradeCalculatedModal.propTypes = {
  modal: object,
  gradingMethod: object,
};

export default HowIsTheGradeCalculatedModal;
