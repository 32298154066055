import { CreatePublicationInputDto } from '../../../types/dto/create-publication.dto';
import { CreatePublicationReturn } from './types';
import { COMMUNITY_MUTATIONS } from './mutations';
import { useMutation } from '@ampli/services';

export const useCreatePublication = (
  data: CreatePublicationInputDto,
  options = {}
): CreatePublicationReturn => {
  const [mutate, metadata] = useMutation(
    COMMUNITY_MUTATIONS.CREATE_PUBLICATION,
    { ...options, variables: { data } }
  );

  return { mutate, metadata };
};
