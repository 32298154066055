import React from 'react';
import { useTheme } from '@emotion/react';
import { Typography, convertPXToREM } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';

import { ImageAssetProps } from '../types';

import * as Styled from './image-asset.styles';

const ImageAsset = ({
  data,
  banner,
  template,
}: ImageAssetProps): React.ReactElement => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const alt = data.image.descriptionText
    ? `início da audiodescrição
    ${data.image.descriptionText}
    fim da audiodescrição`
    : `imagem sem audiodescrição`;

  return (
    <>
      <Styled.Figure
        data-testid="image-asset-figure"
        className={banner ? 'banner' : 'asset'}
      >
        <Styled.Image
          data-testid="image-asset"
          src={data.imageUrls?.original}
          alt={alt}
          /* styled props */
          banner={banner}
        />
        {data.caption ? (
          <Typography
            data-testid="image-asset-caption"
            className="image-caption"
            as="figcaption"
            size={12}
            color={theme.colors.text.lightBlack}
            margin={isDesktop ? '0px' : `0 ${convertPXToREM(16)}`}
            textAlign="center"
          >
            {data.caption}
          </Typography>
        ) : null}
      </Styled.Figure>

      {template && banner ? (
        <Styled.Banner
          data-testid="image-asset-banner"
          url={template[isDesktop ? 'bannerUrl' : 'mobileBannerUrl']}
        />
      ) : null}
    </>
  );
};

export default ImageAsset;
