import styled from '@emotion/styled';
import { convertPXToVW } from '@sofia/ui';

export const Wrapper = styled.div`
  display: inline-flex;
  padding: 4px;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.avatar.border};
  background: ${({ theme }) => theme.colors.avatar.background.secondary};
  position: relative;
`;

export const AvatarCircle = styled.div`
  color: ${({ theme }) => theme.colors.avatar.text};
  display: flex;
  padding: 8px 13px;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.avatar.background.primary};
`;

export const AvatarDropDownWrapper = styled.div`
  background: ${({ theme }) => theme.colors.avatar.background.white};
  box-shadow: 0px 2px 8px 0px rgba(33, 37, 41, 0.12);

  position: absolute;
  right: 0;
  top: 120%;

  padding: 16px;
  border-radius: 16px;
  min-width: ${convertPXToVW(308)};
`;

export const FlexBox = styled.div<{ margin?: string; justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  margin: ${({ margin }) => margin};
  cursor: pointer;
`;

export const TextWrapper = styled.div`
  margin-right: 30px;
`;
