import styled from '@emotion/styled';

import {
  convertPXToREM,
  convertPXToVW,
  convertPXToVH,
  mediaQuery,
  SvgTimes,
} from '@sofia/ui';

import { keyframes } from '@emotion/react';

const enteringAnimation = keyframes`
	from {
		opacity: 0;
		transform: translateY(100%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
`;

const leavingAnimation = keyframes`
	from {
    opacity: 1;
    transform: translateY(0);
  }
	to {
    opacity: 0;
    transform: translateY(100%);
	}
`;

export const Dialog = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  left: unset;

  width: ${convertPXToVW(400)};
  padding: 24px;

  display: flex;
  flex-direction: column;
  border-radius: 8px;
  grid-gap: 16px;

  background-color: ${({ theme }) => theme.colors.icon.background};
  box-shadow: 0px 8px 32px 0px
    ${({ theme }) => theme.colors.communication.color.primary}1F;

  animation: ${enteringAnimation} 600ms ease-in-out;
  z-index: 1001;

  &.leaving {
    animation: ${leavingAnimation} 600ms ease-in-out;
  }

  ${mediaQuery('desktop-max')} {
    width: calc(100% - 24px);
    bottom: 12px;
    right: 12px;
    left: 12px;
  }
`;

export const Header = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`;

export const Category = styled.span`
  display: flex;
  align-items: center;
`;

export const CloseIcon = styled(SvgTimes)`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.span`
  padding: 6px;
  width: fit-content;
  cursor: pointer;
  border-radius: 50%;
`;

export const ContentWrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;

export const FooterLinkButton = styled.button`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  font-size: ${convertPXToREM(16)};
  font-weight: 700;
  line-height: 1.5;
  text-align: center;

  outline: 0;
  cursor: pointer;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.text.primary};
  border: 2px solid ${({ theme }) => theme.colors.button.primary};

  width: 100%;
  height: ${convertPXToVH(56)};
  border-radius: 8px;
  padding: ${convertPXToVH(19)} ${convertPXToVW(24)};

  svg {
    margin-left: 10px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${mediaQuery('desktop-max')} {
    padding: ${convertPXToVH(19)} ${convertPXToVW(0)};
  }
`;

export const Image = styled.img`
  width: 100%;
  height: ${convertPXToVH(264)};
  background-color: ${({ theme }) =>
    theme.colors.communication.color.primary}1f;
  border-radius: 4px;
  object-fit: cover;
  object-position: center;
`;
