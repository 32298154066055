import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const FooterModal = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  padding-top: 32px;
`;

export const Button = styled.button`
  cursor: 'pointer';
  display: 'contents';
  backgroundcolor: 'transparent';
  border: 0;
  boxshadow: 'none';
  padding: 0;
`;

export const JoyrideStyles = (theme) => css`
  tooltip {
    border-radius: 8px;
    padding: 32px;
  }
  tooltipTitle {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 16;
    color: ${theme.colors.text.black};
  }
  tooltipContainer {
    text-align: left;
  }
  tooltipContent {
    padding: 0;
    padding-top: 16;
    font-family: 'Roboto';
    font-size: 16;
    color: ${theme.colors.text.black};
  }
  spotlight-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  spotlight {
    cursor: default;
  }
  overlay-color: rgba(0, 0, 0, 0.5);
  overlay {
    cursor: default;
    z-index: 1000;
  }
  options {
    primary-color: ${theme.colors.text.primary};
  }
  buttonClose {
    width: 12px;
    height: 12px;
    top: 30px;
    right: 30px;
    padding: 0;
    color: ${theme.colors.text.black};
  }
  buttonBack {
    font-family: Roboto;
    font-weight: 700;
    font-size: 16px;
    padding: 24px;
    width: 100%;
    border-radius: 8px;
    border: 'solid 2px ${theme.colors.button.primary}';
    background-color: transparent;
    color: ${theme.colors.text.primary};
  }
  buttonNext {
    font-family: Roboto;
    font-weight: 700;
    font-size: 16px;
    padding: 24px;
    width: 100%;
    border-radius: 8px;
    background-color: ${theme.colors.button.primary};
    color: ${theme.colors.text.white};
  }

  buttonSkip {
    font-family: Roboto;
    font-weight: 700;
    font-size: 16px;
    padding: 24px;
    width: 100%;
    border-radius: 8px;
    background-color: transparent;
    color: ${theme.colors.text.primary};
  }
`;
