import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const Img = styled.img`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 200px;
  border-radius: 6px 6px 0 0;
  ${mediaQuery('desktop')} {
    height: 100%;
    width: 300px;
    border-radius: 6px 0 0 6px;
  }
`;
