import React from 'react';
import { number, shape, string } from 'prop-types';
import { Card, Typography, ProgressBar, SvgArrowRight } from '@sofia/ui';
import { clickByKey } from '@ampli/utils';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import {
  CardContainer as StyledCardContainer,
  ActionText as StyledActionText,
} from '../subject-learning-unit-section-card-styles';
import { useBreakpoint } from '@ampli/hooks';
import * as Styled from './subject-learning-unit-section-styles';

const SubjectLearningUnitSection = ({
  section,
  learningUnitOrder,
  learningUnitId,
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const history = useHistory();
  const { url } = useRouteMatch();
  const sectionUrl = `${url}/unidade/${learningUnitId}/aula/${section.pathUrl}`;
  const progress = Math.round(section.progress?.percentage * 100);
  const isCompleted = section.progress?.percentage === 1;

  const onClick = () => {
    history.push(sectionUrl, { fromSubjectPage: true });
  };

  return (
    <StyledCardContainer>
      <Card
        data-testid={`beta-learning-unit-card${learningUnitOrder}-section${section.order}`}
        className="card-learning-unit-section-item"
        hover={theme.colors.card.color.hover}
        borderColor={theme.colors.card.border.primary}
        onClick={onClick}
        onKeyDown={(event) => clickByKey(event, onClick)}
        tabIndex={0}
      >
        <Styled.SectionInfo isDesktop={isDesktop}>
          <Typography
            margin="0 4px 0 0"
            size={isDesktop ? 20 : 16}
            color={theme.colors.text.black}
          >
            {`Aula ${section.order}`}
          </Typography>
          <Styled.SectionTitle
            ellipsis={isDesktop}
            color={theme.colors.text.lightBlack}
          >
            {section.title}
          </Styled.SectionTitle>
        </Styled.SectionInfo>

        <Styled.SectionProgress isDesktop={isDesktop}>
          <Styled.FlexSpaceBetween>
            <Typography
              margin={0}
              size={isDesktop ? 16 : 14}
              color={isCompleted ? '#008566' : theme.colors.text.lightBlack}
            >
              {isCompleted ? 'Completa' : 'Incompleta'}
            </Typography>
            <Typography
              margin={0}
              size={isDesktop ? 16 : 14}
              color={theme.colors.text.lightBlack}
            >
              {progress}%
            </Typography>
          </Styled.FlexSpaceBetween>
          <ProgressBar progress={progress} />
        </Styled.SectionProgress>

        {isDesktop ? (
          <StyledActionText color={theme.colors.text.primary}>
            <span>Acessar aula</span>
            <SvgArrowRight aria-hidden />
          </StyledActionText>
        ) : (
          <></>
        )}
      </Card>
    </StyledCardContainer>
  );
};

SubjectLearningUnitSection.propTypes = {
  section: shape({
    order: number.isRequired,
    title: string.isRequired,
  }).isRequired,
  learningUnitOrder: string | number,
  learningUnitId: string,
};

export default SubjectLearningUnitSection;
