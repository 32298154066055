import { gql } from 'graphql.macro';

export const CREATE_PUBLICATION = gql`
  mutation CreatePublication($data: PublicationInput!) {
    data: createPublication(data: $data) {
      body
      category
    }
  }
`;

export const COMMUNITY_MUTATIONS = {
  CREATE_PUBLICATION,
};
