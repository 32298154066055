import { TGetLiveByIdHook, TGetLivesHook } from './types';
import {
  useGetLiveByIdQuery,
  useGetLivesFromSubjectLazyQuery,
} from './hooks.gql';
import { useCourseEnrollmentsContext } from '../../../../shared/contexts/course-enrollments-context';
import { useParams } from 'react-router-dom';

export const useGetLives: TGetLivesHook = (
  courseVersionId = '',
  subjectId = ''
) => {
  const {
    states: { courseEnrollments },
  } = useCourseEnrollmentsContext();

  const params = useParams<{ subjectEnrollmentId: string }>();

  const [
    subjectQuery,
    {
      loading: subjectLoading,
      called: subjectCalled,
      data: subjectData,
      refetch: subjectRefetch,
    },
  ] = useGetLivesFromSubjectLazyQuery();

  const loading =
    subjectId.length > 0 ? subjectLoading : courseEnrollments?.loading;
  const data = subjectId.length > 0 ? subjectData?.data?.lives : [];

  const lives = loading || !data ? [] : data;

  return {
    getData: () => {
      if (subjectId.length > 0 && !subjectCalled) {
        subjectQuery({
          variables: {
            subjectEnrollmentId: params?.subjectEnrollmentId,
          },
        });
      } else if (courseVersionId.length > 0) {
        return courseEnrollments.data.lives;
      }

      return lives;
    },
    loading,
    reload: subjectRefetch,
  };
};

export const useGetLiveById: TGetLiveByIdHook = (liveId, prefetchedLive) => {
  const { loading, data, error } = useGetLiveByIdQuery({
    skip: !liveId || Boolean(prefetchedLive),
    variables: {
      liveId,
    },
  });

  return {
    live: data?.data || null,
    loading,
    error,
  };
};
