import {
  useFindStudentCourseEnrollments,
  useServiceClient,
  useGetStudent,
} from '@ampli/services';

import { generatePath, useHistory } from 'react-router-dom';
import { filter, find, groupBy } from '@ampli/utils';
import { changeTheme } from '@ampli/ui';

import { COURSE_ENROLLMENT_ATTENDING } from '../constants';
import { useGetState } from '../state';
import { routePath } from '../routes';
import { useMeContext } from '../modules/shared/contexts/me-context';
import logout from '../lib/logout';

const useCourseSelectionPageData = () => {
  const history = useHistory();
  const client = useServiceClient('privateClient');

  const [
    {
      selectedCourseEnrollmentType,
      selectedCourseEnrollmentId,
      selectedCourseVersionId,
    },
    { setCourseEnrollmentId, setCourseEnrollmentType },
  ] = useGetState();

  const { states: statesMe } = useMeContext();
  const { me, loading: meLoading, error: meError } = statesMe.getMe;

  const {
    data: student,
    loading: studentLoading,
    error: studentError,
  } = useGetStudent(me?.personId);

  const {
    data: courseEnrollments,
    loading: courseEnrollmentsLoading,
    error: courseEnrollmentsError,
  } = useFindStudentCourseEnrollments(me?.personId);

  changeTheme();

  const loading = meLoading || studentLoading || courseEnrollmentsLoading;

  const courseEnrollment =
    find(courseEnrollments || [], { id: selectedCourseEnrollmentId }) || {};

  const currentCourseEnrollmentsByDegree = groupBy(
    filter(courseEnrollments, { status: COURSE_ENROLLMENT_ATTENDING }),
    'course.courseType.degree'
  );

  const pastCourseEnrollmentsByDegree = groupBy(
    filter(
      courseEnrollments,
      ({ status }) => status !== COURSE_ENROLLMENT_ATTENDING
    ),
    'course.courseType.degree'
  );

  const handleLogout = () => logout({ client, history });

  const handleLogo = () =>
    history.push(
      generatePath(routePath.home, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
      })
    );

  const error = Boolean(meError || studentError || courseEnrollmentsError);

  return {
    me,
    student,
    courseEnrollment,
    courseEnrollments,
    currentCourseEnrollmentsByDegree,
    pastCourseEnrollmentsByDegree,
    loading,
    error,
    handleLogout,
    handleLogo,
    setCourseEnrollmentId,
    selectedCourseEnrollmentType,
    setCourseEnrollmentType,
  };
};

export default useCourseSelectionPageData;
