import { useFindLearningObjectEnrollments } from '../../../services/learning-unit';
import { extractLearningObjectData } from '../utils';
import { useParams } from 'react-router-dom';
import { SectionParams } from '../../../shared';
import { SectionPageDataHook } from '../types';
import { useCourseEnrollmentsContext } from '../../../../shared/contexts/course-enrollments-context';
import { useMeContext } from '../../../../shared/contexts/me-context';

const useSectionPageData: SectionPageDataHook = () => {
  const {
    sectionPath,
    learningUnitEnrollmentId,
    learningObjectPath,
    subjectEnrollmentId,
  } = useParams<SectionParams>();

  const { me, loading: meLoading } = useMeContext()?.states?.getMe;
  const { data: courseEnrollment, loading: courseEnrollmentLoading } =
    useCourseEnrollmentsContext()?.states?.courseEnrollments;

  const subjectEnrollment =
    (!courseEnrollmentLoading &&
      courseEnrollment?.subjectEnrollments?.find(
        (enrollment) => enrollment.id === subjectEnrollmentId
      )) ||
    {};

  const {
    learningObjectEnrollments,
    learningUnitEnrollment,
    sectionEnrollment,
    loading: learningObjectLoading,
  } = useFindLearningObjectEnrollments(
    subjectEnrollmentId,
    learningUnitEnrollmentId,
    sectionPath
  );

  const loading = meLoading || courseEnrollmentLoading || learningObjectLoading;

  return {
    courseEnrollment,
    courseId: courseEnrollment?.course?.id || '',
    course: courseEnrollment?.course || null,
    externalId: courseEnrollment?.externalId || '',
    subjectEnrollment,
    me,
    learningUnit: learningUnitEnrollment || {},
    section: sectionEnrollment || {},
    learningObjects: learningObjectEnrollments,
    loading,
    learningObjectData: extractLearningObjectData(
      learningObjectEnrollments,
      learningObjectPath
    ),
  };
};

export default useSectionPageData;
