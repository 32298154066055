import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useReducer,
  useMemo,
} from 'react';
import { node, dataStorage, string, arrayOf, fastOmitDeep } from '@ampli/utils';

import useActions from './use-actions';
import reducers from './reducers';
import initialState from './initial-state';
import { isDebug } from '../config';
import { updateKindlePersistedProperties } from '../utils';
import { ScreenFallbackLoader } from '../modules';
import { locationPathname } from '../constants';

const reducer = (state, action) => {
  if (reducers[action.type]) {
    const newState = reducers[action.type](state, action);
    console.debug('REDUCER', action.type, { state, newState });
    return newState;
  }

  return state;
};

export const StateContext = createContext();

export const useGetState = () => useContext(StateContext);

const permanentDenyPersistProperties = [/^debug.*$/];

export const StateProvider = ({ denyPersistProperties = [], children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loadingState, setLoadingState] = useState(true);
  const actions = useActions(dispatch);

  const finalDenyPersistProperties = useMemo(
    () => [...permanentDenyPersistProperties, ...denyPersistProperties],
    [denyPersistProperties]
  );

  // reset state with state in storage
  useEffect(() => {
    if (loadingState) {
      dataStorage
        .getItem('state')
        .then(
          (state) =>
            state &&
            actions.resetState({
              ...initialState,
              ...state,
            })
        )
        .finally(() => setLoadingState(false))
        .catch(console.error);
    }

    if (isDebug) {
      window.AMPLI_ACTIONS = actions;
    }
  }, [loadingState, actions]);

  // save new state on storage
  useEffect(() => {
    if (!loadingState) {
      const updateProperty = updateKindlePersistedProperties(state);
      updateProperty({
        property: 'formatFont',
        oldValues: ['Noto Serif', 'standard', 'Roboto'],
        defaultValue: 'Roboto Slab',
      });
      updateProperty({
        property: 'fontSize',
        oldValues: [14, 22, 26, 30],
        defaultValue: 20,
      });
      dataStorage
        .getItem('state')
        .then((previousState) =>
          dataStorage.setItem('previousState', previousState)
        )
        .then(() =>
          dataStorage.setItem(
            'state',
            fastOmitDeep(state, finalDenyPersistProperties)
          )
        )
        .catch(console.error);
    }
  }, [state, loadingState, denyPersistProperties, finalDenyPersistProperties]);

  return loadingState ? (
    <ScreenFallbackLoader pathname={locationPathname()} />
  ) : (
    <StateContext.Provider value={[state, actions]}>
      {children}
    </StateContext.Provider>
  );
};

StateProvider.propTypes = {
  children: node.isRequired,
  denyPersistProperties: arrayOf(string),
};
