import styled from '@emotion/styled';

export const FlexColumn = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  > :first-of-type {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  ${({ active }) => active && 'margin-right: 16px;'}
`;

export const ActiveMarkContainer = styled.div`
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
`;

export const ActiveMark = styled.div<{ background: string }>`
  width: 12px;
  height: 12px;
  background: ${({ background }) => background};
  border-radius: 50px;
`;
