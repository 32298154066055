import { gql } from 'graphql.macro';

export const GET_AVAILABLE_INCOME_TAX_YEARS = gql`
  query GetAvailableIncomeTaxYears($courseEnrollmentId: String!) {
    data: getAvailableIncomeTaxYears(courseEnrollmentId: $courseEnrollmentId) {
      years
    }
  }
`;

export const GENERATE_INCOME_TAX_DOCUMENT = gql`
  mutation GenerateIncomeTaxDocument(
    $courseEnrollmentId: String!
    $year: String!
  ) {
    generateIncomeTaxDocument(
      courseEnrollmentId: $courseEnrollmentId
      year: $year
    ) {
      url
    }
  }
`;
