import { zendeskApiToken, zendeskUrl } from '../../../../../../../config';
import { UploadedFile } from '../../../shared';
import { isMobile } from '@ampli/utils';
import { request } from '@ampli/services';

import type { CreateTicket } from '../types';

import {
  zendeskCustomFieldsIds,
  createTicketUrl,
  ticketFormId,
} from '../config';

export const uploadFilesUrl = (filename: string): string =>
  `${zendeskUrl}/uploads?filename=${filename}`;

export type UseZendeskLogic = {
  createTicket: (values: Partial<CreateTicket>) => Promise<Response>;
};

export const useAmpliLogic = (): UseZendeskLogic => {
  const uploadFiles = async (attachments: UploadedFile[]) => {
    return await Promise.all(
      attachments.map(async ({ file }) => {
        const data = new Blob([file], { type: file.type });

        return request({
          url: uploadFilesUrl(file.name),
          method: 'POST',
          data,
          headers: {
            'Content-Type': file.type,
            Authorization: `Bearer ${zendeskApiToken}`,
          },
        }).then((data: { upload: { token: string } }) => data.upload.token);
      })
    );
  };

  const createTicket = async (
    values: Partial<CreateTicket>
  ): Promise<Response> => {
    const fileTokens = values.attachment
      ? await uploadFiles(values.attachment)
      : null;

    const isAmpli = values?.companyName?.startsWith('Ampli');

    const subject = isAmpli
      ? `[Ampli] - ${values?.description}`
      : `[PTK] - ${values?.description}`;

    const custom_fields = zendeskCustomFieldsIds.map((i) => ({
      id: i.id,
      value: {
        brand: isAmpli ? 'ampli' : 'anhanguera',
        requesterCpf: values?.me?.username,
        contactReason: 'duvida_pedagogica',
        internCategory: 'duvida_pedagogica___ajuda_do_tutor',
        course: values.course,
        discipline: values.subject,
        unity: values.unity,
        learningObject: `${values.class} - ${values.learningObject}`,
      }[i.field],
    }));

    const comment = {
      body: values.description,
      uploads: fileTokens,
    };

    const body = {
      ticket: {
        ticket_form_id: ticketFormId,
        requester: { name: values?.me?.fullName, email: values?.me?.email },
        subject,
        custom_fields,
        comment,
      },
      data: {
        device: isMobile() ? 'mobile' : 'desktop',
        brand: values.companyName,
        date_time_click: values.openTalkTutorFrameDate,
        date_time_send: new Date().toISOString(),
        time_elapsed_video: values.time_elapsed_video,
      },
    };

    return fetch(createTicketUrl, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${zendeskApiToken}`,
      },
    }).then((response) => response.json());
  };

  return { createTicket };
};
