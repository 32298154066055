import React from 'react';
import { Input } from '@sofia/ui';
import { object } from 'prop-types';

const MandatoryInternshipInfoFormResponsible = ({ formik }) => {
  return (
    <>
      <Input
        id="companyResponsible.name"
        label="Responsável pela empresa*"
        aria-label="Nome do responsável empresa"
        placeholder="Nome do responsável"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.companyResponsible?.name || ''}
        error={Boolean(
          formik.touched.companyResponsible?.name &&
            formik.errors.companyResponsible?.name
        )}
        infoText={
          formik.touched.companyResponsible?.name &&
          formik.errors.companyResponsible?.name
        }
        labelWeight="700"
        width="100%"
        required
      />
      <Input
        id="companyResponsible.role"
        label="Cargo*"
        aria-label="Cargo do responsável"
        placeholder="Cargo do responsável"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.companyResponsible?.role || ''}
        error={Boolean(
          formik.touched.companyResponsible?.role &&
            formik.errors.companyResponsible?.role
        )}
        infoText={
          formik.touched.companyResponsible?.role &&
          formik.errors.companyResponsible?.role
        }
        labelWeight="700"
        width="100%"
        required
      />
    </>
  );
};

MandatoryInternshipInfoFormResponsible.propTypes = {
  formik: object.isRequired,
};

export default MandatoryInternshipInfoFormResponsible;
