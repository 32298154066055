import fetch from 'isomorphic-unfetch';
import { trim, toLower } from 'lodash';
let ampliEnv;
export const getEnv = async function () {
  let location = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (ampliEnv) return ampliEnv;
  const response = await fetch(`${location}/env`);
  const env = await response.text();
  ampliEnv = trim(toLower(env));
  return ampliEnv;
};