import React, { useEffect, useState } from 'react';
import {
  serviceRequestSteps,
  serviceRequestKeys,
  serviceRequestStatus,
  serviceRequestTexts,
} from './constants';
import { useGetState } from '../../state';
import { useGetLastOrder } from './hooks';
import {
  ServiceRequestContent,
  ServiceRequestLoading,
  ServiceRequestReady,
} from './components';
import { string, object } from 'prop-types';

const ServiceRequest = ({ typeOfStatus, handlers }) => {
  const [step, setStep] = useState(serviceRequestSteps.default);
  const [itsExpirationDate, setItsExpirationDate] = useState(false);

  const [{ selectedCourseEnrollmentId }] = useGetState();

  const getTextsForRequestType = (requestType) => {
    return serviceRequestKeys[requestType];
  };

  const texts = serviceRequestTexts[getTextsForRequestType(typeOfStatus)];

  const { data, loading } = useGetLastOrder({
    courseEnrollmentId: selectedCourseEnrollmentId,
    orderSubject: texts?.orderSubject,
  });

  const stepComponents = {
    default: serviceRequestKeys[getTextsForRequestType(typeOfStatus)] !==
      'isStudentCard' && (
      <ServiceRequestContent
        loading={loading}
        typeOfStatus={getTextsForRequestType(typeOfStatus)}
        courseEnrollmentId={selectedCourseEnrollmentId}
        orderSubject={texts?.orderSubject}
        itsExpirationDate={itsExpirationDate}
        data={data}
        setStep={setStep}
      />
    ),
    loading: (
      <ServiceRequestLoading
        loading={loading}
        courseEnrollmentId={selectedCourseEnrollmentId}
        orderSubject={texts?.orderSubject}
        typeOfStatus={getTextsForRequestType(typeOfStatus)}
        setStep={setStep}
      />
    ),
    ready: (
      <ServiceRequestReady
        loading={loading}
        handlers={handlers}
        typeOfStatus={getTextsForRequestType(typeOfStatus)}
        courseEnrollmentId={selectedCourseEnrollmentId}
        orderSubject={texts?.orderSubject}
        setStep={setStep}
      />
    ),
  };

  const hasDocument = data?.orderUrl ? true : false;

  useEffect(() => {
    if (data) {
      if (hasDocument) {
        setStep('ready');
      } else {
        setStep(serviceRequestStatus[data?.status]);
      }
    }

    if (data?.status === 'EXPIRED') setItsExpirationDate(true);

    return () => {
      setItsExpirationDate(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, data?.status]);

  return stepComponents[step];
};

ServiceRequest.propTypes = {
  typeOfStatus: string,
  handlers: object,
};

export default ServiceRequest;
