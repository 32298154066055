import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  convertPXToVH,
  convertPXToVW,
  convertPXToREM,
  mediaQuery,
} from '@sofia/ui';

export const OnboardingModal = styled.div`
  ${() => css`
  .onboarding {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 24px 24px 0;

    ${mediaQuery('desktop')} {
      margin: 32px 32px 0;
    }
  }

  .onboarding__illustration {
    margin-right: auto;
    margin-left: auto;
  }

  .onboarding__title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 16px;

    ${mediaQuery('desktop')} {
      font-size: 20px;
    }

    .onboarding__description {
    line-height: 1.5;
    margin-bottom: 16px;
  }`}
`;

export const OnboardingModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${convertPXToVH('8px')};
  margin: 0 24px 24px;

  ${mediaQuery('desktop')} {
    margin: 0 32px 32px;
  }
`;

export const CloseIcon = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

interface ButtonProps extends React.ComponentProps<'button'> {
  size?: number | string;
  width?: number | string;
  margin?: number | string;
  color?: string;
  maxWidth?: string;
  hasBorder?: boolean;
}

const Button = styled.button<ButtonProps>`
  border: 2px solid transparent;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: ${({ content = 'center' }) => content};
  border-radius: 8px;
  font-size: ${({ size }) => convertPXToREM(size)};
  font-weight: bold;
  line-height: 1.5;
  outline: 0;
  cursor: pointer;
  padding: ${convertPXToVH(16)} ${convertPXToVW(24)};
  text-align: center;
  width: ${({ width = '100%' }) => width};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};

  &:disabled {
    opacity: 0.5;
  }

  ${mediaQuery('desktop')} {
    padding: ${convertPXToVH(19)} ${convertPXToVW(24)};
    max-width: ${({ maxWidth = '250px' }) => convertPXToVW(maxWidth)};
  }
`;

export const ButtonOutline = styled(Button)`
  background: transparent;
  border-color: ${({ theme, hasBorder = false }) =>
    hasBorder ? theme.colors.button.primary : 'transparent'};
  color: ${({ theme }) => theme.colors.button.primary};

  &:hover {
    background: ${({ theme }) => theme.colors.background.primary};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.background.primary};
  }
`;

export const ButtonPrimary = styled(Button)`
  background: ${({ theme }) => theme.colors.button.primary};
  border-color: transparent;
  color: ${({ theme }) => theme.colors.text.white};

  &:hover {
    background: ${({ theme }) => theme.colors.card.background.hover};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.card.background.hover};
  }
`;
