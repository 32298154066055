import React from 'react';

import { TalkTutorFrameProps } from '../../talk-tutor-frame';
import { SectionRightFrame } from '../../../../..';
import { useAmpliFrame } from './contexts/ampli-frame-context';

import * as Sections from './sections';

type AmpliFrameProps = TalkTutorFrameProps & {
  onCloseClick: () => void;
  isOpen: boolean;
};

export const AmpliFrame: React.FC<AmpliFrameProps> = ({
  hideAttachmentField,
  onCloseClick,
  infos,
  isOpen,
}: AmpliFrameProps) => {
  const { page, dispatch, onClose } = useAmpliFrame();

  const Content = {
    FORM: Sections.FormSection,
    ERROR: Sections.ErrorSection,
    SUCCESS: Sections.SuccessSection,
  }[page];

  React.useEffect(() => {
    dispatch({ infos, hideAttachmentField, onCloseClick });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, hideAttachmentField]);

  return (
    <SectionRightFrame
      title="Falar com o tutor"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Content />
    </SectionRightFrame>
  );
};
