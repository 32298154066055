import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW } from '@sofia/ui';

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const LegendsWrapper = styled(FlexCenter)`
  column-gap: ${convertPXToVW(8)};
  justify-content: center;
`;

export const PunctuationWrapper = styled(FlexCenter)`
  justify-content: space-between;
  padding: ${convertPXToVH(24)} ${convertPXToVW(24)};
`;

export const CardWrapper = styled.div`
  padding: ${convertPXToVH(24)} ${convertPXToVW(24)};

  & > div + div,
  & > p + div {
    margin-top: ${convertPXToVH(24)};
  }
`;
