import { gql } from 'graphql.macro';

export const GET_COURSE_ENROLLMENT_ROLES_AND_STATUS = gql`
  query GetCourseEnrollmentRolesAndStatus($courseEnrollmentId: ID!) {
    data: getCourseEnrollment(courseEnrollmentId: $courseEnrollmentId) {
      id
      roles
      status
      course {
        id
        name
      }
    }
  }
`;

export const GET_COMPANY_NAME = gql`
  query GetCompanyName($courseEnrollmentId: String) {
    data: getStudentEducationProvider(courseEnrollmentId: $courseEnrollmentId) {
      name
    }
  }
`;

export const GET_SUBJECT_NAME = gql`
  query GetSubjectEnrollmentName($subjectEnrollmentId: ID!) {
    data: getSubjectEnrollment(subjectEnrollmentId: $subjectEnrollmentId) {
      id
      subject {
        id
        name
      }
    }
  }
`;

export const GET_TUTOR = gql`
  query GetTutor($courseEnrollmentId: ID!) {
    data: getCourseEnrollmentStaffList(
      courseEnrollmentId: $courseEnrollmentId
    ) {
      tutors {
        subjectName
        tutor {
          id
          name
          email
          picture
        }
      }
    }
  }
`;

export const GENERATE_TUTOR_TOKEN = gql`
  mutation GenerateSecretToAccessContent(
    $learningUnitEnrollmentId: ID!
    $sectionId: ID!
  ) {
    data: generateSecretToAccessContent(
      learningUnitEnrollmentId: $learningUnitEnrollmentId
      sectionId: $sectionId
    )
  }
`;
