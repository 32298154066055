export const AK_TAB = 9;
export const AK_ENTER = 13;
export const AK_SPACE = 32;
export const AK_ESC = 27;
export const AK_LEFT = 37;
export const AK_UP = 38;
export const AK_RIGHT = 39;
export const AK_DOWN = 40;

export const ESC_KEY = 'Escape';
export const ENTER_KEY = 'Enter';

export const ignoreStyles = [/-(webkit|moz|ms|o)-/];
