import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { inspect } from 'util';
import * as Sentry from '@sentry/browser';
const levels = {
  error: 0,
  warn: 1,
  info: 2,
  verbose: 3,
  debug: 4,
  silly: 5
};
const termColors = {
  red: '31',
  green: '32',
  olive: '33',
  blue: '34'
};
const originalConsole = {
  log: console.log
};
Object.keys(levels).forEach(level => {
  if (console[level]) {
    originalConsole[level] = console[level];
  }
});
const createLogger = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let {
    appName,
    logLevel = 'info',
    debugMode = false,
    isNode = true,
    inspectOptions = {},
    sentrySettings = null
  } = options;
  if (sentrySettings) {
    Sentry.init(_objectSpread(_objectSpread({}, sentrySettings), {}, {
      ignoreErrors: [/^Warning: /, 'UNAUTHENTICATED', 'Authentication failed', /^Network error: /, /^BAD_USER_INPUT: /, ...(sentrySettings.ignoreErrors || [])],
      allowUrls: [/^https:\/\/(.+\.)?ampli\.com\.br/i, ...(sentrySettings.allowUrls || [])]
    }));
  }
  const color = (color, str) => {
    if (!debugMode) return [str];
    if (isNode) {
      return [`\x1b[${termColors[color]}m${str}\x1b[0m`];
    }
    return [`%c${str}`, `color: ${color};`];
  };
  const colors = {};
  Object.keys(termColors).forEach(colorName => {
    colors[colorName] = str => color(colorName, str);
  });
  const levelColor = {
    error: colors.red,
    warn: colors.olive,
    info: colors.blue,
    debug: colors.green,
    silly: function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return args;
    }
  };
  inspectOptions = _objectSpread({
    colors: debugMode
  }, inspectOptions);
  logLevel = logLevel.toLowerCase();
  const prepareArgs = function (level) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }
    args = args.map(a => a === Object(a) && isNode ? debugMode ? inspect(a, inspectOptions) : JSON.stringify(a) : a);
    args.unshift(level);
    !debugMode && args.unshift(new Date().toISOString().replace(/[TZ]/g, ' '));
    return args;
  };
  const log = function (logFunc) {
    const log = originalConsole[logFunc] || originalConsole.log;
    for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
      args[_key3 - 1] = arguments[_key3];
    }
    log && log(...prepareArgs(...args));
    if (logFunc === 'error' && sentrySettings) {
      const errorIndex = debugMode && !isNode ? 2 : 1;
      let error = args[errorIndex] || 'UNKNOWN ERROR';
      if (!(error instanceof Error)) {
        error = new Error(error.message || error);
      }
      Sentry.withScope(scope => {
        if (error.info) {
          scope.setExtras(error.info);
        }
        scope.setTag('app.name', appName);
        Sentry.captureException(error);
      });
    }
  };
  const logger = {};
  Object.keys(levels).forEach(level => {
    logger[level] = function () {
      if (levels[logLevel] >= levels[level]) {
        for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
          args[_key4] = arguments[_key4];
        }
        log(level, ...levelColor[level](level.toUpperCase()), ...args);
      }
    };
  });
  return logger;
};
const hijackConsole = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const logger = createLogger(options);
  Object.keys(levels).forEach(level => {
    console[level] = logger[level];
  });
  return console;
};
export { Sentry, hijackConsole };
export default createLogger;