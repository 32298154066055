import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';
import useInternshipBaseData from './use-internship-base-data';
import { useParams } from 'react-router-dom';
import { useGetInternship } from './services/internship';

const useInternshipPageData = () => {
  const { subjectEnrollmentId } = useParams();

  const { me, handleLogo, handleLogout, baseLoading } = useInternshipBaseData();

  const { states } = useCourseEnrollmentsContext();
  const { data: courseEnrollment, loading: courseEnrollmentLoading } =
    states.courseEnrollments;

  const { data: subjectEnrollment, loading: subjectEnrollmentLoading } =
    useGetInternship(subjectEnrollmentId);

  return {
    me,
    handleLogo,
    handleLogout,
    subjectEnrollment,
    course: courseEnrollment?.course || {},
    loading: courseEnrollmentLoading || baseLoading || subjectEnrollmentLoading,
  };
};

export default useInternshipPageData;
