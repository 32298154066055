import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const Wrapper = {
  Content: styled.div`
    gap: 32px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.card.background.secondary};
  `,

  ItemDescription: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  `,

  Svg: styled.div`
    width: ${convertPXToREM(24)};
    height: ${convertPXToREM(24)};
  `,
};
