import React from 'react';
import { useBreakpoint } from '@ampli/hooks';

/*eslint import/namespace: ['error', { allowComputed: true }]*/
import FreeTrialMessage from './free-trial/free-trial-message-component';
import * as assetComponents from '../../../../../components/asset';
import SectionContentHeader from './header/section-content-header';
import * as Styled from './section-content-styles';

import { useGetState } from '../../../../../state';
import { useGetVideoProps } from '../hooks';
import {
  freeTrialEnabledRole,
  unavailableFreeTrialStatus,
} from '../../../../../constants';
import {
  TEMPLATE_CUSTOMIZATION_COLOR,
  defaultCustomization,
} from '../menu/template/customization/constants';
import Skeleton from '../../skeleton/skeleton';
import { TLearningObject } from '../../../../section/shared';
import { SectionContentProps } from './types';
import { CustomizationDataProps, EAssetElement } from '../types';
import { CustomizationTemplateType } from '../menu/template/customization/types';
import { getTemplateAsset } from './helper';
import { AssetItem, AssetType } from '../../../graphql/types';
import useReadingRuler from '../../reading-ruler/use-reading-ruler';

const SectionContent = ({
  pageTitle,
  customization,
  template,
  setIsVideoViewCompleted,
  studentFreeTrialCourseData,
  learningObject,
  loading,
}: SectionContentProps): React.ReactElement => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const [
    { selectedCourseEnrollmentType, selectedCourseVersionId, videoTime },
    { setVideoTime },
  ] = useGetState();

  const assets = (learningObject?.assets || []) as Array<AssetItem>;
  const isVideoObject = assets.find((asset) => asset?.type === AssetType.Video);
  const isFirstAssetAsBanner = assets[0]?.type === AssetType.Image;

  const templateTitleObject = React.useMemo(() => {
    const actualElement =
      EAssetElement[
        isVideoObject ? 'VIDEO' : isFirstAssetAsBanner ? 'IMAGE' : 'TITLE'
      ];
    const actualTemplateAsset = getTemplateAsset(actualElement, template);
    return {
      color: actualTemplateAsset?.fontColor,
      element:
        !isVideoObject && !isFirstAssetAsBanner ? actualTemplateAsset : null,
    };
  }, [isFirstAssetAsBanner, isVideoObject, template]);

  const templateSubtitle = React.useMemo(
    () => getTemplateAsset(EAssetElement.SUBTITLE, template),
    [template]
  );
  const templateImage = React.useMemo(
    () => getTemplateAsset(EAssetElement.IMAGE, template),
    [template]
  );
  const templateVideo = React.useMemo(
    () => getTemplateAsset(EAssetElement.VIDEO, template),
    [template]
  );

  const isFreeTrialEnabled = studentFreeTrialCourseData
    ? studentFreeTrialCourseData.freeTrialSituation &&
      !unavailableFreeTrialStatus.includes(
        studentFreeTrialCourseData.freeTrialSituation
      ) &&
      studentFreeTrialCourseData?.roles?.includes(freeTrialEnabledRole)
    : false;

  const sectionLearningObject = learningObject as TLearningObject;

  const videoAssetProps = useGetVideoProps({
    learningObject,
    videoTime,
    setVideoTime,
    setIsVideoViewCompleted,
  });

  const customizationValue = (customization ||
    defaultCustomization(
      isDesktop,
      TEMPLATE_CUSTOMIZATION_COLOR[
        template?.name as keyof CustomizationTemplateType
      ]
    )) as CustomizationDataProps;

  const propsByAsset = customizationValue && {
    VIDEO: {
      pageTitle,
      ...videoAssetProps,
      ...(templateVideo && isVideoObject ? { template: templateVideo } : {}),
    },
    IMAGE: {
      banner: true,
      ...(templateImage && !isVideoObject && isFirstAssetAsBanner
        ? { template: templateImage }
        : {}),
    },
    TABLE: {
      custom: {
        color: customizationValue.theme.color,
        fontSize: customizationValue.fontSize,
      },
    },
  };

  /* READING RULER */
  const [assetsRef, setAssetsRef] = React.useState<
    Array<HTMLDivElement | null>
  >([]);
  const { contentRef, readingRulerComponent } = useReadingRuler({ assetsRef });
  /* END READING RULER */

  return (
    <Styled.SectionContentContainer
      id="section-content"
      ref={contentRef}
      data-testid="section-content"
      background={
        !loading
          ? template?.background || customizationValue.theme.background
          : ''
      }
    >
      {readingRulerComponent}

      {isFreeTrialEnabled ? (
        <FreeTrialMessage
          freeTrialDaysLeft={studentFreeTrialCourseData?.freeTrialDaysLeft}
          selectedCourseEnrollmentType={selectedCourseEnrollmentType}
          selectedCourseVersionId={selectedCourseVersionId}
        />
      ) : null}

      <Styled.ContentContainer
        defaultFontSize={defaultCustomization(isDesktop)?.fontSize}
        customization={customizationValue}
        isDesktop={isDesktop}
        templateSubtitle={templateSubtitle}
        templateTitleFontColor={templateTitleObject.color}
        data-testid="section-content-container"
      >
        <SectionContentHeader
          title={sectionLearningObject?.title}
          subtitle={sectionLearningObject?.subtitle || ''}
          loading={loading}
          templateTitle={templateTitleObject.element}
        />
        <Styled.Container>
          {loading ? (
            <>
              <Skeleton height="40vh" margin="24px 0 64px 0" />
              {/* text asset skeleton */}
              <Skeleton height="24px" margin="0 0 8px 0" />
              <Skeleton height="24px" width="90%" margin="0 0 8px 0" />
              <Skeleton height="24px" width="80%" margin="0 0 8px 0" />
              <Skeleton height="24px" width="95%" margin="0 0 8px 0" />
            </>
          ) : (
            learningObject?.assets?.map((asset: AssetItem, index: number) => {
              const Asset = asset.__typename
                ? assetComponents[asset.__typename]
                : null;
              if (!Asset) return null;

              return (
                <div
                  key={index}
                  ref={(el) => {
                    if (
                      asset.type !== AssetType.Text &&
                      asset.type !== AssetType.Formula &&
                      !assetsRef.includes(el)
                    ) {
                      setAssetsRef([...assetsRef, el]);
                    }
                  }}
                >
                  <Asset
                    data={asset as never}
                    {...propsByAsset[asset.type as keyof typeof propsByAsset]}
                  />
                </div>
              );
            }) || null
          )}
        </Styled.Container>
      </Styled.ContentContainer>
    </Styled.SectionContentContainer>
  );
};

SectionContent.defaultProps = {
  loading: false,
  learningObject: {},
};

export default SectionContent;
