import React from 'react';

import { UseWebChatReturn, UseWebChatProps } from '../../types';
import { useChatEventsHandler } from './use-chat-events-handler';
import { useStartConversation } from './use-start-conversation';
import { useRenderWebChat } from './use-render-webchat';

export const useWebChat = ({
  talkTutorInfos,
  directLineURL,
  quizPagePayload,
  isQuizPageRendered,
  token,
}: UseWebChatProps): UseWebChatReturn => {
  const WebChat = React.useMemo(() => window?.WebChat, []);

  const directLine = React.useMemo(
    () =>
      WebChat?.createDirectLine({
        token,
        domain: new URL('v3/directline', directLineURL),
      }),
    [WebChat, directLineURL, token]
  );

  // Renderiza o componente do webchat
  useRenderWebChat(directLine);

  // Inicia o chat passando os parametros iniciais
  useStartConversation(directLine, quizPagePayload, isQuizPageRendered);

  // Gerencia eventos enviados e recebidos do copilot
  const {
    getFileBlob,
    isUploadButtonAllowed,
    isChatInteractionAllowed,
    shouldChatBeBlocked,
    isUploading,
  } = useChatEventsHandler({ directLine, talkTutorInfos, isQuizPageRendered });

  return {
    loading: !token || !directLine,
    isUploadButtonAllowed,
    isChatInteractionAllowed,
    shouldChatBeBlocked,
    getFileBlob,
    isUploading,
  };
};
