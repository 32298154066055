import React from 'react';

import CommunityMenuHeader from './community-menu-header.presentation';
import DiscardPublication from '../../../create-publication/components/status-modals/discard-publication';
import Menu from '../../../../../shared/components/section/menu/menu';

import { usePublicationFormContext } from '../../../../contexts/publication-form-context';
import { PublicationFormSection } from '../../../create-publication/components/publication-form';
import { useGetState } from '../../../../../../state';
import { useModal } from '@sofia/ui';

const CommunityMenu: React.FC = () => {
  const { states } = usePublicationFormContext();
  const modal = useModal();

  const [{ openCommunitySection }, { setOpenCommunitySection }] = useGetState();

  const onClose = () => {
    if (states.isTextStep) {
      modal.show();
      return;
    }
    setOpenCommunitySection(false);
  };

  return (
    <>
      <Menu
        open={openCommunitySection}
        fromRight
        absolute
        closedSize={0}
        header={
          <CommunityMenuHeader
            title="Criar publicação"
            onToggleMenuClick={onClose}
          />
        }
        content={<PublicationFormSection />}
        scroll="none"
      />
      <DiscardPublication modal={modal} />
    </>
  );
};

export default CommunityMenu;
