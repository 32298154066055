export type AlertType = 'warning' | 'info' | 'danger' | 'default';

export interface AlertInterface {
  type: AlertType;
  message: string | string[];
}

interface ThemeProps {
  color: string;
  background: string;
  colorIcon: string;
  type: string;
}

const typeThemes = {
  default: {
    color: '#212529',
    background: '#F3F6F9',
    colorIcon: '#212529',
    type: 'default',
  },
  info: {
    color: '#212529',
    background: '#E5EDFA',
    colorIcon: '#0041AF',
    type: 'info',
  },
  warning: {
    color: '#212529',
    background: '#FFE6C9',
    colorIcon: '#DF7900',
    type: 'warning',
  },
  danger: {
    color: '#212529',
    background: '#F6E4DF',
    colorIcon: '#A52C05',
    type: 'danger',
  },
};

const getTheme = (type: string): ThemeProps => {
  const filteredThemes = Object.values(typeThemes).filter(
    (theme) => theme.type === type
  );

  return filteredThemes.length > 0 ? filteredThemes[0] : typeThemes.info;
};

export default getTheme;
