import React from 'react';

import {
  Card,
  convertPXToVH,
  convertPXToVW,
  SvgArrowRight,
  SvgExternalLinkAlt,
  Typography,
} from '@sofia/ui';

import { func, node, string, bool } from 'prop-types';
import { useTheme } from '@emotion/react';

import { LinkButton, Wrapper } from './home-styles';

export const SimpleCard = ({
  icon,
  title,
  description,
  onClick,
  onMouseOver,
  id,
  isExternalLink,
  ...cardProps
}) => {
  const theme = useTheme();

  return (
    <Card
      id={id}
      data-testid={id}
      onClick={onClick}
      onMouseOver={onMouseOver}
      hover={theme.colors.card.color.hover}
      minWidth={convertPXToVW(456)}
      borderColor={theme.colors.card.border.primary}
      grid="span 2"
      mobileGrid="span 3"
      minWidthMobile={convertPXToVW(310)}
      {...cardProps}
    >
      <Wrapper id={`internal-${id}`} data-testid={`internal-${id}`}>
        {icon}
        <Typography
          margin={`${convertPXToVH(16)} 0px`}
          weight="700"
          size={24}
          color={theme.colors.text.black}
          data-testid={`titleCard-${id}`}
          id={`titleCard-${id}`}
        >
          {title}
        </Typography>
        <Typography
          margin={`0px 0px ${convertPXToVH(35)} 0px`}
          color={theme.colors.text.lightBlack}
          size={20}
          weight="400"
          id={`descriptionCard-${id}`}
          data-testid={`descriptionCard-${id}`}
        >
          {description}
        </Typography>
        <LinkButton id={`access-${id}`} data-testid={`access-${id}`}>
          <Typography
            margin={`0px ${convertPXToVW(13)} 0px 0px`}
            as="span"
            weight="700"
            color={theme.colors.text.primary}
            id="access-card"
            data-testid="access-card"
          >
            Acessar
          </Typography>
          {isExternalLink ? (
            <SvgExternalLinkAlt fill={theme.colors.text.primary} />
          ) : (
            <SvgArrowRight fill={theme.colors.text.primary} />
          )}
        </LinkButton>
      </Wrapper>
    </Card>
  );
};

SimpleCard.propTypes = {
  title: string,
  description: string,
  icon: node,
  id: string,
  onClick: func,
  onMouseOver: func,
  isExternalLink: bool,
};
