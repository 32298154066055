import * as React from 'react';
import { getColor } from '@ampli/ui';

function SvgSecurityAlertImage(props) {
  const brandBase = getColor('brand-base');

  return (
    <svg width={215} height={174} fill="none" {...props}>
      <path
        opacity={0.18}
        d="M61.56 19.192c-3.793 3.934-6.932 8.446-10.252 12.775-12.53 16.233-27.972 30.175-38.93 47.525-10.96 17.35-16.923 39.875-8.163 58.41 5.907 12.517 17.686 21.386 30.324 26.912A83.745 83.745 0 0068.868 172c24.696-.292 47.593-12.414 71.766-17.631 10.714-2.29 21.701-3.269 32.295-6.075 7.507-1.981 14.854-4.93 21.074-9.562 17.252-12.792 23.352-37.218 18.26-58.158-5.092-20.94-19.735-38.507-36.885-51.447C146.33 7.139 91.235-11.62 61.56 19.192z"
        fill={brandBase}
      />
      <path
        d="M64.336 39.869h.583V10.596A10.015 10.015 0 0174.934.581h65.19a10.016 10.016 0 0110.015 10.016v12.825h.221a.588.588 0 01.589.588v5.736a.586.586 0 01-.589.588h-.221v5.741h.273a.587.587 0 01.588.588V47.52a.59.59 0 01-.588.588h-.273v4.21h.247a.586.586 0 01.588.588v10.958a.59.59 0 01-.588.588h-.247v99.312a10.012 10.012 0 01-10.015 10.015h-65.19a10.016 10.016 0 01-10.015-10.015v-102.9h-.583a.588.588 0 01-.588-.588V40.457a.588.588 0 01.588-.588z"
        fill="#444"
      />
      <path
        d="M104.843 8.297h9.913a1.123 1.123 0 001.122-1.122v-.429a1.12 1.12 0 00-1.122-1.122h-9.913a1.125 1.125 0 00-1.122 1.122v.43a1.124 1.124 0 001.122 1.121zM100.68 8.334a1.372 1.372 0 110-2.743 1.372 1.372 0 010 2.743z"
        fill="#fff"
      />
      <path
        d="M76.95 5.092h9.762v1.174a5.574 5.574 0 005.574 5.574h31.096a5.569 5.569 0 005.574-5.569V5.092h9.152a6.768 6.768 0 016.768 6.769v150.636a6.769 6.769 0 01-6.768 6.768H76.951a6.765 6.765 0 01-6.766-6.765V11.86a6.765 6.765 0 016.763-6.768h.003z"
        fill="#fff"
      />
      <path
        d="M94.43 150.987h26.2a7.067 7.067 0 007.064-7.064 7.063 7.063 0 00-7.064-7.064h-26.2a7.063 7.063 0 100 14.128z"
        fill="#3BDBB8"
      />
      <path
        d="M84.155 92.963h46.75a2.442 2.442 0 002.44-2.44 2.442 2.442 0 00-2.44-2.44h-46.75a2.44 2.44 0 000 4.88zM84.155 100.155h46.75a2.442 2.442 0 002.44-2.44 2.442 2.442 0 00-2.44-2.44h-46.75a2.44 2.44 0 000 4.88zM84.155 107.348h46.75a2.441 2.441 0 000-4.88h-46.75a2.442 2.442 0 00-2.44 2.44 2.438 2.438 0 002.44 2.44zM84.155 114.54h46.75a2.441 2.441 0 000-4.88h-46.75a2.442 2.442 0 00-2.44 2.44 2.438 2.438 0 002.44 2.44zM116.007 121.732h14.898a2.441 2.441 0 000-4.88h-14.898c-.647 0-1.268.257-1.726.714a2.444 2.444 0 000 3.451 2.441 2.441 0 001.726.715z"
        fill="#D6E1EB"
      />
      <path
        d="M76.839 72.966h61.384a2.047 2.047 0 002.046-2.047V25.361a2.045 2.045 0 00-2.046-2.047H76.839a2.049 2.049 0 00-2.047 2.047v45.558a2.049 2.049 0 002.047 2.047z"
        fill="#F5F7FB"
      />
      <path
        d="M107.384 67.805c-10.977 0-19.875-8.899-19.875-19.875 0-10.977 8.898-19.875 19.875-19.875 10.976 0 19.875 8.898 19.875 19.875 0 10.976-8.899 19.875-19.875 19.875z"
        fill={brandBase}
      />
      <path
        d="M99.15 56.589h16.467a1.138 1.138 0 001.136-1.136v-9.937a1.14 1.14 0 00-1.136-1.136H99.149a1.136 1.136 0 00-1.136 1.136v9.937a1.137 1.137 0 001.136 1.136zm16.467-11.073v9.937H99.148l.001-9.937h16.468z"
        fill="#fff"
      />
      <path
        d="M102.273 45.516h10.221v-4.543c0-3.29-2.149-5.679-5.11-5.679-2.962 0-5.111 2.388-5.111 5.679v4.543zm9.086-1.136h-7.95v-3.407c0-2.675 1.634-4.543 3.975-4.543 2.34 0 3.975 1.868 3.975 4.543v3.407zM107.383 51.336a1.135 1.135 0 110-2.27 1.135 1.135 0 010 2.27z"
        fill="#fff"
      />
      <path
        opacity={0.1}
        d="M65.966 59.961v-1.063c.015.274.015.55 0 .824.003.08 0 .16 0 .24z"
        fill="#444"
      />
    </svg>
  );
}

export default SvgSecurityAlertImage;
