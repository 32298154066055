import React, { forwardRef } from 'react';
import { Flex, FlexItem, Button, Text, Icon, css, px2grid } from '@ampli/ui';
import * as Styled from './section-navigation.styles';
import { SectionNavigationProps } from './types';

const SectionNavigation = forwardRef(
  (
    {
      refNextButton,
      refPreviousButton,
      onPreviousClick,
      onNextClick,
      isLastStep,
      lastStepLabel,
      buttonSize,
      nextLoading,
      ariaLabelPreviousButton,
      ariaLabelNextButton,
      nextButtonLabel,
      previousButtonLabel,
      ...props
    }: SectionNavigationProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Flex data-testid="section-navigation">
        <div ref={ref} {...props}>
          <FlexItem flex={1}>
            <Button
              data-testid="section-navigation-previous"
              ref={refPreviousButton}
              size={buttonSize}
              className={Styled.buttonClassName}
              disabled={!onPreviousClick}
              onClick={onPreviousClick}
              aria-label={ariaLabelPreviousButton}
            >
              <Text
                color="accent-base"
                size="x-large"
                className={css`
                  margin-right: ${px2grid(16)};
                `}
              >
                <Icon.ArrowLeftCircle aria-hidden="true" />
              </Text>
              {previousButtonLabel}
            </Button>
          </FlexItem>
          <FlexItem flex={1}>
            <Button
              data-testid="section-navigation-next"
              ref={refNextButton}
              size={buttonSize}
              className={
                isLastStep
                  ? Styled.completeButtonClassName
                  : Styled.buttonClassName
              }
              disabled={!onNextClick}
              onClick={onNextClick}
              loading={nextLoading}
              aria-label={ariaLabelNextButton}
            >
              {isLastStep ? (
                lastStepLabel
              ) : (
                <>
                  {nextButtonLabel}
                  <Text
                    data-testid="section-navigation-next-content"
                    color="accent-base"
                    size="x-large"
                    className={css`
                      margin-left: ${px2grid(16)};
                    `}
                  >
                    <Icon.ArrowRightCircle aria-hidden="true" />
                  </Text>
                </>
              )}
            </Button>
          </FlexItem>
        </div>
      </Flex>
    );
  }
);

SectionNavigation.defaultProps = {
  isLastStep: false,
  lastStepLabel: 'Concluir',
  buttonSize: 'large',
  nextLoading: false,
  nextButtonLabel: 'Próxima',
  previousButtonLabel: 'Anterior',
};

SectionNavigation.displayName = 'SectionNavigation';

export default SectionNavigation;
