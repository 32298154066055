import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import camelCase from './camel-case';
import { ignoreStyles } from '../../constants';
const getNodeStyle = sourceNode => {
  if (window.getComputedStyle) {
    const computedStyle = window.getComputedStyle(sourceNode);
    return Array.from(computedStyle).reduce((reduced, computedStyleKey) => ignoreStyles.some(ignoreStyle => ignoreStyle.test(computedStyleKey)) ? reduced : _objectSpread(_objectSpread({}, reduced), {}, {
      [camelCase(computedStyleKey)]: computedStyle.getPropertyValue(computedStyleKey)
    }), {});
  }
};
export default getNodeStyle;