import React from 'react';

import { SvgCommentMessage, SvgArrowRight } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { generatePath, useHistory } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import { useHistoricConversations } from '../../hooks/use-historic-conversations';
import { ConversationChatButtons } from '../conversation-chat-buttons';
import { useTutorConversations } from '../../hooks/use-tutor-conversations';
import { useHomeConversations } from '../../hooks/use-home-conversations';
import { MessagingRoutesPath } from '../../../../../../routes';
import { useGetState } from '../../../../../../../../state';
import { usePtkFrame } from '../../contexts';
import { useFrame } from '../../../../shared';

import * as Styled from './frame-footer.styles';

const FrameFooter: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const { dispatch, page, conversation, subjectEnrollment } = usePtkFrame();
  const { isCourseEnrollmentBlocked } = useFrame();

  const [
    { selectedCourseEnrollmentType: courseType, selectedCourseVersionId },
  ] = useGetState();

  const { data: historic } = useHistoricConversations();
  const { data: tutor } = useTutorConversations();
  const { data: home } = useHomeConversations();

  const hasHistoricUnreadMessages = React.useMemo(
    () =>
      Boolean(
        historic?.content?.some?.(({ readStatus }) => readStatus === 'UNREAD')
      ),
    [historic?.content]
  );

  const homeFooterValidation = React.useMemo(
    () => page === 'HOME' && home?.content?.length,
    [home?.content?.length, page]
  );

  const tutorFooterValidation = React.useMemo(
    () => page === 'HOME' && tutor?.content?.length,
    [tutor?.content?.length, page]
  );

  const formTypeSelectionValidation = React.useMemo(
    () =>
      page === 'FORM_TYPE_SELECTION' &&
      historic?.content?.length &&
      !home?.content?.length &&
      !tutor?.content?.length,
    [
      historic?.content?.length,
      home?.content?.length,
      page,
      tutor?.content?.length,
    ]
  );

  const showFooter = React.useMemo(
    () =>
      homeFooterValidation ||
      formTypeSelectionValidation ||
      tutorFooterValidation,
    [formTypeSelectionValidation, homeFooterValidation, tutorFooterValidation]
  );

  const isClosed = React.useMemo(
    () => conversation?.status === 'FINALIZADA',
    [conversation?.status]
  );

  const showConversationChatButtons = React.useMemo(
    () =>
      (page === 'CONVERSATION_CHAT' || (!isDesktop && page === 'HISTORIC')) &&
      conversation?.id &&
      !isCourseEnrollmentBlocked &&
      !isClosed &&
      conversation?.agent === 'STUDENT',
    [
      isCourseEnrollmentBlocked,
      conversation?.agent,
      conversation?.id,
      isDesktop,
      isClosed,
      page,
    ]
  );

  const onHistoricClick = () =>
    history.push(
      generatePath(MessagingRoutesPath.historic, {
        courseType,
        courseVersionId: selectedCourseVersionId,
        subjectEnrollmentId: subjectEnrollment?.id,
      })
    );

  if (showConversationChatButtons)
    return (
      <Styled.FrameFooter>
        <ConversationChatButtons />
      </Styled.FrameFooter>
    );

  return showFooter ? (
    <Styled.FrameFooter>
      <Styled.ToFormButton
        type="button"
        color={theme.colors.text.primary}
        background="transparent"
        onClick={() => onHistoricClick()}
      >
        {hasHistoricUnreadMessages ? <Styled.UnreadSignal /> : null}
        Todas as mensagens <SvgArrowRight fill={theme.colors.text.primary} />
      </Styled.ToFormButton>

      {!isCourseEnrollmentBlocked && page === 'HOME' ? (
        <Styled.ToFormButton
          type="button"
          color={theme.colors.text.white}
          background={theme.colors.button.primary}
          onClick={() => dispatch({ page: 'FORM_TYPE_SELECTION' })}
        >
          Falar com o tutor <SvgCommentMessage fill={theme.colors.text.white} />
        </Styled.ToFormButton>
      ) : null}
    </Styled.FrameFooter>
  ) : null;
};

export default FrameFooter;
