import { lazy } from 'react';

export const InauguralSubjectPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Inaugural subject" */ '../pages/subject/inaugural-subject-page'
    )
);
export const InauguralSubjectSectionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Inaugural subject section page" */ '../pages/section/inaugural-subject-section-page'
    )
);

const InauguralSubjectModule = lazy(
  () => import(/* webpackChunkName: "Inaugural subject module" */ './module')
);
export default InauguralSubjectModule;
