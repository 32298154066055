import { useState, useCallback } from 'react';
import { useFormik } from '@ampli/utils';
import { useUploadStudentDocument } from '@ampli/services';

const useUploadDocumentWithBackForm = ({
  schemaOptions = {},
  onSubmit,
  onError,
  onCompleted,
}) => {
  const [hasBackDocument, setHasBackDocument] = useState(false);
  const handleCompleted = ({ data, errors }) => {
    if (!data || errors) {
      onError && onError(errors);
    } else {
      onCompleted && onCompleted();
    }
  };
  const [mutateFront, metadataFront, schema] = useUploadStudentDocument({
    onError,
    onCompleted: handleCompleted,
  });
  const [mutateBack, metadataBack] = useUploadStudentDocument({
    onError,
    onCompleted: handleCompleted,
  });

  let data, called, loading, error;

  if (hasBackDocument) {
    data = metadataFront.data && metadataBack.data;
    called = metadataFront.called && metadataBack.called;
    loading = metadataFront.loading || metadataBack.loading;
    error = metadataFront.error || metadataBack.error;
  } else {
    data = metadataFront.data;
    called = metadataFront.called;
    loading = metadataFront.loading;
    error = metadataFront.error;
  }

  const mutate = useCallback(
    async (variables) => {
      const variablesFront = {
        documentType: variables.documentType,
        documentFile: variables.documentFile,
      };
      await mutateFront({ variables: variablesFront });

      if (variables.documentFileBack) {
        const variablesBack = {
          documentType: `${variables.documentType}_BACK`,
          documentFile: variables.documentFileBack,
        };
        await mutateBack({ variables: variablesBack });
      }
    },
    [mutateFront, mutateBack]
  );

  const handleSubmit = async (variables) => {
    setHasBackDocument(!!variables.documentFileBack);
    onSubmit && onSubmit(variables);
    await mutate(variables);
  };

  const formik = useFormik({
    initialValues: {
      documentType: '',
      documentFile: null,
      documentFileBack: null,
    },
    validationSchema: schema(schemaOptions),
    onSubmit: handleSubmit,
  });

  return {
    formik,
    loading,
    called,
    error,
    data,
  };
};

export default useUploadDocumentWithBackForm;
