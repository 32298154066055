import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

interface EmptyStateProps {
  direction?: 'row' | 'column';
}

export const EmptyState = styled.div<EmptyStateProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.card.background.secondary};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  text-align: center;
  width: 100%;

  ${mediaQuery('desktop')} {
    background-color: ${({ theme }) => theme.colors.card.background.secondary};
    flex-direction: ${({ direction }) => direction};
    gap: 20px;
    text-align: ${({ direction }) =>
      direction === 'column' ? 'center' : 'left'};
  }
`;

export const EmptyStateContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${mediaQuery('desktop')} {
    gap: 5px;
  }
`;

export const EmptyStateTitle = styled.h3<EmptyStateProps>`
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto;
  width: 100%;

  ${mediaQuery('desktop')} {
    margin: 0 ${({ direction }) => (direction === 'column' ? 'auto' : '0')};
    width: 80%;
  }
`;

export const EmptyStateDescription = styled.p<EmptyStateProps>`
  font-size: 16px;
  margin: 0 auto;
  width: 100%;

  ${mediaQuery('desktop')} {
    margin: 0 ${({ direction }) => (direction === 'column' ? 'auto' : '0')};
    width: 80%;
  }
`;
