import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const CardContainer = styled.div`
  > div {
    height: 100%;
    display: flex;
    align-items: center;
    min-height: auto;
    padding: 21px 24px;
  }
`;

export const ProgressText = styled.span`
  color: ${({ color }) => color};
  font-size: ${convertPXToREM(16)};
  line-height: 150%;

  ${mediaQuery('desktop')} {
    font-size: ${convertPXToREM(20)};
    line-height: 140%;
  }

  ${({ marginRight }) => marginRight && 'margin-right: 8px;'}
`;
