import * as Yup from 'yup';
import { convertDateTimeTZ } from '@ampli/utils';
import { isValidDateTest } from '../schema-tests';
const ONLY_LETTERS_ERROR_MESSAGE = 'Apenas letras são permitidas';
const ONLY_LETTERS_REGEX = /^[a-záàâãéèêíïóôõöúçñ\s]+$/i;
const ACADEMIC_DEGREE_CHARACTER_LIMIT = 'Você atingiu o número máximo de caracteres';
const requiredMessage = field => {
  return `${field} é obrigatório`;
};
const getValueAsHour = value => {
  const valueNumbers = value.replace(/\D/gi, '');
  const hour = valueNumbers.length <= 2 ? valueNumbers.padStart(2, '0') : valueNumbers;
  return hour.padEnd(4, '0');
};
const isDateGreaterOrEqualThanTodayTest = message => {
  return {
    name: 'isDateGreaterOrEqualThanTodayTest',
    message,
    test: value => {
      const today = new Date().setHours(0, 0, 0, 0);
      return !!value && convertDateTimeTZ(value) >= today;
    }
  };
};
const isWorkLoadCorrectTest = (startHour, schema) => {
  return schema.test({
    test: value => {
      return !value || !startHour || !!value && startHour.replace(':', '').padEnd(4, '0') < value.replace(':', '').padEnd(4, '0');
    },
    message: 'O horário de fim do expediente deve ser depois do horário de início.'
  });
};
const isAtLeastOneDayFilledTest = () => {
  return {
    name: 'atLeastOneDayFilled',
    message: 'Pelo menos um dia deve ser preenchido.',
    test: value => {
      const hasSomeDayFilled = Object.values(value).some(_ref => {
        let {
          start,
          finish
        } = _ref;
        return start && finish;
      });
      return !!value && hasSomeDayFilled;
    }
  };
};
const isHoursIntervalValidTest = () => {
  return {
    name: 'isIntervalHoursValidTest',
    message: 'Só é possível inserir um horário entre 00:00 e 23:59',
    test: value => {
      return !value || !!value && getValueAsHour(value) >= '0000' && getValueAsHour(value) <= '2359';
    }
  };
};
const isTheBothHoursFilledTest = (startHour, schema) => {
  return schema.test({
    name: 'isTheBothHoursFilledTest',
    message: 'Ambos os horários precisam estar preenchidos',
    test: value => {
      return !startHour && !value || !!value && !!startHour;
    }
  });
};
const weekDay = Yup.object({
  start: Yup.string().test(isHoursIntervalValidTest()),
  finish: Yup.string().test(isHoursIntervalValidTest()).when('start', isTheBothHoursFilledTest).when('start', isWorkLoadCorrectTest)
});
export const getInternshipInfoSchema = () => Yup.object({
  companyResponsible: Yup.object({
    name: Yup.string().required(requiredMessage('Nome responsável pela empresa')).matches(ONLY_LETTERS_REGEX, ONLY_LETTERS_ERROR_MESSAGE),
    role: Yup.string().required(requiredMessage('Cargo do responsável')).matches(ONLY_LETTERS_REGEX, ONLY_LETTERS_ERROR_MESSAGE)
  }),
  fieldSupervisor: Yup.object({
    name: Yup.string().required(requiredMessage('Nome do supervisor')).matches(ONLY_LETTERS_REGEX, ONLY_LETTERS_ERROR_MESSAGE),
    academicDegree: Yup.string().required(requiredMessage('Formação acadêmica do supervisor')).matches(ONLY_LETTERS_REGEX, ONLY_LETTERS_ERROR_MESSAGE).max(249, ACADEMIC_DEGREE_CHARACTER_LIMIT)
  }),
  startDate: Yup.string().required(requiredMessage('Data de início do estágio')).test(isValidDateTest({
    format: 'yyyy-MM-dd'
  })).test(isDateGreaterOrEqualThanTodayTest('A data de início deve ser de hoje em diante.')),
  finishDate: Yup.string().required(requiredMessage('Data de fim do estágio')).test(isValidDateTest({
    format: 'yyyy-MM-dd'
  })).test(isDateGreaterOrEqualThanTodayTest('A data de fim deve ser maior do que hoje')),
  weeklySchedule: Yup.object({
    mon: weekDay,
    tue: weekDay,
    wed: weekDay,
    thu: weekDay,
    fri: weekDay,
    sat: weekDay,
    sun: weekDay
  }).test(isAtLeastOneDayFilledTest())
});