import styled from '@emotion/styled';

import {
  convertPXToREM,
  convertPXToVH,
  convertPXToVW,
  mediaQuery,
} from '@sofia/ui';

const mediaQueryMatch = 'desktop-max';

export const StickyBannerContainer = styled.div`
  background: ${({ theme }) => theme.colors.icon.background};
  border-top: 1px solid ${({ theme }) => theme.colors.calendar.lightBlack};
  border-bottom: 1px solid ${({ theme }) => theme.colors.calendar.lightBlack};
  user-select: none;
  position: relative;
`;

export const StickyBannerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaQuery(mediaQueryMatch)} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const IconWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.background.primary};
  margin-right: 16px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  ${mediaQuery(mediaQueryMatch)} {
    margin-bottom: 24px;
  }
`;

export const FlexResponsive = styled.div`
  display: flex;
  align-items: center;

  ${mediaQuery(mediaQueryMatch)} {
    flex-direction: column;
    width: 100%;
  }
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;

  span {
    display: flex;
    align-items: center;
  }
`;

export const LinkButton = styled.button`
  border: 2px solid ${({ theme }) => theme.colors.text.primary};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0 !important;

  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 700;
  outline: 0;
  cursor: pointer;
  text-align: center;
  white-space: nowrap
  font-size: ${convertPXToREM(16)};
  padding: ${convertPXToVH(19)} ${convertPXToVW(24)};
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background: ${({ theme }) => theme.colors.background.light};
    text-decoration: none;
  }

  &: focus {
    background: ${({ theme }) => theme.colors.background.light};
    text-decoration: none;
  }

  ${mediaQuery(mediaQueryMatch)} {
    height: 56px;
    padding:${convertPXToVH(19)} ${convertPXToVW(0)};
    width: 100%;
  }
`;

export const CloseButton = styled.button`
  border: none;
  outline: 0;
  background: none;
  padding: 0;
  margin-left: 32px;

  ${mediaQuery(mediaQueryMatch)} {
    position: absolute;
    top: 24px;
    right: 24px;
  }
`;
