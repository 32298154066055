import styled from '@emotion/styled';
import { keyframes } from '@emotion/css';
const grow = (dashOffset, dashArray) => keyframes`
  from {
    stroke-dashoffset: ${dashArray};
  }
  to {
    stroke-dashoffset: ${dashOffset};
  }
`;
export const Indicator = styled.circle`
  transform: rotate(-90deg);
  transform-origin: center;
  transition: stroke-dashoffset 0.5s linear;
  ${_ref => {
  let {
    strokeDashoffset,
    strokeDasharray,
    hasAnimation
  } = _ref;
  return hasAnimation ? `animation: ${grow(strokeDashoffset, strokeDasharray)} 0.5s ease-out;` : '';
}}

  transition: ${_ref2 => {
  let {
    progressState
  } = _ref2;
  return progressState === 100 ? 'stroke 0.4s ease-in-out' : 'none';
}};
`;
export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;