import styled from '@emotion/styled';

import { convertPXToREM, mediaQuery, SvgRateStar } from '@sofia/ui';

const mobileSpacing = `
    svg:nth-of-type(1n) {
      margin-right: ${convertPXToREM(7)};
      margin-left: ${convertPXToREM(7)};
    }
    svg:first-of-type {
      margin-left: 0;
    }
    svg:last-of-type {
      margin-right: 0;
    }
`;

export const RateStar = styled(SvgRateStar, {
  shouldForwardProp: (propName) => propName !== 'dataURI',
})`
  width: ${convertPXToREM(48)};
  height: ${convertPXToREM(48)};
  z-index: 2;
  color: transparent;

  padding: ${convertPXToREM(4)};
  ${mediaQuery('desktop')} {
    padding: ${convertPXToREM(10)};
  }

  path {
    stroke: #9cb7ce;
    stroke-width: 2;
  }

  ${({ dataURI }) => `background-image: url(${dataURI});`}

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0% 0%;

  transition: background-size 0.05s, padding ease-out 210ms;

  :focus {
    outline: 2px solid #018296 !important;
  }

  &.hover {
    transition: all ease-out 120ms;
    color: #ffb800;

    background-size: 80% 80%;
    ${mediaQuery('desktop')} {
      background-size: 55% 60%;
    }

    path {
      stroke-width: 0 !important;
    }
  }

  &.active {
    transition: all linear 420ms;
    padding: 0;
    ${mediaQuery('desktop')} {
      padding: ${convertPXToREM(6)};
    }
  }

  &.rated {
    color: #ffb800 !important;
    path {
      stroke-width: 0;
    }
  }
`;

export const StarsAnimation = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  height: ${convertPXToREM(48)};
  width: ${convertPXToREM(240)};
  z-index: 1;
  margin-top: ${convertPXToREM(4)};

  ${mediaQuery('mobile')} {
    bottom: 0px;
    width: ${convertPXToREM(296)};
    ${mobileSpacing}
  }
`;

export const AdjacentStars = styled.div<{ newRating: number }>`
  display: flex;
  align-items: center;
  z-index: 2;

  svg {
    color: transparent;
    path {
      stroke: #9cb7ce;
      stroke-width: 2;
    }
  }

  ${mediaQuery('mobile')} {
    ${mobileSpacing}
  }
`;
