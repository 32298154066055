export enum OrderStatusEnum {
  NOT_FOUND = 'NOT_FOUND',
  REQUESTED = 'REQUESTED',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  EXPIRED = 'EXPIRED',
  STUDENT_PENDING = 'STUDENT_PENDING',
  CANCELLED = 'CANCELLED',
  FINISHED_NOT_REOPEN = 'FINISHED_NOT_REOPEN',
}
