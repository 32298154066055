import { convertPXToVW } from '@sofia/ui';
import { mediaQuery } from '@ampli/ui';

import styled from '@emotion/styled';

export const Container = styled.div`
  align-self: center;

  width: ${convertPXToVW(36)};
  height: auto;

  & > img {
    width: auto;
    height: auto;

    &.imgLogo {
      display: none;
    }
  }

  ${mediaQuery('desktop')} {
    width: ${convertPXToVW(100)};

    & > img {
      &.imgLogo {
        max-width: 100px;
        display: block;
      }

      &.imgLogoResponsive {
        display: none;
      }
    }
  }

  &.clickable:hover {
    cursor: pointer;
  }
`;

export const SpinnerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${convertPXToVW(36)};
  height: auto;

  ${mediaQuery('desktop')} {
    width: ${convertPXToVW(100)};
  }
`;
