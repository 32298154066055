import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const ExamContainer = styled.div`
  ${mediaQuery('mobile')} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const HalfScreen = styled.div`
  margin-right: 48px;
`;

export const FontSizeHandler = styled.div<{ fontSize: number | null }>`
  ${({ fontSize }) =>
    fontSize &&
    `div.rich-text {
      font-size: ${convertPXToREM(fontSize)};
    }`}
  width: 100%;
`;

export const Floating = styled.div<{ top?: boolean; background?: string }>`
  display: block;
  height: fit-content;
  position: sticky;
  z-index: 2;
  top: ${({ top = true }) => (!top ? 0 : 115)}px;
  background: ${({ background }) => background};

  > :not(:last-of-type) {
    margin-bottom: 16px;
    width: 100%;
  }

  > :last-of-type {
    white-space: nowrap;
  }
`;

export const Spacing = styled.div`
  padding: 5px;
  margin: 16px 0px 16px 0px;
  width: 100%;
  max-width: 100vw;
`;

export const Header = styled.div<{ color: string; background: string }>`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  font-size: ${convertPXToREM(16)};
  width: 100%;
  max-width: 100vw;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
`;

export const ButtonGhost = styled.button<{ color: string; hoverColor: string }>`
  border: none;
  border-radius: 8px;
  outline: 0;
  background: transparent;
  cursor: pointer;
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
  color: ${({ color }) => color};
  padding: 19px 24px;
  margin-top: 16px;

  ${mediaQuery('desktop')} {
    margin: 0;
  }
  :hover {
    background-color: #c5d2e0;
    color: ${({ hoverColor }) => hoverColor};
  }
`;

export const FooterContainer = styled.div`
  > div > div > div:last-of-type {
    width: 100%;
    > button {
      width: 100%;
      justify-content: center;
    }
  }
`;
