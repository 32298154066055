import { UseQueryResult, useQuery } from 'react-query';
import { PageConversationInfo } from '../types';
import { useDynamicsLogic } from './use-dynamics-logic';
import { usePtkFrame } from '../contexts';

export type UseConversationsReturn = UseQueryResult<
  PageConversationInfo,
  unknown
>;

const options = {
  staleTime: 1000 * 60 * 1, // 1 minutes
  onError: (error: unknown) => console.error(error),
};

export const useHistoricConversations = (): UseConversationsReturn => {
  const { conversationsHistoricQueries, queryKeys } = usePtkFrame();
  const { getConversations } = useDynamicsLogic();

  return useQuery(
    queryKeys.historic,
    () =>
      getConversations({
        ...conversationsHistoricQueries,
        typeList: ['QUESTION', 'REMOTE'],
      }),
    options
  );
};

export const useUnreadHistoricConversations = (): UseConversationsReturn => {
  const { conversationsHistoricQueries, queryKeys } = usePtkFrame();
  const { getConversations } = useDynamicsLogic();

  return useQuery(
    [...queryKeys.historic, 'unreads'],
    () =>
      getConversations({
        ...conversationsHistoricQueries,
        typeList: ['QUESTION', 'REMOTE'],
        readStatus: 'UNREAD',
      }),
    options
  );
};

export const useTutorHistoricConversations = (): UseConversationsReturn => {
  const { conversationsHistoricQueries, queryKeys } = usePtkFrame();
  const { getConversations } = useDynamicsLogic();

  return useQuery(
    [...queryKeys.historic, 'tutor'],
    () =>
      getConversations({
        ...conversationsHistoricQueries,
        typeList: ['REMOTE'],
      }),
    options
  );
};

export const useStudentHistoricConversations = (): UseConversationsReturn => {
  const { conversationsHistoricQueries, queryKeys } = usePtkFrame();
  const { getConversations } = useDynamicsLogic();

  return useQuery(
    [...queryKeys.historic, 'student'],
    () =>
      getConversations({
        ...conversationsHistoricQueries,
        typeList: ['QUESTION'],
      }),
    options
  );
};
