import * as React from 'react';

function SvgSearchingInCellphone(props) {
  return (
    <svg
      width="156"
      height="120"
      viewBox="0 0 156 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.18"
        d="M68.7767 0.11851C68.0554 0.0925502 67.3381 0.0752439 66.6251 0.0579373C55.1793 -0.188681 43.4985 0.20937 32.6901 4.09902C21.8818 7.98867 11.9206 15.8718 7.7642 26.8615C5.61259 32.5683 5.10509 38.7641 4.30401 44.83C2.80669 56.2048 0.202112 67.48 0.0637045 78.9543C-0.0747031 90.4285 2.5802 102.508 10.2178 110.868C23.4672 125.388 41.846 116.782 57.6119 112.871C66.9685 110.558 76.7017 110.379 86.1322 112.347C92.3564 113.645 98.3666 115.882 104.587 117.258C109.062 118.249 113.717 118.794 118.276 118.184C122.655 117.6 126.031 115.445 130.02 113.71C137.611 110.387 145.287 106.156 150.127 99.0212C153.836 93.4203 155.539 86.6614 154.942 79.9061C153.453 62.0977 137.326 54.6212 129.001 40.6245C124.152 32.4904 120.847 23.266 114.611 16.2136C103.421 3.57982 85.4947 0.667994 68.7767 0.11851Z"
        fill="#FFC633"
      />
      <path
        d="M93.4926 18.0654V106.394C93.4926 108.44 92.7047 110.402 91.3023 111.849C89.8999 113.295 87.9978 114.108 86.0144 114.108H48.9548C46.9714 114.108 45.0693 113.295 43.6669 111.849C42.2644 110.402 41.4766 108.44 41.4766 106.394V18.0654C41.476 17.052 41.669 16.0484 42.0446 15.1119C42.4202 14.1755 42.9709 13.3245 43.6654 12.6077C44.3599 11.8909 45.1844 11.3223 46.092 10.9343C46.9996 10.5464 47.9724 10.3467 48.9548 10.3467H86.0144C86.9968 10.3467 87.9696 10.5464 88.8772 10.9343C89.7847 11.3223 90.6093 11.8909 91.3038 12.6077C91.9983 13.3245 92.549 14.1755 92.9246 15.1119C93.3001 16.0484 93.4932 17.052 93.4926 18.0654Z"
        fill="#4DCDDF"
      />
      <path
        d="M85.2344 11.6575H49.7307C45.7373 11.6575 42.5 14.997 42.5 19.1166V105.178C42.5 109.297 45.7373 112.637 49.7307 112.637H85.2344C89.2278 112.637 92.4651 109.297 92.4651 105.178V19.1166C92.4651 14.997 89.2278 11.6575 85.2344 11.6575Z"
        fill="#473F47"
      />
      <path
        d="M91.1069 19.9608V103.296C91.0881 105.185 90.3428 106.99 89.0348 108.312C87.7269 109.635 85.9634 110.367 84.132 110.349H50.8681C49.6529 110.353 48.4576 110.031 47.3995 109.414C46.3385 108.81 45.4532 107.924 44.8353 106.848C44.2174 105.773 43.8892 104.547 43.8848 103.296V19.9608C43.9047 18.0704 44.6515 16.2656 45.961 14.9431C47.2705 13.6206 49.0356 12.8887 50.8681 12.9083H56.4882V13.9338C56.5025 14.6065 56.7092 15.2599 57.0823 15.8118C57.4554 16.3638 57.9783 16.7896 58.5853 17.036C58.9927 17.2017 59.4267 17.2868 59.8645 17.2869H75.3159C76.2007 17.2927 77.052 16.9377 77.6843 16.2992C78.3167 15.6606 78.6789 14.7902 78.6922 13.8775V12.9083H84.1446C85.9336 12.9043 87.6569 13.6036 88.9637 14.864C89.6408 15.5164 90.1804 16.3056 90.5492 17.1824C90.9179 18.0593 91.1077 19.005 91.1069 19.9608Z"
        fill="white"
      />
      <path
        opacity="0.31"
        d="M69.6871 69.5916H50.3562C50.0203 69.5916 49.748 69.8724 49.748 70.2189V70.2232C49.748 70.5697 50.0203 70.8506 50.3562 70.8506H69.6871C70.023 70.8506 70.2953 70.5697 70.2953 70.2232V70.2189C70.2953 69.8724 70.023 69.5916 69.6871 69.5916Z"
        fill="#4DCDDF"
      />
      <path
        opacity="0.31"
        d="M69.6871 72.6675H50.3562C50.0203 72.6675 49.748 72.9484 49.748 73.2948V73.2992C49.748 73.6457 50.0203 73.9265 50.3562 73.9265H69.6871C70.023 73.9265 70.2953 73.6457 70.2953 73.2992V73.2948C70.2953 72.9484 70.023 72.6675 69.6871 72.6675Z"
        fill="#4DCDDF"
      />
      <path
        opacity="0.18"
        d="M93.4929 58.26H41.4727V61.6305H93.4929V58.26Z"
        fill="#4DCDDF"
      />
      <path
        d="M94.3232 57.0826L93.4844 55.4688"
        stroke="white"
        strokeWidth="1.45"
        strokeMiterlimit="10"
      />
      <path
        d="M81.8334 66.4724H50.7295C50.1874 66.4724 49.748 66.9257 49.748 67.4848C49.748 68.044 50.1874 68.4973 50.7295 68.4973H81.8334C82.3755 68.4973 82.8149 68.044 82.8149 67.4848C82.8149 66.9257 82.3755 66.4724 81.8334 66.4724Z"
        fill="#4DCDDF"
      />
      <path
        opacity="0.31"
        d="M69.6871 80.1787H50.3562C50.0203 80.1787 49.748 80.4596 49.748 80.8061V80.8104C49.748 81.1569 50.0203 81.4378 50.3562 81.4378H69.6871C70.023 81.4378 70.2953 81.1569 70.2953 80.8104V80.8061C70.2953 80.4596 70.023 80.1787 69.6871 80.1787Z"
        fill="#4DCDDF"
      />
      <path
        opacity="0.31"
        d="M69.6871 83.2549H50.3562C50.0203 83.2549 49.748 83.5358 49.748 83.8822V83.8866C49.748 84.2331 50.0203 84.5139 50.3562 84.5139H69.6871C70.023 84.5139 70.2953 84.2331 70.2953 83.8866V83.8822C70.2953 83.5358 70.023 83.2549 69.6871 83.2549Z"
        fill="#4DCDDF"
      />
      <path
        d="M81.8334 77.0547H50.7295C50.1874 77.0547 49.748 77.508 49.748 78.0671C49.748 78.6263 50.1874 79.0796 50.7295 79.0796H81.8334C82.3755 79.0796 82.8149 78.6263 82.8149 78.0671C82.8149 77.508 82.3755 77.0547 81.8334 77.0547Z"
        fill="#4DCDDF"
      />
      <path
        opacity="0.31"
        d="M69.6871 90.7615H50.3562C50.0203 90.7615 49.748 91.0424 49.748 91.3888V91.3932C49.748 91.7396 50.0203 92.0205 50.3562 92.0205H69.6871C70.023 92.0205 70.2953 91.7396 70.2953 91.3932V91.3888C70.2953 91.0424 70.023 90.7615 69.6871 90.7615Z"
        fill="#4DCDDF"
      />
      <path
        opacity="0.31"
        d="M69.6871 93.8425H50.3562C50.0203 93.8425 49.748 94.1234 49.748 94.4699V94.4742C49.748 94.8207 50.0203 95.1016 50.3562 95.1016H69.6871C70.023 95.1016 70.2953 94.8207 70.2953 94.4742V94.4699C70.2953 94.1234 70.023 93.8425 69.6871 93.8425Z"
        fill="#4DCDDF"
      />
      <path
        d="M81.8334 87.6423H50.7295C50.1874 87.6423 49.748 88.0956 49.748 88.6548C49.748 89.2139 50.1874 89.6672 50.7295 89.6672H81.8334C82.3755 89.6672 82.8149 89.2139 82.8149 88.6548C82.8149 88.0956 82.3755 87.6423 81.8334 87.6423Z"
        fill="#4DCDDF"
      />
      <g opacity="0.41">
        <path
          opacity="0.41"
          d="M86.8204 36.9557C89.0857 36.9529 91.3373 37.3165 93.4934 38.033V34.8702C89.8124 33.8089 85.9434 33.6406 82.1889 34.3786C78.4344 35.1166 74.8964 36.7408 71.8518 39.1242C68.8071 41.5075 66.3384 44.5853 64.6387 48.1167C62.9391 51.6482 62.0547 55.5374 62.0547 59.4801C62.0547 63.4229 62.9391 67.3121 64.6387 70.8436C66.3384 74.375 68.8071 77.4528 71.8518 79.8361C74.8964 82.2195 78.4344 83.8437 82.1889 84.5817C85.9434 85.3197 89.8124 85.1514 93.4934 84.0901V80.9273C90.4913 81.9212 87.3161 82.2325 84.1863 81.84C81.0564 81.4475 78.0461 80.3604 75.3623 78.6535C72.6785 76.9466 70.3849 74.6603 68.6391 71.9519C66.8934 69.2435 65.7369 66.1772 65.2493 62.9639C64.7616 59.7506 64.9544 56.4664 65.8142 53.3372C66.6741 50.208 68.1807 47.308 70.2304 44.8367C72.2802 42.3653 74.8244 40.3812 77.6883 39.0208C80.5521 37.6603 83.6676 36.9558 86.8204 36.9557Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <path
        d="M88.1418 83.8004C83.2416 83.8004 78.4514 82.3011 74.3773 79.4921C70.3033 76.6831 67.1284 72.6908 65.2543 68.02C63.3802 63.3493 62.8911 58.2101 63.8489 53.2525C64.8067 48.295 67.1684 43.7419 70.6352 40.1693C74.102 36.5966 78.518 34.165 83.3247 33.1819C88.1315 32.1989 93.1128 32.7086 97.6386 34.6467C102.164 36.5847 106.031 39.864 108.75 44.0696C111.469 48.2752 112.918 53.2182 112.913 58.2732C112.898 65.0434 110.283 71.5316 105.64 76.3164C100.997 81.1012 94.7047 83.7924 88.1418 83.8004ZM88.1418 35.7228C83.8244 35.7228 79.6038 37.0431 76.0137 39.517C72.4235 41.9909 69.6249 45.5072 67.9715 49.6215C66.3181 53.7358 65.8843 58.2634 66.7247 62.632C67.5652 67.0006 69.6423 71.0142 72.6934 74.1653C75.7445 77.3164 79.6328 79.4637 83.8667 80.3358C88.1006 81.2078 92.49 80.7655 96.4802 79.0646C100.47 77.3638 103.882 74.4808 106.285 70.7801C108.687 67.0793 109.972 62.727 109.977 58.2732C109.974 52.2989 107.674 46.5697 103.58 42.3439C99.4857 38.1182 93.9333 35.7415 88.1418 35.7357V35.7228Z"
        fill="#444444"
      />
      <path
        d="M121.69 96.1094C121.356 96.1097 121.026 96.0414 120.717 95.9084C120.409 95.7755 120.13 95.5805 119.895 95.335L103.664 78.3442C103.431 78.1016 103.247 77.8139 103.122 77.4976C102.997 77.1814 102.934 76.8428 102.936 76.5013C102.938 76.1598 103.005 75.8221 103.134 75.5075C103.263 75.1929 103.45 74.9076 103.686 74.668C103.922 74.4283 104.202 74.2391 104.508 74.111C104.815 73.983 105.144 73.9186 105.475 73.9217C105.806 73.9248 106.133 73.9953 106.438 74.129C106.742 74.2628 107.018 74.4572 107.25 74.7012L123.481 91.6919C123.829 92.0564 124.065 92.5188 124.159 93.0211C124.253 93.5234 124.201 94.0431 124.01 94.5151C123.819 94.987 123.496 95.3901 123.084 95.6738C122.671 95.9574 122.186 96.109 121.69 96.1094Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M129.466 101.508C129.189 101.791 128.861 102.015 128.5 102.166C128.139 102.318 127.753 102.394 127.363 102.391C126.973 102.389 126.588 102.306 126.229 102.15C125.87 101.993 125.545 101.765 125.272 101.478L106.163 81.4718C106.057 81.3629 105.96 81.2441 105.874 81.117C105.824 81.0478 105.777 80.9785 105.735 80.9093C105.599 80.6727 105.538 80.3985 105.559 80.1243C105.58 79.8501 105.683 79.5893 105.853 79.3777C106.224 78.9217 106.629 78.4964 107.065 78.1057C107.565 77.6473 108.107 77.2401 108.684 76.8899C108.919 76.7442 109.193 76.6788 109.466 76.7029C109.74 76.727 109.999 76.8393 110.206 77.024C110.264 77.0708 110.319 77.1214 110.37 77.1754L129.512 97.1861C129.785 97.473 129.999 97.8123 130.144 98.1848C130.29 98.5574 130.362 98.9557 130.358 99.3572C130.353 99.7586 130.272 100.155 130.119 100.524C129.966 100.893 129.744 101.228 129.466 101.508Z"
        fill="#473F47"
      />
      <path
        opacity="0.18"
        d="M60.7488 32.0494V40.8585C60.7488 42.0133 60.3052 43.1211 59.5152 43.9393C58.7252 44.7575 57.6531 45.2195 56.5336 45.224H41.4766V27.6838H56.5336C57.6531 27.6884 58.7252 28.1504 59.5152 28.9686C60.3052 29.7868 60.7488 30.8946 60.7488 32.0494Z"
        fill="#4DCDDF"
      />
      <path
        d="M55.7866 25.3694H27.7612C25.424 25.3694 23.5293 27.3239 23.5293 29.735V38.544C23.5293 40.9551 25.424 42.9096 27.7612 42.9096H55.7866C58.1239 42.9096 60.0186 40.9551 60.0186 38.544V29.735C60.0186 27.3239 58.1239 25.3694 55.7866 25.3694Z"
        fill="white"
      />
      <path
        d="M58.7406 29.0419C60.4246 29.0419 61.7897 27.6336 61.7897 25.8964C61.7897 24.1592 60.4246 22.751 58.7406 22.751C57.0566 22.751 55.6914 24.1592 55.6914 25.8964C55.6914 27.6336 57.0566 29.0419 58.7406 29.0419Z"
        fill="#4DCDDF"
      />
      <path
        d="M51.3865 30.9719H31.5732C31.1099 30.9719 30.7344 31.3593 30.7344 31.8373V31.8416C30.7344 32.3195 31.1099 32.7069 31.5732 32.7069H51.3865C51.8497 32.7069 52.2253 32.3195 52.2253 31.8416V31.8373C52.2253 31.3593 51.8497 30.9719 51.3865 30.9719Z"
        fill="#4DCDDF"
      />
      <path
        d="M51.3865 35.5884H31.5732C31.1099 35.5884 30.7344 35.9758 30.7344 36.4537V36.458C30.7344 36.9359 31.1099 37.3234 31.5732 37.3234H51.3865C51.8497 37.3234 52.2253 36.9359 52.2253 36.458V36.4537C52.2253 35.9758 51.8497 35.5884 51.3865 35.5884Z"
        fill="#4DCDDF"
      />
      <path
        d="M127.534 115.138C127.713 115.37 127.821 115.651 127.844 115.947C127.866 116.242 127.802 116.538 127.66 116.795C127.51 117.041 127.29 117.231 127.029 117.339C126.768 117.447 126.481 117.468 126.208 117.397C126.113 117.372 126.024 117.324 125.948 117.258C125.793 117.103 125.768 116.826 125.621 116.67C125.469 116.54 125.284 116.457 125.089 116.428C124.742 116.297 124.416 116.115 124.12 115.887C124.072 115.859 124.03 115.82 123.998 115.774C123.968 115.717 123.952 115.653 123.952 115.588C123.88 115.256 123.93 114.908 124.09 114.61C124.179 114.499 124.287 114.406 124.409 114.338C124.64 114.19 124.905 114.108 125.177 114.1C125.388 114.115 125.597 114.164 125.793 114.247C126.351 114.412 126.874 114.682 127.337 115.043"
        fill="#4DCDDF"
      />
      <path
        d="M126.447 112.486L127.383 115.809C127.383 115.809 127.207 116.644 126.217 116.293C125.227 115.943 124.396 113.325 124.396 113.325L126.447 112.486Z"
        fill="#FCBDBA"
      />
      <path
        d="M117.027 116.211L118.395 119.672C118.395 119.672 119.179 120.161 119.737 119.945L120.286 119.711L119.804 114.878L117.027 116.211Z"
        fill="#FCBDBA"
      />
      <path
        d="M106.864 81.0265C106.772 80.9011 106.65 80.7626 106.504 80.7842C106.357 80.8059 106.332 80.8665 106.243 80.8405C106.189 80.8204 106.141 80.7825 106.109 80.7323C105.901 80.5039 105.669 80.2993 105.417 80.1223C105.264 79.9834 105.079 79.8867 104.88 79.841C104.735 79.8335 104.591 79.8706 104.466 79.9476C104.342 80.0247 104.242 80.1382 104.18 80.2737C104.055 80.5431 104.024 80.8487 104.092 81.139C104.166 81.4293 104.279 81.7074 104.427 81.9654C104.517 82.1588 104.632 82.3392 104.767 82.5019C105.187 82.9735 105.883 83.0514 106.445 83.3413C106.505 83.379 106.572 83.4027 106.642 83.4105C106.702 83.4004 106.758 83.3759 106.807 83.3391C106.856 83.3022 106.895 83.2541 106.923 83.1985C107.14 82.8789 107.272 82.5058 107.305 82.1169C107.274 81.7142 107.12 81.3321 106.864 81.0265Z"
        fill="#FCBDBA"
      />
      <path
        d="M112.316 77.2755C112.249 77.2563 112.177 77.264 112.115 77.2972C112.08 77.326 112.049 77.3594 112.023 77.3967C111.905 77.5265 111.712 77.5308 111.553 77.6043C111.451 77.6576 111.36 77.7325 111.288 77.8242C111.216 77.9159 111.163 78.0224 111.134 78.1365C111.078 78.3641 111.054 78.5986 111.062 78.8331C111.052 78.9571 111.064 79.082 111.1 79.2009C111.119 79.2588 111.153 79.3099 111.199 79.3482C111.245 79.3865 111.301 79.4105 111.36 79.4172C111.456 79.3994 111.546 79.3594 111.624 79.3004C111.784 79.2099 111.969 79.1793 112.149 79.2139C112.379 79.2905 112.598 79.3966 112.803 79.5297C112.907 79.5933 113.026 79.6268 113.147 79.6268C113.268 79.6268 113.387 79.5933 113.491 79.5297C113.584 79.4436 113.657 79.3372 113.705 79.2182C113.893 78.7855 114.124 78.3053 113.705 77.9548C113.311 77.6014 112.831 77.3665 112.316 77.2755Z"
        fill="#FCBDBA"
      />
      <path
        d="M124.781 93.176C124.683 94.996 124.486 96.8089 124.19 98.606C123.812 100.389 123.586 102.413 123.586 102.413L127.08 112.797C127.08 112.797 124.953 114.255 123.586 113.689C122.219 113.122 118.758 105.161 118.758 105.161L117.441 96.1052L120.927 91.9775L124.781 93.176Z"
        fill="#473F47"
      />
      <path
        d="M115.14 92.4531C115.14 92.4531 112.648 100.877 112.648 103.066C112.648 105.256 115.635 116.046 115.635 116.046C115.635 116.046 118.877 117.18 119.636 116.046L120.395 114.913L118.571 104.053C118.571 104.053 121.154 98.3849 121.611 96.9269C121.888 96.0283 122.086 95.1059 122.203 94.1708L115.14 92.4531Z"
        fill="#473F47"
      />
      <path
        d="M116.188 69.3796C115.897 69.9801 115.711 70.629 115.639 71.2963C115.606 71.6265 115.657 71.9598 115.787 72.2633C115.917 72.5668 116.122 72.83 116.381 73.027C116.539 73.105 116.687 73.2039 116.821 73.3212C117.291 73.8144 116.754 74.7101 117.023 75.3504C117.131 75.5562 117.308 75.7139 117.522 75.7936C117.735 75.8734 117.969 75.8696 118.18 75.7831C118.387 75.6827 118.569 75.5364 118.714 75.3545C118.859 75.1727 118.963 74.96 119.019 74.7317C119.14 74.2678 119.193 73.7882 119.178 73.3082L119.225 70.4483C119.291 69.8121 119.194 69.1691 118.944 68.5835C118.777 68.2813 118.531 68.0334 118.235 67.8682C117.938 67.7029 117.602 67.627 117.266 67.649C116.62 67.7918 116.423 68.8215 116.188 69.3796Z"
        fill="#FCBDBA"
      />
      <path
        d="M106.515 83.6571C106.515 83.6571 111.833 88.5592 112.21 88.5592C112.588 88.5592 114.949 85.4786 114.949 85.4786L115.138 92.4488C115.795 92.8487 116.479 93.1986 117.185 93.4959C118.334 93.9805 119.789 94.4304 121.06 94.3093C123.644 94.067 124.78 93.1757 124.78 93.1757L123.191 76.7604C123.191 76.7604 120.179 74.5236 119.152 74.8654C118.415 75.0009 117.661 75.0009 116.925 74.8654C115.44 74.6663 113.922 76.7604 113.922 76.7604L111.946 83.4537L107.312 81.7577C107.009 81.9548 106.771 82.2415 106.629 82.5804C106.487 82.9193 106.447 83.2945 106.515 83.6571Z"
        fill="#4DCDDF"
      />
      <path
        d="M116.636 66.2775C117.194 65.7539 118.457 65.5376 119.073 65.4684C119.547 65.3988 120.03 65.4346 120.489 65.5734C120.949 65.7122 121.374 65.9508 121.736 66.2731C122.743 67.2596 122.848 68.8691 122.835 70.3488C122.823 71.8286 122.802 73.4164 123.641 74.5414C124.362 75.5019 125.54 75.8999 126.459 76.6398C126.913 76.9867 127.289 77.4293 127.564 77.9381C127.839 78.4468 128.005 79.0102 128.053 79.5905C128.267 84.0383 121.682 82.1303 119.505 81.1395C119.12 80.9862 118.766 80.7602 118.461 80.4732C118.139 80.1143 117.902 79.6833 117.769 79.2141C117.258 77.6245 117.345 75.8949 118.012 74.3683C118.113 74.1433 118.222 73.8621 118.083 73.663C118.008 73.5749 117.913 73.5067 117.806 73.464C117.528 73.3448 117.289 73.1457 117.117 72.8903C116.945 72.6349 116.848 72.3339 116.838 72.0232C116.928 71.5675 117.127 71.1422 117.416 70.7858C117.559 70.6075 117.656 70.3961 117.702 70.17C117.747 69.9438 117.738 69.7097 117.676 69.4878C117.525 69.1374 117.165 68.9643 116.896 68.709C116.427 68.2764 116.087 67.1125 116.477 66.4895C116.521 66.4124 116.575 66.3412 116.636 66.2775Z"
        fill="#F6A569"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="56.8699"
          y1="156.583"
          x2="131.795"
          y2="-134.388"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" />
          <stop offset="0.08" stopOpacity="0.69" />
          <stop offset="0.21" stopOpacity="0.32" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="102.936"
          y1="96.1094"
          x2="126.664"
          y2="93.0613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BDBB8" />
          <stop offset="1" stopColor="#01B6D0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgSearchingInCellphone;
