import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const Banner = styled.div<{
  url: string | null | undefined;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  border-bottom-left-radius: 75vh 20%;
  border-bottom-right-radius: 75vh 20%;
  ${({ url }) => `background-image: url("${url}");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;`}

  height: calc(45vh);
  ${mediaQuery('desktop')} {
    height: calc(60vh + 60px);
  }
`;
