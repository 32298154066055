import styled from '@emotion/styled';
import { convertPXToREM } from '../../utils';
export const Tab = styled('div', {
  shouldForwardProp: propName => propName !== 'selectedBorderColor' && propName !== 'selectedColor' && propName !== 'minWidth' && propName !== 'padding' && propName !== 'width' && propName !== 'border' && propName !== 'background' && propName !== 'color' && propName !== 'weight'
})(_ref => {
  let {
    selectedBorderColor,
    selectedColor,
    background,
    minWidth,
    padding,
    weight,
    border,
    width,
    color,
    disabled
  } = _ref;
  return `
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${background || 'transparent'};
  width: ${width || 'auto'};
  min-width: ${minWidth || '136px'};
  ${!border ? `
      border: 0;
      border-bottom: 4px solid transparent;
      ` : `border: ${border};`}
  cursor: pointer;
  font-weight: ${weight || 700};
  color: ${color || '#495057'};

  line-height: 115%;
  font-size: ${convertPXToREM(16)};

  @media (max-width: 769px) {
    line-height: 140%;
    font-size: ${convertPXToREM(14)};
  }

  ${!padding ? `
    padding: 20px;
    @media (max-width: 769px) {
      padding: 16px;
    }` : `padding: ${padding};`}

  &:focus {
    outline: none;
    border-width: 4px;
  }

  &[aria-selected='true'] {
    border-color: ${selectedBorderColor};
    color: ${selectedColor};
  }

  ${disabled && `
    opacity: 0.5;
    ${!border ? 'border-bottom-color: transparent;' : ''};
    cursor: default;
    pointer-events: none;
`}
`;
});