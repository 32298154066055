import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px;
  gap: 24px;

  max-height: ${convertPXToREM(580)};
  overflow: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleTextArea = styled.textarea`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text.black};

  border: none;
  resize: none;
  overflow: hidden;
  background: transparent;
  outline: none;
  caret-color: ${({ theme }) => theme.colors.text.primary};

  height: auto;

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.lighterBlack};
  }

  &:focus-visible {
    outline: none;
  }

  &:focus {
    border: none;
    background-color: none;
  }

  &:hover {
    background-color: none;
    border-color: none;
  }
`;
