import React from 'react';
import { string, func } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import { Typography } from '@sofia/ui';

import OnboardingContent from './onboarding-content';
import OnboardingSide from './onboarding-side';
import SideScreen from '../side-screen/side-screen';
import WelcomeImage from '../image/welcome-image';

const OnboardingWelcome = ({ onNextClick, ...props }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <SideScreen
      {...props}
      side={
        isDesktop && (
          <OnboardingSide>
            <WelcomeImage />
          </OnboardingSide>
        )
      }
    >
      <OnboardingContent
        title="Boas-vindas!"
        image={!isDesktop && <WelcomeImage />}
        isDesktop={isDesktop}
        onPrimaryClick={onNextClick}
      >
        <Typography>Sua jornada até o diploma começa agora!</Typography>
        <Typography margin="15px 0">
          Antes de começar, alguns pontos muito importantes:
        </Typography>
        <li>
          <Typography as="span">
            Sempre que precisar, fique à vontade para entrar em contato conosco,
            nós responderemos em até <strong>24 horas úteis</strong>
          </Typography>
        </li>
        <br />
        <li>
          <Typography as="span">
            Vamos precisar de uma foto de seu <strong>RG</strong> ou{' '}
            <strong>CNH</strong>, de <strong>seu rosto</strong> e do{' '}
            <strong>certificado de conclusão</strong> do ensino médio. Vamos lá?
          </Typography>
        </li>
      </OnboardingContent>
    </SideScreen>
  );
};

OnboardingWelcome.propTypes = {
  onNextClick: func,
  className: string,
};

export default OnboardingWelcome;
