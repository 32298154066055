import styled from '@emotion/styled';
import { mediaQuery } from '@ampli/ui';
import { convertPXToREM } from '@sofia/ui';

export const CardContainer = styled.div`
  > div {
    display: flex;
    min-height: auto;
    padding: 21px 24px;
    align-items: center;
    @media (min-width: 1012px) {
      white-space: nowrap;
    }
  }
`;

export const IconContainer = styled.div`
  border-radius: 50%;
  background: ${({ completed, iconColor }) => {
    if (iconColor) return iconColor;
    return completed ? '#698AAE' : '#ffa433';
  }};
  padding: 10px 11px;
  margin-right: 24px;
  svg path {
    fill: #fff;
  }
`;

export const CardLabel = styled.span`
  font-weight: 400;
  font-size: ${convertPXToREM(16)};
  line-height: 150%;

  ${mediaQuery('desktop')} {
    font-weight: 700;
    font-size: ${convertPXToREM(20)};
    line-height: 140%;
  }

  margin-right: 24px;
`;

export const FlexFlexEnd = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;
`;

export const CardDesc = styled.span`
  font-weight: 400;
  font-size: ${convertPXToREM(20)};
  line-height: 140%;
`;

export const WrapperTextButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
