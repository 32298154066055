import React from 'react';

import { Skeleton } from '../../../../../../../../../../components';

const ConversationChatSkeleton: React.FC = () => (
  <React.Fragment>
    <Skeleton height={26} margin="0px 0px 38px" borderRadius={6} />

    {[0, 1].map((i) => (
      <Skeleton key={i} height={120} margin="16px 0px" borderRadius={6} />
    ))}
  </React.Fragment>
);

export default ConversationChatSkeleton;
