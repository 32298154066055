import React, { forwardRef } from 'react';
import { string } from '@ampli/utils';
import { cx, css, px2grid, IconButton } from '@ampli/ui';

const SettingButton = forwardRef((props, ref) => (
  <IconButton
    ref={ref}
    {...props}
    iconPosition="top"
    iconSize="large"
    iconMargin="none"
    color="text-dark"
    size="x-small"
    className={cx(
      'setting-button',
      css`
        margin: 0 ${px2grid(8)};
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      `,
      props.className
    )}
  />
));
SettingButton.displayName = 'IconButton';
SettingButton.propTypes = {
  className: string,
};

export default SettingButton;
