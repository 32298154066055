import { useQuery } from '@ampli/services';
import { GetPublicKeyHook } from './types';
import { GET_PAYMENT_CREDIT_CARD_PUBLIC_KEY } from './queries';

export const useGetPublicKey: GetPublicKeyHook = ({
  courseEnrollmentId,
  options,
}) => {
  const { data, loading, error, ...rest } = useQuery(
    GET_PAYMENT_CREDIT_CARD_PUBLIC_KEY,
    {
      ...options,
      variables: {
        courseEnrollmentId,
      },
      context: {
        autoHandle: true,
      },
    }
  );

  const publicKey = loading || !data ? {} : data.data;

  return {
    data: publicKey,
    loading,
    error,
    ...rest,
  };
};
