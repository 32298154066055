import { useQuery, useMutation } from '@ampli/services';
import {
  GET_DISCHARGE_DOCUMENT_YEARS,
  SELECT_DISCHARGE_DOCUMENT_YEAR,
} from './queries';
import {
  GetDischargeDocumentYearsHook,
  MutationSelectDischargeDocumentYearHook,
} from './types';

export const useGetDischargeDocumentYears: GetDischargeDocumentYearsHook = ({
  courseEnrollmentId,
  options = {},
}) => {
  const { data, loading, error } = useQuery(GET_DISCHARGE_DOCUMENT_YEARS, {
    ...options,
    variables: {
      courseEnrollmentId,
    },
    fetchPolicy: 'no-cache',
  });

  const dischargeDocumentYears = loading || !data ? [] : data.data;

  return {
    data: dischargeDocumentYears,
    loading,
    error,
  };
};

export const useMutationSelectDischargeDocumentYear: MutationSelectDischargeDocumentYearHook =
  ({ options }) => {
    const [mutate, metadata] = useMutation(SELECT_DISCHARGE_DOCUMENT_YEAR, {
      ...options,
    });

    return [mutate, metadata];
  };
