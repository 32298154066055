type TLinkDownload = (props: {
  target?: string;
  fileUrl: string;
  documentName: string;
  documentExtension: string;
}) => Promise<void>;

const linkDownload: TLinkDownload = async ({
  target,
  fileUrl,
  documentName,
  documentExtension,
}) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = `${documentName}.${documentExtension}`;
  link.target = target || '_blank';
  document.body.appendChild(link);
  link.click();
  link.parentNode && link.parentNode.removeChild(link);
  URL.revokeObjectURL(link.href);
};

export default linkDownload;
