export default function APIError(message) {
  let info = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const error = new Error(message);
  error.name = 'APIError';
  error.info = info;
  Object.setPrototypeOf(error, Object.getPrototypeOf(this));
  if (Error.captureStackTrace) {
    Error.captureStackTrace(error, APIError);
  }
  return error;
}
APIError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true
  }
});
if (Object.setPrototypeOf) {
  Object.setPrototypeOf(APIError, Error);
} else {
  APIError.__proto__ = Error;
}