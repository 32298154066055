import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { ProgressBar } from '@sofia/ui';

import * as Styled from './section-menu-progress-styles';
import { TLearningObject } from '../../../../../../../../section/shared';
import { SectionMenuProgressProps } from '../../types';

const SectionMenuProgress = ({
  open,
  sectionProgress,
  sectionOrder,
  sectionTitle,
  learningObjects,
}: SectionMenuProgressProps): React.ReactElement => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const closedClass = !open ? 'closed' : '';
  const progress = sectionProgress ? Math.round(sectionProgress * 100) : 0;

  const amountOfCompleteLearningObjects: number = (
    learningObjects as Array<TLearningObject>
  ).filter((learningObject) => !!learningObject?.completed).length;

  const getProgressWrapperAriaLabel = () => {
    return `Aula ${sectionOrder}: ${sectionTitle}.
    Progresso em ${progress}%.
    ${amountOfCompleteLearningObjects} de ${learningObjects.length} conteúdos completos`;
  };

  const PercentageText = () => (
    <Styled.PercentageText
      data-testid="section-menu-progress-percentage-text"
      color={theme.colors.text.lightBlack}
      className={closedClass}
    >
      {progress}%
    </Styled.PercentageText>
  );

  return (
    <Styled.ProgressContainer
      data-testid="section-menu-progress"
      className={closedClass}
      aria-label={getProgressWrapperAriaLabel()}
    >
      {open ? (
        <>
          <ProgressBar
            progress={progress}
            data-testid="section-menu-progress-bar"
          />
          <PercentageText />
        </>
      ) : isDesktop ? (
        <Styled.ProgressCircle
          data-testid="section-menu-progress-circle"
          progress={progress / 100}
          color="#33AB8C"
          strokeWidth={4}
          size={51}
        >
          <PercentageText />
        </Styled.ProgressCircle>
      ) : null}
    </Styled.ProgressContainer>
  );
};

export default SectionMenuProgress;
