import React from 'react';

import { string, bool, object } from 'prop-types';
import { Header, Banner } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';

import { useFreeTrialRegressiveCounter } from '../../hooks';
import { FreeTrialRegressiveCounter } from '../free-trial-regressive-counter';
import { LayoutContent } from '../layout';

import BreadCrumbs from './BreadCrumbs';

const HomeBanner = ({
  courseName,
  selectedCourseEnrollmentType,
  selectedCourseVersionId,
  studentFreeTrialCourseData,
  isFreeTrialEnabled,
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const regressiveCounter = useFreeTrialRegressiveCounter(
    studentFreeTrialCourseData
  );

  const showFreeTrialMessage =
    regressiveCounter.showFreeTrialCounter && isFreeTrialEnabled;

  return (
    <Banner margin={0} background={theme.colors.banner.primary}>
      <LayoutContent padding={isDesktop ? ['small', 'huge'] : ['base', 'none']}>
        <Header
          padding="0px"
          breadCrumbs={<BreadCrumbs courseName={courseName} />}
          background="none"
          lastChildren={
            <>
              {showFreeTrialMessage ? (
                <FreeTrialRegressiveCounter
                  endDate={regressiveCounter.freeTrialDaysLeft}
                  freeTrialSituation={regressiveCounter.freeTrialSituation}
                  selectedCourseEnrollmentType={selectedCourseEnrollmentType}
                  selectedCourseVersionId={selectedCourseVersionId}
                />
              ) : null}
            </>
          }
        />
      </LayoutContent>
    </Banner>
  );
};

HomeBanner.propTypes = {
  courseName: string,
  className: string,
  selectedCourseEnrollmentType: string,
  selectedCourseVersionId: string,
  studentFreeTrialCourseData: object,
  isFreeTrialEnabled: bool,
};

export default HomeBanner;
