import {
  SvgSuccessIllustration,
  SvgElogioSuccessIllustration,
  SvgCriticaSuccessIllustration,
} from '../../../../shared';

export const ELOGIO = {
  title: 'Elogio enviado!',
  description:
    'Agradecemos imensamente pelo reconhecimento e esperamos continuar a atender às suas expectativas e superá-las sempre que possível.',
  illustration: SvgElogioSuccessIllustration,
};

export const DUVIDA = {
  title: 'Dúvida enviada!',
  description:
    'Assim que possível o tutor irá te responder. Nosso horário de atendimento é de segunda a sexta das 08:00 às 18:00 horas. Até breve!',
  illustration: SvgSuccessIllustration,
};

export const RECLAMACAO = {
  title: 'Crítica enviada!',
  description:
    'Agradecemos pelo seu retorno. Estamos sempre buscando melhorar nossos serviços. Sua crítica será levada em consideração para que possamos oferecer um atendimento ainda melhor aos nossos alunos.',
  illustration: SvgCriticaSuccessIllustration,
};
