import { useGetLogoCompany } from '@ampli/services';

const useGetCompanyInformation = (selectedCourseEnrollmentId, options) => {
  const { data, loading } = useGetLogoCompany(
    selectedCourseEnrollmentId,
    options
  );

  return {
    company: data?.data,
    loading,
  };
};

export default useGetCompanyInformation;
