import React from 'react';
import { useTheme } from '@emotion/react';

import * as Styled from './section-progress.styles';
import { TLearningObject } from '../../../../../section/shared';
import { SectionMobileProgressProps } from '../types';

const SectionMobileProgress = ({
  learningObjects,
  activeLearningObjectPath,
}: SectionMobileProgressProps): React.ReactElement => {
  const theme = useTheme();
  return (
    <Styled.Wrapper data-testid="section-progress">
      {learningObjects.map((object, index) => {
        const active = object.pathUrl === activeLearningObjectPath;
        const hasCompletedProperty = Object.prototype.hasOwnProperty.call(
          object,
          'completed'
        );

        return (
          <Styled.ProgressBar
            data-testid="progress-bar"
            key={index}
            size={learningObjects.length}
            active={active && theme.colors.text.lighterSuccess}
            completed={
              hasCompletedProperty &&
              (object as TLearningObject).completed &&
              theme.colors.text.success
            }
          />
        );
      })}
    </Styled.Wrapper>
  );
};

export default SectionMobileProgress;
