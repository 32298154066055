import { gql } from 'graphql.macro';

export const FindLearningUnitEnrollmentsForSectionPage = gql`
  query FindLearningUnitEnrollmentsForSectionPage($subjectEnrollmentId: ID!) {
    data: findLearningUnitEnrollments(
      subjectEnrollmentId: $subjectEnrollmentId
    ) {
      id
      startDate
      endDate
      learningUnitId
      tempLearningUnitId
      learningUnitVersionId
      title
      status
      order
      template {
        assetElementConfigs {
          backgroundColor
          bannerUrl
          borderStyle
          element
          fontColor
          mobileBannerUrl
        }
        background
        name
      }
      sections {
        id
        title
        order
        pathUrl @client
        progress @client {
          quantity
          completed
          percentage
          percentageLabel
        }
        learningObjects {
          id
          title
          subtitle
          completed
          duration
          type @client
          pathUrl @client
          assets {
            ... on Asset {
              id
              type
            }
            ... on VideoAsset {
              mediastreamId
              captionLink
              size
              videoLink
            }
            ... on ImageAsset {
              caption
              imageId
              image {
                formats
                baseUrl
                descriptionText
              }
              imageUrls @client
            }
            ... on FileAsset {
              altText
              description
              id
              mimeType
              size
              type
              url
            }
            ... on TextAsset {
              content
            }
            ... on TableAsset {
              content
            }
            ... on FormulaAsset {
              content
            }
          }
        }
      }
    }
  }
`;
