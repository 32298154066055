import React from 'react';
import { RichText } from '@ampli/ui';
import { ButtonPrimary } from '@sofia/ui';
import { bool, string } from 'prop-types';

import * as Styled from './subject-presentation-text-type.styles';
import { useTheme } from '@emotion/react';
import { SectionRoutesPath } from '../../../../../modules/section';
import { useGetState } from '../../../../../state';
import { generatePath, useHistory, useParams } from 'react-router-dom';

const SubjectPresentationTextType = ({
  content,
  hideStartButton,
  learningUnitId,
  pathUrl,
  buttonText,
}) => {
  const history = useHistory();
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();
  const { subjectEnrollmentId } = useParams();
  const theme = useTheme();

  return (
    <Styled.IntroductionContainer>
      <Styled.TextWrapper>
        <RichText>{content}</RichText>
      </Styled.TextWrapper>
      {!hideStartButton && (
        <Styled.ButtonWrapper>
          <ButtonPrimary
            color="white"
            id="start-subject-button"
            background={theme.colors.button.primary}
            onClick={() => {
              history.push(
                generatePath(SectionRoutesPath.subjectSection, {
                  courseType: selectedCourseEnrollmentType,
                  courseVersionId: selectedCourseVersionId,
                  subjectEnrollmentId: subjectEnrollmentId,
                  learningUnitEnrollmentId: learningUnitId,
                  sectionPath: pathUrl,
                  fromSubjectPage: true,
                })
              );
            }}
          >
            {buttonText}
          </ButtonPrimary>
        </Styled.ButtonWrapper>
      )}
    </Styled.IntroductionContainer>
  );
};

SubjectPresentationTextType.propTypes = {
  hideStartButton: bool,
  content: string,
  pathUrl: string,
  learningUnitId: string,
  buttonText: string,
};

export default SubjectPresentationTextType;
