import { array, arrayOf, number, object, shape, string } from 'prop-types';
import { generatePath, useHistory } from 'react-router-dom';
import { Accordion, Typography, useModal } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';

import { useQuizAttemptsData } from '../../../hooks';
import { useGetState } from '../../../state';
import { routePath } from '../../../routes';
import { PopUp } from '../../../modules/shared';
import { QUIZ } from '../../../constants';
import { sortObjectsByOrder } from '../../../utils';

import SubjectLearningUnitActivity from './activity/subject-learning-unit-activity';
import SubjectLearningUnitSection from './section/subject-learning-unit-section';
import * as Styled from './subject-learning-unit-card-styles';
import React from 'react';

import { PracticalActivitiesRoutesPath } from '../../../modules';
import { CardContainer as StyledCardContainer } from './subject-learning-unit-section-card-styles';

const SubjectLearningUnitCard = ({
  id,
  learningUnit,
  gradingMethod,
  subjectEnrollmentId,
  entitiesLabelsDictionary,
  nextPracticalActivity,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const hasQuizAssignmentInGrade = !!gradingMethod?.assignmentConfigs.find(
    (assignmentConfig) => assignmentConfig?.type === QUIZ
  );
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();
  const GetValidAttempts = (learningUnit) => {
    return useQuizAttemptsData({ learningUnit }).validAttempts;
  };

  const exceededQuizNumberAttemptsModal = useModal();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const practicalActivityRoute = `${handlePracticalActivityType()}PracticalActivities`;

  const handlePracticalActivityType = () => {
    if (nextPracticalActivity.__typename === 'PresentialPracticalActivity') {
      return 'presential';
    }
    return 'online';
  };

  return (
    <>
      <Styled.Container id={id}>
        <Accordion
          data-testid={`beta-learning-unit-card${learningUnit?.order}`}
          title={`${entitiesLabelsDictionary?.learningUnitTitle(
            learningUnit?.order
          )} - ${learningUnit?.title}`}
          markerColor={theme.colors.text.primary}
          iconColor={theme.colors.text.primary}
          contentMaxHeight="100rem"
        >
          <Styled.SectionsContainer>
            {sortObjectsByOrder(learningUnit?.sections)?.map((section, key) => (
              <SubjectLearningUnitSection
                key={key}
                section={section}
                learningUnitId={learningUnit.id}
                learningUnitOrder={learningUnit?.order}
              />
            ))}
          </Styled.SectionsContainer>

          {hasQuizAssignmentInGrade || nextPracticalActivity ? (
            <Styled.ActivitiesWrapper
              background={theme.colors.separator.lightColor}
              borderColor={theme.colors.separator.color}
            >
              <StyledCardContainer>
                {hasQuizAssignmentInGrade ? (
                  <SubjectLearningUnitActivity
                    onClick={() => {
                      GetValidAttempts(learningUnit) > 0
                        ? history.push(
                            generatePath(routePath.quiz, {
                              courseType: selectedCourseEnrollmentType,
                              courseVersionId: selectedCourseVersionId,
                              subjectEnrollmentId,
                              learningUnitEnrollmentId: learningUnit.id,
                            })
                          )
                        : exceededQuizNumberAttemptsModal.show();
                    }}
                    title="Exercício de unidades"
                    description="Reforce seu aprendizado e obtenha pontos par complementar sua
              nota!"
                    activityText="Fazer exercício"
                  />
                ) : null}

                {nextPracticalActivity ? (
                  <SubjectLearningUnitActivity
                    onClick={() => {
                      if (practicalActivityRoute) {
                        return history.push(
                          generatePath(
                            PracticalActivitiesRoutesPath[
                              practicalActivityRoute
                            ],
                            {
                              courseType: selectedCourseEnrollmentType,
                              courseVersionId: selectedCourseVersionId,
                              subjectEnrollmentId,
                              learningUnitEnrollmentId: learningUnit.id,
                            }
                          )
                        );
                      }
                    }}
                    title="Atividade Prática"
                    description="Aplique na prática os conhecimentos adquiridos na unidade."
                    activityText="Fazer atividade"
                    nextPracticalActivity={nextPracticalActivity}
                  />
                ) : null}
              </StyledCardContainer>
            </Styled.ActivitiesWrapper>
          ) : null}
        </Accordion>
      </Styled.Container>

      <PopUp
        popUpState={exceededQuizNumberAttemptsModal}
        headerText="Você já utilizou todas as tentativas!"
        footerText="Voltar"
        isDesktop={isDesktop}
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          Em breve, confira a correção dos exercícios na sua plataforma de
          estudos.
        </Typography>

        <Typography margin={0} size={16} color={theme.colors.text.lightBlack}>
          Lembrando que será considerada a maior pontuação!
        </Typography>
      </PopUp>
    </>
  );
};

SubjectLearningUnitCard.propTypes = {
  id: string.isRequired,
  learningUnit: shape({
    order: number.isRequired,
    title: string.isRequired,
    sections: arrayOf(object.isRequired).isRequired,
    assignments: array.isRequired,
  }).isRequired,
  gradingMethod: object.isRequired,
  subjectEnrollmentId: string.isRequired,
  entitiesLabelsDictionary: object,
  nextPracticalActivity: object,
};

export default SubjectLearningUnitCard;
