import React from 'react';
import { func, node, string, object } from 'prop-types';
import * as Styled from './subject-learning-unit-activitiy-styles';
import { Card, SvgFileText, SvgArrowRight, Typography, Tag } from '@sofia/ui';
import { clickByKey } from '@ampli/utils';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '@ampli/hooks';
import {
  FlexColumn as StyledFlexColumn,
  ActionText as StyledActionText,
} from '../subject-learning-unit-section-card-styles';
import { toExtensiveDate } from '../../../../modules';

const SubjectLearningUnitActivity = ({
  onClick,
  icon,
  title,
  description,
  activityText,
  nextPracticalActivity,
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const scheduling = nextPracticalActivity?.scheduling || null;

  const isToday =
    scheduling?.date &&
    new Date(scheduling.date).setHours(24, 0, 0, 0) ===
      new Date().setHours(0, 0, 0, 0);

  const showChangeDateIcon = () => {
    const threeDaysInMilliseconds = 24 * 60 * 60 * 1000 * 3;
    const dateOfLastUpdatePlusThreeDays =
      nextPracticalActivity?.orderOrInfoLastUpdate
        ? new Date(nextPracticalActivity.orderOrInfoLastUpdate).getTime() +
          threeDaysInMilliseconds
        : null;

    return dateOfLastUpdatePlusThreeDays &&
      dateOfLastUpdatePlusThreeDays < new Date().getTime() ? (
      <Styled.RecordIcon
        bgcolor={theme.colors.live.danger}
        data-testid="section-content-header-live-badge"
      />
    ) : null;
  };

  return (
    <Card
      hover={theme.colors.card.color.hover}
      borderColor={theme.colors.card.border.primary}
      onClick={onClick}
      onKeyDown={(event) => clickByKey(event, onClick)}
      tabIndex={0}
    >
      {isDesktop ? (
        <Styled.IconContainer stroke={theme.colors.text.black}>
          {icon || <SvgFileText aria-hidden />}
        </Styled.IconContainer>
      ) : null}
      <StyledFlexColumn>
        <Styled.Title color={theme.colors.text.black}>{title}</Styled.Title>
        <Styled.Desc color={theme.colors.text.lightBlack}>
          {description}
        </Styled.Desc>
      </StyledFlexColumn>
      {scheduling ? (
        <Styled.NextActivityWrapper>
          <Typography
            weight={isDesktop ? 400 : 700}
            color={theme.colors.text[isDesktop ? 'lightBlack' : 'black']}
          >
            Próxima atividade
          </Typography>
          <Tag
            size={14}
            theme={{
              color:
                theme.colors.text[
                  scheduling?.date
                    ? isToday
                      ? 'darkerWarning'
                      : 'black'
                    : 'lightBlack'
                ],
              background: isToday
                ? theme.colors.communication.background.primary
                : theme.colors.tag.primary,
            }}
            text={
              <Styled.Flex>
                {showChangeDateIcon()}
                {toExtensiveDate({
                  date: scheduling?.date,
                  time: scheduling?.startTime,
                  isDesktop,
                })}
              </Styled.Flex>
            }
          />
        </Styled.NextActivityWrapper>
      ) : null}
      {isDesktop ? (
        <StyledActionText width="100%" color={theme.colors.text.primary}>
          <span>{activityText}</span>
          <SvgArrowRight aria-hidden />
        </StyledActionText>
      ) : null}
    </Card>
  );
};

SubjectLearningUnitActivity.defaultProps = {
  icon: null,
};

SubjectLearningUnitActivity.propTypes = {
  onClick: func.isRequired,
  icon: node,
  title: string.isRequired,
  description: string.isRequired,
  activityText: string.isRequired,
  nextPracticalActivity: object,
};

export default SubjectLearningUnitActivity;
