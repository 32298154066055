import React from 'react';

import { SvgExit, Typography, SvgTextSize } from '@sofia/ui';
import { node, func, bool } from 'prop-types';
import { useTheme } from '@emotion/react';
import { TopBar } from '@ampli/ui';

import { SimpleLayout, LayoutContent as Container } from '../../..';

import * as Styled from './quiz-correction-layout.styles';

const QuizCorrectionLayout = ({
  onFontSizeClick,
  talkTutorFrame,
  onCloseClick,
  isResponsive,
  children,
  title,
}) => {
  const theme = useTheme();

  const exitButton = onCloseClick ? (
    <Styled.QuizButton
      color={theme.colors.text.lightBlack}
      aria-label="Fechar correção"
      onClick={onCloseClick}
    >
      <SvgExit fill={theme.colors.text.lightBlack} />
      <Typography weight={isResponsive ? 700 : 400}>
        Sair da correção
      </Typography>
    </Styled.QuizButton>
  ) : null;

  const buttonsContent = (exitBtn = null) => (
    <>
      {title}
      <Styled.ButtonsWrapper>
        <Styled.QuizButton
          color={theme.colors.text.lightBlack}
          weight={400}
          onClick={onFontSizeClick}
          aria-label="Alterar o tamanho da fonte"
        >
          <SvgTextSize fill={theme.colors.text.lightBlack} />
          <Typography>Tamanho da fonte</Typography>
        </Styled.QuizButton>

        {exitBtn}
      </Styled.ButtonsWrapper>
    </>
  );

  return (
    <SimpleLayout talkTutorFrame={talkTutorFrame}>
      <TopBar as="header" sticky={!isResponsive}>
        <Container padding={isResponsive ? ['none'] : ['small']}>
          {isResponsive ? (
            exitButton
          ) : (
            <Styled.FlexBetween>
              {buttonsContent(exitButton)}
            </Styled.FlexBetween>
          )}
        </Container>
      </TopBar>

      {isResponsive ? (
        <Styled.FlexColumn>{buttonsContent()}</Styled.FlexColumn>
      ) : null}

      <Container padding={isResponsive ? ['none'] : ['large', 'small', 'huge']}>
        {isResponsive ? (
          children
        ) : (
          <Styled.FlexBetween>{children}</Styled.FlexBetween>
        )}
      </Container>
    </SimpleLayout>
  );
};

QuizCorrectionLayout.defaultProps = {
  isResponsive: false,
};

QuizCorrectionLayout.propTypes = {
  title: node.isRequired,
  children: node.isRequired,
  onFontSizeClick: func.isRequired,
  onHelpClick: func.isRequired,
  onCloseClick: func,
  isResponsive: bool,
  talkTutorFrame: node,
};

export default QuizCorrectionLayout;
