import React from 'react';

import {
  getFrameFormSchema,
  SharedContextProps,
  secondsToMinutes,
  FormValuesProps,
  UploadedFile,
  useFrame,
  Formik,
} from '../../../shared';

import {
  initialAmpliPage,
  initialValues,
  AmpliPages,
  reducer,
  State,
} from './ampli-frame-state';

import { useAmpliLogic } from '../hooks/use-ampli-logic';
import { useGetState } from '../../../../../../../state';

import { useMutation } from 'react-query';
import { useFormik } from '@ampli/utils';

export type ProviderProps = {
  children: React.ReactNode;
};

export type ContextProps = Partial<SharedContextProps> & {
  hideAttachmentField: boolean;
  dispatch: React.Dispatch<Partial<State>>;
  loading: boolean;
  formik: Formik;
  page: AmpliPages;
  onUploadFile: (label: string, value: UploadedFile[]) => Promise<void>;
  onSubmit: (e: React.FormEvent) => void;
  onClose: () => void;
};

export const AmpliFrameContext = React.createContext<ContextProps>({
  hideAttachmentField: false,
  scrollType: '',
  loading: false,
  isAmpli: true,
  formik: {},
  page: 'FORM',
  onUploadFile: async () => {},
  dispatch: () => {},
  onSubmit: () => {},
  onClose: () => {},
});

export const AmpliFrameProvider: React.FC<ProviderProps> = ({
  children,
}: ProviderProps) => {
  let formik: Formik = React.useMemo(() => ({}), []);

  const [state, dispatch] = React.useReducer(reducer, initialValues);

  const [{ videoTime }] = useGetState();

  const { scrollType, courseData, company, isAmpli, subject, me } = useFrame();
  const { createTicket } = useAmpliLogic();

  const onError = React.useCallback(
    (error: unknown) => {
      dispatch({ page: 'ERROR' });
      formik.resetForm();
      console.error(error);
    },
    [formik]
  );

  const createTicketMutation = useMutation(createTicket, {
    onError: (error) => onError(error),
    onSuccess: () => {
      dispatch({ page: 'SUCCESS' });
    },
  });

  const time_elapsed_video = React.useMemo(
    () =>
      videoTime[state.infos?.learningObjectID]
        ? secondsToMinutes(videoTime[state.infos?.learningObjectID])
        : null,
    [state.infos?.learningObjectID, videoTime]
  );

  const handleSubmit = React.useCallback(
    async (values: FormValuesProps) =>
      createTicketMutation.mutateAsync({
        me,
        description: values?.description,
        attachment: values?.attachment,
        companyName: company?.name,
        course: courseData?.course?.name,
        subject: subject?.name,
        unity: state.infos?.unity,
        class: state.infos?.class,
        learningObject: state.infos?.learningObject,
        openTalkTutorFrameDate: state.infos?.openTalkTutorFrameDate,
        time_elapsed_video,
      }),
    [
      state.infos?.openTalkTutorFrameDate,
      state.infos?.learningObject,
      courseData?.course?.name,
      createTicketMutation,
      state.infos?.unity,
      state.infos?.class,
      time_elapsed_video,
      company?.name,
      subject?.name,
      me,
    ]
  );

  formik = useFormik({
    validationSchema: getFrameFormSchema(),
    validateOnChange: false,
    validateOnBlur: true,
    validateOnMount: false,
    initialErrors: false,
    onSubmit: handleSubmit,
    initialValues: { description: '', attachment: null },
  });

  const onUploadFile = React.useCallback(
    async (label: string, value: UploadedFile[]) => {
      await formik.setFieldValue(label, value);
      await formik.setFieldTouched(label, true);

      if (value === null) {
        formik.setErrors({ attachment: formik.errors.attachment });
      }
    },
    [formik]
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      formik.handleSubmit();
    },
    [formik]
  );

  const onClose = React.useCallback(() => {
    state.onCloseClick && state.onCloseClick();
    formik.resetForm();
    dispatch({ page: initialAmpliPage });
  }, [formik, state]);

  return (
    <AmpliFrameContext.Provider
      value={{
        hideAttachmentField: state.hideAttachmentField,
        scrollType,
        loading: createTicketMutation.isLoading,
        isAmpli,
        page: state.page,
        onUploadFile,
        dispatch,
        onSubmit,
        onClose,
        formik,
      }}
    >
      {children}
    </AmpliFrameContext.Provider>
  );
};

export const useAmpliFrame = (): ContextProps =>
  React.useContext(AmpliFrameContext);
