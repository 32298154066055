import React from 'react';
import PropTypes from 'prop-types';
import { LayoutContent } from '../../../../components/layout';
import { ServiceRequestSkeleton } from '../skeleton';
import { Typography, ButtonPrimary, SvgDownloadAlt } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import Card from '../card/card';
import {
  analyticsEvents,
  secretaryCertificateOfEnrollmentReady,
} from '../../constants';
import { format } from 'date-fns';
import { useGetLastOrder } from '../../hooks';
import pt from 'date-fns/locale/pt-BR';

import {
  Container,
  Img,
  ColLeft,
  ColRight,
  ContainerText,
  Row,
  ContainerButton,
} from './service-request-ready-styles';

import IMAGE_EMAIL_VERIFICATION from '../../../../assets/images/secretary/email-verification.png';
import StudentCardModal from '../student-card-modal/student-card-modal';
import downloadDocument from '../../utils/download-document';
import { pushAnhangueraEvent } from '../../../../lib/ga';

export default function SecretaryCertificateOfEnrollmentReady({
  typeOfStatus,
  courseEnrollmentId,
  orderSubject,
  handlers,
  setStep,
}) {
  const theme = useTheme();
  const texts = secretaryCertificateOfEnrollmentReady[typeOfStatus];

  const { data, loading } = useGetLastOrder({
    courseEnrollmentId: courseEnrollmentId,
    orderSubject: orderSubject,
  });

  const treatingDateText = (date) => {
    return format(new Date(date), "dd/MM/yyyy 'às' HH:mm", {
      locale: pt,
    });
  };

  const handleOpenModal = () => {
    handlers.applyRequestNewStudentCardDialog.show();
  };

  const handleDownloadDocument = () => {
    pushAnhangueraEvent(
      'event',
      analyticsEvents[typeOfStatus]?.downloadDocument
    );

    downloadDocument({
      documentUrl: data?.orderUrl,
      documentName: 'Carteirinha_de_estudandte.pdf',
    });
  };

  return (
    <>
      <LayoutContent>
        {loading ? (
          <ServiceRequestSkeleton />
        ) : (
          <Container>
            <ColLeft>
              <Img
                aria-hidden={true}
                tabIndex={-1}
                src={IMAGE_EMAIL_VERIFICATION}
              />
              <ContainerText>
                <Typography
                  margin="15px 0 10px 0"
                  weight="700"
                  color={theme.colors.text.black}
                  size={24}
                >
                  {texts?.titleText}
                </Typography>
                <Typography
                  margin="0px"
                  color={theme.colors.text.black}
                  weight="500"
                  size={16}
                >
                  {texts?.textDesc}
                </Typography>
              </ContainerText>
            </ColLeft>
            <ColRight>
              <Card noBorder={true}>
                <Typography
                  margin="0 0 20px 0"
                  color={theme.colors.text.black}
                  weight="bold"
                  size={28}
                >
                  {texts?.titleCard}
                </Typography>
                <Row>
                  <Typography
                    margin="0 0 20px 0"
                    color={theme.colors.text.black}
                    size={16}
                    weight={700}
                  >
                    Solicitação
                  </Typography>
                  {data?.requestDate ? (
                    <Typography
                      margin="0 0 20px 0"
                      color={theme.colors.text.lightBlack}
                      weight="400"
                      size={16}
                    >
                      {treatingDateText(data?.requestDate)}
                    </Typography>
                  ) : null}
                </Row>

                {typeOfStatus !== 'isStudentCard' &&
                typeOfStatus !== 'isAcademicTranscript' ? (
                  <Row>
                    <Typography
                      margin="0 0 20px 0"
                      color={theme.colors.text.black}
                      weight="400"
                      size={16}
                    >
                      Validade
                    </Typography>
                    <Typography
                      margin="0 0 20px 0"
                      color={theme.colors.text.black}
                      weight="700"
                      size={16}
                    >
                      90 dias a partir da solicitação
                    </Typography>
                  </Row>
                ) : null}

                <Row>
                  <Typography
                    margin="0 0 20px 0"
                    color={theme.colors.text.black}
                    size={16}
                    weight={700}
                  >
                    Protocolo
                  </Typography>
                  <Typography
                    margin="0 0 20px 0"
                    color={theme.colors.text.lightBlack}
                    weight="400"
                    size={16}
                  >
                    {data?.ticketNumber}
                  </Typography>
                </Row>

                <ContainerButton>
                  <ButtonPrimary
                    onClick={handleDownloadDocument}
                    width="100%"
                    background={theme.colors.button.primary}
                    color={theme.colors.button.text.primary}
                    tabIndex={0}
                    margin="0 0 10px 0"
                  >
                    <SvgDownloadAlt /> Baixar documento
                  </ButtonPrimary>
                  <ButtonPrimary
                    width="100%"
                    background="transparent"
                    onClick={handleOpenModal}
                  >
                    <Typography
                      weight={700}
                      size={16}
                      color={theme.colors.button.primary}
                    >
                      {typeOfStatus === 'isStudentCard'
                        ? 'Solicitar nova carteirinha'
                        : 'Solicitar novo histórico escolar'}
                    </Typography>
                  </ButtonPrimary>
                </ContainerButton>
              </Card>
            </ColRight>
          </Container>
        )}
        {typeOfStatus === 'isStudentCard' ||
        typeOfStatus === 'isAcademicTranscript' ? (
          <StudentCardModal
            handlers={handlers}
            setStep={setStep}
            typeOfStatus={typeOfStatus}
          />
        ) : null}
      </LayoutContent>
    </>
  );
}

SecretaryCertificateOfEnrollmentReady.propTypes = {
  typeOfStatus: PropTypes.string,
  courseEnrollmentId: PropTypes.string,
  orderSubject: PropTypes.string,
  handlers: PropTypes.object,
  setStep: PropTypes.func,
};
