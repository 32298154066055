import { AffiliateBrands, brandInfo } from '..';
import { parseAddress } from './utils';
import { Address } from './types';

type PoloContactInfosType = {
  whatsapp: null | { url: string; name: string };
  phone: null | { url: string; name: string };
  address: string;
};

const whatsappUrl = (phone: string) =>
  'https://api.whatsapp.com/send?phone=' + phone;

const whatsappName = (name: string) => {
  const mask = name.match(/(\d{2})(\d{2})(\d{5})(\d{4})/);

  return '(' + mask?.[2] + ') ' + mask?.[3] + '-' + mask?.[4];
};

const phone = { url: 'tel:3003-4030', name: '3003-4030' };

export const poloContactInfos = (
  affiliateBrand: AffiliateBrands,
  affiliateType: 'POLO' | 'POLO_PARCEIRO',
  affiliateAddress: Address
): PoloContactInfosType =>
  ({
    BRAND_AMPLI: {
      phone,
      address: parseAddress(affiliateAddress),
      whatsapp: {
        url: whatsappUrl(brandInfo.ampli.whatsappPhone),
        name: whatsappName(brandInfo.ampli.whatsappPhone),
      },
    },
    BRAND_ANHANGUERA: {
      POLO: {
        phone,
        address: parseAddress(affiliateAddress),
        whatsapp: {
          url: whatsappUrl(brandInfo.anhanguera.whatsappPhone),
          name: whatsappName(brandInfo.anhanguera.whatsappPhone),
        },
      },
      POLO_PARCEIRO: {
        phone: null,
        whatsapp: null,
        address: parseAddress(affiliateAddress),
      },
    }[affiliateType],
    BRAND_PITAGORAS: {
      POLO: {
        phone,
        address: parseAddress(affiliateAddress),
        whatsapp: {
          url: whatsappUrl(brandInfo.pitagoras.whatsappPhone),
          name: whatsappName(brandInfo.pitagoras.whatsappPhone),
        },
      },
      POLO_PARCEIRO: {
        phone: null,
        whatsapp: null,
        address: parseAddress(affiliateAddress),
      },
    }[affiliateType],
    BRAND_UNIC: {
      POLO: {
        phone,
        address: parseAddress(affiliateAddress),
        whatsapp: {
          url: whatsappUrl(brandInfo.unic.whatsappPhone),
          name: whatsappName(brandInfo.unic.whatsappPhone),
        },
      },
      POLO_PARCEIRO: {
        phone: null,
        whatsapp: null,
        address: parseAddress(affiliateAddress),
      },
    }[affiliateType],
    BRAND_UNIDERP: {
      POLO: {
        phone,
        address: parseAddress(affiliateAddress),
        whatsapp: {
          url: whatsappUrl(brandInfo.uniderp.whatsappPhone),
          name: whatsappName(brandInfo.uniderp.whatsappPhone),
        },
      },
      POLO_PARCEIRO: {
        phone: null,
        whatsapp: null,
        address: parseAddress(affiliateAddress),
      },
    }[affiliateType],
    BRAND_UNIME: {
      POLO: {
        phone,
        address: parseAddress(affiliateAddress),
        whatsapp: {
          url: whatsappUrl(brandInfo.unime.whatsappPhone),
          name: whatsappName(brandInfo.unime.whatsappPhone),
        },
      },
      POLO_PARCEIRO: {
        phone: null,
        whatsapp: null,
        address: parseAddress(affiliateAddress),
      },
    }[affiliateType],
    BRAND_UNOPAR: {
      POLO: {
        phone,
        address: parseAddress(affiliateAddress),
        whatsapp: {
          url: whatsappUrl(brandInfo.unopar.whatsappPhone),
          name: whatsappName(brandInfo.unopar.whatsappPhone),
        },
      },
      POLO_PARCEIRO: {
        phone: null,
        whatsapp: null,
        address: parseAddress(affiliateAddress),
      },
    }[affiliateType],
  }[affiliateBrand]);

export const descriptionByPolo = {
  partnerPole:
    'Caso esteja com dúvidas ou precisando de ajuda, fique à vontade de procurar seu local de ensino no endereço informado ou entrar em contato através dos canais de atendimento disponibilizados',
  othersPoles:
    'Está com dúvidas ou precisando de ajuda, fique à vontade de entrar em contato através dos nossos canais de atendimento',
};
