import { authStorage } from '@ampli/utils';
import { basename } from '../config';

const validateAccountGuard = async (to, from, next) => {
  try {
    const { setOnboarding } = to.meta?.actions;
    const { onboarding } = to.meta?.state;
    const { whitelisting } = to.location?.state;
    const data = await authStorage.getItem('loginInfo');

    if (!data) throw new Error('Not allowed.');
    if (data.forcePasswordReset && !onboarding && setOnboarding) {
      setOnboarding(true);
    }
    if (
      !data.forcePasswordReset &&
      from.location.pathname.replace(basename, '/') === '/login'
    ) {
      next.redirect('/login/senha');
    } else if (data.forcePasswordReset || whitelisting) {
      next();
    } else {
      throw new Error('Not allowed.');
    }
  } catch (error) {
    console.warn(error);
    await authStorage.removeItem('loginInfo');
    next.redirect('/login');
  }
};

export default validateAccountGuard;
