import { GET_COURSE_ENROLLMENT_ROLES } from '@ampli/services';
import { generatePath } from 'react-router-dom';
import { routePath } from '../routes';

const onboardingGuard = async (to, from, next) => {
  try {
    const privateClient = to.meta?.clients?.privateClient;
    const selectedCourseEnrollmentId =
      to.meta?.state?.selectedCourseEnrollmentId;

    const noAuth = to.meta?.auth?.noAuth;
    const onboarding = to.meta?.state?.onboarding;
    const isOnboardingPage = to.meta?.isOnboardingPage;
    const isOnboardingTrigger = to.meta?.isOnboardingTrigger;

    if (!onboarding && isOnboardingPage) throw new Error('Not allowed.');

    const notInOnboarding = onboarding && !isOnboardingPage && !noAuth;
    let hasChatEnabled = false;

    if (isOnboardingTrigger && selectedCourseEnrollmentId) {
      const response = await privateClient.query({
        query: GET_COURSE_ENROLLMENT_ROLES,
        variables: {
          courseEnrollmentId: selectedCourseEnrollmentId,
        },
      });

      hasChatEnabled = response?.data?.data?.roles?.includes('CHAT_ENABLED');
    } else {
      next();
    }

    // TODO: Remove after invetigation on onboarding bug
    // eslint-disable-next-line sonarjs/no-all-duplicated-branches
    if (notInOnboarding && isOnboardingTrigger && hasChatEnabled) {
      next();
      // next.redirect(routePath.onboardingWelcome);
    } else {
      next();
    }
  } catch (error) {
    console.warn(error);
    next.redirect(
      generatePath(routePath.home, {
        courseType: '',
      })
    );
  }
};

export default onboardingGuard;
