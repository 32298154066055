import { convertDateTimeTZ } from '.';

/* Returns boolean check if is same date as today */
const isSameDate = (startDate?: string | Date): boolean => {
  if (!startDate) return false;

  return (
    convertDateTimeTZ(startDate)?.getDay() ===
      convertDateTimeTZ(new Date())?.getDay() &&
    convertDateTimeTZ(startDate)?.getMonth() ===
      convertDateTimeTZ(new Date())?.getMonth() &&
    convertDateTimeTZ(startDate)?.getFullYear() ===
      convertDateTimeTZ(new Date())?.getFullYear()
  );
};

export default isSameDate;
