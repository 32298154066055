import { useTheme } from '@emotion/react';
import { InitPropsHook, GetAriaLabels } from '../types';

export const useInitProps: InitPropsHook = (
  questionChoices,
  evaluation,
  stepsControl
) => {
  const theme = useTheme();
  const numberOfQuestions = evaluation.questions.length;
  const answeredQuestions = questionChoices.filter((choice) => choice);
  const { step, toStep, isLastStep, isFirstStep, previousStep, nextStep } =
    stepsControl;
  const question = evaluation.questions[step];
  const navigationQuestions = () => {
    return [...evaluation.questions]?.map((question, index) => {
      return {
        id: question?.id,
        status: questionChoices[index] ? 'RESPONDIDAS' : 'DEFAULT',
      };
    });
  };

  return {
    theme,
    numberOfQuestions,
    answeredQuestions,
    question,
    navigationQuestions,
    step,
    toStep,
    isLastStep,
    isFirstStep,
    previousStep,
    nextStep,
  };
};

export const getAriaLabels: GetAriaLabels = (
  isLastStep,
  step,
  totalQuestions,
  durationInMinutes,
  timeLeftInMinutes,
  type
) => {
  const nextButtonLabel = isLastStep
    ? `Finalizar ${type}`
    : `Próxima questão. Ir para ${Math.min(
        step + 2,
        totalQuestions
      )} de ${totalQuestions}`;
  const previousButtonLabel = `Questão Anterior. Ir para ${step} de ${totalQuestions}`;
  const timerLabel = `Se passaram ${durationInMinutes} minutos de ${type}.
  Faltam ${timeLeftInMinutes} minutos para terminar.`;

  return { nextButtonLabel, previousButtonLabel, timerLabel };
};
