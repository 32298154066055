import React from 'react';
import { Separator } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import PopUp from '../pop-up/pop-up';
import { SIZES } from './helpers';
import { OptionsPopUpProps } from './types';
import { Wrapper } from './options-pop-up.styles';

const OptionsPopUpContainer: React.FC<OptionsPopUpProps> = ({
  isDesktop,
  popUpState,
  ariaLabel,
  headerText,
  children,
  ...rest
}: OptionsPopUpProps) => {
  const theme = useTheme();

  const optionsPopUp = {
    ariaLabel: ariaLabel,
    footer: <></>,
    headerText: headerText,
    isDesktop: isDesktop,
    popUpState: popUpState,
    headerMargin: `${SIZES.VH_32} ${SIZES.VW_32} ${SIZES.VH_24} ${SIZES.VW_32}`,
    ...rest,
  };

  return (
    <PopUp {...optionsPopUp}>
      <Separator
        color={theme.colors.separator.color}
        height="1px"
        margin="0 0 24px 0"
        width="100%"
      />
      <Wrapper.Content>
        <Wrapper.List>{children}</Wrapper.List>
      </Wrapper.Content>
    </PopUp>
  );
};

OptionsPopUpContainer.defaultProps = {
  headerText: 'Mais opções',
};

export default OptionsPopUpContainer;
