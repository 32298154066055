import styled from '@emotion/styled';

import { ButtonPrimaryLong, convertPXToVH } from '@sofia/ui';

export const FrameFooter = styled.div`
  padding: 24px 32px;
  margin-bottom: -1px;

  border-top: 1px solid #dee2e6;
  background: white;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ToFormButton = styled(ButtonPrimaryLong)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${convertPXToVH(13)} 0px;
`;

export const UnreadSignal = styled.div`
  width: 23px;
  height: 23px;
  background: ${({ theme }) => theme.colors.button.danger};
  border-radius: 999px;
  margin-right: 8px;
`;
