import React from 'react';
import { func, object, string } from 'prop-types';
import {
  Typography,
  ButtonOutline,
  SvgQuestionCircle,
  ProgressBar,
  Card,
  SvgCalendarAlt,
  SvgInfoCircle,
  SvgReadWoman,
  convertPXToVW,
  SvgIlustraError,
  SvgIlustraCheck,
} from '@sofia/ui';
import { useTheme } from '@emotion/react';
import {
  ResumeCardTitleWrapper,
  ButtonWrapper,
  Wrapper,
  FlexWrapper,
  ButtonWidth,
  CardStatus,
} from './grade-styles';
import { useBreakpoint } from '@ampli/hooks';

const GradeSubjectResumeCard = ({
  subjectEnrollment,
  onHowIsTheGradeCalculatedClick,
  onSubjectEnrollmentPeriodClick,
}) => {
  const finalGradePercentage = subjectEnrollment.finalGrade / 100;
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const theme = useTheme();
  const totalPoints = () => {
    return subjectEnrollment.finalGrade !== 0
      ? `${subjectEnrollment.finalGrade} pontos`
      : 'Você ainda não tem pontos';
  };

  const chooseCardStatus = (status) => {
    const IN_PROGRESS = (
      <CardStatus color={theme.colors.background.secondary}>
        <SvgReadWoman />
        <Typography margin="40px 0 0 0" size={24} weight={700}>
          Você ainda está cursando esta matéria
        </Typography>
      </CardStatus>
    );
    const PASSED = (
      <CardStatus color={theme.colors.live.lightSuccess}>
        <SvgIlustraCheck />
        <Typography margin="40px 0 0 0" size={24} weight={700}>
          Você foi aprovado nesta matéria
        </Typography>
      </CardStatus>
    );
    const FAILED = (
      <CardStatus color={theme.colors.live.lightDanger}>
        <SvgIlustraError />
        <Typography margin="40px 0 0 0" size={24} weight={700}>
          Você foi reprovado nesta matéria
        </Typography>
      </CardStatus>
    );

    switch (status) {
      case 'IN_PROGRESS':
        return IN_PROGRESS;
      case 'PASSED':
        return PASSED;
      case 'FAILED':
        return FAILED;
      default:
        return IN_PROGRESS;
    }
  };

  return (
    <ResumeCardTitleWrapper>
      <Typography
        as="h1"
        color={theme.colors.text.black}
        size={isDesktop ? 48 : 40}
        weight={700}
      >
        {subjectEnrollment.subject.name}
      </Typography>
      <Typography
        margin="24px 0 40px 0"
        color={theme.colors.text.lightBlack}
        size={isDesktop ? 20 : 16}
        weight={400}
      >
        Aqui na página da disciplina você pode conferir a sua situação atual,
        suas notas e seu calendário de estudo e provas.
      </Typography>
      <ButtonWidth>
        <ButtonOutline
          content="flex-start"
          paddingMob="26px"
          margin="0 24px 0 0"
          padding="24px"
          borderBoxColor={theme.colors.card.border.primary}
          onClick={onHowIsTheGradeCalculatedClick}
        >
          <ButtonWrapper>
            <div>
              <SvgQuestionCircle />
            </div>
            <Typography
              margin="0 0 0 18px"
              color={theme.colors.text.black}
              size={isDesktop ? 20 : 16}
              weight={400}
            >
              Como é calculada minha nota?
            </Typography>
          </ButtonWrapper>
        </ButtonOutline>
        <ButtonOutline
          content="flex-start"
          paddingMob="26px"
          onClick={onSubjectEnrollmentPeriodClick}
          padding="24px"
          borderBoxColor={theme.colors.card.border.primary}
        >
          <ButtonWrapper>
            <div>
              <SvgCalendarAlt fill={theme.colors.text.primary} />
            </div>
            <Typography
              margin="0 0 0 18px"
              color={theme.colors.text.black}
              size={isDesktop ? 20 : 16}
              weight={400}
            >
              Calendário da matéria
            </Typography>
          </ButtonWrapper>
        </ButtonOutline>
      </ButtonWidth>
      <Typography
        as="h3"
        margin={isDesktop ? '80px 0 24px 0' : '48px 0 24px 0'}
        color={theme.colors.text.black}
        size={isDesktop ? 32 : 24}
        weight={700}
      >
        Sua situação atual na matéria
      </Typography>
      <ButtonWidth>
        <Card
          noHover
          cursor="default"
          minWidthMobile="auto"
          padding="32px"
          minHeight="0px"
          minWidth={convertPXToVW(615)}
          margin="0 0 24px 0"
          background={theme.colors.background.primary}
        >
          <Wrapper>
            <Typography
              as="span"
              weight={700}
              color={theme.colors.text.lightBlack}
              size={isDesktop ? 24 : 16}
            >
              Pontuação total
            </Typography>
            <Typography
              weight={700}
              color={theme.colors.text.black}
              size={isDesktop ? 32 : 20}
              margin={isDesktop ? '56px 0 24px 0' : '32px 0 24px 0'}
            >
              {totalPoints()}
            </Typography>
            <ProgressBar
              progressBackground={{
                background: theme.colors.background.info,
                height: 16,
              }}
              progressBar={{
                background: theme.colors.card.border.secondary,
                height: 16,
              }}
              progress={finalGradePercentage * 100}
            />
            <FlexWrapper>
              <Typography
                weight={700}
                color={theme.colors.text.lightBlack}
                size={16}
                margin="16px 0 0 0"
              >
                0 pontos
              </Typography>
              <Typography
                weight={700}
                color={theme.colors.text.lightBlack}
                size={16}
                margin="16px 0 0 0"
              >
                100 pontos
              </Typography>
            </FlexWrapper>
            <ButtonWidth align flex>
              <div>
                <SvgInfoCircle />
              </div>
              <Typography
                weight={700}
                color={theme.colors.text.lightBlack}
                size={16}
                margin={isDesktop ? '16px 0 0 18px' : '0 0 0 18px'}
              >
                Você precisa de {subjectEnrollment.gradingMethod.cutOffScore}{' '}
                pontos para ser aprovado
              </Typography>
            </ButtonWidth>
          </Wrapper>
        </Card>
        {chooseCardStatus(subjectEnrollment.status)}
      </ButtonWidth>
    </ResumeCardTitleWrapper>
  );
};

GradeSubjectResumeCard.propTypes = {
  className: string,
  subjectEnrollment: object.isRequired,
  onHowIsTheGradeCalculatedClick: func.isRequired,
  onSubjectEnrollmentPeriodClick: func.isRequired,
};

export default GradeSubjectResumeCard;
