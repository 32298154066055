import React from 'react';
import {
  useModal,
  Typography,
  CheckBox,
  ButtonPrimary,
  ButtonOutline,
} from '@sofia/ui';
import { ModalInterface, PopUp, useBreakpoint } from '../../../../modules';
import {
  FilterRow,
  ModalCard,
  ModalFooterWrapper,
  OptionsContainer,
} from './filter-modal.styles';
import { useTheme } from '@emotion/react';

interface FilterModalProps {
  content?: string;
  setSelectedFilters: (filters: string[]) => void;
  selectedFilters: string[];
}

const FilterModal = React.forwardRef(
  (
    { content, setSelectedFilters, selectedFilters }: FilterModalProps,
    ref: React.Ref<ModalInterface>
  ) => {
    const modal = useModal();
    const { isDesktop } = useBreakpoint();
    const theme = useTheme();

    const handleShowModal = () => modal.show();
    const handleHideModal = () => modal.hide();
    const [arrayAux, setArrayAux] = React.useState<string[]>(selectedFilters);

    React.useImperativeHandle(ref, () => ({
      onOpen() {
        handleShowModal();
      },
      onClose() {
        handleHideModal();
      },
    }));

    const handleCheckList = (option: string) => {
      if (arrayAux.includes(option)) {
        const index = arrayAux.indexOf(option);
        arrayAux.splice(index, 1);
        setArrayAux([...arrayAux]);
      } else {
        setArrayAux([...arrayAux, option]);
      }
    };

    const filterOptions = [
      { color: '#0047FF', text: 'Datas acadêmicas', value: 'ACADEMIC_DATES' },
      {
        color: '#BC00CC',
        text: 'Eventos exclusivos',
        value: 'UNIQUE_EVENTS',
      },
      { color: '#8000FF', text: 'Secretaria', value: 'SECRETARIAT' },
      { text: 'Financeiro', color: '#FF7A00', value: 'FINANCIAL' },
    ];

    const defaultContent = (
      <OptionsContainer>
        {filterOptions.map((option) => {
          return (
            <FilterRow color={option.color} key={option.text}>
              <ModalCard>
                <Typography size="16">{option.text}</Typography>
                <CheckBox
                  borderColor={theme.colors.checkbox.border.primary}
                  backgroundColor={theme.colors.checkbox.color.primary}
                  onChange={() => {
                    handleCheckList(option.value);
                  }}
                  checked={arrayAux.includes(option.value)}
                />
              </ModalCard>
            </FilterRow>
          );
        })}
      </OptionsContainer>
    );

    React.useEffect(() => {
      setArrayAux([...selectedFilters]);
    }, [selectedFilters]);

    return (
      <PopUp
        ariaLabel="Incluir filtro"
        headerText="Incluir filtro"
        isDesktop={isDesktop}
        popUpState={modal}
        footer={
          <ModalFooterWrapper>
            <ButtonOutline
              width="100%"
              borderBoxColor={theme.colors.text.primary}
              color={theme.colors.text.primary}
              onClick={() => {
                setArrayAux([]);
              }}
            >
              Limpar
            </ButtonOutline>
            <ButtonPrimary
              width="100%"
              background={theme.colors.button.primary}
              color={theme.colors.button.secondary}
              onClick={() => {
                handleHideModal();
                setSelectedFilters([...arrayAux]);
              }}
            >
              Filtrar
            </ButtonPrimary>
          </ModalFooterWrapper>
        }
        showFooter
      >
        {content || defaultContent}
      </PopUp>
    );
  }
);

FilterModal.displayName = 'FilterModal';

export default FilterModal;
