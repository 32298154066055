import { ICourseEnrollmentsData } from '../../../shared/contexts/course-enrollments-context.types';

export const noKindleSelector = 'no-kindle';

export const sectionContextDefaultProps = {
  courseEnrollment: {} as ICourseEnrollmentsData,
  course: null,
  externalId: '',
  subjectEnrollment: {},
  me: {},
  learningUnit: {},
  section: {},
  learningObjects: [],
  loading: false,
  template: null,
  studentFreeTrialCourseData: {},
  firstLearningObject: {},
  learningObject: {},
  learningObjectIndex: -1,
  previousLearningObject: null,
  nextLearningObject: null,
  isLastLearningObject: false,
  isFirstLearningObject: false,
  isLastLearningObjectToComplete: false,
};

export const sectionReducerInitialState = {
  openTalkTutorFrameDate: null,
  selectedCustomization: null,
  isVideoViewCompleted: null,
};
