import React, { useEffect, useCallback } from 'react';
import { string, object, func } from '@ampli/utils';
import {
  css,
  px2grid,
  useDialogState,
  Paragraph,
  Text,
  TextButton,
  Field,
  FileInput,
  Thumbnail,
} from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';

import { PersonSelfieImage } from '../image';
import OnboardingDocumentsWebcamModal from './onboarding-documents-webcam-modal';

const OnboardingSelfieDocument = ({ formik, onWebcamClick, ...props }) => {
  const webcamDialog = useDialogState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFieldValue = useCallback(formik.setFieldValue, []);
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  useEffect(() => {
    setFieldValue('documentType', 'SELFIE');
  }, [setFieldValue]);

  return (
    <div {...props}>
      <Paragraph marginSize="x-large">
        <Text>
          Tire ou carregue uma foto do seu rosto na altura dos olhos, sem
          óculos, boné ou outros acessórios.
        </Text>
      </Paragraph>
      <Paragraph as="div" marginSize="small">
        <Field
          as={FileInput}
          name="documentFile"
          onChange={(e) => {
            const file = e.target.files[0];
            setFieldValue('documentFile', file);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.documentFile}
          error={formik.touched.documentFile && formik.errors.documentFile}
          accept="image/jpeg,image/png,application/pdf"
        >
          {formik.values.documentFile ? (
            <Thumbnail
              file={formik.values.documentFile}
              className={css`
                margin-right: ${px2grid(16)};
              `}
            />
          ) : (
            <PersonSelfieImage
              className={css`
                margin-right: ${px2grid(16)};
              `}
            />
          )}
          <Text size="small">Foto do seu rosto</Text>
        </Field>
      </Paragraph>
      {isDesktop && (
        <Paragraph marginSize="x-large" textAlign="center">
          <TextButton size="x-small" underline onClick={webcamDialog.show}>
            Tirar foto pela Webcam
          </TextButton>
        </Paragraph>
      )}
      <OnboardingDocumentsWebcamModal
        {...webcamDialog}
        onSuccessClick={(e, blob) => {
          const selfieFile = new File([blob], 'selfie', {
            type: blob.type,
            size: blob.size,
          });
          setFieldValue('documentFile', selfieFile);
          onWebcamClick && onWebcamClick(e, blob);
        }}
      />
    </div>
  );
};

OnboardingSelfieDocument.propTypes = {
  className: string,
  formik: object.isRequired,
  onWebcamClick: func,
};

export default OnboardingSelfieDocument;
