import { mediaQuery, convertPXToREM } from '@sofia/ui';
import { css, getColor, getFontSize, getLineHeightSize } from '@ampli/ui';

export const buttonClassName = css`
  border: 1px solid ${getColor('line')};
  border-radius: 0;
  padding: ${convertPXToREM(16)} 0 !important;
  ${mediaQuery('tablet-max')} {
    font-size: ${getFontSize('x-small')};
    line-height: ${getLineHeightSize('x-small')};
  }
`;

export const completeButtonClassName = css`
  ${buttonClassName};

  background: ${getColor('accent-base')};
  border-color: ${getColor('accent-base')};
`;
