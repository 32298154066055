import { calculateProgress, DateTime, filter, find, size, map } from '@ampli/utils';
import { dateField } from './commons';
const inProgressStatus = ['IN_PROGRESS', 'IN_RECOVERY', 'AWAITING_CHOICE', 'PAUSED'];
const takenStatus = ['PASSED', 'FAILED'];
const ignoredStatus = ['CEASED'];
const approvedStatus = 'PASSED';
const completedStatus = ['PASSED', 'COMPLETED'];
const subjectEnrollmentsInProgress = {
  read(cached, _ref) {
    let {
      readField
    } = _ref;
    if (cached) return cached;
    const subjectEnrollmentRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      }
    });
    const notCeasedRefs = filter(subjectEnrollmentRefs, subjectEnrollmentRef => {
      const status = readField('status', subjectEnrollmentRef);
      return !ignoredStatus.includes(status);
    });
    const inProgressRefs = filter(notCeasedRefs, subjectEnrollmentRef => {
      const status = readField('status', subjectEnrollmentRef);
      return inProgressStatus.includes(status);
    });
    const startedRefs = filter(inProgressRefs, subjectEnrollmentRef => {
      const startDate = DateTime.fromISO(readField('startDate', subjectEnrollmentRef));
      const status = readField('status', subjectEnrollmentRef);
      return DateTime.local() >= startDate || inProgressStatus.includes(status);
    });
    const takenRefs = filter(notCeasedRefs, subjectEnrollmentRef => {
      const status = readField('status', subjectEnrollmentRef);
      return takenStatus.includes(status);
    });
    return find([startedRefs, inProgressRefs, takenRefs, notCeasedRefs], size) || [];
  }
};
const subjectEnrollmentsTaken = {
  read(cached, _ref2) {
    let {
      readField
    } = _ref2;
    if (cached) return cached;
    const subjectEnrollmentRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      }
    });
    return filter(subjectEnrollmentRefs, subjectEnrollmentRef => {
      const status = readField('status', subjectEnrollmentRef);
      return takenStatus.includes(status);
    });
  }
};
const subjectEnrollmentsToBeTaken = {
  read(cached, _ref3) {
    let {
      readField
    } = _ref3;
    if (cached) return cached;
    const subjectEnrollmentRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      }
    });
    const courseSubjectRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      },
      from: readField('course')
    });
    const subjectRefs = map(courseSubjectRefs, courseSubjectRef => readField('subject', courseSubjectRef));
    const subjectTakenRefs = map(filter(subjectEnrollmentRefs, subjectEnrollmentRef => {
      const status = readField('status', subjectEnrollmentRef);
      return !ignoredStatus.includes(status);
    }), subjectEnrollmentRef => readField('subject', subjectEnrollmentRef));
    const electiveSubjectTakenRefs = map(filter(subjectEnrollmentRefs, subjectEnrollmentRef => {
      const status = readField('status', subjectEnrollmentRef);
      const isElective = readField('isElective', subjectEnrollmentRef);
      return isElective && !ignoredStatus.includes(status);
    }), subjectEnrollmentRef => readField('subject', subjectEnrollmentRef));
    let electiveCounter = electiveSubjectTakenRefs.length;
    return map(filter(subjectRefs, subject => {
      if (!subject) {
        electiveCounter = electiveCounter - 1;
        return electiveCounter < 0;
      }
      return subjectTakenRefs.filter(sub => !!sub).map(sub => sub.__ref).indexOf(subject.__ref) < 0;
    }), subject => ({
      subject
    }));
  }
};
const progress = {
  read(cached, _ref4) {
    let {
      readField
    } = _ref4;
    if (cached) return cached;
    const courseSubjectRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      },
      from: readField('course')
    });
    const subjectEnrollmentRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      }
    });
    const approvedSubjectEnrollmentRefs = filter(subjectEnrollmentRefs, subjectEnrollmentRef => {
      const status = readField('status', subjectEnrollmentRef);
      return status === approvedStatus;
    });
    const {
      completed,
      quantity,
      percentage,
      percentageLabel
    } = calculateProgress(approvedSubjectEnrollmentRefs ? approvedSubjectEnrollmentRefs.length : 0, courseSubjectRefs ? courseSubjectRefs.length : 0);
    return {
      completed,
      quantity,
      percentage,
      percentageLabel
    };
  }
};
const numberOfSubjects = {
  read(cached, _ref5) {
    let {
      readField
    } = _ref5;
    if (cached) return cached;
    const courseRef = readField('course');
    const subjectRefs = readField('subjects', courseRef);
    return size(subjectRefs);
  }
};
const numberOfCompletedSubjects = {
  read(cached, _ref6) {
    let {
      readField
    } = _ref6;
    if (cached) return cached;
    const subjectEnrollmentRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      }
    });
    return size(filter(subjectEnrollmentRefs, subjectEnrollmentRef => {
      const status = readField('status', subjectEnrollmentRef);
      return completedStatus.includes(status);
    }));
  }
};
const roles = {
  read(cached, _ref7) {
    let {
      readField
    } = _ref7;
    if (cached) return cached;
    const courseRef = readField('course');
    const courseTypeRef = readField('courseType', courseRef);
    const degree = readField('degree', courseTypeRef);
    return degree === 'LANGUAGES' ? [] : ['PAYMENT_ENABLED'];
  }
};
export default {
  fields: {
    startDate: dateField,
    estimatedEndDate: dateField,
    subjectEnrollmentsInProgress,
    subjectEnrollmentsTaken,
    subjectEnrollmentsToBeTaken,
    progress,
    numberOfSubjects,
    numberOfCompletedSubjects,
    roles
  }
};