import React from 'react';
import { Tab } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { number, object, bool } from 'prop-types';
import { tabs } from '../helper';

const SubjectPresentationTabs = ({ hideTeacherTab }) => {
  const theme = useTheme();

  const upperFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return tabs.map((tab, index) => {
    const canShowTab = () => {
      if (tab.name === 'teacher') {
        return hideTeacherTab === false;
      } else {
        return true;
      }
    };

    return (
      canShowTab() && (
        <Tab
          key={index}
          id={tab.name}
          aria-label={`Aba ${tab.label}`}
          width={`calc(100% / ${tabs?.length})`}
          selectedColor={theme.colors.text.primary}
          selectedBorderColor={theme.colors.text.primary}
        >
          {upperFirst(tab?.label)}
        </Tab>
      )
    );
  });
};

SubjectPresentationTabs.propTypes = {
  tabState: object.isRequired,
  subjectGoalsSize: number,
  learningUnitsSize: number,
  hideTeacherTab: bool,
};

export default SubjectPresentationTabs;
