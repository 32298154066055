import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const Wrapper = styled.div`
  border-radius: 8px;
  border: 2px dashed ${({ theme }) => theme.colors.separator.lightColor};
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 16px;
  margin-top: 24px;
  flex-direction: column;

  ${mediaQuery('desktop')} {
    flex-direction: row;
  }

  > button {
    white-space: nowrap;
    margin-top: 0;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: solid 1px ${({ theme }) => theme.colors.separator.lightColor};
  margin-bottom: 16px;

  ${mediaQuery('desktop')} {
    border-right: solid 1px ${({ theme }) => theme.colors.separator.lightColor};
    margin-right: 16px;
    margin-bottom: 0;
    border-bottom: 0;
  }
`;
