import React from 'react';
import {
  BannerContainer,
  TextContainer,
  StyledSvgWomanShocked,
} from './free-trial-expired-banner.styles';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { string } from '@ampli/utils';
import { CryingFaceEmoji } from '@ampli/ui';

const FreeTrialExpiredBanner = ({ freeTrialStatus }) => {
  const theme = useTheme();
  const isFreeTrialExpired = freeTrialStatus === 'EXPIRED';

  return isFreeTrialExpired ? (
    <BannerContainer>
      <StyledSvgWomanShocked />
      <TextContainer>
        <Typography
          size={32}
          weight="700"
          margin="12px 0px"
          color={theme.colors.text.black}
        >
          Seu período grátis acabou! <CryingFaceEmoji />
        </Typography>
        <Typography size={16} weight="400" color={theme.colors.text.black}>
          Desbloqueie o conteúdo e continue a sua jornada conosco com acesso
          exclusivo a todas as aulas, atividades, provas e outros benefícios que
          só nóste proporcionamos.
        </Typography>
      </TextContainer>
    </BannerContainer>
  ) : null;
};

FreeTrialExpiredBanner.propTypes = {
  freeTrialStatus: string,
};

export default FreeTrialExpiredBanner;
