import { withQueryClient } from './hocs/withQueryClient';
import { FrameProvider } from './shared';

import {
  TalkTutorFrame as Frame,
  TalkTutorFrameProps,
} from './talk-tutor-frame';

import React from 'react';

export const TalkTutorFrame: React.FC<TalkTutorFrameProps> = (props) => {
  return withQueryClient(
    <FrameProvider>
      <Frame {...props} />
    </FrameProvider>
  );
};
