import * as types from './action-types';

const creator =
  (type, defaultPayload) =>
  (dispatch) =>
  (payload = defaultPayload, meta = {}) => {
    console.debug('ACTION', type, { payload, meta });
    return dispatch({ type, payload, meta });
  };

export const resetState = creator(types.RESET_STATE);
export const setOnboarding = creator(types.SET_ONBOARDING);
export const setOpenMenu = creator(types.SET_OPEN_MENU);
export const setOpenCustomization = creator(types.SET_OPEN_CUSTOMIZATION);
export const setOpenNotes = creator(types.SET_OPEN_NOTES);
export const setOpenCommunitySection = creator(
  types.SET_OPEN_COMMUNITY_SECTION
);
export const setOpenTalkTutorFrame = creator(types.SET_OPEN_TALK_TUTOR_FRAME);
export const setFontSize = creator(types.SET_FONT_SIZE);
export const setVideoTime = creator(types.SET_VIDEO_TIME);
export const setDebugBlockedFeatures = creator(
  types.SET_DEBUG_BLOCKED_FEATURES
);
export const closeInauguralSubjectTutorial = creator(
  types.SET_SHOW_INAUGURAL_SUBJECT_TUTORIAL,
  false
);
export const openInauguralSubjectTutorial = creator(
  types.SET_SHOW_INAUGURAL_SUBJECT_TUTORIAL,
  true
);

export const setCourseId = creator(types.SET_COURSE_ID);
export const setCourseVersionId = creator(types.SET_COURSE_VERSION_ID);
export const setCourseEnrollmentId = creator(types.SET_COURSE_ENROLLMENT_ID);
export const setCourseEnrollmentType = creator(
  types.SET_COURSE_ENROLLMENT_TYPE
);
export const setPersistData = creator(types.SET_PERSIST_DATA);
export const setAccessInternshipSubject = creator(
  types.SET_ACCESS_INTERNSHIP_SUBJECT
);
export const setHasShownNotesOnboarding = creator(
  types.SET_HAS_SHOWN_NOTES_ONBOARDING
);
export const setHasShownSectionEduCommunication = creator(
  types.SET_HAS_SHOWN_SECTION_EDU_COMMUNICATION
);
export const setReadingRuler = creator(types.SET_READING_RULER);
