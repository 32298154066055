import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW, mediaQuery } from '@sofia/ui';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${convertPXToVH(24)} ${convertPXToVW(24)} 0;

  ${mediaQuery('desktop')} {
    margin: ${convertPXToVH(32)} ${convertPXToVW(32)};
  }
`;

export const FooterWrapper = styled.div`
  margin: ${convertPXToVH(24)} ${convertPXToVH(24)} ${convertPXToVH(41)};

  & > button {
    width: 100%;
  }

  ${mediaQuery('desktop')} {
    margin: ${convertPXToVH(40)} ${convertPXToVW(32)} ${convertPXToVH(32)};
  }
`;
