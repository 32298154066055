import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW, mediaQuery } from '@sofia/ui';

export const BannerWrapper = styled.div<{ background: string }>`
  padding: 0 ${convertPXToVW(24)};
  background: ${({ background }) => background};

  ${mediaQuery('desktop')} {
    padding: ${convertPXToVH(16)} 0;
    margin-bottom: ${convertPXToVH(80)};
  }
`;
