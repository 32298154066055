import { useQuery } from '@ampli/services';
import { GET_FINANCIAL_ROLES } from './queries';
import { GetFinancialRolesHook } from './types';

export const useGetFinancialRoles: GetFinancialRolesHook = ({
  courseEnrollmentId,
  options = {},
}) => {
  const { data, loading, error } = useQuery(GET_FINANCIAL_ROLES, {
    ...options,
    variables: {
      courseEnrollmentId,
    },
    fetchPolicy: 'no-cache',
  });

  const financialRoles = loading || !data ? [] : data.data;

  return {
    data: financialRoles,
    loading,
    error,
  };
};
