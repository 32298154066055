import React from 'react';
import { SvgTextSize } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { ObjectCustomizationButtonProps } from '../types';
import { useRolesContext } from '../../../../contexts/communication-roles-context';
import { useBreakpoint } from '../../../../hooks';
import * as Styled from './object-customization-button.styles';

const ObjectCustomizationButton = ({
  onOpenCustomizationClick,
}: ObjectCustomizationButtonProps): React.ReactElement => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();

  const [hasActiveTour, setHasActiveTour] = React.useState<boolean>(false);

  const { communicationCapabilities } = useRolesContext();

  const hasSomeTourEnabled =
    communicationCapabilities?.course.ONBOARDING_TOUR_ENABLED ||
    communicationCapabilities?.platform.PLATFORM_ONBOARDING_TOUR_ENABLED;

  React.useEffect(() => {
    window.addEventListener('hasActiveTour', () => {
      if (hasSomeTourEnabled) setHasActiveTour(true);
    });

    return () => {
      window.removeEventListener('hasActiveTour', () => {
        setHasActiveTour(false);
      });
    };
  }, [hasActiveTour, hasSomeTourEnabled]);

  return (
    <>
      <Styled.CustomizationButton
        id="customization-button"
        data-testid="customization-button"
        onClick={() => {
          onOpenCustomizationClick();
        }}
        aria-controls="content-customization-menu"
        aria-label={'Customização'}
        /* styled props */
        borderColor={theme.colors.button.primary}
      >
        <SvgTextSize />
        {isDesktop ? 'Customização' : null}
      </Styled.CustomizationButton>
    </>
  );
};

export default ObjectCustomizationButton;
