import {
  InstalmentStatusEnum,
  InvoicePaymentMethodEnum,
  ChangeBillingDayStatusEnum,
} from '../types/enums';
import { convertPXToVW, convertPXToVH, convertPXToREM } from '@sofia/ui';

export const roles = {
  applyCoupon: 'APPLY_COUPON',
  changeBillingDay: 'CHANGE_BILLING_DAY',
  creditCardRecurring: 'CREDIT_CARD_RECURRING',
  dischargeDocument: 'DISCHARGE_DOCUMENT',
  incomeTax: 'INCOME_TAX',
  listCreditCard: 'LIST_CREDIT_CARD',
  makeRecurring: 'MAKE_RECURRING',
  negotiation: 'NEGOTIATION',
  newFinancial: 'NEW_FINANCIAL',
  newFinancialWeb: 'NEW_FINANCIAL_WEB',
  paymentBoleto: 'PAYMENT_BOLETO',
  paymentCreditCard: 'PAYMENT_CREDIT_CARD',
  paymentPIX: 'PAYMENT_PIX',
  removeCreditCard: 'REMOVE_CREDIT_CARD',
  saveForFutureUse: 'SAVE_FOR_FUTURE_USE',
  scholarship: 'SCHOLARSHIP',
  refund: 'REFUND',
  secondCallBillingEnabled: 'SECOND_CALL_BILLING_ENABLED',
};

export const instalmentStatusLabel = {
  [InstalmentStatusEnum.OPEN]: 'Aberta',
  [InstalmentStatusEnum.PAID]: 'Paga',
  [InstalmentStatusEnum.CANCELLED]: 'Cancelada',
  [InstalmentStatusEnum.BLOCKED]: 'Indisponível',
  [InstalmentStatusEnum.OVERDUE]: 'Atrasada',
  [InstalmentStatusEnum.PENDING]: 'Processando',
  [InstalmentStatusEnum.FAILED]: 'Pagamento falhou',
  [InstalmentStatusEnum.UNDERPAID]: 'Pagamento parcial',
  [InstalmentStatusEnum.AVAILABLE]: 'Disponível',

  [InstalmentStatusEnum.OVERPAID]: 'Pago a mais',
  [InstalmentStatusEnum.REFUNDED]: 'Reembolsado',
  [InstalmentStatusEnum.WROTE_OFF]: 'Outros meios de pagamento',
};

type PaymentLabels = {
  [key in InvoicePaymentMethodEnum]: string;
};

export const paymentMethodsLabel: PaymentLabels = {
  [InvoicePaymentMethodEnum.PIX]: 'Pix',
  [InvoicePaymentMethodEnum.BOLETO]: 'Boleto',
  [InvoicePaymentMethodEnum.CREDIT_CARD]: 'Cartão de crédito',
  [InvoicePaymentMethodEnum.EXEMPTION]: 'Isenção',
  [InvoicePaymentMethodEnum.OTHER]: 'Pagamento por compensação ou crédito',
  [InvoicePaymentMethodEnum.SCHOLARSHIP_100]: 'Bolsa 100%',
  [InvoicePaymentMethodEnum.WROTE_OFF]: 'Baixado',
  [InvoicePaymentMethodEnum.NEGOTIATION]: 'Negociação',
};

export const paymentQueryParams = {
  INSTALMENTS: 'parcelas',
};

export const PaymentWarningType = {
  danger: 'DANGER',
  info: 'INFO',
  warning: 'WARNING',
};

export enum InvoiceBlockType {
  GENERIC = 'GENERIC',
  DOCUMENTS = 'DOCUMENTS',
}

export enum FinGaEvents {
  FIN_CUPOM = 'fin_cupom',
  FIN_INCOME_TAX = 'fin_income_tax',
  FIN_HOME_ENCAMINHAMENTO_NEGOCIACAO = 'fin_home_encaminhamento_negociacao',
  FIN_HOME_EXTRATO_FINANCEIRO = 'fin_home_extrato_financeiro',
  FIN_HOME_ALTERACAO_VENCIMENTO = 'fin_home_alteracao_vencimento',
  FIN_HOME_DECLARACAO_ANUAL_DE_QUITACAO = 'fin_home_declaracao_anual_de_quitacao',
  FIN_HOME_FATURA_EM_ABERTO = 'fin_home_fatura_em_aberto',
  FIN_RESUMO_FATURA_EM_ABERTO_DETALHES = 'fin_resumo_fatura_em_aberto_detalhes',
  FIN_RESUMO_DETALHES_DOWNLOAD = 'fin_resumo_detalhes_download',
  FIN_RESUMO_ENCAMINHAMENTO_NEGOCIACAO = 'fin_resumo_encaminhamento_negociacao',
  FIN_FORMA_PAGAMENTO_CREDITO = 'fin_forma_pagamento_credito',
  FIN_FORMA_PAGAMENTO_PIX = 'fin_forma_pagamento_pix',
  FIN_FORMA_PAGAMENTO_BOLETO = 'fin_forma_pagamento_boleto',
  FIN_BOLETO_DETALHES = 'fin_boleto_detalhes',
  FIN_BOLETO_GERADO_COPIAR_LINHA = 'fin_boleto_gerado_copiar_linha',
  FIN_WEB_BOLETO_GERADO_IMPRIMIR = 'fin_web_boleto_gerado_imprimir',
  FIN_CREDITO_DETALHES = 'fin_credito_detalhes',
  FIN_EDITAR_ENDERECO_COBRANCA = 'fin_editar_endereço_cobrança',
  FIN_CREDITO_PROCESSANDO_PAGAMENTO = 'fin_credito_processando_pagamento',
  FIN_CREDITO_ERRO_PAGAMENTO = 'fin_credito_erro_pagamento',
  FIN_CREDITO_SUCESSO_PAGAMENTO = 'fin_credito_sucesso_pagamento',
  FIN_RESUMO_RETORNO_ANTIGA = 'fin_resumo_retorno_antiga',
  FIN_RESUMO_PAGAR_SELECIONADA = 'fin_resumo_pagar_selecionada',
  FIN_RESUMO_PAGAR_SELECIONADA_COM_ANTERIOR_ABERTA = 'fin_resumo_pagar_selecionada_com_anterior_aberta',
  FIN_EXTRATO_DETALHES = 'fin_extrato_detalhes',
  FIN_EXTRATO_DETALHES_DOWNLOAD = 'fin_extrato_detalhes_download',
  FIN_HOME_BENEFICIOS = 'fin_home_beneficios',
  FIN_BENEFICIOS_DETALHES = 'fin_beneficios_detalhes',
  FIN_BENEFICIOS_DETALHES_ENVIAR_DOCUMENTO = 'fin_beneficios_detalhes_enviar_documento',
  FIN_BENEFICIOS_ENVIAR_DOCUMENTO_SUCESSO = 'fin_beneficios_enviar_documento_sucesso',
}

export enum AK {
  TAB = 'Tab',
  ENTER = 'Enter',
  SPACE = ' ',
  ESC = 'Escape',
  LEFT = 'ArrowLeft',
  UP = 'ArrowUp',
  RIGHT = 'ArrowRight',
  DOWN = 'ArrowDown',
}

export enum UsersType {
  LEGACY = 'LEGACY',
  CURRENT = 'CURRENT',
}

export enum DateFormats {
  DEFAULT_DATE_FORMAT = 'dd/MM/yyyy',
  DATE_TIME_FORMAT_SHORT = "dd/MM/yyyy 'às' HH:mm",
  DATE_TIME_FORMAT_WITH_SECONDS = "dd/MM/yyyy 'às' HH:mm:ss",
}

export const MOCK_SHARE_DATA = {
  title: 'AVA',
  text: 'ava',
  url: 'https://ava.ampli.com.br/',
};

export const OrderStatusTranslations = {
  PROCESSING: 'Processando',
  SUCCESS: 'Sucesso',
  NOT_FOUND: 'Não Encontrado',
  REQUESTED: 'Solicitado',
  FINISHED: 'Concluído',
  EXPIRED: 'Expirado',
  STUDENT_PENDING: 'Aguardando Aluno',
  CANCELLED: 'Cancelado',
  FINISHED_NOT_REOPEN: 'Finalizado não reabrir',
};

export const SIZES = {
  VW_4: convertPXToVW(4),
  VH_4: convertPXToVH(4),
  VW_8: convertPXToVW(8),
  VH_8: convertPXToVH(8),
  VW_10: convertPXToVW(10),
  VH_10: convertPXToVH(10),
  VH_11: convertPXToVH(11),
  VW_12: convertPXToVW(12),
  VH_12: convertPXToVH(12),
  VH_13: convertPXToVH(13),
  VW_13: convertPXToVW(13),
  VW_16: convertPXToVW(16),
  VH_16: convertPXToVH(16),
  VH_19: convertPXToVH(19),
  VW_19: convertPXToVW(19),
  VW_20: convertPXToVW(20),
  VH_20: convertPXToVH(20),
  VW_24: convertPXToVW(24),
  VH_24: convertPXToVH(24),
  VW_25: convertPXToVW(25),
  VH_25: convertPXToVH(25),
  VW_28: convertPXToVW(28),
  VH_28: convertPXToVH(28),
  VW_32: convertPXToVW(32),
  VH_32: convertPXToVH(32),
  VW_40: convertPXToVW(40),
  VH_40: convertPXToVH(40),
  VW_48: convertPXToVW(48),
  VH_48: convertPXToVH(48),
  VW_56: convertPXToVW(56),
  VH_56: convertPXToVH(56),
  VW_64: convertPXToVW(64),
  VH_64: convertPXToVH(64),
  VW_70: convertPXToVW(70),
  VH_70: convertPXToVH(70),
  VW_80: convertPXToVW(80),
  VH_80: convertPXToVH(80),
  VH_90: convertPXToVH(90),
  VW_90: convertPXToVW(90),
  VH_98: convertPXToVH(98),
  VW_103: convertPXToVW(103),
  VH_103: convertPXToVH(103),
  VW_104: convertPXToVW(104),
  VH_104: convertPXToVH(104),
  VH_117: convertPXToVH(117),
  VW_120: convertPXToVW(120),
  VH_120: convertPXToVH(120),
  VW_136: convertPXToVW(136),
  VH_136: convertPXToVH(136),
  VW_145: convertPXToVW(145),
  VH_145: convertPXToVH(145),
  VW_147: convertPXToVW(147),
  VH_190: convertPXToVH(190),
  VW_190: convertPXToVW(190),
  VW_216: convertPXToVW(216),
  VH_216: convertPXToVH(216),
  VW_296: convertPXToVW(296),
  VW_242: convertPXToVW(242),
  VH_242: convertPXToVH(242),
  VW_300: convertPXToVW(300),
  VH_300: convertPXToVH(300),
  VW_328: convertPXToVW(328),
  VH_328: convertPXToVH(328),
  VW_376: convertPXToVW(376),
  VH_376: convertPXToVH(376),
  VW_400: convertPXToVW(400),
  VH_400: convertPXToVH(400),
  VH_456: convertPXToVH(456),
  VW_456: convertPXToVW(456),
  VH_550: convertPXToVH(550),
  VW_550: convertPXToVW(550),
  VW_616: convertPXToVW(616),
  VH_616: convertPXToVH(616),
  VW_700: convertPXToVW(700),
  VH_700: convertPXToVH(700),

  REM_16: convertPXToREM(16),
  REM_24: convertPXToREM(24),
  REM_40: convertPXToREM(40),
  REM_229: convertPXToREM(229),
  REM_253: convertPXToREM(253),
  REM_255: convertPXToREM(255),
  REM_296: convertPXToREM(296),
  REM_328: convertPXToREM(328),
  REM_351: convertPXToREM(351),
  REM_452: convertPXToREM(452),
};

export const ChangeBillingDayStatusByUrl = {
  [ChangeBillingDayStatusEnum.CONTRACT]: 'contrato',
  [ChangeBillingDayStatusEnum.SELECT_DAY]: 'selecionar-dia',
  [ChangeBillingDayStatusEnum.SELECTED_DAY]: 'confirmar',
  [ChangeBillingDayStatusEnum.PROCESSING]: 'processando',
  [ChangeBillingDayStatusEnum.SUCCESS]: 'sucesso',
  [ChangeBillingDayStatusEnum.UNAVAILABLE]: 'indisponivel',
  [ChangeBillingDayStatusEnum.PROCESSING_CONTRACT]: 'processamento-de-contrato',
};

export const typesPayment = {
  PIX: 'pix',
  BILLET: 'boleto',
  CREDIT_CARD: 'cartao-credito',
  EXISTING_CREDIT_CARD: 'cartao-credito-existente',
};

export const CsatFeatures = {
  paymentCreditCard: 'PaymentCreditCard',
  paymentPix: 'PaymentPix',
  paymentBoleto: 'PaymentBoleto',
  taxInvoice: 'TaxInvoice',
  billingCycle: 'BillingCycle',
  financialServiceOrder: 'FinancialServiceOrder',
};
