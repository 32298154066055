import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import { Grid } from '@ampli/ui';
import Skeleton from '../../../../skeleton/skeleton';
import PageLoaderLayout from '../layout/page-loader-layout';
import { HomePage } from './notes-page-loader.styles';

const NotesHomePageLoader = (): React.ReactElement => {
  const isDesktop = useBreakpoint() === 'desktop';

  return (
    <PageLoaderLayout data-testid="notes-home-page-loader">
      <HomePage.Heading>
        <Skeleton height="48px" width="50%" />
        <Skeleton height="20px" width="80%" margin="8px 0 0" />
      </HomePage.Heading>
      <HomePage.ContentWrapper>
        <Skeleton height="32px" width="160px" margin="0 0 24px" />
        <Grid
          columns={isDesktop ? 2 : 1}
          columnGap={24}
          rowGap={24}
          data-testid="notes-home-page-loader-items"
        >
          <div>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton key={index} height="120px" borderRadius="8px" />
            ))}
          </div>
        </Grid>
      </HomePage.ContentWrapper>
    </PageLoaderLayout>
  );
};

export default NotesHomePageLoader;
