import React from 'react';
import { generatePath, Link, useParams, useHistory } from 'react-router-dom';
import { Breadcrumbs, Header, Typography } from '@sofia/ui';
import { routePath } from '../../routes';
import { useGetState } from '../../state';
import { LayoutContent } from '../layout';
import { useTheme } from '@emotion/react';
import { string } from 'prop-types';

import MandatoryInternshipOnboarding from './onboarding/mandatory-internship-onboarding';

const MandatoryInternship = ({ courseName }) => {
  const theme = useTheme();
  const history = useHistory();
  const { subjectEnrollmentId } = useParams();
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const getRoute = (route) => {
    history.push(
      generatePath(route, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
        subjectEnrollmentId: subjectEnrollmentId,
      })
    );
  };

  const breadcrumbsComponent = (
    <Breadcrumbs
      secondColor={theme.colors.breadCrumbs.secondary}
      firstColor={theme.colors.breadCrumbs.primary}
      fill={theme.colors.breadCrumbs.primary}
      stroke={theme.colors.breadCrumbs.primary}
      shadow={false}
      backClick={() => history.goBack()}
    >
      <Link
        to={generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })}
      >
        <span>Início</span>
      </Link>
      <Typography as="span" weight={700}>
        Estágio obrigatório
      </Typography>
    </Breadcrumbs>
  );

  return (
    <>
      <Header
        titleColor={theme.colors.text.secondary}
        descriptionColor={theme.colors.text.light}
        background={theme.colors.banner.primary}
        breadCrumbs={breadcrumbsComponent}
      />
      <LayoutContent>
        <MandatoryInternshipOnboarding
          courseName={courseName}
          onHasInternshipClick={() =>
            getRoute(routePath.mandatoryInternshipCompanyInputPage)
          }
          onDoesNotHasInternshipClick={() =>
            getRoute(routePath.mandatoryInternshipGuidePage)
          }
        />
      </LayoutContent>
    </>
  );
};

MandatoryInternship.propTypes = {
  courseName: string.isRequired,
};

export default MandatoryInternship;
