export enum InvoicePaymentMethodEnum {
  BOLETO = 'BOLETO',
  CREDIT_CARD = 'CREDIT_CARD',
  EXEMPTION = 'EXEMPTION',
  NEGOTIATION = 'NEGOTIATION',
  OTHER = 'OTHER',
  PIX = 'PIX',
  SCHOLARSHIP_100 = 'SCHOLARSHIP_100',
  WROTE_OFF = 'WROTE_OFF',
}
