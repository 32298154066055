import * as React from 'react';
import { string } from '@ampli/utils';

function GrayArrow({ stroke, id }) {
  return (
    <svg
      id={id}
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3.3335L12.6667 8.00016L8 12.6668"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

GrayArrow.propTypes = {
  stroke: string,
  id: string,
};

export default GrayArrow;
