import { keyframes } from '@emotion/css';
import styled from '@emotion/styled';
import { getStringOrNumberStyle } from '../../../utils';
const grow = width => keyframes`
  from {
    width: 0;
  }
  to {
    width: ${width};
  }
`;
export const Progress = styled.div(_ref => {
  let {
    height,
    border,
    borderRadius
  } = _ref;
  return `
  height: ${height ? getStringOrNumberStyle(height) : '10px'};
  ${border ? `border: ${border};` : ''};
  border-radius: ${borderRadius ? getStringOrNumberStyle(borderRadius) : '30px'};
`;
});
export const ProgressBackground = styled(Progress)(_ref2 => {
  let {
    background,
    width
  } = _ref2;
  return `
  width: ${width ? getStringOrNumberStyle(width) : '100%'};
  display: flex;
  align-items: center;
  background: ${background || '#EBF0F5'};
`;
});
export const ProgressBar = styled(Progress)(_ref3 => {
  let {
    width,
    background,
    noAnimation
  } = _ref3;
  return `
  width: ${width}%;
  background: ${background || '#008566'};
  transition: width 0.3s linear;

  ${!noAnimation && `animation: ${grow(width)} 0.3s ease-out;`}
`;
});