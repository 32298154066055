import styled from '@emotion/styled';

import { keyframes } from '@emotion/react';
import { convertPXToREM, mediaQuery, SvgCheck } from '@sofia/ui';

const checkAnimation = keyframes`
	from {
		opacity: 0;
		transform: rotateX(90deg) skewX(-30deg);
	}
	to {
		opacity: 1;
		transform: rotateX(0deg) skewX(0deg);
	}
`;

export const ColumnDiv = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  ${mediaQuery('mobile')} {
    flex-direction: column;
    margin-bottom: 24px;
  }
`;

export const FlexedStars = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -10px;

  ${mediaQuery('mobile')} {
    margin-bottom: -5px;
    flex-direction: column;
  }
`;

export const FlexedComment = styled.div<{ inputDisabled?: boolean }>`
  display: flex;
  margin-top: 4px;
  transition: margin-top ease-out 300ms;
  transition-delay: 1s;
  flex-direction: column;

  ${mediaQuery('desktop')} {
    flex-direction: row;
  }

  ${({ inputDisabled }) => inputDisabled && `margin-top: -21px;`}
`;

export const Title = styled.span<{ color: string }>`
  white-space: nowrap;
  font-weight: 700;
  color: ${({ color }) => color};
  font-size: ${convertPXToREM(16)};
`;

export const SubTitle = styled.span`
  white-space: nowrap;
  color: #8e9294;
  font-size: ${convertPXToREM(16)};
  margin-top: 8px;
  margin-left: 0px;

  ${mediaQuery('desktop')} {
    margin-left: 8px;
    margin-top: 0px;
  }
`;

export const Comment = styled('span', {
  shouldForwardProp: (propName) => propName !== 'inputDisabled',
})<{ inputDisabled?: boolean }>`
  color: ${({ color }) => color};
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
  opacity: 1;
  transition: opacity ease-out 300ms;
  transition-delay: 1s;
  ${({ inputDisabled }) => inputDisabled && 'opacity: 0 !important;'}
`;

export const BoldCheck = styled(SvgCheck)`
  transform-origin: bottom right 4px;
  transition: opacity 300ms ease-in;
  animation: ${checkAnimation} 300ms ease;
`;

export const CommentInput = styled.input<{
  borderColor: string;
  focusColor: string;
  disabledColor: string;
}>`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin-right: 8px;
  height: 56px;
  box-shadow: unset;
  transition: height linear 0.5s;
  border: 2px solid ${({ borderColor }) => borderColor};
  :focus {
    ${({ focusColor }) =>
      `border-color: ${focusColor}; outline-color: ${focusColor};`};
  }
  :disabled {
    background-color: transparent;
    opacity: 1;
    color: ${({ disabledColor }) => disabledColor};
  }
  ${mediaQuery('mobile')} {
    margin-bottom: 12px;
    height: 102px;
  }
`;

export const BoxComment = styled.div`
  margin-top: 40px;

  ${mediaQuery('desktop')} {
    border-radius: 16px;
    background: #ebf0f5;
    padding: 32px;
    margin-top: 24px;
  }
`;

export const ContainerButton = styled.div`
  height: 56px;
  button {
    padding: 19px 53px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${mediaQuery('desktop')} {
      width: auto;
    }
  }
`;
