import React from 'react';
import { func } from '@ampli/utils';
import {
  DialogBackdrop,
  Dialog,
  DialogSection,
  DialogFooter,
  Paragraph,
  Text,
} from '@ampli/ui';
import { ButtonPrimaryLong, SvgCheckCircle } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { FlexWrapper, DialogSuccessHeader } from './onboarding.styles';

const OnboardingDocumentsSuccessModal = ({ onSuccessClick, ...props }) => {
  const theme = useTheme();
  return (
    <>
      <DialogBackdrop {...props} />
      <Dialog {...props} aria-labelledby="successModalTitle">
        <DialogSuccessHeader id="successModalTitle">
          <SvgCheckCircle size={48} />
          Documentos enviados com sucesso!
        </DialogSuccessHeader>
        <DialogSection>
          <Paragraph>
            <Text>
              Iremos avaliar seus documentos, acompanhe a{' '}
              <strong>situação de verificação em Aluno</strong>, dentro do
              aplicativo. Se algum documento ficou pendente,{' '}
              <strong>regularize no mesmo menu em até 90 dias corridos</strong>{' '}
              e evite bloqueios.
            </Text>
          </Paragraph>
        </DialogSection>
        <DialogFooter>
          <FlexWrapper>
            <ButtonPrimaryLong
              color={theme.colors.button.text.primary}
              background={theme.colors.button.primary}
              onClick={onSuccessClick}
            >
              Entendi
            </ButtonPrimaryLong>
          </FlexWrapper>
        </DialogFooter>
      </Dialog>
    </>
  );
};

OnboardingDocumentsSuccessModal.propTypes = {
  hide: func.isRequired,
  onSuccessClick: func.isRequired,
};

export default OnboardingDocumentsSuccessModal;
