export default {
  simulators:
    '/:courseType/:courseVersionId/enrollment/:subjectEnrollmentId/simuladores',
  onlinePracticalActivities:
    '/:courseType/:courseVersionId/enrollment/:subjectEnrollmentId/atividades-praticas/online/:learningUnitEnrollmentId',
  presentialPracticalActivities:
    '/:courseType/:courseVersionId/enrollment/:subjectEnrollmentId/atividades-praticas/presencial/:learningUnitEnrollmentId',
  activity:
    '/:courseType/:courseVersionId/enrollment/:subjectEnrollmentId/atividades-praticas/presencial/:learningUnitEnrollmentId/:practicalActivityId',
};
