import React from 'react';
import { useTheme } from '@emotion/react';
import { Tag, Typography, convertPXToVH } from '@sofia/ui';
import { DetailsAccordionTitleInterface } from './helper';
import { Wrapper } from './styles';

const DetailsAccordionTitle: React.FC<DetailsAccordionTitleInterface> = ({
  title,
  description,
  tag,
}: DetailsAccordionTitleInterface) => {
  const theme = useTheme();

  return (
    <Wrapper.Content data-testid="details-accordion-title">
      {tag ? (
        <Tag
          data-testid="details-accordion-title-tag"
          type={tag.type}
          text={tag.label}
          theme={tag.theme}
        />
      ) : null}
      <Wrapper.Infos>
        <Typography
          size={16}
          color={theme.colors.text.black}
          weight={700}
          margin={`${convertPXToVH(19)} 0 0 0`}
        >
          {title}
        </Typography>
        <Typography
          size={16}
          color={theme.colors.text.black}
          weight={400}
          margin={`${convertPXToVH(19)} 0 0 0`}
        >
          {description}
        </Typography>
      </Wrapper.Infos>
    </Wrapper.Content>
  );
};

export default DetailsAccordionTitle;
