import styled from '@emotion/styled';

import {
  convertPXToREM,
  convertPXToVW,
  convertPXToVH,
  mediaQuery,
} from '@sofia/ui';

import { keyframes } from '@emotion/react';

const maxDesktopMediaQuery = mediaQuery('desktop-max');

const enteringAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const leavingAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`;

export const CopilotButton = styled.button<{
  isHoverOrFocus: boolean;
  isFixed: boolean;
  isMobileQuiz: boolean;
}>`
  height: 56px;

  border: none;
  outline: none;
  background: radial-gradient(73.25% 100% at 100% 0%, #7389ff 0%, #004ecc 100%);
  box-shadow: 0px 4px 16px 0px ${({ theme }) => theme.colors.text.black}1f;
  color: ${({ theme }) => theme.colors.icon.background};

  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: ${convertPXToREM(14)};
  letter-spacing: -5%;
  line-height: 140%;

  overflow: hidden;
  transition: padding-right cubic-bezier(0.45, 1.45, 0.8, 1) 0.3s;

  ${({ isFixed }) =>
    isFixed
      ? `
        border-radius: 16px;
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 999;
      `
      : `
        border-radius: 8px;
        height: 40px;

        ${maxDesktopMediaQuery} {
          width: 40px;
          padding: 0;
        }
      `}

  ${({ isHoverOrFocus }) => (isHoverOrFocus ? `padding-right: 16px;` : '')}

  ${maxDesktopMediaQuery} {
    bottom: 60px;
  }

  ${({ isMobileQuiz }) => (isMobileQuiz ? `margin-bottom: 100px` : '')}
`;

export const CloseButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const CopilotContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: ${({ theme }) => theme.colors.card.background.white};

  width: ${convertPXToVW(360)};
  height: ${convertPXToVH(540)};
  box-shadow: 0px 4px 12px 0px rgba(33, 37, 41, 0.18);
  border-radius: 8px;
  padding: 16px;

  z-index: 999;

  ${maxDesktopMediaQuery} {
    width: 100%;
    height: 100vh;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  animation: ${enteringAnimation} 150ms ease-in-out;

  &.leaving {
    animation: ${leavingAnimation} 150ms ease-in-out;
  }

  #webchat {
    width: 100%;
    height: 90%;
  }
`;

export const AvatarEdu = styled.img<{
  isFixed: boolean;
}>`
  margin-bottom: -1px;

  ${({ isFixed }) =>
    isFixed
      ? `
        width: 56px;
        height: 56px;
      `
      : `
        width: 40px;
        height: 40px;
      `}
`;

export const LogoEdu = styled.img`
  width: 104px;
`;

export const ChatHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
`;

export const WebChat = styled.div<{ isUploadButtonAllowed: boolean }>`
  ${({ isUploadButtonAllowed }) =>
    isUploadButtonAllowed
      ? `
        .webchat__send-box-text-box {
          padding-left: 32px !important;
        }
      `
      : ``}
`;

export const UploadButton = styled.label`
  cursor: pointer;
  position: absolute;
  left: 16px;
  width: min-content;
  bottom: 16px;

  input {
    display: none;
    visibility: hidden;
  }
`;
