import styled from '@emotion/styled';

import SvgTourIllustrationAirplane from './tour-illustration-airplane';
import SvgTourIllustrationMobile from './tour-illustration-mobile';
import SvgTourIllustrationBook from './tour-illustration-book';
import SvgTourIllustrationPc from './tour-illustration-pc';

export const Airplane = styled(SvgTourIllustrationAirplane)`
  width: 227px;
  height: 170px;
  place-self: center;
`;

export const Book = styled(SvgTourIllustrationBook)`
  width: 227px;
  height: 170px;
  place-self: center;
`;

export const Notebook = styled(SvgTourIllustrationPc)`
  width: 227px;
  height: 170px;
  place-self: center;
`;

export const Mobile = styled(SvgTourIllustrationMobile)`
  width: 227px;
  height: 170px;
  place-self: center;
`;
