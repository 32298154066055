import * as React from 'react';
import { getColor } from '@ampli/ui';

function SvgPinMapImage(props) {
  return (
    <svg width={41} height={40} fill="none" {...props}>
      <path
        d="M34.844 36.409h-7.88L13.54 37.713.015 36.409V4.468l13.47.977 13.761-.977 13.462 1.847-.346 24.528-5.518 5.566z"
        fill="url(#pin-map-image_svg__paint0_linear)"
      />
      <path
        d="M34.587 35.965h-7.708L13.75 37.233.52 35.965V4.887l13.175.951 13.46-.95 13.167 1.796-.338 23.866-5.397 5.415z"
        fill="#fff"
      />
      <path
        d="M34.63 35.119h-8.118L14.13 36.315 1.649 35.12V5.805l12.428.898 12.696-.898L39.193 7.5v23.032L34.63 35.12z"
        fill={getColor('brand-lighter')}
      />
      <path
        d="M36.166 36.106l-.138-.076-14.254-7.89 7.24-24.046-2.463-.976-3.51 11.653L1.134 3.118 0 5.812l22.184 11.805-6.446 21.406 2.465.977 2.714-9.013L35.033 38.8l1.133-2.694z"
        fill="#fff"
      />
      <path
        d="M34.056 35.917l.095-6.051 5.834.235-5.93 5.816z"
        fill="url(#pin-map-image_svg__paint1_linear)"
      />
      <path
        d="M1.65 18.473l17.892 7.919 1.19 2.463-1.184 1.233L1.65 21.604v-3.13zM34.587 35.917l.087-5.584 5.31.217-5.397 5.367zM25.96 31.221l7.466-24.508 2.97.405-8.364 25.385-2.072-1.282z"
        fill="#fff"
      />
      <path
        opacity={0.1}
        d="M14.077 36.315l12.428-1.196V5.805l-12.428.898"
        fill="#000"
        fillOpacity={0.3}
      />
      <path
        d="M29.298 8.319c0 4.593-8.238 17.6-8.238 17.6s-8.239-13.007-8.239-17.6c0-1.092.213-2.174.627-3.183a8.32 8.32 0 011.785-2.7A8.233 8.233 0 0117.906.634a8.171 8.171 0 016.307 0c1 .418 1.908 1.031 2.673 1.804a8.32 8.32 0 011.786 2.699 8.383 8.383 0 01.626 3.183z"
        fill={getColor('brand-light')}
      />
      <path
        d="M21.06 12.589a4.906 4.906 0 100-9.812 4.906 4.906 0 000 9.812zM32.654 18.473l6.539 1.677v3.13l-8.005-2.855 1.466-1.952zM7.734 24.487L6.082 35.905l1.874.394 2.47-10.535-.003-1.494-2.69.217zM7.667 9.582L5.814 20.316l1.435.774 3.42-11.511-3.002.003zM11.212 7.853a.395.395 0 100-.789.395.395 0 000 .789zM6.477 28.263a.395.395 0 100-.789.395.395 0 000 .79zM6.082 31.172a.395.395 0 100-.789.395.395 0 000 .79zM10.274 30.482a.394.394 0 100-.789.394.394 0 000 .789zM3.517 18.65a.395.395 0 100-.79.395.395 0 000 .79zM34.591 18.256a.395.395 0 100-.79.395.395 0 000 .79zM31.188 31.96a.394.394 0 100-.788.394.394 0 000 .789z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="pin-map-image_svg__paint0_linear"
          x1={20.361}
          y1={37.713}
          x2={20.361}
          y2={4.468}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.535} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="pin-map-image_svg__paint1_linear"
          x1={37.02}
          y1={35.917}
          x2={37.02}
          y2={29.866}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.12} />
          <stop offset={0.551} stopOpacity={0.09} />
          <stop offset={1} stopOpacity={0.02} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgPinMapImage;
