import styled from '@emotion/styled';
import { Separator, convertPXToREM } from '@sofia/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 24px;
`;

export const SubjectContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 4px;
  margin: 0 0 16px;

  align-items: center;

  > p {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: ${convertPXToREM(292)};
    width: 100%;
    white-space: nowrap;
  }
`;

export const UnitContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 4px;
  margin: 16px 0;

  align-items: center;

  & p:first-of-type {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: ${convertPXToREM(192)};
    width: 100%;
    white-space: nowrap;
  }
`;

export const Unit = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #000;
    border-radius: 50%;
    margin: 0 8px;
  }
`;

export const StyledSeparator = styled(Separator)`
  background-color: ${({ theme }) => theme.colors.card.background.inactive};
`;
