/* eslint-disable no-useless-escape */
import {
  NotesHomePageLoader,
  NotesLearningUnitPageLoader,
  NotesSubjectPageLoader,
  SectionPageLoader,
  PracticalActivitiesPageLoader,
} from './components/page-loader';

export const customFullLoaderByPath = [
  {
    pathRegex: `minhas-anotacoes\/?$`,
    component: NotesHomePageLoader,
  },
  {
    pathRegex: `minhas-anotacoes(?!\/.*\/unidade)`,
    component: NotesSubjectPageLoader,
  },
  {
    pathRegex: `minhas-anotacoes\/.*\/unidade`,
    component: NotesLearningUnitPageLoader,
  },
  { pathRegex: `\/aula\/`, component: SectionPageLoader },
  {
    pathRegex: `\/simuladores`,
    component: PracticalActivitiesPageLoader,
  },
  {
    pathRegex: `\/atividades-praticas\/`,
    component: PracticalActivitiesPageLoader,
  },
];
