import React from 'react';
import { useModal } from '@sofia/ui';
import { ModalInterface, PopUp, useBreakpoint } from '../../../modules';
import { Wrapper } from './pop-up-second-call-exam-release.styles';
import { IPopUpSecondCallExamReleaseProps } from './pop-up-second-call-exam-release.types';
import ButtonFooter from './button-footer';
import CardDetails from '../card-details/card-details.presentation';
import CardAlert from '../card-alert/card-alert.presentation';

const PopUpSecondCallExamRelease = React.forwardRef(
  (
    {
      handleConfirm,
      isLoading,
      period,
      value,
      info,
    }: IPopUpSecondCallExamReleaseProps,
    ref: React.Ref<ModalInterface>
  ) => {
    const modal = useModal();
    const { isDesktop } = useBreakpoint();

    const handleShowModal = () => modal.show();
    const handleHideModal = () => modal.hide();

    React.useImperativeHandle(ref, () => ({
      onOpen() {
        handleShowModal();
      },
      onClose() {
        handleHideModal();
      },
    }));

    return (
      <PopUp
        ariaLabel="Liberar prova de segunda chamada"
        footer={
          <ButtonFooter
            handleConfirm={handleConfirm}
            handleHideModal={handleHideModal}
            isLoading={isLoading}
          />
        }
        headerText="Liberar prova de segunda chamada"
        isDesktop={isDesktop}
        popUpState={modal}
      >
        <Wrapper.Content>
          <CardDetails period={period} value={value} />
          <CardAlert message={info} />
        </Wrapper.Content>
      </PopUp>
    );
  }
);

PopUpSecondCallExamRelease.displayName = 'PopUpSecondCallExamRelease';

export default PopUpSecondCallExamRelease;
