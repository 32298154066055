import styled from '@emotion/styled';

export const Field = styled.div`
  width: 100%;
  > span {
    display: flex;
  }
  span:first-of-type {
    margin-bottom: 4px;
  }
`;
