import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW } from '@sofia/ui';

export const Wrapper = styled.div`
  display: flex;
  margin: ${convertPXToVH(27)} 0px ${convertPXToVH(22)} 0px;
`;

export const NameWrapper = styled.div`
  margin-left: ${convertPXToVW(7)};
`;

export const WrapperImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperResume = styled.div`
  padding: 0 ${convertPXToVW(24)};
`;
