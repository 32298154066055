import { useMutation, useQuery } from '@ampli/services';
import {
  GENERATE_SCHOLARSHIP_DOCUMENT_UPLOAD_URL,
  GET_SCHOLARSHIP_DOCUMENT_REQUEST,
  GET_SCHOLARSHIP_REQUEST,
  GET_SCHOLARSHIP_REQUEST_DETAILS,
  UPLOAD_SCHOLARSHIP_DOCUMENT,
} from './queries';
import {
  GetScholarshipDocumentRequestHook,
  GetScholarshipRequestDetailsHook,
  GetScholarshipRequestHook,
  MutationGenerateScholarshipDocumentUploadUrlHook,
  MutationUploadScholarshipDocumentHook,
} from './types';
import {
  generateScholarshipDocumentUploadUrl,
  getScholarshipDocumentRequest,
  getScholarshipRequest,
  getScholarshipRequestDetails,
} from '../../../shared/mocks';

export const useMutationGenerateScholarshipDocumentUploadUrl: MutationGenerateScholarshipDocumentUploadUrlHook =
  ({ enableMock = false, options = {} }) => {
    const [mutate, { loading, error, data }] = useMutation(
      GENERATE_SCHOLARSHIP_DOCUMENT_UPLOAD_URL,
      { ...options }
    );

    const scholarshipDocumentUploadUrl = loading || !data ? {} : data.data;

    return [
      mutate,
      {
        loading,
        error,
        data: enableMock
          ? generateScholarshipDocumentUploadUrl.data
          : scholarshipDocumentUploadUrl,
      },
    ];
  };

export const useGetScholarshipRequest: GetScholarshipRequestHook = ({
  courseEnrollmentId,
  enableMock = false,
  options = {},
}) => {
  const { data, loading, error, refetch } = useQuery(GET_SCHOLARSHIP_REQUEST, {
    ...options,
    variables: {
      courseEnrollmentId,
    },
    fetchPolicy: 'no-cache',
  });

  const scholarship = loading || !data ? null : data.data;

  return {
    data: enableMock ? getScholarshipRequest.data : scholarship,
    loading,
    error,
    refetch,
  };
};

export const useGetScholarshipRequestDetails: GetScholarshipRequestDetailsHook =
  ({ courseEnrollmentId, id, enableMock = false, options = {} }) => {
    const { data, loading, error, refetch } = useQuery(
      GET_SCHOLARSHIP_REQUEST_DETAILS,
      {
        ...options,
        variables: {
          courseEnrollmentId,
          id,
        },
        fetchPolicy: 'no-cache',
      }
    );

    const scholarshipDetails = loading || !data ? null : data.data;

    return {
      data: enableMock ? getScholarshipRequestDetails.data : scholarshipDetails,
      loading,
      error,
      refetch,
    };
  };

export const useGetScholarshipDocumentRequest: GetScholarshipDocumentRequestHook =
  ({ courseEnrollmentId, id, enableMock = false, options = {} }) => {
    const { data, loading, error, refetch } = useQuery(
      GET_SCHOLARSHIP_DOCUMENT_REQUEST,
      {
        ...options,
        variables: {
          courseEnrollmentId,
          id,
        },
        fetchPolicy: 'no-cache',
      }
    );

    const scholarshipDocument = loading || !data ? null : data.data;

    return {
      data: enableMock
        ? getScholarshipDocumentRequest.data
        : scholarshipDocument,
      loading,
      error,
      refetch,
    };
  };

export const useMutationUploadScholarshipDocument: MutationUploadScholarshipDocumentHook =
  ({ options = {} }) => {
    const [mutate, metaData] = useMutation(UPLOAD_SCHOLARSHIP_DOCUMENT, {
      ...options,
    });
    return [mutate, metaData];
  };
