import React from 'react';
import LoginFooter from './login-footer';
import { string, bool, node } from 'prop-types';
import { ForgotWrapper } from '../../pages/login/login-styles';

const LoginPasswordFooter = ({
  buttonWidth,
  disabled,
  loading,
  ButtonFooterRedirect,
  primaryActionLabel,
  forgotPass,
}) => (
  <>
    <LoginFooter
      ButtonFooterRedirect={ButtonFooterRedirect}
      buttonWidth={buttonWidth}
      disabled={disabled}
      loading={loading}
      primaryActionLabel={primaryActionLabel}
    />
    <ForgotWrapper>{forgotPass}</ForgotWrapper>
  </>
);

export default LoginPasswordFooter;

LoginPasswordFooter.propTypes = {
  buttonWidth: string,
  disabled: bool,
  loading: bool,
  primaryActionLabel: string,
  forgotPass: node,
  ButtonFooterRedirect: string,
};
