import { pathUrlField } from './commons';
const type = {
  read(cached, _ref) {
    let {
      readField
    } = _ref;
    if (cached) return cached;
    let firstAssetType = 'text';
    let hasVideo = false;
    const assets = readField('assets');
    if ((assets === null || assets === void 0 ? void 0 : assets.length) > 0) {
      var _assets$filter;
      const asset = assets[0];
      firstAssetType = readField('type', asset).replace('Asset', '').toLowerCase();
      hasVideo = ((_assets$filter = assets.filter(asset => readField('type', asset).toLowerCase().includes('video'))) === null || _assets$filter === void 0 ? void 0 : _assets$filter.length) > 0;
    }
    return firstAssetType === 'image' && !hasVideo ? 'text' : hasVideo ? 'video' : firstAssetType;
  }
};
export default {
  keyFields: ['id'],
  fields: {
    type,
    pathUrl: pathUrlField
  }
};