import styled from '@emotion/styled';
import { mediaQuery } from '../../utils';
export const Container = styled.div`
  ${mediaQuery('mobile')}, ${mediaQuery('tablet')} {
    display: flex;
    flex-direction: column;
  }
`;
export const Wrapper = styled.div(_ref => {
  let {
    closedSize,
    openedSize,
    absolute
  } = _ref;
  return `
  transition: padding-right 0.1s ease-in-out;
  ${mediaQuery('desktop')} {
    &.closed {
      padding-right: ${closedSize}px;
    }
    padding-right: ${!absolute ? openedSize : 0}px;
  }
`;
});
export const Drawer = styled.div(_ref2 => {
  let {
    fromRight,
    closedSize,
    openedSize,
    withBackdrop
  } = _ref2;
  return `
    ${fromRight ? 'right: 0' : 'left: 0'};
    ${fromRight ? 'border-left' : 'border-right'}: 1px solid #dee2e6;
  position: fixed;
  bottom: 0;
  top: 0;
  z-index: 1001;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  transition: max-width 0.1s ease-in-out;

  &.closed {
    max-width: 0px;
  }
  ${mediaQuery('desktop')} {
    z-index: ${withBackdrop ? 1001 : 10};
    max-width: ${openedSize}px;
    &.closed {
      display: flex;
      max-width: ${closedSize}px;
    }
  }


`;
});
export const List = styled.div`
  ${_ref3 => {
  let {
    scrollType
  } = _ref3;
  return scrollType ? `overflow: ${scrollType};` : '';
}}
  &:hover::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 2px;
  }
`;