import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import PageLoaderLayout from '../layout/page-loader-layout';
import Skeleton from '../../../../skeleton/skeleton';
import { NotesLearningUnitSectionSkeleton } from '../../../../../../notes/shared';
import { LearningUnitPage } from './notes-page-loader.styles';

const NotesLearningUnitPageLoader = (): React.ReactElement => {
  const isDesktop = useBreakpoint() === 'desktop';

  return (
    <PageLoaderLayout data-testid="notes-learning-unit-page-loader">
      <LearningUnitPage.Heading>
        <Skeleton height="58px" width="50%" />
        {!isDesktop ? (
          <Skeleton
            height="34px"
            data-testid="notes-learning-unit-page-loader-mobile-subtitle-skeleton"
          />
        ) : null}
        <LearningUnitPage.HeadingButtons>
          {isDesktop ? (
            <Skeleton
              height="68px"
              width="220px"
              data-testid="notes-learning-unit-page-loader-button-access-skeleton"
            />
          ) : null}
          <Skeleton
            height={isDesktop ? '68px' : '56px'}
            width={isDesktop ? '160px' : '100%'}
            margin={isDesktop ? '0 0 0 16px' : '24px 0 0'}
            data-testid="notes-learning-unit-page-loader-button-download-skeleton"
          />
        </LearningUnitPage.HeadingButtons>
      </LearningUnitPage.Heading>

      {isDesktop ? (
        <Skeleton
          height="34px"
          data-testid="notes-learning-unit-page-loader-subtitle-skeleton"
        />
      ) : null}

      <LearningUnitPage.ContentWrapper>
        <NotesLearningUnitSectionSkeleton isDesktop={isDesktop} />
        <NotesLearningUnitSectionSkeleton isDesktop={isDesktop} />
      </LearningUnitPage.ContentWrapper>
    </PageLoaderLayout>
  );
};

export default NotesLearningUnitPageLoader;
