import React from 'react';
import { Breadcrumbs } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { node } from 'prop-types';
import SubjectImageBanner from './image/subject-image-banner';
import * as Styled from './subject-banner-styles';

const SubjectBanner = ({ children }) => {
  const theme = useTheme();

  return (
    <SubjectImageBanner>
      <Styled.BreadcrumbsContainer>
        <Breadcrumbs
          secondColor={theme?.colors.breadCrumbs.secondary}
          firstColor={theme?.colors.breadCrumbs.primary}
          fill={theme?.colors.breadCrumbs.primary}
          backClick={() => {
            history.go(-1);
          }}
          shadow={false}
        >
          {children}
        </Breadcrumbs>
      </Styled.BreadcrumbsContainer>
    </SubjectImageBanner>
  );
};

SubjectBanner.propTypes = {
  children: node,
};

export default SubjectBanner;
