const firstName = {
  read(cached, _ref) {
    let {
      readField
    } = _ref;
    if (cached) return cached;
    const fullName = readField('fullName');
    return (fullName === null || fullName === void 0 ? void 0 : fullName.split(' ')[0]) || '';
  }
};
const lastName = {
  read(cached, _ref2) {
    let {
      readField
    } = _ref2;
    if (cached) return cached;
    const fullName = readField('fullName');
    return (fullName === null || fullName === void 0 ? void 0 : fullName.split(' ').at(-1)) || '';
  }
};
const phoneNumberString = {
  read(cached, _ref3) {
    let {
      readField
    } = _ref3;
    if (cached) return cached;
    const phoneNumber = readField('phoneNumber') || {};
    return phoneNumber.areaCode + phoneNumber.number;
  }
};
export default {
  fields: {
    firstName,
    lastName,
    phoneNumberString
  }
};