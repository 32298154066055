import styled from '@emotion/styled';

import { Card as SofiaCard, convertPXToREM, Typography } from '@sofia/ui';

interface CardProps {
  read?: boolean;
}

export const Card = styled(SofiaCard)<CardProps>`
  position: relative;
  overflow: hidden;

  ${({ read, theme }) =>
    !read &&
    `
    &:before {
      position: absolute;
      content: "";
      top: -2px;
      left: 0;
      height: 103%;
      width: 4px;
      background: ${theme.colors.card.background.primary};
      border-radius: 10px 0px 0px 10px;
    }
  `}
`;

export const CardWrapper = styled.div`
  padding: 16px;
  display: flex;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CallToAction = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.button.primary};
  font-size: ${convertPXToREM(16)};
  font-weight: 600;
`;

interface TruncateTextProps {
  maxLines?: number;
}

export const TruncateText = styled(Typography)<TruncateTextProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ maxLines }) => maxLines || '3'};
  -webkit-box-orient: vertical;
`;
