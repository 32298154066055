import React from 'react';
import { useTheme } from '@emotion/react';
import { Accordion } from '@sofia/ui';
import Body from './details-accordion-body';
import Title from './details-accordion-title';
import { DetailsAccordionInterface } from './helper';
import { Wrapper } from './styles';

const DetailsAccordion: React.FC<DetailsAccordionInterface> = ({
  tag,
  title,
  description,
  items,
  onClick,
  textButton = 'Mais detalhes',
}: DetailsAccordionInterface) => {
  const theme = useTheme();

  return (
    <Wrapper.Content data-testid="details-accordion">
      <Accordion
        title={<Title title={title} description={description} tag={tag} />}
        iconColor={theme.colors.text.black}
        markerColor={'transparent'}
      >
        <Body items={items} onClick={onClick} textButton={textButton} />
      </Accordion>
    </Wrapper.Content>
  );
};

export default DetailsAccordion;
