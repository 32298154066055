import React from 'react';

import BeforeStartDesktop from './before-start.desktop';
import BeforeStartMobile from './before-start.mobile';

import { useBreakpoint } from '../../../../../..';

const BeforeStartModal: React.FC = () =>
  useBreakpoint().isDesktop ? <BeforeStartDesktop /> : <BeforeStartMobile />;

export default BeforeStartModal;
