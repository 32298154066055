import styled from '@emotion/styled';
import { convertPXToREM, convertPXToVH } from '../../utils';
export const FlexWrapper = styled.div`
  display: flex;
  gap: ${convertPXToREM(16)};
  margin: ${_ref => {
  let {
    margin
  } = _ref;
  return margin || `${convertPXToVH(56)} 0 0 0`;
}};
  padding: ${_ref2 => {
  let {
    padding
  } = _ref2;
  return padding;
}};

  @media (max-width: 930px) {
    margin-top: 32px;
  } ;
`;
export const Line = styled.div`
  width: 100%;
  height: ${convertPXToVH(5)};
  background: ${_ref3 => {
  let {
    active,
    activeColor,
    inactiveColor
  } = _ref3;
  return active ? activeColor : inactiveColor;
}};
  border-radius: 8px;
`;