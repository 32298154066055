import { gql } from 'graphql.macro';

export const GET_ENROLLMENT_CANCELLATION = gql`
  query EnrollmentCancellation(
    $courseEnrollmentId: ID!
    $action: EnrollmentCancellationAction!
  ) {
    data: enrollmentCancellation(
      courseEnrollmentId: $courseEnrollmentId
      action: $action
    ) {
      warnings
      recipentType
      recipent
    }
  }
`;
