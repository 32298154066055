import React from 'react';

import { usePopoverState } from '@ampli/ui';
import { NavBar } from '@sofia/ui';
import { number, string, arrayOf, bool, func } from 'prop-types';

import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { includes } from 'lodash';

import { useCourseEnrollmentsContext } from '../../modules/shared/contexts/course-enrollments-context';
import { FreeTrialPopover } from '../free-trial-popover';
import { LayoutContent } from '../layout';
import { useGetState } from '../../state';
import { routePath } from '../../routes';

import {
  unavailableFreeTrialStatus,
  freeTrialEnabledRole,
  locationPathname,
} from '../../constants';

import { useRolesContext } from '../../modules/shared/contexts/communication-roles-context';
import { useMeContext } from '../../modules/shared/contexts/me-context';
import { Creatives, useBreakpoint } from '../../modules';

import Avatar from './avatar.presentation';

import * as Styled from './navigation.styles';
import NavigationItems from './navigation-items.presentation';

const Navigation = ({
  freeTrialDaysLeft,
  freeTrialSituation,
  roles,
  Logo,
  shouldRedirect,
  redirectionBlockAction,
  setLastNavigationRoute,
}) => {
  const [isSubjectPage, setIsSubjectPage] = React.useState(false);
  const history = useHistory();
  const { states: statesMe } = useMeContext();
  const { me } = statesMe.getMe;

  const theme = useTheme();
  const { isDesktop, isTablet } = useBreakpoint();

  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const { communicationCapabilities } = useRolesContext();

  const { courseType, courseVersionId, subjectEnrollmentId } = useParams();

  const { notification } = Creatives.useNotifications();

  const { states } = useCourseEnrollmentsContext();

  const { data: selectedEnrollment } = states.courseEnrollments;

  const homePath = routePath.courseSelection;
  const profilePath = routePath.profile;
  const actualPath = locationPathname();

  const externalId = selectedEnrollment?.externalId;

  if (externalId && window.DD_RUM) {
    window.DD_RUM.setUserProperty('ra', externalId);
  }

  let showPayment = true;

  const isPathHome = homePath === actualPath;
  const isPathProfile = profilePath === actualPath;

  const hasRequiredParams =
    selectedCourseVersionId && selectedCourseEnrollmentType;
  const routePathHome = hasRequiredParams
    ? generatePath(routePath.home, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
      })
    : null;

  const isPaymentEnabled = roles?.includes('PAYMENT_ENABLED');

  const goToHome = () => {
    const pathHome = generatePath(routePath.home, {
      courseType: selectedCourseEnrollmentType,
      courseVersionId: selectedCourseVersionId,
    });

    history.push(pathHome);
  };

  const handleBlockAction = () => {
    if (setLastNavigationRoute) {
      setLastNavigationRoute(() => goToHome);
      redirectionBlockAction();
    }
  };

  const handleLogo = () => {
    if (
      actualPath !== homePath &&
      selectedCourseVersionId &&
      selectedCourseEnrollmentType
    ) {
      if (shouldRedirect) {
        goToHome();
      }

      handleBlockAction();
    }
  };

  const isEligibleForFreeTrial =
    freeTrialSituation &&
    !includes(unavailableFreeTrialStatus, freeTrialSituation);
  const isRoleEligible = includes(roles, freeTrialEnabledRole);

  const isFreeTrialEnabled = isEligibleForFreeTrial && isRoleEligible;

  const showNotificationsCenter =
    communicationCapabilities?.course.NOTIFICATION_CENTER_ENABLED &&
    !isPathHome &&
    Creatives.Notifications;

  const isSessionStorageAvailable = typeof sessionStorage !== 'undefined';

  const closedFreeTrialAdvise = isSessionStorageAvailable
    ? sessionStorage.getItem('closedFreeTrialAdvise')
    : null;

  const freeTrialPopoverState = usePopoverState({
    visible: false,
    placement: isDesktop ? 'bottom-start' : 'top-end',
  });

  if (isPathHome || (isPathProfile && isDesktop)) {
    showPayment = false;
  }

  const hasChatEnabled = selectedEnrollment?.roles?.some(
    (role) => role === 'CHAT_ENABLED'
  );

  const shouldRenderSlidingCreative = Creatives.Sliding && !isPathHome;

  let layoutPadding;
  if (isDesktop) {
    layoutPadding = ['x-small', 'huge'];
  } else if (isTablet) {
    layoutPadding = ['small'];
  } else {
    layoutPadding = ['none'];
  }

  React.useEffect(() => {
    if (
      `/${courseType}/${courseVersionId}/enrollment/${subjectEnrollmentId}` ===
      locationPathname()
    )
      setIsSubjectPage(true);
  }, [courseType, courseVersionId, subjectEnrollmentId]);

  React.useLayoutEffect(() => {
    if (closedFreeTrialAdvise === null && isSubjectPage)
      freeTrialPopoverState.show();
  }, [closedFreeTrialAdvise, freeTrialPopoverState, isSubjectPage]);

  return (
    <>
      <NavBar
        color={theme.colors.navBar.color}
        active={theme.colors.navBar.active}
      >
        <LayoutContent padding={layoutPadding}>
          <Styled.WrapperNavegation>
            <Styled.NavigationLogoItemsWrapper>
              {isDesktop ? (
                <Styled.WrapperLogo onClick={handleLogo}>
                  {Logo}
                </Styled.WrapperLogo>
              ) : null}
              <NavigationItems
                showPayment={showPayment}
                actualPath={actualPath}
                courseType={selectedCourseEnrollmentType}
                courseVersionId={selectedCourseVersionId}
                isPaymentEnabled={isPaymentEnabled}
                routePathHome={routePathHome}
                firstName={me?.firstName?.substring(0, 1)}
                goToHome={goToHome}
                hasChatEnabled={hasChatEnabled}
                isPathHome={isPathHome}
                freeTrialPopoverState={freeTrialPopoverState}
                shouldShowNotificationBell={
                  showNotificationsCenter && notification?.bell
                }
                shouldRedirect={shouldRedirect}
                redirectionBlockAction={redirectionBlockAction}
                setLastNavigationRoute={setLastNavigationRoute}
              />
            </Styled.NavigationLogoItemsWrapper>

            {isDesktop && !isPathHome ? (
              <Styled.WrapperAvatar>
                {showNotificationsCenter ? <Creatives.Notifications /> : null}
                <Avatar
                  ra={selectedEnrollment.externalId}
                  roles={roles}
                  shouldRedirect={shouldRedirect}
                  redirectionBlockAction={redirectionBlockAction}
                  setLastNavigationRoute={setLastNavigationRoute}
                />
              </Styled.WrapperAvatar>
            ) : null}
          </Styled.WrapperNavegation>
        </LayoutContent>
      </NavBar>

      {shouldRenderSlidingCreative ? <Creatives.Sliding /> : null}

      {isFreeTrialEnabled ? (
        <FreeTrialPopover
          {...freeTrialPopoverState}
          freeTrialDaysLeft={freeTrialDaysLeft}
          freeTrialSituation={freeTrialSituation}
          closedFreeTrialAdvise={closedFreeTrialAdvise}
          selectedCourseEnrollmentType={selectedCourseEnrollmentType}
          selectedCourseVersionId={selectedCourseVersionId}
        />
      ) : null}
    </>
  );
};

Navigation.propTypes = {
  freeTrialDaysLeft: number,
  freeTrialSituation: string,
  roles: arrayOf(string),
  Logo: Node,
  shouldRedirect: bool,
  redirectionBlockAction: func,
  setLastNavigationRoute: func,
};

export default Navigation;
