import styled from '@emotion/styled';
import { convertPXToREM } from '../../utils';
import { Separator as _Separator } from '../Separator';
export const Flex = styled.div`
  display: flex;
`;
export const Header = styled(Flex)`
  margin-bottom: 24px;
  align-items: center;
`;
export const Help = styled.button`
  display: flex;
  border: none;
  background: transparent;
  padding: 8px;
  margin-left: auto;
  cursor: pointer;
  font-size: ${convertPXToREM(16)};
  font-weight: 700;
  color: ${_ref => {
  let {
    color
  } = _ref;
  return color || '#004ECC';
}};
  :hover {
    background: #dbe3ec;
    border-radius: 8px;
  }
`;
export const FlexCenterColumn = styled(Flex)`
  flex-direction: column;
  align-items: center;
`;
export const Wording = styled(FlexCenterColumn)`
  font-size: ${convertPXToREM(20)};
  line-height: 140%;
  color: ${_ref2 => {
  let {
    color
  } = _ref2;
  return color;
}};

  @media (max-width: 769px) {
    line-height: 150%;
    font-size: ${convertPXToREM(16)};
  }

  svg,
  img {
    margin-top: 40px;
    @media (max-width: 769px) {
      margin-top: 24px;
    }
  }
`;
export const Separator = styled(_Separator)`
  margin: 48px 0;
  @media (max-width: 769px) {
    margin: 32px 0;
  }
  background-color: #dbe3ec;
`;
export const Options = styled.ul`
  list-style: none;
  padding: 0;
  > :not(:last-of-type) {
    margin-bottom: 16px;
  }
`;
export const Option = styled(FlexCenterColumn)`
  font-size: ${convertPXToREM(20)};
  line-height: 140%;
  color: ${_ref3 => {
  let {
    color
  } = _ref3;
  return color;
}};

  @media (max-width: 769px) {
    line-height: 150%;
    font-size: ${convertPXToREM(16)};
  }

  &[aria-selected='true'] {
    color: ${_ref4 => {
  let {
    selectedColor
  } = _ref4;
  return selectedColor || 'white';
}};
  }

  svg,
  img {
    margin-top: 16px;
  }
`;