import React from 'react';

import { TalkTutorFrameProps } from '../../talk-tutor-frame';
import { SectionRightFrame } from '../../../../..';
import { usePtkFrame } from './contexts';
import { FrameFooter } from './components';

import * as Sections from './sections';

type PtkFrameProps = TalkTutorFrameProps & {
  onCloseClick: () => void;
  isOpen: boolean;
};

export const PtkFrame: React.FC<PtkFrameProps> = ({
  hideAttachmentField,
  onCloseClick,
  infos,
  isOpen,
}: PtkFrameProps) => {
  const { page, dispatch, onClose } = usePtkFrame();

  const Content = {
    HOME: Sections.HomeSection,
    CONVERSATION_CHAT: Sections.ConversationChatSection,
    FORM_TYPE_SELECTION: Sections.FormTypeSelectionSection,
    FORM: Sections.FormSection,
    SUCCESS: Sections.SuccessSection,
    ERROR: Sections.ErrorSection,
    RATING: Sections.RatingSection,
    HISTORIC: Sections.HistoricSection,
    BLOCKED: Sections.BlockedSection,
  }[page];

  React.useLayoutEffect(() => {
    dispatch({ infos, hideAttachmentField, onCloseClick });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, hideAttachmentField]);

  return (
    <SectionRightFrame
      title="Falar com o tutor"
      isOpen={isOpen}
      onClose={onClose}
      footer={<FrameFooter />}
      isFullWidth={page === 'HISTORIC'}
    >
      <Content />
    </SectionRightFrame>
  );
};
