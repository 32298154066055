import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

import { useBreakpoint } from '../../../../';
import { CommunitySectionButton } from '../../../../community/features/discuss-on-community';
import { SectionLocationState } from '../types';
import { createHandleClose } from '../utils';
import { useRolesContext } from '../../../contexts/communication-roles-context';
import { TalkTutorButton } from '../../../../messaging';
import { SectionBarProps } from './types';
import { SectionParams } from '../../../../section/shared';
import { ChatCopilot } from '../../../../../components/chat-copilot';
import { useGetState } from '../../../../../state';
import ObjectCustomizationButton from './customization/object-customization-button';
import SectionMobileProgress from './progress/section-progress';
import NotesButton from './notes/notes-button';
import * as Styled from './section-bar-styles';
import { useNewFeature } from '../../tours/new-feature/new-feature.hooks';
import {
  onboardingIds,
  SECTION_CHAT_COPILOT_VIEWED,
  SECTION_CHAT_COPILOT_VIEWED_DESCRIPTION,
} from '../../../utils';
import { SvgTimes, SvgArrowToRight } from '@sofia/ui';
import SectionBarCopiloButton from './copilot/section-bar-copilot-button.presentation';
import useReadingRuler from '../../reading-ruler/use-reading-ruler';

const SectionBar = ({
  onOpenCustomizationClick,
  createHandleCloseProp,
  subjectEnrollmentId,
  onOpenNotesClick,
  onOpenMenuClick,
  learningObjects,
  hideCloseButton,
  showNotesButton,
  onHelpClick,
  studentId,
  hideTutorButton,
  talkTutorInfos,
}: SectionBarProps): React.ReactElement => {
  const history = useHistory<SectionLocationState>();
  const { isDesktop } = useBreakpoint();

  const { learningObjectPath } = useParams<SectionParams>();
  const { state: locationState } = useLocation<SectionLocationState>();
  const backIterations = locationState?.backIterations || 1;
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const { communicationCapabilities, loadingCapabilities } = useRolesContext();

  const showTalkTutorButton =
    !loadingCapabilities &&
    !communicationCapabilities.course.CHAT_COPILOT_ENABLED &&
    !hideTutorButton;

  const closeProps = {
    history,
    fromSubjectPage: locationState?.fromSubjectPage,
    backIterations,
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
  };

  const onCloseClick =
    createHandleCloseProp?.(closeProps) ||
    createHandleClose({
      subjectEnrollmentId,
      ...closeProps,
    });

  useNewFeature({
    communicationKey: SECTION_CHAT_COPILOT_VIEWED,
    communicationDescription: SECTION_CHAT_COPILOT_VIEWED_DESCRIPTION,
    localStorageItem: 'showedNewChatInsideSection',
    tourId: onboardingIds.newFeature.id.newChatSection,
    studentId,
  });

  /* READING RULER */
  const { readingRulerBtnHandler } = useReadingRuler({});
  /* END READING RULER */

  return (
    <Styled.StickyContainer data-testid="section-bar">
      <Styled.TopBarContainer>
        {!isDesktop ? (
          <Styled.IconArrowRightBar
            data-testid="section-bar-open-button"
            onClick={onOpenMenuClick}
            role="button"
          >
            <SvgArrowToRight />
          </Styled.IconArrowRightBar>
        ) : null}

        <Styled.ActionsContainer id="section-actions-container">
          {readingRulerBtnHandler}

          <ObjectCustomizationButton
            studentId={studentId}
            onOpenCustomizationClick={onOpenCustomizationClick}
          />

          {showTalkTutorButton ? (
            <TalkTutorButton onClick={onHelpClick} />
          ) : null}

          {showNotesButton && onOpenNotesClick ? (
            <NotesButton onOpenNotesClick={onOpenNotesClick} />
          ) : null}

          {isDesktop ? <CommunitySectionButton /> : null}
        </Styled.ActionsContainer>

        <Styled.ChatAndCloseButtonWrapper>
          <ChatCopilot
            isNotFixed
            talkTutorInfos={talkTutorInfos}
            componentId="virtual-assistant-chat-section"
          />

          {/* INICIO SECTION CHAT EDU */}
          <SectionBarCopiloButton studentId={studentId} />
          {/* FIM SECTION CHAT EDU */}

          {hideCloseButton ? null : (
            <Tooltip arrow title={'Fechar aula'}>
              <Styled.IconX
                data-testid="close-section"
                id="close-class"
                onClick={onCloseClick}
                aria-label={'Fechar aula'}
                role="button"
              >
                <SvgTimes />
              </Styled.IconX>
            </Tooltip>
          )}
        </Styled.ChatAndCloseButtonWrapper>
      </Styled.TopBarContainer>

      {!isDesktop ? (
        <SectionMobileProgress
          learningObjects={learningObjects}
          activeLearningObjectPath={learningObjectPath}
        />
      ) : null}
    </Styled.StickyContainer>
  );
};

SectionBar.defaultProps = {
  hideCloseButton: false,
  hideTutorButton: false,
};

export default SectionBar;
