// TODO: Remover na refatoração do componente, quando os useEffects forem corrigidos.
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from 'react';
import { object, string, func } from 'prop-types';
import SubjectExamsAssignmentsButton from '../button/subject-exams-assignments-button';
import { generatePath, useHistory } from 'react-router-dom';
import PopUpInvalidFrequency from './popup-validation-frequency';
import PopUpInvalidPeriod from './popup-validation-period';
import { DateTime } from 'luxon';
import { routePath } from '../../../../routes';
import { Typography } from '@sofia/ui';
import PopUpSecondCallExamRelease from '../../pop-up-second-call-exam-release/pop-up-second-call-exam-release.presentation';

//TODO: Com microfront desvincular comunicação direta com o módulo de 'payment'.
import {
  useGetFinancialRoles,
  useGetSecondCallInfo,
  useMutationAcceptSecondCall,
} from '../../../../modules/payment/services';
import { useGetState } from '../../../../state';
import { roles } from '../../../../modules/payment/shared/constants';

const getDueDate = (dueDate) => {
  return DateTime.fromISO(dueDate).set({
    hour: 23,
    minute: 59,
    second: 59,
  });
};

const getFirstCall = (assignments) => {
  const firstCallCurrentFlow = assignments.find(
    (assignment) => assignment.detail.specialization?.currentFlow === 'FIRST'
  );
  const firstCallNoSpecialization = assignments.find(
    (assignment) => assignment.detail.specialization === null
  );

  return firstCallCurrentFlow || firstCallNoSpecialization;
};

const getSecondCall = (assignments) => {
  const today = DateTime.local();
  const secondCallAssignment = assignments.find(
    (subjectEnrollmentAssignmet) =>
      subjectEnrollmentAssignmet.detail.specialization?.currentFlow === 'SECOND'
  );

  const secondCallAssignmentStatus = secondCallAssignment?.status;

  const startDateIso = DateTime.fromISO(
    secondCallAssignment?.detail?.startDate
  );
  const dueDateFormat = getDueDate(secondCallAssignment?.detail?.dueDate);

  const isInSecondCallPeriod = today >= startDateIso && today <= dueDateFormat;

  if (
    !isInSecondCallPeriod &&
    secondCallAssignmentStatus !== 'NOT_AVAILABLE' &&
    secondCallAssignmentStatus !== 'DONE' &&
    secondCallAssignmentStatus !== 'AVAILABLE'
  ) {
    return undefined;
  }

  return secondCallAssignment;
};

const getRecoveryCall = (assignments) => {
  const today = DateTime.local();
  const secondCallAssignment = assignments.find(
    (subjectEnrollmentAssignmet) =>
      subjectEnrollmentAssignmet?.detail?.specialization?.currentFlow ===
      'SECOND'
  );
  const startDateIso = DateTime.fromISO(
    secondCallAssignment?.detail?.startDate
  );
  const dueDateFormat = getDueDate(secondCallAssignment?.detail?.dueDate);

  const isInSecondCallPeriod =
    secondCallAssignment && today >= startDateIso && today <= dueDateFormat;

  if (secondCallAssignment && isInSecondCallPeriod) return undefined;
  return assignments.find(
    (subjectEnrollmentAssignmet) =>
      subjectEnrollmentAssignmet.detail.specialization?.currentFlow ===
      'RECOVERY'
  );
};

const getExamsAssignments = ({ assignments }) => {
  const types = ['EXAM', 'SEMESTRAL_EXAM_ONLINE'];
  return assignments?.filter((subjectEnrollmentAssignmet) =>
    types.includes(subjectEnrollmentAssignmet?.detail.config?.type)
  );
};

const goToExamCorrectionPage = ({
  highestGradeAttemptId,
  attempts,
  selectedCourseVersionId,
  selectedCourseEnrollmentType,
  history,
  title,
}) => {
  const highestGrade = getHighestGradeAttempt({
    highestGradeAttemptId,
    attempts,
  });

  return history.push({
    pathname: generatePath(routePath.examCorrection, {
      courseType: selectedCourseEnrollmentType,
      courseVersionId: selectedCourseVersionId,
      examId: highestGrade.referenceId,
      attemptId: highestGrade.id,
    }),
    state: { title },
  });
};

const getHighestGradeAttempt = ({ highestGradeAttemptId, attempts }) => {
  return attempts.find((attempt) => attempt.id == highestGradeAttemptId);
};

const getBtnProperties = ({ status, display, dueDate }) => {
  if (status == 'DONE') {
    return {
      text: `${display?.name} realizada`,
      description: '',
      completed: true,
      iconColor: '#008566',
    };
  }
  const today = DateTime.local();
  const dueDateIso = getDueDate(dueDate);
  if (today > dueDateIso) {
    return {
      text: `${display?.name} não realizada`,
      description: '',
      completed: false,
      iconColor: '#B1C2D5',
    };
  }

  return {
    text: `Fazer ${display?.name}`,
    description: 'Atinja a pontuação necessária para a aprovação',
    completed: false,
  };
};

const getPopProperties = (detail, today) => {
  const isStartExamPeriod = today >= DateTime.fromISO(detail?.startDate);
  const isEndExamPeriod = today > DateTime.fromISO(detail?.dueDate);
  if (isEndExamPeriod) {
    return {
      header: 'Prazo de realização encerrado',
      content: 'Prazo de realização da prova já foi encerrado',
    };
  }
  if (!isStartExamPeriod) {
    return {
      header: 'Você está fora do período de prova',
      content: `A prova estará disponível no período de ${detail?.display?.periodDates}`,
    };
  }
};

const ButtonExamFlows = ({
  subjectEnrollment,
  onStartExam,
  setAssignmentTittle,
  selectedCourseVersionId,
  selectedCourseEnrollmentType,
}) => {
  const [
    getSecondCallInfo,
    {
      data: dataGetSecondCallInfo,
      called: calledGetSecondCallInfo,
      loading: loadingGetSecondCallInfo,
    },
  ] = useGetSecondCallInfo({});

  const [mutationAcceptSecondCall, { loading: loadingAcceptSecondCall }] =
    useMutationAcceptSecondCall({});

  const [{ selectedCourseEnrollmentId }] = useGetState();

  const { data: dataFinancialRoles } = useGetFinancialRoles({
    courseEnrollmentId: selectedCourseEnrollmentId,
  });

  const hasSecondCallBillingEnabledRole = dataFinancialRoles?.some(
    (role) => role === roles.secondCallBillingEnabled
  );

  const modalSecondCallExamRelease = React.useRef(null);
  const history = useHistory();
  const invalidPeriodModal = useRef(null);
  const insufficientAttendanceModal = useRef(null);

  const today = DateTime.local();

  const showModalSecondCallExamRelease = () => {
    modalSecondCallExamRelease.current.onOpen();
  };

  const handleMutationAcceptSecondCall = async (onSuccess) => {
    try {
      const { data: { acceptSecondCall } = {} } =
        await mutationAcceptSecondCall({
          variables: {
            subjectEnrollmentId: subjectEnrollment.id,
          },
        });

      if (acceptSecondCall && onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error('Failed to accept second call:', error);
    }
  };

  const assignmentExams = getExamsAssignments(subjectEnrollment);

  const attendanceProgress = subjectEnrollment?.progress?.details.find(
    (progress) => progress.type === 'ATTENDANCE'
  );

  // Remoção temporária da validação de frequência
  // const hasNecessaryFrequency = attendanceProgress?.percentage >= 0.75;
  const hasNecessaryFrequency = attendanceProgress?.percentage >= 0;

  const firstCall = getFirstCall(assignmentExams);
  const secondCall = getSecondCall(assignmentExams);
  const recoveryCall = getRecoveryCall(assignmentExams);
  const currentCall = recoveryCall || secondCall || firstCall;

  useEffect(() => {
    if (loadingGetSecondCallInfo || !calledGetSecondCallInfo) return;
    const shouldShowModal =
      !dataGetSecondCallInfo.agreedSecondCall && calledGetSecondCallInfo;

    const shouldRedirectOnStartExam =
      dataGetSecondCallInfo.agreedSecondCall && calledGetSecondCallInfo;

    if (shouldShowModal) return showModalSecondCallExamRelease();
    if (shouldRedirectOnStartExam) return onStartExam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingGetSecondCallInfo,
    onStartExam,
    calledGetSecondCallInfo,
    dataGetSecondCallInfo,
  ]);

  useEffect(() => {
    setAssignmentTittle(currentCall?.detail.display.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCall]);

  if (!subjectEnrollment) return null;

  const onStartExamClick = ({
    highestGradeAttemptId,
    attempts,
    dueDate,
    startDate,
    config,
    specialization,
  }) => {
    const today = DateTime.local();
    const dueDateIso = getDueDate(dueDate);
    const startDateIso = DateTime.fromISO(startDate);

    if (attempts.length >= config.numberOfAttempts) {
      return goToExamCorrectionPage({
        highestGradeAttemptId,
        attempts,
        selectedCourseVersionId,
        selectedCourseEnrollmentType,
        history,
        title: currentCall?.detail.display.name,
      });
    }

    if (!hasNecessaryFrequency && config.type != 'SEMESTRAL_EXAM_ONLINE') {
      return insufficientAttendanceModal.current?.onOpen();
    }

    if (today < startDateIso || today > dueDateIso) {
      return invalidPeriodModal.current?.onOpen();
    }
    if (
      specialization &&
      specialization?.currentFlow === 'SECOND' &&
      hasSecondCallBillingEnabledRole
    ) {
      return getSecondCallInfo({
        variables: {
          subjectEnrollmentId: subjectEnrollment.id,
        },
      });
    }

    return onStartExam();
  };

  const properties = getBtnProperties({
    status: currentCall?.status,
    display: currentCall?.detail?.display,
    dueDate: currentCall?.detail?.dueDate,
  });

  const isInExamPeriod =
    today >= DateTime.fromISO(currentCall?.detail.startDate) &&
    today <= DateTime.fromISO(currentCall?.detail.dueDate);
  const popUpProperties = getPopProperties(currentCall?.detail, today);

  if (!subjectEnrollment || !assignmentExams.length) return null;

  return (
    <>
      <SubjectExamsAssignmentsButton
        aria-label="exam"
        completed={properties.completed}
        text={properties.text}
        description={properties.description}
        onClick={() =>
          !loadingGetSecondCallInfo && onStartExamClick(currentCall?.detail)
        }
        iconColor={properties.iconColor}
      />
      <PopUpInvalidFrequency
        content={
          <>
            <Typography>
              Você precisa completar 75% das aulas para liberação da prova.
            </Typography>
            <Typography>
              Acesse as aulas incompletas, assista aos vídeos e conteúdos
              pendentes.
            </Typography>
          </>
        }
        ref={insufficientAttendanceModal}
      />
      <PopUpInvalidPeriod
        ref={invalidPeriodModal}
        examStarted={isInExamPeriod}
        {...popUpProperties}
      />
      {dataGetSecondCallInfo?.secondCallInformation ? (
        <PopUpSecondCallExamRelease
          handleConfirm={() => handleMutationAcceptSecondCall(onStartExam)}
          period={`${dataGetSecondCallInfo.secondCallInformation.examStartDate} a ${dataGetSecondCallInfo.secondCallInformation.examEndDate}`}
          value={dataGetSecondCallInfo.secondCallInformation.sanitizedValue}
          ref={modalSecondCallExamRelease}
          isLoading={loadingAcceptSecondCall}
          info={dataGetSecondCallInfo.secondCallInformation.info}
        />
      ) : null}
    </>
  );
};

export default ButtonExamFlows;

ButtonExamFlows.propTypes = {
  subjectEnrollment: object,
  onStartExam: func,
  setAssignmentTittle: func,
  selectedCourseVersionId: string,
  selectedCourseEnrollmentType: string,
};
