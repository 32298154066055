import { sectionContextDefaultProps } from './constants';
import { useSectionPageData } from './hooks';
import { SectionContextType } from './types';

import MathJaxProvider from './mathjax-provider';
import React from 'react';
import SectionPagePresentation from './section-page.presentation';

const SectionContext = React.createContext<SectionContextType>(
  sectionContextDefaultProps
);

export const useSectionContext = (): SectionContextType =>
  React.useContext(SectionContext);

const SectionProvider = (): React.ReactElement => {
  const {
    courseEnrollment,
    learningObjects,
    learningUnit,
    externalId,
    subjectEnrollment,
    section,
    loading,
    course,
    me,
    learningObjectData: {
      isLastLearningObjectToComplete,
      previousLearningObject,
      isFirstLearningObject,
      isLastLearningObject,
      firstLearningObject,
      nextLearningObject,
      learningObjectIndex,
      learningObject,
    },
  } = useSectionPageData();

  return (
    <SectionContext.Provider
      value={{
        courseEnrollment,
        isLastLearningObjectToComplete,
        previousLearningObject,
        isFirstLearningObject,
        isLastLearningObject,
        firstLearningObject,
        nextLearningObject,
        learningObjectIndex,
        learningObjects,
        learningObject,
        learningUnit,
        externalId,
        subjectEnrollment,
        section,
        loading,
        course,
        me,
      }}
    >
      <MathJaxProvider
        config={{
          AssistiveMML: {
            disabled: false,
          },
        }}
      >
        <SectionPagePresentation />
      </MathJaxProvider>
    </SectionContext.Provider>
  );
};

export default SectionProvider;
