import styled from '@emotion/styled';

export const Text = styled.p`
  margin-bottom: 0px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
