import styled from '@emotion/styled';

export const Wrapper = styled.div`
  margin: 0 24px 24px 24px;
  > button {
    height: 56px;
    padding-top: unset;
    padding-bottom: unset;
    width: 100%;
  }
`;
