import styled from '@emotion/styled';

export const Flex = styled.div`
  display: flex;
  width: 100%;
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const Container = styled(Column)`
  justify-content: flex-start;
`;

export const Icon = styled.div<{ noNotes: boolean }>`
  display: flex;
  align-items: center;
  margin-left: auto;

  ${({ noNotes }) =>
    noNotes
      ? `
    border-radius: 50%;
    background: #dbe3ec;
    padding: 12px 14px;
    height: 44px;
    margin-top: auto;
    margin-bottom: auto;
  `
      : ''}
`;
