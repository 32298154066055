import React from 'react';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '@ampli/hooks';
import { ButtonGhost, ButtonPrimary, Typography, Input } from '@sofia/ui';
import { useFormik } from '@ampli/utils';
import { func } from 'prop-types';
import * as Styled from './mandatory-internship-confirm-company-data.styles';
import MandatoryIntershipConfirmCompanyDataField from '../field/mandatory-internship-confirm-company-data-field';
import { getCompanyPhoneSchema } from '../schemas/company-phone.schema';
import { formatPhoneNumber } from '../helper';
import { useMandatoryInternshipContext } from '../../../../routes/internship-routes';

const MandatoryInternshipConfirmCompanyData = ({ onBack, onNext }) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const { company, setCompany } = useMandatoryInternshipContext();

  const companyFields = company
    ? [
        [
          { name: 'Razão Social', val: company.companyName },
          { name: 'Nome Fantasia', val: company.tradingName },
          { name: 'CNPJ', val: company.cnpj },
        ],
        [{ name: 'CEP', val: company.zipCode }],
        [
          { name: 'Estado', val: company.state },
          { name: 'Município', val: company.city },
          {
            name: 'Endereço',
            val: `${company.address} Bairro: ${company.neighbourhood}`,
          },
        ],
      ]
    : [];

  const formik = useFormik({
    initialValues: {
      phone: company.phone,
    },
    validationSchema: getCompanyPhoneSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    initialErrors: false,
  });

  const onNextClick = () => {
    setCompany((oldCompany) => ({ ...oldCompany, phone: formik.values.phone }));
    onNext();
  };

  return (
    <>
      <Typography
        weight={700}
        size={isDesktop ? 48 : 32}
        color={theme.colors.text.black}
        margin={`0 0 ${isDesktop ? 56 : 40}px 0`}
      >
        Confira os dados da empresa
      </Typography>

      {companyFields.map((fields, index) => {
        return (
          <Styled.Grid key={index} columns={isDesktop ? fields.length : 1}>
            {fields.map((info, fieldIndex) => {
              return (
                <MandatoryIntershipConfirmCompanyDataField
                  key={fieldIndex}
                  field={info}
                />
              );
            })}
          </Styled.Grid>
        );
      })}

      <Styled.InputWrapper>
        <Input
          id="phone"
          label="Telefone da empresa"
          aria-label="Telefone da empresa"
          placeholder="(00) 0000 0000"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formatPhoneNumber(formik.values.phone)}
          error={Boolean(formik.touched.phone && formik.errors.phone)}
          infoText={formik.touched.phone && formik.errors.phone}
          labelWeight="700"
          maxLength={14}
          width="100%"
          required
        />
      </Styled.InputWrapper>

      <Styled.ButtonsWrapper>
        <ButtonGhost
          hoverColor={theme.colors.button.darkPrimary}
          color={theme.colors.button.primary}
          aria-label="Voltar"
          onClick={onBack}
        >
          Voltar
        </ButtonGhost>
        <ButtonPrimary
          color={theme.colors.text.white}
          background={theme.colors.button.primary}
          aria-label="Avançar para próxima etapa"
          width="100%"
          onClick={onNextClick}
          disabled={
            !formik.isValid ||
            Object.keys(formik.errors).length > 0 ||
            !formik.values.phone
          }
        >
          Avançar
        </ButtonPrimary>
      </Styled.ButtonsWrapper>
    </>
  );
};

MandatoryInternshipConfirmCompanyData.propTypes = {
  onBack: func,
  onNext: func,
};

export default MandatoryInternshipConfirmCompanyData;
