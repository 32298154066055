import { useTheme } from '@emotion/react';
import { string } from 'prop-types';
import React from 'react';
import ImgEmptyApproved from '../../../../../assets/images/disciplines/empty_state_approved.svg';
import ImgEmptyDefault from '../../../../../assets/images/disciplines/empty_state_default.svg';
import ImgEmptyFailed from '../../../../../assets/images/disciplines/empty_state_failed.svg';
import ImgEmptyFinished from '../../../../../assets/images/disciplines/empty_state_finished.svg';
import ImgEmptyFuture from '../../../../../assets/images/disciplines/empty_state_future.svg';
import ImgEmptyReEnrollment from '../../../../../assets/images/disciplines/empty_state_re_enrollment.svg';
import * as Styled from './syllabus-empty-state.styles';

interface SyllabusEmptyStateProps {
  status: string;
}

interface EmptyStateItem {
  img: string;
  title: string;
  description: string;
  direction: 'row' | 'column';
}

const EmptyState: Record<string, EmptyStateItem> = {
  approved: {
    img: ImgEmptyApproved,
    direction: 'column',
    title: 'Sua jornada acadêmica está só começando!',
    description:
      'Você ainda não concluiu nenhuma disciplina, mas suas futuras aprovações serão exibidas aqui assim que você terminar sua primeira disciplina.',
  },
  failed: {
    img: ImgEmptyFailed,
    direction: 'column',
    title: 'Sua dedicação está valendo a pena!',
    description:
      'Não há disciplinas reprovadas aqui. Isso significa que você está no caminho certo! Continue se dedicando aos estudos.',
  },
  future: {
    img: ImgEmptyFuture,
    direction: 'column',
    title: 'Sua jornada acadêmica está só começando!',
    description:
      'Você ainda não concluiu nenhuma disciplina, mas suas futuras aprovações serão exibidas aqui assim que você terminar sua primeira disciplina.',
  },
  RE_ENROLLMENT: {
    img: ImgEmptyReEnrollment,
    direction: 'row',
    title: 'Rematrícula necessária para continuar sua jornada!',
    description:
      'Atualmente, você não tem disciplinas em andamento porque a rematrícula ainda não foi realizada. Finalize o processo para desbloquear suas aulas e continuar sua jornada acadêmica com sucesso.',
  },
  FINISHED: {
    img: ImgEmptyFinished,
    direction: 'row',
    title: 'Você completou sua jornada acadêmica!',
    description:
      'Você completou com sucesso todas as disciplinas do seu curso. Agora é o momento de explorar novos horizontes e buscar novos desafios acadêmicos ou profissionais!',
  },
  default: {
    img: ImgEmptyDefault,
    direction: 'row',
    title: 'Aguardando o início das disciplinas.',
    description:
      'Neste espaço, você verá suas disciplinas em andamento quando estiverem disponíveis. Para mais informações, verifique seu calendário acadêmico ou acesse nossa secretaria.',
  },
};

const SyllabusEmptyState: React.FC<SyllabusEmptyStateProps> = ({
  status,
}: SyllabusEmptyStateProps) => {
  const theme = useTheme();
  const selectedState = EmptyState[status] || EmptyState.default;

  return (
    <Styled.EmptyState theme={theme} direction={selectedState.direction}>
      <img src={selectedState.img} alt={selectedState.title} />

      <Styled.EmptyStateContent>
        <Styled.EmptyStateTitle direction={selectedState.direction}>
          {selectedState.title}
        </Styled.EmptyStateTitle>
        <Styled.EmptyStateDescription direction={selectedState.direction}>
          {selectedState.description}
        </Styled.EmptyStateDescription>
      </Styled.EmptyStateContent>
    </Styled.EmptyState>
  );
};

SyllabusEmptyState.propTypes = {
  status: string.isRequired,
};

export default SyllabusEmptyState;
