import styled from '@emotion/styled';
export const TabPanel = styled.div`
  margin: ${_ref => {
  let {
    margin = '80px 0'
  } = _ref;
  return margin;
}};

  @media (max-width: 769px) {
    margin: ${_ref2 => {
  let {
    mobileMargin = '40px 0'
  } = _ref2;
  return mobileMargin;
}};
    padding: ${_ref3 => {
  let {
    padding
  } = _ref3;
  return padding || '0px 8px 24px 8px';
}};
  }

  display: ${_ref4 => {
  let {
    show
  } = _ref4;
  return show ? 'block' : 'none';
}};
`;