import React from 'react';

import { bool, object } from 'prop-types';
import { MessagingHistoricCard } from '../../../modules';
import { useRolesContext } from '../../../modules/shared/contexts/communication-roles-context';
import { EntitiesLabels } from '../../../constants';
import { useGetState } from '../../../state';
import { Skeleton } from '../../skeleton';

import SubjectPresentationCard from './presentation-card/subject-presentation-card';
import SubjectProgressCard from './progress-card/subject-progress-card';
import SubjectGradeCard from './grade-card/subject-grade-card';
import SubjectNotesCard from './notes-card/subject-notes-card';
import SubjectTitle from '../title/subject-title';

import * as Styled from './subject-header-styles';
import { LiveAccessCard } from '../../../modules/live';
import { SubjectCalendarCard } from './subject-calendar-card';
import { SubjectCalendarModal } from './subject-calendar-modal';

import Joyride from 'react-joyride';

import useGetSubjectCalendarTourStyles from './subject-calendar-tour-styles';
import { useModal } from '@sofia/ui';

const SubjectHeader = ({ subjectEnrollment, loading }) => {
  const notesCardRef = React.useRef();

  const [
    { selectedCourseEnrollmentType: courseType, selectedCourseVersionId },
  ] = useGetState();
  const subjectCalendarModal = useModal();

  const progress = subjectEnrollment?.progress?.engagementPercentage;
  const entitiesLabelsDictionary = EntitiesLabels(courseType);

  const [hasActiveTour, setHasActiveTour] = React.useState(false);
  const [runSubjectCalendarTour, setRunSubjectCalendarTour] =
    React.useState(false);

  const isFirstAccess = localStorage.getItem('isFirstAccess');

  const { hasSubjectOnboardingTourEnabled } = useRolesContext();

  React.useEffect(() => {
    window.addEventListener('hasActiveTour', () => {
      if (hasSubjectOnboardingTourEnabled) setHasActiveTour(true);
    });

    return () => {
      window.removeEventListener('hasActiveTour', () => {
        setHasActiveTour(false);
      });
    };
  }, [hasActiveTour, hasSubjectOnboardingTourEnabled]);

  const notesCard = !loading ? (
    <SubjectNotesCard
      ref={notesCardRef}
      courseType={courseType}
      courseVersionId={selectedCourseVersionId}
      subjectEnrollmentId={subjectEnrollment?.id}
    />
  ) : (
    <Skeleton height={90} borderRadius={6} ref={notesCardRef} />
  );

  const messagingHistoricCard = !loading ? (
    <MessagingHistoricCard
      courseType={courseType}
      courseVersionId={selectedCourseVersionId}
      subjectEnrollmentId={subjectEnrollment?.id}
    />
  ) : (
    <Skeleton height={90} borderRadius={6} ref={notesCardRef} />
  );

  const handleOpenSubjectCalendar = () => {
    subjectCalendarModal.show();
  };

  const handleStartSubjectCalendarTour = () => {
    setRunSubjectCalendarTour(true);
  };

  const steps = [
    {
      target: '#subject-calendar-card',
      content:
        'Clique aqui e confira o calendário da disciplina sempre que necessário.',
      disableBeacon: true,
      title: 'Confira o calendário da disciplina',
      focusElement: true,
      waitForElement: true,
    },
  ];

  const isStartEndIntervalValid =
    subjectEnrollment?.startEndInterval?.s &&
    subjectEnrollment?.startEndInterval?.e;

  React.useEffect(() => {
    if (isFirstAccess !== 'false' && !loading) {
      subjectCalendarModal.show();
    }
  }, [isFirstAccess, loading]);

  return (
    <>
      <Joyride
        disableScrolling
        run={runSubjectCalendarTour}
        showSkipButton={false}
        hideCloseButton={true}
        steps={steps}
        locale={{
          close: 'Entendi',
        }}
        styles={useGetSubjectCalendarTourStyles()}
      />
      <Styled.TitleWrapper>
        <SubjectTitle
          subjectName={subjectEnrollment?.subject?.name}
          subTitle={
            <>
              {entitiesLabelsDictionary.subjectFinalDate}:{' '}
              <strong>{subjectEnrollment?.endDate}</strong>
            </>
          }
        />
        <LiveAccessCard subjectId={subjectEnrollment?.subject?.id} />
      </Styled.TitleWrapper>

      <Styled.Container>
        <Styled.ColumnFlex>
          {!loading ? (
            <>
              {subjectEnrollment ? (
                <SubjectCalendarCard
                  currentPeriod={
                    isStartEndIntervalValid
                      ? subjectEnrollment.startEndInterval
                      : null
                  }
                  onClick={handleOpenSubjectCalendar}
                />
              ) : null}
            </>
          ) : (
            <Skeleton height={90} borderRadius={6} />
          )}

          {!loading ? (
            <SubjectProgressCard
              progress={progress}
              details={subjectEnrollment.progress?.details || []}
              entitiesLabelsDictionary={entitiesLabelsDictionary}
            />
          ) : (
            <Skeleton height={90} borderRadius={6} />
          )}

          {!loading ? (
            <SubjectPresentationCard
              entitiesLabelsDictionary={entitiesLabelsDictionary}
            />
          ) : (
            <Skeleton height={90} borderRadius={6} />
          )}

          {!loading ? (
            <SubjectGradeCard
              courseType={courseType}
              courseVersionId={selectedCourseVersionId}
              subjectEnrollmentId={subjectEnrollment?.id}
              finalGrade={subjectEnrollment?.finalGrade}
            />
          ) : (
            <Skeleton height={90} borderRadius={6} />
          )}

          {notesCard}

          {messagingHistoricCard}
        </Styled.ColumnFlex>
      </Styled.Container>

      <SubjectCalendarModal
        status={subjectEnrollment?.status}
        isFirstAccess={isFirstAccess}
        handleStartSubjectCalendarTour={handleStartSubjectCalendarTour}
        modalState={subjectCalendarModal}
      />
    </>
  );
};

SubjectHeader.propTypes = {
  subjectEnrollment: object.isRequired,
  loading: bool,
};

export default SubjectHeader;
