import React from 'react';
import { getColor } from '@ampli/ui';

function SvgPersonSelfieImage(props) {
  const brandLight = getColor('brand-light');

  return (
    <svg viewBox="0 0 49 50" width={49} height={50} fill="none" {...props}>
      <path
        d="M37.095 17.06a8.53 8.53 0 100-17.06 8.53 8.53 0 000 17.06z"
        fill="#003D51"
      />
      <path
        d="M17.598 18.587L5.872 14.584l-1.651 3.365s6.205 3.057 6.54 3.325c.476.381 5.428 2.87 7.185 2.193 1.757-.677.467-4.488-.348-4.88zM33.98 15.383s.575 3.162 0 3.546c-.576.383 3.833 5.08 3.833 5.08l1.821-1.63.575-3.738s-1.725-2.396-.958-4.504c.767-2.109-5.271 1.246-5.271 1.246z"
        fill="#9F616A"
      />
      <path
        d="M35.705 16.34a6.134 6.134 0 100-12.267 6.134 6.134 0 000 12.268z"
        fill="#9F616A"
      />
      <path
        d="M41.494 44.43v-.097l-16.62-2.498L23.328 50a5.106 5.106 0 001.028-.176c3.737-1.04 10.27-2.95 17.139-5.395z"
        fill="#04546D"
      />
      <path
        d="M41.58 44.399c.06-.87.247-2.3.834-2.756.175-.137.311-.835.416-1.86 2.21 3.628 4.527.8 5.047-.153.322-.591.403-1.665.48-2.68.06-.794.118-1.553.286-2.016.384-1.054.315-1.91-.26-3.06.08-.263.004-.375-.098-.523-.14-.204-.328-.476-.217-1.305.384-1.725-2.012-8.817-2.012-8.817-.006-.024-.491-2.11-2.492-2.204-2.013-.096-3.81-1.153-3.81-1.153l-1.366 3.932h-1.054s-2.396-3.642-3.738-3.93c-1.254-.268-2.424-.119-3.197-.02a44.18 44.18 0 01-.157.02c-.256.032-.713-.053-1.266-.156-1.108-.206-2.6-.483-3.622-.035a.576.576 0 00-.135.083c-.5.076-1.238.176-1.43.135-.295-.063-4.042-.805-5.397.02-1.29.785-2.301.142-2.395.078l-.007-.005.007.005c.21.147 4.982 3.52-.474 5.35 0 0 .148.466 1.608.594 1.39.121 1.744.357 2.314.735l.088.06c.625.412 1.82.762 2.604.868.291.04.841.25 1.435.478.599.229 1.24.475 1.707.58-.323 1.984-1.553 9.94-.884 12.2.767 2.587.192 3.067-.191 3.354-.384.287 8.721 2.875 8.721 2.875H39.6a210.38 210.38 0 001.98-.694z"
        fill="#FFB800"
      />
      <path
        d="M46.719 42.483c.326-.878.63-1.801.63-1.801 0-6.073-3.929-2.971-3.929-2.971l-1.1 6.422c1.464-.528 2.938-1.079 4.399-1.65z"
        fill="#9F616A"
      />
      <path
        d="M34.699 8.913c3.017 0 5.463-1.244 5.463-2.78 0-1.534-2.446-2.779-5.463-2.779s-5.463 1.245-5.463 2.78c0 1.535 2.446 2.78 5.463 2.78z"
        fill="#003D51"
      />
      <path
        d="M42.078 10.638c.265 0 .48-.472.48-1.054 0-.582-.215-1.054-.48-1.054-.264 0-.479.472-.479 1.054 0 .582.215 1.054.48 1.054z"
        fill="#9F616A"
      />
      <path
        d="M2.728 4.812l-.039 7.741a1.312 1.312 0 001.057 1.232c.071.014.142.028.21.05l3.084 1.007c.068.023.139.04.211.041a1.31 1.31 0 001.349-1.26l.163-4.41a.096.096 0 01.1-.091.096.096 0 00.1-.092l.048-1.245a.096.096 0 00-.092-.1.096.096 0 01-.092-.1l.023-.574a.096.096 0 01.1-.092.096.096 0 00.099-.092l.049-1.244a.096.096 0 00-.092-.1.096.096 0 01-.093-.1l.027-.935A1.312 1.312 0 007.85 3.103a.755.755 0 00-.201-.003l-3.39.34c-.066.007-.134.006-.201.005a1.312 1.312 0 00-1.329 1.367z"
        fill={brandLight}
      />
      <path
        d="M4.224 5.38c.023-.583-.173-1.063-.437-1.073-.265-.01-.497.453-.52 1.034-.023.582.172 1.062.437 1.073.264.01.497-.453.52-1.035z"
        fill="#000"
        fillOpacity={0.15}
      />
      <path
        d="M5.364 17.822l-1.334.148S.972 17.345.73 15.154C.486 12.963.847 8.39 3.525 8.19c2.677-.2.855 5.98.855 5.98l1.688.48.227 2.008-.931 1.164z"
        fill="#9F616A"
      />
    </svg>
  );
}

export default SvgPersonSelfieImage;
