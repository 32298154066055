import React from 'react';
import * as Styles from './before-start.styles';
import { Typography, SvgReadWoman } from '@sofia/ui';

const Learning: React.FC = () => {
  return (
    <Styles.OnboardingModal>
      <div className="onboarding">
        <SvgReadWoman className="onboarding__illustration" aria-hidden="true" />
        <Typography className="onboarding__title">
          Faça um tour por aprendizagem!
        </Typography>
        <Typography margin="0 0 16px 0" className="onboarding__description">
          Faça um tour interativo com o passo a passo de como assistir suas
          aulas.
        </Typography>
      </div>
    </Styles.OnboardingModal>
  );
};

export default Learning;
