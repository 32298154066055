import { useMutation, useLazyQuery } from '@ampli/services';
import { GET_SECOND_CALL_INFO, MUTATION_ACCEPT_SECOND_CALL } from './queries';
import {
  LazyGetSecondCallInfoHook,
  MutationAcceptSecondCallHook,
} from './types';

export const useGetSecondCallInfo: LazyGetSecondCallInfoHook = ({
  options = {},
}) => {
  const [query, { data, loading, error, called }] = useLazyQuery(
    GET_SECOND_CALL_INFO,
    {
      ...options,
      context: {
        autoHandle: true,
      },
      fetchPolicy: 'no-cache',
    }
  );

  return [query, { data, loading, error, called }];
};

export const useMutationAcceptSecondCall: MutationAcceptSecondCallHook = ({
  options,
}) => {
  const [mutate, { loading, error, data }] = useMutation(
    MUTATION_ACCEPT_SECOND_CALL,
    { ...options }
  );

  const requestTaxInvoiceDocument = loading || !data ? {} : data.data;

  return [
    mutate,
    {
      data: requestTaxInvoiceDocument,
      error,
      loading,
    },
  ];
};
