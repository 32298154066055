import React, { FC } from 'react';
import { SvgAngleRight, Typography } from '@sofia/ui';
import { OptionsPopUpItemProps } from './types';
import { Wrapper } from './options-pop-up.styles';
import { useTheme } from '@emotion/react';

const OptionsPopUpItem: FC<OptionsPopUpItemProps> = ({
  label,
  onClick,
  ...rest
}: OptionsPopUpItemProps) => {
  const theme = useTheme();
  return (
    <Wrapper.Item onClick={onClick} {...rest}>
      <Typography
        size={16}
        color={theme.colors.text.black}
        weight="700"
        margin="0"
      >
        {label}
      </Typography>
      <SvgAngleRight />
    </Wrapper.Item>
  );
};

export default OptionsPopUpItem;
