import { useCallback } from 'react';
import { useBreakpoint } from '@ampli/hooks';
export const usePlayerActions = _ref => {
  let {
    player,
    setPlaybackRatePanel,
    playbackRatePanel,
    bottomSheet,
    waiting
  } = _ref;
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const handleTogglePlay = useCallback(e => {
    e.stopPropagation();
    const paused = player.paused();
    if ((isDesktop || player.userActive() || paused) && !waiting) {
      if (paused) {
        player.play();
      } else {
        player.pause();
      }
      setPlaybackRatePanel(false);
    }
  }, [player, waiting, isDesktop]);
  const handleToggleFullscreen = useCallback(e => {
    e.stopPropagation();
    if (player.isFullscreen()) {
      player.exitFullscreen();
    } else {
      player.requestFullscreen();
    }
    setPlaybackRatePanel(false);
  }, [player]);
  const handlePlaybackRateClick = useCallback((e, _ref2) => {
    let {
      rate
    } = _ref2;
    e.stopPropagation();
    player.playbackRate(rate);
    setPlaybackRatePanel(false);
    bottomSheet === null || bottomSheet === void 0 || bottomSheet.hide();
  }, [player, bottomSheet]);
  const handlePlaybackTogglePanel = useCallback(e => {
    e.stopPropagation();
    setPlaybackRatePanel(!playbackRatePanel);
  }, [playbackRatePanel]);
  return {
    handleTogglePlay,
    handleToggleFullscreen,
    handlePlaybackRateClick,
    handlePlaybackTogglePanel
  };
};
export const usePlayerVolumeActions = _ref3 => {
  let {
    player,
    setPlaybackRatePanel,
    setLastVolume,
    lastVolume
  } = _ref3;
  const handleVolumeControlToggleMute = useCallback(e => {
    e.stopPropagation();
    if (player.muted()) {
      player.muted(false);
      player.volume(lastVolume || 0.2);
    } else {
      setLastVolume(player.volume());
      player.muted(true);
      player.volume(0);
    }
    setPlaybackRatePanel(false);
  }, [player, lastVolume]);
  const handleVolumeControlStartDrag = useCallback((e, volume) => {
    e.stopPropagation();
    player.volume(volume.percent);
    setLastVolume(volume.percent);
    setPlaybackRatePanel(false);
  }, [player]);
  const handleVolumeControlSliderMove = useCallback((e, volume) => {
    e.stopPropagation();
    if (volume.dragging) {
      player.volume(volume.percent);
      setLastVolume(volume.percent);
    }
  }, [player]);
  return {
    handleVolumeControlSliderMove,
    handleVolumeControlStartDrag,
    handleVolumeControlToggleMute
  };
};
export const usePlayerProgressBarActions = _ref4 => {
  let {
    player,
    setKeepPause,
    keepPause,
    setDragging
  } = _ref4;
  const handleProgressBarSliderMove = useCallback((e, progress) => {
    e.stopPropagation();
    if (progress.dragging) {
      player.currentTime(progress.position);
    }
  }, [player]);
  const handleProgressBarJump = useCallback((e, progress) => {
    e.stopPropagation();
    player.currentTime(progress.position);
  }, [player]);
  const handleProgressBarStartDrag = useCallback((e, progress) => {
    e.stopPropagation();
    player.currentTime(progress.position);
    if (player.paused()) {
      setKeepPause(true);
    } else {
      setKeepPause(false);
      player.pause();
    }
    setDragging(true);
  }, [player]);
  const handleProgressBarStopDrag = useCallback(e => {
    e.stopPropagation();
    if (!keepPause && player.paused()) {
      player.play();
      setKeepPause(true);
    }
    setDragging(false);
  }, [player, keepPause]);
  return {
    handleProgressBarStopDrag,
    handleProgressBarStartDrag,
    handleProgressBarSliderMove,
    handleProgressBarJump
  };
};