import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW } from '../../utils';
import { css } from '@emotion/css';
export const DropDownWrapper = styled.div`
  margin-bottom: ${convertPXToVH(24)};
  padding: ${convertPXToVH(24)} ${convertPXToVW(24)};
  border: 1px solid #dee2e6;
  border-radius: 8px;
  background: white;

  &:hover {
    cursor: pointer;
  }
`;
export const AbsoluteWrapper = styled.div`
  position: absolute;
  right: 0;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: convertPXToREM(16);

  ${_ref => {
  let {
    open
  } = _ref;
  return open === true && css`
      & > svg {
        transform: rotate(180deg);
      }
    `;
}}
`;
export const Body = styled.div`
  display: ${_ref2 => {
  let {
    open
  } = _ref2;
  return open ? 'block' : 'none';
}};
  margin-top: ${convertPXToVH(22)};
  font-size: convertPXToREM(20);
  padding: 10px 0;

  @media (max-width: 937px) {
    padding: 0;
  }
`;