import React from 'react';
import { Skeleton } from '../../../../../shared';
import * as Styled from './notes-learning-unit-section-skeleton.styles';

export interface NotesLearningUnitSectionSkeletonProps {
  isDesktop: boolean;
}

function NotesLearningUnitSectionSkeleton({
  isDesktop,
}: NotesLearningUnitSectionSkeletonProps): React.ReactElement {
  return (
    <Styled.SectionWrapper data-testid="notes-learning-unit-section-skeleton">
      <Styled.HeadingWrapper>
        <Styled.HeadingTop data-testid="notes-learning-unit-section-skeleton-header-top">
          <Skeleton height="48px" width="200px" />
          {isDesktop ? (
            <Skeleton height="66px" width="260px" borderRadius="8px" />
          ) : null}
        </Styled.HeadingTop>

        <Skeleton height="28px" />
      </Styled.HeadingWrapper>

      <Skeleton height="208px" borderRadius="8px" />

      {!isDesktop ? <Skeleton height="66px" borderRadius="8px" /> : null}
    </Styled.SectionWrapper>
  );
}

export default NotesLearningUnitSectionSkeleton;
