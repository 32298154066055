/* eslint-disable */
import styled from '@emotion/styled';
import { convertPXToREM, convertPXToVH, convertPXToVW } from '../../utils';
export const Header = styled.header`
  display: flex;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: ${_ref => {
  let {
    maxWidth
  } = _ref;
  return convertPXToVW(maxWidth);
}};
  margin-right: ${convertPXToVW(24)};

  @media (max-width: 600px) {
    width: 0px;
    max-width: none;
    margin-right: 0;
    ${_ref2 => {
  let {
    menuOpen
  } = _ref2;
  if (menuOpen) {
    return `
        width: 100%;
        margin-right: ${convertPXToVW(24)};
        `;
  }
}}
  }
`;
export const Menu = styled.ul`
  display: flex;
  list-style: none;
  flex-direction: column;
  padding: 0;

  @media (max-width: 600px) {
    display: block;
    width: 0px;
    height: 100vh;
    top: 70px;
    left: 0px;
    background: #fff;
    transition: 0.6s;
    z-index: 1000;
    visibility: hidden;
    overflow-y: hidden;
    margin: 0;

    ${_ref3 => {
  let {
    menuOpen
  } = _ref3;
  if (menuOpen) {
    return `
        width: 100vw;
        visibility: visible;
        overflow-y: auto;`;
  }
}}
  }
`;
export const Span = styled.span`
  display: block;
  color: black;
  text-decoration: none;
  padding: 0;
  font-size: ${convertPXToREM(20)};
  font-weight: 500;
  color: ${_ref4 => {
  let {
    active,
    activeColor
  } = _ref4;
  return active ? activeColor : '#212529';
}};

  @media (max-width: 600px) {
    font-size: ${convertPXToREM(16)};
    font-weight: 700;
  }
`;
export const Item = styled.li`
  min-width: ${convertPXToVW(250)};
  padding: ${convertPXToVH(27)} 0;
  gap: ${convertPXToVH(27)};
  border-bottom: 1px solid ${_ref5 => {
  let {
    borderColor
  } = _ref5;
  return borderColor;
}};
  cursor: pointer;

  &:hover {
    background-color: ${_ref6 => {
  let {
    hoverColor
  } = _ref6;
  return hoverColor;
}};
    border-color: #698aae;
  }

  &:focus {
    border: 4px solid #004ecc;
  }

  @media (max-width: 600px) {
    padding: ${convertPXToVH(24)} ${convertPXToVW(16)};
  }
`;
export const Nav = styled.nav`
  width: 100%;
`;
export const ContainerButtonMenu = styled.div`
  width: 100vw;
  height: 50px;
  background: ${_ref7 => {
  let {
    backgroundHeaderColor
  } = _ref7;
  return backgroundHeaderColor;
}};
  padding: 0 ${convertPXToVW(20)};
  align-items: center;
  display: none;
  @media (max-width: 600px) {
    display: flex;
    width: 0px;

    ${_ref8 => {
  let {
    menuOpen
  } = _ref8;
  if (menuOpen) {
    return `
          width: 100vw;`;
  }
}}
  }
`;
export const ContainerShowMobile = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`;
export const ButtonMenu = styled.button`
  padding: 0 !important;
  @media (max-width: 600px) {
    display: flex;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    background: none;
    cursor: pointer;
    gap: 0.5rem;
    position: absolute;
    top: 5px;
    left: 0;
  }
`;
export const SpanHamburger = styled.span`
  color: ${_ref9 => {
  let {
    buttonColor
  } = _ref9;
  return buttonColor;
}};

  @media (max-width: 600px) {
    border-top: 2px solid;
    width: 30px;

    &::after,
    &::before {
      content: '';
      display: block;
      width: 30px;
      height: 2px;
      background: currentColor;
      margin-top: 5px;
      transition: 0.3s;
      position: relative;
    }

    ${_ref10 => {
  let {
    menuOpen
  } = _ref10;
  if (menuOpen) {
    return `
          border-top-color: transparent;
          &::before {
            transform: rotate(135deg);
          }
          &::after {
            transform: rotate(-135deg);
            top: -7px;
          }
        `;
  }
}}
  }
`;