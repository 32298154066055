import styled from '@emotion/styled';

export const NotificationsListWrapper = styled.div`
  &:nth-of-type(2n) {
    margin: 20px 0px;
  }
`;

export const NotificationsList = styled.div`
  margin-top: 16px;

  > div {
    margin-bottom: 16px;
  }
`;
