import { useLazyQuery, useQuery } from '../hooks';
import { GET_ME } from './queries';
export const useGetMe = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    loading,
    data,
    error
  } = useQuery(GET_ME, options);
  const me = loading || !data ? {} : data.data;
  return {
    me,
    loading,
    error
  };
};
export const useGetMeLazy = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useLazyQuery(GET_ME, options);
};