import styled from '@emotion/styled';

import { convertPXToVW, convertPXToVH } from '@sofia/ui';
import { SizeMaxWidthScreenMobile } from '@ampli/ui';

export const EmailBox = styled.div`
  position: relative;
  background: ${(props) =>
    props.active ? 'rgba(204, 240, 246, 0.5)' : '#ffffff'};
  border: 1px solid #b1c2d5;
  cursor: pointer;
  box-sizing: border-box;
  padding: ${convertPXToVH(32)} ${convertPXToVW(32)};
  border-radius: 8px;
  align-items: center;
  display: flex;
  margin-bottom: 0.82vh;

  @media (max-width: ${SizeMaxWidthScreenMobile}) {
    padding: ${convertPXToVH(32)} ${convertPXToVW(16)};
  }
`;

export const EmailIconWrapper = styled.div`
  border-radius: 50%;
  height: fit-content;
  width: fit-content;
  padding: 8px;
  margin-right: ${convertPXToVW(16)};
  background: ${(props) => props.color};

  @media (max-width: 769px) {
    margin-right: ${convertPXToVW(8)};
  }
`;

export const TextWrapper = styled.div`
  margin-right: ${convertPXToVW(32)};
  min-width: ${convertPXToVW(144)};

  @media (max-width: ${SizeMaxWidthScreenMobile}) {
    margin-right: 0;
  }
`;

export const Hyperlink = styled.span`
  cursor: pointer;
  color: #01b6d0;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  right: ${convertPXToVW(40)};

  @media (max-width: 1300px) {
    right: ${convertPXToVW(10)};
  }
`;

export const ForgotWrapper = styled.div`
  margin-top: ${convertPXToVH(48)};
`;

export const WrapperTalkToUs = styled.div`
  margin-top: ${convertPXToVH(10)};
`;

export const DropDownItem = styled.div`
  border-top: 1px solid #d6e1eb;
  padding: ${convertPXToVH(15)} 0;
  display: flex;
  align-items: center;
`;

export const DynamicLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 40px;
`;
