import React from 'react';

import CategoryTypeHint from './category-type-hint.presentation';
import CategoryType from './category-type.presentation';

import {
  ICategoryStepProps,
  PUBLICATION_CATEGORY,
  TActiveTab,
} from '../../../../../types';

import { usePublicationFormContext } from '../../../../../contexts/publication-form-context';
import { Typography } from '@sofia/ui';

import * as Styles from './category-step.styles';

const CategoryStep: React.FC<ICategoryStepProps> = ({
  isVisible,
}: ICategoryStepProps) => {
  const [activeTab, setActiveTab] = React.useState<TActiveTab>(
    PUBLICATION_CATEGORY.DOUBT
  );

  const { actions } = usePublicationFormContext();

  return isVisible ? (
    <React.Fragment>
      <Styles.Header>
        <Typography>
          Qual a <strong>categoria</strong> da publicação?
        </Typography>
      </Styles.Header>

      <Styles.Content>
        <CategoryType
          activeTab={activeTab}
          setActiveTab={(category) => {
            setActiveTab(category);
            actions.onSelectCategory(category);
          }}
        />
        <CategoryTypeHint activeTab={activeTab} />
      </Styles.Content>
    </React.Fragment>
  ) : null;
};

export default CategoryStep;
