import { useFormik } from '@ampli/utils';
import { useUploadStudentDocument } from '@ampli/services';

const useUploadDocumentForm = ({
  schemaOptions = {},
  onSubmit,
  onError,
  onCompleted,
}) => {
  const [mutate, { data, called, loading, error }, schema] =
    useUploadStudentDocument({
      onError,
      onCompleted: ({ data, errors }) => {
        if (!data || errors) {
          onError && onError(errors);
        } else {
          onCompleted && onCompleted();
        }
      },
    });

  const handleSubmit = async (variables) => {
    onSubmit && onSubmit(variables);
    return await mutate({ variables });
  };

  const formik = useFormik({
    initialValues: {
      documentType: '',
      documentFile: null,
    },
    validationSchema: schema(schemaOptions),
    onSubmit: handleSubmit,
  });

  return {
    formik,
    loading,
    called,
    error,
    data,
  };
};

export default useUploadDocumentForm;
