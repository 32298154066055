import * as Yup from 'yup';
import { DateTime, validateCpf } from '@ampli/utils';
const requiredMsg = 'Preenchimento obrigatório.';
const isValidDocumentTest = () => ({
  name: 'isValidDocumentTest',
  message: 'CPF inválido.',
  test: value => {
    const onlyNumbers = value && value.replace(/[^\d]+/g, '');
    const filled = onlyNumbers && onlyNumbers.length === 11;
    return filled && validateCpf(onlyNumbers);
  }
});
const isValidDateTest = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: 'isValidDateTest',
    message: 'Data inválida.',
    test: value => {
      const {
        format = 'dd/MM/yyyy'
      } = options;
      const filled = value && value.replace(/[^\d]+/g, '').length === 8;
      return filled && DateTime.fromFormat(value, format).isValid;
    }
  };
};
export const candidateCredentialsSchema = () => Yup.object({
  username: Yup.string().required(requiredMsg).test(isValidDocumentTest()),
  password: Yup.string().required(requiredMsg).test(isValidDateTest())
});

// eslint-disable-next-line sonarjs/no-identical-functions
export const sellerCredentialsSchema = () => Yup.object({
  username: Yup.string().required(requiredMsg).test(isValidDocumentTest()),
  password: Yup.string().required(requiredMsg).test(isValidDateTest())
});
export const studentCredentialsSchema = () => Yup.object({
  username: Yup.string().required(requiredMsg).test(isValidDocumentTest()),
  password: Yup.string().required(requiredMsg),
  deviceId: Yup.string().required(requiredMsg)
});
export const ampliCredentialsSchema = () => Yup.object({
  username: Yup.string().required(requiredMsg),
  password: Yup.string().required(requiredMsg)
});