import styled from '@emotion/styled';

export const ListItemSkeleton = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 24px;
  border-bottom: 1px solid #dee2e6;
`;

export const IconContainer = styled('div', {
  shouldForwardProp: (propName) => propName !== 'completed',
})<{ completed: boolean; color: string }>(
  ({ completed, color }) => `
  &:not(.closed) {
    margin-right: 16px;
  }
  &.closed {
    padding: 12px;
    line-height: 0;
  }
  height: 100%;

  svg {
    width: 20px;
    height: 20px;
    color: ${completed ? '#33AB8C' : color};
    ${
      completed &&
      `path, circle {
        stroke: #33AB8C;
      }`
    }
  }

  `
);
