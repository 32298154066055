import React from 'react';

import AvatarEdu from '../../../../assets/avatar-edu.png';

import { ChatCopilotProps } from '../../types';
import { useRolesContext } from '../../../../modules/shared/contexts/communication-roles-context';
import { useChatCopilot } from './chat-copilot.logic';
import { useBreakpoint } from '../../../../modules';
import { WebChat } from '../webchat';

import * as Styles from '../../styles';

const ChatCopilot: React.FC<ChatCopilotProps> = ({
  answerContainerRef,
  questionContainerRef,
  isQuizCopilotOpen,
  talkTutorInfos,
  isNotFixed = false,
  quizPagePayload,
  isQuizPageRendered,
  isChatCopilotOnpenOnRender = false,
  isCopilotOnQuiz = false,
  componentId = 'chat-copilot',
}: ChatCopilotProps) => {
  const { isDesktop } = useBreakpoint();

  window.onload = () => {
    sessionStorage.removeItem('serviceCreated');
    sessionStorage.removeItem('chatClosed');
  };

  const { communicationCapabilities, loadingCapabilities } = useRolesContext();

  const {
    isCourseEnrollmentBlocked,
    setIsCopilotOpen,
    isHoverOrFocus,
    isCopilotOpen,
    directLineURL,
    containerRef,
    eduButtonRef,
    token,
  } = useChatCopilot({ isChatCopilotOnpenOnRender });

  const showEduText =
    (isHoverOrFocus && isDesktop) ||
    (isNotFixed && isDesktop) ||
    (isHoverOrFocus && !isNotFixed && !isDesktop);

  const showCopilotButton = () => {
    if (isCopilotOnQuiz) {
      return true;
    }
    return (
      !loadingCapabilities &&
      communicationCapabilities.course.CHAT_COPILOT_ENABLED &&
      !isCourseEnrollmentBlocked
    );
  };

  const isMobileQuiz = () => {
    if (isCopilotOnQuiz && !isDesktop) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (
      isQuizCopilotOpen &&
      isCopilotOpen &&
      window.location.pathname.includes('quiz')
    ) {
      setIsCopilotOpen(false);
      setTimeout(() => setIsCopilotOpen(true), 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuizCopilotOpen]);

  return showCopilotButton() ? (
    <React.Fragment>
      <Styles.CopilotButton
        isMobileQuiz={isMobileQuiz()}
        data-testid={componentId}
        id={componentId}
        ref={eduButtonRef}
        type="button"
        isHoverOrFocus={isHoverOrFocus || isNotFixed}
        isFixed={!isNotFixed}
        onClick={() => setIsCopilotOpen(true)}
      >
        <Styles.AvatarEdu src={AvatarEdu} alt="Edu" isFixed={!isNotFixed} />
        {showEduText ? 'Edu' : null}
      </Styles.CopilotButton>

      {isCopilotOpen ? (
        <WebChat
          answerContainerRef={answerContainerRef}
          questionContainerRef={questionContainerRef}
          setIsCopilotOpen={setIsCopilotOpen}
          containerRef={containerRef}
          token={token}
          directLineURL={directLineURL}
          quizPagePayload={quizPagePayload}
          isQuizPageRendered={isQuizPageRendered}
          talkTutorInfos={talkTutorInfos}
        />
      ) : null}
    </React.Fragment>
  ) : null;
};

export default ChatCopilot;
