import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

interface IBgColor {
  bgcolor: string;
}

const LiveAnimation = ({ bgcolor }: IBgColor) => keyframes`
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(3, 3);
    background-color: ${bgcolor}50;
  }
  100% {
    transform: scale(1, 1);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: -1px 0 0 0;
  ${mediaQuery('desktop')} {
    margin: -1px 16px 0 0;
  }
  > svg {
    margin-right: 8px;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const RecordIcon = styled.div`
  display: inline-block;
  position: relative;
  top: calc(50% - 5px);
  background-color: ${({ bgcolor }: IBgColor) => bgcolor};
  width: 8px;
  height: 8px;
  margin: 8px 8px 8px 2px;
  border: 1px solid ${({ theme }) => theme.colors.text.black}10;
  border-radius: 50%;
  z-index: 1;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: ${({ bgcolor }: IBgColor) => bgcolor}60;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: ${({ bgcolor }: IBgColor) => LiveAnimation({ bgcolor })} 2s
      ease-in-out infinite;
    z-index: -1;
  }
`;
