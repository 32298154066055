import { convertDateTimeTZ } from '.';

type GetRemainingTime = (date: string | Date) => number;

const getRemainingTime: GetRemainingTime = (date) => {
  return (
    convertDateTimeTZ(date)?.getTime() -
    convertDateTimeTZ(new Date())?.getTime()
  );
};

export default getRemainingTime;
