import { authStorage } from '@ampli/utils';
import { useMeContext } from '../../../../../../../modules/shared/contexts/me-context';
import DOMPurify from 'dompurify';

import { routes } from '../config';

import type { FormValuesProps, UploadedFile } from '../../../shared';

import type {
  GetConversationMessages,
  PageConversationInfo,
  EvaluateConversation,
  ReplyConversation,
  ConversationDTO,
  GetConversation,
  PageMessageInfo,
} from '../types';

type CreateConversation = ConversationDTO & Pick<FormValuesProps, 'attachment'>;

export type UseDynamicsLogic = {
  createConversation: (values: CreateConversation) => Promise<Response>;
  replyConversation: (values: ReplyConversation) => Promise<Response>;
  getConversations: (
    props: Partial<GetConversation>
  ) => Promise<PageConversationInfo>;
  getConversationMessages: (
    values: GetConversationMessages
  ) => Promise<PageMessageInfo | null>;
  closeConversation: (id?: string) => Promise<Response>;
  commentEvaluation: (values: EvaluateConversation) => Promise<Response>;
  ratingEvaluation: (values: EvaluateConversation) => Promise<Response>;
  readMessage: (id?: string) => Promise<Response>;
};

const errorMessageViewAs = 'Você não tem permissão';

export type ErrorMessage = { exceptionMessage: string };

export const headers = async (options: {
  isFileRequest?: boolean;
  studentId: string;
}): Promise<{ Authorization: string; User: string }> => {
  const token = await authStorage.getItem('token');

  return {
    ...(!options?.isFileRequest
      ? { 'Content-Type': 'application/json' }
      : null),
    Authorization: 'Bearer' + ' ' + token,
    User: options?.studentId,
  };
};

export const useDynamicsLogic = (): UseDynamicsLogic => {
  const { states: statesMe } = useMeContext();
  const { me } = statesMe.getMe;

  const uploadFiles = async (
    attachments: UploadedFile[]
  ): Promise<string | ErrorMessage> => {
    const formData = new FormData();
    const ViewAs = await authStorage.getItem('ViewAs');

    attachments.forEach(({ file }) => formData.append('file', file));

    if (!ViewAs) {
      return fetch(routes.postFile, {
        method: 'POST',
        headers: await headers({ isFileRequest: true, studentId: me?.id }),
        body: formData,
      }).then(async (res) => {
        const response = await res.json();

        // Sanitize the response to prevent XSS attacks
        return DOMPurify.sanitize(response);
      });
    } else {
      return { exceptionMessage: errorMessageViewAs };
    }
  };

  const createConversation = async (values: CreateConversation) => {
    const ViewAs = await authStorage.getItem('ViewAs');
    const fileToken = values.attachment
      ? await uploadFiles(values.attachment)
      : null;

    if ((fileToken as ErrorMessage)?.exceptionMessage)
      throw new Error('Failed to upload file');

    delete values.attachment;

    if (!ViewAs) {
      return fetch(routes.postConversation, {
        method: 'POST',
        headers: await headers({ studentId: me?.id }),
        body: JSON.stringify({ ...values, fileToken }),
      });
    } else {
      throw new Error(errorMessageViewAs);
    }
  };

  const addParam = (
    params: URLSearchParams,
    key: string,
    value?: string | number | string[] | null
  ) => (value ? params.set(key, String(value)) : null);

  const getConversations = async (
    values: Partial<GetConversation>
  ): Promise<PageConversationInfo> => {
    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(values))
      value && addParam(params, key, String(value));

    return fetch(routes.getConversation(params), {
      method: 'GET',
      headers: await headers({ studentId: me?.id }),
    }).then((response) => response.json());
  };

  const getConversationMessages = async (
    values: GetConversationMessages
  ): Promise<PageMessageInfo | null> => {
    if (!values.id) return null;
    const params = new URLSearchParams();

    addParam(params, 'sort', values?.sort || 'updatedAt,DESC');

    return fetch(routes.getConversationMessages(values.id, params), {
      method: 'GET',
      headers: await headers({ studentId: me?.id }),
    }).then((response) => response.json());
  };

  const replyConversation = async (
    values: ReplyConversation
  ): Promise<Response> => {
    let fileToken;

    if (values.attachment) fileToken = await uploadFiles(values.attachment);

    const ViewAs = await authStorage.getItem('ViewAs');

    delete values.attachment;

    if (!ViewAs) {
      return fetch(routes.replyConversation, {
        method: 'POST',
        headers: await headers({ studentId: me?.id }),
        body: JSON.stringify({ ...values, fileToken }),
      });
    } else {
      throw new Error(errorMessageViewAs);
    }
  };

  const closeConversation = async (id?: string) => {
    if (!id) return null;
    const ViewAs = await authStorage.getItem('ViewAs');
    if (!ViewAs) {
      return fetch(routes.closeConversation(id), {
        method: 'PUT',
        headers: await headers({ studentId: me?.id }),
      }).then((response) => response.json());
    } else {
      throw new Error(errorMessageViewAs);
    }
  };

  const ratingEvaluation = async ({ id, rating }: EvaluateConversation) => {
    if (!id) return null;

    const ViewAs = await authStorage.getItem('ViewAs');
    if (!ViewAs) {
      return fetch(routes.ratingEvaluationConversation(id), {
        method: 'PUT',
        headers: await headers({ studentId: me?.id }),
        body: JSON.stringify({ rating }),
      }).then((response) => response.json());
    } else {
      throw new Error(errorMessageViewAs);
    }
  };

  const commentEvaluation = async ({ id, comment }: EvaluateConversation) => {
    if (!id) return null;
    const ViewAs = await authStorage.getItem('ViewAs');
    if (!ViewAs) {
      return fetch(routes.commentEvaluationConversation(id), {
        method: 'PUT',
        headers: await headers({ studentId: me?.id }),
        body: JSON.stringify({ comment }),
      }).then((response) => response.json());
    } else {
      throw new Error(errorMessageViewAs);
    }
  };

  const readMessage = async (id?: string) => {
    if (!id) return null;
    const ViewAs = await authStorage.getItem('ViewAs');
    if (!ViewAs) {
      return fetch(routes.readMessage(id), {
        method: 'PUT',
        headers: await headers({ studentId: me?.id }),
      }).then((response) => response.json());
    } else {
      throw new Error(errorMessageViewAs);
    }
  };

  return {
    getConversationMessages,
    createConversation,
    replyConversation,
    closeConversation,
    getConversations,
    commentEvaluation,
    ratingEvaluation,
    readMessage,
  };
};
