import styled from '@emotion/styled';

import SvgEmptyIllustration from '../../../../../../assets/empty.illustration';

import { mediaQuery, convertPXToVH, convertPXToVW } from '@sofia/ui';
import { Icon, IconButton } from '@ampli/ui';

const breakpoint = 'desktop-max';

export const Main = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: 1fr 2fr;
`;

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
`;

export const HeaderWrapper = styled.div`
  background-color: #ffffff;
  padding: 32px 40px 0px;
`;

export const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background-color: #ffffff;
  border-bottom: 1px solid var(--color-neutral-color-300, #dee2e6);
`;

interface FilterProps {
  active: boolean;
}

export const FilterChip = styled.button<FilterProps>`
  border: none;
  padding: 8px 24px;
  width: fit-content;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.button.primary : theme.colors.background.secondary};
  color: ${({ theme, active }) =>
    active ? '#FFFFFF' : theme.colors.text.lightBlack};
  transition: background-color 0.05s ease;

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.colors.icon.secondary : theme.colors.separator.lightColor};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const FiltersWrapper = styled.div<{ type: 'chip' | 'tab' }>`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  gap: ${({ type }) => type === 'chip' && '16px'};
  margin-top: ${({ type }) => type === 'chip' && convertPXToVH(24)};
  padding-bottom: ${convertPXToVH(24)};
  width: 100%;
  flex: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ContentWrapper = styled.div<{
  isHistoricChatMobile: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;
  padding: 0px 40px 40px;

  ${mediaQuery(breakpoint)} {
    grid-template-columns: 1fr;
  }
`;

export const ConversationList = styled.div`
  overflow-y: auto;
  height: 100%;
  padding-right: ${convertPXToVW(24)};

  ${mediaQuery(breakpoint)} {
    padding-right: 0px;
  }
`;

export const ConversationView = styled.div<{
  paddingRight?: number;
  paddingLeft?: number;
}>`
  overflow-y: auto;
  height: 100%;
  padding-right: ${({ paddingRight }) => convertPXToVW(paddingRight)};
  padding-left: ${({ paddingLeft }) => convertPXToVW(paddingLeft)};
  padding-bottom: ${convertPXToVH(16)};
`;

interface FlexProps {
  align?: string;
  justify?: string;
  direction?: string;
  margin?: string;
  width?: string | number;
  maxWidth?: string | number;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${({ align = 'center' }) => align};
  justify-content: ${({ justify = 'space-between' }) => justify};
  flex-direction: ${({ direction = 'row' }) => direction};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const IconBtn = styled(IconButton)`
  background-color: #dbe3ec;
  border-radius: 50%;
  svg {
    color: #212529;
    box-sizing: content-box;
    pointer-events: none;
  }
  :hover {
    background-color: #c5d2e0;
  }
`;

export const IconX = styled(Icon.X)`
  padding: 10px;
`;

export const FilterTab = styled.button<FilterProps>`
  border: none;
  background: transparent;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 2px solid
    ${({ theme, active }) =>
      active ? theme.colors.text.primary : theme.colors.slide.dots.inactive};
  padding: 18px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${({ theme, active }) =>
    active ? theme.colors.text.primary : theme.colors.text.lightBlack};
  transition: all 0.05s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.background.light};
  }
`;

export const EmptyIllustration = styled(SvgEmptyIllustration)`
  width: 100%;
  padding: 20px 0px;
`;
