import { mediaQuery } from '@sofia/ui';
import styled from '@emotion/styled';

export const Flex = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 16px;
  flex-direction: column;
  > :not(:last-of-type) {
    margin-bottom: 16px;
  }
  ${mediaQuery('desktop')} {
    flex-direction: row;
    align-items: center;
    > :not(:last-of-type) {
      margin-bottom: 0px;
    }
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  ${({ hasThumbnail }: { hasThumbnail: boolean }) =>
    hasThumbnail
      ? `
  margin-left: 0px;
  margin-top: 200px;
  ${mediaQuery('desktop')}{
    margin-top: 0;
    margin-left: 300px;
  }`
      : ''}
`;

export const CardContainer = styled.div(
  ({ isCoursePage, isLive }: { isLive: boolean; isCoursePage: boolean }) => `
  position: relative;
  > div {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 1;
    min-height: auto;
    padding: 24px;
    justify-content: flex-start;
    ${mediaQuery('desktop')} {
      padding: 24px 32px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    ${isLive ? '' : 'cursor: default; pointer-events: none;'}
  }

  margin-bottom: 40px;
  ${isCoursePage ? 'margin-top: 40px;' : ''}
  ${mediaQuery('desktop')} {
    margin-bottom: 56px;
    ${isCoursePage ? 'margin-top: 56px;' : ''}
  }
`
);
