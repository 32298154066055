import React from 'react';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { Breadcrumbs, Header, Typography } from '@sofia/ui';
import { routePath } from '../../../routes';
import { useGetState } from '../../../state';
import { LayoutContent } from '../../layout';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '@ampli/hooks';
import MandatoryInternshipGuideContent from './content/mandatory-internship-guide-content';

const MandatoryInternshipGuide = () => {
  const theme = useTheme();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const { subjectEnrollmentId } = useParams();
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const breadcrumbsComponent = (
    <Breadcrumbs
      secondColor={theme.colors.breadCrumbs.secondary}
      firstColor={theme.colors.breadCrumbs.primary}
      fill={theme.colors.breadCrumbs.primary}
      stroke={theme.colors.breadCrumbs.primary}
      shadow={false}
      backClick={() => history.goBack()}
    >
      <Link
        to={generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })}
      >
        <span>Início</span>
      </Link>
      <Link
        to={generatePath(routePath.mandatoryInternshipPage, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
          subjectEnrollmentId: subjectEnrollmentId,
        })}
      >
        <span>Estágio obrigatório</span>
      </Link>
      <Typography as="span" weight={700}>
        {isDesktop ? 'Ainda não tenho um estágio' : 'Orientações'}
      </Typography>
    </Breadcrumbs>
  );

  return (
    <>
      <Header
        titleColor={theme.colors.text.secondary}
        descriptionColor={theme.colors.text.light}
        background={theme.colors.banner.primary}
        breadCrumbs={breadcrumbsComponent}
      />
      <LayoutContent>
        <MandatoryInternshipGuideContent />
      </LayoutContent>
    </>
  );
};

MandatoryInternshipGuide.propTypes = {};

export default MandatoryInternshipGuide;
