import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { string } from 'prop-types';
import {
  TimerContent,
  TimerComponentContent,
  TimeText,
  TimeTitle,
  TimeContent,
  StudiesButton,
  ClockIcon,
  TimeSeparator,
  TimeDescriptionText,
} from './styles';
import { Icon, getColor } from '@ampli/ui';
import { useRegressiveCounter, useBreakpoint } from '@ampli/hooks';
import { locationPathname } from '../../constants';

const FreeTrialRegressiveCounter = ({
  endDate,
  freeTrialSituation,
  selectedCourseEnrollmentType,
  selectedCourseVersionId,
}) => {
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';
  const location = useLocation();

  const defaultComponentColors = {
    text: 'neutral-900',
    button: 'neutral-light-background',
    background: 'neutral-light-background',
    defaultButton: true,
  };

  const [currentComponentsColor, setCurrentComponentsColor] = useState(
    defaultComponentColors
  );

  const transformDaysLeft = (daysToAdd) => {
    if (!daysToAdd) return;
    const currentDate = new Date();
    return currentDate.setDate(currentDate.getDate() + daysToAdd);
  };

  const totalRemainingTimeArray = useRegressiveCounter(
    transformDaysLeft(endDate)
  );
  const [isClassOrHomePage, setIsClassOrHomePage] = useState(false);

  const alertUseChangingColor = React.useCallback((lastDaysComponentColors) => {
    const alertComponentColors = {
      text: 'danger-600',
      button: 'danger-800',
      background: 'free-trial-background',
    };

    setCurrentComponentsColor(alertComponentColors);
    setInterval(() => {
      setCurrentComponentsColor(lastDaysComponentColors);
      sessionStorage.setItem('freeTrialRegressiveAlert', true);
    }, 3000);
  }, []);

  useEffect(() => {
    const lastDaysComponentColors = {
      text: 'neutral-900',
      button: 'warning-800',
      background: 'free-trial-background',
    };

    if (
      freeTrialSituation === 'HALF_USED' ||
      (sessionStorage.getItem('freeTrialRegressiveAlert') &&
        freeTrialSituation === 'EXPIRING')
    )
      return setCurrentComponentsColor(lastDaysComponentColors);

    if (freeTrialSituation === 'EXPIRING' || freeTrialSituation === 'EXPIRED')
      alertUseChangingColor(lastDaysComponentColors);
  }, [alertUseChangingColor, freeTrialSituation]);

  const formatTime = (time) => {
    if (time.toString().length === 1) return `0${time}`;
    return time;
  };

  const goToPayment = () => {
    history.replace(
      `/${selectedCourseEnrollmentType}/${selectedCourseVersionId}/pagamento?utm_source=ava-sofia&utm_medium=aviso-conteudo`
    );
  };

  useEffect(() => {
    setIsClassOrHomePage(
      locationPathname().includes('enrollment') ||
        locationPathname().includes('meus-cursos')
    );
  }, [location]);

  const lastItem = totalRemainingTimeArray.at(-1);

  if (totalRemainingTimeArray.length < 1 || isClassOrHomePage) return null;
  return (
    <TimerComponentContent
      currentComponentsColor={currentComponentsColor.background}
    >
      <TimeContent>
        <ClockIcon
          secondaryColor={getColor(currentComponentsColor.text)}
          primaryColor={getColor(currentComponentsColor.text)}
        />
        {totalRemainingTimeArray.map((totalTime, index) => (
          <React.Fragment key={index}>
            <TimerContent>
              <TimeText currentComponentColor={currentComponentsColor.text}>
                {formatTime(totalTime.time)}
              </TimeText>
              <TimeDescriptionText
                currentComponentColor={currentComponentsColor.text}
              >
                {totalTime.title}
              </TimeDescriptionText>
            </TimerContent>
            {lastItem !== totalTime ? (
              <TimeSeparator
                currentComponentsColor={currentComponentsColor.text}
              >
                :
              </TimeSeparator>
            ) : null}
          </React.Fragment>
        ))}
      </TimeContent>
      <TimeTitle currentComponentsColor={currentComponentsColor.text}>
        {isMobile
          ? 'Desbloqueie sua jornada conosco!'
          : 'Desbloqueie os benefícios da sua jornada conosco!'}
      </TimeTitle>
      <StudiesButton
        currentComponentColor={currentComponentsColor.button}
        defaultButton={currentComponentsColor.defaultButton}
        onClick={goToPayment}
      >
        Desbloquear conteúdo <Icon.Lock />
      </StudiesButton>
    </TimerComponentContent>
  );
};

FreeTrialRegressiveCounter.propTypes = {
  endDate: string,
  freeTrialSituation: string,
  selectedCourseEnrollmentType: string,
  selectedCourseVersionId: string,
};

export default FreeTrialRegressiveCounter;
