import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const MessageActionsWrapper = styled.div<{ reverse?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${mediaQuery('desktop')} {
    margin: 32px;
  }

  > button {
    height: 56px;
    padding-top: unset;
    padding-bottom: unset;
    padding-right: 16px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    svg {
      margin: 0 10px 0 0;
    }
  }
  ${({ reverse }) =>
    reverse
      ? `flex-direction: row-reverse;
    > button:first-of-type {
      margin-left: 16px;
    }`
      : `
      > button:first-of-type {
        margin-right: 16px;
      }
    `}
`;
