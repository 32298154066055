import { lazy } from 'react';

export const PresentialPracticalActivitiesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Practical Activities presential" */ '../pages/presential'
    )
);

export const OnlinePracticalActivitiesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Practical Activities online" */ '../pages/online'
    )
);

export const ActivityPage = lazy(
  () => import(/* webpackChunkName: "Activity" */ '../pages/activity')
);

const PracticalActivitiesModule = lazy(
  () => import(/* webpackChunkName: "Practical Activities module" */ './module')
);

export default PracticalActivitiesModule;
