import React from 'react';

export default function useClickOutsideChatCopilot(
  ref,
  setIsCopilotOpen,
  questionContainerRef,
  answerContainerRef
) {
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !questionContainerRef.current.contains(event.target) &&
        !answerContainerRef.current.contains(event.target)
      ) {
        setIsCopilotOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [answerContainerRef, questionContainerRef, ref, setIsCopilotOpen]);
}
