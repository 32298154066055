import { convertPXToVW } from '@sofia/ui';
import { mediaQuery } from '@ampli/ui';

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 0 8px;
  margin-top: 24px;
  ${mediaQuery('desktop')} {
    margin-top: 64px;
    padding: 0;
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 40px;

  ${mediaQuery('desktop')} {
    margin-bottom: 56px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const SkeletonHeaderWrapper = styled.div`
  width: ${convertPXToVW(170)};
`;

export const SkeletonInfoWrapper = styled.div`
  width: ${convertPXToVW(280)};
  margin-bottom: 16px;
`;
