import styled from '@emotion/styled';
import { SvgFileLandscapeAlt } from '@sofia/ui';

export const PrefixIcon = styled(SvgFileLandscapeAlt)`
  margin-right: 18px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
`;
