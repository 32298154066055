import React from 'react';
import * as Styled from './navigation.styles';
import { TNavLinkItemProps } from './navigation.types';

const NavLinkItem: React.FC<TNavLinkItemProps> = ({
  idText,
  dataTestidText,
  icon,
  text,
  ...rest
}: TNavLinkItemProps) => {
  return (
    <Styled.NavLinkStyled {...rest}>
      <Styled.WrapperIcon>{icon}</Styled.WrapperIcon>
      <Styled.NavItemLabel data-testid={dataTestidText} id={idText}>
        {text}
      </Styled.NavItemLabel>
    </Styled.NavLinkStyled>
  );
};

export default NavLinkItem;
