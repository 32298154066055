import { useLazyQuery, useMutation, useQuery } from '@ampli/services';
import { StatusBillingDaySelectDayMock } from './mocks';
import {
  ACCEPT_CONTRACT_CHANGE_BILLING_DAY,
  CANCEL_BILLING_DAY_REQUEST,
  CLEAR_SELECTED_DAY,
  CONFIRM_CHANGE_BILLING_DAY,
  GET_CURRENT_STATUS_CHANGE_BILLING_DAY,
  SELECT_DAY_CHANGE_BILLING_DAY,
} from './queries';
import {
  AcceptContractChangeBillingDayHook,
  CancelBillingDayRequestHook,
  ClearSelectedDayHook,
  ConfirmChangeBillingDayHook,
  GetCurrentStatusChangeBillingDayHook,
  LazyGetCurrentStatusChangeBillingDayHook,
  SelectDayChangeBillingDayHook,
} from './types';

export const useLazyGetCurrentStatusChangeBillingDay: LazyGetCurrentStatusChangeBillingDayHook =
  ({ enableMock, options }) => {
    const [query, { data, loading, ...rest }] = useLazyQuery(
      GET_CURRENT_STATUS_CHANGE_BILLING_DAY,
      {
        ...options,
        context: {
          autoHandle: true,
        },
        fetchPolicy: 'no-cache',
      }
    );
    const CurrentStatusChangeBillingDay = loading || !data ? null : data.data;
    return [
      query,
      {
        data: enableMock
          ? StatusBillingDaySelectDayMock
          : CurrentStatusChangeBillingDay,
        loading,
        ...rest,
      },
    ];
  };

export const useGetCurrentStatusChangeBillingDay: GetCurrentStatusChangeBillingDayHook =
  (courseEnrollmentId, options, enableMock) => {
    const { data, loading, error, refetch } = useQuery(
      GET_CURRENT_STATUS_CHANGE_BILLING_DAY,
      {
        ...options,
        variables: {
          courseEnrollmentId,
        },
        context: {
          autoHandle: true,
        },
        fetchPolicy: 'no-cache',
      }
    );

    const CurrentStatusChangeBillingDay = loading || !data ? null : data.data;

    return {
      refetch,
      data: enableMock
        ? StatusBillingDaySelectDayMock
        : CurrentStatusChangeBillingDay,
      loading,
      error,
    };
  };

export const useMutationSelectDayChangeBillingDay: SelectDayChangeBillingDayHook =
  () => {
    const [mutate, { loading, error, data }] = useMutation(
      SELECT_DAY_CHANGE_BILLING_DAY
    );

    const SelectDayChangeBillingDay = loading || !data ? {} : data;

    return [mutate, { loading, error, data: SelectDayChangeBillingDay }];
  };

export const useMutationAcceptContractChangeBillingDay: AcceptContractChangeBillingDayHook =
  () => {
    const [mutate, { loading, error, data }] = useMutation(
      ACCEPT_CONTRACT_CHANGE_BILLING_DAY
    );

    const AcceptContractChangeBillingDay = loading || !data ? {} : data;

    return [mutate, { loading, error, data: AcceptContractChangeBillingDay }];
  };

export const useMutationCancelBillingDayRequest: CancelBillingDayRequestHook =
  () => {
    const [mutate, { loading, error, data }] = useMutation(
      CANCEL_BILLING_DAY_REQUEST
    );

    const CancelBillingDayRequest = loading || !data ? {} : data;

    return [mutate, { loading, error, data: CancelBillingDayRequest }];
  };

export const useMutationConfirmChangeBillingDay: ConfirmChangeBillingDayHook =
  () => {
    const [mutate, { loading, error, data }] = useMutation(
      CONFIRM_CHANGE_BILLING_DAY
    );

    const ConfirmChangeBillingDay = loading || !data ? {} : data;

    return [mutate, { loading, error, data: ConfirmChangeBillingDay }];
  };

export const useMutationClearSelectedDay: ClearSelectedDayHook = () => {
  const [mutate, { loading, error, data }] = useMutation(CLEAR_SELECTED_DAY);

  const ConfirmChangeBillingDay = loading || !data ? {} : data;

  return [mutate, { loading, error, data: ConfirmChangeBillingDay }];
};
