import { gql } from 'graphql.macro';

export const LIST_ARTICLES = gql`
  query ListWisdomArticles($id: ID!) {
    data: articlesByEnrollment(id: $id) {
      id
      title
    }
  }
`;

export const SEARCH_ARTICLES = gql`
  query FilterArticles($criteria: String!) {
    data: filterArticles(criteria: $criteria) {
      id
      title
    }
  }
`;

export const GET_ONE_ARTICLE = gql`
  query GetWisdomArticle($id: ID!) {
    data: getOneArticle(id: $id) {
      id
      title
      description
      content
    }
  }
`;

export const RATE_ARTICLE = gql`
  mutation RateArticle($rating: RateArticleInput!) {
    data: rateArticle(rating: $rating)
  }
`;

export const RATE_ARTICLE_COMMENT = gql`
  mutation RateArticleComment($rating: FeedbackInput) {
    data: rateArticleComment(feedback: $rating)
  }
`;

export const GET_AFFILIATE_ID = gql`
  query GetCourseEnrollment($courseEnrollmentId: ID!) {
    data: getCourseEnrollment(courseEnrollmentId: $courseEnrollmentId) {
      affiliateId
    }
  }
`;

export const GET_AFFILIATE = gql`
  query GetAffiliate($affiliateId: ID!) {
    data: getAffiliate(affiliateId: $affiliateId) {
      id
      name
      brand
      source
      ownerName
      ownerPhone
      status
      type
      address {
        street
        number
        postalCode
        complement
        neighbourhood
        city {
          name
        }
        state {
          acronym
        }
      }
    }
  }
`;
