import React from 'react';
import * as Styles from './after-end.styles';
import SvgTourIllustrationImage from './assets/tour-illustration-image';
import { useTheme } from '@emotion/react';

const Welcome: React.FC = () => {
  const theme = useTheme();
  return (
    <>
      <Styles.HeaderImage>
        <SvgTourIllustrationImage />
        <Styles.Title as="h3" size={20} weight={700}>
          Temos um conteúdo para você iniciar sua jornada!
        </Styles.Title>
      </Styles.HeaderImage>
      <Styles.Subtitle
        as="p"
        size={16}
        weight={400}
        color={theme.colors.text.black}
      >
        Além do tour pelo AVA, criamos um material especial para você conhecer
        melhor a instituição. Aproveite!{' '}
        <span role="img" aria-label="Rosto sorrindo">
          😁
        </span>
      </Styles.Subtitle>
    </>
  );
};

export default Welcome;
