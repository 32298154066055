import { gql } from 'graphql.macro';

export const PAY_INVOICE_BY_PIX = gql`
  mutation PayInvoiceByPix($courseEnrollmentId: String!, $invoiceId: String!) {
    data: payInvoiceByPix(
      courseEnrollmentId: $courseEnrollmentId
      invoiceId: $invoiceId
    ) {
      code
      pixImage
      receivedPayment
    }
  }
`;
