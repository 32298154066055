import React from 'react';
import { getColor } from '@ampli/ui';

function SvgAffiliate(props) {
  const brandLight = getColor('brand-light');
  const brandLighter = getColor('brand-lighter');

  return (
    <svg viewBox="0 0 104 72" width={104} height={72} fill="none" {...props}>
      <path
        d="M.234 72L0 71.944C2.612 59.662 9.178 48.51 18.486 40.54c7.038-6.016 15.408-10.04 24.322-11.692a50.592 50.592 0 0126.634 2.248c8.554 3.126 16.214 8.502 22.26 15.623 6.046 7.12 10.278 15.75 12.298 25.076l-.234.057c-5.53-25.67-26.81-43.598-51.75-43.598C27.403 28.253 5.626 46.651.234 72zM16.12 15.494l-3.12-1.8L30.467 0h44.291v12.345L46.063 26.293 16.119 15.494z"
        fill={brandLight}
      />
      <path
        d="M82.81 6.334V.226h-5.877V1.72L74.74 0 60.401 11.291l-5.61 5.156-8.7 46.048L59.21 72h30.491V11.743l-6.892-5.41z"
        fill={brandLighter}
      />
      <path d="M60.424 13.758H13V72h47.424V13.758z" fill="#fff" />
      <path d="M40.139 52.267h-8.72v19.67h8.72v-19.67z" fill={brandLight} />
      <path
        d="M22.076 57.887v-3.122h-3.738v3.122h3.738zm-.312 2.497H18.34v3.123h3.737v-3.123h-.312zm6.54-5.619h-3.738v3.122h3.738v-3.122zm0 5.619h-3.738v3.123h3.738v-3.123zm19.309-2.497v-3.122h-3.737v3.122h3.737zm-.311 2.497h-3.426v3.123h3.737v-3.123h-.311zm6.541-5.619h-3.737v3.122h3.737v-3.122zm0 5.619h-3.737v3.123h3.737v-3.123z"
        fill="#D6E1EB"
      />
      <path
        d="M73.776 27.966v-3.122h-3.737v3.122h3.736zm-.312 2.498H70.04v3.122h3.737v-3.122h-.312zm6.539-5.62h-3.737v3.122h3.737v-3.122zm0 5.62h-3.737v3.122h3.737v-3.122z"
        fill="#fff"
      />
      <path
        d="M73.776 45.45v-3.122h-3.737v3.122h3.736zm-.312 2.498H70.04v3.122h3.737v-3.122h-.312zm6.539-5.62h-3.737v3.122h3.737v-3.122zm0 5.62h-3.737v3.122h3.737v-3.122z"
        fill="#3F3D56"
      />
      <path
        d="M54.466 25.417H18.339v7.493h36.127v-7.493zm0 14.986H18.339v7.493h36.127v-7.493z"
        fill={brandLight}
      />
      <path
        opacity={0.5}
        d="M71.821 42.467H61.508v8.256H71.82v-8.256z"
        fill="#6C63FF"
      />
      <path
        opacity={0.2}
        d="M82.248 42.467H71.934v8.256h10.314v-8.256z"
        fill="#6C63FF"
      />
      <path
        d="M73.665 71.676c10.621 0 19.231-8.627 19.231-19.27 0-10.641-8.61-19.268-19.231-19.268-10.622 0-19.232 8.627-19.232 19.269 0 10.642 8.61 19.269 19.232 19.269z"
        fill="url(#affiliate_svg__paint0_linear)"
      />
      <path
        d="M73.664 71.156c10.335 0 18.713-8.394 18.713-18.749 0-10.354-8.378-18.749-18.713-18.749s-18.713 8.395-18.713 18.75c0 10.354 8.378 18.748 18.713 18.748z"
        fill="#FFC633"
      />
      <path
        d="M73.663 67.64c8.397 0 15.204-6.82 15.204-15.233S82.06 37.174 73.663 37.174 58.46 43.994 58.46 52.407 65.266 67.64 73.662 67.64z"
        fill="#fff"
      />
      <path
        d="M73.664 53.318a.91.91 0 10-.002-1.82.91.91 0 00.002 1.82z"
        fill="url(#affiliate_svg__paint1_linear)"
      />
      <path
        d="M74.007 52.52l-.56-.19 5.044-11.829.56.264-5.044 11.754z"
        fill="url(#affiliate_svg__paint2_linear)"
      />
      <path
        d="M78.551 40.61l-4.938 11.718.36.152 4.938-11.718-.36-.152z"
        fill="#2196F3"
      />
      <path
        d="M76.81 58.697l-3.251-6.065.448-.225 3.363 5.953-.56.337z"
        fill="url(#affiliate_svg__paint3_linear)"
      />
      <path
        d="M73.963 52.448l-.342.187 3.248 5.99.343-.187-3.249-5.99z"
        fill="#2196F3"
      />
      <path
        d="M87.828 53.189a.39.39 0 100-.781.39.39 0 000 .78z"
        fill="#6C63FF"
      />
      <path
        d="M61.901 45.643a.506.506 0 10-.877-.508.506.506 0 10.877.508z"
        fill="url(#affiliate_svg__paint4_linear)"
      />
      <path
        d="M61.846 60.28a.508.508 0 00-.506-.88.508.508 0 00.506.88z"
        fill="url(#affiliate_svg__paint5_linear)"
      />
      <path
        d="M59.5 53.305a.507.507 0 100-1.014.507.507 0 000 1.014z"
        fill="url(#affiliate_svg__paint6_linear)"
      />
      <path
        d="M66.886 40.62a.508.508 0 00-.507-.878.508.508 0 00.507.879z"
        fill="url(#affiliate_svg__paint7_linear)"
      />
      <path
        d="M73.904 38.798a.508.508 0 00-.506-.879.508.508 0 00.506.88z"
        fill="url(#affiliate_svg__paint8_linear)"
      />
      <path
        d="M80.922 40.621a.508.508 0 00-.507-.879.508.508 0 00.507.88z"
        fill="url(#affiliate_svg__paint9_linear)"
      />
      <path
        d="M86.119 45.83a.508.508 0 00-.507-.88.508.508 0 00.507.88z"
        fill="url(#affiliate_svg__paint10_linear)"
      />
      <path
        d="M85.991 60.28a.508.508 0 00-.506-.878.508.508 0 00.506.879z"
        fill="url(#affiliate_svg__paint11_linear)"
      />
      <path
        d="M80.661 65.358a.508.508 0 00-.506-.879.508.508 0 00.506.88z"
        fill="url(#affiliate_svg__paint12_linear)"
      />
      <path
        d="M73.904 66.922a.508.508 0 00-.507-.88.508.508 0 00.507.88z"
        fill="url(#affiliate_svg__paint13_linear)"
      />
      <path
        d="M67.147 65.359a.508.508 0 00-.506-.88.508.508 0 00.506.88z"
        fill="url(#affiliate_svg__paint14_linear)"
      />
      <path
        d="M73.664 38.736a.39.39 0 100-.78.39.39 0 000 .78zm7.356 1.688a.39.39 0 10-.533.143.39.39 0 00.533-.143zm4.992 5.291a.391.391 0 00-.39-.677.391.391 0 00.39.677zm.063 14.321a.39.39 0 10-.533.143.39.39 0 00.533-.143zm-5.47 5.194a.391.391 0 00-.39-.677.391.391 0 00.39.677zM66.842 40.569a.391.391 0 00-.39-.677.391.391 0 00.39.677zm-4.994 5.003a.391.391 0 00-.675-.39.39.39 0 10.676.39zm26.222 7.679a.508.508 0 00-.507-.879.508.508 0 00.507.88zm-26.282 6.928a.391.391 0 00-.39-.677.391.391 0 00.39.677zm5.469 4.909a.39.39 0 10-.675-.39.39.39 0 10.675.39zM59.5 53.189a.39.39 0 100-.781.39.39 0 000 .78zm14.164 13.669a.39.39 0 100-.78.39.39 0 000 .78z"
        fill="#69F0AE"
      />
      <path
        opacity={0.2}
        d="M59.24 53.84a15.254 15.254 0 013.15-9.281 15.204 15.204 0 018.142-5.437 15.179 15.179 0 019.761.658 15.215 15.215 0 017.342 6.48 15.235 15.235 0 00-3.52-5.047 15.203 15.203 0 00-11.305-4.14 15.182 15.182 0 00-10.768 5.39 15.245 15.245 0 00-3.483 11.544 15.25 15.25 0 001.915 5.85 15.205 15.205 0 01-1.233-6.018z"
        fill="#6C63FF"
      />
      <path
        d="M73.665 53.059a.65.65 0 10-.002-1.302.65.65 0 00.002 1.302z"
        fill="#69F0AE"
      />
      <defs>
        <linearGradient
          id="affiliate_svg__paint0_linear"
          x1={6652.87}
          y1={25470}
          x2={6652.87}
          y2={12247.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint1_linear"
          x1={384.609}
          y1={956.001}
          x2={384.609}
          y2={926.44}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint2_linear"
          x1={1164.3}
          y1={5923.53}
          x2={1164.3}
          y2={4637.56}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint3_linear"
          x1={788.655}
          y1={3477.32}
          x2={788.655}
          y2={3125.08}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint4_linear"
          x1={-356.298}
          y1={849.41}
          x2={-348.367}
          y2={844.84}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint5_linear"
          x1={310.393}
          y1={748.752}
          x2={310.393}
          y2={739.586}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint6_linear"
          x1={-24.854}
          y1={866.371}
          x2={-20.261}
          y2={858.431}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint7_linear"
          x1={-637.678}
          y1={653.432}
          x2={-628.519}
          y2={653.432}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint8_linear"
          x1={-572.443}
          y1={643.999}
          x2={-563.284}
          y2={643.999}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint9_linear"
          x1={-510.837}
          y1={667.497}
          x2={-501.689}
          y2={667.497}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint10_linear"
          x1={-469.054}
          y1={719.778}
          x2={-459.896}
          y2={719.778}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint11_linear"
          x1={-484.649}
          y1={850.242}
          x2={-475.491}
          y2={850.242}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint12_linear"
          x1={-537.859}
          y1={890.792}
          x2={-528.71}
          y2={890.792}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint13_linear"
          x1={-600.504}
          y1={898.152}
          x2={-591.345}
          y2={898.152}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="affiliate_svg__paint14_linear"
          x1={-660.008}
          y1={877.263}
          x2={-650.859}
          y2={877.263}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgAffiliate;
