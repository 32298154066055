import React from 'react';
import { Typography } from '@sofia/ui';
import { ITitleProps } from './settings-card.types';
import { useBreakpoint } from '../../hooks';

const Title: React.FC<ITitleProps> = ({ title, ...rest }: ITitleProps) => {
  const { isDesktop } = useBreakpoint();
  const size = isDesktop ? 20 : 16;

  return (
    <Typography margin="0 0 4px 0" weight="700" size={size} {...rest}>
      {title}
    </Typography>
  );
};

export default Title;
