import { lazy } from 'react';

export const AssignmentOnboardingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Assignment Onboarding" */ '../pages/onboarding/assignment-onboarding-page'
    )
);

export const AssignmentCorrectionFeedbacksPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Assignment Correction Feedbacks" */ '../pages/feedback'
    )
);

const AssignmentModule = lazy(
  () => import(/* webpackChunkName: "Assignment module" */ './module')
);

export default AssignmentModule;
