import React from 'react';

import { SvgBookOpen, SvgFileBookmark, Typography } from '@sofia/ui';
import { useSectionPageData } from '../../../../../../../../section/pages/learning-object/hooks';
import { Skeleton } from '../../../../../../../../../components';
import { useTheme } from '@emotion/react';

import {
  SubjectContainer,
  StyledSeparator,
  UnitContainer,
  Container,
  Unit,
} from './text-step-header.styles';

const TextStepHeader: React.FC = (): React.ReactElement => {
  const theme = useTheme();

  const { subjectEnrollment, learningUnit, section, loading } =
    useSectionPageData();

  console.log(loading);

  return (
    <React.Fragment>
      <Container>
        <SubjectContainer>
          <SvgFileBookmark />

          <Typography color={theme.colors.text.lightBlack}>
            {subjectEnrollment?.subject?.name}
          </Typography>
        </SubjectContainer>

        <StyledSeparator />

        <UnitContainer>
          {loading ? (
            <Skeleton height={30} borderRadius={6} />
          ) : (
            <React.Fragment>
              <div>
                <SvgBookOpen width={16} height={16} />
              </div>

              <Typography color={theme.colors.text.lightBlack}>
                Aula {section?.order} - {section?.title}
              </Typography>

              <Unit>
                <Typography color={theme.colors.text.lightBlack}>
                  Unidade {learningUnit?.order}
                </Typography>
              </Unit>
            </React.Fragment>
          )}
        </UnitContainer>
      </Container>

      <StyledSeparator />
    </React.Fragment>
  );
};

export default TextStepHeader;
