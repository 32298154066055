import styled from '@emotion/styled';

export const Wrapper = styled.div`
  > :not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const BottomSheetHeading = styled.div`
  margin-top: 24px;
`;

export const BottomSheetFooter = styled.div`
  margin-bottom: 24px;

  & > button {
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px;
`;
