import { useLazyQuery, useQuery, useMutation } from '@ampli/services';
import {
  GET_INVOICE,
  GET_INVOICE_BOLETO_PDF,
  GET_INVOICE_DOCUMENT_URL,
  GET_INVOICE_HOME_MODEL,
  PAY_INVOICE_BY_BOLETO,
} from './queries';
import {
  GetInvoiceHomeModelHook,
  LazyGetInvoiceBoletoPdfHook,
  LazyGetInvoiceDocumentUrlHook,
  LazyGetInvoiceHook,
  MutationPayInvoiceByBoletoHook,
} from './types';
import { getInvoiceHomeModel } from '../../../shared/mocks';

export const useGetInvoiceHomeModel: GetInvoiceHomeModelHook = ({
  courseEnrollmentId,
  options = {},
  enableMock = false,
}) => {
  const { data, loading, error, refetch } = useQuery(GET_INVOICE_HOME_MODEL, {
    ...options,
    variables: {
      courseEnrollmentId,
    },
    fetchPolicy: 'no-cache',
  });

  const invoiceHomeModel = loading || !data ? {} : data.getInvoiceHomeModel;

  return {
    data: enableMock ? getInvoiceHomeModel.data : invoiceHomeModel,
    error,
    loading,
    refetch,
  };
};

export const useLazyGetInvoiceBoletoPdf: LazyGetInvoiceBoletoPdfHook = ({
  options = {},
}) => {
  const [query, { data, loading, error, ...rest }] = useLazyQuery(
    GET_INVOICE_BOLETO_PDF,
    {
      ...options,
      fetchPolicy: 'no-cache',
    }
  );

  const invoiceBoletoPdf = loading || !data ? {} : data.data;

  return [
    query,
    {
      data: invoiceBoletoPdf,
      loading,
      error,
      ...rest,
    },
  ];
};

export const useMutationPayInvoiceByBoleto: MutationPayInvoiceByBoletoHook = ({
  options,
}) => {
  const [mutate, metadata] = useMutation(PAY_INVOICE_BY_BOLETO, {
    ...options,
    context: {
      autoHandle: true,
    },
  });

  return [mutate, metadata];
};

export const useLazyGetInvoice: LazyGetInvoiceHook = ({ options = {} }) => {
  const [query, { data, loading, error }] = useLazyQuery(GET_INVOICE, {
    ...options,

    context: {
      autoHandle: true,
    },
    fetchPolicy: 'no-cache',
  });

  const getInvoice = loading || !data ? null : data.data;

  return [query, { data: getInvoice, loading, error }];
};

export const useLazyGetInvoiceDocumentUrl: LazyGetInvoiceDocumentUrlHook = ({
  options = {},
}) => {
  const [query, { data, loading, error }] = useLazyQuery(
    GET_INVOICE_DOCUMENT_URL,
    {
      ...options,
      context: {
        autoHandle: true,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const invoiceDocumentUrl = loading || !data ? null : data.data;

  return [query, { data: invoiceDocumentUrl, loading, error }];
};
