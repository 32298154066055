import React from 'react';

import { SvgCommentExclamation, Typography, convertPXToVH } from '@sofia/ui';
import { EmptyStateWrapper } from './empty-state.styles';
import { useTheme } from '@emotion/react';

const EmptyState: React.FC = () => {
  const theme = useTheme();

  return (
    <EmptyStateWrapper>
      <SvgCommentExclamation fill={theme.colors.icon.primary} />

      <Typography
        size={24}
        weight={500}
        color={theme.colors.text.lightBlack}
        margin={`${convertPXToVH(16)} 0px 0px`}
        textAlign="center"
      >
        Nenhuma notificação encontrada.
      </Typography>
    </EmptyStateWrapper>
  );
};

export default EmptyState;
