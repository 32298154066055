import { createContentEvaluationSchema } from './schemas';
import {
  TCreateContentEvaluationHook,
  TGetLastContentEvaluationByContentAndStudentHook,
} from './types';
import {
  useCreateContentEvaluationMutation,
  useGetLastContentEvaluationByContentAndStudentLazyQuery,
} from './hooks.gql';
import { createDefaultContentEvaluation } from '../../pages/learning-object/utils';
import { CreateDefaultContentEvaluationProps } from '../../pages/learning-object/utils/create-default-content-evaluation';

export const useCreateContentEvaluation: TCreateContentEvaluationHook = (
  contentEvaluation,
  options = {}
) => {
  const [mutate, { data, loading, error }] = useCreateContentEvaluationMutation(
    {
      ...options,
      variables: {
        contentEvaluation,
      },
      context: {
        autoHandle: true,
      },
    }
  );
  return [
    mutate,
    { data: { data: data?.data || { id: '' } }, loading, error },
    createContentEvaluationSchema,
  ];
};

export const useGetLastContentEvaluationByContentAndStudent: TGetLastContentEvaluationByContentAndStudentHook =
  ({ studentId, learningObjectId, options = {} }) => {
    const [query, { loading, called, data, refetch }] =
      useGetLastContentEvaluationByContentAndStudentLazyQuery({
        ...options,
        variables: {
          studentId,
          learningObjectId,
        },
      });

    const contentEvaluation = loading || !data ? null : data.data;

    return {
      getData: (props: CreateDefaultContentEvaluationProps) => {
        if (!called) {
          query();
        }
        return contentEvaluation || createDefaultContentEvaluation(props);
      },
      contentEvaluationLoading: loading,
      reload: () => {
        if (refetch) {
          refetch();
        }
      },
    };
  };
