import React from 'react';

const useFloatingBoxOnView = (current: HTMLDivElement | null): void => {
  const observer = current
    ? new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting) {
            current.scrollIntoView({
              block: 'end',
              behavior: 'smooth',
            });
          }
        },
        {
          threshold: 1.0,
        }
      )
    : null;

  React.useEffect(() => {
    if (observer && current) {
      observer.observe(current);
      return () => observer.disconnect();
    }
  });
};

export default useFloatingBoxOnView;
