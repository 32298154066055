import React from 'react';

import { SimpleCard, SvgHeartBreak, SvgPadlock } from '@sofia/ui';
import { Grid, GridItem } from '@ampli/ui';
import { bool, func } from 'prop-types';
import { useTheme } from '@emotion/react';

import { ACTIONS } from '../../constants';
import { pushAnhangueraEvent } from '../../../../lib/ga';

export default function SuspensionCancellationCards({ isDesktop, onChoose }) {
  const theme = useTheme();

  const gridGap = isDesktop ? 24 : 16;
  const gridColumns = isDesktop ? 4 : 2;

  const handleCancelCourse = () => {
    onChoose(ACTIONS.cancellation);
    pushAnhangueraEvent('event', 'Sec_cancelamento_card');
  };

  const handleSuspensionCourse = () => {
    onChoose(ACTIONS.suspend);
    pushAnhangueraEvent('event', 'Sec_trancamento_card');
  };

  return (
    <Grid columns={gridColumns} columnGap={gridGap} rowGap={gridGap}>
      <div>
        <GridItem column="span 2" aria-label="Trancamento de curso">
          <SimpleCard
            id="suspension"
            onClick={handleSuspensionCourse}
            compact={!isDesktop}
            title={'Trancamento'}
            description={'Entre em contato com a gente'}
            icon={<SvgPadlock fill={theme.colors.icon.black} />}
            role="button"
            colorTitle={theme.colors.text.black}
            borderColor={theme.colors.card.border.primary}
            colorDescription={theme.colors.text.lightBlack}
            colorLinkButton={theme.colors.text.primary}
            grid="span 2"
            mobileGrid="span 3"
          />
        </GridItem>

        <GridItem column="span 2" aria-label="Cancelamento de curso">
          <SimpleCard
            id="cancellation"
            onClick={handleCancelCourse}
            compact={!isDesktop}
            title={'Cancelamento'}
            description={'Entre em contato com a gente'}
            icon={<SvgHeartBreak fill={theme.colors.icon.black} />}
            role="button"
            colorTitle={theme.colors.text.black}
            borderColor={theme.colors.card.border.primary}
            colorDescription={theme.colors.text.lightBlack}
            colorLinkButton={theme.colors.text.primary}
            grid="span 2"
            mobileGrid="span 3"
          />
        </GridItem>
      </div>
    </Grid>
  );
}

SuspensionCancellationCards.propTypes = {
  isDesktop: bool,
  onChoose: func,
};
