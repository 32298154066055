import { Box, mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';

export const ImageBanner = styled(Box)`
  padding: 24px;
  margin: 0;
  z-index: 1;

  ${mediaQuery('desktop')} {
    padding: 16px 24px;
  }
  background: ${({ background }) => background};
`;

export const LayoutWrapper = styled.div`
  > div {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
