/* eslint-disable prettier/prettier */
import { Typography, QuestionNavigation } from '@sofia/ui';

import { getAriaLabels, useInitProps } from './helper';
import { TakeEvaluationDesktopProps } from '../types';
import { firstLetterUpperCase } from '../../section';
import { ExamLayoutDesktop } from '../../../../../components';

import NavigationFooter from '../../navigation/navigation';
import Question from './question';
import React from 'react';

import * as Styled from './take-evaluation.styles';
import { useLocation } from 'react-router-dom';

interface IState {
  title: string;
}
import { ChatCopilot } from '../../../../../components/chat-copilot';
import { useMeContext } from '../../../contexts/me-context';
import { useCourseEnrollmentsContext } from '../../../contexts/course-enrollments-context';
import { ICopilotQuizPayload } from '../../../../../components/chat-copilot/types';

const TakeEvaluationDesktop = ({
  talkTutorFrame,
  evaluation,
  onHelpClick,
  onFontSizeClick,
  onFinishClick,
  onQuestionChoiceClick,
  questionChoices,
  stepsControl,
  finishLoading,
  timeLeftInMinutes,
  durationInMinutes,
  totalTime,
  changeFontSizeRef,
  fontSize,
  type,
}: TakeEvaluationDesktopProps): React.ReactElement => {
  const questionNavRef = React.useRef<
    | {
        selectQuestionId: (id: string) => void;
      }
    | undefined
  >();

  const {
    states: { getMe },
  } = useMeContext();
  const {
    states: { courseEnrollments },
  } = useCourseEnrollmentsContext();

  const [payload, setPayload] = React.useState<ICopilotQuizPayload>(
    {} as ICopilotQuizPayload
  );
  const [isQuizPageRendered, setIsQuizPageRendered] =
    React.useState<boolean>(false);

  const questionContainerRef = React.useRef<HTMLDivElement>(null);
  const answerContainerRef = React.useRef<HTMLDivElement>(null);

  const {
    navigationQuestions,
    numberOfQuestions,
    answeredQuestions,
    previousStep,
    isFirstStep,
    isLastStep,
    question,
    nextStep,
    toStep,
    theme,
    step,
  } = useInitProps(questionChoices, evaluation, stepsControl);

  const { nextButtonLabel, previousButtonLabel } = getAriaLabels(
    isLastStep,
    step,
    numberOfQuestions,
    durationInMinutes,
    timeLeftInMinutes,
    type
  );

  const [isQuizCopilotOpen, setIsQuizCopilotOpen] =
    React.useState<boolean>(false);

  const types = [
    {
      bgColor: theme.colors.button.primary,
      borderColor: theme.colors.button.primary,
      color: 'white',
      selectedBgColor: theme.colors.button.primary,
      selectedBorderColor: theme.colors.button.darkPrimary,
      status: 'RESPONDIDAS',
      text: 'Respondidas',
    },
    {
      borderColor: theme.colors.button.primary,
      selectedColor: '#212529',
      selectedBorderColor: theme.colors.button.primary,
      text: 'Sem respostas',
      status: 'DEFAULT',
    },
  ];

  const closeAndOpenCopilot = () => {
    setIsQuizCopilotOpen(true);
    setTimeout(() => setIsQuizCopilotOpen(false), 500);
  };

  const onPreviousClick = isFirstStep
    ? null
    : () => {
        previousStep();
        questionNavRef.current?.selectQuestionId(
          evaluation.questions[step - 1]?.id
        );
        closeAndOpenCopilot();
      };

  const onNextClick = () => {
    if (isLastStep) {
      onFinishClick();
    } else {
      nextStep();
      questionNavRef.current?.selectQuestionId(
        evaluation.questions[step + 1]?.id
      );
      closeAndOpenCopilot();
    }
  };

  const { title }: IState = (useLocation()?.state || {}) as IState;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [question]);

  React.useEffect(() => {
    setPayload({
      studentMoment: 'LEARNING_QUESTION',
      academicRegistration: courseEnrollments?.data.externalId,
      subjectName: evaluation?.subject.name,
      studentName: getMe.me.firstName,
      courseName: courseEnrollments?.data.course.name,
      exerciseQuestion: evaluation?.questions[step].baseText,
      questionDescription: evaluation?.questions[step].statement,
      initialInteraction: step + 1 === 1,
      classID: evaluation?.questions[step].classKrotonId,
      dictSugestions: '',
      modalityCourse: 'EAD',
      studentBirthDate: '01/01/1901',
    });
  }, [
    courseEnrollments?.data.course.name,
    courseEnrollments?.data.externalId,
    evaluation?.questions,
    evaluation?.subject.name,
    getMe.me.firstName,
    step,
  ]);

  React.useEffect(() => {
    if (window.location.pathname.includes('exercicio')) {
      setIsQuizPageRendered(true);
      return;
    }
    setIsQuizPageRendered(false);
  }, []);

  return (
    <ExamLayoutDesktop
      talkTutorFrame={talkTutorFrame}
      title={
        <Typography as="span" size={20} data-testid="take-evaluation-title">
          <b>{firstLetterUpperCase(title)}</b> {evaluation.subject.name}
        </Typography>
      }
      changeFontSizeRef={changeFontSizeRef}
      onFontSizeClick={onFontSizeClick}
      onHelpClick={onHelpClick}
    >
      <Styled.FontSizeHandler
        data-testid="take-evaluation-font-size-handler"
        fontSize={fontSize === 'large' ? 18 : null}
      >
        <Styled.HalfScreen ref={answerContainerRef}>
          <Question
            step={step}
            question={question}
            questionChoices={questionChoices}
            onHelpClick={onHelpClick}
            onChoiceClick={onQuestionChoiceClick.bind(null, step)}
          />
        </Styled.HalfScreen>
      </Styled.FontSizeHandler>
      <Styled.Floating ref={questionContainerRef}>
        <QuestionNavigation
          ref={questionNavRef}
          title={
            <span>
              Questões realizadas: {<strong>{answeredQuestions.length}</strong>}{' '}
              de {numberOfQuestions}
            </span>
          }
          onQuestionClick={(index: number) => {
            toStep(index);
            closeAndOpenCopilot();
          }}
          questions={navigationQuestions()}
          timer={{
            totalTimeInSeconds: totalTime * 60,
            startTimeInSeconds: (totalTime - +timeLeftInMinutes) * 60,
          }}
          types={types}
        />
        <Styled.FooterContainer>
          <NavigationFooter
            isFirstStep={isFirstStep}
            isLastStep={isLastStep}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
            nextLoading={finishLoading}
            lastStepLabel={`Finalizar ${type}`}
            ariaLabelPreviousButton={previousButtonLabel}
            ariaLabelNextButton={nextButtonLabel}
          />
        </Styled.FooterContainer>
      </Styled.Floating>

      {evaluation?.hasAdaptiveFeedback &&
      evaluation?.questions[step]?.classKrotonId ? (
        <ChatCopilot
          answerContainerRef={answerContainerRef}
          questionContainerRef={questionContainerRef}
          isQuizCopilotOpen={isQuizCopilotOpen}
          isCopilotOnQuiz
          isChatCopilotOnpenOnRender
          isQuizPageRendered={isQuizPageRendered}
          quizPagePayload={payload}
        />
      ) : null}
    </ExamLayoutDesktop>
  );
};

TakeEvaluationDesktop.defaultProps = {
  finishLoading: false,
};

export default TakeEvaluationDesktop;
