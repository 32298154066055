import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import { string } from '@ampli/utils';
import {
  DialogBackdrop,
  DialogAlert,
  DialogHeader,
  DialogSection,
  Text,
  FlexItem,
  Paragraph,
  DialogFooter,
} from '@ampli/ui';
import { HourglassImage } from '../image';

const RetryFinishExamAlert = ({ attemptsSend, ...props }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <>
      <DialogBackdrop {...props} />
      <DialogAlert
        {...props}
        hideOnEsc={false}
        hideOnClickOutside={false}
        aria-labelledby="RetryfinishExamAlertId"
      >
        <DialogHeader>
          <Text
            id="RetryfinishExamAlertId"
            color="text-dark"
            size={isDesktop ? 'x-large' : 'medium'}
          >
            Aguarde...
          </Text>
        </DialogHeader>
        <FlexItem flex={1}>
          <DialogSection>
            <Paragraph textAlign="center" marginSize="large">
              <HourglassImage baseColor={'accent-base'} aria-hidden />
            </Paragraph>
            <Paragraph marginSize="large">
              <Text size={isDesktop ? 'medium' : 'small'}>
                Estamos finalizando sua prova, por favor{' '}
                <strong>não saia</strong> do modo tela cheia.
              </Text>
            </Paragraph>
          </DialogSection>
        </FlexItem>
        <DialogFooter>
          <Paragraph textAlign="center" marginSize="large">
            <Text size={isDesktop ? 'medium' : 'small'}>
              Tentativa {attemptsSend > 10 ? 10 : attemptsSend}/10
            </Text>
          </Paragraph>
        </DialogFooter>
      </DialogAlert>
    </>
  );
};

RetryFinishExamAlert.propTypes = {
  attemptsSend: string.isRequired,
};

export default RetryFinishExamAlert;
