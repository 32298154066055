import styled from '@emotion/styled';

import { convertPXToREM, Typography } from '@sofia/ui';

export const Card = styled.div<{ isSelected?: boolean; isUnred?: boolean }>`
  > div {
    height: 100%;
    padding: 16px;
    margin: 16px 0px;
    display: block;
    outline: none;

    ${({ isSelected, theme }) =>
      isSelected &&
      `
        border-color: ${theme.colors.text.primary};
        border-width: 4px !important;
      `}

    ${({ isUnred, theme }) =>
      isUnred &&
      `
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 8px;
        top: 16px;
        bottom: 16px;
        width: 4px;
        border-radius: 999px;
        background: ${theme.colors.tag.danger};
      }
    `}
  }
`;

interface FlexProps {
  align?: string;
  justify?: string;
  direction?: string;
  margin?: string;
  width?: string | number;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${({ align = 'center' }) => align};
  justify-content: ${({ justify = 'space-between' }) => justify};
  flex-direction: ${({ direction = 'row' }) => direction};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
`;

export const IconButton = styled.button`
  background: none;
  outline: none;
  border: 2px solid transparent;
  padding: 8px;
  border-radius: 4px;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.colors.button.lightBlack};
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.button.primary};
    background: ${({ theme }) => theme.colors.button.lightBlack};
  }
`;

interface PreviewProps {
  isStudentAgent: boolean;
  color: string;
  isHistoricPage: boolean;
}

export const Preview = ({
  isHistoricPage,
  color,
  isStudentAgent,
}: PreviewProps): string => {
  const margin = isHistoricPage ? '0px' : '8px 0px 0px';

  return `
  margin: ${isStudentAgent ? margin : '8px 0px'};
  font-size: ${convertPXToREM(14)};
  font-weight: 700;
  color: ${color};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  user-select: none;

  p {
    margin: 0px;
  }
`;
};

export const TruncateText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  user-select: none;
`;
