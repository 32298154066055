export type TPublishType = 'comment' | 'publication';

export enum PUBLICATION_CATEGORY {
  TIP = 'TIP',
  DOUBT = 'DOUBT',
}

export type TPublicationFilter = 'MY' | 'ALL';

export interface IUserActions {
  canIReport: boolean;
  canIDelete: boolean;
}

export interface IPublication {
  id: string;
  studentName: string;
  courseEnrollmentId: string;
  subjectId: string;
  learningUnitId: string;
  sectionOrder: string;
  sectionId: string;
  learningUnitOrder: string;
  title: string;
  body: string;
  category: PUBLICATION_CATEGORY;
  categoryDetail: string;
  reportCount: number;
  likeCount: number;
  commentCount: number;
  learningUnitName: string;
  subjectName: string;
  sectionName: string;
  dateCreation: string;
  lastUpdate: string;
  actionsPublication: IUserActions;
}

export interface PublicationProviderProps {
  children: React.ReactNode;
}
