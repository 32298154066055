import { QUIZ } from '../constants';

const useQuizAttemptsData = ({ learningUnit }) => {
  const assignmentQuiz = learningUnit?.assignments.find(
    (assignment) => assignment.detail.config.type === QUIZ
  );

  if (!assignmentQuiz) {
    return {};
  }

  const assignmentQuizConfig = assignmentQuiz.detail.config;
  const assignmentQuizAttempts = assignmentQuiz.detail.attempts.length;
  const validAttempts =
    assignmentQuizConfig.numberOfAttempts - assignmentQuizAttempts;

  return {
    assignmentQuiz,
    assignmentQuizConfig,
    assignmentQuizAttempts,
    validAttempts,
  };
};

export default useQuizAttemptsData;
