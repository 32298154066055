import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const Container = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  ${({ isLive }: { isLive: boolean }) =>
    isLive
      ? `
    width: 100%;
    > p {
      margin: 19px 0 19px auto;
    }
    `
      : `
    > p {
      margin: 19px 0;
    }
    `}

  svg {
    margin-left: 12px;
  }
    ${mediaQuery('mobile')} {
    > p {
      margin: 35px 0 0 0;
    }
`;
