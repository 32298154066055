import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW } from '../../utils';
import { Typography } from '../..';
export const FilesUploadContainer = styled.section`
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${_ref => {
  let {
    error,
    borderColor
  } = _ref;
  if (error) {
    return `border: 2px solid #bc3206;`;
  } else {
    return `border: 2px solid ${borderColor};`;
  }
}}

  background: ${_ref2 => {
  let {
    background
  } = _ref2;
  return background;
}};
  padding: ${convertPXToVH(16)} ${convertPXToVW(16)};
  border-radius: 8px;

  &:hover {
    background-color: ${_ref3 => {
  let {
    hoverColor
  } = _ref3;
  return hoverColor;
}};
  }
`;
export const FormField = styled.input`
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
export const UploadFileContainer = styled.section`
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: ${_ref4 => {
  let {
    background
  } = _ref4;
  return background;
}};
  padding: ${convertPXToVH(10)} ${convertPXToVW(16)};
  margin-bottom: ${convertPXToVH(24)};
  border-radius: 8px;

  &:hover {
    background-color: ${_ref5 => {
  let {
    hoverColor
  } = _ref5;
  return hoverColor;
}};
  }

  &:focus {
    border: 4px solid #004ecc;
    background-color: #f3f6f9;
  }
`;
export const RemoveFileBtn = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
export const TruncateText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  user-select: none;
`;
export const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;