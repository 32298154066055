import React from 'react';
import { useQuery, useMutation } from '@ampli/services';
import {
  GET_TAX_INVOICE_DOCUMENT_STATUS,
  REQUEST_TAX_INVOICE_DOCUMENT,
} from './queries';
import {
  GetTaxInvoiceDocumentStatusHook,
  MutationRequestTaxInvoiceDocumentHook,
} from './types';

export const useGetTaxInvoiceDocumentStatus: GetTaxInvoiceDocumentStatusHook =
  ({ courseEnrollmentId, invoiceId, options = {} }) => {
    const { data, loading, error, refetch } = useQuery(
      GET_TAX_INVOICE_DOCUMENT_STATUS,
      {
        ...options,
        variables: {
          courseEnrollmentId,
          invoiceId,
        },
        fetchPolicy: 'no-cache',
      }
    );

    const getTaxInvoiceDocumentStatus = loading || !data ? {} : data.data;

    return {
      data: getTaxInvoiceDocumentStatus,
      error,
      loading,
      refetch,
    };
  };

export const useMutationRequestTaxInvoiceDocument: MutationRequestTaxInvoiceDocumentHook =
  ({ options, courseEnrollmentId, invoiceId }) => {
    const [mutate, { loading, error, data, ...rest }] = useMutation(
      REQUEST_TAX_INVOICE_DOCUMENT,
      { ...options }
    );

    const { onSuccess, onError } = React.useMemo(() => {
      return {
        onSuccess: options?.onSuccess,
        onError: options?.onError,
      };
    }, [options?.onSuccess, options?.onError]);

    const handleSubmit = React.useCallback(async () => {
      try {
        const { data, error } = await mutate({
          variables: {
            courseEnrollmentId,
            invoiceId,
          },
          context: {
            autoHandle: true,
          },
        });
        !error && data && onSuccess && onSuccess(data.data);
      } catch (error) {
        onError && onError();
      }
    }, [mutate, onSuccess, onError, courseEnrollmentId, invoiceId]);

    const requestTaxInvoiceDocument = loading || !data ? {} : data.data;

    return [
      mutate,
      {
        data: requestTaxInvoiceDocument,
        error,
        handleSubmit,
        loading,
        ...rest,
      },
    ];
  };
