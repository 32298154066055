import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui/dist/utils';

export const FormSection = styled.div`
  > div:first-of-type {
    margin-bottom: 16px;
  }
`;

export const RowFormSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  margin-top: 16px;

  > div {
    width: 100%;
  }

  ${mediaQuery('mobile')} {
    flex-direction: column;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 56px;

  > button {
    width: auto;
  }

  > button:not(:last-of-type) {
    margin-top: 0px;
    margin-right: 24px;
  }

  ${mediaQuery('mobile')} {
    margin-top: 40px;
    flex-direction: column-reverse;
    justify-content: center;

    > button:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`;
