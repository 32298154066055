import styled from '@emotion/styled';

export const FrameSuccessWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 24px;
`;
