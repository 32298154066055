import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import colors from "../utils/colors";
const graduateTheme = {
  colors: _objectSpread(_objectSpread({}, colors), {}, {
    'brand-base': '#1ac09c',
    'brand-light': '#3bdbb8',
    'brand-lighter': '#d8f8f1',
    'gradient-linear-brand': 'linear-gradient(233.53deg, #4dcddf -26.85%, #00b085 100%)',
    'gradient-linear-brand-light': 'linear-gradient(180deg, #d8f8f1 0%, #ffffff 100%)',
    'gradient-linear-brand-alpha': 'linear-gradient(82.37deg, rgba(59, 219, 184, 0.3) 0%, #00B085 100%)'
  })
};
export default graduateTheme;