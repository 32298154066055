import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import fetch from 'isomorphic-unfetch';
import { mapKeys, toLower, merge } from 'lodash';
import { authStorage } from "./storages";
import { APIError } from "./errors";
export const graphqlFetch = async function (uri) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const token = await authStorage.getItem('token').catch(e => {
    console.error(e);
    return null;
  });
  options.headers = _objectSpread(_objectSpread({
    'content-type': 'application/json'
  }, token && {
    authorization: `Bearer ${token}`
  }), options.headers && mapKeys(options.headers, (value, key) => toLower(key)));
  console.info('GraphQL fetch.');
  console.debug({
    uri,
    options,
    token
  });
  return fetch(uri, _objectSpread(_objectSpread({}, options), {}, {
    method: 'POST'
  })).then(res => {
    console.info('GraphQL fetch response.');
    console.debug(res);
    return res.json();
  }).then(_ref => {
    let {
      data,
      errors
    } = _ref;
    if (errors) {
      // TODO: support multiple errors
      const {
        message,
        status,
        code
      } = errors[0];
      throw new APIError(message, {
        status,
        code
      });
    }
    console.debug({
      data
    });
    return data;
  }).catch(error => {
    console.info('GraphQL fetch error.');
    console.error(error);
    throw error;
  });
};
export const graphqlFetchCreator = defaultOptions => function (uri) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return graphqlFetch(uri, merge(defaultOptions, options));
};