const confetti = typeof Worker !== 'undefined' ? require('canvas-confetti').default : () => {};
import { isMobile } from "../../utils";
const launchConfetti = () => {
  const colors = ['#B9F044', '#FAC6CF', '#CCF0F6', '#D1F4DC', '#87C6A8', '#E2AF54'];
  if (isMobile()) {
    const end = Date.now() + 1100;
    (function frame() {
      confetti({
        particleCount: 6,
        angle: 70,
        spread: 55,
        origin: {
          x: -0.6
        },
        colors: colors
      });
      confetti({
        particleCount: 6,
        angle: 110,
        spread: 55,
        origin: {
          x: 1.6
        },
        colors: colors
      });
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  } else {
    const end = Date.now() + 1000;
    (function frame() {
      confetti({
        particleCount: 6,
        angle: 60,
        spread: 55,
        origin: {
          x: -0.1
        },
        colors: colors
      });
      confetti({
        particleCount: 6,
        angle: 120,
        spread: 55,
        origin: {
          x: 1.1
        },
        colors: colors
      });
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }
};
export default launchConfetti;