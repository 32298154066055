import styled from '@emotion/styled';

import { mediaQuery, convertPXToVH } from '@sofia/ui';

export const Flex = styled.div<{ isHistoricPage: boolean }>`
  ${({ isHistoricPage }) =>
    isHistoricPage &&
    `
    background: #FFFFFF;
    position: sticky;
    bottom: 0;

    ${mediaQuery('desktop')} {
      padding-top: ${convertPXToVH(24)};
    }
  `}
`;
