import styled from '@emotion/styled';

import {
  SizeFontBase,
  SizeFontLarge,
  SizeFontXLarge,
  SizeFontXXLarge,
  SizeFontXXXLarge,
  SizeFontLarger,
  SizeFontXXLessLarge,
  SizeFontLessLarger,
} from '@ampli/ui/src/tokens';

import { convertPXToREM, mediaQuery } from '@sofia/ui';
import { IconButton } from '@material-ui/core';

export const Flex = styled.div`
  display: flex;
  flex: 1;
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

export const FlexAlignItemsCenter = styled.div`
  display: flex;
  align-items: center;
  ${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'};
`;

export const LayoutContainer = styled.div`
  > div {
    display: flex;
  }
`;

export const HelpBtn = styled.button`
  svg {
    margin: 10px;
  }
  ${mediaQuery('desktop')} {
    margin-right: 40px;
    flex-direction: row;
    padding: 8px 16px;
    svg {
      margin: 0 10px 0 0;
    }
  }
  span {
    font-size: ${convertPXToREM(16)};
    font-weight: 400;
    line-height: 150%;
  }
  :hover,
  &[aria-expanded='true'] {
    background: #ebf0f5;
  }

  flex-direction: column;
  display: flex;
  align-items: center;
  border: solid 1px transparent;
  border-radius: 8px;
  background: transparent;
`;

export const IconBtn = styled(IconButton)`
  background-color: #dbe3ec;
  border-radius: 50%;
  svg {
    color: #212529;
    box-sizing: content-box;
    pointer-events: none;
  }
  :hover {
    background-color: #c5d2e0;
  }
`;

const sizeMap = (isDesktop) => [
  { tag: 'h6', fontSize: SizeFontBase },
  { tag: 'h5', fontSize: isDesktop ? SizeFontLarge : '17px' },
  { tag: 'h4', fontSize: isDesktop ? SizeFontXLarge : SizeFontLarge },
  { tag: 'h3', fontSize: isDesktop ? SizeFontXXLarge : SizeFontXXLessLarge },
  { tag: 'h2', fontSize: SizeFontXXXLarge },
  { tag: 'h1', fontSize: isDesktop ? SizeFontLarger : SizeFontLessLarger },
];

/* Gets default title font size and adds the difference between new fontsize
set by the user (kindle) and the standard text default font size */
const getIncreasedFontSize = (sizeFont, customFontSize, defaultFontSize) =>
  convertPXToREM(parseInt(sizeFont, 10) + (customFontSize - defaultFontSize));

export const ContentContainer = styled.div(
  ({ isDesktop, customization = {}, defaultFontSize }) => {
    const customColor = customization.theme ? customization.theme.color : {};

    const mappedHeadings = sizeMap(isDesktop).map((size) => {
      return `${size.tag} {
        p,
        span,
        pre,
        strong {
          color: ${customColor};
          font-size: ${getIncreasedFontSize(
            size.fontSize,
            customization.fontSize,
            defaultFontSize
          )};
          font-family: ${customization.formatFont};
          text-align: ${customization.formatAlign};
          line-height: 140%;
        }
        ${
          size.tag !== 'h1' &&
          `
            :not(:first-of-type) {
              margin-top: ${isDesktop ? '64px' : '48px'}
            }
          `
        }
      }`;
    });

    const defaultObject = `
      line-height: 168%;
    `;

    return `
      margin: 0 16px;

      ${mappedHeadings}

      .rich-text {
        p,
        span:not(.no-kindle),
        pre,
        strong,
        math,
        {
          ${defaultObject}
          font-size: ${convertPXToREM(customization.fontSize)};
          font-family: ${customization.formatFont};
          text-align: ${customization.formatAlign};
        }

        ${mappedHeadings}
      }

      table {
        width: 100%;
        color: ${customColor};

        th, td{
          color: ${customColor};
          border-color: ${customColor};
          font-size: ${convertPXToREM(customization.fontSize)};
          font-family: ${customization.formatFont};
          text-align: ${customization.formatAlign};
        }
      }

      mjx-container {
        color: ${customColor};
        ${defaultObject}
        font-size: ${convertPXToREM(customization.fontSize)} !important;
        mjx-math {
          font-family: ${customization.formatFont};
        }
      }
    `;
  }
);

export const SectionContentContainer = styled.div(
  ({ background, isDesktop }) => `
    background-color: ${background};
    padding: ${isDesktop ? '40px 0' : '56px 0'};
  `
);
