import {
  useFindStudentCourseEnrollmentsWithFreeTrialInfo,
  useFindStudentCourseEnrollmentsProgress,
  useGetCourseEnrollmentForSyllabusPage,
  useServiceClient,
  useGetSubjectEnrollmentsForSyllabusPage,
} from '@ampli/services';
import { useMeContext } from '../modules/shared/contexts/me-context';
import { generatePath, useHistory } from 'react-router-dom';
import { DateTime } from '@ampli/utils';

import { filterFreeTrialDataFromByEnrollmentId } from '../utils';
import { useGetState } from '../state';
import { routePath } from '../routes';

import logout from '../lib/logout';
import React from 'react';
import { useModal } from '@sofia/ui';
import { filterActiveEnrollments } from '../modules/academic-program/shared';

const useSyllabusPageData = () => {
  const client = useServiceClient('privateClient');
  const history = useHistory();
  const { states: statesMe } = useMeContext();
  const { me } = statesMe.getMe;
  const reenrollmentPeriodModal = useModal();

  const [firstName, setFirstName] = React.useState();

  const [
    {
      selectedCourseEnrollmentId,
      selectedCourseEnrollmentType,
      selectedCourseVersionId,
    },
  ] = useGetState();

  const { data: courseEnrollment, loading: courseEnrollmentLoading } =
    useGetCourseEnrollmentForSyllabusPage(selectedCourseEnrollmentId);

  const { data: subjectEnrollments, loading: subjectEnrollmentsLoading } =
    useGetSubjectEnrollmentsForSyllabusPage(selectedCourseEnrollmentId);

  const { data: progress, loading: progressLoading } =
    useFindStudentCourseEnrollmentsProgress(selectedCourseEnrollmentId);

  const { data: studentFreeTrialData, loading: studentFreeTrialDataLoading } =
    useFindStudentCourseEnrollmentsWithFreeTrialInfo(
      courseEnrollment?.studentId
    );

  const studentFreeTrialCourseData = React.useMemo(
    () =>
      filterFreeTrialDataFromByEnrollmentId(
        studentFreeTrialData?.data,
        courseEnrollment?.id
      ),
    [courseEnrollment?.id, studentFreeTrialData?.data]
  );

  const enrollmentsToBeTakenByDate =
    subjectEnrollments?.subjectEnrollmentsInProgress?.filter((enrollment) => {
      return DateTime.local() < enrollment.startDate;
    });

  const subjectEnrollmentsInProgress =
    subjectEnrollments?.subjectEnrollmentsInProgress
      ? subjectEnrollments?.subjectEnrollmentsInProgress.filter(
          (item) => !enrollmentsToBeTakenByDate.includes(item)
        )
      : [];

  const activeEnrollments = filterActiveEnrollments(
    subjectEnrollmentsInProgress
  );

  const subjectEnrollmentsTaken = React.useMemo(
    () => subjectEnrollments?.subjectEnrollmentsTaken || [],
    [subjectEnrollments?.subjectEnrollmentsTaken]
  );

  const subjectEnrollmentsTakenFailed = React.useMemo(
    () =>
      subjectEnrollments?.subjectEnrollmentsTaken?.filter(
        (enrollment) => enrollment.status === 'FAILED'
      ) || [],
    [subjectEnrollments?.subjectEnrollmentsTaken]
  );

  const subjectEnrollmentsTakenPassed = React.useMemo(
    () =>
      subjectEnrollments?.subjectEnrollmentsTaken?.filter(
        (enrollment) => enrollment.status === 'PASSED'
      ) || [],
    [subjectEnrollments?.subjectEnrollmentsTaken]
  );

  const subjectEnrollmentsToBeTaken = React.useMemo(
    () => [
      ...(enrollmentsToBeTakenByDate || []),
      ...(subjectEnrollments?.futureSubjects || []),
    ],
    [enrollmentsToBeTakenByDate, subjectEnrollments?.futureSubjects]
  );

  const handleLogout = React.useCallback(
    () => logout({ client, history }),
    [client, history]
  );

  const handleLogo = React.useCallback(
    () =>
      history.push(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })
      ),
    [history, selectedCourseEnrollmentType, selectedCourseVersionId]
  );

  const getSubjectPath = (subject) => {
    if (subject.isElective && subject.electiveStatus === 'AWAITING_CHOICE') {
      return routePath.electiveSubjectOnboarding;
    }
    if (subject.subType === 'MANDATORY_INTERNSHIP') {
      return routePath.mandatoryInternshipPage;
    }
    return routePath.subject;
  };

  const handleSubject = React.useCallback(
    (subject) => {
      if (subject.electiveStatus === 'PAUSED') {
        reenrollmentPeriodModal.show();
        return;
      }
      const targetPath = getSubjectPath(subject);
      history.push(
        generatePath(targetPath, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
          subjectEnrollmentId: subject.subjectEnrollmentId,
        })
      );
    },
    [
      history,
      reenrollmentPeriodModal,
      selectedCourseEnrollmentType,
      selectedCourseVersionId,
    ]
  );

  React.useEffect(() => {
    setFirstName(me.firstName);
  }, [me.firstName]);

  return {
    firstName,
    handleLogout,
    handleLogo,
    courseEnrollmentLoading,
    progressLoading,
    studentFreeTrialDataLoading,
    courseEnrollment,
    subjectEnrollmentsLoading,
    subjectEnrollmentsInProgress,
    subjectEnrollmentsTaken,
    subjectEnrollmentsToBeTaken,
    subjectEnrollmentsTakenFailed,
    subjectEnrollmentsTakenPassed,
    progress,
    studentFreeTrialCourseData,
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
    reenrollmentPeriodModal,
    handleSubject,
    activeEnrollments,
  };
};

export default useSyllabusPageData;
