import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  :not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const ArcContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
  ${mediaQuery('desktop')} {
    margin: 32px 0;
  }
`;

export const ArcContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  position: fixed;
  top: 35%;
`;

export const ProgressText = styled.span`
  font-weight: 700;
  font-size: ${convertPXToREM(40)};
  line-height: 120%;
  color: ${({ color }) => color};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const WarningContainer = styled.div`
  display: flex;
  width: '90%';
  margin: 16px 0px;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  border-bottom: 1px solid #ebf0f5;
`;
