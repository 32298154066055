import React from 'react';
import styled from '@emotion/styled';

interface SuccessIllustrationProps {
  illustration: React.FC;
}

export const SuccessIllustration: React.FC<SuccessIllustrationProps> = ({
  illustration: Illustration,
}: SuccessIllustrationProps) => {
  const Content = styled(Illustration)`
    width: 100%;
  `;

  return <Content />;
};
