import {
  SizeFontBase,
  SizeFontLarge,
  SizeFontXLarge,
  SizeFontXXLarge,
  SizeFontXXXLarge,
  SizeFontLarger,
  SizeFontXXLessLarge,
  SizeFontLessLarger,
} from '@ampli/ui/src/tokens';
import { convertPXToREM, mediaQuery } from '@sofia/ui';
import { EAssetElement, TAssetElementConfig, TContentTemplate } from '../types';
import { TSizeMap, CustomSubtitleFunctionProps } from './types';

export const sizeMap = (isDesktop: boolean): TSizeMap[] => [
  { tag: 'h6', fontSize: SizeFontBase },
  { tag: 'h5', fontSize: isDesktop ? SizeFontLarge : '17px' },
  { tag: 'h4', fontSize: isDesktop ? SizeFontXLarge : SizeFontLarge },
  { tag: 'h3', fontSize: isDesktop ? SizeFontXXLarge : SizeFontXXLessLarge },
  { tag: 'h2', fontSize: SizeFontXXXLarge },
  { tag: 'h1', fontSize: isDesktop ? SizeFontLarger : SizeFontLessLarger },
];

/* Gets default title font size and adds the difference between new fontsize
set by the user (kindle) and the standard text default font size */
export const getIncreasedFontSize = (
  sizeFont: string,
  customFontSize: number,
  defaultFontSize: number
): string =>
  convertPXToREM(parseInt(sizeFont, 10) + (customFontSize - defaultFontSize));

export const getTemplateAsset = (
  element: EAssetElement,
  template?: TContentTemplate | null
): TAssetElementConfig | undefined =>
  template?.assetElementConfigs?.find(
    (assetElement) => assetElement.element === element
  );

const commonStyleContent = ({
  fontColor,
  backgroundColor,
}: CustomSubtitleFunctionProps): string => `
    color: ${fontColor};
    background-color: ${backgroundColor};
    width: fit-content;
    line-height: 33.6px;
    max-width: 80% !important;

    ${mediaQuery('mobile')} {
      line-height: 27.6px;
    }
  `;

export const CustomSubtitle = {
  RADIUS_EVEN: (props: CustomSubtitleFunctionProps): string => `
    ${commonStyleContent(props)}
    border-radius: 1px 16px;
    padding: 4px 8px;
  `,
  RADIUS_ALL: (props: CustomSubtitleFunctionProps): string => `
    ${commonStyleContent(props)}
    border-radius: 4px;
    padding: 4px;
  `,
  MARKER: ({
    fontColor,
    backgroundColor,
  }: CustomSubtitleFunctionProps): string => `
    color: ${fontColor};
    width: fit-content;
    display: inline;
    line-height: 33.6px;
    background: linear-gradient(to bottom, transparent 20px, ${backgroundColor} 12px);
    background-repeat: no-repeat;

    ${mediaQuery('mobile')} {
      background: linear-gradient(to bottom, transparent 16px, ${backgroundColor} 12px);
      line-height: 27.6px;
    }
  `,
};
