import {
  useFindStudentCourseEnrollmentsWithFreeTrialInfo,
  useServiceClient,
  useFindStudentMyCourseEnrollmentsPage,
} from '@ampli/services';

import { groupBy, filter } from '@ampli/utils';
import { changeTheme } from '@ampli/ui';
import { useHistory } from 'react-router-dom';

import { useGetState } from '../state';

import logout from '../lib/logout';
import React from 'react';
import { useMeContext } from '../modules/shared/contexts/me-context';
import { ECourseEnrollmentStatus } from '../modules/shared/contexts/course-enrollments-context.types';

const useCourseSelection = () => {
  const history = useHistory();
  const client = useServiceClient('privateClient');

  const [
    {
      selectedCourseEnrollmentId,
      selectedCourseEnrollmentType,
      selectedCourseId,
      selectedCourseVersionId,
    },
    {
      setCourseEnrollmentId,
      setCourseEnrollmentType,
      setCourseId,
      setCourseVersionId,
    },
  ] = useGetState();

  const { states: statesMe } = useMeContext();

  const {
    data: courseEnrollments,
    loading: loadingCourseEnrollments,
    refetch: refetchCourseEnrollments,
    error: errorCourseEnrollments,
  } = useFindStudentMyCourseEnrollmentsPage(statesMe.getMe.me.personId);

  const { me, loading: loadingMe, error: errorMe } = statesMe.getMe;

  changeTheme();

  const { data: studentFreeTrialData, loading: studentFreeTrialDataLoading } =
    useFindStudentCourseEnrollmentsWithFreeTrialInfo(me?.personId);

  const loading = React.useMemo(
    () => loadingMe || loadingCourseEnrollments || studentFreeTrialDataLoading,
    [loadingMe, loadingCourseEnrollments, studentFreeTrialDataLoading]
  );

  const currentCourseEnrollmentsByDegree = React.useMemo(
    () =>
      groupBy(
        filter(courseEnrollments, ({ status }) => {
          return (
            status === ECourseEnrollmentStatus.ATTENDING ||
            status === ECourseEnrollmentStatus.RE_ENROLLMENT
          );
        }),
        'course.courseType.degree'
      ),
    [courseEnrollments]
  );

  const pastCourseEnrollmentsByDegree = React.useMemo(
    () =>
      groupBy(
        filter(courseEnrollments, ({ status }) => {
          return (
            status !== ECourseEnrollmentStatus.ATTENDING &&
            status !== ECourseEnrollmentStatus.RE_ENROLLMENT
          );
        }),
        'course.courseType.degree'
      ),
    [courseEnrollments]
  );

  const handleLogout = React.useCallback(
    () => logout({ client, history }),
    [client, history]
  );

  const error = React.useMemo(
    () => Boolean(errorMe || errorCourseEnrollments),
    [errorMe, errorCourseEnrollments]
  );

  return {
    loading,
    me,
    handleLogout,
    error,
    currentCourseEnrollmentsByDegree,
    pastCourseEnrollmentsByDegree,
    setCourseEnrollmentId,
    setCourseEnrollmentType,
    setCourseId,
    setCourseVersionId,
    selectedCourseEnrollmentId,
    selectedCourseEnrollmentType,
    selectedCourseId,
    selectedCourseVersionId,
    studentFreeTrialData,
    refetchCourseEnrollments,
  };
};

export default useCourseSelection;
