import React from 'react';

import { useBreakpoint } from '../../../hooks';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { Tooltip } from '@material-ui/core';

import * as Styled from './section-right-frame-header.styles';

interface SectionRightFrameHeaderProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
}

const SectionRightFrameHeader: React.FC<SectionRightFrameHeaderProps> = ({
  onClose,
  title,
  isOpen,
}: SectionRightFrameHeaderProps) => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();

  return (
    <Styled.FrameHeader className={!isOpen ? 'closed-frame' : ''}>
      <Styled.FlexWrapper>
        {isOpen ? (
          <Styled.FlexTitleContainer>
            <Typography
              id="section-right-frame-title"
              color={theme?.colors?.text?.black}
              size={24}
              weight={700}
            >
              {title}
            </Typography>
          </Styled.FlexTitleContainer>
        ) : null}

        {(!isDesktop && isOpen) || isDesktop ? (
          <Tooltip arrow title="Fechar menu" placement="bottom">
            <Styled.IconBtn
              id="section-right-frame-collapse-icon"
              icon={isOpen ? <Styled.IconX /> : null}
              size="xx-large"
              color="text-dark"
              onClick={onClose}
              aria-label="Fechar"
              className={!isOpen && 'closed-frame'}
            />
          </Tooltip>
        ) : null}
      </Styled.FlexWrapper>
    </Styled.FrameHeader>
  );
};

export default SectionRightFrameHeader;
