import { useTheme } from '@emotion/react';
import React from 'react';
import { SvgExclamationCircle, Typography, Modal } from '@sofia/ui';

import {
  ModalBody,
  ModalFooter,
  AlertBox,
  IconContainer,
  List,
} from './reenrollment-period-modal.styles';
import { useBreakpoint } from '../../modules';
import { ReenrollmentPeriodModalProps } from './reenrollment-period-modal.types';
import ListItem from './list-item';
import ButtonFooter from './button-footer';

const ReenrollmentPeriodModal: React.FC<ReenrollmentPeriodModalProps> = ({
  handleModal,
  ...props
}: ReenrollmentPeriodModalProps) => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();

  const headerProps = {
    headerText: 'Disciplina pausada',
    color: theme.colors.text.black,
    size: isDesktop ? 32 : 24,
  };

  return (
    <Modal
      {...props}
      header={{ ...headerProps }}
      handleModal={handleModal}
      shouldCloseOnEsc={true}
      footer={<ButtonFooter onClick={handleModal.hide} text="Entendi" />}
    >
      <ModalBody>
        <Typography color={theme.colors.text.black} margin="0 0 22px 0">
          Sua disciplina está temporariamente pausada devido à falta de
          renovação da matrícula. Por favor, regularize essa pendência para
          continuar seus estudos.
        </Typography>
      </ModalBody>

      <AlertBox>
        <IconContainer>
          <SvgExclamationCircle fill={theme.colors.alerts.icon.primary} />
        </IconContainer>
        <List>
          <ListItem
            text="Alunos isentos das mensalidades devem dar o aceite no termo de
              renovação de matrícula."
          />
          <ListItem
            text="Alunos pagantes precisam realizar o pagamento da mensalidade de
            rematrícula."
          />
        </List>
      </AlertBox>

      <ModalFooter>
        <Typography color={theme.colors.text.black} margin="16px 0 16px 0">
          Se você já realizou a sua rematrícula, peço que aguarde alguns
          instantes para que as disciplinas sejam liberadas novamente.
        </Typography>
      </ModalFooter>
    </Modal>
  );
};

export default ReenrollmentPeriodModal;
