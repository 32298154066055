import React from 'react';
import { func } from '@ampli/utils';
import {
  DialogBackdrop,
  DialogAlert,
  DialogHeader,
  DialogCloseButton,
  DialogSection,
  Text,
  Flex,
  Paragraph,
  DialogFooter,
  TextButton,
  px2grid,
} from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';

const StartExamAlert = ({ onStartClick, onCancelClick, ...props }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <>
      <DialogBackdrop aria-hidden {...props} />
      <DialogAlert {...props}>
        <DialogHeader>
          <DialogCloseButton onClick={props.hide} />
          <Text
            as="h1"
            color="text-dark"
            size={isDesktop ? 'x-large' : 'medium'}
          >
            Deseja começar a prova?
          </Text>
        </DialogHeader>
        <DialogSection>
          <Paragraph>
            <Text size={isDesktop ? 'medium' : 'small'}>
              Certifique-se de que está preparado e comece a prova
            </Text>
          </Paragraph>
        </DialogSection>
        <Flex justifyContent="flex-end">
          <DialogFooter>
            <TextButton
              underline
              color="text-dark"
              onClick={onCancelClick}
              size={isDesktop ? 'medium' : 'small'}
              style={{ marginRight: px2grid(16) }}
            >
              Cancelar
            </TextButton>
            <TextButton
              underline
              color="brand-base"
              onClick={onStartClick}
              size={isDesktop ? 'medium' : 'small'}
              aria-label="Sim, começar prova"
            >
              Sim, avançar
            </TextButton>
          </DialogFooter>
        </Flex>
      </DialogAlert>
    </>
  );
};

StartExamAlert.propTypes = {
  hide: func.isRequired,
  onStartClick: func.isRequired,
  onCancelClick: func.isRequired,
};

export default StartExamAlert;
