import { number, object, arrayOf, shape, string } from 'prop-types';
import { Card, useModal } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { clickByKey } from '@ampli/utils';
import { useTheme } from '@emotion/react';

import { PopUp } from '../../../../modules/shared';

import SubjectProgressCardInfoContent from './content/subject-progress-card-info-content';
import React from 'react';

import * as Styled from './subject-progress-card-styles';
import { pushAmpliEvent } from '../../../../lib/ga';

const SubjectProgressCard = ({
  details,
  progress,
  entitiesLabelsDictionary,
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const progressModal = useModal();

  const onClick = () => {
    pushAmpliEvent('event', 'subject_progress_card');
    progressModal.show();
  };

  return (
    <>
      <Styled.CardContainer>
        <Card
          id={'progress-card'}
          hover={theme.colors.card.color.hover}
          borderColor={theme.colors.card.border.primary}
          focusColor={theme.colors.card.border.third}
          onClick={onClick}
          aria-label={`Progresso da disciplina em ${progress}%`}
          onKeyDown={(event) => clickByKey(event, onClick)}
          tabIndex={0}
        >
          <Styled.ProgressText
            id="progress-percent"
            marginRight
            color={theme.colors.text.primary}
          >
            {progress}%
          </Styled.ProgressText>
          <Styled.ProgressText
            id="progress-text"
            color={theme.colors.text.black}
          >
            Progresso em aula
          </Styled.ProgressText>
        </Card>
      </Styled.CardContainer>

      <PopUp
        popUpState={progressModal}
        headerText="Progresso em aula"
        isDesktop={isDesktop}
        ariaLabel="Modal progresso da disciplina"
      >
        <SubjectProgressCardInfoContent
          details={details}
          progress={progress}
          entitiesLabelsDictionary={entitiesLabelsDictionary}
        />
      </PopUp>
    </>
  );
};

SubjectProgressCard.propTypes = {
  details: arrayOf(
    shape({
      type: string.isRequired,
      quantity: number.isRequired,
      completed: number,
      percentage: number.isRequired,
    }).isRequired
  ).isRequired,
  progress: number,
  entitiesLabelsDictionary: object,
};

export default SubjectProgressCard;
