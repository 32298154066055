import React from 'react';
import { useTheme } from '@emotion/react';
import { Typography, SvgQuestionCircle, SvgLightBulb } from '@sofia/ui';
import {
  ICategoryTypeHintProps,
  PUBLICATION_CATEGORY,
} from '../../../../../types';
import * as Styles from './category-step.styles';

const CategoryTypeHint: React.FC<ICategoryTypeHintProps> = ({
  activeTab,
}: ICategoryTypeHintProps) => {
  const theme = useTheme();

  return (
    <Styles.CategoryHintCard>
      <Styles.CategoryHintCardHeader>
        <Typography weight={600}>Quando usar essa categoria?</Typography>
      </Styles.CategoryHintCardHeader>
      {activeTab === PUBLICATION_CATEGORY.DOUBT ? (
        <>
          <Styles.Tag background={theme.colors.card.background.warning}>
            <SvgQuestionCircle
              fill={theme.colors.icon.warning}
              width={16}
              height={16}
            />
            <Typography weight={600}>Dúvida</Typography>
          </Styles.Tag>
          <Typography>
            Utilize a categoria Dúvida sempre que precisar de esclarecimentos
            sobre uma aula específica. Essa categoria é ideal para perguntas que
            podem ser respondidas por seus colegas de curso.
          </Typography>
        </>
      ) : (
        <>
          <Styles.Tag background={theme.colors.card.background.hoverDisabled}>
            <SvgLightBulb
              fill={theme.colors.button.darkPrimary}
              width={16}
              height={16}
            />
            <Typography weight={600}>Dica</Typography>
          </Styles.Tag>
          <Typography>
            Utilize a categoria Dica para compartilhar sugestões úteis, dividir
            experiências práticas, estratégias de estudo ou recursos adicionais
            sobre as aulas que possam facilitar o aprendizado de outros alunos.
          </Typography>
        </>
      )}
    </Styles.CategoryHintCard>
  );
};

export default CategoryTypeHint;
