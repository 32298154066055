import { find } from '@ampli/utils';
const type = {
  read(cached, _ref) {
    let {
      readField
    } = _ref;
    if (cached) return cached;
    const assignmentRef = readField('assignment');
    const configRef = readField('config', assignmentRef);
    return readField('type', configRef);
  }
};
const subjectName = {
  read(cached, _ref2) {
    let {
      readField
    } = _ref2;
    if (cached) return cached;
    const subjectEnrollmentRef = readField('subjectEnrollment');
    const subjectRef = readField('subject', subjectEnrollmentRef);
    return readField('name', subjectRef);
  }
};
const numberOfLearningUnits = {
  read(cached, _ref3) {
    let {
      readField
    } = _ref3;
    if (cached) return cached;
    const subjectEnrollmentRef = readField('subjectEnrollment');
    const learningUnitRefs = readField('learningUnits', subjectEnrollmentRef);
    return learningUnitRefs.length;
  }
};
const highestGradeExamId = {
  read(cached, _ref4) {
    let {
      readField
    } = _ref4;
    if (cached) return cached;
    const assignmentRef = readField('assignment');
    const attemptRefs = readField('attempts', assignmentRef);
    const highestGradeAttemptId = readField('highestGradeAttemptId', assignmentRef);
    const highestGradeAttemptRef = find(attemptRefs, ref => {
      return readField('id', ref) === highestGradeAttemptId;
    });
    return readField('referenceId', highestGradeAttemptRef);
  }
};
export default {
  fields: {
    type,
    subjectName,
    numberOfLearningUnits,
    highestGradeExamId
  }
};