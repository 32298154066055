import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const Container = styled.div`
  padding: 24px;
  > :not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const Header = styled.div<{ isObject: boolean; isLoading?: boolean }>(
  ({ isObject, isLoading }) => `
  ${isLoading ? 'gap: 8px;' : ''}
  display:flex;
  width: 100%;
  ${
    isObject
      ? `justify-content: space-between; align-items: center;`
      : `flex-direction: column; margin-bottom: 32px;`
  }
  > p {
    :first-of-type {
      font-size: ${isObject ? convertPXToREM(24) : convertPXToREM(32)};
    }
    :last-of-type {
      font-size: ${isObject ? convertPXToREM(14) : convertPXToREM(16)};
    }
  }
`
);
