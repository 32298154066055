import styled from '@emotion/styled';
import { VideoPlayer } from '@ampli/video-player';
import { convertPXToREM, mediaQuery } from '@sofia/ui';

export const JSVideoPlayer = styled(VideoPlayer)`
  height: 35vh;
  max-height: calc(100vh - ${convertPXToREM(120)});
  *:focus {
    outline: 2px solid ${({ theme }) => theme.colors.button.focus.primary};
  }

  ${mediaQuery('desktop')} {
    height: 60vh;
  }
`;
