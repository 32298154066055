import styled from '@emotion/styled';
import { convertPXToREM, mediaQuery } from '@sofia/ui';

export const CommunitySectionButton = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  border: solid 2px ${({ theme }) => theme.colors.card.border.third};
  border-radius: 8px;
  background: transparent;

  padding: ${convertPXToREM(16)};
  margin-bottom: ${convertPXToREM(24)};

  svg {
    margin: 0 ${convertPXToREM(8)} 0 0;
  }

  &:hover,
  &[aria-expanded='true'] {
    background: ${({ theme }) => theme.colors.card.color.third};
  }

  ${({ theme, isActive }) =>
    isActive ? `color: ${theme.colors.text.primary};` : ''}

  ${mediaQuery('desktop')} {
    border-color: transparent;
    width: fit-content;
    margin-bottom: 0px;
  }
`;
