import styled from '@emotion/styled';
import { mediaQuery, convertPXToVW, convertPXToVH } from '@sofia/ui';

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const AppBar = styled(Flex)`
  justify-content: space-between;

  ${mediaQuery('tablet-max')} {
    padding: ${convertPXToVH(8)} 0;
  }
`;

export const BreadcrumbsWrapper = styled.div`
  ${mediaQuery('desktop')} {
    margin-bottom: ${convertPXToVH(80)};
  }

  & > div {
    padding: 0 ${convertPXToVW(24)};
  }
`;
