import { lazy } from 'react';

export const NotesPage = lazy(
  () => import(/* webpackChunkName: "Notes" */ '../pages/home/notes-home-page')
);

export const NotesSubjectPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Notes Subject" */ '../pages/subject/notes-subject-page'
    )
);

export const NotesLearningUnitPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Notes Learning Unit" */ '../pages/learning-unit/notes-learning-unit-page'
    )
);

const NotesModule = lazy(
  () => import(/* webpackChunkName: "Notes module" */ './module')
);

export default NotesModule;
