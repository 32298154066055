import { useLazyQuery } from '@ampli/services';
import { GET_ENROLLMENT_CANCELLATION } from '../queries';
import { useGetState } from '../../../state';
import React from 'react';

export const useGetEnrollmentCancellation = (options = {}) => {
  const [{ selectedCourseEnrollmentId }] = useGetState();
  const [tempAction, setTempAction] = React.useState(null);

  const [query, { data, called, error, loading }] = useLazyQuery(
    GET_ENROLLMENT_CANCELLATION,
    {
      ...options,
    }
  );

  const enrollmentCancellation = loading || !data ? null : data.data;

  return {
    getData: (chosenAction) => {
      if (!called || chosenAction !== tempAction) {
        setTempAction(chosenAction);
        query({
          variables: {
            courseEnrollmentId: selectedCourseEnrollmentId,
            action: chosenAction,
          },
          skip: !selectedCourseEnrollmentId || chosenAction,
        });
      }
      return enrollmentCancellation;
    },
    loading,
    error,
  };
};
