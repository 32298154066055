import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6px 0 16px 0;
`;

export const DayContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  p:first-of-type {
    padding-right: 5px;
  }
`;

export const DayInText = styled.span``;
