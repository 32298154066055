import React from 'react';

import { SectionRightFrameHeader } from './section-right-frame-header';
import { useBreakpoint } from '../../hooks';

import * as Styled from './section-right-frame.styles';

interface SectionRightFrameProps {
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  title: string;
  isFullWidth?: boolean;
  footer?: React.ReactNode;
}

const SectionRightFrame: React.FC<SectionRightFrameProps> = ({
  isFullWidth,
  children,
  onClose,
  footer,
  title,
  isOpen,
}: SectionRightFrameProps) => {
  const { isDesktop } = useBreakpoint();

  return (
    <React.Fragment>
      <Styled.FrameWrapper>
        <Styled.FrameMenu
          isFullWidth={isFullWidth}
          className={!isOpen ? 'closed-frame' : ''}
        >
          <Styled.SideBarWrapper
            isFullWidth={isFullWidth}
            className={!isOpen ? 'closed-frame' : ''}
          >
            <SectionRightFrameHeader
              onClose={onClose}
              title={title}
              isOpen={isOpen}
            />

            {isOpen ? (
              <React.Fragment>
                {children}
                {React.isValidElement(footer) ? footer : null}
              </React.Fragment>
            ) : null}
          </Styled.SideBarWrapper>
        </Styled.FrameMenu>
      </Styled.FrameWrapper>

      {isOpen && !isDesktop ? <Styled.BackgroundLayer /> : null}
    </React.Fragment>
  );
};

export default SectionRightFrame;
