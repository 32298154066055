export enum InstalmentStatusEnum {
  OPEN = 'OPEN',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  BLOCKED = 'BLOCKED',
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  UNDERPAID = 'UNDERPAID',
  OVERPAID = 'OVERPAID',
  REFUNDED = 'REFUNDED',
  WROTE_OFF = 'WROTE_OFF',
  AVAILABLE = 'AVAILABLE',
}
