import React from 'react';
import { useGetLiveAccessCard } from './live-access-card.logic';
import LiveAccessCardLabel from './label/live-access-card-label.presentation';
import * as Styled from './live-access-card.styles';
import { useTheme } from '@emotion/react';
import { CardButton } from '@sofia/ui';
import LiveAccessCardHeader from './header/live-access-card-header.presentation';
import LiveAccessCardAction from './action/live-access-card-action.presentation';
import { clickByKey } from '../../../../../shared';
import LiveThumbnailImg from '../thumbnail/live-thumbnail';

const LiveAccessCard = ({
  courseVersionId,
  subjectId,
}: {
  courseVersionId?: string;
  subjectId?: string;
}): React.ReactElement | null => {
  const theme = useTheme();

  const {
    getAriaLabel,
    isLive,
    showThumbnail,
    cardClick,
    loading,
    live,
    beginningTime,
    state,
  } = useGetLiveAccessCard({ courseVersionId, subjectId });

  return !loading && live ? (
    <Styled.CardContainer
      isLive={isLive}
      isCoursePage={Boolean(courseVersionId)}
      data-testid="live-access-card"
    >
      <CardButton
        data-testid="live-access-card-btn"
        borderColor={theme.colors.live.secondary}
        onClick={cardClick}
        hoverColor={theme.colors.card.color.hover}
        onKeyDown={(event: React.KeyboardEvent<Element>) =>
          clickByKey({ event, onClick: cardClick })
        }
        tabIndex={0}
        aria-label={getAriaLabel()[state]}
      >
        {showThumbnail ? <LiveThumbnailImg /> : null}
        <Styled.FlexColumn hasThumbnail={showThumbnail}>
          {/* Time & Theme */}
          <LiveAccessCardHeader
            state={state}
            startDate={live.startDateTime}
            beginningTime={beginningTime}
          />

          {/* Live label */}
          <LiveAccessCardLabel name={live.title} state={state} />

          {/* Watch live button */}
          {isLive /* || (!isLive && !isDesktop) */ ? (
            <LiveAccessCardAction
              data-testid="live-access-card-action"
              state={state}
            />
          ) : null}
        </Styled.FlexColumn>
        {/* {!isLive && isDesktop ? (
          <LiveAccessCardAction
            data-testid="live-access-card-action-desktop"
            state={state}
          />
        ) : null} */}
      </CardButton>
    </Styled.CardContainer>
  ) : null;
};

export default LiveAccessCard;
