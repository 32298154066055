import styled from '@emotion/styled';
import { convertPXToVH } from '@sofia/ui';

interface ProgressBarProps {
  completed: string | false;
  active: string | false;
  size: number;
}

export const Wrapper = styled.div`
  background-color: white;
  position: sticky;
  top: 58px;
  display: flex;
`;

export const ProgressBar = styled('div', {
  shouldForwardProp: (propName) =>
    propName !== 'completed' && propName !== 'active' && propName !== 'size',
})<ProgressBarProps>`
  background: #d6e1eb;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
  height: ${convertPXToVH(8)};
  ${({ size }) => `width: calc(100% / ${size})`};

  ${({ completed, active }) =>
    (completed && `background: ${completed}`) ||
    (active && `background: ${active}`)};
`;
