import React from 'react';
import SvgTermsIllustration from '../../assets/terms-illustration';

import { SvgArrowRight, Typography, Modal, Alerts } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';

import { useCommunicationTermsModal } from './modal.hook';
import { analyticsCreativesEvents } from '../../../shared/utils/communication-constants';
import { useQueryParams } from '../../../../hooks';
import { parsedCategory } from '../../types';
import { pushEvent } from '../../../../lib/ga';

import * as Styled from './modal.styles';
import { locationPathname } from '../../../../constants';

type Props = {
  handleModal: {
    show: () => void;
    hide: () => void;
    state: boolean;
  };
};

const CommunicationTermsModal: React.FC<Props> = ({
  handleModal: modalHandler,
}: Props) => {
  const theme = useTheme();
  const params = useQueryParams();
  const size = Array.from(params).length;

  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const { modal, onCtaClick } = useCommunicationTermsModal();

  const category = parsedCategory(modal?.categoryType);
  const Icon = category?.icon;

  const handleModal = React.useMemo(
    () => ({
      ...modalHandler,
      hide: () => {
        pushEvent(
          'event',
          analyticsCreativesEvents.termsModal.Com_contrato_prestservicos_clique
            .event,
          {
            item: analyticsCreativesEvents.termsModal.Com_contrato_prestservicos_clique.param(
              'fechar'
            ),
          }
        );
        modalHandler.hide();
      },
    }),
    [modalHandler]
  );

  React.useEffect(() => {
    if (size) {
      window.history.pushState({}, '', locationPathname());
      handleModal.show();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  const ModalHeader = (
    <Styled.Header>
      {category ? (
        <>
          <Icon
            fill={theme.colors.text.black}
            color={theme.colors.text.black}
            width="24px"
            height="24px"
          />

          <Typography size={16} weight={700} margin={`0px 0px 0px 8px`}>
            {category?.title}
          </Typography>
        </>
      ) : null}
    </Styled.Header>
  );

  const handleModalButton = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const localStorageTerms = localStorage.getItem('termsModal');
    return localStorageTerms ? handleModal.hide() : onCtaClick(e);
  };

  const ModalFooter = (
    <Styled.Footer>
      {modal?.ctaText && modal.ctaLink ? (
        <Styled.CustomButtonGhost
          onClick={handleModalButton}
          target="_self"
          color={theme.colors.button.primary}
        >
          <Typography space="nowrap">{modal?.ctaText}</Typography>
          <SvgArrowRight
            fill={theme.colors.text.primary}
            style={{ marginLeft: '15px', marginTop: '2px' }}
          />
        </Styled.CustomButtonGhost>
      ) : null}
    </Styled.Footer>
  );

  return modal ? (
    <Modal
      aria-labelledby="dialog_label"
      aria-describedby="dialog_desc"
      handleModal={handleModal}
      header={ModalHeader}
      footer={ModalFooter}
      width={544}
    >
      {isDesktop ? (
        <Styled.IllustrationWrapper>
          <SvgTermsIllustration />
        </Styled.IllustrationWrapper>
      ) : null}

      <Typography
        margin={isDesktop ? '0px' : '24px 0 0 0'}
        weight={700}
        size={24}
        id="dialog_label"
      >
        {modal?.title}
      </Typography>

      <Typography id="dialog_desc" margin="16px 0px">
        {modal?.body}
      </Typography>

      <Alerts
        theme={{
          background: theme.colors.icon.background,
          color: theme.colors.text.black,
        }}
        padding={16}
        iconLeft={<Styled.AlertIcon fill={theme.colors.icon.secondary} />}
        text={modal?.infoText}
      />
    </Modal>
  ) : null;
};

export default CommunicationTermsModal;
