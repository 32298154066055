import { DateTime } from '@ampli/utils';

export const getButtonAriaLabel = ({
  hasCompletedExams,
  hasCompletedRecoveryExams,
  subjectName,
  examDueDate,
  assignmentDueDate,
}) => {
  return {
    exam: hasCompletedExams
      ? `Prova final da disciplina de ${subjectName} realizada.`
      : `Fazer prova final da disciplina de ${subjectName}. Prazo ${examDueDate}.`,
    recovery: hasCompletedRecoveryExams
      ? `Prova de recuperação da disciplina de ${subjectName} realizada.`
      : `Fazer prova de recuperação da disciplina de ${subjectName}. Prazo ${examDueDate}.`,
    assignment: `Entrega de arquivos. Prazo para envio ${assignmentDueDate.toLocaleString(
      DateTime.DATE_FULL
    )}`,
    simulators: 'Fazer aulas práticas em um simulador de laboratório virtual',
  };
};
