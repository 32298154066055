/* eslint-disable prettier/prettier */
import React from 'react';

import SvgBannerIllustration from '../../assets/banner-illustration';

import { SvgArrowLeft, Typography } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';

import {
  analyticsCreativesEvents,
  useInternLinksCTA,
  INTERNAL_LINKS,
} from '../../../shared/utils/communication-constants';

import { useCommunicationBanner, Banner } from './banner.hook';
import { useUpdateReadCreative } from '../../services/hooks';
import { useStickBanner } from '../sticky-banner/sticky-banner.hook';
import { CtaIconToggler } from '../components/cta-icon-toggler';
import { parsedCategory } from '../../types';
import { LayoutContent } from '../../../../components';
import { useOnScreen } from '../../hooks/useOnScreen';
import { pushEvent } from '../../../../lib/ga';

import * as Styles from './banner.styles';

const TIMER = 6000;

interface SelectExamDialogInterface {
  show: () => void;
}

interface BannerProps extends Banner {
  isActive: boolean;
  width?: number;
  selectExamDialog?: SelectExamDialogInterface;
}

const BannerItem = ({
  selectExamDialog,
  categoryType,
  isActive,
  ctaLink,
  ctaText,
  title,
  width,
  body,
  image,
}: BannerProps): React.ReactElement | null => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const theme = useTheme();

  const category = parsedCategory(categoryType);

  const callToAction = useInternLinksCTA(ctaLink as INTERNAL_LINKS, {
    onFinalExameModal: () => selectExamDialog?.show(),
  });

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      pushEvent(
        'event',
        analyticsCreativesEvents.banner.Com_banner_clique_cta.event,
        {
          item: analyticsCreativesEvents.banner.Com_banner_clique_cta.param(
            category?.title
          ),
        }
      );

      if (typeof callToAction === 'string') {
        window.open(callToAction);
      } else {
        callToAction();
      }
    },
    [callToAction, category?.title]
  );

  return (
    <Styles.BannerWrapper width={width}>
      {isDesktop ? (
        <Styles.IllustrationWrapper>
          {image ? <img src={image} alt={title} /> : <SvgBannerIllustration />}
        </Styles.IllustrationWrapper>
      ) : null}

      <Styles.ContentWrapper>
        <div>
          <Typography
            margin={0}
            weight={700}
            color={theme.colors.kindle.lightBlack}
          >
            <category.icon
              width="24px"
              height="24px"
              {...(categoryType === 'ONBOARDING'
                ? {
                    color: theme.colors.text.black,
                  }
                : null)}
            />
            <Styles.BannerCategory>{category.title}</Styles.BannerCategory>
          </Typography>

          <Styles.CustomTypograpy
            as="h3"
            size={24}
            weight={700}
            margin="16px 0px 0px"
            lineHeight="100%"
            lines={2}
          >
            {title}
          </Styles.CustomTypograpy>

          <Styles.CustomTypograpy
            as="p"
            size={16}
            weight={400}
            color={theme.colors.text.blackSecondary}
            margin={ctaText ? '16px 0px' : '16px 0px 0px'}
            lines={3}
          >
            {body}
          </Styles.CustomTypograpy>
        </div>

        {ctaText ? (
          <Styles.CtaButton
            aria-disabled={!isActive ? 'true' : false}
            tabIndex={!isActive ? -1 : 0}
            onClick={handleClick}
          >
            {ctaText}
            <CtaIconToggler ctaLink={ctaLink} />
          </Styles.CtaButton>
        ) : null}
      </Styles.ContentWrapper>
    </Styles.BannerWrapper>
  );
};

interface BannersWrapperProps {
  selectExamDialog?: {
    show: () => void;
  };
}

const BannersWrapper = ({
  selectExamDialog,
}: BannersWrapperProps): React.ReactElement | null => {
  const [active, setActive] = React.useState(0);

  const bannerRef = React.useRef<HTMLDivElement | null>(null);
  const bannerWidth = bannerRef.current?.clientWidth;
  const isBannerOnScreen = useOnScreen(bannerRef, {});

  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const theme = useTheme();

  const { banners, loading, courseEnrollmentId } = useCommunicationBanner();
  const { openStickBanner } = useStickBanner();

  const isSlider = React.useMemo(() => banners?.length > 1, [banners?.length]);
  const bannerActive = React.useMemo(() => banners[active], [active, banners]);

  const padding = isDesktop
    ? ['40px', 'huge']
    : isSlider
    ? ['0px', '0px', '40px']
    : ['none'];

  const paddingByBannerWidth = bannerWidth ? padding : ['none'];

  const [updateRead] = useUpdateReadCreative(
    courseEnrollmentId,
    bannerActive?.id
  );

  const hasBannerToShow = React.useMemo(
    () => banners?.length !== 0 && !loading,
    [banners?.length, loading]
  );

  const canSetTimer = React.useMemo(
    () => isSlider && isBannerOnScreen && bannerWidth,
    [isBannerOnScreen, bannerWidth, isSlider]
  );

  const onArrowsClick = (type: 'left' | 'right') =>
    ({
      left: () => {
        setActive(active !== 0 ? active - 1 : banners?.length - 1);
        pushEvent(
          'event',
          analyticsCreativesEvents.banner.Com_banner_clique_transicao.event
        );
      },
      right: () => {
        setActive(active + 1 < banners?.length ? active + 1 : 0);
        pushEvent(
          'event',
          analyticsCreativesEvents.banner.Com_banner_clique_transicao.event
        );
      },
    }[type]);

  React.useEffect(() => {
    if (isBannerOnScreen && bannerActive) {
      if (!bannerActive?.read) updateRead();
      pushEvent(
        'event',
        analyticsCreativesEvents.banner.Com_banner_visualizacao.event
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerActive, isBannerOnScreen]);

  React.useEffect(() => {
    if (canSetTimer) {
      const intervalId = setInterval(() => {
        setActive((old) => (old + 1 < banners?.length ? old + 1 : 0));
      }, TIMER);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSetTimer, active]);

  return (banners?.length && isDesktop) || (!isDesktop && !openStickBanner) ? (
    <LayoutContent padding={paddingByBannerWidth}>
      <Styles.BannerContainer
        ref={bannerRef}
        bannerWidth={Boolean(bannerWidth) && hasBannerToShow}
      >
        {isSlider && bannerWidth ? (
          <>
            <Styles.ArrowButton
              aria-label="Banner anterior"
              direction="left"
              onClick={() => onArrowsClick('left')()}
            >
              <SvgArrowLeft fill={theme.colors.icon.primary} />
            </Styles.ArrowButton>

            <Styles.ArrowButton
              aria-label="Próximo banner"
              direction="right"
              onClick={() => onArrowsClick('right')()}
            >
              <SvgArrowLeft fill={theme.colors.icon.primary} />
            </Styles.ArrowButton>

            <Styles.DotsWrapper>
              {banners?.map((_, index) => (
                <Styles.DotPagination key={index} active={index === active} />
              ))}
            </Styles.DotsWrapper>
          </>
        ) : null}

        {bannerWidth ? (
          <Styles.BannerSliderWrapper>
            <Styles.BannerSlider
              bannerWidth={bannerWidth}
              active={active}
              max={banners?.length}
            >
              {banners?.map((banner, index) => (
                <BannerItem
                  key={banner.id}
                  width={bannerWidth}
                  isActive={active === index}
                  selectExamDialog={selectExamDialog}
                  {...banner}
                />
              ))}
            </Styles.BannerSlider>
          </Styles.BannerSliderWrapper>
        ) : null}
      </Styles.BannerContainer>
    </LayoutContent>
  ) : null;
};

export default BannersWrapper;
