export const CREATE_INTERNSHIP = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateInternship"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "subjectEnrollmentId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "companyData"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CompanyDataInput"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "companyResponsible"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CompanyResponsibleInput"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "fieldSupervisor"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "FieldSupervisorInput"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "finishDate"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "weeklySchedule"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "WeeklyScheduleInput"
          }
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createInternship"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "subjectEnrollmentId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "subjectEnrollmentId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "companyData"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "companyData"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "companyResponsible"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "companyResponsible"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "fieldSupervisor"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "fieldSupervisor"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "startDate"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "startDate"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "finishDate"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "finishDate"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "weeklySchedule"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "weeklySchedule"
            }
          }
        }],
        "directives": [],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            },
            "arguments": [],
            "directives": []
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 603,
    "source": {
      "body": "\n  mutation CreateInternship(\n    $subjectEnrollmentId: ID!\n    $companyData: CompanyDataInput!\n    $companyResponsible: CompanyResponsibleInput!\n    $fieldSupervisor: FieldSupervisorInput!\n    $startDate: String!\n    $finishDate: String!\n    $weeklySchedule: WeeklyScheduleInput!\n  ) {\n    createInternship(\n      subjectEnrollmentId: $subjectEnrollmentId\n      companyData: $companyData\n      companyResponsible: $companyResponsible\n      fieldSupervisor: $fieldSupervisor\n      startDate: $startDate\n      finishDate: $finishDate\n      weeklySchedule: $weeklySchedule\n    ) {\n      success\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
export const GET_INTERNSHIP_GUARD = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetSubjectEnrollment"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "subjectEnrollmentId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "data"
        },
        "name": {
          "kind": "Name",
          "value": "getSubjectEnrollment"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "subjectEnrollmentId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "subjectEnrollmentId"
            }
          }
        }],
        "directives": [],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "courseSubject"
            },
            "arguments": [],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "subType"
                },
                "arguments": [],
                "directives": []
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mandatoryInternship"
            },
            "arguments": [],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "consolidatedStatus"
                },
                "arguments": [],
                "directives": []
              }]
            }
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 263,
    "source": {
      "body": "\n  query GetSubjectEnrollment($subjectEnrollmentId: ID!) {\n    data: getSubjectEnrollment(subjectEnrollmentId: $subjectEnrollmentId) {\n      id\n      courseSubject {\n        subType\n      }\n      mandatoryInternship {\n        consolidatedStatus\n      }\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};