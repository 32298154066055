import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["loading", "data"],
  _excluded2 = ["loading", "data"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useQuery } from '../hooks';
import { FIND_AFFILIATE_BY_GEOLOCATION_NEAR, FIND_AFFILIATE_BY_STATUS } from './queries';
export const useFindAffiliatesByGeolocationNear = function (latitude, longitude, courseDegree) {
  let distance = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1000;
  let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  const _useQuery = useQuery(FIND_AFFILIATE_BY_GEOLOCATION_NEAR, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        longitude: longitude,
        latitude: latitude,
        distance: distance,
        courseDegree: courseDegree
      },
      skip: !longitude || !latitude || !distance
    })),
    {
      loading,
      data
    } = _useQuery,
    rest = _objectWithoutProperties(_useQuery, _excluded);
  const exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading
  }, rest);
};
export const useFindAffiliatesByStatus = function (courseDegree, status) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const _useQuery2 = useQuery(FIND_AFFILIATE_BY_STATUS, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        courseDegree: courseDegree,
        status: status
      },
      skip: !courseDegree || !status
    })),
    {
      loading,
      data
    } = _useQuery2,
    rest = _objectWithoutProperties(_useQuery2, _excluded2);
  const exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading
  }, rest);
};