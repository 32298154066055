import React from 'react';

import { useNotifications } from '../../contexts/notification-context';
import { NotificationCard } from '../notification-card';
import { Skeleton } from '../../../../../../components';
import { Creative } from '../../../../types';

import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import * as Styles from './notifications-list.styles';

interface ListProps {
  notifications?: Creative[];
  title?: string;
}

const List: React.FC<ListProps> = ({ notifications, title }: ListProps) => {
  const theme = useTheme();

  return notifications?.length ? (
    <Styles.NotificationsListWrapper>
      {title ? (
        <Typography color={theme.colors.text.lighterBlack} size={14}>
          {title}
        </Typography>
      ) : null}

      <Styles.NotificationsList>
        {notifications.map((notification) => (
          <NotificationCard
            key={notification.id}
            categoryType={notification.categoryType}
            title={notification.title}
            body={notification.body}
            read={notification.read}
            id={notification.id}
          />
        ))}
      </Styles.NotificationsList>
    </Styles.NotificationsListWrapper>
  ) : null;
};

const NotificationsList: React.FC = () => {
  const {
    filteredNotifications,
    previousNotifications,
    recentNotifications,
    loading,
  } = useNotifications();

  const Loader = () => (
    <>
      {[1, 2, 3].map((item) => (
        <Skeleton
          key={item}
          height={120}
          borderRadius={8}
          margin="0px 0px 16px"
        />
      ))}
    </>
  );

  const FilteredNotifications = () => (
    <Styles.NotificationsListWrapper>
      <List notifications={filteredNotifications} />
    </Styles.NotificationsListWrapper>
  );

  if (loading) return <Loader />;

  if (filteredNotifications?.length) return <FilteredNotifications />;

  return (
    <>
      <List title="Recentes" notifications={recentNotifications} />
      <List title="Anteriores" notifications={previousNotifications} />
    </>
  );
};

export default NotificationsList;
