import { GET_INTERNSHIP_GUARD } from '@ampli/services';
import { generatePath } from 'react-router-dom';
import { routePath } from '../routes';

const internshipGuard = async (to, _from, next) => {
  const privateClient = to.meta?.clients?.privateClient;
  const subjectEnrollmentId = to.match?.params?.subjectEnrollmentId;
  const isRegistrationPage = to.meta?.isRegistrationPage;

  try {
    const response = await privateClient.query({
      query: GET_INTERNSHIP_GUARD,
      variables: {
        subjectEnrollmentId: subjectEnrollmentId,
      },
      fetchPolicy: 'cache-first',
    });

    const subjectEnrollment = response?.data?.data;

    if (subjectEnrollment?.courseSubject?.subType !== 'MANDATORY_INTERNSHIP') {
      console.warn(
        'Could not find subject or the subject is not of type internship'
      );
      next.redirect(
        generatePath(routePath.home, {
          courseType: to.meta?.state?.selectedCourseEnrollmentType,
          courseVersionId: to.meta?.state?.selectedCourseVersionId,
        })
      );
    } else if (
      isRegistrationPage &&
      !!subjectEnrollment?.mandatoryInternship &&
      subjectEnrollment.mandatoryInternship.consolidatedStatus !==
        'NOT_RELEASED'
    ) {
      console.warn(
        'Can not access internship registration pages when have already registered or the consolidated status is not NOT_RELEASED'
      );
      next.redirect(
        generatePath(routePath.mandatoryInternshipPage, {
          courseType: to.meta?.state?.selectedCourseEnrollmentType,
          courseVersionId: to.meta?.state?.selectedCourseVersionId,
          subjectEnrollmentId,
        })
      );
    } else {
      next();
    }

    if (!response?.errors) {
      next();
    } else {
      throw response.errors[0];
    }
  } catch (error) {
    console.warn(error);
    next.redirect('/');
  }
};

export default internshipGuard;
