import UAParser from 'ua-parser-js';
import crypto from 'crypto-js';

export const getDevice = () => {
  const uap = new UAParser();
  let result = uap.getResult();
  result.screen = {};
  result.global = {};
  result.global.language = (
    navigator.language ||
    navigator.userLanguage ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    ''
  ).toLowerCase();

  result.screen.colorDepth = screen.colorDepth || -1;
  result.screen.resolution = [screen.availWidth, screen.availHeight];
  result.screen.pixelDepth = screen.pixelDepth || -1;

  result.cpu.cpuCores = navigator.hardwareConcurrency || -1;

  return result;
};

export const getId = () => {
  const seed = JSON.stringify(getDevice());
  let md5Bytes = crypto.MD5(seed);
  md5Bytes[6] &= 0x0f; /* clear version        */
  md5Bytes[6] |= 0x30; /* set to version 3     */
  md5Bytes[8] &= 0x3f; /* clear variant        */
  md5Bytes[8] |= 0x80; /* set to IETF variant  */
  const hex = md5Bytes.toString();
  return hex.replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/, '$1-$2-$3-$4-$5');
};
