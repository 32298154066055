import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const CardContainer = styled.div`
  > div {
    display: flex;
    align-items: center;
    padding: 16px;
    min-height: auto;
    flex-direction: column;
    :not(:last-of-type) {
      margin-bottom: 24px;
    }
    ${mediaQuery('desktop')} {
      padding: 24px;
      flex-direction: row;
    }
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionText = styled.div(
  ({ color, width = 'auto' }) => `
  display: flex;
  align-items: center;
  margin-left: 8px;
  justify-content: flex-end;
  width: ${width};
  white-space: nowrap;
  span {
    color: ${color};
    font-weight: 700;
    font-size: ${convertPXToREM(16)};
    line-height: 115%;
    margin-right: 16px;
  }
  svg path {
    fill: ${color};
  }
`
);
