import React from 'react';

const FileTypes: React.FC = (): React.ReactElement => {
  return (
    <svg
      width="119"
      height="62"
      viewBox="0 0 119 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.1172 21.6966V17.05H22.2345C21.8338 17.0501 21.4496 17.2094 21.1663 17.4927C20.883 17.776 20.7239 18.1603 20.7239 18.561V43.4391C20.7239 43.8398 20.883 44.224 21.1663 44.5074C21.4496 44.7907 21.8338 44.95 22.2345 44.95H40.7635C41.1642 44.9499 41.5485 44.7907 41.8318 44.5073C42.1152 44.224 42.2745 43.8398 42.2748 43.4391V23.2076H37.6282C37.2275 23.2076 36.8431 23.0484 36.5598 22.765C36.2764 22.4817 36.1172 22.0974 36.1172 21.6966ZM26.4827 34.1512C26.44 34.3908 26.2537 34.5576 25.9898 34.5576H25.984C25.6991 34.5576 25.4926 34.3403 25.4926 34.0442C25.4926 33.7373 25.6991 33.5253 25.9948 33.5253C26.2853 33.5253 26.4862 33.7318 26.4917 34.0442C26.492 34.0808 26.4889 34.1168 26.4827 34.1512ZM29.4501 32.772C29.1593 33.0398 28.724 33.1682 28.2218 33.1682C28.1102 33.1682 28.0095 33.1629 27.9314 33.1462V34.4965H27.0885V30.7846C27.3511 30.74 27.719 30.7065 28.2383 30.7065C28.7631 30.7065 29.1429 30.8069 29.394 31.0134C29.6342 31.1978 29.7901 31.5103 29.7904 31.8789C29.7904 32.2528 29.6733 32.5655 29.4501 32.772ZM33.5015 34.4965H32.608L31.8039 33.0448C31.5804 32.6375 31.3299 32.1573 31.1513 31.7165H31.1293C31.1575 32.2196 31.1681 32.7494 31.1681 33.3635V34.4965H30.3869L30.3866 34.4962V30.7341H31.3798V30.7344L31.9772 31.7878L32.1613 32.1126C32.3848 32.5088 32.6028 32.9778 32.7751 33.4019H32.7866C32.7364 32.9053 32.7193 32.3978 32.7193 31.8287V30.7341H33.5015V34.4965ZM37.4347 34.3232C37.1722 34.4128 36.6755 34.5359 36.1786 34.5359C35.4923 34.5359 34.9953 34.3632 34.6494 34.0337C34.3084 33.7042 34.119 33.2134 34.119 32.6601C34.1246 31.4102 35.0344 30.6956 36.2679 30.6956C36.7534 30.6956 37.1275 30.7905 37.3117 30.8742L37.1331 31.5605C36.9266 31.4657 36.6752 31.399 36.2623 31.399C35.5533 31.399 35.0121 31.8008 35.0121 32.6158C35.0121 33.3973 35.5031 33.8493 36.2009 33.8493C36.3907 33.8493 36.5469 33.8272 36.6142 33.7993V33.0064H36.0335V32.3417H37.4347V34.3232Z"
        fill="#001F50"
      />
      <path
        d="M28.3775 32.5042C28.7337 32.4552 28.9476 32.2385 28.9476 31.9062C28.9476 31.82 28.933 31.7422 28.9042 31.674C28.8326 31.5041 28.6729 31.3953 28.4351 31.3637C28.3848 31.357 28.334 31.3538 28.2832 31.3541C28.11 31.3541 27.9928 31.3649 27.9314 31.382V32.4924C28.0036 32.5094 28.0929 32.5147 28.2163 32.5147C28.2727 32.5147 28.3266 32.511 28.3775 32.5042ZM36.1173 17.05V21.6966C36.1173 22.0973 36.2764 22.4816 36.5597 22.7649C36.843 23.0483 37.2272 23.2075 37.6279 23.2076H42.2748L36.1173 17.05Z"
        fill="#001F50"
      />
      <path
        opacity="0.6"
        d="M36.1172 17.05V21.6966C36.1172 22.0973 36.2763 22.4816 36.5596 22.7649C36.8429 23.0483 37.2271 23.2075 37.6278 23.2076H42.2747L36.1172 17.05Z"
        fill="white"
      />
      <path
        d="M26.5892 34.8677V34.4957V34.1694L26.4838 34.1504L26.5892 34.1692V34.0431V34.0414V33.2131M26.5892 34.8677L26.9929 34.0431L26.9927 34.0342C26.988 33.7721 26.8992 33.5152 26.7114 33.32C26.6732 33.2804 26.6324 33.2447 26.5892 33.2131M26.5892 34.8677C26.4251 34.9874 26.2199 35.0568 25.9909 35.0568H25.985C25.4025 35.0568 24.9937 34.5937 24.9937 34.0434C24.9937 33.7759 25.0854 33.5163 25.2749 33.3209C25.4654 33.1243 25.7243 33.0245 25.9959 33.0245C26.2104 33.0245 26.4177 33.0872 26.5892 33.2131M26.5892 34.8677V34.9957H27.0892H27.9321H28.4321V34.4957V33.6608C28.936 33.6284 29.4248 33.475 29.7896 33.139L29.7901 33.1385C29.8246 33.1066 29.8572 33.0733 29.8879 33.0389V34.4957V34.9957H30.3879H31.1691H31.6691V34.4957V33.8307L32.1717 34.738L32.3145 34.9957H32.6091H33.5025H34.0025V34.4957V34.0265C34.0882 34.1588 34.1883 34.2816 34.3028 34.3923L34.3028 34.3923L34.3054 34.3948C34.7726 34.8402 35.4105 35.0351 36.1797 35.0351C36.7468 35.0351 37.2996 34.8976 37.5977 34.7955L37.9358 34.6797V34.3224V32.3409V31.8409H37.5776L37.618 31.6856L37.7966 30.9993L37.9025 30.5922L37.5196 30.4182C37.2571 30.2989 36.8094 30.1948 36.2689 30.1948C35.5587 30.1948 34.897 30.4009 34.4046 30.8322C34.2487 30.9688 34.1141 31.1242 34.0025 31.2963V30.7333V30.2333H33.5025H32.721H32.221V30.7333V31.2013L31.8162 30.487L31.6725 30.2336H31.3812H30.3879H29.8879V30.7336V30.7901C29.8324 30.7288 29.7716 30.6722 29.7058 30.6211C29.3251 30.3116 28.8098 30.2057 28.239 30.2057C27.6994 30.2057 27.3023 30.2404 27.0054 30.2909L26.5892 30.3616V30.7838V33.2131M35.5131 32.615C35.5131 32.5303 35.521 32.4568 35.5346 32.3927V32.8413C35.5209 32.7752 35.5131 32.7001 35.5131 32.615ZM28.437 31.9635C28.4365 31.964 28.435 31.9655 28.4321 31.9677V31.8717C28.4383 31.8738 28.443 31.8757 28.4466 31.8774C28.4476 31.8835 28.4486 31.8928 28.4486 31.9057C28.4486 31.9346 28.444 31.9497 28.442 31.9551C28.4401 31.96 28.4387 31.9617 28.437 31.9635Z"
        fill="#001F50"
        stroke="#001F50"
      />
      <path
        d="M24.5318 30.58H27.5018C28.4818 30.58 28.9718 30.9933 28.9718 31.82V33.66C28.9718 34.4867 28.4818 34.9 27.5018 34.9H26.2818V37H24.5318V30.58ZM27.2218 33.43V32.05C27.2218 31.9033 27.1385 31.83 26.9718 31.83H26.2818V33.65H26.9718C27.1385 33.65 27.2218 33.5767 27.2218 33.43ZM29.6709 30.58H32.6209C33.6009 30.58 34.0909 30.9933 34.0909 31.82V35.76C34.0909 36.5867 33.6009 37 32.6209 37H29.6709V30.58ZM32.3409 35.63V31.95C32.3409 31.8033 32.2576 31.73 32.0909 31.73H31.4209V35.85H32.0909C32.2576 35.85 32.3409 35.7767 32.3409 35.63ZM38.7139 31.83H36.6139V33.39H38.4339V34.54H36.6139V37H34.8639V30.58H38.7139V31.83Z"
        fill="white"
      />
      <path
        d="M64.1172 21.6966V17.05H50.2345C49.8338 17.0501 49.4496 17.2094 49.1663 17.4927C48.883 17.776 48.7239 18.1603 48.7239 18.561V43.4391C48.7239 43.8398 48.883 44.224 49.1663 44.5074C49.4496 44.7907 49.8338 44.95 50.2345 44.95H68.7635C69.1642 44.9499 69.5485 44.7907 69.8318 44.5073C70.1152 44.224 70.2745 43.8398 70.2748 43.4391V23.2076H65.6282C65.2275 23.2076 64.8431 23.0484 64.5598 22.765C64.2764 22.4817 64.1172 22.0974 64.1172 21.6966ZM54.4827 34.1512C54.44 34.3908 54.2537 34.5576 53.9898 34.5576H53.984C53.6991 34.5576 53.4926 34.3403 53.4926 34.0442C53.4926 33.7373 53.6991 33.5253 53.9948 33.5253C54.2853 33.5253 54.4862 33.7318 54.4917 34.0442C54.492 34.0808 54.4889 34.1168 54.4827 34.1512ZM57.4501 32.772C57.1593 33.0398 56.724 33.1682 56.2218 33.1682C56.1102 33.1682 56.0095 33.1629 55.9314 33.1462V34.4965H55.0885V30.7846C55.3511 30.74 55.719 30.7065 56.2383 30.7065C56.7631 30.7065 57.1429 30.8069 57.394 31.0134C57.6342 31.1978 57.7901 31.5103 57.7904 31.8789C57.7904 32.2528 57.6733 32.5655 57.4501 32.772ZM61.5015 34.4965H60.608L59.8039 33.0448C59.5804 32.6375 59.3299 32.1573 59.1513 31.7165H59.1293C59.1575 32.2196 59.1681 32.7494 59.1681 33.3635V34.4965H58.3869L58.3866 34.4962V30.7341H59.3798V30.7344L59.9772 31.7878L60.1613 32.1126C60.3848 32.5088 60.6028 32.9778 60.7751 33.4019H60.7866C60.7364 32.9053 60.7193 32.3978 60.7193 31.8287V30.7341H61.5015V34.4965ZM65.4347 34.3232C65.1722 34.4128 64.6755 34.5359 64.1786 34.5359C63.4923 34.5359 62.9953 34.3632 62.6494 34.0337C62.3084 33.7042 62.119 33.2134 62.119 32.6601C62.1246 31.4102 63.0344 30.6956 64.2679 30.6956C64.7534 30.6956 65.1275 30.7905 65.3117 30.8742L65.1331 31.5605C64.9266 31.4657 64.6752 31.399 64.2623 31.399C63.5533 31.399 63.0121 31.8008 63.0121 32.6158C63.0121 33.3973 63.5031 33.8493 64.2009 33.8493C64.3907 33.8493 64.5469 33.8272 64.6142 33.7993V33.0064H64.0335V32.3417H65.4347V34.3232Z"
        fill="#004ECC"
      />
      <path
        d="M56.3775 32.5042C56.7337 32.4552 56.9476 32.2385 56.9476 31.9062C56.9476 31.82 56.933 31.7422 56.9042 31.674C56.8326 31.5041 56.6729 31.3953 56.4351 31.3637C56.3848 31.357 56.334 31.3538 56.2832 31.3541C56.11 31.3541 55.9928 31.3649 55.9314 31.382V32.4924C56.0036 32.5094 56.0929 32.5147 56.2163 32.5147C56.2727 32.5147 56.3266 32.511 56.3775 32.5042ZM64.1173 17.05V21.6966C64.1173 22.0973 64.2764 22.4816 64.5597 22.7649C64.843 23.0483 65.2272 23.2075 65.6279 23.2076H70.2748L64.1173 17.05Z"
        fill="#004ECC"
      />
      <path
        opacity="0.6"
        d="M64.1172 17.05V21.6966C64.1172 22.0973 64.2763 22.4816 64.5596 22.7649C64.8429 23.0483 65.2271 23.2075 65.6278 23.2076H70.2747L64.1172 17.05Z"
        fill="white"
      />
      <path
        d="M54.5892 34.8677V34.4957V34.1694L54.4838 34.1504L54.5892 34.1692V34.0431V34.0414V33.2131M54.5892 34.8677L54.9929 34.0431L54.9927 34.0342C54.988 33.7721 54.8992 33.5152 54.7114 33.32C54.6732 33.2804 54.6324 33.2447 54.5892 33.2131M54.5892 34.8677C54.4251 34.9874 54.2199 35.0568 53.9909 35.0568H53.985C53.4025 35.0568 52.9937 34.5937 52.9937 34.0434C52.9937 33.7759 53.0854 33.5163 53.2749 33.3209C53.4654 33.1243 53.7243 33.0245 53.9959 33.0245C54.2104 33.0245 54.4177 33.0872 54.5892 33.2131M54.5892 34.8677V34.9957H55.0892H55.9321H56.4321V34.4957V33.6608C56.936 33.6284 57.4248 33.475 57.7896 33.139L57.7901 33.1385C57.8246 33.1066 57.8572 33.0733 57.8879 33.0389V34.4957V34.9957H58.3879H59.1691H59.6691V34.4957V33.8307L60.1717 34.738L60.3145 34.9957H60.6091H61.5025H62.0025V34.4957V34.0265C62.0882 34.1588 62.1883 34.2816 62.3028 34.3923L62.3028 34.3923L62.3054 34.3948C62.7726 34.8402 63.4105 35.0351 64.1797 35.0351C64.7468 35.0351 65.2996 34.8976 65.5977 34.7955L65.9358 34.6797V34.3224V32.3409V31.8409H65.5776L65.618 31.6856L65.7966 30.9993L65.9025 30.5922L65.5196 30.4182C65.2571 30.2989 64.8094 30.1948 64.2689 30.1948C63.5587 30.1948 62.897 30.4009 62.4046 30.8322C62.2487 30.9688 62.1141 31.1242 62.0025 31.2963V30.7333V30.2333H61.5025H60.721H60.221V30.7333V31.2013L59.8162 30.487L59.6725 30.2336H59.3812H58.3879H57.8879V30.7336V30.7901C57.8324 30.7288 57.7716 30.6722 57.7058 30.6211C57.3251 30.3116 56.8098 30.2057 56.239 30.2057C55.6994 30.2057 55.3023 30.2404 55.0054 30.2909L54.5892 30.3616V30.7838V33.2131M63.5131 32.615C63.5131 32.5303 63.521 32.4568 63.5346 32.3927V32.8413C63.5209 32.7752 63.5131 32.7001 63.5131 32.615ZM56.437 31.9635C56.4365 31.964 56.435 31.9655 56.4321 31.9677V31.8717C56.4383 31.8738 56.443 31.8757 56.4466 31.8774C56.4476 31.8835 56.4486 31.8928 56.4486 31.9057C56.4486 31.9346 56.444 31.9497 56.442 31.9551C56.4401 31.96 56.4387 31.9617 56.437 31.9635Z"
        fill="#004ECC"
        stroke="#004ECC"
      />
      <path
        d="M52.2904 34.43H54.0404V35.53C54.0404 35.6767 54.1238 35.75 54.2904 35.75H54.4004C54.5671 35.75 54.6504 35.6767 54.6504 35.53V30.58H56.4004V35.76C56.4004 36.5867 55.9104 37 54.9304 37H53.7604C52.7804 37 52.2904 36.5867 52.2904 35.76V34.43ZM57.1607 30.58H60.1307C61.1107 30.58 61.6007 30.9933 61.6007 31.82V33.66C61.6007 34.4867 61.1107 34.9 60.1307 34.9H58.9107V37H57.1607V30.58ZM59.8507 33.43V32.05C59.8507 31.9033 59.7674 31.83 59.6007 31.83H58.9107V33.65H59.6007C59.7674 33.65 59.8507 33.5767 59.8507 33.43ZM66.5098 32.78H64.7598V31.95C64.7598 31.8033 64.6765 31.73 64.5098 31.73H64.1498C63.9831 31.73 63.8998 31.8033 63.8998 31.95V35.63C63.8998 35.7767 63.9831 35.85 64.1498 35.85H64.5098C64.6765 35.85 64.7598 35.7767 64.7598 35.63V34.56H64.2598V33.45H66.5098V35.76C66.5098 36.5867 66.0198 37 65.0398 37H63.6198C62.6398 37 62.1498 36.5867 62.1498 35.76V31.82C62.1498 30.9933 62.6398 30.58 63.6198 30.58H65.0398C66.0198 30.58 66.5098 30.9933 66.5098 31.82V32.78Z"
        fill="white"
      />
      <path
        d="M92.1172 21.6966V17.05H78.2345C77.8338 17.0501 77.4496 17.2094 77.1663 17.4927C76.883 17.776 76.7239 18.1603 76.7239 18.561V43.4391C76.7239 43.8398 76.883 44.224 77.1663 44.5074C77.4496 44.7907 77.8338 44.95 78.2345 44.95H96.7635C97.1642 44.9499 97.5485 44.7907 97.8318 44.5073C98.1152 44.224 98.2745 43.8398 98.2748 43.4391V23.2076H93.6282C93.2275 23.2076 92.8431 23.0484 92.5598 22.765C92.2764 22.4817 92.1172 22.0974 92.1172 21.6966ZM82.4827 34.1512C82.44 34.3908 82.2537 34.5576 81.9898 34.5576H81.984C81.6991 34.5576 81.4926 34.3403 81.4926 34.0442C81.4926 33.7373 81.6991 33.5253 81.9948 33.5253C82.2853 33.5253 82.4862 33.7318 82.4917 34.0442C82.492 34.0808 82.4889 34.1168 82.4827 34.1512ZM85.4501 32.772C85.1593 33.0398 84.724 33.1682 84.2218 33.1682C84.1102 33.1682 84.0095 33.1629 83.9314 33.1462V34.4965H83.0885V30.7846C83.3511 30.74 83.719 30.7065 84.2383 30.7065C84.7631 30.7065 85.1429 30.8069 85.394 31.0134C85.6342 31.1978 85.7901 31.5103 85.7904 31.8789C85.7904 32.2528 85.6733 32.5655 85.4501 32.772ZM89.5015 34.4965H88.608L87.8039 33.0448C87.5804 32.6375 87.3299 32.1573 87.1513 31.7165H87.1293C87.1575 32.2196 87.1681 32.7494 87.1681 33.3635V34.4965H86.3869L86.3866 34.4962V30.7341H87.3798V30.7344L87.9772 31.7878L88.1613 32.1126C88.3848 32.5088 88.6028 32.9778 88.7751 33.4019H88.7866C88.7364 32.9053 88.7193 32.3978 88.7193 31.8287V30.7341H89.5015V34.4965ZM93.4347 34.3232C93.1722 34.4128 92.6755 34.5359 92.1786 34.5359C91.4923 34.5359 90.9953 34.3632 90.6494 34.0337C90.3084 33.7042 90.119 33.2134 90.119 32.6601C90.1246 31.4102 91.0344 30.6956 92.2679 30.6956C92.7534 30.6956 93.1275 30.7905 93.3117 30.8742L93.1331 31.5605C92.9266 31.4657 92.6752 31.399 92.2623 31.399C91.5533 31.399 91.0121 31.8008 91.0121 32.6158C91.0121 33.3973 91.5031 33.8493 92.2009 33.8493C92.3907 33.8493 92.5469 33.8272 92.6142 33.7993V33.0064H92.0335V32.3417H93.4347V34.3232Z"
        fill="#7389FF"
      />
      <path
        d="M84.3775 32.5042C84.7337 32.4552 84.9476 32.2385 84.9476 31.9062C84.9476 31.82 84.933 31.7422 84.9042 31.674C84.8326 31.5041 84.6729 31.3953 84.4351 31.3637C84.3848 31.357 84.334 31.3538 84.2832 31.3541C84.11 31.3541 83.9928 31.3649 83.9314 31.382V32.4924C84.0036 32.5094 84.0929 32.5147 84.2163 32.5147C84.2727 32.5147 84.3266 32.511 84.3775 32.5042ZM92.1173 17.05V21.6966C92.1173 22.0973 92.2764 22.4816 92.5597 22.7649C92.843 23.0483 93.2272 23.2075 93.6279 23.2076H98.2748L92.1173 17.05Z"
        fill="#7389FF"
      />
      <path
        opacity="0.6"
        d="M92.1172 17.05V21.6966C92.1172 22.0973 92.2763 22.4816 92.5596 22.7649C92.8429 23.0483 93.2271 23.2075 93.6278 23.2076H98.2747L92.1172 17.05Z"
        fill="white"
      />
      <path
        d="M82.5892 34.8677V34.4957V34.1694L82.4838 34.1504L82.5892 34.1692V34.0431V34.0414V33.2131M82.5892 34.8677L82.9929 34.0431L82.9927 34.0342C82.988 33.7721 82.8992 33.5152 82.7114 33.32C82.6732 33.2804 82.6324 33.2447 82.5892 33.2131M82.5892 34.8677C82.4251 34.9874 82.2199 35.0568 81.9909 35.0568H81.985C81.4025 35.0568 80.9937 34.5937 80.9937 34.0434C80.9937 33.7759 81.0854 33.5163 81.2749 33.3209C81.4654 33.1243 81.7243 33.0245 81.9959 33.0245C82.2104 33.0245 82.4177 33.0872 82.5892 33.2131M82.5892 34.8677V34.9957H83.0892H83.9321H84.4321V34.4957V33.6608C84.936 33.6284 85.4248 33.475 85.7896 33.139L85.7901 33.1385C85.8246 33.1066 85.8572 33.0733 85.8879 33.0389V34.4957V34.9957H86.3879H87.1691H87.6691V34.4957V33.8307L88.1717 34.738L88.3145 34.9957H88.6091H89.5025H90.0025V34.4957V34.0265C90.0882 34.1588 90.1883 34.2816 90.3028 34.3923L90.3028 34.3923L90.3054 34.3948C90.7726 34.8402 91.4105 35.0351 92.1797 35.0351C92.7468 35.0351 93.2996 34.8976 93.5977 34.7955L93.9358 34.6797V34.3224V32.3409V31.8409H93.5776L93.618 31.6856L93.7966 30.9993L93.9025 30.5922L93.5196 30.4182C93.2571 30.2989 92.8094 30.1948 92.2689 30.1948C91.5587 30.1948 90.897 30.4009 90.4046 30.8322C90.2487 30.9688 90.1141 31.1242 90.0025 31.2963V30.7333V30.2333H89.5025H88.721H88.221V30.7333V31.2013L87.8162 30.487L87.6725 30.2336H87.3812H86.3879H85.8879V30.7336V30.7901C85.8324 30.7288 85.7716 30.6722 85.7058 30.6211C85.3251 30.3116 84.8098 30.2057 84.239 30.2057C83.6994 30.2057 83.3023 30.2404 83.0054 30.2909L82.5892 30.3616V30.7838V33.2131M91.5131 32.615C91.5131 32.5303 91.521 32.4568 91.5346 32.3927V32.8413C91.5209 32.7752 91.5131 32.7001 91.5131 32.615ZM84.437 31.9635C84.4365 31.964 84.435 31.9655 84.4321 31.9677V31.8717C84.4383 31.8738 84.443 31.8757 84.4466 31.8774C84.4476 31.8835 84.4486 31.8928 84.4486 31.9057C84.4486 31.9346 84.444 31.9497 84.442 31.9551C84.4401 31.96 84.4387 31.9617 84.437 31.9635Z"
        fill="#7389FF"
        stroke="#7389FF"
      />
      <path
        d="M79.7096 30.58H82.6796C83.6596 30.58 84.1496 30.9933 84.1496 31.82V33.66C84.1496 34.4867 83.6596 34.9 82.6796 34.9H81.4596V37H79.7096V30.58ZM82.3996 33.43V32.05C82.3996 31.9033 82.3162 31.83 82.1496 31.83H81.4596V33.65H82.1496C82.3162 33.65 82.3996 33.5767 82.3996 33.43ZM87.6786 30.58H89.2786V37H87.7786L86.3486 33.63V37H84.7486V30.58H86.2486L87.6786 34.01V30.58ZM94.3907 32.78H92.6407V31.95C92.6407 31.8033 92.5573 31.73 92.3907 31.73H92.0307C91.864 31.73 91.7807 31.8033 91.7807 31.95V35.63C91.7807 35.7767 91.864 35.85 92.0307 35.85H92.3907C92.5573 35.85 92.6407 35.7767 92.6407 35.63V34.56H92.1407V33.45H94.3907V35.76C94.3907 36.5867 93.9007 37 92.9207 37H91.5007C90.5207 37 90.0307 36.5867 90.0307 35.76V31.82C90.0307 30.9933 90.5207 30.58 91.5007 30.58H92.9207C93.9007 30.58 94.3907 30.9933 94.3907 31.82V32.78Z"
        fill="white"
      />
    </svg>
  );
};

export default FileTypes;
