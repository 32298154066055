import { checkRedirect, elementExists, waitForElement } from './utils';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

export const useTourControls = (id, steps) => {
  const history = useHistory();
  const [tour, setTour] = useState({
    run: false,
    stepIndex: 0,
  });

  const checkActiveTour = () => {
    const isTourActive = localStorage.getItem('isTourActive');
    const tourActive = localStorage.getItem('tourActive');
    if (isTourActive && tourActive !== id)
      console.warn('Another tour is already active', tourActive);

    return { canStartTour: !isTourActive, tourActive };
  };

  const _setState = (newState) => {
    const tourActive = checkActiveTour();
    if (tourActive.canStartTour && newState?.run === true) {
      localStorage.setItem('tourActive', id);
      localStorage.setItem('isTourActive', true);
      window.dispatchEvent(new Event('hasActiveTour'));
    } else if (tourActive.tourActive === id && newState?.run === false) {
      localStorage.removeItem('tourActive');
      localStorage.removeItem('isTourActive');
    }
    newState.run =
      newState.run && (tourActive.canStartTour || tourActive.tourActive === id);
    setTour(newState);
  };

  const start = (index = tour.stepIndex) => {
    if (checkRedirect(steps[index]?.redirect)) {
      history.replace(steps[index]?.redirect);
    }
    if (steps[index]?.executeBefore) {
      steps[index]?.executeBefore();
    }
    if (!elementExists(steps[index]?.target) && steps[index]?.waitForElement) {
      waitForElement(steps[index]?.target)
        .then(() =>
          _setState({
            run: true,
            stepIndex: index,
          })
        )
        .catch(() => {});
    } else if (
      elementExists(steps[index]?.target) ||
      !steps[index]?.waitForElement
    ) {
      _setState({
        run: true,
        stepIndex: index,
      });
    }
  };

  const stop = (index = tour.stepIndex) => {
    _setState({
      run: false,
      stepIndex: index,
    });
  };

  const reset = () => {
    _setState({
      run: false,
      stepIndex: 0,
    });
  };

  const next = (index = tour.stepIndex) => {
    if (!elementExists(steps[index]?.target) && steps[index]?.waitForElement) {
      waitForElement(steps[index]?.target)
        .then(() =>
          _setState({
            run: tour.run || false,
            stepIndex: (index || 0) + 1,
          })
        )
        .catch(() => {});
    } else if (
      elementExists(steps[index]?.target) ||
      !steps[index]?.waitForElement
    ) {
      _setState({
        run: tour.run || false,
        stepIndex: (index || 0) + 1,
      });
    }
  };

  const back = (index = tour.stepIndex) => {
    if (!elementExists(steps[index]?.target) && steps[index]?.waitForElement) {
      waitForElement(steps[index]?.target)
        .then(() =>
          _setState({
            run: tour.run || false,
            stepIndex: (index || 0) - 1,
          })
        )
        .catch(() => {});
    } else if (
      elementExists(steps[index]?.target) ||
      !steps[index]?.waitForElement
    ) {
      _setState({
        run: tour.run || false,
        stepIndex: (index || 0) - 1,
      });
    }
  };

  const goTo = (stepIndex) => {
    _setState({
      run: tour.run || false,
      stepIndex: stepIndex,
    });
  };

  return {
    start,
    stop,
    reset,
    next,
    back,
    goTo,
    checkActiveTour,
    state: tour,
  };
};
