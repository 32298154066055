import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const TitleAndSubtitleContainer = styled.div`
  margin: 40px auto;
  padding: 0 16px;

  ${mediaQuery('desktop')} {
    display: flex;
    margin: 56px auto;
    padding: 0;
  }
`;

export const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.span`
  color: ${({ color }) => color};
  font-weight: 700;
  font-size: ${convertPXToREM(24)};

  ${mediaQuery('desktop')} {
    font-size: ${convertPXToREM(48)};
    &.ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
`;

export const Subtitle = styled.span`
  color: ${({ color }) => color};
  font-weight: 400;
  font-size: ${convertPXToREM(16)};
  line-height: 150%;
  margin-top: 8px;

  ${mediaQuery('desktop')} {
    font-size: ${convertPXToREM(20)};
    line-height: 140%;
  }
`;
