import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["data"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useQuery } from '../hooks';
import { GET_BANNER_BY_LOCATION } from './queries';
export const useGetBannerByLocation = function (location) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery = useQuery(GET_BANNER_BY_LOCATION, _objectSpread(_objectSpread({
      skip: !location
    }, options), {}, {
      variables: {
        location
      },
      fetchPolicy: 'no-cache'
    })),
    {
      data
    } = _useQuery,
    rest = _objectWithoutProperties(_useQuery, _excluded);
  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};