import React from 'react';

import { generatePath, useHistory } from 'react-router-dom';
import { useMeContext } from '../../../../modules/shared/contexts/me-context';

import {
  useListPendingRematriculaById,
  useUpdateReadCreative,
  useGetCourseRoles,
} from '../../services/hooks';

import { analyticsCreativesEvents } from '../../../shared/utils/communication-constants';
import { useGetState } from '../../../../state';
import { pushEvent } from '../../../../lib/ga';
import { routePath } from '../../../../routes';
import { Creative } from '../../types';

type CourseTypeAndRoles = {
  roles: string[];
  course: { id: string; courseType: { code: string }; versionId: string };
};

type UseStickBanner = {
  listPendingEnroll: Creative[];
  canShowStickyBanner: boolean;
  hasPendingEnroll: boolean;
  pendingEnroll: Creative;
  openStickBanner: boolean;
  updateRead: () => void;
  onCtaClick: () => void;
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const STORAGE_KEY = 'sticky-banner-communication';
const STORAGE_KEY_CLICK = 'sticky-banner-communication-click';
const today = new Date().toLocaleDateString();

export const useStickBanner = (): UseStickBanner => {
  const [openStickBanner, setOpenStickBanner] = React.useState(false);

  const history = useHistory();

  const canStickyBanner = sessionStorage.getItem(STORAGE_KEY);
  const stickyBannerClickControl = sessionStorage.getItem(STORAGE_KEY_CLICK);
  const stickyBannerDateControl = localStorage.getItem(STORAGE_KEY);
  const { states: statesMe } = useMeContext();
  const { me } = statesMe.getMe;

  const studentCPF = me?.username;

  const [{ selectedCourseEnrollmentId }] = useGetState();

  const { data: courseEnrollment } = useGetCourseRoles<CourseTypeAndRoles>(
    selectedCourseEnrollmentId
  );

  const { data: listPendingEnroll } = useListPendingRematriculaById<Creative[]>(
    courseEnrollment?.course?.courseType?.code,
    courseEnrollment.course?.versionId,
    courseEnrollment?.course?.id,
    studentCPF
  );

  const pendingEnroll = listPendingEnroll?.[0];

  const [updateRead] = useUpdateReadCreative(studentCPF, pendingEnroll?.id);

  const onClose = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      sessionStorage.setItem(STORAGE_KEY, 'false');
      localStorage.setItem(STORAGE_KEY, today);
      sessionStorage.setItem(STORAGE_KEY_CLICK, 'clicked');
      setOpenStickBanner(false);

      pushEvent(
        'event',
        analyticsCreativesEvents.rematBanner.Com_contrato_rematricula_clique
          .event,
        {
          item: analyticsCreativesEvents.rematBanner.Com_contrato_rematricula_clique.param(
            'fechar'
          ),
        }
      );
    },
    []
  );

  const hasPendingEnroll = listPendingEnroll?.length > 0;

  const isAmpli = courseEnrollment?.roles?.includes('CHAT_ENABLED');

  const canShowStickyBanner =
    (!isAmpli && stickyBannerClickControl !== 'clicked') ||
    canStickyBanner !== 'false' ||
    stickyBannerDateControl !== today;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [courseType, courseVersionId, termsPath, contractPath, contractId] =
    pendingEnroll?.ctaLink?.replace('/', '').split('/') || [];

  const onCtaClick = () => {
    pushEvent(
      'event',
      analyticsCreativesEvents.rematBanner.Com_contrato_rematricula_clique
        .event,
      {
        item: analyticsCreativesEvents.rematBanner.Com_contrato_rematricula_clique.param(
          'cta'
        ),
      }
    );

    return history.push(
      generatePath(routePath.termsAndContractsDetails, {
        courseType,
        courseVersionId,
        contractId,
      })
    );
  };

  React.useEffect(() => {
    if (canShowStickyBanner && hasPendingEnroll) {
      setOpenStickBanner(true);
    }
  }, [canShowStickyBanner, hasPendingEnroll]);

  React.useLayoutEffect(() => {
    if (openStickBanner) {
      sessionStorage.setItem(STORAGE_KEY, 'false');
      localStorage.setItem(STORAGE_KEY, today);

      pushEvent(
        'event',
        analyticsCreativesEvents.rematBanner
          .Com_contrato_rematricula_visualizacao.event
      );
    }
  }, [openStickBanner]);

  React.useLayoutEffect(() => {
    if (openStickBanner && !pendingEnroll?.read) updateRead();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openStickBanner, pendingEnroll?.read]);

  return {
    canShowStickyBanner,
    listPendingEnroll,
    hasPendingEnroll,
    openStickBanner,
    pendingEnroll,
    updateRead,
    onCtaClick,
    onClose,
  };
};
