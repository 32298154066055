import { isAmpliProduction, isDebug } from '../config';
import {
  ampliProductionUrl,
  anhangueraProductionUrl,
  homologUrl,
} from '../constants';

const isAmpli =
  window.location.origin === ampliProductionUrl ||
  window.location.origin === homologUrl;
const isAnhanguera = window.location.origin === anhangueraProductionUrl;

const gaEventTracking = (action, label) => {
  function gtag() {
    window.dataLayer.push(arguments);
  }

  if (isAmpliProduction) {
    window.dataLayer = window.dataLayer || [];
    gtag(action, label);
  }
};

export const pushHomologEvent = (action, label, params) => {
  try {
    window.dataLayer = window.dataLayer || [];

    window.gtag(action, label, { ...params });
  } catch (error) {
    console.error('error: pushHomologEvent', error);
  }
};

export const pushAmpliEvent = (action, label, params) => {
  try {
    if (isAmpli) {
      window.dataLayer = window.dataLayer || [];

      window.gtag(action, label, { ...params });
    }
  } catch (error) {
    console.error('error: pushAmpliEvent', error);
  }
};

export const pushAnhangueraEvent = (action, label, params) => {
  try {
    if (isAnhanguera) {
      window.dataLayer = window.dataLayer || [];

      window.gtag(action, label, { ...params });
    } else if (!isAmpli && !isAnhanguera) {
      pushHomologEvent(action, label);
    }
  } catch (error) {
    console.error('error: pushAnhangueraEvent', error);
  }
};

export const pushEvent = isDebug
  ? pushHomologEvent
  : isAmpli
  ? pushAmpliEvent
  : pushAnhangueraEvent;

export default gaEventTracking;
