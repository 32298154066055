import React from 'react';

import {
  ButtonPrimaryLong,
  ButtonGhostLong,
  LineSteps,
  Toast,
} from '@sofia/ui';

import { usePublicationFormContext } from '../../../../contexts/publication-form-context';
import { TextStep, CategoryStep } from '../form-steps';
import { useTheme } from '@emotion/react';

import usePublicationFormLogic from './publication-form.logic';
import WaitingPublication from '../status-modals/waiting-publish';
import ErrorPublication from '../status-modals/error-publication';

import * as Styles from './publication-form.styles';

const PublicationFormSection = (): React.ReactElement => {
  const theme = useTheme();

  const {
    states: { isTextStep, isCategoryStep, step },
  } = usePublicationFormContext();

  const {
    states: {
      isSectionPageDataLoading,
      isButtonEnabled,
      waitingModal,
      errorModal,
      hasSuceeded,
    },
    actions: {
      getSecondaryButtonText,
      handleSecondaryButton,
      getPrimaryButtonText,
      handleButtonPrimary,
      setIsButtonEnabled,
    },
  } = usePublicationFormLogic();

  return (
    <Styles.Content>
      <Styles.Body isTextStep={isTextStep}>
        <LineSteps
          step={step}
          margin={isTextStep ? '24px 0' : '0 0 24px'}
          padding={isTextStep ? '0 24px' : '0'}
          activeColor={theme.colors.slide.dots.active}
          inactiveColor={theme.colors.slide.dots.inactive}
        />

        <CategoryStep isVisible={isCategoryStep} />

        <TextStep
          handleSetIsButtonEnabled={setIsButtonEnabled}
          isVisible={isTextStep}
        />
      </Styles.Body>

      <Styles.StyledSeparator />

      <Styles.Footer>
        <ButtonPrimaryLong
          data-testid="community-publication-creation-category-next"
          color={theme.colors.text.white}
          background={theme.colors.button.primary}
          onClick={handleButtonPrimary}
          disabled={
            (!isButtonEnabled && isTextStep) || isSectionPageDataLoading
          }
        >
          {getPrimaryButtonText()}
        </ButtonPrimaryLong>
        <ButtonGhostLong
          data-testid="community-publication-creation-category-cancel"
          color={theme.colors.button.primary}
          onClick={handleSecondaryButton}
        >
          {getSecondaryButtonText()}
        </ButtonGhostLong>
      </Styles.Footer>
      <WaitingPublication modal={waitingModal} />
      <ErrorPublication modal={errorModal} />
      <Toast
        type="success"
        show={hasSuceeded}
        text="Comentário publicado com sucesso!"
      />
    </Styles.Content>
  );
};

export default PublicationFormSection;
