/* eslint-disable prettier/prettier */
import React from 'react';

import {
  useCommunicationCapabilities,
  ICommunicationCapabilities,
} from '../../..';

export type RolesContextType = ICommunicationCapabilities & {
  hasWelcomeOnboardingTourEnabled: boolean;
  hasSubjectOnboardingTourEnabled: boolean;
};

export interface RolesProviderProps {
  children: React.ReactNode;
}

export const RolesContext = React.createContext<RolesContextType>(
  {} as RolesContextType
);

const RolesProvider = ({ children }: RolesProviderProps): JSX.Element => {
  const { communicationCapabilities, loadingCapabilities } =
    useCommunicationCapabilities();

  const hasWelcomeOnboardingTourEnabled = Boolean(
    !loadingCapabilities &&
    communicationCapabilities?.platform.PLATFORM_ONBOARDING_TOUR_ENABLED
  );

  const hasSubjectOnboardingTourEnabled = Boolean(
    !loadingCapabilities &&
    communicationCapabilities?.course.ONBOARDING_TOUR_ENABLED
  );

  return (
    <RolesContext.Provider
      value={{
        hasSubjectOnboardingTourEnabled,
        hasWelcomeOnboardingTourEnabled,
        communicationCapabilities,
        loadingCapabilities,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

export const useRolesContext = (): RolesContextType =>
  React.useContext(RolesContext);

export const withRolesProvider = (
  children: React.ReactNode
): React.ReactNode => <RolesProvider>{children}</RolesProvider>;

export default RolesProvider;
