import styled from '@emotion/styled';

import { convertPXToVW, convertPXToVH } from '@sofia/ui';
import { mediaQuery } from '@ampli/ui';
import { keyframes } from '@emotion/react';

const enteringAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const leavingAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10%);
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 100px;
  right: 100px;

  display: flex;
  flex-flow: column;

  background: #ffffff;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  width: ${convertPXToVW(536)};
  height: ${convertPXToVH(640)};
  z-index: 999;

  animation: ${enteringAnimation} 150ms ease-in-out;

  &.leaving {
    animation: ${leavingAnimation} 150ms ease-in-out;
  }

  ${mediaQuery('tablet-max')} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
  }
`;

export const Content = styled.div`
  padding: 24px;
  overflow-y: auto;
  flex: auto;
`;

export const CloseButton = styled.button`
  border: none;
  border-radius: 999px;
  background: none;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 24px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #b1c2d5;
`;

export const BackButton = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
`;
