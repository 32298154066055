import React from 'react';

import { Header, Breadcrumbs, Typography, Banner } from '@sofia/ui';
import { string, object } from 'prop-types';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';

import { LayoutContent } from '../layout';

const GradeHomeHeader = ({ homePath, history }) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const breadcrumbsComponent = (
    <Breadcrumbs
      secondColor={theme.colors.breadCrumbs.secondary}
      firstColor={theme.colors.breadCrumbs.primary}
      fill={theme.colors.breadCrumbs.primary}
      stroke={theme.colors.breadCrumbs.primary}
      shadow={false}
      backClick={() => history.goBack()}
      margin="0px"
    >
      <Link to={homePath}>
        <span>Cursos</span>
      </Link>

      <p>Notas e correções</p>
    </Breadcrumbs>
  );

  return (
    <>
      <Banner background={theme.colors.banner.primary}>
        <LayoutContent>
          <Header
            description=""
            title=""
            titleColor={theme.colors.text.secondary}
            descriptionColor={theme.colors.text.light}
            background="none"
            breadCrumbs={breadcrumbsComponent}
            padding="0px"
            lastChildren
          />
        </LayoutContent>
      </Banner>
      <LayoutContent>
        <div>
          <Typography
            id="notes-corrections-title"
            as="h1"
            size={isDesktop ? 48 : 32}
            weight="bold"
          >
            Notas e correções
          </Typography>
        </div>
      </LayoutContent>
    </>
  );
};

GradeHomeHeader.propTypes = {
  homePath: string.isRequired,
  history: object,
};

export default GradeHomeHeader;
