import { REGEX_CPF } from '../constants';

export const validateCPF = (inputCPF) => {
  let sum = 0;
  let remainder;

  if (inputCPF == '00000000000') return false;

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
  }
  remainder = (sum * 10) % 11;

  if (remainder == 10 || remainder == 11) {
    remainder = 0;
  }
  if (remainder != parseInt(inputCPF.substring(9, 10))) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
  }
  remainder = (sum * 10) % 11;

  if (remainder == 10 || remainder == 11) remainder = 0;
  if (remainder != parseInt(inputCPF.substring(10, 11))) {
    return false;
  }
  return true;
};

export const validateCPFLength = (e, setError) => {
  if (REGEX_CPF.test(e.target.value) !== true) {
    setError(true);
  } else {
    setError(false);
  }
};
