import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["data"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useQuery, useMutation, useServiceClient } from '../hooks';
import { FINISH_EXAM, EXAM_FRAGMENT, GET_EXAM, CREATE_EXAM_BY_ASSIGNMENT } from './queries';
export const useFinishExam = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(FINISH_EXAM, options);
};
export const useGetExam = function (examId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery = useQuery(GET_EXAM, _objectSpread(_objectSpread({
      skip: !examId
    }, options), {}, {
      variables: {
        examId
      }
    })),
    {
      data
    } = _useQuery,
    rest = _objectWithoutProperties(_useQuery, _excluded);
  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export const useGetLocalExam = function (examId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const privateClient = useServiceClient('privateClient');
  return examId ? privateClient.readFragment(_objectSpread(_objectSpread({}, options), {}, {
    id: `Exam:${examId}`,
    fragment: EXAM_FRAGMENT
  })) : undefined;
};
export const useCreateExamByAssignment = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(CREATE_EXAM_BY_ASSIGNMENT, options);
};