import React from 'react';

import { ButtonPrimaryLong, Typography } from '@sofia/ui';
import { SvgErrorIllustration } from '../../../../shared';
import { useAmpliFrame } from '../../contexts';
import { useTheme } from '@emotion/react';

import * as Styled from './error-section.styles';

const ErrorSection: React.FC = () => {
  const theme = useTheme();

  const { onClose } = useAmpliFrame();

  return (
    <Styled.FrameSuccessWrapper>
      <SvgErrorIllustration />

      <Typography
        textAlign="center"
        weight="700"
        size={24}
        margin="16px 0px 8px 0px"
      >
        Ops...
      </Typography>

      <Typography textAlign="center" size={16} margin="0px 16px 24px">
        Tivemos um problema e sua mensagem não pode ser enviada. Tente novamente
        em alguns instantes.
      </Typography>

      <ButtonPrimaryLong
        type="button"
        onClick={onClose}
        color={theme.colors.text.white}
        background={theme.colors.button.primary}
      >
        Entendi
      </ButtonPrimaryLong>
    </Styled.FrameSuccessWrapper>
  );
};

export default ErrorSection;
