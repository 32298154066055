import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useLayoutEffect, useState } from 'react';
import { getNodeStyle, calculateClientRect } from '../utils';
const useComputedStyles = (parentRef, featureRefs) => {
  const [styles, setStyles] = useState(new WeakMap());
  useLayoutEffect(() => {
    const computeStyles = () => {
      // performatic get styles and calculate rect
      window.requestAnimationFrame(() => {
        const newStyles = new WeakMap();
        featureRefs.forEach(featureRef => {
          newStyles.set(featureRef, _objectSpread(_objectSpread({}, getNodeStyle(featureRef.current)), calculateClientRect(parentRef, featureRef)));
        });
        setStyles(newStyles);
      });
    };
    computeStyles();
    window.addEventListener('resize', computeStyles);
    return () => {
      window.removeEventListener('resize', computeStyles);
    };
  }, [parentRef, featureRefs]);
  return styles;
};
export default useComputedStyles;