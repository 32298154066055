import * as React from 'react';
import { getColor } from '@ampli/ui';

function SvgDiscardProgress(props) {
  const brandBase = getColor('brand-base');
  const brandLight = getColor('brand-light');

  return (
    <svg width={213} height={119} fill="none" {...props}>
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.963 20.502C50.368 1.169 79.64 4.515 103.224 15.406c27.852 12.863 60.511 32.613 54.782 62.844-5.514 29.104-43.491 31.651-72.97 33.139-18.933.956-34.243-10.754-43.693-27.249-11.75-20.507-24.182-46.086-8.38-63.638z"
        fill="#FFEDBF"
      />
      <g clipPath="url(#discard-progress_svg__clip0)">
        <path
          d="M44.72 118.547H12.257s-.658-9.657 3.235-9.767c3.894-.11 3.455 4.28 8.336-1.756 4.88-6.036 10.803-5.706 11.571-2.14.768 3.567-1.48 6.42 2.632 5.542 4.113-.878 10.036 1.427 6.69 8.121z"
          fill="#D8F8F1"
        />
        <path
          d="M27.94 118.548l-.11-.004c.256-6.358 1.587-10.442 2.659-12.75 1.163-2.506 2.284-3.52 2.295-3.53l.073.082c-.01.01-1.119 1.014-2.273 3.503-1.065 2.297-2.389 6.365-2.643 12.699zM39.69 118.562l-.105-.031a17.029 17.029 0 014.283-7.13l.074.081a16.912 16.912 0 00-4.252 7.08zM17.37 118.554l-.11-.015a11.957 11.957 0 00-1.074-6.986 9.605 9.605 0 00-1.694-2.475l.076-.08c.7.741 1.28 1.586 1.718 2.506a12.069 12.069 0 011.084 7.05z"
          fill="#fff"
        />
        <path
          d="M49.127 118.693H8.755l-.01-.137c-.023-.34-.538-8.369 2.062-11.234a2.842 2.842 0 012.082-1c1.694-.048 2.76.587 3.619 1.097 1.619.961 2.689 1.596 6.578-3.214 4.213-5.21 8.76-6.331 11.373-5.708 1.688.403 2.852 1.541 3.193 3.124.323 1.498.22 2.906.137 4.037-.089 1.21-.158 2.166.339 2.599.415.362 1.242.395 2.605.104 2.77-.591 6.637-.086 8.578 2.424 1.044 1.35 1.867 3.803-.144 7.827l-.04.081zm-40.097-.294h39.915c1.498-3.041 1.545-5.611.134-7.435-1.78-2.301-5.516-2.907-8.285-2.316-1.48.316-2.362.264-2.86-.169-.607-.529-.532-1.55-.438-2.842.081-1.112.182-2.497-.131-3.954-.316-1.467-1.4-2.524-2.974-2.9-2.53-.603-6.95.504-11.077 5.608-4.049 5.007-5.269 4.283-6.956 3.28-.87-.516-1.857-1.101-3.461-1.055a2.548 2.548 0 00-1.873.903c-2.336 2.575-2.05 9.795-1.994 10.88z"
          fill="#8E9294"
        />
        <path
          d="M181.041 118.856h-39.765l-.008-.223L139.37 64.3h43.577l-1.906 54.556zm-39.319-.462h38.873l1.874-53.632h-42.621l1.874 53.632z"
          fill="#444"
        />
        <path
          d="M150.762 110.304V75.879a1.512 1.512 0 00-3.025 0v34.425a1.512 1.512 0 003.025 0zM162.671 110.304V75.879a1.512 1.512 0 00-3.025 0v34.425a1.512 1.512 0 003.025 0zM174.58 110.304V75.879a1.512 1.512 0 00-3.025 0v34.425a1.512 1.512 0 003.025 0zM160.332 47.066a2 2 0 00.622 2.759l45.427 28.723a2 2 0 002.759-.622l1.655-2.62a2 2 0 00-.622-2.759l-45.426-28.723a2 2 0 00-2.76.622l-1.655 2.62zM196.29 46.383a8.877 8.877 0 00-12.84 3.847 8.892 8.892 0 00-.695 4.948l2.355 1.49a7.014 7.014 0 011.885-8.104 7.002 7.002 0 018.294-.595 7.011 7.011 0 01-3.488 12.931l2.356 1.49a8.884 8.884 0 005.438-12.258 8.884 8.884 0 00-3.303-3.75h-.002z"
          fill="#444"
        />
        <path d="M209.89 118.538H0V119h209.89v-.462z" fill="#D6E1EB" />
        <path
          d="M91.677 115.385h-3.363l-1.6-12.978h4.964l-.001 12.978z"
          fill="#FEB8B8"
        />
        <path
          d="M81.828 118.509h10.569v-4.086h-6.542a4.028 4.028 0 00-4.027 4.029v.057z"
          fill="#2F2E41"
        />
        <path
          d="M90.495 95.274l2.32 2.436 10.494-7.791-3.423-3.596-9.391 8.951z"
          fill="#FEB8B8"
        />
        <path
          d="M95.204 98.828l-4.514-4.74-2.956 2.82 7.292 7.656.041-.04a4.034 4.034 0 00.978-4.369 4.034 4.034 0 00-.841-1.327z"
          fill="#2F2E41"
        />
        <path
          d="M67.088 59.72a2.761 2.761 0 011.32-4.02L81.61 22.032l5.147 3.084L72.09 57.413a2.777 2.777 0 01-1.477 3.377 2.772 2.772 0 01-3.525-1.07zM117.201 62.133a2.754 2.754 0 01-2.512-2.235 2.757 2.757 0 01.035-1.196L95.247 28.236l5.516-2.356 17.511 30.848a2.771 2.771 0 011.867 3.18 2.773 2.773 0 01-2.94 2.226v-.001z"
          fill="#FEB8B8"
        />
        <path
          d="M81.74 56.348l3.448 51.269 8.24-.728 1.695-37.824 4.605 16.244 9.935.728-3.933-32.113-23.99 2.424z"
          fill="#444"
        />
        <path
          d="M101.181 82.885l-1.454 2.425L89.55 92.34l7.27 3.88s14.055-7.76 12.843-10.184l-8.482-3.152z"
          fill="#444"
        />
        <path
          d="M74.526 35.606l2.868-8.086a14.437 14.437 0 017.465-8.24 20.638 20.638 0 0112.119-.664l1.044.26a20.168 20.168 0 017.649 3.695c1.767 1.395 3.357 3.16 3.499 5.058a.668.668 0 00.003.012c.49 2.147.732 13.3.732 13.3h-4.318l.683 15.032-.055-.109s-24.904 4.716-24.904 2.291V42.637l-.51-5.618-6.275-1.413z"
          fill={brandLight}
        />
        <path
          d="M97.771 16.514a6.903 6.903 0 006.902-6.905 6.903 6.903 0 00-6.902-6.906A6.903 6.903 0 0090.87 9.61a6.903 6.903 0 006.901 6.905z"
          fill="#FEB8B8"
        />
        <path
          d="M98.805 6.512l5.359.215c.674-.002 1.41-.026 1.924-.462.773-.659.644-1.9.229-2.828-1.154-2.584-3.729-3.51-6.557-3.433-2.828.076-5.791 1.035-7.314 3.419-1.523 2.384-1.934 5.395-1.36 8.165a8.902 8.902 0 017.275-5.075l.444-.001z"
          fill="#2F2E41"
        />
        <path
          d="M130.627 74.055L111.23 60.453a.835.835 0 01-.203-1.159l15.224-21.733a.83.83 0 011.157-.203l19.397 13.6a.836.836 0 01.203 1.159L131.785 73.85a.834.834 0 01-1.158.205z"
          fill={brandBase}
        />
        <path
          d="M134.989 59.296l-11.382-7.98a1.228 1.228 0 01-.3-1.706 1.233 1.233 0 011.268-.493c.157.035.306.1.437.193l11.381 7.98a1.223 1.223 0 01.3 1.706 1.222 1.222 0 01-1.704.3zM132.377 63.025l-11.382-7.98a1.218 1.218 0 01-.3-1.706 1.216 1.216 0 01.79-.504 1.225 1.225 0 01.915.203l11.381 7.982a1.224 1.224 0 01-1.404 2.005zM129.736 66.795l-11.381-7.981a1.214 1.214 0 01-.503-.791 1.22 1.22 0 01.203-.915 1.229 1.229 0 011.268-.493c.157.035.305.1.437.193l11.381 7.981a1.223 1.223 0 01.3 1.706 1.222 1.222 0 01-1.705.3zM134.957 51.92l-4.409-3.092a1.226 1.226 0 01.49-2.21 1.225 1.225 0 01.915.204l4.408 3.092a1.223 1.223 0 01.3 1.705 1.222 1.222 0 01-1.704.3z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="discard-progress_svg__clip0">
          <path fill="#fff" d="M0 0h212.5v119H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDiscardProgress;
