import * as React from 'react';
import { getColor } from '@ampli/ui';
import { string } from '@ampli/utils';

function SvgHourglassImage({ baseColor, ...props }) {
  const brandLight = baseColor ? getColor(baseColor) : getColor('brand-light');

  return (
    <svg width={128} height={125} fill="none" {...props}>
      <path
        opacity={0.18}
        d="M120.255 113.721c-1.865 1.139-3.961 2.178-6.146 3.299-8.341 4.303-18.065 4.769-27.245 5.122l-43.96 1.699c-7.406.284-15.087.52-21.905-2.411a27.813 27.813 0 01-10.168-7.703c-4.412-5.207-7.346-11.922-8.826-18.668C-.414 84.022.355 72.569.17 61.268c-.106-6.583-.538-13.222.578-19.71 1.116-6.488 3.998-12.954 9.248-16.84 7.06-5.219 16.306-4.705 24.56-5.804 8.704-1.159 16.165-5.462 23.921-9.35C66.604 5.497 75.86 3.318 84.937 4.19c9.177.888 15.829 5.12 20.971 12.886a38.715 38.715 0 016.27 17.962c.663 7.556-.898 15.23.209 22.739 2.176 14.71 14.188 26.66 15.522 41.475.699 7.735-2.689 11.443-7.654 14.469z"
        fill={brandLight}
      />
      <path
        opacity={0.41}
        d="M120.253 113.721c-1.865 1.139-3.96 2.178-6.146 3.299-8.341 4.303-18.065 4.769-27.245 5.122l-43.96 1.7c-7.405.283-15.087.519-21.905-2.412a27.813 27.813 0 01-10.168-7.703l109.424-.006z"
        fill={brandLight}
      />
      <g filter="url(#hourglass-image_svg__filter0_d)">
        <path
          d="M42.72 9.795s-9.39 11.357-3.13 21.09c6.26 9.735 17.366 16.974 21.593 24.338 4.227 7.364-3.442 15.35-6.104 16.844-2.662 1.494-26.913 19.597-11.891 38.188h50.7s16.43-16.844-10.956-37.188c0 0-19.56-10.107-1.252-24.461 18.307-14.354 21.593-28.02 12.912-38.687L42.72 9.795z"
          fill={brandLight}
        />
        <path
          d="M42.72 9.795s-9.39 11.357-3.13 21.09c6.26 9.735 17.366 16.974 21.593 24.338 4.227 7.364-3.442 15.35-6.104 16.844-2.662 1.494-26.913 19.597-11.891 38.188h50.7s16.43-16.844-10.956-37.188c0 0-19.56-10.107-1.252-24.461 18.307-14.354 21.593-28.02 12.912-38.687L42.72 9.795z"
          fill="url(#hourglass-image_svg__paint0_linear)"
        />
        <path
          d="M97.467 5.147H38.78c-1.326 0-2.402 1.09-2.402 2.433v.005c0 1.343 1.076 2.432 2.402 2.432h58.686c1.326 0 2.402-1.09 2.402-2.432V7.58c0-1.344-1.076-2.433-2.402-2.433zM97.467 110.448H38.782c-1.327 0-2.402 1.088-2.402 2.432v.005c0 1.343 1.075 2.432 2.401 2.432h58.686c1.327 0 2.402-1.089 2.402-2.432v-.005c0-1.344-1.075-2.432-2.402-2.432z"
          fill={brandLight}
        />
        <path
          d="M97.467 5.147H38.78c-1.326 0-2.402 1.09-2.402 2.433v.005c0 1.343 1.076 2.432 2.402 2.432h58.686c1.326 0 2.402-1.09 2.402-2.432V7.58c0-1.344-1.076-2.433-2.402-2.433z"
          fill="url(#hourglass-image_svg__paint1_linear)"
        />
        <path
          d="M97.467 110.448H38.782c-1.327 0-2.402 1.088-2.402 2.432v.005c0 1.343 1.075 2.432 2.401 2.432h58.686c1.327 0 2.402-1.089 2.402-2.432v-.005c0-1.344-1.075-2.432-2.402-2.432z"
          fill="url(#hourglass-image_svg__paint2_linear)"
        />
        <path
          d="M89.607 84.397c-8.591-4.644-37.017-5.415-42.411-1.436 5.035-6.751 11.952-10.725 11.952-10.725 6.809-4.31 14.553-.565 14.553-.565s9.85 5.103 15.906 12.726z"
          fill="url(#hourglass-image_svg__paint3_linear)"
        />
        <path
          d="M89.43 109.883H46.943l-1.64-1.494c-4.851-8.407-3.66-15.834-.473-21.742a29.935 29.935 0 012.364-3.663c5.395-4.002 33.82-3.232 42.411 1.437a26.373 26.373 0 012.364 3.495c.46.811.86 1.654 1.201 2.523 4.237 10.835-3.74 19.444-3.74 19.444z"
          fill={brandLight}
        />
        <path
          d="M91.984 87.892c-2.028 7.479-22.255-3.931-31.678-4.788-6.222-.56-12.794 3.275-12.794 3.275s-2.123.78-2.69.258a29.95 29.95 0 012.363-3.662c5.395-4.003 33.82-3.232 42.412 1.436a26.379 26.379 0 012.388 3.48z"
          fill="url(#hourglass-image_svg__paint4_linear)"
        />
        <path
          d="M47.106 37S61.659 48.606 62.6 50.852c.94 2.245 8.51 3.557 10.875 1.56 2.364-1.996 8.368-5.803 14.316-14.416 5.948-8.614-13.612-2.993-20.184-1.743-6.572 1.25-17.84-6.866-20.501.747z"
          fill={brandLight}
        />
        <path
          d="M47.103 37.002s5.636 3.744 10.643 2.619c5.008-1.125 8.294-4.367 15.178-3.744 6.884.622 13.768 3.62 15.962.378 2.194-3.241-.156-5.87-7.045-4.994-6.889.876-18.307.996-23.782.623-5.476-.374-15.49.502-10.956 5.118z"
          fill={brandLight}
        />
        <path
          d="M47.103 37.002s5.636 3.744 10.643 2.619c5.008-1.125 8.294-4.367 15.178-3.744 6.884.622 13.768 3.62 15.962.378 2.194-3.241-.156-5.87-7.045-4.994-6.889.876-18.307.996-23.782.623-5.476-.374-15.49.502-10.956 5.118z"
          fill="url(#hourglass-image_svg__paint5_linear)"
        />
        <path
          d="M67.444 56.306c-.4.266-.725.632-.945 1.063a1.12 1.12 0 00-.114.89c.067.163.181.301.327.397a.852.852 0 00.491.14c.297-.03.58-.144.813-.331.473-.311.993-.709.922-1.192-.07-.484-.808-.829-1.442-1.02M70.083 60.733c-.16.041-.31.115-.44.216-.066.057-.104.12-.052.182.107.08.24.12.374.11a1.63 1.63 0 00.373-.067c.218-.067.473-.149.42-.245-.051-.095-.368-.167-.656-.205M67.55 62.136c-.16.042-.31.115-.44.216-.067.057-.1.125-.052.182.107.081.24.12.373.11.127-.005.253-.028.374-.067.217-.062.472-.144.425-.244-.047-.1-.373-.168-.662-.206M71.584 68.672a1.697 1.697 0 00-.648-.139c-.123 0-.232.029-.26.12a.484.484 0 00.208.378c.123.095.26.169.407.22.27.106.572.211.662.1.09-.11-.133-.425-.346-.679M64.083 77.186a1.693 1.693 0 00-.643-.14c-.127 0-.236.034-.26.12-.023.087.047.25.203.379.125.096.264.17.412.22.265.105.572.21.657.1.085-.11-.132-.425-.34-.68"
          fill={brandLight}
        />
      </g>
      <defs>
        <linearGradient
          id="hourglass-image_svg__paint0_linear"
          x1={37.438}
          y1={60.025}
          x2={99.178}
          y2={60.025}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.32} stopColor="#fff" stopOpacity={0.39} />
          <stop offset={0.68} stopColor="#fff" stopOpacity={0.68} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hourglass-image_svg__paint1_linear"
          x1={16134.8}
          y1={681.886}
          x2={16135.1}
          y2={586.249}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.13} stopOpacity={0.69} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="hourglass-image_svg__paint2_linear"
          x1={16168.4}
          y1={1691.37}
          x2={16168.4}
          y2={1919.84}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.13} stopOpacity={0.69} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="hourglass-image_svg__paint3_linear"
          x1={11674.7}
          y1={3996.27}
          x2={11640.6}
          y2={4502.06}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.32} stopColor="#fff" stopOpacity={0.39} />
          <stop offset={0.68} stopColor="#fff" stopOpacity={0.68} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hourglass-image_svg__paint4_linear"
          x1={13011.9}
          y1={2884.22}
          x2={12978.2}
          y2={3673.11}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.13} stopOpacity={0.69} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="hourglass-image_svg__paint5_linear"
          x1={11760.7}
          y1={1900.36}
          x2={11758.7}
          y2={1586.93}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.13} stopOpacity={0.69} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <filter
          id="hourglass-image_svg__filter0_d"
          x={29.379}
          y={0.147}
          width={77.49}
          height={124.169}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={3.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

SvgHourglassImage.propTypes = {
  baseColor: string,
};

export default SvgHourglassImage;
