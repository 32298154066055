import React from 'react';
import {
  SECTION_EDU_COMMUNICATION_COMPLETED_DESCRIPTION,
  SECTION_EDU_COMMUNICATION_COMPLETED_KEY,
} from '../../../../../../constants';
import useHandleCopilotCommunication from '../../hooks/copilot/use-handle-copilot-communication';
import useSectionCopilot from '../../hooks/copilot/use-section-copilot';
import { useTheme } from '@emotion/react';
import { TSectionBarCopilotButton } from './types';
import { useBreakpoint } from '../../../../hooks';
import { useCourseEnrollmentsContext } from '../../../../contexts/course-enrollments-context';

export const useSectionBarCopilotButtonLogic: TSectionBarCopilotButton = ({
  studentId,
}) => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();
  const { payload, isCopilotOpen, startChatOpened } = useSectionCopilot();
  const { onCloseCommunication, canShowCommunication, loading } =
    useHandleCopilotCommunication({
      studentId,
      input: {
        key: SECTION_EDU_COMMUNICATION_COMPLETED_KEY,
        desc: SECTION_EDU_COMMUNICATION_COMPLETED_DESCRIPTION,
      },
    });
  const [commsAnchorEl, setCommsAnchorEl] =
    React.useState<HTMLButtonElement | null>();

  const {
    states: {
      courseEnrollments: { data, loading: loadingEnrollment },
    },
  } = useCourseEnrollmentsContext();
  const hasLearningEduRole =
    !loadingEnrollment && data?.roles?.includes('CONTENT_EDU_ENABLED');

  return {
    theme,
    commsAnchorEl,
    setCommsAnchorEl,
    payload,
    isCopilotOpen,
    startChatOpened,
    onCloseCommunication,
    canShowCommunication,
    loading,
    isDesktop,
    hasLearningEduRole,
  };
};
