import React, { KeyboardEvent } from 'react';
import * as Styled from './file-asset.styles';
import { useTheme } from '@emotion/react';
import { Typography, ButtonGhost, SvgFileDownloadAlt } from '@sofia/ui';
import { pushAmpliEvent } from '../../../lib/ga';
import {
  clickByKey,
  DownloadFile,
  useBreakpoint,
  useDownloadFileAnimation,
} from '../../../modules';
import { linkDownload } from '../../../utils';
import { FileAssetProps } from '../types';
import { ApolloError } from '@apollo/client';

const FileAsset = ({ data }: FileAssetProps): React.ReactElement | null => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();
  const [error, setError] = React.useState<ApolloError | undefined>();
  const onClick = async (event: MouseEvent | KeyboardEvent) => {
    event.preventDefault();
    if (!isFinished && isDownloading === null) {
      pushAmpliEvent('event', 'learning_object_asset_file_download');
      setIsDownloading(true);
      await fetch(data.url)
        .then((response) => response.blob())
        .then((res) => {
          const splitUrl = data.url.split('/');
          const docName = splitUrl[splitUrl.length - 1].split('.')[0];
          return linkDownload({
            fileUrl: URL.createObjectURL(res),
            documentName: docName,
            documentExtension: 'pdf',
          });
        })
        .then(() => setIsDownloading(false))
        .catch((fetchError) => {
          setError(fetchError);
          setIsDownloading(false);
        });
    }
  };

  const { isFinished, isDownloading, setIsDownloading } =
    useDownloadFileAnimation({
      error,
    });

  return data ? (
    <Styled.Wrapper>
      <Styled.FlexColumn>
        <Typography color={theme.colors.text.lightBlack} weight={700} size={14}>
          Dica para você
        </Typography>
        <Typography
          color={theme.colors.text.black}
          size={20}
          margin={isDesktop ? '8px 0 0 0' : '8px 0 16px 0'}
        >
          {data.description}
        </Typography>
      </Styled.FlexColumn>
      <ButtonGhost
        color={theme.colors.text.primary}
        hoverBackground={theme.colors.background.primary}
        hoverColor={theme.colors.button.darkerPrimary}
        padding="16px 24px"
        aria-label={data.altText}
        onClick={onClick}
        onKeyDown={(event: KeyboardEvent) =>
          clickByKey({
            event: event as KeyboardEvent<Element>,
            onClick: () => onClick(event),
          })
        }
      >
        <DownloadFile isDownloading={isDownloading} isFinished={isFinished}>
          <SvgFileDownloadAlt fill={theme.colors.text.primary} />
          Baixar PDF
        </DownloadFile>
      </ButtonGhost>
    </Styled.Wrapper>
  ) : null;
};

export default FileAsset;
