import { FakeXhr, hexToAscii, utf8Decoder } from '@ampli/utils';
import {
  HTTP_SUCCESS_STATUS,
  RESPOND_OBJECT_KEY,
  URI_OBJECT_KEY,
  XHR_OBJECT_KEY,
} from '../../../../../constants';
import { hlsKey1, hlsKey2, skPath } from '../../../../../config';
import { BeforeVideoRequestOptions } from '../types';

const hlsKey = new Uint8Array(hlsKey1.concat(hlsKey2));
const fakeSkPath = new Uint8Array(skPath);

const createHeaders = (content: string) => ({
  'Content-Type': 'text/plain; charset=utf-8',
  'Content-Length': content.length,
});

const handleBeforeVideoRequest = (
  options: BeforeVideoRequestOptions
): BeforeVideoRequestOptions => {
  const uri = options[URI_OBJECT_KEY];
  const fakePath = utf8Decoder(fakeSkPath);
  const uriTest = new RegExp(fakePath);

  if (uriTest.test(uri)) {
    const key = utf8Decoder(hlsKey);
    const responseText = hexToAscii(key, 5);
    const xhr = new FakeXhr();
    options[XHR_OBJECT_KEY] = xhr;

    setTimeout(() => {
      xhr[RESPOND_OBJECT_KEY](
        HTTP_SUCCESS_STATUS,
        createHeaders(responseText),
        responseText
      );
    }, 1000);
  }
  return options;
};

export default handleBeforeVideoRequest;
