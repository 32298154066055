import styled from '@emotion/styled';
import { convertPXToVW, convertPXToVH, mediaQuery } from '@sofia/ui';

export const Wrapper = {
  Content: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,

  Item: styled.div`
    display: flex;
    flex-direction: column;

    ${mediaQuery('tablet-max')} {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
  `,

  Infos: styled.div`
    display: flex;
    flex-direction: row;

    gap: 16px;

    ${mediaQuery('tablet')} {
      flex-direction: column;

      gap: 8px;
      margin-top: 27px;

      > p {
        margin: 0;
      }
    }
  `,
};

export const Flex = {
  RowSpaceBetween: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    ${mediaQuery('mobile')} {
      flex-wrap: wrap;
      gap: ${convertPXToVH(22)};
    }
  `,

  Row: styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: ${convertPXToVW(40)};

    ${mediaQuery('mobile')} {
      flex-wrap: wrap;
      gap: ${convertPXToVH(22)};
    }
  `,
};
