import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["top", "left", "width", "height"];
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { convertPXToREM, mediaQuery } from '../../utils';
export const cloneWrap = _ref => {
  let {
    top = 0,
    left = 0,
    width = 0,
    height = 0
  } = _ref;
  return css`
  height: ${convertPXToREM(height)};
  left: ${convertPXToREM(left)};
  position: absolute;
  top: ${convertPXToREM(top)};
  width: ${convertPXToREM(width)};
`;
};

// eslint-disable-next-line no-unused-vars
export const clone = _ref2 => {
  let {
      top,
      left,
      width,
      height
    } = _ref2,
    styles = _objectWithoutProperties(_ref2, _excluded);
  return css(styles);
};
export const stepButtons = () => css`
  position: absolute;
  bottom: 24px;
  right: 16px;
  padding: 16px;
`;
export const FloatingBox = styled.div`
  background-color: #ffffff;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  border-radius: 8px;
  white-space: nowrap;

  ${mediaQuery('desktop')} {
    padding: 24px;
  }

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;