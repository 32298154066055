import * as React from 'react';
import { getColor } from '@ampli/ui';

function SvgExamDoubtsImage(props) {
  const brandBase = getColor('brand-base');
  const brandLight = getColor('brand-light');
  const brandLighter = getColor('brand-lighter');

  return (
    <svg width={150} height={114} fill="none" {...props}>
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.646 49.99c.498 27.528-24.064 44.798-49.657 52.078-30.227 8.598-68.975 14.901-86.044-13.48C-8.487 61.266 18.5 33.284 39.794 11.96 53.471-1.737 73.372-2.706 92.219 4.195c23.432 8.581 50.975 20.802 51.427 45.794z"
        fill={brandLighter}
      />
      <path
        d="M41.31 7.817C18.495 7.817 0 20.937 0 37.123c0 7.226 3.69 13.84 9.802 18.95v21.27L22.897 63.36a55.351 55.351 0 0018.413 3.067c22.814 0 41.31-13.12 41.31-29.305 0-16.185-18.496-29.306-41.31-29.306z"
        fill="#F2F2F2"
      />
      <path
        d="M44.23 3.658c-22.815 0-41.31 13.12-41.31 29.306 0 7.226 3.69 13.84 9.803 18.95v21.27l13.095-13.982a55.35 55.35 0 0018.412 3.067c22.815 0 41.31-13.12 41.31-29.305 0-16.185-18.495-29.306-41.31-29.306z"
        stroke="url(#exam-doubts-image_svg__paint0_linear)"
        strokeWidth={1.131}
        strokeMiterlimit={10}
      />
      <path
        d="M42.252 51.21c8.318 0 15.062-7.2 15.062-16.083 0-8.882-6.744-16.082-15.062-16.082-8.319 0-15.062 7.2-15.062 16.082 0 8.882 6.744 16.082 15.062 16.082z"
        fill={brandLighter}
      />
      <path
        d="M38.572 31.086c.798 0 1.445-.904 1.445-2.02 0-1.115-.647-2.02-1.445-2.02s-1.444.905-1.444 2.02c0 1.116.646 2.02 1.444 2.02zM46.036 31.086c.798 0 1.445-.904 1.445-2.02 0-1.115-.647-2.02-1.445-2.02-.797 0-1.444.905-1.444 2.02 0 1.116.647 2.02 1.444 2.02zM51.282 35.421c0 2.9-4.537 7.786-9.115 7.786s-8.943-4.996-8.943-7.896c0-2.9 4.27-.248 8.849-.248 4.578 0 9.209-2.542 9.209.358z"
        fill="#444"
      />
      <path
        d="M114.725 54.631c19.482 0 35.275 11.204 35.275 25.025 0 6.17-3.151 11.819-8.37 16.182V114l-11.182-11.939a47.294 47.294 0 01-15.723 2.619c-19.482 0-35.275-11.204-35.275-25.025 0-13.82 15.793-25.024 35.275-25.024z"
        fill={brandLight}
      />
      <path
        d="M112.23 51.081c19.482 0 35.275 11.204 35.275 25.025 0 6.17-3.151 11.819-8.37 16.182v18.162l-11.182-11.94a47.266 47.266 0 01-15.723 2.62c-19.482 0-35.275-11.204-35.275-25.024s15.793-25.024 35.275-25.024z"
        stroke="url(#exam-doubts-image_svg__paint1_linear)"
        strokeWidth={0.965}
        strokeMiterlimit={10}
      />
      <path
        d="M113.919 91.685c-7.103 0-12.861-6.148-12.861-13.732 0-7.585 5.758-13.733 12.861-13.733 7.104 0 12.862 6.148 12.862 13.733 0 7.584-5.758 13.732-12.862 13.732z"
        fill={brandLighter}
      />
      <path
        d="M117.061 74.506c-.681 0-1.234-.772-1.234-1.725 0-.952.553-1.724 1.234-1.724.681 0 1.234.772 1.234 1.724 0 .953-.553 1.725-1.234 1.725zM110.688 74.506c-.681 0-1.233-.772-1.233-1.725 0-.952.552-1.724 1.233-1.724s1.234.772 1.234 1.724c0 .953-.553 1.725-1.234 1.725zM106.209 78.204c0 2.477 3.873 6.648 7.783 6.648 3.909 0 7.636-4.265 7.636-6.742 0-2.477-3.646-.212-7.556-.212-3.909 0-7.863-2.17-7.863.306z"
        fill="#444"
      />
      <defs>
        <linearGradient
          id="exam-doubts-image_svg__paint0_linear"
          x1={2.92}
          y1={73.184}
          x2={94.294}
          y2={58.633}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BDBB8" />
          <stop offset={1} stopColor={brandBase} />
        </linearGradient>
        <linearGradient
          id="exam-doubts-image_svg__paint1_linear"
          x1={147.505}
          y1={110.45}
          x2={69.48}
          y2={98.025}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BDBB8" />
          <stop offset={1} stopColor={brandBase} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgExamDoubtsImage;
