import styled from '@emotion/styled';
import { convertPXToREM } from '../../utils';
export const Card = styled.div`
  border: solid 2px #b1c2d5;
  border-radius: 8px;
  z-index: 2;
  width: 100%;
  height: fit-content;
  @media (min-width: 769px) {
    width: fit-content;
    height: fit-content;
  }
`;
export const Container = styled.div`
  padding: 24px;
`;
export const Title = styled.div`
  display: flex;
  justify-content: center;
`;
export const SubtitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  margin-top: 16px;
  white-space: nowrap;
`;
export const Subtitle = styled.div`
  display: flex;
  align-items: center;
`;
export const SubtitleColor = styled.div(_ref => {
  let {
    bgColor,
    borderColor
  } = _ref;
  return `
  background: ${bgColor || 'transparent'};
  border: solid 2px ${borderColor || bgColor || '#040cee'};
  border-radius: 50%;
  min-width: 12px;
  min-height: 12px;
  max-width: 12px;
  max-height: 12px;
  margin-right: 4px;
`;
});
export const QuestionContainer = styled.div`
  padding: 24px 0px 0px;
  position: relative;
`;
export const Position = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  overflow: none;
  > :not(:last-of-type) {
    margin-right: 8px;
    [aria-selected='true'] {
      margin-right: 7px;
    }
  }

  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 8px;
    grid-column-gap: 8px;
    overflow: none;

    > :not(:last-of-type) {
      margin-right: 0px;
    }
  }
`;
export const Question = styled.div(_ref2 => {
  let {
    theme,
    color
  } = _ref2;
  return `
  border: solid 1px ${theme.borderColor || '#040cee'};
  background: ${theme.bgColor || 'transparent'};
  color: ${color || '#212529'};
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
  margin: 1px;
  border-radius: 50%;
  min-width: 38px;
	min-height: 38px;
	max-width: 38px;
	max-height: 38px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &[aria-selected='true'] {
    border: solid 2px ${theme.selectedBorderColor || '#040cee'};
    background-color: ${theme.selectedBgColor || '#e5edfa'};
    color: ${theme.selectedColor || theme.color || '#212529'};
  }
`;
});
export const Footer = styled.div`
  padding: 16px;
  border-top: solid 2px #b1c2d5;
`;