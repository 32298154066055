import styled from '@emotion/styled';

import { convertPXToVH } from '@sofia/ui';

export const LastQuestionsList = styled.div`
  margin: ${convertPXToVH(16)} 0px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;
