/* eslint-disable prettier/prettier */
import { getAriaLabels, useInitProps } from './helper';

import { QuestionNavigation, Separator, Timer } from '@sofia/ui';
import { ExamLayoutMobile } from '../../../../../components';

import NavigationFooter from '../../navigation/navigation';
import Question from './question';
import React from 'react';

import * as Styled from './take-evaluation.styles';
import { firstLetterUpperCase } from '../../section';
import { CustomScreenOrientation, TakeEvaluationProps } from '../types';
import { useLocation } from 'react-router-dom';
import { useMeContext } from '../../../contexts/me-context';
import { useCourseEnrollmentsContext } from '../../../contexts/course-enrollments-context';
import { ChatCopilot } from '../../../../../components/chat-copilot';
import { ICopilotQuizPayload } from '../../../../../components/chat-copilot/types';

interface IState {
  title: string;
}

const TakeEvaluationMobile = ({
  talkTutorFrame,
  evaluation,
  onHelpClick,
  onFinishClick,
  onQuestionChoiceClick,
  questionChoices,
  stepsControl,
  timeLeftInMinutes,
  durationInMinutes,
  totalTime,
  type,
  finishLoading,
}: TakeEvaluationProps): React.ReactElement => {
  const questionNavRef = React.useRef<{
    selectQuestionId: (id: string) => void;
  }>();

  const {
    states: { getMe },
  } = useMeContext();
  const {
    states: { courseEnrollments },
  } = useCourseEnrollmentsContext();

  const [payload, setPayload] = React.useState<ICopilotQuizPayload>(
    {} as ICopilotQuizPayload
  );
  const [isQuizPageRendered, setIsQuizPageRendered] =
    React.useState<boolean>(false);

  const {
    theme,
    numberOfQuestions,
    answeredQuestions,
    question,
    navigationQuestions,
    step,
    toStep,
    isLastStep,
    isFirstStep,
    previousStep,
    nextStep,
  } = useInitProps(questionChoices, evaluation, stepsControl);

  const { nextButtonLabel, previousButtonLabel, timerLabel } = getAriaLabels(
    isLastStep,
    step,
    numberOfQuestions,
    durationInMinutes,
    timeLeftInMinutes,
    type
  );

  const onPreviousClick = isFirstStep
    ? null
    : () => {
        previousStep();
        questionNavRef.current?.selectQuestionId(
          evaluation.questions[step - 1]?.id
        );
      };

  const onNextClick = () => {
    if (isLastStep) {
      onFinishClick();
    } else {
      nextStep();
      questionNavRef.current?.selectQuestionId(
        evaluation.questions[step + 1]?.id
      );
    }
  };

  const types = [
    {
      bgColor: theme.colors.button.primary,
      borderColor: theme.colors.button.primary,
      color: 'white',
      selectedBgColor: theme.colors.button.primary,
      selectedBorderColor: theme.colors.button.darkPrimary,
      status: 'RESPONDIDAS',
      text: 'Respondidas',
    },
    {
      borderColor: theme.colors.button.primary,
      selectedBorderColor: theme.colors.button.primary,
      text: 'Sem respostas',
      status: 'DEFAULT',
    },
  ];

  const { title }: IState = (useLocation()?.state || {}) as IState;

  React.useEffect(() => {
    window.scrollTo(0, 0);
    (screen.orientation as unknown as CustomScreenOrientation).lock('portrait');
  }, [question]);

  React.useEffect(() => {
    setPayload({
      studentMoment: 'LEARNING_QUESTION',
      academicRegistration: courseEnrollments?.data.externalId,
      subjectName: evaluation?.subject.name,
      studentName: getMe.me.firstName,
      courseName: courseEnrollments?.data.course.name,
      exerciseQuestion: evaluation?.questions[step].baseText,
      questionDescription: evaluation?.questions[step].statement,
      initialInteraction: step + 1 === 1,
      classID: evaluation?.questions[step].classKrotonId,
      dictSugestions: '',
      modalityCourse: 'EAD',
      studentBirthDate: '01/01/1901',
    });
  }, [
    courseEnrollments?.data.course.name,
    courseEnrollments?.data.externalId,
    evaluation?.questions,
    evaluation?.subject.name,
    getMe.me.firstName,
    step,
  ]);

  React.useEffect(() => {
    if (window.location.pathname.includes('exercicio')) {
      setIsQuizPageRendered(true);
      return;
    }
    setIsQuizPageRendered(false);
  }, []);

  return (
    <ExamLayoutMobile
      talkTutorFrame={talkTutorFrame}
      header={
        <>
          <Styled.Floating top={false}>
            <Styled.Header
              background={theme.colors.banner.primary}
              color={theme.colors.breadCrumbs.primary}
              data-testid="take-evaluation-title"
            >
              <span>{firstLetterUpperCase(title)}</span>
              <span>{evaluation.subject.name}</span>
            </Styled.Header>
          </Styled.Floating>
          <Styled.Spacing>
            <QuestionNavigation
              ref={questionNavRef}
              title={
                <span>
                  Questões realizadas:{' '}
                  {<strong>{answeredQuestions.length}</strong>} de{' '}
                  {numberOfQuestions}
                </span>
              }
              onQuestionClick={(index: number) => toStep(index)}
              questions={navigationQuestions()}
              types={types}
            />
          </Styled.Spacing>
        </>
      }
      footer={
        <>
          <Timer
            aria-label={timerLabel}
            totalTimeInSeconds={totalTime * 60}
            startTimeInSeconds={(totalTime - +timeLeftInMinutes) * 60}
          />
          <Separator margin="16px 0" color="#b1c2d5" />
          <NavigationFooter
            isFirstStep={isFirstStep}
            isLastStep={isLastStep}
            nextLoading={finishLoading}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
            lastStepLabel={`Finalizar ${type}`}
            ariaLabelPreviousButton={previousButtonLabel}
            ariaLabelNextButton={nextButtonLabel}
          />
        </>
      }
    >
      <Question
        step={step}
        question={question}
        questionChoices={questionChoices}
        onHelpClick={onHelpClick}
        onChoiceClick={onQuestionChoiceClick.bind(null, step)}
      />
      <Styled.FlexCenter>
        <Styled.ButtonGhost
          color={theme.colors.button.primary}
          hoverColor={theme.colors.button.darkPrimary}
          onClick={onHelpClick}
        >
          Questão com problemas?
        </Styled.ButtonGhost>
      </Styled.FlexCenter>

      {evaluation?.hasAdaptiveFeedback &&
      evaluation?.questions[step]?.classKrotonId ? (
        <ChatCopilot
          isCopilotOnQuiz
          isQuizPageRendered={isQuizPageRendered}
          quizPagePayload={payload}
        />
      ) : null}
    </ExamLayoutMobile>
  );
};

export default TakeEvaluationMobile;
