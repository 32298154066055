import { useLazyQuery } from '@ampli/services';
import { GET_NEGOTIATION_PAYMENT_URL } from './queries';
import { LazyGetNegotiationPaymentUrlHook } from './types';

export const useLazyGetNegotiationPaymentUrl: LazyGetNegotiationPaymentUrlHook =
  ({ options }) => {
    const [query, { data, loading, error }] = useLazyQuery(
      GET_NEGOTIATION_PAYMENT_URL,
      {
        ...options,
        context: {
          autoHandle: true,
        },
        fetchPolicy: 'no-cache',
      }
    );

    const externalPaymentUrl = loading || !data ? {} : data.data;

    return [query, { data: externalPaymentUrl, loading, error }];
  };
