import React from 'react';

import CustomizationSlider from '../../../slider/customization-slider';

import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { LETTER_AND_WORD_SPACING_SLIDER } from '../../../../constants';
import {
  FlexGap as StyledFlexGap,
  SliderWrapper as StyledSliderWrapper,
} from '../../customization-section.styles';
import {
  BetweenWordsAndLettersProps,
  LetterAndWordSpacingType,
} from '../../../../types';

const BetweenWordsAndLetters = ({
  reset,
  defaultValue,
  onChange,
  label,
  icon,
}: BetweenWordsAndLettersProps): React.ReactElement => {
  const theme = useTheme();
  const [value, setValue] =
    React.useState<LetterAndWordSpacingType>(defaultValue);

  React.useEffect(() => {
    if (reset) {
      setValue(defaultValue);
    }
  }, [defaultValue, reset]);

  return (
    <StyledFlexGap margin="0 0 16px 0" data-testid={`between__${label}`}>
      <Typography size={16} color={theme.colors.text.black} weight={700}>
        Entre {label}
      </Typography>
      <StyledSliderWrapper>
        {icon}
        <CustomizationSlider
          config={LETTER_AND_WORD_SPACING_SLIDER}
          ariaLabel={`Espaçamento entre ${label}`}
          value={value}
          sufix="%"
          onChange={(value) => {
            setValue(value);
            onChange(value);
          }}
        />
      </StyledSliderWrapper>
    </StyledFlexGap>
  );
};

export default BetweenWordsAndLetters;
