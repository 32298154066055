import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { ButtonPrimary } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { useCreateContentEvaluation } from '../../../../../services';
import { noKindleSelector } from '../../../constants';
import { RatingStars } from '../../../../../../shared';

import {
  ContentEvaluationDataProps,
  ContentEvaluationProps,
} from '../../../types';

import {
  getNewContentEvaluationInput,
  getButtonEvaluationLabels,
  showTooltip,
} from '../../../utils';

import * as Styled from './content-evaluation-styles';

const ContentEvaluation = ({
  contentEvaluation,
  onNewContentEvaluation,
  sendFeedback,
}: ContentEvaluationProps): React.ReactElement => {
  const theme = useTheme();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [inputDisabled, setInputDisabled] = React.useState(true);
  const [hasErrors, setHasErrors] = React.useState(false);

  const [newContentEvaluation, setNewContentEvaluation] =
    React.useState<ContentEvaluationDataProps>(
      getNewContentEvaluationInput(contentEvaluation)
    );

  const [mutate] = useCreateContentEvaluation(contentEvaluation, {
    onError: () => {
      setHasErrors(true);
      setTimeout(() => {
        setInputDisabled(false);
        setHasErrors(false);
      }, 2000);
    },
    onCompleted: () => {
      setTimeout(() => {
        onNewContentEvaluation();
        setButtonDisabled(true);
      }, 1000);
    },
  });

  const applyEvaluation = (rate?: number) => {
    setInputDisabled(!(rate && buttonDisabled));

    const newContentEvaluationObj = {
      ...newContentEvaluation,
      ...{ ['createdDate']: new Date().toISOString() },
    };

    mutate({
      variables: {
        contentEvaluation: rate
          ? {
              ...newContentEvaluationObj,
              ...{ ['rating']: rate },
            }
          : newContentEvaluationObj,
      },
    });
  };

  const updateNewContentEvaluationState = (
    attr: 'comment' | 'rating',
    newAttrState: string | number
  ) => {
    setNewContentEvaluation({
      ...newContentEvaluation,
      ...{ [attr]: newAttrState },
    });
  };

  const btnContent = getButtonEvaluationLabels(
    hasErrors,
    inputDisabled,
    buttonDisabled,
    <Styled.BoldCheck />
  );

  React.useEffect(() => {
    if (contentEvaluation && Object.keys(newContentEvaluation)?.length === 0) {
      setNewContentEvaluation(getNewContentEvaluationInput(contentEvaluation));
    }
  }, [contentEvaluation, newContentEvaluation]);

  return (
    <>
      <Styled.FlexedStars>
        <Styled.ColumnDiv>
          <Styled.Title
            color={theme.colors.text.lightBlack}
            className={noKindleSelector}
          >
            Avalie este conteúdo
          </Styled.Title>
          <Styled.SubTitle className={noKindleSelector}>
            Escolha de 1 a 5 estrelas
          </Styled.SubTitle>
        </Styled.ColumnDiv>

        {newContentEvaluation?.rating >= 0 && contentEvaluation?.rating >= 0 ? (
          <RatingStars
            idPrefix="star"
            newRating={newContentEvaluation?.rating}
            rating={contentEvaluation?.rating}
            onStarClick={(rate) => {
              applyEvaluation(rate);
              updateNewContentEvaluationState('rating', rate);
            }}
            getAriaLabel={(rate) =>
              `Avaliar conteúdo em ${rate} estrela${rate > 1 ? 's' : ''}`
            }
          />
        ) : null}
      </Styled.FlexedStars>

      {newContentEvaluation?.rating &&
      (newContentEvaluation.rating > 0 ||
        newContentEvaluation.rating !== contentEvaluation?.rating) ? (
        <Styled.BoxComment>
          <Styled.Comment
            color={theme.colors.text.lightBlack}
            className={noKindleSelector}
          >
            Deixe um comentário (opcional)
          </Styled.Comment>
          <Styled.FlexedComment>
            <Tooltip
              title={showTooltip(inputRef, newContentEvaluation)}
              placement="top-start"
              arrow
            >
              <Styled.CommentInput
                disabledColor={theme.colors.text.secondary}
                borderColor={theme.colors.card.border.primary}
                focusColor={theme.colors.text.primary}
                /* Component props */
                ref={inputRef}
                placeholder="Conte o motivo da sua avaliação"
                onChange={(evt) => {
                  setButtonDisabled(!evt?.target?.value);
                  updateNewContentEvaluationState('comment', evt.target.value);
                }}
                disabled={inputDisabled}
                aria-label={`Comentário: ${newContentEvaluation?.comment}`}
                value={newContentEvaluation?.comment || ''}
              />
            </Tooltip>
            <Styled.ContainerButton>
              <ButtonPrimary
                background={theme.colors.button.primary}
                onClick={() => {
                  applyEvaluation();
                  setTimeout(() => {
                    sendFeedback();
                  }, 2000);
                }}
                color="#fff"
                disabled={buttonDisabled}
                aria-label={btnContent['aria-label']}
                aria-live="assertive"
              >
                {btnContent.label}
              </ButtonPrimary>
            </Styled.ContainerButton>
          </Styled.FlexedComment>
        </Styled.BoxComment>
      ) : null}
    </>
  );
};

export default ContentEvaluation;
