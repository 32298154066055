import React from 'react';
import { useTheme } from '@emotion/react';
import { Typography } from '@sofia/ui';
import { exact, string } from 'prop-types';
import * as Styled from './mandatory-internship-confirm-company-data-field.styles';

const MandatoryIntershipConfirmCompanyDataField = ({ field }) => {
  const theme = useTheme();

  return (
    <Styled.Field>
      <Typography
        as="span"
        weight={700}
        size={14}
        color={theme.colors.text.lightBlack}
      >
        {field.name}
      </Typography>
      <Typography as="span" size={16} color={theme.colors.text.black}>
        {field.val}
      </Typography>
    </Styled.Field>
  );
};

MandatoryIntershipConfirmCompanyDataField.propTypes = {
  field: exact({
    name: string,
    val: string,
  }),
};

export default MandatoryIntershipConfirmCompanyDataField;
