import React from 'react';

import { ButtonPrimary } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { useEvaluation } from './evaluation.hook';
import { RatingStars } from '../../../../../../../shared';
import { usePtkFrame } from '../../contexts';

import * as Styled from './evaluation.styles';

const noKindleSelector = 'no-kindle';

const Evaluation: React.FC = () => {
  const theme = useTheme();

  const {
    commentEvaluation,
    ratingEvaluation,
    showInputButton,
    commentFinished,
    ratingFinished,
    buttonDisabled,
    inputDisabled,
    dispatch,
    comment,
    rating,
  } = useEvaluation();

  const { conversation } = usePtkFrame();

  const onStarClick = React.useCallback(
    (rate: number) => {
      dispatch({ rating: rate });

      return ratingEvaluation({
        id: conversation?.id,
        rating: rate,
      });
    },
    [conversation?.id, dispatch, ratingEvaluation]
  );

  const applyEvaluation = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      if (!conversation?.id || !rating) return;

      return commentEvaluation({
        id: conversation?.id,
        comment,
      });
    },
    [comment, commentEvaluation, conversation?.id, rating]
  );

  const btnContent = commentFinished ? (
    <span>
      Enviado <Styled.BoldCheck fill="#8e9294" />
    </span>
  ) : (
    'Enviar'
  );

  return (
    <Styled.EvaluationWrapper id="evaluation">
      {!commentFinished ? (
        <Styled.Title className={noKindleSelector}>
          Escolha de 1 a 5 estrelas
        </Styled.Title>
      ) : null}

      <Styled.FlexedStars>
        <RatingStars
          idPrefix="frame-star"
          newRating={rating!}
          onStarClick={onStarClick}
          disabled={ratingFinished}
          getAriaLabel={(rate) =>
            `Avaliar conteúdo em ${rate} estrela${rate > 1 ? 's' : ''}`
          }
        />
      </Styled.FlexedStars>

      {showInputButton ? (
        <Styled.BoxComment>
          <Styled.Comment
            color={theme.colors.text.lightBlack}
            className={noKindleSelector}
          >
            Deixe um comentário (opcional)
          </Styled.Comment>

          <Styled.FlexedComment>
            <Styled.CommentInput
              disabledColor={theme.colors.text.secondary}
              borderColor={theme.colors.card.border.primary}
              focusColor={theme.colors.text.primary}
              placeholder="Conte o motivo da sua avaliação"
              aria-label={`Comentário: ${comment}`}
              disabled={inputDisabled}
              value={comment}
              onChange={(evt) =>
                dispatch({
                  comment: evt.target.value,
                })
              }
            />

            <Styled.ContainerButton>
              <ButtonPrimary
                type="button"
                background={theme.colors.button.primary}
                disabled={buttonDisabled || comment === ''}
                color="#fff"
                aria-live="assertive"
                onClick={applyEvaluation}
              >
                {btnContent}
              </ButtonPrimary>
            </Styled.ContainerButton>
          </Styled.FlexedComment>
        </Styled.BoxComment>
      ) : null}
    </Styled.EvaluationWrapper>
  );
};

export default Evaluation;
