import React from 'react';

export function useHoverFocus(): [
  React.RefObject<HTMLButtonElement>,
  { isHover: boolean; isFocus: boolean }
] {
  const [isHover, setIsHover] = React.useState<boolean>(false);
  const [isFocus, setIsFocus] = React.useState<boolean>(false);

  const ref = React.useRef<HTMLButtonElement>(null);

  const handleMouseOver = (): void => setIsHover(true);
  const handleMouseOut = (): void => setIsHover(false);

  const handleFocus = (): void => setIsFocus(true);
  const handleBlur = (): void => setIsFocus(false);

  React.useEffect(
    () => {
      const node = ref.current;

      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
        node.addEventListener('focus', handleFocus);
        node.addEventListener('blur', handleBlur);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
          node.removeEventListener('focus', handleFocus);
          node.removeEventListener('blur', handleBlur);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current]
  );

  return [ref, { isHover, isFocus }];
}
