import * as Types from '../../../../shared/graphql/types';

import * as Operations from './queries';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@ampli/services';
import { TLazyQueryHook, TQueryHook } from '../../../../shared';
const defaultOptions = {} as const;
export type GetLivesFromSubjectQueryVariables = Types.Exact<{
  subjectEnrollmentId: Types.Scalars['String']['input'];
}>;

export type GetLivesFromSubjectQuery = {
  __typename?: 'Query';
  data?: {
    lives: Array<{
      __typename?: 'Live';
      id: string;
      title: string;
      eventId: string;
      teacherName: string;
      description: string;
      startDateTime: string;
      endDateTime: string;
      channelId: string;
    }> | null;
  };
};

export type GetLivesByCourseQueryVariables = Types.Exact<{
  courseVersionId: Types.Scalars['String']['input'];
}>;

export type GetLivesByCourseQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'Live';
    id: string;
    title: string;
    description: string;
    startDateTime: string;
    endDateTime: string;
    channelId: string;
    eventId: string;
    teacherName: string;
  }> | null;
};

export type GetLiveByIdQueryVariables = Types.Exact<{
  liveId: Types.Scalars['String']['input'];
}>;

export type GetLiveByIdQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'Live';
    id: string;
    title: string;
    eventId: string;
    teacherName: string;
    description: string;
    startDateTime: string;
    endDateTime: string;
    channelId: string;
  } | null;
};

/**
 * __useGetLivesBySubjectQuery__
 *
 * To run a query within a React component, call `useGetLivesBySubjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLivesBySubjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLivesBySubjectQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useGetLivesFromSubjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLivesFromSubjectQuery,
    GetLivesFromSubjectQueryVariables
  >
): Apollo.QueryResult<
  GetLivesFromSubjectQuery,
  GetLivesFromSubjectQueryVariables
> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useQuery as TQueryHook)<
    GetLivesFromSubjectQuery,
    GetLivesFromSubjectQueryVariables
  >(Operations.GetLivesFromSubejectEnrollment, options);
}
export function useGetLivesFromSubjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLivesFromSubjectQuery,
    GetLivesFromSubjectQueryVariables
  >
): Apollo.QueryTuple<
  GetLivesFromSubjectQuery,
  GetLivesFromSubjectQueryVariables
> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useLazyQuery as TLazyQueryHook)<
    GetLivesFromSubjectQuery,
    GetLivesFromSubjectQueryVariables
  >(Operations.GetLivesFromSubejectEnrollment, options);
}
export type GetLivesFromSubjectQueryHookResult = ReturnType<
  typeof useGetLivesFromSubjectQuery
>;
export type GetLivesFromSubjectLazyQueryHookResult = ReturnType<
  typeof useGetLivesFromSubjectLazyQuery
>;
export type GetLivesBySubjectQueryResult = Apollo.QueryResult<
  GetLivesFromSubjectQuery,
  GetLivesFromSubjectQueryVariables
>;

/**
 * __useGetLivesByCourseQuery__
 *
 * To run a query within a React component, call `useGetLivesByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLivesByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLivesByCourseQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetLivesByCourseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLivesByCourseQuery,
    GetLivesByCourseQueryVariables
  >
): Apollo.QueryResult<GetLivesByCourseQuery, GetLivesByCourseQueryVariables> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useQuery as TQueryHook)<
    GetLivesByCourseQuery,
    GetLivesByCourseQueryVariables
  >(Operations.GetLivesByCourse, options);
}
export function useGetLivesByCourseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLivesByCourseQuery,
    GetLivesByCourseQueryVariables
  >
): Apollo.QueryTuple<GetLivesByCourseQuery, GetLivesByCourseQueryVariables> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useLazyQuery as TLazyQueryHook)<
    GetLivesByCourseQuery,
    GetLivesByCourseQueryVariables
  >(Operations.GetLivesByCourse, options);
}
export type GetLivesByCourseQueryHookResult = ReturnType<
  typeof useGetLivesByCourseQuery
>;
export type GetLivesByCourseLazyQueryHookResult = ReturnType<
  typeof useGetLivesByCourseLazyQuery
>;
export type GetLivesByCourseQueryResult = Apollo.QueryResult<
  GetLivesByCourseQuery,
  GetLivesByCourseQueryVariables
>;

/**
 * __useGetLiveByIdQuery__
 *
 * To run a query within a React component, call `useGetLiveByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveByIdQuery({
 *   variables: {
 *      liveId: // value for 'liveId'
 *   },
 * });
 */
export function useGetLiveByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLiveByIdQuery,
    GetLiveByIdQueryVariables
  >
): Apollo.QueryResult<GetLiveByIdQuery, GetLiveByIdQueryVariables> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useQuery as TQueryHook)<
    GetLiveByIdQuery,
    GetLiveByIdQueryVariables
  >(Operations.GetLiveById, options);
}
export function useGetLiveByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLiveByIdQuery,
    GetLiveByIdQueryVariables
  >
): Apollo.QueryTuple<GetLiveByIdQuery, GetLiveByIdQueryVariables> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useLazyQuery as TLazyQueryHook)<
    GetLiveByIdQuery,
    GetLiveByIdQueryVariables
  >(Operations.GetLiveById, options);
}
export type GetLiveByIdQueryHookResult = ReturnType<typeof useGetLiveByIdQuery>;
export type GetLiveByIdLazyQueryHookResult = ReturnType<
  typeof useGetLiveByIdLazyQuery
>;
export type GetLiveByIdQueryResult = Apollo.QueryResult<
  GetLiveByIdQuery,
  GetLiveByIdQueryVariables
>;
