import React from 'react';

import { Typography, TabPanel, Tab, Tabs, Separator } from '@sofia/ui';
import { func, arrayOf, object } from 'prop-types';
import { generatePath } from 'react-router-dom';
import { size } from '@ampli/utils';

import { Layout, LayoutContent } from '../layout';
import { useGetState } from '../../state';
import { routePath } from '../../routes';
import { FlexGrid } from './grade-styles';

import SyllabusListSubjects from '../syllabus/list-subjects/syllabus-list-subjects';
import ListCorrections from './list-corrections';
import GradeHomeHeader from './grade-home-header';

const GradeHome = ({
  subjectEnrollmentsInProgress,
  subjectEnrollmentsTaken,
  lastDoneAssignments,
  onSubjectClick,
  history,
  onShowCorrectionClick,
  ...props
}) => {
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const hasSubjectsInProgress = size(subjectEnrollmentsInProgress) > 0;
  const hasSubjectsTaken = size(subjectEnrollmentsTaken) > 0;
  const hasSubjects = hasSubjectsInProgress || hasSubjectsTaken;

  const generateTabs = () => {
    const tabs = [];
    const tabsPanel = [];
    const inProgressTab = (
      <Tab tabIndex={0} key={0} id="tab-current">
        Disciplinas atuais
      </Tab>
    );
    const takenTab = (
      <Tab tabIndex={0} key={1} id="tab-past">
        Disciplinas anteriores
      </Tab>
    );
    const inProgressTabPanel = (
      <TabPanel id="tab-current" key={0} padding={0}>
        <SyllabusListSubjects
          subjectEnrollments={subjectEnrollmentsInProgress}
          onSubjectClick={onSubjectClick}
          showExamPeriod
          blockCardDirection
          isGradeProgress
          showElective={false}
        />
      </TabPanel>
    );
    const takenTabPanel = (
      <TabPanel id="tab-past" key={1} padding={0}>
        <SyllabusListSubjects
          subjectEnrollments={subjectEnrollmentsTaken}
          onSubjectClick={onSubjectClick}
          showExamPeriod
          blockCardDirection
          isGradeProgress
          showElective={false}
        />
      </TabPanel>
    );

    hasSubjectsInProgress &&
      tabs.push(inProgressTab) &&
      tabsPanel.push(inProgressTabPanel);

    hasSubjectsTaken && tabs.push(takenTab) && tabsPanel.push(takenTabPanel);

    return (
      <Tabs
        tabs={tabs}
        tabPanels={tabsPanel}
        defaultSelectedTabId="tab-current"
      />
    );
  };

  return (
    <Layout {...props}>
      <GradeHomeHeader
        history={history}
        homePath={generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })}
      />

      <LayoutContent>
        <Typography
          lineHeight="27.6px"
          margin="0 0 16px 0"
          weight={700}
          size={24}
          id="last-corrections-title"
        >
          Últimas correções
        </Typography>
        <FlexGrid>
          <ListCorrections
            corrections={lastDoneAssignments}
            onShowCorrectionClick={onShowCorrectionClick}
          />
        </FlexGrid>
        {hasSubjects && (
          <>
            <Separator />
            {generateTabs()}
          </>
        )}
      </LayoutContent>
    </Layout>
  );
};

GradeHome.propTypes = {
  lastDoneAssignments: arrayOf(object),
  history: object,
  subjectEnrollmentsInProgress: arrayOf(object.isRequired).isRequired,
  subjectEnrollmentsTaken: arrayOf(object.isRequired).isRequired,
  onSubjectClick: func.isRequired,
  onShowCorrectionClick: func.isRequired,
};

export default GradeHome;
