import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${mediaQuery('mobile')} {
    flex-direction: column;
  }
`;

export const Col = styled.div`
  display: flex;
  padding: 20px;
`;

export const ColLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1.5;
  padding: 20px;
  ${mediaQuery('mobile')} {
    justify-content: center;
  }
`;

export const ContainerText = styled.div`
  margin: 0 20px;
  width: 100%;

  div:first-of-type {
    width: 80%;
  }

  @media (max-width: 1024px) {
    margin: 20px 20px 0 0;
  }

  ${mediaQuery('mobile')} {
    margin: 20px 0;
    max-width: none;
  }
`;

export const ColRight = styled.div`
  display: flex;
  flex: 1;
  div:first-of-type {
    div:first-of-type {
      width: 80%;
    }
    div:last-of-type {
      width: 30%;
    }
  }
  ${mediaQuery('mobile')} {
    justify-content: center;
    div:first-of-type {
      padding: 0;
    }
  }
`;
