import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const Wrapper = {
  Content: styled.div<{ margin: string }>`
    margin: ${({ margin }) => margin};
  `,

  Svg: styled.div`
    width: ${convertPXToREM(24)};
    height: ${convertPXToREM(24)};
  `,
};
