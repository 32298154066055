import React from 'react';
import { Skeleton } from '../../../../skeleton';
import {
  Container,
  ColLeft,
  ColRight,
  ContainerText,
} from './service-skeleton-styles';
import Card from '../../card/card';

export default function SecretarySkeleton() {
  const MARGIN_SKELLETON = '10px 0 0 0 ';
  return (
    <Container>
      <ColLeft>
        <ContainerText>
          <Skeleton height={20} borderRadius={8} />
          <Skeleton height={20} margin={MARGIN_SKELLETON} borderRadius={8} />
          <Skeleton height={20} margin={MARGIN_SKELLETON} borderRadius={8} />
          <Skeleton height={20} margin={MARGIN_SKELLETON} borderRadius={8} />
          <Skeleton height={20} margin={MARGIN_SKELLETON} borderRadius={8} />
          <Skeleton height={20} margin={MARGIN_SKELLETON} borderRadius={8} />
        </ContainerText>
      </ColLeft>
      <ColRight>
        <Card noBorder>
          <Skeleton height={38} borderRadius={8} margin={'0 0 20px 0'} />
          <Skeleton height={20} margin={MARGIN_SKELLETON} borderRadius={8} />
          <Skeleton height={20} margin={MARGIN_SKELLETON} borderRadius={8} />
          <Skeleton height={20} margin={MARGIN_SKELLETON} borderRadius={8} />
          <Skeleton height={25} margin={'32px 0 0 0'} borderRadius={8} />
        </Card>
      </ColRight>
    </Container>
  );
}
