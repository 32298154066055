import React from 'react';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import PopUp from '../../pop-up/pop-up';
import { TimeRunningOutAlertProps } from '../types';

const TimeRunningOutAlert = ({
  timeLeft,
  modal,
  isDesktop,
  type,
}: TimeRunningOutAlertProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <PopUp
      popUpState={modal}
      headerText={`Restam ${timeLeft} minutos`}
      isDesktop={isDesktop}
      ariaLabel={`Informando que faltam ${timeLeft} para acabar`}
      data-testid="time-running-out-alert"
    >
      <Typography as="span" color={theme.colors.text.black} size={16}>
        Seu tempo de {type} está acabando, responda dentro do prazo e não deixe
        as questões em branco.
      </Typography>
    </PopUp>
  );
};

export default TimeRunningOutAlert;
