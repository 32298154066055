/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled from '@emotion/styled';

import {
  convertPXToVH,
  convertPXToVW,
  ButtonPrimary,
  Typography,
} from '@sofia/ui';

interface ArrowButtonProps {
  direction: 'left' | 'right';
}

interface BannerWrapperProps {
  width?: number;
}

interface BannerContainerProps {
  bannerWidth?: boolean;
}
interface BannerSliderProps {
  bannerWidth?: number;
  active: number;
  max: number;
}

interface DotPaginationProps {
  active?: boolean;
}

export const BannerContainer = styled.div<BannerContainerProps>`
  width: 100%;
  display: grid;
  position: relative;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.wrapper.color};

  ${({ bannerWidth }) =>
    bannerWidth
      ? `
        @media (min-width: 768px) {
          min-height: ${convertPXToVH(288)};
        }

        @media (max-width: 768px) {
          border-radius: 0px;
          min-height: ${convertPXToVH(288)};
        }
      `
      : `
        height: 0;
      `};
`;

export const BannerSliderWrapper = styled.div`
  overflow: hidden;
`;

export const BannerSlider = styled.div<BannerSliderProps>`
  display: flex;

  ${({ bannerWidth, active, max }) => `
    width: ${convertPXToVW(bannerWidth! * max)};
    transform: translateX(-${bannerWidth! * active}px);
  `}

  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const BannerWrapper = styled.div<BannerWrapperProps>`
  width: ${({ width }) => `${width}px`};
  display: flex;
  align-items: center;
  padding: 32px;
  @media (max-width: 768px) {
    height: 100%;
    align-items: unset;
  }
`;

export const IllustrationWrapper = styled.div`
  border-radius: 4px;
  width: 317px;
  overflow: hidden;
  margin-right: 32px;
  height: -webkit-fill-available;

  & > img {
    width: 100%;
    height: 100%;
  }

  & > svg {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 768px) {
    min-height: 240px;
  }
`;

export const CtaButton = styled(ButtonPrimary)`
  width: fit-content;
  background: ${({ theme }) => theme.colors.button.primary};
  color: #ffffff;

  @media (max-width: 540px) {
    width: 100%;
  }
`;

CtaButton.defaultProps = {
  padding: '12px 20px',
};

export const ArrowButton = styled.button<ArrowButtonProps>`
  border: none;
  border-radius: 9999px;
  padding: 12px;
  background: ${({ theme }) => theme.colors.icon.background};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;

  ${({ direction }) =>
    direction === 'right' &&
    `
    right: -48px;
    > svg {
      transform: rotate(180deg);
    }
    @media (max-width: 768px) {
      bottom: -46px;
      right: 24px;
      top: unset;
      transform: unset;
    }
  `}

  ${({ direction }) =>
    direction === 'left' &&
    `
    left: -48px;
    @media (max-width: 768px) {
      bottom: -46px;
      left: 24px;
      top: unset;
      transform: unset;
    }
  `}
`;

export const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -24px;
  @media (max-width: 768px) {
    bottom: -32px;
  }
  left: 50%;
  transform: translateX(-50%);
`;

export const DotPagination = styled.div<DotPaginationProps>`
  width: 12px;
  height: 12px;
  border: 2px solid ${({ theme }) => theme.colors.button.primary};
  border-radius: 9999px;
  background: ${({ theme, active }) =>
    active ? theme.colors.button.primary : '#FFFFFF'};
  margin: 0px 2px;
  transition: all 0.2s ease;
`;

export const BannerCategory = styled.span`
  margin-left: 8px;
`;

export const CustomTypograpy = styled(Typography)`
  @media (min-width: 768px) {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${({ lines }) => lines || 1};
    -webkit-box-orient: vertical;
    padding-bottom: 2px;
  }
`;
