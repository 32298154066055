import React from 'react';
import { string, node, number } from '@ampli/utils';

import * as Styled from './subject-image-banner-styles';
import { useTheme } from '@emotion/react';
import { LayoutContent } from '../../../layout';

const SubjectImageBanner = ({ children, className }) => {
  const theme = useTheme();

  return (
    <Styled.ImageBanner
      background={theme?.colors?.banner?.primary}
      className={className}
    >
      {children && (
        <Styled.LayoutWrapper>
          <LayoutContent>{children}</LayoutContent>
        </Styled.LayoutWrapper>
      )}
    </Styled.ImageBanner>
  );
};

SubjectImageBanner.propTypes = {
  children: node,
  className: string,
  maxWidth: number,
};

export default SubjectImageBanner;
