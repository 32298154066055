import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["loading", "data"],
  _excluded2 = ["loading", "data"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useMutation, useQuery } from '../hooks';
import { CREATE_SELLER_PUBLIC, GET_SELLER, GET_SELLER_BY_CPF } from './queries';
import { createSellerPublicSchema } from './schema';
export const useGetSeller = function (sellerId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery = useQuery(GET_SELLER, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        id: sellerId
      },
      skip: !sellerId
    })),
    {
      loading,
      data
    } = _useQuery,
    rest = _objectWithoutProperties(_useQuery, _excluded);
  const exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading
  }, rest);
};
export const useGetSellerByCpf = function (cpf) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery2 = useQuery(GET_SELLER_BY_CPF, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        cpf
      },
      skip: !cpf
    })),
    {
      loading,
      data
    } = _useQuery2,
    rest = _objectWithoutProperties(_useQuery2, _excluded2);
  const exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading
  }, rest);
};
export const useCreateSellerPublic = options => {
  return [...useMutation(CREATE_SELLER_PUBLIC, options), createSellerPublicSchema];
};