import styled from '@emotion/styled';
import { convertPXToVH, mediaQuery, ButtonOutline } from '@sofia/ui';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${convertPXToVH(24)};

  & > p {
    flex: 1;
  }

  ${mediaQuery('mobile')} {
    margin-bottom: ${convertPXToVH(16)};
  }
`;

export const ButtonDownload = styled(ButtonOutline)`
  & svg {
    margin-left: 8px;
  }
`;
