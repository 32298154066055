import React from 'react';
import {
  Card,
  convertPXToVH,
  convertPXToVW,
  SvgArrowRight,
  Typography,
} from '@sofia/ui';
import {
  func,
  string,
  node,
  bool,
  shape,
  oneOfType,
  arrayOf,
} from 'prop-types';
import { useTheme } from '@emotion/react';
import { Wrapper, LinkButton } from './home-styles';

const CommunicationCard = ({
  id,
  courseEnrollmentSettings,
  onReactivateEnrollmentClick,
  disableTab,
  blockCourseEnrollmentContentAccess,
}) => {
  const theme = useTheme();

  return (
    <Card
      id={id}
      width="100%"
      minHeight="initial"
      borderColor={theme.colors.communication.border.primary}
      background={theme.colors.communication.background.primary}
      hover={theme.colors.communication.background.primary}
      noPointer
    >
      <Wrapper id={`internal-${id}`}>
        <Typography
          margin={`0 0 ${convertPXToVH(8)}`}
          weight="700"
          size={16}
          color={theme.colors.text.black}
          id={`titleCard-${id}`}
        >
          {courseEnrollmentSettings.title}
        </Typography>
        <Typography
          margin={`0 0 ${convertPXToVH(8)}`}
          color={theme.colors.text.lightBlack}
          size={16}
          id={`descriptionCard-${id}`}
        >
          {courseEnrollmentSettings.description}
        </Typography>
        {blockCourseEnrollmentContentAccess &&
        !!courseEnrollmentSettings.button?.label ? (
          <LinkButton
            id={`access-${id}`}
            tabIndex={disableTab ? '-1' : undefined}
            onClick={onReactivateEnrollmentClick}
          >
            <Typography
              margin={`0px ${convertPXToVW(13)} 0px 0px`}
              as="span"
              weight="700"
              color={theme.colors.text.primary}
            >
              {courseEnrollmentSettings.button?.label}
            </Typography>

            <SvgArrowRight fill={theme.colors.text.primary} />
          </LinkButton>
        ) : null}
      </Wrapper>
    </Card>
  );
};

CommunicationCard.propTypes = {
  courseEnrollmentSettings: shape({
    messages: oneOfType([arrayOf(node), string]).isRequired,
    button: shape({
      icon: string,
      label: node.isRequired,
    }),
  }).isRequired,
  id: string,
  disableTab: bool,
  onReactivateEnrollmentClick: func,
  blockCourseEnrollmentContentAccess: bool,
};

export default CommunicationCard;
