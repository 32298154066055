import React from 'react';

import { useCourseEnrollmentsContext } from '../../../../modules/shared/contexts/course-enrollments-context';
import { useMeContext } from '../../../../modules/shared/contexts/me-context';

import { courseEnrollmentInactiveStatus } from '../../../../constants';
import { UseChatCopilotReturn } from '../../types';
import { useHoverFocus } from '../../../../modules';
import { copilotTokenDevelopment, copilotUrls } from '../../../../config';

interface IChatCopilotParams {
  isChatCopilotOnpenOnRender: boolean;
}

export const useChatCopilot = ({
  isChatCopilotOnpenOnRender,
}: IChatCopilotParams): UseChatCopilotReturn => {
  const [isCopilotOpen, setIsCopilotOpen] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<string>();
  const [directLineURL, setDirectLineURL] = React.useState<string>();

  const containerRef = React.useRef<HTMLDivElement>(null);

  const [eduButtonRef, { isHover, isFocus }] = useHoverFocus();

  const isHoverOrFocus = isHover || isFocus;

  const { states: statesMe } = useMeContext();
  const { states } = useCourseEnrollmentsContext();

  const { loading: loadingMe } = statesMe.getMe;
  const { data: courseEnrollment, loading: loadingEnrollment } =
    states.courseEnrollments;

  const isCourseEnrollmentBlocked = React.useMemo(
    () =>
      courseEnrollmentInactiveStatus.includes(String(courseEnrollment?.status)),
    [courseEnrollment?.status]
  );

  const isQuizPage = window.location.pathname.includes('exercicio');

  const tokenEndpointURL = React.useMemo(
    () => new URL(!isQuizPage ? copilotUrls.token : copilotTokenDevelopment),
    []
  );

  const apiVersion = tokenEndpointURL.searchParams.get('api-version');

  React.useEffect(() => {
    if (isCopilotOpen) containerRef.current?.classList.remove('leaving');
  }, [isCopilotOpen]);

  React.useEffect(() => {
    if (window && !loadingEnrollment && !loadingMe) {
      (async function () {
        try {
          await Promise.all([
            fetch(
              new URL(
                `/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`,
                tokenEndpointURL
              )
            )
              .then((response) => response.json())
              .then((response) =>
                setDirectLineURL(response.channelUrlsById.directline)
              )
              .catch(() => {
                throw new Error(
                  'Failed to retrieve regional channel settings.'
                );
              }),
            fetch(tokenEndpointURL)
              .then((response) => response.json())
              .then((response) => {
                setToken(response.token);
                setIsCopilotOpen(isChatCopilotOnpenOnRender);

                return response;
              })
              .catch(() => {
                throw new Error('Failed to retrieve Direct Line token.');
              }),
          ]);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [apiVersion, loadingEnrollment, loadingMe, tokenEndpointURL]);

  return {
    isCourseEnrollmentBlocked,
    setIsCopilotOpen,
    isHoverOrFocus,
    isCopilotOpen,
    directLineURL,
    containerRef,
    eduButtonRef,
    token,
  };
};
