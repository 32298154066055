import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

interface ContentProps {
  width: string;
  height: string;
  borderRadius: string;
  margin: string;
  minWidth?: string;
}

const Shine = keyframes`
  0% {
    background-position: -100px;
  }
  30% {
    background-position: -100px;
  }
  100% {
    background-position: 78vw;
  }
`;

export const Wrapper = {
  Content: styled.div<ContentProps>`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height};
    border-radius: ${({ borderRadius }) => borderRadius};
    min-width: ${({ minWidth = 'initial' }) => minWidth};
    background-image: linear-gradient(
      90deg,
      #e7edf1 0px,
      rgba(248, 251, 252, 0.1) 40px,
      #e7edf1 80px
    );
    background-size: 88.6vw;
    animation: ${Shine} 2s linear infinite;
    margin: ${({ margin }) => margin};
  `,
};
