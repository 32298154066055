import styled from '@emotion/styled';
import { Typography, mediaQuery } from '@sofia/ui';

export const HeaderImage = styled.div`
  max-width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 32px 0 16px 0;
`;

export const Title = styled(Typography)`
  text-align: center;
  padding-top: 8px;
`;

export const Subtitle = styled(Typography)`
  text-align: center;
`;

export const Illustrations = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${mediaQuery('desktop')} {
    flex-wrap: nowrap;
  }
  justify-content: center;
  padding: 16px 0;
`;

export const Illustration = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1 1 230px;
`;

export const IllustrationBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 8px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 24px 24px;
  margin-top: 24px;
  align-items: center;
`;

export const WrapperButtons = styled.div`
  max-width: 240px;
  text-align: center;
`;
