import FakeXhr from 'fake-xml-http-request';
import { stringToArrayBuffer } from './conversions';

Object.defineProperty(FakeXhr.prototype, 'response', {
  configurable: true,
  set: function (responseText) {
    this.responseText = responseText;
  },
  get: function () {
    if (this.responseType === 'arraybuffer') {
      return stringToArrayBuffer(this.responseText);
    }

    return this.responseText;
  },
});

export default FakeXhr;
