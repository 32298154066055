import React from 'react';

const ImageEmailValidation: React.FC = (): React.ReactElement => {
  return (
    <svg
      width="210"
      height="210"
      viewBox="0 0 210 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M108 210C160.467 210 203 167.467 203 115C203 62.5329 160.467 20 108 20C55.533 20 13 62.5329 13 115C13 167.467 55.533 210 108 210Z"
        fill="#E0F0ED"
      />
      <path
        d="M152.058 133.635C148.933 134.613 139.229 137.797 133.607 146.012C132.667 147.385 130.47 150.665 131.6 152.435C132.986 154.606 138.633 153.39 140.842 152.76C147.945 150.734 169.211 141.064 197.36 126.708C187.375 127.031 169.218 128.265 152.058 133.635Z"
        fill="#081F2D"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M181.791 72.1964C178.888 68.631 162.892 49.8068 153.042 52.4281C152.446 52.5852 151.172 52.9409 150.411 54.0576C148.898 56.2578 150.304 59.9713 152.372 63.4534C151.685 62.7338 151.04 62.1053 150.444 61.5594C147.905 59.2188 145.151 56.7456 142.785 57.5231C141.032 58.1022 140.279 60.2029 139.998 60.9805C139.08 63.5279 140.42 67.7378 142.794 72.4358C142.322 71.9394 141.867 71.4846 141.438 71.071C137.84 67.6138 135.557 66.4475 133.771 67.0347C132.017 67.622 131.256 69.7311 130.983 70.4927C129.784 73.8176 132.439 79.9794 136.219 86.3729H136.21C133.994 83.5442 131.463 82.2292 129.9 82.9488C129.569 83.0981 128.75 83.5857 128.213 85.7195C127.361 89.0692 128.246 92.3198 129.676 95.6282C132.166 101.401 134.134 102.171 137.162 108.721C138.08 110.706 138.799 112.096 139.403 114.205C140.966 119.705 140.023 123.65 142.935 131.764C145.606 139.233 147.93 139.291 152.529 148.381C156.017 155.305 158.461 162.708 159.782 170.348L197.151 141.225C197.068 138.852 197.001 135.32 197.151 131.035C197.556 118.985 199.26 111.293 199.665 107.86C201.153 95.1064 192.205 84.9662 181.791 72.1964Z"
        fill="#C5D2E0"
      />
      <path
        d="M150.632 60.0093C152.687 65.0982 157.796 70.9272 158.623 71.8575M141.33 69.272C145.553 79.4501 156.775 93.6877 159.92 97.6214L141.33 69.272ZM134.195 82.7571C139.214 92.3564 148.155 103.693 150.908 107.136L134.195 82.7571Z"
        stroke="#212529"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M188.559 103.163C183.194 93.4077 177.244 86.8187 172.928 82.7049C168.951 78.9142 157.933 68.4129 152.345 71.391C151.704 71.746 151.151 72.2421 150.728 72.8418C147.701 76.9578 151.552 84.3624 153.619 88.3368C155.828 92.5854 160.077 99.1876 168.215 105.462"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M169.644 124.654C167.681 122.265 166.183 119.528 165.229 116.587C163.212 110.341 164.256 104.932 164.883 102.521"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M209.773 172.226L138.024 195.919C136.298 181.088 134.448 170.862 133.364 164.086C132.709 159.981 132.037 155.905 131.349 151.859C131.358 151.888 131.386 151.973 131.423 152.06C131.447 152.133 131.477 152.204 131.511 152.272C131.537 152.329 131.568 152.384 131.604 152.435C132.985 154.603 138.63 153.391 140.843 152.759C147.945 150.735 169.21 141.068 197.36 126.711L209.773 172.226Z"
        fill="#698AAE"
      />
      <path
        d="M133.184 162.948C138.88 164.576 144.961 164.222 150.43 161.942L201.199 140.784"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M133.607 146.012C132.667 147.385 130.47 150.665 131.6 152.435C132.986 154.606 138.633 153.39 140.842 152.76C147.945 150.734 169.211 141.064 197.36 126.708"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.3744 53.3576C61.4208 56.1583 54.5897 54.2108 52.0063 56.1655C49.3288 58.1915 49.3171 65.2686 46.2099 66.342C43.0714 67.426 38.6808 61.856 35.361 61.9225C32.0741 61.9883 27.9044 67.7376 24.692 66.7612C21.5924 65.8189 21.3178 58.7561 18.5171 56.8025C15.7164 54.8489 8.99328 57.0301 7.03854 54.4467C5.01265 51.7692 8.96803 45.8705 7.8947 42.7631C6.81066 39.6247 0.0670328 37.4278 0.000485892 34.108C-0.0653147 30.8211 6.57234 28.3659 7.5489 25.1535C8.49125 22.0541 4.30439 16.3159 6.25801 13.5152C8.21163 10.7145 15.0428 12.662 17.6262 10.7073C20.3036 8.68124 20.3153 1.60413 23.4226 0.530801C26.5612 -0.55323 30.9518 5.01681 34.2715 4.95026C37.5584 4.88446 41.7281 -0.864818 44.9406 0.111618C48.0401 1.05385 48.3148 8.11665 51.1155 10.0703C53.9162 12.0239 60.6393 9.84264 62.594 12.426C64.62 15.1036 60.6645 21.0023 61.7379 24.1097C62.8219 27.2482 69.5657 29.445 69.6321 32.7648C69.698 36.0517 63.0604 38.5068 62.0837 41.7192C61.1413 44.8187 65.3282 50.5569 63.3744 53.3576Z"
        fill="#008566"
      />
      <path
        d="M47.8136 24.5732L32.6934 39.6934C32.3029 40.0839 31.6697 40.0839 31.2792 39.6934L25.6172 34.0314"
        stroke="#E0F0ED"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M107.252 120.351L191.51 96.1437C193.582 95.5484 195.34 94.1676 196.409 92.2954C197.478 90.4232 197.773 88.2075 197.233 86.1205L184.18 35.7256L83 63.1343L96.9874 114.572C97.275 115.63 97.77 116.62 98.4436 117.485C99.1172 118.349 99.9559 119.072 100.911 119.609C101.866 120.147 102.919 120.489 104.007 120.617C105.096 120.744 106.199 120.654 107.252 120.351Z"
        fill="#FFE6C9"
      />
      <path
        d="M184.18 35.7256L141.126 79.1968L83 63.1343"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M146.968 82.4919C146.491 83.162 144.824 82.6961 144.193 83.1638C143.539 83.6485 143.536 85.3418 142.778 85.5986C142.011 85.858 140.94 84.5253 140.129 84.5412C139.327 84.5569 138.309 85.9325 137.524 85.6989C136.768 85.4734 136.701 83.7836 136.017 83.3162C135.333 82.8487 133.692 83.3706 133.214 82.7525C132.72 82.1119 133.686 80.7006 133.423 79.9571C133.159 79.2062 131.512 78.6806 131.496 77.8863C131.48 77.0999 133.101 76.5124 133.339 75.7438C133.569 75.0022 132.547 73.6293 133.024 72.9592C133.501 72.2891 135.169 72.7551 135.799 72.2874C136.453 71.8027 136.456 70.1094 137.214 69.8526C137.981 69.5932 139.053 70.9259 139.863 70.91C140.666 70.8942 141.684 69.5187 142.468 69.7523C143.225 69.9777 143.292 71.6676 143.975 72.135C144.659 72.6024 146.3 72.0805 146.778 72.6986C147.272 73.3393 146.307 74.7506 146.569 75.4941C146.833 76.245 148.48 76.7706 148.496 77.5649C148.512 78.3513 146.892 78.9387 146.653 79.7073C146.423 80.4489 147.445 81.8219 146.968 82.4919Z"
        fill="#BC3206"
      />
      <path
        d="M169.651 124.659C167.686 122.269 166.187 119.531 165.234 116.587C163.249 110.45 164.316 105.098 164.854 102.667C158.865 97.2905 155.499 91.9642 153.621 88.3414C151.554 84.3631 147.699 76.96 150.727 72.8416C151.152 72.2439 151.706 71.7493 152.348 71.3941C157.939 68.4166 168.956 78.9207 172.926 82.7089C177.243 86.8195 183.198 93.4115 188.558 103.163L169.651 124.659Z"
        fill="#C5D2E0"
      />
    </svg>
  );
};

export default ImageEmailValidation;
