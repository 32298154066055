import React from 'react';
import { string, bool, node } from 'prop-types';
import { Typography } from '@sofia/ui';
import { QuestionLegend, QuestionLegendDot } from './question-bullets.styles';

const Legend = ({ dotActive, color, children, ...props }) => (
  <QuestionLegend {...props}>
    <QuestionLegendDot active={dotActive} color={color} size={16} />{' '}
    <Typography>{children}</Typography>
  </QuestionLegend>
);

Legend.defaultProps = {
  dotActive: false,
};

Legend.propTypes = {
  children: node.isRequired,
  dotActive: bool,
  color: string,
};

export default Legend;
