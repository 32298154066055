import React, { useEffect, useCallback } from 'react';
import { string, object, func } from '@ampli/utils';
import {
  css,
  px2grid,
  useDialogState,
  Paragraph,
  Text,
  TextButton,
  Field,
  FileInput,
  Thumbnail,
} from '@ampli/ui';

import { CpfImage } from '../image';
import OnboardingDocumentsSkipModal from './onboarding-documents-skip-modal';

const receitaFederalUrl =
  'https://receita.economia.gov.br/orientacao/tributaria/cadastros/cadastro-de-pessoas-fisicas-cpf/servicos/comprovante-de-inscricao-no-cpf';

const OnboardingCpfDocument = ({ formik, onSkipStepClick, ...props }) => {
  const skipDialog = useDialogState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFieldValue = useCallback(formik.setFieldValue, []);

  useEffect(() => {
    setFieldValue('documentType', 'CPF');
  }, [setFieldValue]);

  return (
    <div {...props}>
      <Paragraph marginSize="x-large">
        <Text>
          Precisamos agora de seu CPF, escolha um local iluminado e tire uma
          foto da frente dele.
        </Text>
      </Paragraph>
      <Paragraph as="div" marginSize="small">
        <Field
          as={FileInput}
          name="documentFile"
          onChange={(e) => {
            const file = e.target.files[0];
            setFieldValue('documentFile', file);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.documentFile}
          error={formik.touched.documentFile && formik.errors.documentFile}
          accept="image/jpeg,image/png,application/pdf"
        >
          {formik.values.documentFile ? (
            <Thumbnail
              file={formik.values.documentFile}
              className={css`
                margin-right: ${px2grid(16)};
              `}
            />
          ) : (
            <CpfImage
              className={css`
                margin-right: ${px2grid(16)};
              `}
            />
          )}
          <Text size="small">Foto do seu CPF</Text>
        </Field>
      </Paragraph>
      <Paragraph marginSize="x-large">
        <TextButton
          color="accent-base"
          size="x-small"
          onClick={skipDialog.show}
        >
          Não tenho o CPF impresso
        </TextButton>
      </Paragraph>
      <OnboardingDocumentsSkipModal
        {...skipDialog}
        title="Não tenho o CPF impresso"
        description={
          <Text>
            Se o seu RG já tem o CPF informado nele, você pode pular essa etapa,
            caso contrário, você precisará acessar o{' '}
            <Text
              as="a"
              underline
              target="_blank"
              rel="noopener noreferrer"
              href={receitaFederalUrl}
            >
              site da receita federal
            </Text>{' '}
            e imprimir seu CPF.
          </Text>
        }
        skipLabel="Pular etapa"
        onSkipClick={() => {
          skipDialog.hide();
          onSkipStepClick && onSkipStepClick();
        }}
      />
    </div>
  );
};

OnboardingCpfDocument.propTypes = {
  className: string,
  formik: object.isRequired,
  onSkipStepClick: func.isRequired,
};

export default OnboardingCpfDocument;
