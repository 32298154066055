import React from 'react';
import { useTheme } from '@emotion/react';

import { SvgExternalLinkAlt } from '@sofia/ui';

interface IButtonIconProps {
  ctaLink?: string;
  color?: string;
}

const CtaIconToggler: React.FC<IButtonIconProps> = ({
  ctaLink,
  color,
}: IButtonIconProps) => {
  const theme = useTheme();
  const isExternalLink = ctaLink ? /^https?:\/\//.test(ctaLink) : false;

  const fill = color ? color : theme.colors.text.white;

  return <>{isExternalLink && <SvgExternalLinkAlt fill={fill} />}</>;
};

export default CtaIconToggler;
