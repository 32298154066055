import {
  cx,
  css,
  px2grid,
  getColor,
  mediaQuery,
  px2rem,
  Chip,
} from '@ampli/ui';
import PlayArrowRounded from '@material-ui/icons/PlayArrowRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import styled from '@emotion/styled';
import {
  ColorSemanticError,
  ColorSemanticErrorLight,
} from '@ampli/ui/src/tokens';
import { convertPXToVH, Typography } from '@sofia/ui';

export const courseCard = (props) =>
  cx(
    css`
      min-width: ${px2grid(240)};
      ${mediaQuery('desktop')} {
        width: ${px2grid(290)};
      }

      &:hover {
        border-color: red;
      }
    `,
    props.className
  );

export const degreeIcon = (props) => css`
  background-color: ${getColor(props.bgColor)};
  border-radius: 100%;
  height: ${px2grid(24)};
  padding: ${px2grid(4)};
  width: ${px2grid(24)};
`;
export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EllipsisText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PaddingBox = styled.div`
  padding: 25px 18px;
`;

export const FlexBox = styled.div`
  display: flex;
`;

export const Img = styled.img`
  border-radius: 6px;
  width: 100%;
  height: ${convertPXToVH(116)};
  object-fit: cover;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
`;

export const MarginWrapper = styled.div`
  margin-bottom: ${convertPXToVH(12)};
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const CardContainer = styled.div`
  background-color: ${({ background }) => background};
  padding: 32px;
  ${mediaQuery('mobile')} {
    padding: 24px;
  }
`;

export const AddWrapper = styled.div`
  margin: 0 16px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  min-width: 56px;
  background-color: ${({ background }) => background};
`;

export const ElectiveTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQuery('desktop')} {
    padding-left: 72px;
  }
`;

export const LiveButtonContainer = styled.div`
  position: absolute;
  padding: 0 16px 16px;
  width: 100%;
  top: 40px;
  z-index: 2;
  left: 0;
`;

export const PlayIcon = styled(PlayArrowRounded)`
  background: #a50e2870;
  padding: 4px;
  color: #fff;
  border-radius: 50%;
  &.MuiSvgIcon-root {
    font-size: ${px2rem(28)};
  }
`;

export const RecordIcon = styled(FiberManualRecordIcon)`
  color: ${ColorSemanticErrorLight};
  margin-right: 4px;
  &.MuiSvgIcon-root {
    font-size: ${px2rem(9.008)};
  }
`;

export const RelativeContainer = styled.div`
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 330px;
`;

export const LiveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: ${ColorSemanticError};
  border-radius: 8px;
  width: 100%;

  :hover {
    background: #c40a18;
    cursor: pointer;
  }
`;

export const FlexWrapperDeadline = styled.div`
  display: flex;
  margin: 0px 0px ${convertPXToVH(10)};

  ${mediaQuery('mobile')} {
    margin: ${convertPXToVH(16)} 0px ${convertPXToVH(10)};
  }
`;

export const FlexWrapperTimeLeft = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    width: 20px;
    height: 20px;
  }
`;

export const FreeTrialDaysLeftLabel = styled(Chip)`
  position: absolute;
  background: white;
  margin-top: ${px2grid(85)};
  margin-left: ${px2grid(15)};
  opacity: 90%;
  border: none;
  ${mediaQuery('mobile')} {
    margin-left: ${px2grid(5)};
  }
`;

export const NewDiscipline = styled(Chip)`
  position: absolute;
  background: white;
  margin-top: ${convertPXToVH(10)};
  margin-left: ${convertPXToVH(10)};
`;
