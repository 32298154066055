import React from 'react';
import { useTheme } from '@emotion/react';
import * as Styled from './alerts.styles';
import { Typography } from '@sofia/ui';
import PopUp from '../../pop-up/pop-up';
import { FinishEvaluationAlertProps } from '../types';

const FinishEvaluationAlert = ({
  onFinishExamClick,
  incomplete,
  modal,
  isDesktop,
  type,
}: FinishEvaluationAlertProps): React.ReactElement => {
  const [confirmPressed, setConfirmPressed] = React.useState(false);
  const theme = useTheme();

  const incompleteLabel = incomplete
    ? type && type[type.length - 1] === 'a'
      ? ' incompleta'
      : ' incompletos'
    : '';

  const handleCancel = () => {
    modal.hide();
  };

  return (
    <PopUp
      popUpState={modal}
      headerText={`Finalizar ${type}${incompleteLabel}`}
      isDesktop={isDesktop}
      ariaLabel={`Confirmação para enviar ${type}${incompleteLabel} para correção`}
      data-testid="finish-evaluation-alert"
      footer={
        <Styled.ActionsWrapper>
          <>
            <Styled.ButtonGhost
              data-testid="finish-evaluation-button"
              color={theme.colors.button.primary}
              onClick={(event) => {
                event.preventDefault();
                onFinishExamClick();
                setConfirmPressed(true);
              }}
              disabled={confirmPressed}
            >
              Sim, enviar
            </Styled.ButtonGhost>
            <Styled.ButtonGhost
              data-testid="finish-evaluation-cancel-button"
              color={theme.colors.text.black}
              onClick={handleCancel}
            >
              Cancelar
            </Styled.ButtonGhost>
          </>
        </Styled.ActionsWrapper>
      }
    >
      <Typography as="span" color={theme.colors.text.black} size={16}>
        Deseja terminar e enviar {type}
        {incomplete && <b>{incompleteLabel}</b>} para correção?
      </Typography>
    </PopUp>
  );
};

export default FinishEvaluationAlert;
