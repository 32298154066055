import { useFormik } from '@ampli/utils';
import { useResetPassword, useLoginStudent } from '@ampli/services';
import { getId } from '../lib/client-uuid';

const useResetPasswordForm = ({ onSubmit, onError, onCompleted }) => {
  const handleError = (error) => {
    const errorMsg = /Validation id .+ is invalid/gi.test(error?.message || '')
      ? 'Ocorreu um problema ao verificar seu telefone. ' +
        'Volte ao passo anterior e refaça a verificação.'
      : 'Ocorreu um problema ao tentar criar uma senha. Tente novamente.';

    onError && onError(errorMsg);
  };

  const [login] = useLoginStudent({
    onError: handleError,
    onCompleted: (response) => {
      if (response && !response.errors && response.data) {
        onCompleted && onCompleted(response.data);
      } else {
        handleError();
      }
    },
  });

  const [mutate, { data, called, loading, error }] = useResetPassword({
    onError: handleError,
  });

  const handleSubmit = (values) => {
    const username = values.username;
    const verificationId = values.validationId;
    const password = values.password;
    const code = values.code;
    const deviceId = getId();
    mutate({
      variables: {
        username,
        verificationId,
        password,
        code,
      },
    })
      .then(({ data, errors }) => {
        if (!data) {
          handleError(errors[0]);
          return false;
        }

        return login({
          variables: {
            username,
            password,
            deviceId,
          },
        });
      })
      .catch(console.error);

    onSubmit && onSubmit();
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      userId: '',
      validationId: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleSubmit,
  });

  return {
    formik,
    loading,
    called,
    error,
    data,
  };
};

export default useResetPasswordForm;
