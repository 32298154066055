export const clear = (value: string): string => {
  return value.replace(/[^0-9]/g, '');
};

export const applyMask = (value: string, mask: string): string => {
  let result = '';
  let inc = 0;

  Array.from(value).forEach((letter, index) => {
    if (!mask[index + inc].match(/[0-9]/)) {
      result += mask[index + inc];
      inc++;
    }
    result += letter;
  });

  return result;
};
