import { ApolloError } from '@apollo/client';
import React from 'react';

const useDownloadFileAnimation = ({
  error,
}: {
  error?: ApolloError | undefined;
}): {
  isFinished: boolean;
  isDownloading: boolean | null;
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean | null>>;
} => {
  const [isDownloading, setIsDownloading] = React.useState<boolean | null>(
    null
  );
  const [isFinished, setIsFinished] = React.useState(false);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isDownloading === false) {
      setIsFinished(true);
      timer = setTimeout(() => setIsDownloading(null), 75);
    }
    if (error) {
      setIsFinished(false);
    }
    return () => clearTimeout(timer);
  }, [isDownloading, error]);

  React.useEffect(() => {
    let finishedTimer: NodeJS.Timeout;
    if (isFinished) {
      finishedTimer = setTimeout(() => setIsFinished(false), 3000);
    }
    return () => clearTimeout(finishedTimer);
  }, [isFinished]);

  return { isFinished, isDownloading, setIsDownloading };
};

export default useDownloadFileAnimation;
