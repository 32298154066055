export const calculateProgress = (completedQuantity, subjectsQuantity) => {
  let percentageNumber = 0;
  if (completedQuantity != 0 && subjectsQuantity != 0) {
    percentageNumber = completedQuantity / subjectsQuantity;
    percentageNumber = percentageNumber > 1 ? 1 : percentageNumber;
  }
  const percentageLabel = `${Math.round(percentageNumber * 100)}%`;
  return {
    completed: completedQuantity,
    quantity: subjectsQuantity,
    percentage: percentageNumber,
    percentageLabel
  };
};