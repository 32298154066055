import { gql } from 'graphql.macro';

export const LIST_BANNERS_BY_ID = gql`
  query ListBannersById($data: StudentNotificationInputV2!) {
    data: listBannersByIdV3(data: $data) {
      id
      type
      title
      body
      ctaText
      ctaLink
      read
      image
      createdDate
      lastUpdateDate
      categoryType
    }
  }
`;

export const LIST_MODAL_BY_ID = gql`
  query ListModalById($data: StudentNotificationInputV2!) {
    data: listModalByIdV3(data: $data) {
      id
      type
      title
      body
      ctaText
      ctaLink
      infoText
      read
      image
      createdDate
      lastUpdateDate
      categoryType
    }
  }
`;

export const LIST_SLIDING_BY_ID = gql`
  query ListSlidingById($data: StudentNotificationInputV2!) {
    data: listCreativeSlidingByDeviceTypeAndStudentIdV3(data: $data) {
      id
      type
      title
      body
      ctaText
      ctaLink
      read
      image
      createdDate
      lastUpdateDate
      categoryType
    }
  }
`;

export const GET_TERMS_BY_ID = gql`
  query listContractServiceById($data: ContractServiceInput) {
    data: listContractServiceById(data: $data) {
      body
      title
      ctaText
      ctaLink
      infoText
      type
      categoryType
    }
  }
`;

export const LIST_NOTIFICATIONS_BY_ID = gql`
  query ListNotificationsById($data: StudentNotificationInputV2!) {
    data: listNotificationsByIdV3(data: $data) {
      bell
      creative {
        id
        type
        title
        body
        ctaText
        ctaLink
        read
        image
        createdDate
        lastUpdateDate
        categoryType
      }
    }
  }
`;

export const GET_COURSE_ROLES = gql`
  query GetCourseRoles($courseEnrollmentId: ID!) {
    data: getCourseEnrollment(courseEnrollmentId: $courseEnrollmentId) {
      roles
    }
  }
`;

export const LIST_PENDING_REMATRICULA_BY_ID = gql`
  query ListPendingEnrollById(
    $courseTypeCode: String!
    $courseVersionId: String!
    $courseId: String!
    $studentId: String!
  ) {
    data: listPendingEnrollById(
      data: {
        courseTypeCode: $courseTypeCode
        courseVersionId: $courseVersionId
        courseId: $courseId
        studentId: $studentId
      }
    ) {
      id
      title
      body
      type
      categoryType
      image
      ctaLink
      ctaText
      read
      statusCampaign
      createdDate
      lastUpdateDate
    }
  }
`;

export const UPDATE_READ_CREATIVE = gql`
  mutation UpdateReadV3($read: ReadInputV2) {
    data: updateReadV3(read: $read)
  }
`;

export const UPDATE_BELL = gql`
  mutation UpdateBellV3($courseEnrollmentId: ID) {
    data: updateBellV3(courseEnrollmentId: $courseEnrollmentId)
  }
`;

export const UPDATE_WELCOME_TOUR = gql`
  mutation UpdateStudentWelcomeTour {
    data: updateStudentWelcomeTourV2
  }
`;

export const UPDATE_SUBJECT_TOUR = gql`
  mutation UpdateStudentSubjectTour {
    data: updateStudentSubjectTourV2
  }
`;

export const UPLOAD_COMMUNICATION_BASE64_FILE = gql`
  mutation UploadCommunicationBase64File($data: UploadBase64FileInput!) {
    data: uploadCommunicationBase64File(data: $data)
  }
`;

export const GET_WELCOME_TOUR = gql`
  query GetStudentWelcomeTour {
    data: getStudentWelcomeTourV2 {
      alreadyRead
      shouldPresent
    }
  }
`;

export const GET_SUBJECT_TOUR = gql`
  query GetStudentSubjectTour($courseEnrollmentId: String!) {
    data: getStudentSubjectTourV2(course_enrollment_id: $courseEnrollmentId) {
      alreadyRead
      shouldPresent
      subjectId
    }
  }
`;

export const GET_STUDENT_PREFERENCES = gql`
  query GetStudentPreferences {
    data: getStudentPreferences {
      categories {
        code
        value
      }
    }
  }
`;

export const UPDATE_STUDENT_PREFERENCES = gql`
  mutation UpdateStudentPreferencesRequest($input: [PreferenceCategoryInput]!) {
    updateStudentPreferencesRequest(input: $input)
  }
`;
