import React from 'react';
import { object, func, bool, string } from 'prop-types';
import { Modal, Typography, convertPXToREM } from '@sofia/ui';
import { subjectEnrollmentStatusLabelByStatus } from '../../../../constants';

import {
  Container,
  HeaderDescription,
  PeriodAssignments,
  Assignment,
  AssignmentTitle,
  AssignmentDescription,
  WaringContainer,
} from './subject-calendar-modal.styles';
import { useTheme } from '@emotion/react';
import { dateFormat } from '../../../../modules/shared/utils';
import { useBreakpoint } from '../../../../modules';
import { useSubjectCalendarData } from '../../services/index';

const SubjectCalendarModal = ({
  status,
  handleStartSubjectCalendarTour,
  modalState,
  isFirstAccess,
}) => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();
  const { loading, calendarData } = useSubjectCalendarData();

  const currentSubjectStatus = subjectEnrollmentStatusLabelByStatus[status];

  const handleAgreeButton = () => {
    if (isFirstAccess !== 'false') {
      handleStartSubjectCalendarTour();
      modalState.hide();
    }

    modalState.hide();
    localStorage.setItem('isFirstAccess', false);
  };

  return !loading ? (
    <Modal
      isBottomSheet={!isDesktop}
      style={{
        minWidth: isDesktop ? convertPXToREM(708) : '100%',
      }}
      handleModal={modalState}
      header={{
        headerText: 'Calendário da disciplina',
        headerTextSize: isDesktop ? 32 : 24,
      }}
      footer={{
        buttonText: 'Entendi',
        buttonClick: () => {
          handleAgreeButton();
        },
      }}
    >
      <Container>
        <HeaderDescription>
          <Typography size={isDesktop ? 20 : 16}>
            Situação da disciplina: <strong>{currentSubjectStatus}</strong>
          </Typography>
        </HeaderDescription>

        <PeriodAssignments>
          {calendarData?.getCallendarSubjectEnrollment?.map(
            (assignment, index) => (
              <>
                <Assignment
                  key={'assignment' + index}
                  warning={assignment?.warn}
                >
                  <AssignmentTitle>
                    <Typography size={isDesktop ? 20 : 16} weight={700}>
                      {assignment?.title}
                    </Typography>

                    <Typography size={isDesktop ? 20 : 16} weight={700}>
                      {dateFormat(assignment?.startDate)} a{' '}
                      {dateFormat(assignment?.dueDate)}
                    </Typography>
                  </AssignmentTitle>

                  <AssignmentDescription>
                    <Typography size={isDesktop ? 16 : 14}>
                      {assignment?.subTitle}
                    </Typography>
                  </AssignmentDescription>
                </Assignment>
                {assignment?.warn && (
                  <WaringContainer>
                    <Typography
                      size={isDesktop ? 18 : 14}
                      weight={700}
                      color={theme.colors.text.dangerBold}
                    >
                      {assignment?.warn}
                    </Typography>
                  </WaringContainer>
                )}
              </>
            )
          )}
        </PeriodAssignments>
      </Container>
    </Modal>
  ) : null;
};

SubjectCalendarModal.propTypes = {
  handleStartSubjectCalendarTour: func,
  modalState: object,
  isFirstAccess: bool,
  status: string,
};

export default SubjectCalendarModal;
