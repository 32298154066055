import React from 'react';
import { number } from 'prop-types';

const ImageNoConnection = ({ width, height, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 376 458"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M188 457.845C291.83 457.845 376 373.71 376 269.923C376 166.136 291.83 82 188 82C84.1705 82 0 166.136 0 269.923C0 373.71 84.1705 457.845 188 457.845Z"
        fill="#EBF0F5"
      />
      <path
        d="M206.137 298.096C198.713 300.418 175.661 307.98 162.304 327.488C160.07 330.749 154.852 338.538 157.535 342.74C160.83 347.896 174.245 345.008 179.493 343.512C196.366 338.7 246.887 315.739 313.757 281.647C290.037 282.416 246.903 285.344 206.137 298.096Z"
        fill="#081F2D"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M325.669 338.281L201.743 350.026C190.307 320.133 176.455 299.392 166.414 286.409C130.26 239.585 67.7378 194.981 70.194 180.113C70.7835 176.499 72.8662 174.83 72.8662 174.83C77.3856 171.197 86.1095 174.948 91.2574 177.383C101.711 182.313 114.757 189.934 126.075 196.907C77.0315 166.011 54.4168 154.757 55.9091 140.617C56.0273 139.36 56.3413 136.493 58.385 134.273C68.8775 122.842 110.612 143.936 145.646 164.815C115.209 145.508 68.9367 113.121 70.9797 94.8545C71.157 93.1064 71.7463 87.9214 75.5579 85.0146C85.343 77.6101 106.741 92.3603 108.843 93.8529C93.2813 81.0471 83.6732 70.5196 85.2254 59.7369C85.4216 58.3817 86.1293 53.982 89.5677 51.1733C95.6981 46.1649 107.114 48.9736 122.598 56.6138C158.162 74.1926 215.438 117.54 232.002 130.208C228.982 125.648 227.034 120.465 226.304 115.045C224.988 105.5 226.363 90.5925 233.692 87.5087C246.011 82.3432 271.123 112.453 284.346 133.233C306.786 168.488 311.776 203.252 312.916 212.327C317.632 250.096 308.318 266.849 318.005 311.021C320.027 320.249 322.585 329.351 325.669 338.281Z"
        fill="#C5D2E0"
      />
      <path
        d="M87.3545 172.78C97.1635 178.825 108.437 185.695 121.206 193.665C134.019 201.664 144.167 208.167 149.686 211.722"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M116.765 145.41C136.056 159.28 156.22 171.495 165.774 177.177"
        stroke="#081F2D"
        strokeWidth="7.635"
        strokeMiterlimit="10"
      />
      <path
        d="M91.251 76.7246C96.875 82.6198 104.156 88.8619 112.574 95.7101C132.81 112.154 153.867 127.48 175.658 141.627"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M224.491 0H116.422C105.571 0 96.7734 8.79347 96.7734 19.6408V235.69C96.7734 246.537 105.571 255.33 116.422 255.33H224.491C235.343 255.33 244.14 246.537 244.14 235.69V19.6408C244.14 8.79347 235.343 0 224.491 0Z"
        fill="#E0F0ED"
      />
      <path
        d="M170.115 183.023C199.519 183.023 223.356 159.196 223.356 129.804C223.356 100.411 199.519 76.584 170.115 76.584C140.71 76.584 116.873 100.411 116.873 129.804C116.873 159.196 140.71 183.023 170.115 183.023Z"
        fill="#66CAAE"
      />
      <path
        d="M191.976 114.902L165.355 141.512L154.643 130.804"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M151.657 12.7793H189.257"
        stroke="#081F2D"
        strokeWidth="7.635"
        strokeMiterlimit="10"
      />
      <path
        d="M142.635 240.296H198.283"
        stroke="white"
        strokeWidth="7.635"
        strokeMiterlimit="10"
      />
      <path
        d="M226.362 264.843C225.498 259.776 219.072 218.766 244.085 198.339C249.803 193.665 256.386 191.21 256.386 191.21C256.917 173.317 252.653 160.943 247.956 152.419C239.704 137.414 228.603 131.561 226.303 115.043C224.987 105.497 226.362 90.5898 233.691 87.5061C246.011 82.3405 271.122 112.45 284.346 133.23C306.785 168.486 311.775 203.25 312.915 212.324C317.631 250.093 308.337 266.847 318.005 311.019C320.035 320.245 322.594 329.347 325.667 338.281"
        fill="#C5D2E0"
      />
      <path
        d="M256.385 191.209C256.385 168.829 252.204 160.129 247.956 152.418C239.703 137.413 228.602 131.56 226.303 115.042"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M226.362 264.844C225.498 259.776 219.072 218.767 244.085 198.34C249.784 193.686 256.385 191.211 256.386 191.21C258.545 190.341 260.764 189.626 263.024 189.068"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M343.251 389.74L172.799 446.001C168.7 410.784 164.305 386.501 161.73 370.408C160.174 360.661 158.578 350.983 156.941 341.375C156.964 341.443 157.03 341.644 157.117 341.851C157.174 342.024 157.244 342.193 157.326 342.356C157.387 342.491 157.461 342.62 157.546 342.741C160.826 347.89 174.238 345.011 179.495 343.512C196.366 338.705 246.884 315.749 313.759 281.657L343.251 389.74Z"
        fill="#3969EB"
      />
      <path
        d="M161.297 367.704C174.83 371.571 189.276 370.729 202.269 365.317L322.877 315.073"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
      <path
        d="M162.304 327.488C160.07 330.749 154.852 338.538 157.535 342.74C160.83 347.896 174.245 345.008 179.493 343.512C196.366 338.7 246.887 315.739 313.757 281.647"
        stroke="#081F2D"
        strokeWidth="0.69"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

ImageNoConnection.defaultProps = {
  width: 376,
  height: 458,
};

ImageNoConnection.propTypes = {
  width: number,
  height: number,
};

export default ImageNoConnection;
