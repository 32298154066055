import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
const _excluded = ["data"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useMutation, useQuery, useLazyQuery } from '../hooks';
import { CANCEL_PENDING_INSTALMENT, CHANGE_BILLING_DAY, GET_AVAILABLE_BILLING_DAYS, GET_INSTALMENT_STATUS, PAY_INSTALMENT_BY_BOLETO, PAY_INSTALMENT_BY_EXISTING_CREDIT_CARD, PAY_INSTALMENT_BY_NEW_CREDIT_CARD, GET_MONTH_OF_NEW_BILLING_DAY, GENERATE_BOLETO_FILE, GET_INVOICE_DOCUMENT, GET_NEGOTIATION_URL } from './queries';
import { payInstalmentByNewCreditCardSchema } from './schemas';
export const usePayInstalmentByBoleto = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(PAY_INSTALMENT_BY_BOLETO, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    }
  }));
};
export const usePayInstalmentByNewCreditCard = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const mutation = useMutation(PAY_INSTALMENT_BY_NEW_CREDIT_CARD, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    }
  }));
  return [...mutation, payInstalmentByNewCreditCardSchema];
};
export const usePayInstalmentByExistingCreditCard = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(PAY_INSTALMENT_BY_EXISTING_CREDIT_CARD, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    }
  }));
};
export const useChangeBillingDay = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(CHANGE_BILLING_DAY, options);
};
export const useGetAvailableBillingDays = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useQuery(GET_AVAILABLE_BILLING_DAYS, options);
};
export const useGetInstalmentStatus = function (instalmentId, courseEnrollmentId) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const _useQuery = useQuery(GET_INSTALMENT_STATUS, _objectSpread(_objectSpread({
      skip: !instalmentId
    }, options), {}, {
      variables: {
        instalmentId,
        courseEnrollmentId
      },
      fetchPolicy: 'no-cache'
    })),
    {
      data
    } = _useQuery,
    rest = _objectWithoutProperties(_useQuery, _excluded);
  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export const useCancelPendingInstalment = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(CANCEL_PENDING_INSTALMENT, options);
};
export const useGenerateBoletoFile = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(GENERATE_BOLETO_FILE, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    }
  }));
};
export const useGetMonthOfNewBillingDay = function (paymentPlanId, billingDay) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return useQuery(GET_MONTH_OF_NEW_BILLING_DAY, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      paymentPlanId,
      billingDay
    },
    context: {
      autoHandle: true
    }
  }));
};
export const useGetInvoiceDocument = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(GET_INVOICE_DOCUMENT, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const useGetNegotiationUrl = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useLazyQuery(GET_NEGOTIATION_URL, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId
    },
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};