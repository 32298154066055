import styled from '@emotion/styled';
import { mediaQuery } from '@ampli/ui';

export const Flex = styled.div<{ margin?: string }>`
  display: flex;
  ${({ margin }) => `margin: ${margin};`}
`;

export const ItemsWrapper = styled.div`
  > :first-of-type {
    margin-bottom: 24px;
  }
  span {
    line-height: 120%;
  }
`;

export const ItemsList = styled.div`
  margin: 24px 0;
`;

export const ActionsWrapper = styled(Flex)`
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  button {
    width: 100%;
  }

  ${mediaQuery('desktop')} {
    flex-direction: row;
    button {
      width: 100%;
    }
    button:last-of-type {
      margin-top: 0;
      margin-left: 40px;
      height: 100%;
    }
  }
`;

export const IconWrapper = styled.div`
  margin-right: 16px;
  svg {
    width: 26px;
  }
`;
