import styled from '@emotion/styled';

import { SvgArrowRight } from '@sofia/ui';

export const ArrowRight = styled(SvgArrowRight)`
  margin: 0px 0px 0px 8px !important;

  svg,
  path {
    fill: #ffffff !important;
  }
`;
