import React from 'react';

import { usePublicationFormContext } from '../../../../../../../contexts/publication-form-context';

import {
  IUseTextStepFormLogicParams,
  IUseTextStepFormLogic,
} from './text-step-form.types';

const useTextStepFormLogic = ({
  handleSetIsButtonEnabled,
}: IUseTextStepFormLogicParams): IUseTextStepFormLogic => {
  const {
    states: { titleTextareaRef, publicationTextareaRef },
  } = usePublicationFormContext();

  const [title, setTitle] = React.useState<string>('');
  const [titleCount, setTitleCount] = React.useState<number>(0);
  const [publicationText, setPublicationText] = React.useState<string>('');

  const [publicationTextCount, setPublicationTextCount] =
    React.useState<number>(0);

  const handleOnChangeTitle = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setTitle(event.target.value);
    setTitleCount(event.target.value.length);
  };

  const handleOnChangePublicationText = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setPublicationText(event.target.value);
    setPublicationTextCount(event.target.value.length);
  };

  const charactersLimitTitle = 180;
  const charactersLimitPublicationText = 540;
  const minCharacters = 20;

  React.useEffect(() => {
    if (titleCount >= minCharacters && publicationTextCount >= minCharacters) {
      handleSetIsButtonEnabled(true);
    } else {
      handleSetIsButtonEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleCount, publicationTextCount]);

  return {
    states: {
      titleTextareaRef,
      publicationTextareaRef,
      title,
      titleCount,
      publicationText,
      publicationTextCount,
      charactersLimitTitle,
      charactersLimitPublicationText,
      minCharacters,
    },
    actions: {
      handleOnChangeTitle,
      handleOnChangePublicationText,
    },
  };
};

export default useTextStepFormLogic;
