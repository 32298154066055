import React from 'react';

import { usePublicationFormContext } from '../../../../contexts/publication-form-context';
import { useCreatePublication } from '../../../../services/graphql-hooks/publication-form/hooks';
import { IUsePublicationForm } from './publication-form.types';
import { useSectionPageData } from '../../../../../section/pages/learning-object/hooks';
import { useGetState } from '../../../../../../state';
import { useModal } from '@sofia/ui';

const usePublicationFormLogic = (): IUsePublicationForm => {
  const [hasSuceeded, setHasSuceeded] = React.useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = React.useState<boolean>(false);

  const errorModal = useModal();
  const waitingModal = useModal();

  const [, { setOpenCommunitySection }] = useGetState();

  const { actions, states } = usePublicationFormContext();

  const {
    loading: isSectionPageDataLoading,
    learningObjectData,
    subjectEnrollment,
    courseEnrollment,
    learningUnit,
    section,
  } = useSectionPageData();

  const { mutate, metadata } = useCreatePublication({
    courseEnrollmentId: courseEnrollment?.id,
    title: states.titleTextareaRef.current?.value as string,
    body: states.publicationTextareaRef.current?.value as string,
    category: states.categorySelectedRef.current,
    learningUnitName: learningUnit?.title,
    learningUnitId: learningUnit?.id,
    learningUnitOrder: String(learningUnit?.order),
    sectionIdOrder: String(section?.order),
    sectionId: section?.id,
    lessonSectionOrder: learningObjectData?.learningObjectIndex,
    sectionName: section?.title,
    subjectId: subjectEnrollment?.subject?.id as string,
    subjectName: subjectEnrollment?.subject?.name as string,
  });

  const handleButtonPrimary = async (): Promise<void> => {
    if (states.isCategoryStep) {
      actions.setStep(states.step + 1);
    } else if (states.isTextStep) {
      waitingModal.show();

      await mutate()
        .then((response) => {
          setHasSuceeded(true);
          setTimeout(() => {
            setHasSuceeded(false);
          }, 5000);

          return response;
        })
        .catch(() => {
          waitingModal.hide();
          errorModal.show();
        })
        .finally(() => {
          waitingModal.hide();
          setOpenCommunitySection(false);
        });
    }
  };

  const handleSecondaryButton = (): void => {
    if (states.isCategoryStep) {
      setOpenCommunitySection(false);
    } else {
      actions.setStep(states.step - 1);
    }
  };

  const getPrimaryButtonText = (): string => {
    if (states.isCategoryStep) return 'Continuar';
    if (metadata?.loading) return 'Loading...';

    return 'Publicar';
  };

  const getSecondaryButtonText = (): string => {
    if (states.isCategoryStep) return 'Cancelar';
    return 'Voltar';
  };

  return {
    actions: {
      setIsButtonEnabled,
      handleButtonPrimary,
      handleSecondaryButton,
      getPrimaryButtonText,
      getSecondaryButtonText,
    },
    states: {
      isSectionPageDataLoading,
      isButtonEnabled,
      waitingModal,
      errorModal,
      hasSuceeded,
    },
  };
};

export default usePublicationFormLogic;
