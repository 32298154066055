import React from 'react';
import { ResumeList } from '../../../..';
import {
  NameWrapper,
  Wrapper,
  WrapperImg,
  WrapperResume,
} from './subject-presentation-teacher-info.styles';
import { object } from 'prop-types';
import { FrameIcon } from '../../../../image';
import { Typography, Avatar, convertPXToVH } from '@sofia/ui';
import { useTheme } from '@emotion/react';

const TeacherInfo = ({ teacher }) => {
  const theme = useTheme();
  if (teacher) {
    return (
      <WrapperResume>
        <Wrapper>
          <Avatar
            value={teacher.name}
            aria-hidden="true"
            size={80}
            src={teacher.picture}
          />
          <NameWrapper>
            <Typography margin="4px 0px" color="#444" size={18}>
              {teacher.name}
            </Typography>
            <Typography weight="bold" color="#444" size={14}>
              Professor(a)
            </Typography>
          </NameWrapper>
        </Wrapper>
        <Typography margin="4px 0px" color="#444" size={18}>
          {teacher.email}
        </Typography>
        <ResumeList resume={teacher?.resume} />
      </WrapperResume>
    );
  } else {
    return (
      <>
        <WrapperImg top="4px">
          <FrameIcon aria-hidden="true" width={`200px`} height={`200px`} />
          <Typography
            color={theme.colors.text.black}
            size={16}
            margin={`${convertPXToVH(20)} 0px ${convertPXToVH(10)} 0px`}
          >
            Ainda não há professores cadastrados nesta disciplina
          </Typography>
          <Typography
            color={theme.colors.text.lightBlack}
            size={14}
            margin={`${convertPXToVH(21)} 0px ${convertPXToVH(8)} 0px`}
          >
            Tente novamente mais tarde
            <span aria-labelledby="rosto triste" role="img">
              😕
            </span>
          </Typography>
        </WrapperImg>
      </>
    );
  }
};

TeacherInfo.propTypes = {
  teacher: object,
};

export default TeacherInfo;
