import styled from '@emotion/styled';

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 24px 0px;
  margin-bottom: 16px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 24px 24px;
  margin-top: 16px;
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 2px;
  margin: 16px 0px;
  object-fit: cover;
  object-position: center;
`;

export const InfoTextContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.alerts.background.primary};
`;
