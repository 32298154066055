import { useListBannersById } from '../../services/hooks';
import { useGetState } from '../../../../state';
import { Creative } from '../../types';

export type Banner = Creative;

type UseCommunicationBanner = () => {
  courseEnrollmentId: string;
  banners: Banner[];
  loading: boolean;
};

export const useCommunicationBanner: UseCommunicationBanner = () => {
  const [{ selectedCourseEnrollmentId }] = useGetState();

  const { data: banners, loading } = useListBannersById<Banner[]>(
    selectedCourseEnrollmentId,
    { context: { skipLogout: true } }
  );

  return { banners, loading, courseEnrollmentId: selectedCourseEnrollmentId };
};
