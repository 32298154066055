import { convertPXToVH, convertPXToVW, convertPXToREM } from '@sofia/ui';
import { px2grid, mediaQuery, Chip } from '@ampli/ui';

import styled from '@emotion/styled';

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems};
`;

export const PaddingBox = styled.div`
  padding: 25px 18px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const FlexBox = styled.div`
  display: flex;
  height: 100%;

  ${mediaQuery('mobile')} {
    flex-direction: column;
  }
`;

export const Img = styled.img`
  border-radius: 6px 0px 0px 6px;
  width: ${convertPXToVW(300)};
  min-height: ${convertPXToVH(256)};
  object-fit: cover;

  ${mediaQuery('mobile')} {
    width: 100%;
    min-height: ${convertPXToVW(200)};
    border-radius: 6px 6px 0px 0px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ padding }) => padding || '16px'};
`;

export const Tag = styled(Chip)`
  background: ${({ theme }) => theme.colors.calendar.lightBlack};
  border: none;
  width: fit-content;
  font-size: ${convertPXToREM(16)};
  padding: 3px 8px;

  ${mediaQuery('mobile')} {
    margin: ${px2grid(6)} 0px;
  }
`;
