import React from 'react';
import { func, bool } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import {
  DialogBackdrop,
  DialogHeader,
  DialogSection,
  Text,
  Paragraph,
  DialogFooter,
  GradientButton,
  TextButton,
  DialogAlert,
} from '@ampli/ui';

import { WITH_FULLSCREEN, WITHOUT_FULLSCREEN } from '../../constants';
import { DiscardProgressImage } from '../image';

const modes = {
  [WITH_FULLSCREEN]: {
    title: 'Sair do modo tela cheia',
    subtitle: 'Ao sair da tela cheia você perderá todo seu progresso na prova.',
    description:
      'Este é um aviso único, caso decida retornar à prova, não saia novamente, ou perderá sua prova automaticamente por medidas de segurança.',
  },
  [WITHOUT_FULLSCREEN]: {
    title: 'Você saiu da prova',
    subtitle: 'Notamos que você saiu da tela de prova.',
    description:
      'Este é um aviso único, caso decida retornar à prova, não saia novamente, ou perderá sua prova automaticamente por medidas de segurança.',
  },
};

const ExamExitFullscreenModal = ({
  fullscreenEnabled = true,
  onReturnToExamClick,
  onCancelExamClick,
  ...props
}) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const mode = fullscreenEnabled ? WITH_FULLSCREEN : WITHOUT_FULLSCREEN;
  const data = modes[mode];

  return (
    <>
      <DialogBackdrop aria-hidden {...props} />
      <DialogAlert {...props} hideOnEsc={false} hideOnClickOutside={false}>
        <DialogHeader>
          <Paragraph textAlign="center" marginSize="x-large">
            <DiscardProgressImage aria-hidden />
          </Paragraph>
          <Paragraph
            as="div"
            aria-label="Deseja mesmo sair do modo tela cheia?"
            textAlign="center"
          >
            <Text as="strong" color="text-dark">
              {data.title}
            </Text>
          </Paragraph>
        </DialogHeader>
        <DialogSection>
          <Paragraph>
            <Text size="small">{data.subtitle}</Text>
          </Paragraph>
          <Paragraph>
            <Text as="strong" size="small">
              {data.description}
            </Text>
          </Paragraph>
        </DialogSection>
        <DialogFooter>
          <Paragraph textAlign="center" marginSize="large">
            <GradientButton fluid={!isDesktop} onClick={onReturnToExamClick}>
              Retornar à prova
            </GradientButton>
          </Paragraph>
          <Paragraph textAlign="center">
            <TextButton
              fluid={!isDesktop}
              underline
              onClick={onCancelExamClick}
            >
              Desistir
            </TextButton>
          </Paragraph>
        </DialogFooter>
      </DialogAlert>
    </>
  );
};

ExamExitFullscreenModal.propTypes = {
  hide: func.isRequired,
  onReturnToExamClick: func.isRequired,
  onCancelExamClick: func.isRequired,
  fullscreenEnabled: bool,
};

export default ExamExitFullscreenModal;
