import styled from '@emotion/styled';
import { convertPXToREM, convertPXToVH, convertPXToVW } from '../../utils';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Label = styled.label`
  font-size: ${convertPXToREM(16)};
  color: ${_ref => {
  let {
    colorLabel
  } = _ref;
  return colorLabel;
}};
  font-weight: 700;
  margin-bottom: 8px;
`;
export const Select = styled.select`
  padding: ${convertPXToVH(16)} ${convertPXToVW(16)};
  border-radius: 8px;
  background: ${_ref2 => {
  let {
    background
  } = _ref2;
  return background;
}};
  color: ${_ref3 => {
  let {
    color
  } = _ref3;
  return color;
}};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${_ref4 => {
  let {
    maxWidth
  } = _ref4;
  return maxWidth;
}};

  ${_ref5 => {
  let {
    error,
    borderColor
  } = _ref5;
  if (error) {
    return `border: 2px solid #bc3206;`;
  } else {
    return `border: 2px solid ${borderColor}};`;
  }
}} &: focus {
    border: 2px solid #004ecc;
  }

  &: focus-visible {
    outline: none;
  }

  //Icon
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC45OTk3IDEuMTY5N0MxMC44MTIzIDAuOTgzNDQ4IDEwLjU1ODkgMC44Nzg5MDYgMTAuMjk0NyAwLjg3ODkwNkMxMC4wMzA1IDAuODc4OTA2IDkuNzc3MDcgMC45ODM0NDggOS41ODk3IDEuMTY5N0w1Ljk5OTcgNC43MDk3TDIuNDU5NyAxLjE2OTdDMi4yNzIzNCAwLjk4MzQ0OCAyLjAxODg5IDAuODc4OTA2IDEuNzU0NyAwLjg3ODkwNkMxLjQ5MDUyIDAuODc4OTA2IDEuMjM3MDcgMC45ODM0NDggMS4wNDk3IDEuMTY5N0MwLjk1NTk3NiAxLjI2MjY2IDAuODgxNTgyIDEuMzczMjYgMC44MzA4MTMgMS40OTUxMkMwLjc4MDA0NCAxLjYxNjk4IDAuNzUzOTA2IDEuNzQ3NjkgMC43NTM5MDYgMS44Nzk3QzAuNzUzOTA2IDIuMDExNzEgMC43ODAwNDQgMi4xNDI0MiAwLjgzMDgxMyAyLjI2NDI4QzAuODgxNTgyIDIuMzg2MTMgMC45NTU5NzYgMi40OTY3NCAxLjA0OTcgMi41ODk3TDUuMjg5NyA2LjgyOTdDNS4zODI2NyA2LjkyMzQzIDUuNDkzMjcgNi45OTc4MiA1LjYxNTEzIDcuMDQ4NTlDNS43MzY5OSA3LjA5OTM2IDUuODY3NjkgNy4xMjU1IDUuOTk5NyA3LjEyNTVDNi4xMzE3MiA3LjEyNTUgNi4yNjI0MiA3LjA5OTM2IDYuMzg0MjggNy4wNDg1OUM2LjUwNjE0IDYuOTk3ODIgNi42MTY3NCA2LjkyMzQzIDYuNzA5NyA2LjgyOTdMMTAuOTk5NyAyLjU4OTdDMTEuMDkzNCAyLjQ5Njc0IDExLjE2NzggMi4zODYxMyAxMS4yMTg2IDIuMjY0MjhDMTEuMjY5NCAyLjE0MjQyIDExLjI5NTUgMi4wMTE3MSAxMS4yOTU1IDEuODc5N0MxMS4yOTU1IDEuNzQ3NjkgMTEuMjY5NCAxLjYxNjk4IDExLjIxODYgMS40OTUxMkMxMS4xNjc4IDEuMzczMjYgMTEuMDkzNCAxLjI2MjY2IDEwLjk5OTcgMS4xNjk3WiIgZmlsbD0iIzIxMjUyOSIvPgo8L3N2Zz4K');
  background-repeat: no-repeat, repeat;
  background-position: right ${convertPXToVW(16)} top 50%, 0 0;
  background-size: ${convertPXToREM(10)} auto, 100%;
`;
export const Option = styled.option`
  font-size: ${convertPXToREM(16)};
  font-weight: 400;
  color: #212529;
  opacity: 0.5;
`;