import styled from '@emotion/styled';

export const Grid = styled.div`
  width: 100%;
  > [role='tablist'] {
    gap: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
