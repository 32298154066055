import React from 'react';
import { Switch } from 'react-router-dom';
import { GuardedRoute } from 'react-router-guards';
import { useServiceClient } from '@ampli/services';
import { useGetState } from '../state';
import { contentGuard, internshipGuard } from '../guards';
import { useQueryParams } from '../hooks';
import routePath from './routes-path';
import {
  MandatoryInternshipConfirmCompanyPage,
  MandatoryInternshipCompanyInputPage,
  MandatoryInternshipGuidePage,
  MandatoryInternshipInfoPage,
  MandatoryInternshipPage,
  MandatoryInternshipSubjectPresentationPage,
} from './lazy-routes';
import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';

const MandatoryInternshipContext = React.createContext({
  company: {},
  setCompanyInfo: () => {},
});

export const useMandatoryInternshipContext = () =>
  React.useContext(MandatoryInternshipContext);

const Routes = () => {
  const { states } = useCourseEnrollmentsContext();
  const response = states.courseEnrollments;

  const handleGuardedRoute = async (to, from, next) => {
    await contentGuard(to, from, next, response);
  };

  const clients = useServiceClient('all');

  const queryParams = useQueryParams();

  const [state, actions] = useGetState();

  const meta = { clients, actions, state, queryParams };
  const authMeta = {
    ...meta,
    auth: { redirect: routePath.login },
    isRegistrationPage: false,
  };
  const [company, setCompany] = React.useState({});

  return (
    <MandatoryInternshipContext.Provider
      value={{
        company,
        setCompany,
      }}
    >
      <Switch>
        <GuardedRoute
          exact
          path={routePath.mandatoryInternshipPage}
          meta={authMeta}
          guards={[handleGuardedRoute, internshipGuard]}
        >
          <MandatoryInternshipPage />
        </GuardedRoute>
        <GuardedRoute
          exact
          path={routePath.mandatoryInternshipCompanyInputPage}
          meta={{ ...authMeta, isRegistrationPage: true }}
          guards={[handleGuardedRoute, internshipGuard]}
        >
          <MandatoryInternshipCompanyInputPage />
        </GuardedRoute>
        <GuardedRoute
          exact
          path={routePath.mandatoryInternshipConfirmCompanyPage}
          meta={{ ...authMeta, isRegistrationPage: true }}
          guards={[handleGuardedRoute, internshipGuard]}
        >
          <MandatoryInternshipConfirmCompanyPage />
        </GuardedRoute>
        <GuardedRoute
          exact
          path={routePath.mandatoryInternshipInfoPage}
          meta={{ ...authMeta, isRegistrationPage: true }}
          guards={[handleGuardedRoute, internshipGuard]}
        >
          <MandatoryInternshipInfoPage />
        </GuardedRoute>
        <GuardedRoute
          exact
          path={routePath.mandatoryInternshipGuidePage}
          meta={{ ...authMeta, isRegistrationPage: true }}
          guards={[handleGuardedRoute, internshipGuard]}
        >
          <MandatoryInternshipGuidePage />
        </GuardedRoute>

        <GuardedRoute
          exact
          path={routePath.mandatoryInternshipSubjectPresentationPage}
          meta={authMeta}
          guards={[handleGuardedRoute, internshipGuard]}
        >
          <MandatoryInternshipSubjectPresentationPage />
        </GuardedRoute>
      </Switch>
    </MandatoryInternshipContext.Provider>
  );
};

export default Routes;
