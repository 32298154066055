import React from 'react';
import { useGetSubjectStaff } from '@ampli/services';
import { useFindInternshipPresentationSubjectEnrollment } from '../components/internship/services/hooks';
import useInternshipBaseData from './use-internship-base-data';

const useInternshipPresentationPageData = () => {
  const { me, handleLogo, handleLogout, baseLoading, subjectEnrollmentId } =
    useInternshipBaseData();

  const { data: subjectEnrollment, loading: subjectEnrollmentLoading } =
    useFindInternshipPresentationSubjectEnrollment(subjectEnrollmentId);

  const { data: dataSubjectStaff, loading: loadingSubjectStaff } =
    useGetSubjectStaff(subjectEnrollmentId);

  const subject = React.useMemo(
    () => ({
      ...subjectEnrollment?.subject,
      subjectEnrollmentId,
    }),
    [subjectEnrollment, subjectEnrollmentId]
  );

  const teacher = dataSubjectStaff?.data.teacher;

  return {
    me,
    handleLogo,
    handleLogout,
    teacher,
    subject,
    loading: subjectEnrollmentLoading || baseLoading || loadingSubjectStaff,
  };
};

export default useInternshipPresentationPageData;
