import React from 'react';
import QuizCorrectionBase from './base/quiz-correction-base';

import { quizCorrectionProps } from './helper';

const QuizCorrectionDesktop = ({ ...props }) => {
  return <QuizCorrectionBase {...props} isResponsive={false} />;
};

QuizCorrectionDesktop.defaultProps = {
  fontSize: 'medium',
};

QuizCorrectionDesktop.propTypes = quizCorrectionProps;

export default QuizCorrectionDesktop;
