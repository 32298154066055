import { lazy } from 'react';

export const LeadPage = lazy(() =>
  import(/* webpackChunkName: "Login" */ '../pages/login/lead-page')
);
export const LegacyPage = lazy(() =>
  import(/* webpackChunkName: "Login" */ '../pages/login/legacy-page')
);
export const LoginPage = lazy(() =>
  import(/* webpackChunkName: "Login" */ '../pages/login/login-page')
);
export const DynamicsLoginPage = lazy(() =>
  import(/* webpackChunkName: "Login" */ '../pages/login/dynamics-login-page')
);
export const HomePage = lazy(() =>
  import(/* webpackChunkName: "Home" */ '../pages/home-page/home-page')
);
export const ValidateAccountPage = lazy(() =>
  import(/* webpackChunkName: "Login" */ '../pages/login/validate-account-page')
);
export const PasswordPage = lazy(() =>
  import(/* webpackChunkName: "Login" */ '../pages/login/password-page')
);
export const NewPasswordPage = lazy(() =>
  import(/* webpackChunkName: "Login" */ '../pages/login/new-password-page')
);
export const OnboardingWelcomePage = lazy(() =>
  import(
    /* webpackChunkName: "Onboarding" */ '../pages/onboarding/onboarding-welcome-page'
  )
);
export const OnboardingCompromisePage = lazy(() =>
  import(
    /* webpackChunkName: "Onboarding" */ '../pages/onboarding/onboarding-compromise-page'
  )
);
export const OnboardingDocumentsPage = lazy(() =>
  import(
    /* webpackChunkName: "Onboarding" */ '../pages/onboarding/onboarding-documents-page'
  )
);
export const ElectiveSubjectOnBoardingPage = lazy(() =>
  import(
    /* webpackChunkName: "ElectiveSubject" */ '../pages/elective/elective-subject-onboarding-page'
  )
);

export const ElectiveSubjectChoicesPage = lazy(() =>
  import(
    /* webpackChunkName: "ElectiveSubject" */ '../pages/elective/elective-subject-choices-page'
  )
);
export const SubjectPage = lazy(() =>
  import(/* webpackChunkName: "Subject" */ '../pages/subject/subject-page')
);

export const ElectiveSubjectPresentationPage = lazy(() =>
  import(
    /* webpackChunkName: "ElectiveSubject" */ '../pages/elective/elective-subject-presentation-page'
  )
);

export const SubjectPresentationPage = lazy(() =>
  import(
    /* webpackChunkName: "Subject" */ '../pages/subject/subject-presentation-page'
  )
);
export const SyllabusPage = lazy(() =>
  import(/* webpackChunkName: "Syllabus" */ '../pages/syllabus/syllabus-page')
);
export const AcademicCalendarPage = lazy(() =>
  import(
    /* webpackChunkName: "CalendarAcademic" */ '../pages/academic-calendar/academic-calendar-page'
  )
);
export const SyllabusDocumentPage = lazy(() =>
  import(
    /* webpackChunkName: "Syllabus" */ '../pages/syllabus/syllabus-document-page'
  )
);
export const QuizOnboardingPage = lazy(() =>
  import(/* webpackChunkName: "Quiz" */ '../pages/quiz/quiz-onboarding-page')
);
export const TakeQuizPage = lazy(() =>
  import(/* webpackChunkName: "Quiz" */ '../pages/quiz/take-quiz-page')
);
export const QuizInAnalysisPage = lazy(() =>
  import(/* webpackChunkName: "Quiz" */ '../pages/quiz/quiz-in-analysis-page')
);
export const QuizResultsAttemptsPage = lazy(() =>
  import(
    /* webpackChunkName: "Quiz" */ '../pages/quiz/quiz-results-attempts-page'
  )
);
export const QuizCorrectionPage = lazy(() =>
  import(/* webpackChunkName: "Quiz" */ '../pages/quiz/quiz-correction-page')
);
export const ExamOnboardingPage = lazy(() =>
  import(/* webpackChunkName: "Exam" */ '../pages/exam/exam-onboarding-page')
);
export const TakeExamPage = lazy(() =>
  import(/* webpackChunkName: "Exam" */ '../pages/exam/take-exam-page')
);
export const ExamInAnalysisPage = lazy(() =>
  import(/* webpackChunkName: "Exam" */ '../pages/exam/exam-in-analysis-page')
);
export const ExamResultPage = lazy(() =>
  import(/* webpackChunkName: "Exam" */ '../pages/exam/exam-result-page')
);
export const ExamCorrectionPage = lazy(() =>
  import(/* webpackChunkName: "Exam" */ '../pages/exam/exam-correction-page')
);
export const SelectLoginType = lazy(() =>
  import(
    /* webpackChunkName: "Login" */ '../pages/login/select-login-type-page'
  )
);
export const TalkToUsLogin = lazy(() =>
  import(/* webpackChunkName: "Login" */ '../pages/login/TalkToUs/talk-to-us')
);
export const GradeHomePage = lazy(() =>
  import(/* webpackChunkName: "Grade" */ '../pages/grade/grade-home-page')
);
export const GradeSubjectPage = lazy(() =>
  import(/* webpackChunkName: "Grade" */ '../pages/grade/grade-subject-page')
);
export const CourseSelectionPage = lazy(() =>
  import(
    /* webpackChunkName: "CourseSelection" */ '../pages/course-selection-page'
  )
);
export const StudentPage = lazy(() =>
  import(
    /* webpackChunkName: "CourseSelection" */ '../pages/student/student-page'
  )
);
export const AcoPage = lazy(() =>
  import(/* webpackChunkName: "CourseSelection" */ '../pages/aco/aco-page')
);
export const CourseCancellationPage = lazy(() =>
  import(
    /* webpackChunkName: "CourseCancellation" */ '../pages/course-cancellation/course-cancellation-page'
  )
);
export const MobileWatsonChatbotPage = lazy(() =>
  import(
    /* webpackChunkName: "MobileWatsonChatbot" */ '../pages/mobile/mobile-watson-chatbot-page'
  )
);
export const SecretaryPage = lazy(() =>
  import(
    /* webpackChunkName: "Secretary" */ '../pages/secretary/secretary-page'
  )
);

export const SecretaryProgramaticContent = lazy(() =>
  import(
    /* webpackChunkName: "Secretary" */ '../pages/secretary-progamatic-content/secretary-programatic-content-page'
  )
);

export const SecretaryRegistrationPage = lazy(() =>
  import(
    /* webpackChunkName: "SecretaryRegistration" */ '../pages/secretary-registration/secretary-registration-page'
  )
);

export const SecretarySendingDocumentsPage = lazy(() =>
  import(
    /* webpackChunkName: "Secretary Registration" */ '../pages/secretary-sending-documents/secretary-sending-documents-page/secretary-sending-documents-page'
  )
);

export const TutorPage = lazy(() =>
  import(/* webpackChunkName: "Tutor" */ '../pages/tutor/tutor')
);

export const UnexpectedErrorPage = lazy(() =>
  import(
    /* webpackChunkName: "UnexpectedError" */ '../pages/error/unexpected-error-page'
  )
);

export const SecretaryRequestHistoryPage = lazy(() =>
  import(
    /* webpackChunkName: "SecretaryRequestHistory" */ '../pages/secretary-request-history/secretary-request-history-page/secretary-request-history-page'
  )
);

export const RequestDetailsPage = lazy(() =>
  import(
    /* webpackChunkName: "Request Details" */ '../pages/request-details/request-details-page'
  )
);

export const StudentCardPage = lazy(() =>
  import(
    /* webpackChunkName: "Stundent Card" */ '../pages/student-card/student-card-page'
  )
);

export const UpdateDataPage = lazy(() =>
  import(
    /* webpackChunkName: "Secretary Registration Updated" */ '../pages/update-data/update-data-page'
  )
);

export const TermsAndContractsPage = lazy(() =>
  import(
    /* webpackChunkName: "Secretary terms and contracts" */ '../pages/terms-and-contracts/terms-and-contracts-page'
  )
);

export const TermsAndContractsDetailsPage = lazy(() =>
  import(
    /* webpackChunkName: "Secretary terms and contracts details" */ '../pages/terms-and-contracts-details/terms-and-contracts-details-page'
  )
);

export const AcademicTranscriptPage = lazy(() =>
  import(
    /* webpackChunkName: "Secretary Academic Transcript" */ '../pages/academic-transcript/academic-transcript-page'
  )
);

/* INTERNSHIP */
export const MandatoryInternshipPage = lazy(() =>
  import(
    /* webpackChunkName: "Mandatory Internship" */ '../pages/internship/mandatory-internship-page'
  )
);
export const MandatoryInternshipCompanyInputPage = lazy(() =>
  import(
    /* webpackChunkName: "Mandatory Internship Company Input" */ '../pages/internship/mandatory-internship-company-input-page'
  )
);
export const MandatoryInternshipConfirmCompanyPage = lazy(() =>
  import(
    /* webpackChunkName: "Mandatory Internship Confirm Company" */ '../pages/internship/mandatory-internship-confirm-company-page'
  )
);
export const MandatoryInternshipInfoPage = lazy(() =>
  import(
    /* webpackChunkName: "Mandatory Internship Info" */ '../pages/internship/mandatory-internship-info-page'
  )
);
export const MandatoryInternshipGuidePage = lazy(() =>
  import(
    /* webpackChunkName: "Mandatory Internship Guide" */ '../pages/internship/mandatory-internship-guide-page'
  )
);
export const MandatoryInternshipSubjectPresentationPage = lazy(() =>
  import(
    /* webpackChunkName: "Mandatory Internship Subject Presentation" */ '../pages/internship/mandatory-internship-subject-presentation-page'
  )
);
export const ProfilePage = lazy(() =>
  import(
    /* webpackChunkName: "Mandatory Internship Subject Presentation" */ '../modules/identity/pages/profile/profile.presentation'
  )
);
export const CommunicationPreferencesPage = lazy(() =>
  import(
    /* webpackChunkName: "Mandatory Internship Subject Presentation" */ '../modules/communication/pages/communication-preferences'
  )
);

export const SecurityPage = lazy(() =>
  import(
    /* webpackChunkName: "Mandatory Internship Subject Presentation" */ '../modules/identity/pages/security/'
  )
);

export const CourseInformationPage = lazy(() =>
  import(
    /* webpackChunkName: "Course information page" */ '../pages/course-information/course-information.presentation'
  )
);
