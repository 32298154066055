import { createRef, useEffect, useState, useCallback, RefObject } from 'react';
import { throttle } from '../../../..';

interface IVisibilityElement {
  (offset?: number, throttleMilliseconds?: number): [
    boolean,
    RefObject<HTMLDivElement>
  ];
}

const useVisibilityElement: IVisibilityElement = (
  offset = 0,
  throttleMilliseconds = 100
) => {
  const [isVisible, setIsVisible] = useState(false);
  const currentElement = createRef<HTMLDivElement>();

  const onScroll = useCallback(
    () =>
      throttle(() => {
        if (!currentElement.current) {
          setIsVisible(false);
          return;
        }
        const top = currentElement.current.getBoundingClientRect().top;
        setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
      }, throttleMilliseconds)(),
    [currentElement, offset, throttleMilliseconds]
  );

  useEffect(() => {
    if (currentElement.current) {
      onScroll();
      window.addEventListener('scroll', onScroll);
    }
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll, currentElement]);

  return [isVisible, currentElement];
};

export default useVisibilityElement;
