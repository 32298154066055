const boleto = {
  read(boleto, _ref) {
    let {
      readField
    } = _ref;
    const paymentMethod = readField('paymentMethod');
    if (paymentMethod !== 'BOLETO') {
      return null;
    }
    return boleto;
  }
};
const status = {
  read(status, _ref2) {
    let {
      readField
    } = _ref2;
    const overdueDays = readField('overdueDays') || 0;
    if (status === 'OPEN' && overdueDays > 0) {
      return 'OVERDUE';
    }
    return status;
  }
};
export default {
  fields: {
    status,
    boleto
  }
};