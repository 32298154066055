import styled from '@emotion/styled';

export const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const FlexGap = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const TabWrapper = styled('div')<{ fontFamily?: string }>(
  ({
    theme: {
      colors: { kindle },
    },
    fontFamily,
  }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > [role='tab'] {
    width: 100%;
    min-width: auto;
    border: 1px solid ${kindle.border.secondary};
    background: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    height: 100%;
    ${fontFamily && `font-family: ${fontFamily}`};

    &[aria-selected='true'] {
      border-width: 2px;
    }

    &:focus, &:active {
      outline: solid 2px ${kindle.primary};
      outline-offset: -3px;
      border-width: 2px;
    }

    &:not([aria-selected='true']):hover,
    &:not([aria-selected='true']).hover:hover {
      border-color: ${kindle.border.secondary};
    }

    &:hover,
    &.hover:hover {
      background-color: ${kindle.secondary};
    }
  }
`
);

export const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
`;
