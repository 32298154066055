import styled from '@emotion/styled';
import { mediaQuery } from '@ampli/ui';

export const LayoutWrapper = styled.div`
  > div {
    padding-top: 0;
    padding-bottom: 4rem;
  }
`;

export const LearningUnitCardWrapper = styled.div`
  margin-bottom: 40px;

  ${mediaQuery('desktop')} {
    margin-bottom: 56px;
  }
`;

export const IconWrapper = styled.div`
  ${mediaQuery('desktop')} {
    right: 64px;
  }
  padding: 12px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.button.focus.primary};
    svg > path {
      fill: ${({ theme }) => theme.colors.button.darkPrimary};
    }
  }
`;
