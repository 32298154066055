import React from 'react';

import { px2grid, Container } from '@ampli/ui';
import { node } from 'prop-types';

import * as Styled from './exam-layout-mobile.styles';

import SimpleLayout from '../../simple-layout';

const ExamLayoutMobile = ({ talkTutorFrame, children, header, footer }) => {
  return (
    <Styled.FlexColumn data-testid="exam-layout-mobile">
      <SimpleLayout>
        {header}
        <Container as="section" padding={['xx-small', 'small', px2grid(122)]}>
          {children}
        </Container>
        <Styled.Footer>{footer}</Styled.Footer>
        {talkTutorFrame}
      </SimpleLayout>
    </Styled.FlexColumn>
  );
};

ExamLayoutMobile.propTypes = {
  talkTutorFrame: node,
  header: node.isRequired,
  children: node.isRequired,
  footer: node.isRequired,
};

export default ExamLayoutMobile;
