import React from 'react';
import { IIconProps } from './types';
import * as Styles from './modals.styles';

const Icon: React.FC<IIconProps> = ({ background, children }: IIconProps) => {
  return (
    <Styles.IconBackground background={background}>
      {children}
    </Styles.IconBackground>
  );
};

export default Icon;
