import React from 'react';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '@ampli/hooks';
import { Header, NavBar } from '@sofia/ui';
import { Separator } from '@ampli/ui';
import Skeleton from '../../../../skeleton/skeleton';
import { LayoutContent, LayoutWrapper } from '../../../../../../../components';
import * as Styled from './page-loader-layout.styles';

interface PageLoaderLayoutProps {
  breadcrumbItems: number;
  children: React.ReactNode;
}

const PageLoaderLayout = ({
  breadcrumbItems,
  children,
  ...props
}: PageLoaderLayoutProps): React.ReactElement => {
  const theme = useTheme();
  const isDesktop = useBreakpoint() === 'desktop';

  return (
    <LayoutWrapper data-testid="page-loader-layout" {...props}>
      <LayoutContent padding={['none', 'small']}>
        <Styled.AppBar>
          {!isDesktop ? (
            <Skeleton
              width="36px"
              height="36px"
              borderRadius="8px"
              data-testid="page-loader-layout-logo-mobile-skeleton"
            />
          ) : null}

          <NavBar
            color={theme.colors.navBar.color}
            active={theme.colors.navBar.active}
          >
            <Skeleton
              width="100px"
              height="53px"
              borderRadius="8px"
              margin="8px 68px 8px 0"
            />
            <Skeleton width="75px" height="21px" />
            <Skeleton width="94px" height="21px" />
            <Skeleton width="115px" height="21px" />
            <Skeleton width="69px" height="21px" />
          </NavBar>
          <Styled.Flex>
            {isDesktop ? (
              <Skeleton
                width="100px"
                height="21px"
                data-testid="page-loader-layout-username-skeleton"
              />
            ) : null}
            <Separator orientation="vertical" transparent margin="x-small" />
            <Skeleton width="40px" height="40px" borderRadius="50%" />
          </Styled.Flex>
        </Styled.AppBar>
      </LayoutContent>

      <Styled.BreadcrumbsWrapper>
        <Header
          titleColor={theme.colors.text.secondary}
          descriptionColor={theme.colors.text.light}
          background={theme.colors.banner.primary}
          breadCrumbs={
            <LayoutContent padding={['small']}>
              <Styled.Flex>
                {isDesktop ? (
                  Array.from({ length: breadcrumbItems }, (_, index) => (
                    <Skeleton
                      height="21px"
                      width="120px"
                      margin="0 20px 0 0"
                      key={index}
                      data-testid="page-loader-layout-breadcrumb-item-skeleton"
                    />
                  ))
                ) : (
                  <Skeleton
                    height="21px"
                    width="160px"
                    data-testid="page-loader-layout-breadcrumb-item-mobile-skeleton"
                  />
                )}
              </Styled.Flex>
            </LayoutContent>
          }
        />
      </Styled.BreadcrumbsWrapper>

      <LayoutContent padding={['small']}>{children}</LayoutContent>
    </LayoutWrapper>
  );
};

PageLoaderLayout.defaultProps = {
  breadcrumbItems: 3,
};

export default PageLoaderLayout;
