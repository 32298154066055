import { ReactNode } from 'react';

type GetButtonEvaluationLabels = (
  hasErrors: boolean,
  inputDisabled: boolean,
  buttonDisabled: boolean,
  checkIcon: ReactNode
) => {
  label: string | ReactNode;
  'aria-label': string;
};

const getButtonEvaluationLabels: GetButtonEvaluationLabels = (
  hasErrors,
  inputDisabled,
  buttonDisabled,
  checkIcon
) => {
  if (hasErrors) {
    return {
      label: 'Erro',
      'aria-label': 'Erro no envio do comentário, tente novamente',
    };
  }

  if (!inputDisabled) {
    return {
      label: 'Enviar',
      'aria-label': 'Enviar o comentário!',
    };
  }

  return buttonDisabled
    ? {
        label: 'Enviado',
        'aria-label': 'Comentário enviado!',
      }
    : {
        label: checkIcon,
        'aria-label': '',
      };
};

export default getButtonEvaluationLabels;
