import * as React from 'react';
import { getColor } from '@ampli/ui';

function SvgMobileAlertImage(props) {
  const brandLighter = getColor('brand-lighter');

  return (
    <svg width={142} height={120} fill="none" {...props}>
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.18 24.347C26.57 4.013 57.5 7.532 82.42 18.987c29.43 13.53 63.938 34.302 57.885 66.1-5.827 30.61-45.955 33.289-77.103 34.855-20.006 1.005-36.182-11.312-46.168-28.66C4.62 69.711-8.518 42.807 8.18 24.346z"
        fill={brandLighter}
      />
      <path
        d="M8.196 17.23h37.197c1.795 0 3.536.617 4.938 1.75a8.009 8.009 0 012.775 4.488l18.795 84.307c.205.92.209 1.874.01 2.795a6.5 6.5 0 01-1.156 2.538 6.407 6.407 0 01-2.108 1.806 6.338 6.338 0 01-2.666.738l-38.238 1.905a8.383 8.383 0 01-5.667-1.842 8.558 8.558 0 01-3.057-5.166L8.357 50.942l-.793.23-2.04-11.298.817-.206-2.976-16.639a4.978 4.978 0 01.086-2.144c.185-.7.52-1.35.981-1.904a4.876 4.876 0 011.688-1.3 4.823 4.823 0 012.076-.451z"
        fill="#3F3D56"
      />
      <path
        d="M40.496 19.047h4.342c1.247 0 2.456.431 3.427 1.222a5.535 5.535 0 011.912 3.126l18.04 83.62a3.46 3.46 0 01-.603 2.789 3.357 3.357 0 01-2.484 1.358l-38.115 2.526a4.349 4.349 0 01-2.987-.931 4.445 4.445 0 01-1.615-2.708L7.019 23.619a3.927 3.927 0 01.833-3.179c.36-.436.811-.787 1.32-1.028a3.805 3.805 0 011.627-.365h4.119c.399 0 .786.133 1.103.378.316.245.544.59.647.979.092.343.293.647.572.863.28.217.622.334.974.334h19.554c.69 0 1.353-.263 1.859-.736a2.78 2.78 0 00.869-1.818z"
        fill="#FFEDBF"
      />
      <path
        opacity={0.1}
        d="M31.23 88.124c0 2.476.972 4.85 2.702 6.6a9.177 9.177 0 006.526 2.733 9.177 9.177 0 006.525-2.733 9.387 9.387 0 002.697-6.949 9.38 9.38 0 00-2.882-6.433 9.172 9.172 0 00-6.513-2.549 9.18 9.18 0 00-6.414 2.794 9.387 9.387 0 00-2.642 6.537z"
        fill="#000"
      />
      <path
        opacity={0.1}
        d="M31.23 91.152c0 .117.001.233.006.349a9.284 9.284 0 004.293 4.4 9.137 9.137 0 006.072.723 9.216 9.216 0 005.186-3.274 9.4 9.4 0 002.018-6.182 9.284 9.284 0 00-4.293-4.4 9.137 9.137 0 00-6.072-.723 9.217 9.217 0 00-5.185 3.274 9.4 9.4 0 00-2.026 5.833z"
        fill="#000"
      />
      <path
        d="M39.161 95.613C44.58 97.08 50.148 93.829 51.6 88.35c1.452-5.479-1.763-11.11-7.18-12.578C39 74.305 33.431 77.556 31.98 83.035c-1.452 5.478 1.763 11.11 7.18 12.578zM36.57 71.853c-1.396-.353-2.68-1.169-3.698-2.348-1.017-1.178-1.725-2.67-2.037-4.294l-8.75-45.823a13.214 13.214 0 01-.063-4.601 12.23 12.23 0 011.507-4.223c.746-1.268 1.71-2.34 2.831-3.152a8.78 8.78 0 013.69-1.565 8.439 8.439 0 013.944.279c1.29.405 2.499 1.112 3.553 2.077a11.37 11.37 0 012.578 3.534 12.928 12.928 0 011.182 4.412l4.013 46.622a9.805 9.805 0 01-.453 3.918c-.412 1.242-1.07 2.35-1.92 3.232-.852.884-1.872 1.518-2.98 1.854a6.33 6.33 0 01-3.398.078z"
        fill="#444"
      />
      <path
        d="M107.333 27.032c3.794 0 6.87-3.11 6.87-6.947s-3.076-6.948-6.87-6.948-6.87 3.11-6.87 6.948c0 3.837 3.076 6.947 6.87 6.947z"
        fill="#2F2E41"
      />
      <path
        d="M114.744 107.145l1.307 5.663h-3.174l-1.306-6.418 3.173.755zM99.436 107.145v5.286l-3.173.377v-5.663h3.173zM115.677 47.11s-.373 8.117-.746 8.684c-.374.566-3.734 8.684-3.734 8.684l-1.68-5.286s2.614-4.342 2.24-6.796c-.373-2.455-.138-5.475-.138-5.475l4.058.188zM105.037 27.474c2.887 0 5.227-2.366 5.227-5.285 0-2.92-2.34-5.286-5.227-5.286-2.887 0-5.227 2.367-5.227 5.286 0 2.919 2.34 5.285 5.227 5.285z"
        fill="#9F616A"
      />
      <path
        d="M109.33 22.755s-.186 7.551 1.12 8.684c1.307 1.133-7.467.189-7.467.189s1.867-5.286.374-6.419c-1.494-1.132 5.973-2.454 5.973-2.454z"
        fill="#9F616A"
      />
      <path
        d="M102.423 31.251s1.12-2.077 2.614-1.888c1.493.189 6.347.567 6.533.944.187.378.747 1.7 1.121 2.077.373.377 3.173.944 3.36 3.21.187 2.265-6.721 16.046-6.721 16.046s.934 3.21.747 3.965c-.187.755.933 3.02.747 3.965-.187.944 2.8 6.607 1.307 13.97v11.894s5.04 20.578 3.546 22.089c-1.493 1.51-6.533.566-7.467 0-.933-.567-4.854-29.074-4.854-29.074l-1.866-9.629-.187 21.145s.373 17.746-.933 18.313c-1.307.566-6.348.755-6.721-.378-.291-.883-1.718-24.39-2.332-34.693A38.92 38.92 0 0192.14 62.6c.777-3.564 1.905-7.75 3.19-9.45 2.427-3.21 3.92-13.404 3.92-13.404l-3.173-5.853s1.867-1.888 2.987-1.888 3.36-.755 3.36-.755z"
        fill="#2F2E41"
      />
      <path
        d="M114.931 34.272l.984.677s.696 12.16.136 12.916c-.56.755-4.454.386-4.747-.279-.293-.665 3.627-13.314 3.627-13.314z"
        fill="#2F2E41"
      />
      <path
        d="M98.283 45.328c.547 3.834 1.025 7.246-1.769 10.334a52.605 52.605 0 01-9.433 8.264c-.343.918-5.374 7.66-5.63 4.97-.256-2.69 1.482-4.797 3.953-7.071 2.47-2.274 5.925-7.208 7.875-9.916 1.95-2.707 1.147-4.395 1.08-6.657-.068-2.261 3.157.103 3.924.076z"
        fill="#9F616A"
      />
      <path
        d="M96.263 109.373s-.187 2.114-.747 2.68c-.56.567-2.054 1.322-1.867 3.398.187 2.077 1.4 2.171 1.4 2.171l-.093-.472.56.189.093.283h5.227s.094-1.982-.093-2.926c-.187-.944-.187-5.474-.747-5.286-.56.189-2.053.378-2.427 1.133-.373.755-1.493.491-1.306-1.17zM115.304 109.373s.187 2.114.747 2.68c.56.567 2.053 1.322 1.867 3.398-.187 2.077-1.4 2.171-1.4 2.171l.093-.472-.56.189-.093.283h-5.228s-.093-1.982.094-2.926c.187-.944.187-5.474.747-5.286.56.189 2.053.378 2.426 1.133.374.755 1.494.491 1.307-1.17zM98.69 33.328l-2.614.566s0 4.154-.747 5.853c-.746 1.699-2.053 5.663-1.493 5.852.56.19 5.787 2.832 6.16 1.322.374-1.51 1.307-13.593-1.306-13.593zM114.775 17.884c3.794 0 6.87-3.11 6.87-6.947s-3.076-6.948-6.87-6.948-6.87 3.11-6.87 6.948c0 3.837 3.076 6.947 6.87 6.947z"
        fill="#2F2E41"
      />
      <path
        d="M120.701 16.689a6.799 6.799 0 01-6.236.947 6.862 6.862 0 01-2.739-1.76 6.96 6.96 0 01-1.634-2.835 6.99 6.99 0 00.918 2.695 6.912 6.912 0 001.911 2.095 6.795 6.795 0 005.401 1.141 6.837 6.837 0 002.581-1.146 6.927 6.927 0 001.908-2.097 7.028 7.028 0 00.682-5.537 7.017 7.017 0 01-.515 3.635 6.937 6.937 0 01-2.277 2.862z"
        fill="#2F2E41"
      />
      <path
        d="M109.165 26.336c2.782 0 5.038-2.28 5.038-5.094 0-2.814-2.256-5.094-5.038-5.094-2.783 0-5.038 2.28-5.038 5.094 0 2.814 2.255 5.094 5.038 5.094z"
        fill="#2F2E41"
      />
      <path
        d="M104.928 22.863c2.466 0 4.466-1.581 4.466-3.531 0-1.95-2-3.532-4.466-3.532s-4.465 1.581-4.465 3.532c0 1.95 1.999 3.531 4.465 3.531z"
        fill="#2F2E41"
      />
      <path
        d="M103.737 24.467c.468 0 .848-.679.848-1.516 0-.838-.38-1.517-.848-1.517-.468 0-.847.68-.847 1.517 0 .837.379 1.516.847 1.516z"
        fill="#A0616A"
      />
    </svg>
  );
}

export default SvgMobileAlertImage;
