import React from 'react';

import {
  CardButton,
  Typography,
  useModal,
  SvgBookOpen,
  SvgArrowRight,
  SvgCalendarAlt,
} from '@sofia/ui';

import { useHistory } from 'react-router-dom';
import { routePath } from '../../../routes';
import { useGetState } from '../../../state';
import { object, func } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';

import { PopUp } from '../../../modules/shared';
import SyllabusInfoGraduationDateContent from './syllabus-info-graduation-date-content';
import * as Styled from './syllabus-info.styles';

const SyllabusInfo = ({ onInauguralSubjectClick, courseEndDate }) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const isDesktop = breakpoint === 'desktop';
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const graduationDateModal = useModal();

  return (
    <>
      <Styled.InfoCardsButton>
        <div>
          <CardButton
            onClick={onInauguralSubjectClick}
            icon={<SvgBookOpen fill={theme.colors.text.primary} />}
            iconEnd={<SvgArrowRight fill={theme.colors.text.primary} />}
            borderColor={theme.colors.card.border.primary}
            id="inaugural-class"
            data-testid="inaugural-class"
            margin="0 0 16px 0"
            title={
              <Typography
                margin={0}
                color={theme.colors.text.black}
                size={isDesktop ? 20 : 16}
                id="inaugural-class-title"
                data-testid="inaugural-class-title"
              >
                Inicie sua jornada
              </Typography>
            }
          />
        </div>
        <div>
          <CardButton
            onClick={() => {
              history.push(routePath.academicCalendar, {
                courseType: selectedCourseEnrollmentType,
                courseVersionId: selectedCourseVersionId,
              });
            }}
            icon={<SvgCalendarAlt fill={theme.colors.text.primary} />}
            borderColor={theme.colors.card.border.primary}
            id="calendar-date"
            data-testid="calendar-date"
            title={
              <Typography
                margin={0}
                id="calendar-date-title"
                data-testid="calendar-date-title"
                color={theme.colors.text.black}
                size={isDesktop ? 20 : 16}
              >
                Calendário
              </Typography>
            }
          />
        </div>
      </Styled.InfoCardsButton>
      <PopUp
        popUpState={graduationDateModal}
        ariaLabel="Modal de previsão de formatura"
        headerText="Previsão de formatura"
        isDesktop={isDesktop}
      >
        <SyllabusInfoGraduationDateContent courseEndDate={courseEndDate} />
      </PopUp>
    </>
  );
};

SyllabusInfo.propTypes = {
  courseEndDate: object,
  onInauguralSubjectClick: func.isRequired,
};

export default SyllabusInfo;
