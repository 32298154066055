import * as Yup from 'yup';

const REQUIRED_MSG_DOCUMENT_FILE = 'Você não selecionou um documento.';
const FILE_FORMAT_NOT_SUPPORTED = 'Formato de arquivo não aceito.';
const SIZE_DOCUMENT = 'size document';
const REQUIRED_MSG = 'Escreva sua dúvida!';

const FILE_SIZE = (size: string) =>
  `Tamanho do arquivo maior que ${size} megabyte permitido.`;

export const getFrameFormSchema: (validationSchema?: any) => any = (
  validationSchema
) =>
  Yup.object({
    description: Yup.string().required(REQUIRED_MSG),
    attachment: Yup.lazy(() => {
      if (validationSchema?.requestDocumentAgeMinor) {
        return Yup.mixed()
          .required(REQUIRED_MSG_DOCUMENT_FILE)
          .test(
            'type',
            FILE_FORMAT_NOT_SUPPORTED,
            (value) =>
              value && validationSchema?.validDocuments.includes(value.type)
          )
          .test(SIZE_DOCUMENT, FILE_SIZE(validationSchema?.size), (value) => {
            const convertMbtoBytes = validationSchema?.size * 1000 * 1000;
            return value && value.size < convertMbtoBytes;
          });
      }
      return Yup.mixed().notRequired();
    }),
  });
