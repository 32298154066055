import styled from '@emotion/styled';
import {
  convertPXToVH,
  convertPXToVW,
  mediaQuery,
  convertPXToREM,
} from '@sofia/ui';

const tabletMediaMax = mediaQuery('tablet-max');

export const FlexWrapper = styled.div`
  display: flex;
`;

export const TitleFlexWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`;

export const IconWrapper = styled.div`
  padding: 12px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 8px;
  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
    svg > path {
      fill: white;
    }
  }
`;

export const Wrapper = styled.div`
  padding: ${convertPXToREM(32)};

  ${tabletMediaMax} {
    padding: ${convertPXToREM(24)};
  }
`;

export const CommunicationWrapper = styled.div`
  margin-top: ${convertPXToVH(80)};
  width: 100%;

  ${tabletMediaMax} {
    margin-top: ${convertPXToVH(40)};
  }

  & > div:hover {
    border-color: ${({ theme }) => theme.colors.communication.border.primary};
  }
`;

export const LinkButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
`;

export const HomeDialog = styled.div`
  background: rgba(33, 37, 41, 0.8);
  backdrop-filter: blur(4px);
  z-index: 1001;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;

export const HomeDialogWrapper = styled.div`
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  top: 154px;
`;

export const DialogHomeCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 72px 32px 16px 32px;
  position: relative;
  max-height: 90%;
  border-radius: 8px;
  width: ${convertPXToVW(932)};
  cursor: pointer;
  overflow-x: hidden;

  @media (max-width: 932px) {
    width: auto;
  }
`;

export const HomeDialogText = styled.div`
  text-align: center;

  @media (max-width: 932px) {
    margin: 0 42px;
  }
`;

export const Container = styled.div`
  margin-top: ${convertPXToVH(50)};
`;

export const SubjectsListRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 16px;
  margin-top: 32px;
  > div {
    width: 100%;
  }
  ${mediaQuery('desktop')} {
    margin-top: 56px;
    width: 100%;
    > div {
      width: calc(50% - 12px);
    }
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
`;

export const WrapperSyllabusEmptyState = styled.div`
  margin-top: ${convertPXToVH(56)};
  ${mediaQuery('mobile')} {
    margin-top: ${convertPXToVH(16)};
  }
`;
