import React from 'react';
import { GET_SUBJECT_ENROLLMENT_FOR_SUBJECT_PAGE } from '@ampli/services';
import { DateTime, uuid } from '@ampli/utils';
import { CreateAttendanceHook } from '../types';
import { useCreateManyAttendances } from '../../../services/attendance';
import { logMessage } from '../../../../shared';
import { ELogType } from '../../../../shared/utils/log-message';
import { reflectAttendance } from '../../../shared';
import { ApolloCache, ApolloError, FetchResult } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { CreateManyAttendancesMutation } from '../../../services/attendance/hooks.gql';

export const useCreateAttendance: CreateAttendanceHook = ({
  attendance,
  isSectionNavigationVisible,
  isVideoType,
  isVideoViewCompleted,
  isLearningObjectCompleted,
  isLastLearningObjectToComplete,
  attendanceErrorMsg,
  isSubjectEnrollmentEnabled,
}) => {
  const [
    createAttendances,
    { loading: createAttendancesLoading, called: createAttendancesCalled },
  ] = useCreateManyAttendances();

  const handleError = React.useCallback(
    (error: ApolloError | GraphQLError): void => {
      let msgTimeout: NodeJS.Timeout | null = null;
      logMessage(ELogType.error, error);
      attendanceErrorMsg.current = true;
      msgTimeout = setTimeout(() => {
        attendanceErrorMsg.current = false;
      }, 5000);

      return clearTimeout(msgTimeout as NodeJS.Timeout);
    },
    [attendanceErrorMsg]
  );

  const attendancePayload = React.useCallback(
    (refetchCount = 0) => {
      const data = [
        {
          ...attendance,
          id: uuid(),
          completionTime: DateTime.local().toISO(),
        },
      ];
      return {
        variables: {
          data: data,
        },
        onError: (error: ApolloError | GraphQLError) => {
          handleError(error);
          if (refetchCount < 2) {
            setTimeout(
              () => createAttendances(attendancePayload(refetchCount + 1)),
              (refetchCount + 1) * 1000
            );
          }
        },
        update: (
          cache: ApolloCache<CreateManyAttendancesMutation>,
          result: FetchResult<CreateManyAttendancesMutation>
        ) => {
          if (result?.errors) {
            logMessage(ELogType.error, result?.errors);
          } else if (result?.data?.data && !result?.errors) {
            data.forEach(
              ({ sectionId, subjectEnrollmentId, learningObjectId }) => {
                reflectAttendance(
                  { sectionId, subjectEnrollmentId, learningObjectId },
                  { cache }
                );
              }
            );
          }
        },
        ...(isLastLearningObjectToComplete
          ? {
              awaitRefetchQueries: true,
              refetchQueries: [
                {
                  query: GET_SUBJECT_ENROLLMENT_FOR_SUBJECT_PAGE,
                  variables: {
                    subjectEnrollmentId: attendance?.subjectEnrollmentId,
                  },
                },
              ],
            }
          : {}),
      };
    },
    [attendance, createAttendances, handleError, isLastLearningObjectToComplete]
  );

  React.useEffect(() => {
    const isLearningObjectViewed =
      (isVideoType && isVideoViewCompleted) ||
      (!isVideoType && isSectionNavigationVisible);
    if (
      isLearningObjectViewed &&
      isLearningObjectCompleted === false &&
      !createAttendancesLoading &&
      !createAttendancesCalled &&
      isSubjectEnrollmentEnabled
    ) {
      logMessage(ELogType.log, 'chamada de presença');
      createAttendances(attendancePayload());
    }
  }, [
    attendancePayload,
    createAttendances,
    createAttendancesCalled,
    createAttendancesLoading,
    isLearningObjectCompleted,
    isSectionNavigationVisible,
    isSubjectEnrollmentEnabled,
    isVideoType,
    isVideoViewCompleted,
  ]);
};

export default useCreateAttendance;
