import React from 'react';
import { useTheme } from '@emotion/react';
import useBreakpoint from '../../../hooks/use-breakpoint';
import { NewFeatureProps } from '../../../../communication/types/onboarding';
import TourStep from '../tour-step';
import { onboardingIds } from '../../../utils/communication-constants';

type NewFeatureControlsProps = {
  appendCommunicationOnTrack: () => void;
};

export const useNewFeatureControls = ({
  appendCommunicationOnTrack,
}: NewFeatureControlsProps): NewFeatureProps => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();

  const setNewProfileShown = () => {
    appendCommunicationOnTrack();
    localStorage.setItem('showedNewProfileCommunication', 'true');
  };

  const NewProfileTooltip = {
    id: onboardingIds.newFeature.id.newProfile,
    steps: [
      {
        target: isDesktop
          ? onboardingIds.welcome.target.profileDesktop
          : onboardingIds.welcome.target.profileMobile,
        hideFooter: true,
        content: (
          <TourStep
            heading="Temos novidade!"
            headingStyles={{
              margin: '8px 0',
              color: theme.colors.text.lightBlack,
            }}
            title="Um lugar pensado em você"
            titleStyles={{ margin: '8px 0', fontSize: '20px' }}
            description={
              isDesktop ? (
                <>
                  No novo menu <strong>Perfil</strong>, você pode realizar
                  configurações e personalizar a plataforma de acordo com as
                  suas necessidades do dia a dia.
                </>
              ) : (
                <>
                  No novo menu <strong>Perfil</strong> você terá acesso às suas
                  notificações, central de ajuda, além de realizar configurações
                  e personalizar a plataforma da sua maneira.
                </>
              )
            }
          />
        ),
        styles: {
          tooltip: {
            padding: 24,
          },
          tooltipContent: {
            paddingTop: 0,
          },
        },
      },
    ],
    onEndTour: setNewProfileShown,
    onCloseTour: setNewProfileShown,
    hideTitle: true,
    hideCloseButton: false,
  };

  return { Tour: NewProfileTooltip };
};
