import { GET_COURSE_ENROLLMENT } from '@ampli/services';

const isFreetrialGuard = async (to, from, next) => {
  const privateClient = to.meta?.clients?.privateClient;
  const courseEnrollmentId = to.meta?.state?.selectedCourseEnrollmentId;

  try {
    const response = await privateClient.query({
      query: GET_COURSE_ENROLLMENT,
      variables: {
        courseEnrollmentId,
      },
    });

    const freeTrialRole = response?.data?.data?.roles.includes(
      'FREE_TRIAL_CANCELLATION'
    );
    freeTrialRole ? next() : next.redirect('/');
  } catch (error) {
    console.warn(error);
    next.redirect('/');
  }
};

export default isFreetrialGuard;
