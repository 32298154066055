import React from 'react';

import {
  Typography,
  useModal,
  SvgClose,
  SvgCheck,
  SvgCopy,
  SvgFileSlash,
} from '@sofia/ui';

import { WhatsappOutline, Phone, MapPin } from '@ampli/icon';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';

import {
  descriptionByPolo,
  poloContactInfos,
} from '../../../wisdom/wisdom.constants';

import { AffiliateBrands, PopUp } from '../..';
import { useGetAffiliate } from '../../../wisdom/services/hooks';
import { SvgComment } from '../../../wisdom/assets/comment.icon';
import { Skeleton } from '../../../../components';
import { Address } from '../../../wisdom/types';

import * as Styled from './service-channels-content.styles';

interface ServiceChannelsModalProps {
  affiliateId?: string;
  type?: 'modal' | 'popover';
  modal?: typeof useModal;
}

interface GetAffiliateType {
  loading: boolean;
  data: {
    brand: AffiliateBrands;
    type: 'POLO_PARCEIRO' | 'POLO';
    address: Address;
  };
}
interface EmptyStateProps {
  type: ServiceChannelsModalProps['type'];
}

const EmptyState: React.FC<EmptyStateProps> = ({ type }: EmptyStateProps) => {
  const theme = useTheme();

  return (
    <Styled.Flex
      direction="column"
      align="center"
      justify="center"
      gap={8}
      width="100%"
      data-testid="empty-state"
    >
      <SvgFileSlash />
      <Typography size={16} color={theme.colors.text.black}>
        <strong>Sem dados do polo no momento</strong>
      </Typography>
      <Typography
        size={16}
        color={theme.colors.text.lightBlack}
        textAlign={type === 'modal' ? 'center' : 'left'}
      >
        Seu polo ainda não forneceu dados para contato,
        {type === 'modal' ? <br /> : null}
        mas em breve essas informações serão disponibilizadas
      </Typography>
    </Styled.Flex>
  );
};

const ServiceChannelsModal: React.FC<ServiceChannelsModalProps> = ({
  affiliateId,
  type,
  modal,
}: ServiceChannelsModalProps) => {
  const [copyIcon, setCopyIcon] = React.useState<'copy' | 'check'>('copy');

  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const { data: affiliate, loading }: GetAffiliateType =
    useGetAffiliate(affiliateId);

  const Wrapper = type === 'modal' ? PopUp : Styled.Popover;

  const hasAddress = !!affiliate?.address;

  const isEmptyState = !affiliate || !hasAddress;

  const isAmpliPole = affiliate?.brand === 'BRAND_AMPLI';

  const isOwnPole = affiliate?.type === 'POLO';

  const isPartnerPole = affiliate?.type === 'POLO_PARCEIRO';

  const poloContact = poloContactInfos(
    affiliate?.brand,
    affiliate?.type,
    affiliate?.address
  );

  const onCopyToClipboard = async (value: string) => {
    setCopyIcon('check');

    try {
      await navigator.clipboard.writeText(value);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }

    return setTimeout(() => setCopyIcon('copy'), 1000);
  };

  const footerPadding = type === 'modal' ? '0px 0px 24px' : '0px';

  return (
    <Wrapper
      popUpState={modal}
      showFooter={false}
      isDesktop={isDesktop}
      buttonHeight={buttonRef.current?.clientHeight}
      shouldCloseOnEsc={false}
      shouldCloseOnBackdrop={!isDesktop}
      shouldCloseOnClickOutside={!isDesktop}
      data-testid="service-channels-modal"
    >
      <Styled.Flex
        gap={24}
        direction="column"
        align="flex-start"
        justify="flex-start"
      >
        <Styled.Flex
          gap={8}
          direction="row"
          justify="space-between"
          align="center"
        >
          <Styled.Flex
            gap={8}
            direction="row"
            justify="space-between"
            align="center"
            width="fit-content"
          >
            <SvgComment fill={theme.colors.icon.black} />

            <Typography weight={700} color={theme.colors.text.black}>
              Canais de atendimento
            </Typography>
          </Styled.Flex>

          {type === 'modal' ? null : (
            <Styled.CloseButton type="button" onClick={() => modal.hide()}>
              <SvgClose />
            </Styled.CloseButton>
          )}
        </Styled.Flex>

        <Styled.Flex direction="column">
          {!isAmpliPole && isPartnerPole && hasAddress ? (
            <Typography size={24} weight="700" margin="0px 0px 16px">
              Você faz parte de um dos nossos polos parceiros
            </Typography>
          ) : null}

          <Typography>
            {!isAmpliPole && isPartnerPole && hasAddress
              ? descriptionByPolo.partnerPole
              : descriptionByPolo.othersPoles}
          </Typography>
        </Styled.Flex>

        <Styled.DivideLine />

        {loading ? (
          <Styled.Flex gap={24} direction="column" padding={footerPadding}>
            <Skeleton height={80} />
          </Styled.Flex>
        ) : isEmptyState ? (
          <Styled.Flex gap={24} direction="column" padding={footerPadding}>
            <EmptyState type={type} />
          </Styled.Flex>
        ) : null}

        {isAmpliPole || isOwnPole ? (
          <Styled.Flex gap={24} direction="column" padding={footerPadding}>
            <Styled.ButtonOutline
              type="button"
              onClick={() => window.open(poloContact?.whatsapp?.url)}
            >
              <Styled.Flex gap={8} align="center" justify="center">
                <WhatsappOutline
                  color={theme.colors.text.primary}
                  width="24px"
                  height="24px"
                />
                WhatsApp
              </Styled.Flex>
            </Styled.ButtonOutline>

            {poloContact?.phone?.url ? (
              <Styled.ButtonOutline
                type="button"
                onClick={() => window.open(poloContact?.phone?.url)}
              >
                <Styled.Flex gap={8} align="center" justify="center">
                  <Phone
                    color={theme.colors.text.primary}
                    width="24px"
                    height="24px"
                  />
                  {poloContact.phone?.name}
                </Styled.Flex>
              </Styled.ButtonOutline>
            ) : null}
          </Styled.Flex>
        ) : null}

        {!isAmpliPole && isPartnerPole && hasAddress ? (
          <Styled.Flex gap={24} direction="column" padding={footerPadding}>
            <Styled.Flex direction="row" gap={8}>
              <MapPin color={theme.colors.text.primary} width={16} />

              <Typography
                transform="uppercase"
                size={14}
                color={theme.colors.text.primary}
              >
                Endereço
              </Typography>
            </Styled.Flex>

            <Styled.Flex
              direction={type === 'modal' ? 'row' : 'column'}
              gap={24}
              width="100%"
            >
              <Typography weight={700} transform="capitalize">
                {poloContact?.address}
              </Typography>

              <Styled.CustomButtom
                type="button"
                padding="18px 40px"
                borderBoxColor={theme.colors.text.primary}
                color={theme.colors.text.primary}
                onClick={() => onCopyToClipboard(poloContact?.address)}
                isModal={type === 'modal'}
              >
                {copyIcon === 'copy' ? (
                  <SvgCopy color={theme.colors.text.primary} width={24} />
                ) : (
                  <SvgCheck fill={theme.colors.text.primary} width={24} />
                )}
                <Typography>Copiar</Typography>
              </Styled.CustomButtom>
            </Styled.Flex>
          </Styled.Flex>
        ) : null}
      </Styled.Flex>
    </Wrapper>
  );
};

export default ServiceChannelsModal;
