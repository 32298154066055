import { NoteType } from '../../../../../../../../notes/shared';
import { NoteDefaultType } from '../../types';

export const defaultNote = (noteProps: NoteDefaultType): NoteType => ({
  content: '',
  ...noteProps,
});

export const getLearningObjectTitle = (
  learningObjectId: string,
  learningObjects?: { id: string; title: string }[]
): string =>
  learningObjects?.find(
    (learningObject) => learningObject.id === learningObjectId
  )?.title || '';
