import { convertPXToREM, mediaQuery } from '@sofia/ui';
import styled from '@emotion/styled';

export const NotesButton = styled('button')(
  ({ theme }) => `
    svg {
      margin: 10px;
      height: 100%;
    }
			padding: 0;
    ${mediaQuery('desktop')} {
      margin-right: 24px;
      flex-direction: row;
      padding: 8px 16px;
      svg {
        margin: 0 10px 0 0;
      }
    }
    span {
      font-size: ${convertPXToREM(16)};
      font-weight: 400;
      line-height: 150%;
    }
    :hover, &[aria-expanded='true'] {
      background: ${theme.colors.background.secondary};
    }

    display: flex;
    align-items: center;
    flex-direction: column;
    background: transparent;
    border: solid 1px transparent;
    border-radius: 8px;
`
);
