import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const BgTheme = styled.div<{ themeColor: string }>`
  background: linear-gradient(
    180deg,
    ${({ themeColor }) => themeColor} 50%,
    white 50%
  );
  height: 100vh;
  display: flex;
`;

export const HeaderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #b1c2d5;
  border-radius: 50%;
  background: white;
  z-index: 2;
  width: 64px;
  height: 64px;
  ${mediaQuery('mobile')} {
    margin-top: -60px;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled(FlexCenter)`
  margin: 0 24px;
  ${mediaQuery('desktop')} {
    max-width: 592px;
    min-width: 592px;
  }
`;

export const Center = styled(FlexCenter)`
  margin: auto;
`;

export const Content = styled.div`
  margin: 0 24px;
  ${mediaQuery('desktop')} {
    max-width: 328px;
    min-width: 328px;
  }
`;

export const Card = styled(FlexCenter)`
  padding: 24px;
  border: solid 1px #b1c2d5;
  border-radius: 8px;
  background: white;

  margin-top: 32px;
  margin-bottom: 40px;
  ${mediaQuery('desktop')} {
    margin-top: 0px;
    margin-bottom: 32px;
  }
`;

export const NumberOutOfMax = styled.div<{
  firstColor?: string;
  firstSize?: number;
  secondSize?: number;
}>`
  span {
    font-weight: 700;
    :first-of-type {
      font-size: ${({ firstSize = 48 }) => convertPXToREM(firstSize)};
      color: ${({ firstColor, theme }) =>
        firstColor || theme.colors.text.black};
    }
    :last-of-type {
      font-size: ${({ secondSize = 24 }) => convertPXToREM(secondSize)};
      color: ${({ theme }) => theme.colors.text.lightBlack};
    }
  }
`;

export const ProgressCirclesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
