import React from 'react';

import TourModal from './modal';
import Joyride from 'react-joyride';

import { string, bool, func, node, object } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useModal } from '@sofia/ui';

import {
  toggleHighlightElements,
  addTourEventListener,
  checkRedirect,
} from './utils';

import { useBeforeStartModal } from '../../modules/shared/components/tours/welcome-tour/modals/before-start/before-start.mobile';
import { useTourControls } from './controls';
import { useBreakpoint } from '../../modules';
import { useTheme } from '@emotion/react';

import * as Styles from './modal.styles';

import './style.css';

const Tour = ({
  id,
  steps = [],
  resetOnOverlayClick = true,
  showIndicator = false,
  CustomIndicatorComponent = null,
  showModalBeforeStart = false,
  showModalAfterEnd = false,
  modalBeforeStart = {},
  modalAfterEnd = {},
  onStartTour = () => {},
  onEndTour = () => {},
  onCloseTour = () => {},
  onChangeStep = () => {},
  hideTitle = false,
  hideCloseButton,
}) => {
  steps = steps.map((step, index) => {
    return {
      waitForElement: true,
      focusElement: true,
      ...step,
      title: hideTitle ? null : `Etapa ${index + 1} de ${steps.length}`,
      disableBeacon: true,
    };
  });
  const history = useHistory();
  const theme = useTheme();

  const { beforeStartModalStep, setBeforeStartModalStep } =
    useBeforeStartModal();

  const { isDesktop } = useBreakpoint();

  const {
    start,
    stop,
    reset,
    next,
    back,
    goTo,
    checkActiveTour,
    /** @type {{run: unknown, stepIndex: number}} */
    state: { run, stepIndex },
  } = useTourControls(id, steps);

  const modalBeforeHandler = useModal();
  const modalAfterHandler = useModal();

  hideCloseButton =
    typeof hideCloseButton === 'undefined'
      ? stepIndex === steps.length - 1
      : hideCloseButton;

  const startTour = () => {
    const tourActive = checkActiveTour();
    if (!tourActive.canStartTour) return;

    if (showModalBeforeStart && stepIndex === 0) {
      modalBeforeHandler.show();
    } else if (!showModalBeforeStart) {
      start();
    }
  };

  addTourEventListener(`action-tourStart-${id}`, () => {
    startTour();
  });

  addTourEventListener(`action-tourStop-${id}`, () => {
    stop();
  });

  addTourEventListener(`action-tourReset-${id}`, () => {
    reset();
  });

  addTourEventListener(`action-tourNextStep-${id}`, () => {
    next();
  });

  addTourEventListener(`action-tourPrevStep-${id}`, () => {
    back();
  });

  addTourEventListener(`action-tourGoToStep-${id}`, (e) => {
    goTo(e.detail.stepIndex);
  });

  const handleCallback = (data) => {
    const { action, index, status, lifecycle, type, step } = data;

    if (type === 'tooltip') {
      toggleHighlightElements(step?.highlight);
    }

    if (type === 'tour:start') onStartTour();

    if (type === 'step:before' && action === 'next' && step?.focusElement) {
      document.querySelector(step?.target)?.focus();
    }

    if (
      type === 'step:before' &&
      (action === 'next' || action === 'prev') &&
      step?.executeBefore
    ) {
      step?.executeBefore();
    }

    if (type === 'step:after') {
      if (action === 'next') {
        next(index);
        if (step?.focusElement) {
          document.querySelector(step?.target)?.blur();
        }
        onChangeStep({ action, index, step });
        if (checkRedirect(steps[index + 1]?.redirect)) {
          stop(index + 1);
          history.replace(steps[index + 1]?.redirect);
        }
        if (step?.executeAfter) {
          stop(index + 1);
          step?.executeAfter();
        }
      } else if (action === 'prev') {
        back(index);
        if (step?.focusElement) {
          document.querySelector(step?.target)?.blur();
        }
        onChangeStep({ action, index, step });
        if (checkRedirect(steps[index - 1]?.redirect)) {
          stop(index - 1);
          history.replace(steps[index - 1]?.redirect);
        }
        if (step?.onPrevClick) {
          stop(index - 1);
          step?.onPrevClick();
        }
      } else if (action === 'close') {
        onCloseTour(stepIndex);
        if (resetOnOverlayClick) reset();
        else stop();
        if (step?.focusElement) {
          document.querySelector(step?.target)?.blur();
        }
      }
    } else if (
      (action === 'next' || action === 'prev') &&
      lifecycle === 'init' &&
      status === 'paused'
    ) {
      setTimeout(() => start(index), step?.timeout || 0);
    } else if (
      (type === 'tour:end' && action === 'next') ||
      stepIndex === steps.length
    ) {
      onEndTour(index);
      reset();
      if (showModalAfterEnd) modalAfterHandler.show();
    } else if (type === 'tour:end' && action === 'skip') {
      onEndTour(index);
      reset();
    }
  };

  const onModalStartLastStepButtonsClick = (type) =>
    ({
      welcomeTour: () => {
        if (beforeStartModalStep === 3 || isDesktop) {
          modalBeforeHandler.hide();
          setBeforeStartModalStep(0);
          if (type === 'confirm') start();
        }
      },
      subjectTour: () => {
        modalBeforeHandler.hide();
        if (type === 'confirm') start();
      },
    }[id]());

  const TourIndicator = () => {
    return CustomIndicatorComponent ? (
      CustomIndicatorComponent
    ) : (
      <Styles.Button id={`tour-${id}-indicator`} onClick={() => startTour()}>
        <h3>?</h3>
      </Styles.Button>
    );
  };

  return (
    <>
      {showIndicator ? <TourIndicator /> : null}

      <Joyride
        debug
        spotlightClicks={false}
        callback={handleCallback}
        run={run}
        stepIndex={stepIndex}
        continuous
        hideCloseButton={hideCloseButton}
        showSkipButton={stepIndex === 0}
        disableOverlayClose={true}
        floaterProps={{
          autoOpen: true,
          styles: {
            floater: { zIndex: 99999 },
          },
        }}
        locale={{
          back: 'Anterior',
          close: 'Fechar',
          last: 'Concluir',
          next: stepIndex === 0 ? 'Próxima etapa' : 'Próxima',
          skip: 'Pular tour',
        }}
        styles={{
          tooltip: {
            borderRadius: 8,
            padding: 32,
          },
          tooltipTitle: {
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: 16,
            color: theme.colors.text.black,
          },
          tooltipContainer: {
            textAlign: 'left',
          },
          tooltipContent: {
            padding: 0,
            paddingTop: 16,
            fontFamily: 'Roboto',
            fontSize: 16,
            color: theme.colors.text.black,
          },
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
          spotlight: {
            cursor: 'default',
          },
          overlayColor: 'rgba(0, 0, 0, 0.5)',
          overlay: {
            cursor: 'default',
            zIndex: 1000,
          },
          options: {
            primaryColor: theme.colors.text.primary,
          },
          buttonClose: {
            width: 12,
            height: 12,
            top: 30,
            right: 30,
            padding: 0,
            color: theme.colors.text.black,
          },
          buttonBack: {
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: 16,
            padding: 24,
            width: '100%',
            borderRadius: 8,
            border: `solid 2px ${theme.colors.button.primary}`,
            backgroundColor: 'transparent',
            color: theme.colors.text.primary,
          },
          buttonNext: {
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: 16,
            padding: 24,
            width: '100%',
            borderRadius: 8,
            backgroundColor: theme.colors.button.primary,
            color: theme.colors.text.white,
          },
          buttonSkip: {
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: 16,
            padding: 24,
            width: '100%',
            borderRadius: 8,
            backgroundColor: 'transparent',
            color: theme.colors.text.primary,
          },
        }}
        steps={steps}
      />
      <TourModal
        modalHandler={modalBeforeHandler}
        modalStyles={modalBeforeStart.styles}
        {...modalBeforeStart.details}
        onConfirm={() => {
          modalBeforeStart.onConfirm && modalBeforeStart.onConfirm();
          onModalStartLastStepButtonsClick('confirm');
        }}
        onCancel={() => {
          modalBeforeStart.onCancel && modalBeforeStart.onCancel();
          onModalStartLastStepButtonsClick('cancel');
        }}
      />
      <TourModal
        modalHandler={modalAfterHandler}
        modalStyles={modalAfterEnd.styles}
        {...modalAfterEnd.details}
        onConfirm={() => {
          modalAfterHandler.hide();
          modalAfterEnd.onConfirm && modalAfterEnd.onConfirm();
        }}
        onCancel={() => {
          modalAfterHandler.hide();
          modalAfterEnd.onCancel && modalAfterEnd.onCancel();
        }}
      />
    </>
  );
};

Tour.propTypes = {
  id: string,
  steps: {
    target: string,
    content: node,
    focusElement: bool,
    waitForElement: bool,
    highlight: [string],
    redirect: string,
  },
  showModalBeforeStart: bool,
  showModalAfterEnd: bool,
  modalBeforeStart: {
    styles: object,
    details: {
      content: node,
      locale: {
        confirm: string,
        cancel: string,
      },
      hideCancel: bool,
    },
    onConfirm: func,
    onCancel: func,
  },
  modalAfterEnd: {
    styles: object,
    details: {
      content: node,
      locale: {
        confirm: string,
        cancel: string,
      },
      hideCancel: bool,
    },
    onConfirm: func,
    onCancel: func,
  },
  resetOnOverlayClick: bool,
  showIndicator: bool,
  CustomIndicatorComponent: node,
  onStartTour: func,
  onEndTour: func,
  onCloseTour: func,
  onChangeStep: func,
  hideTitle: bool,
  hideCloseButton: bool,
};

export default Tour;
