import React from 'react';

import { Typography } from '@sofia/ui';

import { Filters, useNotificationsFilters } from './use-notifications-filters';
import { analyticsCreativesEvents } from '../../../../../shared/utils/communication-constants';
import { FiltersWrapper, Chip } from './notifications-filter.styles';
import { pushEvent } from '../../../../../../lib/ga';
import { Creative } from '../../../../types';

interface FilterChipProps {
  title: string;
  active: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FilterChip: React.FC<FilterChipProps> = ({
  onClick,
  active,
  title,
}: FilterChipProps) => (
  <Chip aria-label={`${title} filtro`} onClick={onClick} active={active}>
    <Typography space="nowrap" size={12} weight={700}>
      {title}
    </Typography>
  </Chip>
);

const NotificationsFilters: React.FC = () => {
  const [activeFilters, setActiveFilters] = React.useState<string[]>([]);

  const { filters, notification, dispatch, loading } =
    useNotificationsFilters();

  const onFilterClick = React.useCallback((category: Filters) => {
    pushEvent(
      'event',
      analyticsCreativesEvents.notificationsCenter
        .Com_central_notificacoes_clique_filtro.event,
      {
        item: analyticsCreativesEvents.notificationsCenter.Com_central_notificacoes_clique_filtro.param(
          category?.title
        ),
      }
    );

    return setActiveFilters((old) =>
      old.find((item) => category.key.includes(item))
        ? old.filter((item) => !category.key.includes(item))
        : [...old, ...category.key]
    );
  }, []);

  React.useEffect(() => {
    let filteredNotifications: Creative[] = [];

    if (activeFilters.includes('unread')) {
      const unreads = notification?.creative?.filter(({ read }) => !read);

      filteredNotifications =
        activeFilters.length > 1
          ? unreads.filter((unhead) =>
              activeFilters.find((filter) => filter === unhead.categoryType)
            )
          : unreads;
    } else {
      filteredNotifications = notification?.creative?.filter(
        ({ categoryType }) =>
          activeFilters.find((filter) => filter === categoryType)
      );
    }

    dispatch({
      filteredNotifications,
      content:
        !loading &&
        ((activeFilters.length && !filteredNotifications?.length) ||
          !notification?.creative?.length)
          ? 'empty'
          : 'list',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, loading]);

  return (
    <FiltersWrapper>
      {filters.map((category) => (
        <FilterChip
          key={category.title}
          title={category.title}
          active={activeFilters.some((item) => category.key.includes(item))}
          onClick={() => onFilterClick(category)}
        />
      ))}
    </FiltersWrapper>
  );
};

export default NotificationsFilters;
