import React from 'react';
import { Typography, SvgArrowLeft } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import Skeleton from '../../../../../../../skeleton/skeleton';
import { NotesMenuObjectHeaderProps } from '../../../types';
import * as Styled from './notes-menu-object-header.styles';

const NotesMenuObjectHeader = ({
  title,
  desc,
  onReturnToSectionList,
  loading,
}: NotesMenuObjectHeaderProps): React.ReactElement => {
  const theme = useTheme();
  return (
    <Styled.Button
      color={theme.colors.text.black}
      background={theme.colors.background.primary}
      hover={theme.colors.background.secondary}
      aria-label="Voltar para lista de aulas"
      onClick={onReturnToSectionList}
    >
      <Styled.Flex>
        <Styled.Icon>
          <SvgArrowLeft />
        </Styled.Icon>
        <Styled.Column>
          {loading ? (
            <Skeleton height="36px" width="90%" />
          ) : (
            <Typography size={24} color={theme.colors.text.black} weight={700}>
              {title}
            </Typography>
          )}
          {loading ? (
            <Skeleton height="21px" width="90%" />
          ) : (
            <Typography color={theme.colors.text.black} size={14} weight={700}>
              {desc}
            </Typography>
          )}
        </Styled.Column>
      </Styled.Flex>
    </Styled.Button>
  );
};

NotesMenuObjectHeader.defaultProps = {
  desc: '',
  loading: false,
};

export default NotesMenuObjectHeader;
