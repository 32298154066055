import Title from './title';
import Description from './description';
import Icon from './icon';
import SettingsCard from './settings-card.presentation';

export const Components = {
  Description: Description,
  Icon: Icon,
  Title: Title,
};

export default SettingsCard;
