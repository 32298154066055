import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';

export const TabsWrapper = styled.div`
  div[role='tabpanel'] {
    margin-top: 32px;
    ${mediaQuery('desktop')} {
      margin-top: 56px;
    }
  }
`;
