import {
  SvgCommentSearch,
  SvgGraduationCap,
  SvgUserCircle,
  SvgBookOpen,
  SvgRocket,
  SvgStar,
} from '@sofia/ui';
import { DollarSign } from '@ampli/icon';

import { IconProps } from './icon';

export type CategoryType =
  | 'ACADEMICO'
  | 'FINANCEIRO'
  | 'REMATRICULA'
  | 'SECRETARIA'
  | 'PESQUISAS'
  | 'EMPREGABILIDADE'
  | 'NOVIDADESEPARCERIAS'
  | 'TUTOR'
  | 'ONBOARDING';

export const categories: CategoryType[] = [
  'ACADEMICO',
  'FINANCEIRO',
  'REMATRICULA',
  'SECRETARIA',
  'PESQUISAS',
  'EMPREGABILIDADE',
  'NOVIDADESEPARCERIAS',
  'TUTOR',
  'ONBOARDING',
];

export const parsedCategory = (
  key?: CategoryType
): {
  title: string;
  icon: React.FC<IconProps>;
} =>
  ({
    ACADEMICO: {
      title: 'Seus estudos',
      icon: SvgBookOpen,
    },
    FINANCEIRO: {
      title: 'Seu financeiro',
      icon: DollarSign,
    },
    REMATRICULA: {
      title: 'Sua secretaria',
      icon: SvgUserCircle,
    },
    SECRETARIA: {
      title: 'Sua secretaria',
      icon: SvgUserCircle,
    },
    PESQUISAS: {
      title: 'Queremos te ouvir',
      icon: SvgCommentSearch,
    },
    EMPREGABILIDADE: {
      title: 'Pra você',
      icon: SvgRocket,
    },
    NOVIDADESEPARCERIAS: {
      title: 'Pra você',
      icon: SvgRocket,
    },
    TUTOR: {
      title: 'Tutor',
      icon: SvgGraduationCap,
    },
    ONBOARDING: {
      title: 'Boas-vindas',
      icon: SvgStar,
    },
  }[key || 'ACADEMICO']);
