import React from 'react';
import { Typography } from '@sofia/ui';
import { ChatCopilot } from '../../../../../../components/chat-copilot';
import NewFeatureCommunication from '../../new-feature-communication/new-feature-communication';
import { useSectionBarCopilotButtonLogic } from './section-bar-copilot-button.logic';
import * as Styled from './section-bar-copilot-button.styles';
import AvatarEdu from '../../../../../../assets/avatar-edu.png';
import AvatarOnBoardingEdu from '../../../../../../assets/avatar-edu-onboarding-aprendizagem.png';

const SectionBarCopiloButton = ({
  studentId,
}: {
  studentId: string;
}): React.ReactElement | null => {
  const {
    theme,
    commsAnchorEl,
    setCommsAnchorEl,
    payload,
    isCopilotOpen,
    startChatOpened,
    onCloseCommunication,
    canShowCommunication,
    loading,
    isDesktop,
    hasLearningEduRole,
  } = useSectionBarCopilotButtonLogic({ studentId });

  return hasLearningEduRole ? (
    payload && !canShowCommunication ? (
      <Styled.Wrapper>
        <ChatCopilot
          isNotFixed
          isQuizCopilotOpen={isCopilotOpen && !canShowCommunication}
          isCopilotOnQuiz
          isChatCopilotOnpenOnRender={startChatOpened}
          isQuizPageRendered
          quizPagePayload={payload}
        />
      </Styled.Wrapper>
    ) : canShowCommunication ? (
      <>
        <Styled.CopilotButton
          ref={(ref: HTMLButtonElement) => {
            if (!loading && commsAnchorEl !== null) {
              setCommsAnchorEl(ref);
            }
          }}
        >
          <Styled.AvatarEdu src={AvatarEdu} alt="Edu" />
          {isDesktop ? 'Edu' : null}
        </Styled.CopilotButton>
        {commsAnchorEl ? (
          <NewFeatureCommunication
            closeXBtn
            backdrop
            absoluteTopSpace={42}
            actions={null}
            anchorEl={commsAnchorEl}
            handleClose={() => {
              setCommsAnchorEl(null);
              onCloseCommunication();
            }}
            arrowPosition="right"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            title={
              <>
                <Styled.AvatarCommunicationEdu
                  src={AvatarOnBoardingEdu}
                  alt="Edu"
                />
                <Typography
                  size={16}
                  weight={700}
                  color={theme.colors.text.black}
                >
                  Conheça o Edu!
                </Typography>
              </>
            }
            message="Seu assistente virtual de estudos está pronto para esclarecer suas dúvidas e indicar materiais de estudo sobre o conteúdo. Experimente agora!"
          />
        ) : null}
      </>
    ) : null
  ) : null;
};

export default SectionBarCopiloButton;
