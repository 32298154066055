import React from 'react';
import { throttle } from 'lodash';
const parseContent = function () {
  let pseudo = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ':before';
  let selector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'body';
  const el = document.querySelector(selector);
  const content = window.getComputedStyle(el, pseudo).content;
  if (content && content !== '"normal"') {
    return content.replace(/^["']|["']$/g, '');
  }
  return '';
};
export const useBreakpoint = function () {
  let showCurrentSize = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  const [state, setState] = React.useState();
  const [currentSize, setCurrentSize] = React.useState({});
  const context = React.useMemo(() => ({
    currentBreakpoint: state,
    currentSize,
    isDesktop: state === 'desktop',
    isTablet: state === 'tablet',
    isMobile: state === 'mobile'
  }), [currentSize, state]);
  React.useEffect(() => {
    let mounted = true;
    let rAF = null;
    const onResize = throttle(() => {
      var _window, _window2;
      if (!mounted) {
        return;
      }
      if (rAF) {
        window.cancelAnimationFrame(rAF);
      }
      const breakpoint = parseContent();
      setCurrentSize({
        width: (_window = window) === null || _window === void 0 ? void 0 : _window.innerWidth,
        height: (_window2 = window) === null || _window2 === void 0 ? void 0 : _window2.innerHeight
      });
      rAF = window.requestAnimationFrame(() => setState(breakpoint));
    }, 200);
    window.addEventListener('resize', onResize, {
      passive: true
    });
    onResize();
    return () => {
      mounted = false;
      rAF = null;
      window.removeEventListener('resize', onResize);
    };
  }, []);
  return showCurrentSize ? context : context.currentBreakpoint;
};