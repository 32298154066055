import { SizeMaxWidthScreenMobile, SizeMaxWidthScreenTablet, SizeMinWidthScreenDesktop, SizeMinWidthScreenSuper, SizeMinWidthScreenTablet } from './tokens';
const superMin = SizeMinWidthScreenSuper;
const deskMin = SizeMinWidthScreenDesktop;
const tabletMin = SizeMinWidthScreenTablet;
const tabletMax = SizeMaxWidthScreenTablet;
const mobileMax = SizeMaxWidthScreenMobile;
const mediaQuery = match => {
  return {
    super: `@media (min-width: ${superMin})`,
    desktop: `@media (min-width: ${deskMin})`,
    'desktop-max': `@media (max-width: ${deskMin})`,
    'tablet-min': `@media (min-width: ${tabletMin})`,
    'tablet-max': `@media (max-width: ${tabletMax})`,
    tablet: `@media (min-width: ${tabletMin}) and (max-width: ${tabletMax})`,
    mobile: `@media (max-width: ${mobileMax})`
  }[match] || match;
};
export default mediaQuery;