import styled from '@emotion/styled';

import { convertPXToREM, mediaQuery, SvgCheck } from '@sofia/ui';
import { keyframes } from '@emotion/react';

const checkAnimation = keyframes`
	from {
		opacity: 0;
		transform: rotateX(90deg) skewX(-30deg);
	}
	to {
		opacity: 1;
		transform: rotateX(0deg) skewX(0deg);
	}
`;

const enteringAnimation = keyframes`
	from {
    opacity: 0;
    transform: translateY(90%);
  }
	to {
    opacity: 1;
    transform: translateY(0%);
	}
`;

const leavingAnimation = keyframes`
	from {
    opacity: 1;
    transform: translateY(0%);
  }
	to {
    opacity: 0;
    transform: translateY(90%);
	}
`;

export const EvaluationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: opacity 300ms ease-in;
  animation: ${enteringAnimation} 300ms ease;

  &.leaving {
    animation: ${leavingAnimation} 300ms ease;
  }
`;

export const FlexedStars = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -10px;

  ${mediaQuery('mobile')} {
    margin-bottom: -5px;
    flex-direction: column;
  }
`;

interface FlexedCommentProps {
  inputDisabled?: boolean;
}

export const FlexedComment = styled.div<FlexedCommentProps>`
  display: flex;
  margin-top: 4px;
  transition: margin-top ease-out 300ms;
  transition-delay: 1s;
  flex-direction: column;

  ${({ inputDisabled }) => inputDisabled && `margin-top: -21px;`}
`;

export const Title = styled.span`
  white-space: nowrap;
  color: #8e9294;
  font-size: ${convertPXToREM(16)};
  margin-top: 8px;
`;

interface CommentProps {
  inputDisabled?: boolean;
  color?: string;
}

export const Comment = styled('span', {
  shouldForwardProp: (propName) => propName !== 'inputDisabled',
})<CommentProps>`
  color: ${({ color }) => color};
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
  opacity: 1;
  transition: opacity ease-out 300ms;
  transition-delay: 1s;
  ${({ inputDisabled }) => inputDisabled && 'opacity: 0 !important;'}
`;

export const BoldCheck = styled(SvgCheck)`
  transform-origin: bottom right 4px;
  transition: opacity 300ms ease-in;
  animation: ${checkAnimation} 300ms ease;
`;

interface CommentInputProps {
  borderColor?: string;
  focusColor?: string;
  disabledColor?: string;
}

export const CommentInput = styled.input<CommentInputProps>`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin-right: 8px;
  height: 56px;
  box-shadow: unset;
  transition: height linear 0.5s;
  border: 2px solid ${({ borderColor }) => borderColor};

  &:focus {
    ${({ focusColor }) =>
      `border-color: ${focusColor}; outline-color: ${focusColor};`};
  }

  &:disabled {
    background-color: transparent;
    opacity: 1;
    cursor: not-allowed;
    color: ${({ disabledColor }) => disabledColor};
  }

  ${mediaQuery('mobile')} {
    margin-bottom: 12px;
  }
`;

export const BoxComment = styled.div`
  width: 100%;
  margin-top: 40px;

  ${mediaQuery('desktop')} {
    margin-top: 24px;
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  height: 56px;
  margin-top: 16px;

  button {
    width: 100%;
    height: 100%;
    padding: 19px 53px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      align-items: center;

      svg {
        margin-left: 6px;
      }
    }
  }
`;
