import { ChangeBillingDayStatusByUrl } from '../shared/constants';

const URL_BASE = '/:courseType/:courseVersionId/pagamento';

export default {
  home: `${URL_BASE}`,
  invoiceSummary: `${URL_BASE}/resumo/:invoiceId`,
  financialStatement: `${URL_BASE}/extrato-financeiro`,
  paymentOptions: `${URL_BASE}/forma-de-pagamento/:invoiceId`,
  paymentBoleto: `${URL_BASE}/boleto/:invoiceId`,
  paymentPix: `${URL_BASE}/pix/:invoiceId`,
  paymentCreditCard: `${URL_BASE}/cartao-de-credito/:invoiceId`,
  paymentAnalysis: `${URL_BASE}/analise-pagamento/:type/:invoiceId`,
  paymentAnalysisTimeout: `${URL_BASE}/analise-pagamento-atrasou/:type/:invoiceId`,
  paymentAnalysisSuccess: `${URL_BASE}/analise-pagamento-sucesso/:type/:invoiceId`,
  paymentAnalysisError: `${URL_BASE}/analise-pagamento-erro/:type/:invoiceId`,
  downloadCoupon: `${URL_BASE}/baixar-comprovante-cupom`,
  paymentIncomeTax: `${URL_BASE}/informe-de-mensalidades`,
  scholarship: `${URL_BASE}/beneficios`,
  scholarshipDetails: `${URL_BASE}/beneficios/:id`,
  sendDocumentation: `${URL_BASE}/beneficios/enviar-documentacao/:id`,
  sendDocumentationUpload: `${URL_BASE}/beneficios/enviar-documentacao/formulario/:id`,
  sendDocumentationUploadSuccess: `${URL_BASE}/beneficios/documentação-enviada-com-sucesso/:id`,
  billingCycleUpdateContract: `${URL_BASE}/alterar-data-de-vencimento/${ChangeBillingDayStatusByUrl.CONTRACT}`,
  billingCycleUpdateSelectDay: `${URL_BASE}/alterar-data-de-vencimento/${ChangeBillingDayStatusByUrl.SELECT_DAY}`,
  billingCycleUpdateSelectedDay: `${URL_BASE}/alterar-data-de-vencimento/${ChangeBillingDayStatusByUrl.SELECTED_DAY}`,
  billingCycleUpdateProcessing: `${URL_BASE}/alterar-data-de-vencimento/${ChangeBillingDayStatusByUrl.PROCESSING}`,
  billingCycleUpdateSuccess: `${URL_BASE}/alterar-data-de-vencimento/${ChangeBillingDayStatusByUrl.SUCCESS}`,
  billingCycleUpdateProcessingContract: `${URL_BASE}/alterar-data-de-vencimento/${ChangeBillingDayStatusByUrl.PROCESSING_CONTRACT}`,
  billingCycleUpdateTimeOut: `${URL_BASE}/alterar-data-de-vencimento/processamento-atrasou`,
  annualSettlementStatement: `${URL_BASE}/declaracao-anual-de-quitacao`,
  taxInvoiceDocument: `${URL_BASE}/nota-fiscal/:id/`,
  taxInvoiceDocumentUnavailable: `${URL_BASE}/nota-fiscal/:id/documento-indisponivel`,
  errorWithAvailableForecast: `${URL_BASE}/erro-documento-previsao-disponibilidade`,
  errorException: `${URL_BASE}/erro-exception`,
  refund: `${URL_BASE}/reembolso`,
};
