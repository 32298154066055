import styled from '@emotion/styled';
import { convertPXToREM, mediaQuery } from '@sofia/ui';

export const IconBackground = styled.div<{ background?: string }>`
  background: ${({ background }) => background};
  border-radius: 16px;
  padding: 8px;
  width: 48px;
  height: 48px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const Content = styled.div`
  padding: 24px;

  ${mediaQuery('desktop')} {
    width: ${convertPXToREM(456)};
  }
`;

export const Footer = styled.div`
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  ${mediaQuery('desktop')} {
    flex-direction: row;
    .discard-publication-button {
      order: -1;
    }
  }
`;
