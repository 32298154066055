import { GET_ME } from '@ampli/services';

const authGuard = async (to, from, next) => {
  const noAuth = to.meta?.auth?.noAuth;
  if (noAuth) return next();
  const redirect = to.meta?.auth?.redirect || '/';
  const privateClient = to.meta?.clients?.privateClient;

  const successfulAuth = noAuth ? () => next.redirect(redirect) : next;
  const unsuccessfulAuth = noAuth ? next : () => next.redirect(redirect);

  try {
    const response = await privateClient.query({ query: GET_ME });

    if (!response?.errors && response?.data) {
      dispatchEvent(
        new CustomEvent('apmContextChange', {
          detail: {
            studentId: response.data.data?.id,
            route: to?.match?.path,
            url: to?.match?.url,
          },
        })
      );
      successfulAuth();
    } else {
      dispatchEvent(
        new CustomEvent('apmContextChange', {
          detail: {
            studentId: response.data?.data?.id,
            route: to?.match?.path,
            url: to?.match?.url,
          },
        })
      );
      throw response.errors[0];
    }
  } catch (error) {
    dispatchEvent(
      new CustomEvent('apmContextChange', {
        detail: {
          studentId: undefined,
          route: to?.match?.path,
          url: to?.match?.url,
        },
      })
    );
    console.warn(error);
    unsuccessfulAuth();
  }
};

export default authGuard;
