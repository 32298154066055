import { features } from '../config';

export const fullscreenEnabled =
  features.examInFullscreen &&
  !!document.fullscreenEnabled &&
  !!document.documentElement.requestFullscreen &&
  !!document.exitFullscreen;

export const isInFullscreen = () => {
  return Boolean(
    document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
  );
};

export const handleExitFullscreen = () =>
  fullscreenEnabled && isInFullscreen() && document.exitFullscreen();

export const handleRequestFullscreen = () =>
  new Promise((resolve, reject) => {
    if (!fullscreenEnabled) {
      return resolve();
    }

    const request = document.documentElement.requestFullscreen();

    if (request?.then) {
      request.then(resolve).catch(reject);
    } else {
      resolve();
    }
  });
