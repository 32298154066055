import styled from '@emotion/styled';
import { convertPXToREM, mediaQuery } from '@sofia/ui';

interface ICustomizationButton {
  anchorEl?: HTMLButtonElement | null;
  borderColor: string;
}

export const CustomizationButton = styled('button', {
  shouldForwardProp: (propName: string) =>
    propName !== 'anchorEl' && propName !== 'borderColor',
})<ICustomizationButton>(
  ({ anchorEl, borderColor }: ICustomizationButton) => `
    svg {
      margin: 10px;
      height: 100%;
    }
			padding: 0px;
    ${mediaQuery('desktop')} {
      margin-right: 24px;
      flex-direction: row;
      padding: 8px 16px;
      svg {
        margin: 0 10px 0 0;
      }
    }
    span {
      font-size: ${convertPXToREM(16)};
      font-weight: 400;
      line-height: 150%;
    }
    :hover, &[aria-expanded='true'] {
      background: #EBF0F5;
    }

    display: flex;
    align-items: center;
    flex-direction: column;
    background: transparent;
    border: solid 1px ${anchorEl ? borderColor : 'transparent'};
    ${anchorEl ? 'border-radius: 40px;' : 'border-radius: 8px;'}
`
);
