import { exact, oneOf, string, node, array, oneOfType } from 'prop-types';
export const typeEnum = ['standard', 'error', 'warning', 'success'];
export const tagPropType = {
  text: oneOfType([string.isRequired, array.isRequired]),
  theme: exact({
    color: string,
    background: string
  }),
  type: oneOf(typeEnum),
  iconLeft: node,
  iconRight: node
};
export const typeThemes = {
  standard: {
    color: '#212529',
    background: '#DBE3EC'
  },
  error: {
    color: '#212529',
    background: '#F6E4DF'
  },
  warning: {
    color: '#212529',
    background: '#FFE6C9'
  },
  success: {
    color: '#212529',
    background: '#99E2CD'
  }
};