import { Card, Typography, SvgBookOpen, SvgArrowRight } from '@sofia/ui';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useBreakpoint } from '@ampli/hooks';
import { clickByKey } from '@ampli/utils';
import { useTheme } from '@emotion/react';
import { object } from 'prop-types';

import React from 'react';

import * as Styled from './subject-presentation-card-styles';
import { pushAmpliEvent } from '../../../../lib/ga';

const SubjectPresentationCard = ({ entitiesLabelsDictionary }) => {
  const theme = useTheme();
  const history = useHistory();
  const match = useRouteMatch();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const onClick = () => {
    pushAmpliEvent('event', 'subject_presentation_card');
    history.push(match.url + '/apresentacao');
  };

  return (
    <Styled.CardContainer>
      <Card
        id="subject-presentation-card"
        hover={theme.colors.card.color.hover}
        borderColor={theme.colors.card.border.primary}
        focusColor={theme.colors.card.border.third}
        onClick={onClick}
        aria-label={'Apresentação da disciplina'}
        onKeyDown={(event) => clickByKey(event, onClick)}
        tabIndex={0}
      >
        <Styled.FlexSpaceBetween>
          <Styled.Flex>
            <Styled.Icon stroke="transparent">
              <SvgBookOpen fill={theme.colors.text.primary} aria-hidden />
            </Styled.Icon>
            <Typography
              color={theme.colors.text.black}
              margin="0"
              size={isDesktop ? 20 : 16}
              id="subject-presentation-text"
            >
              {entitiesLabelsDictionary?.subjectPresentation}
            </Typography>
          </Styled.Flex>
          <Styled.Icon>
            <SvgArrowRight fill={theme.colors.text.primary} aria-hidden />
          </Styled.Icon>
        </Styled.FlexSpaceBetween>
      </Card>
    </Styled.CardContainer>
  );
};

SubjectPresentationCard.propTypes = {
  entitiesLabelsDictionary: object,
};

export default SubjectPresentationCard;
