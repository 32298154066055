import { arrayOf, object } from 'prop-types';
import { useTheme } from '@emotion/react';
import { Accordion, Typography } from '@sofia/ui';
import { sortObjectsByOrder } from '../../../../../utils';
import * as Styled from './subject-presentation-content-styles';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

const SubjectPresentationContent = ({ learningUnits, dictionary }) => {
  const theme = useTheme();

  const showTooltip = (index, text) => {
    const ref = document.getElementById(`section-title-${index}`);
    if (ref?.scrollWidth > ref?.offsetWidth) {
      return text;
    } else {
      return '';
    }
  };

  return (
    <>
      {sortObjectsByOrder(learningUnits)?.map((learningUnit, index) => {
        return (
          <Styled.ListContainer
            key={index}
            last={index === learningUnits?.length - 1}
          >
            <Accordion
              title={`${dictionary?.learningUnitTitle(learningUnit?.order)} - ${
                learningUnit?.title
              }`}
              markerColor={theme.colors.text.primary}
              iconColor={theme.colors.text.primary}
            >
              {learningUnit?.sections?.length
                ? sortObjectsByOrder(learningUnit.sections).map(
                    (section, index) => {
                      return (
                        <Styled.SectionList
                          key={index}
                          last={index === learningUnit.sections.length - 1}
                        >
                          <Typography
                            size={20}
                            color={theme.colors.text.black}
                            margin="0 0 4px"
                          >
                            Aula {section?.order}
                          </Typography>

                          <Styled.SectionTitleWrapper>
                            <Tooltip
                              title={showTooltip(index, section?.title)}
                              placement="top"
                              arrow
                            >
                              <Typography
                                id={`section-title-${index}`}
                                size={16}
                                color={theme.colors.text.lightBlack}
                                margin="0"
                              >
                                {section?.title}
                              </Typography>
                            </Tooltip>
                          </Styled.SectionTitleWrapper>
                        </Styled.SectionList>
                      );
                    }
                  )
                : null}
            </Accordion>
          </Styled.ListContainer>
        );
      })}
    </>
  );
};

SubjectPresentationContent.propTypes = {
  learningUnits: arrayOf(object.isRequired).isRequired,
  dictionary: object,
};

export default SubjectPresentationContent;
