import {
  switchObjectProperty,
  reduce,
  object,
  func,
  map,
  bool,
} from '@ampli/utils';

import { Tab, TabList, TabPanel, useTabState } from '@ampli/ui';
import { convertPXToVW, Typography, SvgQuestionCircle } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';

import {
  TitleCourseSelectionSkeleton,
  CardCourseSelectionSkeleton,
  NumberOfCoursesSkeleton,
} from './skeleton/course-selection-skeleton';

import CoursesByDegree from './courses-by-degree';
import React from 'react';

import { useCourseSelection } from '../../hooks';
import { useRolesContext } from '../../modules/shared/contexts/communication-roles-context';
import { LayoutContent } from '../layout';
import { useGetState } from '../../state';
import { startTour } from '../tour';

import {
  useGetTermsById,
  useGetStudentWelcomeTour,
} from '../../modules/communication/services/hooks';

import * as styles from './styles';

const responsiveProps = switchObjectProperty(
  () => ({
    mobile: {
      gridGap: 16,
    },
    desktop: {
      gridGap: 24,
    },
  }),
  'mobile'
);

const countCourses = (courses) =>
  reduce(
    courses,
    (numberOfCourses, courseEnrollments) =>
      numberOfCourses + courseEnrollments.length,
    0
  );

const CourseSelection = ({
  currentCourseEnrollmentsByDegree,
  pastCourseEnrollmentsByDegree,
  onCourseEnrollmentClick,
  studentFreeTrialData,
  loading,
}) => {
  const { currentBreakpoint, isDesktop } = useBreakpoint(true);

  const responsive = responsiveProps(currentBreakpoint);
  const theme = useTheme();
  const tab = useTabState();

  const { me } = useCourseSelection();

  const [
    {
      selectedCourseEnrollmentType: courseTypeCode,
      selectedCourseVersionId: courseVersionId,
      selectedCourseId: courseId,
    },
  ] = useGetState();

  const { data: modal } = useGetTermsById(
    courseTypeCode,
    courseVersionId,
    courseId,
    me.username,
    {
      fetchPolicy: 'network-only',
    }
  );

  const welcomeTour = useGetStudentWelcomeTour();
  const shouldPresentWelcomeTour = welcomeTour?.data?.data?.shouldPresent;
  const alreadyReadWelcomeTour = welcomeTour?.data?.data?.alreadyRead;
  const [hasActiveTour, setHasActiveTour] = React.useState(false);

  const { hasWelcomeOnboardingTourEnabled } = useRolesContext();

  const enableWelcomeTour = React.useMemo(
    () => shouldPresentWelcomeTour && hasWelcomeOnboardingTourEnabled,
    [shouldPresentWelcomeTour, hasWelcomeOnboardingTourEnabled]
  );

  const showWelcomeTour = React.useMemo(
    () => enableWelcomeTour && !alreadyReadWelcomeTour,
    [enableWelcomeTour, alreadyReadWelcomeTour]
  );

  const hasPendingContract = modal?.length;

  const numberOfCurrentCourses = React.useMemo(
    () => countCourses(currentCourseEnrollmentsByDegree),
    [currentCourseEnrollmentsByDegree]
  );

  const numberOfPastCourses = React.useMemo(
    () => countCourses(pastCourseEnrollmentsByDegree),
    [pastCourseEnrollmentsByDegree]
  );

  const hasCurrentCourses = numberOfCurrentCourses > 0;
  const hasPastCourses = numberOfPastCourses > 0;
  const gridGap = responsive.gridGap;

  React.useEffect(() => {
    window.addEventListener('hasActiveTour', () => {
      if (enableWelcomeTour) setHasActiveTour(true);
    });

    return () => {
      window.removeEventListener('hasActiveTour', () => {
        setHasActiveTour(false);
      });
    };
  }, [hasActiveTour, enableWelcomeTour]);

  React.useEffect(() => {
    if (showWelcomeTour) startTour('welcomeTour');
  }, [showWelcomeTour]);

  return (
    <>
      <LayoutContent
        padding={
          isDesktop ? ['huge', 'small', '0px'] : ['small', 'small', '0px']
        }
      >
        {loading ? (
          <NumberOfCoursesSkeleton />
        ) : (
          <styles.FlexWrapper>
            <Typography
              id="myCourses"
              data-testid="myCourses"
              color={theme.colors.text.black}
              margin="0px 1.313vw"
              size={isDesktop ? 48 : 32}
              weight={700}
            >
              Meus cursos
            </Typography>
            {enableWelcomeTour ? (
              <styles.IconWrapper
                id="question-icon-course-selection"
                data-testid="question-icon-course-selection"
                hoverColor={theme.colors.card.background.hover}
                onClick={() => {
                  startTour('welcomeTour');
                }}
              >
                <SvgQuestionCircle fill={theme.colors.breadCrumbs.icon} />
              </styles.IconWrapper>
            ) : null}
          </styles.FlexWrapper>
        )}

        <TabList {...tab} noBoxShadow aria-label="Selecione cursos">
          {loading ? (
            <TitleCourseSelectionSkeleton />
          ) : (
            <>
              {hasCurrentCourses ? (
                <Tab
                  {...tab}
                  color={theme.colors.navBar.active}
                  selected={tab.selectedId === 'currentCoursesTab'}
                  actived={theme.colors.navBar.active}
                  width={convertPXToVW(234)}
                  id="currentCoursesTab"
                  data-testid="currentCoursesTab"
                >
                  Atuais
                </Tab>
              ) : null}

              {hasPastCourses ? (
                <Tab
                  {...tab}
                  color={theme.colors.navBar.active}
                  selected={tab.selectedId === 'pastCoursesTab'}
                  actived={theme.colors.navBar.active}
                  width={convertPXToVW(234)}
                  id="pastCoursesTab"
                  data-testid="pastCoursesTab"
                >
                  Cursados
                </Tab>
              ) : null}
            </>
          )}
        </TabList>
      </LayoutContent>

      <styles.Hr id="tab-separator" data-testid="tab-separator" />

      <LayoutContent
        padding={isDesktop ? ['huge', 'small'] : ['small']}
        className={styles.layoutContentBottom}
      >
        {loading ? (
          <CardCourseSelectionSkeleton />
        ) : (
          <>
            {hasCurrentCourses ? (
              <TabPanel {...tab}>
                {map(
                  currentCourseEnrollmentsByDegree,
                  (courseEnrollments, degree) => (
                    <CoursesByDegree
                      key={`current-courses-by-degree_${degree}`}
                      courseEnrollments={courseEnrollments}
                      degree={degree}
                      gridCarouselProps={{ gap: gridGap }}
                      onCourseEnrollmentClick={onCourseEnrollmentClick}
                      studentFreeTrialData={studentFreeTrialData}
                      hasPendingContract={hasPendingContract}
                    />
                  )
                )}
              </TabPanel>
            ) : null}

            {hasPastCourses ? (
              <TabPanel {...tab}>
                {map(
                  pastCourseEnrollmentsByDegree,
                  (courseEnrollments, degree) => (
                    <CoursesByDegree
                      key={`past-courses-by-degree_${degree}`}
                      courseEnrollments={courseEnrollments}
                      degree={degree}
                      gridCarouselProps={{ gap: gridGap }}
                      onCourseEnrollmentClick={onCourseEnrollmentClick}
                      studentFreeTrialData={studentFreeTrialData}
                      hasPendingContract={hasPendingContract}
                    />
                  )
                )}
              </TabPanel>
            ) : null}
          </>
        )}
      </LayoutContent>
    </>
  );
};

CourseSelection.propTypes = {
  currentCourseEnrollmentsByDegree: object.isRequired,
  pastCourseEnrollmentsByDegree: object.isRequired,
  onCourseEnrollmentClick: func,
  loading: bool,
  studentFreeTrialData: object,
};

export default CourseSelection;
