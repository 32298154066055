import { useLazyQuery } from '@ampli/services';
import { GET_EXTERNAL_PAYMENT } from './queries';
import { LazyGetExternalPaymentHook } from './types';

export const useLazyGetExternalPayment: LazyGetExternalPaymentHook = ({
  options = {},
}) => {
  const [query, { data, loading, error, ...rest }] = useLazyQuery(
    GET_EXTERNAL_PAYMENT,
    {
      ...options,
      context: {
        autoHandle: true,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const externalPayment = loading || !data ? {} : data.data;

  return [query, { data: externalPayment, loading, error, ...rest }];
};
