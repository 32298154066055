import React from 'react';
import { ResumeCard } from '../';
import { Line, Wrapper } from './resume-list.styles';
import { array } from 'prop-types';
import { Typography } from '@sofia/ui';

const ResumeList = ({ resume }) => {
  return resume.map((item, index) => {
    return (
      <Wrapper key={index}>
        <Typography color="#444444" size={16} margin="0px 0px 8px 0px">
          {item.titration}
        </Typography>
        <ResumeCard title={item.title} institution={item.institution} />
        <Line />
      </Wrapper>
    );
  });
};

ResumeList.propTypes = {
  resume: array,
};

export default ResumeList;
