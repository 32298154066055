import React from 'react';

import { Typography, SvgArrowRight, Card } from '@sofia/ui';
import { generatePath, useHistory } from 'react-router-dom';
import { useBreakpoint } from '@ampli/hooks';
import { clickByKey } from '@ampli/utils';
import { useTheme } from '@emotion/react';

import { MessagingRoutesPath as routePath } from '../../../../modules';
import { useHistoricConversations } from '../talk-tutor-frame/components/ptk-frame/hooks/use-historic-conversations';
import { FrameProvider, useFrame } from '../talk-tutor-frame/shared';
import { PtkFrameProvider } from '../talk-tutor-frame/components/ptk-frame/contexts';
import { withQueryClient } from '../talk-tutor-frame/hocs/withQueryClient';
import { Signal } from '../talk-tutor-frame/components/talk-tutor-button/talk-tutor-button.styles';

import * as Styled from './messaging-historic-card.styles';

interface MessagingHistoricCardProps {
  subjectEnrollmentId: string;
  courseType: string;
  courseVersionId: string;
}

const MessagingHistoricCard = React.forwardRef(
  (
    {
      courseType,
      courseVersionId,
      subjectEnrollmentId,
    }: MessagingHistoricCardProps,
    ref: React.ForwardedRef<HTMLElement>
  ) => {
    const history = useHistory();
    const theme = useTheme();
    const breakpoint = useBreakpoint();
    const isDesktop = breakpoint === 'desktop';

    const { data: historic } = useHistoricConversations();
    const { isAmpli } = useFrame();

    const showNotification = React.useMemo(
      () =>
        Boolean(
          historic?.content?.some?.(({ readStatus }) => readStatus === 'UNREAD')
        ),
      [historic?.content]
    );

    const onClick = () =>
      history.push(
        generatePath(routePath.historic, {
          courseType,
          courseVersionId,
          subjectEnrollmentId,
        })
      );

    return !isAmpli ? (
      <Card
        ref={ref}
        hover={theme.colors.card.color.hover}
        borderColor={theme.colors.card.border.primary}
        minHeight="initial"
        onClick={onClick}
        onKeyDown={(event: KeyboardEvent) => clickByKey(event, onClick)}
        tabIndex={0}
        padding="21px 24px"
        id="subject-notes-card"
      >
        <Styled.Wrapper>
          <Styled.TitleContent>
            <Styled.PrefixIcon fill={theme.colors.text.primary} />
            <Typography
              id="subject-notes-title"
              color={theme.colors.text.black}
              size={isDesktop ? 20 : 16}
              margin={0}
            >
              Mensagens do tutor
            </Typography>
          </Styled.TitleContent>

          <Styled.Wrapper gap="16px">
            {showNotification ? <Signal size="large" /> : null}
            <SvgArrowRight fill={theme.colors.text.primary} aria-hidden />
          </Styled.Wrapper>
        </Styled.Wrapper>
      </Card>
    ) : null;
  }
);

MessagingHistoricCard.displayName = 'MessagingHistoricCard';

const MessagingHistoric: React.FC<MessagingHistoricCardProps> = (props) =>
  withQueryClient(
    <FrameProvider>
      <PtkFrameProvider>
        <MessagingHistoricCard {...props} />
      </PtkFrameProvider>
    </FrameProvider>
  );

export default MessagingHistoric;
