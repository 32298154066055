import React from 'react';
import { useTheme } from '@emotion/react';
import { Card } from '@sofia/ui';
import { ISettingsCardProps } from './settings-card.types';
import { useBreakpoint } from '../../hooks';

const SettingsCard: React.FC<ISettingsCardProps> = ({
  id,
  onClick,
  children,
  ...rest
}: ISettingsCardProps) => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();
  const padding = isDesktop ? '32px' : '16px';

  return (
    <Card
      id={id}
      onClick={onClick}
      minHeight="170px"
      hover={theme.colors.card.color.primary}
      borderColor={theme.colors.input.border.primary}
      shadow={false}
      hoverBorderColor={theme.colors.card.border.third}
      borderSize="2px"
      padding={padding}
      hasHoverIcon
      {...rest}
    >
      {React.Children.map(children, (child) =>
        React.isValidElement(child) ? React.cloneElement(child) : child
      )}
    </Card>
  );
};

export default SettingsCard;
