import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const Wrapper = styled.div`
  position: relative;
  padding: 16px 0;
`;

export const TableContainer = styled.div(
  ({
    scrollBorder,
    scrollBackground,
  }: {
    scrollBorder: string;
    scrollBackground: string;
  }) => `
  display: block;
  max-width: ${convertPXToREM(712)};
  overflow-x: auto;
  overflow-y: hidden;
  padding: 16px 0;

  ::-webkit-scrollbar-track {
    border-radius: 8px;
    border: 1px solid ${scrollBorder};
    background: ${scrollBackground};
  }

  td,
  th {
    border: 1px solid;
    padding: 8px 16px;
  }

  table {
    width: 100%;
    border: none;
    position: relative;
    border-collapse: collapse;
  }
`
);

export const LeftShadow = styled.div(
  ({ height, themeColor }: { height?: number; themeColor?: string }) => `
  display: block;
  position: absolute;
  width: 12px;
  ${height ? `height: ${height - 40}px;` : ''}
  background: linear-gradient(90deg, ${themeColor}10 0%, ${themeColor}00 100%);
  left: 0;
  top: 56px;
`
);

export const RightShadow = styled.div(
  ({ height, themeColor }: { height?: number; themeColor?: string }) => `
  display: block;
  position: absolute;
  width: 12px;
  ${height ? `height: ${height - 40}px;` : ''}
  background: linear-gradient(270deg, ${themeColor}10 0%, ${themeColor}00 100%);
  right: 0;
  top: 56px;
`
);
