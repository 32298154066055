import { useBreakpoint } from '@ampli/hooks';
import { node } from '@ampli/utils';

const MediaMatch = ({ desktop, mobile, tablet }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const isMobile = breakpoint === 'mobile';

  return isDesktop ? desktop : isMobile ? mobile : tablet || mobile;
};

MediaMatch.propTypes = {
  desktop: node.isRequired,
  mobile: node.isRequired,
  tablet: node,
};

export default MediaMatch;
