import React from 'react';
import { IContentProps } from '../../../communication/types';

const TourStep = ({
  heading,
  title,
  description,
  headingStyles,
  titleStyles,
  image,
  imageAlt = '',
  imageStyles,
  imageWidth,
  imageHeight,
}: IContentProps): JSX.Element => {
  return (
    <>
      {image ? (
        <img
          src={image}
          alt={imageAlt}
          style={imageStyles}
          width={imageWidth}
          height={imageHeight}
        />
      ) : null}
      {heading ? (
        <p style={headingStyles}>
          <strong>{heading}</strong>
        </p>
      ) : null}
      {title ? (
        <p style={titleStyles}>
          <strong>{title}</strong>
        </p>
      ) : null}
      {description ? <p>{description}</p> : null}
    </>
  );
};

export default TourStep;
