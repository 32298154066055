import { useMutation, useQuery } from '@ampli/services';
import {
  GET_AFFILIATE,
  GET_AFFILIATE_ID,
  GET_ONE_ARTICLE,
  SEARCH_ARTICLES,
  LIST_ARTICLES,
  RATE_ARTICLE,
  RATE_ARTICLE_COMMENT,
} from './queries';
import { useGetState } from '../../../state';

export const useListArticles = (id, options = {}) => {
  const { data, ...rest } = useQuery(LIST_ARTICLES, {
    ...options,
    variables: { id: id },
  });

  return { data: data?.data, ...rest };
};

export const useSearchArticles = (criteria, options = {}) => {
  const { data, ...rest } = useQuery(SEARCH_ARTICLES, {
    ...options,
    variables: {
      criteria: criteria,
    },
  });

  return {
    data: data?.data,
    ...rest,
  };
};

export const useGetOneArticle = (id, options = {}) => {
  const { data, ...rest } = useQuery(GET_ONE_ARTICLE, {
    ...options,
    variables: { id: id },
  });

  return { data: data?.data, ...rest };
};

export const useRateArticle = (options = {}) =>
  useMutation(RATE_ARTICLE, options);

export const useGetAffiliateId = (id, options = {}) => {
  const { data, ...rest } = useQuery(GET_AFFILIATE_ID, {
    ...options,
    variables: { courseEnrollmentId: id },
  });

  return { data: data?.data, ...rest };
};

export const useGetAffiliate = (id, options = {}) => {
  const { data, ...rest } = useQuery(GET_AFFILIATE, {
    ...options,
    variables: { affiliateId: id },
  });

  return { data: data?.data, ...rest };
};

export const useRateArticleComment = (options = {}) => {
  const [{ selectedCourseEnrollmentId }] = useGetState();

  const [mutate, { loading }] = useMutation(RATE_ARTICLE_COMMENT, {
    ...options,
    context: {
      autoHandle: true,
    },
  });

  const handleSubmit = async ({ articleId, comment }) => {
    try {
      const res = await mutate({
        variables: {
          feedback: {
            articleId,
            comment,
            courseEnrollmentID: selectedCourseEnrollmentId,
          },
        },
      });
      return res.data.data;
    } catch (error) {
      console.log('error', error);
    }
  };
  return { handleSubmit, loading };
};
