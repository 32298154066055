import styled from '@emotion/styled';

import { convertPXToREM } from '@sofia/ui';

interface BodyDetailsProps {
  color: string;
}

export const NotificationDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Image = styled.img`
  width: 100%;
  object-align: center;
  object-fit: cover;
  border-radius: 8px;
`;

export const BodyDetails = ({ color }: BodyDetailsProps): string => {
  return `
  font-size: ${convertPXToREM(16)};
  font-weight: 400;
  color: ${color};
  white-space: pre-wrap;
`;
};
