export default {
  /* onboarding */
  onboardingAssignment:
    '/:courseType/:courseVersionId/trabalho/:subjectEnrollmentId',
  onboardingAssignmentDocument:
    '/:courseType/:courseVersionId/trabalho/:subjectEnrollmentId/:assignmentId',
  /* correction */
  assignmentCorrectionFeedbacks:
    '/:courseType/:courseVersionId/trabalho/:subjectEnrollmentId/:assignmentId/correcao',
};
