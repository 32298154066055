import styled from '@emotion/styled';
import { convertPXToVW } from '../../utils';
export const Wrapper = styled.div`
  z-index: 1001;
  background: white;
  box-shadow: 0px 8px 32px rgba(33, 37, 41, 0.12);
  border-radius: 8px;
  width: 344px;
  min-height: 72px;
  align-items: center;
  padding: 8px;
  position: fixed;
  right: 20px;
  top: 20px;
  display: ${_ref => {
  let {
    display
  } = _ref;
  return display ? 'flex' : 'none';
}};

  @media (max-width: 360px) {
    right: 0px;
    left: -7px;
  }
`;
export const IconWrapper = styled.div`
  background: ${_ref2 => {
  let {
    type
  } = _ref2;
  return type === 'error' ? '#F6E4DF' : type === 'success' ? '#E0F0ED' : '#FFE6C9';
}};
  min-width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${convertPXToVW(8)};
`;
export const XWrapper = styled.div`
  padding-left: 0px;
  min-width: 24px;
  cursor: pointer;
`;
export const TextWrapper = styled.div`
  overflow: hidden;
  height: inherit;
  min-width: ${convertPXToVW(240)};
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`;