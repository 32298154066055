import { KeyboardEvent, MouseEvent } from 'react';

interface ClickByKeyProps {
  event: KeyboardEvent | MouseEvent;
  onClick?: (event: MouseEvent) => void;
  keysForClick?: string[];
}

const clickByKey = (props: ClickByKeyProps): void => {
  const { event, onClick, keysForClick = [' ', 'Enter'] } = props;

  const keyName = 'key' in event ? (event as KeyboardEvent).key : '';
  if (onClick && keysForClick.indexOf(keyName) >= 0) {
    onClick(event as MouseEvent);
  }
};

export default clickByKey;
