import { mediaQuery } from '@ampli/ui';

import styled from '@emotion/styled';

export const LayoutContentWrapper = styled.div`
  > div {
    padding-right: 8px;
    padding-left: 8px;

    ${mediaQuery('desktop')} {
      padding-right: 64px;
      padding-left: 64px;
    }
  }
`;

export const InProgressWrapper = styled.div`
  margin-bottom: 40px;
  ${mediaQuery('desktop')} {
    margin-bottom: 80px;
  }
`;

export const SkeletonWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 16px;
  margin-top: 32px;
  > div {
    width: 100%;
  }
  ${mediaQuery('desktop')} {
    margin-top: 56px;
    width: 100%;
    > div {
      width: calc(50% - 12px);
    }
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
`;
