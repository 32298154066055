import { firstLetterUpperCase } from '../../../utils';

const toExtensiveDate = ({
  date,
  time,
  isDesktop,
}: {
  date: string;
  time?: string;
  isDesktop: boolean;
  isToday?: boolean;
}): string => {
  const utcDate = time
    ? new Date(`${date}T${time || ''}`)
    : new Date(new Date(date).setHours(24, 0, 0, 0));

  const isToday =
    new Date(date).setHours(24, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);

  return `${
    isToday
      ? 'Hoje'
      : firstLetterUpperCase(
          utcDate
            .toLocaleDateString('pt-BR', {
              weekday: isDesktop ? 'long' : 'short',
            })
            .replace(isDesktop ? '-feira' : '.', '')
        )
  }, ${firstLetterUpperCase(
    utcDate.toLocaleDateString('pt-BR', {
      day: 'numeric',
      timeZone: 'UTC',
    })
  )} de ${firstLetterUpperCase(
    utcDate
      .toLocaleDateString('pt-BR', {
        month: 'short',
      })
      .replace('.', '')
  )}`;
};

export default toExtensiveDate;
