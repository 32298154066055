import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["loading", "data"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useQuery, useMutation } from '../hooks';
import { FIND_CONTACT_PERMISSIONS_BY_RECIPIENT, UPDATE_CONTACT_PERMISSIONS } from './queries';
import { contactPermissionSchema } from './schemas';
export const useFindContactPermissionsByRecipient = function (recipient) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery = useQuery(FIND_CONTACT_PERMISSIONS_BY_RECIPIENT, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        recipient
      },
      skip: !recipient
    })),
    {
      loading,
      data
    } = _useQuery,
    rest = _objectWithoutProperties(_useQuery, _excluded);
  const exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading
  }, rest);
};
export const useUpdateManyContactPermissions = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return [...useMutation(UPDATE_CONTACT_PERMISSIONS, _objectSpread({}, options)), contactPermissionSchema];
};