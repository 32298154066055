import React from 'react';
import { Skeleton } from '../../../skeleton';
import * as S from './cards-skeleton-styles';

export default function CardsSkeleton() {
  return (
    <S.Container tabIndex={-1}>
      <Skeleton height={80} />
      <Skeleton height={150} />
      <Skeleton height={80} />
      <Skeleton height={120} />
      <Skeleton height={80} />
    </S.Container>
  );
}
