import React from 'react';

import { useBreakpoint } from '@ampli/hooks';

import {
  useStudentHistoricConversations,
  useUnreadHistoricConversations,
  useTutorHistoricConversations,
  useHistoricConversations,
} from '../../hooks/use-historic-conversations';

import { FilterType, OnFilterParams } from './components';
import { ConversationInfo } from '../../types';
import { initialState } from './historic-section.constants';
import { usePtkFrame } from '../../contexts';
import { useFrame } from '../../../../shared';

export type State = {
  activeFilterChip: FilterType;
  activeFilterTab: FilterType;
};

type UseHistoricSectionReturn = State & {
  isCourseEnrollmentBlocked: boolean;
  isHistoricChatMobile: boolean;
  onFilterChange: (params: OnFilterParams) => void;
  conversations?: ConversationInfo[];
  refetching: boolean;
  historicData: {
    isRefetching: boolean;
  };
};

export const useHistoricSection = (): UseHistoricSectionReturn => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const [{ activeFilterChip, activeFilterTab }, dispatchState] =
    React.useReducer<React.Reducer<State, Partial<State>>>(
      (state, action) => ({ ...state, ...action }),
      initialState
    );

  const { isCourseEnrollmentBlocked } = useFrame();
  const { dispatch, conversation } = usePtkFrame();

  const { ...historicData } = useHistoricConversations();
  const { data: students } = useStudentHistoricConversations();
  const { data: tutors } = useTutorHistoricConversations();
  const { data: unreads } = useUnreadHistoricConversations();

  const conversations = React.useMemo(
    () =>
      ({
        TUTOR: {
          ALL: tutors?.content,
          NOT_READ: unreads?.content.filter((conv) => conv.agent === 'TUTOR'),
        }[activeFilterChip as string],
        STUDENT: {
          ALL: students?.content,
          NOT_READ: unreads?.content.filter((conv) => conv.agent === 'STUDENT'),
        }[activeFilterChip as string],
      }[activeFilterTab as string]),
    [
      activeFilterChip,
      activeFilterTab,
      students?.content,
      tutors?.content,
      unreads?.content,
    ]
  );

  const isHistoricChatMobile = React.useMemo(
    () =>
      Boolean(
        !isDesktop && conversation?.status !== 'FINALIZADA' && conversation?.id
      ),
    [conversation?.id, conversation?.status, isDesktop]
  );

  const refetching = React.useMemo(
    () =>
      historicData?.isRefetching ||
      historicData?.isFetching ||
      historicData?.isLoading,
    [
      historicData?.isFetching,
      historicData?.isLoading,
      historicData?.isRefetching,
    ]
  );

  const onFilterChange = React.useCallback(
    (params: OnFilterParams) => dispatchState({ ...params }),
    []
  );

  React.useEffect(() => {
    if (isDesktop && !conversation?.id)
      dispatch({
        conversation: conversations?.length ? conversations?.[0] : null,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations, conversation]);

  React.useEffect(() => {
    dispatch({ page: 'HISTORIC' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isCourseEnrollmentBlocked,
    isHistoricChatMobile,
    activeFilterChip,
    activeFilterTab,
    onFilterChange,
    conversations,
    historicData,
    refetching,
  };
};
