import React from 'react';
import { string, bool, node } from '@ampli/utils';
import { cx, css, px2grid, Text, getColor } from '@ampli/ui';

const Dot = ({ active = false, color, ...props }) => {
  const resolvedColor = getColor(color);
  const resolvedSize = px2grid(8);

  return (
    <div
      {...props}
      className={cx(
        'dot',
        css`
          border: 1px solid ${resolvedColor};
          border-radius: 50%;
          display: inline-block;
          height: ${resolvedSize};
          width: ${resolvedSize};

          &.active {
            background-color: ${resolvedColor};
          }
        `,
        active && 'active',
        props.className
      )}
    />
  );
};
Dot.propTypes = {
  className: string,
  active: bool,
  color: string.isRequired,
};

const Legend = ({
  dotActive = false,
  color = 'brand-base',
  children,
  ...props
}) => (
  <div
    {...props}
    className={cx(
      'legend',
      css`
        display: inline-block;
        margin: 0 ${px2grid(12)};

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      `,
      props.className
    )}
  >
    <Dot active={dotActive} color={color} />{' '}
    <Text color="text-dark" size="x-small">
      {children}
    </Text>
  </div>
);
Legend.propTypes = {
  className: string,
  children: node.isRequired,
  dotActive: bool,
  color: string,
};

export default Legend;
