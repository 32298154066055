import React, { useEffect, useRef } from 'react';
import { string, number, bool, func, any } from 'prop-types';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { BulletButton } from './question-bullets.styles';

const QuestionBullet = ({
  size,
  active,
  complete,
  onClick,
  bullet,
  darkColor,
  lightColor,
  ...props
}) => {
  const theme = useTheme();
  const ref = useRef();

  useEffect(() => {
    if (active && ref.current) {
      const parent = ref.current.parentNode;
      const x = ref.current.offsetLeft - parent.offsetWidth / 2;
      parent.scrollTo({ top: 0, left: x, behavior: 'smooth' });
    }
  }, [active]);

  return (
    <BulletButton
      {...props}
      ref={ref}
      resolvedDarkColor={darkColor}
      resolvedSize={size - 2}
      resolvedLightColor={lightColor}
      active={active}
      complete={complete}
      clickable={!!onClick}
      onClick={() => onClick(bullet)}
      aria-label={`${active ? 'Você está na questão' : 'Questão'} ${
        bullet + 1
      }. Status: ${complete ? 'Respondida' : 'Sem resposta'}`}
    >
      <Typography
        weight={700}
        color={active ? darkColor : theme.colors.text.white}
      >
        {bullet + 1}
      </Typography>
    </BulletButton>
  );
};

QuestionBullet.defaultProps = {
  active: false,
  complete: false,
};

QuestionBullet.propTypes = {
  bullet: number.isRequired,
  active: bool,
  complete: any,
  size: number,
  onClick: func,
  darkColor: string,
  lightColor: string,
};

export default QuestionBullet;
