import { DocumentNode } from 'graphql';
import { MockRequestGraphqlType } from '../types';

interface QueryMock<TResult, TVariables> {
  request: MockRequestGraphqlType;
  result: TResult;
  variables?: Partial<TVariables>;
}

/**
 * Cria um mock de consulta GraphQL com base na consulta e resultado fornecidos.
 * @param query A consulta GraphQL a ser mockada.
 * @param result O resultado esperado da consulta.
 * @variables Um objeto com as variáveis da consulta (opcional).
 * @returns Um objeto contendo a solicitação e o resultado da consulta.
 */

function createQueryMock<TResult, TVariables>({
  query,
  result,
  variables = {},
}: {
  query: DocumentNode;
  result: TResult;
  variables?: Partial<TVariables>;
}): QueryMock<TResult, TVariables> {
  return {
    request: {
      query,
      variables: variables,
    },
    result,
  };
}

export default createQueryMock;
