import { gql } from 'graphql.macro';

export const GET_CREDIT_CARD_RECURRENCE = gql`
  query GetCreditCardRecurrence($courseEnrollmentId: ID!) {
    data: getCreditCardRecurrence(courseEnrollmentId: $courseEnrollmentId) {
      id
      lastFourDigits
      holderName
    }
  }
`;

export const REMOVE_CREDIT_CARD = gql`
  mutation RemoveCreditCard($courseEnrollmentId: String!, $creditCardId: ID!) {
    data: removeCreditCard(
      courseEnrollmentId: $courseEnrollmentId
      creditCardId: $creditCardId
    )
  }
`;

export const PAY_INVOICE_BY_EXISTING_CREDIT_CARD = gql`
  mutation PayInvoiceByExistingCreditCard(
    $input: InvoiceExistingCreditCardPaymentInput!
  ) {
    data: payInvoiceByExistingCreditCard(input: $input)
  }
`;

export const PAY_INVOICE_BY_CREDIT_CARD = gql`
  mutation PayInvoiceByCreditCard($input: InvoiceNewCreditCardPaymentInput!) {
    data: payInvoiceByCreditCard(input: $input)
  }
`;

export const DISABLE_CREDIT_CARD_RECURRENCE = gql`
  mutation DisableCreditCardRecurrence($courseEnrollmentId: ID!) {
    data: disableCreditCardRecurrence(courseEnrollmentId: $courseEnrollmentId)
  }
`;

export const PAY_INVOICE_BY_CREDIT_CARD_RESULT = gql`
  query PayInvoiceByCreditCardResult(
    $courseEnrollmentId: String!
    $invoiceId: String!
  ) {
    data: payInvoiceByCreditCardResult(
      courseEnrollmentId: $courseEnrollmentId
      invoiceId: $invoiceId
    )
  }
`;

export const GET_CREDIT_CARD_LIST_BY_ENROLLMENT_ID = gql`
  query GetCreditCardListByEnrollmentId($courseEnrollmentId: String!) {
    data: getCreditCardListByEnrollmentId(
      courseEnrollmentId: $courseEnrollmentId
    ) {
      creditCards {
        id
        lastFourDigits
        holderName
      }
      canCreateANewCreditCard
    }
  }
`;
