import React from 'react';

import { string, node, bool, oneOfType } from 'prop-types';
import { useTheme } from '@emotion/react';
import { Tooltip } from '@material-ui/core';

import * as Styled from './subject-title-styles.js';

const SubjectTitle = ({
  subjectName,
  subTitle,
  subtitleClassName,
  titleClassName,
  children,
  ellipsis,
}) => {
  const theme = useTheme();
  const txtRef = React.useRef(null);
  const [allowTooltip, setAllowTooltip] = React.useState(false);
  const showTooltip = () => {
    return allowTooltip && ellipsis ? `${subjectName}` : '';
  };

  React.useEffect(() => {
    if (txtRef.current?.scrollHeight > txtRef.current?.offsetHeight + 2) {
      setAllowTooltip(true);
    }
  }, []);

  return (
    <>
      <Styled.TitleAndSubtitleContainer>
        <Styled.FlexColumn>
          <Tooltip arrow title={showTooltip()} placement="top">
            <Styled.Title
              id="subject-title"
              ref={txtRef}
              color={theme.colors.presentation.header.primary}
              className={`${titleClassName} ${ellipsis ? 'ellipsis' : ''}`}
            >
              {subjectName}
            </Styled.Title>
          </Tooltip>

          {subTitle?.length > 0 ? (
            <Styled.Subtitle
              id="subject-subTitle"
              color={theme.colors.presentation.header.secondary}
              className={subtitleClassName}
            >
              {subTitle}
            </Styled.Subtitle>
          ) : (
            <></>
          )}
        </Styled.FlexColumn>
        {children}
      </Styled.TitleAndSubtitleContainer>
    </>
  );
};

SubjectTitle.propTypes = {
  ellipsis: false,
};

SubjectTitle.propTypes = {
  subjectName: string.isRequired,
  subTitle: oneOfType([node, string]),
  subtitleClassName: string,
  titleClassName: string,
  children: node,
  ellipsis: bool,
};

export default SubjectTitle;
