import React from 'react';
import PropTypes from 'prop-types';
import * as S from './loading-spinner-styles';

export default function LoadingSpinner({ width, height, color }) {
  return <S.Spinner width={width} height={height} color={color} />;
}

LoadingSpinner.defaultProps = {
  width: 50,
  height: 50,
  color: '#01b6d0',
};

LoadingSpinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
