import { useTheme } from '@emotion/react';
import { Typography } from '@sofia/ui';
import React from 'react';
import { IDescriptionProps } from './settings-card.types';
import { useBreakpoint } from '../../hooks';

const Description: React.FC<IDescriptionProps> = ({
  description,
  ...rest
}: IDescriptionProps) => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();
  const size = isDesktop ? 16 : 12;

  return (
    <Typography
      color={theme.colors.text.lightBlack}
      margin="0"
      size={size}
      {...rest}
    >
      {description}
    </Typography>
  );
};

export default Description;
