import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

type TUseUrlChangeProps<T> = () => T;

const useUrlChange = <T>(callback: TUseUrlChangeProps<T>): void => {
  const history = useHistory();
  const [location, setLocation] = useState(history.location);

  useEffect(() => {
    const handleLocationChange = () => {
      const newLocation = history.location;
      if (newLocation !== location) {
        callback();
        setLocation(newLocation);
      }
    };
    const unlisten = history.listen(handleLocationChange);

    return () => {
      unlisten();
    };
  }, [history, callback, location]);
};

export default useUrlChange;
