import styled from '@emotion/styled';
import { convertPXToREM, convertPXToVH, convertPXToVW } from '../../utils';
const Button = styled.button`
  border: 2px solid transparent;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: ${_ref => {
  let {
    size
  } = _ref;
  return convertPXToREM(size);
}};
  font-weight: bold;
  line-height: 1.5;
  outline: 0;
  cursor: pointer;
  padding: ${convertPXToVH(8)} ${convertPXToVW(10)};
  text-align: center !important;
  width: ${_ref2 => {
  let {
    width
  } = _ref2;
  return width;
}};
  color: ${_ref3 => {
  let {
    color
  } = _ref3;
  return color;
}};
  margin: ${_ref4 => {
  let {
    margin
  } = _ref4;
  return margin;
}};
  inline-size: ${_ref5 => {
  let {
    inlineSize
  } = _ref5;
  return inlineSize;
}};

  &[disabled] {
    opacity: 0.5;
  }
`;
export const ButtonOutline = styled(Button)`
  background: linear-gradient(#fff, #fff) padding-box,
    ${_ref6 => {
  let {
    borderBoxColor
  } = _ref6;
  return borderBoxColor;
}} border-box;
  font-weight: 700;
  padding: ${_ref7 => {
  let {
    padding
  } = _ref7;
  return padding;
}};
  margin: ${_ref8 => {
  let {
    margin
  } = _ref8;
  return margin;
}};
  justify-content: ${_ref9 => {
  let {
    content
  } = _ref9;
  return content;
}};

  @media (max-width: 769px) {
    padding: ${_ref10 => {
  let {
    paddingMob
  } = _ref10;
  return paddingMob ? paddingMob : '8px 10px';
}};
  }

  &:disabled {
    background: #dee2e6;
    cursor: default;
    border: 1px solid #dee2e6;
    color: #868e96;
  }

  &:hover {
    ${_ref11 => {
  let {
    hover,
    borderBoxColor
  } = _ref11;
  return `background: linear-gradient(${hover}, ${hover}) padding-box, ${borderBoxColor} border-box;`;
}};
    border-color: ${_ref12 => {
  let {
    Borderhover
  } = _ref12;
  return Borderhover;
}};
  }

  &:focus {
    background: ${_ref13 => {
  let {
    focus
  } = _ref13;
  return focus;
}};
    border-color: ${_ref14 => {
  let {
    focusBorder
  } = _ref14;
  return focusBorder;
}};
    color: ${_ref15 => {
  let {
    colorFocus
  } = _ref15;
  return colorFocus;
}};
  }
`;
export const ButtonPrimary = styled(Button)(_ref16 => {
  let {
    background,
    loading,
    borderColor,
    color,
    margin,
    hover,
    Borderhover,
    padding,
    focus,
    focusBorder,
    colorFocus,
    minWidth,
    inlineSize
  } = _ref16;
  return `
  background: ${loading ? '#dee2e6' : background};
  border-radius: 8px;
  border-color: ${borderColor || 'transparent'};
  color: ${color};
  margin: ${margin};
  padding: ${padding || `${convertPXToVH(19)} ${convertPXToVW(24)}`};
  font-weight: 700;
  inline-size: ${inlineSize};
  min-width: ${minWidth};

  svg {
    margin: 0px 9px;
  }

  #rotate {
    animation-name: rotateAnimation;
    animation-duration: 10.5s;

    @keyframes rotateAnimation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &:disabled {
    background: #dee2e6;
    cursor: default;
    pointer-events: none;
    border: 1px solid #dee2e6;
    color: #868e96;
  }

  ${!loading ? `
    &:hover {
      background-color: ${hover};
      border-color: ${Borderhover};
    }
  ` : ''}

  &: focus {
    background: ${focus};
    border-color: ${focusBorder};
    color: ${colorFocus};
  }

  &:loading {
    background: #dee2e6;
    point-events: none;
  }

  @media (max-width: 930px) {
    height: 56px;
    padding-top: 16px;
  }
`;
});
export const ButtonPrimaryLong = styled(ButtonPrimary)`
  padding: ${_ref17 => {
  let {
    padding
  } = _ref17;
  return padding || `${convertPXToVH(13)} ${convertPXToVW(90)}`;
}};
  width: ${_ref18 => {
  let {
    width
  } = _ref18;
  return width || '100%';
}};
  inline-size: ${_ref19 => {
  let {
    inlineSize
  } = _ref19;
  return inlineSize;
}};
`;
export const ButtonOutlineLong = styled(ButtonOutline)`
  padding: ${convertPXToVH(13)} ${convertPXToVW(90)};
`;
export const ButtonGhost = styled.button`
  border: none;
  border-radius: 8px;
  outline: 0;
  background: transparent;
  cursor: pointer;
  font-weight: ${_ref20 => {
  let {
    weight = 700
  } = _ref20;
  return weight;
}};
  font-size: ${convertPXToREM(16)};
  color: ${_ref21 => {
  let {
    color
  } = _ref21;
  return color;
}};
  padding: ${_ref22 => {
  let {
    padding = '19px 24px'
  } = _ref22;
  return padding;
}};
  margin-top: ${_ref23 => {
  let {
    marginTop = '16px'
  } = _ref23;
  return marginTop;
}};
  ${_ref24 => {
  let {
    minWidth
  } = _ref24;
  return `min-width: ${minWidth}`;
}};
  ${_ref25 => {
  let {
    width
  } = _ref25;
  return `width: ${width}`;
}};

  &:hover {
    background-color: ${_ref26 => {
  let {
    hoverBackground = '#F3F6F9'
  } = _ref26;
  return hoverBackground;
}};
    color: ${_ref27 => {
  let {
    hoverColor = '#002871'
  } = _ref27;
  return hoverColor;
}};
  }

  &:disabled {
    background: #dee2e6;
    cursor: not-allowed;
    border: 1px solid #dee2e6;
    color: #868e96;

    &:hover {
      background: #dee2e6;
      color: #868e96;
    }
  }

  @media (max-width: 930px) {
    margin: 0;
  }
`;
export const ButtonGhostLong = styled(ButtonGhost)`
  padding: ${_ref28 => {
  let {
    padding
  } = _ref28;
  return padding || `${convertPXToVH(13)} ${convertPXToVW(90)}`;
}};
  width: ${_ref29 => {
  let {
    width
  } = _ref29;
  return width || '100%';
}};
`;
ButtonGhost.defaultProps = {
  padding: '19px 24px'
};