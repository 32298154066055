export const updateKindlePersistedProperties = (state) => {
  return ({ property, oldValues, defaultValue }) => {
    const hasProperty = Object.prototype.hasOwnProperty.call(
      state?.persistData || {},
      property
    );

    if (
      hasProperty &&
      oldValues.some((value) => state.persistData[property] === value)
    ) {
      state.persistData[property] = defaultValue;
    }
  };
};
