export const RESET_STATE = 'RESET_STATE';
export const SET_ONBOARDING = 'SET_ONBOARDING';
export const SET_OPEN_MENU = 'SET_OPEN_MENU';
export const SET_OPEN_CUSTOMIZATION = 'SET_OPEN_CUSTOMIZATION';
export const SET_OPEN_NOTES = 'SET_OPEN_NOTES';
export const SET_FONT_SIZE = 'SET_FONT_SIZE';
export const SET_VIDEO_TIME = 'SET_VIDEO_TIME';
export const SET_DEBUG_BLOCKED_FEATURES = 'SET_DEBUG_BLOCKED_FEATURES';
export const SET_SHOW_INAUGURAL_SUBJECT_TUTORIAL =
  'SET_SHOW_INAUGURAL_SUBJECT_TUTORIAL';
export const SET_COURSE_ENROLLMENT_ID = 'SET_COURSE_ENROLLMENT_ID';
export const SET_COURSE_ENROLLMENT_TYPE = 'SET_COURSE_ENROLLMENT_TYPE';
export const SET_COURSE_ID = 'SET_COURSE_ID';
export const SET_COURSE_VERSION_ID = 'SET_COURSE_VERSION_ID';
export const SET_ENTITIES_LABELS_DICTIONARY = 'SET_ENTITIES_LABELS_DICTIONARY';
export const SET_PERSIST_DATA = 'SET_PERSIST_DATA';
export const SET_ACCESS_INTERNSHIP_SUBJECT = 'SET_ACCESS_INTERNSHIP_SUBJECT';
export const SET_HAS_SHOWN_NOTES_ONBOARDING = 'SET_HAS_SHOWN_NOTES_ONBOARDING';
export const SET_HAS_SHOWN_SECTION_EDU_COMMUNICATION =
  'SET_HAS_SHOWN_SECTION_EDU_COMMUNICATION';
export const SET_OPEN_COMMUNITY_SECTION = 'SET_OPEN_COMMUNITY_SECTION';
export const SET_OPEN_TALK_TUTOR_FRAME = 'SET_OPEN_TALK_TUTOR_FRAME';

export const SET_READING_RULER = 'SET_READING_RULER';
