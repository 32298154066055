import React from 'react';
import { startTour } from '../../../../../components/tour';
import { useHandleTrack } from '../../section';
import {
  IUseNewFeatureProps,
  IUseNewFeatureReturn,
} from '../../../../communication/types';
import { onboardingIds } from '../../../utils';
import { useCreativeContext } from '../../../../communication/creatives/creatives-exhibition-controller/creatives-exhibition-controller';

export const useNewFeature = ({
  communicationKey,
  communicationDescription,
  localStorageItem,
  tourId,
  studentId,
  additionalConditionsToShowFeature = true,
  creativeType,
}: IUseNewFeatureProps): IUseNewFeatureReturn => {
  const [hasActiveTour, setHasActiveTour] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const { currentCreative, shouldDisplayCreative, markCurrentAsDone } =
    useCreativeContext();

  const { withoutTrack, loading: trackServiceLoading } = useHandleTrack({
    studentId: studentId,
    input: {
      key: communicationKey,
      desc: communicationDescription,
    },
  });

  const shouldShowFeature = React.useMemo(
    () =>
      !trackServiceLoading &&
      withoutTrack &&
      localStorage.getItem(localStorageItem) !== 'true' &&
      additionalConditionsToShowFeature,
    [
      trackServiceLoading,
      withoutTrack,
      localStorageItem,
      additionalConditionsToShowFeature,
    ]
  );

  const businessRules = () => shouldShowFeature;

  React.useEffect(() => {
    // Não concorre com os demais criativos, não precisa entrar na fila
    if (
      shouldShowFeature &&
      tourId === onboardingIds.newFeature.id.newChatSection
    ) {
      setIsVisible(true);
      startTour(tourId);
      return;
    }

    // Avalia a fila de criativos para verificar se deve ser exibido
    if (trackServiceLoading == null || trackServiceLoading || !creativeType)
      return;

    if (
      !shouldDisplayCreative({ type: creativeType, businessRules }) &&
      !hasActiveTour
    ) {
      markCurrentAsDone(creativeType);
      setIsVisible(false);
      return;
    }

    setIsVisible(true);
    startTour(tourId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCreative, trackServiceLoading, businessRules, hasActiveTour]);

  React.useEffect(() => {
    window.addEventListener('hasActiveTour', () => {
      setHasActiveTour(true);
    });

    return () => {
      window.removeEventListener('hasActiveTour', () => {
        setHasActiveTour(false);
      });
    };
  }, [shouldShowFeature]);

  return { hasActiveTour, setHasActiveTour, isVisible };
};
