import { useQuery } from '@ampli/services';

import * as queries from './queries';

type QueryResult = {
  data: string[];
  loading: boolean;
  error: boolean;
  refetch: () => void;
};

export const useCapabilitiesByStudentId = (options = {}): QueryResult => {
  const { data, loading, ...rest } = useQuery(
    queries.GET_CAPABILITIES_BY_STUDENT_ID,
    { ...options }
  );

  const capabilities = loading || !data ? [] : data?.data;

  return { data: capabilities, loading, ...rest };
};

export const useCapabilitiesByCourseEnrollmentId = (
  courseEnrollmentId: string,
  options = {}
): QueryResult => {
  const { data, loading, ...rest } = useQuery(
    queries.GET_CAPABILITIES_BY_COURSE_ENROLLMENT_ID,
    {
      ...options,
      skip: !courseEnrollmentId,
      variables: { course_enrollment_id: courseEnrollmentId },
    }
  );

  const capabilities = loading || !data ? [] : data?.data;

  return { data: capabilities, loading, ...rest };
};
