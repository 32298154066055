import { gql } from 'graphql.macro';

export const GET_PAYMENT_CREDIT_CARD_PUBLIC_KEY = gql`
  query GetPaymentCreditCardPublicKey($courseEnrollmentId: String!) {
    data: getPaymentCreditCardPublicKey(
      courseEnrollmentId: $courseEnrollmentId
    ) {
      id
      publicKey
      paymentClient
    }
  }
`;
