import styled from '@emotion/styled';
import {
  Button,
  Title,
  getColor,
  ColorFreeTrialBackground,
  ColorFreeTrialBorder,
  ColorFreeTrialButton,
  ColorNeutralDarker,
} from '@ampli/ui';
import {
  convertPXToREM,
  convertPXToVW,
  convertPXToVH,
  mediaQuery,
} from '@sofia/ui';
import { ArrowRight } from '@ampli/icon';

export const FreeTrialContainer = styled.div`
  background-color: ${getColor(ColorFreeTrialBackground)};
  border: ${convertPXToREM(2)} solid;
  border-color: ${getColor(ColorFreeTrialBorder)};
  border-radius: ${convertPXToREM(10)};
  margin: ${convertPXToVH(40)} auto ${convertPXToVH(40)} !important;
  width: 70%;

  ${mediaQuery('super')} {
    max-width: none;
    width: 30%;
  }

  ${mediaQuery('mobile')} {
    width: 90%;
  }

  ${mediaQuery('tablet')} {
    width: 92%;
  }
`;

export const FreeTrialTitle = styled(Title)`
  color: ${getColor(ColorNeutralDarker)};
  font-size: ${convertPXToREM(16)};
  font-weight: bold;
  margin: ${convertPXToVH(28)} ${convertPXToVW(35)};
  margin-bottom: 0 !important;
`;

export const FreeTrialText = styled.p`
  color: ${getColor(ColorNeutralDarker)};
  font-size: ${convertPXToREM(16)};
  margin: ${convertPXToVH(8)} ${convertPXToVW(35)};
  margin-bottom: 0 !important;
  line-height: 1.5;
`;

export const PayButton = styled(Button)`
  background-color: transparent;
  border: none;
  font-size: ${convertPXToREM(16)};
  font-weight: bold;
  color: ${getColor(ColorFreeTrialButton)};
  margin: ${convertPXToVH(10)} ${convertPXToVW(8)} !important;

  ${mediaQuery('tablet')} {
    margin: ${convertPXToVH(10)} ${convertPXToVW(22)} !important;
  }
`;

export const Arrow = styled(ArrowRight)`
  margin: ${convertPXToREM(2)};
`;
