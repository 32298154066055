import React from 'react';

import { SectionLayoutProps } from './types';

import * as Styled from './section-layout.styles';

const SectionLayout = ({
  topBar,
  children,
  leftMenus,
  rightMenus,
}: SectionLayoutProps): React.ReactElement => {
  return (
    <Styled.Column data-testid="section-layout">
      <Styled.Flex>
        {!!leftMenus && React.isValidElement(leftMenus) ? leftMenus : null}
        <Styled.Column>
          {!!topBar && React.isValidElement(topBar) ? topBar : null}
          {children}
        </Styled.Column>
        {!!rightMenus && React.isValidElement(rightMenus) ? rightMenus : null}
      </Styled.Flex>
    </Styled.Column>
  );
};

export default SectionLayout;
