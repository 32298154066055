import { DateTime } from '@ampli/utils';

const isValidDateTest = (options = {}) => ({
  name: 'isValidDateTest',
  message: 'Data inválida.',
  test: (value) => {
    const { format = 'dd/MM/yyyy' } = options;
    const filled = value && value.replace(/[^\d]+/g, '').length === 8;
    return filled && DateTime.fromFormat(value, format).isValid;
  },
});

export default isValidDateTest;
