import styled from '@emotion/styled';

export const BreadcrumbsContainer = styled.div`
  > div {
    padding: 0;
    height: auto;
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
  }
`;
