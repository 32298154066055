import React from 'react';
import { useHistory } from 'react-router-dom';
import { string, func, bool, shape } from 'prop-types';
import { useBreakpoint } from '@ampli/hooks';
import { Typography, SvgCheck, ButtonPrimary, convertPXToVH } from '@sofia/ui';
import EstadaoLogo from '../../assets/images/benefits/estadao-logo.png';
import SpotifyLogo from '../../assets/images/benefits/spotify-logo.png';
import { useTheme } from '@emotion/react';
import {
  Line,
  Container,
  BenefitCard,
  InfoContainer,
  BenefitContainer,
  Description,
  StyledUserIcon,
  StyledBookClosedIcon,
  ButtonContainer,
  Img,
  StyledModal,
} from './free-trial-expiration-modal.styles';

const FreeTrialExpirationModal = ({
  selectedCourseEnrollmentType,
  selectedCourseVersionId,
  handleModal,
  ...props
}) => {
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const theme = useTheme();

  const onButtonClick = () => {
    history.push(
      `/${selectedCourseEnrollmentType}/${selectedCourseVersionId}/pagamento?utm_source=ava-sofia&utm_medium=paywall`
    );
  };

  return (
    <StyledModal
      {...props}
      header={{
        headerText: 'Comece sua jornada conosco!',
        color: theme.colors.text.black,
        size: isDesktop ? 32 : 24,
      }}
      handleModal={handleModal}
      shouldCloseOnEsc={false}
      footer={
        <ButtonContainer>
          <ButtonPrimary
            background={theme.colors.button.primary}
            color={theme.colors.button.text.primary}
            width={'100%'}
            size={16}
            margin={`0 0 ${convertPXToVH(12)} 0`}
            onClick={onButtonClick}
          >
            Desbloquear conteúdo {''}
            <SvgCheck />
          </ButtonPrimary>
        </ButtonContainer>
      }
    >
      <InfoContainer>
        <StyledBookClosedIcon color={theme.colors.text.primary} />

        <Description>
          <Typography
            size={isDesktop ? 20 : 16}
            weight="700"
            color={theme.colors.text.black}
          >
            Conteúdos de aulas exclusivas
          </Typography>
          <Typography size={16} color={theme.colors.text.lightBlack}>
            Acesso a todas as aulas, provas e outras atividades do período
            letivo.
          </Typography>
        </Description>
      </InfoContainer>
      <InfoContainer>
        <StyledUserIcon color={theme.colors.text.primary} />
        <Description>
          <Typography
            size={isDesktop ? 20 : 16}
            weight="700"
            color={theme.colors.text.black}
          >
            Comprovante de matrícula
          </Typography>
          <Typography size={16} color={theme.colors.text.lightBlack}>
            Estudante pra valer! Direito a meia entrada e outros benefícios que
            só o estudante tem.
          </Typography>
        </Description>
      </InfoContainer>
      <Container>
        <Line />
        <Typography
          size={14}
          textAlign="center"
          weight="700"
          color={theme.colors.text.lightBlack}
        >
          Você ganha também
        </Typography>
        <Line />
      </Container>
      <BenefitContainer>
        <BenefitCard>
          <Img aria-hidden={true} tabIndex={-1} src={SpotifyLogo} />
          <Typography size={16} color={theme.colors.text.lightBlack}>
            Desconto no Premium do Spotify, que sai por apenas R$ 8,50.
          </Typography>
        </BenefitCard>
        <BenefitCard>
          <Img aria-hidden={true} tabIndex={-1} src={EstadaoLogo} />
          <Typography size={16} color={theme.colors.text.lightBlack}>
            Informação à vontade. Todo conteúdo exclusivo do Estadão.
          </Typography>
        </BenefitCard>
      </BenefitContainer>
    </StyledModal>
  );
};

FreeTrialExpirationModal.propTypes = {
  selectedCourseEnrollmentType: string,
  selectedCourseVersionId: string,
  handleModal: shape({
    state: bool,
    hide: func,
  }).isRequired,
};

export default FreeTrialExpirationModal;
