import { useTheme } from '@emotion/react';
import { Card, Typography, Tag } from '@sofia/ui';
import { oneOf } from 'prop-types';
import React from 'react';
import * as Styled from './mandatory-internship-subject-header-situation-card.styles';
import { useBreakpoint } from '@ampli/hooks';
import {
  INTERNSHIP_STATUS,
  internshipStatusTheme,
} from '../../../../../constants';

const MandatoryInternshipSubjectHeaderSituationCard = ({ status }) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const statusTheme = internshipStatusTheme(theme);

  return (
    <Styled.CardContainer>
      <Card
        id="mandatory-internship-subject-header-situation-card"
        borderColor={theme.colors.card.border.primary}
        aria-label={`Situação do termo de compromisso: ${INTERNSHIP_STATUS[status]}`}
        tabIndex={-1}
        onClick={null}
      >
        <Styled.FlexSpaceBetween>
          <Styled.Flex>
            <Typography
              as="span"
              color={theme.colors.text.black}
              size={isDesktop ? 20 : 16}
              id="Situação do termo de compromisso"
            >
              Situação do termo de compromisso
            </Typography>
          </Styled.Flex>
          <Typography
            as="span"
            color={theme.colors.text.lighBlack}
            size={isDesktop ? 20 : 16}
            weight={700}
            id={status}
          >
            {isDesktop ? (
              INTERNSHIP_STATUS[status]
            ) : (
              <Tag
                theme={statusTheme[status]}
                text={INTERNSHIP_STATUS[status]}
              />
            )}
          </Typography>
        </Styled.FlexSpaceBetween>
      </Card>
    </Styled.CardContainer>
  );
};

MandatoryInternshipSubjectHeaderSituationCard.propTypes = {
  status: oneOf(Object.keys(INTERNSHIP_STATUS)).isRequired,
};

export default MandatoryInternshipSubjectHeaderSituationCard;
