import { forEach, cloneDeepWith, some, map } from 'lodash';
export const omitDeep = (collection, excludeKeys) => {
  const omitFn = value => {
    if (value && typeof value === 'object') {
      forEach(excludeKeys, key => {
        delete value[key];
      });
    }
  };
  return cloneDeepWith(collection, omitFn);
};

// WARNING: the omitObject will be converted to a string, then the functions
// contained in the object will be lost.
export const fastOmitDeep = function (omitObject) {
  let omitProperties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return JSON.parse(JSON.stringify(omitObject, (key, value) => some(map(omitProperties, propertyKey => propertyKey.test ? propertyKey.test(key) : propertyKey === key)) ? undefined : value));
};