import { lazy } from 'react';

export const CommunityPage = lazy(
  () => import(/* webpackChunkName: "Community" */ '../pages/community-page')
);

export const SectionPublicationsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Community" */ '../pages/section-publications-page/section-publications-page.presentation'
    )
);

export const PublicationDetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Community" */ '../pages/publication-details-page/publication-details-page.presentation'
    )
);

const CommunityModule = lazy(
  () => import(/* webpackChunkName: "Community module" */ './module')
);

export default CommunityModule;
