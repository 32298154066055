import React from 'react';
import SlidingCore from './core/sliding-core';

import { useSliding } from './sliding.hook';

const Sliding: React.FC = () => {
  const {
    hasSlidingAvailable,
    handlerPopup,
    onCallToAction,
    sliding,
    isSlidingVisible,
  } = useSliding();

  if (!isSlidingVisible || !hasSlidingAvailable) return null;

  return (
    <SlidingCore
      onCtaClick={onCallToAction}
      handlePopUp={handlerPopup}
      headerText={sliding.title}
      category={sliding.categoryType}
      bodyText={sliding.body}
      footer={{ buttonText: sliding.ctaText, buttonLink: sliding.ctaLink }}
      image={sliding.image}
    />
  );
};

export default Sliding;
