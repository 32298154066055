import styled from '@emotion/styled';
import { SIZES } from './helpers';

export const Wrapper = {
  Content: styled.div`
    margin-bottom: ${SIZES.VH_24};
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: ${SIZES.VH_16};
  `,
  Item: styled.li`
    display: flex;
    justify-content: space-between;
    gap: ${SIZES.VH_10};
    padding: ${SIZES.VH_16} 0;
    cursor: pointer;
    &:hover {
      p {
        color: ${({ theme }) => theme.colors.text.primary};
      }
      svg > path {
        fill: ${({ theme }) => theme.colors.text.primary};
      }
    }
  `,
};
