import { filter } from '@ampli/utils';

import { paymentQueryParams } from '../constants';
import { useQueryParams } from '../hooks';

const useFilterInstalments = (instalments) => {
  const queryParams = useQueryParams();
  const instalmentNumbers = queryParams
    .get(paymentQueryParams.INSTALMENTS)
    .split(',');

  return filter(instalments, ({ instalmentNumber }) =>
    instalmentNumbers.includes(`${instalmentNumber}`)
  );
};

export default useFilterInstalments;
