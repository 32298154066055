import React from 'react';

const ImageHandsOnNotebookSparkle = ({
  width = 88,
  height = 81,
  title = '',
  desc = '',
  ...props
}: {
  width?: number;
  height?: number;
  title?: string;
  desc?: string;
}): React.ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(title || desc
        ? { 'aria-labelledby': `${title || ''} ${desc || ''}` }
        : {})}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.1436 7.10642C76.6309 -5.83354 81.0781 16.6953 76.6309 45.9045C74.9543 56.91 66.1477 64.5051 55.1436 65.2275C47.8836 65.7027 40.1091 66.2884 33.1485 64.3359C19.4748 60.4993 13.7221 49.9364 15.0178 36.9166C16.5024 21.9833 38.9696 10.4875 55.1436 7.10642Z"
        fill="#ACB1FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.9348 29.1117C65.0241 29.8507 63.9767 27.3462 63.2026 27.7066C59.8693 29.2531 51.2492 30.1244 46.2949 33.9839C45.5116 42.3049 41.2585 57.8977 40.5117 63.1303L46.4634 63.7188L63.658 61.0181C63.5487 57.7928 66.8455 29.4903 65.9348 29.1117Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.2629 28.8594C62.6419 29.7772 55.8864 29.7405 49.017 32.9896C47.4125 43.5447 47.3487 53.7464 45.7441 64.3014L63.1615 61.6443C62.4368 57.872 68.1757 27.859 65.2629 28.8594Z"
        fill="#C5D2E0"
      />
      <path
        d="M15.8014 42.208L1.55664 55.8713L15.2199 76.5116L23.6505 66.0461L15.8014 42.208Z"
        fill="#004ECC"
      />
      <path
        d="M74.5245 79.9999L68.1289 72.1507L78.013 51.2197L86.4435 59.3596L81.5015 70.9879L74.5245 79.9999Z"
        fill="#004ECC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.2951 51.4806C51.7131 50.7386 45.707 55.2626 51.7085 58.1127C49.8275 58.0765 43.1592 64.4191 58.7999 64.3512C62.5802 66.3327 64.967 68.2599 68.3012 71.8564C69.943 69.789 75.9905 62.4738 78.3038 51.6615C74.0406 46.4138 68.7887 42.5548 65.3901 41.6636C65.2982 42.8082 64.9303 44.5589 64.8429 45.7804C49.3769 42.9756 49.0779 48.065 53.2951 51.4806ZM39.4387 37.4563C42.5107 38.2978 44.2123 39.8721 40.9609 45.2285C43.012 46.3821 44.732 52.4125 38.6155 52.7789C40.455 58.9089 29.5005 67.7396 27.3574 61.1799C26.2123 62.6864 24.4923 64.6091 23.1633 66.102C21.8572 64.1205 16.265 53.4756 15.5107 42.3784C20.873 36.9497 32.3196 29.7566 43.5639 31.3942C47.0774 31.9055 43.3707 35.5472 39.4387 37.4563Z"
        fill="white"
      />
      <path
        d="M63.5049 58.9916C50.3724 53.3637 41.6022 64.4059 56.204 64.2831C60.4979 64.2467 64.8465 68.4427 68.1821 72.1204C69.3548 73.416 72.7999 78.3074 74.4426 79.9304M77.8649 51.8453C75.5058 63.2103 69.2818 70.4838 67.9357 72.2568M84.2806 57.3596C83.5277 69.2746 79.065 75.0207 74.1232 79.9985M65.4534 41.8578C69.8476 42.5352 78.5722 52.359 86.4435 59.3053M71.6592 49.8042C65.3576 42.8216 44.9926 43.3444 52.7407 51.2089M67.7988 54.3911C49.0081 45.2264 46.179 57.205 51.7642 58.0279M57.0071 32.1158C58.2164 32.1158 58.8734 28.8564 57.0071 27.779C54.7256 26.447 51.7733 28.129 53.2472 29.9156M62.7384 30.7429C63.9476 30.7429 64.6047 27.4835 62.7384 26.4061C60.4568 25.0741 57.5045 26.7561 58.9784 28.5427M63.724 26.897C55.0952 28.9246 46.1653 32.9705 46.0786 33.4705C44.9287 40.1576 41.2736 59.7781 40.1466 62.6829M48.8849 33.8933C48.132 35.7844 47.7532 53.1318 46.8634 55.423C46.4071 56.5777 46.1333 65.2332 46.1333 63.3648M41.0592 52.2045C41.4288 53.1773 41.8167 54.1411 42.2045 55.1048M51.0843 34.057C52.2935 34.057 52.9506 30.7975 51.0843 29.7201C48.8027 28.3881 45.8504 30.0701 47.3243 31.8567M15.8664 42.2033C16.5524 50.5291 19.1101 58.5943 23.3499 65.8015M29.5922 51.9908C45.8915 43.7444 37.6734 67.0289 28.2004 62.8557C26.1927 61.9692 32.0425 56.3095 29.1131 59.5098C27.0003 61.8237 24.8374 64.5604 23.2769 66.3288C21.9673 67.829 17.9198 73.3296 16.1722 75.2844L15.2199 76.51C11.9688 71.6213 8.53364 62.8557 6.78939 55.1048L5.99917 51.9908M26.0649 47.663C43.3635 36.8755 46.4117 52.7136 38.9647 52.8682M22.9346 43.2898C29.4233 37.0618 48.3601 33.9524 41.0044 45.2946M38.4445 37.6619C43.5963 35.7526 49.186 30.5293 40.6759 31.0748C22.7452 32.2211 13.216 44.8598 5.99917 51.9908M1.55664 55.6867C2.96988 54.8317 4.42986 53.5415 5.99917 51.9908"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.792 1.67237L51.4066 3.30032C51.3213 3.66017 51.094 3.96787 50.7787 4.15023L48.9346 5.21654L50.7787 6.28286C51.094 6.46521 51.3213 6.77291 51.4066 7.13277L51.792 8.76071L52.1775 7.13277C52.2627 6.77291 52.4901 6.46521 52.8054 6.28286L54.6495 5.21654L52.8054 4.15023C52.4901 3.96787 52.2627 3.66017 52.1775 3.30032L51.792 1.67237ZM52.3012 0.408414C52.172 -0.13744 51.4121 -0.13744 51.2829 0.408414L50.6428 3.11151C50.6087 3.25545 50.5178 3.37853 50.3916 3.45147L48.1448 4.7507C47.7903 4.95567 47.7903 5.47741 48.1448 5.68238L50.3916 6.98161C50.5178 7.05455 50.6087 7.17763 50.6428 7.32158L51.2829 10.0247C51.4121 10.5705 52.172 10.5705 52.3012 10.0247L52.9413 7.32158C52.9754 7.17763 53.0663 7.05455 53.1925 6.98161L55.4393 5.68238C55.7938 5.47741 55.7938 4.95567 55.4393 4.7507L53.1925 3.45147C53.0663 3.37853 52.9754 3.25545 52.9413 3.11151L52.3012 0.408414Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8923 15.2441L23.3427 17.3865C23.2212 17.8601 22.8972 18.265 22.4476 18.505L19.8185 19.9082L22.4476 21.3115C22.8972 21.5515 23.2212 21.9564 23.3427 22.43L23.8923 24.5724L24.4419 22.43C24.5634 21.9564 24.8875 21.5515 25.3371 21.3115L27.9661 19.9082L25.3371 18.505C24.8875 18.265 24.5634 17.8601 24.4419 17.3865L23.8923 15.2441ZM24.6182 13.5808C24.434 12.8624 23.3507 12.8624 23.1664 13.5808L22.2538 17.138C22.2053 17.3275 22.0756 17.4894 21.8958 17.5854L18.6925 19.2952C18.1871 19.5649 18.1871 20.2516 18.6925 20.5213L21.8958 22.2311C22.0756 22.3271 22.2053 22.489 22.2538 22.6785L23.1664 26.2358C23.3507 26.9541 24.434 26.9541 24.6182 26.2358L25.5308 22.6785C25.5794 22.489 25.709 22.3271 25.8888 22.2311L29.0921 20.5213C29.5975 20.2516 29.5975 19.5649 29.0921 19.2952L25.8888 17.5854C25.709 17.4894 25.5794 17.3275 25.5308 17.138L24.6182 13.5808Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.0108 22.5415L76.6253 24.1695C76.5401 24.5293 76.3128 24.837 75.9974 25.0194L74.1533 26.0857L75.9974 27.152C76.3128 27.3343 76.5401 27.6421 76.6253 28.0019L77.0108 29.6299L77.3963 28.0019C77.4815 27.6421 77.7088 27.3343 78.0242 27.152L79.8682 26.0857L78.0242 25.0194C77.7088 24.837 77.4815 24.5293 77.3963 24.1695L77.0108 22.5415ZM77.52 21.2776C77.3907 20.7317 76.6309 20.7317 76.5016 21.2776L75.8615 23.9806C75.8275 24.1246 75.7365 24.2477 75.6104 24.3206L73.3635 25.6198C73.009 25.8248 73.009 26.3466 73.3635 26.5515L75.6104 27.8508C75.7365 27.9237 75.8275 28.0468 75.8615 28.1907L76.5016 30.8938C76.6309 31.4397 77.3907 31.4397 77.52 30.8938L78.1601 28.1907C78.1941 28.0468 78.2851 27.9237 78.4112 27.8508L80.6581 26.5515C81.0125 26.3466 81.0125 25.8248 80.6581 25.6198L78.4112 24.3206C78.2851 24.2477 78.1941 24.1246 78.1601 23.9806L77.52 21.2776Z"
        fill="black"
      />
    </svg>
  );
};

export default ImageHandsOnNotebookSparkle;
