import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  GET_ACADEMIC_STAFF,
  useFindStudentCourseEnrollmentsWithFreeTrialInfo,
  useListAvailableExams,
  useServiceClient,
} from '@ampli/services';

import { filterFreeTrialDataFromByEnrollmentId } from '../utils';
import { useGetState } from '../state';

import logout from '../lib/logout';
import { useMeContext } from '../modules/shared/contexts/me-context';
import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';

const useHomePageData = () => {
  const history = useHistory();
  const client = useServiceClient('privateClient');

  const [
    {
      selectedCourseEnrollmentId,
      selectedCourseEnrollmentType,
      selectedCourseVersionId,
    },
  ] = useGetState();

  const { states: statesMe } = useMeContext();
  const { me, loading: meLoading, error: meError } = statesMe.getMe;

  const { states } = useCourseEnrollmentsContext();
  const {
    loading: courseEnrollmentLoading,
    data: courseEnrollment,
    error: courseEnrollmentError,
    client: courseEnrollmentClient,
  } = states.courseEnrollments;

  const course = React.useMemo(
    () => courseEnrollment?.course || {},
    [courseEnrollment?.course]
  );

  const blockCourseEnrollmentContentAccess = React.useMemo(
    () =>
      !courseEnrollment?.roles?.includes(
        'ALLOW_COURSE_ENROLLMENT_CONTENT_ACCESS'
      ),
    [courseEnrollment?.roles]
  );

  const enrollmentsToBeTakenByDate =
    courseEnrollment?.subjectEnrollmentsInProgress?.filter((enrollment) => {
      const todayDate = new Date().toISOString();
      const startChoiceDate = new Date(
        enrollment.startDateForChoice
      ).toISOString();
      const endChoiceDate = new Date(enrollment.endDateForChoice).toISOString();

      return (
        (todayDate < new Date(enrollment.startDate).toISOString() &&
          !startChoiceDate &&
          !endChoiceDate) ||
        (todayDate < startChoiceDate && todayDate > endChoiceDate)
      );
    });

  const enrollmentsToBeTakenByStatus =
    courseEnrollment?.subjectEnrollmentsInProgress?.filter((enrollment) => {
      return enrollment.status === 'AWAITING_ENROLLMENT';
    });

  const enrollmentsToBeTaken = enrollmentsToBeTakenByDate
    ? [...enrollmentsToBeTakenByDate, ...enrollmentsToBeTakenByStatus]
    : [];

  const subjectEnrollmentsInProgress =
    courseEnrollment?.subjectEnrollmentsInProgress
      ? courseEnrollment?.subjectEnrollmentsInProgress.filter(
          (item) => !enrollmentsToBeTaken.includes(item)
        )
      : [];

  const {
    data: availableExams,
    loading: availableExamsLoading,
    error: availableExamsError,
  } = useListAvailableExams(courseEnrollment?.id);

  const { data: studentFreeTrialData, loading: studentFreeTrialDataLoading } =
    useFindStudentCourseEnrollmentsWithFreeTrialInfo(
      courseEnrollment?.studentId
    );

  const loading = React.useMemo(
    () =>
      meLoading ||
      availableExamsLoading ||
      courseEnrollmentLoading ||
      studentFreeTrialDataLoading,
    [
      meLoading,
      availableExamsLoading,
      courseEnrollmentLoading,
      studentFreeTrialDataLoading,
    ]
  );

  const error = React.useMemo(
    () => Boolean(meError || availableExamsError || courseEnrollmentError),
    [meError, availableExamsError, courseEnrollmentError]
  );

  const studentFreeTrialCourseData = React.useMemo(
    () =>
      filterFreeTrialDataFromByEnrollmentId(
        studentFreeTrialData?.data,
        courseEnrollment?.id
      ),
    [courseEnrollment?.id, studentFreeTrialData?.data]
  );

  const handleLogout = React.useCallback(
    () => logout({ client, history }),
    [client, history]
  );

  const prefetchTutorData = React.useCallback(
    () =>
      course?.id &&
      courseEnrollmentClient.query({
        query: GET_ACADEMIC_STAFF,
        variables: {
          courseEnrollmentId: selectedCourseEnrollmentId,
          courseId: course?.id,
        },
      }),
    [course?.id, courseEnrollmentClient, selectedCourseEnrollmentId]
  );

  React.useLayoutEffect(() => {
    prefetchTutorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    subjectEnrollmentAvailableExams:
      availableExams?.subjectEnrollmentAvailableExams,
    loading,
    courseEnrollmentLoading,
    me,
    error,
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
    course,
    courseEnrollment,
    blockCourseEnrollmentContentAccess,
    subjectEnrollmentsInProgress,
    handleLogout,
    studentFreeTrialCourseData,
    courseEnrollmentClient,
  };
};

export default useHomePageData;
