import React from 'react';
import { Alerts, SvgInfoCircle } from '@sofia/ui';
import { Wrapper } from './card-alert.styles';
import { ICardAlertProps } from './card-alert.types';
import { useTheme } from '@emotion/react';

const CardAlert: React.FC<ICardAlertProps> = ({ message }: ICardAlertProps) => {
  const theme = useTheme();

  const optionsThemeAlert = {
    color: theme.colors.text.black,
    background: theme.colors.alerts.background.primary,
  };

  return (
    <Alerts
      iconLeft={
        <Wrapper.Svg>
          <SvgInfoCircle fill={theme.colors.text.primary} />
        </Wrapper.Svg>
      }
      text={message}
      theme={optionsThemeAlert}
    />
  );
};

export default CardAlert;
