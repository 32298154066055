import React from 'react';

import { Typography } from '@sofia/ui';
import { ArrowLeft } from '@ampli/icon';
import { useTheme } from '@emotion/react';

import { BackButtonWrapper } from './back-button.styles';
import { usePtkFrame } from '../../contexts';
import { PtkPages } from '../../contexts/ptk-frame-state';

interface BackButtonProps {
  page?: PtkPages;
  callback?: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({
  page,
  callback,
}: BackButtonProps) => {
  const theme = useTheme();

  const { onBack } = usePtkFrame();

  return (
    <BackButtonWrapper
      onClick={() => onBack(page, callback)}
      type="button"
      id="go-back-button"
    >
      <ArrowLeft
        currentColor={theme.colors.text.black}
        height="18px"
        width="18px"
        id="go-back-icon"
      />

      <Typography
        aria-label="Voltar para etapa anterior."
        margin="0px 8px"
        weight={700}
        color={theme.colors.text.black}
        size={14}
        id="go-back-text"
      >
        Voltar
      </Typography>
    </BackButtonWrapper>
  );
};

export default BackButton;
