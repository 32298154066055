import { shape, string, number, func, bool, arrayOf, node } from 'prop-types';

export const childNodeStyle = `
table {
  display: block;
  max-width: 100%!important;
  border-spacing: 10px 0;
  margin-top: 0.5em;
  tbody {
    display: block;
    overflow-x: auto;
  }
  td {
    width: fit-content;
    min-width: min(50vw, 200px);
    max-width: 50vw;
  }

  th,td {
    border: solid 1px;
    padding: 10px;
  }
  th {
    font-weight: bold;
  }
}
`;

export const answerTagTheme = (theme) => ({
  CORRECT: {
    text: 'Correta',
    theme: {
      color: theme.colors.text.black,
      background: theme.colors.tag.lightSuccess,
    },
  },
  INCORRECT: {
    text: 'Incorreta',
    theme: {
      color: theme.colors.text.white,
      background: theme.colors.tag.darkDanger,
    },
  },
});

export const cardChoiceTheme = (theme) => ({
  CORRECT: {
    borderColor: theme.colors.card.border.success,
    background: theme.colors.card.background.success,
    hoverBorderColor: theme.colors.card.border.success,
  },
  INCORRECT: {
    borderColor: theme.colors.card.border.danger,
    background: theme.colors.card.background.danger,
    hoverBorderColor: theme.colors.card.border.danger,
  },
});

export const footerButtonsLabel = (step, numberOfQuestions) => ({
  ariaLabelNextButton: `Próxima questão. Ir para ${Math.min(
    step + 2,
    numberOfQuestions
  )} de ${numberOfQuestions}`,
  ariaLabelPreviousButton: `Questão Anterior. Ir para ${step} de ${numberOfQuestions}`,
  lastStepLabel: 'Concluir',
});

export const quizCorrectionProps = {
  quiz: shape({
    id: string.isRequired,
    subject: shape({
      name: string.isRequired,
    }).isRequired,
    questions: arrayOf(
      shape({
        id: string.isRequired,
        baseText: string.isRequired,
        statement: string.isRequired,
        choices: arrayOf(
          shape({
            id: string.isRequired,
            description: string.isRequired,
            feedback: string.isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired
    ).isRequired,
    answerSheet: shape({
      answers: arrayOf(
        shape({
          questionId: string.isRequired,
          choiceId: string,
          isCorrect: bool.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
  attempt: shape({
    grade: number.isRequired,
  }).isRequired,
  assignment: shape({
    config: shape({
      weight: number.isRequired,
    }).isRequired,
  }).isRequired,
  stepsControl: shape({
    step: number.isRequired,
    toStep: func.isRequired,
    isLastStep: bool.isRequired,
    isFirstStep: bool.isRequired,
    previousStep: func.isRequired,
    nextStep: func.isRequired,
  }).isRequired,
  fontSize: string,
  onFontSizeClick: func.isRequired,
  onHelpClick: func.isRequired,
  courseType: string.isRequired,
  onFinishQuizCorrectionClick: func.isRequired,
  onCloseQuizCorrectionClick: func.isRequired,
  children: node.isRequired,
  isResponsive: bool,
  talkTutorFrame: node,
};
