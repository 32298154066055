import * as Logos from '../../../assets/logos';

export const host = window.location.hostname;

export type AffiliateBrands =
  | 'BRAND_AMPLI'
  | 'BRAND_ANHANGUERA'
  | 'BRAND_PITAGORAS'
  | 'BRAND_UNIC'
  | 'BRAND_UNIDERP'
  | 'BRAND_UNIME'
  | 'BRAND_UNOPAR';

export type BrandType =
  | 'ampli'
  | 'anhanguera'
  | 'pitágoras'
  | 'pitagoras'
  | 'unic'
  | 'uniderp'
  | 'unime'
  | 'unopar';

export const brandsUrls = {
  'ava.ampli.com.br': 'ampli',
  'www.anhanguera.com': 'anhanguera',
  'www.pitagoras.com.br': 'pitagoras',
  'www.unic.com.br': 'unic',
  'www.uniderp.com.br': 'uniderp',
  'www.unime.edu.br': 'unime',
  'www.unopar.com.br': 'unopar',
};

export const ACTIVE_BRAND =
  brandsUrls[host as keyof typeof brandsUrls] || 'ampli';

export const brandName =
  ACTIVE_BRAND === 'pitagoras'
    ? 'Pitágoras'
    : ACTIVE_BRAND.charAt(0).toUpperCase() +
      ACTIVE_BRAND.slice(1).toLowerCase();

export const brandInfo = {
  ampli: {
    hostname: 'ava.ampli.com.br',
    registerLink: 'https://www.ampli.com.br',
    brand: 'BRAND_AMPLI',
    loginUrl: '',
    leadUrl:
      'https://www.anhanguera.com/area-do-candidato/login?utm_source=lp_ampli&utm_medium=cta_header&utm_campaign=acompanhar_inscricao',
    logo: Logos.ampli,
    whatsappPhone: '5511945356813',
  },
  anhanguera: {
    hostname: 'www.anhanguera.com',
    registerLink: 'https://' + host,
    brand: 'BRAND_ANHANGUERA',
    loginUrl: 'https://login.anhanguera.com/',
    leadUrl: 'https://www.anhanguera.com/area-do-candidato/login',
    logo: Logos.anhanguera,
    whatsappPhone: '5511913481040',
  },
  unopar: {
    hostname: 'www.unopar.com.br',
    registerLink: 'https://' + host,
    brand: 'BRAND_UNOPAR',
    loginUrl: 'https://login.unopar.br/',
    leadUrl: 'https://www.unopar.com.br/area-do-candidato/login',
    logo: Logos.unopar,
    whatsappPhone: '5511913481035',
  },
  unic: {
    hostname: 'www.unic.com.br',
    registerLink: 'https://' + host,
    brand: 'BRAND_UNIC',
    loginUrl: 'https://login.unic.br/',
    leadUrl: 'https://www.unic.com.br/area-do-candidato/login',
    logo: Logos.unic,
    whatsappPhone: '5511913140288',
  },
  unime: {
    hostname: 'www.unime.edu.br',
    registerLink: 'https://' + host,
    brand: 'BRAND_UNIME',
    loginUrl: 'https://login.unime.edu.br/',
    leadUrl: 'https://www.unime.edu.br/area-do-candidato/login',
    logo: Logos.unime,
    whatsappPhone: '5511913134318',
  },
  pitagoras: {
    hostname: 'www.pitagoras.com.br',
    registerLink: 'https://' + host,
    brand: 'BRAND_PITAGORAS',
    loginUrl: 'https://login.faculdadepitagoras.com.br/',
    leadUrl: 'https://www.pitagoras.com.br/area-do-candidato/login',
    logo: Logos.pitagoras,
    whatsappPhone: '5511913140273',
  },
  uniderp: {
    hostname: 'www.uniderp.com.br',
    registerLink: 'https://' + host,
    brand: 'BRAND_UNIDERP',
    loginUrl: 'https://login.uniderp.br/',
    leadUrl: 'https://www.uniderp.com.br/area-do-candidato/login',
    logo: Logos.uniderp,
    whatsappPhone: '5511913140306',
  },
};

export const brandsInfos = brandInfo[ACTIVE_BRAND as keyof typeof brandInfo];
