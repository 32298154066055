import React from 'react';

const ImageWomanSendingWorkOnComputer = () => {
  return (
    <svg
      width="230"
      height="193"
      viewBox="0 0 230 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.94 64.5452C196.395 34.4079 180.157 39.6071 166.62 38.236C155.978 37.1363 158.922 30.3084 155.616 24.3158C155.342 23.8149 155.01 23.3478 154.628 22.9237C155.306 22.2991 155.818 21.5158 156.118 20.6443C156.419 19.7728 156.498 18.8404 156.35 17.9306C156.201 17.0208 155.829 16.1621 155.266 15.4317C154.704 14.7013 153.969 14.1219 153.127 13.7457C152.286 13.3694 151.364 13.208 150.444 13.2759C149.525 13.3438 148.637 13.6389 147.86 14.1347C147.083 14.6306 146.441 15.3116 145.992 16.1167C145.543 16.9219 145.301 17.8259 145.287 18.7477C140.248 18.4206 135.355 20.8357 135.271 28.1438C137.436 28.1438 140.958 24.9631 141.536 20.7522C143.429 26.6265 151.739 27.5801 152.268 29.2018C152.672 33.2595 153.368 41.73 157.586 48.0985C169.926 46.7273 177.241 57.4459 197.94 64.5452Z"
        fill="#212529"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.378 46.3391C157.677 44.787 155.199 39.8384 153.898 37.7365C152.394 35.2935 152.888 29.1686 151.977 28.6883C149.889 27.6582 144.195 26.9831 141.439 20.7607C140.27 28.1245 134.013 28.5004 134.911 28.2985C134.681 29.955 136.233 35.3631 136.01 35.5579C130.192 40.7223 129.05 42.358 137.653 40.4996C138.739 43.8544 139.525 46.1512 140.256 49.1928C144.655 53.3132 156.835 49.0536 157.378 46.3391Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.008 47.58C192.24 40.3415 219.335 79.1093 221.876 118.204C223.219 138.89 213.524 157.181 197.634 162.29C197.651 166.741 197.311 171.186 196.617 175.583C195.406 187.151 133.573 175.145 111.774 177.818C92.9327 180.128 73.688 158.218 74.8851 152.149C80.6064 123.139 77.4882 59.1477 148.008 47.58Z"
        fill="#B1C2D5"
      />
      <path
        d="M157.112 43.7789C155.971 39.7211 153.04 38.9068 152.351 29.8587C152.094 26.5039 144.445 28.4666 141.709 20.894C141.222 19.502 140.839 27.5966 135.174 28.2509"
        stroke="#212529"
        strokeWidth="1.4825"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.963 29.786C145.046 29.1262 144.665 28.535 144.112 28.4656C143.559 28.3963 143.043 28.8749 142.96 29.5348C142.878 30.1946 143.259 30.7857 143.812 30.8551C144.365 30.9245 144.88 30.4458 144.963 29.786Z"
        fill="#212529"
      />
      <path
        d="M137.962 30.6538C138.02 30.1885 137.752 29.7717 137.363 29.7228C136.974 29.6739 136.612 30.0115 136.553 30.4768C136.495 30.9421 136.763 31.359 137.152 31.4078C137.541 31.4567 137.903 31.1191 137.962 30.6538Z"
        fill="#212529"
      />
      <path
        d="M148.149 66.4096C148.502 66.2398 148.624 65.7595 148.421 65.3368C148.218 64.9141 147.767 64.7091 147.413 64.879C147.06 65.0488 146.938 65.5292 147.141 65.9519C147.344 66.3745 147.796 66.5795 148.149 66.4096Z"
        fill="#212529"
      />
      <path
        d="M143.423 79.1543C143.815 79.1543 144.133 78.7742 144.133 78.3052C144.133 77.8362 143.815 77.4561 143.423 77.4561C143.031 77.4561 142.713 77.8362 142.713 78.3052C142.713 78.7742 143.031 79.1543 143.423 79.1543Z"
        fill="#212529"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.4507 100.429H13.7966C9.92674 100.429 7.66471 103.596 8.75744 107.459L23.1997 158.316C24.2994 162.186 28.3571 165.346 32.2478 165.346H106.846C110.716 165.346 112.985 162.179 111.885 158.316L97.471 107.431C96.3713 103.561 92.3136 100.401 88.4229 100.401L88.4507 100.429ZM138.738 165.666H31.5518C31.1182 165.646 30.685 165.715 30.2791 165.869C29.8732 166.023 29.5033 166.259 29.1921 166.562C28.8809 166.864 28.6351 167.228 28.47 167.629C28.3048 168.031 28.2238 168.462 28.2318 168.896C28.3126 169.797 28.7312 170.635 29.4038 171.24C30.0764 171.846 30.9532 172.174 31.8581 172.16H139.044C139.477 172.18 139.911 172.111 140.316 171.957C140.722 171.803 141.092 171.567 141.403 171.265C141.715 170.962 141.96 170.599 142.126 170.197C142.291 169.796 142.372 169.365 142.364 168.931C142.283 168.029 141.864 167.192 141.192 166.586C140.519 165.981 139.642 165.652 138.738 165.666Z"
        fill="#EBF0F5"
        stroke="#212529"
        strokeMiterlimit="22.93"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.769 151.401C113.236 152.187 111.203 162.537 119.653 161.347C117.53 163.24 116.103 171.091 133.963 166.873C140.227 165.224 148.28 163.609 155.734 164.249C155.4 160.226 154.488 145.749 145.767 131.161C135.55 129.609 125.687 130.646 120.975 133.131C118.191 134.286 118.56 137.307 119.716 138.796C109.025 142.77 110.528 151.095 115.769 151.401Z"
        fill="white"
      />
      <path
        d="M142.023 40.6912C146.36 40.7887 148.127 37.0024 148.747 32.0677M135.523 52.948C134.892 55.1497 134.452 57.4014 134.207 59.6784C135.258 58.6622 145.594 53.2403 145.636 53.3169C151.204 63.3673 152.839 64.5087 159.194 52.2241C159.353 51.9936 159.455 51.729 159.493 51.4516M145.775 131.493C155.018 146.68 155.693 161.317 156.02 164.679M137.186 144.87C105.587 147.487 112.77 163.976 119.723 161.435M137.054 136.553C122.646 133.456 103.945 144.905 115.283 151.287M137.2 154.461C114.044 156.598 112.018 172.947 134.082 166.989C140.409 165.291 148.587 163.593 156.145 164.205C158.804 164.435 167.762 166.328 171.284 166.44C185.413 166.864 195.985 162.389 197.551 161.199M146.116 53.9363C146.116 65.0725 131.876 91.2147 132.272 103.096C132.648 114.441 124.463 122.73 121.094 132.718M106.931 85.5213C105.232 90.0871 98.7942 114.997 101.188 118.93M186.276 82.7373C184.578 87.3031 178.139 112.213 180.534 116.146M119.619 138.195C116.334 131.931 128.361 128.695 137.43 130.839C140.388 131.535 143.45 130.887 145.991 130.929C158.421 131.117 172.049 131.507 179.19 128.737M158.665 130.992C170.275 145.114 171.291 156.187 170.977 166.906M136.059 35.3528C135.222 33.0039 134.851 30.5146 134.966 28.0238C134.966 21.3004 139.887 17.9247 145.309 18.3702C145.384 17.4559 145.684 16.5745 146.183 15.8042C146.681 15.034 147.362 14.3988 148.165 13.9551C148.968 13.5115 149.868 13.2731 150.785 13.2612C151.703 13.2493 152.609 13.4642 153.423 13.8869C154.237 14.3095 154.934 14.9268 155.453 15.6839C155.971 16.4409 156.294 17.3142 156.393 18.2262C156.492 19.1382 156.365 20.0606 156.022 20.9114C155.679 21.7622 155.131 22.5151 154.426 23.103C158.742 27.9751 155.644 36.3899 164.567 38.0464C179.559 40.8304 198.254 32.7497 202.521 57.486C202.6 57.95 202.672 58.414 202.736 58.878M140.095 30.968C139.817 33.1813 124.087 44.3314 136.511 40.7121C139.065 39.9604 143.367 35.9932 144.529 33.3901M140.297 48.4448C139.483 44.9647 138.773 44.039 137.576 40.2249L140.297 48.4448Z"
        stroke="#212529"
        strokeWidth="0.69"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImageWomanSendingWorkOnComputer;
