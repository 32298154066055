import styled from '@emotion/styled';

export const CardContainer = styled.div`
  > div {
    height: 100%;
    display: flex;
    align-items: center;
    min-height: auto;
    padding: 21px 24px;
  }
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  > :first-of-type {
    margin-right: 16px;
  }
`;

export const Icon = styled.div(
  ({ stroke }) =>
    stroke &&
    `
  svg path {
    stroke: ${stroke};
  }
`
);
