import { convertPXToREM, mediaQuery } from '@sofia/ui';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';

export const IconBtn = styled.div`
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors.background.icon.secondary};
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  svg {
    color: ${({ theme }: { theme: Theme }) => theme.colors.text.black};
    box-sizing: content-box;
    pointer-events: none;
  }
  :hover {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.scrollbar.border};
  }
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  ${mediaQuery('desktop')} {
    padding: 24px 32px;
  }
  background-color: #f3f6f9;
  margin-bottom: -1px;
`;

export const HelpBtn = styled.button`
  svg {
    margin: 10px;
  }
  ${mediaQuery('desktop')} {
    margin-right: 24px;
    flex-direction: row;
    padding: 8px 16px;
    svg {
      margin: 0 10px 0 0;
    }
  }
  span {
    font-size: ${convertPXToREM(16)};
    font-weight: 400;
    line-height: 150%;
  }
  :hover,
  &[aria-expanded='true'] {
    background: #ebf0f5;
  }

  flex-direction: column;
  display: flex;
  align-items: center;
  border: solid 1px transparent;
  border-radius: 8px;
  background: transparent;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  ${mediaQuery('desktop')} {
    width: auto;
    margin-right: 60px;
  }

  > .ReactQueryDevtools {
    display: none;
  }
`;

export const IconArrowRightBar = styled(IconBtn)`
  ${mediaQuery('mobile')} {
    margin-right: 0px;
  }
  > svg {
    padding: 13px 12px;
  }
`;

export const IconX = styled(IconBtn)`
  margin-left: 32px;
  ${mediaQuery('mobile')} {
    margin-left: 20px;
  }
`;

export const ChatAndCloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
