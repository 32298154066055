import styled from '@emotion/styled';
import { Button } from '@ampli/ui';
import { convertPXToREM } from '@sofia/ui';

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  visibility: ${({ visibility }) => (visibility ? 'visible' : 'hidden')};
`;

export const TodayButton = styled.button`
  border: none;
  border-radius: 8px;
  outline: 0;
  background: transparent;
  cursor: pointer;
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
  color: ${({ theme }) => theme.colors.text.primary};

  @media (max-width: 930px) {
    margin: 0 ${convertPXToREM(8)} 0 ${convertPXToREM(16)};
  }
`;

export const Container = styled.div`
  max-width: ${convertPXToREM(456)};
  min-width: ${convertPXToREM(280)};

  width: 100%;
  height: ${convertPXToREM(428)};

  color: #eee;
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    margin: 0;
  }

  transition: max-height 0.4s ease, padding 0.4s ease;

  @media (max-width: 930px) {
    max-width: unset;
    width: 100%;

    height: ${({ isCollapsed }) =>
      isCollapsed ? convertPXToREM(175) : convertPXToREM(428)};

    position: fixed;
    top: 57px;
    left: 0;

    z-index: 1000;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  padding: 10px;
  align-items: center;

  gap: 20px;

  @media (max-width: 930px) {
    gap: 16px;
  }
`;

export const ContainerSmallIcon = styled.div`
  display: flex;
  svg {
    transform: ${({ transform }) => transform};
    font-size: 15px !important;
  }
`;

export const TextButton = styled.p`
  text-transform: capitalize;
`;

export const ButtonNav = styled(Button)`
  max-width: 100px;
  padding: 0;

  display: flex;
  color: ${({ color }) => color};
  align-items: center;
  justify-content: center;

  p {
    font-size: 0.75rem;
  }
`;

export const ContainerCalendar = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.card.border.primary};
  border-radius: 8px;
  padding: 16px;

  @media (max-width: 930px) {
    height: unset;
    box-shadow: none;
    max-width: unset;
    padding: 0;

    border: none;
    border-radius: 0px 8px 8px 0px;
    padding: 0 16px;

    ${({ collapsed }) =>
      collapsed &&
      `
        height: ${convertPXToREM(150)};
        overflow: hidden;
      `}
  } ;
`;

export const SwipeMobile = styled.div`
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.07);
  border-radius: 0px 0px 12px 12px;
  display: flex;
  height: ${convertPXToREM(26)};
  padding: 8px ${convertPXToREM(125)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  transform: translateY(${({ deltaY }) => deltaY}px);

  z-index: 9999;
  background: white;
`;

export const ButtonCalendar = styled.button`
  margin: 0;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ presentToday, theme }) => {
    if (presentToday) {
      return `
        background-color: ${theme.colors.text.black};
        color: white !important;
      `;
    }
  }}
`;

export const DaysContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 50px;

  padding: 4px;
  align-items: center;
  justify-content: center;
`;

export const DayButton = styled.div`
  display: flex;
  flex-direction: column;

  width: 40px;
  padding: 4px 0;

  cursor: pointer;
`;

export const DayLabel = styled.div`
  display: flex;
  flex-direction: column;

  width: 40px;

  padding: 4px;
  border-radius: 8px;
  gap: 6px;

  align-items: center;
  justify-content: center;

  :hover {
    background: ${({ theme }) => theme.colors.background.primary};
  }
`;

export const DayLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: 50%;
  background: ${({ background }) => background};
`;

export const EventDateContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
`;

export const EventDateLabel = styled.div`
  width: 6px;
  height: 6px;

  border-radius: 50%;
  background: ${({ color }) => color};
`;

export const SpanCalendar = styled.span`
  margin: 0;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Month = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;

  h1 {
    margin: 0;
    color: ${({ color }) => color};
    font-family: inherit;
    font-size: 16px;
    white-space: nowrap;
    font-style: normal;
    font-weight: bold;
  }

  @media (max-width: 930px) {
    width: auto;
    margin-right: ${convertPXToREM(60)};
    padding: 10px 0 0 0;
  }
`;

export const Weekdays = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    height: 34px;
    width: calc((100% - 25px) / 7);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 140%;
    color: #c0bebc;
  }
`;

export const Days = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 7px;

  button {
    font-size: 1.4rem;
    margin: 0.3rem;
    width: calc(100% / 9);
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #444444;
    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: ${convertPXToREM(14)};
    border-radius: 42px;
  }

  @media (max-width: 930px) {
    button {
      height: 36px;
    }
  }

  button:hover:not(.today) {
    cursor: pointer;
  }
`;

export const ContainerTooltip = styled.div`
  white-space: pre-line;
`;

export const ContainerDays = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`;

export const PrevDate = styled.div`
  opacity: 0.5;
`;

export const NextDate = styled.div`
  opacity: 0.5;
`;

export const EventDate = styled.div`
  width: 7px !important;
  height: 7px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: ${({ background }) => background};
  position: absolute !important;
  bottom: -15px !important;
  border-radius: 50px !important;
  color: #fff !important;
  font-size: 0.625rem !important;
  font-weight: bold !important;
`;

export const TextDay = styled.p`
  text-transform: capitalize;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  opacity: ${({ isSundayOrOutMonth }) => (isSundayOrOutMonth ? 0.5 : '1')};
`;

export const Today = styled.div`
  ${({ presentToday }) => {
    if (presentToday) {
      return `
          width: 32px;
          height: 32px;
          border-radius: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
      `;
    }
  }}
`;
