import {
  useQuery,
  useLazyQuery,
  useMutation,
  useServiceClient,
} from '@ampli/services';

import * as queries from './queries';
import {
  InitialPreferenceType,
  UpdateStudentPreferencesParams,
} from '../types';

type QueryResult<T> = {
  data: T;
  loading: boolean;
  error: boolean;
  refetch: () => void;
};

interface UpdateTourParams {
  id: string;
}

interface UpdateTourReturnMutation {
  data: { data: { id: boolean } };
}

interface UpdateTourReturn<T> {
  mutate: (variables?: UpdateTourParams) => Promise<UpdateTourReturnMutation>;
  metadata: { loading: boolean; error: Error | undefined; data: T };
}

interface UpdateStudentPreferencesReturnMutation {
  data: {
    data: UpdateStudentPreferencesParams;
  };
}

interface HandleSubmitProps {
  onSuccess?: () => void;
  onError?: () => void;
}

interface UpdateStudentPreferencesReturn<T> {
  mutate: (
    variables?: UpdateStudentPreferencesParams
  ) => Promise<UpdateStudentPreferencesReturnMutation>;
  metadata: {
    loading: boolean;
    error: Error | undefined;
    data: T;
  };
  handleSubmit: ({ onSuccess, onError }: HandleSubmitProps) => Promise<void>;
}

const DEVICE_TYPE = 'WEB';

export const useListBannersById = <T>(
  id: string,
  options = {}
): QueryResult<T> => {
  const { data, loading, ...rest } = useQuery(queries.LIST_BANNERS_BY_ID, {
    ...options,
    skip: !id,
    variables: { data: { courseEnrollmentId: id, device: DEVICE_TYPE } },
  });

  const banners = loading || !data ? [] : data?.data;

  return { data: banners, ...rest };
};

export const useListModalById = <T>(
  id: string,
  options = {}
): QueryResult<T> => {
  const { data, loading, ...rest } = useQuery(queries.LIST_MODAL_BY_ID, {
    ...options,
    skip: !id,
    variables: { data: { courseEnrollmentId: id, device: DEVICE_TYPE } },
  });

  const modals = loading || !data ? [] : data?.data;

  return { data: modals, loading, ...rest };
};

export const useListSlidingById = <T>(
  id: string,
  options = {}
): QueryResult<T> => {
  const { data, loading, ...rest } = useQuery(queries.LIST_SLIDING_BY_ID, {
    ...options,
    variables: { data: { courseEnrollmentId: id, device: DEVICE_TYPE } },
  });

  const sliding = loading || !data ? [] : data?.data;

  return { data: sliding, loading, ...rest };
};

export const useGetTermsById = <T>(
  courseTypeCode: string,
  courseVersionId: string,
  courseId: string,
  studentId: string,
  options = {}
): QueryResult<T> => {
  const { data, loading, ...rest } = useLazyQuery(queries.GET_TERMS_BY_ID, {
    ...options,
    variables: {
      data: { courseTypeCode, courseVersionId, courseId, studentId },
    },
  });

  const terms = loading || !data ? [] : data?.data;

  const client = useServiceClient('publicClient');

  const refetch = () =>
    client.refetchQueries({
      query: queries.GET_TERMS_BY_ID,
    });

  return { data: terms, loading, refetch, ...rest };
};

export const useListNotificationsById = <T>(
  id: string,
  options = {}
): QueryResult<T> => {
  const { data, loading, ...rest } = useQuery(
    queries.LIST_NOTIFICATIONS_BY_ID,
    {
      ...options,
      skip: !id,
      variables: { data: { courseEnrollmentId: id, device: DEVICE_TYPE } },
    }
  );

  return {
    data: loading || !data ? {} : data?.data,
    ...rest,
  };
};

export const useGetCourseRoles = <T>(
  courseEnrollmentId: string,
  options = {}
): QueryResult<T> => {
  const { data, loading, ...rest } = useQuery(queries.GET_COURSE_ROLES, {
    ...options,
    variables: { courseEnrollmentId },
    skip: !courseEnrollmentId,
  });

  const courseEnrollment = loading || !data ? {} : data.data;

  return {
    data: courseEnrollment,
    loading,
    ...rest,
  };
};

export const useListPendingRematriculaById = <T>(
  courseTypeCode: string,
  courseVersionId: string,
  courseId: string,
  studentId: string,
  options = {}
): QueryResult<T> => {
  const { data, loading, ...rest } = useQuery(
    queries.LIST_PENDING_REMATRICULA_BY_ID,
    {
      ...options,
      variables: { courseTypeCode, courseVersionId, courseId, studentId },
      skip: !courseTypeCode || !courseVersionId || !courseId || !studentId,
    }
  );

  return {
    data: loading || !data ? {} : data.data,
    loading,
    ...rest,
  };
};

export const useUpdateReadCreative = <T>(
  courseEnrollmentId: string,
  creativeId: string,
  options = {},
  refetchNotifications?: boolean
): [func: () => void, options: QueryResult<T>] => {
  return useMutation(queries.UPDATE_READ_CREATIVE, {
    ...options,
    variables: { read: { courseEnrollmentId, creativeId } },
    ...(refetchNotifications && {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: queries.LIST_NOTIFICATIONS_BY_ID,
          variables: { data: { courseEnrollmentId, device: DEVICE_TYPE } },
        },
      ],
    }),
  });
};

export const useUpdateBell = <T>(
  id: string,
  options = {},
  refetchNotifications?: boolean
): [func: () => void, options: QueryResult<T>] => {
  return useMutation(queries.UPDATE_BELL, {
    ...options,
    variables: { courseEnrollmentId: id },
    ...(refetchNotifications && {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: queries.LIST_NOTIFICATIONS_BY_ID,
          variables: { data: { courseEnrollmentId: id, device: DEVICE_TYPE } },
        },
      ],
    }),
  });
};

export const useGetStudentWelcomeTour = <T>(): QueryResult<T> => {
  return useQuery(queries.GET_WELCOME_TOUR, {
    fetchPolicy: 'no-cache',
  });
};

export const useGetStudentSubjectTour = <T>(
  courseEnrollmentId: string
): QueryResult<T> => {
  return useQuery(queries.GET_SUBJECT_TOUR, {
    variables: { courseEnrollmentId },
    skip: !courseEnrollmentId,
    fetchPolicy: 'no-cache',
  });
};

export const useUpdateSubjectTour = <T>(): UpdateTourReturn<T> => {
  const [mutate, metadata] = useMutation(queries.UPDATE_SUBJECT_TOUR, {
    fetchPolicy: 'no-cache',
  });

  return { mutate, metadata };
};

export const useUpdateWelcomeTour = <T>(): UpdateTourReturn<T> => {
  const [mutate, metadata] = useMutation(queries.UPDATE_WELCOME_TOUR, {
    fetchPolicy: 'no-cache',
  });

  return { mutate, metadata };
};

export const useGetStudentPreferences = <T>(): QueryResult<T> => {
  const { data, loading, ...rest } = useQuery(queries.GET_STUDENT_PREFERENCES);
  return { data: loading || !data ? {} : data.data, loading, ...rest };
};

export const useUpdateStudentPreferences = <T>(
  preferenceCategoryInput: InitialPreferenceType[]
): UpdateStudentPreferencesReturn<T> => {
  const [mutate, metadata] = useMutation(queries.UPDATE_STUDENT_PREFERENCES, {
    variables: { input: preferenceCategoryInput },
    fetchPolicy: 'no-cache',
    refetchQueries: [
      {
        query: queries.GET_STUDENT_PREFERENCES,
      },
    ],
  });

  const handleSubmit = async ({ onSuccess, onError }: HandleSubmitProps) => {
    try {
      const res = await mutate({
        variables: { input: preferenceCategoryInput },
      });

      if (res.data?.updateStudentPreferencesRequest) onSuccess && onSuccess();
      else onError && onError();
    } catch (error) {
      onError && onError();
    }
  };

  return {
    mutate,
    metadata,
    handleSubmit,
  };
};

export const useUploadCommunicationBase64File = (
  data: { file: string; fileName: string } | null,
  options = {}
): [
  func: () => Promise<{ data: { data: string } }>,
  options: QueryResult<string>
] => {
  return useMutation(queries.UPLOAD_COMMUNICATION_BASE64_FILE, {
    ...options,
    variables: { data },
    skip: !data,
    fetchPolicy: 'no-cache',
  });
};
