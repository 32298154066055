import { ampli } from '../../../../../../../config';

const env: 'development' | 'staging' | 'integration' | 'production' = ampli.env;
const squad: 'academico' | 'ptk' = ampli.squad;

const tutoring_communication_url = {
  development: 'http://127.0.0.1:8080/tutoring-communication/ptk',
  staging: {
    ptk: 'https://tutoring-communication.ptk.stg.ampli.com.br/tutoring-communication/ptk',
    academico:
      'https://tutoring-communication.academico.stg.ampli.com.br/tutoring-communication/ptk',
  }[squad],
  integration:
    'https://tutoring-communication.academico.int.ampli.com.br/tutoring-communication/ptk',
  production:
    'https://tutoring-communication.ampli.com.br/tutoring-communication/ptk',
}[env];

export const baseURl = tutoring_communication_url;

export const routes = {
  getFile: (fileToken: string): string => `${baseURl}/file/${fileToken}`,
  postFile: `${baseURl}/file`,
  postConversation: `${baseURl}/conversation`,
  replyConversation: `${baseURl}/reply`,
  closeConversation: (id: string): string =>
    `${baseURl}/conversation/${id}/quick-closure`,
  ratingEvaluationConversation: (id: string): string =>
    `${baseURl}/conversation/${id}/quick-closure-rating-evaluation`,
  commentEvaluationConversation: (id: string): string =>
    `${baseURl}/conversation/${id}/quick-closure-comment-evaluation`,
  reopenConversation: (id: string): string =>
    `${baseURl}/conversation/${id}/reopening`,
  getConversation: (params?: URLSearchParams): string =>
    `${baseURl}/conversation?${params}`,
  getConversationMessages: (id: string, params?: URLSearchParams): string =>
    `${baseURl}/conversation/${id}/messages?${params}`,
  readMessage: (id: string): string => `${baseURl}/conversation/${id}/status`,
};

export const getTutorPreviewPath = (
  learningObjectPath: string,
  learningUnitEnrollmentId: string,
  classID: string,
  token: string
): string => {
  const origin = window.location.origin;

  if (origin.includes('anhanguera'))
    return `${origin}/aluno/tutor-preview/${learningObjectPath}/${learningUnitEnrollmentId}/${classID}/${token}`;

  return `${origin}/tutor-preview/${learningObjectPath}/${learningUnitEnrollmentId}/${classID}/${token}`;
};
