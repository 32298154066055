import styled from '@emotion/styled';
const colors = {
  white: '#fff',
  boxShadow: '0px 2px 8px 0px rgba(33, 37, 41, 0.12)'
};
const sizes = {
  borderRadius: '8px',
  padding: '10px',
  maxWidth: '224px'
};
export const Wrapper = {
  Container: styled.div`
    position: relative;
    display: inline-block;
  `,
  Button: styled.div`
    cursor: pointer;
  `,
  Content: styled.div`
    position: absolute;
    background: ${colors.white};
    padding: ${sizes.padding};
    max-width: ${sizes.maxWidth};
    border-radius: ${sizes.borderRadius};
    z-index: 1;
    box-shadow: ${colors.boxShadow};
    display: ${_ref => {
    let {
      isVisible
    } = _ref;
    return isVisible ? 'flex' : 'none';
  }};
    box-sizing: content-box;
    align-items: center;
    justify-content: center;

    ${_ref2 => {
    let {
      direction
    } = _ref2;
    return direction;
  }}
  `
};