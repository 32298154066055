import { gql } from 'graphql.macro';

export const FIND_INAUGURAL_SUBJECT_LEARNING_UNITS = gql`
  query FindInauguralSubjectLearningUnits($courseEnrollmentId: String!) {
    data: getInauguralSubjectByCourseEnrollmentId(
      courseEnrollmentId: $courseEnrollmentId
    ) {
      id
      subjectLearningUnits: learningUnits {
        id
        order
        learningUnit {
          id
          currentLearningUnitVersion {
            sections {
              id
              order
              title
              pathUrl @client
            }
          }
        }
      }
    }
  }
`;

export const FIND_INAUGURAL_SUBJECT = gql`
  query FindInauguralSubject($courseEnrollmentId: String!) {
    data: getInauguralSubjectByCourseEnrollmentId(
      courseEnrollmentId: $courseEnrollmentId
    ) {
      id
      code
      name
      image {
        id
        baseUrl
        formats
        descriptionText
      }
      imageUrls @client
    }
  }
`;

export const GET_INAUGURAL_SUBJECT_SECTION = gql`
  query GetInauguralSubjectSection($courseEnrollmentId: String!) {
    data: getInauguralSubjectByCourseEnrollmentId(
      courseEnrollmentId: $courseEnrollmentId
    ) {
      id
      subjectLearningUnits: learningUnits {
        id
        learningUnit {
          id
          currentLearningUnitVersion {
            sections {
              id
              title
              order
              learningObjects {
                id
                title
                subtitle
                duration
                assets {
                  ... on Asset {
                    id
                    type
                  }
                  ... on VideoAsset {
                    mediastreamId
                    captionLink
                    size
                    videoLink
                  }
                  ... on ImageAsset {
                    caption
                    imageId
                    image {
                      id
                      formats
                      baseUrl
                      descriptionText
                    }
                    imageUrls @client
                  }
                  ... on FileAsset {
                    altText
                    description
                    id
                    mimeType
                    size
                    type
                    url
                  }
                  ... on TextAsset {
                    content
                  }
                  ... on TableAsset {
                    content
                  }
                  ... on FormulaAsset {
                    content
                  }
                }
                type @client
                pathUrl @client
              }
              pathUrl @client
            }
          }
        }
      }
    }
  }
`;

export const GET_INAUGURAL_SUBJECT_SECTION_GUARD = gql`
  query GetInauguralSubjectSectionGuard($courseEnrollmentId: String!) {
    data: getInauguralSubjectByCourseEnrollmentId(
      courseEnrollmentId: $courseEnrollmentId
    ) {
      id
      subjectLearningUnits: learningUnits {
        id
        learningUnit {
          id
          currentLearningUnitVersion {
            sections {
              id
              pathUrl @client
              learningObjects {
                id
                pathUrl @client
              }
            }
          }
        }
      }
    }
  }
`;
