import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import * as Styled from './evaluation-result.styles';
import {
  ButtonPrimary,
  CircularProgressBar,
  Typography,
  SvgTrophy,
  SvgSad,
} from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { EvaluationResultProps } from '../types';

const EvaluationResult = ({
  onBackClick,
  isApproved,
  timeWasRunningOut,
  grade,
  maxGrade,
  numberOfCorrectAnswers,
  numberOfQuestions,
  durationInMinutes,
  maxDurationInMinutes,
  resultType,
  footer,
  header,
}: EvaluationResultProps): React.ReactElement => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const yours = resultType[resultType.length - 1] === 'o' ? 'do' : 'da';

  const progressCircles = [
    {
      current: numberOfCorrectAnswers,
      max: numberOfQuestions,
      type: 'question',
    },
    {
      current: durationInMinutes,
      max: maxDurationInMinutes,
      type: 'duration',
    },
  ];

  const headerObj = isApproved
    ? {
        color: 'white',
        text: 'Parabéns! Continue assim!',
      }
    : {
        color: theme.colors.text.black,
        text: `Poxa! Você não foi desta vez.
        Vamos melhorar na próxima?`,
      };

  const Icon = () => (
    <Styled.HeaderIcon>
      {isApproved ? (
        <SvgTrophy data-testid="trophy-icon" />
      ) : (
        <SvgSad data-testid="sad-icon" />
      )}
    </Styled.HeaderIcon>
  );

  return (
    <Styled.BgTheme
      themeColor={isApproved ? '#B1C2D5' : '#F6E4DF'}
      data-testid="evaluation-result"
    >
      <Styled.Center>
        <Styled.Header>
          {isDesktop && <Icon />}
          <Typography
            textAlign="center"
            margin="24px 0"
            size={isDesktop ? 32 : 20}
            weight={700}
            color={header ? header.color : headerObj.color}
          >
            {header ? header.text : headerObj.text}
          </Typography>
        </Styled.Header>
        <Styled.Content>
          <Styled.Card>
            {!isDesktop && <Icon />}
            <Typography
              margin="8px 0 8px 0"
              size={16}
              color={theme.colors.text.lightBlack}
              data-testid="evaluation-result-text"
            >
              Nota {yours} {resultType}:
            </Typography>
            <Styled.NumberOutOfMax
              firstColor={
                isApproved ? theme.colors.text.black : theme.colors.text.danger
              }
              data-testid="evaluation-result-number-content"
            >
              <span>{grade}</span>
              <span> / {maxGrade}</span>
            </Styled.NumberOutOfMax>
            <Styled.ProgressCirclesContainer>
              {progressCircles.map(({ current, max, type }, index) => {
                const progress = (max / current) * 100;
                return (
                  <Styled.FlexCenter
                    key={index}
                    data-testid={`evaluation-result-${type}-progress`}
                  >
                    <CircularProgressBar
                      progress={Number(progress.toFixed(2))}
                      hasProgressText={false}
                      indicator={{
                        stroke:
                          type === 'duration'
                            ? timeWasRunningOut
                              ? '#FF8A00'
                              : theme.colors.text.primary
                            : isApproved
                            ? theme.colors.text.primary
                            : theme.colors.text.danger,
                      }}
                    >
                      <Styled.NumberOutOfMax
                        firstSize={16}
                        secondSize={14}
                        data-testid={`evaluation-result-${type}-progress-number`}
                      >
                        <span>{current}</span>
                        <span>{type === 'duration' ? 'min' : ` / ${max}`}</span>
                      </Styled.NumberOutOfMax>
                    </CircularProgressBar>
                    <Typography
                      margin="8px 0 0 0"
                      weight={700}
                      size={14}
                      color={theme.colors.text.lightBlack}
                      data-testid={`evaluation-result-${type}-progress-text`}
                    >
                      {type === 'question'
                        ? 'Questões corretas'
                        : `Tempo utilizado`}
                    </Typography>
                  </Styled.FlexCenter>
                );
              })}
            </Styled.ProgressCirclesContainer>
          </Styled.Card>
          {footer || (
            <ButtonPrimary
              width="100%"
              onClick={onBackClick}
              color="#fff"
              background={theme.colors.button.primary}
              hover={theme.colors.button.darkPrimary}
              data-testid="evaluation-result-footer-button"
            >
              Voltar para o início
            </ButtonPrimary>
          )}
        </Styled.Content>
      </Styled.Center>
    </Styled.BgTheme>
  );
};

EvaluationResult.defaultProps = {
  isApproved: false,
  timeWasRunningOut: false,
  grade: 0,
  maxGrade: 0,
  numberOfCorrectAnswers: 0,
  numberOfQuestions: 0,
  durationInMinutes: 0,
  maxDurationInMinutes: 0,
  resultType: 'prova',
};

export default EvaluationResult;
