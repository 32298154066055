import styled from '@emotion/styled';

interface ChipProps {
  active: boolean;
}

export const Chip = styled.button<ChipProps>`
  border: none;
  padding: 6px 12px;
  width: fit-content;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.button.primary : theme.colors.background.secondary};
  color: ${({ theme, active }) =>
    active ? '#FFFFFF' : theme.colors.text.lightBlack};
  transition: background-color 0.05s ease;

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.colors.icon.secondary : theme.colors.separator.lightColor};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const FiltersWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 24px 24px 0px;
  flex: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
