import styled from '@emotion/styled';
import { convertPXToVW, convertPXToVH } from '@sofia/ui';
import { mediaQuery } from '@ampli/ui';
import { SvgWomanShocked } from './woman-shocked';

export const BannerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${convertPXToVH(60)} auto;
  padding: ${convertPXToVH(40)} ${convertPXToVW(40)};
  border-radius: 8px;

  ${mediaQuery('mobile')} {
    flex-direction: column;
    justify-content: center;
    margin: ${convertPXToVH(25)} 0px ${convertPXToVH(40)} 0px;
    padding: ${convertPXToVH(24)} ${convertPXToVW(24)};
  }

  ${mediaQuery('super')} {
    margin: ${convertPXToVH(50)} 0px;
    padding: ${convertPXToVH(30)} ${convertPXToVW(30)};
  }
`;

export const TextContainer = styled.div`
  margin: ${convertPXToVH(24)} ${convertPXToVW(24)};

  ${mediaQuery('mobile')} {
    text-align: center;
  }
`;

export const StyledSvgWomanShocked = styled(SvgWomanShocked)`
  width: 300px;
`;
