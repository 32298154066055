import styled from '@emotion/styled';
import { convertPXToREM } from '../../utils';
const wrapperDesktopPadding = 32;
const wrapperMobilePadding = 24;
const wrapperBorderSize = 2;
const hoverColor = '#f3f6f9';
export const Wrapper = styled.div(_ref => {
  let {
    open
  } = _ref;
  return `
  background: #ffffff;
  border: ${wrapperBorderSize}px solid #b1c2d5;
  border-radius: 8px;
  position: relative;

  ${open ? `
    padding-bottom: ${wrapperMobilePadding}px;
    @media (min-width: 769px) {
      padding-bottom: ${wrapperDesktopPadding}px;
    }
  ` : ''}
`;
});
export const Marker = styled.div`
  background: ${_ref2 => {
  let {
    color
  } = _ref2;
  return color;
}};
  position: absolute;
  border-radius: 0px 4px 4px 0px;
  width: 8px;
  height: ${wrapperDesktopPadding}px;

  left: -${wrapperMobilePadding + wrapperBorderSize}px;
  @media (min-width: 769px) {
    left: -${wrapperDesktopPadding + wrapperBorderSize}px;
  }
`;
export const ContentContainer = styled.div(_ref3 => {
  let {
    open,
    maxHeight
  } = _ref3;
  return `
  @media (min-width: 769px) {
    margin: 0 ${wrapperDesktopPadding}px;
  }

  ${open ? `
    margin: 0 ${wrapperMobilePadding}px;
    max-height: ${maxHeight || '100vh'};
    opacity: 1;
  ` : `
    opacity: 0;
    margin-top: 0;
    max-height: 0;
  `}

  transition: opacity 200ms ease-out, max-height 300ms ease-in-out,
    margin-top 100ms ease-in-out;
`;
});
export const Header = styled.div(_ref4 => {
  let {
    open
  } = _ref4;
  return `
  display: flex;
  align-items: center;
  padding: ${wrapperMobilePadding}px;
  border-radius: 8px;

  @media (min-width: 769px) {
    padding: ${wrapperDesktopPadding}px;
  }

  &:hover {
    ${!open ? `background: ${hoverColor};` : ''}
    cursor: pointer;
  }
`;
});
export const FlexMRAuto = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 12px;
  margin-right: auto;
`;
export const Title = styled.span`
  font-weight: 400;
  font-size: ${convertPXToREM(20)};
  line-height: 140%;
  padding: 2px 0;
`;
export const Handler = styled.div(_ref5 => {
  let {
    open
  } = _ref5;
  return `
  display: flex;
  transform: rotate(${open ? 180 : 0}deg);
  transform-origin: center;
  transition: transform 300ms ease-in-out;
`;
});