import { InfosProps } from '../../../shared';

export type AmpliPages = 'FORM' | 'SUCCESS' | 'ERROR';

export type State = {
  hideAttachmentField: boolean;
  onCloseClick: () => void;
  infos: InfosProps;
  page: AmpliPages;
};

export type Reducer = React.Reducer<State, Partial<State>>;

export const initialAmpliPage: AmpliPages = 'FORM';

export const initialValues: State = {
  hideAttachmentField: false,
  onCloseClick: () => {},
  infos: {},
  page: initialAmpliPage,
};

export const reducer: Reducer = (state, action) => ({ ...state, ...action });
