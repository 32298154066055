import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';
import { IFloatingBox } from './interfaces';

export const FloatingBox = styled.div<IFloatingBox>`
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  position: absolute;
  ${({ boxPosition, boxCustomPosition }) =>
    `${boxPosition === 'top' ? 'bottom' : 'top'}: calc(100% + ${
      boxCustomPosition?.mobile || '21px'
    });`};
  ${({ boxAlign }) =>
    boxAlign === 'center'
      ? 'left: 50%; transform: translateX(-50%);'
      : typeof boxAlign === 'number'
      ? `left: ${boxAlign}%`
      : `${boxAlign}: 0;`};
  width: ${({ width = '328px' }) => width};
  ${mediaQuery('desktop')} {
    padding: 32px;
    ${({ boxPosition, boxCustomPosition }) =>
      `${boxPosition === 'top' ? 'bottom' : 'top'}: calc(100% + ${
        boxCustomPosition?.desktop || '34px'
      });`};
    width: ${({ width = '616px' }) => width};
  }
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    ${({ boxPosition }) => `
        border-${boxPosition}: 12px solid #ffffff;
        ${boxPosition}: 100%;
      `};
    ${({ iconAlign }) =>
      iconAlign === 'center'
        ? `
        left: 50%; transform: translateX(-50%);
      `
        : `left: ${iconAlign.left || 'auto'}; right: ${
            iconAlign.right || 'auto'
          }`};
    position: absolute;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 8px;
  ${mediaQuery('desktop')} {
    column-gap: 16px;
    margin-top: 0;
  }
  & > button {
    width: 100%;
    border: none;
  }
`;
