import { DateTime } from '@ampli/utils';
import { arrayOf, bool, func, object, string } from 'prop-types';
import React from 'react';
import { getTruncatedDateTime } from '../../../utils';
import SyllabusElectiveSubject from './elective/syllabus-elective-subject';
import SyllabusSubject from './subject/syllabus-subject';
import * as Styled from './syllabus-list-subjects.styles';
import { SyllabusEmptyState } from '../../../modules/academic-program/shared/components';

const SyllabusListSubjects = ({
  subjectEnrollments,
  onSubjectClick,
  blockCourseEnrollmentContentAccess,
  disabled,
  isGradeProgress,
  type,
}) => {
  const electiveSubject = React.useRef(null);

  const onSyllabusSubjectClick = ({ subjectEnrollment, subject }) =>
    !disabled
      ? () => {
          onSubjectClick &&
            onSubjectClick({
              pathUrl: subject.pathUrl,
              subjectEnrollmentId: subjectEnrollment.id,
              isElective: subjectEnrollment.isElective,
              electiveStatus: subjectEnrollment.status,
              subType: subjectEnrollment.courseSubject?.subType,
            });
        }
      : null;

  return (
    <>
      {subjectEnrollments.length <= 0 ? (
        <SyllabusEmptyState status={type} />
      ) : (
        <Styled.HalfItemRow>
          {subjectEnrollments.map((subjectEnrollment, index) => {
            if (!subjectEnrollment) return null;
            const typename = subjectEnrollment.__typename;
            const subject = subjectEnrollment.subject;

            const actualDate = getTruncatedDateTime();
            const endDate = DateTime.fromISO(
              subjectEnrollment.endDateForChoice
            );
            const startDate = DateTime.fromISO(
              subjectEnrollment.startDateForChoice
            );
            if (
              (!subject && typename === 'SubjectEnrollment') ||
              (typename === 'FutureSubject' && subjectEnrollment.isElective)
            ) {
              if (type === 'future') {
                return <SyllabusElectiveSubject id={index} />;
              }
              if (
                subjectEnrollment.isElective &&
                actualDate <= endDate &&
                startDate <= actualDate &&
                subjectEnrollment.status === 'AWAITING_CHOICE'
              ) {
                electiveSubject.current = subjectEnrollment;
              }
              return null;
            }

            return (
              <SyllabusSubject
                blockCourseEnrollmentContentAccess={
                  blockCourseEnrollmentContentAccess
                }
                id={index}
                isGradeProgress={isGradeProgress}
                key={index}
                name={
                  typename === 'SubjectEnrollment'
                    ? subject?.name
                    : subjectEnrollment.name
                }
                onClick={onSyllabusSubjectClick({
                  subjectEnrollment,
                  subject,
                })}
                subjectEnrollment={subjectEnrollment}
              />
            );
          })}
        </Styled.HalfItemRow>
      )}

      {electiveSubject.current ? (
        <SyllabusElectiveSubject electiveSubject={electiveSubject.current} />
      ) : null}
    </>
  );
};

SyllabusListSubjects.defaultProps = {
  showExamPeriod: false,
  disabled: false,
  blockCardDirection: false,
  showElective: true,
  isGradeProgress: false,
  type: '',
};

SyllabusListSubjects.propTypes = {
  subjectEnrollments: arrayOf(object.isRequired).isRequired,
  onSubjectClick: func,
  blockCourseEnrollmentContentAccess: bool,
  showExamPeriod: bool,
  disabled: bool,
  blockCardDirection: bool,
  showElective: bool,
  isGradeProgress: bool,
  type: string,
};

export default SyllabusListSubjects;
