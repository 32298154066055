import * as Yup from 'yup';
import { CNPJvalidator } from '@ampli/utils';

export const getCompanyInputSchema = () =>
  Yup.object({
    cnpj: Yup.string()
      .required('CNPJ da empresa é obrigatório')
      .test('isCNPJValid', function (value) {
        const validation = CNPJvalidator(value);
        return !validation.val
          ? this.createError({ message: validation.message, path: 'cnpj' })
          : true;
      }),
  });
