import * as Yup from 'yup';
import { REGEX_PHONE_NUMBER } from '../../../../constants';
import { convertTextToPhone, convertTextToPhonelandline } from '@ampli/utils';

const isValidPhoneOrCellphoneNumber = () => {
  return {
    name: 'isValidPhoneOrCellphoneNumber',
    message: 'Telefone da empresa inválido.',
    test: (value) =>
      !value ||
      (!!value &&
        (REGEX_PHONE_NUMBER.test(convertTextToPhone(value)) ||
          REGEX_PHONE_NUMBER.test(convertTextToPhonelandline(value)))),
  };
};

export const getCompanyPhoneSchema = () =>
  Yup.object({
    phone: Yup.string()
      .required('Telefone da empresa é obrigatório')
      .test(isValidPhoneOrCellphoneNumber()),
  });
