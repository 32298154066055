import * as Types from '../../../shared/graphql/types';

import * as Operations from './queries';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@ampli/services';
import { TMutationHook } from '../../../shared';
const defaultOptions = {} as const;
export type CreateManyAttendancesMutationVariables = Types.Exact<{
  data: Types.CreateAttendanceInput[] | Types.CreateAttendanceInput;
}>;

export type CreateManyAttendancesMutation = {
  __typename?: 'Mutation';
  data?: boolean | null;
};

export type CreateManyAttendancesMutationFn = Apollo.MutationFunction<
  CreateManyAttendancesMutation,
  CreateManyAttendancesMutationVariables
>;

/**
 * __useCreateManyAttendancesMutation__
 *
 * To run a mutation, you first call `useCreateManyAttendancesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyAttendancesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyAttendancesMutation, { data, loading, error }] = useCreateManyAttendancesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateManyAttendancesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManyAttendancesMutation,
    CreateManyAttendancesMutationVariables
  >
): Apollo.MutationTuple<
  CreateManyAttendancesMutation,
  CreateManyAttendancesMutationVariables
> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useMutation as TMutationHook)<
    CreateManyAttendancesMutation,
    CreateManyAttendancesMutationVariables
  >(Operations.CreateManyAttendances, options);
}
export type CreateManyAttendancesMutationHookResult = ReturnType<
  typeof useCreateManyAttendancesMutation
>;
export type CreateManyAttendancesMutationResult =
  Apollo.MutationResult<CreateManyAttendancesMutation>;
export type CreateManyAttendancesMutationOptions = Apollo.BaseMutationOptions<
  CreateManyAttendancesMutation,
  CreateManyAttendancesMutationVariables
>;
