import React from 'react';
import { MathJaxContext, MathJaxContextProps } from 'better-react-mathjax';

const MathJaxProvider = ({
  children,
  ...props
}: MathJaxContextProps & { children: React.ReactNode }): React.ReactElement => (
  <MathJaxContext {...props}>{children}</MathJaxContext>
);

export default MathJaxProvider;
