import React from 'react';
import VideoPlayer from './player/video-player';
import { VideoAssetProps } from '../types';
import * as Styled from './video-asset.styles';
import { useBreakpoint } from '../../../modules';

const VideoAsset = ({
  data,
  channelId,
  title,
  pageTitle,
  template,
  onHelpClick,
  onFinishing,
  onTimeUpdate,
  onReady,
  onError,
  onLoadedData,
  onBeforeRequest,
}: VideoAssetProps): React.ReactElement => {
  const { isDesktop } = useBreakpoint();

  return (
    <>
      <VideoPlayer
        banner
        data={data}
        channelId={channelId}
        playerType={'mediastream'}
        title={title}
        subtitle={pageTitle}
        onHelpClick={onHelpClick}
        onFinishing={onFinishing}
        onTimeUpdate={onTimeUpdate}
        onReady={onReady}
        onLoadedData={onLoadedData}
        onBeforeRequest={onBeforeRequest}
        onError={onError}
        isDesktop={isDesktop}
      />
      {template ? (
        <Styled.Banner
          data-testid="video-asset-banner"
          url={template[isDesktop ? 'bannerUrl' : 'mobileBannerUrl']}
        />
      ) : null}
    </>
  );
};

VideoAsset.defaultProps = {
  title: '',
  pageTitle: '',
  onHelpClick: () => {},
  onFinishing: () => {},
  onTimeUpdate: () => {},
  onReady: () => {},
  onLoadedData: () => {},
  onBeforeRequest: () => {},
  onError: () => {},
};

export default VideoAsset;
