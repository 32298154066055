import React from 'react';
import * as Styled from './semester-exam.styles';
import { useTheme } from '@emotion/react';
import {
  Typography,
  CardButton,
  SvgArrowRight,
  Modal,
  useModal,
} from '@sofia/ui';
import { TSubjectEnrollment } from './types';
import { ImageWomanCalendar } from '../../image';
import { EAssignmentStatus, clickByKey, useBreakpoint } from '../../../modules';
import { useGetState } from '../../../state';
import { generatePath, useHistory } from 'react-router-dom';
import { routePath } from '../../../routes';

enum EAssignmentStatusLabel {
  AVAILABLE = 'Prova liberada',
  DONE = 'Prova realizada',
  NOT_AVAILABLE = 'Prova não liberada',
}

const SemestralExamBanner = ({
  subjectEnrollment,
}: {
  subjectEnrollment: TSubjectEnrollment;
}): React.ReactElement => {
  const history = useHistory();
  const theme = useTheme();
  const modal = useModal();
  const isDesktop = useBreakpoint().isDesktop;
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();
  const assignment = subjectEnrollment?.assignments?.find(
    (assignment) => assignment?.detail.config.type === 'SEMESTRAL_EXAM_ONLINE'
  );

  const dateFormat = (date: string) => {
    const utcDate = new Date(date);
    return new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    ).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  };

  const routeParams = {
    courseType: selectedCourseEnrollmentType,
    courseVersionId: selectedCourseVersionId,
  };

  const onClick = () => {
    if (assignment) {
      if (assignment.status === EAssignmentStatus.NOT_AVAILABLE) {
        modal.show();
      } else if (assignment.status === EAssignmentStatus.AVAILABLE) {
        history.push(
          generatePath(routePath.exam, {
            ...routeParams,
            subjectEnrollmentId: subjectEnrollment.id,
          })
        );
      } else {
        history.push(
          generatePath(routePath.grade, {
            ...routeParams,
          })
        );
      }
    }
  };

  return (
    <>
      <Styled.CardContainer>
        <CardButton
          hover={theme.colors.background.secondary}
          borderColor={theme.colors.icon.background}
          backgroundColor={theme.colors.background.primary}
          onClick={onClick}
          onKeyDown={(event: React.KeyboardEvent<Element>) =>
            clickByKey({ event, onClick })
          }
          tabIndex={0}
        >
          <Styled.TextContainer>
            {isDesktop ? <ImageWomanCalendar /> : null}
            <Styled.FlexColumn>
              <Typography
                size={isDesktop ? 32 : 20}
                weight="700"
                margin="12px 0px"
                color={theme.colors.text.black}
              >
                Prova Semestral
              </Typography>

              {assignment ? (
                <>
                  <Styled.Flex margin={'auto 0 8px 0'}>
                    <Typography color={theme.colors.text.lightBlack}>
                      Situação:
                    </Typography>
                    <Typography
                      color={theme.colors.text.black}
                      margin="0 0 0 4px"
                    >
                      {EAssignmentStatusLabel[assignment?.status]}
                    </Typography>
                  </Styled.Flex>
                  <Styled.Flex>
                    <Typography color={theme.colors.text.lightBlack}>
                      Período de provas:
                    </Typography>
                    <Typography
                      color={theme.colors.text.black}
                      margin="0 0 0 4px"
                    >
                      {`${dateFormat(
                        assignment.detail.startDate
                      )} - ${dateFormat(assignment.detail.dueDate)}`}
                    </Typography>
                  </Styled.Flex>
                </>
              ) : null}
            </Styled.FlexColumn>

            <Styled.ActionContainer>
              <Typography weight={700} color={theme.colors.text.primary}>
                {assignment?.status === EAssignmentStatus.DONE
                  ? 'Ver correção'
                  : 'Fazer a prova'}{' '}
                <SvgArrowRight fill={theme.colors.text.primary} />
              </Typography>
            </Styled.ActionContainer>
          </Styled.TextContainer>
        </CardButton>
      </Styled.CardContainer>

      <Modal
        handleModal={modal}
        header={{
          headerText: 'Prova ainda não liberada',
        }}
        footer={{
          buttonText: 'Entendi',
          buttonClick: () => {
            modal.hide();
          },
        }}
      >
        <Typography color={theme.colors.text.lightBlack}>
          Aguarde o período de provas para liberação, e tente novamente.
        </Typography>
      </Modal>
    </>
  );
};

export default SemestralExamBanner;
