import styled from '@emotion/styled';
import { convertPXToVH, mediaQuery } from '@sofia/ui';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${convertPXToVH(24)} 0 ${convertPXToVH(27)};
`;

export const ButtonsWrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: row;

  button {
    width: 50%;
    white-space: nowrap;
  }

  button:first-of-type {
    margin-top: 0;
    margin-right: 16px;
  }

  ${mediaQuery('mobile')} {
    padding: 24px;
    justify-content: flex-end;

    button:first-of-type {
      margin-top: 0;
      margin-right: 0;
    }
  }
`;
