import { useGetSubjectEnrollment, useServiceClient } from '@ampli/services';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useMeContext } from '../modules/shared/contexts/me-context';
import { EXAM_CUT_OFF_SCORE_PERCENTAGE } from '../constants';
import { useGetState } from '../state';
import { routePath } from '../routes';

import logout from '../lib/logout';
import React from 'react';

const isApproved = ({ grade, maxGrade }) => {
  const gradePercentage = grade / maxGrade;
  return gradePercentage >= EXAM_CUT_OFF_SCORE_PERCENTAGE;
};

const getReferenceId = (assignment) =>
  assignment.detail.attempts?.find(
    (attempt) =>
      (attempt.assignmentAttempId || attempt.id) ===
      assignment.detail.highestGradeAttemptId
  )?.referenceId;

const useGradeSubjectPageData = () => {
  const [me, setMe] = React.useState();
  const { states: statesMe } = useMeContext();
  const { me: user } = statesMe.getMe;

  const history = useHistory();
  const client = useServiceClient('privateClient');

  const { subjectEnrollmentId } = useParams();

  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const { data: subjectEnrollment, loading: subjectEnrollmentLoading } =
    useGetSubjectEnrollment(subjectEnrollmentId);

  const lastDoneAssignments = [];

  subjectEnrollment?.assignments?.forEach((assignment, index) => {
    const title = index === 0 ? 'EXAM' : 'EXAM2';
    const highestGradeExamId = getReferenceId(assignment);

    const commonProps = {
      id: assignment.id,
      grade: assignment.grade,
      maxGrade: assignment.maxGrade,
      isApproved: isApproved(assignment),
      display: assignment.detail?.display || {},
    };

    if (highestGradeExamId && assignment.detail.config.type !== 'ASSIGNMENT') {
      lastDoneAssignments.push({
        type: title,
        highestGradeExamId,
        assignment: {
          highestGradeAttemptId: assignment.detail.highestGradeAttemptId,
        },
        ...commonProps,
      });
    }

    if (assignment.detail.config.type === 'ASSIGNMENT') {
      getReferenceId(assignment) &&
        lastDoneAssignments.push({
          type: assignment.detail.config.type,
          name: assignment.detail.display.name,
          assignment: {
            feedbacks: assignment.detail.assignmentFiles.map(
              (assignmentFile) => assignmentFile.feedback
            ),
            detailId: assignment.detail.id,
            highestGradeAttemptId: assignment.detail.highestGradeAttemptId,
          },
          ...commonProps,
        });
    }
  });

  subjectEnrollment?.learningUnits.forEach((unit) => {
    unit.assignments.forEach((assignment) => {
      if (assignment.detail.config.type === 'QUIZ') {
        const highestGradeExamId = getReferenceId(assignment);
        highestGradeExamId &&
          lastDoneAssignments.push({
            type: assignment.detail.config.type,
            grade: assignment.grade,
            maxGrade: assignment.maxGrade,
            highestGradeExamId,
            assignment: {
              highestGradeAttemptId: assignment.detail.highestGradeAttemptId,
            },
            isApproved: isApproved(assignment),
            display: assignment.detail?.display || {},
          });
      }
    });
  });

  const loading = subjectEnrollmentLoading;

  const handleLogout = React.useCallback(
    () => logout({ client, history }),
    [client, history]
  );

  const handleLogo = React.useCallback(
    () =>
      history.push(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
        })
      ),
    [history, selectedCourseEnrollmentType]
  );

  React.useEffect(() => {
    setMe({ firstName: user.firstName, personId: user.id });
  }, [user.firstName, user.id]);

  return {
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
    lastDoneAssignments,
    subjectEnrollment,
    subjectEnrollmentId,
    handleLogout,
    handleLogo,
    loading,
    me,
  };
};

export default useGradeSubjectPageData;
