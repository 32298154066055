import styled from '@emotion/styled';
import { mediaQuery, convertPXToVW } from '@sofia/ui';

export const Grid = styled.div`
  display: grid;
  margin-bottom: 40px;
  ${mediaQuery('mobile')} {
    row-gap: 20px;
    margin-bottom: 20px;
  }
  grid-template-columns: ${({ columns }) => {
    let frames = '';
    for (let i = 0; i < columns; i++) {
      frames += '1fr ';
    }
    return frames;
  }};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 56px;

  > button {
    width: auto;
  }

  > button:not(:last-of-type) {
    margin-top: 0px;
    margin-right: 24px;
  }

  ${mediaQuery('mobile')} {
    margin-top: 40px;
    flex-direction: column;
    justify-content: center;

    > button:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`;

export const InputWrapper = styled.div`
  max-width: ${convertPXToVW(344)};

  ${mediaQuery('mobile')} {
    max-width: initial;
  }
`;
