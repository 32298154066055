import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';

export const FlexCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Bg = styled.div`
  background: #ebf0f5;
  height: 100vh;
`;

export const Center = styled(FlexCenter)`
  margin: 0 24px;
  ${mediaQuery('desktop')} {
    max-width: 400px;
    min-width: 400px;
    margin: auto;
  }
`;
