import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const ButtonGhost = styled.button<{
  hoverColor?: string;
  color: string;
}>`
  border: none;
  border-radius: 8px;
  outline: 0;
  background: transparent;
  cursor: pointer;
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
  color: ${({ color }) => color};
  padding: 19px 24px;
  margin-top: 16px;

  ${mediaQuery('desktop')} {
    margin: 0;
  }
  :hover {
    background-color: #c5d2e0;
    color: ${({ hoverColor }) => hoverColor};
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  button {
    padding: 19px 8px;
  }

  ${mediaQuery('desktop')} {
    margin: 40px 32px 32px;
    flex-direction: row;
    button {
      padding: 19px 24px;
    }
  }
`;
