import { mediaQuery } from '@ampli/ui';
import { keyframes } from '@emotion/react';

import styled from '@emotion/styled';

const ProgressFocus = (theme) => keyframes`
  0% {
    background: ${theme.colors.background.secondary};
  }
  50% {
    background: ${theme.colors.scrollbar.border};
  }
  100% {
    background: ${theme.colors.background.secondary};
  }
`;

const Focus = (theme) => keyframes`
  0% {
    background: white;
    border-color: ${theme.colors.card.border.secondary};
  }
  50% {
    background: ${theme.colors.background.primary};
    border-color: ${theme.colors.background.primary};
  }
  100% {
    background: white;
    border-color: ${theme.colors.card.border.secondary};
  }
`;

export const Card = styled.div`
  > div {
    height: 100%;
    padding: 24px;
    display: block;

    ${mediaQuery('desktop')} {
      display: grid;
      align-content: space-between;
      justify-content: start;
      grid-template-columns: repeat(auto-fit, minmax(100%, 2fr));
      height: 194px;
    }
    :nth-of-type(n) {
      width: 100%;
    }

    > p {
      ${mediaQuery('desktop')} {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  > div[aria-disabled='true'] {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  > div {
    animation: ${({ theme, isNewEnrolledSubject }) =>
        isNewEnrolledSubject && Focus(theme)}
      1s linear infinite;
  }
  * div[role='progressbar'] {
    animation: ${({ theme, isNewEnrolledSubject }) =>
        isNewEnrolledSubject && ProgressFocus(theme)}
      1s linear infinite;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
