import styled from '@emotion/styled';
export const Wrapper = styled.div`
  padding: 16px;
  background: white;
  border-radius: 8px;
  cursor: pointer;

  ${_ref => {
  let {
    isNew
  } = _ref;
  if (isNew) {
    return `border-left: 4px solid #004ECC; background: #F3F6F9;`;
  }
}}
`;