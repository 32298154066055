import { FreeTrialStatusType, ButtonAriaLabels } from './types';

export const unavailableFreeTrialStatus: FreeTrialStatusType[] = [
  'EXPIRED',
  'PAID',
  'PAID_AND_RUNNING',
  'DISABLED',
];

export const PEDAGOGIC_TICKET_FORM_ID = 360001804631;

// gets from start and end, also white spaces, tabs, line breaks and others
export const ponctuationsRegex = /^[\s,.\-:;]+|[\s,.\-:;]+$/g;

export const sectionFooterButtonsAriaLabel: ButtonAriaLabels = ({
  prevIndex,
  nextIndex,
  isLastLearningObject,
  learningObjects,
}) => ({
  ariaLabelPreviousButton: `Estudar conteúdo anterior. Ir para ${
    prevIndex + 1
  } de ${learningObjects}.`,
  ariaLabelNextButton: isLastLearningObject
    ? `Concluir conteúdo ${learningObjects} de ${learningObjects}`
    : `Estudar próximo conteúdo. Ir para ${
        nextIndex + 1
      } de ${learningObjects}.`,
});

export const sectionFooterButtonsLabel = {
  desktop: {
    previousButtonLabel: 'Conteúdo anterior',
    nextButtonLabel: 'Próximo conteúdo',
  },
  mobile: {
    previousButtonLabel: 'Anterior',
    nextButtonLabel: 'Próximo',
  },
};
