import styled from '@emotion/styled';
import {
  mediaQuery,
  convertPXToREM,
  convertPXToVH,
  convertPXToVW,
} from '@sofia/ui';

export const Flex = styled.div`
  display: flex;
`;

export const BarStickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const BarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  background-color: #f3f6f9;
  margin-bottom: -1px;
`;

export const BarButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  ${mediaQuery('desktop')} {
    width: auto;
    margin-right: 60px;
  }
`;

export const SectionMenuHeader = styled.div`
  &:not(.closed) {
    padding: 32px 24px;
  }
  ${mediaQuery('desktop')} {
    &.closed {
      padding: 32px 10px 42px 10px;
    }
  }
  border-bottom: 1px solid #dee2e6;
`;

export const FlexWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
`;

export const FlexTitleContainer = styled(Flex)`
  flex-direction: column;
  padding-right: 8px;
  width: 100%;
`;

export const ListItemSkeleton = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 24px;
  border-bottom: 1px solid #dee2e6;
`;

export const SectionContentContainer = styled.div`
  padding: 40px 0;
  margin: 0 auto;
  max-width: ${convertPXToREM(712)};
  width: 100%;

  ${mediaQuery('mobile')} {
    padding: 56px 16px;
  }
`;

export const Footer = styled.div`
  padding: ${convertPXToVH(32)} ${convertPXToVW(16)};
  background-color: ${({ theme }) => theme.colors.background.primary};

  ${mediaQuery('desktop')} {
    padding: ${convertPXToVH(56)} 0;
  }
`;

export const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: ${convertPXToREM(712)};
  ${mediaQuery('desktop')} {
    padding: 0px 16px;
  }
`;

export const FooterSkeletonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterSeparator = styled.hr`
  border-width: 0;
  height: 1px;
  background: ${({ theme }) => theme.colors.separator.color};
  width: 100%;
  margin: 32px auto;

  ${mediaQuery('desktop')} {
    margin: 56px auto;
  }
`;
