import { checkState } from '../utils';
import { useGetLives } from '../../services/live';
import { sortByDate } from '../../../..';
import { ELiveState, ILive } from '../types';

type TLiveDataHook = ({
  prefetchedLive,
  subjectId,
  courseVersionId,
}: {
  prefetchedLive?: ILive | null;
  subjectId?: string;
  courseVersionId?: string;
}) => {
  loading: boolean;
  state: ELiveState;
  live: ILive | null | undefined;
};

const useGetLiveData: TLiveDataHook = ({
  prefetchedLive = undefined,
  subjectId,
  courseVersionId,
}) => {
  const { getData, loading } = useGetLives(courseVersionId, subjectId);

  let lives;
  let closerDateLive;
  if (!prefetchedLive) {
    lives = getData() || [];
    const filteredLives = sortByDate(
      lives.filter(
        (orderedLive) =>
          new Date(orderedLive?.endDateTime)?.getTime() > new Date().getTime()
      ),
      'startDateTime'
    ) as ILive[];
    closerDateLive = filteredLives.length > 0 ? filteredLives[0] : null;
  } else {
    closerDateLive = prefetchedLive;
  }

  return {
    loading,
    state: checkState(closerDateLive || {}),
    live: closerDateLive,
  };
};

export default useGetLiveData;
