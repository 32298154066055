import styled from '@emotion/styled';

export const FormulaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 18px 0;

  mjx-root > mjx-mn.mjx-n {
    padding-left: 0 !important;
    position: relative;
    top: -0.5em;
  }
`;
