import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useRef } from 'react';
import useIsInViewport from 'use-is-in-viewport';
const useScroller = _ref => {
  let {
    isInViewportSettings = {},
    scrollIntoViewSettings = {}
  } = _ref;
  const scrollRef = useRef(null);
  const [isInViewport, ref] = useIsInViewport(_objectSpread(_objectSpread({}, isInViewportSettings), {}, {
    target: scrollRef
  }));
  const scroller = () => {
    scrollRef.current.scrollIntoView(_objectSpread({
      behavior: 'smooth',
      block: 'start'
    }, scrollIntoViewSettings));
  };
  return {
    ref,
    scroller,
    isInViewport,
    originalRef: scrollRef
  };
};
export default useScroller;