import styled from '@emotion/styled';
import { convertPXToREM, mediaQuery } from '@sofia/ui';

export const Text = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
`;

export const ButtonAnotherReview = styled.div<{
  color: string;
}>`
  color: ${({ color }) => color};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
  cursor: pointer;
  padding: 10px;

  ${mediaQuery('mobile')} {
    padding: 20px 0;
    margin: 32px 0;
  }
`;

export const ContainerFeedback = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  flex-direction: column;

  ${mediaQuery('desktop')} {
    margin-bottom: 50px;
    flex-direction: row;
  }
`;
