import React from 'react';

import { useBreakpoint } from '@ampli/hooks';
import { node, array } from 'prop-types';
import { Container } from '@ampli/ui';

const LayoutContent = ({ children, padding, ...props }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <Container padding={padding} maxWidth={isDesktop ? 1090 : 968} {...props}>
      {children}
    </Container>
  );
};

LayoutContent.defaultProps = {
  padding: ['small'],
};

LayoutContent.propTypes = {
  children: node,
  padding: array,
};

export default LayoutContent;
