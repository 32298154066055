import { mediaQuery } from '@sofia/ui';
import styled from '@emotion/styled';

export const ActivitiesWrapper = styled.div`
  padding: 16px;
  background: ${({ background }) => background};
  border-radius: 0px 0px 8px 8px;
  border-top: 2px solid ${({ borderColor }) => borderColor};
  ${mediaQuery('desktop')} {
    padding: 32px;
  }
`;

export const Container = styled.div`
  margin-bottom: 16px;
  ${mediaQuery('desktop')} {
    margin-bottom: 24px;
  }

  > div {
    padding-bottom: 0px;
    #accordionContent {
      margin: 0px;
    }
  }
`;

export const SectionsContainer = styled.div`
  padding: 0 16px 16px;
  ${mediaQuery('desktop')} {
    padding: 0 32px 32px;
  }

  > :not(:last-of-type) {
    margin-bottom: 16px;
  }
`;
