import styled from '@emotion/styled';

export const FilterModalWrapper = styled.div`
  display: flex;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;

  margin-left: 6px;
  margin-bottom: 24px;
`;

export const ModalFooterWrapper = styled(FilterModalWrapper)`
  display: flex;
  flex-direction: row;

  padding: 0 32px 32px 32px;
  align-items: center;

  & :last-child {
    margin-left: 24px;
  }

  button {
    padding: 19px 24px;
    max-height: 56px;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalCard = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  > div {
    cursor: pointer;
  }
`;

export const FilterRow = styled.div`
  position: relative;
  width: 100%;
  padding: 8px 0 8px 8px;

  &::before {
    content: '';
    position: absolute;
    top: 7px;
    left: -4px;
    width: 3px;
    height: 24px;

    border-radius: 8px;

    background-color: ${({ color }) => color};
  }
`;
