import React from 'react';

import { useParams, useHistory, generatePath } from 'react-router-dom';
import { useGetExam, useGetAssignment } from '@ampli/services';
import { useSteps } from '@ampli/hooks';
import { find } from '@ampli/utils';

import { useGetState } from '../state';
import { routePath } from '../routes';
import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';

const useExamCorrectionPageData = () => {
  const history = useHistory();
  const { examId, attemptId } = useParams();

  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const { data: exam, loading: examLoading } = useGetExam(examId);

  const { data: assignment, loading: assignmentLoading } = useGetAssignment(
    exam?.assignmentId
  );

  const { states: statesCourseEnrollment } = useCourseEnrollmentsContext();
  const { data: courseEnrollment, loading: courseEnrollmentLoading } =
    statesCourseEnrollment.courseEnrollments;

  const subjectEnrollment = courseEnrollment?.subjectEnrollments?.find(
    (subject) => subject?.subject?.id === exam?.subject?.id
  );

  const questions = React.useMemo(
    () => exam?.questions || [],
    [exam?.questions]
  );

  const numberOfQuestions = React.useMemo(
    () => questions.length || 0,
    [questions.length]
  );

  const attempt = React.useMemo(
    () => find(assignment?.attempts || [], { id: attemptId }),
    [assignment?.attempts, attemptId]
  );

  const stepsControl = useSteps(numberOfQuestions);

  const loading = React.useMemo(
    () => examLoading || assignmentLoading || courseEnrollmentLoading,
    [assignmentLoading, examLoading, courseEnrollmentLoading]
  );

  React.useEffect(() => {
    if (!loading && !exam) {
      console.warn('Exam not found.');
      history.replace(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })
      );
    }
  }, [
    history,
    loading,
    exam,
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
  ]);

  return {
    loading,
    exam,
    attempt,
    assignment,
    stepsControl,
    course: courseEnrollment?.course,
    courseEnrollment,
    subjectEnrollment,
    selectedCourseVersionId,
    selectedCourseEnrollmentType,
  };
};

export default useExamCorrectionPageData;
