export const stringToArrayBuffer = (text: string): ArrayBuffer => {
  const buf = new ArrayBuffer(text.length);
  const bufView = new Uint8Array(buf);

  for (let index = 0; index < text.length; index++) {
    bufView[index] = text.charCodeAt(index);
  }

  return buf;
};

export const hexToAscii = (...args: (number | string)[]): string => {
  if (
    !args.every((arg) => typeof arg === 'number' || typeof arg === 'string')
  ) {
    throw new Error('Invalid arguments. Only numbers and strings are allowed.');
  }

  const hex = args.join('').replace(/\s+/gi, '');
  const characters: string[] = [];

  for (let i = 0; i < hex.length; i += 2) {
    const code = parseInt(hex.substr(i, 2), 16);
    if (!isNaN(code) && code !== 0) {
      characters.push(String.fromCharCode(code));
    }
  }

  return characters.join('');
};

export const utf8Decoder = (arrayBuffer: ArrayBuffer): string => {
  return new TextDecoder().decode(arrayBuffer);
};
