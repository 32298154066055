import React from 'react';

import {
  analyticsCreativesEvents,
  onboardingIds,
} from '../../../utils/communication-constants';

import {
  UseTourControlsProps,
  TourControlsProps,
  StudentTourProps,
} from '../../../../communication/types/onboarding';

import { useGetStudentSubjectTour } from '../../../../../modules/communication/services/hooks';
import { stopTour, switchTour } from '../../../../../components/tour';
import { useRolesContext } from '../../../contexts/communication-roles-context';
import { LearningFinish } from './modals/after-end';
import { useGetState } from '../../../../../state';
import { pushEvent } from '../../../../../lib/ga';
import { Learning } from './modals/before-start';

import TourStep from '../tour-step';

import * as queries from '../../../../communication/services/hooks';

const useSubjectTourControls = ({
  goToPage,
}: UseTourControlsProps): TourControlsProps => {
  const [{ selectedCourseEnrollmentId }] = useGetState();

  const subjectTour = useGetStudentSubjectTour<StudentTourProps>(
    selectedCourseEnrollmentId
  );

  const alreadyReadSubjectTour = subjectTour?.data?.data?.alreadyRead;

  const { hasSubjectOnboardingTourEnabled } = useRolesContext();

  const showSubjectTour =
    hasSubjectOnboardingTourEnabled && !alreadyReadSubjectTour;

  const { mutate: updateSubjectTour } = queries.useUpdateSubjectTour();

  const { goToPreviousPage, goToHomePage } = goToPage();

  const showSubjectTourInfo = () => {
    if (showSubjectTour) {
      switchTour(
        onboardingIds.subject.id.subjectTour,
        onboardingIds.subject.id.subjectTourInfo
      );
      updateSubjectTour();
    }
  };

  const finishTour = async () => {
    stopTour(onboardingIds.subject.id.subjectTour);
    updateSubjectTour();
  };

  const SubjectTourInfo = !showSubjectTour
    ? null
    : {
        id: onboardingIds.subject.id.subjectTourInfo,
        steps: [
          {
            target: onboardingIds.subject.target.questionIconHome,
            content: (
              <TourStep
                title="Faça o tour por aprendizagem quando quiser!"
                description="Clique no seu curso e tenha acesso ao tour interativo pelas principais funcionalidades de aprendizagem."
              />
            ),
            onPrevClick: () => {
              switchTour(
                onboardingIds.subject.id.subjectTourInfo,
                onboardingIds.subject.id.subjectTour
              );
              updateSubjectTour();
            },
          },
        ],
        onEndTour: () => {
          subjectTour.refetch();
        },
        onCloseTour: () => {
          subjectTour.refetch();
        },
        hideTitle: true,
      };

  const SubjectTour = {
    id: onboardingIds.subject.id.subjectTour,
    steps: [
      {
        target: onboardingIds.subject.target.carouselActives,
        executeAfter: () => {
          const element = document.querySelector(
            onboardingIds.subject.target.carouselActives
          )?.children[0] as HTMLElement;
          element?.click();
        },
        content: (
          <TourStep
            title="Veja suas disciplinas em andamento"
            description="Ao clicar no seu curso você terá acesso às suas disciplinas, os status e progresso. Além da biblioteca, calendário e outras funcionalidades."
          />
        ),
      },
      {
        target: onboardingIds.subject.target.situationCard,
        executeAfter: () => {
          const learningUnitElement = document.querySelector(
            onboardingIds.subject.target.learningUnitCard
          )?.children[0]?.children[0] as HTMLElement;
          learningUnitElement?.click();
          setTimeout(() => {
            const element = document.querySelector(
              onboardingIds.subject.target.cardLearningUnitSectionItem
            ) as HTMLElement;
            element?.click();
          }, 500);
        },
        onPrevClick: goToPreviousPage,
        highlight: [
          onboardingIds.subject.target.progressCard,
          onboardingIds.subject.target.subjectPresentationCard,
          onboardingIds.subject.target.subjectPontuationCard,
          onboardingIds.subject.target.learningUnitCard,
        ],
        content: (
          <TourStep
            title="Acompanhe datas, progresso e suas notas"
            description="Clique na disciplina para acessar as aulas, pontuação e atividades práticas."
          />
        ),
      },
      {
        target: onboardingIds.subject.target.sectionMenuListItem,
        onPrevClick: goToPreviousPage,
        highlight: [onboardingIds.subject.target.sectionLearningObjectMenu],
        content: (
          <TourStep
            title="Conheça o ambiente de aula"
            description="Ao clicar nas unidades você terá acesso às aulas. Aqui você poderá estudar e acompanhar seu progresso."
          />
        ),
      },
      {
        target: onboardingIds.subject.target.sectionActionsContainer,
        content: (
          <TourStep
            title="Customize o texto e envie dúvidas para o tutor"
            description="Ajuste o texto e a cor do fundo para melhorar sua experiência e envie suas dúvidas para o tutor enquanto estuda."
          />
        ),
      },
    ],
    showModalBeforeStart: true,
    modalBeforeStart: {
      onCancel: () => {
        showSubjectTourInfo();
        pushEvent(
          'event',
          analyticsCreativesEvents.onboarding.Com_tour_aprendizagem_pular.event
        );
      },
      onConfirm: () => {
        pushEvent(
          'event',
          analyticsCreativesEvents.onboarding.Com_tour_aprendizagem_iniciar
            .event
        );
      },
      details: {
        content: <Learning />,
        locale: {
          confirm: 'Ir para o tour',
          cancel: 'Pular',
        },
      },
    },
    showModalAfterEnd: true,
    modalAfterEnd: {
      onCancel: finishTour,
      onConfirm: finishTour,
      details: {
        content: <LearningFinish />,
        locale: {
          confirm: 'Ir para minhas disciplinas!',
        },
        hideCancel: true,
      },
    },
    onEndTour: (stepIndex = -1) => {
      goToHomePage();

      if (stepIndex === 0) {
        pushEvent(
          'event',
          analyticsCreativesEvents.onboarding.Com_tour_aprendizagem_etapa_fechar
            .event,
          {
            item: analyticsCreativesEvents.onboarding.Com_tour_aprendizagem_etapa_fechar.param(
              stepIndex + 1
            ),
          }
        );
        showSubjectTourInfo();
      } else {
        updateSubjectTour();
        pushEvent(
          'event',
          analyticsCreativesEvents.onboarding.Com_tour_aprendizagem_concluir
            .event
        );
      }
    },
    onCloseTour: (stepIndex = -1) => {
      goToHomePage();
      updateSubjectTour();
      showSubjectTourInfo();

      pushEvent(
        'event',
        analyticsCreativesEvents.onboarding.Com_tour_aprendizagem_etapa_fechar
          .event,
        {
          item: analyticsCreativesEvents.onboarding.Com_tour_aprendizagem_etapa_fechar.param(
            stepIndex + 1
          ),
        }
      );
    },
  };

  return { Tour: SubjectTour, TourInfo: SubjectTourInfo };
};

export default useSubjectTourControls;
