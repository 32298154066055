import React from 'react';

import { ColorNeutralTitle } from '@ampli/ui/src/tokens';
import { basename, isProduction } from './config';
import { css } from '@ampli/ui';

import * as CourseImages from './assets/images/course';

export const helpNumber = '3003-4030';

export const weekWorkDayChoices = [
  { id: 1, desc: 'Segunda-feira', name: 'mon' },
  { id: 2, desc: 'Terça-feira', name: 'tue' },
  { id: 3, desc: 'Quarta-feira', name: 'wed' },
  { id: 4, desc: 'Quinta-feira', name: 'thu' },
  { id: 5, desc: 'Sexta-feira', name: 'fri' },
  { id: 6, desc: 'Sábado', name: 'sat' },
  { id: 7, desc: 'Domingo', name: 'sun' },
];

//regex
// gets from start and end, also white spaces, tabs, line breaks and others
export const ponctuationsRegex = /^[\s,.\-:;]+|[\s,.\-:;]+$/g;
export const REGEX_CPF =
  /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/;
export const REGEX_PHONE_NUMBER = /\(\d\d\)\d{4,5}-\d{4}/g;
export const LINE_BETWEEN_TAGS_REGEX = /(>\r\n<|>\r<|>\n<)/g;

export const ampliProductionUrl = 'https://ava.ampli.com.br';
export const homologUrl = 'https://ava.ptk.stg.ampli.com.br';
export const devLocalUrl = 'http://localhost:7004';
export const devHostUrl = 'http://127.0.0.1:7004';
export const anhangueraProductionUrl = 'https://www.anhanguera.com';
export const anhangueraUrlHostname = 'www.anhanguera.com';

export const ampliAnalyticsTag = 'G-W9G7E32SQ5';
export const homologAnalyticsTag = 'G-2H4TKHN7VB';
export const anhangueraAnalyticsTag = 'G-ZKFN3HBTCJ';

export const analyticsMapper = {
  [ampliProductionUrl]: ampliAnalyticsTag,
  [anhangueraProductionUrl]: anhangueraAnalyticsTag,
  [homologUrl]: homologAnalyticsTag,
  [devLocalUrl]: homologAnalyticsTag,
  [devHostUrl]: homologAnalyticsTag,
};

//ak keys
export const AK_TAB = 9;
export const AK_ENTER = 13;
export const AK_SPACE = 32;
export const AK_ESC = 27;
export const AK_LEFT = 37;
export const AK_UP = 38;
export const AK_RIGHT = 39;
export const AK_DOWN = 40;

// course
export const COURSE_ENROLLMENT_ATTENDING = 'ATTENDING';
export const COURSE_ENROLLMENT_FINISHED = 'FINISHED';
export const courseEnrollmentLabelByStatus = {
  ATTENDING: 'Cursando',
  FINISHED: 'Concluído',
  SUSPENDED: 'Trancado',
  CANCELLED: 'Cancelado',
  TRANSFERRED: 'Transferido',
  ABANDONED: 'Inadimplente',
  DROPPED: 'Desligado',
};

export const courseEnrollmentInactiveStatus = [
  'SUSPENDED',
  'CANCELLED',
  'TRANSFERRED',
  'DROPPED',
];

export const coursesTypes = [
  'UNDERGRADUATE',
  'LANGUAGES',
  'GRADUATE',
  'OPEN',
  'EJA',
];

export const courseCardImageByDegree = {
  DEFAULT: CourseImages.TECHNICAL,
  UNDERGRADUATE: CourseImages.UNDERGRADUATE,
  LANGUAGES: CourseImages.LANGUAGES,
  GRADUATE: CourseImages.GRADUATE,
  EJA: CourseImages.EJA,
};

export const courseTypeLabelByDegree = {
  GRADUATE: 'Pós-graduação',
  UNDERGRADUATE: 'Graduação',
  LANGUAGES: 'Idiomas',
  EJA: 'EJA',
  TECHNICAL: 'Curso Técnico',
};

export const monthNames = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
};

// subject
export const SUBJECT_ENROLLMENT_IN_RECOVERY = 'IN_RECOVERY';
export const subjectEnrollmentStatusLabelByStatus = {
  IN_PROGRESS: 'Cursando',
  IN_RECOVERY: 'Recuperação',
  COMPLETED: 'Cursado',
  PASSED: 'Aprovada',
  FAILED: 'Reprovada',
  UNAVAILABLE: 'Em espera',
  PRE_ENROLLMENT: 'Futura',
  AWAITING_ENROLLMENT: 'Futura',
  PAUSED: 'Pausado',
};
export const subjectEnrollmentApprovedStatus = ['PASSED', 'COMPLETED'];
export const subjectEnrollmentReprovedStatus = ['REPROVED'];
export const SUBJECT_MINIMUM_FREQUENCY_PERCENTAGE = 0.75;
export const ASSIGNMENT_SEND_MAIL_TO = 'tutoria@ampli.com.br';

// video request
export const HTTP_SUCCESS_STATUS = 200;
export const RESPOND_OBJECT_KEY = 'respond';
export const URI_OBJECT_KEY = 'uri';
export const XHR_OBJECT_KEY = 'xhr';

// payment
export const instalmentStatusLabelByStatus = {
  OPEN: 'Aberta',
  CANCELLED: 'Cancelado',
  PAID: 'Pago',
  OVERPAID: 'Pago acima do valor',
  UNDERPAID: 'Parcialmente paga',
  PENDING: 'Pendente',
  REFUNDED: 'Reembolsado',
  OVERDUE: 'Atrasada',
  WROTE_OFF: 'Pago',
  FAILED: 'Falhou',
  BLOCKED: 'Indisponível',
};
export const paymentMethods = {
  BOLETO: 'Boleto',
  CREDIT_CARD: 'Cartão de crédito',
  EXEMPTION: 'Isenção',
  PIX: 'Pix',
};
export const paymentQueryParams = {
  INSTALMENTS: 'parcelas',
};
export const instalmentOpenStatus = ['OPEN', 'OVERDUE', 'FAILED'];
export const instalmentPaidStatus = [
  'PAID',
  'OVERPAID',
  'UNDERPAID',
  'WROTE_OFF',
];
export const ALTERED_CLOCK = {
  FORWARD: 'device-clock-forward',
  BACK: 'device-clock-back',
};
// exam
export const EXAM_TIME_MINUTES = isProduction ? 60 : 5;
export const EXAM_TIME_LEFT_MINUTES = isProduction ? 5 : 2;
export const EXAM_CUT_OFF_SCORE_PERCENTAGE = 0.6;
export const questionStripTags = ['a', 'button', 'script'];

// quiz
export const QUIZ_TIME_MINUTES = isProduction ? 60 : 5;
export const QUIZ_TIME_LEFT_MINUTES = isProduction ? 5 : 2;
export const QUIZ_CUT_OFF_SCORE_PERCENTAGE = 0.6;

// assignment
export const ASSIGNMENT_DAYS_TO_CORRECTION = 10;

// notes
export const MAX_LENGTH_NOTE = 1000;

export const questionChoicesStripTags = questionStripTags.concat([
  'ol',
  'li',
  'ul',
  'p',
]);
// section edu communication
export const SECTION_EDU_COMMUNICATION_COMPLETED_KEY =
  'SECTION_EDU_COMMUNICATION_COMPLETED';
export const SECTION_EDU_COMMUNICATION_COMPLETED_DESCRIPTION =
  'Section edu communication was completed';
// new feature communication
export const NEW_TEMPLATE_COMMUNICATION_COMPLETED_KEY =
  'NEW_TEMPLATE_COMMUNICATION_COMPLETED';
export const NEW_TEMPLATE_COMMUNICATION_COMPLETED_DESCRIPTION =
  'New template communication was completed';

/* Reading ruler communicatino */
export const READING_RULER_COMMUNICATION_COMPLETED_KEY =
  'READING_RULER_COMMUNICATION_COMPLETED';
export const READING_RULER_COMMUNICATION_COMPLETED_DESC =
  'Reading ruler communication was completed';

// notes onboarding
export const NOTES_ONBOARDING_COMPLETED_KEY = 'NOTES_ONBOARDING_COMPLETED';
export const NOTES_ONBOARDING_COMPLETED_DESCRIPTION =
  'Notes onboarding was completed';

// mandatory internship tutorial
export const MANDATORY_INTERNSHIP_TUTORIAL_ONBOARDING_COMPLETED_KEY =
  'MANDATORY_INTERNSHIP_TUTORIAL_ONBOARDING_COMPLETED';
export const MANDATORY_INTERNSHIP_TUTORIAL_ONBOARDING_COMPLETED_DESCRIPTION =
  'Mandatory internship tutorial onboarding was completed';

// grade
export const ATTENDANCE = 'ATTENDANCE';
export const EXAM = 'EXAM';
export const SEMESTRAL_EXAM_ONLINE = 'SEMESTRAL_EXAM_ONLINE';
export const ASSIGNMENT = 'ASSIGNMENT';
export const QUIZ = 'QUIZ';
export const FINISHBY_UNSPECIFIED = 'UNSPECIFIED';
export const FINISHBY_WEB = 'SYSTEM_WEB';
export const FINISHBY_IOS = 'SYSTEM_IOS';
export const FINISHBY_ANDROID = 'SYSTEM_ANDROID';
export const FINISHBY_USER = 'USER';
export const STATUS_FINISHED = 'FINISHED';

// generic
export const WITH_FULLSCREEN = 'withFullscreenFeature';
export const WITHOUT_FULLSCREEN = 'withoutFullscreenFeature';
export const STATUS_IN_RECOVERY = 'IN_RECOVERY';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';

// APP Stores
export const STORES = {
  GOOGLEPLAY: {
    URL: 'https://play.google.com/store/apps/details?id=br.com.ampli',
  },
  APPLESTORE: {
    URL: 'https://apps.apple.com/us/app/id1503246413',
  },
};

// Virtual Library
export const VIRTUAL_LIBRARY = {
  url: 'https://biblioteca-virtual.com',
};

// LABORATORY - SIMULATORS
export const LABORATORY_AUTH = {
  key: 'cogna_ampli_algetec',
  secret: 'mAgt1wW8aGiGERf0wfFi6ig5z0613I0j',
};

export const LABORATORY = {
  url: 'https://grupoa-u.blackboard.com',
  launchUrl: 'https://www.virtuaslab.net/ualabs/launcher.php',
  roles: {
    tutor: 'urn:lti:role:ims/lis/Instructor',
    student: 'urn:lti:role:ims/lis/Learner',
  },
  ...LABORATORY_AUTH,
};

// Course Types
export const COURSE_TYPES = {
  EJA: 'EJA',
  OPEN: 'OPEN',
  GRADUATE: 'GRADUATE',
  UNDERGRADUATE: 'UNDERGRADUATE',
  CORPORATE: 'CORPORATE',
};

export const KEY_CODES = {
  space: {
    keyCode: 32,
  },
  enter: {
    keyCode: 13,
  },
};

export const locationPathname = () =>
  window.location.pathname.replace(basename, '/').replace('//', '/');

// Entities labels for different types of course
export const EntitiesLabels = (
  courseType = locationPathname().split('/')[1]
) => {
  /** Desabilitando dicionário EJA temporariamente */
  // if (courseType === 'eja') {
  if (courseType === '-') {
    return {
      finishedSubjects: function () {
        return `${arguments[0]} de ${arguments[1]} módulos concluídos`;
      },
      mySubjectsTitle: 'Meus Módulos',
      mySubjectTitle: 'Meu Módulo',
      subjectsGradeTitle: 'Módulos',
      subjectGradeTitle: 'Módulo',
      subjectGradePoints: 'Minha pontuação no módulo',
      subjectGradeExam: 'Prova final do módulo',
      currentSubjects: 'Módulos Atuais',
      futureSubjects: 'Módulos Futuros',
      concludedSubjects: 'Módulos Concluídos',
      pickYourElectiveSubject: 'Escolha seu próximo módulo optativo',
      youReachedElectiveSubject: function () {
        return `${arguments[0]}, você chegou em um módulo optativo!`;
      },
      youChoseElectiveSubject: function () {
        return `Ótima escolha! Seu módulo optativo foi confirmado com sucesso e estará disponível a partir de ${arguments[0]}.`;
      },
      yourNextSubject: function () {
        return `${arguments[0]}, será seu próximo módulo!`;
      },
      estimatedConclusion: (
        <>
          Sua data de formatura é calculada conforme{' '}
          <strong>seu progresso no curso e número de módulos em aberto</strong>,
          considerando o tempo de refação das matérias que tiverem reprova.
        </>
      ),
      subjectFinalDate: 'Fim do módulo',
      subjectProgress: 'Progresso do módulo',
      subjectProgressModalText:
        'O progresso no módulo é calculado baseado na conclusão de: provas, trabalhos, aulas e provas presenciais.',
      subjectPresentation: 'Apresentação do módulo',
      subjectPresentationStartButton: 'Começar o módulo',
      subjectFinalExam: 'Realizar a prova final do módulo',
      subjectGradeMethod: function () {
        return `${arguments[0]} do módulo`;
      },
      subjectCurrentStatus: 'Situação atual no módulo ',
      subjectExamOrientation:
        'Durante estas datas você deverá fazer a prova final do módulo e se precisar, a recuperação.',
      subjectSectionOrientation:
        'Suas aulas estarão sempre dentro de cada módulo. Aperte para selecionar',
      subjectElectiveOrientation1: (
        <>
          Quer dizer que{' '}
          <span
            className={css`
              color: ${ColorNeutralTitle};
            `}
          >
            você pode escolher
          </span>{' '}
          qual módulo estudar! Legal né?
        </>
      ),
      subjectElectiveOrientation1Mobile:
        'Quer dizer que você pode escolher qual módulo estudar! Legal né?',
      subjectElectiveOrientation2:
        'Você estuda sua optativa depois do módulo atual',
      subjectElectiveOrientation3:
        'E após terminá-la, você continua com o próximo módulo do seu curso.',
      subjectElectiveHeader: 'Escolha qual módulo você quer estudar',
      subjectElectiveConfirmationTitle:
        function subjectElectiveConfirmationTitle() {
          return (
            <>
              Deseja estudar o módulo de <strong>{arguments[0]}</strong> ?
              <br />
              Você não poderá alterar mais tarde.
            </>
          );
        },
      subjectElectiveConfirmationText: function () {
        return `Confirmar minha escolha para estudar o módulo de ${arguments[0]}`;
      },
      subjectClose: 'Fechar módulo',
      subjectChoose: 'Escolher módulo',
      subjectAccess: 'Acessar outro módulo',
      subjectsAccess: 'Acessar meus módulos',
      subjectChooseChoice: function () {
        return `Escolher módulo de ${arguments[0]}`;
      },
      noteButtonDescription:
        'Consulte suas anotações escritas durante as aulas de seus módulos',
      notePageSubtitle:
        'Acesse as anotações feitas durante a aula em cada módulo que você estudou.',
      notePageEmptySubtitle:
        'Para escrever anotações, basta acessar um módulo e começar a estudar!',
      subjectAwaiting: function () {
        return `Seu módulo está em espera porque começa dia ${arguments[0]},
          faltam ${arguments[1]} dia${arguments[1] > 1 ? 's' : ''}.`;
      },
      subjectExamCriteria: function subjectExamCriteria() {
        return (
          <>
            Estamos animados para a aplicação da prova também, porém, antes é
            preciso que você tenha pelo menos{' '}
            <strong> {arguments[0]}% de frequência nas aulas do módulo.</strong>
          </>
        );
      },
      learningUnitTitle: function () {
        return `DISCIPLINA ${arguments[0]}`;
      },
      learningUnitCount: function () {
        return `Disciplinas ${arguments[0]}`;
      },
      learningUnitQuiz: function () {
        return `Exercício${arguments[1] ? '' : 's'} da Disciplina ${
          arguments[0]
        }`;
      },
      learningUnitTitleQuiz: function () {
        return `Exercícios Unidade ${arguments[0]}`;
      },
      learningUnitQuizOrientation: function learningUnitQuizOrientation() {
        return (
          <>
            Para concluir seus estudos da{' '}
            <strong>disciplina {arguments[0]}</strong>, agora você pode reforçar
            seu aprendizado e <strong>obter pontos</strong> para
            <strong> complementar sua nota!</strong>
          </>
        );
      },
      learningUnitQuizReplace: function () {
        return arguments[0] === 'Exercício das unidades'
          ? 'Exercício das disciplinas'
          : arguments[0];
      },
      learningUnitOrientation:
        'Programe-se para estudar uma Disciplina por semana e seus estudos nunca atrasarão.',
      learningUnitAndSection: function () {
        return `Aula ${arguments[0]} - Disciplina ${arguments[1]}`;
      },
      learningUnitAndSectionCount: function () {
        return `${arguments[0]} disciplinas / ${arguments[1]} aulas`;
      },
      acoTitle: 'Atividades complementares',
    };
  }
  return {
    finishedSubjects: function () {
      return `${arguments[0]} de ${arguments[1]} disciplinas concluídas`;
    },
    mySubjectsTitle: 'Minhas Disciplinas',
    mySubjectTitle: 'Minha Disciplina',
    subjectsGradeTitle: 'Disciplinas',
    subjectGradeTitle: 'Disciplina',
    subjectGradePoints: 'Minha pontuação na disciplina',
    subjectGradeExam: 'Prova final da disciplina',
    futureSubjects: 'Disciplinas Futuras',
    concludedSubjects: 'Disciplinas Concluídas',
    electiveSubject: 'Disciplina Optativa',
    currentSubjects: 'Disciplinas em andamento',
    shortPickYourElectiveSubject: 'Escolha sua disciplina optativa',
    youReachedElectiveSubject: function () {
      return `${arguments[0]}, você chegou em uma disciplina optativa!`;
    },
    youChoseElectiveSubject: function () {
      return `Ótima escolha! Sua disciplina optativa foi confirmada com sucesso
      e estará disponível a partir de ${arguments[0]}.`;
    },
    yourNextSubject: function () {
      return `${arguments[0]}, será sua próxima disciplina!`;
    },
    estimatedConclusion: (
      <>
        Sua data de formatura é calculada conforme{' '}
        <strong>
          seu progresso no curso e número de disciplinas em aberto
        </strong>
        , considerando o tempo de refação das matérias que tiverem reprova.
      </>
    ),
    subjectFinalDate: 'Final da disciplina',
    subjectProgress: 'Progresso da disciplina',
    subjectProgressModalText:
      'O progresso na disciplina é calculado baseado na conclusão de: provas, trabalhos, aulas e provas presenciais.',
    subjectPresentation: 'Apresentação da disciplina',
    subjectPresentationStartButton: 'Começar a disciplina',
    subjectFinalExam: 'Realizar a prova final da disciplina',
    subjectGradeMethod: function () {
      return `${arguments[0]} da disciplina`;
    },
    subjectExamOrientation:
      'Durante estas datas você deverá fazer a prova final da disciplina e se precisar, a recuperação.',
    subjectSectionOrientation:
      'Suas aulas estarão sempre dentro de cada disciplina. Aperte para selecionar',
    subjectCurrentStatus: 'Situação atual na disciplina ',
    subjectElectiveOrientation1: (
      <>
        Quer dizer que{' '}
        <span
          className={css`
            color: ${ColorNeutralTitle};
          `}
        >
          você pode escolher
        </span>{' '}
        qual disciplina estudar! Legal né?
      </>
    ),
    subjectElectiveOrientation1Mobile:
      'Quer dizer que você pode escolher qual disciplina estudar! Legal né?',
    subjectElectiveOrientation2:
      'Você estuda sua optativa depois da disciplina atual',
    subjectElectiveOrientation3:
      'E após terminá-la, você continua com a próxima disciplina do seu curso.',
    subjectElectiveHeader: 'Escolha qual disciplina você quer estudar',
    subjectElectiveConfirmationTitle:
      function subjectElectiveConfirmationTitle() {
        return (
          <>
            Deseja estudar a disciplina de <strong>{arguments[0]}</strong> ?
            <br />
            Você não poderá alterar mais tarde.
          </>
        );
      },
    subjectElectiveConfirmationText: function () {
      return `Confirmar minha escolha para estudar a disciplina de ${arguments[0]}`;
    },
    subjectClose: 'Fechar módulo',
    subjectChoose: 'Escolher disciplina',
    subjectAccess: 'Acessar outra disciplina',
    subjectsAccess: 'Acessar minhas disciplinas',
    subjectChooseChoice: function () {
      return `Escolher disciplina de ${arguments[0]}`;
    },
    noteButtonDescription:
      'Consulte suas anotações escritas durante as aulas de suas disciplinas',
    notePageSubtitle:
      'Acesse as anotações feitas durante a aula em cada disciplina que você estudou.',
    notePageEmptySubtitle:
      'Para escrever anotações, basta acessar uma disciplina e começar a estudar!',
    subjectAwaiting: function () {
      return `Sua disciplina está em espera porque começa dia ${arguments[0]},
        faltam ${arguments[1]} dia${arguments[1] > 1 ? 's' : ''}.`;
    },
    subjectExamCriteria: function subjectExamCriteria() {
      return (
        <>
          Estamos animados para a aplicação da prova também, porém antes é
          preciso que você tenha pelo menos{' '}
          <strong>
            {arguments[0]}% de frequência nas aulas da disciplina.
          </strong>
        </>
      );
    },
    learningUnitTitle: function () {
      return `UNIDADE ${arguments[0]}`;
    },
    learningUnitCount: function () {
      return `Unidades ${arguments[0]}`;
    },
    learningUnitQuiz: function () {
      return `Exercício${arguments[1] ? '' : 's'} da Unidade ${arguments[0]}`;
    },
    learningUnitQuizOrientation: function learningUnitQuizOrientation() {
      return (
        <>
          Para concluir seus estudos da <strong>unidade {arguments[0]}</strong>,
          agora você pode reforçar seu aprendizado e{' '}
          <strong>obter pontos</strong> para
          <strong> complementar sua nota!</strong>
        </>
      );
    },
    learningUnitQuizReplace: function () {
      return arguments[0];
    },
    learningUnitOrientation:
      'Programe-se para estudar uma Unidade por semana e seus estudos nunca atrasarão.',
    learningUnitAndSection: function () {
      return `Unidade ${arguments[0]} / Aula ${arguments[1]}`;
    },
    learningUnitAndSectionCount: function () {
      return `${arguments[0]} unidades / ${arguments[1]} aulas`;
    },
    acoTitle: 'Atividades complementares',
  };
};

// Zendesk
export const EJA_TICKET_FORM_ID = 4418136230932;

export const unavailableFreeTrialStatus = [
  'EXPIRED',
  'PAID',
  'PAID_AND_RUNNING',
  'DISABLED',
];

export const notActiveFreeTrialStatus = [
  'PAID',
  'PAID_AND_RUNNING',
  'DISABLED',
  'NOT_STARTED',
];

export const notActiveFreeTrialCourse = ['EXPIRED'];

export const newsCardDisplay = { myCourses: 'MY_COURSES', course: 'COURSE' };

export const freeTrialEnabledRole = 'FREE_TRIAL_ENABLED';

export const INTERNSHIP_STATUS = {
  PENDING_APPROVAL: 'Em análise',
  REPROVED: 'Reprovado',
  APPROVED: 'Aprovado',
  PENDING_RELEASE: 'Em análise',
  NOT_RELEASED: 'Não liberado',
  RELEASED: 'Liberado',
};

export const INTERNSHIP_ASSIGNMENT_STATUS = [
  'AVAILABLE',
  'DONE',
  'ONGOING',
  'NOT_AVAILABLE',
  'DISAPPROVED',
];

export const internshipStatusTheme = (theme) => ({
  PENDING_APPROVAL: {
    color: theme.colors.text.black,
    background: theme.colors.tag.warning,
  },
  PENDING_RELEASE: {
    color: theme.colors.text.black,
    background: theme.colors.tag.warning,
  },
  APPROVED: {
    color: theme.colors.text.white,
    background: theme.colors.tag.success,
  },
  RELEASED: {
    color: theme.colors.text.black,
    background: theme.colors.tag.lighterSuccess,
  },
  REPROVED: {
    color: theme.colors.text.black,
    background: theme.colors.tag.danger,
  },
  NOT_RELEASED: {
    color: theme.colors.text.black,
    background: theme.colors.tag.danger,
  },
});

export const subjectChooseCard = {
  MANDATORY: {
    title: 'Escolha sua disciplina eletiva',
  },
  OPTIONAL: {
    title: 'Escolha sua disciplina optativa',
  },
};
