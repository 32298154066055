import React from 'react';
import { bool } from 'prop-types';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { Typography, ButtonPrimary, SvgCheckCircle, useModal } from '@sofia/ui';
import { PopUp } from '../../../../modules/shared';
import * as Styled from './elective-subject-success-message.styles';

const ElectiveSubjectSuccessMessage = ({ manySubjects }) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const ref = React.useRef({});
  const [hide, setHideState] = React.useState(false);

  const successModal = useModal();

  const onClick = React.useCallback(() => {
    successModal.show();
  }, [successModal]);

  const handleUnderstood = () => {
    successModal.hide();
  };

  React.useEffect(() => {
    if (!hide) {
      onClick();
      setHideState(true);
    }
  }, [hide, onClick]);

  return (
    <div ref={ref}>
      <PopUp
        popUpState={successModal}
        header={
          <Styled.HeaderWrapper>
            <SvgCheckCircle fill={theme.colors.icon.success} size={64} />
            <Typography
              size={isDesktop ? 32 : 20}
              color={theme.colors.text.black}
              weight={700}
              textAlign="center"
              lineHeight={isDesktop ? '38.4px' : '24px'}
              margin={isDesktop ? '8px 0' : '24px 0'}
            >
              Inscrição realizada com sucesso
            </Typography>
          </Styled.HeaderWrapper>
        }
        footer={
          <Styled.FooterWrapper>
            <ButtonPrimary
              onClick={handleUnderstood}
              color={theme.colors.text.white}
              background={theme.colors.button.primary}
              hoverColor={theme.colors.button.darkPrimary}
            >
              Continuar
            </ButtonPrimary>
          </Styled.FooterWrapper>
        }
        isDesktop={isDesktop}
        ariaLabel={`Escolha de disciplina${
          manySubjects ? 's' : ''
        } feita com sucesso!`}
      >
        <Typography
          size={isDesktop ? 20 : 16}
          color={theme.colors.text.lightBlack}
          textAlign="center"
          lineHeight={isDesktop ? '38.4px' : '24px'}
        >
          {manySubjects
            ? 'Suas disciplinas foram confirmadas com sucesso e estarão disponíveis'
            : 'Sua disciplina foi confirmada com sucesso e estará disponível'}{' '}
          em até <b>24 horas</b>. Bons estudos!{' '}
          <span role="img" aria-label="Rosto sorrindo">
            😁
          </span>
        </Typography>
      </PopUp>
    </div>
  );
};

ElectiveSubjectSuccessMessage.propTypes = {
  manySubjects: bool.isRequired,
};

export default ElectiveSubjectSuccessMessage;
