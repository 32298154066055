import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ApolloClient, ApolloLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';
import { APIError, authStorage, compact, concat, forEach, GraphQLError, omitDeep } from '@ampli/utils';
import typePolicies from './type-policies';
import { createCss, showError } from './error/default-error-notifier';
export const logout = () => authStorage.clear();
const checkUnauthorizedStatus = status => status === 401 || status === 403;
const cacheCreator = function () {
  let cacheSettings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new InMemoryCache(_objectSpread({
    typePolicies
  }, cacheSettings)).restore(window.__APOLLO_STATE__ || {});
};
const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, ['__typename']);
  }
  return forward(operation);
});
export const errorLink = onError(_ref => {
  let {
    graphQLErrors,
    operation,
    networkError
  } = _ref;
  const context = operation.getContext();
  const requestHeaders = context.headers || {};
  const response = context.response || {};
  const responseStatus = response.status;
  const operationName = operation.operationName;
  const autoHandle = context.autoHandle;
  const logoutCallback = () => {
    let packageAva;
    try {
      packageAva = require('../../../sites/ava/package.json');
      const homepage = packageAva.homepage;
      const sanitizedUrl = homepage.endsWith('/') ? homepage.slice(0, -1) : homepage;
      window.location.href = `${sanitizedUrl}/login`;
    } catch (error) {
      window.location.href = '/';
    }
  };
  const dispatchError = async (message, status, ErrorClass) => {
    if (checkUnauthorizedStatus(status) && !context.skipLogout) {
      var _requestHeaders$autho;
      if (((_requestHeaders$autho = requestHeaders.authorization) === null || _requestHeaders$autho === void 0 ? void 0 : _requestHeaders$autho.indexOf('Bearer')) >= 0) {
        try {
          await logout();
          logoutCallback();
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      console.error(new ErrorClass(message, {
        requestHeaders,
        responseStatus,
        operationName
      }));
    }
  };
  if (graphQLErrors) {
    forEach(graphQLErrors, async error => {
      if (error.code === 'FORBIDDEN') {
        showError('Você não tem permissão para realizar esta ação');
      }
      if (error.code === 'UNAUTHORIZED' || error.code === 'UNAUTHENTICATED') {
        try {
          await logout();
          logoutCallback();
        } catch (error) {
          console.error(error);
        }
      }
      if (autoHandle) {
        if (error.status === 406) {
          showError(error.message);
        } else if (error.status === 403) {
          showError('Você não tem permissão para realizar esta ação');
        } else {
          console.error(new GraphQLError(error.message, {
            requestHeaders,
            responseStatus,
            operationName
          }));
          showError('Tivemos um erro inesperado, por favor tente novamente.');
        }
      } else {
        dispatchError(`${error.code}: ${error.message} (${error.status})`, error.status, GraphQLError);
      }
    });
  }
  if (networkError) {
    dispatchError(networkError.message, responseStatus, APIError);
  }
});
const headersLink = setContext(_ref2 => {
  let {
    headers = {}
  } = _ref2;
  return {
    headers: _objectSpread({
      authorization: 'Basic cG9ydGFsOnNlY3JldA=='
    }, headers)
  };
});
const authLink = setContext(_ref3 => {
  let {
    headers = {}
  } = _ref3;
  return authStorage.getItem('token').then(token => token ? {
    headers: _objectSpread(_objectSpread({}, headers), {}, {
      authorization: `Bearer ${token}`
    })
  } : {
    headers
  }).catch(error => {
    console.error(error);
    return {
      headers
    };
  });
});
export const clientCreator = _ref4 => {
  let {
    graphqlUrl,
    links = [],
    secure = true,
    cacheSettings = {}
  } = _ref4;
  createCss();
  const link = from(compact(concat(links, [errorLink, headersLink, secure && authLink, omitTypenameLink, createUploadLink({
    uri: graphqlUrl
  })])));
  return new ApolloClient({
    cache: cacheCreator(cacheSettings),
    link,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all'
      },
      query: {
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  });
};