import React from 'react';

import { useTheme } from '@emotion/react';
import { Typography } from '@sofia/ui';

import CustomizationSlider from '../../../slider/customization-slider';

import { FONT_SIZE_SLIDER } from '../../../../constants';
import {
  FlexGap as StyledFlexGap,
  SliderWrapper as StyledSliderWrapper,
} from '../../customization-section.styles';
import { FontSizeSliderProps, FontSizeType } from '../../../../types';

const FontSizeSlider = ({
  reset,
  defaultValue,
  onChange,
}: FontSizeSliderProps): React.ReactElement => {
  const theme = useTheme();
  const [value, setValue] = React.useState<FontSizeType>(defaultValue);

  React.useEffect(() => {
    if (reset) {
      setValue(defaultValue);
    }
  }, [defaultValue, reset]);

  return (
    <StyledFlexGap margin="0 0 16px 0" data-testid="font-size-slider">
      <Typography size={16} color={theme.colors.text.black} weight={700}>
        Tamanho dos textos
      </Typography>
      <StyledSliderWrapper>
        <Typography
          weight={700}
          size={FONT_SIZE_SLIDER.MIN}
          color={theme.colors.text.black}
          margin="0 16px 0 0"
        >
          Aa
        </Typography>
        <CustomizationSlider
          config={FONT_SIZE_SLIDER}
          ariaLabel="Tamanho da fonte, grupo de 9 itens"
          value={value}
          onChange={(value) => {
            setValue(value);
            onChange(value);
          }}
        />
      </StyledSliderWrapper>
    </StyledFlexGap>
  );
};

export default FontSizeSlider;
