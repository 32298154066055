import React from 'react';
import { useTheme } from '@emotion/react';
import { Typography } from '@sofia/ui';
import Skeleton from '../../../../../skeleton/skeleton';
import { NotesMenuListProps } from '../types';
import * as Styled from './notes-menu-list.styles';

const NotesMenuList = ({
  isObject,
  title,
  desc,
  children,
  loading,
}: NotesMenuListProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <Styled.Container>
      <Styled.Header isObject={isObject} isLoading={loading}>
        {loading ? (
          <Skeleton
            width={isObject ? '75px' : '50%'}
            height={isObject ? '28px' : '38px'}
          />
        ) : (
          <Typography
            color={theme.colors.text.black}
            weight={700}
            lineHeight="120%"
          >
            {title}
          </Typography>
        )}
        {loading ? (
          <Skeleton
            width={isObject ? '50%' : '100%'}
            height={isObject ? '20px' : '24px'}
          />
        ) : (
          <Typography
            color={
              isObject
                ? theme.colors.text.lighterBlack
                : theme.colors.text.black
            }
            weight={700}
          >
            {desc}
          </Typography>
        )}
      </Styled.Header>
      {children}
    </Styled.Container>
  );
};

NotesMenuList.defaultProps = {
  desc: '',
  loading: false,
};

export default NotesMenuList;
