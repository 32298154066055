import React from 'react';
import { Banner, Typography, Breadcrumbs } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { Layout } from '../../components';
import { LayoutContent } from '../../components/layout';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { routePath } from '../../routes';
import { useGetState } from '../../state';
import * as Styled from './suspension-cancellation.styles';
import SuspensionCancellation from './components/suspension-cancellation';

const SuspensionCancellationPage = () => {
  const history = useHistory();
  const theme = useTheme();

  const [
    {
      selectedCourseEnrollmentType,
      selectedCourseEnrollmentId,
      selectedCourseVersionId,
    },
  ] = useGetState();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Layout>
      <Banner background={theme.colors.banner.primary}>
        <LayoutContent>
          <Styled.BreadcrumbsContainer>
            <Breadcrumbs
              backClick={goBack}
              fill={theme.colors.text.secondary}
              firstColor={theme.colors.text.secondary}
              secondColor={theme.colors.text.secondary}
            >
              <Link
                to={generatePath(routePath.secretaryStudent, {
                  courseType: selectedCourseEnrollmentType,
                  courseVersionId: selectedCourseVersionId,
                  courseEnrollmentId: selectedCourseEnrollmentId,
                })}
              >
                <span>Secretaria</span>
              </Link>
              <Typography
                weight="bold"
                color={theme.colors.text.secondary}
                margin="0px"
              >
                <b>Trancamento e cancelamento</b>
              </Typography>
            </Breadcrumbs>
          </Styled.BreadcrumbsContainer>
        </LayoutContent>
      </Banner>

      <LayoutContent>
        <SuspensionCancellation />
      </LayoutContent>
    </Layout>
  );
};

export default SuspensionCancellationPage;
