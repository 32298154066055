import React from 'react';

import { TextArea, ButtonPrimaryLong, MultipleFileUpload } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { Loader } from '@ampli/ui';

import { DUVIDA, ELOGIO, RECLAMACAO } from './form.constants';
import { UploadedFile } from '../../../../shared';
import { usePtkFrame } from '../../contexts';
import { BackButton } from '../../components';

import * as Styled from '../../../../../../../shared/components/section-right-frame/section-right-frame.styles';

const MAX_SIZE = 10 * 1024 * 1024; // 10MB

const FormSection: React.FC = () => {
  const {
    hideAttachmentField,
    onUploadFile,
    conversation,
    isSubmiting,
    scrollType,
    onSubmit,
    dispatch,
    formType,
    formik,
    page,
  } = usePtkFrame();

  const theme = useTheme();

  const isHistoricPage = React.useMemo(() => page === 'HISTORIC', [page]);

  const { label, placeholder } = React.useMemo(
    () =>
      ({
        RECLAMACAO,
        DUVIDA,
        ELOGIO,
      }[formType]),
    [formType]
  );

  return (
    <Styled.ListWrapper
      scroll={scrollType}
      onSubmit={onSubmit}
      padding={isHistoricPage ? '0px' : '24px'}
      height={isHistoricPage ? 'unset' : '100%'}
    >
      {!isHistoricPage ? (
        <BackButton
          page={conversation ? 'CONVERSATION_CHAT' : 'FORM_TYPE_SELECTION'}
        />
      ) : null}

      <Styled.FieldWrapper>
        <TextArea
          rows="6"
          height="unset"
          label={isHistoricPage ? '' : label}
          placeholder={placeholder}
          labelMarginBottom="24px"
          name="description"
          onChange={formik.handleChange}
          textCount={true}
          maxLength={4000}
          onBlur={formik.handleBlur}
          value={formik.values.description}
          infoText={formik.errors.description || null}
          error={Boolean(
            formik.touched.description && formik.errors.description
          )}
        />
      </Styled.FieldWrapper>

      {hideAttachmentField ? null : (
        <Styled.FieldWrapper>
          <MultipleFileUpload
            title="Anexar arquivo"
            multiple={false}
            maxFileSize={MAX_SIZE}
            error={Boolean(
              formik.touched.attachment && formik.errors.attachment
            )}
            onClick={(files: UploadedFile[]) =>
              onUploadFile('attachment', files)
            }
          />
        </Styled.FieldWrapper>
      )}

      <Styled.ButtonWrapper>
        {isHistoricPage ? (
          <ButtonPrimaryLong
            type="button"
            color={theme.colors.text.black}
            onClick={() => dispatch({ isRespondingByHistory: false })}
          >
            Cancelar
          </ButtonPrimaryLong>
        ) : null}

        <ButtonPrimaryLong
          type="submit"
          color={theme.colors.text.white}
          background={theme.colors.button.primary}
          disabled={isSubmiting}
        >
          {isSubmiting ? (
            <Loader
              fullScreen={false}
              className="loader-no-background"
              color="#004ecc"
              size={18}
            />
          ) : (
            'Enviar'
          )}
        </ButtonPrimaryLong>
      </Styled.ButtonWrapper>
    </Styled.ListWrapper>
  );
};

export default FormSection;
