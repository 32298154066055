import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { TabList as _TabList } from '@sofia/ui';

const Pulse = keyframes`
	0% {
		box-shadow: 0 0 0 5px #7389FF20, 0 0 0 0px #7389FF20;
	}

	50% {
		box-shadow: 0 0 0 10px #7389FF40, 0 0 0 5px #7389FF40;
	}

  75% {
		box-shadow: 0 0 0 10px transparent, 0 0 0 10px #7389FF40;
	}

	100% {
		box-shadow: 0 0 0 0px transparent, 0 0 0 10px transparent;
	}
}`;

export const Grid = styled.div`
  [role='tablist'] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const TabList = styled(_TabList)`
  gap: 16px;
  .animate {
    #tab-5 {
      animation: ${Pulse} 2s linear infinite;
    }
  }
`;

export const ThemePreview = styled.div<{ background: string }>`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background};
`;
