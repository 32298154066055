export enum ScholarshipStatusEnum {
  PROCESSING = 'PROCESSING',
  ATTENDANCE = 'ATTENDANCE',
  PENDING_DOCUMENT = 'PENDING_DOCUMENT',
  SENT = 'SENT',
  RESENT = 'RESENT',
  APPROVED = 'APPROVED',
  REPROVED = 'REPROVED',
  CANCELLED = 'CANCELLED',
}
