type TSortByDate = (
  list: Array<unknown>,
  dateFieldPath: string
) => Array<unknown>;

function getValue(object: unknown, path: string): unknown {
  const array = path.split('.');
  let current = object as Record<string, never>;
  for (let i = 0; i < array.length; i++) {
    if (current?.[array?.[i]]) {
      current = current[array[i]];
    }
  }
  return current;
}

const sortByDate: TSortByDate = (
  list: Array<unknown>,
  dateFieldPath: string
) => {
  return list.length > 0
    ? list.sort((a, b) => {
        const prevDate = getValue(a, dateFieldPath)
          ? new Date(getValue(a, dateFieldPath) as Date | string).getTime()
          : null;
        const nextDate = getValue(b, dateFieldPath)
          ? new Date(getValue(b, dateFieldPath) as Date | string).getTime()
          : null;
        if (!prevDate) {
          return 1;
        }
        if (!nextDate) {
          return -1;
        }
        return prevDate < nextDate ? -1 : 1;
      })
    : [];
};

export default sortByDate;
