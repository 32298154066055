import styled from '@emotion/styled';

export const Flex = styled.div`
  display: flex;
  align-items: center;

  & > button {
    margin-top: 0;
    text-decoration: underline;
  }
`;

export const FlexItem = styled(Flex)`
  flex: 1;
`;
