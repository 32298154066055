import React from 'react';
import ReactDOM from 'react-dom';
import { isDebug } from '../config';

const initializeAxeCore = () => {
  if (isDebug) {
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
  }
};

export default initializeAxeCore;
