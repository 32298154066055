import React from 'react';
import { Separator } from './separator';
import { Card } from './card';
import { bool, func, array } from 'prop-types';
import CardsSkeleton from './skeleton/cards-skeleton';
import * as S from './cards-styles';
import { LoadingSpinner } from '../../loading-spinner';
import { Typography, ButtonGhost } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';
import { throttle } from 'lodash';

function Cards({
  loading,
  loadingMoreDates,
  enrollmentCalendarData,
  queryEnrollmentCalendar,
  isFilterReturnEmpty,
  setSelectedFilters,
  isReachingEnd,
}) {
  const theme = useTheme();
  const scrollRef = React.useRef(null);
  const [lastScrollTop, setLastScrollTop] = React.useState(0);

  const loadMoreItems = React.useCallback(() => {
    if (!loadingMoreDates) {
      queryEnrollmentCalendar();
    }
  }, [loadingMoreDates, queryEnrollmentCalendar]);

  const handleClearFilters = () => {
    setSelectedFilters([]);
  };

  React.useEffect(() => {
    const handleScroll = throttle(() => {
      const scrollTop = scrollRef.current.scrollTop;
      if (
        scrollTop > lastScrollTop &&
        scrollRef.current.scrollTop + scrollRef.current.clientHeight >=
          scrollRef.current.scrollHeight - 10 &&
        !loadingMoreDates &&
        !isReachingEnd
      ) {
        loadMoreItems();
      }
      setLastScrollTop(scrollTop);
    }, 200);

    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [
    enrollmentCalendarData,
    loadingMoreDates,
    loadMoreItems,
    lastScrollTop,
    isReachingEnd,
  ]);

  return (
    <S.Container
      id="container-cards"
      data-testid="container-cards"
      ref={scrollRef}
    >
      {loading || (!enrollmentCalendarData?.length && !isFilterReturnEmpty) ? (
        <CardsSkeleton />
      ) : (
        <>
          {enrollmentCalendarData?.map((item) => (
            <React.Fragment key={uuidv4()}>
              <Separator date={item[0]} id={item[0]} key={uuidv4()} />

              {item[1].map((card) =>
                !isFilterReturnEmpty ? (
                  <Card
                    redirect={card.redirect}
                    description={card.description}
                    key={uuidv4()}
                    id={`${card.startDate}-${card.eventDate + 1}`}
                    data-testid={`${card.startDate}-${card.eventDate + 1}ss`}
                    name={card.title}
                    info={card.message?.message}
                    infoType={card.message?.type}
                    redirectLink={card?.actions?.external_url}
                    subType={card.subCategory}
                    type={card.category}
                    startDate={card.startDate}
                    endDate={card.endDate}
                    address={card.address}
                    timer={card.timer}
                    eventDate={card.eventDate}
                  />
                ) : null
              )}
            </React.Fragment>
          ))}

          {isFilterReturnEmpty ? (
            <S.NoDataContainer>
              <Typography color={theme.colors.text.lighterBlack}>
                Não há eventos para os filtros escolhidos.
              </Typography>

              <ButtonGhost onClick={handleClearFilters}>
                <Typography weight={700} color={theme.colors.text.primary}>
                  Limpar filtros
                </Typography>
              </ButtonGhost>
            </S.NoDataContainer>
          ) : null}

          {loadingMoreDates ? (
            <S.LoadingContainer>
              <LoadingSpinner width={24} height={24} color="#868E96" />
            </S.LoadingContainer>
          ) : null}

          {isReachingEnd && !loadingMoreDates && !isFilterReturnEmpty ? (
            <S.EndContainer>
              <Typography color={theme.colors.text.lighterBlack}>
                Você chegou ao final da lista de eventos.
              </Typography>
            </S.EndContainer>
          ) : null}
        </>
      )}
    </S.Container>
  );
}

Cards.propTypes = {
  loading: bool,
  loadingMoreDates: bool,
  enrollmentCalendarData: array,
  queryEnrollmentCalendar: func,
  isFilterReturnEmpty: bool,
  setSelectedFilters: func,
  isReachingEnd: bool,
};

export default Cards;
