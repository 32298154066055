import styled from '@emotion/styled';

export const Line = styled.div`
  border: 1px solid #d6e1eb;
  margin: 16px 0px;
`;

export const Wrapper = styled.div`
  margin-top: 30px;
`;
