import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useLazyQuery, useMutation, useServiceClient } from '../hooks';
import { VALIDATE_USER_SMS_CODE, RESET_USER_PASSWORD, VERIFY_USER_PHONE_NUMBER, GET_AMPLI_USER_LOGIN_INFO, NOTIFY_STUDENT_BY_TYPE, VALIDATE_NOTIFICATION_CODE, RESET_PASSWORD, GET_POLICIES, CREATE_DEVICE_WHITELIST } from './queries';
import { validateUserSmsCodeSchema, resetUserPasswordSchema, verifyUserPhoneNumberSchema, getAmpliUserLoginInfoSchema } from './schemas';
export const useValidateUserSmsCode = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [mutate, metadata] = useMutation(VALIDATE_USER_SMS_CODE, _objectSpread(_objectSpread({}, options), {}, {
    client,
    fetchPolicy: 'no-cache'
  }));
  return [mutate, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: validateUserSmsCodeSchema
  })];
};
export const useResetUserPassword = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [mutate, metadata] = useMutation(RESET_USER_PASSWORD, _objectSpread(_objectSpread({}, options), {}, {
    client,
    fetchPolicy: 'no-cache'
  }));
  return [mutate, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: resetUserPasswordSchema
  })];
};
export const usePolicies = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [query, metadata] = useLazyQuery(GET_POLICIES, _objectSpread(_objectSpread({}, options), {}, {
    client,
    fetchPolicy: 'no-cache'
  }));
  return [query, _objectSpread({}, metadata)];
};
export const useCreateDeviceWhitelist = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(CREATE_DEVICE_WHITELIST, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const useResetPassword = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [mutate, metadata] = useMutation(RESET_PASSWORD, _objectSpread(_objectSpread({}, options), {}, {
    client,
    fetchPolicy: 'no-cache'
  }));
  return [mutate, _objectSpread({}, metadata)];
};
export const useVerifyUserPhoneNumber = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [mutate, metadata] = useMutation(VERIFY_USER_PHONE_NUMBER, _objectSpread(_objectSpread({}, options), {}, {
    client,
    fetchPolicy: 'no-cache'
  }));
  return [mutate, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: verifyUserPhoneNumberSchema
  })];
};
export const useNotifyStudentByType = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  return useMutation(NOTIFY_STUDENT_BY_TYPE, _objectSpread(_objectSpread({}, options), {}, {
    client,
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const useValidateNotificationCode = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  return useMutation(VALIDATE_NOTIFICATION_CODE, _objectSpread(_objectSpread({}, options), {}, {
    client,
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const useGetAmpliUserLoginInfo = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [query, metadata] = useLazyQuery(GET_AMPLI_USER_LOGIN_INFO, _objectSpread(_objectSpread({}, options), {}, {
    client
  }));
  return [query, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: getAmpliUserLoginInfoSchema
  })];
};