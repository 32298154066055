import React from 'react';
import { useTheme } from '@emotion/react';
import { Typography, SvgTimes } from '@sofia/ui';
import { Tooltip } from '@material-ui/core';
import { func } from 'prop-types';
import { NotesMenuHeaderProps } from '../types';
import * as Styled from './notes-menu-header.styles';

const NotesMenuHeader = ({
  onToggleMenuClick,
}: NotesMenuHeaderProps): React.ReactElement => {
  const theme = useTheme();
  return (
    <Styled.Header>
      <Typography size={24} weight={700} color={theme.colors.text.black}>
        Minhas anotações
      </Typography>
      <Tooltip arrow title={'Fechar minhas anotações'}>
        <Styled.IconBtn
          onClick={onToggleMenuClick}
          aria-label={'Fechar minhas anotações'}
        >
          <SvgTimes />
        </Styled.IconBtn>
      </Tooltip>
    </Styled.Header>
  );
};

NotesMenuHeader.propTypes = {
  onToggleMenuClick: func.isRequired,
};

export default NotesMenuHeader;
