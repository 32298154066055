/* eslint-disable prettier/prettier */
/* eslint-disable react/display-name */
import React from 'react';

import { routePath } from '../../../../../routes';
import useBreakpoint from '../../../hooks/use-breakpoint';
import TourStep from '../tour-step';

import {
  UseTourControlsProps,
  TourControlsProps,
  StudentTourProps,
} from '../../../../communication/types/onboarding';
import {
  analyticsCreativesEvents,
  onboardingIds,
} from '../../../utils/communication-constants';
import {
  useBeforeStartModal,
  SliderElipsis,
} from './modals/before-start/before-start.mobile';

import { useGetStudentWelcomeTour } from '../../../../../modules/communication/services/hooks';
import { BeforeStartModal } from './modals/before-start';
import { useRolesContext } from '../../../contexts/communication-roles-context';
import { switchTour } from '../../../../../components/tour';
import { ArrowRight } from './welcome.styles';
import { pushEvent } from '../../../../../lib/ga';
import { Welcome } from './modals/after-end';

import * as queries from '../../../../communication/services/hooks';

interface WelcomeTourControlsProps extends UseTourControlsProps {
  appendCommunicationOnTrack: () => void;
}

const useWelcomeTourControls = ({
  goToPage,
  appendCommunicationOnTrack,
}: WelcomeTourControlsProps): TourControlsProps => {
  const { mutate: updateWelcomeTour } = queries.useUpdateWelcomeTour();

  const welcomeTour = useGetStudentWelcomeTour<StudentTourProps>();

  const alreadyReadWelcomeTour = welcomeTour?.data?.data?.alreadyRead;

  const { hasWelcomeOnboardingTourEnabled } = useRolesContext();

  const showWelcomeTour =
    hasWelcomeOnboardingTourEnabled && !alreadyReadWelcomeTour;

  const { isDesktop } = useBreakpoint();

  const { beforeStartModalStep, setBeforeStartModalStep } =
    useBeforeStartModal();

  const { goToPreviousPage, goToInauguralSubject, goToCourseSelection } =
    goToPage();

  const onInfoFinished = async () => welcomeTour.refetch();

  const showWelcomeTourInfo = () => {
    if (showWelcomeTour) {
      switchTour(
        onboardingIds.welcome.id.welcomeTour,
        onboardingIds.welcome.id.welcomeTourInfo
      );
    }
  };
  const finishTour = async () => {
    appendCommunicationOnTrack();
    localStorage.setItem('showedNewProfileCommunication', 'true');
    return updateWelcomeTour().finally(() => goToCourseSelection());
  };

  const confirmBeforeStarModalLastStep =
    isDesktop || beforeStartModalStep === 3;

  const onCancelModalBeforeStart = () => {
    showWelcomeTourInfo();
    updateWelcomeTour();
    pushEvent(
      'event',
      analyticsCreativesEvents.onboarding.Com_tour_boas_vindas_pular.event
    );
  };

  const onConfirmModalBeforeStart = () => {
    pushEvent(
      'event',
      analyticsCreativesEvents.onboarding.Com_tour_boas_vindas_iniciar.event
    );
  };

  const WelcomeTourInfo = !showWelcomeTour
    ? null
    : {
        id: onboardingIds.welcome.id.welcomeTourInfo,
        steps: [
          {
            target: onboardingIds.welcome.target.questionIconCourseSelection,
            content: (
              <TourStep
                title="Acesse o tour pelo AVA a qualquer momento!"
                description="Clique no ícone na home e conheça as principais funcionalidades da plataforma por um guia interativo."
              />
            ),
            onPrevClick: () => {
              switchTour(
                onboardingIds.welcome.id.welcomeTourInfo,
                onboardingIds.welcome.id.welcomeTour
              );
              updateWelcomeTour();
            },
          },
        ],
        onEndTour: onInfoFinished,
        onCloseTour: onInfoFinished,
        hideTitle: true,
      };

  const ResponsiveProfileStep = {
    target: isDesktop
      ? onboardingIds.welcome.target.profileDesktop
      : onboardingIds.welcome.target.profileMobile,
    highlight: [onboardingIds.welcome.target.topBar],
    redirect: routePath.profile,
    onPrevClick: goToPreviousPage,
    content: (
      <TourStep
        title={
          isDesktop
            ? 'Ajuste a plataforma da sua maneira'
            : 'Um lugar pensado em você'
        }
        description={
          isDesktop ? (
            <>
              Em <strong>Perfil</strong> você pode realizar configurações e
              personalizar a plataforma de acordo com as suas necessidades do
              dia a dia.
            </>
          ) : (
            <>
              Em <strong>Perfil</strong> você terá acesso às suas notificações,
              central de ajuda, além de realizar configurações e personalizar a
              plataforma da sua maneira.
            </>
          )
        }
      />
    ),
  };

  const HelpStep = {
    target: onboardingIds.welcome.target.help,
    highlight: [onboardingIds.welcome.target.topBar],
    onPrevClick: goToPreviousPage,
    content: (
      <TourStep
        title="Tire suas principais dúvidas pela plataforma"
        description={
          <>
            Em <strong>Ajuda</strong> você pode tirar suas principais dúvidas
            sobre o seu curso, plataforma e outros assuntos.
          </>
        }
      />
    ),
  };

  const WelcomeTour = {
    id: onboardingIds.welcome.id.welcomeTour,
    steps: [
      {
        target: onboardingIds.welcome.target.myCoursesHeader,
        executeBefore: () => {
          const element = document.querySelector(
            onboardingIds.welcome.target.cardEnterCourse
          ) as HTMLElement;
          element?.click();
        },
        executeAfter: () => {
          const element = document.querySelector(
            onboardingIds.welcome.target.secretary
          ) as HTMLElement;
          element?.click();
        },
        highlight: [onboardingIds.welcome.target.topBar],
        content: (
          <TourStep
            title="Acesse e veja suas disciplinas"
            description={
              <>
                No menu <strong>Início</strong> você pode ver todas as disciplinas do seu curso e ter acesso rápido a funcionalidades relacionadas a ele.
              </>
            }
          />
        ),
      },
      {
        target: onboardingIds.welcome.target.secretary,
        executeAfter: () => {
          const element = document.querySelector(
            onboardingIds.welcome.target.payment
          ) as HTMLElement;
          element?.click();
        },
        onPrevClick: goToPreviousPage,
        highlight: [onboardingIds.welcome.target.topBar],
        content: (
          <TourStep
            title="Variados serviços disponíveis"
            description={
              <>
                Em <strong>Secretaria</strong> você pode acompanhar suas
                solicitações, baixar sua carteirinha de estudante, além de
                outras funcionalidades.
              </>
            }
          />
        ),
      },
      {
        target: onboardingIds.welcome.target.payment,
        executeAfter: () => {
          const element = document.querySelector(
            onboardingIds.welcome.target.help
          ) as HTMLElement;
          element?.click();
        },
        onPrevClick: goToPreviousPage,
        clickElement: true,
        highlight: [onboardingIds.welcome.target.topBar],
        content: (
          <TourStep
            title="Pague suas mensalidades com praticidade"
            description={
              <>
                Em <strong>Pagamento</strong> você aproveita o conforto de
                efetuar os pagamentos das suas mensalidades de qualquer lugar.
                Temos a opção de PIX!
              </>
            }
          />
        ),
      },
      ...(isDesktop
        ? [HelpStep, ResponsiveProfileStep]
        : [ResponsiveProfileStep]),
    ],
    showModalBeforeStart: true,
    modalBeforeStart: {
      styles: {
        width: isDesktop ? '60vw' : '100vw',
        maxWidth: isDesktop ? '755px' : '100vw',
        height: isDesktop ? 'unset' : '100vh',
        maxHeight: isDesktop ? 'inherit' : 'unset',
        justifyContent: isDesktop ? 'unset' : 'space-around',
      },
      onCancel: confirmBeforeStarModalLastStep
        ? onCancelModalBeforeStart
        : () => setBeforeStartModalStep(3),
      onConfirm: confirmBeforeStarModalLastStep
        ? onConfirmModalBeforeStart
        : () => setBeforeStartModalStep(beforeStartModalStep + 1),
      details: {
        // eslint-disable-next-line react/display-name
        ...(!isDesktop && { renderBeforeButtons: () => <SliderElipsis /> }),
        content: <BeforeStartModal />,
        shouldCloseOnClickOut: true,
        locale: {
          // eslint-disable-next-line react/display-name
          ...(!isDesktop && { icon: () => <ArrowRight /> }),
          isButtonLong: !isDesktop,
          cancel: confirmBeforeStarModalLastStep ? 'Pular tour' : 'Pular',
          confirm: isDesktop
            ? 'Começar tour pelo AVA'
            : beforeStartModalStep === 3
            ? 'Começar tour'
            : 'Próximo',
        },
      },
    },
    showModalAfterEnd: true,
    modalAfterEnd: {
      styles: { maxWidth: '500px' },
      details: {
        content: <Welcome />,
        locale: { confirm: 'Inicie sua jornada!', isButtonLong: !isDesktop },
        hideCancel: true,
      },
      onConfirm: () => {
        updateWelcomeTour();
        goToInauguralSubject();
        pushEvent(
          'event',
          analyticsCreativesEvents.onboarding.Com_inicie_jornada_iniciar.event
        );
      },
    },
    onEndTour: (stepIndex = -1) => {
      finishTour();

      if (stepIndex === 0) {
        pushEvent(
          'event',
          analyticsCreativesEvents.onboarding.Com_tour_boas_vindas_etapa_fechar
            .event,
          {
            item: analyticsCreativesEvents.onboarding.Com_tour_boas_vindas_etapa_fechar.param(
              stepIndex + 1
            ),
          }
        );
        showWelcomeTourInfo();
      } else {
        pushEvent(
          'event',
          analyticsCreativesEvents.onboarding.Com_tour_boas_vindas_concluir
            .event
        );
      }
    },
    onCloseTour: (stepIndex = -1) => {
      finishTour();
      pushEvent(
        'event',
        analyticsCreativesEvents.onboarding.Com_tour_boas_vindas_etapa_fechar
          .event,
        {
          item: analyticsCreativesEvents.onboarding.Com_tour_boas_vindas_etapa_fechar.param(
            stepIndex + 1
          ),
        }
      );
      showWelcomeTourInfo();
    },
  };

  return { Tour: WelcomeTour, TourInfo: WelcomeTourInfo };
};

export default useWelcomeTourControls;
