import { gql } from 'graphql.macro';

export const GET_TAX_INVOICE_DOCUMENT_STATUS = gql`
  query GetTaxInvoiceDocumentStatus(
    $invoiceId: String!
    $courseEnrollmentId: String!
  ) {
    data: getTaxInvoiceDocumentStatus(
      invoiceId: $invoiceId
      courseEnrollmentId: $courseEnrollmentId
    ) {
      requestData {
        createdDate
        fileUrl
        orderId
        orderStatus
        title
        serviceForecast
        ticketNumber
      }
      availableForecast
      allowRequest
    }
  }
`;

export const REQUEST_TAX_INVOICE_DOCUMENT = gql`
  mutation RequestTaxInvoiceDocument(
    $invoiceId: String!
    $courseEnrollmentId: String!
  ) {
    data: requestTaxInvoiceDocument(
      invoiceId: $invoiceId
      courseEnrollmentId: $courseEnrollmentId
    ) {
      createdDate
      fileUrl
      orderId
      orderStatus
      title
      serviceForecast
      ticketNumber
    }
  }
`;
