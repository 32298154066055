import { authStorage, dataStorage, localStorage } from '@ampli/utils';
import { logout } from '@ampli/services';

import { routePath } from '../routes';

const clearLocalStorageItems = () => {
  const items = [
    'courseEnrollmentData',
    'termsModal',
    'tourActive',
    'isTourActive',
    'hipotenusa/student-data/previousState',
    'hipotenusa/student-data/state',
  ];
  items.forEach((item) => localStorage.removeItem(item));
};

export const clearCaches = async () => {
  try {
    const keys = await caches.keys();
    await Promise.all(keys.map((key) => caches.delete(key)));
  } catch (error) {
    console.error(error);
  }
};

export const clearIndexedDB = async () => {
  try {
    const dbs = await indexedDB.databases();
    await Promise.all(dbs.map((db) => indexedDB.deleteDatabase(db.name)));
  } catch (error) {
    console.error(error);
  }
};

export const clearAll = async ({ client, studentContext }) => {
  try {
    if (client) await client.clearStore();
    if (studentContext) await studentContext.clear();
    await dataStorage.clear();
    await authStorage.clear();
    clearLocalStorageItems();
    sessionStorage.clear();
    await clearCaches();
    await clearIndexedDB();
  } catch (error) {
    console.error(error);
  }
};

export default async (params) => {
  return Promise.all([logout(), clearAll(params)])
    .then(() => {
      window.DD_RUM && window.DD_RUM.clearUser();
      if (params?.history) params.history.replace(routePath.login);
      return true;
    })
    .catch(console.error);
};
