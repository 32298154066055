import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const Flex = styled.div`
  display: flex;
`;

export const SectionMenuHeader = styled.div`
  &:not(.closed) {
    padding: 32px 24px;
  }
  ${mediaQuery('desktop')} {
    &.closed {
      padding: 32px 10px 42px 10px;
    }
  }
  border-bottom: 1px solid #dee2e6;
`;

export const FlexWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
`;

export const FlexTitleContainer = styled(Flex)`
  flex-direction: column;
  padding-right: 8px;
  width: 100%;
`;

export const PageTitle = styled.span`
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
  line-height: 115%;
  margin-bottom: 16px;
  color: ${({ color }) => color};
`;

export const SectionTitle = styled.span`
  font-size: ${convertPXToREM(24)};
  font-weight: 700;
  line-height: 140%;
  color: ${({ color }) => color};
`;

export const MenuHandle = styled('div', {
  shouldForwardProp: (propName) => propName !== 'themeColor',
})<{ themeColor: string }>`
  display: inline-block;
  line-height: 0;
  margin-bottom: auto;
  background-color: #dbe3ec;
  border-radius: 50%;
  cursor: pointer;

  &:not(.closed) {
    padding: 14px 12px;
  }
  &.closed {
    padding: 13px 12px;
  }
  svg {
    color: ${({ themeColor }) => themeColor};
    box-sizing: content-box;
    pointer-events: none;
  }
  div[role='tooltip'] {
    font-size: ${convertPXToREM(16)};
  }
  :hover {
    background-color: #c5d2e0;
  }
`;
