import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { convertPXToVH, convertPXToVW } from '@sofia/ui';

export const Wrapper = styled.div`
  padding: ${convertPXToVH(10)} ${convertPXToVW(17)} ${convertPXToVH(10)}
    ${convertPXToVW(20)};
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.14);
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  right: 0px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  ${({ open }) =>
    open === true &&
    css`
      & > svg {
        transform: rotate(180deg);
      }
    `}
`;

export const Body = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  margin-top: ${convertPXToVH(22)};
`;
