import React, { lazy } from 'react';
import { useServiceClient } from '@ampli/services';
import { useQueryParams } from '../hooks';
import { useGetState } from '../state';
import routePath from './routes-path';

const PaymentModule = lazy(() =>
  import(/* webpackChunkName: "Payment" */ '../modules/payment/module')
);

const Routes = () => {
  const clients = useServiceClient('all');
  const queryParams = useQueryParams();
  const [state, actions] = useGetState();
  const meta = { clients, actions, state, queryParams };
  const authMeta = { ...meta, auth: { redirect: routePath.login } };

  return <PaymentModule routesSettings={{ guardedRouteMeta: authMeta }} />;
};

export default Routes;
