import { logMessage } from '../../../..';
import { VideoAssetProps } from '../../../../../components/asset/types';
import { openZendeskBot } from '../../../../../lib/zendesk';
import { TLearningObject, PlayerEvent } from '../../../../section/shared';
import { ELogType } from '../../../utils/log-message';
import { handleBeforeVideoRequest } from '../utils';

type GetVideoPropsHookProps = {
  videoTime: { [key: string]: number };
  learningObject: TLearningObject | Record<string, never>;
  setVideoTime: (videoTime: { [key: string]: number }) => void;
  setIsVideoViewCompleted?: (hasCompleted: boolean) => void;
};

type GetVideoPropsHook = (
  props: GetVideoPropsHookProps
) => Omit<VideoAssetProps, 'data'>;

const useGetVideoProps: GetVideoPropsHook = ({
  videoTime,
  learningObject,
  setIsVideoViewCompleted,
  setVideoTime,
}) => {
  const sectionLearningObject = learningObject as TLearningObject;

  return {
    title: sectionLearningObject?.title || '',
    onHelpClick: () => openZendeskBot(),
    onFinishing: () => {
      logMessage(ELogType.log, 'video completado pela mdstrm');
      if (!sectionLearningObject?.completed) {
        if (setIsVideoViewCompleted) {
          setIsVideoViewCompleted(true);
        }
        setVideoTime({ [learningObject.id]: 0 });
      }
    },
    onTimeUpdate: (_e: Event, player: PlayerEvent) => {
      setVideoTime({ [learningObject.id]: player.currentTime() });
    },
    onLoadedData: (_e: Event, player: PlayerEvent) => {
      const currentTime = videoTime[learningObject.id] || 0;
      player.currentTime(currentTime);
    },
    onBeforeRequest: handleBeforeVideoRequest,
    onError: (error: ErrorEvent) => {
      logMessage(ELogType.log, error?.message, error);
    },
  };
};

export default useGetVideoProps;
