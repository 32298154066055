import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW } from '../../utils';
export const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${convertPXToVH(10)} ${convertPXToVW(20)};
  background: ${_ref => {
  let {
    background
  } = _ref;
  return background;
}};
  border-radius: 8px;
  margin: ${_ref2 => {
  let {
    margin
  } = _ref2;
  return margin;
}};
  cursor: pointer;
  gap: ${convertPXToVW(20)};

  p {
    margin: 0;
  }
`;
export const WrapperSvg = styled.div`
  width: 24px;
  height: 24px;
`;