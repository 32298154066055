import React from 'react';
import { useBreakpoint } from '@ampli/hooks';

import {
  SectionLayout,
  SectionContentHeader,
  SectionMenu,
} from '../../../../section';
import Skeleton from '../../../../skeleton/skeleton';
import * as Styled from './section-page-loader.styles';

const SectionPageLoader = (): React.ReactElement => {
  const isDesktop = useBreakpoint() === 'desktop';
  const barButtonSkeleton = (
    <Skeleton
      height={isDesktop ? '42px' : '44px'}
      width={isDesktop ? '180px' : '50px'}
      borderRadius="8px"
      margin={isDesktop ? '0 24px 0 0' : '0'}
      data-testid="bar-button-skeleton"
    />
  );
  const footerButtonSkeleton = (
    <Skeleton
      width={isDesktop ? '220px' : '145px'}
      height={isDesktop ? '68px' : '56px'}
      borderRadius="8px"
      data-testid="footer-button-skeleton"
    />
  );

  return (
    <SectionLayout
      topBar={
        <Styled.BarStickyContainer>
          <Styled.BarContainer>
            {!isDesktop ? (
              <Skeleton
                height="44px"
                width="44px"
                minWidth="44px"
                borderRadius="50%"
              />
            ) : null}
            <Styled.BarButtons>
              {barButtonSkeleton}
              {barButtonSkeleton}
              {barButtonSkeleton}
            </Styled.BarButtons>
            <Skeleton
              height="44px"
              width="44px"
              minWidth="44px"
              borderRadius="50%"
            />
          </Styled.BarContainer>
        </Styled.BarStickyContainer>
      }
      leftMenus={
        <SectionMenu
          open={isDesktop}
          header={
            <Styled.SectionMenuHeader>
              <Styled.FlexWrapper>
                <Styled.FlexTitleContainer>
                  <Skeleton height="18px" width="50%" margin="0 0 8px 0" />
                  <Skeleton height="32px" margin="8px 0 16px 0" />
                </Styled.FlexTitleContainer>
              </Styled.FlexWrapper>

              <Styled.FlexWrapper>
                <Skeleton height="20px" margin="0 16px 0 0" />
                <Skeleton height="20px" width="32px" />
              </Styled.FlexWrapper>
            </Styled.SectionMenuHeader>
          }
          content={Array.from({ length: 8 }, (_, index) => (
            <Styled.ListItemSkeleton key={index}>
              <Skeleton height="20px" width="20px" margin="0 16px 0 0" />
              <Skeleton height="24px" />
            </Styled.ListItemSkeleton>
          ))}
        />
      }
    >
      <Styled.SectionContentContainer>
        <SectionContentHeader loading />

        <Skeleton height="40vh" margin="24px 0 64px 0" />
        <Skeleton height="24px" margin="0 0 8px 0" />
        <Skeleton height="24px" width="90%" margin="0 0 8px 0" />
        <Skeleton height="24px" width="80%" margin="0 0 8px 0" />
        <Skeleton height="24px" width="95%" margin="0 0 8px 0" />
      </Styled.SectionContentContainer>

      <Styled.Footer>
        <Styled.FooterContainer>
          <Styled.FooterSkeletonWrapper>
            <Skeleton height="24px" width="30%" />
            <Skeleton height="44px" width="30%" />
          </Styled.FooterSkeletonWrapper>

          <Styled.FooterSeparator />

          <Styled.FooterSkeletonWrapper>
            {footerButtonSkeleton}
            {footerButtonSkeleton}
          </Styled.FooterSkeletonWrapper>
        </Styled.FooterContainer>
      </Styled.Footer>
    </SectionLayout>
  );
};

export default SectionPageLoader;
