import React from 'react';
import { MediastreamVideoPlayer } from '@ampli/mediastream-video-player';
import VideoPlayerSecondary from './video-player-secondary';
import { Toast } from '@sofia/ui';
import { VideoPlayerProps } from '../../types';
import { PlayerEvent } from '../../../../modules/section/shared';
import '@ampli/video-player/dist/styles';
import { logMessage } from '../../../../modules';
import { ELogType } from '../../../../modules/shared/utils/log-message';

const VideoPlayer = ({
  data,
  channelId,
  playerType,
  onTimeUpdate,
  onLoadedData,
  isDesktop,
  ...props
}: VideoPlayerProps): React.ReactElement => {
  const bufferMsg = React.useRef(false);
  const mediastreamId =
    !data?.mediastreamId || data?.mediastreamId?.length < 24
      ? 'a'.repeat(24)
      : data.mediastreamId;

  const onBufferError = () => {
    logMessage(ELogType.log, 'problema de internet');
    bufferMsg.current = true;
    setTimeout(() => {
      bufferMsg.current = false;
    }, 5000);
  };

  return (
    <>
      {playerType === 'mediastream' ? (
        <MediastreamVideoPlayer
          videoId={channelId || mediastreamId}
          onProgress={(event: Event, player: PlayerEvent) =>
            onTimeUpdate && onTimeUpdate(event, player)
          }
          onLoadedData={(event: Event, player: PlayerEvent) =>
            onLoadedData && onLoadedData(event, player)
          }
          onSeek={(event: Event, player: PlayerEvent) =>
            onTimeUpdate && onTimeUpdate(event, player)
          }
          type={channelId ? 'live' : 'media'}
          onBufferError={!bufferMsg.current && onBufferError}
          isDesktop={isDesktop}
          height={isDesktop ? 577 : 303}
          {...props}
        />
      ) : null}
      {playerType === 'mp4' && data ? (
        <VideoPlayerSecondary
          src={data.videoLink}
          type="video/mp4"
          onTimeUpdate={onTimeUpdate}
          onLoadedData={onLoadedData}
          {...props}
        />
      ) : null}
      <Toast
        text={
          isDesktop
            ? 'Seu vídeo está travando? Feche outras abas ou apps abertos que possam consumir sua internet.'
            : ' Vídeo travando? Tente fechar outras abas ou apps abertos.'
        }
        type="warning"
        show={Boolean(bufferMsg.current)}
        aria-live="assertive"
      />
    </>
  );
};

VideoPlayer.defaultProps = {
  onTimeUpdate: () => {},
  onLoadedData: () => {},
};

export default VideoPlayer;
