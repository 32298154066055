import { convertDateTimeTZ } from '../../../../section/shared';

const MS_IN_MINUTE = 1000 * 60;
const MS_IN_HOUR = MS_IN_MINUTE * 60;
const getDiffInMin = (diff: number): number => Math.round(diff / MS_IN_MINUTE);
const getDiffInHr = (diff: number): number => Math.round(diff / MS_IN_HOUR);

const getDiffTimeBetweenStartAndTodayInMin = (startDate: string): string => {
  const startDateStr = convertDateTimeTZ(startDate);
  if (startDateStr) {
    const today = new Date();
    if (startDateStr.toISOString() < today.toISOString()) {
      const diffInMs = Math.abs(Number(today) - Number(startDateStr));
      const diffInMin = Number(getDiffInMin(diffInMs));
      return `há ${
        diffInMin / 60 > 0
          ? `${getDiffInHr(diffInMs)} horas`
          : `${diffInMin} minutos`
      }`;
    }
  }
  return '';
};

export default getDiffTimeBetweenStartAndTodayInMin;
