import React from 'react';
import NotesMenuList from '../notes-menu-list';
import NotesMenuSectionCard from './card/notes-menu-section-card';
import NotesMenuFooter from '../../footer/notes-menu-footer';
import { pushAmpliEvent } from '../../../../../../../../../lib/ga';
import { SectionRoutesPath } from '../../../../../../../../section';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useGetState } from '../../../../../../../../../state';
import { NotesRoutesPath as routePath } from '../../../../../../../../notes';
import {
  NotesModal,
  NotesObjectModal,
  NotesObjectModalFooter,
} from '../../../../../../../../notes/shared';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { Typography } from '@sofia/ui';
import Skeleton from '../../../../../../skeleton/skeleton';
import { NotesMenuSectionListProps, SectionType } from '../../types';
import { GlobalParams } from '../../../../../../..';

const NotesMenuSectionList = ({
  sections,
  subjectNotes,
  learningUnitTitle,
  learningUnitOrder,
  learningUnitId,
  currentSection,
  onSectionClick,
  onReturnCurrentSectionNotes,
  loading,
}: NotesMenuSectionListProps): React.ReactElement => {
  const theme = useTheme();
  const messageRef = React.useRef<NotesModal>(null);
  const [modalDescription, setModalDescription] = React.useState('');
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const history = useHistory();
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();
  const { subjectEnrollmentId } = useParams<GlobalParams>();

  const [navSection, setNavSection] = React.useState<SectionType | null>(null);

  const goToSection = (pathUrl: string) => {
    pushAmpliEvent('event', 'notes-menu-on-change-section');
    history.push(
      generatePath(SectionRoutesPath.subjectSection, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
        subjectEnrollmentId: subjectEnrollmentId,
        learningUnitEnrollmentId: learningUnitId,
        sectionPath: pathUrl,
        fromSubjectPage: true,
      })
    );
  };

  const goToNotesBySubject = () => {
    pushAmpliEvent('event', 'notes-menu-redirect-to-notes-page');
    history.push(
      generatePath(routePath.subjectNotes, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
        subjectEnrollmentId: subjectEnrollmentId,
      })
    );
  };

  const getNotesSize = (sectionId: string) => {
    return subjectNotes.filter((note) => note.sectionId === sectionId)?.length;
  };

  return (
    <>
      <NotesMenuList
        isObject={false}
        title={`Unidade ${learningUnitOrder}`}
        desc={learningUnitTitle}
        loading={loading}
      >
        {loading
          ? Array.from({ length: 4 }).map((_, index) => (
              <Skeleton key={index} height="109px" borderRadius="8px" />
            ))
          : sections?.map((section, index) => {
              const size = getNotesSize(section.id);
              return (
                <NotesMenuSectionCard
                  key={index}
                  sectionOrder={section.order}
                  notesSize={size}
                  onSectionClick={(
                    event: React.MouseEvent<HTMLButtonElement>
                  ) => {
                    if (size === 0 && section.id !== currentSection?.id) {
                      setNavSection(section);
                      setModalDescription(
                        'Para criar uma anotação em outra aula é necessário acessar o conteúdo correspondente da aula que deseja anotar.'
                      );
                      messageRef.current?.onOpen(event);
                    } else {
                      onSectionClick(section);
                    }
                  }}
                />
              );
            })}
      </NotesMenuList>
      <NotesMenuFooter
        onReturnCurrentSectionNotes={onReturnCurrentSectionNotes}
        buttonText="Acessar todas anotações"
        buttonClick={(event) => {
          setNavSection(null);
          setModalDescription(
            'Se você continuar, você irá acessar uma nova página com todas suas anotações que já escreveu.'
          );
          messageRef.current?.onOpen(event);
        }}
      />
      <NotesObjectModal
        ref={messageRef}
        headerText="Você sairá do conteúdo da aula que está estudando!"
        headerMargin="32px 30px"
        ariaLabel={`Mensagem de confirmação para ir até o conteúdo da aula ${navSection?.order}`}
        footer={
          <NotesObjectModalFooter
            reverse
            secondaryBtn={{
              borderBoxColor: theme.colors.text.primary,
              color: theme.colors.text.primary,
              onClick: () => {
                pushAmpliEvent('event', 'notes-menu-on-change-section-cancel');
                messageRef.current?.onClose();
              },
              children: 'Cancelar',
            }}
            primaryBtn={{
              onClick: () =>
                navSection?.pathUrl
                  ? goToSection(navSection.pathUrl)
                  : goToNotesBySubject(),
              color: isDesktop
                ? theme.colors.text.white
                : theme.colors.text.primary,
              background: theme.colors.button.primary,
              children: 'Continuar',
            }}
          />
        }
      >
        <Typography color={theme.colors.text.black} size={16}>
          {modalDescription}
        </Typography>
      </NotesObjectModal>
    </>
  );
};

NotesMenuSectionList.defaultProps = {
  subjectNotes: [],
  loading: false,
};

export default NotesMenuSectionList;
