import styled from '@emotion/styled';
import { convertPXToVH, mediaQuery } from '@sofia/ui';

export const Flex = styled.div`
  display: flex;
`;

export const HeadingWrapper = styled(Flex)`
  flex-direction: column;
  row-gap: ${convertPXToVH(8)};
`;

export const HeadingTop = styled(Flex)`
  align-items: center;
  justify-content: space-between;

  ${mediaQuery('tablet-max')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SectionWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: ${convertPXToVH(56)};

  ${mediaQuery('tablet-max')} {
    row-gap: ${convertPXToVH(24)};
  }

  ${mediaQuery('desktop')} {
    margin: ${convertPXToVH(80)} 0 ${convertPXToVH(80)};
    row-gap: ${convertPXToVH(32)};
  }
`;
