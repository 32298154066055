import { authStorage } from '@ampli/utils';

const newPasswordGuard = async (to, from, next) => {
  try {
    const data = await authStorage.getItem('loginInfo');
    if (!data) throw new Error('Not allowed.');

    const isResetPasswordFlow = data.forcePasswordReset;

    if (isResetPasswordFlow === false) {
      next.redirect('/login/senha');
    } else {
      next();
    }
  } catch (error) {
    console.warn(error);
    await authStorage.removeItem('loginInfo');
    next.redirect('/login');
  }
};

export default newPasswordGuard;
