import { gql } from 'graphql.macro';

export const GET_INVOICE_HOME_MODEL = gql`
  query GetInvoiceHomeModel($courseEnrollmentId: String!) {
    getInvoiceHomeModel(courseEnrollmentId: $courseEnrollmentId) {
      invoiceSections {
        title
        invoices {
          id
          status
          title
          shortTitle
          formattedAmount
          currentDueDate
          block {
            type
            reason
          }
          details {
            paymentAlert {
              type
              message
            }
            invoiceAlert {
              type
              message
            }
            info
            notes
            items {
              title
              formattedAmount
              subItems {
                title
                formattedAmount
              }
            }
            allowGetInvoiceDocument
            allowGetInvoiceTaxDocument
            associatePayment {
              id
              title
              paidAt
              paidAmount
              paymentMethod
              invoiceId
            }
          }
          olderInvoicePaymentSuggestion
          hasExternalPayment
        }
      }
      informationType
      billingDay
    }
  }
`;

export const GET_INVOICE_BOLETO_PDF = gql`
  query GetInvoiceBoletoPdf($courseEnrollmentId: String!, $invoiceId: String!) {
    data: getInvoiceBoletoPdf(
      courseEnrollmentId: $courseEnrollmentId
      invoiceId: $invoiceId
    ) {
      url
    }
  }
`;

export const PAY_INVOICE_BY_BOLETO = gql`
  mutation PayInvoiceByBoleto(
    $courseEnrollmentId: String!
    $invoiceId: String!
  ) {
    data: payInvoiceByBoleto(
      courseEnrollmentId: $courseEnrollmentId
      invoiceId: $invoiceId
    ) {
      barcode
      barcodeImage
    }
  }
`;

export const GET_INVOICE = gql`
  query GetInvoice($courseEnrollmentId: String!, $invoiceId: String!) {
    data: getInvoice(
      courseEnrollmentId: $courseEnrollmentId
      invoiceId: $invoiceId
    ) {
      id
      status
      title
      shortTitle
      formattedAmount
      currentDueDate
      block {
        type
        reason
      }
      details {
        warning {
          type
          message
        }
        info
        notes
        items {
          title
          formattedAmount
          subItems {
            title
            formattedAmount
          }
        }
        allowGetInvoiceDocument
        associatePayment {
          id
          title
          paidAt
          paidAmount
          paymentMethod
          invoiceId
        }
      }
      olderInvoicePaymentSuggestion
    }
  }
`;

export const GET_INVOICE_DOCUMENT_URL = gql`
  query GetInvoiceDocumentUrl(
    $courseEnrollmentId: String!
    $invoiceId: String!
  ) {
    data: getInvoiceDocumentUrl(
      courseEnrollmentId: $courseEnrollmentId
      invoiceId: $invoiceId
    ) {
      invoiceDocumentUrl
    }
  }
`;
