import { useGetState } from '../../../state';

import * as Hooks from '../services/roles/hooks';

const CAPABILITIES = {
  NOTIFICATION_CENTER_ENABLED: 'NOTIFICATION_CENTER_ENABLED',
  ONBOARDING_TOUR_ENABLED: 'ONBOARDING_TOUR_ENABLED',
  PLATFORM_ONBOARDING_TOUR_ENABLED: 'PLATFORM_ONBOARDING_TOUR_ENABLED',
  PREFERENCES_COMMUNICATION_ENABLED: 'PREFERENCES_COMMUNICATION_ENABLED',
  CHAT_COPILOT_ENABLED: 'CHAT_COPILOT_ENABLED',
} as const;

export type CapabilitiesKeyType = keyof typeof CAPABILITIES;

export type CapabilitiesType = {
  [key in CapabilitiesKeyType]?: boolean;
};

export interface ICommunicationCapabilities {
  loadingCapabilities: boolean;
  communicationCapabilities: {
    platform: CapabilitiesType;
    course: CapabilitiesType;
  };
}

const capabilitiesEntries = Object.entries(CAPABILITIES);

const getCapabilitiesEnabled = (capabilities: string[]) =>
  capabilitiesEntries.reduce(
    (acc, [key, value]) => ({ ...acc, [key]: capabilities?.includes(value) }),
    {}
  );

export const useCommunicationCapabilities = (): ICommunicationCapabilities => {
  const [{ selectedCourseEnrollmentId }] = useGetState();

  const { data: platformCapabilities, loading: platformLoading } =
    Hooks.useCapabilitiesByStudentId();

  const { data: courseCapabilities, loading: courseLoading } =
    Hooks.useCapabilitiesByCourseEnrollmentId(selectedCourseEnrollmentId);

  const platform = getCapabilitiesEnabled(platformCapabilities);

  const course = getCapabilitiesEnabled(courseCapabilities);

  return {
    communicationCapabilities: { course, platform },
    loadingCapabilities: courseLoading || platformLoading,
  };
};
