export const SizeGridBase = '4px';
export const SizeFontXSmall = '12px';
export const SizeFontSmall = '14px';
export const SizeFontBase = '16px';
export const SizeFontLarge = '18px';
export const SizeFontXLarge = '20px';
export const SizeFontXXLessLarge = '22px';
export const SizeFontXXLarge = '24px';
export const SizeFontXXXLarge = '28px';
export const SizeFontLessLarger = '30px';
export const SizeFontLarger = '32px';
export const SizeFontXLarger = '36px';
export const SizeFontHuge = '40px';
export const SizeFontXHuge = '44px';

export const SizeMaxWidthScreenMiniMobile = '319px';
export const SizeMinWidthScreenMobile = '320px';
export const SizeMaxWidthScreenMobile = '500px';
export const SizeMinWidthScreenTablet = '501px';
export const SizeMaxWidthScreenTablet = '768px';
export const SizeMinWidthScreenDesktop = '769px';
export const SizeMaxWidthScreenDesktop = '1919px';
export const SizeMinWidthScreenSuper = '1920px';

export const SizeMinWidthDesktopWithoutPx = 769;

export const ColorNeutralTitle = '#444444';
export const ColorNeutralDarker = '#212121';
export const ColorNeutralDark = '#8e9294';
export const ColorNeutralDarkTransparent = 'rgba(150, 146, 144, 0.6)';
export const ColorNeutralDefault = 'rgba(142, 146, 148, 0.25)';
export const ColorNeutralLight = '#c0bebc';
export const ColorNeutralLighter = '#d6e1eb';
export const ColorNeutralSurface500 = '#b1c2d5';
export const ColorNeutralBackground = '#f5f7fb';
export const ColorNeutral900 = '#212529';
export const ColorNeutral700 = '#495057';
export const ColorNeutralLightBackground = '#ebf0f5';
export const ColorNeutralSurface = '#DBE3EC';
export const ColorNeutralSeabed = '#402fa8';
export const ColorBlackWeekDark = '#181818';

export const ColorTitleDark = ColorNeutralTitle;
export const ColorTextDark = ColorNeutralDarker;
export const ColorTextLight = ColorNeutralDark;
export const ColorTextPlaceholder = '#b3b0af';
export const ColorTextWhite = '#fff';
export const ColorTextWarn = '#DFAC48';

export const ColorLine = ColorNeutralLighter;
export const ColorBackground = ColorNeutralBackground;
export const ColorBorder = '#ecebed';

export const ColorAnhangueraLightYellow = '#feda92';
export const ColorAnhangueraLightRed = '#ff9fa3';

export const ColorBrandBaseLight = '#09bbcd';
export const ColorBrandBase = '#01b6d0';
export const ColorBrandLight = '#4dcddf';
export const ColorBrandLighter = '#ccf0f6';
export const ColorBrandDarker = '#01a4bb';
export const ColorBrandDark = '#009A98';
export const ColorBrandGreen100 = '#1AC09C';

export const ColorAccentBase = '#ffb800';
export const ColorAccentLight = '#ffce4d';
export const ColorAccentLighter = '#ffedbf';
export const ColorWarning800 = '#9F5600';

export const ColorSemanticSuccess = '#47d474';
export const ColorSemanticSuccessLight = '#91e5ac';
export const ColorSemanticSuccessLighter = '#d1f4dc';
export const ColorSemanticSuccessDark = '#3ba86b';
export const ColorSemanticError = '#ec1c40';
export const ColorDanger600 = '#A52C05';
export const ColorDanger800 = '#8D2504';
export const ColorSemanticErrorLight = '#f68ea0';
export const ColorSemanticErrorLighter = '#fac6cf';
export const ColorSemanticAlert = '#ff7a00';
export const ColorSemanticAlertLight = '#ffaf66';
export const ColorSemanticAlertLighter = '#ffebd9';
export const ColorSemanticApproved = '#0B53DE';

export const ColorFeedbackSuccess = '#008566';

export const ColorPrimary500 = '#004ECC';

export const ColorWhatsapp = '#2BB886';

export const GradientLinearBrand =
  'linear-gradient(57.25deg, #3bdbb8 0%, #00b7d1 100%)';
export const GradientLinearBrandLight =
  'linear-gradient(180deg, #ccf0f6 0%, #ffffff 100%)';
export const GradientLinearBrandAlt =
  'linear-gradient(82.37deg, #3BDBB8 0%, #01B6D0 100%)';
export const GradientLinearInactive =
  'linear-gradient(85.83deg, #c3e3dc 0%, #b7dce1 100%);';
export const GradientLinearAlert =
  'linear-gradient(42.23deg, #f4c342 0%, #ff6b00 110%)';
export const GradientLinearBrandAlpha =
  'linear-gradient(82.37deg, rgba(59, 219, 184, 0.3) 0%, #00b7d1 100%)';
export const GradientLinearWarning =
  ' linear-gradient(77.07deg, #F4C342 -0.33%, #FF7A00 110.61%);';
export const GradientLinearComingSoon =
  'linear-gradient(82.37deg, #007787 0%, #006c91 100%);';
export const GradientLinearTimBanner =
  'linear-gradient(77.20deg, #154277 40%, #317AD0 60%);';
export const GradientLinearCourseCard =
  'linear-gradient(345.53deg, #FEDA92 -10.94%, #FF9FA3 95.4%);';
export const GradientLinearCourseCardMobile =
  'linear-gradient(135deg, #FFF6B7 0%, #F6416C 100%);';

export const ColorSodexoBase = '#2A2D83';
export const ColorSodexoAccentBase = '#fe2d45';
export const ColorSodexoDark = '#858994';
export const ColorSodexoBackground = 'rgba(230, 231, 232, 0.4)';
export const ColorSodexoDivisorDashedColor = ColorSodexoAccentBase;

export const ColorBackgroundTranslucent = 'rgba(0, 0, 0, 0.13)';

export const ColorKrotonNeutralDarker = '#4D4D4D';
export const ColorBrDistribuidoraBase = '#088C50';
export const ColorBrDistribuidoraAccentBase = '#FFCB31';

export const ColorUndergraduateBrandBase = '#3bdbb8';

export const ColorFreeTrialBackground = '#ffe6c9';
export const ColorFreeTrialBorder = '#ffd399';
export const ColorFreeTrialButton = '#004ecc';

export const ColorAnhangueraSecondary = '#0b589a';
export const ColorAnhangueraBlue = '#17AAF3';
export const ColorAnhangueraPrimary = '#e85a13';
export const ColorAnhangueraOrange = '#cd4c0a';
export const ColorAnhangueraMediumBlue = '#1289D0';
export const ColorAnhangueraNeutralBackground = '#fcfcfc';
export const ColorAnhangueraShadow = 'rgba(29, 29, 29, 0.24)';
export const ColorAnhangueraAlertBanner = '#eaeaea';
export const ColorAnhangueraAlertButtonBackground = '#f2f2f2';
export const ColorAnhangueraAlertButtonIcon = '#5f6368';
export const ColorAnhangueraAlertButtonShadow = '#a6a5a5';
export const ColorAnhangueraHover = '#f38517';
export const ColorAnhangueraBlack = '#1e1e1e';
export const ColorAnhangueraLighterOrange = '#fdceb8';
export const ColorAnhangueraLightOrange = '#f9ad8e';
export const ColorAnhangueraNeutralLighter = '#83D4FA';
