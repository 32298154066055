import { urlToBlobUrl } from "./conversions";
export const downloadFile = async function (url) {
  let settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  try {
    const link = document.createElement('a');
    link.href = await urlToBlobUrl(url, settings);
    link.download = settings.fileName || true;
    link.dispatchEvent(new MouseEvent('click'));
  } catch (e) {
    console.error(e);
    throw new Error(`Can't download the url file: ${url}`);
  }
};