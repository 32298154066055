import React from 'react';
import NotesMenuList from '../notes-menu-list';
import NotesMenuObjectCard from './card/notes-menu-object-card';
import NotesMenuObjectNewCard from './card/new/notes-menu-object-new-card';
import NotesMenuObjectHeader from './header/notes-menu-object-header';
import { defaultNote, getLearningObjectTitle } from './helper';
import NotesMenuFooter from '../../footer/notes-menu-footer';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useGetState } from '../../../../../../../../../state';
import { SectionRoutesPath } from '../../../../../../../../section';
import { pushAmpliEvent } from '../../../../../../../../../lib/ga';

import { Typography } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import {
  GET_ANNOTATION_BY_COURSE_ENROLLMENT,
  GET_ANNOTATION_BY_COURSE_ENROLLMENT_AND_SUBJECT,
} from '@ampli/services';
import {
  NotesModal,
  NotesObjectForm,
  NotesObjectModal,
  NotesObjectModalFooter,
  NoteType,
} from '../../../../../../../../notes/shared';
import Skeleton from '../../../../../../skeleton/skeleton';
import { NotesMenuObjectListProps } from '../../types';
import { GlobalParams } from '../../../../../../../..';

const NotesMenuObjectList = ({
  subjectNotes,
  section,
  learningUnitOrder,
  learningUnitTitle,
  learningUnitId,
  learningObjectId,
  learningObjects,
  subjectId,
  onReturnToSectionList,
  isCurrentSection,
  onReturnCurrentSectionNotes,
  loading,
  learningUnitVersionId,
}: NotesMenuObjectListProps): React.ReactElement => {
  const theme = useTheme();
  const [crudNote, setCrudNote] = React.useState<NoteType | null>(null);
  const [sectionNotes, setSectionNotes] = React.useState<NoteType[]>([]);
  const messageRef = React.useRef<NotesModal>(null);
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const history = useHistory();
  const [
    {
      selectedCourseEnrollmentType,
      selectedCourseVersionId,
      selectedCourseEnrollmentId,
    },
  ] = useGetState();
  const { subjectEnrollmentId } = useParams<GlobalParams>();

  React.useEffect(() => {
    setSectionNotes(
      subjectNotes.length > 0
        ? subjectNotes.filter((note) => note.sectionId === section?.id)
        : []
    );
  }, [section, subjectNotes]);

  const onSave = (savedNote: NoteType) => {
    if (crudNote?.id) {
      setSectionNotes(
        sectionNotes.map((note) =>
          savedNote.id === note.id
            ? { ...note, content: savedNote.content }
            : note
        )
      );
    } else {
      setSectionNotes([...sectionNotes, savedNote]);
    }
  };

  const onDelete = (deletedNoteId: string) => {
    const sectionNotesFiltered = sectionNotes.filter(
      (note) => note.id !== deletedNoteId
    );
    setSectionNotes(sectionNotesFiltered);
  };

  const goToSection = () => {
    pushAmpliEvent('event', 'notes-menu-on-change-section');
    history.push(
      generatePath(SectionRoutesPath.subjectSection, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
        subjectEnrollmentId: subjectEnrollmentId,
        learningUnitEnrollmentId: learningUnitId,
        sectionPath: section?.pathUrl || '',
        fromSubjectPage: true,
      })
    );
  };

  const onNewNote = () => {
    if (!subjectId || !section || !learningObjectId || !learningUnitVersionId)
      return;
    pushAmpliEvent('event', 'notes-menu-new-note');
    setCrudNote(
      defaultNote({
        courseEnrollmentId: selectedCourseEnrollmentId,
        sectionId: section.id,
        subjectId,
        learningObjectId,
        createdDate: new Date().toISOString(),
        learningUnitVersionId,
      })
    );
  };

  const getNoteTitle = (learningObjectId: string) =>
    getLearningObjectTitle(
      learningObjectId,
      isCurrentSection ? learningObjects : section?.learningObjects
    );

  const refetchQueries = [
    {
      query: GET_ANNOTATION_BY_COURSE_ENROLLMENT_AND_SUBJECT,
      variables: {
        courseEnrollmentId: selectedCourseEnrollmentId,
        subjectId,
      },
    },
    {
      query: GET_ANNOTATION_BY_COURSE_ENROLLMENT,
      variables: { courseEnrollmentId: selectedCourseEnrollmentId },
    },
  ];

  return (
    <>
      <NotesMenuObjectHeader
        title={`Unidade ${learningUnitOrder}`}
        desc={learningUnitTitle}
        onReturnToSectionList={onReturnToSectionList}
        loading={loading}
      />

      <NotesMenuList
        isObject
        title={`Aula ${section?.order}`}
        desc={`${
          sectionNotes.length === 0 ? 'SEM' : sectionNotes.length
        } ANOTAÇ${sectionNotes.length === 1 ? 'ÃO' : 'ÕES'}`}
        loading={loading}
      >
        {loading ? (
          <>
            <Skeleton height="90px" borderRadius="8px" />
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} height="160px" borderRadius="8px" />
            ))}
          </>
        ) : (
          <>
            {crudNote ? (
              <NotesObjectForm
                note={crudNote}
                title={getNoteTitle(crudNote.learningObjectId)}
                onCancel={() => setCrudNote(null)}
                onSave={onSave}
                onDelete={onDelete}
                queryOptions={{ refetchQueries }}
              />
            ) : null}

            {!crudNote && isCurrentSection ? (
              <NotesMenuObjectNewCard
                notesSize={sectionNotes.length}
                onNewNote={onNewNote}
              />
            ) : null}

            {!crudNote
              ? sectionNotes.map((note, index) => (
                  <NotesMenuObjectCard
                    key={index}
                    title={getNoteTitle(note.learningObjectId)}
                    content={note.content}
                    onEdit={() => {
                      pushAmpliEvent('event', 'notes-menu-edit-note');
                      setCrudNote(note);
                    }}
                  />
                ))
              : null}
          </>
        )}
      </NotesMenuList>

      {!isCurrentSection && !crudNote ? (
        <NotesMenuFooter
          onReturnCurrentSectionNotes={onReturnCurrentSectionNotes}
          buttonText={`Acessar o conteúdo da Aula ${section?.order}`}
          buttonClick={(event) => messageRef.current?.onOpen(event)}
        />
      ) : null}

      <NotesObjectModal
        ref={messageRef}
        headerText="Você quer acessar outra aula?"
        ariaLabel={`Mensagem de confirmação para ir até o conteúdo da aula ${section?.order}`}
        footer={
          <NotesObjectModalFooter
            reverse
            secondaryBtn={{
              borderBoxColor: theme.colors.text.primary,
              color: theme.colors.text.primary,
              onClick: () => {
                pushAmpliEvent('event', 'notes-menu-on-change-section-cancel');
                messageRef.current?.onClose();
              },
              children: 'Cancelar',
            }}
            primaryBtn={{
              onClick: goToSection,
              color: isDesktop
                ? theme.colors.text.white
                : theme.colors.text.primary,
              background: theme.colors.button.primary,
              children: 'Continuar',
            }}
          />
        }
      >
        <Typography color={theme.colors.text.black} size={16}>
          Se você continuar, você irá sair da aula atual e acessar outra aula
          dessa unidade.
        </Typography>
      </NotesObjectModal>
    </>
  );
};

NotesMenuObjectList.defaultProps = {
  isCurrentSection: true,
  subjectNotes: [],
  loading: false,
};

export default NotesMenuObjectList;
