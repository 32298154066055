import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@sofia/ui';

const CardResume = ({ title, institution }) => {
  return (
    <>
      <Typography color="#444444" size={16} margin="0px 0px 4px 0px">
        {title}
      </Typography>
      <Typography color="#8E9294" size={16} margin="0px 0px 4px 0px">
        {institution}
      </Typography>
    </>
  );
};

CardResume.propTypes = {
  title: string,
  institution: string,
};

export default CardResume;
