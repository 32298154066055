import * as Types from '../../../shared/graphql/types';

import * as Operations from './queries';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@ampli/services';
import { TLazyQueryHook, TQueryHook } from '../../../shared';
const defaultOptions = {} as const;
export type FindLearningUnitEnrollmentsForSectionPageQueryVariables =
  Types.Exact<{
    subjectEnrollmentId: Types.Scalars['ID']['input'];
  }>;

export type FindLearningUnitEnrollmentsForSectionPageQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'LearningUnitEnrollment';
    id: string;
    startDate?: string | null;
    endDate?: string | null;
    learningUnitId: string;
    tempLearningUnitId?: string | null;
    learningUnitVersionId: string;
    title: string;
    status: Types.LearningUnitStatus;
    order: number;
    template?: {
      __typename?: 'ContentTemplate';
      background?: string | null;
      name: string;
      assetElementConfigs: Array<{
        __typename?: 'AssetElementConfig';
        backgroundColor?: string | null;
        bannerUrl?: string | null;
        borderStyle?: Types.BorderStyle | null;
        element: Types.AssetElement;
        fontColor?: string | null;
        mobileBannerUrl?: string | null;
      } | null>;
    } | null;
    sections: Array<{
      __typename?: 'SectionEnrollment';
      id: string;
      title: string;
      order: number;
      pathUrl?: string | null;
      progress?: {
        __typename?: 'EnrollmentProgress';
        quantity?: number | null;
        completed?: number | null;
        percentage?: number | null;
        percentageLabel?: string | null;
      } | null;
      learningObjects: Array<{
        __typename?: 'LearningObjectEnrollment';
        id: string;
        title: string;
        subtitle?: string | null;
        completed: boolean;
        duration?: number | null;
        type?: Types.LearningObjectType | null;
        pathUrl?: string | null;
        assets: Array<Types.AssetItem>;
      }>;
    }>;
  }>;
};

/**
 * __useFindLearningUnitEnrollmentsForSectionPageQuery__
 *
 * To run a query within a React component, call `useFindLearningUnitEnrollmentsForSectionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLearningUnitEnrollmentsForSectionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLearningUnitEnrollmentsForSectionPageQuery({
 *   variables: {
 *      subjectEnrollmentId: // value for 'subjectEnrollmentId'
 *   },
 * });
 */
export function useFindLearningUnitEnrollmentsForSectionPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindLearningUnitEnrollmentsForSectionPageQuery,
    FindLearningUnitEnrollmentsForSectionPageQueryVariables
  >
): Apollo.QueryResult<
  FindLearningUnitEnrollmentsForSectionPageQuery,
  FindLearningUnitEnrollmentsForSectionPageQueryVariables
> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useQuery as TQueryHook)<
    FindLearningUnitEnrollmentsForSectionPageQuery,
    FindLearningUnitEnrollmentsForSectionPageQueryVariables
  >(Operations.FindLearningUnitEnrollmentsForSectionPage, options);
}
export function useFindLearningUnitEnrollmentsForSectionPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindLearningUnitEnrollmentsForSectionPageQuery,
    FindLearningUnitEnrollmentsForSectionPageQueryVariables
  >
): Apollo.QueryTuple<
  FindLearningUnitEnrollmentsForSectionPageQuery,
  FindLearningUnitEnrollmentsForSectionPageQueryVariables
> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useLazyQuery as TLazyQueryHook)<
    FindLearningUnitEnrollmentsForSectionPageQuery,
    FindLearningUnitEnrollmentsForSectionPageQueryVariables
  >(Operations.FindLearningUnitEnrollmentsForSectionPage, options);
}
export type FindLearningUnitEnrollmentsForSectionPageQueryHookResult =
  ReturnType<typeof useFindLearningUnitEnrollmentsForSectionPageQuery>;
export type FindLearningUnitEnrollmentsForSectionPageLazyQueryHookResult =
  ReturnType<typeof useFindLearningUnitEnrollmentsForSectionPageLazyQuery>;
export type FindLearningUnitEnrollmentsForSectionPageQueryResult =
  Apollo.QueryResult<
    FindLearningUnitEnrollmentsForSectionPageQuery,
    FindLearningUnitEnrollmentsForSectionPageQueryVariables
  >;
