import { gql } from 'graphql.macro';

export const APPLY_INVOICE_COUPON = gql`
  mutation ApplyInvoiceCoupon($courseEnrollmentId: ID!, $couponCode: String!) {
    data: applyInvoiceCoupon(
      courseEnrollmentId: $courseEnrollmentId
      couponCode: $couponCode
    ) {
      couponName
      instalmentAmount
      totalInstalmentAmount
      startDate
      endDate
      couponAmount
      numberOfInstalmentsWithCoupon
      couponAppliedDate
    }
  }
`;
