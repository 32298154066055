import React from 'react';

const SevenMbsFileSizeLimit: React.FC = (): React.ReactElement => {
  return (
    <svg
      width="66"
      height="22"
      viewBox="0 0 66 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7503 1.456V4.496L7.51025 22H1.71825L7.76625 5.776H0.72625V1.456H13.7503ZM31.0903 6.032H31.6663C34.5036 6.032 35.9223 7.35467 35.9223 10V22H30.4823V10.736C30.4823 10.2667 30.2156 10.032 29.6823 10.032H29.1383C28.5623 10.032 28.2743 10.352 28.2743 10.992V22H22.8343V10.736C22.8343 10.2667 22.5676 10.032 22.0343 10.032H21.4903C20.9143 10.032 20.6263 10.352 20.6263 10.992V22H15.1863V6.032H20.5303V8.08C20.8289 6.71467 21.7996 6.032 23.4423 6.032H24.0183C26.0023 6.032 27.3036 6.704 27.9223 8.048C28.0929 7.42933 28.4663 6.93867 29.0423 6.576C29.6183 6.21333 30.3009 6.032 31.0903 6.032ZM46.5673 6.032H47.1433C49.9806 6.032 51.3993 7.35467 51.3993 10V18.032C51.3993 20.6773 49.9806 22 47.1433 22H46.5673C44.9246 22 43.9539 21.3173 43.6553 19.952V22H38.3113V0.591999H43.7513V7.76C44.1566 6.608 45.0953 6.032 46.5673 6.032ZM45.9593 17.296V10.736C45.9593 10.2667 45.6926 10.032 45.1593 10.032H44.6153C44.0393 10.032 43.7513 10.352 43.7513 10.992V17.04C43.7513 17.68 44.0393 18 44.6153 18H45.1593C45.6926 18 45.9593 17.7653 45.9593 17.296ZM60.5038 17.776V16.496C60.5038 16.0267 60.2371 15.792 59.7038 15.792H57.7518C54.6798 15.792 53.1438 14.48 53.1438 11.856V10C53.1438 7.35467 54.7118 6.032 57.8478 6.032H60.8238C63.9598 6.032 65.5278 7.35467 65.5278 10V11.344H60.3438V10.256C60.3438 9.78667 60.0771 9.552 59.5438 9.552H59.2238C58.6904 9.552 58.4238 9.78667 58.4238 10.256V11.664C58.4238 12.1333 58.6904 12.368 59.2238 12.368H61.1758C62.8184 12.368 63.9918 12.7093 64.6958 13.392C65.4211 14.0533 65.7838 15.0133 65.7838 16.272V18.032C65.7838 20.6773 64.2158 22 61.0798 22H57.9438C54.8078 22 53.2398 20.6773 53.2398 18.032V16.72H58.5198V17.776C58.5198 18.2453 58.7864 18.48 59.3198 18.48H59.7038C60.2371 18.48 60.5038 18.2453 60.5038 17.776Z"
        fill="black"
      />
    </svg>
  );
};

export default SevenMbsFileSizeLimit;
