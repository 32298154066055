import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { convertPXToVW, convertPXToVH } from '@sofia/ui';

export const WrapperSurface = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
  padding: 40px;
  display: flex;
  margin-top: 24px;

  @media (max-width: 769px) {
    padding: 32px;

    svg {
      display: none;
    }
  }
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 52px;

  @media (max-width: 769px) {
    margin-left: 0;
  }
`;

export const ResumeCardTitleWrapper = styled.div`
  margin: 0px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    display: block;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWidth = styled.div`
  display: flex;

  ${({ align }) => align && `align-items: end;`}
  button {
    min-width: ${convertPXToVW(456)};
  }

  @media (max-width: 769px) {
    display: ${({ flex }) => (flex ? 'flex' : 'block')};
    align-items: center;
    margin-top: 24px;
    button {
      min-width: 100%;
      margin-bottom: 16px;
    }
  }
`;

export const ModalTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginB }) => marginB};
  justify-content: ${({ content }) => content};

  ${({ grid }) =>
    grid &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
    `}

  @media (max-width: 769px) {
    display: ${({ flex }) => (flex ? 'flex' : 'block')};

    align-items: ${({ align }) => (align ? 'center' : 'unset')};

    ${({ direction }) => css`
      flex-direction: ${direction};
    `}
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardStatus = styled.div`
  background-color: ${({ color }) => color};
  padding: 24px;
  text-align: center;
  margin: 0 0 24px 24px;

  @media (max-width: 769px) {
    margin: 0;
  }
`;

export const GrayRetangle = styled.div`
  height: 16px;
  border-radius: 4px;
  width: 16px;
  background-color: ${({ theme }) => theme.colors.checkbox.border.primary};
`;

export const TipsForYouWrapper = styled.div`
  padding: 32px 94px 32px 32px;
  border: 2px #b1c2d5 solid;
  border-radius: 8px;
  display: flex;

  @media (max-width: 769px) {
    margin: 0;
    display: block;
    padding: 25px 25px 25px 10px;
  }
`;

export const TipsTextWrapper = styled.div`
  margin-left: 50px;

  @media (max-width: 769px) {
    margin: 60px 0 0 20px;
  }
`;

export const FlexGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FeedbackWrapper = styled.div`
  height: 100%;
  max-height: ${convertPXToVH(302)};
  overflow-x: scroll;
`;
