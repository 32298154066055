import React from 'react';

import {
  CopyToClipboard,
  ButtonPrimary,
  Typography,
  SvgPhone,
  Toast,
} from '@sofia/ui';

import { BULLET_POINT_TYPE_ENUM } from '../../constants';
import { array, isMobile } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import { string, func } from 'prop-types';
import { useTheme } from '@emotion/react';

import * as Styled from './bullet-points.styles';

export default function SuspensionCancellationBulletPoints({
  action,
  warnings,
  recipent,
  recipentType,
  onGoBack,
}) {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  let timeout;

  const [toastState, setToastState] = React.useState(false);

  const commonId = action.toLowerCase();

  const handleToast = () => {
    if (!toastState) {
      setToastState(true);
      timeout = setTimeout(() => {
        setToastState(false);
      }, 5000);
    }
    return null;
  };

  const lowercaseFirstLetter = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
  };

  const trimmedRecipent = recipent.replace('-', '');

  React.useEffect(() => {
    return timeout && clearTimeout(timeout);
  });

  return (
    <>
      <Typography
        weight="700"
        color={theme.colors.text.black}
        size={isDesktop ? 32 : 24}
        margin="0 0 8px 0"
        id={`title-${commonId}`}
      >
        {BULLET_POINT_TYPE_ENUM[action].TITLE}
      </Typography>

      <Styled.Flex>
        <Styled.FlexColumn>
          <Typography
            color={theme.colors.text.black}
            size={16}
            margin="0 0 40px 0"
            id={`subtitle-${commonId}`}
          >
            {BULLET_POINT_TYPE_ENUM[action].SUBTITLE}
          </Typography>

          <Styled.WarningTitle id={`warning-title-${commonId}`}>
            <Styled.Text color={theme.colors.text.danger}>
              IMPORTANTE
            </Styled.Text>

            <Styled.Text color={theme.colors.text.black}>
              : Antes de realizar o{' '}
              {lowercaseFirstLetter(BULLET_POINT_TYPE_ENUM[action].TITLE)} do
              curso, informamos que:
            </Styled.Text>
          </Styled.WarningTitle>

          <ul id={`warnings-list-${commonId}`}>
            {warnings.map((warning, index) => {
              return (
                <li key={index} id={`warning-${commonId}-${index}`}>
                  {warning}
                </li>
              );
            })}
          </ul>
        </Styled.FlexColumn>

        <Styled.ContactCard id={`relationship-card-${commonId}`}>
          {/* title and subtitle */}
          <Typography
            weight="700"
            color={theme.colors.text.black}
            size={isDesktop ? 24 : 20}
            margin="0 0 8px 0"
            id={`relationship-title-${commonId}`}
          >
            Central de Relacionamento com Aluno
          </Typography>

          <Typography
            color={theme.colors.text.black}
            size={16}
            margin="16px 0"
            id={`relationship-subtitle-${commonId}`}
          >
            {BULLET_POINT_TYPE_ENUM[action][recipentType].DESC}
          </Typography>

          {/* copy value */}
          <CopyToClipboard
            background="#EBF0F5"
            colorIcon={theme.colors.text.primary}
            colorText={theme.colors.text.black}
            margin={0}
            value={recipent}
            textSize={isDesktop ? 24 : recipentType === 'EMAIL' ? 16 : 20}
            onClick={handleToast}
            id={`relationship-copy-${commonId}`}
          />

          <Typography
            color={theme.colors.text.black}
            size={16}
            margin="8px 0 16px 0"
            id={`relationship-copy-text-${commonId}`}
          >
            Clique no{' '}
            {lowercaseFirstLetter(
              BULLET_POINT_TYPE_ENUM[action][recipentType].ENUM
            )}{' '}
            acima para copiar
          </Typography>

          {/* actions */}
          {isMobile() && (
            <ButtonPrimary
              background={theme.colors.button.primary}
              href={`tel:${trimmedRecipent.replace(/\D/g, '')}`}
              tabindex="0"
              color="#fff"
              width="100%"
              margin="0 0 16px 0"
              id={`relationship-call-action-${commonId}`}
            >
              <SvgPhone />
              Ligar
            </ButtonPrimary>
          )}

          <Styled.ButtonWrapper
            tabindex="0"
            color={theme.colors.button.primary}
            borderBoxColor={theme.colors.button.backgroundColor}
            Borderhover={theme.colors.button.darkPrimary}
            width="100%"
            onClick={onGoBack}
            id={`relationship-back-action-${commonId}`}
          >
            Voltar
          </Styled.ButtonWrapper>
        </Styled.ContactCard>
      </Styled.Flex>

      <Toast
        type="success"
        show={toastState}
        text={`${BULLET_POINT_TYPE_ENUM[action][recipentType].ENUM} copiado com sucesso.`}
      />
    </>
  );
}

SuspensionCancellationBulletPoints.propTypes = {
  action: string.isRequired,
  warnings: array.isRequired,
  recipentType: string.isRequired,
  recipent: string.isRequired,
  onGoBack: func.isRequired,
};
