import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ColLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1.5;
  padding: 20px 20px 20px 0;
  ${mediaQuery('mobile')} {
    justify-content: center;
  }
`;

export const ContainerText = styled.div`
  margin-right: 20px;

  @media (max-width: 1024px) {
    margin: 20px 20px 0 0;
  }

  ${mediaQuery('mobile')} {
    margin: 20px 0;
    max-width: none;
  }
`;

export const ColRight = styled.div`
  display: flex;
  flex: 1;
  ${mediaQuery('mobile')} {
    justify-content: center;
  }
`;
