import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, SvgUnlock } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '@ampli/hooks';
import { string, includes, array } from '@ampli/utils';
import { StudentEmoji } from '@ampli/ui';
import {
  notActiveFreeTrialStatus,
  freeTrialEnabledRole,
} from '../../constants';
import {
  BannerContainer,
  InfoContainer,
  StyledButton,
  StyledSvgGuideBook,
} from './paywall-banner.styles';

const PaywallBanner = ({
  studentFreeTrialCourseData,
  studentFreeTrialCourseDataRoles = [],
  selectedCourseEnrollmentType,
  selectedCourseVersionId,
}) => {
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const theme = useTheme();

  const isFreeTrial = !includes(
    notActiveFreeTrialStatus,
    studentFreeTrialCourseData
  );
  const isFreeTrialEnabled = includes(
    studentFreeTrialCourseDataRoles,
    freeTrialEnabledRole
  );

  const onButtonClick = () => {
    history.push(
      `/${selectedCourseEnrollmentType}/${selectedCourseVersionId}/pagamento`
    );
  };

  return (
    <>
      {isFreeTrialEnabled && isFreeTrial && (
        <BannerContainer id="card-transform-your-future" theme={theme}>
          <StyledSvgGuideBook />
          <InfoContainer>
            <div>
              <Typography
                size={32}
                weight="700"
                margin={!isDesktop && '35px 0px 0px'}
                color={theme.colors.text.black}
                id="title-transform-your-future"
              >
                Transforme seu futuro! <StudentEmoji />
              </Typography>
              <Typography
                size={16}
                weight="400"
                color={theme.colors.text.black}
                margin="24px 0px"
                id="description-transform-your-future"
              >
                Quer ter acesso exclusivo a provas, atividades e outros
                benefícios da sua jornada de estudos? Faça sua matrícula e
                desbloqueie esses benefícios!
              </Typography>
            </div>
            <StyledButton
              background={theme.colors.button.primary}
              color={theme.colors.button.secondary}
              width="40%"
              margin="12px 0px"
              onClick={onButtonClick}
              id="link-transform-your-future"
            >
              Desbloquear conteúdo <SvgUnlock fill={theme.colors.text.white} />
            </StyledButton>
          </InfoContainer>
        </BannerContainer>
      )}
    </>
  );
};

PaywallBanner.propTypes = {
  studentFreeTrialCourseData: string,
  studentFreeTrialCourseDataRoles: array,
  selectedCourseEnrollmentType: string,
  selectedCourseVersionId: string,
};

export default PaywallBanner;
