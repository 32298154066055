import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  max-width: 390px;
  margin: 2.46vh 0;
`;

export const ContainerCard = styled.div`
  width: 100%;
`;

export const ContainerTitle = styled.div`
  width: 150px;
  margin-bottom: 2.05vh;
`;

export const ContainerTitleCourse = styled.div`
  width: 150px;
`;

export const ContainerNumberOfCourse = styled.div`
  width: 250px;
  margin-top: 3.2vh;
  margin-bottom: 4.1vh;
`;
