import { isBetweenDates } from '../../../../section/shared';
import { ELiveState, ILive } from '../types';

const checkState = (live: ILive | Record<string, never>): ELiveState => {
  return isBetweenDates(live.startDateTime, live.endDateTime)
    ? ELiveState.LIVE
    : ELiveState.SCHEDULED;
};

export default checkState;
