import styled from '@emotion/styled';
import {
  convertPXToVW,
  convertPXToVH,
  SvgUser,
  SvgBookClosed,
  Modal,
} from '@sofia/ui';
import { mediaQuery } from '@ampli/ui';
import { themeWhiteLabel } from '../../themes/theme';

export const Line = styled.div`
  background: ${themeWhiteLabel.colors.text.lighterBlack};
  width: 30%;
  height: ${convertPXToVH(1)};

  ${mediaQuery('mobile')} {
    width: 25%;
  }
`;

export const BenefitContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: ${convertPXToVH(20)};

  ${mediaQuery('mobile')} {
    display: flex;
    flex-direction: column;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: ${convertPXToVH(12)};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px ${convertPXToVW(12)} ${convertPXToVH(10)};
`;

export const BenefitCard = styled.div`
  width: 48%;
  border: 1px solid ${themeWhiteLabel.colors.card.border.primary};
  border-radius: 8px;
  padding: ${convertPXToVW(15)} ${convertPXToVH(15)};

  ${mediaQuery('mobile')} {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const StyledBookClosedIcon = styled(SvgBookClosed)`
  width: ${convertPXToVW(20)};
  margin: ${convertPXToVW(5)} ${convertPXToVH(8)};

  ${mediaQuery('mobile')} {
    width: ${convertPXToVW(30)};
  }
`;

export const StyledUserIcon = styled(SvgUser)`
  width: ${convertPXToVW(30)};
  margin: ${convertPXToVW(5)} ${convertPXToVH(8)};

  ${mediaQuery('mobile')} {
    width: ${convertPXToVW(40)};
  }
`;

export const Img = styled.img`
  max-width: ${convertPXToVW(100)};
  max-height: ${convertPXToVW(210)};
  margin-right: ${convertPXToVH(20)};
  margin-bottom: ${convertPXToVH(10)};
`;

export const ButtonContainer = styled.div`
  height: ${convertPXToVH(56)};
  margin: ${convertPXToVW(40)} ${convertPXToVH(32)};

  ${mediaQuery('desktop')} {
    margin: ${convertPXToVW(25)} ${convertPXToVH(25)};
  }
`;

export const StyledModal = styled(Modal)`
  ${mediaQuery('desktop')} {
    width: ${convertPXToVW(500)} !important;

    #headerTitle {
      margin: ${convertPXToVW(20)} 0 !important;
    }
  }
`;
