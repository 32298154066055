import React from 'react';
import * as Styled from './live-thumbnail.styles';
import desktopThumbnail from './thumbnail-desktop.png';
import mobileThumbnail from './thumbnail-mobile.png';
import { useBreakpoint } from '../../../../../shared';

const LiveThumbnailImg = (): React.ReactElement => {
  const { isDesktop } = useBreakpoint();
  return (
    <Styled.Img
      data-testid="live-access-card-thumbnail"
      alt="Estamos ao vivo"
      src={isDesktop ? desktopThumbnail : mobileThumbnail}
    />
  );
};

export default LiveThumbnailImg;
