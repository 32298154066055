import React from 'react';

import { TextArea, ButtonPrimaryLong, MultipleFileUpload } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { Loader } from '@ampli/ui';

import { useAmpliFrame } from '../../contexts';
import { UploadedFile } from '../../../../shared';

import * as Styled from '../../../../../../../shared/components/section-right-frame/section-right-frame.styles';

const FormSection: React.FC = () => {
  const {
    hideAttachmentField,
    onUploadFile,
    scrollType,
    onSubmit,
    loading,
    formik,
  } = useAmpliFrame();

  const theme = useTheme();

  return (
    <Styled.ListWrapper scroll={scrollType} onSubmit={onSubmit}>
      <Styled.FieldWrapper>
        <TextArea
          rows="6"
          height="unset"
          label="Descreva com detalhes a sua dúvida."
          placeholder="Escreva a sua dúvida aqui"
          labelMarginBottom="24px"
          name="description"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description}
          infoText={formik.errors.description || null}
          error={Boolean(
            formik.touched.description && formik.errors.description
          )}
        />
      </Styled.FieldWrapper>

      {hideAttachmentField ? null : (
        <Styled.FieldWrapper>
          <MultipleFileUpload
            title="Anexar arquivo"
            error={Boolean(
              formik.touched.attachment && formik.errors.attachment
            )}
            onClick={(files: UploadedFile[]) =>
              onUploadFile('attachment', files)
            }
          />
        </Styled.FieldWrapper>
      )}

      <ButtonPrimaryLong
        type="submit"
        color={theme.colors.text.white}
        background={theme.colors.button.primary}
        disabled={loading}
      >
        {loading ? (
          <Loader
            fullScreen={false}
            className="loader-no-background"
            color="#FFFFFF"
            size={18}
          />
        ) : (
          'Enviar'
        )}
      </ButtonPrimaryLong>
    </Styled.ListWrapper>
  );
};

export default FormSection;
