import React from 'react';

import { SvgNotes, Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { NotesButtonProps } from '../types';
import { useRolesContext } from '../../../../contexts/communication-roles-context';
import { useBreakpoint } from '../../../../hooks';
import { useGetState } from '../../../../../../state';

import * as Styled from './notes-button.styles';

const NotesButton = ({
  onOpenNotesClick,
}: NotesButtonProps): React.ReactElement => {
  const [hasActiveTour, setHasActiveTour] = React.useState(false);

  const theme = useTheme();
  const { isDesktop } = useBreakpoint();
  const [{ openNotes }] = useGetState();

  const buttonColor = openNotes
    ? theme.colors.button.primary
    : theme.colors.text.light;

  const { communicationCapabilities } = useRolesContext();

  const hasSomeTourEnabled =
    communicationCapabilities?.course.ONBOARDING_TOUR_ENABLED ||
    communicationCapabilities?.platform.PLATFORM_ONBOARDING_TOUR_ENABLED;

  React.useEffect(() => {
    window.addEventListener('hasActiveTour', () => {
      if (hasSomeTourEnabled) setHasActiveTour(true);
    });

    return () => {
      window.removeEventListener('hasActiveTour', () => {
        setHasActiveTour(false);
      });
    };
  }, [hasActiveTour, hasSomeTourEnabled]);

  return (
    <Styled.NotesButton
      /* styled props */
      color={theme.colors.button.primary}
      data-testid="notes-button"
      onClick={onOpenNotesClick}
      aria-label="Minhas anotações"
    >
      <SvgNotes fill={buttonColor} />
      {isDesktop && !hasActiveTour ? (
        <Typography color={buttonColor}>Minhas anotações</Typography>
      ) : null}
    </Styled.NotesButton>
  );
};

export default NotesButton;
