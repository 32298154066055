import { useParams } from 'react-router-dom';
import { useGetSubjectCalendar } from './subject-calendar/index';

const useSubjectCalendarData = () => {
  const { subjectEnrollmentId } = useParams();

  const { data: calendarData, loading: calendarDataLoading } =
    useGetSubjectCalendar(subjectEnrollmentId);

  return {
    calendarData,
    loading: calendarDataLoading,
  };
};

export default useSubjectCalendarData;
