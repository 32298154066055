import { AK_DOWN, AK_LEFT, AK_RIGHT, AK_UP } from '../../constants';
const tabsKeyNavigation = (key, elList, onNavigated) => {
  const elements = elList.filter(el => el.getAttribute('aria-disabled') === 'false' && el.getAttribute('aria-hidden') === 'false');
  const lastIndex = elements.length - 1;
  const targetIndex = elements.findIndex(el => el === key.target);
  const targetEl = elements[targetIndex];
  const nextEl = elements[targetIndex + 1 <= lastIndex ? targetIndex + 1 : 0];
  const previousEl = elements[targetIndex - 1 >= 0 ? targetIndex - 1 : lastIndex];
  if ([AK_RIGHT, AK_DOWN].indexOf(key.keyCode) >= 0 && nextEl) {
    targetEl.blur();
    nextEl.focus();
    const nextElId = nextEl.getAttribute('id');
    onNavigated(nextElId.split('tab-')[1]);
  }
  if ([AK_LEFT, AK_UP].indexOf(key.keyCode) >= 0 && previousEl) {
    targetEl.blur();
    previousEl.focus();
    const previousElId = previousEl.getAttribute('id');
    onNavigated(previousElId.split('tab-')[1]);
  }
};
export default tabsKeyNavigation;