import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: ${({ isHoliday, isEmptyToday }) =>
    isHoliday || isEmptyToday ? '0' : '0 16px'};
  margin-bottom: 16px;
  border-radius: 8px;
  background: white;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 1px;
    width: 3px;
    height: 100%;
    border-radius: 10px;
    background-color: ${({ typeColor }) => typeColor};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.background.primary};
  }

  > span {
    margin: 0;
    padding: 8px 0;
  }
`;

export const Badge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;

  p {
    margin-right: 8px;
    font-family: inherit;
    font-size: ${convertPXToREM(14)};
    font-weight: ${({ type }) => (type === 'Feriado' ? '400' : 'bold')};
    color: ${({ type, theme }) =>
      type === 'Feriado'
        ? theme.colors.text.lighterBlack
        : theme.colors.text.secondary};
  }
`;

const TagStyleMap = {
  ACADEMIC_DATES: {
    text: '#0047FF',
    fill: '#0047FF0D',
    stroke: '#0047FF80',
  },
  UNIQUE_EVENTS: {
    text: '#BC00CC',
    fill: '#BC00CC0D',
    stroke: '#BC00CC80',
  },
  SECRETARIAT: {
    text: '#8000FF',
    fill: '#8000FF0D',
    stroke: '#8000FF80',
  },
  FINANCIAL: {
    text: '#FF7A00',
    fill: '#FF7A000D',
    stroke: '#FF7A0080',
  },
};

export const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  max-width: 40px;
  max-height: 20px;

  padding: 1px 7px;

  border-radius: 2px;
  border: 1px solid ${({ tagType }) => TagStyleMap[tagType].stroke};
  background: ${({ tagType }) => TagStyleMap[tagType].fill};

  color: ${({ tagType }) => TagStyleMap[tagType].text};
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

export const ContaierTitle = styled.div`
  padding: 4px 0;

  h1 {
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.breadCrumbs.primary};
  }
`;

export const Temporizer = styled.p`
  font-weight: 400 !important;
  color: ${({ theme, type }) =>
    type !== 'Feriado'
      ? theme.colors.text.secondary
      : theme.colors.text.lighterBlack};
`;

export const Containericon = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

export const EventTime = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #444444;
  }

  svg {
    margin-right: 10px;
  }
`;
export const Description = styled.div`
  p {
    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #444444;
  }
`;

export const Timezone = styled.span`
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #444444;
`;

export const ContainerButton = styled.div`
  margin: 16px 0;
  display: flex;
  flex: 1;
  width: 100%;

  a {
    width: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  button {
    color: ${({ typeColor }) => typeColor};
    display: flex;
    background: transparent;
    border-color: ${({ typeColor }) => typeColor};
    padding: 16px;
    max-width: 500px;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  svg {
    position: absolute;
    right: 25px;
  }

  p {
    font-size: 1rem;
    font-weight: 700;
  }
`;
