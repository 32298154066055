import React from 'react';

import { ButtonPrimaryLong, Typography, ButtonOutlineLong } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { ELOGIO, DUVIDA, RECLAMACAO } from './success.constants';
import { ListWrapperCore } from '../../../../../../../shared/components/section-right-frame/section-right-frame.styles';
import { usePtkFrame } from '../../contexts';

import * as Styled from './success-section.styles';

const SuccessSection: React.FC = () => {
  const theme = useTheme();

  const { onClose, dispatch, onBack, scrollType, formType, backTo } =
    usePtkFrame();

  const { title, description, illustration } = React.useMemo(
    () =>
      ({
        RECLAMACAO,
        ELOGIO,
        DUVIDA,
      }[formType]),
    [formType]
  );

  return (
    <ListWrapperCore scroll={scrollType}>
      <Styled.SuccessIllustration illustration={illustration} />

      <Typography
        textAlign="center"
        weight="700"
        size={24}
        margin="16px 0px 8px 0px"
      >
        {title}
      </Typography>

      <Typography textAlign="center" size={16} margin="0px 16px 24px">
        {description}
      </Typography>

      <ButtonPrimaryLong
        type="button"
        onClick={onClose}
        color={theme.colors.text.white}
        background={theme.colors.button.primary}
        margin="0px 0px 12px"
      >
        Entendi
      </ButtonPrimaryLong>

      <ButtonOutlineLong
        type="button"
        borderBoxColor={theme.colors.button.primary}
        color={theme.colors.button.primary}
        width="100%"
        size={16}
        onClick={() =>
          onBack(backTo || 'HOME', () => dispatch({ backTo: null }))
        }
      >
        Voltar
      </ButtonOutlineLong>
    </ListWrapperCore>
  );
};

export default SuccessSection;
