import React from 'react';
import { ButtonGhost, ButtonPrimary, Typography } from '@sofia/ui';
import { clickByKey } from '@ampli/utils';
import { useTheme } from '@emotion/react';
import * as Styled from './tutorial-floating-box.styles';
import { useBreakpoint } from '@ampli/hooks';
import { ITutorialFloatingBox } from './interfaces';
import useFloatingBoxOnView from './hooks/use-floating-box-on-view';

const TutorialFloatingBox = ({
  title,
  subtitle,
  buttonNext,
  buttonPrev,
  iconAlign,
  boxPosition,
  boxAlign,
  width,
  boxCustomPosition,
}: ITutorialFloatingBox): React.ReactElement => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const ref = React.useRef(null);
  useFloatingBoxOnView(ref.current);

  return (
    <Styled.FloatingBox
      boxCustomPosition={boxCustomPosition}
      boxPosition={boxPosition}
      boxAlign={boxAlign}
      iconAlign={iconAlign}
      width={width}
      ref={ref}
    >
      <Typography
        weight={700}
        {...(isDesktop
          ? { size: 24, lineHeight: '115%', margin: '0 0 16px' }
          : { size: 20, lineHeight: '120%', margin: '0 0 8px' })}
      >
        {title}
      </Typography>
      <Typography
        {...(isDesktop
          ? { size: 20, lineHeight: '140%' }
          : { size: 16, lineHeight: '150%' })}
      >
        {subtitle}
      </Typography>
      <Styled.ButtonsWrapper>
        <ButtonGhost
          color={
            isDesktop ? theme.colors.text.primary : theme.colors.text.lightBlack
          }
          onClick={buttonPrev.onClick}
          onKeyDown={(event: KeyboardEvent) =>
            clickByKey(event, buttonPrev.onClick)
          }
          padding="19px 0"
        >
          <Typography lineHeight="115%">{buttonPrev.text}</Typography>
        </ButtonGhost>
        {isDesktop ? (
          <ButtonPrimary
            background={theme.colors.button.primary}
            color={theme.colors.button.text.primary}
            onClick={buttonNext.onClick}
            onKeyDown={(event: KeyboardEvent) =>
              clickByKey(event, buttonNext.onClick)
            }
            margin="16px 0 0"
          >
            <Typography lineHeight="115%">{buttonNext.text}</Typography>
          </ButtonPrimary>
        ) : (
          <ButtonGhost
            color={theme.colors.text.primary}
            onClick={buttonNext.onClick}
            onKeyDown={(event: KeyboardEvent) =>
              clickByKey(event, buttonNext.onClick)
            }
          >
            <Typography lineHeight="115%">{buttonNext.text}</Typography>
          </ButtonGhost>
        )}
      </Styled.ButtonsWrapper>
    </Styled.FloatingBox>
  );
};

TutorialFloatingBox.defaultProps = {
  iconAlign: 'center',
  boxPosition: 'bottom',
  boxAlign: 'center',
};

export default TutorialFloatingBox;
