import styled from '@emotion/styled';

import {
  convertPXToVH,
  SvgInfoCircle,
  mediaQuery,
  ButtonGhost,
} from '@sofia/ui';

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 24px 0px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 32px 32px;
`;

export const Image = styled.img`
  width: 100%;
  max-height: ${convertPXToVH(250)};
  border-radius: 2px;
  margin: 16px 0px;
  object-fit: cover;
  object-position: center;
`;

export const IllustrationWrapper = styled.div`
  ${mediaQuery('desktop')} {
    padding-left: 60px;
  }
`;

export const AlertIcon = styled(SvgInfoCircle)`
  min-width: 24px;
`;

export const CustomButtonGhost = styled(ButtonGhost)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
