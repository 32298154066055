export const ACTIONS = {
  suspend: 'SUSPEND',
  cancellation: 'CANCELLATION',
};

export const BULLET_POINT_TYPE_ENUM = {
  SUSPEND: {
    TITLE: 'Pausa nos estudos',
    SUBTITLE: 'Tem certeza de que essa pausa nos estudos é necessária?',
    CONTACT_CHANNEL: {
      DESC: 'A solicitação de trancamento pode ser realizada no Polo onde você estuda ou na Central de Relacionamento com o Aluno. Entre em contato conosco pelos nossos canais de atendimento:',
      ENUM: '',
    },
    PHONE: {
      DESC: 'A solicitação de trancamento pode ser realizada no Polo onde você estuda ou na Central de Relacionamento com o Aluno. Abaixo, disponibilizamos o número de telefone:',
      ENUM: 'Telefone',
    },
    EMAIL: {
      DESC: 'O trancamento deve ser enviado para o e-mail indicado. Informe o motivo do trancamento, nome completo e CPF.',
      ENUM: 'Email',
    },
  },
  CANCELLATION: {
    TITLE: 'Cancelamento dos estudos',
    SUBTITLE:
      'Sabemos que na vida temos imprevistos e ficamos tristes ao saber que não será possível continuar os estudo com a gente.',
    CONTACT_CHANNEL: {
      DESC: 'A solicitação de cancelamento pode ser realizada no Polo onde você estuda ou na Central de Relacionamento com o Aluno. Entre em contato conosco pelos nossos canais de atendimento:',
      ENUM: '',
    },
    PHONE: {
      DESC: 'A solicitação de cancelamento pode ser realizada no Polo onde você estuda ou na Central de Relacionamento com o Aluno. Abaixo, disponibilizamos o número de telefone:',
      ENUM: 'Telefone',
    },
    EMAIL: {
      DESC: 'O cancelamento deve ser enviado para o e-mail indicado. Informe o motivo cancelamento, nome completo e CPF.',
      ENUM: 'Email',
    },
  },
};
