import styled from '@emotion/styled';
export const Separator = styled.div`
  width: ${_ref => {
  let {
    width
  } = _ref;
  return width ? width : '100%';
}};
  height: ${_ref2 => {
  let {
    height
  } = _ref2;
  return height ? height : '1px';
}};
  margin: ${_ref3 => {
  let {
    margin
  } = _ref3;
  return margin ? margin : '0 0 0 0';
}};
  background-color: ${_ref4 => {
  let {
    color
  } = _ref4;
  return color || '#B1C2D5';
}};
`;