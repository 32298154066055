import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCallback } from 'react';
import { useLazyQuery, useMutation } from '../hooks';
import { CREATE_ANNOTATION, DELETE_ANNOTATION, GET_ANNOTATION_BY_COURSE_ENROLLMENT, GET_ANNOTATION_BY_COURSE_ENROLLMENT_AND_SUBJECT, UPDATE_ANNOTATION } from './queries';
import { createAnnotationSchema, deleteAnnotationSchema, updateAnnotationSchema } from './schemas';
const getQueryData = (called, data, loading, query) => {
  if (!called) {
    query();
  }
  return !loading ? data === null || data === void 0 ? void 0 : data.data : [];
};
const reloadQuery = refetch => {
  if (refetch) {
    refetch();
  }
};
export const useCreateAnnotation = function (input) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [createMutate, metadata] = useMutation(CREATE_ANNOTATION, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      input
    },
    context: {
      autoHandle: true
    }
  }));
  return [createMutate, metadata, createAnnotationSchema];
};
export const useUpdateAnnotation = function (input) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [updateMutate, metadata] = useMutation(UPDATE_ANNOTATION, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      input
    },
    context: {
      autoHandle: true
    }
  }));
  return [updateMutate, metadata, updateAnnotationSchema];
};
export const useDeleteAnnotation = function (annotationId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [deleteMutate, metadata] = useMutation(DELETE_ANNOTATION, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      annotationId
    },
    context: {
      autoHandle: true
    }
  }));
  return [deleteMutate, metadata, deleteAnnotationSchema];
};
const useLazyGetAnnotationsByCourseEnrollment = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useLazyQuery(GET_ANNOTATION_BY_COURSE_ENROLLMENT, _objectSpread(_objectSpread({}, options), {}, {
    skip: !courseEnrollmentId,
    variables: {
      courseEnrollmentId
    }
  }));
};
export const useGetAnnotationsByCourseEnrollment = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [query, {
    loading,
    called,
    data,
    refetch,
    error
  }] = useLazyGetAnnotationsByCourseEnrollment(courseEnrollmentId, options);
  const getData = useCallback(getQueryData, [getQueryData]);
  const reload = useCallback(reloadQuery, [reloadQuery]);
  return {
    getData: () => getData(called, data, loading, query),
    courseAnnotationsLoading: loading,
    courseAnnotationsError: error,
    reload: () => reload(refetch)
  };
};
const useLazyGetAnnotationsByCourseEnrollmentAndSubject = function (courseEnrollmentId, subjectId) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return useLazyQuery(GET_ANNOTATION_BY_COURSE_ENROLLMENT_AND_SUBJECT, _objectSpread(_objectSpread({}, options), {}, {
    skip: !courseEnrollmentId || !subjectId,
    variables: {
      courseEnrollmentId,
      subjectId
    }
  }));
};
export const useGetAnnotationsByCourseEnrollmentAndSubject = function (courseEnrollmentId, subjectId) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const [query, {
    loading,
    called,
    data,
    refetch,
    error
  }] = useLazyGetAnnotationsByCourseEnrollmentAndSubject(courseEnrollmentId, subjectId, options);
  const getData = useCallback(getQueryData, [getQueryData]);
  const reload = useCallback(reloadQuery, [reloadQuery]);
  return {
    getData: () => getData(called, data, loading, query),
    subjectAnnotationsLoading: loading,
    subjectAnnotationsError: error,
    reload: () => reload(refetch)
  };
};