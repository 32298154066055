import styled from '@emotion/styled';
import { SvgEditAlt, Typography } from '@sofia/ui';

export const Column = styled.div`
  > div {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const EditTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const EditIcon = styled(SvgEditAlt)`
  margin-left: 10px;
`;

export const NoteContent = styled(Typography)`
  word-break: break-word;
`;

export const NoteWrapper = styled.div`
  > p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  margin-bottom: 24px;
`;
