import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';

export const Container = styled.div`
  margin-bottom: 40px;

  ${mediaQuery('desktop')} {
    margin-bottom: 56px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 769px) {
    > div {
      padding: 0 8px;
    }
  }
`;

export const FlexCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  > :first-of-type {
    margin-bottom: 16px;
  }

  ${mediaQuery('desktop')} {
    flex-direction: row;
    > {
      :first-of-type {
        margin-right: 24px;
        margin-bottom: 0;
      }
    }
    > div {
      width: 100%;
    }
  }
`;

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  > :first-of-type {
    margin-bottom: 16px;
  }
  > div {
    height: 100%;
  }
`;
