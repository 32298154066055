import styled from '@emotion/styled';
import { convertPXToVW, convertPXToVH, mediaQuery } from '@sofia/ui';

export const ContainerCardContent = styled.div`
  width: 100%;
  max-width: ${convertPXToVW(378)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${mediaQuery('tablet-max')} {
    max-width: none;
  }
`;

export const ContainerButton = styled.div`
  margin-top: ${convertPXToVH(24)};
  width: 100%;
  button {
    padding: ${convertPXToVH(19)} ${convertPXToVW(24)};
  }
`;

export const CardCopy = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  background: #ebf0f5;
  border-radius: 8px;
  margin: 25px 0 0 0;
  cursor: pointer;
  svg {
    margin-right: 20px;
  }
`;
