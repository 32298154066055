const SUPPORTED_DOCUMENT_EXTENSIONS = [
  'jpeg',
  'png',
  'image/jpeg',
  'image/png',
];

export const hasScholarship = {
  data: {
    hasScholarship: true,
  },
};

export const getScholarshipRequest = {
  data: [
    {
      id: '1',
      status: 'PROCESSING',
      title: 'Scholarship Request 1',
      ticketNumber: '123456',
      channel: 'Online',
      requestDate: '2023-06-15T09:00:00Z',
    },
    {
      id: '2',
      status: 'APPROVED',
      title: 'Scholarship Request 2',
      ticketNumber: '789012',
      channel: 'Email',
      requestDate: '2023-06-13T15:30:00Z',
    },
  ],
};

export const getScholarshipRequestDetails = {
  data: {
    id: '1',
    status: 'PROCESSING',
    title: 'Scholarship Request 1',
    ticketNumber: '123456',
    channel: 'Online',
    requestDate: '2023-06-14T09:00:00Z',
    serviceForecast: '2023-06-14T14:00:00Z',
    description: 'Lorem ipsum dolor sit amet.',
    ComplementaryFields: [
      {
        title: 'Field 1',
        value: 'Value 1',
      },
      {
        title: 'Field 2',
        value: 'Value 2',
      },
    ],
    history: [
      {
        status: 'PENDING_DOCUMENT',
        requestDate: '2023-06-14T09:00:00Z',
      },
      {
        status: 'ATTENDANCE',
        requestDate: '2023-06-14T11:30:00Z',
      },
      {
        status: 'PROCESSING',
        requestDate: '2023-06-14T11:30:00Z',
      },
      {
        status: 'PROCESSING',
        requestDate: '2023-06-18T11:30:00Z',
      },
    ],
    contractId: 'ABC123',
  },
};

export const getScholarshipContractUrl = {
  data: {
    getScholarshipContractUrl: {
      url: 'https://example.com/contract',
    },
  },
};

export const getScholarshipDocumentRequest = {
  data: {
    title: 'Enviar CPF ou RG',
    description: 'Você pode escolher entre um dos dois documentos.',
    documentTypes: [
      {
        name: 'ID Card',
        status: 'PENDING',
        subTypes: [
          {
            id: '1',
            name: 'Front Side',
            info: {
              description: 'Upload the front side of your ID card.',
              url: '',
            },
          },
          {
            id: '2',
            name: 'Back Side',
            info: {
              description: 'Upload the back side of your ID card.',
              url: '',
            },
          },
        ],
        supportedDocumentExtensions: SUPPORTED_DOCUMENT_EXTENSIONS,
        documentSizeLimit: 7,
      },
      {
        name: 'Transcript',
        status: 'PENDING',
        subTypes: [
          {
            id: '3',
            name: 'High School',
            info: {
              description: 'Upload your high school transcript.',
              url: '',
            },
          },
          {
            id: '4',
            name: 'College',
            info: {
              description: 'Upload your college transcript.',
              url: '',
            },
          },
        ],
        supportedDocumentExtensions: SUPPORTED_DOCUMENT_EXTENSIONS,
        documentSizeLimit: 7,
      },
    ],
  },
};

export const generateScholarshipDocumentUploadUrl = {
  data: {
    generateScholarshipDocumentUploadUrl: [
      {
        subTypeId: '1',
        uploadUrl: 'https://example.com/upload',
      },
      {
        subTypeId: '2',
        uploadUrl: 'https://example.com/upload',
      },
    ],
  },
};

export const uploadScholarshipDocument = {
  data: {
    uploadScholarshipDocument: null,
  },
};
