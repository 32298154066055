import whitelabelPalette from '../../themes/palettes/whitelabel';

export const blobToBase64 = (blob: Blob): Promise<FileReader['result']> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const styleOptions = {
  hideUploadButton: true,
  backgroundColor: whitelabelPalette.neutral['100'],
  bubbleBackground: '#CCE0FF',
  bubbleBorderRadius: 16,
  bubbleFromUserBackground: '#CCE0FF',
  bubbleFromUserBorderRadius: 16,
  bubbleFromUserTextColor: whitelabelPalette.primary['900'],
  bubbleTextColor: whitelabelPalette.primary['900'],
  sendBoxButtonColor: whitelabelPalette.primary['500'],
};
