import { gql } from '@apollo/client';

export const GetLivesFromSubejectEnrollment = gql`
  query getSubjectEnrollment($subjectEnrollmentId: ID!) {
    data: getSubjectEnrollment(subjectEnrollmentId: $subjectEnrollmentId) {
      lives {
        channelId
        description
        endDateTime
        eventId
        id
        startDateTime
        teacherName
        title
      }
    }
  }
`;

export const GetLivesByCourse = gql`
  query GetLivesByCourse($courseVersionId: String!) {
    data: getLivesByCourse(courseVersionId: $courseVersionId) {
      id
      title
      description
      startDateTime
      endDateTime
      channelId
      eventId
      teacherName
    }
  }
`;

export const GetLiveById = gql`
  query GetLiveById($liveId: ID!) {
    data: getLiveById(liveId: $liveId) {
      id
      title
      description
      startDateTime
      endDateTime
      channelId
      eventId
      teacherName
    }
  }
`;
