import React from 'react';
import { useTheme } from '@emotion/react';
import { ButtonGhost, ButtonOutline } from '@sofia/ui';
import { NotesMenuFooterProps } from '../types';
import * as Styled from './notes-menu-footer.styles';

const NotesMenuFooter = ({
  onReturnCurrentSectionNotes,
  buttonText,
  buttonClick,
}: NotesMenuFooterProps): React.ReactElement => {
  const theme = useTheme();
  return (
    <Styled.Wrapper>
      <ButtonOutline
        borderBoxColor={theme.colors.text.primary}
        color={theme.colors.text.primary}
        width="100%"
        onClick={onReturnCurrentSectionNotes}
      >
        Voltar para anotações da aula atual
      </ButtonOutline>
      {buttonText && buttonClick ? (
        <ButtonGhost
          color={theme.colors.text.primary}
          aria-label={buttonText}
          onClick={buttonClick}
          margin="16px 0 0 0"
        >
          {buttonText}
        </ButtonGhost>
      ) : null}
    </Styled.Wrapper>
  );
};

export default NotesMenuFooter;
