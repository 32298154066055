import React, { useEffect, useState, useMemo, forwardRef } from 'react';

import { clickByKey, DateTime } from '@ampli/utils';
import { useTheme } from '@emotion/react';
import { CardButton, ProgressBar, TagList, Typography } from '@sofia/ui';
import { bool, func, number, object, oneOfType, string } from 'prop-types';

import { useHistory, useLocation } from 'react-router-dom';
import { subjectEnrollmentStatusLabelByStatus } from '../../../../constants';
import { useBreakpoint } from '../../../../modules';
import * as Styled from './syllabus-subject.styles';

const SyllabusSubject = forwardRef(
  (
    {
      blockCourseEnrollmentContentAccess,
      name,
      onClick,
      id,
      isGradeProgress,
      subjectEnrollment,
    },
    ref
  ) => {
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useBreakpoint().isDesktop;
    const { state: locationState } = useLocation();

    const [started, setStarted] = useState(false);
    const [ended, setEnded] = useState(false);
    const [showExam, setShowExam] = useState(false);
    const [progress, setProgress] = useState(0);
    const [statusLabel, setStatusLabel] = useState('');
    const [isNewEnrolledSubject, setIsNewEnrolledSubject] = useState(false);

    const tagsTypes = {
      Aprovada: 'success',
      Recuperação: 'warning',
      Reprovada: 'error',
    };

    const getTagsList = ({ started, ended, showExam, statusLabel }) => {
      const tags = [];
      const statusExcluded = ['Pausado', 'Aprovada', 'Reprovada', 'Futura'];
      const isStatusValid = !statusExcluded.includes(statusLabel);

      const addTag = (tag) => {
        if (!tags.some((existingTag) => existingTag.text === tag.text)) {
          tags.push(tag);
        }
      };

      if (!ended && isStatusValid) {
        const tagText = started ? 'Cursando' : 'Futura';

        addTag({ text: tagText });
      }

      if (statusLabel) {
        if (
          statusLabel === 'Cursando' &&
          tags.some((tag) => tag.text === 'Futura')
        ) {
          return tags;
        }
        addTag({
          text: statusLabel,
          type: tagsTypes[statusLabel] || 'standard',
        });
      }

      if (showExam && statusLabel !== 'Recuperação') {
        addTag({
          text: 'Período de provas',
          type: 'warning',
        });
      }

      return tags;
    };

    const syllabusSubjectStatus = useMemo(() => {
      return ended ? 'ended' : started ? 'active' : 'future';
    }, [ended, started]);

    useEffect(() => {
      const successElectiveSubjectId =
        locationState?.successElectiveSubjects?.find(
          (successElective) => successElective?.id === subjectEnrollment.id
        ) || null;
      if (successElectiveSubjectId) {
        setIsNewEnrolledSubject(true);
        const timer = setTimeout(() => {
          setIsNewEnrolledSubject(false);
          history.replace({
            state: { ...locationState, successElectiveSubjects: null },
          });
        }, 3000);
        return () => clearTimeout(timer);
      }
    }, [
      history,
      locationState,
      locationState?.successElectiveSubjects,
      subjectEnrollment.id,
    ]);

    useEffect(() => {
      setStarted(
        subjectEnrollment.startDate
          ? DateTime.local() >= subjectEnrollment.startDate
          : false
      );
      setEnded(
        subjectEnrollment.closingDate
          ? DateTime.local() > subjectEnrollment.closingDate
          : false
      );
      setShowExam(
        subjectEnrollment.examStartEndInterval?.contains(DateTime.local())
      );
      setProgress(
        subjectEnrollment.progress?.percentage
          ? subjectEnrollment.progress.percentage * 100
          : 0
      );
      setStatusLabel(
        subjectEnrollmentStatusLabelByStatus[subjectEnrollment.status]
      );
    }, [subjectEnrollment]);

    return (
      <Styled.Card
        ref={ref}
        id={`card-${syllabusSubjectStatus}-${id}`}
        className={id}
        isNewEnrolledSubject={isNewEnrolledSubject}
      >
        <CardButton
          id={`internalCard-${syllabusSubjectStatus}-${id}`}
          onClick={onClick}
          onKeyDown={(evt) => clickByKey(evt, onClick)}
          aria-disabled={!onClick || blockCourseEnrollmentContentAccess}
          borderColor={theme.colors.card.border.primary}
          tabIndex={0}
          role="link"
          aria-labelledby={`titleSubject-${syllabusSubjectStatus}-${id}`}
        >
          <TagList
            id={`${syllabusSubjectStatus}-${id}`}
            tagList={getTagsList({
              started: started,
              ended: ended,
              showExam: showExam,
              statusLabel: statusLabel,
            })}
          />
          <Typography
            margin="16px 0"
            id={`titleSubject-${syllabusSubjectStatus}-${id}`}
            weight={700}
            color={theme.colors.text.black}
            size={isDesktop ? 24 : 20}
          >
            {name}
          </Typography>
          {isGradeProgress ? (
            <>
              <ProgressBar
                id={`${syllabusSubjectStatus}-${id}`}
                progress={progress}
              />
              <Styled.Flex>
                <Typography
                  margin={`16px 0 0 0`}
                  color={theme.colors.text.lightBlack}
                  weight={700}
                  size={16}
                  id={`progressNumberstart-${syllabusSubjectStatus}-${id}`}
                >
                  0 pontos
                </Typography>
                <Typography
                  margin={`16px 0 0 0`}
                  color={theme.colors.text.lightBlack}
                  weight={700}
                  size={16}
                  id={`progressNumberend-${syllabusSubjectStatus}-${id}`}
                >
                  100 pontos
                </Typography>
              </Styled.Flex>
            </>
          ) : (
            <Styled.Flex>
              <ProgressBar
                id={`${syllabusSubjectStatus}-${id}`}
                progress={progress}
              />
              <Typography
                margin={`0 0 0 16px`}
                color={theme.colors.text.lightBlack}
                weight={700}
                size={16}
                id={`progressNumberend-${syllabusSubjectStatus}-${id}`}
                data-testid={`progressNumberend-${syllabusSubjectStatus}-${id}`}
              >
                {progress.toFixed(0)}%
              </Typography>
            </Styled.Flex>
          )}
        </CardButton>
      </Styled.Card>
    );
  }
);

SyllabusSubject.displayName = 'SyllabusSubject';

SyllabusSubject.propTypes = {
  name: string,
  id: oneOfType([string || number]),
  onClick: func,
  blockCourseEnrollmentContentAccess: bool,
  isGradeProgress: bool,
  subjectEnrollment: object,
};

export default SyllabusSubject;
