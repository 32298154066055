import React from 'react';

import { useTheme } from '@emotion/react';
import { Separator, Typography } from '@sofia/ui';

import * as Styled from './customization-section.styles';
import { CustomizationSectionProps } from '../../types';

const CustomizationSection = ({
  title,
  children,
}: CustomizationSectionProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <Styled.Container
      backgroundColor={theme.colors.card.color.secondary}
      data-testid="customization-section"
    >
      <Typography
        size={20}
        weight={700}
        color={theme.colors.text.black}
        data-testid="customization-section-title"
      >
        {title}
      </Typography>
      <Separator color={theme.colors.card.border.secondary} />
      {children}
    </Styled.Container>
  );
};

export default CustomizationSection;
