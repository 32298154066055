import React from 'react';

import { ButtonPrimaryLong, Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { SvgSuccessIllustration } from '../../../../shared';
import { useAmpliFrame } from '../../contexts';

import * as Styled from './success-section.styles';

const SuccessSection: React.FC = () => {
  const theme = useTheme();

  const { onClose } = useAmpliFrame();

  return (
    <Styled.FrameSuccessWrapper>
      <SvgSuccessIllustration />

      <Typography
        textAlign="center"
        weight="700"
        size={24}
        margin="16px 0px 8px 0px"
      >
        Dúvida enviada!
      </Typography>

      <Typography textAlign="center" size={16} margin="0px 16px 24px">
        Assim que possível o tutor irá te responder. Nosso horário de
        atendimento é de segunda a sexta das 08:00 às 18:00 horas. Até breve!
      </Typography>

      <ButtonPrimaryLong
        type="button"
        onClick={onClose}
        color={theme.colors.text.white}
        background={theme.colors.button.primary}
      >
        Entendi
      </ButtonPrimaryLong>
    </Styled.FrameSuccessWrapper>
  );
};

export default SuccessSection;
