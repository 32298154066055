import React from 'react';
import { TutorialFloatingBox } from '../../../../modules';

const getTutorialSteps = ({
  refs,
  elements,
  setTutorialStep,
  isDesktop,
  setTermAccordion,
  onInit,
  onClose,
}) => {
  const {
    accordion,
    openedAccordion,
    releasedTag,
    tutorialBtn,
    sendPdf,
    approvedTag,
  } = elements;
  const { termRef, tutorialRef, pdfRef } = refs;

  const prevText = 'Passo anterior';
  const nextText = 'Próximo passo';
  const defaultTitle = 'Termo de estágio';
  const props = (
    isDefault = true,
    title = defaultTitle,
    boxPosition = 'top'
  ) => {
    return {
      title,
      boxPosition,
      ...(isDesktop ? { width: 'calc(200% + 24px)' } : {}),
      ...(isDefault ? { boxAlign: isDesktop ? 'right' : 'center' } : {}),
    };
  };

  const stepsTxt = {
    first: {
      subtitle: 'Abra o container Termo de Estágio para visualizar o conteúdo.',
      buttonPrev: {
        text: prevText,
        onClick: onInit,
      },
      buttonNext: {
        text: nextText,
        onClick: () => setTutorialStep('second'),
      },
      ...props(),
    },
    second: {
      subtitle: 'Aqui você pode baixar o pdf do seu termo de estágio.',
      buttonPrev: {
        text: prevText,
        onClick: () => setTutorialStep('first'),
      },
      buttonNext: {
        text: nextText,
        onClick: () => {
          setTermAccordion(true);
          setTutorialStep('third');
        },
      },
      ...props(),
    },
    third: {
      subtitle:
        'Você pode também observar o status do seu termo, que deverá estar como Liberado para que você possa fazer o download.',
      buttonPrev: {
        text: prevText,
        onClick: () => setTutorialStep('second'),
      },
      buttonNext: {
        text: nextText,
        onClick: () => setTutorialStep('fourth'),
      },
      boxAlign: 45,
      boxCustomPosition: {
        desktop: '-4rem',
        mobile: '-2rem',
      },
      iconAlign: {
        left: '80%',
      },
      ...props(false),
    },
    fourth: {
      subtitle:
        'Uma vez que você já coletou as assinaturas pendentes, você pode clicar em enviar, e selecionar o documento digitalizado.',
      buttonPrev: {
        text: prevText,
        onClick: () => {
          setTermAccordion(true);
          setTutorialStep('third');
        },
      },
      buttonNext: {
        text: nextText,
        onClick: () => setTutorialStep('fifth'),
      },
      ...props(),
    },
    fifth: {
      subtitle:
        'Após o período de análise, se aprovado, seu status mudará para aprovado, e você já poderá começar seu estágio.',
      buttonPrev: {
        text: prevText,
        onClick: () => setTutorialStep('fourth'),
      },
      buttonNext: {
        text: nextText,
        onClick: () => {
          setTutorialStep('sixth');
          setTermAccordion(false);
        },
      },
      boxAlign: 45,
      boxCustomPosition: {
        desktop: '-4rem',
        mobile: '-2rem',
      },
      iconAlign: {
        left: '80%',
      },
      ...props(false),
    },
    sixth: {
      subtitle:
        'Repita todas as etapas e revise nos detalhes do termo de estágio.',
      buttonPrev: {
        text: prevText,
        onClick: () => {
          setTermAccordion(true);
          setTutorialStep('fifth');
        },
      },
      buttonNext: {
        text: 'Concluir passo a passo',
        onClick: onClose,
      },
      boxAlign: 50,
      boxCustomPosition: {
        desktop: '-6rem',
        mobile: '-4rem',
      },
      iconAlign: {
        left: '80%',
      },
      ...props(false, 'Faça o passo a passo a qualquer momento!', 'bottom'),
    },
  };

  return {
    first: {
      features: [accordion],
      featureRefs: [termRef],
      featureWrappers: [
        <div key="tutorial-button">
          <TutorialFloatingBox {...stepsTxt['first']} />
        </div>,
      ],
    },
    second: {
      features: [openedAccordion],
      featureRefs: [termRef],
      featureWrappers: [
        <div key="tutorial-button">
          <TutorialFloatingBox {...stepsTxt['second']} />
        </div>,
      ],
    },
    third: {
      features: [releasedTag],
      featureRefs: [termRef],
      featureWrappers: [
        <div key="tutorial-button">
          <TutorialFloatingBox {...stepsTxt['third']} />
        </div>,
      ],
    },
    fourth: {
      features: [sendPdf],
      featureRefs: [pdfRef],
      featureWrappers: [
        <div key="tutorial-button">
          <TutorialFloatingBox {...stepsTxt['fourth']} />
        </div>,
      ],
    },
    fifth: {
      features: [approvedTag],
      featureRefs: [termRef],
      featureWrappers: [
        <div key="tutorial-button">
          <TutorialFloatingBox {...stepsTxt['fifth']} />
        </div>,
      ],
    },
    sixth: {
      features: [tutorialBtn],
      featureRefs: [tutorialRef],
      featureWrappers: [
        <div key="tutorial-button">
          <TutorialFloatingBox {...stepsTxt['sixth']} />
        </div>,
      ],
    },
  };
};

export default getTutorialSteps;
