import { useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { find } from '@ampli/utils';
import { useCreateExamByAssignment } from '@ampli/services';

import { routePath } from '../routes';
import useHomePageData from './use-home-page-data';
import { useGetState } from '../state';

const useExamPageData = (options = {}) => {
  const { onCreateExamCompleted, onCreateExamError } = options;
  const history = useHistory();
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();
  const { subjectEnrollmentId } = useParams();
  const { subjectEnrollmentAvailableExams, loading, ...homePageData } =
    useHomePageData();
  const [createExam, createExamMetadata] = useCreateExamByAssignment({
    onError: onCreateExamError,
    onCompleted: onCreateExamCompleted,
  });

  const subjectEnrollment = find(subjectEnrollmentAvailableExams, {
    id: subjectEnrollmentId,
  });

  useEffect(() => {
    if (!loading && !subjectEnrollment) {
      console.warn('SubjectEnrollment not found.');
      history.replace(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })
      );
    }
  }, [
    history,
    loading,
    subjectEnrollment,
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
  ]);

  return {
    ...homePageData,
    loading,
    subjectEnrollment,
    createExam,
    createExamMetadata,
  };
};

export default useExamPageData;
