import React from 'react';

import { useParams, useHistory, generatePath } from 'react-router-dom';
import { useServiceClient } from '@ampli/services';
import { useGetState } from '../state';
import { routePath } from '../routes';
import { useMeContext } from '../modules/shared/contexts/me-context';
import logout from '../lib/logout';

const useInternshipBaseData = () => {
  const { subjectEnrollmentId } = useParams();
  const history = useHistory();
  const client = useServiceClient('privateClient');

  const [
    {
      selectedCourseEnrollmentId,
      selectedCourseEnrollmentType,
      selectedCourseVersionId,
    },
  ] = useGetState();

  const { states: statesMe } = useMeContext();
  const { me, loading: meLoading } = statesMe.getMe;

  const handleLogout = React.useCallback(
    () => logout({ client, history }),
    [client, history]
  );

  const handleLogo = React.useCallback(
    () =>
      history.push(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })
      ),
    [history, selectedCourseEnrollmentType, selectedCourseVersionId]
  );

  return {
    me,
    handleLogo,
    handleLogout,
    baseLoading: meLoading,
    subjectEnrollmentId,
    selectedCourseEnrollmentId,
  };
};

export default useInternshipBaseData;
