import { GetConversation, PageConversationInfo } from '../types';
import { UseQueryResult, useQuery } from 'react-query';
import { useDynamicsLogic } from './use-dynamics-logic';
import { usePtkFrame } from '../contexts';

export type UseConversations = () => UseQueryResult<
  PageConversationInfo,
  unknown
>;

export const useHomeConversations: UseConversations = () => {
  const { conversationsHomeQueries, queryKeys } = usePtkFrame();

  const { getConversations } = useDynamicsLogic();

  return useQuery(
    queryKeys.home,
    () => getConversations(conversationsHomeQueries as GetConversation),
    {
      staleTime: 1000 * 60 * 1, // 1 minutes
      onError: (error) => console.log(error),
    }
  );
};
