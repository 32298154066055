import React from 'react';
import * as Styled from './reading-ruler.styles';
import { Typography, SvgStraighten } from '@sofia/ui';
import useReadingRulerBtnLogic from './reading-ruler-btn.logic';
import { NewFeatureCommunication } from '../section';

const ReadingRulerBtnPresentation = (): React.ReactElement | null => {
  const {
    theme,
    isDesktop,
    onRulerClick,
    btnColor,
    commsAnchorEl,
    setComms,
    onRulerClose,
    shouldShowComms,
  } = useReadingRulerBtnLogic();

  return isDesktop ? (
    <>
      <Styled.RulerBtn
        data-testid="reading-ruler-btn"
        color={theme.colors.button.primary}
        ref={(ref) => commsAnchorEl !== null && setComms(ref)}
        onClick={() => onRulerClick()}
        aria-label="Régua de leitura"
      >
        <SvgStraighten fill={btnColor} />
        <Typography color={btnColor}>Régua de leitura</Typography>
      </Styled.RulerBtn>
      {shouldShowComms ? (
        <NewFeatureCommunication
          anchorEl={commsAnchorEl}
          actions={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          handleClose={() => onRulerClose()}
          message="Confira a nova régua de leitura"
        />
      ) : null}
    </>
  ) : null;
};

export default ReadingRulerBtnPresentation;
