import { useTheme } from '@emotion/react';
import {
  IGetCounterTextColorParams,
  IUseCharacterCounter,
} from './character-counter.types';

const useCharacterLogic = (): IUseCharacterCounter => {
  const theme = useTheme();

  const getCounterTextColor = ({
    currentCharactersCount,
    charactersLimit,
  }: IGetCounterTextColorParams): string => {
    if (currentCharactersCount >= charactersLimit) {
      return theme.colors.text.danger;
    } else if (charactersLimit - currentCharactersCount <= 50) {
      return theme.colors.icon.warning;
    }

    return theme.colors.text.lighterBlack;
  };

  return {
    actions: {
      getCounterTextColor,
    },
  };
};

export default useCharacterLogic;
