import React from 'react';

interface SvgTourIllustrationProps {
  title?: string;
  desc?: string;
}

const SvgTourIllustrationPc: React.FC<SvgTourIllustrationProps> = ({
  title,
  desc,
  ...props
}: SvgTourIllustrationProps) => {
  return (
    <svg
      width="227"
      height="170"
      viewBox="0 0 227 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={`${title ? 'iconTourIllustrationTitle' : ''} ${
        desc ? 'iconTourIllustrationDesc' : ''
      }`}
      {...props}
    >
      {title ? <title id="iconTourIllustrationTitle">{title}</title> : null}
      {desc ? <desc id="iconTourIllustrationDesc">{desc}</desc> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.618 57.5151C182.309 31.9802 168.553 36.3854 157.084 35.2237C148.068 34.2919 150.562 28.5068 147.761 23.4293C147.529 23.0049 147.248 22.6091 146.924 22.2498C147.498 21.7206 147.932 21.0569 148.187 20.3185C148.442 19.5801 148.509 18.79 148.383 18.0192C148.257 17.2483 147.942 16.5208 147.465 15.9019C146.989 15.283 146.366 14.7922 145.653 14.4734C144.94 14.1546 144.159 14.0178 143.38 14.0753C142.601 14.1329 141.849 14.3829 141.19 14.803C140.532 15.2231 139.988 15.8002 139.608 16.4824C139.227 17.1645 139.022 17.9305 139.011 18.7115C134.742 18.4343 130.596 20.4807 130.526 26.6727C132.359 26.6727 135.343 23.9777 135.833 20.4099C137.436 25.3871 144.477 26.1951 144.925 27.5691C145.267 31.0072 145.857 38.1841 149.43 43.58C159.885 42.4183 166.082 51.5 183.618 57.5151Z"
        fill="#212529"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.254 42.0883C149.507 40.7732 147.408 36.5803 146.306 34.7993C145.032 32.7294 145.451 27.5398 144.678 27.1329C142.909 26.2601 138.086 25.6881 135.751 20.416C134.76 26.6553 129.459 26.9737 130.22 26.8027C130.025 28.2062 131.34 32.7884 131.151 32.9535C126.222 37.3292 125.255 38.7151 132.543 37.1405C133.463 39.983 134.129 41.929 134.748 44.5061C138.475 47.9973 148.794 44.3882 149.254 42.0883Z"
        fill="#F3F6F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.317 43.1373C178.789 37.0042 201.745 69.8517 203.897 102.976C205.035 120.503 196.821 136.001 183.359 140.329C183.374 144.101 183.086 147.867 182.498 151.593C181.472 161.394 129.087 151.221 110.619 153.486C94.6571 155.444 78.3531 136.879 79.3673 131.737C84.2143 107.157 81.5726 52.9385 141.317 43.1373Z"
        fill="#7389FF"
      />
      <path
        d="M149.03 39.9177C148.063 36.4797 145.581 35.7897 144.997 28.1233C144.779 25.2809 138.298 26.9439 135.981 20.5277C135.568 19.3483 135.244 26.2067 130.444 26.7611"
        stroke="#212529"
        strokeWidth="1.25671"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.737 28.0609C138.807 27.5019 138.484 27.001 138.015 26.9422C137.547 26.8834 137.11 27.289 137.04 27.8481C136.97 28.4071 137.293 28.908 137.761 28.9668C138.23 29.0256 138.667 28.62 138.737 28.0609Z"
        fill="#212529"
      />
      <path
        d="M132.805 28.7974C132.855 28.4032 132.628 28.05 132.298 28.0086C131.968 27.9672 131.661 28.2532 131.612 28.6475C131.562 29.0417 131.789 29.3949 132.119 29.4363C132.448 29.4777 132.756 29.1917 132.805 28.7974Z"
        fill="#212529"
      />
      <path
        d="M141.434 59.091C141.734 58.9471 141.837 58.5401 141.665 58.182C141.493 57.8239 141.11 57.6502 140.811 57.7941C140.511 57.938 140.408 58.345 140.58 58.7031C140.753 59.0613 141.135 59.2349 141.434 59.091Z"
        fill="#212529"
      />
      <path
        d="M137.432 69.893C137.764 69.893 138.033 69.5709 138.033 69.1736C138.033 68.7762 137.764 68.4541 137.432 68.4541C137.099 68.4541 136.83 68.7762 136.83 69.1736C136.83 69.5709 137.099 69.893 137.432 69.893Z"
        fill="#212529"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.8601 87.9211H27.6134C24.3349 87.9211 22.4186 90.6043 23.3443 93.8772L35.5797 136.968C36.5114 140.247 39.9491 142.924 43.2453 142.924H106.445C109.723 142.924 111.646 140.241 110.714 136.968L98.5021 93.8536C97.5705 90.5748 94.1327 87.8975 90.8366 87.8975L90.8601 87.9211ZM133.463 143.196H42.6556C42.2882 143.179 41.9213 143.237 41.5774 143.368C41.2335 143.498 40.9201 143.698 40.6565 143.954C40.3928 144.211 40.1846 144.519 40.0447 144.859C39.9048 145.199 39.8361 145.564 39.843 145.932C39.9113 146.696 40.266 147.405 40.8359 147.918C41.4057 148.431 42.1485 148.71 42.9151 148.698H133.722C134.09 148.715 134.457 148.656 134.801 148.526C135.144 148.395 135.458 148.195 135.722 147.939C135.985 147.683 136.193 147.375 136.333 147.035C136.473 146.694 136.542 146.329 136.535 145.961C136.467 145.198 136.112 144.488 135.542 143.975C134.972 143.462 134.23 143.184 133.463 143.196Z"
        fill="#FFA433"
        stroke="#212529"
        strokeWidth="1.25671"
        strokeMiterlimit="22.93"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.003 131.106C111.857 131.773 110.135 140.542 117.294 139.533C115.495 141.137 114.286 147.789 129.417 144.216C134.724 142.818 141.546 141.45 147.861 141.993C147.578 138.584 146.806 126.318 139.417 113.957C130.761 112.642 122.406 113.521 118.414 115.626C116.055 116.605 116.368 119.164 117.347 120.426C108.289 123.794 109.563 130.847 114.003 131.106Z"
        fill="#F3F6F9"
      />
      <path
        d="M136.246 37.305C139.92 37.3875 141.417 34.1795 141.942 29.9983M130.739 47.69C130.205 49.5554 129.832 51.4633 129.624 53.3926C130.515 52.5316 139.271 47.9376 139.306 48.0025C144.024 56.5181 145.409 57.4852 150.793 47.0766C150.928 46.8813 151.014 46.6571 151.046 46.4221M139.424 114.24C147.255 127.108 147.827 139.51 148.104 142.358M132.148 125.574C105.377 127.792 111.463 141.762 117.353 139.61M132.036 118.527C119.83 115.903 103.986 125.604 113.591 131.012M132.16 133.701C112.542 135.511 110.826 149.364 129.518 144.316C134.878 142.877 141.807 141.438 148.21 141.957C150.463 142.151 158.052 143.756 161.035 143.85C173.005 144.21 181.962 140.418 183.289 139.409M139.713 48.5274C139.713 57.9629 127.649 80.1128 127.985 90.1794C128.303 99.7918 121.369 106.815 118.515 115.278M106.516 75.2889C105.077 79.1575 99.6224 100.264 101.651 103.596M173.737 72.93C172.298 76.7986 166.843 97.9047 168.872 101.237M117.265 119.919C114.482 114.611 124.671 111.869 132.354 113.686C134.86 114.275 137.455 113.727 139.607 113.762C150.138 113.922 161.684 114.252 167.734 111.905M150.345 113.815C160.18 125.781 161.041 135.163 160.776 144.245M134.784 43.8745C134.094 40.9259 133.492 40.1415 132.478 36.9099M131.193 32.7818C130.484 30.7916 130.17 28.6824 130.267 26.5721C130.267 20.8753 134.436 18.0152 139.029 18.3926C139.093 17.618 139.348 16.8711 139.77 16.2185C140.192 15.5659 140.769 15.0277 141.449 14.6518C142.129 14.2759 142.892 14.074 143.669 14.0639C144.446 14.0538 145.214 14.2359 145.904 14.594C146.594 14.9521 147.184 15.4751 147.623 16.1165C148.062 16.758 148.336 17.4979 148.42 18.2707C148.504 19.0434 148.396 19.8249 148.105 20.5458C147.815 21.2667 147.35 21.9046 146.754 22.4027C150.41 26.5308 147.786 33.6605 155.345 35.064C168.046 37.4229 183.885 30.5763 187.499 51.5349C187.566 51.9281 187.627 52.3212 187.682 52.7144M134.613 29.0666C134.377 30.9419 121.051 40.3892 131.576 37.3227C133.74 36.6858 137.384 33.3244 138.369 31.1188"
        stroke="#212529"
        strokeWidth="0.837805"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgTourIllustrationPc;
