import React from 'react';

type Options = {
  display?: boolean;
};

export const withCanDisplayValidation = <T,>(
  children: React.FC<T>,
  { display }: Options
): React.FC<T> | null => {
  if (!display) return null;
  return children;
};
