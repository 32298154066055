import { mediaQuery, convertPXToREM } from '@sofia/ui';
import styled from '@emotion/styled';

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  margin: 0 auto;
  max-width: ${convertPXToREM(712)};
  width: 100%;

  ${mediaQuery('tablet-max')} {
    padding: 0 16px;
  }
`;

export const Wrapper = styled.div<{
  bannerTitleUrl?: string | null;
}>`
  ${({ bannerTitleUrl }) =>
    bannerTitleUrl && `background-image: url(${bannerTitleUrl});`}
  padding: 42px 0 12px;
  margin-bottom: 12px;
  background-position: center 100%;

  ${mediaQuery('tablet-max')} {
    padding: 39px 0 11px;
    background-position: 0 0;
  }
`;
