import styled from '@emotion/styled';
export const Container = styled.div`
  position: relative;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Input = styled.input`
  all: unset;
  width: 24px;
  height: 24px;
  display: inline-block;
  background: #ffffff;
  border: 2px solid ${_ref => {
  let {
    borderColor
  } = _ref;
  return borderColor;
}};
  border-radius: 4px;

  &:focus {
    border: 4px solid #004ecc;
    background-color: #f3f6f9;
  }

  &:checked {
    background-color: ${_ref2 => {
  let {
    backgroundColor
  } = _ref2;
  return backgroundColor;
}};
    border: 2px solid ${_ref3 => {
  let {
    backgroundColor
  } = _ref3;
  return backgroundColor;
}} !important;
    border: none;
    ~ svg {
      position: absolute;
      visibility: visible;
      pointer-events: none;
    }
  }
  & ~ svg {
    position: absolute;
    visibility: hidden;
  }

  ${_ref4 => {
  let {
    disabled
  } = _ref4;
  return disabled && `
    &:checked {
      background-color: #ADB5BD;
      border: 2px solid #ADB5BD !important;
    }
  `;
}}
`;