import styled from '@emotion/styled';
import { ButtonGhost } from '@sofia/ui';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.card.border.primary};
  border-radius: 8px;
  padding: 8px 16px 16px 16px;
  min-width: 280px;
  height: 100%;
  margin-bottom: 32px;
  overflow-y: auto;

  @media (max-width: 930px) {
    border: none;
  } ;
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-bottom: 6px;
`;

export const ButtonGhostFilter = styled(ButtonGhost)`
  display: flex;

  margin-top: 0;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 16px;
`;

export const FilterText = styled.p`
  font-weight: bold;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Flex = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  padding: 8px;

  justify-content: center;
  align-items: center;

  > button {
    margin: 0;
    padding: 19px 24px;
  }
`;

export const EndContainer = styled.div`
  display: flex;

  padding: 8px;

  justify-content: center;
  align-items: center;
`;
