import React from 'react';
import {
  IUseAutoGrowingTextarea,
  IUseAutoGrowingTextareaProps,
} from './auto-growing-textarea.types';

const useAutoGrowingTextareaLogic = ({
  textareaRef,
}: IUseAutoGrowingTextareaProps): IUseAutoGrowingTextarea => {
  const handleOnInput = (event: React.FormEvent<HTMLTextAreaElement>): void => {
    const textarea = event.currentTarget;

    if (textarea.scrollHeight > 30) textarea.style.maxHeight = 'unset';

    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  React.useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;

      textarea.style.height = 'auto';
      textarea.style.maxHeight = 'unset';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, []);

  return {
    actions: {
      handleOnInput,
    },
  };
};

export default useAutoGrowingTextareaLogic;
