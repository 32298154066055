export enum StatusEnum {
  WARNING = 'WARNING',
  STANDARD = 'STANDARD',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  DEFAULT = 'DEFAULT',
}

export const COLOR_WARNING = '#FF8A00';
export const COLOR_STANDARD = '#C5D2E0';
export const COLOR_ERROR = '#BC3206';
export const COLOR_SUCCESS = '#008566';
export const COLOR_PRIMARY = '#004ECC';

export const StatusColors = [
  {
    name: StatusEnum.WARNING,
    value: COLOR_WARNING,
  },
  {
    name: StatusEnum.STANDARD,
    value: COLOR_STANDARD,
  },
  {
    name: StatusEnum.ERROR,
    value: COLOR_ERROR,
  },
  {
    name: StatusEnum.SUCCESS,
    value: COLOR_SUCCESS,
  },
  {
    name: StatusEnum.DEFAULT,
    value: COLOR_PRIMARY,
  },
];

export interface ItemsInterface {
  description: string;
  showButton: boolean;
  status?: StatusEnum;
  subTitle?: string;
  title: string;
}

export interface TimeLineInterface {
  items: ItemsInterface[];
  onClick?: () => void;
  onClickMobile?: () => void;
  textButton?: string;
  textButtonMobile?: string;
  isShowingTimelineDetails?: boolean;
}
