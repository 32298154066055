import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { SvgRateStar } from '@sofia/ui';

export const makeIdAnimation = (idPrefix: string): string =>
  `${idPrefix}-animation`;

export const makeId = (idPrefix: string, index: number): string =>
  `${idPrefix}-${index}`;

/**
 * Creates dataURI from svg icon to make shrink/grow from center animation */
export const dataURI = `data:image/svg+xml,${encodeURIComponent(
  renderToStaticMarkup(
    <SvgRateStar width={48} height={48} pathFill="#FFB800" />
  )
)}`;
