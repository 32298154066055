import React from 'react';
import { Switch } from 'react-router-dom';
import { GuardedRoute } from 'react-router-guards';
import { useServiceClient } from '@ampli/services';

import { GradeHomePage, GradeSubjectPage } from './lazy-routes';
import { useGetState } from '../state';
import { contentGuard } from '../guards';
import { useQueryParams } from '../hooks';
import { features } from '../config';
import routePath from './routes-path';
import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';

const Routes = () => {
  const { states } = useCourseEnrollmentsContext();
  const response = states.courseEnrollments;

  const handleGuardedRoute = async (to, from, next) => {
    await contentGuard(to, from, next, response);
  };
  const clients = useServiceClient('all');
  const queryParams = useQueryParams();
  const [state, actions] = useGetState();

  const meta = { clients, actions, state, queryParams };
  const authMeta = { ...meta, auth: { redirect: routePath.login } };
  const gradeMeta = { ...authMeta, toggle: features.grade };

  return (
    <Switch>
      <GuardedRoute
        exact
        path={routePath.grade}
        meta={gradeMeta}
        guards={[handleGuardedRoute]}
      >
        <GradeHomePage />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={routePath.subjectGrade}
        meta={gradeMeta}
        guards={[handleGuardedRoute]}
      >
        <GradeSubjectPage />
      </GuardedRoute>
    </Switch>
  );
};

export default Routes;
