import { useTheme } from '@emotion/react';
import React from 'react';
import { routePath } from '../../../../routes';
import { generatePath, useHistory } from 'react-router-dom';
import { Typography } from '@sofia/ui';
import { clickByKey } from '@ampli/utils';
import { ArrowRight } from '@ampli/icon';
import { number, string } from 'prop-types';
import * as Styled from './subject-grade-card-styles';
import { useBreakpoint } from '@ampli/hooks';
import { pushAmpliEvent } from '../../../../lib/ga';

const SubjectGradeCard = ({
  subjectEnrollmentId,
  courseType,
  courseVersionId,
  finalGrade,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const showSubjectGrade = () => {
    pushAmpliEvent('event', 'subject_grade_card');
    history.push(
      generatePath(routePath.subjectGrade, {
        courseType,
        courseVersionId,
        subjectEnrollmentId,
      })
    );
  };

  return (
    <Styled.Card
      hover={theme?.colors?.card?.background?.hover}
      background={theme?.colors?.card?.background?.primary}
      onClick={showSubjectGrade}
      onKeyDown={(event) => clickByKey(event, showSubjectGrade)}
      tabIndex={0}
      id="subject-pontuation-card"
    >
      <Styled.FlexSpaceBetween>
        <Typography
          id="subject-pontuation-text"
          color="#fff"
          size={isDesktop ? 20 : 16}
          margin={0}
        >
          Pontuação atual:
        </Typography>
        <Styled.Flex>
          <Typography
            color="#fff"
            size={isDesktop ? 20 : 16}
            margin={0}
            weight={700}
            id="subject-pontuation-value"
          >
            {`${finalGrade || 0} pontos de 100`}
          </Typography>
          <ArrowRight />
        </Styled.Flex>
      </Styled.FlexSpaceBetween>
    </Styled.Card>
  );
};

SubjectGradeCard.propTypes = {
  subjectEnrollmentId: string.isRequired,
  courseType: string.isRequired,
  courseVersionId: string.isRequired,
  finalGrade: number.isRequired,
};

export default SubjectGradeCard;
