import React, { useContext } from 'react';
import {
  CreativeType,
  CreativeStatus,
  ICreativeContextProps,
  ICreativeDisplayProps,
} from '../../types/creatives/exhibition-controller';

const CreativeContext = React.createContext({} as ICreativeContextProps);

const creativeOrder: CreativeType[] = ['chat-copilot', 'modal', 'sliding'];

export const CreativeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentCreativeIndex, setCurrentCreativeIndex] = React.useState<
    number | null
  >(0);
  const [, setCreativeStatuses] = React.useState<
    Record<string, CreativeStatus>
  >(() =>
    creativeOrder.reduce((acc, creative) => {
      acc[creative] = 'pending';
      return acc;
    }, {} as Record<string, CreativeStatus>)
  );

  const currentCreative =
    currentCreativeIndex !== null ? creativeOrder[currentCreativeIndex] : null;

  // Cada criativo tem sua própria regra de negócio para ser exibido
  const shouldDisplayCreative = ({
    type,
    businessRules = () => true,
  }: ICreativeDisplayProps) => {
    if (currentCreative !== type) return false;
    if (!!businessRules && !businessRules()) return false;

    return true;
  };

  const markCurrentAsDone = (type: CreativeType) => {
    setCreativeStatuses((prevStatuses: Record<string, CreativeStatus>) => {
      // Ignora criativos já finalizados
      if (prevStatuses[type] === 'done') {
        return prevStatuses;
      }

      const updatedStatuses: Record<string, CreativeStatus> = {
        ...prevStatuses,
        [type]: 'done',
      };

      const nextIndex = creativeOrder.findIndex(
        (creative) => updatedStatuses[creative] === 'pending'
      );

      setCurrentCreativeIndex(nextIndex >= 0 ? nextIndex : null);
      return updatedStatuses;
    });
  };

  return (
    <CreativeContext.Provider
      value={{
        currentCreative,
        markCurrentAsDone,
        shouldDisplayCreative,
      }}
    >
      {children}
    </CreativeContext.Provider>
  );
};

export const useCreativeContext = (): ICreativeContextProps =>
  useContext(CreativeContext);
