import { GuardFunction } from 'react-router-guards';
import { clearIndexedDB } from '../lib/logout';

const itemsLocalStorageKey = [
  'selectedCourseEnrollmentId',
  'selectedCourseId',
  'selectedCourseVersionId',
];

const loginAuthGuard: GuardFunction = async (to, _from, next) => {
  const state = to?.meta?.state;

  console.log('state--', state);

  const logoutPromises = itemsLocalStorageKey.map(async (key) => {
    if (state[key] !== null) {
      await clearIndexedDB();
    }
  });

  await Promise.all(logoutPromises);

  next();
};

export default loginAuthGuard;
