import React from 'react';

import { differenceInDays } from 'date-fns';

import { useListNotificationsById } from '../../../services/hooks';
import { Notification } from '../types';
import { useGetState } from '../../../../../state';
import { Creative } from '../../../types';

export type State = {
  filteredNotifications?: Creative[];
  notificationId: string | null;
  content: 'list' | 'details' | 'empty';
};

type ReducerAction = Partial<State>;

type NotificationsProviderProps = {
  children: React.ReactNode;
};

type NotificationsContextData = State & {
  previousNotifications: Creative[];
  recentNotifications: Creative[];
  courseEnrollmentId: string;
  notification: Notification;
  dispatch: React.Dispatch<Partial<State>>;
  loading: boolean;
};

const today = new Date();
const daysInterval = 7;

const initialValues: State = {
  filteredNotifications: undefined,
  notificationId: null,
  content: 'list',
};

const reducer: React.Reducer<State, ReducerAction> = (
  state: State,
  action: ReducerAction
) => ({ ...state, ...action });

export const NotificationsContext = React.createContext(
  {} as NotificationsContextData
);

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({
  children,
}: NotificationsProviderProps) => {
  const [state, dispatch] = React.useReducer(reducer, initialValues);

  const [{ selectedCourseEnrollmentId }] = useGetState();

  const {
    data: { bell, creative },
    loading,
  } = useListNotificationsById<Notification>(selectedCourseEnrollmentId, {
    context: { skipLogout: true },
  });

  const previousNotifications = creative?.filter(
    (notification) =>
      differenceInDays(today, new Date(notification?.createdDate)) >
      daysInterval
  );

  const recentNotifications = creative?.filter(
    (notification) =>
      differenceInDays(today, new Date(notification?.createdDate)) <=
      daysInterval
  );

  return (
    <NotificationsContext.Provider
      value={{
        previousNotifications,
        recentNotifications,
        notification: { bell, creative },
        courseEnrollmentId: selectedCourseEnrollmentId,
        dispatch,
        loading,
        ...state,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications: () => NotificationsContextData = () => {
  return React.useContext(NotificationsContext);
};

export const withNotificationsProvider = (
  children: React.ReactNode
): React.ReactElement => (
  <NotificationsProvider>{children}</NotificationsProvider>
);
