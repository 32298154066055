import * as Yup from 'yup';
import { isValidDateTest } from '../schema-tests';
const requiredMsg = 'Preenchimento obrigatório.';
export const createAnnotationSchema = () => Yup.array.of(Yup.object({
  courseEnrollmentId: Yup.string().required(requiredMsg),
  subjectId: Yup.string().required(requiredMsg),
  sectionId: Yup.string().required(requiredMsg),
  learningObjectId: Yup.string().required(requiredMsg),
  content: Yup.string().required(requiredMsg),
  createdDate: Yup.string().required(requiredMsg).test(isValidDateTest())
}));
export const updateAnnotationSchema = () => Yup.array.of(Yup.object({
  id: Yup.string().required(requiredMsg),
  content: Yup.string().required(requiredMsg)
}));
export const deleteAnnotationSchema = () => Yup.array.of(Yup.object({
  annotationId: Yup.string().required(requiredMsg)
}));