import React from 'react';
import { Input, Typography } from '@sofia/ui';
import { object } from 'prop-types';
import { weekWorkDayChoices } from '../../../../../constants';
import { useTheme } from '@emotion/react';
import { convertTextToHourMin } from '@ampli/utils';
import * as Styled from './mandatory-internship-info-form-work-load.styles';

const MandatoryInternshipInfoFormWorkLoad = ({ formik }) => {
  const theme = useTheme();

  const getFirstErrorMessage = React.useMemo(() => {
    const weeklySchedule = formik.errors.weeklySchedule;
    return typeof weeklySchedule === 'object' && weeklySchedule !== null
      ? Object.values(weeklySchedule[Object.keys(weeklySchedule)[0]])[0]
      : weeklySchedule;
  }, [formik.errors.weeklySchedule]);

  const onBlur = (e, field) => {
    const value = e.target.value;
    if (value?.length > 0) {
      const hour = value.length <= 2 ? `${value.padStart(2, '0')}:` : value;
      const hourMin = hour.padEnd(5, '0');
      formik.setFieldValue(field, hourMin);
    }
  };

  return (
    <>
      <Typography size={20} color={theme.colors.text.black} margin="32px 0">
        Escreva abaixo os horários em que você irá estagiar durante o período do
        estágio. Sua carga horária diária deve ser corrida (sem pausas), de no
        máximo 06 horas diárias e de 30 horas semanais.
      </Typography>
      <Styled.FieldWrapper>
        {weekWorkDayChoices.map(({ id, name, desc }) => {
          return (
            <Styled.Field key={id}>
              <Typography
                margin="0 0 8px 0"
                size={14}
                weight={700}
                color={theme.colors.text.black}
              >
                {desc}
              </Typography>
              <Styled.InputsWrapper>
                <Input
                  id={`weeklySchedule.${name}.start`}
                  aria-label={`Horário de início do estágio na ${name}`}
                  placeholder="00:00"
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    onBlur(e, `weeklySchedule.${name}.start`);
                  }}
                  value={
                    convertTextToHourMin(
                      formik.values?.weeklySchedule?.[name]?.start || ''
                    ) || ''
                  }
                  error={Boolean(
                    formik.touched.weeklySchedule?.[name]?.start &&
                      formik.errors.weeklySchedule?.[name]?.start
                  )}
                />
                <Typography size={16} color={theme.colors.text.black}>
                  ás
                </Typography>
                <Input
                  id={`weeklySchedule.${name}.finish`}
                  aria-label={`Horário de término do estágio na ${desc}`}
                  placeholder="00:00"
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    onBlur(e, `weeklySchedule.${name}.finish`);
                  }}
                  value={
                    convertTextToHourMin(
                      formik.values?.weeklySchedule?.[name]?.finish || ''
                    ) || ''
                  }
                  error={Boolean(
                    formik.touched.weeklySchedule?.[name]?.finish &&
                      formik.errors.weeklySchedule?.[name]?.finish
                  )}
                />
              </Styled.InputsWrapper>
            </Styled.Field>
          );
        })}
      </Styled.FieldWrapper>
      {formik.errors?.weeklySchedule &&
      Object.keys(formik.errors.weeklySchedule).length > 0 &&
      formik.touched?.weeklySchedule &&
      Object.keys(formik.touched.weeklySchedule).length > 0 ? (
        <Typography
          size={14}
          color={theme.colors.text.danger}
          margin="4px 0 0 0"
          weight={700}
        >
          {getFirstErrorMessage}
        </Typography>
      ) : (
        <></>
      )}
    </>
  );
};

MandatoryInternshipInfoFormWorkLoad.propTypes = {
  formik: object.isRequired,
};

export default MandatoryInternshipInfoFormWorkLoad;
