import React from 'react';
import { Modal, Typography } from '@sofia/ui';
import { IStatusModalProps } from './types';
import * as Styles from './modals.styles';

const BaseModal: React.FC<IStatusModalProps> = ({
  Footer,
  Icon,
  CustomComponent,
  title,
  description,
  handleModal,
}: IStatusModalProps) => {
  return (
    <Modal
      handleModal={handleModal}
      footer={Footer ? <Footer /> : null}
      contentPadding="0"
      hideCloseIcon
      padding="0"
    >
      <Styles.Content>
        <Styles.IconWrapper>
          <Icon />
        </Styles.IconWrapper>
        <Typography as="h4" size={20} weight={700} textAlign="center">
          {title}
        </Typography>
        {description ? (
          <Typography textAlign="center" margin="8px 0 0">
            {description}
          </Typography>
        ) : null}
        {CustomComponent ? <CustomComponent /> : null}
      </Styles.Content>
    </Modal>
  );
};

export default BaseModal;
