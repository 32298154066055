import { useGetState } from '../../../../../../state';
import { usePublicationFormContext } from '../../../../contexts/publication-form-context';
import { IDiscardPublicationReturn, IModalsLogicProps } from './types';

const useDiscardPublicationLogic = ({
  modal,
}: IModalsLogicProps): IDiscardPublicationReturn => {
  const [, { setOpenCommunitySection }] = useGetState();
  const {
    actions: { setStep },
  } = usePublicationFormContext();

  const handleModal = (event?: string) => {
    modal.hide();
    if (event === 'discard') {
      setOpenCommunitySection(false);
      setStep(0);
    }
  };

  return {
    actions: {
      handleModal,
    },
  };
};

export default useDiscardPublicationLogic;
