import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW } from '@sofia/ui';

const SIZES = {
  VH_16: convertPXToVH(16),
  VH_24: convertPXToVH(24),
  VW_24: convertPXToVW(24),
};

export const Wrapper = {
  Margin: styled.div`
    margin: ${SIZES.VH_24} ${SIZES.VW_24};
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SIZES.VH_16};
  `,
};

export const Flex = {
  FlexColumn: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SIZES.VH_16};
  `,
};
