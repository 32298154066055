import React from 'react';
import { string, func, node } from '@ampli/utils';
import {
  DialogBackdrop,
  DialogAlert,
  DialogHeader,
  DialogCloseButton,
  DialogSection,
  Paragraph,
  DialogFooter,
  TextButton,
  Text,
  Flex,
  Separator,
} from '@ampli/ui';

const OnboardingDocumentsSkipModal = ({
  title,
  description,
  skipLabel,
  onSkipClick,
  ...props
}) => {
  return (
    <>
      <DialogBackdrop {...props} />
      <DialogAlert {...props} aria-labelledby="skipModalTitle">
        <DialogHeader>
          <Text id="skipModalTitle" color="text-dark" size="x-large">
            {title}
          </Text>
          <DialogCloseButton onClick={props.hide} />
        </DialogHeader>
        <DialogSection>
          <Paragraph>{description}</Paragraph>
        </DialogSection>
        <Flex justifyContent="flex-end">
          <DialogFooter>
            <TextButton underline onClick={props.hide}>
              Voltar
            </TextButton>
            <Separator orientation="vertical" transparent margin="x-small" />
            <TextButton underline color="semantic-error" onClick={onSkipClick}>
              {skipLabel}
            </TextButton>
          </DialogFooter>
        </Flex>
      </DialogAlert>
    </>
  );
};

OnboardingDocumentsSkipModal.propTypes = {
  hide: func.isRequired,
  onSkipClick: func.isRequired,
  title: string.isRequired,
  description: node.isRequired,
  skipLabel: string.isRequired,
};

export default OnboardingDocumentsSkipModal;
