import React from 'react';
import { func, bool } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import {
  css,
  DialogBackdrop,
  Dialog,
  DialogHeader,
  DialogCloseButton,
  DialogSection,
  Text,
  Paragraph,
  DialogFooter,
  GradientButton,
  TextButton,
  getColor,
} from '@ampli/ui';

import { WITH_FULLSCREEN, WITHOUT_FULLSCREEN } from '../../constants';
import { RequestFullscreenImage, SecurityAlertImage } from '../image';

const modes = {
  [WITH_FULLSCREEN]: {
    title: 'Modo tela cheia',
    description:
      'Por medidas de segurança, a prova deverá ser realizada em modo tela cheia.',
    image: <RequestFullscreenImage aria-hidden />,
    buttonLabel: 'Entrar em modo tela cheia',
  },
  [WITHOUT_FULLSCREEN]: {
    title: 'Alerta de segurança',
    description: (
      <>
        <strong>É proibido sair da tela durante a prova.</strong>
        <br />O descumprimento desta orientação pode resultar na anulação do
        teste.
      </>
    ),
    image: <SecurityAlertImage aria-hidden />,
    buttonLabel: 'Entendi',
  },
};

const ExamRequestFullscreenModal = ({
  onRequestFullscreenClick,
  requestFullscreenLoading = false,
  fullscreenEnabled = true,
  ...props
}) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const mode = fullscreenEnabled ? WITH_FULLSCREEN : WITHOUT_FULLSCREEN;
  const data = modes[mode];

  return (
    <>
      <DialogBackdrop aria-hidden {...props} />
      <Dialog
        {...props}
        fullscreen
        className={css`
          background: ${getColor('gradient-linear-brand-light')};
        `}
      >
        <DialogHeader>
          <DialogCloseButton onClick={props.hide} aria-label="Fechar" />
          <Paragraph textAlign="center" marginSize="x-large">
            {data.image}
          </Paragraph>
          <Paragraph textAlign="center">
            <Text as="strong" color="text-dark" aria-hidden>
              {data.title}
            </Text>
          </Paragraph>
        </DialogHeader>
        <DialogSection>
          <Paragraph textAlign="center" marginSize="large">
            <Text size="small">{data.description}</Text>
          </Paragraph>
        </DialogSection>
        <DialogFooter>
          <Paragraph textAlign="center" marginSize="large">
            <GradientButton
              fluid={!isDesktop}
              onClick={onRequestFullscreenClick}
              loading={requestFullscreenLoading}
              disabledOnLoading={false}
            >
              {data.buttonLabel}
            </GradientButton>
          </Paragraph>
          <Paragraph textAlign="center">
            <TextButton fluid={!isDesktop} underline onClick={props.hide}>
              Desistir
            </TextButton>
          </Paragraph>
        </DialogFooter>
      </Dialog>
    </>
  );
};

ExamRequestFullscreenModal.propTypes = {
  hide: func.isRequired,
  onRequestFullscreenClick: func.isRequired,
  requestFullscreenLoading: bool,
  fullscreenEnabled: bool,
};

export default ExamRequestFullscreenModal;
