const handleDownloadFile = (documentFile: File): void => {
  const url = URL.createObjectURL(documentFile);
  const anchor = document.createElement('a');

  anchor.href = url;
  anchor.download = documentFile.name;
  anchor.click();
  URL.revokeObjectURL(url);
};

export default handleDownloadFile;
