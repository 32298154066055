import {
  LEARNING_OBJECT_COMPLETED_FRAGMENT,
  SUBJECT_ENROLLMENT_FRAGMENT,
  LOCAL_SECTION_ENROLLMENT_FRAGMENT,
} from '@ampli/services';
import { ApolloCache } from '@apollo/client';

const reflectAttendance = (
  {
    subjectEnrollmentId,
    sectionId,
    learningObjectId,
  }: {
    subjectEnrollmentId: string;
    sectionId: string;
    learningObjectId: string;
  },
  { cache }: { cache: ApolloCache<unknown> }
): void => {
  const learningObjectCacheId = cache.identify({
    __typename: 'LearningObjectEnrollment',
    id: learningObjectId,
  });

  cache.writeFragment({
    id: learningObjectCacheId,
    fragment: LEARNING_OBJECT_COMPLETED_FRAGMENT,
    data: { completed: true },
  });

  const sectionCacheId = cache.identify({
    __typename: 'SectionEnrollment',
    id: sectionId,
  });

  const section = cache.readFragment<{
    progress?: { completed: number; quantity: number };
  }>({
    fragment: LOCAL_SECTION_ENROLLMENT_FRAGMENT,
    id: sectionCacheId,
  });

  const sectionProgress = section?.progress || {
    completed: 0,
    quantity: 1,
  };

  if (sectionProgress.completed === sectionProgress.quantity) {
    const subjectEnrollmentCacheId = cache.identify({
      __typename: 'SubjectEnrollment',
      id: subjectEnrollmentId,
    });

    const subjectEnrollment = cache.readFragment<{
      progress: {
        completed: number;
        quantity: number;
        details: Array<{
          completed: number;
          quantity: number;
          type: string;
          percentage: number;
          percentageLabel: string;
        }>;
      };
    }>({
      fragment: SUBJECT_ENROLLMENT_FRAGMENT,
      id: subjectEnrollmentCacheId,
    });

    if (subjectEnrollment) {
      const subjectEnrollmentProgress = subjectEnrollment.progress;
      const completed = subjectEnrollmentProgress.completed + 1;
      const quantity = subjectEnrollmentProgress.quantity;
      const percentage = completed / quantity;
      const percentageLabel = `${Math.round(percentage * 100)}%`;

      const subjectEnrollmentProgressDetails =
        subjectEnrollmentProgress.details.map((detail) => {
          let completed = detail.completed;
          let percentage = detail.percentage;
          let percentageLabel = detail.percentageLabel;

          if (detail.type === 'ATTENDANCE') {
            completed += 1;
            percentage = completed / detail.quantity;
            percentage = percentage > 1 ? 1 : percentage;
            percentageLabel = `${Math.round(percentage * 100)}%`;
          }

          return {
            ...detail,
            completed,
            percentage,
            percentageLabel,
          };
        });

      cache.writeFragment({
        id: subjectEnrollmentCacheId,
        fragment: SUBJECT_ENROLLMENT_FRAGMENT,
        data: {
          ...subjectEnrollment,
          progress: {
            ...subjectEnrollmentProgress,
            completed,
            percentage,
            percentageLabel,
            details: subjectEnrollmentProgressDetails,
          },
        },
      });
    }
  }
};

export default reflectAttendance;
