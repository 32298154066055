import React, { useEffect, useRef } from 'react';
import { isMSESupported } from '@ampli/utils';
const useUnsupportedMSE = callbackWhenUnsupported => {
  const triggerControl = useRef(false);
  useEffect(() => {
    if (triggerControl.current === false) {
      triggerControl.current = true;
      if (!isMSESupported()) {
        callbackWhenUnsupported();
      }
    }
  }, [callbackWhenUnsupported, triggerControl]);
};
export default useUnsupportedMSE;