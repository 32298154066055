import styled from '@emotion/styled';
import { convertPXToVW, convertPXToVH, ButtonPrimary } from '@sofia/ui';
import { mediaQuery } from '@ampli/ui';
import { SvgGuideBook } from './guide-book';

export const BannerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${convertPXToVH(30)} ${convertPXToVW(30)};
  border-radius: 8px;
  margin: ${convertPXToVH(64)} auto;

  ${mediaQuery('mobile')} {
    flex-direction: column;
    padding: ${convertPXToVH(20)} ${convertPXToVW(20)};
    margin: ${convertPXToVH(32)} 0px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${convertPXToVW(10)};

  ${mediaQuery('mobile')} {
    align-items: center;
    text-align: center;
  }
`;

export const StyledButton = styled(ButtonPrimary)`
  font-size: 14px !important;
  padding: ${convertPXToVH(16)} ${convertPXToVW(24)};
  margin-top: 0px !important;

  ${mediaQuery('super')} {
    width: 50%;
  }

  ${mediaQuery('mobile')} {
    width: 100%;
  }
`;

export const StyledSvgGuideBook = styled(SvgGuideBook)`
  width: ${convertPXToVW(300)};
  margin: 0px ${convertPXToVH(13)};

  ${mediaQuery('mobile')} {
    margin-top: ${convertPXToVH(16)};
  }
`;
