import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const IntroductionContainer = styled.div`
  padding: 0 24px;
  ${mediaQuery('desktop')} {
    padding: 0;
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: 56px;

  .rich-text {
    font-weight: 400;
    font-size: ${convertPXToREM(18)};
    line-height: 168%;

    ${mediaQuery('desktop')} {
      font-size: 20px;
      font-size: ${convertPXToREM(20)};
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
