import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { Typography } from '@sofia/ui';
import { object } from 'prop-types';
import { DateTime } from '@ampli/utils';

const SyllabusInfoGraduationDateContent = ({ courseEndDate }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const theme = useTheme();
  return (
    <>
      <Typography
        margin={isDesktop ? '0 0 32px 0' : '0 0 16px 0'}
        size={16}
        color={theme.colors.text.black}
      >
        Sua data de formatura é calculada conforme seu progresso no curso e
        número de disciplinas em aberto, considerando o tempo de refação das
        matérias que tiverem reprova.
      </Typography>
      <Typography margin="0 0 8px 0" size={16} color={theme.colors.text.black}>
        Previsão da sua formatura
      </Typography>
      <Typography
        margin={0}
        size={16}
        color={theme.colors.text.black}
        weight={700}
      >
        {courseEndDate?.toLocaleString(DateTime.DATE_FULL)}
      </Typography>
    </>
  );
};

SyllabusInfoGraduationDateContent.propTypes = {
  courseEndDate: object,
};

export default SyllabusInfoGraduationDateContent;
