import React from 'react';
import { store } from 'react-notifications-component';
import { Toast } from '@sofia/ui';

const show = (message, title, type) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    container: 'top-right',
    dismiss: {
      duration: 5000,
      pauseOnHover: true,
      showIcon: true,
      onScreen: true,
    },
    content: (
      <Toast id={`toast${type}`} text={message} type={type} show={true} />
    ),
  });
};

const NotificationService = {
  success: (message, title) => show(message, title, 'success'),
  error: (message, title) => show(message, title, 'error'),
  info: (message, title) => show(message, title, 'info'),
  warning: (message, title) => show(message, title, 'warning'),
};

export default NotificationService;
