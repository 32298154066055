import React from 'react';

const ImageHandsOnNotebook = ({
  width = 88,
  height = 77,
  title = '',
  desc = '',
  ...props
}: {
  width?: number;
  height?: number;
  title?: string;
  desc?: string;
}): React.ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(title || desc
        ? { 'aria-labelledby': `${title || ''} ${desc || ''}` }
        : {})}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.9448 1.43244C75.9448 -2.92568 81.0786 12.6973 76.6312 41.9076C74.9545 52.9135 66.1477 60.5089 55.1432 61.2313C47.8828 61.7065 40.1081 62.2922 33.1472 60.3397C19.473 56.5029 13.7201 45.9396 15.0159 32.9193C16.5005 17.9855 37.7702 4.81361 53.9448 1.43244Z"
        fill="#DBE3EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.9348 25.1108C65.024 25.8498 63.9766 23.3452 63.2025 23.7056C59.8691 25.2522 51.2487 26.1236 46.2941 29.9831C45.5108 38.3045 41.2576 53.8978 40.5107 59.1306L46.4626 59.7191L63.6578 57.0183C63.5486 53.7929 66.8455 25.4894 65.9348 25.1108Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.2636 24.8584C62.6425 25.7763 55.8868 25.7396 49.0171 28.9888C47.4126 39.5443 47.3487 49.7463 45.7441 60.3017L63.1622 57.6445C62.4374 53.8721 68.1765 23.858 65.2636 24.8584Z"
        fill="#C5D2E0"
      />
      <path
        d="M15.7999 38.209L1.55469 51.8728L15.2185 72.5139L23.6494 62.048L15.7999 38.209Z"
        fill="#004ECC"
      />
      <path
        d="M74.5247 76L68.1289 68.1505L78.0134 47.2188L86.4442 55.3589L81.502 66.9877L74.5247 76Z"
        fill="#004ECC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.2955 47.4803C51.7134 46.7384 45.7071 51.2625 51.7089 54.1127C49.8278 54.0765 43.1592 60.4193 58.8006 60.3514C62.581 62.333 64.9679 64.2603 68.3022 67.8569C69.9441 65.7894 75.9918 58.4739 78.3051 47.6613C74.0418 42.4133 68.7897 38.5543 65.391 37.663C65.299 38.8076 64.9311 40.5585 64.8437 41.78C49.3771 38.975 49.0782 44.0646 53.2955 47.4803ZM39.4386 33.4556C42.5108 34.2971 44.2124 35.8715 40.9609 41.228C43.0121 42.3817 44.7321 48.4123 38.6154 48.7788C40.455 54.9089 29.5001 63.74 27.3569 57.18C26.2117 58.6866 24.4917 60.6093 23.1626 62.1023C21.8564 60.1207 16.264 49.4755 15.5098 38.3778C20.8723 32.9489 32.3193 25.7556 43.5639 27.3933C47.0776 27.9045 43.3708 31.5464 39.4386 33.4556Z"
        fill="white"
      />
      <path
        d="M63.5053 54.9919C50.3723 49.3637 41.6017 60.4063 56.2041 60.2836C60.4981 60.2472 64.8469 64.4433 68.1826 68.1211C69.3554 69.4168 72.8006 74.3084 74.4434 75.9314M77.8658 47.8453C75.5066 59.2107 69.2824 66.4845 67.9362 68.2575M84.2818 53.3598C83.5288 65.2752 79.066 71.0215 74.124 75.9996M65.4538 37.8575C69.8482 38.5348 78.5731 48.359 86.4447 55.3055M71.6598 45.8041C65.358 38.8212 44.9922 39.344 52.7406 47.2089M67.7993 50.3912C49.0079 41.2261 46.1787 53.2052 51.7641 54.0281M57.0072 28.1151C58.2165 28.1151 58.8736 24.8555 57.0072 23.7781C54.7256 22.446 51.7732 24.1281 53.2471 25.9147M62.7387 26.7421C63.9479 26.7421 64.605 23.4826 62.7387 22.4051C60.4571 21.0731 57.5046 22.7552 58.9785 24.5418M63.7243 22.8961C55.0952 24.9237 46.165 28.9698 46.0783 29.4698C44.9283 36.1572 41.2732 55.7783 40.146 58.6833M48.8847 29.8926C48.1317 31.7838 47.753 49.1319 46.8631 51.4231C46.4068 52.5779 46.133 61.2337 46.133 59.3652M41.0587 48.2045C41.4283 49.1773 41.8162 50.1411 42.2041 51.1049M51.0841 30.0563C52.2934 30.0563 52.9505 26.7967 51.0841 25.7193C48.8025 24.3872 45.8501 26.0693 47.324 27.8559M15.865 38.203C16.551 46.529 19.1088 54.5945 23.3487 61.802M29.5913 47.9908C45.8912 39.7441 37.6728 63.0294 28.1995 58.8561C26.1916 57.9696 32.0417 52.3096 29.1121 55.5101C26.9993 57.8241 24.8364 60.5609 23.2757 62.3293C21.9661 63.8295 17.9185 69.3304 16.1708 71.2852L15.2185 72.5109C11.9673 67.622 8.53195 58.8561 6.78763 51.1049L5.99738 47.9908M26.0639 43.6629C43.3631 32.8749 46.4114 48.7136 38.9642 48.8682M22.9335 39.2895C29.4224 33.0613 48.3599 29.9517 41.0039 41.2943M38.444 33.6614C43.5959 31.752 49.1858 26.5285 40.6754 27.074C22.744 28.2204 13.2145 40.8595 5.99738 47.9908M1.55469 51.6868C2.96798 50.8318 4.42801 49.5415 5.99738 47.9908"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.7139 8.57227C66.8679 9.66642 67.0386 10.2859 66.8537 11.3886"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.9523 15.0186C72.697 14.2013 73.0605 13.675 74.0263 13.1091"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.4453 19.166C75.5421 19.0284 76.1627 18.8689 77.2697 19.0784"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.625 7.35547C62.7791 8.44962 62.9497 9.06911 62.7648 10.1718"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImageHandsOnNotebook;
