import React from 'react';
import * as Styled from './section-content-header-styles';
import { Typography } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import Skeleton from '../../../skeleton/skeleton';
import { SectionContentHeaderProps } from '../types';

const SectionContentHeader = ({
  title,
  subtitle,
  loading,
  templateTitle,
}: SectionContentHeaderProps): React.ReactElement => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const bannerTitleUrl = templateTitle
    ? templateTitle[isDesktop ? 'bannerUrl' : 'mobileBannerUrl']
    : null;

  return (
    <Styled.Wrapper
      data-testid="section-content-header"
      bannerTitleUrl={bannerTitleUrl}
    >
      <Styled.Header>
        <Styled.Flex>
          {loading ? (
            <Skeleton height="45px" width="45%" margin="0 0 24px 0" />
          ) : (
            <Typography
              as="h1"
              weight={700}
              data-testid="section-content-header-title"
            >
              <span>{title}</span>
            </Typography>
          )}
        </Styled.Flex>
        {subtitle ? (
          <Typography
            color={theme.colors.text.lightBlack}
            data-testid="section-content-header-subtitle"
          >
            {subtitle}
          </Typography>
        ) : null}
      </Styled.Header>
    </Styled.Wrapper>
  );
};

SectionContentHeader.defaultProps = {
  loading: false,
  title: '',
  subtitle: '',
};

export default SectionContentHeader;
