import React from 'react';

import CustomizationSection from '../../section/customization-section';
import ThemePicker from './theme-picker/theme-picker';
import { ColorSectionProps } from '../../../types';
import { pushAmpliEvent } from '../../../../../../../../../../lib/ga';

const ColorSection = ({
  selectedCustomization,
  reset,
  onClickEvent,
  defaultTemplate,
  showNewTemplateCommunication,
}: ColorSectionProps): React.ReactElement => {
  return (
    <CustomizationSection title="Configurações de Cores">
      <ThemePicker
        reset={reset}
        defaultTemplate={defaultTemplate}
        defaultValue={selectedCustomization?.theme}
        showNewTemplateCommunication={showNewTemplateCommunication}
        onChange={(selectedTheme) => {
          pushAmpliEvent('event', `kindle-theme-${selectedTheme.title}`);
          onClickEvent('theme', selectedTheme);
        }}
      />
    </CustomizationSection>
  );
};

export default ColorSection;
