import { TLearningObject } from '../types';

const findLearningObjectIndex = (
  learningObjectPerType: TLearningObject | null,
  learningObjects: TLearningObject[]
): number =>
  learningObjects?.findIndex(
    (learningObject) =>
      learningObjectPerType && learningObject.id === learningObjectPerType.id
  );

export default findLearningObjectIndex;
