import React from 'react';
import { useTheme } from '@emotion/react';
import { CardButton, Typography, SvgPlus } from '@sofia/ui';
import * as Styled from './notes-menu-object-new-card.styles';
import { NotesMenuObjectNewCardProps } from '../../../../types';

const NotesMenuObjectNewCard = ({
  notesSize,
  onNewNote,
}: NotesMenuObjectNewCardProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <Styled.Wrapper>
      <CardButton
        borderType="dashed"
        borderColor={theme.colors.card.border.primary}
        hoverColor={theme.colors.background.secondary}
        backgroundColor={theme.colors.background.primary}
        onClick={onNewNote}
      >
        <Styled.Column>
          <Styled.Flex>
            <Styled.IconWrapper>
              <SvgPlus fill={theme.colors.text.primary} />
            </Styled.IconWrapper>
            <Typography size={20} weight={700} color={theme.colors.text.black}>
              Nova anotação
            </Typography>
          </Styled.Flex>

          {notesSize === 0 ? (
            <Typography
              size={16}
              color={theme.colors.text.lightBlack}
              margin="8px 0 0 0"
            >
              Anotações ajudam a fixar partes importantes do conteúdo e auxiliar
              na resolução dos exercícios.
            </Typography>
          ) : null}
        </Styled.Column>
      </CardButton>
    </Styled.Wrapper>
  );
};

export default NotesMenuObjectNewCard;
