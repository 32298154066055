import { ApolloClient } from '@apollo/client';
import { ILive } from '../../live/features/common/types';

export interface ICourseEnrollmentsData {
  id: string;
  status: ECourseEnrollmentStatus;
  startDate: Date;
  estimatedEndDate: Date;
  studentId: string;
  erpIntegration: boolean;
  freeTrialStatus: boolean;
  externalId: string;
  classShift: string;
  gradingPeriodSequence: string;
  modality: string;
  submodality: string;
  lives: ILive[];
  roles: string[];
  tracks: ITracks[];
  course: ICourse;
  subjectsProgress: ISubjectProgress;
  workloadProgress: IWorkloadProgress;
  subjectEnrollments: ISubjectEnrollment[];
  subjectEnrollmentsInProgress: ISubjectEnrollment[];
  subjectEnrollmentsTaken: ISubjectEnrollment[];
}

interface ISubjectProgress {
  conclusionPercentage: string;
  passedCourseNumber: string;
  totalCourseNumber: string;
}

interface IWorkloadProgress {
  conclusionPercentage: string;
  passedWorkloadNumber: string;
  totalWorkloadNumber: string;
}

export interface ICourseEnrollmentsProviderProps {
  children: React.ReactNode;
}

export interface ICourseEnrollmentsReturn {
  states: {
    courseEnrollments: {
      data: ICourseEnrollmentsData;
      loading: boolean;
      error: unknown;
      refetch: () => Promise<void>;
      client: ApolloClient<unknown>;
    };
  };
}

export interface ICourseType {
  code: string;
  degree: string;
  name: string;
}

export interface ICourse {
  id: string;
  name: string;
  courseType: ICourseType;
  courseStructureDocument: string;
  versionId: string;
  complementaryWorkload: number;
  workload: number;
}
export interface ISubjectEnrollment {
  id: string;
  isElective: boolean;
  electiveType: EElectiveType;
  startDateForChoice: string;
  endDateForChoice: Date;
  subject: ISubject;
  courseSubject: ICousrseSubject;
  status: ESubjectEnrollmentStatus;
  startDate: Date;
  endDate: Date;
  closingDate: Date;
  assignments: ISubjectEnrollmentAssignment[];
  gradingMethod: IgradingMethod;
  choices: ISubjectEnrollmentChoice[];
  learningUnits: ILearningUnitEnrollment[];
  startEndInterval: string;
  examStartEndInterval: string;
  assignmentStartEndInterval: string;
  progress: ISubjectEnrollmentProgress;
}

interface ITracks {
  key: string;
  value: string;
}

enum EElectiveType {
  MANDATORY,
  OPTIONAL,
}

export interface ISubject {
  id: string;
  name: string;
  introduction: string;
}

interface ICousrseSubject {
  subType: ESubtype;
}

interface ISubjectEnrollmentAssignment {
  id: string;
  status: ESubjectEnrollmentAssignmentStatus;
  detail: IAssignment;
}

interface IAssignment {
  id: string;
  highestGradeAttemptId: string;
  startDate: Date;
  dueDate: Date;
  config: IAssignmentConfig;
}

interface IAssignmentConfig {
  id: string;
  type: EAssignmentConfigType;
  weight: number;
  durationInMinutes: number;
  reference: EAssignmentConfigReferenceType;
}

interface IgradingMethod {
  id: string;
  assignmentConfigs: IAssignmentConfig[];
}

interface ISubjectEnrollmentChoice {
  id: string;
  pathUrl: string;
}

interface ILearningUnitEnrollment {
  id: string;
}

interface ISubjectEnrollmentProgress {
  completed: number;
  quantity: number;
  percentage: number;
  details: ISubjectEnrollmentProgressDetail[];
}

interface ISubjectEnrollmentProgressDetail {
  type: EAssignmentConfigType;
  completed: number;
  quantity: number;
  percentage: number;
}

export enum ECourseEnrollmentStatus {
  ATTENDING = 'ATTENDING',
  FINISHED = 'FINISHED',
  SUSPENDED = 'SUSPENDED',
  CANCELLED = 'CANCELLED',
  ABANDONED = 'ABANDONED',
  DROPPED = 'DROPPED',
  TRANSFERRED = 'TRANSFERRED',
  FINISHING = 'FINISHING',
  RE_ENROLLMENT = 'RE_ENROLLMENT',
}

enum EAssignmentConfigType {
  EXAM,
  ATTENDANCE,
  QUIZ,
  ASSIGNMENT,
  PRESENTIAL_EXAM,
  SEMESTRAL_EXAM_ONLINE,
}

enum EAssignmentConfigReferenceType {
  SUBJECT,
  LEARNING_UNIT,
}

enum ESubjectEnrollmentAssignmentStatus {
  AVAILABLE,
  DONE,
  ONGOING,
  NOT_AVAILABLE,
  DISAPPROVED,
}

enum ESubtype {
  UNSPECIFIED,
  ACO_ED,
  DISCIPLINE,
  PEDAGOGICAL_PRACTICE,
  INTEGRATED_PROJECT,
  TCC_TFG,
  MANDATORY_INTERNSHIP,
}

export enum ESubjectEnrollmentStatus {
  UNAVAILABLE = 'UNAVAILABLE',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_RECOVERY = 'IN_RECOVERY',
  COMPLETED = 'COMPLETED',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  CEASED = 'CEASED',
  AWAITING_CHOICE = 'AWAITING_CHOICE',
  AWAITING_ENROLLMENT = 'AWAITING_ENROLLMENT',
  PRE_ENROLLMENT = 'PRE_ENROLLMENT',
  CANCELED = 'CANCELED',
  PAUSED = 'PAUSED',
}
