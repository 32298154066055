import React from 'react';
import * as Styled from './recordings-access.styles';
import { Typography, SvgArrowRight, SvgPlay, Card } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useGetState } from '../../../../../../state';
import { RecordingsRoutesPath } from '../../../../routes';
import { clickByKey } from '../../../../../shared';
import { ILive } from '../../../../../live/features/common/types';
import { useCourseEnrollmentsContext } from '../../../../../shared/contexts/course-enrollments-context';

const RecordingsAccessPresentation = (
  subjectRecordings: ILive[]
): React.ReactElement | null => {
  const theme = useTheme();
  const history = useHistory();
  const { subjectEnrollmentId } = useParams<{ subjectEnrollmentId: string }>();
  const { selectedCourseEnrollmentType, selectedCourseVersionId } =
    useGetState();
  const courseLiveRecordings =
    useCourseEnrollmentsContext()?.states?.courseEnrollments?.data?.lives?.filter(
      (live) => live.endDateTime
    );
  const onClick = () => {
    history.push(
      generatePath(RecordingsRoutesPath.recordings, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
        subjectEnrollmentId: subjectEnrollmentId,
      })
    );
  };

  return subjectRecordings?.length > 0 || courseLiveRecordings?.length > 0 ? (
    <Styled.Wrapper>
      <Card
        id="recordings-access"
        hover={theme.colors.card.color.hover}
        borderColor={theme.colors.card.border.primary}
        focusColor={theme.colors.card.border.third}
        tabIndex={0}
        onClick={onClick}
        onKeyDown={(event: React.KeyboardEvent<Element>) =>
          clickByKey({ event, onClick })
        }
      >
        <Styled.PlayWrapper>
          <SvgPlay fill={theme.colors.text.white} />
        </Styled.PlayWrapper>
        <Typography>Gravações de Lives</Typography>
        <SvgArrowRight fill={theme.colors.text.primary} aria-hidden />
      </Card>
    </Styled.Wrapper>
  ) : null;
};

export default RecordingsAccessPresentation;
