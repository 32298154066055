import React from 'react';
import { Switch } from 'react-router-dom';
import { GuardedRoute } from 'react-router-guards';
import { useServiceClient } from '@ampli/services';

import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';
import { SyllabusDocumentPage, SyllabusPage } from './lazy-routes';
import { useGetState } from '../state';
import { contentGuard } from '../guards';
import { useQueryParams } from '../hooks';

import routePath from './routes-path';

const Routes = () => {
  const clients = useServiceClient('all');
  const { states } = useCourseEnrollmentsContext();
  const response = states.courseEnrollments;

  const handleGuardedRoute = async (to, from, next) => {
    await contentGuard(to, from, next, response);
  };

  const queryParams = useQueryParams();

  const [state, actions] = useGetState();

  const meta = { clients, actions, state, queryParams };
  const authMeta = { ...meta, auth: { redirect: routePath.login } };
  return (
    <Switch>
      <GuardedRoute
        exact
        path={routePath.syllabus}
        meta={authMeta}
        guards={[handleGuardedRoute]}
      >
        <SyllabusPage />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={routePath.syllabusDocument}
        meta={authMeta}
        guards={[handleGuardedRoute]}
      >
        <SyllabusDocumentPage />
      </GuardedRoute>
    </Switch>
  );
};

export default Routes;
