import React from 'react';

import { SvgUserCircle, SvgArrowRight, SvgClose, Typography } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';

import { useStickBanner } from './sticky-banner.hook';
import { LayoutContent } from '../../../../components';

import * as Styled from './sticky-banner.styles';

const StickyBanner: React.FC = () => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const contentPadding = isDesktop ? ['small', 'huge'] : ['base'];

  const { openStickBanner, pendingEnroll, onClose, onCtaClick } =
    useStickBanner();

  return openStickBanner ? (
    <Styled.StickyBannerContainer>
      <LayoutContent padding={contentPadding}>
        <Styled.StickyBannerContent>
          <Styled.Flex>
            {isDesktop ? (
              <Styled.IconWrapper>
                <SvgUserCircle />
              </Styled.IconWrapper>
            ) : null}

            <Styled.FlexCol>
              <span>
                <Typography
                  as="strong"
                  color={theme.colors.text.black}
                  size={20}
                  margin="0px 4px 0px 0px"
                >
                  {pendingEnroll?.title}
                </Typography>
              </span>

              <Typography
                as="p"
                color={theme.colors.text.blackSecondary}
                margin="8px 0px 0px"
              >
                {pendingEnroll?.body}
              </Typography>
            </Styled.FlexCol>
          </Styled.Flex>

          <Styled.FlexResponsive>
            <Styled.LinkButton type="button" onClick={onCtaClick}>
              <Typography margin="0px 8px 0px 0px">
                {pendingEnroll?.ctaText}
              </Typography>
              <SvgArrowRight fill={theme.colors.text.primary} />
            </Styled.LinkButton>

            <Styled.CloseButton onClick={onClose}>
              <SvgClose />
            </Styled.CloseButton>
          </Styled.FlexResponsive>
        </Styled.StickyBannerContent>
      </LayoutContent>
    </Styled.StickyBannerContainer>
  ) : null;
};

export default StickyBanner;
