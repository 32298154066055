import React from 'react';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { ButtonGhost, ButtonPrimary, Typography } from '@sofia/ui';
import { Grid } from '@ampli/ui';

import { pushAmpliEvent } from '../../../../../lib/ga';
import {
  ImageBusinessWomanWritingNotes,
  ImageWomanReadingBook,
  ImageBusinessWomanFindingDocument,
} from '../../../../../components';
import { NotesOnboardingProps, StepColumnType } from './types';
import * as Styled from './styles';

const NotesOnboarding = ({
  onConcludeOnboarding,
  isResponsive,
}: NotesOnboardingProps): React.ReactElement => {
  const theme = useTheme();
  const history = useHistory();
  const [stepIndex, setStepIndex] = React.useState(0);

  const isLastStep = () => stepIndex === steps.length - 1;

  const handleConcludeOnboarding = () => {
    pushAmpliEvent('event', 'notes-onboarding-understood');
    onConcludeOnboarding();
  };

  const onNextStep = () => {
    if (!isLastStep()) {
      setStepIndex(stepIndex + 1);
    } else {
      handleConcludeOnboarding();
    }
  };

  const steps = [
    {
      title: 'Anote durante as aulas',
      desc: 'Você pode anotar e consultar anotações enquanto estuda.',
      image: ImageWomanReadingBook,
    },
    {
      title: 'Edite suas anotações',
      desc: 'Você consegue editar ou excluir anotações que fez durante as aulas.',
      image: ImageBusinessWomanWritingNotes,
    },
    {
      title: 'Navegue entre anotações',
      desc: 'Você consegue navegar entre anotações sem sair da aula.',
      image: ImageBusinessWomanFindingDocument,
    },
  ];

  const renderStepColumn = (
    { image: Image, title, desc }: StepColumnType,
    showDots = true
  ) => {
    return (
      <Styled.Column key={title}>
        <Styled.ImageWrapper>
          <Image />
        </Styled.ImageWrapper>

        <Typography
          weight={700}
          size={24}
          color={theme.colors.text.black}
          margin="0 0 8px"
        >
          {title}
        </Typography>
        <Styled.DescriptionWrapper>
          <Typography size={16} color={theme.colors.text.black}>
            {desc}
          </Typography>
        </Styled.DescriptionWrapper>

        {showDots ? (
          <Styled.StepWrapper>
            {steps.map((_step, index) => {
              return (
                <Styled.Step
                  key={index}
                  background={theme.colors.text.primary}
                  current={stepIndex === index}
                  onClick={() => setStepIndex(index)}
                />
              );
            })}
          </Styled.StepWrapper>
        ) : null}
      </Styled.Column>
    );
  };

  return (
    <Styled.Wrapper isResponsive={isResponsive} data-testid="notes-onboarding">
      {!isResponsive ? (
        <div>
          <Typography
            weight={700}
            size={48}
            color={theme.colors.text.black}
            textAlign="center"
          >
            Conheça suas anotações!
          </Typography>

          <Typography
            size={20}
            color={theme.colors.text.black}
            margin="10px 0"
            textAlign="center"
          >
            Você tem a possibilidade de criar e consultar anotações durante suas
            aulas e atividades.
          </Typography>
        </div>
      ) : null}

      <Styled.ContentWrapper>
        {isResponsive ? (
          renderStepColumn(steps[stepIndex])
        ) : (
          <Grid columns={3} columnGap={24}>
            <Styled.ContentWrapperList>
              {steps.map((step) => {
                return renderStepColumn(step, false);
              })}
            </Styled.ContentWrapperList>
          </Grid>
        )}
      </Styled.ContentWrapper>

      <Styled.ButtonsWrapper isResponsive={isResponsive}>
        <ButtonPrimary
          onClick={isResponsive ? onNextStep : handleConcludeOnboarding}
          color={theme.colors.text.white}
          background={theme.colors.button.primary}
          aria-label="Avançar para a próxima etapa"
          width="100%"
          data-testid="notes-onboarding-understood"
        >
          {isResponsive
            ? !isLastStep()
              ? 'Próximo'
              : 'Entendi'
            : 'Seguir para minhas anotações'}
        </ButtonPrimary>
        {isResponsive ? (
          !isLastStep() ? (
            <ButtonGhost
              onClick={() => {
                onConcludeOnboarding();
                pushAmpliEvent('event', 'notes-onboarding-skip');
              }}
              color={theme.colors.text.primary}
              hoverColor={theme.colors.button.darkPrimary}
              aria-label="Pular apresentação"
              width="100%"
            >
              Pular apresentação
            </ButtonGhost>
          ) : null
        ) : (
          <ButtonGhost
            onClick={() => {
              history.go(-1);
              pushAmpliEvent('event', 'notes-onboarding-go-back');
            }}
            color={theme.colors.text.primary}
            hoverColor={theme.colors.button.darkPrimary}
            aria-label="Voltar"
            width="100%"
          >
            Voltar
          </ButtonGhost>
        )}
      </Styled.ButtonsWrapper>
    </Styled.Wrapper>
  );
};

NotesOnboarding.defaultProps = {
  isResponsive: true,
};

export default NotesOnboarding;
