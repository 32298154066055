import React from 'react';
import { node, string, object, oneOfType } from 'prop-types';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: node.isRequired,
    FallbackComponent: oneOfType([node, string, object]),
  };

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      const FallbackComponent = this.props.FallbackComponent;
      // You can render any custom fallback UI
      return (
        <FallbackComponent
          error={this.state.error}
          errorInfo={this.state.errorInfo}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
