import { generatePath, Link, useParams, useHistory } from 'react-router-dom';
import { bool, number, object, string } from 'prop-types';
import { useBreakpoint } from '@ampli/hooks';

import { LayoutWrapper as StyledLayoutWrapper } from '../subject-presentation-styles';
import { LayoutContent } from '../../../layout';
import { routePath } from '../../../../routes';

import ElectiveSubjectConfirmation from '../../elective/confirmation/elective-subject-confirmation';
import SubjectBanner from '../../banner/subject-banner';
import SubjectTitle from '../../title/subject-title';
import React from 'react';

const SubjectPresentationHeader = ({
  subject,
  sectionCount,
  unitCount,
  isElective,
  isElectiveSelected,
  studentId,
  entitiesLabelsDictionary,
  selectedCourseEnrollmentType,
  selectedCourseVersionId,
}) => {
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const { subjectEnrollmentId } = useParams();

  return (
    <>
      <SubjectBanner>
        <Link
          to={generatePath(routePath.home, {
            courseType: selectedCourseEnrollmentType,
            courseVersionId: selectedCourseVersionId,
          })}
        >
          <span>Início</span>
        </Link>
        {!isElective ? (
          <Link onClick={() => history.goBack()}>
            <span>{entitiesLabelsDictionary?.mySubjectTitle}</span>
          </Link>
        ) : (
          <Link
            to={generatePath(routePath.electiveSubjectChoices, {
              courseType: selectedCourseEnrollmentType,
              courseVersionId: selectedCourseVersionId,
              subjectEnrollmentId: subjectEnrollmentId,
            })}
          >
            <span>Optativa</span>
          </Link>
        )}
        <b>{entitiesLabelsDictionary?.subjectPresentation}</b>
      </SubjectBanner>
      <StyledLayoutWrapper isDesktop={isDesktop} title>
        <LayoutContent>
          <SubjectTitle
            subjectName={subject?.name}
            subTitle={
              unitCount && sectionCount
                ? entitiesLabelsDictionary.learningUnitAndSectionCount(
                    unitCount,
                    sectionCount
                  )
                : ''
            }
            ellipsis
          >
            {isElective ? (
              <ElectiveSubjectConfirmation
                subject={subject}
                isSubjectSelected={isElectiveSelected}
                studentId={studentId}
              />
            ) : null}
          </SubjectTitle>
        </LayoutContent>
      </StyledLayoutWrapper>
    </>
  );
};

SubjectPresentationHeader.propTypes = {
  subject: object.isRequired,
  sectionCount: number,
  unitCount: number,
  isElective: bool,
  isElectiveSelected: bool,
  studentId: string,
  entitiesLabelsDictionary: object.isRequired,
  selectedCourseEnrollmentType: string.isRequired,
  selectedCourseVersionId: string.isRequired,
};

export default SubjectPresentationHeader;
