import { gql } from 'graphql.macro';

export const GET_ME = gql`
  query GetMe {
    data: me {
      id
      username
      fullName
      email
      personId
      phoneNumber {
        countryCode
        areaCode
        number
      }
      firstName @client
      phoneNumberString @client
    }
  }
`;

export const GET_ADDRESS = gql`
  query GetAddress($postalCode: PostalCode!) {
    data: getAddress(postalCode: $postalCode) {
      postalCode
      street
      neighbourhood
      cityId
      stateId
      state {
        id
        name
        acronym
      }
      city {
        id
        name
        latitude
        longitude
      }
      cityName @client
      stateAcronym @client
    }
  }
`;

export const GET_STUDENT_ADDRESS = gql`
  query GetStudentAddress($studentId: ID!) {
    data: getStudent(studentId: $studentId) {
      id
      address {
        postalCode
        street
        number
        complement
        neighbourhood
        city {
          id
          name
        }
        state {
          id
          acronym
        }
        cityName @client
        stateAcronym @client
      }
    }
  }
`;

export const GET_STUDENT_EMAIL = gql`
  query GetStudentContacts($enrollmentId: ID!) {
    data: getStudentContacts(enrollmentId: $enrollmentId) {
      mainEmail
    }
  }
`;
