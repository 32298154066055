import { gql } from 'graphql.macro';

export const CreateContentEvaluation = gql`
  mutation CreateContentEvaluation(
    $contentEvaluation: ContentEvaluationInput!
  ) {
    data: createContentEvaluation(contentEvaluation: $contentEvaluation) {
      id
    }
  }
`;

export const GetLastContentEvaluationByContentAndStudent = gql`
  query GetLastContentEvaluationByContentAndStudent(
    $studentId: String!
    $learningObjectId: String!
  ) {
    data: getLastContentEvaluationByContentAndStudent(
      studentId: $studentId
      learningObjectId: $learningObjectId
    ) {
      id
      studentId
      courseId
      courseTypeCode
      courseVersionId
      courseEnrollmentId
      subjectId
      subjectEnrollmentId
      learningUnitId
      learningUnitVersionId
      learningUnitEnrollmentId
      sectionId
      learningObjectId
      rating
      comment
      client
    }
  }
`;
