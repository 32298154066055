import { gql } from '@apollo/client';

export const GET_SUBJECT_CALENDAR = gql`
  query GetCallendarSubjectEnrollment($subjectEnrollmentId: ID!) {
    getCallendarSubjectEnrollment(subjectEnrollmentId: $subjectEnrollmentId) {
      subjectEnrollmentId
      title
      subTitle
      startDate
      dueDate
      currentFlow
      assignmentId
      type
      order
      warn
    }
  }
`;
