import { useUpdateAnnotation } from '@ampli/services';
import { MutateNoteHookProps } from './types';

const useUpdateNote = ({
  onCancel,
  onComplete,
  ...options
}: MutateNoteHookProps): [
  (note: { id: string; content: string }) => void,
  boolean
] => {
  const [updateMutate, { loading: updateNoteLoading }] = useUpdateAnnotation(
    null,
    {
      onCompleted: () => {
        onComplete();
        if (onCancel) onCancel();
      },
      ...options,
    }
  );

  const updateNote = (note: { id: string; content: string }) => {
    updateMutate({
      variables: {
        input: note,
      },
    });
  };

  return [updateNote, updateNoteLoading];
};

export default useUpdateNote;
