import styled from '@emotion/styled';

import { convertPXToREM } from '@sofia/ui';

export const ActionCardButton = styled.button`
  background-color: ${({ theme }) => theme.colors.card.background.white};
  border-color: ${({ theme }) => theme.colors.card.border.primary};
  border-width: 2px;
  border-style: solid;

  grid-gap: ${convertPXToREM(16)};
  padding: ${convertPXToREM(24)};
  border-radius: ${convertPXToREM(8)};

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: background-color 0.2s;

  :hover {
    border-color: ${({ theme }) => theme.colors.card.border.third};
    background-color: ${({ theme }) =>
      theme.colors.card.background.hoverDisabled};
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: ${convertPXToREM(16)};
`;
