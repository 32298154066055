import { useMutation } from '@ampli/services';
import { APPLY_INVOICE_COUPON } from './queries';
import { MutationApplyInvoiceCouponHook } from './types';

export const useMutationApplyInvoiceCoupon: MutationApplyInvoiceCouponHook = ({
  options,
}) => {
  const [mutate, { loading, error, data, ...rest }] = useMutation(
    APPLY_INVOICE_COUPON,
    { ...options }
  );

  const applyInvoiceCoupon = loading || !data ? {} : data.data;

  return [mutate, { loading, error, data: applyInvoiceCoupon, ...rest }];
};
