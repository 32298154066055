import React from 'react';

function ImageCelebratingSuccess(): React.ReactElement {
  return (
    <svg
      width="456"
      height="355"
      viewBox="0 0 456 355"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.931 48.7502C294.192 30.4953 343.472 72.144 329.06 166.859C323.627 202.546 295.089 227.174 259.429 229.516C235.902 231.057 210.708 232.956 188.152 226.625C143.841 214.184 125.199 179.933 129.398 137.714C134.209 89.291 154.518 59.7137 206.931 48.7502Z"
        fill="#FFD399"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M287.619 300.025C262.446 270.078 242.084 206.112 236.978 180.397C243.751 159.61 289.149 157.373 293.379 164.569C307.506 204.667 317.908 222.753 340.982 257.796C337.321 285.147 289.623 302.41 287.619 300.025Z"
        fill="#FFD399"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.697 309.701C190.937 283.321 208.63 220.294 217.133 189.778C213.43 168.231 168.824 159.472 163.602 166.015C146.004 203.78 129.841 244.69 110.935 264.328C127.108 300.204 160.194 313.099 163.697 309.701Z"
        fill="#FFD399"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M330.906 82.7275C333.249 93.1001 327.298 96.1391 322.013 97.2154C331.888 97.5003 333.027 98.9143 335.728 109.371C334.61 101.542 334.673 95.8226 345.054 92.256C342.997 92.657 332.784 97.2471 330.906 82.7275Z"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.418 79.7415C163.76 90.1141 157.81 93.153 152.524 94.2293C162.399 94.5143 163.538 95.9282 166.239 106.385C165.121 98.5557 165.184 92.8365 175.566 89.2699C173.498 89.692 163.285 94.2716 161.418 79.7415Z"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M206.214 46.0911C208.408 48.919 209.885 50.3857 211.141 53.7835"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M325.22 135.445C328.448 133.894 330.178 132.755 333.765 132.227"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M326 163.134C329.461 164.052 331.518 164.326 334.588 166.299"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M192.762 49.2566C194.957 52.0845 196.434 53.5513 197.689 56.949"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M293.559 86.062C296.154 83.5928 297.462 81.9784 300.722 80.385"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M287.851 81.2505C290.446 78.7813 291.755 77.1668 295.015 75.5735"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.082 137.028C139.853 135.477 138.123 134.337 134.536 133.81"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.322 164.717C138.861 165.635 136.804 165.909 133.734 167.882"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M287.619 300.025C262.446 270.078 242.084 206.112 236.978 180.397C243.751 159.61 289.149 157.373 293.379 164.569C307.506 204.667 317.908 222.753 340.982 257.796C337.321 285.147 289.623 302.41 287.619 300.025Z"
        fill="#33AB8C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M252.55 122.319C239.974 99.8851 233.897 89.7552 227.979 93.3323C217.946 99.3892 228.232 115.154 239.985 135.16C224.518 122.699 211.721 117.022 207.649 123.437C201.952 132.396 210.107 134.802 232.653 153.837C234.383 163.218 235.533 169.148 245.766 172.947C247.497 173.591 247.243 176.429 247.391 179.183C255.272 177.411 268.302 177.337 283.061 162.522C284.275 160.686 301.714 120.314 291.312 113.339C288.727 111.619 278.05 112.199 273.091 131.531C270.454 99.3997 263.364 84.7641 256.865 85.4817C252.149 85.9987 250.472 99.4419 252.55 122.319Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M330.906 82.7275C333.249 93.1001 327.298 96.1391 322.013 97.2154C331.888 97.5003 333.027 98.9143 335.728 109.371C334.61 101.542 334.673 95.8226 345.054 92.256C342.997 92.657 332.784 97.2471 330.906 82.7275Z"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.418 79.7415C163.76 90.1141 157.81 93.153 152.524 94.2293C162.399 94.5143 163.538 95.9282 166.239 106.385C165.121 98.5557 165.184 92.8365 175.566 89.2699C173.498 89.692 163.285 94.2716 161.418 79.7415Z"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M206.214 46.0911C208.408 48.919 209.885 50.3857 211.141 53.7835"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M325.22 135.445C328.448 133.894 330.178 132.755 333.765 132.227"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M326 163.134C329.461 164.052 331.518 164.326 334.588 166.299"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M192.762 49.2566C194.957 52.0845 196.434 53.5513 197.689 56.949"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M293.559 86.062C296.154 83.5928 297.462 81.9784 300.722 80.385"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M287.851 81.2505C290.446 78.7813 291.755 77.1668 295.015 75.5735"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.082 137.028C139.853 135.477 138.123 134.337 134.536 133.81"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.322 164.717C138.861 165.635 136.804 165.909 133.734 167.882"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.697 309.701C190.937 283.321 208.63 220.294 217.133 189.778C213.43 168.231 168.824 159.472 163.602 166.015C146.004 203.78 129.841 244.69 110.935 264.328C127.108 300.204 160.194 313.099 163.697 309.701Z"
        fill="#33AB8C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.117 129.663C225.795 109.287 233.275 100.117 238.603 104.528C247.655 111.978 235.206 126.086 220.668 144.182C237.769 134.084 251.263 130.317 254.365 137.25C258.711 146.947 250.292 148.139 225.236 163.725C222.176 172.757 220.172 178.498 209.505 180.745C207.701 181.136 207.533 183.985 206.994 186.675C199.451 183.784 186.569 181.832 174.099 165.044C173.171 163.06 161.734 120.557 173.044 115.186C175.85 113.856 186.327 115.966 188.458 135.815C195.706 104.401 204.832 90.9371 211.162 92.5516C215.751 93.7862 215.477 107.335 210.117 129.663Z"
        fill="white"
      />
      <path
        d="M208.123 181.494C207.934 182.718 206.752 185.779 206.689 186.507M297.515 179.637C283.357 197.417 256.296 204.508 241.599 201.965M282.217 163.978C284.897 161.351 299.963 119.797 291.639 115.006C278.535 107.451 270.939 139.709 269.99 139.888C261.929 141.471 250.335 157.214 255.219 165.466M272.1 131.953C274.21 92.6466 245.84 55.7041 252.571 123.152C253.51 132.649 235.037 78.6758 224.877 97.2473C223.474 99.8114 224.371 104.465 226.322 109.783M234.404 126.73L234.604 127.099C236.366 130.264 238.044 133.145 239.32 135.329M238.698 135.54C237.347 134.485 235.058 132.575 232.283 130.56L231.83 130.233M216.004 122.118C214.752 121.84 213.463 121.759 212.185 121.876H211.869M233.57 158.713C233.704 159.254 233.838 159.775 233.971 160.274M246.727 174.171C246.727 175.416 247.465 178.614 247.423 179.342M284.591 162.459C267.711 179.553 248.552 179.933 243.256 180.397M242.559 173.76C241.283 174.477 235.649 177.865 237.179 183.489C241.842 209.869 267.563 277.401 288.991 299.793M173.593 162.501C157.873 106.839 179.733 103.937 189.154 133.694C194.197 96.0233 226.08 64.9898 210.096 128.924C207.796 138.136 233.866 87.4445 241.23 107.293C244.089 115.017 226.829 136.121 220.615 143.94C219.729 145.058 247.465 125.569 254.523 136.49C261.581 147.411 226.038 161.425 225.215 163.324C220.879 173.876 220.003 177.78 208.261 180.386M172.39 164.421C186.664 183.774 205.528 186.918 210.708 188.153M157.103 179.574C168.55 199.222 194.303 210.175 209.168 209.732M172.063 163.767C173.223 164.653 163.275 162.712 160.954 167.988C153.917 189.218 132.394 238.074 111.262 264.634M233.95 160.169C235.849 167.386 237.706 170.721 246.948 174.192C237.717 170.784 235.86 167.492 233.961 160.274L233.95 160.169Z"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ImageCelebratingSuccess;
