import { useState, useCallback } from 'react';
const useSteps = function (stepsNum) {
  let initialStep = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  const [step, setStep] = useState(initialStep);
  const firstStep = 0;
  const lastStep = stepsNum - 1;
  const isFirstStep = step === firstStep;
  const isLastStep = step === lastStep;
  const isInitialStep = step === initialStep;
  const previousStep = useCallback(function () {
    let to = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
    if (!isFirstStep) setStep(step - to);
  }, [isFirstStep, step]);
  const nextStep = useCallback(function () {
    let to = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
    if (!isLastStep) setStep(step + to);
  }, [isLastStep, step]);
  const toStep = useCallback(stepNum => {
    if (stepNum < firstStep) {
      setStep(firstStep);
    } else if (stepNum > lastStep) {
      setStep(lastStep);
    } else {
      setStep(stepNum);
    }
  }, [firstStep, lastStep]);
  const resetSteps = useCallback(() => setStep(initialStep), [initialStep]);
  return {
    step,
    isFirstStep,
    isLastStep,
    isInitialStep,
    previousStep,
    nextStep,
    toStep,
    resetSteps
  };
};
export default useSteps;