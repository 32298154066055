import React from 'react';

import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { LoadingSpinner, Skeleton } from '../../../../../../../../components';
import { useTutorConversations } from '../../hooks/use-tutor-conversations';
import { useHomeConversations } from '../../hooks/use-home-conversations';
import { ConversationCard } from '../../components';
import { ListWrapperCore } from '../../../../../../../shared/components/section-right-frame/section-right-frame.styles';
import { usePtkFrame } from '../../contexts';

import * as Styled from './home-section.styles';

const HomeSection: React.FC = () => {
  const theme = useTheme();

  const { scrollType, conversationsHomeQueries, dispatch } = usePtkFrame();

  const {
    data: studentConversations,
    isLoading: studentIsLoading,
    isRefetching: studentIsRefetching,
    isFetching: studentIsFetching,
  } = useHomeConversations();

  const {
    data: tutorConversations,
    isLoading: tutorIsLoading,
    isRefetching: tutorIsRefetching,
    isFetching: tutorIsFetching,
  } = useTutorConversations();

  const conversations = React.useMemo(
    () => studentConversations?.content,
    [studentConversations?.content]
  );

  const studentRefetching = React.useMemo(
    () => studentIsRefetching || studentIsFetching,
    [studentIsFetching, studentIsRefetching]
  );

  const tutorRefetching = React.useMemo(
    () => tutorIsRefetching || tutorIsFetching,
    [tutorIsRefetching, tutorIsFetching]
  );

  const isListEmpty = React.useMemo(
    () =>
      !studentIsLoading &&
      !studentIsRefetching &&
      !studentIsFetching &&
      !tutorIsLoading &&
      !tutorIsRefetching &&
      !tutorIsFetching &&
      (!tutorConversations?.content ||
        tutorConversations?.content?.length === 0) &&
      (!conversations || conversations.length === 0),
    [
      conversations,
      studentIsFetching,
      studentIsLoading,
      studentIsRefetching,
      tutorConversations?.content,
      tutorIsFetching,
      tutorIsLoading,
      tutorIsRefetching,
    ]
  );

  React.useLayoutEffect(() => {
    if (isListEmpty) dispatch({ page: 'FORM_TYPE_SELECTION' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationsHomeQueries, isListEmpty]);

  return (
    <ListWrapperCore scroll={scrollType}>
      {tutorConversations?.content?.length ? (
        <React.Fragment>
          <Styled.Flex>
            <Typography
              color={theme.colors.text.black}
              size={20}
              weight={700}
              margin="0px 6px 0px 0px"
            >
              Mensagens do tutor:
            </Typography>

            {tutorRefetching ? (
              <LoadingSpinner
                color={theme.colors.text.primary}
                height={18}
                width={18}
              />
            ) : null}
          </Styled.Flex>

          <Styled.LastQuestionsList>
            {tutorConversations?.content?.map((data) => (
              <ConversationCard key={data.id} conversation={data} />
            ))}
          </Styled.LastQuestionsList>
        </React.Fragment>
      ) : null}

      {conversations?.length ? (
        <React.Fragment>
          <Styled.Flex>
            <Typography
              color={theme.colors.text.black}
              size={20}
              weight={700}
              margin="0px 6px 0px 0px"
            >
              Minhas mensagens:
            </Typography>

            {studentRefetching ? (
              <LoadingSpinner
                color={theme.colors.text.primary}
                height={18}
                width={18}
              />
            ) : null}
          </Styled.Flex>

          <Styled.LastQuestionsList>
            {conversations.map((data) => (
              <ConversationCard key={data.id} conversation={data} />
            ))}
          </Styled.LastQuestionsList>
        </React.Fragment>
      ) : null}

      {studentIsLoading || tutorIsLoading
        ? [0, 1, 2].map((i) => (
            <Skeleton key={i} height={120} margin="16px 0px" borderRadius={6} />
          ))
        : null}
    </ListWrapperCore>
  );
};

export default HomeSection;
