import { gql } from 'graphql.macro';

export const GET_DISCHARGE_DOCUMENT_YEARS = gql`
  query GetDischargeDocumentYears($courseEnrollmentId: String!) {
    data: getDischargeDocumentYears(courseEnrollmentId: $courseEnrollmentId) {
      years
      message
      warning
    }
  }
`;

export const SELECT_DISCHARGE_DOCUMENT_YEAR = gql`
  mutation SelectDischargeDocumentYear(
    $courseEnrollmentId: String!
    $year: String!
  ) {
    data: selectDischargeDocumentYear(
      courseEnrollmentId: $courseEnrollmentId
      year: $year
    ) {
      requestData {
        createdDate
        serviceForecast
        orderId
        title
        ticketNumber
        orderStatus
        fileUrl
      }
      status
    }
  }
`;
