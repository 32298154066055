import { useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
  useGetExam,
  useGetSubjectEnrollment,
  useLazyListAvailableExams,
} from '@ampli/services';

import { routePath } from '../routes';
import useHomePageData from './use-home-page-data';
import useExamTimes from './use-exam-times';
import { useGetState } from '../state';

const useExamInAnalysisPageData = (options = {}) => {
  const { onFetchListAvailableExamsCompleted } = options;
  const history = useHistory();
  const params = useParams();
  const { subjectEnrollmentId, examId } = params;
  const { loading: homeLoading, me, ...homePageData } = useHomePageData();

  const { data: exam, loading: examLoading } = useGetExam(examId);
  const {
    data: subjectEnrollment,
    loading: subjectEnrollmentLoading,
    startPolling: subjectEnrollmentStartPolling,
    stopPolling: subjectEnrollmentStopPolling,
  } = useGetSubjectEnrollment(subjectEnrollmentId, {
    fetchPolicy: 'network-only',
  });
  const [
    {
      selectedCourseEnrollmentType,
      selectedCourseEnrollmentId,
      selectedCourseVersionId,
    },
  ] = useGetState();
  const [fetchListAvailableExams] = useLazyListAvailableExams(
    selectedCourseEnrollmentId,
    {
      fetchPolicy: 'network-only',
      onCompleted: onFetchListAvailableExamsCompleted,
    }
  );

  const { maxDurationInMinutes, timeRunningOutMinutes } = useExamTimes(exam);

  const loading = homeLoading || examLoading;

  useEffect(() => {
    if (!loading && !exam) {
      console.warn('Exam not found.');
      history.replace(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })
      );
    }
  }, [
    history,
    loading,
    exam,
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
  ]);

  return {
    ...homePageData,
    me,
    loading,
    subjectEnrollment,
    exam,
    maxDurationInMinutes,
    timeRunningOutMinutes,
    subjectEnrollmentStartPolling,
    subjectEnrollmentStopPolling,
    subjectEnrollmentLoading,
    fetchListAvailableExams,
  };
};

export default useExamInAnalysisPageData;
