import * as Yup from 'yup';
import { TCreateContentEvaluationSchema } from './types';

const requiredMsg = 'Preenchimento obrigatório.';

export const createContentEvaluationSchema =
  (): TCreateContentEvaluationSchema =>
    Yup.array().of(
      Yup.object({
        studentId: Yup.string().required(requiredMsg),
        courseId: Yup.string().required(requiredMsg),
        courseTypeCode: Yup.string().required(requiredMsg),
        courseVersionId: Yup.string().required(requiredMsg),
        courseEnrollmentId: Yup.string().required(requiredMsg),
        subjectId: Yup.string().required(requiredMsg),
        subjectEnrollmentId: Yup.string().required(requiredMsg),
        learningUnitId: Yup.string().required(requiredMsg),
        learningUnitVersionId: Yup.string().required(requiredMsg),
        learningUnitEnrollmentId: Yup.string().required(requiredMsg),
        sectionId: Yup.string().required(requiredMsg),
        learningObjectId: Yup.string().required(requiredMsg),
        rating: Yup.number().required(requiredMsg),
        client: Yup.string().required(requiredMsg),
      })
    );
