import styled from '@emotion/styled';

export const Wrapper = styled.div`
  > div {
    display: flex;
    min-height: auto;
    padding: 21px 24px;
    align-items: center;
    @media (min-width: 1012px) {
      white-space: nowrap;
    }
    svg:last-of-type {
      margin-left: auto;
    }
  }
`;

export const PlayWrapper = styled.div`
  border-radius: 50%;
  padding: 14px;
  background-color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  margin-right: 16px;
`;
