// Calculates the verification digits
// from: https://gist.github.com/fiuzagr/a267fcc66388e0c43912364f0df042d6
//

const digitMod = (value, len) => {
  const mod = len - Math.round(value - Math.floor(value / len) * len);
  return mod >= 10 ? 0 : mod;
};
const mapValuesWithDigitModByKey = (_key, _len) => (value, key) => _key === key ? digitMod(value, _len) : value;
const reduceDigitsByKey = _key => (digits, value, key) => {
  if (key > _key) {
    digits[_key] = digits[_key] + value * (key + (_key === 0 ? 1 : 0));
    digits[key] = value;
  }
  return digits;
};
const genArrayDigits = len => new Array(len).fill(0);
const genRandomArrayDigits = len => genArrayDigits(len).map(() => Math.round(Math.random() * 9));
const reduceArrayDigits = (digits, verifyDigits) => verifyDigits.length ? reduceArrayDigits(digits.reduce(reduceDigitsByKey(verifyDigits.length - 1), genArrayDigits(verifyDigits.length)).map(mapValuesWithDigitModByKey(verifyDigits.length - 1, digits.length)), verifyDigits.slice(1)) : digits;
export const calculateDigits = (digits, verifyDigits) => reduceArrayDigits(digits.concat(verifyDigits).reverse(), verifyDigits).reverse();
export const generateCpf = () => calculateDigits(genRandomArrayDigits(9), genArrayDigits(2)).join('');
export const validateCpf = cpf => cpf && cpf.length === 11 && !/^(\d)\1{9}/.test(cpf) && calculateDigits(cpf.split('').slice(0, -2), genArrayDigits(2)).join('') === cpf;