import { useQuery, useMutation } from '@ampli/services';
import {
  GENERATE_INCOME_TAX_DOCUMENT,
  GET_AVAILABLE_INCOME_TAX_YEARS,
} from './queries';
import {
  GetAvailableIncomeTaxYearsHook,
  MutationGenerateIncomeTaxDocumentHook,
} from './types';

export const useGetAvailableIncomeTaxYears: GetAvailableIncomeTaxYearsHook = ({
  courseEnrollmentId,
  options = {},
}) => {
  const { data, loading, error, ...rest } = useQuery(
    GET_AVAILABLE_INCOME_TAX_YEARS,
    {
      ...options,
      context: {
        autoHandle: true,
      },
      variables: {
        courseEnrollmentId,
      },
    }
  );

  const availableIncomeTaxYears = loading || !data ? [] : data.data;

  return {
    data: availableIncomeTaxYears,
    loading,
    error,
    ...rest,
  };
};

export const useMutationGenerateIncomeTaxDocument: MutationGenerateIncomeTaxDocumentHook =
  () => {
    const [mutate, metadata] = useMutation(GENERATE_INCOME_TAX_DOCUMENT);
    return [mutate, metadata];
  };
