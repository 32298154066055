import { mediaQuery } from '@sofia/ui';
import styled from '@emotion/styled';

export const GridWrapper = styled.div`
  & > div > div {
    ${mediaQuery('tablet-max')} {
      padding: 24px;
    }
  }
`;
