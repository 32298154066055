import { loadScript } from '@ampli/utils';

import { zendeskKey, zendeskBotKey } from '../config';
import { closeWatsonAssistantChat } from './watsonAssistantChat';

const initialUpdateSettings = {
  webWidget: {
    chat: {
      suppress: false,
    },
    contactForm: {
      suppress: false,
    },
    helpCenter: {
      suppress: false,
    },
    talk: {
      suppress: false,
    },
    answerBot: {
      suppress: false,
    },
  },
};

const WEB_WIDGET = 'webWidget';
const WEB_WIDGET_ON = 'webWidget:on';

const WEB_WIDGET_BOT = 'messenger';
const WEB_WIDGET_BOT_ON = 'messenger:on';

let handleCloseCallback;

export const handleCloseWebWidget = () => {
  try {
    console.debug('ZE: Close web widget');
    window?.zE && window.zE(WEB_WIDGET, 'hide');
    handleCloseCallback && handleCloseCallback();
    handleCloseCallback = undefined;
  } catch (e) {
    console.error(e);
  }
};

export const handleCloseZendeskBot = () => {
  try {
    console.debug('ZE: Close zendesk bot');
    window?.zE && window.zE(WEB_WIDGET_BOT, 'hide');
    handleCloseCallback && handleCloseCallback();
    handleCloseCallback = undefined;
  } catch (e) {
    console.error(e);
  }
};

export const installZendesk = () =>
  loadScript(`https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`, {
    id: 'ze-snippet',
  })
    .then(() => {
      if (window.zE) {
        window.zE(WEB_WIDGET, 'hide');
        window.zE(WEB_WIDGET_ON, 'close', handleCloseWebWidget);
        window.zE(WEB_WIDGET_ON, 'open', () =>
          console.debug('ZE: Open web widget')
        );
      }
      return true;
    })
    .catch(console.error);

export const installZendeskBot = () =>
  loadScript(
    `https://static.zdassets.com/ekr/snippet.js?key=${zendeskBotKey}`,
    { id: 'ze-snippet' }
  )
    .then(() => {
      if (window.zE) {
        window.zE(WEB_WIDGET_BOT, 'hide');
        window.zE(WEB_WIDGET_BOT_ON, 'close', handleCloseZendeskBot);
        window.zE(WEB_WIDGET_BOT_ON, 'open', () =>
          console.debug('ZE: Open zendesk bot')
        );
      }
      return true;
    })
    .catch(console.error);

export const openZendesk = (settings = {}) => {
  try {
    closeWatsonAssistantChat();
    const {
      updateSettings = initialUpdateSettings,
      onOpen,
      onClose,
    } = settings;
    if (window.zE) {
      window.zE(WEB_WIDGET, 'updateSettings', updateSettings);
      window.zE(WEB_WIDGET, 'show');
      window.zE(WEB_WIDGET, 'open');
      onOpen && onOpen();
      handleCloseCallback = onClose;
    }
  } catch (e) {
    console.error(e);
  }
};

export const openZendeskChat = (props = {}) => {
  handleCloseWebWidget();
  openZendesk({
    ...props,
    updateSettings: {
      ...(props.updateSettings || {}),
      webWidget: {
        chat: {
          suppress: false,
        },
        contactForm: {
          suppress: false,
        },
        helpCenter: {
          suppress: true,
        },
        talk: {
          suppress: false,
        },
        answerBot: {
          suppress: true,
        },
        ...(props.updateSettings?.webWidget || {}),
      },
    },
  });
};

export const openZendeskBot = (settings = {}) => {
  try {
    const { onOpen, onClose } = settings;
    if (window.zE) {
      window.zE(WEB_WIDGET_BOT, 'show');
      window.zE(WEB_WIDGET_BOT, 'open');
      onOpen && onOpen();
      handleCloseCallback = onClose;
    }
  } catch (e) {
    console.error(e);
  }
};

export const prefillZendesk = ({ phoneNumber }) => {
  try {
    if (window.zE) {
      window.zE(WEB_WIDGET, 'prefill', {
        phone: {
          value: phoneNumber || '',
        },
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const parsePrefillAcademicTicketFormData = ({
  name,
  email,
  username,
  subjectEnrollmentName,
  courseName,
  unit,
  learningObject,
}) => {
  return [
    {
      id: 'name',
      prefill: {
        '*': name || '',
      },
    },
    {
      id: 'email',
      prefill: {
        '*': email || '',
      },
    },
    {
      id: 'key:360031428471',
      prefill: {
        '*': username || '',
      },
    },
    {
      id: 'key:360033582452',
      prefill: {
        '*': subjectEnrollmentName || '',
      },
    },
    {
      id: 'key:360033598231',
      prefill: {
        '*': courseName || '',
      },
    },
    {
      id: 'key:360033582752',
      prefill: {
        '*': unit || '',
      },
    },
    {
      id: 'key:360033582812',
      prefill: {
        '*': learningObject || '',
      },
    },
  ];
};
