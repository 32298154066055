import React from 'react';
import * as Styled from './live-access-card-label.styles';
import { useTheme } from '@emotion/react';
import { Typography } from '@sofia/ui';
import { ELiveState } from '../../../types';
import { useBreakpoint } from '../../../../../../shared';

interface ILiveAccessCardLabel {
  name: string;
  state: ELiveState;
}

const LiveAccessCardLabel = ({
  name,
  state,
}: ILiveAccessCardLabel): React.ReactElement => {
  const theme = useTheme();
  const isLive = state === ELiveState.LIVE;
  const { isDesktop } = useBreakpoint();

  return (
    <Styled.Wrapper isLive={isLive}>
      <Typography
        data-testid="live-access-card-label"
        color={theme.colors.text.black}
        {...(isDesktop && isLive
          ? {
              size: 24,
              weight: 700,
            }
          : {})}
      >
        {name}
      </Typography>
    </Styled.Wrapper>
  );
};

export default LiveAccessCardLabel;
