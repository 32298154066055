import React from 'react';
import { shape, string, bool } from '@ampli/utils';
import { Card, Separator } from '@ampli/ui';

import { questionChoicesStripTags } from '../../constants';
import { TextAsset } from '../asset';

const QuestionCorrection = ({ choice, answer, fontSize = 'medium' }) => {
  const emptyChoice = choice === undefined;

  return (
    <>
      <Card
        borderColor={answer.isCorrect ? 'semantic-success' : 'semantic-error'}
        label={emptyChoice ? 'Nenhuma alternativa assinalada' : 'Sua resposta'}
      >
        <TextAsset
          size={fontSize}
          color="text-dark"
          data={{ content: emptyChoice ? '' : choice.description }}
          stripTags={questionChoicesStripTags}
        />
      </Card>
      <Separator margin="xx-small" transparent />
      {!emptyChoice && (
        <Card
          background={
            answer.isCorrect ? 'semantic-success-light' : 'semantic-error-light'
          }
        >
          <TextAsset
            size={fontSize}
            color="text-dark"
            data={{ content: choice.feedback }}
            stripTags={questionChoicesStripTags}
          />
        </Card>
      )}
    </>
  );
};

QuestionCorrection.propTypes = {
  choice: shape({
    description: string,
  }),
  answer: shape({
    isCorrect: bool.isRequired,
    correctChoice: shape({
      feedback: string.isRequired,
    }).isRequired,
  }).isRequired,
  fontSize: string,
};

export default QuestionCorrection;
