import React from 'react';
import * as Styled from './video-player-styles';
import { VideoPlayerSecondaryProps } from '../../types';
import '@ampli/video-player/dist/styles';

const VideoPlayerSecondary = ({
  src,
  type,
  ...props
}: VideoPlayerSecondaryProps): React.ReactElement => {
  const sources = [{ type, src, cacheEncryptionKeys: true }];

  return (
    <div data-testid={`video-player-${type}`}>
      <Styled.JSVideoPlayer
        controls
        sources={sources}
        html5={{
          vhs: {
            overrideNative: false,
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false,
        }}
        {...props}
      />
    </div>
  );
};

export default VideoPlayerSecondary;
