import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const SectionTitle = styled.div`
  ${({ ellipsis }) =>
    ellipsis
      ? `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`
      : 'margin-top: 4px;'}
  color: ${({ color }) => color};
  font-size: ${convertPXToREM(16)};
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`;

export const SectionProgress = styled.div(
  ({ isDesktop }) => `
  display: flex;
  width: 100%;
  flex-direction: column;
  ${isDesktop ? 'margin: 0 32px;' : 'margin-top: 8px;'}
`
);

export const SectionInfo = styled.div`
  ${({ isDesktop }) => (isDesktop ? `min-width: 45%;` : 'width: 100%;')}
  display: flex;
  flex-direction: column;
`;
