import React from 'react';
import { ButtonPrimary } from '@sofia/ui';
import { ButtonContainer } from './reenrollment-period-modal.styles';
import { ButtonFooterProps } from './reenrollment-period-modal.types';
import { useTheme } from '@emotion/react';

const ButtonFooter: React.FC<ButtonFooterProps> = ({
  onClick,
  text,
}: ButtonFooterProps) => {
  const theme = useTheme();

  return (
    <ButtonContainer>
      <ButtonPrimary
        background={theme.colors.button.primary}
        color={theme.colors.button.text.primary}
        width={'100%'}
        size={16}
        onClick={onClick}
      >
        {text}
      </ButtonPrimary>
    </ButtonContainer>
  );
};

export default ButtonFooter;
