import React from 'react';

import { string, object, bool, func } from 'prop-types';
import { useBreakpoint } from '@ampli/hooks';

import { ModalTextWrapper } from './grade-styles';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import GradeSubjectListPunctuationCard from './grade-subject-list-punctuation-card';

const myGrades = (assignments) => {
  const groupAssignments = Object.groupBy(assignments, (a) => a.type);
  const resultAssignments = {};
  Object.keys(groupAssignments).forEach((key) => {
    resultAssignments[key] = groupAssignments[key].reduce((acc, next) => {
      if (!acc) return next;
      const aux = JSON.parse(JSON.stringify(acc));
      //todo levar essa regra pro back-end
      if (acc.type.includes('MANDATORY_INTERNSHIP')) {
        return aux;
      }
      if (acc.reference == 'SUBJECT' && acc.type != 'ASSIGNMENT') {
        if (acc.points > next.points) {
          //todo: usar currentFlow dos assignments
          aux.title = acc.title;
        } else {
          aux.title = next.title;
        }
        aux.points = Math.max(acc.points, next.points);
      } else {
        aux.points += next.points;
        aux.maxGrade += next.maxGrade;
      }
      aux.progress = (aux.points / aux.maxGrade) * 100;
      return aux;
    });
  });
  return resultAssignments;
};

const getAssignments = (subjectEnrollment, isMandatoryInternship) => {
  const learningUnitsAssignments = subjectEnrollment.learningUnits
    .map((lu) => lu.assignments)
    .flat()
    .map((a) => {
      return {
        key: a.learningUnitEnrollmentAssignment,
        points: a.grade || 0,
        maxGrade: a.maxGrade || 0,
        title: a.detail.display.name,
        reference: a.detail.config.reference,
        type: a.detail.config.type,
      };
    });
  // eslint-disable-next-line sonarjs/no-identical-functions
  const subjectAssingments = subjectEnrollment.assignments.map((a) => {
    let type = a.detail.config.type;
    if (a.detail.config.type == 'ASSIGNMENT' && isMandatoryInternship) {
      type = `MANDATORY_INTERNSHIP_${a.id}`;
    }
    return {
      key: a.id,
      points: a.grade || 0,
      maxGrade: a.maxGrade || 0,
      title: a.detail.display.name,
      reference: 'SUBJECT',
      type,
    };
  });
  return [...learningUnitsAssignments, ...subjectAssingments];
};
const GradeSubjectListGrades = ({
  subjectEnrollment,
  isMandatoryInternship,
  handleSubjectClick,
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const assignments = getAssignments(subjectEnrollment, isMandatoryInternship);
  const gradeAssignments = myGrades(assignments);
  if (!assignments) return null;

  return (
    <div>
      <Typography
        as="h3"
        margin="80px 0 24px 0"
        color={theme.colors.text.black}
        size={isDesktop ? '32' : '24'}
        weight={700}
      >
        Suas pontuações
      </Typography>
      <ModalTextWrapper content="end" flex direction="column" grid>
        {Object.values(gradeAssignments).map(({ key, ...assignment }) => {
          return (
            <GradeSubjectListPunctuationCard
              title={assignment.title}
              key={key}
              onClick={handleSubjectClick}
              {...assignment}
            />
          );
        })}
      </ModalTextWrapper>
    </div>
  );
};

GradeSubjectListGrades.defaultProps = {
  isMandatoryInternship: false,
};

GradeSubjectListGrades.propTypes = {
  className: string,
  subjectEnrollment: object.isRequired,
  isMandatoryInternship: bool,
  handleSubjectClick: func.isRequired,
};

export default GradeSubjectListGrades;
