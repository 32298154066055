import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW } from '../../utils';
export const FileUploadContainer = styled.section`
  width: 100%;
  position: relative;
  ${_ref => {
  let {
    error,
    borderColor,
    withFile
  } = _ref;
  if (error) {
    return `border: 2px solid #bc3206;`;
  } else {
    return `border: 2px ${withFile ? 'solid' : 'dashed'} ${borderColor};`;
  }
}}
  background: ${_ref2 => {
  let {
    background,
    withFile
  } = _ref2;
  return withFile ? '#fff' : background;
}};
  padding: ${convertPXToVH(24)} ${convertPXToVW(24)};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    background-color: ${_ref3 => {
  let {
    hoverColor
  } = _ref3;
  return hoverColor;
}};
    border-color: #698aae;
  }

  &:focus {
    border: 4px solid #004ecc;
    background-color: #f3f6f9;
  }
`;
export const FormField = styled.input`
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
export const UploadFileBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  color: #004ecc;
  padding: ${convertPXToVH(12)} ${convertPXToVW(12)} ${convertPXToVH(12)} 0;
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;

  svg {
    margin-right: ${convertPXToVW(12)};
  }
`;