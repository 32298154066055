import React from 'react';
import * as Styled from './download-file.styles';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

const DownloadFile = ({
  isFinished,
  isDownloading,
  isPrimary = false,
  children,
}: {
  isDownloading: boolean | null;
  isFinished: boolean;
  isPrimary?: boolean;
  children: NonNullable<React.ReactNode>;
}): React.ReactElement => {
  const theme = useTheme();

  return (
    <>
      {/* Control to know that the component is not in default state */}
      {!isFinished && isDownloading === null ? (
        children
      ) : (
        <Styled.Wrapper>
          {/* Control to know that the component is finished downloading but not restored to default state */}
          <Styled.LeftCurtain
            theme={theme}
            isFinished={isFinished}
            isPrimary={isPrimary}
          />
          <Styled.Ellipsis>
            {[0, 1, 2].map((index) => (
              <Styled.EllipsisDot
                key={index}
                theme={theme}
                isPrimary={isPrimary}
                isFinished={isFinished}
              />
            ))}
          </Styled.Ellipsis>

          <Typography
            color={
              isFinished && isDownloading === null
                ? 'transparent'
                : theme.colors.text[isPrimary ? 'white' : 'primary']
            }
          >
            Baixando
          </Typography>
          <Styled.RightCurtain
            theme={theme}
            isFinished={isFinished}
            isPrimary={isPrimary}
          />

          {/* Control to know that the component is finished downloading and will start timeout to reset to default state */}
          {isFinished && isDownloading === null ? (
            <Styled.Check
              fill={theme.colors.text[isPrimary ? 'white' : 'primary']}
            />
          ) : null}
        </Styled.Wrapper>
      )}
    </>
  );
};

export default DownloadFile;
