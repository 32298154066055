export const formToJson = form => Object.fromEntries(new FormData(form));
export const objectToBlobUrl = typeof window !== 'undefined' && window.URL.createObjectURL;
export const urlToBlob = function (url) {
  let settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const response = xhr.response;
      const fileBlob = new Blob([response], {
        type: settings.fileType || response.type
      });
      resolve(fileBlob);
    };
    xhr.onerror = reject;
    xhr.send();
  });
};
export const urlToBlobUrl = (url, settings) => urlToBlob(url, settings).then(objectToBlobUrl);
export const blobToFile = (blob, fileName) => {
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob;
};
export const stringToArrayBuffer = text => {
  const buf = new ArrayBuffer(text.length);
  const bufView = new Uint8Array(buf);
  for (const index in text) {
    bufView[index] = text.charCodeAt(index);
  }
  return buf;
};
export const hexToAscii = function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  for (const a of args) {
    if (!['number', 'string'].includes(typeof a)) {
      throw new Error('Invalid arguments.');
    }
  }
  const hex = args.join('').replace(/\s+/gi, '');
  const stack = [];
  for (var i = 0; i < hex.length; i += 2) {
    const code = parseInt(hex.substr(i, 2), 16);
    if (!isNaN(code) && code !== 0) {
      stack.push(String.fromCharCode(code));
    }
  }
  return stack.join('');
};
export const utf8Decoder = arrayBuffer => {
  return new TextDecoder().decode(arrayBuffer);
};