import styled from '@emotion/styled';
import { Separator, convertPXToREM } from '@sofia/ui';

export const Content = styled.div`
  display: grid;
  grid-gap: ${convertPXToREM(16)};
`;

export const Body = styled.div<{ isTextStep: boolean }>`
  padding: ${({ isTextStep }) => (!isTextStep ? convertPXToREM(24) : 0)};
  overflow-x: hidden;
`;

export const Footer = styled.div`
  padding: ${convertPXToREM(24)};

  position: relative;
  bottom: 0;
  background: white;
`;

export const StyledSeparator = styled(Separator)`
  position: relative;

  background-color: ${({ theme }) => theme.colors.card.background.inactive};
`;
