import React from 'react';

import { Breadcrumbs } from '@sofia/ui';
import { useHistory } from 'react-router-dom';
import { node, bool, func } from 'prop-types';
import { useTheme } from '@emotion/react';

const BreadcrumbWrapper = ({ children, shadow, backClick }) => {
  const history = useHistory();
  const theme = useTheme();
  const handleBackClick = () => {
    if (backClick) return backClick();
    history.go(-1);
  };

  return (
    <Breadcrumbs
      shadow={shadow}
      secondColor={theme.colors.breadCrumbs.secondary}
      firstColor={theme.colors.breadCrumbs.primary}
      fill={theme.colors.breadCrumbs.primary}
      backClick={handleBackClick}
    >
      {children}
    </Breadcrumbs>
  );
};

BreadcrumbWrapper.propTypes = {
  children: node,
  shadow: bool,
  backClick: func,
};

export default BreadcrumbWrapper;
