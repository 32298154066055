import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';

export const InnerText = styled.div`
  padding: 10px 16px;
`;

export const ActionsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    margin-top: 0;
    width: 100%;
  }
`;

export const Container = styled.div`
  max-width: 300px;
  padding: 24px;
  background: #fff;
  border-radius: 8px;
`;

export const AbsoluteTopSpace = styled.div`
  ${({ height }: { height: number }) =>
    height > 0 ? `height: ${height}px;` : 'display:none;'}
`;

interface IMuiPopover {
  absoluteTopSpace: number;
  arrowPosition: 'left' | 'center' | 'right';
}

export const MuiPopover = styled(Popover, {
  shouldForwardProp: (propName) => propName !== 'absoluteTopSpace',
})<IMuiPopover>(
  ({ absoluteTopSpace, arrowPosition }: IMuiPopover) => `
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
    border-radius: 8px;
    top: ${absoluteTopSpace > 0 ? 55 - absoluteTopSpace / 2 : 67}px !important;
    padding-top: ${absoluteTopSpace > 0 ? 0 : '12px'};
    margin-left: 1%;
    ${mediaQuery('desktop')} {
      margin-left: 4%;
    }
    filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.14));
    :after {
      content: '';
      display: block;
      position: absolute;
      top: ${absoluteTopSpace > 0 ? `${absoluteTopSpace + 3}px` : '14px'};
      right: ${
        arrowPosition === 'left'
          ? '90%'
          : arrowPosition === 'right'
          ? '10%'
          : '50%'
      };
      width: 20px;
      height: 12px;
      background-color: #fff;
      transform: translateY(-50%) rotate(45deg);
    }
  }
`
);

export const XBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 5px 0;
`;
