import { Theme } from '@emotion/react';

export const mockTheme: Theme = {
  colors: {
    background: {
      primary: 'background.primary',
      secondary: 'background.secondary',
      light: 'background.light',
      white: 'background.white',
      icon: {
        primary: 'background.icon.primary',
        secondary: 'background.icon.secondary',
        warning: 'background.icon.warning',
        danger: 'background.icon.danger',
      },
    },
    text: {
      primary: 'text.primary',
      secondary: 'text.secondary',
      black: 'text.black',
      blackSecondary: 'text.blackSecondary',
      lightBlack: 'text.lightBlack',
      light: 'text.light',
      white: 'text.white',
      lighterBlack: 'text.lighterBlack',
      danger: 'text.danger',
      success: 'text.success',
      lighterSuccess: 'text.lighterSuccess',
      lightSuccess: 'text.lightSuccess',
      warning: 'text.warning',
      darkerWarning: 'text.darkerWarning',
      disabled: 'text.disabled',
    },
    fileUpload: {
      border: {
        primary: 'fileUpload.border.primary',
        danger: 'fileUpload.border.danger',
      },
      background: {
        primary: 'fileUpload.background.primary',
      },
    },
    navBar: {
      color: 'navBar.color',
      button: 'navBar.button',
      active: 'navBar.active',
      border: 'navBar.background',
    },
    avatar: {
      border: 'avatar.border',
      text: 'avatar.text',
      background: {
        primary: 'ampliPalette.primary',
        secondary: 'ampliPalette.secondary',
        white: 'ampliPalette.white',
      },
    },
    slide: {
      dots: {
        active: 'slide.dots.active',
        inactive: 'slide.dots.inactive',
      },
    },
    banner: {
      primary: 'banner.primary',
    },
    icon: {
      primary: 'icon.primary',
      secondary: 'icon.secondary',
      black: 'icon.black',
      background: 'icon.background',
      warning: 'icon.warning',
      success: 'icon.success',
    },
    tab: {
      primary: 'tab.primary',
    },
    button: {
      primary: 'button.primary',
      secondary: 'button.secondary',
      darkPrimary: 'button.darkPrimary',
      darkerPrimary: 'button.darkerPrimary',
      lightBlack: 'button.lightBlack',
      backgroundColor: 'button.backgroundColor',
      success: 'button.success',
      danger: 'button.danger',
      text: {
        primary: 'button.text.primary',
      },
      focus: {
        primary: 'button.focus.primary',
        secondary: 'button.focus.secondary',
      },
    },
    breadCrumbs: {
      primary: 'breadCrumbs.primary',
      secondary: 'breadCrumbs.secondary',
      black: 'breadCrumbs.black',
      icon: 'breadCrumbs.icon',
    },
    calendar: {
      primary: 'calendar.primary',
      secondary: 'calendar.secondary',
      lightBlack: 'calendar.lightBlack',
      warning: 'calendar.warning',
    },
    card: {
      border: {
        primary: 'card.border.primary',
        secondary: 'card.border.secondary',
        third: 'card.border.third',
        success: 'card.border.success',
        danger: 'card.border.danger',
        shadow: true,
        borderType: 'dotted',
      },
      background: {
        primary: 'card.background.primary',
        secondary: 'card.background.secondary',
        hover: 'card.background.hover',
        warning: 'card.background.warning',
        success: 'card.background.success',
        danger: 'card.background.danger',
        disabled: 'card.background.disabled',
        hoverDisabled: 'card.background.hoverDisabled',
        inactive: 'card.background.inactive',
        white: 'card.background.white',
      },
      color: {
        background: 'card.color.background',
        primary: 'card.color.primary',
        secondary: 'card.color.secondary',
        third: 'card.color.third',
        hover: 'card.color.hover',
        warning: 'card.color.warning',
      },
    },
    creditCard: {
      background: {
        primary: 'creditCard.background.primary',
      },
      color: {
        primary: 'creditCard.color.primary',
      },
    },
    communication: {
      border: {
        primary: 'communication.border.primary',
      },
      background: {
        primary: 'communication.background.primary',
      },
      color: {
        primary: 'communication.color.primary',
        secondary: 'communication.color.secondary',
      },
    },
    presentation: {
      secondary: 'presentation.secondary',
      header: {
        primary: 'presentation.header.primary',
        secondary: 'presentation.header.secondary',
      },
    },
    kindle: {
      primary: 'kindle.primary',
      secondary: 'kindle.secondary',
      ternary: 'kindle.ternary',
      lightPrimary: 'kindle.lightPrimary',
      black: 'kindle.black',
      focus: 'kindle.focus',
      lightBlack: 'kindle.lightBlack',
      border: {
        secondary: 'kindle.border.secondary',
      },
    },
    live: {
      primary: 'live.primary',
      secondary: 'live.secondary',
      danger: 'live.danger',
      lightDanger: 'live.lightDanger',
    },
    page: {
      background: {
        primary: 'page.background.primary',
        secondary: 'page.background.secondary',
      },
    },
    input: {
      border: {
        primary: 'input.border.primary',
      },
      focus: {
        primary: 'input.focus.primary',
      },
    },
    checkbox: {
      border: {
        primary: 'checkbox.border.primary',
      },
      color: {
        primary: 'checkbox.color.primary',
      },
    },
    radiobutton: {
      border: {
        primary: 'radiobutton.border.primary',
      },
      color: {
        primary: 'radiobutton.color.primary',
      },
    },
    separator: {
      lightColor: 'separator.lightColor',
      color: 'separator.color',
    },
    tag: {
      primary: 'tag.primary',
      lightSuccess: 'tag.lightSuccess',
      success: 'tag.success',
      lighterSuccess: 'tag.lighterSuccess',
      danger: 'tag.danger',
      darkDanger: 'tag.darkDanger',
      warning: 'tag.warning',
    },
    wrapper: {
      color: 'wrapper.color',
    },
    scrollbar: {
      background: 'scrollbar.background',
      border: 'scrollbar.border',
    },
    alerts: {
      background: {
        primary: 'alerts.background.primary',
        secondary: 'alerts.background.secondary',
        tertiary: 'alerts.background.tertiary',
      },
      icon: {
        primary: 'alerts.icon.primary',
      },
    },
  },
};
