import React from 'react';
import { object, string, func } from 'prop-types';
import { DialogBackdrop } from '@ampli/ui';
import { ButtonPrimary, Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import {
  DialogContainer,
  DialogHeaderSection,
  DialogActionSection,
} from './student-card-modal-styles';
import { pushAnhangueraEvent } from '../../../../lib/ga';
import { analyticsEvents } from '../../constants';

export default function StudentCardModal({ typeOfStatus, handlers, setStep }) {
  const theme = useTheme();

  const handleCancel = () => {
    handlers.applyRequestNewStudentCardDialog.hide();
  };

  const handleContinue = () => {
    handlers.applyRequestNewStudentCardDialog.hide();
    typeOfStatus === 'isStudentCard'
      ? handlers.handleStepProgress('PRESENTATION')
      : setStep('default');

    pushAnhangueraEvent(
      'event',
      analyticsEvents[typeOfStatus]?.requestNewDocument
    );
  };

  return (
    <>
      <DialogBackdrop {...handlers.applyRequestNewStudentCardDialog} />
      <DialogContainer {...handlers.applyRequestNewStudentCardDialog}>
        <DialogHeaderSection>
          <Typography
            weight={700}
            color={theme.colors.text.black}
            margin="0 0 16px 0"
            size={32}
          >
            {typeOfStatus === 'isStudentCard'
              ? 'Solicitar nova carteirinha'
              : 'Solicitar novo histórico escolar'}
          </Typography>
          <Typography
            weigth="400"
            color={theme.colors.text.black}
            margin="0 0 32px 0"
            size={16}
          >
            {typeOfStatus === 'isStudentCard'
              ? 'Ao solicitar uma nova carteirinha excluirá automaticamente a anterior. Deseja realmente solicitar outra carteirinha?'
              : 'Ao solicitar um novo histórico escolar excluirá automaticamente o anterior. Deseja realmente solicitar outro histórico escolar?'}
          </Typography>
        </DialogHeaderSection>
        <DialogActionSection>
          <ButtonPrimary
            background={theme.colors.button.primary}
            color={theme.colors.text.white}
            margin="0"
            aria-label={'Solicitar nova carteirinha'}
            width={'100%'}
            className="button"
            onClick={handleContinue}
          >
            Sim, continuar
          </ButtonPrimary>
          <ButtonPrimary
            background={'transparent'}
            color={theme.colors.button.primary}
            margin="0"
            aria-label={'Cancelar'}
            width={'100%'}
            className="add-photo button"
            onClick={handleCancel}
          >
            Cancelar
          </ButtonPrimary>
        </DialogActionSection>
      </DialogContainer>
    </>
  );
}

StudentCardModal.propTypes = {
  typeOfStatus: string,
  handlers: object,
  setStep: func,
};
