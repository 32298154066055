import React from 'react';

import { SvgGuideBook, Typography, Card, ProgressBar, isEven } from '@sofia/ui';
import { arrayOf, object, func, bool } from 'prop-types';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';

import {
  WrapperSurface,
  WrapperText,
  Wrapper,
  FlexWrapper,
} from './grade-styles';
import { getGradeProgress } from './helper';
import { AssignmentRoutesPath } from '../../modules';
import { useGetState } from '../../state';
import { generatePath, useHistory, useParams } from 'react-router-dom';

const ListCorrections = ({ onShowCorrectionClick, corrections, infoColor }) => {
  const theme = useTheme();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';
  const {
    subjectEnrollmentId: paramSubjectEnrollmentId,
    assignmentId: paramAssignmentId,
  } = useParams();
  const [
    {
      selectedCourseEnrollmentType: courseType,
      selectedCourseVersionId: courseVersionId,
    },
  ] = useGetState();

  const infoColorCard = infoColor
    ? {
        noHover: true,
        cursor: 'default',
        background: theme.colors.background.primary,
      }
    : {};

  const infoColorProgress = infoColor
    ? {
        progressBackground: { background: theme.colors.background.info },
        progressBar: { background: theme.colors.card.border.secondary },
      }
    : {};

  const getCardMargin = (index) => {
    if (isMobile) {
      return '0 0 24px 0';
    } else {
      return !isEven(index) ? '0 0 24px 24px' : '0 0 24px 0';
    }
  };

  const onShowAssignmentFeedbacks = (correction) => {
    const subjectEnrollmentId =
      correction?.subjectEnrollment?.id || paramSubjectEnrollmentId;
    const assignmentId =
      correction?.assignment?.id ||
      correction?.assignment?.detailId ||
      paramAssignmentId;

    if (subjectEnrollmentId && assignmentId) {
      history.push(
        generatePath(AssignmentRoutesPath.assignmentCorrectionFeedbacks, {
          courseType,
          courseVersionId,
          subjectEnrollmentId,
          assignmentId,
        })
      );
    }
  };

  return (
    <>
      {!corrections?.length ? (
        <WrapperSurface>
          <SvgGuideBook />
          <WrapperText>
            <Typography size={24} weight={700} margin="0 0 24px 0">
              Você ainda não tem nenhuma correção disponível
            </Typography>
            <Typography size={16}>
              Caso você tenha feito alguma prova ou exercício, em breve estará
              disponível aqui para você conferir.
            </Typography>
          </WrapperText>
        </WrapperSurface>
      ) : (
        corrections.map((correction, index) => {
          return (
            <Card
              {...infoColorCard}
              flex="2 0 41%"
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex={1}
              minWidthMobile="auto"
              padding="32px"
              minHeight="0px"
              width="460px"
              margin={getCardMargin(index)}
              key={`correction_${index}`}
              id={`correction-card-${index}`}
              onClick={() =>
                correction.type === 'ASSIGNMENT'
                  ? onShowAssignmentFeedbacks(correction)
                  : onShowCorrectionClick(correction)
              }
              borderColor={theme.colors.card.border.primary}
            >
              <Wrapper id={`correction-internal-${index}`}>
                <Typography
                  as="span"
                  weight={700}
                  color={theme.colors.text.lightBlack}
                  id={`correction-type-${index}`}
                  size={16}
                >
                  {correction.display?.name}
                </Typography>
                <Typography
                  as="span"
                  margin="8px 0 10px 0"
                  weight={700}
                  color={theme.colors.text.black}
                  size={24}
                  id={`correction-name-${index}`}
                >
                  {correction.subjectName}
                </Typography>
                <Typography
                  weight={700}
                  color={theme.colors.text.black}
                  size={32}
                  margin="0 0 10px 0"
                  id={`correction-grade-${index}`}
                >
                  {correction.grade || 0} pontos
                </Typography>
                <ProgressBar
                  {...infoColorProgress}
                  progress={getGradeProgress(
                    correction.grade,
                    correction.maxGrade
                  )}
                  id={`correction-${index}`}
                />
                <FlexWrapper>
                  <Typography
                    weight={700}
                    color={theme.colors.text.lightBlack}
                    size={16}
                    margin="16px 0 0 0"
                    id={`correction-min-grade-${index}`}
                  >
                    0 pontos
                  </Typography>
                  <Typography
                    weight={700}
                    color={theme.colors.text.lightBlack}
                    size={16}
                    margin="16px 0 0 0"
                    id={`correction-max-grade-${index}`}
                  >
                    {correction.maxGrade} pontos
                  </Typography>
                </FlexWrapper>
              </Wrapper>
            </Card>
          );
        })
      )}
    </>
  );
};

ListCorrections.propTypes = {
  corrections: arrayOf(object),
  onShowCorrectionClick: func.isRequired,
  infoColor: bool,
};

ListCorrections.defaultProps = {
  infoColor: false,
  isMandatoryInternship: false,
};

export default ListCorrections;
