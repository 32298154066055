import {
  useFindStudentCourseEnrollmentsGradeHomePage,
  useGetLastDoneAssignments,
} from '@ampli/services';

import { generatePath, useHistory } from 'react-router-dom';
import { map } from '@ampli/utils';

import { EXAM_CUT_OFF_SCORE_PERCENTAGE } from '../constants';
import { useGetState } from '../state';
import { useHomeData } from '.';
import { routePath } from '../routes';

import React from 'react';

const defineIsApproved = (props) => {
  const gradePercentage = props.grade / props.maxGrade;
  const isApproved = gradePercentage >= EXAM_CUT_OFF_SCORE_PERCENTAGE;

  return {
    ...props,
    isApproved,
  };
};

const useGradeHomePageData = () => {
  const history = useHistory();

  const [
    {
      selectedCourseEnrollmentId,
      selectedCourseEnrollmentType,
      selectedCourseVersionId,
    },
  ] = useGetState();

  const { me, handleLogout } = useHomeData();

  const { data: courseEnrollment, loading: courseEnrollmentsLoading } =
    useFindStudentCourseEnrollmentsGradeHomePage(selectedCourseEnrollmentId);

  const { data, loading: lastDoneAssignmentsLoading } =
    useGetLastDoneAssignments(courseEnrollment?.id);

  const subjectEnrollmentsInProgress = React.useMemo(
    () => courseEnrollment.subjectEnrollmentsInProgress,
    [courseEnrollment.subjectEnrollmentsInProgress]
  );

  const subjectEnrollmentsTaken = React.useMemo(
    () => courseEnrollment.subjectEnrollmentsTaken,
    [courseEnrollment.subjectEnrollmentsTaken]
  );

  const lastDoneAssignments = React.useMemo(
    () => (data ? map(data, defineIsApproved) : undefined),
    [data]
  );

  const loading = React.useMemo(
    () => courseEnrollmentsLoading || lastDoneAssignmentsLoading,
    [courseEnrollmentsLoading, lastDoneAssignmentsLoading]
  );

  const handleLogo = React.useCallback(
    () =>
      history.push(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
        })
      ),
    [history, selectedCourseEnrollmentType]
  );

  return {
    me,
    loading,
    handleLogo,
    handleLogout,
    courseEnrollment,
    lastDoneAssignments,
    subjectEnrollmentsTaken,
    selectedCourseVersionId,
    subjectEnrollmentsInProgress,
    selectedCourseEnrollmentType,
  };
};

export default useGradeHomePageData;
