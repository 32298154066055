import { useState } from 'react';
import { useFormik } from '@ampli/utils';
import { useLoginStudent } from '@ampli/services';

import useLoginVendor from './use-login-vendor';
import { getId } from '../lib/client-uuid';

const useStudentLoginForm = ({ onSubmit, onError, onCompleted }) => {
  const [loginCompleted, setLoginCompleted] = useState(false);
  const [deviceWhiteListed, setDeviceWhiteListed] = useState(false);
  const [login, { data, called, loading, error }] = useLoginStudent({
    onError,
    onCompleted: (response) => {
      setDeviceWhiteListed(response?.data?.deviceWhiteListed);
      setLoginCompleted(true);
    },
  });

  // runs when the user logs in
  useLoginVendor({
    skip: !loginCompleted,
    deviceWhiteListed,
    onCompleted,
  });

  const handleSubmit = (values) => {
    const username = values.username;
    const password = values.password;
    const deviceId = getId();

    login({
      variables: {
        username,
        password,
        deviceId,
      },
    });
    onSubmit && onSubmit();
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: handleSubmit,
  });

  return {
    formik,
    loading,
    called,
    error,
    data,
  };
};

export default useStudentLoginForm;
