import React, { useCallback, useEffect } from 'react';
import { string, func, object, DateTime } from '@ampli/utils';
import { useBreakpoint, useSteps } from '@ampli/hooks';
import { LineSteps, FlexItem, useDialogState, Text } from '@ampli/ui';

import { DocumentsImage } from '../image';
import SideScreen from '../side-screen/side-screen';
import OnboardingContent from './onboarding-content';
import OnboardingSide from './onboarding-side';
import OnboardingPersonDocument from './onboarding-person-document';
import OnboardingCpfDocument from './onboarding-cpf-document';
import OnboardingSelfieDocument from './onboarding-selfie-document';
import OnboardingCertificateDocument from './onboarding-certificate-document';
import OnboardingDocumentsSkipModal from './onboarding-documents-skip-modal';
import OnboardingDocumentsSuccessModal from './onboarding-documents-success-modal';
import { useTheme } from '@emotion/react';

const OnboardingDocuments = ({
  onNextClick,
  formProps,
  courseStartDate,
  ...props
}) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const skipDialog = useDialogState();
  const successDialog = useDialogState({
    hideOnEsc: false,
    hideOnClickOutside: false,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showSuccessDialog = useCallback(successDialog.show, []);
  const isDriverLicense =
    formProps?.personDocument?.formik?.values?.documentType ===
    'DRIVER_LICENSE';

  const theme = useTheme();
  const { step, isLastStep, isFirstStep, nextStep } = useSteps(4);
  const stepProps = [
    {
      title: 'Foto de um documento',
      component: (
        <OnboardingPersonDocument formik={formProps.personDocument.formik} />
      ),
      formProps: formProps.personDocument,
    },
    {
      title: 'Foto do CPF',
      component: (
        <OnboardingCpfDocument
          formik={formProps.cpfDocument.formik}
          onSkipStepClick={nextStep}
        />
      ),
      formProps: formProps.cpfDocument,
    },
    {
      title: 'Foto do seu rosto',
      component: (
        <OnboardingSelfieDocument formik={formProps.selfiePhoto.formik} />
      ),
      formProps: formProps.selfiePhoto,
    },
    {
      title: 'Foto do certificado de conclusão',
      component: (
        <OnboardingCertificateDocument
          formik={formProps.certificateDocument.formik}
          onSkipStepClick={showSuccessDialog}
        />
      ),
      formProps: formProps.certificateDocument,
    },
  ];
  const currentStep = stepProps[step];
  const currentFormik = currentStep.formProps.formik;
  const currentCalled = currentStep.formProps.called;
  const currentLoading = currentStep.formProps.loading;
  const currentData = currentStep.formProps.data;
  const currentError = currentStep.formProps.error;

  const nextStepOrFinish = useCallback(() => {
    if (isLastStep) {
      showSuccessDialog();
    } else if (isFirstStep && isDriverLicense) {
      nextStep(2);
    } else {
      nextStep();
    }
  }, [isLastStep, isFirstStep, isDriverLicense, nextStep, showSuccessDialog]);

  useEffect(() => {
    if (currentCalled && !currentLoading && currentData && !currentError) {
      nextStepOrFinish();
    }
  }, [
    currentCalled,
    currentLoading,
    currentData,
    currentError,
    nextStepOrFinish,
  ]);

  return (
    <SideScreen
      {...props}
      side={
        isDesktop && (
          <OnboardingSide>
            <DocumentsImage />
          </OnboardingSide>
        )
      }
    >
      <OnboardingContent
        title={currentStep.title}
        image={!isDesktop && <DocumentsImage />}
        isDesktop={isDesktop}
        loading={currentLoading}
        onPrimaryClick={currentFormik.submitForm}
        onSecondaryClick={skipDialog.show}
        secondaryButtonLabel="Enviar depois"
      >
        <FlexItem flex={1}>{currentStep.component}</FlexItem>
        <LineSteps
          color={theme.colors.text.primary}
          stepActive={step + 1}
          qty={4}
        />
      </OnboardingContent>
      <OnboardingDocumentsSkipModal
        {...skipDialog}
        title="Enviar depois"
        description={
          <Text>
            O envio de documentos é obrigatório e caso não envie até o{' '}
            <strong>
              dia{' '}
              {courseStartDate
                ?.plus({ days: 90 })
                .toLocaleString(DateTime.DATE_SHORT)}
            </strong>{' '}
            seu acesso será <strong>bloqueado</strong>, tem certeza que deseja
            enviar depois?
          </Text>
        }
        skipLabel="Sim, enviar depois"
        onSkipClick={() => {
          skipDialog.hide();
          nextStepOrFinish();
        }}
      />
      <OnboardingDocumentsSuccessModal
        {...successDialog}
        onSuccessClick={onNextClick}
      />
    </SideScreen>
  );
};

OnboardingDocuments.propTypes = {
  formProps: object.isRequired,
  courseStartDate: object.isRequired,
  onNextClick: func.isRequired,
  className: string,
};

export default OnboardingDocuments;
