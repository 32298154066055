import styled from '@emotion/styled';
import { Separator } from '@sofia/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  width: 100%;
`;

export const StyledSeparator = styled(Separator)`
  position: absolute;
  width: calc(100% + 24px);

  top: calc(28% + 24px);
  left: 0;
`;
