import React from 'react';
import { ArrowLeft } from '@ampli/icon';
import { func, object } from 'prop-types';
import { Typography } from '@sofia/ui';

import { BackButtonWrapper } from './login-styles';

const BackButton = ({ onBackClick, theme }) => {
  return (
    <BackButtonWrapper
      id="go-back-button"
      data-testid="go-back-button"
      onClick={onBackClick}
    >
      <ArrowLeft
        id="go-back-icon"
        data-testid="go-back-icon"
        currentColor={theme.colors.text.black}
      />
      <Typography
        margin={`0 0 65px 16px`}
        size={16}
        color={theme.colors.text.black}
        weight={700}
        id="go-back-text"
        data-testid="go-back-text"
        aria-label="Voltar para etapa anterior, botão"
      >
        Voltar
      </Typography>
    </BackButtonWrapper>
  );
};

BackButton.propTypes = {
  onBackClick: func,
  theme: object,
};

export default BackButton;
