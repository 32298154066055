import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './card-styles';

function Card({ noBorder, children }) {
  return <Container noBorder={noBorder}>{children}</Container>;
}

Card.defaultProps = {
  noBorder: false,
};

Card.propTypes = {
  children: PropTypes.node,
  noBorder: PropTypes.bool,
};

export default Card;
