import styled from '@emotion/styled';
export const Wrapper = {
  Content: styled.div`
    border: 2px solid #b1c2d5;
    border-radius: 8px;
    display: flex;
    padding: 8px;
    flex-direction: column;
    background-color: white;
    transition: all 0.3s ease;
  `,
  Svg: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  `,
  SearchInput: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 0 5px;
    ${_ref => {
    let {
      isFocused
    } = _ref;
    return isFocused && `
    outline: 2px solid #004ECC;
    border-radius: 8px;
  `;
  }}
  `
};
export const Input = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  padding: 8px 0;
`;
export const SuggestionsList = {
  List: styled.ul`
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    max-height: 0;
    overflow: auto;
    transition: max-height 0.3s ease;
    ${_ref2 => {
    let {
      isVisible
    } = _ref2;
    return isVisible && `
    margin-top: 10px;
    max-height: 200px;
  `;
  }}
  `,
  Item: styled.li`
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
      border-radius: 8px;
    }
  `
};