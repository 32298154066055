import React from 'react';
import Button, { TalkTutorButtonProps } from './talk-tutor-button';

import { FrameProvider, useFrame } from '../../shared';
import { PtkFrameProvider } from '../ptk-frame/contexts';
import { withQueryClient } from '../../hocs/withQueryClient';

const ButtonContent: React.FC<TalkTutorButtonProps> = (
  props: TalkTutorButtonProps
) => {
  const { isAmpli } = useFrame();

  const withPtkProvider = (children: React.ReactNode) => {
    if (isAmpli) return <>{children}</>;

    return <PtkFrameProvider>{children}</PtkFrameProvider>;
  };

  return withPtkProvider(<Button {...props} />);
};

export const TalkTutorButton: React.FC<TalkTutorButtonProps> = (
  props: TalkTutorButtonProps
) => {
  return withQueryClient(
    <FrameProvider>
      <ButtonContent {...props} />
    </FrameProvider>
  );
};
