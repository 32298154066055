import { useFinishExam, useGetLocalExam } from '@ampli/services';
import { size, compact, map, Duration } from '@ampli/utils';
import { useParams, useHistory } from 'react-router-dom';
import { useDuration, useSteps } from '@ampli/hooks';
import { FINISHBY_WEB } from '../constants';
import { useEffect } from 'react';

import useQuizPageData from './use-quiz-page-data';
import useQuestionChoices from './use-question-choices';
import useQuizTimes from './use-quiz-times';

const useTakeQuizPageData = ({
  onFinishQuizCompleted,
  onFinishQuizError,
  onTimeout,
}) => {
  const history = useHistory();
  const { quizId } = useParams();

  const { loading, me, learningUnit, ...quizPageData } = useQuizPageData();

  const quiz = useGetLocalExam(!loading && quizId);

  const [finishQuiz, finishQuizMetadata] = useFinishExam({
    onError: onFinishQuizError,
    onCompleted: onFinishQuizCompleted,
  });

  const { maxDurationInMinutes, timeRunningOutMinutes } = useQuizTimes(quiz);

  const questions = quiz?.questions || [];

  const numberOfQuestions = questions.length || 0;

  const [questionChoices, setQuestionChoices] =
    useQuestionChoices(numberOfQuestions);

  const handleFinishQuiz = (
    finishedBy = 'UNSPECIFIED',
    finishedReason = ''
  ) => {
    const answers = map(questions, (question, index) => ({
      questionId: question.id,
      choiceId: questionChoices[index] || null,
    }));

    return finishQuiz({
      variables: {
        finishExamInput: {
          answers,
          examId: quizId,
          finishedBy,
          finishedReason,
        },
      },
    });
  };

  const handleTimeout = () => {
    onTimeout(handleFinishQuiz, {
      finishedBy: FINISHBY_WEB,
      finishedReason: 'Time out',
    });
  };

  const { duration, progress } = useDuration(
    {
      progress: { precision: 1 },
      timer: { milliseconds: 500 },
      end: { minutes: maxDurationInMinutes },
    },
    handleTimeout
  );

  const isTimeRunningOut = duration.as('minutes') > timeRunningOutMinutes;
  const incompleteQuiz = size(compact(questionChoices)) < numberOfQuestions;
  const stepsControl = useSteps(numberOfQuestions);

  const durationInteger = Duration.fromObject({
    minutes: Math.floor(duration.as('minutes')),
  });

  const quizTimeLeftInteger = Duration.fromObject({
    minutes: maxDurationInMinutes - durationInteger.as('minutes'),
  });

  useEffect(() => {
    if (!loading && !quiz) {
      console.warn('Quiz not found.');
      history.goBack();
    }
  }, [history, loading, quiz]);

  return {
    ...quizPageData,
    me,
    loading,
    learningUnit,
    quiz,
    duration,
    progress,
    isTimeRunningOut,
    questionChoices,
    setQuestionChoices,
    incompleteQuiz,
    stepsControl,
    numberOfQuestions,
    finishQuiz,
    finishQuizMetadata,
    handleFinishQuiz,
    maxDurationInMinutes,
    timeRunningOutMinutes,
    timeRunning: Math.floor(duration.as('minutes')),
    durationInteger,
    quizTimeLeftInteger,
  };
};

export default useTakeQuizPageData;
