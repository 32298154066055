import React from 'react';

import {
  IPublicationFormContext,
  PUBLICATION_CATEGORY,
  PublicationProviderProps,
} from '../types';

import {
  CATEGORY_STEP,
  TEXT_STEP,
} from '../features/create-publication/components/publication-form/publication-form.constants';

export const PublicationFormContext = React.createContext<
  IPublicationFormContext | undefined
>(undefined);

const PublicationFormProvider: React.FC<PublicationProviderProps> = ({
  children,
}: PublicationProviderProps) => {
  const titleTextareaRef = React.useRef<HTMLTextAreaElement>(null);
  const publicationTextareaRef = React.useRef<HTMLTextAreaElement>(null);
  const categorySelectedRef = React.useRef<PUBLICATION_CATEGORY>(
    PUBLICATION_CATEGORY.DOUBT
  );

  const [step, setStep] = React.useState<number>(0);

  const isTextStep = step === TEXT_STEP;
  const isCategoryStep = step === CATEGORY_STEP;

  const onSelectCategory = (category: PUBLICATION_CATEGORY) => {
    categorySelectedRef.current = category;
  };

  const value: IPublicationFormContext = React.useMemo(
    () => ({
      actions: { setStep, onSelectCategory },
      states: {
        publicationTextareaRef,
        categorySelectedRef,
        titleTextareaRef,
        isCategoryStep,
        isTextStep,
        step,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step]
  );

  return (
    <PublicationFormContext.Provider value={value}>
      {children}
    </PublicationFormContext.Provider>
  );
};

export const usePublicationFormContext = (): IPublicationFormContext => {
  const context = React.useContext(PublicationFormContext);
  if (!context) {
    throw new Error(
      'usePublicationFormContext must be used within a PublicationFormProvider'
    );
  }
  return context;
};

export default PublicationFormProvider;
