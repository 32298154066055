import React from 'react';

import { Icon, PopoverDisclosure } from '@ampli/ui';
import { SvgGraduationCap, SvgUsersAlt } from '@sofia/ui';
import { generatePath } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import { TNavigationItemsProps } from './navigation.types';
import { openZendeskBot } from '../../lib/zendesk';
import { CommunityPath } from '../../modules/community';
import { useBreakpoint } from '../../modules';
import { routePath } from '../../routes';

import NavLinkItem from './nav-link-item';
import HelpOrProfileButton from './help-or-profile-button';

import * as Styled from './navigation.styles';
import useNavigationItemsLogic from './navigation-items.logic';

const NavigationItems: React.FC<TNavigationItemsProps> = ({
  showPayment,
  actualPath,
  courseType,
  courseVersionId,
  isPaymentEnabled,
  routePathHome,
  firstName,
  hasChatEnabled,
  freeTrialPopoverState,
  shouldShowNotificationBell,
  isPathHome,
  shouldRedirect,
  redirectionBlockAction,
  setLastNavigationRoute,
}: TNavigationItemsProps) => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();
  const { actions } = useNavigationItemsLogic({
    shouldRedirect,
    redirectionBlockAction,
    setLastNavigationRoute,
  });

  return (
    <Styled.HelpWrapper>
      {(showPayment && (
        <>
          <NavLinkItem
            text="Início"
            icon={
              <SvgGraduationCap
                stroke={actions.handleGetLinkColor(
                  actions.checkActualPathTo(actualPath, routePath.home)
                )}
              />
            }
            id="my-courses-header"
            data-testid="my-courses-header"
            to={routePathHome}
            onClick={(event) =>
              actions.handleNavigateOrBlock(event, routePathHome)
            }
            exact
            activeClassName="actived"
            dataTestidText="text-courses-header"
          />

          {isDesktop ? (
            <NavLinkItem
              id="community"
              data-testid="community"
              activeClassName="actived"
              text="Comunidade"
              idText="txt-community-header"
              dataTestidText="txt-community-header"
              exact
              icon={<SvgUsersAlt fill={theme.colors.navBar.color} />}
              to={generatePath(CommunityPath.community, {
                courseType: courseType,
                courseVersionId: courseVersionId,
              })}
            />
          ) : null}

          <NavLinkItem
            id="secretary"
            data-testid="secretary"
            to={actions.getPath(routePath.secretaryStudent)}
            onClick={(event) => actions.handleClickSecretaryLink(event)}
            exact
            activeClassName="actived"
            text="Secretaria"
            icon={
              <Icon.User
                color={actions.handleGetLinkColor(
                  actions.checkActualPathTo(
                    actualPath,
                    routePath.secretaryStudent
                  )
                )}
              />
            }
            idText="txt-secretary-header"
            dataTestidText="txt-secretary-header"
          />

          {isDesktop ? null : (
            <NavLinkItem
              id="community"
              data-testid="community"
              activeClassName="actived"
              text="Comunidade"
              idText="txt-community-header"
              dataTestidText="txt-community-header"
              exact
              icon={<SvgUsersAlt fill={theme.colors.navBar.color} />}
              to={generatePath(CommunityPath.community, {
                courseType: courseType,
                courseVersionId: courseVersionId,
              })}
            />
          )}

          {isPaymentEnabled && (
            <PopoverDisclosure {...freeTrialPopoverState}>
              <NavLinkItem
                id="payment"
                data-testid="payment"
                to={actions.getPath(routePath.payment.home)}
                onClick={(event) =>
                  actions.handleNavigateOrBlock(
                    event,
                    actions.getPath(routePath.payment.home)
                  )
                }
                activeClassName="actived"
                exact
                idText="txt-payment-header"
                dataTestidText="txt-payment-header"
                icon={
                  <Icon.DollarSign
                    color={actions.handleGetLinkColor(
                      actions.checkActualPathTo(
                        actualPath,
                        routePath.payment.home
                      )
                    )}
                  />
                }
                text="Pagamento"
              />
            </PopoverDisclosure>
          )}
        </>
      )) || <></>}

      {!isPathHome && (
        <HelpOrProfileButton
          hasChatEnabled={hasChatEnabled}
          isPathHome={isPathHome}
          firstName={firstName}
          shouldShowNotificationBell={shouldShowNotificationBell}
          handleClickHelp={openZendeskBot}
          courseType={courseType}
          courseVersionId={courseVersionId}
          actualPath={actualPath}
          shouldRedirect={shouldRedirect}
          redirectionBlockAction={redirectionBlockAction}
          setLastNavigationRoute={setLastNavigationRoute}
        />
      )}
    </Styled.HelpWrapper>
  );
};

export default NavigationItems;
