import React from 'react';
import { Skeleton } from '../../../skeleton';
import * as S from './calendar-skeleton-styles';

export default function CalendarSkeleton() {
  return (
    <S.Container tabIndex={-1}>
      <Skeleton height={496} />
    </S.Container>
  );
}
