import styled from '@emotion/styled';

import { Typography, convertPXToVH } from '@sofia/ui';

interface MessageCardProps {
  background?: string;
}

export const MessageCard = styled.div<MessageCardProps>`
  background-color: ${({ background }) => background};
  padding: 16px;
  border-radius: 8px;
  margin: ${convertPXToVH(16)} 0px;
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TruncateText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  user-select: none;
`;

export const UploadFileContainer = styled.button`
  width: 100%;
  border: none;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: ${convertPXToVH(10)} 0px;
  margin-bottom: ${convertPXToVH(24)};
  border-radius: 8px;
`;

export const Time = styled(Typography)`
  color: #212529;
  letter-spacing: 0.25px;
`;
