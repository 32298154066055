import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { forEach } from 'lodash';
export const i18nCreator = _ref => {
  let {
    uses = [],
    init = {}
  } = _ref;
  i18n.use(Backend).use(LanguageDetector);
  forEach(uses, use => i18n.use(use));
  i18n.init(_objectSpread({
    fallbackLng: {
      'pt-BR': ['pt', 'en'],
      'pt-PT': ['pt', 'en'],
      default: ['en']
    },
    debug: false,
    interpolation: {
      escapeValue: false
    }
  }, init));
  return i18n;
};