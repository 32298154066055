import React from 'react';

import { Typography } from '@sofia/ui';
import { clickByKey } from '@ampli/utils';
import { useTheme } from '@emotion/react';

import { CategoryType, parsedCategory } from '../../../types';
import { CtaIconToggler } from '../../components/cta-icon-toggler';

import * as Styled from './sliding-core.styles';

type FooterObj = {
  buttonText?: string;
  buttonLink?: string;
};

interface PopupProps {
  onCtaClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  headerText?: string;
  category?: CategoryType;
  bodyText?: string;
  image?: string;
  footer?: FooterObj;
  handlePopUp: {
    state: boolean;
    hide: () => void;
    show: () => void;
  };
}

// O timing da animação de saída é de 600ms, mas o timing para fechar o popup é de 596ms.
// Disparando a função de fechar antes dos 600ms impede que o popup apareça novamente após a animação finalizar.
const leavingAnimationTimeToClose = 596;

const SlidingCore: React.FC<PopupProps> = ({
  handlePopUp,
  headerText,
  onCtaClick,
  bodyText,
  category: _category,
  footer,
  image,
}: PopupProps) => {
  const dialogRef: React.LegacyRef<HTMLDivElement> | null = React.useRef(null);

  const theme = useTheme();

  const category = parsedCategory(_category);
  const Icon = category?.icon;

  const onClose = () => {
    dialogRef.current?.classList.add('leaving');
    setTimeout(() => handlePopUp?.hide(), leavingAnimationTimeToClose);
  };

  React.useEffect(() => {
    if (handlePopUp.state) dialogRef.current?.classList.remove('leaving');
  }, [handlePopUp.state]);

  return handlePopUp?.state ? (
    <Styled.Dialog role="document" ref={dialogRef}>
      <Styled.Header>
        <Styled.Category>
          {Icon ? <Icon width="24px" height="24px" /> : null}

          {category?.title ? (
            <Typography as="span" weight={700} margin="0px 0px 0px 8px">
              {category.title}
            </Typography>
          ) : null}
        </Styled.Category>

        <Styled.IconWrapper
          tabIndex={0}
          onClick={onClose}
          onKeyDown={(event) => clickByKey(event, onClose)}
          aria-label="Fechar"
        >
          <Styled.CloseIcon />
        </Styled.IconWrapper>
      </Styled.Header>

      <Styled.ContentWrapper>
        {image ? <Styled.Image src={image} alt={headerText} /> : null}

        <Typography
          as="h2"
          size={24}
          color={theme.colors.text.secondary}
          weight={700}
          margin={0}
        >
          {headerText}
        </Typography>

        <Typography>{bodyText}</Typography>
      </Styled.ContentWrapper>

      {footer?.buttonText && footer?.buttonLink ? (
        <Styled.FooterLinkButton onClick={(e) => onCtaClick && onCtaClick(e)}>
          {footer.buttonText}
          <CtaIconToggler
            color={theme.colors.text.primary}
            ctaLink={footer.buttonLink}
          />
        </Styled.FooterLinkButton>
      ) : null}
    </Styled.Dialog>
  ) : null;
};

export default SlidingCore;
