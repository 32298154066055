export type RegexMatchedString<Pattern extends string> = `${string & {
  __brand: Pattern;
}}`;
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type TEmailAddress =
  RegexMatchedString<"/^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/">;
export type TMoney = RegexMatchedString<'/^$?[d,]+(.d*)?$/'>;
export type TPostalCode = RegexMatchedString<'/^[0-9]{5}[-][0-9]{3}$/'>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Any Type */
  Any: { input: unknown; output: unknown };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: Record<string, never> };
  /** A field whose value matches /^([0-9]{11,14}|[A-Z0-9]{4,20})$/. */
  DocumentNumber: { input: string | number; output: string | number };
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: TEmailAddress; output: TEmailAddress };
  Interval: { input: string; output: Record<string, never> };
  /** JSON Type */
  JSON: { input: JSON; output: JSON };
  /** Money Type */
  Money: { input: TMoney; output: TMoney };
  /** A field whose value matches /^[0-9]{5}[-][0-9]{3}$/. */
  PostalCode: { input: TPostalCode; output: TPostalCode };
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: string; output: string };
  Upload: { input: unknown; output: unknown };
  _FieldSet: { input: unknown; output: unknown };
};

export type AcoDataInput = {
  duration: Scalars['Int']['input'];
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  subCategoryId: Scalars['String']['input'];
};

export type AcoListResponse = {
  __typename?: 'ACOListResponse';
  activities?: Maybe<Array<Activity>>;
};

export type AcoSummary = {
  __typename?: 'ACOSummary';
  approvedHours: Scalars['Int']['output'];
  hoursLeft: Scalars['Int']['output'];
  pendingApprovalHours: Scalars['Int']['output'];
  reprovedHours: Scalars['Int']['output'];
  requestedHours: Scalars['Int']['output'];
};

export type Activity = {
  __typename?: 'Activity';
  category?: Maybe<ActivityCategory>;
  course?: Maybe<Course>;
  duration: Scalars['Int']['output'];
  endDate: Scalars['String']['output'];
  files?: Maybe<Array<SavedFile>>;
  id: Scalars['ID']['output'];
  sendDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  status: ActivityStatus;
  statusReason?: Maybe<Scalars['String']['output']>;
};

export type ActivityCategory = {
  __typename?: 'ActivityCategory';
  categoryId: Scalars['ID']['output'];
  categoryName: Scalars['String']['output'];
  subCategoryId: Scalars['ID']['output'];
  subCategoryName: Scalars['String']['output'];
};

export enum ActivityStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Reproved = 'REPROVED',
}

export type ActivityWorkloadCompletionDate = {
  __typename?: 'ActivityWorkloadCompletionDate';
  completionDate?: Maybe<Scalars['String']['output']>;
};

export type AddAttemptInput = {
  grade?: InputMaybe<Scalars['Int']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
};

export type AddLearningUnitVersionInput = {
  learningUnitId: Scalars['ID']['input'];
};

export type Address = {
  __typename?: 'Address';
  city: City;
  cityId: Scalars['Int']['output'];
  cityName?: Maybe<Scalars['String']['output']>;
  complement?: Maybe<Scalars['String']['output']>;
  neighbourhood?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['PostalCode']['output'];
  state: State;
  stateAcronym?: Maybe<Scalars['String']['output']>;
  stateId: Scalars['Int']['output'];
  street?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  cityId: Scalars['Int']['input'];
  complement?: InputMaybe<Scalars['String']['input']>;
  neighbourhood?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  stateId: Scalars['Int']['input'];
  street: Scalars['String']['input'];
};

export type AddressRegistration = {
  __typename?: 'AddressRegistration';
  address?: Maybe<Address>;
  isBillingAddress?: Maybe<Scalars['Boolean']['output']>;
};

export type AddressRegistrationInput = {
  address?: InputMaybe<AddressInput>;
  isBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddressWithoutIdInput = {
  cityId?: InputMaybe<Scalars['Int']['input']>;
  complement?: InputMaybe<Scalars['String']['input']>;
  neighbourhood?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  stateId?: InputMaybe<Scalars['Int']['input']>;
  street: Scalars['String']['input'];
};

export type Affiliate = {
  __typename?: 'Affiliate';
  address: Address;
  collaborateId?: Maybe<Scalars['String']['output']>;
  courseDegree: CourseDegree;
  geolocation: AffiliateLocation;
  id: Scalars['ID']['output'];
  mecCode?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
  ownerPhone: Scalars['String']['output'];
  status: AffiliateStatus;
};

export type AffiliateAppointmentSession = {
  __typename?: 'AffiliateAppointmentSession';
  affiliateId: Scalars['String']['output'];
  date: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  remainingSeats: Scalars['Int']['output'];
  seats: Scalars['Int']['output'];
  startTime: Scalars['String']['output'];
};

export type AffiliateConnection = {
  __typename?: 'AffiliateConnection';
  data?: Maybe<Array<Affiliate>>;
  page: Page;
};

export type AffiliateDistance = {
  __typename?: 'AffiliateDistance';
  affiliate: Affiliate;
  distance: Scalars['Float']['output'];
};

export type AffiliateLocation = {
  __typename?: 'AffiliateLocation';
  coordinates: Array<Scalars['Float']['output']>;
};

export type AffiliateLocationInput = {
  coordinates: Array<Scalars['Float']['input']>;
};

export enum AffiliateStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type AffiliateStub = {
  __typename?: 'AffiliateStub';
  address: Address;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AffiliateStubConnection = {
  __typename?: 'AffiliateStubConnection';
  data?: Maybe<Array<Maybe<AffiliateStub>>>;
};

export type Annotation = {
  __typename?: 'Annotation';
  content: Scalars['String']['output'];
  courseEnrollmentId: Scalars['String']['output'];
  createdDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  learningObjectId: Scalars['String']['output'];
  sectionId: Scalars['String']['output'];
  subjectId: Scalars['String']['output'];
};

export type Answer = {
  __typename?: 'Answer';
  choiceId?: Maybe<Scalars['ID']['output']>;
  correctChoice: Choice;
  isCorrect: Scalars['Boolean']['output'];
  questionId: Scalars['ID']['output'];
};

export type AnswerSheet = {
  __typename?: 'AnswerSheet';
  answers: Array<Answer>;
  grade: Scalars['Int']['output'];
};

export type ApiClient = {
  __typename?: 'ApiClient';
  accessTokenValiditySeconds: Scalars['Int']['output'];
  authorities: Scalars['String']['output'];
  authorizedGrantTypes: Scalars['String']['output'];
  clientId: Scalars['ID']['output'];
  clientSecret: Scalars['String']['output'];
  redirectUri?: Maybe<Scalars['String']['output']>;
  refreshTokenValiditySeconds: Scalars['Int']['output'];
  resources?: Maybe<Scalars['String']['output']>;
  scopes?: Maybe<Scalars['String']['output']>;
};

export type ApiClientConnection = {
  __typename?: 'ApiClientConnection';
  data: Array<ApiClient>;
  page: Page;
};

export type AppendTrackInputRequest = {
  description?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type AppendTrackRequest = {
  inputs: Array<AppendTrackInputRequest>;
  referenceId: Scalars['String']['input'];
};

export type ApplyCouponResponse = {
  __typename?: 'ApplyCouponResponse';
  couponAmount: Scalars['Money']['output'];
  couponAppliedDate: Scalars['String']['output'];
  couponName: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  instalmentAmount: Scalars['Money']['output'];
  numberOfInstalmentsWithCoupon: Scalars['Int']['output'];
  startDate: Scalars['String']['output'];
  totalInstalmentAmount: Scalars['Money']['output'];
};

export type ApplyCouponResponseDto = {
  __typename?: 'ApplyCouponResponseDto';
  couponAmount: Scalars['String']['output'];
  couponAppliedDate: Scalars['String']['output'];
  couponName: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  instalmentAmount: Scalars['String']['output'];
  numberOfInstalmentsWithCoupon: Scalars['Int']['output'];
  startDate: Scalars['String']['output'];
  totalInstalmentAmount: Scalars['String']['output'];
};

export type ApplyDiscountInput = {
  discount: DiscountInput;
  paymentPlanId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type AppointmentExam = {
  __typename?: 'AppointmentExam';
  affiliateAppointmentId: Scalars['String']['output'];
  assignmentId: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locator: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  studentId: Scalars['String']['output'];
};

export type Article = {
  __typename?: 'Article';
  articleS3contentId?: Maybe<Scalars['String']['output']>;
  authorEmail?: Maybe<Scalars['String']['output']>;
  authorName?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  creationDate?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dynamicsArticleId?: Maybe<Scalars['String']['output']>;
  expiredDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keyWords?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  modificationDate?: Maybe<Scalars['String']['output']>;
  numberOfViews?: Maybe<Scalars['Int']['output']>;
  publishDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Asset = {
  id: Scalars['ID']['output'];
  type: AssetType;
};

export enum AssetElement {
  video = 'VIDEO',
  title = 'TITLE',
  subtitle = 'SUBTITLE',
  image = 'IMAGE',
}

export type AssetItem =
  | FormulaAsset
  | ImageAsset
  | TableAsset
  | TextAsset
  | VideoAsset
  | FileAsset;

export enum AssetType {
  Formula = 'FORMULA',
  Image = 'IMAGE',
  Table = 'TABLE',
  Text = 'TEXT',
  Video = 'VIDEO',
  File = 'FILE',
}

export type Assignment = {
  __typename?: 'Assignment';
  assignmentFiles?: Maybe<Array<Maybe<AssignmentFile>>>;
  attempts: Array<Maybe<AssignmentAttempt>>;
  config: AssignmentConfig;
  dueDate: Scalars['String']['output'];
  grade?: Maybe<Scalars['Int']['output']>;
  highestGradeAttemptId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  startDate: Scalars['String']['output'];
};

export type AssignmentAttempt = {
  __typename?: 'AssignmentAttempt';
  attemptDate: Scalars['String']['output'];
  grade?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
};

export type AssignmentConfig = {
  __typename?: 'AssignmentConfig';
  description: Scalars['String']['output'];
  dueDays?: Maybe<Scalars['Int']['output']>;
  durationInMinutes?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  minimumProgressToClose?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  numberOfAttempts?: Maybe<Scalars['Int']['output']>;
  numberOfQuestions?: Maybe<Scalars['Int']['output']>;
  reference: AssignmentConfigReferenceType;
  startPeriod?: Maybe<AssignmentConfigStartPeriodType>;
  type: AssignmentConfigType;
  typeConfig?: Maybe<TypeConfig>;
  weight: Scalars['Int']['output'];
};

export type AssignmentConfigData = {
  description: Scalars['String']['input'];
  dueDays?: InputMaybe<Scalars['Int']['input']>;
  durationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  minimumProgressToClose?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  numberOfAttempts?: InputMaybe<Scalars['Int']['input']>;
  numberOfQuestions?: InputMaybe<Scalars['Int']['input']>;
  reference: AssignmentConfigReferenceType;
  startPeriod?: InputMaybe<AssignmentConfigStartPeriodType>;
  type: AssignmentConfigType;
  typeConfig?: InputMaybe<TypeConfigInput>;
  weight: Scalars['Int']['input'];
};

export type AssignmentConfigList = {
  __typename?: 'AssignmentConfigList';
  data?: Maybe<Array<Maybe<AssignmentConfig>>>;
  page?: Maybe<Page>;
};

export enum AssignmentConfigReferenceType {
  LearningUnit = 'LEARNING_UNIT',
  Subject = 'SUBJECT',
}

export enum AssignmentConfigStartPeriodType {
  NextDayAfterSubjectEndDate = 'NEXT_DAY_AFTER_SUBJECT_END_DATE',
  SinceSubjectStartDate = 'SINCE_SUBJECT_START_DATE',
}

export enum AssignmentConfigType {
  Assignment = 'ASSIGNMENT',
  Attendance = 'ATTENDANCE',
  Exam = 'EXAM',
  PresentialExam = 'PRESENTIAL_EXAM',
  Quiz = 'QUIZ',
}

export type AssignmentFile = {
  __typename?: 'AssignmentFile';
  assignmentFileType?: Maybe<AssignmentFileType>;
  assignmentFileUrl?: Maybe<Scalars['String']['output']>;
  assignmentId?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  sentDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AssignmentFileStatus>;
  validationDate?: Maybe<Scalars['String']['output']>;
};

export enum AssignmentFileStatus {
  Approved = 'APPROVED',
  NotSent = 'NOT_SENT',
  Rejected = 'REJECTED',
  Sent = 'SENT',
  UnspecifiedStatus = 'UNSPECIFIED_STATUS',
}

export enum AssignmentFileType {
  Assignment = 'ASSIGNMENT',
  UnspecifiedType = 'UNSPECIFIED_TYPE',
}

export type AssignmentList = {
  __typename?: 'AssignmentList';
  data?: Maybe<Array<Maybe<Assignment>>>;
  page?: Maybe<Page>;
};

export enum AssignmentType {
  Assignment = 'ASSIGNMENT',
  Attendance = 'ATTENDANCE',
  Exam = 'EXAM',
  PresentialExam = 'PRESENTIAL_EXAM',
  Quiz = 'QUIZ',
}

export type Attendance = {
  __typename?: 'Attendance';
  completionTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  learningObjectId: Scalars['String']['output'];
  learningUnitEnrollmentId: Scalars['String']['output'];
  sectionId?: Maybe<Scalars['String']['output']>;
  subjectEnrollmentId: Scalars['String']['output'];
};

export enum AttendanceStatus {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
}

export enum AttendanceType {
  Content = 'CONTENT',
  ReviewContent = 'REVIEW_CONTENT',
  Summary = 'SUMMARY',
}

export type Audit = {
  __typename?: 'Audit';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  removed?: Maybe<Scalars['Boolean']['output']>;
};

export type Authentication = {
  __typename?: 'Authentication';
  accessToken: Scalars['String']['output'];
  expiresIn: Scalars['Int']['output'];
  scope: Scalars['String']['output'];
  tokenType: Scalars['String']['output'];
};

export type Authority = {
  __typename?: 'Authority';
  description: Scalars['String']['output'];
  name: Scalars['ID']['output'];
};

export type AuthorityConnection = {
  __typename?: 'AuthorityConnection';
  data: Array<Authority>;
  page: Page;
};

export type AuthorityInput = {
  description: Scalars['String']['input'];
  name: Scalars['ID']['input'];
};

export type AvailableCategories = {
  __typename?: 'AvailableCategories';
  categories?: Maybe<Array<Category>>;
};

export type AvailableExams = {
  __typename?: 'AvailableExams';
  subjectEnrollmentAvailableExams?: Maybe<Array<Maybe<SubjectEnrollment>>>;
};

export type BankType = {
  __typename?: 'BankType';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Banner = {
  __typename?: 'Banner';
  description: Scalars['String']['output'];
  desktopImage?: Maybe<Image>;
  desktopImageId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  location: BannerLocation;
  mobileImage?: Maybe<Image>;
  mobileImageId?: Maybe<Scalars['ID']['output']>;
};

export type BannerConnection = {
  __typename?: 'BannerConnection';
  data: Array<Banner>;
  page: Page;
};

export enum BannerLocation {
  Home = 'HOME',
}

export type BannerUpdateInput = {
  desktopImageId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  mobileImageId?: InputMaybe<Scalars['ID']['input']>;
};

export type BaseLearningObject = {
  assets: Array<AssetItem>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type BaseLearningUnit = {
  goals?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: LearningUnitStatus;
  title: Scalars['String']['output'];
};

export type BaseSection = {
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type BillingCycleWarnings = {
  __typename?: 'BillingCycleWarnings';
  messages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Boleto = {
  __typename?: 'Boleto';
  barCode: Scalars['String']['output'];
  code: Scalars['String']['output'];
  html?: Maybe<Scalars['String']['output']>;
  pdf?: Maybe<Scalars['String']['output']>;
  qrCode?: Maybe<Scalars['String']['output']>;
};

export type BoletoPaymentInput = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  enrollmentId?: InputMaybe<Scalars['String']['input']>;
  instalmentId: Scalars['ID']['input'];
};

export enum BorderStyle {
  radiusAll = 'RADIUS_ALL',
  marker = 'MARKER',
  radiusEven = 'RADIUS_EVEN',
}

export enum Brand {
  Amex = 'AMEX',
  Aura = 'AURA',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Elo = 'ELO',
  Hipercard = 'HIPERCARD',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  Visa = 'VISA',
}

export type BrandInput = {
  code: Scalars['String']['input'];
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type BrandList = {
  __typename?: 'BrandList';
  data?: Maybe<Array<Maybe<EducationProviderBrand>>>;
  page?: Maybe<Page>;
};

export type BvAuthenticationUser = {
  __typename?: 'BvAuthenticationUser';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CalendarCaption = {
  __typename?: 'CalendarCaption';
  captionKey: Scalars['String']['output'];
  captionName: Scalars['String']['output'];
};

export type CalendarDay = {
  __typename?: 'CalendarDay';
  date: Scalars['String']['output'];
  dateEvents: Array<Maybe<CalendarEvent>>;
  dateEventsSize: Scalars['String']['output'];
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  date?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endHour?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  redirectLink?: Maybe<Scalars['String']['output']>;
  startHour?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<CalendarEventSubTypes>;
  subTypeLabel?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CalendarEventTypes>;
};

export enum CalendarEventSubTypes {
  AcademicSaturday = 'ACADEMIC_SATURDAY',
  AcademicWeek = 'ACADEMIC_WEEK',
  CourseEvent = 'COURSE_EVENT',
  NationalStateHoliday = 'NATIONAL_STATE_HOLIDAY',
  Others = 'OTHERS',
  SubjectActivityEnd = 'SUBJECT_ACTIVITY_END',
  SubjectActivityStart = 'SUBJECT_ACTIVITY_START',
  SubjectEnd = 'SUBJECT_END',
  SubjectStart = 'SUBJECT_START',
}

export enum CalendarEventTypes {
  AcademicDates = 'ACADEMIC_DATES',
  CourseEvents = 'COURSE_EVENTS',
  NationalStateHolidays = 'NATIONAL_STATE_HOLIDAYS',
}

export type CalendarPayload = {
  __typename?: 'CalendarPayload';
  caption: Array<CalendarCaption>;
  electiveDays: Scalars['String']['output'];
  events: Array<CalendarDay>;
};

export type CancellationReasons = {
  __typename?: 'CancellationReasons';
  description: Scalars['String']['output'];
  reasonCode: Scalars['String']['output'];
};

export type CancellationStatus = {
  __typename?: 'CancellationStatus';
  order?: Maybe<OrderResponse>;
  status: OrderStatus;
};

export type Candidate = {
  __typename?: 'Candidate';
  accessLink?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Address>;
  affiliateId?: Maybe<Scalars['String']['output']>;
  audit: Audit;
  birthDate?: Maybe<Scalars['String']['output']>;
  cityOfBirthId?: Maybe<Scalars['Int']['output']>;
  contractId?: Maybe<Scalars['String']['output']>;
  contractInfoFilled: Scalars['Boolean']['output'];
  contractSigned?: Maybe<Scalars['Boolean']['output']>;
  contractUrl?: Maybe<Scalars['String']['output']>;
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  countryOfBirthId?: Maybe<Scalars['Int']['output']>;
  course: CourseStub;
  courseId: Scalars['String']['output'];
  coursePriceId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['DocumentNumber']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enemGrade?: Maybe<Scalars['Int']['output']>;
  enemOption?: Maybe<Scalars['Boolean']['output']>;
  enrollmentStatus?: Maybe<EnrollmentStatus>;
  exam?: Maybe<CandidateExam>;
  examTracking?: Maybe<CandidateExamTracking>;
  externalId?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  isFreeTrialOption?: Maybe<Scalars['Boolean']['output']>;
  issuingAuthority?: Maybe<Scalars['String']['output']>;
  leadCity?: Maybe<City>;
  leadCityId?: Maybe<Scalars['Int']['output']>;
  maritalStatus?: Maybe<MaritalStatus>;
  mobilePhone: Scalars['String']['output'];
  nationalIdentity?: Maybe<Scalars['String']['output']>;
  nationalIdentityStateId?: Maybe<Scalars['Int']['output']>;
  optIn?: Maybe<Scalars['Boolean']['output']>;
  paymentInfo?: Maybe<CandidatePaymentInfo>;
  queryStrings?: Maybe<Array<CandidateQueryString>>;
  rneDocument?: Maybe<Scalars['String']['output']>;
  sellerDocument?: Maybe<Scalars['String']['output']>;
  sellerId?: Maybe<Scalars['String']['output']>;
  specialNeeds?: Maybe<Array<SpecialNeed>>;
  stateOfBirthId?: Maybe<Scalars['Int']['output']>;
  status: CandidateStatus;
  steps?: Maybe<CandidateSteps>;
  studentId: Scalars['String']['output'];
  underageInfo?: Maybe<UnderageCandidateInfo>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type CandidateBoletoPaymentInput = {
  candidateId: Scalars['ID']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  instalmentId: Scalars['ID']['input'];
  paymentPlanId: Scalars['ID']['input'];
};

export type CandidateConnection = {
  __typename?: 'CandidateConnection';
  data?: Maybe<Array<Candidate>>;
  page: Page;
};

export type CandidateContract = {
  __typename?: 'CandidateContract';
  body: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  personId: Scalars['String']['output'];
  url: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type CandidateCreditCardPaymentInput = {
  candidateId: Scalars['ID']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  creditCardInput: CreditCardInput;
  recurring: Scalars['Boolean']['input'];
};

export type CandidateEnemEvaluationInput = {
  candidateId: Scalars['ID']['input'];
  enemGrade: Scalars['Int']['input'];
  isApproved: Scalars['Boolean']['input'];
};

export type CandidateExam = {
  __typename?: 'CandidateExam';
  answer: Scalars['String']['output'];
  examGrades: Array<Maybe<CandidateExamGrade>>;
  feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type CandidateExamConnection = {
  __typename?: 'CandidateExamConnection';
  data?: Maybe<Array<CandidateExam>>;
  page: Page;
};

export type CandidateExamEvaluationInput = {
  examGrades: Array<InputMaybe<CandidateExamGradeInput>>;
  feedback: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isApproved: Scalars['Boolean']['input'];
};

export type CandidateExamGrade = {
  __typename?: 'CandidateExamGrade';
  candidateExamId: Scalars['String']['output'];
  grade: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  type: ExamGradeType;
};

export type CandidateExamGradeInput = {
  candidateExamId: Scalars['String']['input'];
  grade: Scalars['Int']['input'];
  type: ExamGradeType;
};

export type CandidateExamTracking = {
  __typename?: 'CandidateExamTracking';
  gradeFromCorrectionApi?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  returnDate?: Maybe<Scalars['String']['output']>;
  sendingDate: Scalars['String']['output'];
};

export type CandidateInCourseResponse = {
  __typename?: 'CandidateInCourseResponse';
  candidateId?: Maybe<Scalars['ID']['output']>;
  exists: Scalars['Boolean']['output'];
};

export type CandidateInfoInput = {
  address?: InputMaybe<AddressWithoutIdInput>;
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  courseId: Scalars['String']['input'];
  coursePriceId?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['DocumentNumber']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enemOption?: InputMaybe<Scalars['Boolean']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  isFreeTrialOption?: InputMaybe<Scalars['Boolean']['input']>;
  leadCityId?: InputMaybe<Scalars['Int']['input']>;
  mobilePhone: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  queryStrings?: InputMaybe<Array<CandidateLeadQueryStringInput>>;
  sellerDocument?: InputMaybe<Scalars['String']['input']>;
  sellerId?: InputMaybe<Scalars['String']['input']>;
  specialNeeds?: InputMaybe<Array<SpecialNeedInput>>;
};

export type CandidateLeadQueryStringInput = {
  field: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CandidatePayByNewCreditCardInput = {
  candidateId: Scalars['ID']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  creditCard: CreditCardInput;
  instalmentId: Scalars['ID']['input'];
};

export type CandidatePayment = {
  __typename?: 'CandidatePayment';
  amount: Scalars['Money']['output'];
  boleto?: Maybe<Boleto>;
  couponCode?: Maybe<Scalars['String']['output']>;
  discount: Scalars['Money']['output'];
  dueDate: Scalars['String']['output'];
  instalmentId: Scalars['ID']['output'];
  instalmentStatus: InstalmentStatus;
  paymentMethod: PaymentMethod;
  paymentPlanId: Scalars['ID']['output'];
  totalAmount: Scalars['Money']['output'];
};

export type CandidatePaymentInfo = {
  __typename?: 'CandidatePaymentInfo';
  amount: Scalars['Money']['output'];
  boletoBarCode?: Maybe<Scalars['String']['output']>;
  boletoCode?: Maybe<Scalars['String']['output']>;
  boletoHtml?: Maybe<Scalars['String']['output']>;
  boletoPdf?: Maybe<Scalars['String']['output']>;
  boletoQrCode?: Maybe<Scalars['String']['output']>;
  discount: Scalars['Money']['output'];
  dueDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  increment: Scalars['Money']['output'];
  instalmentId: Scalars['String']['output'];
  paymentMethod: PaymentMethod;
  paymentPlanId: Scalars['String']['output'];
};

export type CandidatePaymentInput = {
  candidateId: Scalars['ID']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: Scalars['String']['input'];
};

export type CandidateQueryString = {
  __typename?: 'CandidateQueryString';
  field: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum CandidateStatus {
  Approved = 'APPROVED',
  Enrolled = 'ENROLLED',
  InAnalysis = 'IN_ANALYSIS',
  Lead = 'LEAD',
  PendingPayment = 'PENDING_PAYMENT',
  Registered = 'REGISTERED',
  Reproved = 'REPROVED',
  SignedContract = 'SIGNED_CONTRACT',
  Unpaid = 'UNPAID',
}

export type CandidateStepAction = {
  __typename?: 'CandidateStepAction';
  status?: Maybe<Scalars['String']['output']>;
};

export type CandidateSteps = {
  __typename?: 'CandidateSteps';
  contract?: Maybe<CandidateStepAction>;
  enrollment?: Maybe<CandidateStepAction>;
  entranceExam?: Maybe<CandidateStepAction>;
  payment?: Maybe<CandidateStepAction>;
  registrationInfo?: Maybe<CandidateStepAction>;
};

export type CaptchaInput = {
  origin: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type Categories = {
  __typename?: 'Categories';
  categoryIconURL: Scalars['String']['output'];
  categoryType: Scalars['String']['output'];
  categoryTypeName: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subCategories?: Maybe<Array<SubCategory>>;
};

export type CategoryIcon = {
  data: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CategorySection = {
  __typename?: 'CategorySection';
  aggregatedName: Scalars['String']['output'];
  categoryId: Scalars['String']['output'];
  categoryName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type CategorySectionList = {
  __typename?: 'CategorySectionList';
  data: Array<CategorySection>;
  page: Page;
};

export type CategorySectionWithCourses = {
  __typename?: 'CategorySectionWithCourses';
  courses?: Maybe<Array<Maybe<Course>>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ChangeBillingCyclesResponse = {
  __typename?: 'ChangeBillingCyclesResponse';
  canRequestChangeCycle: Scalars['Boolean']['output'];
  lastChangeDate?: Maybe<Scalars['String']['output']>;
  order?: Maybe<OrderResponse>;
  status: OrderStatus;
};

export type ChangeCyclesResponse = {
  __typename?: 'ChangeCyclesResponse';
  canRequestChangeCycle: Scalars['Boolean']['output'];
  lastChangeDate?: Maybe<Scalars['String']['output']>;
  order?: Maybe<OrderResponse>;
  status: OrderStatus;
};

export type ChangeGenericFieldsInput = {
  courseEnrollmentId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  fieldValue: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  subject: EnumSubject;
};

export type ChangeIdentityDocumentInput = {
  courseEnrollmentId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  driverLicense?: InputMaybe<DriverLicenseInput>;
  file: Scalars['Upload']['input'];
  identityDocumentType: NationalIdentityType;
  nationalIdentity?: InputMaybe<NationalIdentityInput>;
  rne?: InputMaybe<RneInput>;
};

export type ChangeSocialNameInput = {
  courseEnrollmentId: Scalars['ID']['input'];
  file?: InputMaybe<Scalars['Upload']['input']>;
  socialName: Scalars['String']['input'];
  socialNameInDocument: Scalars['Boolean']['input'];
};

export enum Channel {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP',
}

export type Choice = {
  __typename?: 'Choice';
  description: Scalars['String']['output'];
  feedback: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isCorrect: Scalars['Boolean']['output'];
};

export type ChoiceForExam = {
  __typename?: 'ChoiceForExam';
  description: Scalars['String']['output'];
  feedback: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ChoiceInput = {
  description: Scalars['String']['input'];
  feedback: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isCorrect: Scalars['Boolean']['input'];
};

export type City = {
  __typename?: 'City';
  affiliates?: Maybe<Array<AffiliateStub>>;
  capital: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  state: State;
};

export type CityConnection = {
  __typename?: 'CityConnection';
  data: Array<City>;
  page: Page;
};

export enum Communication {
  Bilateral = 'BILATERAL',
  Unilateral = 'UNILATERAL',
}

export type CompanyContact = {
  __typename?: 'CompanyContact';
  contacts: Array<Maybe<CompanyContactType>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  website: Scalars['String']['output'];
};

export type CompanyContactType = {
  __typename?: 'CompanyContactType';
  contact?: Maybe<Scalars['String']['output']>;
  type: Contact;
};

export type CompanyData = {
  __typename?: 'CompanyData';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  cnpj: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  state: Scalars['String']['output'];
  tradingName?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
  neighbourhood: Scalars['String']['input'];
};

export type CompanyDataInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  cnpj: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state: Scalars['String']['input'];
  tradingName?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
  neighbourhood: Scalars['String']['input'];
};

export type CompanyInput = {
  address?: InputMaybe<AddressInput>;
  cnpj: Scalars['String']['input'];
  code: Scalars['String']['input'];
  corporateName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tradingName: Scalars['String']['input'];
};

export type CompanyList = {
  __typename?: 'CompanyList';
  data?: Maybe<Array<Maybe<EducationProviderCompany>>>;
  page?: Maybe<Page>;
};

export type CompanyResponsible = {
  __typename?: 'CompanyResponsible';
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

export type CompanyResponsibleInput = {
  name: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export enum ConsolidatedStatus {
  Approved = 'APPROVED',
  NotReleased = 'NOT_RELEASED',
  PendingApproval = 'PENDING_APPROVAL',
  PendingRelease = 'PENDING_RELEASE',
  Released = 'RELEASED',
  Reproved = 'REPROVED',
}

export enum Contact {
  Chat = 'CHAT',
  Phone = 'PHONE',
  Whatsapp = 'WHATSAPP',
}

export type ContactPermission = {
  __typename?: 'ContactPermission';
  channel: Channel;
  context: NotificationContext;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  recipient: Scalars['String']['output'];
};

export type ContactPermissionInput = {
  channel: Channel;
  context: NotificationContext;
  enabled: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  recipient: Scalars['String']['input'];
};

export type ContentEvaluation = {
  __typename?: 'ContentEvaluation';
  client: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  courseEnrollmentId: Scalars['String']['output'];
  courseId: Scalars['String']['output'];
  courseTypeCode: Scalars['String']['output'];
  courseVersionId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  learningObjectId: Scalars['String']['output'];
  learningUnitEnrollmentId: Scalars['String']['output'];
  learningUnitId: Scalars['String']['output'];
  learningUnitVersionId: Scalars['String']['output'];
  rating: Scalars['Int']['output'];
  sectionId: Scalars['String']['output'];
  studentId: Scalars['String']['output'];
  subjectEnrollmentId: Scalars['String']['output'];
  subjectId: Scalars['String']['output'];
};

export type ContentEvaluationInput = {
  client: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  courseEnrollmentId: Scalars['String']['input'];
  courseId: Scalars['String']['input'];
  courseTypeCode: Scalars['String']['input'];
  courseVersionId: Scalars['String']['input'];
  learningObjectId: Scalars['String']['input'];
  learningUnitEnrollmentId: Scalars['String']['input'];
  learningUnitId: Scalars['String']['input'];
  learningUnitVersionId: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
  sectionId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
  subjectEnrollmentId: Scalars['String']['input'];
  subjectId: Scalars['String']['input'];
};

export type Contract = {
  __typename?: 'Contract';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ContractAgreementRequest = {
  accepted?: InputMaybe<Scalars['Boolean']['input']>;
  enrollmentId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
};

export type ContractIngressAgreementResponse = {
  __typename?: 'ContractIngressAgreementResponse';
  description?: Maybe<Scalars['String']['output']>;
  fileHtml?: Maybe<Scalars['String']['output']>;
  filePdf?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<ContractSignature>;
};

export type ContractIngressDetailResponse = {
  __typename?: 'ContractIngressDetailResponse';
  canRefuse?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disagreementWarning?: Maybe<Scalars['String']['output']>;
  fileHtml?: Maybe<Scalars['String']['output']>;
  filePdf?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<ContractSignature>;
};

export type ContractIngressResponse = {
  __typename?: 'ContractIngressResponse';
  acceptedContracts?: Maybe<Array<Maybe<Contract>>>;
  pendingContracts?: Maybe<Array<Maybe<Contract>>>;
};

export type ContractServiceInput = {
  courseId: Scalars['String']['input'];
  courseType: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type ContractSignature = {
  __typename?: 'ContractSignature';
  agreement?: Maybe<Scalars['Boolean']['output']>;
  dateTime?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
};

export type CorrectedExamDto = {
  comments?: InputMaybe<Scalars['String']['input']>;
  essayFromPartner: Scalars['String']['input'];
  grade: Scalars['Int']['input'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CountryConnection = {
  __typename?: 'CountryConnection';
  data: Array<Country>;
  page: Page;
};

export type Coupon = {
  __typename?: 'Coupon';
  amountAvailable: Scalars['Int']['output'];
  category?: Maybe<CouponCategory>;
  code: Scalars['ID']['output'];
  courseDegrees?: Maybe<Array<Maybe<CourseDegree>>>;
  courseIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description: Scalars['String']['output'];
  discount: Scalars['Money']['output'];
  expirationDate: Scalars['String']['output'];
  initialAmount: Scalars['Int']['output'];
  numberOfInstalments: Scalars['Int']['output'];
  onlyNewEnrollment?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  type: CouponType;
};

export enum CouponCategory {
  B2B = 'B2B',
  B2BConvenioKroton = 'B2B_CONVENIO_KROTON',
  FreeTrial = 'FREE_TRIAL',
}

export type CouponConnection = {
  __typename?: 'CouponConnection';
  data: Array<Coupon>;
  page: Page;
};

export type CouponInput = {
  category?: InputMaybe<CouponCategory>;
  code: Scalars['ID']['input'];
  courseDegrees?: InputMaybe<Array<InputMaybe<CourseDegree>>>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description: Scalars['String']['input'];
  discount: Scalars['Money']['input'];
  expirationDate: Scalars['String']['input'];
  initialAmount: Scalars['Int']['input'];
  numberOfInstalments: Scalars['Int']['input'];
  onlyNewEnrollment?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  type: CouponType;
};

export enum CouponType {
  Exact = 'EXACT',
  Percentage = 'PERCENTAGE',
}

export type Course = {
  __typename?: 'Course';
  candidateCount: Scalars['Int']['output'];
  cardImageUrl?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  complementaryWorkload: Scalars['Int']['output'];
  coordinatorId?: Maybe<Scalars['String']['output']>;
  courseDegreeInformation?: Maybe<Array<Maybe<CourseDegreeInformation>>>;
  courseStructureDocument: Scalars['String']['output'];
  courseType: CourseType;
  courseTypeCode: Scalars['String']['output'];
  descriptions?: Maybe<Array<CourseDescription>>;
  duration: Scalars['String']['output'];
  fieldOfStudies?: Maybe<Array<FieldOfStudy>>;
  fieldOfStudyCodes?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  mandatoryInternship: Scalars['Boolean']['output'];
  mecCode?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  partner?: Maybe<Partner>;
  partnerId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Money']['output']>;
  status: CourseStatus;
  subjects?: Maybe<Array<CourseSubject>>;
  summary: Scalars['String']['output'];
  testimonials?: Maybe<Array<CourseTestimonial>>;
  tutorId?: Maybe<Scalars['String']['output']>;
  urlCode?: Maybe<Scalars['String']['output']>;
  versionId?: Maybe<Scalars['String']['output']>;
  versions?: Maybe<Array<CourseVersion>>;
  visibleToWindow: Scalars['Boolean']['output'];
  workload: Scalars['Int']['output'];
};

export type CourseSubjectsArgs = {
  full?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CourseCandidateCount = {
  __typename?: 'CourseCandidateCount';
  count: Scalars['Int']['output'];
  course: Course;
  courseId: Scalars['ID']['output'];
};

export type CourseCategory = {
  __typename?: 'CourseCategory';
  courseDegree: CourseDegree;
  iconLink: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  main: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  urlCode: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type CourseCategoryConnection = {
  __typename?: 'CourseCategoryConnection';
  data: Array<Maybe<CourseCategory>>;
  page: Page;
};

export type CourseCategoryCreateInput = {
  courseDegree: CourseDegree;
  icon: CategoryIcon;
  main: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  visible: Scalars['Boolean']['input'];
};

export type CourseCategoryUpdateInput = {
  courseDegree: CourseDegree;
  icon?: InputMaybe<CategoryIcon>;
  iconLink?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  main: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  visible: Scalars['Boolean']['input'];
};

export type CourseCategoryWithSections = {
  __typename?: 'CourseCategoryWithSections';
  category: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sections?: Maybe<Array<Maybe<CategorySectionWithCourses>>>;
  urlCode: Scalars['String']['output'];
};

export type CourseCategoryWithSectionsConnection = {
  __typename?: 'CourseCategoryWithSectionsConnection';
  data: Array<Maybe<CourseCategoryWithSections>>;
  page: Page;
};

export type CourseConnection = {
  __typename?: 'CourseConnection';
  data: Array<Course>;
  page: Page;
};

export type CourseData = {
  __typename?: 'CourseData';
  code: Scalars['String']['output'];
  courseType: CourseType;
  id: Scalars['ID']['output'];
  mecCode?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  partnerId?: Maybe<Scalars['String']['output']>;
  status: CourseStatus;
  urlCode?: Maybe<Scalars['String']['output']>;
  visibleToWindow: Scalars['Boolean']['output'];
};

export enum CourseDegree {
  Corporate = 'CORPORATE',
  Eja = 'EJA',
  Graduate = 'GRADUATE',
  Languages = 'LANGUAGES',
  Open = 'OPEN',
  Technical = 'TECHNICAL',
  Undergraduate = 'UNDERGRADUATE',
}

export enum CourseDegreeDataType {
  Authorization = 'AUTHORIZATION',
  Recognition = 'RECOGNITION',
  RecognitionRenewal = 'RECOGNITION_RENEWAL',
}

export type CourseDegreeInformation = {
  __typename?: 'CourseDegreeInformation';
  courseDegreeSubfields?: Maybe<CourseDegreeSubfields>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CourseDegreeInformationInput = {
  courseDegreeSubfields?: InputMaybe<CourseDegreeSubfieldsInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CourseDegreeSubfields = {
  __typename?: 'CourseDegreeSubfields';
  compoundEmecProcessingInformation?: Maybe<EmecInformation>;
  courseDegreeDataType: CourseDegreeDataType;
  date?: Maybe<Scalars['String']['output']>;
  douNumber?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  publicationDate?: Maybe<Scalars['String']['output']>;
  publicationPage?: Maybe<Scalars['String']['output']>;
  publicationSection?: Maybe<Scalars['String']['output']>;
  publicationVehicle?: Maybe<Scalars['String']['output']>;
  simpleEmecProcessingInformation?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CourseDegreeSubfieldsInput = {
  compoundEmecProcessingInformation?: InputMaybe<EmecInformationInput>;
  courseDegreeDataType: CourseDegreeDataType;
  date?: InputMaybe<Scalars['String']['input']>;
  douNumber?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  publicationDate?: InputMaybe<Scalars['String']['input']>;
  publicationPage?: InputMaybe<Scalars['String']['input']>;
  publicationSection?: InputMaybe<Scalars['String']['input']>;
  publicationVehicle?: InputMaybe<Scalars['String']['input']>;
  simpleEmecProcessingInformation?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CourseDescription = {
  __typename?: 'CourseDescription';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: CourseDescriptionType;
  typeCode?: Maybe<Scalars['String']['output']>;
};

export type CourseDescriptionInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  typeCode: Scalars['String']['input'];
};

export type CourseDescriptionType = {
  __typename?: 'CourseDescriptionType';
  code: Scalars['ID']['output'];
  iconLink: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CourseDescriptionTypeCreate = {
  code?: InputMaybe<Scalars['ID']['input']>;
  iconData: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CourseDescriptionTypeList = {
  __typename?: 'CourseDescriptionTypeList';
  data?: Maybe<Array<Maybe<CourseDescriptionType>>>;
  page?: Maybe<Page>;
};

export type CourseDescriptionTypeUpdate = {
  code?: InputMaybe<Scalars['ID']['input']>;
  iconData?: InputMaybe<Scalars['String']['input']>;
  iconLink: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CourseEnrollment = {
  __typename?: 'CourseEnrollment';
  affiliateId?: Maybe<Scalars['String']['output']>;
  course: Course;
  courseId: Scalars['String']['output'];
  courseVersionId: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  erpIntegration?: Maybe<Scalars['Boolean']['output']>;
  estimatedEndDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated this property should not be exposed to clients */
  externalId?: Maybe<Scalars['String']['output']>;
  freeTrialDaysLeft?: Maybe<Scalars['Int']['output']>;
  freeTrialEndDate?: Maybe<Scalars['String']['output']>;
  freeTrialSituation?: Maybe<FreeTrialSituation>;
  freeTrialStatus?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isSecondInstalmentPaid?: Maybe<Scalars['Boolean']['output']>;
  lastDoneAssignments?: Maybe<Array<Maybe<DoneAssignmentData>>>;
  numberOfCompletedSubjects?: Maybe<Scalars['Int']['output']>;
  numberOfSubjects?: Maybe<Scalars['Int']['output']>;
  paymentPlan?: Maybe<PaymentPlan>;
  progress?: Maybe<EnrollmentProgress>;
  reason?: Maybe<Reason>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  startDate: Scalars['String']['output'];
  status: CourseEnrollmentStatus;
  studentId: Scalars['String']['output'];
  studentSubjects?: Maybe<Array<Maybe<StudentSubjects>>>;
  subjectEnrollmentsInProgress?: Maybe<Array<Maybe<SubjectEnrollment>>>;
  subjectEnrollmentsTaken?: Maybe<Array<Maybe<SubjectEnrollment>>>;
  subjectEnrollmentsToBeTaken?: Maybe<Array<Maybe<SubjectEnrollment>>>;
  subjects?: Maybe<Array<SubjectEnrollment>>;
  suspensionPeriod?: Maybe<SuspensionPeriod>;
  term?: Maybe<Array<Maybe<CourseEnrollmentTerm>>>;
  tracks: Array<Maybe<TrackInput>>;
  transferredFrom?: Maybe<Scalars['String']['output']>;
  transferredTo?: Maybe<Scalars['String']['output']>;
  validStatusTransitions: Array<CourseEnrollmentStatus>;
};

export type CourseEnrollmentLastDoneAssignmentsArgs = {
  numberOfAssignments?: InputMaybe<Scalars['Int']['input']>;
};

export type CourseEnrollmentPaymentPlanArgs = {
  full?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CourseEnrollmentSubjectsArgs = {
  full?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CourseEnrollmentDegreeData = {
  __typename?: 'CourseEnrollmentDegreeData';
  courseEnrollmentDegreeData?: Maybe<Array<Maybe<EnrollmentDegree>>>;
};

export enum CourseEnrollmentStatus {
  Abandoned = 'ABANDONED',
  Attending = 'ATTENDING',
  Cancelled = 'CANCELLED',
  Dropped = 'DROPPED',
  Finished = 'FINISHED',
  Finishing = 'FINISHING',
  ReEnrollment = 'RE_ENROLLMENT',
  Suspended = 'SUSPENDED',
  Transferred = 'TRANSFERRED',
}

export type CourseEnrollmentTerm = {
  __typename?: 'CourseEnrollmentTerm';
  businessKey: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type CourseEvent = {
  __typename?: 'CourseEvent';
  contentLink?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  info?: Maybe<Scalars['String']['output']>;
  modality: CourseDegree;
  startDate: Scalars['String']['output'];
  startTime?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CourseEventInput = {
  contentLink?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<Scalars['String']['input']>;
  modality: CourseDegree;
  startDate: Scalars['String']['input'];
  startTime?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: CourseEventType;
};

export enum CourseEventType {
  CourseAcademicSaturday = 'COURSE_ACADEMIC_SATURDAY',
  CourseAcademicWeek = 'COURSE_ACADEMIC_WEEK',
  CourseOthers = 'COURSE_OTHERS',
}

export type CourseInput = {
  code: Scalars['String']['input'];
  coordinatorId?: InputMaybe<Scalars['String']['input']>;
  courseDegreeInformation?: InputMaybe<
    Array<InputMaybe<CourseDegreeInformationInput>>
  >;
  courseTypeCode: Scalars['String']['input'];
  descriptions?: InputMaybe<Array<InputMaybe<CourseDescriptionInput>>>;
  fieldOfStudyCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mecCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  partnerId?: InputMaybe<Scalars['String']['input']>;
  testimonials?: InputMaybe<Array<InputMaybe<CourseTestimonialData>>>;
  tutorId?: InputMaybe<Scalars['String']['input']>;
  visibleToWindow: Scalars['Boolean']['input'];
};

export type CoursePriceStub = {
  __typename?: 'CoursePriceStub';
  cityId: Scalars['Int']['output'];
  courseId: Scalars['String']['output'];
  firstInstalmentPrice: Scalars['Money']['output'];
  id: Scalars['ID']['output'];
  otherInstalmentsPrice: Scalars['Money']['output'];
};

export type CoursePriceStubConnection = {
  __typename?: 'CoursePriceStubConnection';
  data?: Maybe<Array<CoursePriceStub>>;
  page: Page;
};

export type CourseSeo = {
  __typename?: 'CourseSeo';
  course?: Maybe<Course>;
  courseCardImageUrl: Scalars['String']['output'];
  courseId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  sections?: Maybe<Array<Maybe<CategorySection>>>;
  sectionsIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  titleTag?: Maybe<Scalars['String']['output']>;
};

export type CourseSeoByUrl = {
  __typename?: 'CourseSeoByUrl';
  course?: Maybe<Course>;
  courseCardImageUrl: Scalars['String']['output'];
  titleTag?: Maybe<Scalars['String']['output']>;
};

export type CourseSeoConnection = {
  __typename?: 'CourseSeoConnection';
  data: Array<CourseSeo>;
  page: Page;
};

export type CourseSeoCreateInput = {
  courseCardImageUrl?: InputMaybe<Scalars['String']['input']>;
  courseId: Scalars['ID']['input'];
  icon: CourseSeoIcon;
  sectionsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  titleTag?: InputMaybe<Scalars['String']['input']>;
};

export type CourseSeoIcon = {
  data: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CourseSeoUpdateInput = {
  courseCardImageUrl?: InputMaybe<Scalars['String']['input']>;
  courseId: Scalars['ID']['input'];
  icon: CourseSeoIcon;
  id: Scalars['ID']['input'];
  sectionsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  titleTag?: InputMaybe<Scalars['String']['input']>;
};

export type CourseStaff = {
  __typename?: 'CourseStaff';
  coordinator?: Maybe<Teacher>;
  tutor?: Maybe<Teacher>;
};

export type CourseStaffList = {
  __typename?: 'CourseStaffList';
  coordinator?: Maybe<Teacher>;
  tutors?: Maybe<Array<Maybe<Tutor>>>;
};

export enum CourseStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Suspended = 'SUSPENDED',
}

export type CourseStub = {
  __typename?: 'CourseStub';
  courseStructureDocument?: Maybe<Scalars['String']['output']>;
  degree: CourseDegree;
  description: Scalars['String']['output'];
  duration: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CourseSubject = {
  __typename?: 'CourseSubject';
  credits?: Maybe<Scalars['Int']['output']>;
  dependsOn?: Maybe<Array<Scalars['ID']['output']>>;
  electives?: Maybe<Array<Electives>>;
  endAtOrder?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isElective: Scalars['Boolean']['output'];
  order: Scalars['Int']['output'];
  startAtOrder?: Maybe<Scalars['Int']['output']>;
  subType?: Maybe<SubType>;
  subject?: Maybe<Subject>;
  subjectId?: Maybe<Scalars['String']['output']>;
  subjectType: SubjectType;
  teacherId?: Maybe<Scalars['ID']['output']>;
  workload: Scalars['Int']['output'];
};

export type CourseSubjectInput = {
  credits?: InputMaybe<Scalars['Int']['input']>;
  dependsOn?: InputMaybe<Array<Scalars['ID']['input']>>;
  electives?: InputMaybe<Array<ElectivesInput>>;
  endAtOrder?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  isElective: Scalars['Boolean']['input'];
  order: Scalars['Int']['input'];
  startAtOrder?: InputMaybe<Scalars['Int']['input']>;
  subType?: InputMaybe<SubType>;
  subjectId?: InputMaybe<Scalars['String']['input']>;
  subjectType: SubjectType;
  teacherId?: InputMaybe<Scalars['ID']['input']>;
  workload: Scalars['Int']['input'];
};

export type CourseTestimonial = {
  __typename?: 'CourseTestimonial';
  courseId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profileImage: Scalars['String']['output'];
};

export type CourseTestimonialData = {
  courseId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  profileImage: Scalars['String']['input'];
};

export type CourseType = {
  __typename?: 'CourseType';
  code: Scalars['ID']['output'];
  degree?: Maybe<CourseDegree>;
  name: Scalars['String']['output'];
};

export type CourseTypeData = {
  code?: InputMaybe<Scalars['ID']['input']>;
  degree: CourseDegree;
  name: Scalars['String']['input'];
};

export type CourseTypeList = {
  __typename?: 'CourseTypeList';
  data?: Maybe<Array<Maybe<CourseType>>>;
  page?: Maybe<Page>;
};

export type CourseVersion = {
  __typename?: 'CourseVersion';
  complementaryWorkload?: Maybe<Scalars['Int']['output']>;
  course: Course;
  courseId: Scalars['String']['output'];
  courseStructureDocument?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['String']['output']>;
  mandatoryInternship: Scalars['Boolean']['output'];
  status: CourseStatus;
  subjects?: Maybe<Array<CourseSubject>>;
  summary?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
  workload?: Maybe<Scalars['Int']['output']>;
};

export type CourseVersionSubjectsArgs = {
  full?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CourseVersionInput = {
  complementaryWorkload: Scalars['Int']['input'];
  courseId: Scalars['String']['input'];
  courseStructureDocument: Scalars['String']['input'];
  duration: Scalars['String']['input'];
  imageId?: InputMaybe<Scalars['String']['input']>;
  mandatoryInternship: Scalars['Boolean']['input'];
  subjects?: InputMaybe<Array<CourseSubjectInput>>;
  summary: Scalars['String']['input'];
  workload: Scalars['Int']['input'];
};

export type CoursesBySubject = {
  __typename?: 'CoursesBySubject';
  courses: Array<Maybe<SubjectCourse>>;
};

export type CreateAffiliateAppointmentSessionInput = {
  affiliateId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  remainingSeats: Scalars['Int']['input'];
  seats: Scalars['Int']['input'];
  startTime: Scalars['String']['input'];
};

export type CreateAffiliateInput = {
  address: AddressInput;
  collaborateId?: InputMaybe<Scalars['String']['input']>;
  courseDegree: CourseDegree;
  mecCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  ownerPhone: Scalars['String']['input'];
  status: AffiliateStatus;
};

export type CreateAnnotationInput = {
  content: Scalars['String']['input'];
  courseEnrollmentId: Scalars['String']['input'];
  createdDate?: InputMaybe<Scalars['DateTime']['input']>;
  learningObjectId: Scalars['String']['input'];
  sectionId: Scalars['String']['input'];
  subjectId: Scalars['String']['input'];
};

export type CreateAppointmentExamInput = {
  affiliateAppointmentId: Scalars['String']['input'];
  assignmentId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type CreateAssignmentInput = {
  assignmentConfigId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAttendanceInput = {
  completionTime: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  learningObjectId: Scalars['String']['input'];
  learningUnitEnrollmentId?: InputMaybe<Scalars['String']['input']>;
  /** Depreciated field. Use learningUnitEnrollmentId instead */
  learningUnitId?: InputMaybe<Scalars['String']['input']>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  subjectEnrollmentId: Scalars['String']['input'];
  type?: InputMaybe<AttendanceType>;
};

export type CreateLearningUnitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateManyAnnotationsInput = {
  createAnnotations: Array<InputMaybe<CreateAnnotationInput>>;
};

export type CreateProductOfferInput = {
  courseDegrees?: InputMaybe<Array<InputMaybe<CourseDegree>>>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta: Scalars['String']['input'];
  displayedIn: DisplayPlace;
  displayedInCourses?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  firstDescription: Scalars['String']['input'];
  imageId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  secondDescription?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  thirdDescription?: InputMaybe<Scalars['String']['input']>;
  urlCode: Scalars['String']['input'];
  urlWebPage: Scalars['String']['input'];
};

export type CreateSectionInput = {
  categoryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Creative = {
  __typename?: 'Creative';
  body?: Maybe<Scalars['String']['output']>;
  categoryType?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  ctaLink?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  infoText?: Maybe<Scalars['String']['output']>;
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  statusCampaign?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Credentials = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreditCard = {
  __typename?: 'CreditCard';
  billingAddress: FullAddress;
  brand: Brand;
  expirationMonth: Scalars['Int']['output'];
  expirationYear: Scalars['Int']['output'];
  firstSixDigits: Scalars['String']['output'];
  holderDocument: Scalars['String']['output'];
  holderName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastFourDigits: Scalars['String']['output'];
  status: CreditCardStatus;
};

export type CreditCardInput = {
  billingAddress: FullAddressInput;
  cvv: Scalars['String']['input'];
  expirationMonth: Scalars['Int']['input'];
  expirationYear: Scalars['Int']['input'];
  holderDocument: Scalars['String']['input'];
  holderName: Scalars['String']['input'];
  number: Scalars['String']['input'];
};

export type CreditCardResponse = {
  __typename?: 'CreditCardResponse';
  holderName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastFourDigits: Scalars['String']['output'];
};

export enum CreditCardStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
}

export type CtaClickContractInput = {
  contractId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type DailyPeriod = {
  __typename?: 'DailyPeriod';
  finish: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export type DailyPeriodInput = {
  finish: Scalars['String']['input'];
  start: Scalars['String']['input'];
};

export enum DeclaredRace {
  Black = 'BLACK',
  Indigenous = 'INDIGENOUS',
  NotDeclared = 'NOT_DECLARED',
  Parda = 'PARDA',
  White = 'WHITE',
  Yellow = 'YELLOW',
}

export enum DeclaredRaceStudent {
  Black = 'BLACK',
  Indigenous = 'INDIGENOUS',
  NotDeclared = 'NOT_DECLARED',
  Parda = 'PARDA',
  White = 'WHITE',
  Yellow = 'YELLOW',
}

export type DefaultCoursePrice = {
  __typename?: 'DefaultCoursePrice';
  courseId: Scalars['ID']['output'];
  firstInstalmentPrice: Scalars['Money']['output'];
  id: Scalars['ID']['output'];
  otherInstalmentsPrice: Scalars['Money']['output'];
};

export type DefaultCoursePriceInput = {
  courseId: Scalars['ID']['input'];
  firstInstalmentPrice: Scalars['Money']['input'];
  otherInstalmentsPrice: Scalars['Money']['input'];
};

export type DefaultCoursePriceList = {
  __typename?: 'DefaultCoursePriceList';
  data?: Maybe<Array<Maybe<DefaultCoursePrice>>>;
  page?: Maybe<Page>;
};

export type DeleteManyAnnotationsInput = {
  id: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type DescribedEnum = {
  __typename?: 'DescribedEnum';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DescribedEnumInputMock = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum DeviceType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

export type DiscountInput = {
  amount: Scalars['Money']['input'];
  type: DiscountType;
};

export enum DiscountType {
  Percent = 'PERCENT',
  Value = 'VALUE',
}

export enum DisplayPlace {
  Course = 'COURSE',
  MyCourses = 'MY_COURSES',
}

export type Document = {
  __typename?: 'Document';
  documentType?: Maybe<DocumentType>;
  documentUrl?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  guidance?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reason?: Maybe<DocumentRejectionReason>;
  sentDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<DocumentStatus>;
  validationDate?: Maybe<Scalars['String']['output']>;
};

export type DocumentRejectionReason = {
  __typename?: 'DocumentRejectionReason';
  defaultGuidance?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type DocumentRejectionReasonList = {
  __typename?: 'DocumentRejectionReasonList';
  data?: Maybe<Array<DocumentRejectionReason>>;
  page: Page;
};

export enum DocumentStatus {
  Approved = 'APPROVED',
  NotSent = 'NOT_SENT',
  Rejected = 'REJECTED',
  Sent = 'SENT',
}

export type DocumentSubType = {
  __typename?: 'DocumentSubType';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum DocumentType {
  AcademicTranscript = 'ACADEMIC_TRANSCRIPT',
  Am2Em = 'AM2EM',
  Amef = 'AMEF',
  Ccem = 'CCEM',
  Cci = 'CCI',
  Ce = 'CE',
  Cifn = 'CIFN',
  Cna = 'CNA',
  Cnh = 'CNH',
  Cpf = 'CPF',
  Dcem = 'DCEM',
  Dcg = 'DCG',
  Dgra = 'DGRA',
  DriverLicense = 'DRIVER_LICENSE',
  Heef = 'HEEF',
  Heem = 'HEEM',
  Passport = 'PASSPORT',
  Rg = 'RG',
  RgBack = 'RG_BACK',
  Rne = 'RNE',
  Selfie = 'SELFIE',
  UndergraduateCertificate = 'UNDERGRADUATE_CERTIFICATE',
  UnspecifiedDocumentType = 'UNSPECIFIED_DOCUMENT_TYPE',
}

export type Documents = {
  __typename?: 'Documents';
  documentId: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subTypeAccepted?: Maybe<Array<Maybe<SubTypeAccepted>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DoneAssignmentData = {
  __typename?: 'DoneAssignmentData';
  assignment?: Maybe<Assignment>;
  grade: Scalars['Int']['output'];
  highestGradeExamId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  maxGrade: Scalars['Int']['output'];
  numberOfLearningUnits?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<SubjectEnrollmentAssignmentStatus>;
  subjectEnrollment?: Maybe<SubjectEnrollment>;
  subjectName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AssignmentConfigType>;
};

export type DriverLicenseInput = {
  birthDate: Scalars['String']['input'];
  firstLicenseDate: Scalars['String']['input'];
  id: Scalars['String']['input'];
  issuanceDate: Scalars['String']['input'];
  /** issuingAuthority/state, example (DETRAN/SP, DETRAN/BA) */
  issuingAuthority: Scalars['String']['input'];
  nationalIdentityNumber: Scalars['String']['input'];
  /** issuingAuthority/state, example (SSP/SP, SSP/BA) */
  nationalIdentityissuingAuthority: Scalars['String']['input'];
  validityDate: Scalars['String']['input'];
};

export type EducationProvider = {
  __typename?: 'EducationProvider';
  brand: EducationProviderBrand;
  brandId: Scalars['String']['output'];
  company: EducationProviderCompany;
  companyId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: EducationProviderStatus;
  type: EducationProviderType;
};

export type EducationProviderBrand = {
  __typename?: 'EducationProviderBrand';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type EducationProviderCompany = {
  __typename?: 'EducationProviderCompany';
  address?: Maybe<Address>;
  cnpj: Scalars['String']['output'];
  code: Scalars['String']['output'];
  corporateName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tradingName: Scalars['String']['output'];
};

export type EducationProviderInfo = {
  __typename?: 'EducationProviderInfo';
  id: Scalars['ID']['output'];
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  responsiveLogo: Scalars['String']['output'];
};

export type EducationProviderInput = {
  brandId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  status: EducationProviderStatus;
  type: EducationProviderType;
};

export type EducationProviderList = {
  __typename?: 'EducationProviderList';
  data?: Maybe<Array<Maybe<EducationProvider>>>;
  page?: Maybe<Page>;
};

export enum EducationProviderStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Suspended = 'SUSPENDED',
}

export enum EducationProviderType {
  Ead = 'EAD',
}

export type Electives = {
  __typename?: 'Electives';
  electiveId: Scalars['ID']['output'];
  teacherId?: Maybe<Scalars['ID']['output']>;
};

export type ElectivesInput = {
  electiveId: Scalars['ID']['input'];
  teacherId?: InputMaybe<Scalars['ID']['input']>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type EmailTemplateData = {
  name: Scalars['String']['input'];
};

export type EmailTemplateList = {
  __typename?: 'EmailTemplateList';
  data?: Maybe<Array<Maybe<EmailTemplate>>>;
  page?: Maybe<TokenPage>;
};

export type EmecInformation = {
  __typename?: 'EmecInformation';
  id?: Maybe<Scalars['ID']['output']>;
  processNumber?: Maybe<Scalars['String']['output']>;
  processType?: Maybe<Scalars['String']['output']>;
  protocolDate?: Maybe<Scalars['String']['output']>;
  registrationDate?: Maybe<Scalars['String']['output']>;
};

export type EmecInformationInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  processNumber?: InputMaybe<Scalars['String']['input']>;
  processType?: InputMaybe<Scalars['String']['input']>;
  protocolDate?: InputMaybe<Scalars['String']['input']>;
  registrationDate?: InputMaybe<Scalars['String']['input']>;
};

export type EnableRecurringPaymentByEnrollmentIdInput = {
  enrollmentId: Scalars['String']['input'];
  studentCreditCardId: Scalars['String']['input'];
};

export type EnableRecurringPaymentInput = {
  courseId: Scalars['String']['input'];
  studentCreditCardId: Scalars['String']['input'];
  studentId?: InputMaybe<Scalars['String']['input']>;
};

export enum EnadeRegulation {
  Irregular = 'IRREGULAR',
  Regular = 'REGULAR',
  UnspecifiedEnadeRegulation = 'UNSPECIFIED_ENADE_REGULATION',
}

export type EnadeStatusInput = {
  enadeRegulation: EnadeRegulation;
  enadeSituation: Scalars['String']['input'];
};

export type EnrollPendingInput = {
  courseId: Scalars['String']['input'];
  courseType: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type EnrollmentCancellation = {
  __typename?: 'EnrollmentCancellation';
  allowChatbot: Scalars['Boolean']['output'];
  recipent?: Maybe<Scalars['String']['output']>;
  recipentType?: Maybe<RecipentType>;
  warnings: Array<Scalars['String']['output']>;
};

export enum EnrollmentCancellationAction {
  Cancellation = 'CANCELLATION',
  Suspend = 'SUSPEND',
}

export type EnrollmentDegree = {
  __typename?: 'EnrollmentDegree';
  courseEnrollmentId: Scalars['ID']['output'];
  degreeExpeditionDate?: Maybe<Scalars['String']['output']>;
  degreePageNumber?: Maybe<Scalars['String']['output']>;
  degreeProcess?: Maybe<Scalars['String']['output']>;
  degreeRegistryDate?: Maybe<Scalars['String']['output']>;
  degreeSequenceNumber?: Maybe<Scalars['String']['output']>;
  graduationDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  registryBook?: Maybe<Scalars['String']['output']>;
  registryNumber?: Maybe<Scalars['String']['output']>;
  registryResponsible?: Maybe<RegistryResponsible>;
};

export type EnrollmentDegreeInput = {
  courseEnrollmentId: Scalars['ID']['input'];
  degreeExpeditionDate?: InputMaybe<Scalars['String']['input']>;
  degreePageNumber?: InputMaybe<Scalars['String']['input']>;
  degreeProcess?: InputMaybe<Scalars['String']['input']>;
  degreeRegistryDate?: InputMaybe<Scalars['String']['input']>;
  degreeSequenceNumber?: InputMaybe<Scalars['String']['input']>;
  graduationDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  registryBook?: InputMaybe<Scalars['String']['input']>;
  registryNumber?: InputMaybe<Scalars['String']['input']>;
  registryResponsible?: InputMaybe<RegistryResponsibleInput>;
};

export type EnrollmentDegreeResponse = {
  __typename?: 'EnrollmentDegreeResponse';
  enrollmentDegree?: Maybe<Scalars['JSON']['output']>;
};

export type EnrollmentEnadeStatus = {
  __typename?: 'EnrollmentEnadeStatus';
  enadeRegulation: EnadeRegulation;
  enadeSituation: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type EnrollmentProgress = {
  __typename?: 'EnrollmentProgress';
  completed?: Maybe<Scalars['Int']['output']>;
  conclusionPercentage: Scalars['String']['output'];
  courseEnrollmentId: Scalars['ID']['output'];
  maskedConclusionPercentage: Scalars['String']['output'];
  passedCourseNumber: Scalars['String']['output'];
  percentage?: Maybe<Scalars['Int']['output']>;
  percentageLabel?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  totalCourseNumber: Scalars['String']['output'];
};

export type EnrollmentRegulationStatus = {
  __typename?: 'EnrollmentRegulationStatus';
  courseEnrollmentId: Scalars['ID']['output'];
  diplomaText: Scalars['String']['output'];
  enadeStatusId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mention: Scalars['String']['output'];
  referenceYear: Scalars['Int']['output'];
  regulationStatusType: RegulationStatusType;
  testDate?: Maybe<Scalars['String']['output']>;
};

export enum EnrollmentStatus {
  AwaitingPayment = 'AWAITING_PAYMENT',
  Studying = 'STUDYING',
  Undefined = 'UNDEFINED',
}

export enum EnumSubject {
  FullName = 'FULL_NAME',
  Gender = 'GENDER',
  MaritalStatus = 'MARITAL_STATUS',
  PlaceOfBirth = 'PLACE_OF_BIRTH',
}

export type Exam = {
  __typename?: 'Exam';
  answerSheet?: Maybe<AnswerSheet>;
  assignmentId: Scalars['ID']['output'];
  createdDate: Scalars['String']['output'];
  durationInMinutes: Scalars['Int']['output'];
  finishedBy?: Maybe<ExamFinishedBy>;
  finishedDate?: Maybe<Scalars['String']['output']>;
  finishedReason?: Maybe<Scalars['String']['output']>;
  geoLocation?: Maybe<GeoLocation>;
  id: Scalars['ID']['output'];
  learningUnitId?: Maybe<Scalars['String']['output']>;
  questions: Array<QuestionForExam>;
  startedDate: Scalars['String']['output'];
  status: ExamStatus;
  subject: Subject;
  subjectGrade: Scalars['Int']['output'];
  subjectId: Scalars['String']['output'];
};

export enum ExamFinishedBy {
  SystemAndroid = 'SYSTEM_ANDROID',
  SystemIos = 'SYSTEM_IOS',
  SystemWeb = 'SYSTEM_WEB',
  Unspecified = 'UNSPECIFIED',
  User = 'USER',
}

export enum ExamGradeType {
  FormalWrittenExpression = 'FORMAL_WRITTEN_EXPRESSION',
  LanguageResources = 'LANGUAGE_RESOURCES',
  Structure = 'STRUCTURE',
  ThematicDevelopment = 'THEMATIC_DEVELOPMENT',
}

export enum ExamStatus {
  Canceled = 'CANCELED',
  Finished = 'FINISHED',
  Started = 'STARTED',
  UnspecifiedExamType = 'UNSPECIFIED_EXAM_TYPE',
}

export type ExistingCreditCardPaymentInput = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  creditCardId: Scalars['ID']['input'];
  instalmentId: Scalars['ID']['input'];
  makeRecurring: Scalars['Boolean']['input'];
};

export type ExternalPayment = {
  __typename?: 'ExternalPayment';
  externalPaymentUrl: Scalars['String']['output'];
};

export type FeatureRole = {
  __typename?: 'FeatureRole';
  block?: Maybe<FeatureRoleBlock>;
  name: Scalars['String']['output'];
};

export type FeatureRoleBlock = {
  __typename?: 'FeatureRoleBlock';
  description: Scalars['String']['output'];
  type: FeatureRoleBlockType;
};

export enum FeatureRoleBlockType {
  ReenrollmentContract = 'REENROLLMENT_CONTRACT',
  ReenrollmentInvoice = 'REENROLLMENT_INVOICE',
}

export type FeatureRolesModel = {
  __typename?: 'FeatureRolesModel';
  additionalInfo?: Maybe<Scalars['JSON']['output']>;
  featureRoles: Array<FeatureRole>;
};

export type FieldOfStudy = {
  __typename?: 'FieldOfStudy';
  code: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type FieldOfStudyData = {
  code?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type FieldOfStudyList = {
  __typename?: 'FieldOfStudyList';
  data?: Maybe<Array<Maybe<FieldOfStudy>>>;
  page?: Maybe<Page>;
};

export type FieldSupervisor = {
  __typename?: 'FieldSupervisor';
  academicDegree: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FieldSupervisorInput = {
  academicDegree: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
};

export type FileAsset = Asset & {
  __typename?: 'FileAsset';
  id: Scalars['ID']['output'];
  size: Scalars['String']['output'];
  type: AssetType.File;
  altText: Scalars['String']['output'];
  description: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Filter = {
  q?: InputMaybe<Scalars['String']['input']>;
};

export type FinancialSimulation = {
  __typename?: 'FinancialSimulation';
  numberOfInstalments: Scalars['Int']['output'];
  numberOfRemainingInstalments: Scalars['Int']['output'];
};

export enum FinancialStatus {
  Defaulter = 'DEFAULTER',
  Settled = 'SETTLED',
  Undetermined = 'UNDETERMINED',
}

export type FindProductInterestRequest = {
  productOfferId: Scalars['ID']['input'];
};

export type FindProductInterestResponse = {
  __typename?: 'FindProductInterestResponse';
  interested?: Maybe<Scalars['Boolean']['output']>;
};

export type FinishExamAnswerInput = {
  choiceId?: InputMaybe<Scalars['ID']['input']>;
  questionId: Scalars['ID']['input'];
};

export type FinishExamInput = {
  answers: Array<FinishExamAnswerInput>;
  examId: Scalars['ID']['input'];
  finishedBy?: InputMaybe<ExamFinishedBy>;
  finishedReason?: InputMaybe<Scalars['String']['input']>;
  geoLocation?: InputMaybe<GeoLocationInput>;
};

export type FormulaAsset = Asset & {
  __typename?: 'FormulaAsset';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: AssetType;
};

export enum FreeTrialSituation {
  Disabled = 'DISABLED',
  Expired = 'EXPIRED',
  Expiring = 'EXPIRING',
  HalfUsed = 'HALF_USED',
  NotStarted = 'NOT_STARTED',
  Paid = 'PAID',
  PaidAndRunning = 'PAID_AND_RUNNING',
  Started = 'STARTED',
}

export type FullAddress = {
  __typename?: 'FullAddress';
  cityName: Scalars['String']['output'];
  complement?: Maybe<Scalars['String']['output']>;
  neighbourhood?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  postalCode: Scalars['PostalCode']['output'];
  stateAcronym: Scalars['String']['output'];
  street: Scalars['String']['output'];
};

export type FullAddressInput = {
  cityName: Scalars['String']['input'];
  complement?: InputMaybe<Scalars['String']['input']>;
  neighbourhood?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  stateAcronym: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  NoInformed = 'NO_INFORMED',
  Other = 'OTHER',
  UnspecifiedGender = 'UNSPECIFIED_GENDER',
  WishNoInform = 'WISH_NO_INFORM',
}

export type GenerateBoletoFileResponse = {
  __typename?: 'GenerateBoletoFileResponse';
  boletoFileUrl: Scalars['String']['output'];
};

export type GenerateIncomeTaxDocumentResponse = {
  __typename?: 'GenerateIncomeTaxDocumentResponse';
  url: Scalars['String']['output'];
};

export type GenerateIncomeTaxDocumentUrlResponse = {
  __typename?: 'GenerateIncomeTaxDocumentUrlResponse';
  url: Scalars['String']['output'];
};

export type GenericFieldsCardInformation = {
  __typename?: 'GenericFieldsCardInformation';
  description?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GenericFieldsStatus = {
  __typename?: 'GenericFieldsStatus';
  cardInformation?: Maybe<GenericFieldsCardInformation>;
  documentSizeLimit?: Maybe<Scalars['Int']['output']>;
  enabledDescription?: Maybe<Scalars['Boolean']['output']>;
  options?: Maybe<Array<DescribedEnum>>;
  order?: Maybe<OrderResponse>;
  supportedDocumentExtension?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  validDocuments: Array<Scalars['String']['output']>;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type GeoLocationInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type GetAvailableBillingCyclesResponse = {
  __typename?: 'GetAvailableBillingCyclesResponse';
  cycles?: Maybe<Array<Scalars['String']['output']>>;
};

export type GetAvailableIncomeTaxYearsResponse = {
  __typename?: 'GetAvailableIncomeTaxYearsResponse';
  years?: Maybe<Array<Scalars['String']['output']>>;
};

export type GetCyclesResponse = {
  __typename?: 'GetCyclesResponse';
  cycles?: Maybe<Array<Scalars['String']['output']>>;
};

export type GetEnrollmentByIdResponse = {
  __typename?: 'GetEnrollmentByIdResponse';
  enrollmentId: Scalars['String']['output'];
};

export type GetIncomeTaxYearsResponse = {
  __typename?: 'GetIncomeTaxYearsResponse';
  years?: Maybe<Array<Scalars['String']['output']>>;
};

export type GetInvoiceCreditCardPublicKey = {
  __typename?: 'GetInvoiceCreditCardPublicKey';
  id?: Maybe<Scalars['String']['output']>;
  paymentClient: PaymentClientCreditCard;
  publicKey?: Maybe<Scalars['String']['output']>;
};

export type GetOrderResponse = {
  __typename?: 'GetOrderResponse';
  orders?: Maybe<Array<Maybe<OrderResponse>>>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type GetPublicKeyResponse = {
  __typename?: 'GetPublicKeyResponse';
  id?: Maybe<Scalars['String']['output']>;
  paymentClient: PaymentClient;
  publicKey?: Maybe<Scalars['String']['output']>;
};

export type Goal = {
  __typename?: 'Goal';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export type GoalInput = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type GradingMethod = {
  __typename?: 'GradingMethod';
  assignmentConfigs: Array<AssignmentConfig>;
  createdDate: Scalars['String']['output'];
  cutOffScore: Scalars['Int']['output'];
  cutOffScorePercentage?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastModifiedDate: Scalars['String']['output'];
  name: Scalars['String']['output'];
  publicationDate?: Maybe<Scalars['String']['output']>;
  publicationStatus: PublicationStatus;
};

export type GradingMethodData = {
  assignmentConfigIds: Array<Scalars['String']['input']>;
  cutOffScore: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GradingMethodList = {
  __typename?: 'GradingMethodList';
  data?: Maybe<Array<Maybe<GradingMethod>>>;
  page?: Maybe<Page>;
};

export type HistoryResponse = {
  __typename?: 'HistoryResponse';
  athenasCreatedDate?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type IdentityDocument = {
  __typename?: 'IdentityDocument';
  id?: Maybe<Scalars['String']['output']>;
  type: NationalIdentityType;
};

export type IdentityDocumentStatus = {
  __typename?: 'IdentityDocumentStatus';
  documentSizeLimit?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<OrderResponse>;
  supportedDocumentExtension?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  warningMessage?: Maybe<Scalars['String']['output']>;
};

export type Image = {
  __typename?: 'Image';
  baseUrl: Scalars['String']['output'];
  descriptionText?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

export type ImageAsset = Asset & {
  __typename?: 'ImageAsset';
  caption?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image: Image;
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrls?: Maybe<Record<string, never> | null>;
  type: AssetType;
};

export type ImageConnection = {
  __typename?: 'ImageConnection';
  data?: Maybe<Array<Image>>;
  page: Page;
};

export type Instalment = {
  __typename?: 'Instalment';
  amount: Scalars['Money']['output'];
  boleto?: Maybe<Boleto>;
  canGenerateBoleto?: Maybe<Scalars['Boolean']['output']>;
  canGetInvoiceDocument?: Maybe<Scalars['Boolean']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  creditCard?: Maybe<CreditCard>;
  creditCardId?: Maybe<Scalars['String']['output']>;
  dailyInterestPercentage?: Maybe<Scalars['Float']['output']>;
  discount: Scalars['Money']['output'];
  dueDate: Scalars['String']['output'];
  fine: Scalars['Money']['output'];
  finePercentage?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  instalmentNumber: Scalars['Int']['output'];
  instalmentType: InstalmentType;
  interest: Scalars['Money']['output'];
  invoiceBlock?: Maybe<InvoiceBlock>;
  isDependency: Scalars['Boolean']['output'];
  otherIncrement: Scalars['Money']['output'];
  overdueDays?: Maybe<Scalars['Int']['output']>;
  paidAmount?: Maybe<Scalars['Money']['output']>;
  paidAt?: Maybe<Scalars['String']['output']>;
  paymentMethod: PaymentMethod;
  paymentPlan?: Maybe<PaymentPlan>;
  paymentPlanId?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<SubjectEnrollment>;
  renewedDueDate?: Maybe<Scalars['String']['output']>;
  status: InstalmentStatus;
  totalAmount: Scalars['Money']['output'];
  totalDueMinusTotalDiscount: Scalars['Money']['output'];
};

export enum InstalmentStatus {
  Blocked = 'BLOCKED',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Open = 'OPEN',
  Overdue = 'OVERDUE',
  Overpaid = 'OVERPAID',
  Paid = 'PAID',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Underpaid = 'UNDERPAID',
  WroteOff = 'WROTE_OFF',
}

export enum InstalmentType {
  Agreement = 'AGREEMENT',
  MonthlyFee = 'MONTHLY_FEE',
}

export type Invoice = {
  __typename?: 'Invoice';
  block?: Maybe<InvoiceBlock>;
  currentDueDate: Scalars['String']['output'];
  details?: Maybe<InvoiceDetail>;
  formattedAmount: Scalars['String']['output'];
  hasExternalPayment: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  olderInvoicePaymentSuggestion?: Maybe<Scalars['ID']['output']>;
  shortTitle: Scalars['String']['output'];
  status: Status;
  title: Scalars['String']['output'];
};

export type InvoiceBlock = {
  __typename?: 'InvoiceBlock';
  reason: Scalars['String']['output'];
  type: InvoiceBlockType;
};

export enum InvoiceBlockType {
  Documents = 'DOCUMENTS',
  Generic = 'GENERIC',
}

export type InvoiceBoleto = {
  __typename?: 'InvoiceBoleto';
  barcode: Scalars['String']['output'];
  barcodeImage: Scalars['String']['output'];
};

export type InvoiceBoletoPdf = {
  __typename?: 'InvoiceBoletoPdf';
  url: Scalars['String']['output'];
};

export type InvoiceCreditCardFullAddressInput = {
  cityName: Scalars['String']['input'];
  complement?: InputMaybe<Scalars['String']['input']>;
  neighbourhood?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  stateAcronym: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type InvoiceCreditCardInput = {
  billingAddress: InvoiceCreditCardFullAddressInput;
  cvv: Scalars['String']['input'];
  expirationMonth: Scalars['String']['input'];
  expirationYear: Scalars['String']['input'];
  holderDocument: Scalars['String']['input'];
  holderName: Scalars['String']['input'];
  number: Scalars['String']['input'];
};

export type InvoiceDetail = {
  __typename?: 'InvoiceDetail';
  allowGetInvoiceDocument: Scalars['Boolean']['output'];
  associatePayment: Array<PaymentStatementItem>;
  info?: Maybe<Scalars['String']['output']>;
  invoiceAlert?: Maybe<PaymentWarning>;
  items: Array<InvoiceItems>;
  notes: Array<Scalars['String']['output']>;
  paymentAlert?: Maybe<PaymentWarning>;
  /** @deprecated This field is deprecated. Use 'invoiceAlert' instead. */
  warning?: Maybe<PaymentWarning>;
};

export type InvoiceDocument = {
  __typename?: 'InvoiceDocument';
  invoiceDocumentUrl: Scalars['String']['output'];
};

export type InvoiceDocumentResponse = {
  __typename?: 'InvoiceDocumentResponse';
  invoiceDocumentUrl: Scalars['String']['output'];
};

export type InvoiceExistingCreditCardPaymentInput = {
  courseEnrollmentId: Scalars['String']['input'];
  creditCardId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  makeRecurring?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoiceHomeModel = {
  __typename?: 'InvoiceHomeModel';
  alert?: Maybe<PaymentWarning>;
  billingDay?: Maybe<Scalars['String']['output']>;
  block?: Maybe<InvoiceBlock>;
  invoiceSections: Array<InvoiceSection>;
};

export type InvoiceItems = {
  __typename?: 'InvoiceItems';
  formattedAmount: Scalars['String']['output'];
  subItems: Array<Maybe<InvoiceSubItems>>;
  title: Scalars['String']['output'];
};

export type InvoiceNewCreditCardPaymentInput = {
  courseEnrollmentId: Scalars['String']['input'];
  creditCardInput: InvoiceCreditCardInput;
  invoiceId: Scalars['ID']['input'];
  makeRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  saveForFutureUse?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum InvoicePaymentMethod {
  Boleto = 'BOLETO',
  CreditCard = 'CREDIT_CARD',
  Exemption = 'EXEMPTION',
  Other = 'OTHER',
  Pix = 'PIX',
}

export type InvoiceSection = {
  __typename?: 'InvoiceSection';
  invoices: Array<Invoice>;
  title: Scalars['String']['output'];
};

export type InvoiceSubItems = {
  __typename?: 'InvoiceSubItems';
  formattedAmount: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Lead = {
  __typename?: 'Lead';
  cityId?: Maybe<Scalars['Int']['output']>;
  courseId?: Maybe<Scalars['ID']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  optIn: Scalars['Boolean']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  queryStrings?: Maybe<Array<CandidateQueryString>>;
  sellerId?: Maybe<Scalars['String']['output']>;
  sourceTag?: Maybe<Scalars['String']['output']>;
  stateId?: Maybe<Scalars['Int']['output']>;
};

export type LeadData = {
  cityId?: InputMaybe<Scalars['Int']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  queryStrings?: InputMaybe<Array<CandidateLeadQueryStringInput>>;
  sellerId?: InputMaybe<Scalars['String']['input']>;
  sourceTag?: InputMaybe<Scalars['String']['input']>;
  stateId?: InputMaybe<Scalars['Int']['input']>;
};

export type LeadLight = {
  __typename?: 'LeadLight';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type LeadLightList = {
  __typename?: 'LeadLightList';
  data?: Maybe<Array<Maybe<LeadLight>>>;
  page?: Maybe<Page>;
};

export type LeadList = {
  __typename?: 'LeadList';
  data?: Maybe<Array<Maybe<Lead>>>;
  page?: Maybe<Page>;
};

export type LearningObject = BaseLearningObject & {
  __typename?: 'LearningObject';
  assets: Array<AssetItem>;
  audit?: Maybe<Audit>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  pathUrl?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<LearningObjectType>;
};

export type LearningObjectEnrollment = BaseLearningObject & {
  __typename?: 'LearningObjectEnrollment';
  assets: Array<AssetItem>;
  completed: Scalars['Boolean']['output'];
  completedDate?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  pathUrl?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<LearningObjectType>;
};

export type LearningObjectInput = {
  assets: Array<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export enum LearningObjectType {
  Formula = 'formula',
  Image = 'image',
  Table = 'table',
  Text = 'text',
  Video = 'video',
}

export type LearningUnit = {
  __typename?: 'LearningUnit';
  associatedSubjects: Array<Maybe<Subject>>;
  audit?: Maybe<Audit>;
  currentLearningUnitVersion?: Maybe<LearningUnitVersion>;
  currentLearningUnitVersionId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  publicationDate?: Maybe<Scalars['String']['output']>;
  publicationStatus: PublicationStatus;
  title: Scalars['String']['output'];
  versions: Array<Maybe<NewVersion>>;
};

export type LearningUnitConnection = {
  __typename?: 'LearningUnitConnection';
  data: Array<LearningUnit>;
  page: Page;
};

export type LearningUnitEnrollment = BaseLearningUnit & {
  __typename?: 'LearningUnitEnrollment';
  assignments?: Maybe<Array<Maybe<LearningUnitEnrollmentAssignment>>>;
  endDate?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Scalars['String']['output']>;
  grade?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  learningUnitId: Scalars['String']['output'];
  learningUnitVersion: LearningUnitVersion;
  learningUnitVersionId: Scalars['ID']['output'];
  maxGrade?: Maybe<Scalars['Int']['output']>;
  order: Scalars['Int']['output'];
  practicalActivities: PracticalActivities;
  reviewContents?: Maybe<ReviewContent>;
  sections: Array<SectionEnrollment>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: LearningUnitStatus;
  tempLearningUnitId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  transferredFrom?: Maybe<Scalars['String']['output']>;
  transferredTo?: Maybe<Scalars['String']['output']>;
};

export type LearningUnitEnrollmentAssignment = {
  __typename?: 'LearningUnitEnrollmentAssignment';
  detail?: Maybe<Assignment>;
  grade?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  maxGrade: Scalars['Int']['output'];
};

export enum LearningUnitStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Suspended = 'SUSPENDED',
}

export type LearningUnitVersion = {
  __typename?: 'LearningUnitVersion';
  audit?: Maybe<Audit>;
  countEnrollments: Scalars['Int']['output'];
  goals?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  learningUnit: LearningUnit;
  learningUnitId: Scalars['ID']['output'];
  publicationDate?: Maybe<Scalars['String']['output']>;
  publicationStatus: PublicationStatus;
  sections: Array<Section>;
};

export type LearningUnitVersionConnection = {
  __typename?: 'LearningUnitVersionConnection';
  data: Array<LearningUnitVersion>;
  page: Page;
};

export type LearningUnitVersionInput = {
  goals?: InputMaybe<Scalars['String']['input']>;
  sections: Array<SectionInput>;
};

export type LegalDocument = {
  __typename?: 'LegalDocument';
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LegalDocumentConnection = {
  __typename?: 'LegalDocumentConnection';
  data: Array<LegalDocument>;
  page: Page;
};

export type LegalDocumentCreateInput = {
  isEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type LegalDocumentCreateWithFileInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  isEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LegalDocumentUpdateInput = {
  id: Scalars['ID']['input'];
  isEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type LegalDocumentUpdateWithFileInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  id: Scalars['ID']['input'];
  isEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LessonPlan = {
  __typename?: 'LessonPlan';
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  simulator: Simulator;
  title: Scalars['String']['output'];
};

export type ListCourseEventResponse = {
  __typename?: 'ListCourseEventResponse';
  data?: Maybe<Array<CourseEvent>>;
  page?: Maybe<Page>;
};

export type Logo = {
  __typename?: 'Logo';
  baseUrl: Scalars['String']['output'];
  formats?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type MandatoryInternship = {
  __typename?: 'MandatoryInternship';
  activities?: Maybe<Scalars['String']['output']>;
  assignmentId: Scalars['String']['output'];
  assignmentStatus: SubjectEnrollmentAssignmentStatus;
  audit: Audit;
  companyData: CompanyData;
  companyResponsible: CompanyResponsible;
  consolidatedStatus: ConsolidatedStatus;
  courseName: Scalars['String']['output'];
  fieldSupervisor: FieldSupervisor;
  finishDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rejectionReason?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['String']['output'];
  status: MandatoryInternshipStatus;
  studentName: Scalars['String']['output'];
  studentUsername: Scalars['String']['output'];
  subjectEnrollmentId: Scalars['String']['output'];
  termUrl?: Maybe<Scalars['String']['output']>;
  weeklySchedule: WeeklySchedule;
};

export type MandatoryInternshipHistory = {
  __typename?: 'MandatoryInternshipHistory';
  action: ConsolidatedStatus;
  createdBy: Scalars['String']['output'];
  date: Scalars['String']['output'];
};

export enum MandatoryInternshipStatus {
  Approved = 'APPROVED',
  InAnalysis = 'IN_ANALYSIS',
  Rejected = 'REJECTED',
}

export enum MaritalStatus {
  Disquitted = 'DISQUITTED',
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  OtherMaritalStatus = 'OTHER_MARITAL_STATUS',
  Separated = 'SEPARATED',
  Single = 'SINGLE',
  StableUnion = 'STABLE_UNION',
  UnspecifiedMaritalStatus = 'UNSPECIFIED_MARITAL_STATUS',
  Widowed = 'WIDOWED',
}

export type Me = {
  __typename?: 'Me';
  email?: Maybe<Scalars['String']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  grantedAuthorities?: Maybe<Array<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  passwordExpired: Scalars['Boolean']['output'];
  personId: Scalars['ID']['output'];
  phoneNumber?: Maybe<Phone>;
  phoneNumberString?: Maybe<Scalars['String']['output']>;
  phoneNumberVerified: Scalars['Boolean']['output'];
  termsAcceptanceDate?: Maybe<Scalars['DateTime']['output']>;
  termsAccepted: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
};

export enum MockResultStatus {
  Expired = 'EXPIRED',
  Finished = 'FINISHED',
  FinishedLegacy = 'FINISHED_LEGACY',
  NotFound = 'NOT_FOUND',
  Processing = 'PROCESSING',
  Requested = 'REQUESTED',
}

export enum ModelSubType {
  MandatoryInternshipTerm = 'MANDATORY_INTERNSHIP_TERM',
}

export enum ModelType {
  Dynamic = 'DYNAMIC',
  None = 'NONE',
  Static = 'STATIC',
}

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  abandonCourseEnrollment: CourseEnrollment;
  accreditStudentDocument: Document;
  addAttempt: Assignment;
  addLearningUnitVersion: LearningUnit;
  answerCandidateExam: CandidateExam;
  appendOnTrack: Track;
  applyCoupon: ApplyCouponResponse;
  /** @deprecated Use applyCoupon instead */
  applyCouponOnNextInstalment: Instalment;
  applyDiscount: PaymentPlan;
  applyInvoiceCoupon: ApplyCouponResponseDto;
  approveACO: Scalars['Boolean']['output'];
  approveStudentDocument: Document;
  cancelCandidatePendingBoleto?: Maybe<CandidatePayment>;
  cancelCourseEnrollment: CourseEnrollment;
  cancelFreeTrialEnrollment: CancellationStatus;
  cancelInstalment: Instalment;
  cancelOrder?: Maybe<OrderResponse>;
  cancelPendingInstalment: Instalment;
  candidatePayByNewCreditCard: CandidatePayment;
  changeBillingCycle: ChangeBillingCyclesResponse;
  changeBillingDay: PaymentPlan;
  changeGenericFields: Scalars['Boolean']['output'];
  changeIdentityDocument: Scalars['Boolean']['output'];
  changeSocialName: Scalars['Boolean']['output'];
  chooseSubjectElective: SubjectEnrollment;
  confirmCandidateEmail: Candidate;
  contractAgreement?: Maybe<ContractIngressAgreementResponse>;
  createAffiliate: Affiliate;
  createAffiliateAppointmentSession: AffiliateAppointmentSession;
  createAnnotation: Annotation;
  createAppointmentExam: AppointmentExam;
  createAssignment: Assignment;
  createAssignmentConfig: AssignmentConfig;
  createAuthority: Authority;
  createBrand?: Maybe<EducationProviderBrand>;
  createCandidate: Candidate;
  createCandidateByPartner: Candidate;
  createCandidateContract: CandidateContract;
  createCandidateFromStudent: Candidate;
  createCategorySection: CategorySection;
  createCompany?: Maybe<EducationProviderCompany>;
  createContentEvaluation: ContentEvaluation;
  createCoupon: Coupon;
  createCourse: Course;
  createCourseCategory: CourseCategory;
  createCourseDescriptionType?: Maybe<CourseDescriptionType>;
  createCourseEvent: Scalars['Boolean']['output'];
  createCourseSeo: CourseSeo;
  createCourseType?: Maybe<CourseType>;
  createEducationProvider?: Maybe<EducationProvider>;
  createEnrollmentReceipt: Scalars['String']['output'];
  /** @deprecated Use createExamByAssignmentId instead */
  createExam: Exam;
  createExamByAssignmentId: Exam;
  createFieldOfStudy?: Maybe<FieldOfStudy>;
  createGradingMethod: GradingMethod;
  createInternship: OperationStatus;
  createLead?: Maybe<Lead>;
  createLeadByPartner?: Maybe<Lead>;
  createLearningUnit: LearningUnit;
  createLegalDocument: LegalDocument;
  createLegalDocumentWithFile: LegalDocument;
  createManyAnnotations: Array<Maybe<Annotation>>;
  createManyAttendances?: Maybe<Scalars['Boolean']['output']>;
  createManyContentEvaluation?: Maybe<Array<ContentEvaluation>>;
  createNewCourseVersion: Course;
  createNewOrder: OrderResponse;
  createOrUpdateCourseEnrollmentDegree?: Maybe<EnrollmentDegree>;
  createOrderWallet?: Maybe<OrderWalletResponse>;
  createOrderWalletWithoutFile?: Maybe<OrderWalletResponse>;
  createPartner: Partner;
  createPerson?: Maybe<Person>;
  createPipelineNotificationConfig?: Maybe<PipelineNotificationConfig>;
  createPipelineNotificationContext?: Maybe<PipelineNotificationContext>;
  createPipelineNotificationDegreeGroup?: Maybe<PipelineNotificationDegreeGroup>;
  createProductOffer: ProductOffer;
  createQuestion: Question;
  createQuestionBankType: BankType;
  createReason: Reason;
  createRole: Role;
  createSeller?: Maybe<Seller>;
  createSellerCategory?: Maybe<SellerCategory>;
  createSellerPublic?: Maybe<Seller>;
  createSellerRegional?: Maybe<SellerRegional>;
  createSellerUnit?: Maybe<SellerUnit>;
  createSmsTemplate: SmsTemplate;
  createStudent: Student;
  createSubject: Subject;
  createTargetGroup?: Maybe<TargetGroup>;
  createTeacher: Scalars['Boolean']['output'];
  createUser: User;
  createWhitelist: Scalars['Boolean']['output'];
  deleteAffiliate: Scalars['ID']['output'];
  deleteAnnotation: Scalars['ID']['output'];
  deleteAssignment: Scalars['ID']['output'];
  deleteAssignmentConfig: Scalars['ID']['output'];
  deleteAuthority: Scalars['ID']['output'];
  deleteBrand: Scalars['ID']['output'];
  deleteCandidate: Scalars['ID']['output'];
  deleteCandidateExam: Scalars['ID']['output'];
  deleteCategorySection: Scalars['ID']['output'];
  deleteCompany: Scalars['ID']['output'];
  deleteCoupon: Scalars['ID']['output'];
  deleteCourse: Scalars['ID']['output'];
  deleteCourseCategory: Scalars['ID']['output'];
  deleteCourseDescriptionType: Scalars['ID']['output'];
  deleteCourseEvent: Scalars['ID']['output'];
  deleteCourseSeo: Scalars['ID']['output'];
  deleteCourseType: Scalars['ID']['output'];
  deleteCourseVersion: Scalars['ID']['output'];
  deleteEducationProvider: Scalars['ID']['output'];
  deleteFieldOfStudy: Scalars['ID']['output'];
  deleteGradingMethod: Scalars['ID']['output'];
  deleteImage: Scalars['ID']['output'];
  deleteLead: Scalars['ID']['output'];
  deleteLearningUnit: Scalars['ID']['output'];
  deleteLegalDocument: Scalars['ID']['output'];
  deleteManyAnnotations: Array<Maybe<Scalars['ID']['output']>>;
  deletePartner: Scalars['ID']['output'];
  deletePerson: Scalars['ID']['output'];
  deletePipelineNotificationConfig: Scalars['ID']['output'];
  deletePipelineNotificationContext: Scalars['ID']['output'];
  deletePipelineNotificationDegreeGroup: Scalars['ID']['output'];
  deleteProductOffer: Scalars['ID']['output'];
  deleteQuestion: Scalars['ID']['output'];
  deleteRole: Scalars['ID']['output'];
  deleteSeller: Scalars['ID']['output'];
  deleteSellerCategory: Scalars['ID']['output'];
  deleteSellerRegional: Scalars['ID']['output'];
  deleteSellerUnit: Scalars['ID']['output'];
  deleteSmsTemplate: Scalars['ID']['output'];
  deleteStudent: Scalars['ID']['output'];
  deleteStudentCreditCard: Scalars['ID']['output'];
  deleteSubject: Scalars['ID']['output'];
  deleteTargetGroup: Scalars['ID']['output'];
  deleteTeacher: Scalars['ID']['output'];
  deleteUser: Scalars['ID']['output'];
  disableCreditCardRecurrence?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use new method disableRecurringPaymentByEnrollmentId() */
  disableRecurringPayment: PaymentPlan;
  disableRecurringPaymentByEnrollmentId: PaymentPlan;
  dropCourseEnrollment: CourseEnrollment;
  /** @deprecated Use new method enableRecurringPaymentByEnrollmentId() */
  enableRecurringPayment: PaymentPlan;
  enableRecurringPaymentByEnrollmentId: PaymentPlan;
  evaluateCandidateEnem: Candidate;
  evaluateCandidateExam: CandidateExam;
  evaluateCandidateExamFromPartner: Candidate;
  finishCourseEnrollment: CourseEnrollment;
  finishExam: Exam;
  finishPresentialExam: Exam;
  generateBoletoFile: GenerateBoletoFileResponse;
  generateIncomeTaxDocument: GenerateIncomeTaxDocumentResponse;
  generateIncomeTaxDocumentUrl: GenerateIncomeTaxDocumentUrlResponse;
  getInvoiceDocument: InvoiceDocument;
  insertRemainInstalments: PaymentPlan;
  loginAmpli: Authentication;
  loginCandidate: Authentication;
  loginPartner: Authentication;
  loginSeller: Authentication;
  loginStudent: Authentication;
  logout?: Maybe<Scalars['ID']['output']>;
  notifyStudentByType: Scalars['Boolean']['output'];
  patchOpenNotification: NotificationResponse;
  payCandidateInstalmentByBoleto: CandidatePayment;
  payInstalmentByBoleto: Instalment;
  payInstalmentByExistingCreditCard: Instalment;
  payInstalmentByNewCreditCard: Instalment;
  payInvoiceByBoleto: InvoiceBoleto;
  payInvoiceByCreditCard?: Maybe<PaymentCreditCardStatusResult>;
  payInvoiceByExistingCreditCard?: Maybe<PaymentCreditCardStatusResult>;
  payInvoiceByPix?: Maybe<PaymentPixStatusResult>;
  publishCourse: Course;
  publishEducationProvider?: Maybe<EducationProvider>;
  publishGradingMethod: GradingMethod;
  publishLearningUnit: LearningUnit;
  publishLearningUnitVersion: LearningUnitVersion;
  publishNewCourseVersion: Course;
  publishQuestion: Question;
  publishSubject: Subject;
  pushNotificationDelete?: Maybe<Scalars['Boolean']['output']>;
  pushNotificationSave?: Maybe<Scalars['Boolean']['output']>;
  rateArticle: Scalars['Boolean']['output'];
  redoCandidateExam: Scalars['ID']['output'];
  registerProductInterest: RegisterProductInterestResponse;
  rejectStudentDocument: Document;
  removeAppointmentExam: OperationStatus;
  removeCreditCard?: Maybe<Scalars['Boolean']['output']>;
  removeLearningUnitVersion: LearningUnit;
  removeStudentProfilePhoto: Student;
  removeWhitelist: Scalars['Boolean']['output'];
  reopenInstalment: Instalment;
  reopenOrder?: Maybe<OrderResponse>;
  reopenOrderWithoutFile?: Maybe<OrderResponse>;
  reprocessSendQuotationToSAP: ReprocessPaymentPlanResponse;
  reproveACO: Scalars['Boolean']['output'];
  requestChangeCycleStatus: ChangeCyclesResponse;
  requestProgramContent?: Maybe<Scalars['String']['output']>;
  resetCandidateEnemOption: Candidate;
  resetPassword: Scalars['Boolean']['output'];
  /** @deprecated Use ResetPassword mutation instead */
  resetUserPassword: Scalars['Boolean']['output'];
  resumeCourseEnrollment: CourseEnrollment;
  retryPipelineNotificationExecution?: Maybe<PipelineNotificationExecution>;
  saveEnadeStatus: EnrollmentEnadeStatus;
  saveRegulationStatus: EnrollmentRegulationStatus;
  saveTextCustomization: TextCustomization;
  secretariatRemoveStudentProfilePhoto: GetEnrollmentByIdResponse;
  secretariatUploadStudentProfilePhoto: Scalars['Boolean']['output'];
  sendACO: Scalars['Boolean']['output'];
  sendAssignmentFileFeedback?: Maybe<Scalars['Boolean']['output']>;
  signCandidateContract: Candidate;
  sofiaChangePassword: Scalars['Boolean']['output'];
  sofiaLoginUser: SofiaLoginResponse;
  sofiaLogoutUser: Scalars['Boolean']['output'];
  sofiaNotifyStudentByType: Scalars['Boolean']['output'];
  sofiaValidateNotificationCode: Scalars['String']['output'];
  suspendCourseEnrollment: CourseEnrollment;
  suspendGradingMethod: GradingMethod;
  suspendLearningUnit: LearningUnit;
  suspendQuestion: Question;
  suspendSubject: Subject;
  transferCourse: CourseEnrollment;
  updateAffiliate: Affiliate;
  updateAnnotation: Scalars['ID']['output'];
  updateAssignmentConfig: AssignmentConfig;
  updateAuthority: Authority;
  updateBanner: Banner;
  updateBell?: Maybe<Scalars['Boolean']['output']>;
  updateBrand?: Maybe<EducationProviderBrand>;
  updateCandidate: Candidate;
  updateCandidateContractInfo: Candidate;
  updateCandidateExam: CandidateExam;
  updateCandidateSellerId: Candidate;
  updateCandidateStatus: Candidate;
  updateCategorySection: CategorySection;
  updateCompany?: Maybe<EducationProviderCompany>;
  updateCoupon: Coupon;
  updateCourse: Course;
  updateCourseCategory: CourseCategory;
  updateCourseDescriptionType?: Maybe<CourseDescriptionType>;
  updateCourseEvent: CourseEvent;
  updateCourseSeo: CourseSeo;
  updateCourseType?: Maybe<CourseType>;
  updateCourseVersion: CourseVersion;
  updateCtaClickContracts?: Maybe<Scalars['Boolean']['output']>;
  updateDefaultCoursePrice?: Maybe<DefaultCoursePrice>;
  updateEducationProvider?: Maybe<EducationProvider>;
  updateEnadeStatus: EnrollmentEnadeStatus;
  updateFieldOfStudy?: Maybe<FieldOfStudy>;
  updateGradingMethod: GradingMethod;
  updateImage: Image;
  updateInstalment: Instalment;
  updateInternshipStatus: OperationStatus;
  updateInternshipTermStatus: OperationStatus;
  updateLead?: Maybe<Lead>;
  updateLeadByPartner?: Maybe<Lead>;
  updateLearningUnit: LearningUnit;
  updateLearningUnitVersion: LearningUnitVersion;
  updateLegalDocument: LegalDocument;
  updateLegalDocumentWithFile: LegalDocument;
  updateManyAnnotations: Array<Maybe<Scalars['ID']['output']>>;
  updateManyContactPermissions?: Maybe<Array<Maybe<ContactPermission>>>;
  updatePartner: Partner;
  updatePaymentPlan: PaymentPlan;
  updatePerson?: Maybe<Person>;
  updatePipelineNotificationConfig?: Maybe<PipelineNotificationConfig>;
  updatePipelineNotificationContext?: Maybe<PipelineNotificationContext>;
  updatePipelineNotificationDegreeGroup?: Maybe<PipelineNotificationDegreeGroup>;
  updateProductOffer: ProductOffer;
  updateQuestion: Question;
  updateRead?: Maybe<Scalars['Boolean']['output']>;
  updateReadList?: Maybe<Scalars['Boolean']['output']>;
  updateReason: Reason;
  updateRegulationStatus: EnrollmentRegulationStatus;
  updateRole: Role;
  updateSeller?: Maybe<Seller>;
  updateSellerCategory?: Maybe<SellerCategory>;
  updateSellerRegional?: Maybe<SellerRegional>;
  updateSellerUnit?: Maybe<SellerUnit>;
  updateSmsTemplate: SmsTemplate;
  updateStudent: Student;
  updateStudentAddresses: GetEnrollmentByIdResponse;
  updateStudentCensusInfo: Student;
  updateStudentContacts: GetEnrollmentByIdResponse;
  updateStudentOnboardingBegin?: Maybe<Scalars['Boolean']['output']>;
  updateStudentOnboardingCourse?: Maybe<Scalars['Boolean']['output']>;
  updateStudentProfile: Student;
  updateStudentRegistration: UpdateStatus;
  updateSubject: Subject;
  updateTargetGroup?: Maybe<TargetGroup>;
  updateTeacher: Scalars['Boolean']['output'];
  updateUser: User;
  updateUserPassword: Scalars['Boolean']['output'];
  uploadAssignmentFile: Scalars['Boolean']['output'];
  uploadImage: Image;
  uploadLogo?: Maybe<Logo>;
  uploadRequestedStudentDocument: Scalars['Boolean']['output'];
  uploadRequestedStudentDocumentIOS: Scalars['Boolean']['output'];
  /** @deprecated Use secretariatUploadStudentDocument instead */
  uploadStudentDocument: Scalars['Boolean']['output'];
  uploadStudentProfilePhoto: Scalars['Boolean']['output'];
  userAgreementWithOrderResults: OrderResponse;
  userInputOnOrder?: Maybe<OrderResponse>;
  validateNotificationCode: ValidationData;
  /** @deprecated Use ValidateNotificationCode mutation instead */
  validateUserSmsCode: SmsValidation;
  verifyUserPhoneNumber: PhoneNumberVerification;
  writeOffInstalment: Instalment;
};

export type MutationAbandonCourseEnrollmentArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type MutationAccreditStudentDocumentArgs = {
  accreditDocumentType: DocumentType;
  referenceDocumentType: DocumentType;
  studentId: Scalars['String']['input'];
};

export type MutationAddAttemptArgs = {
  assignment?: InputMaybe<AddAttemptInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAddLearningUnitVersionArgs = {
  input: AddLearningUnitVersionInput;
};

export type MutationAnswerCandidateExamArgs = {
  answer: Scalars['String']['input'];
  candidateId: Scalars['ID']['input'];
};

export type MutationAppendOnTrackArgs = {
  request: AppendTrackRequest;
};

export type MutationApplyCouponArgs = {
  couponCode: Scalars['String']['input'];
  paymentPlanId: Scalars['ID']['input'];
};

export type MutationApplyCouponOnNextInstalmentArgs = {
  couponCode: Scalars['String']['input'];
  paymentPlanId: Scalars['ID']['input'];
};

export type MutationApplyDiscountArgs = {
  discountInput: ApplyDiscountInput;
};

export type MutationApplyInvoiceCouponArgs = {
  couponCode: Scalars['String']['input'];
  courseEnrollmentId: Scalars['ID']['input'];
};

export type MutationApproveAcoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationApproveStudentDocumentArgs = {
  documentId: Scalars['Int']['input'];
};

export type MutationCancelCandidatePendingBoletoArgs = {
  candidateId: Scalars['ID']['input'];
  instalmentId: Scalars['ID']['input'];
};

export type MutationCancelCourseEnrollmentArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
  elaboration?: InputMaybe<Scalars['String']['input']>;
  reason: ReasonSetInput;
};

export type MutationCancelFreeTrialEnrollmentArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  enrollmentId: Scalars['ID']['input'];
  mockResultStatus?: InputMaybe<MockResultStatus>;
  reasonCode: Scalars['String']['input'];
};

export type MutationCancelInstalmentArgs = {
  instalmentId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
  reorder?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCancelOrderArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type MutationCancelPendingInstalmentArgs = {
  instalmentId: Scalars['ID']['input'];
};

export type MutationCandidatePayByNewCreditCardArgs = {
  data: CandidatePayByNewCreditCardInput;
};

export type MutationChangeBillingCycleArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  cycle: Scalars['String']['input'];
};

export type MutationChangeBillingDayArgs = {
  billingDay: Scalars['Int']['input'];
  paymentPlanId: Scalars['String']['input'];
};

export type MutationChangeGenericFieldsArgs = {
  data?: InputMaybe<ChangeGenericFieldsInput>;
};

export type MutationChangeIdentityDocumentArgs = {
  data?: InputMaybe<ChangeIdentityDocumentInput>;
};

export type MutationChangeSocialNameArgs = {
  data?: InputMaybe<ChangeSocialNameInput>;
};

export type MutationChooseSubjectElectiveArgs = {
  subjectEnrollmentId: Scalars['ID']['input'];
  subjectId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationConfirmCandidateEmailArgs = {
  email: Scalars['String']['input'];
};

export type MutationContractAgreementArgs = {
  request: ContractAgreementRequest;
};

export type MutationCreateAffiliateArgs = {
  affiliate: CreateAffiliateInput;
};

export type MutationCreateAffiliateAppointmentSessionArgs = {
  appointmentSession: CreateAffiliateAppointmentSessionInput;
};

export type MutationCreateAnnotationArgs = {
  input: CreateAnnotationInput;
};

export type MutationCreateAppointmentExamArgs = {
  appointmentExam: CreateAppointmentExamInput;
};

export type MutationCreateAssignmentArgs = {
  assignment?: InputMaybe<CreateAssignmentInput>;
};

export type MutationCreateAssignmentConfigArgs = {
  assignmentConfig?: InputMaybe<AssignmentConfigData>;
};

export type MutationCreateAuthorityArgs = {
  authority: AuthorityInput;
};

export type MutationCreateBrandArgs = {
  brand: BrandInput;
};

export type MutationCreateCandidateArgs = {
  candidate: CandidateInfoInput;
  captcha: CaptchaInput;
};

export type MutationCreateCandidateByPartnerArgs = {
  candidate: CandidateInfoInput;
};

export type MutationCreateCandidateContractArgs = {
  candidateId: Scalars['ID']['input'];
};

export type MutationCreateCandidateFromStudentArgs = {
  courseId: Scalars['ID']['input'];
};

export type MutationCreateCategorySectionArgs = {
  categorySection: CreateSectionInput;
};

export type MutationCreateCompanyArgs = {
  company: CompanyInput;
};

export type MutationCreateContentEvaluationArgs = {
  contentEvaluation: ContentEvaluationInput;
};

export type MutationCreateCouponArgs = {
  coupon: CouponInput;
};

export type MutationCreateCourseArgs = {
  course: CourseInput;
};

export type MutationCreateCourseCategoryArgs = {
  courseCategory: CourseCategoryCreateInput;
};

export type MutationCreateCourseDescriptionTypeArgs = {
  descriptionType?: InputMaybe<CourseDescriptionTypeCreate>;
};

export type MutationCreateCourseEventArgs = {
  courseEvent: CourseEventInput;
};

export type MutationCreateCourseSeoArgs = {
  courseSeo: CourseSeoCreateInput;
};

export type MutationCreateCourseTypeArgs = {
  type?: InputMaybe<CourseTypeData>;
};

export type MutationCreateEducationProviderArgs = {
  educationProvider: EducationProviderInput;
};

export type MutationCreateEnrollmentReceiptArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type MutationCreateExamArgs = {
  subjectEnrollmentId: Scalars['ID']['input'];
};

export type MutationCreateExamByAssignmentIdArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type MutationCreateFieldOfStudyArgs = {
  fieldOfStudy?: InputMaybe<FieldOfStudyData>;
};

export type MutationCreateGradingMethodArgs = {
  gradingMethod: GradingMethodData;
};

export type MutationCreateInternshipArgs = {
  companyData: CompanyDataInput;
  companyResponsible: CompanyResponsibleInput;
  fieldSupervisor: FieldSupervisorInput;
  finishDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  subjectEnrollmentId: Scalars['ID']['input'];
  weeklySchedule: WeeklyScheduleInput;
};

export type MutationCreateLeadArgs = {
  captcha: CaptchaInput;
  lead?: InputMaybe<LeadData>;
};

export type MutationCreateLeadByPartnerArgs = {
  lead?: InputMaybe<LeadData>;
};

export type MutationCreateLearningUnitArgs = {
  input: CreateLearningUnitInput;
};

export type MutationCreateLegalDocumentArgs = {
  legalDocument: LegalDocumentCreateInput;
};

export type MutationCreateLegalDocumentWithFileArgs = {
  legalDocument: LegalDocumentCreateWithFileInput;
};

export type MutationCreateManyAnnotationsArgs = {
  input: CreateManyAnnotationsInput;
};

export type MutationCreateManyAttendancesArgs = {
  data: Array<CreateAttendanceInput>;
};

export type MutationCreateManyContentEvaluationArgs = {
  contentEvaluations: Array<ContentEvaluationInput>;
};

export type MutationCreateNewCourseVersionArgs = {
  courseId: Scalars['ID']['input'];
};

export type MutationCreateNewOrderArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  orderSubject: OrderSubject;
};

export type MutationCreateOrUpdateCourseEnrollmentDegreeArgs = {
  courseEnrollmentDegree?: InputMaybe<EnrollmentDegreeInput>;
};

export type MutationCreateOrderWalletArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  nameSocial: Scalars['Boolean']['input'];
  orderSubject: OrderSubject;
  walletFile?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationCreateOrderWalletWithoutFileArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  nameSocial: Scalars['Boolean']['input'];
  orderSubject: OrderSubject;
};

export type MutationCreatePartnerArgs = {
  partner: PartnerInput;
};

export type MutationCreatePersonArgs = {
  person?: InputMaybe<PersonData>;
};

export type MutationCreatePipelineNotificationConfigArgs = {
  pipelineConfig?: InputMaybe<PipelineNotificationConfigInput>;
};

export type MutationCreatePipelineNotificationContextArgs = {
  pipelineContext?: InputMaybe<PipelineNotificationContextData>;
};

export type MutationCreatePipelineNotificationDegreeGroupArgs = {
  pipelineDegreeGroup?: InputMaybe<PipelineNotificationDegreeGroupData>;
};

export type MutationCreateProductOfferArgs = {
  productOffer: CreateProductOfferInput;
};

export type MutationCreateQuestionArgs = {
  question: QuestionInput;
};

export type MutationCreateQuestionBankTypeArgs = {
  name: Scalars['String']['input'];
};

export type MutationCreateReasonArgs = {
  reason?: InputMaybe<ReasonInput>;
};

export type MutationCreateRoleArgs = {
  role: RoleInput;
};

export type MutationCreateSellerArgs = {
  seller?: InputMaybe<SellerData>;
};

export type MutationCreateSellerCategoryArgs = {
  category?: InputMaybe<SellerCategoryData>;
};

export type MutationCreateSellerPublicArgs = {
  captcha?: InputMaybe<CaptchaInput>;
  seller?: InputMaybe<SellerData>;
};

export type MutationCreateSellerRegionalArgs = {
  regional?: InputMaybe<SellerRegionalData>;
};

export type MutationCreateSellerUnitArgs = {
  unit?: InputMaybe<SellerUnitData>;
};

export type MutationCreateSmsTemplateArgs = {
  smsTemplate: SmsTemplateInput;
};

export type MutationCreateStudentArgs = {
  student: StudentInput;
};

export type MutationCreateSubjectArgs = {
  subject: SubjectInput;
};

export type MutationCreateTargetGroupArgs = {
  targetGroup: TargetGroupInput;
};

export type MutationCreateTeacherArgs = {
  data: TeacherInput;
};

export type MutationCreateUserArgs = {
  user: UserInput;
};

export type MutationCreateWhitelistArgs = {
  deviceId: Scalars['String']['input'];
  deviceType: DeviceType;
  username: Scalars['String']['input'];
  validationId: Scalars['String']['input'];
};

export type MutationDeleteAffiliateArgs = {
  affiliateId: Scalars['ID']['input'];
};

export type MutationDeleteAnnotationArgs = {
  annotationId: Scalars['ID']['input'];
};

export type MutationDeleteAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteAssignmentConfigArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteAuthorityArgs = {
  name: Scalars['ID']['input'];
};

export type MutationDeleteBrandArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCandidateArgs = {
  candidateId: Scalars['ID']['input'];
};

export type MutationDeleteCandidateExamArgs = {
  candidateExamId: Scalars['ID']['input'];
};

export type MutationDeleteCategorySectionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCompanyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCouponArgs = {
  couponCode: Scalars['ID']['input'];
};

export type MutationDeleteCourseArgs = {
  courseId: Scalars['ID']['input'];
};

export type MutationDeleteCourseCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCourseDescriptionTypeArgs = {
  code?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteCourseEventArgs = {
  eventId: Scalars['ID']['input'];
};

export type MutationDeleteCourseSeoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCourseTypeArgs = {
  code?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteCourseVersionArgs = {
  courseVersionId: Scalars['ID']['input'];
};

export type MutationDeleteEducationProviderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFieldOfStudyArgs = {
  code?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteGradingMethodArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteImageArgs = {
  imageId: Scalars['ID']['input'];
};

export type MutationDeleteLeadArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteLearningUnitArgs = {
  learningUnitId: Scalars['ID']['input'];
};

export type MutationDeleteLegalDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteManyAnnotationsArgs = {
  input: DeleteManyAnnotationsInput;
};

export type MutationDeletePartnerArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePersonArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeletePipelineNotificationConfigArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeletePipelineNotificationContextArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeletePipelineNotificationDegreeGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteProductOfferArgs = {
  productOfferId: Scalars['ID']['input'];
};

export type MutationDeleteQuestionArgs = {
  questionId: Scalars['ID']['input'];
};

export type MutationDeleteRoleArgs = {
  name: Scalars['ID']['input'];
};

export type MutationDeleteSellerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteSellerCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteSellerRegionalArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteSellerUnitArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteSmsTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteStudentArgs = {
  studentId: Scalars['ID']['input'];
};

export type MutationDeleteStudentCreditCardArgs = {
  studentCreditCardId: Scalars['ID']['input'];
};

export type MutationDeleteSubjectArgs = {
  subjectId: Scalars['ID']['input'];
};

export type MutationDeleteTargetGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteTeacherArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationDisableCreditCardRecurrenceArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type MutationDisableRecurringPaymentArgs = {
  courseId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type MutationDisableRecurringPaymentByEnrollmentIdArgs = {
  enrollmentId: Scalars['String']['input'];
};

export type MutationDropCourseEnrollmentArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type MutationEnableRecurringPaymentArgs = {
  recurring?: InputMaybe<EnableRecurringPaymentInput>;
};

export type MutationEnableRecurringPaymentByEnrollmentIdArgs = {
  recurring?: InputMaybe<EnableRecurringPaymentByEnrollmentIdInput>;
};

export type MutationEvaluateCandidateEnemArgs = {
  candidateEnemEvaluation: CandidateEnemEvaluationInput;
};

export type MutationEvaluateCandidateExamArgs = {
  candidateExamEvaluation: CandidateExamEvaluationInput;
};

export type MutationEvaluateCandidateExamFromPartnerArgs = {
  correction: CorrectedExamDto;
  subscriptionId: Scalars['ID']['input'];
};

export type MutationFinishCourseEnrollmentArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type MutationFinishExamArgs = {
  finishExamInput?: InputMaybe<FinishExamInput>;
};

export type MutationFinishPresentialExamArgs = {
  finishExamInput?: InputMaybe<FinishExamInput>;
};

export type MutationGenerateBoletoFileArgs = {
  enrollmentId: Scalars['String']['input'];
  instalmentId: Scalars['ID']['input'];
};

export type MutationGenerateIncomeTaxDocumentArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  year: Scalars['String']['input'];
};

export type MutationGenerateIncomeTaxDocumentUrlArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  year: Scalars['String']['input'];
};

export type MutationGetInvoiceDocumentArgs = {
  enrollmentId: Scalars['String']['input'];
  instalmentId: Scalars['String']['input'];
};

export type MutationInsertRemainInstalmentsArgs = {
  paymentPlanId: Scalars['ID']['input'];
};

export type MutationLoginAmpliArgs = {
  credentials: Credentials;
};

export type MutationLoginCandidateArgs = {
  credentials: Credentials;
};

export type MutationLoginPartnerArgs = {
  credentials: Credentials;
};

export type MutationLoginSellerArgs = {
  credentials: Credentials;
};

export type MutationLoginStudentArgs = {
  credentials: Credentials;
};

export type MutationNotifyStudentByTypeArgs = {
  notificationOption: NotificationOption;
  notificationType: NotificationType;
  userId: Scalars['ID']['input'];
};

export type MutationPatchOpenNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPayCandidateInstalmentByBoletoArgs = {
  data: CandidateBoletoPaymentInput;
};

export type MutationPayInstalmentByBoletoArgs = {
  boleto: BoletoPaymentInput;
};

export type MutationPayInstalmentByExistingCreditCardArgs = {
  creditCard: ExistingCreditCardPaymentInput;
};

export type MutationPayInstalmentByNewCreditCardArgs = {
  creditCard: NewCreditCardPaymentInput;
};

export type MutationPayInvoiceByBoletoArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
};

export type MutationPayInvoiceByCreditCardArgs = {
  input: InvoiceNewCreditCardPaymentInput;
};

export type MutationPayInvoiceByExistingCreditCardArgs = {
  input: InvoiceExistingCreditCardPaymentInput;
};

export type MutationPayInvoiceByPixArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
};

export type MutationPublishCourseArgs = {
  courseId: Scalars['ID']['input'];
};

export type MutationPublishEducationProviderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPublishGradingMethodArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPublishLearningUnitArgs = {
  learningUnitId: Scalars['ID']['input'];
};

export type MutationPublishLearningUnitVersionArgs = {
  learningUnitVersionId: Scalars['ID']['input'];
};

export type MutationPublishNewCourseVersionArgs = {
  courseId: Scalars['ID']['input'];
};

export type MutationPublishQuestionArgs = {
  questionId: Scalars['ID']['input'];
};

export type MutationPublishSubjectArgs = {
  subjectId: Scalars['ID']['input'];
};

export type MutationPushNotificationDeleteArgs = {
  data?: InputMaybe<PushNotificationDeleteInput>;
};

export type MutationPushNotificationSaveArgs = {
  data?: InputMaybe<PushNotificationSaveInput>;
};

export type MutationRateArticleArgs = {
  rating?: InputMaybe<RateArticleInput>;
};

export type MutationRedoCandidateExamArgs = {
  candidateId: Scalars['ID']['input'];
};

export type MutationRegisterProductInterestArgs = {
  request: RegisterProductInterestRequest;
};

export type MutationRejectStudentDocumentArgs = {
  description: Scalars['String']['input'];
  documentId: Scalars['Int']['input'];
  guidance: Scalars['String']['input'];
};

export type MutationRemoveAppointmentExamArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveCreditCardArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  creditCardId: Scalars['ID']['input'];
};

export type MutationRemoveLearningUnitVersionArgs = {
  input: RemoveLearningUnitVersionInput;
};

export type MutationRemoveStudentProfilePhotoArgs = {
  studentId: Scalars['ID']['input'];
};

export type MutationRemoveWhitelistArgs = {
  deviceId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationReopenInstalmentArgs = {
  instalmentId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
  removeCoupon?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationReopenOrderArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  reopenFile?: InputMaybe<Scalars['Upload']['input']>;
  ticketNumber: Scalars['String']['input'];
};

export type MutationReopenOrderWithoutFileArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  ticketNumber: Scalars['String']['input'];
};

export type MutationReprocessSendQuotationToSapArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  paymentPlanId: Scalars['ID']['input'];
  removeCoupons?: InputMaybe<Scalars['Boolean']['input']>;
  removeInstalments?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationReproveAcoArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRequestChangeCycleStatusArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  cycle: Scalars['String']['input'];
};

export type MutationRequestProgramContentArgs = {
  data?: InputMaybe<RequestProgramContent>;
};

export type MutationResetCandidateEnemOptionArgs = {
  candidateId: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  validationId: Scalars['ID']['input'];
};

export type MutationResetUserPasswordArgs = {
  password: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  validationId: Scalars['ID']['input'];
};

export type MutationResumeCourseEnrollmentArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type MutationRetryPipelineNotificationExecutionArgs = {
  id: Scalars['String']['input'];
};

export type MutationSaveEnadeStatusArgs = {
  enadeStatus: EnadeStatusInput;
};

export type MutationSaveRegulationStatusArgs = {
  sendStatus: SendStatusInput;
};

export type MutationSaveTextCustomizationArgs = {
  textCustomization: TextCustomizationInput;
};

export type MutationSecretariatRemoveStudentProfilePhotoArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type MutationSecretariatUploadStudentProfilePhotoArgs = {
  photo: Scalars['Upload']['input'];
};

export type MutationSendAcoArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
  data: AcoDataInput;
  files: Array<Scalars['Upload']['input']>;
};

export type MutationSendAssignmentFileFeedbackArgs = {
  assignmentFileId: Scalars['Int']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AssignmentFileStatus>;
};

export type MutationSignCandidateContractArgs = {
  candidateContract: SignCandidateContractInput;
};

export type MutationSofiaChangePasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
  verificationId: Scalars['String']['input'];
};

export type MutationSofiaLoginUserArgs = {
  deviceId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationSofiaNotifyStudentByTypeArgs = {
  notificationOption: NotificationOption;
  notificationType: NotificationType;
  username: Scalars['String']['input'];
};

export type MutationSofiaValidateNotificationCodeArgs = {
  code: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationSuspendCourseEnrollmentArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
  elaboration?: InputMaybe<Scalars['String']['input']>;
  reason: ReasonSetInput;
  suspensionPeriod: SuspensionPeriodInput;
};

export type MutationSuspendGradingMethodArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSuspendLearningUnitArgs = {
  learningUnitId: Scalars['ID']['input'];
};

export type MutationSuspendQuestionArgs = {
  questionId: Scalars['ID']['input'];
};

export type MutationSuspendSubjectArgs = {
  subjectId: Scalars['ID']['input'];
};

export type MutationTransferCourseArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
  targetCourseId: Scalars['ID']['input'];
};

export type MutationUpdateAffiliateArgs = {
  affiliate: UpdateAffiliateInput;
};

export type MutationUpdateAnnotationArgs = {
  input: UpdateAnnotationInput;
};

export type MutationUpdateAssignmentConfigArgs = {
  assignmentConfig?: InputMaybe<AssignmentConfigData>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateAuthorityArgs = {
  authority: AuthorityInput;
};

export type MutationUpdateBannerArgs = {
  banner: BannerUpdateInput;
};

export type MutationUpdateBellArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateBrandArgs = {
  brand: BrandInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateCandidateArgs = {
  candidate: UpdateCandidateInput;
};

export type MutationUpdateCandidateContractInfoArgs = {
  candidateContractInfo: UpdateCandidateContractInfoInput;
};

export type MutationUpdateCandidateExamArgs = {
  candidateExam: UpdateCandidateExamInput;
};

export type MutationUpdateCandidateSellerIdArgs = {
  candidateId: Scalars['ID']['input'];
  sellerId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateCandidateStatusArgs = {
  data: CandidatePaymentInput;
};

export type MutationUpdateCategorySectionArgs = {
  categorySection: UpdateSectionInput;
};

export type MutationUpdateCompanyArgs = {
  company: CompanyInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateCouponArgs = {
  coupon: CouponInput;
  couponCode: Scalars['ID']['input'];
};

export type MutationUpdateCourseArgs = {
  course: CourseInput;
  courseId: Scalars['ID']['input'];
};

export type MutationUpdateCourseCategoryArgs = {
  courseCategory: CourseCategoryUpdateInput;
};

export type MutationUpdateCourseDescriptionTypeArgs = {
  code?: InputMaybe<Scalars['ID']['input']>;
  descriptionType?: InputMaybe<CourseDescriptionTypeUpdate>;
};

export type MutationUpdateCourseEventArgs = {
  courseEvent: CourseEventInput;
  eventId: Scalars['ID']['input'];
};

export type MutationUpdateCourseSeoArgs = {
  courseSeo: CourseSeoUpdateInput;
};

export type MutationUpdateCourseTypeArgs = {
  code?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<CourseTypeData>;
};

export type MutationUpdateCourseVersionArgs = {
  courseVersion: CourseVersionInput;
  courseVersionId: Scalars['ID']['input'];
};

export type MutationUpdateCtaClickContractsArgs = {
  data?: InputMaybe<CtaClickContractInput>;
};

export type MutationUpdateDefaultCoursePriceArgs = {
  defaultCoursePrice?: InputMaybe<DefaultCoursePriceInput>;
  id: Scalars['ID']['input'];
};

export type MutationUpdateEducationProviderArgs = {
  educationProvider: EducationProviderInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateEnadeStatusArgs = {
  enadeStatusInput: EnadeStatusInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateFieldOfStudyArgs = {
  code?: InputMaybe<Scalars['ID']['input']>;
  fieldOfStudy?: InputMaybe<FieldOfStudyData>;
};

export type MutationUpdateGradingMethodArgs = {
  gradingMethod: GradingMethodData;
  id: Scalars['ID']['input'];
};

export type MutationUpdateImageArgs = {
  descriptionText?: InputMaybe<Scalars['String']['input']>;
  documentFile?: InputMaybe<Scalars['Upload']['input']>;
  imageId: Scalars['ID']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationUpdateInstalmentArgs = {
  instalmentId: Scalars['ID']['input'];
  instalmentInput: UpdateInstalmentInput;
};

export type MutationUpdateInternshipStatusArgs = {
  activities?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  status: MandatoryInternshipStatus;
};

export type MutationUpdateInternshipTermStatusArgs = {
  assignmentFileId: Scalars['Int']['input'];
  assignmentId: Scalars['String']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
  mandatoryInternshipId: Scalars['String']['input'];
  status: AssignmentFileStatus;
};

export type MutationUpdateLeadArgs = {
  captcha: CaptchaInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  lead?: InputMaybe<LeadData>;
};

export type MutationUpdateLeadByPartnerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  lead?: InputMaybe<LeadData>;
};

export type MutationUpdateLearningUnitArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLearningUnitInput;
};

export type MutationUpdateLearningUnitVersionArgs = {
  learningUnitVersion: LearningUnitVersionInput;
  learningUnitVersionId: Scalars['ID']['input'];
};

export type MutationUpdateLegalDocumentArgs = {
  legalDocument: LegalDocumentUpdateInput;
};

export type MutationUpdateLegalDocumentWithFileArgs = {
  legalDocument: LegalDocumentUpdateWithFileInput;
};

export type MutationUpdateManyAnnotationsArgs = {
  input: UpdateManyAnnotationsInput;
};

export type MutationUpdateManyContactPermissionsArgs = {
  contactPermissions?: InputMaybe<Array<InputMaybe<ContactPermissionInput>>>;
};

export type MutationUpdatePartnerArgs = {
  id: Scalars['ID']['input'];
  partner: PartnerInput;
};

export type MutationUpdatePaymentPlanArgs = {
  paymentPlan: PaymentPlanInput;
  paymentPlanId: Scalars['ID']['input'];
};

export type MutationUpdatePersonArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  person?: InputMaybe<PersonData>;
};

export type MutationUpdatePipelineNotificationConfigArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pipelineConfig?: InputMaybe<PipelineNotificationConfigInput>;
};

export type MutationUpdatePipelineNotificationContextArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pipelineContext?: InputMaybe<PipelineNotificationContextData>;
};

export type MutationUpdatePipelineNotificationDegreeGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pipelineDegreeGroup?: InputMaybe<PipelineNotificationDegreeGroupData>;
};

export type MutationUpdateProductOfferArgs = {
  productOffer: UpdateProductOfferInput;
};

export type MutationUpdateQuestionArgs = {
  questionId: Scalars['ID']['input'];
  questionInput: QuestionInput;
};

export type MutationUpdateReadArgs = {
  read?: InputMaybe<ReadInput>;
};

export type MutationUpdateReadListArgs = {
  read?: InputMaybe<ReadListInput>;
};

export type MutationUpdateReasonArgs = {
  code?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<ReasonInput>;
};

export type MutationUpdateRegulationStatusArgs = {
  id: Scalars['ID']['input'];
  sendStatus: SendStatusInput;
};

export type MutationUpdateRoleArgs = {
  role: UpdateRoleInput;
};

export type MutationUpdateSellerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  seller?: InputMaybe<UpdateSellerData>;
};

export type MutationUpdateSellerCategoryArgs = {
  category?: InputMaybe<SellerCategoryData>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateSellerRegionalArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  regional?: InputMaybe<SellerRegionalData>;
};

export type MutationUpdateSellerUnitArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  unit?: InputMaybe<SellerUnitData>;
};

export type MutationUpdateSmsTemplateArgs = {
  id: Scalars['ID']['input'];
  smsTemplate: SmsTemplateInput;
};

export type MutationUpdateStudentArgs = {
  student: StudentInput;
  studentId: Scalars['ID']['input'];
};

export type MutationUpdateStudentAddressesArgs = {
  data: StudentAddressInput;
};

export type MutationUpdateStudentCensusInfoArgs = {
  input?: InputMaybe<StudentCensusUpdateInput>;
  studentId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateStudentContactsArgs = {
  data: StudentContactInput;
};

export type MutationUpdateStudentOnboardingBeginArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateStudentOnboardingCourseArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateStudentProfileArgs = {
  input: StudentProfileUpdateInput;
  studentId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateStudentRegistrationArgs = {
  data: StudentRegistrationInput;
};

export type MutationUpdateSubjectArgs = {
  subject: SubjectInput;
  subjectId: Scalars['ID']['input'];
};

export type MutationUpdateTargetGroupArgs = {
  id: Scalars['ID']['input'];
  targetGroup: TargetGroupInput;
};

export type MutationUpdateTeacherArgs = {
  data: TeacherInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUserArgs = {
  user: UpdateUserInput;
};

export type MutationUpdateUserPasswordArgs = {
  id: Scalars['ID']['input'];
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type MutationUploadAssignmentFileArgs = {
  assignmentFile: Scalars['Upload']['input'];
  assignmentFileType: AssignmentFileType;
  assignmentId: Scalars['String']['input'];
};

export type MutationUploadImageArgs = {
  descriptionText?: InputMaybe<Scalars['String']['input']>;
  documentFile: Scalars['Upload']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationUploadLogoArgs = {
  documentFile: Scalars['Upload']['input'];
};

export type MutationUploadRequestedStudentDocumentArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
  document: Array<RequestedStudentDocumentUpload>;
  type: Scalars['String']['input'];
};

export type MutationUploadRequestedStudentDocumentIosArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
  files: Array<Scalars['Upload']['input']>;
  subTypeIds: Array<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type MutationUploadStudentDocumentArgs = {
  documentFile: Scalars['Upload']['input'];
  documentType: DocumentType;
};

export type MutationUploadStudentProfilePhotoArgs = {
  photo: Scalars['Upload']['input'];
};

export type MutationUserAgreementWithOrderResultsArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type MutationUserInputOnOrderArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  file?: InputMaybe<Scalars['Upload']['input']>;
  inputStrategy: OrderUserInputStrategy;
  orderId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type MutationValidateNotificationCodeArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationValidateUserSmsCodeArgs = {
  phoneNumberVerification: PhoneNumberVerificationInput;
  userId: Scalars['ID']['input'];
};

export type MutationVerifyUserPhoneNumberArgs = {
  captcha: CaptchaInput;
  userId: Scalars['ID']['input'];
};

export type MutationWriteOffInstalmentArgs = {
  input: WriteOffInstalmentInput;
};

export type MyOrdersInputconfigResponse = {
  __typename?: 'MyOrdersInputconfigResponse';
  detailmentCharacterLimit: Scalars['Int']['output'];
  documentSizeLimit: Scalars['Int']['output'];
  supportedDocumentExtensions: Array<Scalars['String']['output']>;
};

export type NationalIdentityInput = {
  birthDate: Scalars['String']['input'];
  fatherName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  issuanceDate: Scalars['String']['input'];
  issuingAuthority: Scalars['String']['input'];
  motherName?: InputMaybe<Scalars['String']['input']>;
  /** state abbrebiation, example (SP, BA) */
  state: Scalars['String']['input'];
};

export enum NationalIdentityType {
  Cnh = 'CNH',
  Rg = 'RG',
  Rne = 'RNE',
}

export type NegotiationResponse = {
  __typename?: 'NegotiationResponse';
  url: Scalars['String']['output'];
};

export type NewCreditCardPaymentInput = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  creditCard: CreditCardInput;
  enrollmentId?: InputMaybe<Scalars['String']['input']>;
  instalmentId: Scalars['ID']['input'];
  makeRecurring: Scalars['Boolean']['input'];
  saveForFutureUse: Scalars['Boolean']['input'];
};

export type NewVersion = {
  __typename?: 'NewVersion';
  learningUnitVersionId: Scalars['String']['output'];
  number: Scalars['Int']['output'];
};

export type NotificationCategorization = {
  __typename?: 'NotificationCategorization';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type NotificationCenter = {
  __typename?: 'NotificationCenter';
  body: Scalars['String']['output'];
  communication?: Maybe<Communication>;
  id: Scalars['ID']['output'];
  notificationDate: Scalars['String']['output'];
  notificationSubCategorization: NotificationSubCategorization;
  studentId: Scalars['String']['output'];
  styleTemplateNotification: StyleTemplateNotification;
  title: Scalars['String']['output'];
  visualization: Scalars['Boolean']['output'];
};

export enum NotificationContext {
  NotificationContextCritical = 'NOTIFICATION_CONTEXT_CRITICAL',
  NotificationContextCx = 'NOTIFICATION_CONTEXT_CX',
  NotificationContextCxOnboarding = 'NOTIFICATION_CONTEXT_CX_ONBOARDING',
  NotificationContextGrowthExternal = 'NOTIFICATION_CONTEXT_GROWTH_EXTERNAL',
  NotificationContextGrowthFunnel = 'NOTIFICATION_CONTEXT_GROWTH_FUNNEL',
  NotificationContextInternal = 'NOTIFICATION_CONTEXT_INTERNAL',
}

export type NotificationCreative = {
  __typename?: 'NotificationCreative';
  bell?: Maybe<Scalars['Boolean']['output']>;
  creative?: Maybe<Array<Maybe<Creative>>>;
};

export enum NotificationOption {
  DeviceCreation = 'DEVICE_CREATION',
  Recovery = 'RECOVERY',
  Validation = 'VALIDATION',
}

export type NotificationResponse = {
  __typename?: 'NotificationResponse';
  body?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  notificationSubCategorization: NotificationSubCategorization;
  styleTemplateNotification: StyleTemplateNotification;
  subTitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  visualization: Scalars['Boolean']['output'];
};

export enum NotificationStatus {
  Expired = 'EXPIRED',
  Invalid = 'INVALID',
  Valid = 'VALID',
}

export type NotificationSubCategorization = {
  __typename?: 'NotificationSubCategorization';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  notificationCategorization: NotificationCategorization;
};

export enum NotificationType {
  Email = 'EMAIL',
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP',
}

export type OperationStatus = {
  __typename?: 'OperationStatus';
  success: Scalars['Boolean']['output'];
};

export type OptIn = {
  __typename?: 'OptIn';
  code: Scalars['ID']['output'];
  description: Scalars['String']['output'];
};

export type OptInInput = {
  code: Scalars['ID']['input'];
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderFile = {
  __typename?: 'OrderFile';
  date: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  agree?: Maybe<Scalars['Boolean']['output']>;
  csatEvaluationLink?: Maybe<Scalars['String']['output']>;
  csatEvaluationLinkResponse?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documentValidity?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<Maybe<OrderFile>>>;
  history?: Maybe<Array<Maybe<HistoryResponse>>>;
  id?: Maybe<Scalars['ID']['output']>;
  orderUrl?: Maybe<Scalars['String']['output']>;
  reopenedBlock?: Maybe<Scalars['Boolean']['output']>;
  requestDate?: Maybe<Scalars['String']['output']>;
  serviceForecast?: Maybe<Scalars['String']['output']>;
  serviceOrigin?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OrderStatus>;
  subject?: Maybe<Scalars['String']['output']>;
  ticketNumber?: Maybe<Scalars['String']['output']>;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Finished = 'FINISHED',
  FinishedNotReopen = 'FINISHED_NOT_REOPEN',
  NotFound = 'NOT_FOUND',
  Processing = 'PROCESSING',
  Requested = 'REQUESTED',
  StudentPending = 'STUDENT_PENDING',
}

export enum OrderSubject {
  CertificateOfEnrollment = 'CERTIFICATE_OF_ENROLLMENT',
  Curriculum = 'CURRICULUM',
  EnrollmentLink = 'ENROLLMENT_LINK',
  LittleWallet = 'LITTLE_WALLET',
  ProgramContent = 'PROGRAM_CONTENT',
  Transcript = 'TRANSCRIPT',
}

export enum OrderUserInputStrategy {
  Attachment = 'ATTACHMENT',
  OrderPending = 'ORDER_PENDING',
  Reopen = 'REOPEN',
}

export type OrderWalletResponse = {
  __typename?: 'OrderWalletResponse';
  description?: Maybe<Scalars['String']['output']>;
  documentValidity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  orderUrl?: Maybe<Scalars['String']['output']>;
  requestDate?: Maybe<Scalars['String']['output']>;
  serviceOrigin?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OrderStatus>;
  subject?: Maybe<Scalars['String']['output']>;
  ticketNumber?: Maybe<Scalars['String']['output']>;
};

export type Page = {
  __typename?: 'Page';
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Pagination = {
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
};

export type Partner = {
  __typename?: 'Partner';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  logo?: Maybe<Image>;
  logoId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PartnerInput = {
  description: Scalars['String']['input'];
  logoId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type PartnerList = {
  __typename?: 'PartnerList';
  data?: Maybe<Array<Maybe<Partner>>>;
  page?: Maybe<Page>;
};

export type PassedSubject = {
  __typename?: 'PassedSubject';
  isEquivalent?: Maybe<Scalars['Boolean']['output']>;
  sourceCourse: Course;
  sourceCourseId: Scalars['ID']['output'];
  sourceCourseVersionId: Scalars['ID']['output'];
  sourceSubject: Subject;
  sourceSubjectId: Scalars['ID']['output'];
  targetSubject: Subject;
  targetSubjectId: Scalars['ID']['output'];
};

export type PasswordPolicies = {
  __typename?: 'PasswordPolicies';
  policy: Scalars['String']['output'];
  rules: Array<PasswordPolicyRule>;
};

export type PasswordPolicyRule = {
  __typename?: 'PasswordPolicyRule';
  description: Scalars['String']['output'];
  rule: Scalars['String']['output'];
};

export enum PaymentClient {
  CognaPay = 'COGNA_PAY',
  Mundipagg = 'MUNDIPAGG',
}

export enum PaymentClientCreditCard {
  CognaPay = 'COGNA_PAY',
  Mundipagg = 'MUNDIPAGG',
}

export enum PaymentCreditCardStatusResult {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

export enum PaymentMethod {
  Boleto = 'BOLETO',
  CreditCard = 'CREDIT_CARD',
  Exemption = 'EXEMPTION',
  Other = 'OTHER',
  Pix = 'PIX',
}

export type PaymentPixStatusResult = {
  __typename?: 'PaymentPixStatusResult';
  code: Scalars['String']['output'];
  pixImage: Scalars['String']['output'];
  receivedPayment: Scalars['Boolean']['output'];
};

export type PaymentPlan = {
  __typename?: 'PaymentPlan';
  amount: Scalars['Money']['output'];
  billingDay: Scalars['Int']['output'];
  contractErpCode?: Maybe<Scalars['String']['output']>;
  contractIdErpCode?: Maybe<Scalars['String']['output']>;
  course: Course;
  courseId: Scalars['String']['output'];
  /** @deprecated Use pendingInstalments */
  currentInstalment?: Maybe<Instalment>;
  enrollmentId: Scalars['String']['output'];
  erpPaymentReference?: Maybe<Scalars['String']['output']>;
  financialStatus?: Maybe<FinancialStatus>;
  /** @deprecated Use pendingInstalments */
  futureInstalments: Array<Instalment>;
  id: Scalars['ID']['output'];
  instalments: Array<Instalment>;
  invoiceBlock?: Maybe<InvoiceBlock>;
  /** @deprecated Use role BILLING_CYCLE_UPDATE */
  isErpActiveUser?: Maybe<Scalars['Boolean']['output']>;
  isErpUser?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use role BILLING_CYCLE_UPDATE */
  isErpUserAptToChangeBillingDay?: Maybe<Scalars['Boolean']['output']>;
  numberOfInstalments: Scalars['Int']['output'];
  /** @deprecated Use pendingInstalments */
  overdueInstalments: Array<Instalment>;
  paidInstalments: Array<Instalment>;
  paymentMethod: PaymentMethod;
  pendingInstalments: Array<Instalment>;
  /** @deprecated Use paidInstalments */
  previousInstalments: Array<Instalment>;
  quotationErpCode?: Maybe<Scalars['String']['output']>;
  recurring?: Maybe<Scalars['Boolean']['output']>;
  recurringChargeCreditCard?: Maybe<CreditCard>;
  recurringChargeCreditCardId?: Maybe<Scalars['String']['output']>;
  student: Student;
  studentId: Scalars['String']['output'];
};

export type PaymentPlanInput = {
  billingDay: Scalars['Int']['input'];
  contractErpCode?: InputMaybe<Scalars['String']['input']>;
  contractIdErpCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  recurring: Scalars['Boolean']['input'];
};

export type PaymentStatementItem = {
  __typename?: 'PaymentStatementItem';
  id: Scalars['ID']['output'];
  invoiceId: Scalars['String']['output'];
  paidAmount: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['String']['output']>;
  paymentMethod: InvoicePaymentMethod;
  title: Scalars['String']['output'];
};

export type PaymentWarning = {
  __typename?: 'PaymentWarning';
  message: Scalars['String']['output'];
  type: PaymentWarningType;
};

export enum PaymentWarningType {
  Danger = 'DANGER',
  Info = 'INFO',
  Warning = 'WARNING',
}

export type PendingApprovalAcoResponse = {
  __typename?: 'PendingApprovalACOResponse';
  data?: Maybe<Array<Maybe<PendingApprovalAcoSummary>>>;
  page?: Maybe<Page>;
};

export type PendingApprovalAcoSummary = {
  __typename?: 'PendingApprovalACOSummary';
  activities: Scalars['Int']['output'];
  course: Course;
  courseEnrollmentId: Scalars['ID']['output'];
  duration: Scalars['Int']['output'];
  estimatedEndDate?: Maybe<Scalars['String']['output']>;
  student: Student;
};

export type PendingDocumentsSummary = {
  __typename?: 'PendingDocumentsSummary';
  document?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  pending?: Maybe<Scalars['Int']['output']>;
};

export type PendingDocumentsSummaryPage = {
  __typename?: 'PendingDocumentsSummaryPage';
  data?: Maybe<Array<Maybe<PendingDocumentsSummary>>>;
  page?: Maybe<Page>;
};

export type Person = {
  __typename?: 'Person';
  address?: Maybe<Address>;
  birthDate: Scalars['String']['output'];
  cityOfBirthId?: Maybe<Scalars['Int']['output']>;
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  document: Scalars['String']['output'];
  email: Scalars['String']['output'];
  fatherName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  guardianName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  issuingAuthority?: Maybe<Scalars['String']['output']>;
  maritalStatus?: Maybe<MaritalStatus>;
  motherName?: Maybe<Scalars['String']['output']>;
  nationalIdentity?: Maybe<Scalars['String']['output']>;
  nationalIdentityStateId?: Maybe<Scalars['Int']['output']>;
  phoneNumber: Scalars['String']['output'];
  stateOfBirthId?: Maybe<Scalars['Int']['output']>;
};

export type PersonData = {
  address?: InputMaybe<AddressInput>;
  birthDate: Scalars['String']['input'];
  cityOfBirthId?: InputMaybe<Scalars['Int']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  document: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fatherName?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  guardianName?: InputMaybe<Scalars['String']['input']>;
  issuingAuthority?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  motherName?: InputMaybe<Scalars['String']['input']>;
  nationalIdentity?: InputMaybe<Scalars['String']['input']>;
  nationalIdentityStateId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber: Scalars['String']['input'];
  stateOfBirthId?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonList = {
  __typename?: 'PersonList';
  data?: Maybe<Array<Maybe<Person>>>;
  page?: Maybe<Page>;
};

export type Phone = {
  __typename?: 'Phone';
  areaCode: Scalars['String']['output'];
  countryCode?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
};

export type PhoneNumberVerification = {
  __typename?: 'PhoneNumberVerification';
  verificationId: Scalars['ID']['output'];
};

export type PhoneNumberVerificationInput = {
  code: Scalars['String']['input'];
  verificationId: Scalars['ID']['input'];
};

export type PipelineNotificationConfig = {
  __typename?: 'PipelineNotificationConfig';
  channel: Channel;
  contextId: Scalars['ID']['output'];
  degreeGroupId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  queryParameterList?: Maybe<Array<PipelineNotificationQueryParameter>>;
  schedule: PipelineNotificationSchedule;
  status: PipelineNotificationConfigStatus;
  targetGroupId: Scalars['ID']['output'];
  templateDataList?: Maybe<Array<PipelineNotificationTemplateData>>;
  templateId: Scalars['String']['output'];
};

export type PipelineNotificationConfigFilterInput = {
  channel?: InputMaybe<Channel>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PipelineNotificationConfigStatus>;
  target_group_id?: InputMaybe<Scalars['ID']['input']>;
};

export type PipelineNotificationConfigInput = {
  channel: Channel;
  contextId: Scalars['ID']['input'];
  degreeGroupId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  queryParameterList?: InputMaybe<
    Array<PipelineNotificationQueryParameterInput>
  >;
  schedule: PipelineNotificationScheduleInput;
  status: PipelineNotificationConfigStatus;
  targetGroupId: Scalars['ID']['input'];
  templateDataList?: InputMaybe<Array<PipelineNotificationTemplateDataInput>>;
  templateId: Scalars['String']['input'];
};

export type PipelineNotificationConfigList = {
  __typename?: 'PipelineNotificationConfigList';
  data?: Maybe<Array<Maybe<PipelineNotificationConfig>>>;
  page?: Maybe<Page>;
};

export enum PipelineNotificationConfigStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type PipelineNotificationContext = {
  __typename?: 'PipelineNotificationContext';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  notificationContext: NotificationContext;
};

export type PipelineNotificationContextData = {
  name: Scalars['String']['input'];
  notificationContext: NotificationContext;
};

export type PipelineNotificationContextList = {
  __typename?: 'PipelineNotificationContextList';
  data?: Maybe<Array<Maybe<PipelineNotificationContext>>>;
  page?: Maybe<Page>;
};

export type PipelineNotificationDegreeGroup = {
  __typename?: 'PipelineNotificationDegreeGroup';
  degreeList: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PipelineNotificationDegreeGroupData = {
  degreeList: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type PipelineNotificationDegreeGroupList = {
  __typename?: 'PipelineNotificationDegreeGroupList';
  data?: Maybe<Array<Maybe<PipelineNotificationDegreeGroup>>>;
  page?: Maybe<Page>;
};

export type PipelineNotificationExecution = {
  __typename?: 'PipelineNotificationExecution';
  channel: Channel;
  createdDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastModifiedDate: Scalars['String']['output'];
  pipelineNotificationConfigId: Scalars['String']['output'];
  queryExecutionId?: Maybe<Scalars['String']['output']>;
  queryParameters?: Maybe<Scalars['String']['output']>;
  queryResultUrl?: Maybe<Scalars['String']['output']>;
  status: PipelineNotificationExecutionStatus;
};

export type PipelineNotificationExecutionList = {
  __typename?: 'PipelineNotificationExecutionList';
  data?: Maybe<Array<Maybe<PipelineNotificationExecution>>>;
  page?: Maybe<Page>;
};

export enum PipelineNotificationExecutionStatus {
  Error = 'ERROR',
  Finished = 'FINISHED',
  Started = 'STARTED',
}

export type PipelineNotificationQueryParameter = {
  __typename?: 'PipelineNotificationQueryParameter';
  id: Scalars['ID']['output'];
  pipelineNotificationConfigId: Scalars['ID']['output'];
  targetGroupQueryParameter: TargetGroupQueryParameter;
  targetGroupQueryParameterId: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type PipelineNotificationQueryParameterInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pipelineNotificationConfigId?: InputMaybe<Scalars['ID']['input']>;
  targetGroupQueryParameterId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type PipelineNotificationSchedule = {
  __typename?: 'PipelineNotificationSchedule';
  excludeWeekends?: Maybe<Scalars['Boolean']['output']>;
  hour?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  pipelineNotificationConfigId: Scalars['String']['output'];
  type: PipelineNotificationScheduleType;
  weekDay?: Maybe<Scalars['Int']['output']>;
};

export type PipelineNotificationScheduleInput = {
  excludeWeekends?: InputMaybe<Scalars['Boolean']['input']>;
  hour?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  pipelineNotificationConfigId?: InputMaybe<Scalars['String']['input']>;
  type: PipelineNotificationScheduleType;
  weekDay?: InputMaybe<Scalars['Int']['input']>;
};

export enum PipelineNotificationScheduleType {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Weekly = 'WEEKLY',
}

export type PipelineNotificationTemplateData = {
  __typename?: 'PipelineNotificationTemplateData';
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  pipelineNotificationConfigId: Scalars['ID']['output'];
  targetGroupTemplateData: TargetGroupTemplateData;
  targetGroupTemplateDataId: Scalars['ID']['output'];
};

export type PipelineNotificationTemplateDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  pipelineNotificationConfigId?: InputMaybe<Scalars['ID']['input']>;
  targetGroupTemplateDataId: Scalars['ID']['input'];
};

export type PracticalActivities = {
  __typename?: 'PracticalActivities';
} & Array<LessonPlan>;

export type ProductOffer = {
  __typename?: 'ProductOffer';
  courseDegrees?: Maybe<Array<Maybe<CourseDegree>>>;
  courseIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  cta: Scalars['String']['output'];
  displayedIn: DisplayPlace;
  displayedInCourses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  firstDescription: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  imageId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  secondDescription?: Maybe<Scalars['String']['output']>;
  sequence: Scalars['Int']['output'];
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  thirdDescription?: Maybe<Scalars['String']['output']>;
  urlCode: Scalars['String']['output'];
  urlWebPage: Scalars['String']['output'];
};

export type ProductOfferConnection = {
  __typename?: 'ProductOfferConnection';
  data?: Maybe<Array<ProductOffer>>;
  page: Page;
};

export type ProgramContentRequestReason = {
  __typename?: 'ProgramContentRequestReason';
  description: Scalars['String']['output'];
  reasonId: Scalars['String']['output'];
};

export enum PublicationStatus {
  PublicationStatusDraft = 'PUBLICATION_STATUS_DRAFT',
  PublicationStatusPublished = 'PUBLICATION_STATUS_PUBLISHED',
  PublicationStatusSuspended = 'PUBLICATION_STATUS_SUSPENDED',
  PublicationStatusUnspecified = 'PUBLICATION_STATUS_UNSPECIFIED',
}

export type PushNotificationDeleteInput = {
  deviceID: Scalars['String']['input'];
};

export type PushNotificationSaveInput = {
  deviceID: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  cancellationReasons: Array<CancellationReasons>;
  cancellationStatus: CancellationStatus;
  changeBillingCycleSimulation: BillingCycleWarnings;
  changeBillingCycleStatus: ChangeBillingCyclesResponse;
  changeCycleStatus: ChangeCyclesResponse;
  checkCnpj: CompanyData;
  countEnrolledCandidates: Scalars['Int']['output'];
  enrollmentCancellation: EnrollmentCancellation;
  existsByCandidateDocument: Scalars['Boolean']['output'];
  existsCandidateInSameCourse: CandidateInCourseResponse;
  existsCourseEnrollmentByCourseId?: Maybe<Scalars['Boolean']['output']>;
  findAffiliateByGeoLocationNear: Array<Maybe<AffiliateDistance>>;
  findAffiliateByPostalCode?: Maybe<Affiliate>;
  findAffiliateStubs: Array<AffiliateStub>;
  findAffiliatesByStatus: Array<Maybe<Affiliate>>;
  findAll: Array<Maybe<CourseData>>;
  findAllCompanyContacts?: Maybe<Array<Maybe<CompanyContact>>>;
  findAllLegalDocuments: Array<Maybe<LegalDocument>>;
  findAllProductOffers: Array<Maybe<ProductOffer>>;
  findAllProgramContentRequestReasons: Array<
    Maybe<ProgramContentRequestReason>
  >;
  findAllTeachers: Array<Teacher>;
  findAllTitrations: Array<Titration>;
  findAttendancesBySubjectEnrollment: Array<Maybe<Attendance>>;
  findCandidateExamGradeByExam: Array<Maybe<CandidateExamGrade>>;
  findCandidatesByStatus: CandidateConnection;
  findCities: Array<City>;
  findCompanyContactById?: Maybe<CompanyContact>;
  findContactPermissionsByRecipient?: Maybe<Array<ContactPermission>>;
  findCourseSeoByUrl: CourseSeoByUrl;
  findCourseStubs: Array<CourseStub>;
  findLearningUnitEnrollments: Array<LearningUnitEnrollment>;
  findLearningUnitEnrollmentsMobile: Array<LearningUnitEnrollment>;
  findPartnerById: Partner;
  findProductInterest: FindProductInterestResponse;
  findStates: Array<State>;
  findStudentCourseEnrollments?: Maybe<Array<CourseEnrollment>>;
  findStudentCourseEnrollmentsWithFreeTrialInfo?: Maybe<
    Array<CourseEnrollment>
  >;
  findTextCustomization?: Maybe<TextCustomization>;
  getACO: Activity;
  getACOByStudent: AcoListResponse;
  getACOHistory: AcoListResponse;
  getACOSummary: AcoSummary;
  getActivityWorkloadCompletionDate: ActivityWorkloadCompletionDate;
  getAddress: Address;
  getAffiliate: Affiliate;
  getAffiliateAppointmentSessionByAffiliateId: Array<
    Maybe<AffiliateAppointmentSession>
  >;
  getAffiliateAppointmentSessionByAffiliateIdAndDate: Array<
    Maybe<AffiliateAppointmentSession>
  >;
  getAffiliateAppointmentSessionByAffiliateIdAndStartTimeAfter48Hours: Array<
    Maybe<AffiliateAppointmentSession>
  >;
  getAffiliateAppointmentSessionById: AffiliateAppointmentSession;
  getAffiliates: Array<Maybe<Affiliate>>;
  getAllCountries: Array<Country>;
  getAllCourseTypes: Array<Maybe<CourseType>>;
  getAllEnadeStatus?: Maybe<Array<EnrollmentEnadeStatus>>;
  getAllReadOrUnreadNotificationsByStudent: Array<Maybe<NotificationCenter>>;
  getAllRegulationStatus?: Maybe<Array<EnrollmentRegulationStatus>>;
  getAmpliUserLoginInfo: UserLoginInfo;
  getAnnotationsByCourseEnrollment: Array<Maybe<Annotation>>;
  getAnnotationsByCourseEnrollmentAndSubject: Array<Maybe<Annotation>>;
  getAssignment?: Maybe<Assignment>;
  getAssignmentConfig?: Maybe<AssignmentConfig>;
  getAuthorities: AuthorityConnection;
  getAuthority?: Maybe<Authority>;
  getAvailableBillingCycles: GetAvailableBillingCyclesResponse;
  getAvailableBillingDays: Array<Scalars['Int']['output']>;
  getAvailableIncomeTaxYears: GetAvailableIncomeTaxYearsResponse;
  getBanner: Banner;
  getBannerByLocation: Banner;
  getBrand?: Maybe<EducationProviderBrand>;
  getCalendarEvents: CalendarPayload;
  getCandidate: Candidate;
  getCandidateCountByCourse: Scalars['Int']['output'];
  getCandidateExam: CandidateExam;
  getCandidateExamTracking?: Maybe<CandidateExamTracking>;
  getCandidateExams: Array<Maybe<CandidateExam>>;
  getCandidatePayment?: Maybe<CandidatePayment>;
  getCandidatePaymentInfo?: Maybe<CandidatePaymentInfo>;
  getCandidates: Array<Maybe<Candidate>>;
  getCategorySection: CategorySection;
  getCategorySections?: Maybe<Array<Maybe<CategorySection>>>;
  getCities: Array<Maybe<City>>;
  getCity: City;
  getCompany?: Maybe<EducationProviderCompany>;
  getContractDetail?: Maybe<ContractIngressDetailResponse>;
  getContracts?: Maybe<ContractIngressResponse>;
  getCoupon: Coupon;
  getCoupons: Array<Maybe<Coupon>>;
  getCourse: Course;
  getCourseByUrlCode: Course;
  getCourseCardImageUrl: Scalars['String']['output'];
  getCourseCategory: CourseCategory;
  getCourseDescriptionType?: Maybe<CourseDescriptionType>;
  getCourseEnrollment: CourseEnrollment;
  getCourseEnrollmentActualProgress: EnrollmentProgress;
  getCourseEnrollmentDegrees?: Maybe<CourseEnrollmentDegreeData>;
  getCourseEnrollmentStaffList: CourseStaffList;
  getCourseEventById: CourseEvent;
  getCourseEvents: Array<CourseEvent>;
  getCoursePriceStub: CoursePriceStub;
  getCoursePriceStubById: CoursePriceStub;
  getCoursePricesStub?: Maybe<Array<Maybe<CoursePriceStub>>>;
  getCourseSeo: CourseSeo;
  /** @deprecated Use new method getCourseEnrollmentStaffList() */
  getCourseStaff: CourseStaff;
  /** @deprecated Use new method getCourseEnrollmentStaffList() */
  getCourseStaffList: CourseStaffList;
  getCourseStub: CourseStub;
  getCourseType: CourseType;
  getCourseVersion: CourseVersion;
  getCourses: Array<Maybe<Course>>;
  getCoursesBySubject: CoursesBySubject;
  getCoursesStub: Array<Maybe<CourseStub>>;
  getCoursesWithEnrolledCount?: Maybe<Array<Maybe<CourseCandidateCount>>>;
  getCreditCard: CreditCard;
  getCreditCardRecurrence?: Maybe<CreditCardResponse>;
  getCreditCardsByEnrollmentId: Array<CreditCardResponse>;
  /** @deprecated Use new method getPendingInstalments() */
  getCurrentInstalment?: Maybe<Instalment>;
  /** @deprecated Use new method getPendingInstalments() */
  getCurrentInstalmentByEnrollmentId?: Maybe<Instalment>;
  getCycles: GetCyclesResponse;
  getDeclaredRace: Array<Maybe<DescribedEnum>>;
  getDefaultCoursePrice: DefaultCoursePrice;
  getDefaultCoursePriceByCourseId: DefaultCoursePrice;
  getEducationProvider?: Maybe<EducationProvider>;
  getEmailTemplate: EmailTemplate;
  getEnadeStatusById: EnrollmentEnadeStatus;
  getEnrollmentByStudentIdAndCourseId: CourseEnrollment;
  getEnrollmentDegree: EnrollmentDegreeResponse;
  getExam: Exam;
  getExternalPayment: ExternalPayment;
  getFieldOfStudy?: Maybe<FieldOfStudy>;
  getFinancialRoles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated Use new method getPendingInstalments() */
  getFutureInstalments: Array<Instalment>;
  /** @deprecated Use new method getPendingInstalments() */
  getFutureInstalmentsByEnrollmentId: Array<Instalment>;
  getGenericFieldsStatus: GenericFieldsStatus;
  getGradingMethod?: Maybe<GradingMethod>;
  getIdentityDocumentStatus: IdentityDocumentStatus;
  getImage: Image;
  getImages: Array<Image>;
  getInauguralSubjectByCourseEnrollmentId: Subject;
  getIncomeTaxYears: GetIncomeTaxYearsResponse;
  getInstalment: Instalment;
  getInvoice?: Maybe<Invoice>;
  getInvoiceBoletoPdf: InvoiceBoletoPdf;
  getInvoiceDocumentUrl: InvoiceDocumentResponse;
  getInvoiceHomeModel?: Maybe<InvoiceHomeModel>;
  getLastContentEvaluationByContentAndStudent?: Maybe<ContentEvaluation>;
  getLastContentEvaluationsByStudent?: Maybe<Array<Maybe<ContentEvaluation>>>;
  getLastDoneAssignments?: Maybe<Array<DoneAssignmentData>>;
  getLastOrder: OrderResponse;
  getLead?: Maybe<Lead>;
  getLearningUnit: LearningUnit;
  getLearningUnitVersion: LearningUnitVersion;
  getLearningUnitVersions: Array<Maybe<LearningUnitVersion>>;
  getLearningUnits: Array<Maybe<LearningUnit>>;
  getLegalDocument: LegalDocument;
  getListAllNotify: Array<Maybe<NotificationCenter>>;
  getListSpecificNotification: Array<Maybe<NotificationCenter>>;
  getManyAssignmentConfigs?: Maybe<Array<Maybe<AssignmentConfig>>>;
  getManyAssignments?: Maybe<Array<Maybe<Assignment>>>;
  getManyBrands?: Maybe<Array<Maybe<EducationProviderBrand>>>;
  getManyCompanies?: Maybe<Array<Maybe<EducationProviderCompany>>>;
  getManyCourseCategoryByIds: Array<Maybe<CourseCategory>>;
  getManyCourseDescriptionTypes?: Maybe<Array<Maybe<CourseDescriptionType>>>;
  getManyCourseTypes?: Maybe<Array<Maybe<CourseType>>>;
  getManyDefaultCoursePrice: Array<Maybe<DefaultCoursePrice>>;
  getManyEducationProviders?: Maybe<Array<Maybe<EducationProvider>>>;
  getManyEmailTemplates: Array<EmailTemplate>;
  getManyExams?: Maybe<Array<Maybe<Exam>>>;
  getManyFieldOfStudies?: Maybe<Array<Maybe<FieldOfStudy>>>;
  getManyGradingMethods?: Maybe<Array<Maybe<GradingMethod>>>;
  getManyLeads?: Maybe<Array<Maybe<Lead>>>;
  getManyPartners: Array<Maybe<Partner>>;
  getManyPersons?: Maybe<Array<Maybe<Person>>>;
  getManyPipelineNotificationConfigs?: Maybe<
    Array<Maybe<PipelineNotificationConfig>>
  >;
  getManyPipelineNotificationContexts?: Maybe<
    Array<Maybe<PipelineNotificationContext>>
  >;
  getManyPipelineNotificationDegreeGroups?: Maybe<
    Array<Maybe<PipelineNotificationDegreeGroup>>
  >;
  getManySellerCategories?: Maybe<Array<Maybe<SellerCategory>>>;
  getManySellerRegionals?: Maybe<Array<Maybe<SellerRegional>>>;
  getManySellerUnits?: Maybe<Array<Maybe<SellerUnit>>>;
  getManySellers?: Maybe<Array<Maybe<Seller>>>;
  getManySmsTemplates: Array<SmsTemplate>;
  getManyStudents: Array<Maybe<Student>>;
  getManyTargetGroups?: Maybe<Array<Maybe<TargetGroup>>>;
  getManyWhatsappTemplates: Array<WhatsappTemplate>;
  getMinorCoursePriceByDegree: DefaultCoursePrice;
  getMonthOfNewBillingDay: GetMonthOfNewBillingDayResponse;
  getNegotiationPaymentUrl: ExternalPayment;
  getNegotiationUrl?: Maybe<NegotiationResponse>;
  getOneArticle: Article;
  getOneCourseEnrollmentDegree?: Maybe<EnrollmentDegree>;
  getOrders?: Maybe<GetOrderResponse>;
  /** @deprecated Use new method getPendingInstalments() */
  getOverdueInstalments: Array<Instalment>;
  /** @deprecated Use new method getPendingInstalments() */
  getOverdueInstalmentsByEnrollmentId: Array<Instalment>;
  getPaidInstalments: Array<Instalment>;
  getPartner: Partner;
  getPassedSubjectEnrollments?: Maybe<Array<Maybe<SubjectEnrollmentWithName>>>;
  getPasswordPolicies: PasswordPolicies;
  getPaymentCreditCardPublicKey?: Maybe<GetInvoiceCreditCardPublicKey>;
  getPaymentPlan: PaymentPlan;
  getPaymentStatement: Array<PaymentStatementItem>;
  getPendingInstalments: Array<Instalment>;
  getPerson?: Maybe<Person>;
  getPipelineNotificationConfig?: Maybe<PipelineNotificationConfig>;
  getPipelineNotificationContext?: Maybe<PipelineNotificationContext>;
  getPipelineNotificationDegreeGroup?: Maybe<PipelineNotificationDegreeGroup>;
  /** @deprecated Use new method getPreviousInstalmentsByEnrollmentId() */
  getPreviousInstalments: Array<Instalment>;
  getPreviousInstalmentsByEnrollmentId: Array<Instalment>;
  getProductOffer: ProductOffer;
  getProductOfferByUrlCode: ProductOffer;
  getPublicKey: GetPublicKeyResponse;
  getQuestion: Question;
  getQuestionBankTypeById?: Maybe<BankType>;
  getQuestionBankTypesByName?: Maybe<Array<Maybe<BankType>>>;
  getQuestions: Array<Question>;
  getReason: Reason;
  getReasonByStatus?: Maybe<Array<Maybe<Reason>>>;
  getReasonByStatusForStudent?: Maybe<Array<Maybe<Reason>>>;
  getRegulationStatusById: EnrollmentRegulationStatus;
  getReviewContents?: Maybe<ReviewContent>;
  getRole?: Maybe<Role>;
  getRoles: RoleConnection;
  getSecretariatRolesByEnrollmentId: FeatureRolesModel;
  getSeller?: Maybe<Seller>;
  getSellerByCpf?: Maybe<Seller>;
  getSellerCategory?: Maybe<SellerCategory>;
  getSellerRegional?: Maybe<SellerRegional>;
  getSellerUnit?: Maybe<SellerUnit>;
  getSmsTemplate: SmsTemplate;
  getSocialNameStatus: SocialNameStatus;
  getSpecialNeeds: Array<Maybe<DescribedEnum>>;
  getState: State;
  getStates: Array<Maybe<State>>;
  getStudent: Student;
  getStudentAddresses: StudentAddress;
  getStudentComplementaryFields: StudentComplementaryFields;
  getStudentContacts: StudentContact;
  getStudentCreditCard: StudentCreditCard;
  /** @deprecated Use new method getStudentCreditCardsByEnrollmentId() */
  getStudentCreditCards: Array<StudentCreditCard>;
  getStudentCreditCardsByEnrollmentId: Array<StudentCreditCard>;
  getStudentEducationProvider?: Maybe<EducationProviderInfo>;
  getStudentLoginData: StudentData;
  /** @deprecated Use new method getStudentPaymentPlanByEnrollmentId() */
  getStudentPaymentPlan: PaymentPlan;
  getStudentPaymentPlanByEnrollmentId: PaymentPlan;
  getStudentPhoto: StudentPhoto;
  getStudentRegistration: StudentRegistration;
  /** @deprecated Use GetStudentLoginData query instead */
  getStudentUserLoginInfo: UserLoginInfo;
  getSubject: Subject;
  getSubjectEnrollment: SubjectEnrollment;
  getSubjectEnrollmentStaff: SubjectStaff;
  /** @deprecated Use new method getSubjectEnrollmentStaff() */
  getSubjectStaff: SubjectStaff;
  getSubjects: Array<Maybe<Subject>>;
  getTargetGroup?: Maybe<TargetGroup>;
  getTeacher?: Maybe<Teacher>;
  getTrack: Track;
  getUser?: Maybe<User>;
  getUsers: UserConnection;
  getValidCoupon: Coupon;
  getWhatsappTemplate: WhatsappTemplate;
  health?: Maybe<Scalars['Boolean']['output']>;
  isReentryCandidate: Scalars['Boolean']['output'];
  listAffiliates: AffiliateConnection;
  listAllCourseCategories: CourseCategoryConnection;
  listApiClients: ApiClientConnection;
  listArticles: Array<Maybe<Article>>;
  listAssignment?: Maybe<AssignmentList>;
  listAssignmentConfig?: Maybe<AssignmentConfigList>;
  listAssignmentFiles?: Maybe<Array<Maybe<AssignmentFile>>>;
  listAuthorities: AuthorityConnection;
  listAvailableCategories: AvailableCategories;
  listAvailableExams: AvailableExams;
  listAvailableExamsByCourseEnrollmentId: AvailableExams;
  listBanners: BannerConnection;
  listBannersById: Array<Maybe<Creative>>;
  listBrand?: Maybe<BrandList>;
  listCandidateExams: CandidateExamConnection;
  listCandidates: CandidateConnection;
  listCategories: Array<Maybe<Categories>>;
  listCategorySections: CategorySectionList;
  listCities: CityConnection;
  listCompany?: Maybe<CompanyList>;
  listContractServiceById: Array<Maybe<Creative>>;
  listCountries: CountryConnection;
  listCoupons: CouponConnection;
  listCourseCategoriesWithSectionsByDegree: CourseCategoryWithSectionsConnection;
  listCourseDescriptionType?: Maybe<CourseDescriptionTypeList>;
  listCourseEvent: ListCourseEventResponse;
  listCoursePricesStub: CoursePriceStubConnection;
  listCourseType?: Maybe<CourseTypeList>;
  listCourses: CourseConnection;
  listCoursesByCourseDegree: CourseConnection;
  listCoursesSeo: CourseSeoConnection;
  listDefaultCoursePrice?: Maybe<DefaultCoursePriceList>;
  listEducationProvider?: Maybe<EducationProviderList>;
  listEmailTemplates?: Maybe<EmailTemplateList>;
  listFieldOfStudy?: Maybe<FieldOfStudyList>;
  listGradingMethod?: Maybe<GradingMethodList>;
  listImages?: Maybe<ImageConnection>;
  listLead?: Maybe<LeadList>;
  listLeadLight?: Maybe<LeadLightList>;
  listLearningUnitVersions: LearningUnitVersionConnection;
  listLearningUnits: LearningUnitConnection;
  listLegalDocuments: LegalDocumentConnection;
  listMandatoryInternship: Array<Maybe<MandatoryInternship>>;
  listModalById: Array<Maybe<Creative>>;
  listMyRegistrations: Array<Maybe<Candidate>>;
  listNotificationsById: NotificationCreative;
  listPartner: PartnerList;
  listPendingApprovalACO: PendingApprovalAcoResponse;
  listPendingDocumentsSummary: PendingDocumentsSummaryPage;
  listPendingEnrollById: Array<Maybe<Creative>>;
  listPerson?: Maybe<PersonList>;
  listPipelineNotificationConfig?: Maybe<PipelineNotificationConfigList>;
  listPipelineNotificationContext?: Maybe<PipelineNotificationContextList>;
  listPipelineNotificationDegreeGroup?: Maybe<PipelineNotificationDegreeGroupList>;
  listPipelineNotificationExecution?: Maybe<PipelineNotificationExecutionList>;
  listProductOffers: ProductOfferConnection;
  listQuestions: QuestionConnection;
  listReasons?: Maybe<ReasonList>;
  listRejectionReasons: DocumentRejectionReasonList;
  listRoles: RoleConnection;
  listSeller?: Maybe<SellerList>;
  listSellerCategory?: Maybe<SellerCategoryList>;
  listSellerRegional?: Maybe<SellerRegionalList>;
  listSellerUnit?: Maybe<SellerUnitList>;
  listSmsTemplates?: Maybe<SmsTemplateList>;
  listStates: StateConnection;
  listStudentOnboardingBeginById: Scalars['Boolean']['output'];
  listStudentOnboardingCourseById: Scalars['Boolean']['output'];
  listStudents: StudentConnection;
  listSubjects: SubjectConnection;
  listTargetGroup?: Maybe<TargetGroupList>;
  listTeachers: TeacherPage;
  listUsers: UserConnection;
  listVisibleCategorySections: CategorySectionList;
  listVisibleCategorySectionsByCategoryId?: Maybe<
    Array<Maybe<CategorySectionWithCourses>>
  >;
  listVisibleCourseCategoriesByDegree: CourseCategoryConnection;
  listVisibleCoursesByCourseDegree: CourseConnection;
  listWhatsappTemplates?: Maybe<WhatsappTemplateList>;
  loginBvAuthenticationUser: BvAuthenticationUser;
  mandatoryInternshipHistory?: Maybe<Array<Maybe<MandatoryInternshipHistory>>>;
  me: Me;
  myOrdersInputConfig?: Maybe<MyOrdersInputconfigResponse>;
  payInvoiceByCreditCardResult?: Maybe<PaymentCreditCardStatusResult>;
  requestChangeCycleStatusSimulation: Warnings;
  requestedStudentDocuments: RequestedStudentDocuments;
  sendSms: UserLoginInfo;
  simulateCourseTransfer: TransferSimulation;
  sofiaGetMe: SofiaGetMeResponse;
  sofiaStudentData: SofiaGetStudentData;
  validateRedoCandidateExam?: Maybe<RedoExam>;
  validateWhitelist: Scalars['Boolean']['output'];
};

export type QueryCancellationReasonsArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryCancellationStatusArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
  mockResultStatus?: InputMaybe<MockResultStatus>;
};

export type QueryChangeBillingCycleSimulationArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  cycle: Scalars['String']['input'];
};

export type QueryChangeBillingCycleStatusArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryChangeCycleStatusArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryCheckCnpjArgs = {
  cnpj: Scalars['String']['input'];
};

export type QueryEnrollmentCancellationArgs = {
  action: EnrollmentCancellationAction;
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryExistsByCandidateDocumentArgs = {
  document: Scalars['String']['input'];
};

export type QueryExistsCandidateInSameCourseArgs = {
  birthDate: Scalars['String']['input'];
  courseId: Scalars['ID']['input'];
  document: Scalars['String']['input'];
};

export type QueryExistsCourseEnrollmentByCourseIdArgs = {
  courseId: Scalars['ID']['input'];
};

export type QueryFindAffiliateByGeoLocationNearArgs = {
  courseDegree?: InputMaybe<CourseDegree>;
  distance: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  status?: InputMaybe<AffiliateStatus>;
};

export type QueryFindAffiliateByPostalCodeArgs = {
  cityId: Scalars['Int']['input'];
  courseDegree?: InputMaybe<CourseDegree>;
  postalCode: Scalars['String']['input'];
  status?: InputMaybe<AffiliateStatus>;
};

export type QueryFindAffiliateStubsArgs = {
  cityId: Scalars['Int']['input'];
};

export type QueryFindAffiliatesByStatusArgs = {
  courseDegree: CourseDegree;
  status?: InputMaybe<AffiliateStatus>;
};

export type QueryFindAllProductOffersArgs = {
  courseDegree?: InputMaybe<CourseDegree>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  displayIn?: InputMaybe<DisplayPlace>;
};

export type QueryFindAttendancesBySubjectEnrollmentArgs = {
  subjectEnrollmentId: Scalars['ID']['input'];
};

export type QueryFindCandidateExamGradeByExamArgs = {
  candidateExamId: Scalars['ID']['input'];
};

export type QueryFindCandidatesByStatusArgs = {
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
  status: CandidateStatus;
};

export type QueryFindCitiesArgs = {
  stateId: Scalars['Int']['input'];
};

export type QueryFindCompanyContactByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindContactPermissionsByRecipientArgs = {
  recipient: Scalars['String']['input'];
};

export type QueryFindCourseSeoByUrlArgs = {
  url: Scalars['String']['input'];
};

export type QueryFindLearningUnitEnrollmentsArgs = {
  subjectEnrollmentId: Scalars['ID']['input'];
};

export type QueryFindLearningUnitEnrollmentsMobileArgs = {
  subjectEnrollmentId: Scalars['ID']['input'];
};

export type QueryFindPartnerByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindProductInterestArgs = {
  request: FindProductInterestRequest;
};

export type QueryFindStatesArgs = {
  countryId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFindStudentCourseEnrollmentsArgs = {
  studentId: Scalars['ID']['input'];
};

export type QueryFindStudentCourseEnrollmentsWithFreeTrialInfoArgs = {
  studentId: Scalars['ID']['input'];
};

export type QueryFindTextCustomizationArgs = {
  studentId: Scalars['ID']['input'];
};

export type QueryGetAcoArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetAcoByStudentArgs = {
  studentId: Scalars['ID']['input'];
};

export type QueryGetAcoHistoryArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetAcoSummaryArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetActivityWorkloadCompletionDateArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetAddressArgs = {
  postalCode: Scalars['PostalCode']['input'];
};

export type QueryGetAffiliateArgs = {
  affiliateId: Scalars['ID']['input'];
};

export type QueryGetAffiliateAppointmentSessionByAffiliateIdArgs = {
  affiliateId: Scalars['String']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetAffiliateAppointmentSessionByAffiliateIdAndDateArgs = {
  affiliateId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetAffiliateAppointmentSessionByAffiliateIdAndStartTimeAfter48HoursArgs =
  {
    affiliateId: Scalars['String']['input'];
    pageNumber?: InputMaybe<Scalars['Int']['input']>;
    pageSize?: InputMaybe<Scalars['Int']['input']>;
  };

export type QueryGetAffiliateAppointmentSessionByIdArgs = {
  appointmentSessionId: Scalars['ID']['input'];
};

export type QueryGetAffiliatesArgs = {
  affiliateIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetAllReadOrUnreadNotificationsByStudentArgs = {
  isNotificationsReaded: Scalars['Boolean']['input'];
  studentId: Scalars['ID']['input'];
};

export type QueryGetAmpliUserLoginInfoArgs = {
  captcha: CaptchaInput;
  username: Scalars['String']['input'];
};

export type QueryGetAnnotationsByCourseEnrollmentArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetAnnotationsByCourseEnrollmentAndSubjectArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  subjectId: Scalars['String']['input'];
};

export type QueryGetAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetAssignmentConfigArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetAuthoritiesArgs = {
  filter?: InputMaybe<Filter>;
  names: Array<Scalars['ID']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryGetAuthorityArgs = {
  name: Scalars['ID']['input'];
};

export type QueryGetAvailableBillingCyclesArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetAvailableIncomeTaxYearsArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetBannerArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetBannerByLocationArgs = {
  location: BannerLocation;
};

export type QueryGetBrandArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetCalendarEventsArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
  endDate: Scalars['String']['input'];
  modality: CourseDegree;
  startDate: Scalars['String']['input'];
};

export type QueryGetCandidateArgs = {
  candidateId: Scalars['ID']['input'];
};

export type QueryGetCandidateCountByCourseArgs = {
  courseId: Scalars['ID']['input'];
};

export type QueryGetCandidateExamArgs = {
  candidateExamId: Scalars['ID']['input'];
};

export type QueryGetCandidateExamTrackingArgs = {
  candidateExamId: Scalars['ID']['input'];
};

export type QueryGetCandidateExamsArgs = {
  candidateExamIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetCandidatePaymentArgs = {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetCandidatePaymentInfoArgs = {
  candidateId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetCandidatesArgs = {
  candidateIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetCategorySectionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetCategorySectionsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryGetCitiesArgs = {
  cityIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type QueryGetCityArgs = {
  cityId: Scalars['Int']['input'];
};

export type QueryGetCompanyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetContractDetailArgs = {
  enrollmentId: Scalars['String']['input'];
  idContract: Scalars['String']['input'];
};

export type QueryGetContractsArgs = {
  enrollmentId: Scalars['String']['input'];
};

export type QueryGetCouponArgs = {
  couponCode: Scalars['ID']['input'];
};

export type QueryGetCouponsArgs = {
  couponCodes: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetCourseArgs = {
  courseId: Scalars['ID']['input'];
  courseVersionId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetCourseByUrlCodeArgs = {
  urlCode: Scalars['String']['input'];
};

export type QueryGetCourseCardImageUrlArgs = {
  courseId: Scalars['ID']['input'];
};

export type QueryGetCourseCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetCourseDescriptionTypeArgs = {
  code?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetCourseEnrollmentArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetCourseEnrollmentActualProgressArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetCourseEnrollmentDegreesArgs = {
  courseEnrollmentIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetCourseEnrollmentStaffListArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetCourseEventByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetCourseEventsArgs = {
  eventsIds: Array<Scalars['ID']['input']>;
};

export type QueryGetCoursePriceStubArgs = {
  cityId: Scalars['Int']['input'];
  courseId: Scalars['String']['input'];
};

export type QueryGetCoursePriceStubByIdArgs = {
  coursePriceId: Scalars['String']['input'];
};

export type QueryGetCoursePricesStubArgs = {
  coursePriceIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetCourseSeoArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetCourseStaffArgs = {
  courseId: Scalars['ID']['input'];
};

export type QueryGetCourseStaffListArgs = {
  courseId: Scalars['ID']['input'];
};

export type QueryGetCourseStubArgs = {
  courseId: Scalars['ID']['input'];
};

export type QueryGetCourseTypeArgs = {
  code: Scalars['ID']['input'];
};

export type QueryGetCourseVersionArgs = {
  courseVersionId: Scalars['ID']['input'];
};

export type QueryGetCoursesArgs = {
  courseIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetCoursesBySubjectArgs = {
  subjectId: Scalars['ID']['input'];
};

export type QueryGetCoursesStubArgs = {
  courseIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetCreditCardArgs = {
  creditCardId: Scalars['ID']['input'];
};

export type QueryGetCreditCardRecurrenceArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetCreditCardsByEnrollmentIdArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetCurrentInstalmentArgs = {
  courseId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type QueryGetCurrentInstalmentByEnrollmentIdArgs = {
  enrollmentId: Scalars['String']['input'];
};

export type QueryGetCyclesArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetDefaultCoursePriceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetDefaultCoursePriceByCourseIdArgs = {
  courseId: Scalars['ID']['input'];
};

export type QueryGetEducationProviderArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetEmailTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetEnadeStatusByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetEnrollmentByStudentIdAndCourseIdArgs = {
  courseId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type QueryGetEnrollmentDegreeArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type QueryGetExamArgs = {
  examId: Scalars['ID']['input'];
};

export type QueryGetExternalPaymentArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
};

export type QueryGetFieldOfStudyArgs = {
  code?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetFinancialRolesArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetFutureInstalmentsArgs = {
  courseId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type QueryGetFutureInstalmentsByEnrollmentIdArgs = {
  enrollmentId: Scalars['String']['input'];
};

export type QueryGetGenericFieldsStatusArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
  subject: EnumSubject;
};

export type QueryGetGradingMethodArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetIdentityDocumentStatusArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetImageArgs = {
  imageId: Scalars['ID']['input'];
};

export type QueryGetImagesArgs = {
  imageIds: Array<Scalars['ID']['input']>;
};

export type QueryGetInauguralSubjectByCourseEnrollmentIdArgs = {
  courseEnrollmentId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetIncomeTaxYearsArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetInstalmentArgs = {
  courseEnrollmentId?: InputMaybe<Scalars['String']['input']>;
  instalmentId: Scalars['ID']['input'];
};

export type QueryGetInvoiceArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
};

export type QueryGetInvoiceBoletoPdfArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
};

export type QueryGetInvoiceDocumentUrlArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
};

export type QueryGetInvoiceHomeModelArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetLastContentEvaluationByContentAndStudentArgs = {
  learningObjectId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type QueryGetLastContentEvaluationsByStudentArgs = {
  studentId: Scalars['String']['input'];
};

export type QueryGetLastDoneAssignmentsArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
  numberOfAssignments: Scalars['Int']['input'];
};

export type QueryGetLastOrderArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  orderSubject: OrderSubject;
};

export type QueryGetLeadArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetLearningUnitArgs = {
  learningUnitId: Scalars['ID']['input'];
};

export type QueryGetLearningUnitVersionArgs = {
  learningUnitVersionId: Scalars['ID']['input'];
};

export type QueryGetLearningUnitVersionsArgs = {
  learningUnitVersionIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetLearningUnitsArgs = {
  learningUnitIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetLegalDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetListAllNotifyArgs = {
  studentId: Scalars['ID']['input'];
};

export type QueryGetListSpecificNotificationArgs = {
  studentId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type QueryGetManyAssignmentConfigsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyAssignmentsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyBrandsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetManyCompaniesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryGetManyCourseCategoryByIdsArgs = {
  categoryIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type QueryGetManyCourseDescriptionTypesArgs = {
  codes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyCourseTypesArgs = {
  codes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyDefaultCoursePriceArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetManyEducationProvidersArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetManyEmailTemplatesArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetManyExamsArgs = {
  examIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyFieldOfStudiesArgs = {
  codes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyGradingMethodsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyLeadsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyPartnersArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryGetManyPersonsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyPipelineNotificationConfigsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyPipelineNotificationContextsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyPipelineNotificationDegreeGroupsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManySellerCategoriesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManySellerRegionalsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManySellerUnitsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManySellersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManySmsTemplatesArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetManyStudentsArgs = {
  studentIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetManyTargetGroupsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetManyWhatsappTemplatesArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetMinorCoursePriceByDegreeArgs = {
  degree: CourseDegree;
};

export type QueryGetMonthOfNewBillingDayArgs = {
  billingDay: Scalars['Int']['input'];
  paymentPlanId: Scalars['String']['input'];
};

export type QueryGetNegotiationPaymentUrlArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetNegotiationUrlArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetOneArticleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetOneCourseEnrollmentDegreeArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetOrdersArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  orderSubject?: InputMaybe<OrderSubject>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryGetOverdueInstalmentsArgs = {
  courseId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type QueryGetOverdueInstalmentsByEnrollmentIdArgs = {
  enrollmentId: Scalars['String']['input'];
};

export type QueryGetPaidInstalmentsArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetPartnerArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetPassedSubjectEnrollmentsArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetPaymentCreditCardPublicKeyArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetPaymentPlanArgs = {
  full?: InputMaybe<Scalars['Boolean']['input']>;
  paymentPlanId: Scalars['ID']['input'];
};

export type QueryGetPaymentStatementArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetPendingInstalmentsArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryGetPersonArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetPipelineNotificationConfigArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetPipelineNotificationContextArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetPipelineNotificationDegreeGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetPreviousInstalmentsArgs = {
  courseId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type QueryGetPreviousInstalmentsByEnrollmentIdArgs = {
  enrollmentId: Scalars['String']['input'];
};

export type QueryGetProductOfferArgs = {
  productOfferId: Scalars['ID']['input'];
};

export type QueryGetProductOfferByUrlCodeArgs = {
  urlCode: Scalars['String']['input'];
};

export type QueryGetPublicKeyArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type QueryGetQuestionArgs = {
  questionId: Scalars['ID']['input'];
};

export type QueryGetQuestionBankTypeByIdArgs = {
  bankTypeId: Scalars['String']['input'];
};

export type QueryGetQuestionBankTypesByNameArgs = {
  bankTypeName: Scalars['String']['input'];
};

export type QueryGetQuestionsArgs = {
  questionIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetReasonArgs = {
  code?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetReasonByStatusArgs = {
  status?: InputMaybe<CourseEnrollmentStatus>;
};

export type QueryGetReasonByStatusForStudentArgs = {
  status?: InputMaybe<CourseEnrollmentStatus>;
};

export type QueryGetRegulationStatusByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetReviewContentsArgs = {
  learningUnitEnrollmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetRoleArgs = {
  name: Scalars['ID']['input'];
};

export type QueryGetRolesArgs = {
  filter?: InputMaybe<Filter>;
  names: Array<Scalars['ID']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryGetSecretariatRolesByEnrollmentIdArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type QueryGetSellerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetSellerByCpfArgs = {
  cpf?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetSellerCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetSellerRegionalArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetSellerUnitArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetSmsTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetSocialNameStatusArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetStateArgs = {
  stateId: Scalars['Int']['input'];
};

export type QueryGetStatesArgs = {
  stateIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type QueryGetStudentArgs = {
  studentId: Scalars['ID']['input'];
};

export type QueryGetStudentAddressesArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type QueryGetStudentComplementaryFieldsArgs = {
  studentId: Scalars['ID']['input'];
};

export type QueryGetStudentContactsArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type QueryGetStudentCreditCardArgs = {
  studentCreditCardId: Scalars['ID']['input'];
};

export type QueryGetStudentCreditCardsArgs = {
  courseId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type QueryGetStudentCreditCardsByEnrollmentIdArgs = {
  enrollmentId: Scalars['String']['input'];
};

export type QueryGetStudentEducationProviderArgs = {
  courseEnrollmentId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetStudentLoginDataArgs = {
  username: Scalars['String']['input'];
};

export type QueryGetStudentPaymentPlanArgs = {
  courseId: Scalars['String']['input'];
  full?: InputMaybe<Scalars['Boolean']['input']>;
  studentId: Scalars['String']['input'];
};

export type QueryGetStudentPaymentPlanByEnrollmentIdArgs = {
  enrollmentId: Scalars['String']['input'];
  full?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetStudentPhotoArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type QueryGetStudentRegistrationArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type QueryGetStudentUserLoginInfoArgs = {
  captcha: CaptchaInput;
  username: Scalars['String']['input'];
};

export type QueryGetSubjectArgs = {
  subjectId: Scalars['ID']['input'];
};

export type QueryGetSubjectEnrollmentArgs = {
  subjectEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetSubjectEnrollmentStaffArgs = {
  subjectEnrollmentId: Scalars['ID']['input'];
};

export type QueryGetSubjectStaffArgs = {
  courseVersionId: Scalars['ID']['input'];
  subjectId: Scalars['ID']['input'];
};

export type QueryGetSubjectsArgs = {
  subjectIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryGetTargetGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetTeacherArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetTrackArgs = {
  referenceId: Scalars['String']['input'];
};

export type QueryGetUserArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryGetUsersArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
  userIds: Array<Scalars['ID']['input']>;
};

export type QueryGetValidCouponArgs = {
  couponCode: Scalars['ID']['input'];
  courseId?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetWhatsappTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryListAffiliatesArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListAllCourseCategoriesArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListApiClientsArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListAssignmentArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListAssignmentConfigArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListAssignmentFilesArgs = {
  assignmentId: Scalars['String']['input'];
};

export type QueryListAuthoritiesArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListAvailableCategoriesArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryListAvailableExamsArgs = {
  studentId: Scalars['ID']['input'];
};

export type QueryListAvailableExamsByCourseEnrollmentIdArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QueryListBannersArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListBannersByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryListBrandArgs = {
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCandidateExamsArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCandidatesArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCategorySectionsArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCitiesArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
  stateId: Scalars['Int']['input'];
};

export type QueryListCompanyArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListContractServiceByIdArgs = {
  data?: InputMaybe<ContractServiceInput>;
};

export type QueryListCountriesArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCouponsArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCourseCategoriesWithSectionsByDegreeArgs = {
  courseDegree: CourseDegree;
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCourseDescriptionTypeArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCourseEventArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination: Pagination;
  sort?: InputMaybe<Sort>;
};

export type QueryListCoursePricesStubArgs = {
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCourseTypeArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCoursesArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCoursesByCourseDegreeArgs = {
  degree: CourseDegree;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListCoursesSeoArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListDefaultCoursePriceArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListEducationProviderArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListEmailTemplatesArgs = {
  tokenPagination?: InputMaybe<TokenPagination>;
};

export type QueryListFieldOfStudyArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListGradingMethodArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListImagesArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListLeadArgs = {
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListLeadLightArgs = {
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListLearningUnitVersionsArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListLearningUnitsArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListLegalDocumentsArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListMandatoryInternshipArgs = {
  pagination: Pagination;
  status?: InputMaybe<Array<InputMaybe<ConsolidatedStatus>>>;
  subjectEnrollmentId?: InputMaybe<Scalars['String']['input']>;
  usernameOrStudentName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListModalByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryListNotificationsByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryListPartnerArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListPendingApprovalAcoArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryListPendingDocumentsSummaryArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListPendingEnrollByIdArgs = {
  data?: InputMaybe<EnrollPendingInput>;
};

export type QueryListPersonArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListPipelineNotificationConfigArgs = {
  filter?: InputMaybe<PipelineNotificationConfigFilterInput>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListPipelineNotificationContextArgs = {
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListPipelineNotificationDegreeGroupArgs = {
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListPipelineNotificationExecutionArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListProductOffersArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListQuestionsArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListReasonsArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListRejectionReasonsArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListRolesArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListSellerArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListSellerCategoryArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListSellerRegionalArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListSellerUnitArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListSmsTemplatesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryListStatesArgs = {
  country: Scalars['Int']['input'];
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListStudentOnboardingBeginByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryListStudentOnboardingCourseByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryListStudentsArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
  source: Array<Source>;
};

export type QueryListSubjectsArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListTargetGroupArgs = {
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListTeachersArgs = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListUsersArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListVisibleCategorySectionsArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListVisibleCategorySectionsByCategoryIdArgs = {
  categoryId: Scalars['String']['input'];
};

export type QueryListVisibleCourseCategoriesByDegreeArgs = {
  courseDegree: CourseDegree;
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListVisibleCoursesByCourseDegreeArgs = {
  degree: CourseDegree;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryListWhatsappTemplatesArgs = {
  tokenPagination?: InputMaybe<TokenPagination>;
};

export type QueryLoginBvAuthenticationUserArgs = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type QueryMandatoryInternshipHistoryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMyOrdersInputConfigArgs = {
  courseEnrollmentId: Scalars['String']['input'];
};

export type QueryPayInvoiceByCreditCardResultArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
};

export type QueryRequestChangeCycleStatusSimulationArgs = {
  courseEnrollmentId: Scalars['String']['input'];
  cycle: Scalars['String']['input'];
};

export type QueryRequestedStudentDocumentsArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
};

export type QuerySendSmsArgs = {
  captcha: CaptchaInput;
  username: Scalars['String']['input'];
};

export type QuerySimulateCourseTransferArgs = {
  courseEnrollmentId: Scalars['ID']['input'];
  targetCourseId: Scalars['ID']['input'];
};

export type QuerySofiaStudentDataArgs = {
  username: Scalars['String']['input'];
};

export type QueryValidateRedoCandidateExamArgs = {
  candidateId: Scalars['ID']['input'];
};

export type QueryValidateWhitelistArgs = {
  deviceId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type Question = {
  __typename?: 'Question';
  bankType?: Maybe<Scalars['String']['output']>;
  baseText: Scalars['String']['output'];
  bloomTaxonomy?: Maybe<QuestionBloomTaxonomy>;
  choices: Array<Choice>;
  contentTypeMacro?: Maybe<Scalars['String']['output']>;
  contentTypeMicro?: Maybe<Scalars['String']['output']>;
  difficulty?: Maybe<QuestionDifficult>;
  id: Scalars['ID']['output'];
  krotonCreatedDate?: Maybe<Scalars['String']['output']>;
  krotonId?: Maybe<Scalars['String']['output']>;
  krotonUpdatedDate?: Maybe<Scalars['String']['output']>;
  learningUnitIds?: Maybe<Array<Scalars['String']['output']>>;
  origin?: Maybe<Scalars['String']['output']>;
  statement: Scalars['String']['output'];
  status: QuestionStatus;
  subType?: Maybe<QuestionSubType>;
  subjectIds: Array<Scalars['String']['output']>;
  type: QuestionType;
};

export enum QuestionBloomTaxonomy {
  Analysis = 'ANALYSIS',
  Application = 'APPLICATION',
  Comprehension = 'COMPREHENSION',
  Evaluation = 'EVALUATION',
  Knowledge = 'KNOWLEDGE',
  Synthesis = 'SYNTHESIS',
}

export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  data: Array<Question>;
  page: Page;
};

export enum QuestionDifficult {
  Easy = 'EASY',
  Hard = 'HARD',
  Medium = 'MEDIUM',
}

export type QuestionForExam = {
  __typename?: 'QuestionForExam';
  baseText: Scalars['String']['output'];
  choices: Array<ChoiceForExam>;
  id: Scalars['ID']['output'];
  statement: Scalars['String']['output'];
};

export type QuestionInput = {
  bankType?: InputMaybe<Scalars['String']['input']>;
  baseText?: InputMaybe<Scalars['String']['input']>;
  bloomTaxonomy?: InputMaybe<QuestionBloomTaxonomy>;
  choices: Array<ChoiceInput>;
  contentTypeMacro?: InputMaybe<Scalars['String']['input']>;
  contentTypeMicro?: InputMaybe<Scalars['String']['input']>;
  difficulty?: InputMaybe<QuestionDifficult>;
  krotonCreatedDate?: InputMaybe<Scalars['String']['input']>;
  krotonId?: InputMaybe<Scalars['String']['input']>;
  krotonUpdatedDate?: InputMaybe<Scalars['String']['input']>;
  learningUnitIds?: InputMaybe<Array<Scalars['String']['input']>>;
  origin?: InputMaybe<Scalars['String']['input']>;
  statement: Scalars['String']['input'];
  subType?: InputMaybe<QuestionSubType>;
  subjectIds: Array<Scalars['String']['input']>;
  type: QuestionType;
};

export enum QuestionStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Suspended = 'SUSPENDED',
}

export enum QuestionSubType {
  AssertionReason = 'ASSERTION_REASON',
  ColumnAssociation = 'COLUMN_ASSOCIATION',
  ComplexMultipleChoice = 'COMPLEX_MULTIPLE_CHOICE',
  FillTheBlank = 'FILL_THE_BLANK',
  IncompleteStatement = 'INCOMPLETE_STATEMENT',
  Serialization = 'SERIALIZATION',
  SimpleMultipleChoice = 'SIMPLE_MULTIPLE_CHOICE',
  TrueOrFalse = 'TRUE_OR_FALSE',
}

export enum QuestionType {
  MultipleChoice = 'MULTIPLE_CHOICE',
}

export type RateArticleInput = {
  articleId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ReadInput = {
  creativeId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type ReadListInput = {
  creativeId: Array<InputMaybe<Scalars['String']['input']>>;
  studentId: Scalars['String']['input'];
};

export type Reason = {
  __typename?: 'Reason';
  code: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  openToStudent?: Maybe<Scalars['Boolean']['output']>;
  status: Array<Maybe<CourseEnrollmentStatus>>;
  studentDescription?: Maybe<Scalars['String']['output']>;
};

export type ReasonInput = {
  code: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  openToStudent?: InputMaybe<Scalars['Boolean']['input']>;
  status: Array<InputMaybe<CourseEnrollmentStatus>>;
  studentDescription?: InputMaybe<Scalars['String']['input']>;
};

export type ReasonList = {
  __typename?: 'ReasonList';
  data?: Maybe<Array<Maybe<Reason>>>;
  page?: Maybe<Page>;
};

export type ReasonSetInput = {
  code: Scalars['ID']['input'];
  description: Scalars['String']['input'];
};

export enum RecipentType {
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export type RedoExam = {
  __typename?: 'RedoExam';
  daysLeft?: Maybe<Scalars['Int']['output']>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
};

export type RegisterProductInterestRequest = {
  productOfferId: Scalars['ID']['input'];
};

export type RegisterProductInterestResponse = {
  __typename?: 'RegisterProductInterestResponse';
  interested: Scalars['Boolean']['output'];
};

export type RegistryResponsible = {
  __typename?: 'RegistryResponsible';
  document?: Maybe<Scalars['String']['output']>;
  idOrEnrollmentNumber?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RegistryResponsibleInput = {
  document?: InputMaybe<Scalars['String']['input']>;
  idOrEnrollmentNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum RegulationStatusType {
  Concluding = 'CONCLUDING',
  Freshman = 'FRESHMAN',
  UnspecifiedRegulationStatusType = 'UNSPECIFIED_REGULATION_STATUS_TYPE',
}

export type RemoveLearningUnitVersionInput = {
  learningUnitId: Scalars['ID']['input'];
  learningUnitVersionId: Scalars['ID']['input'];
};

export type ReopenOrderInput = {
  courseEnrollmentId: Scalars['ID']['input'];
  file?: InputMaybe<Scalars['Upload']['input']>;
  reason: Scalars['String']['input'];
  ticketNumber: Scalars['String']['input'];
};

export type ReprocessPaymentPlanResponse = {
  __typename?: 'ReprocessPaymentPlanResponse';
  payload?: Maybe<Scalars['String']['output']>;
};

export type RequestProgramContent = {
  courseEnrollmentId: Scalars['ID']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  reasonId: Scalars['ID']['input'];
};

export type RequestedDocument = {
  __typename?: 'RequestedDocument';
  description: Scalars['String']['output'];
  documentTypes: Array<RequestedDocumentType>;
  status: RequestedStudentDocumentStatus;
  title: Scalars['String']['output'];
  uploadType: RequestedDocumentUploadType;
};

export type RequestedDocumentSubType = {
  __typename?: 'RequestedDocumentSubType';
  id: Scalars['String']['output'];
  info?: Maybe<RequestedDocumentSubTypeInfo>;
  name: Scalars['String']['output'];
};

export type RequestedDocumentSubTypeInfo = {
  __typename?: 'RequestedDocumentSubTypeInfo';
  description?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RequestedDocumentType = {
  __typename?: 'RequestedDocumentType';
  documentSizeLimit: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  subTypes: Array<RequestedDocumentSubType>;
  supportedDocumentExtensions: Array<Scalars['String']['output']>;
};

export enum RequestedDocumentUploadType {
  Generic = 'GENERIC',
  Selfie = 'SELFIE',
}

export enum RequestedStudentDocumentStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Review = 'REVIEW',
}

export type RequestedStudentDocumentUpload = {
  file: Scalars['Upload']['input'];
  subTypeId: Scalars['String']['input'];
};

export type RequestedStudentDocuments = {
  __typename?: 'RequestedStudentDocuments';
  documents?: Maybe<Array<RequestedDocument>>;
  status?: Maybe<RequestedStudentDocumentStatus>;
};

export type ReviewContent = {
  __typename?: 'ReviewContent';
  attendanceStatus: AttendanceStatus;
  learningObjects?: Maybe<Array<LearningObjectEnrollment>>;
};

export enum RneClassification {
  Fronteirico = 'FRONTEIRICO',
  Provisorio = 'PROVISORIO',
  Residente = 'RESIDENTE',
}

export type RneInput = {
  birthDate: Scalars['String']['input'];
  classification: RneClassification;
  emissionNumber: Scalars['String']['input'];
  entryDate: Scalars['String']['input'];
  expeditionDate: Scalars['String']['input'];
  fatherName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  issuingAuthority: Scalars['String']['input'];
  motherName?: InputMaybe<Scalars['String']['input']>;
  validityDate: Scalars['String']['input'];
};

export type Role = {
  __typename?: 'Role';
  authorities: Array<Scalars['ID']['output']>;
  description: Scalars['String']['output'];
  name: Scalars['ID']['output'];
};

export type RoleConnection = {
  __typename?: 'RoleConnection';
  data: Array<Role>;
  page: Page;
};

export type RoleInput = {
  authorities: Array<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['ID']['input'];
};

export type SavedFile = {
  __typename?: 'SavedFile';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export enum SchoolingType {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type SecretariatStudentDocument = {
  __typename?: 'SecretariatStudentDocument';
  documents: Array<Maybe<Documents>>;
  enrollmentId: Scalars['ID']['output'];
};

export type Section = BaseSection & {
  __typename?: 'Section';
  audit?: Maybe<Audit>;
  id: Scalars['ID']['output'];
  learningObjects: Array<LearningObject>;
  order: Scalars['Int']['output'];
  pathUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SectionEnrollment = BaseSection & {
  __typename?: 'SectionEnrollment';
  attendanceStatus: AttendanceStatus;
  id: Scalars['ID']['output'];
  learningObjects: Array<LearningObjectEnrollment>;
  order: Scalars['Int']['output'];
  pathUrl?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<EnrollmentProgress>;
  title: Scalars['String']['output'];
};

export type SectionInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  learningObjects: Array<LearningObjectInput>;
  order: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Seller = {
  __typename?: 'Seller';
  birthDate: Scalars['String']['output'];
  couponCode?: Maybe<Scalars['String']['output']>;
  cpf: Scalars['String']['output'];
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  salesChannel?: Maybe<Scalars['String']['output']>;
  sellerCategory: SellerCategory;
  sellerCategoryId: Scalars['String']['output'];
  sellerCoupons?: Maybe<Array<Maybe<SellerCoupon>>>;
  sellerUnitId?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type SellerCategory = {
  __typename?: 'SellerCategory';
  couponPrefix: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  utmCampaign: Scalars['String']['output'];
  utmMedium: Scalars['String']['output'];
  utmSource: Scalars['String']['output'];
};

export type SellerCategoryData = {
  couponPrefix: Scalars['String']['input'];
  name: Scalars['String']['input'];
  utmCampaign: Scalars['String']['input'];
  utmMedium: Scalars['String']['input'];
  utmSource: Scalars['String']['input'];
};

export type SellerCategoryList = {
  __typename?: 'SellerCategoryList';
  data?: Maybe<Array<Maybe<SellerCategory>>>;
  page?: Maybe<Page>;
};

export type SellerCoupon = {
  __typename?: 'SellerCoupon';
  code?: Maybe<Scalars['String']['output']>;
  sellerId?: Maybe<Scalars['String']['output']>;
};

export type SellerCouponInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  sellerId?: InputMaybe<Scalars['String']['input']>;
};

export type SellerData = {
  birthDate: Scalars['String']['input'];
  cpf: Scalars['String']['input'];
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  salesChannel?: InputMaybe<Scalars['String']['input']>;
  sellerCategoryId: Scalars['String']['input'];
  sellerUnitId?: InputMaybe<Scalars['String']['input']>;
};

export type SellerList = {
  __typename?: 'SellerList';
  data?: Maybe<Array<Maybe<Seller>>>;
  page?: Maybe<Page>;
};

export type SellerRegional = {
  __typename?: 'SellerRegional';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SellerRegionalData = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SellerRegionalList = {
  __typename?: 'SellerRegionalList';
  data?: Maybe<Array<Maybe<SellerRegional>>>;
  page?: Maybe<Page>;
};

export type SellerUnit = {
  __typename?: 'SellerUnit';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  managerName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sellerRegionalId: Scalars['String']['output'];
};

export type SellerUnitData = {
  description: Scalars['String']['input'];
  managerName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sellerRegionalId: Scalars['String']['input'];
};

export type SellerUnitList = {
  __typename?: 'SellerUnitList';
  data?: Maybe<Array<Maybe<SellerUnit>>>;
  page?: Maybe<Page>;
};

export type SendStatusInput = {
  courseEnrollmentId: Scalars['ID']['input'];
  diplomaText: Scalars['String']['input'];
  enadeStatusId: Scalars['String']['input'];
  mention: Scalars['String']['input'];
  referenceYear: Scalars['Int']['input'];
  regulationStatusType: RegulationStatusType;
  testDate?: InputMaybe<Scalars['String']['input']>;
};

export type SignCandidateContractInput = {
  candidateId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
};

export type Simulator = {
  __typename?: 'Simulator';
  id: Scalars['String']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SmsTemplate = {
  __typename?: 'SmsTemplate';
  description: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status: SmsTemplateStatus;
  templateBody: Scalars['String']['output'];
  templateTitle?: Maybe<Scalars['String']['output']>;
};

export type SmsTemplateInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  status: SmsTemplateStatus;
  templateBody: Scalars['String']['input'];
  templateTitle?: InputMaybe<Scalars['String']['input']>;
};

export type SmsTemplateList = {
  __typename?: 'SmsTemplateList';
  data?: Maybe<Array<Maybe<SmsTemplate>>>;
  page?: Maybe<TokenPage>;
};

export enum SmsTemplateStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type SmsValidation = {
  __typename?: 'SmsValidation';
  status: NotificationStatus;
  validationId?: Maybe<Scalars['ID']['output']>;
};

export type SocialNameStatus = {
  __typename?: 'SocialNameStatus';
  documentSizeLimit?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<OrderResponse>;
  requestDocumentAgeMinor: Scalars['Boolean']['output'];
  supportedDocumentExtension?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  urlReferenceDocumentAgeMinor?: Maybe<Scalars['String']['output']>;
};

export type SocialNetwork = {
  __typename?: 'SocialNetwork';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SocialNetworkInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SofiaGetMeResponse = {
  __typename?: 'SofiaGetMeResponse';
  email: Scalars['String']['output'];
  forcePasswordReset: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  grantedAuthorities: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  personId: Scalars['ID']['output'];
  phoneNumber: Phone;
  termsAcceptanceDate?: Maybe<Scalars['DateTime']['output']>;
  termsAccepted: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
};

export type SofiaGetStudentData = {
  __typename?: 'SofiaGetStudentData';
  emailMask: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  forcePasswordReset: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  phoneNumberMask: Scalars['String']['output'];
};

export type SofiaLoginResponse = {
  __typename?: 'SofiaLoginResponse';
  accessToken: Scalars['ID']['output'];
  deviceWhiteListed: Scalars['Boolean']['output'];
  expiresIn: Scalars['Int']['output'];
  scope: Scalars['String']['output'];
  tokenType: Scalars['String']['output'];
};

export type Sort = {
  field: Scalars['String']['input'];
  order: Order;
};

export enum Source {
  Ampli = 'AMPLI',
  Kroton = 'KROTON',
  Sap = 'SAP',
}

export type SpecialNeed = {
  __typename?: 'SpecialNeed';
  description: Scalars['String']['output'];
};

export type SpecialNeedInput = {
  description: Scalars['String']['input'];
};

export enum SpecialNeeds {
  AttentionDeficit = 'ATTENTION_DEFICIT',
  AutismSpectrumDisorder = 'AUTISM_SPECTRUM_DISORDER',
  Blindness = 'BLINDNESS',
  Deafness = 'DEAFNESS',
  DeafBlindness = 'DEAF_BLINDNESS',
  Dyscalculia = 'DYSCALCULIA',
  Dyslexia = 'DYSLEXIA',
  HearingImpairment = 'HEARING_IMPAIRMENT',
  HighSkills = 'HIGH_SKILLS',
  IntellectualDisability = 'INTELLECTUAL_DISABILITY',
  LowVision = 'LOW_VISION',
  MonocularVision = 'MONOCULAR_VISION',
  PhysicalDisabilityMobility = 'PHYSICAL_DISABILITY_MOBILITY',
  SpeechImpairments = 'SPEECH_IMPAIRMENTS',
}

export type State = {
  __typename?: 'State';
  acronym: Scalars['String']['output'];
  country: Country;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type StateConnection = {
  __typename?: 'StateConnection';
  data: Array<State>;
  page: Page;
};

export enum Status {
  Blocked = 'BLOCKED',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Open = 'OPEN',
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  Pending = 'PENDING',
  Underpaid = 'UNDERPAID',
}

export type Student = {
  __typename?: 'Student';
  address: Address;
  audit: Audit;
  bio?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  cityOfBirth?: Maybe<City>;
  cityOfBirthId?: Maybe<Scalars['Int']['output']>;
  contractId?: Maybe<Scalars['String']['output']>;
  contractSigned?: Maybe<Scalars['Boolean']['output']>;
  contractUrl?: Maybe<Scalars['String']['output']>;
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  declaredRace?: Maybe<DeclaredRace>;
  document: Scalars['DocumentNumber']['output'];
  documents?: Maybe<Array<Maybe<StudentDocument>>>;
  driverLicense?: Maybe<Scalars['String']['output']>;
  email: Scalars['EmailAddress']['output'];
  enrollments: Array<CourseEnrollment>;
  fullName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  issuingAuthority?: Maybe<Scalars['String']['output']>;
  maritalStatus?: Maybe<MaritalStatus>;
  mobilePhone: Scalars['String']['output'];
  nationalIdentity?: Maybe<Scalars['String']['output']>;
  nationalIdentityState?: Maybe<State>;
  nationalIdentityStateId?: Maybe<Scalars['Int']['output']>;
  optIns?: Maybe<Array<Maybe<OptIn>>>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  schoolingType?: Maybe<SchoolingType>;
  socialNetworks?: Maybe<Array<Maybe<StudentSocialNetwork>>>;
  specialNeeds?: Maybe<Array<Maybe<SpecialNeed>>>;
  stateOfBirth?: Maybe<State>;
  stateOfBirthId?: Maybe<Scalars['Int']['output']>;
  tracks: Array<Maybe<TrackInput>>;
  underageInfo?: Maybe<UnderageStudentInfo>;
};

export type StudentAddress = {
  __typename?: 'StudentAddress';
  address: AddressRegistration;
  enrollmentId: Scalars['String']['output'];
  secondaryAddress?: Maybe<AddressRegistration>;
};

export type StudentAddressInput = {
  address?: InputMaybe<AddressRegistrationInput>;
  enrollmentId: Scalars['String']['input'];
  secondaryAddress?: InputMaybe<AddressRegistrationInput>;
};

export type StudentCensusUpdateInput = {
  declaredRace: DeclaredRace;
  schoolingType: SchoolingType;
};

export type StudentComplementaryFields = {
  __typename?: 'StudentComplementaryFields';
  homePhone: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  secondEmail: Scalars['String']['output'];
  secondPhone: Scalars['String']['output'];
  socialName: Scalars['String']['output'];
};

export type StudentConnection = {
  __typename?: 'StudentConnection';
  data?: Maybe<Array<Student>>;
  page: Page;
};

export type StudentContact = {
  __typename?: 'StudentContact';
  enrollmentId: Scalars['String']['output'];
  hasMainWhatsapp?: Maybe<Scalars['Boolean']['output']>;
  hasSecondWhatsapp?: Maybe<Scalars['Boolean']['output']>;
  homePhone?: Maybe<Scalars['String']['output']>;
  mainEmail?: Maybe<Scalars['String']['output']>;
  mainPhone?: Maybe<Scalars['String']['output']>;
  notReceiveEmail?: Maybe<Scalars['Boolean']['output']>;
  notReceiveSms?: Maybe<Scalars['Boolean']['output']>;
  notReceiveWhatsapp?: Maybe<Scalars['Boolean']['output']>;
  secondEmail?: Maybe<Scalars['String']['output']>;
  secondPhone?: Maybe<Scalars['String']['output']>;
};

export type StudentContactInput = {
  enrollmentId: Scalars['String']['input'];
  hasMainWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
  hasSecondWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  mainEmail?: InputMaybe<Scalars['String']['input']>;
  mainPhone?: InputMaybe<Scalars['String']['input']>;
  notReceiveEmail?: InputMaybe<Scalars['Boolean']['input']>;
  notReceiveSms?: InputMaybe<Scalars['Boolean']['input']>;
  notReceiveWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
  secondEmail?: InputMaybe<Scalars['String']['input']>;
  secondPhone?: InputMaybe<Scalars['String']['input']>;
};

export type StudentCreditCard = {
  __typename?: 'StudentCreditCard';
  audit: Audit;
  creditCard: CreditCard;
  id: Scalars['ID']['output'];
  isRecurring: Scalars['Boolean']['output'];
};

export type StudentData = {
  __typename?: 'StudentData';
  accountVerified: Scalars['Boolean']['output'];
  emailMask: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  passwordExpired: Scalars['Boolean']['output'];
  phoneNumberMask: Scalars['String']['output'];
};

export type StudentDocument = {
  __typename?: 'StudentDocument';
  document?: Maybe<Document>;
  documentType: DocumentType;
};

export type StudentInput = {
  address: AddressInput;
  bio?: InputMaybe<Scalars['String']['input']>;
  birthDate: Scalars['String']['input'];
  cityOfBirthId?: InputMaybe<Scalars['Int']['input']>;
  contractId?: InputMaybe<Scalars['String']['input']>;
  contractSigned?: InputMaybe<Scalars['Boolean']['input']>;
  contractUrl?: InputMaybe<Scalars['String']['input']>;
  countryOfBirth: Scalars['String']['input'];
  document: Scalars['DocumentNumber']['input'];
  driverLicense?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailAddress']['input'];
  fullName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  issuingAuthority?: InputMaybe<Scalars['String']['input']>;
  maritalStatus: MaritalStatus;
  mobilePhone: Scalars['String']['input'];
  nationalIdentity?: InputMaybe<Scalars['String']['input']>;
  nationalIdentityStateId?: InputMaybe<Scalars['Int']['input']>;
  optIns?: InputMaybe<Array<InputMaybe<OptInInput>>>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  socialNetworks?: InputMaybe<Array<InputMaybe<StudentSocialNetworkInput>>>;
  specialNeeds?: InputMaybe<Array<InputMaybe<SpecialNeedInput>>>;
  stateOfBirthId?: InputMaybe<Scalars['Int']['input']>;
  underageInfo?: InputMaybe<UnderageStudentInfoInput>;
};

export type StudentPhoto = {
  __typename?: 'StudentPhoto';
  enrollmentId: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
};

export type StudentProfileUpdateInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailAddress']['input'];
  mobilePhone: Scalars['String']['input'];
  optIns?: InputMaybe<Array<InputMaybe<OptInInput>>>;
  socialNetworks?: InputMaybe<Array<InputMaybe<StudentSocialNetworkInput>>>;
};

export type StudentRegistration = {
  __typename?: 'StudentRegistration';
  birthDate?: Maybe<Scalars['String']['output']>;
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  declaredRace?: Maybe<DeclaredRaceStudent>;
  document?: Maybe<Scalars['DocumentNumber']['output']>;
  enrollmentId: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<DescribedEnum>;
  hasSpecialNeeds?: Maybe<Scalars['Boolean']['output']>;
  identityDocument: IdentityDocument;
  maritalStatus?: Maybe<DescribedEnum>;
  socialName?: Maybe<Scalars['String']['output']>;
  socialNameInDocuments?: Maybe<Scalars['Boolean']['output']>;
  specialNeeds?: Maybe<Array<Maybe<SpecialNeeds>>>;
  underageInfo?: Maybe<UnderageStudentInfo>;
  warningMessage?: Maybe<Scalars['String']['output']>;
};

export type StudentRegistrationInput = {
  declaredRace?: InputMaybe<DeclaredRaceStudent>;
  enrollmentId: Scalars['String']['input'];
  specialNeeds?: InputMaybe<Array<InputMaybe<SpecialNeeds>>>;
};

export type StudentSocialNetwork = {
  __typename?: 'StudentSocialNetwork';
  id?: Maybe<Scalars['ID']['output']>;
  idOnSocialNetwork?: Maybe<Scalars['String']['output']>;
  socialNetwork?: Maybe<SocialNetwork>;
};

export type StudentSocialNetworkInput = {
  id: Scalars['ID']['input'];
  idOnSocialNetwork: Scalars['String']['input'];
  socialNetwork: SocialNetworkInput;
};

export type StudentSubjects = {
  __typename?: 'StudentSubjects';
  enrollment?: Maybe<SubjectEnrollment>;
  isDependency?: Maybe<Scalars['Boolean']['output']>;
  isElective?: Maybe<Scalars['Boolean']['output']>;
  isFuture?: Maybe<Scalars['Boolean']['output']>;
  subject: Subject;
};

export type StudentsByEnrollmentSource = {
  studentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum StyleTemplateNotification {
  Campaign = 'CAMPAIGN',
  Journey = 'JOURNEY',
}

export type SubCategory = {
  __typename?: 'SubCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum SubType {
  AcoEd = 'ACO_ED',
  Discipline = 'DISCIPLINE',
  IntegratedProject = 'INTEGRATED_PROJECT',
  MandatoryInternship = 'MANDATORY_INTERNSHIP',
  PedagogicalPractice = 'PEDAGOGICAL_PRACTICE',
  TccTfg = 'TCC_TFG',
  Unspecified = 'UNSPECIFIED',
}

export type SubTypeAccepted = {
  __typename?: 'SubTypeAccepted';
  accept?: Maybe<Scalars['String']['output']>;
  documentSubType?: Maybe<Array<Maybe<DocumentSubType>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Subject = {
  __typename?: 'Subject';
  audit: Audit;
  bibliography?: Maybe<Scalars['String']['output']>;
  classHours: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  courses: Array<Maybe<SubjectCourse>>;
  description?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Array<Goal>>;
  gradingMethodId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  introduction?: Maybe<Scalars['String']['output']>;
  learningUnits?: Maybe<Array<SubjectLearningUnit>>;
  name: Scalars['String']['output'];
  pathUrl?: Maybe<Scalars['String']['output']>;
  professor: Scalars['String']['output'];
  programContent?: Maybe<Scalars['String']['output']>;
  publicationDate?: Maybe<Scalars['String']['output']>;
  publicationStatus?: Maybe<PublicationStatus>;
  simulators?: Maybe<Array<SubjectSimulator>>;
  summary: Scalars['String']['output'];
};

export type SubjectConnection = {
  __typename?: 'SubjectConnection';
  data: Array<Subject>;
  page: Page;
};

export type SubjectCourse = {
  __typename?: 'SubjectCourse';
  code: Scalars['String']['output'];
  courseId: Scalars['ID']['output'];
  courseVersionIds: Array<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  status: CourseStatus;
};

export type SubjectEnrollment = {
  __typename?: 'SubjectEnrollment';
  assignmentStartEndInterval?: Maybe<Scalars['Interval']['output']>;
  assignments?: Maybe<Array<Maybe<SubjectEnrollmentAssignment>>>;
  choices?: Maybe<Array<Maybe<Subject>>>;
  choicesIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  closingDate?: Maybe<Scalars['String']['output']>;
  courseSubject: CourseSubject;
  courseSubjectId?: Maybe<Scalars['String']['output']>;
  courseSubjectType: SubjectType;
  endDate: Scalars['String']['output'];
  endDateForChoice?: Maybe<Scalars['String']['output']>;
  examStartEndInterval?: Maybe<Scalars['Interval']['output']>;
  exams?: Maybe<Array<Maybe<Exam>>>;
  finalGrade?: Maybe<Scalars['Int']['output']>;
  finishedExams?: Maybe<Array<Maybe<Exam>>>;
  gradingMethod?: Maybe<GradingMethod>;
  hasCompletedExams?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isDependency: Scalars['Boolean']['output'];
  isElective: Scalars['Boolean']['output'];
  learningUnits?: Maybe<Array<Maybe<LearningUnitEnrollment>>>;
  mandatoryInternship?: Maybe<MandatoryInternship>;
  progress?: Maybe<SubjectEnrollmentProgress>;
  startDate: Scalars['String']['output'];
  startDateForChoice?: Maybe<Scalars['String']['output']>;
  startEndInterval?: Maybe<Scalars['Interval']['output']>;
  status: SubjectEnrollmentStatus;
  subject?: Maybe<Subject>;
  subjectExamDurationInMinutes?: Maybe<Scalars['Int']['output']>;
  subjectExamWeight?: Maybe<Scalars['Int']['output']>;
  subjectId?: Maybe<Scalars['String']['output']>;
  tracks: Array<Maybe<TrackInput>>;
  transferredFrom?: Maybe<Scalars['String']['output']>;
  transferredTo?: Maybe<Scalars['String']['output']>;
};

export type SubjectEnrollmentAssignment = {
  __typename?: 'SubjectEnrollmentAssignment';
  detail?: Maybe<Assignment>;
  grade?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  maxGrade: Scalars['Int']['output'];
  status?: Maybe<SubjectEnrollmentAssignmentStatus>;
};

export enum SubjectEnrollmentAssignmentStatus {
  Available = 'AVAILABLE',
  Disapproved = 'DISAPPROVED',
  Done = 'DONE',
  NotAvailable = 'NOT_AVAILABLE',
  Ongoing = 'ONGOING',
}

export type SubjectEnrollmentProgress = {
  __typename?: 'SubjectEnrollmentProgress';
  completed?: Maybe<Scalars['Int']['output']>;
  details: Array<SubjectEnrollmentProgressDetail>;
  percentage?: Maybe<Scalars['Int']['output']>;
  percentageLabel?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
};

export type SubjectEnrollmentProgressDetail = {
  __typename?: 'SubjectEnrollmentProgressDetail';
  completed?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
  percentageLabel?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  type: AssignmentConfigType;
};

export enum SubjectEnrollmentStatus {
  AwaitingChoice = 'AWAITING_CHOICE',
  AwaitingEnrollment = 'AWAITING_ENROLLMENT',
  Canceled = 'CANCELED',
  Ceased = 'CEASED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  InRecovery = 'IN_RECOVERY',
  Passed = 'PASSED',
  Paused = 'PAUSED',
  PreEnrollment = 'PRE_ENROLLMENT',
  Unavailable = 'UNAVAILABLE',
}

export type SubjectEnrollmentWithName = {
  __typename?: 'SubjectEnrollmentWithName';
  externalId: Scalars['String']['output'];
  subjectName: Scalars['String']['output'];
};

export type SubjectInput = {
  bibliography?: InputMaybe<Scalars['String']['input']>;
  classHours: Scalars['Int']['input'];
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  goals?: InputMaybe<Array<GoalInput>>;
  gradingMethodId?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  introduction?: InputMaybe<Scalars['String']['input']>;
  learningUnits?: InputMaybe<Array<SubjectLearningUnitInput>>;
  name: Scalars['String']['input'];
  professor: Scalars['String']['input'];
  programContent?: InputMaybe<Scalars['String']['input']>;
  simulators?: InputMaybe<Array<SubjectSimulatorInput>>;
  summary: Scalars['String']['input'];
};

export type SubjectLearningUnit = {
  __typename?: 'SubjectLearningUnit';
  id: Scalars['ID']['output'];
  learningUnit: LearningUnit;
  learningUnitId: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export type SubjectLearningUnitInput = {
  id: Scalars['ID']['input'];
  learningUnitId: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type SubjectSimulator = {
  __typename?: 'SubjectSimulator';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SubjectSimulatorInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SubjectStaff = {
  __typename?: 'SubjectStaff';
  teacher?: Maybe<Teacher>;
};

export enum SubjectType {
  Parallel = 'PARALLEL',
  Sequential = 'SEQUENTIAL',
}

export type SuspensionPeriod = {
  __typename?: 'SuspensionPeriod';
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
};

export type SuspensionPeriodInput = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type TableAsset = Asset & {
  __typename?: 'TableAsset';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: AssetType;
};

export type TargetGroup = {
  __typename?: 'TargetGroup';
  courseTypeSupport?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  queryId: Scalars['String']['output'];
  queryParameterList?: Maybe<Array<TargetGroupQueryParameter>>;
  scheduleConfigList?: Maybe<Array<Maybe<TargetGroupScheduleConfig>>>;
  status: TargetGroupStatus;
  templateDataList?: Maybe<Array<TargetGroupTemplateData>>;
};

export enum TargetGroupDataType {
  Boolean = 'BOOLEAN',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  Select = 'SELECT',
  String = 'STRING',
}

export type TargetGroupInput = {
  courseTypeSupport: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  queryId: Scalars['String']['input'];
  queryParameterList?: InputMaybe<Array<TargetGroupQueryParameterInput>>;
  scheduleConfigList?: InputMaybe<Array<TargetGroupScheduleConfigInput>>;
  status: TargetGroupStatus;
  templateDataList?: InputMaybe<Array<TargetGroupTemplateDataInput>>;
};

export type TargetGroupList = {
  __typename?: 'TargetGroupList';
  data?: Maybe<Array<Maybe<TargetGroup>>>;
  page?: Maybe<Page>;
};

export type TargetGroupQueryParameter = {
  __typename?: 'TargetGroupQueryParameter';
  dataType: TargetGroupDataType;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  selectOptions?: Maybe<Scalars['String']['output']>;
  targetGroupId?: Maybe<Scalars['ID']['output']>;
};

export type TargetGroupQueryParameterInput = {
  dataType: TargetGroupDataType;
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  selectOptions?: InputMaybe<Scalars['String']['input']>;
  targetGroupId?: InputMaybe<Scalars['ID']['input']>;
};

export type TargetGroupScheduleConfig = {
  __typename?: 'TargetGroupScheduleConfig';
  id: Scalars['ID']['output'];
  scheduleValue: TargetGroupScheduleType;
  targetGroupId?: Maybe<Scalars['ID']['output']>;
};

export type TargetGroupScheduleConfigInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  scheduleValue: TargetGroupScheduleType;
  targetGroupId?: InputMaybe<Scalars['ID']['input']>;
};

export enum TargetGroupScheduleType {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Weekly = 'WEEKLY',
}

export enum TargetGroupStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type TargetGroupTemplateData = {
  __typename?: 'TargetGroupTemplateData';
  dataType: TargetGroupDataType;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  targetGroupId?: Maybe<Scalars['ID']['output']>;
  templateParameter: Scalars['String']['output'];
};

export type TargetGroupTemplateDataInput = {
  dataType: TargetGroupDataType;
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  targetGroupId?: InputMaybe<Scalars['ID']['input']>;
  templateParameter: Scalars['String']['input'];
};

export type Teacher = {
  __typename?: 'Teacher';
  document: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  resume: Array<TeacherResume>;
  signature?: Maybe<Scalars['String']['output']>;
};

export enum TeacherCourseType {
  Graduate = 'GRADUATE',
  MastersDegree = 'MASTERS_DEGREE',
  Phd = 'PHD',
  PostGraduate = 'POST_GRADUATE',
  Specialty = 'SPECIALTY',
}

export type TeacherInput = {
  document: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['Upload']['input']>;
  resume: Array<TeacherResumeInput>;
  signature?: InputMaybe<Scalars['Upload']['input']>;
};

export type TeacherPage = {
  __typename?: 'TeacherPage';
  data: Array<Teacher>;
  page: Page;
};

export type TeacherResume = {
  __typename?: 'TeacherResume';
  id: Scalars['ID']['output'];
  institution?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titration: Scalars['String']['output'];
  type: TeacherCourseType;
};

export type TeacherResumeInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  institution?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: TeacherCourseType;
};

export type TextAsset = Asset & {
  __typename?: 'TextAsset';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: AssetType;
};

export type TextCustomization = {
  __typename?: 'TextCustomization';
  fontSize: Scalars['Int']['output'];
  formatAlign: Scalars['String']['output'];
  formatFont: Scalars['String']['output'];
  studentId: Scalars['String']['output'];
  themeId: Scalars['Int']['output'];
};

export type TextCustomizationInput = {
  fontSize: Scalars['Int']['input'];
  formatAlign: Scalars['String']['input'];
  formatFont: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
  themeId: Scalars['Int']['input'];
};

export type Titration = {
  __typename?: 'Titration';
  description: Scalars['String']['output'];
  type: TeacherCourseType;
};

export type TokenPage = {
  __typename?: 'TokenPage';
  nextPageToken?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  previousPageToken?: Maybe<Scalars['String']['output']>;
  size: Scalars['Int']['output'];
  totalElements?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type TokenPagination = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type Track = {
  __typename?: 'Track';
  inputs: Array<Maybe<TrackInput>>;
  referenceId: Scalars['ID']['output'];
};

export type TrackInput = {
  __typename?: 'TrackInput';
  createdDate: Scalars['String']['output'];
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
  lastModifiedDate: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TransferSimulation = {
  __typename?: 'TransferSimulation';
  currentCourse: Course;
  currentCourseId: Scalars['ID']['output'];
  currentCourseVersionId: Scalars['ID']['output'];
  financialSimulation?: Maybe<FinancialSimulation>;
  passedSubjects: Array<PassedSubject>;
  student: Student;
  studentId: Scalars['ID']['output'];
  targetCourse: Course;
  targetCourseId: Scalars['ID']['output'];
};

export type Tutor = {
  __typename?: 'Tutor';
  subjectName?: Maybe<Scalars['String']['output']>;
  tutor?: Maybe<Teacher>;
};

export type TypeConfig = {
  __typename?: 'TypeConfig';
  id?: Maybe<Scalars['ID']['output']>;
  modelSubType?: Maybe<ModelSubType>;
  modelType?: Maybe<ModelType>;
  presignedUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TypeConfigInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  modelSubType?: InputMaybe<ModelSubType>;
  modelType?: InputMaybe<ModelType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UnderageCandidateInfo = {
  __typename?: 'UnderageCandidateInfo';
  fatherName?: Maybe<Scalars['String']['output']>;
  guardianName?: Maybe<Scalars['String']['output']>;
  motherName?: Maybe<Scalars['String']['output']>;
};

export type UnderageCandidateInfoInput = {
  fatherName?: InputMaybe<Scalars['String']['input']>;
  guardianName?: InputMaybe<Scalars['String']['input']>;
  motherName?: InputMaybe<Scalars['String']['input']>;
};

export type UnderageStudentInfo = {
  __typename?: 'UnderageStudentInfo';
  fatherName?: Maybe<Scalars['String']['output']>;
  guardianName?: Maybe<Scalars['String']['output']>;
  motherName?: Maybe<Scalars['String']['output']>;
};

export type UnderageStudentInfoInput = {
  fatherName?: InputMaybe<Scalars['String']['input']>;
  guardianName?: InputMaybe<Scalars['String']['input']>;
  motherName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAffiliateInput = {
  address: AddressInput;
  collaborateId?: InputMaybe<Scalars['String']['input']>;
  courseDegree: CourseDegree;
  id: Scalars['ID']['input'];
  mecCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  ownerPhone: Scalars['String']['input'];
  status: AffiliateStatus;
};

export type UpdateAnnotationInput = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateCandidateContractInfoInput = {
  address: AddressInput;
  birthDate: Scalars['String']['input'];
  cityOfBirthId?: InputMaybe<Scalars['Int']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  countryOfBirthId?: InputMaybe<Scalars['Int']['input']>;
  fullName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  id: Scalars['ID']['input'];
  issuingAuthority?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  mobilePhone: Scalars['String']['input'];
  nationalIdentity?: InputMaybe<Scalars['String']['input']>;
  nationalIdentityStateId?: InputMaybe<Scalars['Int']['input']>;
  rneDocument?: InputMaybe<Scalars['String']['input']>;
  stateOfBirthId?: InputMaybe<Scalars['Int']['input']>;
  underageInfo?: InputMaybe<UnderageCandidateInfoInput>;
};

export type UpdateCandidateExamInput = {
  answer: Scalars['String']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateCandidateInput = {
  address?: InputMaybe<AddressInput>;
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  cityOfBirthId?: InputMaybe<Scalars['Int']['input']>;
  contractId?: InputMaybe<Scalars['String']['input']>;
  contractSigned?: InputMaybe<Scalars['Boolean']['input']>;
  contractUrl?: InputMaybe<Scalars['String']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  countryOfBirthId?: InputMaybe<Scalars['Int']['input']>;
  courseId: Scalars['String']['input'];
  coursePriceId?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['DocumentNumber']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enemGrade?: InputMaybe<Scalars['Int']['input']>;
  enemOption?: InputMaybe<Scalars['Boolean']['input']>;
  fullName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  id: Scalars['ID']['input'];
  issuingAuthority?: InputMaybe<Scalars['String']['input']>;
  leadCityId?: InputMaybe<Scalars['Int']['input']>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  mobilePhone: Scalars['String']['input'];
  nationalIdentity?: InputMaybe<Scalars['String']['input']>;
  nationalIdentityStateId?: InputMaybe<Scalars['Int']['input']>;
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  rneDocument?: InputMaybe<Scalars['String']['input']>;
  sellerId?: InputMaybe<Scalars['String']['input']>;
  specialNeeds?: InputMaybe<Array<SpecialNeedInput>>;
  stateOfBirthId?: InputMaybe<Scalars['Int']['input']>;
  status: CandidateStatus;
  underageInfo?: InputMaybe<UnderageCandidateInfoInput>;
};

export type UpdateInstalmentInput = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  discount: Scalars['Money']['input'];
  dueDate: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  otherIncrement: Scalars['Money']['input'];
  reason: Scalars['String']['input'];
};

export type UpdateLearningUnitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type UpdateManyAnnotationsInput = {
  updateAnnotations: Array<InputMaybe<UpdateAnnotationInput>>;
};

export type UpdateProductOfferInput = {
  courseDegrees?: InputMaybe<Array<InputMaybe<CourseDegree>>>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta: Scalars['String']['input'];
  displayedIn: DisplayPlace;
  displayedInCourses?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  firstDescription: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  imageId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  secondDescription?: InputMaybe<Scalars['String']['input']>;
  sequence: Scalars['Int']['input'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  thirdDescription?: InputMaybe<Scalars['String']['input']>;
  urlCode: Scalars['String']['input'];
  urlWebPage: Scalars['String']['input'];
};

export type UpdateRoleInput = {
  authorities?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['ID']['input'];
};

export type UpdateSectionInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSellerData = {
  birthDate: Scalars['String']['input'];
  cpf: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  sellerCategoryId: Scalars['String']['input'];
  sellerCoupons?: InputMaybe<Array<InputMaybe<SellerCouponInput>>>;
  sellerUnitId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStatus = {
  __typename?: 'UpdateStatus';
  order?: Maybe<OrderResponse>;
  status: OrderStatus;
};

export type UpdateUserInput = {
  id: Scalars['ID']['input'];
  password: Scalars['String']['input'];
  personId: Scalars['ID']['input'];
  realm: Scalars['String']['input'];
  roles?: InputMaybe<Array<Scalars['ID']['input']>>;
  secretAnswer?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UploadStatus = {
  __typename?: 'UploadStatus';
  order?: Maybe<OrderResponse>;
  status: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID']['output'];
  passwordExpired: Scalars['Boolean']['output'];
  personId: Scalars['ID']['output'];
  phoneNumberVerified: Scalars['Boolean']['output'];
  realm: Scalars['String']['output'];
  roles: Array<Scalars['ID']['output']>;
  secretAnswer?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  data: Array<User>;
  page: Page;
};

export type UserInput = {
  password: Scalars['String']['input'];
  personId: Scalars['ID']['input'];
  realm: Scalars['String']['input'];
  roles: Array<Scalars['ID']['input']>;
  secretAnswer?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UserLoginInfo = {
  __typename?: 'UserLoginInfo';
  firstName: Scalars['String']['output'];
  lastPhoneNumberDigits: Scalars['String']['output'];
  passwordExpired: Scalars['Boolean']['output'];
  phoneNumberVerification?: Maybe<PhoneNumberVerification>;
  userId: Scalars['ID']['output'];
};

export type ValidationData = {
  __typename?: 'ValidationData';
  status: NotificationStatus;
  verificationId?: Maybe<Scalars['ID']['output']>;
};

export type VideoAsset = Asset & {
  __typename?: 'VideoAsset';
  captionLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mediastreamId?: Maybe<Scalars['String']['output']>;
  size: Scalars['String']['output'];
  type: AssetType;
  videoLink: Scalars['String']['output'];
};

export type VideoAssetSizeArgs = {
  originalFormat?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Warnings = {
  __typename?: 'Warnings';
  messages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type WeeklySchedule = {
  __typename?: 'WeeklySchedule';
  fri?: Maybe<DailyPeriod>;
  mon?: Maybe<DailyPeriod>;
  sat?: Maybe<DailyPeriod>;
  sun?: Maybe<DailyPeriod>;
  thu?: Maybe<DailyPeriod>;
  tue?: Maybe<DailyPeriod>;
  wed?: Maybe<DailyPeriod>;
};

export type WeeklyScheduleInput = {
  fri?: InputMaybe<DailyPeriodInput>;
  mon?: InputMaybe<DailyPeriodInput>;
  sat?: InputMaybe<DailyPeriodInput>;
  sun?: InputMaybe<DailyPeriodInput>;
  thu?: InputMaybe<DailyPeriodInput>;
  tue?: InputMaybe<DailyPeriodInput>;
  wed?: InputMaybe<DailyPeriodInput>;
};

export type WhatsappTemplate = {
  __typename?: 'WhatsappTemplate';
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type WhatsappTemplateList = {
  __typename?: 'WhatsappTemplateList';
  data?: Maybe<Array<Maybe<WhatsappTemplate>>>;
  page?: Maybe<TokenPage>;
};

export type Whitelist = {
  __typename?: 'Whitelist';
  audit: Audit;
  deviceId: Scalars['String']['output'];
  deviceType: DeviceType;
  id: Scalars['ID']['output'];
  username: Scalars['String']['output'];
};

export type WriteOffInstalmentInput = {
  instalmentId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type GetMonthOfNewBillingDayResponse = {
  __typename?: 'getMonthOfNewBillingDayResponse';
  monthOfNewBillingDay: Scalars['Int']['output'];
};
