import React from 'react';
import { Skeleton } from '../../../../components';
import * as Styled from './suspension-cancellation-skeleton.styles';

export default function SuspensionCancellationSkeleton() {
  return (
    <Styled.Container tabIndex={-1}>
      <Styled.Column>
        <Skeleton height={48} margin="0 0 8px 0" />

        <Skeleton height={24} />
        <Skeleton height={36} margin="40px 0 8px 0" />
        <ul>
          <li>
            <Skeleton height={48} margin="0 0 8px 0" />
          </li>
          <li>
            <Skeleton height={48} margin="0 0 8px 0" />
          </li>
          <li>
            <Skeleton height={48} margin="0 0 8px 0" />
          </li>
          <li>
            <Skeleton height={48} />
          </li>
        </ul>
      </Styled.Column>

      <Skeleton height={380} margin="0 0 0 24px" />
    </Styled.Container>
  );
}
