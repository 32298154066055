import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  display: flex;
  padding: 20px;
`;

export const ColLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1.5;
  padding: 20px;
  ${mediaQuery('mobile')} {
    justify-content: center;
  }
`;

export const ContainerText = styled.div`
  margin: 0 20px;
  max-width: 296px;
  width: 100%;

  @media (max-width: 1024px) {
    margin: 20px 20px 0 0;
  }

  ${mediaQuery('mobile')} {
    margin: 20px 0;
    max-width: none;
  }
`;

export const ColRight = styled.div`
  display: flex;
  flex: 1;
  ${mediaQuery('mobile')} {
    justify-content: center;
  }
`;

export const Img = styled.img`
  max-width: 203px;
  max-height: 210px;
  margin-right: 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ContainerButton = styled.div`
  &,
  a {
    width: 100%;
  }
`;
