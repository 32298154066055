import React from 'react';
import PropTypes from 'prop-types';
import { LayoutContent } from '../../../layout';
import { ServiceRequestSkeleton } from '../skeleton';
import {
  Container,
  ColLeft,
  ColRight,
  ContainerText,
} from './service-request-content-styles';
import { Typography, ButtonPrimary } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import Card from '../card/card';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import {
  analyticsEvents,
  serviceRequestContentTexts,
  serviceRequestSteps,
} from '../../constants';
import { useCreateNewOrderStudent } from '@ampli/services';
import { pushAnhangueraEvent } from '../../../../lib/ga';

export default function SecretaryRegistrationContent({
  loading,
  typeOfStatus,
  itsExpirationDate,
  courseEnrollmentId,
  orderSubject,
  data,
  setStep,
}) {
  const theme = useTheme();
  const texts = serviceRequestContentTexts[typeOfStatus];

  const [mutate] = useCreateNewOrderStudent(courseEnrollmentId, orderSubject, {
    onCompleted: ({ data, errors }) => {
      if (data && !errors) {
        setStep(serviceRequestSteps.loading);
      } else {
        console.log('errors', errors);
      }
    },
  });

  const handleSend = () => {
    pushAnhangueraEvent(
      'event',
      analyticsEvents[typeOfStatus]?.requestDocument
    );

    mutate();
  };

  const treatingDateText = (date) => {
    const formatingDate = new Date(date.replaceAll('-', '/'));
    return format(formatingDate, 'dd/MM/yyyy', {
      locale: pt,
    });
  };

  return (
    <>
      <LayoutContent>
        {loading ? (
          <ServiceRequestSkeleton />
        ) : (
          <Container>
            <ColLeft>
              <ContainerText>
                <Typography
                  margin="0 0 10px 0"
                  weight="700"
                  color={theme.colors.text.black}
                  size={24}
                >
                  {texts?.titleText}
                </Typography>
                <Typography
                  margin="0px"
                  color={theme.colors.text.black}
                  size={16}
                >
                  {texts?.textDesc}
                </Typography>
              </ContainerText>
            </ColLeft>
            <ColRight>
              <Card noBorder>
                <Typography
                  margin="0 0 20px 0"
                  color={theme.colors.text.black}
                  weight="700"
                  size={32}
                >
                  {texts?.titleCard}
                </Typography>

                {itsExpirationDate ? (
                  <Typography
                    margin="0 0 20px 0"
                    color={theme.colors.text.black}
                    size={16}
                  >
                    No dia{' '}
                    <strong>{treatingDateText(data?.documentValidity)}</strong>{' '}
                    {texts?.expirationDate}
                  </Typography>
                ) : (
                  <Typography
                    margin="0 0 20px 0"
                    color={theme.colors.text.black}
                    size={16}
                  >
                    {texts?.textDescCard}
                  </Typography>
                )}

                <ButtonPrimary
                  width="100%"
                  background={theme.colors.button.primary}
                  onClick={handleSend}
                  color={theme.colors.button.text.primary}
                >
                  Solicitar
                </ButtonPrimary>
              </Card>
            </ColRight>
          </Container>
        )}
      </LayoutContent>
    </>
  );
}

SecretaryRegistrationContent.propTypes = {
  loading: PropTypes.bool,
  typeOfStatus: PropTypes.string,
  itsExpirationDate: PropTypes.bool,
  courseEnrollmentId: PropTypes.string,
  orderSubject: PropTypes.string,
  data: PropTypes.object,
  setStep: PropTypes.func,
};
