import { gql } from 'graphql.macro';

export const GENERATE_SCHOLARSHIP_DOCUMENT_UPLOAD_URL = gql`
  mutation GenerateScholarshipDocumentUploadUrl(
    $courseEnrollmentId: ID!
    $id: ID!
    $subTypeId: [String!]!
  ) {
    data: generateScholarshipDocumentUploadUrl(
      courseEnrollmentId: $courseEnrollmentId
      id: $id
      subTypeId: $subTypeId
    ) {
      subTypeId
      uploadUrl
    }
  }
`;

export const GET_SCHOLARSHIP_REQUEST = gql`
  query GetScholarshipRequest($courseEnrollmentId: ID) {
    data: getScholarshipRequest(courseEnrollmentId: $courseEnrollmentId) {
      id
      status
      title
      ticketNumber
      channel
      requestDate
    }
  }
`;

export const GET_SCHOLARSHIP_REQUEST_DETAILS = gql`
  query GetScholarshipRequestDetails($courseEnrollmentId: ID!, $id: ID!) {
    data: getScholarshipRequestDetails(
      courseEnrollmentId: $courseEnrollmentId
      id: $id
    ) {
      id
      status
      title
      ticketNumber
      channel
      requestDate
      serviceForecast
      description
      complementaryFields {
        title
        value
      }
      history {
        status
        requestDate
      }
      contractId
    }
  }
`;

export const GET_SCHOLARSHIP_DOCUMENT_REQUEST = gql`
  query GetScholarshipDocumentRequest($courseEnrollmentId: ID!, $id: ID!) {
    data: getScholarshipDocumentRequest(
      courseEnrollmentId: $courseEnrollmentId
      id: $id
    ) {
      title
      description
      status
      subTypes {
        id
        name
        info {
          description
          url
        }
      }
      supportedDocumentExtensions
      documentSizeLimit
    }
  }
`;

export const UPLOAD_SCHOLARSHIP_DOCUMENT = gql`
  mutation UploadScholarshipDocument(
    $courseEnrollmentId: ID!
    $uploadScholarshipDocumentId: ID!
    $subTypeId: [String!]!
  ) {
    uploadScholarshipDocument(
      courseEnrollmentId: $courseEnrollmentId
      id: $uploadScholarshipDocumentId
      subTypeId: $subTypeId
    )
  }
`;
