import React from 'react';
import { func, arrayOf, shape, string } from '@ampli/utils';
import {
  css,
  px2grid,
  mediaQuery,
  DialogBackdrop,
  Dialog,
  DialogHeader,
  DialogCloseButton,
  DialogSection,
  Text,
  Flex,
  FlexItem,
  Card,
  Separator,
  Paragraph,
} from '@ampli/ui';

import { SUBJECT_ENROLLMENT_IN_RECOVERY } from '../../constants';
import { PinMapImage, SubjectExamCalendarImage } from '../image';

const SelectExamModal = ({
  subjectEnrollments,
  onSelectSubjectEnrollment,
  ...props
}) => {
  const subjectEnrollmentsWithExam =
    subjectEnrollments?.filter((subjectEnrollment) => {
      const hasSemesterExam = subjectEnrollment.assignments.some(
        (assignment) =>
          assignment.detail.config.type === 'SEMESTRAL_EXAM_ONLINE'
      );

      return (
        (!hasSemesterExam && subjectEnrollment) ||
        (hasSemesterExam && subjectEnrollment.status === 'IN_RECOVERY')
      );
    }) || [];
  const hasSubjectEnrollments = subjectEnrollmentsWithExam.length > 0;

  return (
    <>
      <DialogBackdrop aria-hidden {...props} />
      <Dialog {...props}>
        <DialogHeader
          className={css`
            padding-bottom: ${px2grid(24)} !important;
          `}
        >
          <DialogCloseButton onClick={props.hide} />
          <div>
            <Text block color="text-dark" size="x-large" aria-hidden>
              Provas disponíveis hoje
            </Text>
            {hasSubjectEnrollments && (
              <Text block size="small">
                Selecione a prova que deseja realizar
              </Text>
            )}
          </div>
        </DialogHeader>
        <DialogSection
          className={css`
            padding-bottom: ${px2grid(24)};
            ${mediaQuery('desktop')} {
              padding-bottom: ${px2grid(32)};
            }
          `}
        >
          {hasSubjectEnrollments ? (
            subjectEnrollmentsWithExam.map((subjectEnrollment, index) => {
              return subjectEnrollment ? (
                <React.Fragment key={index}>
                  <Card
                    onClick={() => {
                      onSelectSubjectEnrollment(subjectEnrollment);
                    }}
                  >
                    <Flex alignItems="center">
                      <div>
                        <PinMapImage
                          aria-hidden
                          className={css`
                            margin-right: ${px2grid(24)};
                          `}
                        />
                        <FlexItem flex={1}>
                          <Text color="text-dark">
                            {subjectEnrollment.status ===
                              SUBJECT_ENROLLMENT_IN_RECOVERY && (
                              <strong>Recuperação -</strong>
                            )}{' '}
                            {subjectEnrollment.subject?.name}
                          </Text>
                        </FlexItem>
                      </div>
                    </Flex>
                  </Card>
                  <Separator margin="xx-small" transparent aria-hidden />
                </React.Fragment>
              ) : null;
            })
          ) : (
            <>
              <Paragraph textAlign="center" marginSize="large">
                <SubjectExamCalendarImage aria-hidden />
              </Paragraph>
              <Paragraph textAlign="center">
                <Text>Não há provas disponíveis no momento</Text>
              </Paragraph>
            </>
          )}
        </DialogSection>
      </Dialog>
    </>
  );
};

SelectExamModal.propTypes = {
  hide: func.isRequired,
  subjectEnrollments: arrayOf(
    shape({
      id: string.isRequired,
      subject: shape({
        name: string.isRequired,
      }).isRequired,
    }).isRequired
  ),
  onSelectSubjectEnrollment: func.isRequired,
};

export default SelectExamModal;
