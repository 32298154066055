import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["data", "loading"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { authStorage } from '@ampli/utils';
import { LOGIN_SELLER, LOGIN_CANDIDATE, LOGIN_STUDENT, LOGIN_AMPLI, FIND_ALL_COMPANY_CONTACTS } from './queries';
import { candidateCredentialsSchema, sellerCredentialsSchema, studentCredentialsSchema, ampliCredentialsSchema } from './schemas';
import { useMutation, useServiceClient, useQuery } from '../hooks';
export const useLoginCandidate = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useLogin({
    options,
    query: LOGIN_CANDIDATE,
    schema: candidateCredentialsSchema
  });
};
export const useLoginSeller = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useLogin({
    options,
    query: LOGIN_SELLER,
    schema: sellerCredentialsSchema
  });
};
export const useLoginStudent = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useLogin({
    options,
    context: {
      autoHandle: true
    },
    query: LOGIN_STUDENT,
    schema: studentCredentialsSchema,
    fetchPolicy: 'no-cache'
  });
};
export const useLoginAmpli = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useLogin({
    options,
    query: LOGIN_AMPLI,
    schema: ampliCredentialsSchema
  });
};
export const useGetAllCompanyContacts = () => {
  const _useQuery = useQuery(FIND_ALL_COMPANY_CONTACTS, {}),
    {
      data,
      loading
    } = _useQuery,
    rest = _objectWithoutProperties(_useQuery, _excluded);
  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data,
    loading
  }, rest);
};
const useLogin = _ref => {
  let {
    options,
    query,
    schema
  } = _ref;
  const publicClient = useServiceClient('publicClient');
  const privateClient = useServiceClient('privateClient');
  return [...useMutation(query, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    },
    client: publicClient,
    onError: errors => authStorage.removeItem('token').then(() => {
      if (options.onError) options.onError(errors);
      return null;
    }),
    onCompleted: data => authStorage.setItem('token', data.data.accessToken).then(async () => {
      var _options$onCompleted;
      if (privateClient) await privateClient.resetStore();
      options === null || options === void 0 || (_options$onCompleted = options.onCompleted) === null || _options$onCompleted === void 0 || _options$onCompleted.call(options, data);
      return null;
    })
  })), schema];
};