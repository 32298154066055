/* eslint-disable no-unused-vars */
import { GET_ME } from '@ampli/services';
import { GET_TERMS_BY_ID } from '../modules/communication/services/queries';
import { generatePath } from 'react-router-dom';
import { routePath } from '../routes';
import { basename } from '../config';

const termsGuard = async (to, from, next) => {
  let courseId;
  const localStorageItems = localStorage.getItem('termsModal');
  const localStorageTerms = localStorageItems && JSON.parse(localStorageItems);

  if (to.meta?.auth?.noAuth) {
    next();
    return;
  }

  try {
    const { privateClient, publicClient } = to.meta?.clients;

    const courseTypeCode = to.meta?.state?.selectedCourseEnrollmentType;
    const courseVersionId = to.meta?.state?.selectedCourseVersionId;
    courseId = to.meta?.state?.selectedCourseId;

    if (to.location.pathname.replace(basename, '/') === '/login') courseId = '';

    if (
      courseId &&
      to.location.pathname.replace(basename, '/') !== '/meus-cursos' &&
      !localStorageTerms?.accepted
    ) {
      const responseUser = await privateClient.query({
        query: GET_ME,
      });
      const studentId = responseUser?.data?.data?.username;

      const response = await publicClient.query({
        query: GET_TERMS_BY_ID,
        variables: {
          data: { courseTypeCode, courseVersionId, courseId, studentId },
        },
        skip: !courseId && !courseVersionId && !courseTypeCode,
      });

      const terms = response?.data?.data || [];

      let hasTermsToAccepted = terms.length > 0;
      !hasTermsToAccepted && next();

      if (
        localStorageTerms?.status &&
        localStorageTerms?.courseId === courseId
      ) {
        const [
          courseType,
          courseVersionId,
          termsPath,
          contractPath,
          contractId,
        ] = terms[0].ctaLink.replace('/', '').split('/') || [];

        to.history.push(
          generatePath(routePath.termsAndContractsDetails, {
            courseType,
            courseVersionId,
            contractId: `${contractId}?terms=true`,
          })
        );
      }
    }

    next();
  } catch (error) {
    courseId = '';
    process.exit(-1);
  }
};

export default termsGuard;
