import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["onCompleted", "onError"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useRef, useEffect, useState } from 'react';
import { useMutation as useMutationApollo } from '@apollo/client';
import useServiceClient from './use-service-client';
const useMutation = function (mutation) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let ignoreUseffectError = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const client = useServiceClient();
  const [times, setTimes] = useState(0);
  const timesRef = useRef(times);
  const {
      onCompleted,
      onError
    } = options,
    restOptions = _objectWithoutProperties(options, _excluded);
  const [_mutate, metadata] = useMutationApollo(mutation, _objectSpread(_objectSpread({
    client
  }, restOptions), {}, {
    onCompleted: response => {
      if (response && !response.errors && response.data) {
        onCompleted && onCompleted(response);
      } else if (!response && ignoreUseffectError) {
        onError && onError(metadata.error);
      }
    }
  }));
  const mutate = function () {
    setTimes(times + 1);
    return _mutate(...arguments);
  };
  useEffect(() => {
    if (timesRef.current !== times && !metadata.loading && metadata.error) {
      timesRef.current = times;
      if (!ignoreUseffectError) {
        onError && onError(metadata.error);
      }
    }
  }, [times, timesRef, metadata.loading, metadata.error, onError, ignoreUseffectError]);
  return [mutate, metadata];
};
export default useMutation;