import React from 'react';
import { useTheme } from '@emotion/react';
import { ButtonOutline, Separator, Typography, convertPXToVH } from '@sofia/ui';
import { DetailsAccordionBodyInterface } from './helper';
import { Wrapper, Flex } from './styles';

const DetailsAccordionBody: React.FC<DetailsAccordionBodyInterface> = ({
  items,
  onClick,
  textButton,
}: DetailsAccordionBodyInterface) => {
  const theme = useTheme();

  return (
    <>
      <Separator
        color={theme.colors.separator.lightColor}
        height="1px"
        margin={`0 0 ${convertPXToVH(32)} 0`}
        width="100%"
      />
      <Flex.RowSpaceBetween data-testid="details-accordion-body">
        <Flex.Row>
          {items.map((item, index) => (
            <Wrapper.Item key={`item-body-${index}`}>
              <Typography
                size={16}
                color={theme.colors.text.black}
                weight="700"
                margin={'0'}
                tabIndex={0}
              >
                {item.label}
              </Typography>
              <Typography
                size={16}
                color={theme.colors.text.black}
                weight="400"
                margin={'0'}
                tabIndex={0}
              >
                {item.value}
              </Typography>
            </Wrapper.Item>
          ))}
        </Flex.Row>
        <Wrapper.Item>
          <ButtonOutline
            borderBoxColor={theme.colors.button.primary}
            color={theme.colors.button.primary}
            margin={'0'}
            onClick={onClick}
            size={16}
            width={'100%'}
            data-testid="details-accordion-body-button"
          >
            {textButton}
          </ButtonOutline>
        </Wrapper.Item>
      </Flex.RowSpaceBetween>
    </>
  );
};

export default DetailsAccordionBody;
