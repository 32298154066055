import styled from '@emotion/styled';
export const Wrapper = styled.div`
  padding: 32px;
  background: ${_ref => {
  let {
    background
  } = _ref;
  return background;
}};
`;
export const FlexWrapper = styled.div`
  display: flex;
  justify-content: ${_ref2 => {
  let {
    justify
  } = _ref2;
  return justify;
}};
  align-items: center;
`;