import { History } from 'history';
import { CustomizationDataProps } from './menu/template/customization/types';

type SectionLocationState = {
  backIterations?: number;
  fromSubjectPage?: string;
};

type BeforeVideoRequestOptions = {
  uri: string;
  xhr: string;
};

type HandleCloseProps = {
  history: History<SectionLocationState>;
  fromSubjectPage?: string;
  backIterations: number;
  selectedCourseEnrollmentType: string;
  selectedCourseVersionId: string;
  subjectEnrollmentId?: string;
};

type TrackType = {
  referenceId: string;
  inputs: Array<{
    key: string;
    description: string;
    value: string;
    createdDate: string;
    lastModifiedDate: string;
  }>;
};

export enum EBorderStyle {
  RADIUS_ALL = 'RADIUS_ALL',
  MARKER = 'MARKER',
  RADIUS_EVEN = 'RADIUS_EVEN',
}

export enum EAssetElement {
  VIDEO = 'VIDEO',
  TITLE = 'TITLE',
  SUBTITLE = 'SUBTITLE',
  IMAGE = 'IMAGE',
}

type TAssetElementConfig = {
  element: EAssetElement;
  bannerUrl?: string | null;
  mobileBannerUrl?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  borderStyle?: EBorderStyle | null;
};

type TContentTemplate = {
  assetElementConfigs: TAssetElementConfig[];
  background?: string | null;
  name: string;
};

export type {
  SectionLocationState,
  BeforeVideoRequestOptions,
  CustomizationDataProps,
  HandleCloseProps,
  TrackType,
  TAssetElementConfig,
  TContentTemplate,
};
