import React from 'react';
import { Drawer } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { SectionMenuProps } from './types';

const Menu = ({
  header,
  open,
  content,
  scroll,
  ...props
}: SectionMenuProps): React.ReactElement => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <Drawer
      isOpen={open}
      withBackdrop={!isDesktop}
      header={header}
      data-testid="menu-drawer"
      scroll={scroll}
      {...props}
    >
      {content}
    </Drawer>
  );
};

Menu.defaultProps = {
  open: false,
};

export default Menu;
