import { SizeMaxWidthScreenTablet } from '@ampli/ui';

import styled from '@emotion/styled';

export const FlexItem = styled.div`
  align-self: ${({ alignSelf }) => alignSelf};
  flex: ${({ finalFlex }) => finalFlex};
  order: ${({ order }) => order};
  width: ${({ width }) => width};
  flex: ${({ flex }) => flex};
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: row;

  background-color: ${({ background }) => background};

  @media (max-width: ${SizeMaxWidthScreenTablet}) {
    height: unset;
    min-height: 100vh;
    overflow: unset;
  }
`;

export const SideWrapper = styled.div`
  flex: 50%;

  @media (max-width: ${SizeMaxWidthScreenTablet}) {
    display: none;
  }
`;

export const ChildrenWrapper = styled.div`
  flex: 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${SizeMaxWidthScreenTablet}) {
    flex: 1;
  }
`;
