export default {
  onboarding: false,
  openMenu: null,
  openCustomization: null,
  openNotes: null,
  openCommunitySection: false,
  openTalkTutorFrame: false,
  fontSize: 'medium',
  videoTime: {},
  debugBlockedFeatures: false,
  showInauguralSubjectTutorial: null,
  selectedCourseEnrollmentId: null,
  selectedCourseVersionId: null,
  selectedCourseId: null,
  selectedCourseEnrollmentType: 'ava',
  persistData: {},
  hasShownNotesOnboarding: false,
  hasShownSectionEduCommunication: false,
  isReadingRulerEnabled: false,
};
