import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { ButtonPrimary, ButtonGhost, Typography } from '@sofia/ui';
import * as Styled from './evaluation-onboarding-content.styles';
import { RedoIcon } from '@ampli/icon';
import { EvaluationOnboardingContentProps } from '../../types';

const EvaluationOnboardingContent = ({
  title,
  subjectName,
  weight,
  button,
  buttonBack,
  items,
  withLoading,
  isSemesterExam,
}: EvaluationOnboardingContentProps): React.ReactElement => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const [loading, setLoading] = React.useState(false);

  return (
    <>
      {/* Header */}
      <Typography
        margin="0 0 16px 0"
        size={isDesktop ? 32 : 24}
        weight={700}
        color={theme.colors.text.black}
      >
        {title}
      </Typography>
      <Typography
        margin="0 0 16px 0"
        size={isDesktop ? 20 : 16}
        color={theme.colors.text.lightBlack}
      >
        {subjectName}
      </Typography>
      <Typography margin={0} size={16} color={theme.colors.text.lighterBlack}>
        Valor total: {weight}
      </Typography>

      {/* Semester exam text */}
      {isSemesterExam ? (
        <Typography
          color={theme.colors.text.lightBlack}
          data-testid="evaluation-content-semester-exam-text"
        >
          {items?.subtitleSemestralExam}
        </Typography>
      ) : null}

      {/* Items */}
      {items ? (
        <Styled.ItemsWrapper data-testid="evaluation-content-items">
          {items.title ? (
            <Typography
              margin="40px 0 0 0"
              weight={700}
              color={theme.colors.text.lightBlack}
              data-testid="evaluation-content-items-title"
            >
              {items.title}
            </Typography>
          ) : null}
          {items.desc ? (
            <Typography
              margin="8px 0 0 0"
              color={theme.colors.text.lightBlack}
              data-testid="evaluation-content-items-desc"
            >
              {items.desc}
            </Typography>
          ) : null}
          <Styled.ItemsList>
            {items.list.map((item, index) => {
              return (
                <Styled.Flex
                  key={index}
                  margin={index !== items.list.length - 1 ? '0 0 24px 0' : ''}
                  data-testid="evaluation-content-items-list"
                >
                  <Styled.IconWrapper>{item.icon}</Styled.IconWrapper>
                  <Typography as="span" color={theme.colors.text.lightBlack}>
                    {item.text}
                  </Typography>
                </Styled.Flex>
              );
            })}
          </Styled.ItemsList>
          {items.footer ? (
            <Typography
              as="span"
              color={theme.colors.text.lightBlack}
              data-testid="evaluation-content-items-footer"
            >
              {items.footer}
            </Typography>
          ) : null}
        </Styled.ItemsWrapper>
      ) : null}

      {/* Actions */}
      <Styled.ActionsWrapper>
        <ButtonPrimary
          onClick={() => {
            if (withLoading) {
              setLoading(true);
            }
            button?.click && button.click();
          }}
          color="#fff"
          background={theme.colors.button.primary}
          hover={theme.colors.button.darkPrimary}
          loading={loading}
          data-testid="evaluation-content-button"
        >
          {loading ? (
            <RedoIcon data-testid="evaluation-content-button-redo" />
          ) : (
            button?.text
          )}
        </ButtonPrimary>

        <ButtonGhost
          onClick={() => {
            buttonBack?.click && buttonBack.click();
          }}
          color={theme.colors.button.primary}
          data-testid="evaluation-content-button"
        >
          {buttonBack?.text}
        </ButtonGhost>
      </Styled.ActionsWrapper>
    </>
  );
};

EvaluationOnboardingContent.defaultProps = {
  withLoading: true,
  isSemesterExam: false,
};

export default EvaluationOnboardingContent;
