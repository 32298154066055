import React from 'react';

import { useBreakpoint } from '@ampli/hooks';

import ColorSection from './components/section/color/color-section';
import TextSection from './components/section/text/text-section';
import Reset from './components/reset/reset';

import { useGetState } from '../../../../../../../state';
import SpacingSection from './components/section/spacing/spacing-section';
import { defaultCustomization } from './constants';
import {
  CustomizationDataProps,
  ObjectCustomizationItemsProps,
  SelectedCustomStyledType,
} from './types';
import {
  NEW_TEMPLATE_COMMUNICATION_COMPLETED_DESCRIPTION,
  NEW_TEMPLATE_COMMUNICATION_COMPLETED_KEY,
} from '../../../../../../../constants';
import { useHandleTrack } from '../../../hooks';

const ObjectCustomizationItems = ({
  onApply,
  defaultTemplate,
  studentId,
}: ObjectCustomizationItemsProps): React.ReactElement => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const [
    { persistData, hasShownNewTemplateCommunication },
    { setPersistData },
  ] = useGetState();
  const [reset, setReset] = React.useState(false);

  const startCustomizationValue =
    Object.keys(persistData)?.length > 0
      ? persistData
      : defaultCustomization(isDesktop, defaultTemplate);
  const [selectedCustomization, setSelectedCustomization] = React.useState(
    startCustomizationValue
  );

  const { withoutTrack, loading, appendCommunicationOnTrack } = useHandleTrack({
    studentId: studentId || '',
    input: {
      key: NEW_TEMPLATE_COMMUNICATION_COMPLETED_KEY,
      desc: NEW_TEMPLATE_COMMUNICATION_COMPLETED_DESCRIPTION,
    },
  });

  React.useEffect(() => {
    if (
      reset &&
      JSON.stringify(selectedCustomization) ===
        JSON.stringify(defaultCustomization(isDesktop, defaultTemplate))
    ) {
      setReset(false);
    }
  }, [setReset, selectedCustomization, reset, isDesktop, defaultTemplate]);

  const setNewCustomization = (newCustomization: CustomizationDataProps) => {
    setSelectedCustomization(newCustomization);
    setPersistData(newCustomization);
    onApply(newCustomization);
  };

  const onClickEvent = (
    selectedAttr: keyof CustomizationDataProps,
    value: SelectedCustomStyledType
  ) => {
    if (value !== null) {
      const newCustomization = {
        ...selectedCustomization,
        ...{ [selectedAttr]: value },
      };
      setNewCustomization(newCustomization);
    }
  };

  const onColorSectionClick = (
    selectedAttr: keyof CustomizationDataProps,
    value: SelectedCustomStyledType
  ) => {
    if (
      JSON.stringify(selectedCustomization[selectedAttr]) !==
        JSON.stringify(value) &&
      defaultTemplate
    ) {
      appendCommunicationOnTrack();
    }
    onClickEvent(selectedAttr, value);
  };

  return (
    <>
      {[
        <Reset
          key="reset"
          onResetClick={() => {
            setNewCustomization(
              defaultCustomization(isDesktop, defaultTemplate)
            );
            setReset(true);
          }}
        />,
        <TextSection
          key="text-section"
          reset={reset}
          selectedCustomization={selectedCustomization}
          onClickEvent={onClickEvent}
        />,
        !loading ? (
          <ColorSection
            key="color-section"
            reset={reset}
            selectedCustomization={selectedCustomization}
            showNewTemplateCommunication={
              withoutTrack && !hasShownNewTemplateCommunication
            }
            onClickEvent={onColorSectionClick}
            defaultTemplate={defaultTemplate}
          />
        ) : null,
        <SpacingSection
          key="spacing-section"
          reset={reset}
          selectedCustomization={selectedCustomization}
          onClickEvent={onClickEvent}
        />,
      ]}
    </>
  );
};

export default ObjectCustomizationItems;
