import { injectGlobal } from '@ampli/ui';
injectGlobal`
.video-js {
  position: relative;
  cursor: pointer;
  display: flex;

  &,
  & > video {
    height: auto;
    width: 100%;
  }

  &.vjs-user-inactive:not(.vjs-paused) {
    &,
    & * {
      cursor: none;
    }
  }
}

.vjs-hidden,
.vjs-big-play-button,
.vjs-loading-spinner {
  display: none !important;
}
`;