import { DateTime, Interval, find, filter, sortBy } from '@ampli/utils';
import { dateField, intervalField } from './commons';
export const subjectEnrollmentStatusLabelByStatus = {
  IN_PROGRESS: 'Cursando',
  IN_RECOVERY: 'Recuperação',
  COMPLETED: 'Cursado',
  PASSED: 'Aprovado',
  FAILED: 'Reprovado',
  UNAVAILABLE: 'Em espera'
};
const hasCompletedExams = {
  read(_, _ref) {
    let {
      readField
    } = _ref;
    const progressRef = readField('progress');
    const progressDetails = readField('details', progressRef);
    const examDetails = find(progressDetails, {
      type: 'EXAM'
    });
    if (examDetails && examDetails.completed === examDetails.quantity) {
      return true;
    }
    return false;
  }
};
const finishedExams = {
  read(cached, _ref2) {
    let {
      readField
    } = _ref2;
    if (cached) return cached;
    const examRefs = readField('exams') || [];
    return sortBy(filter(examRefs, ref => readField('status', ref) === 'FINISHED'), ref => DateTime.fromISO(readField('finishedDate', ref)));
  }
};
const readAssignmentConfigField = function (field, comparator) {
  let defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  let {
    readField
  } = arguments.length > 3 ? arguments[3] : undefined;
  const gradingMethodRef = readField('gradingMethod');
  const assignmentConfigRef = find(readField('assignmentConfigs', gradingMethodRef), assignmentConfigRef => {
    const type = readField('type', assignmentConfigRef);
    const reference = readField('reference', assignmentConfigRef);
    return comparator({
      type,
      reference
    });
  });
  return assignmentConfigRef ? readField(field, assignmentConfigRef) : defaultValue;
};
const subjectExamComparator = _ref3 => {
  let {
    type,
    reference
  } = _ref3;
  return type === 'EXAM' && reference === 'SUBJECT';
};
const subjectExamWeight = {
  read(_, config) {
    return readAssignmentConfigField('weight', subjectExamComparator, 0, config);
  }
};
const subjectExamDurationInMinutes = {
  read(_, config) {
    return readAssignmentConfigField('durationInMinutes', subjectExamComparator, 0, config);
  }
};
const examStartEndInterval = {
  read(cached, _ref4) {
    let {
      readField
    } = _ref4;
    if (cached) return cached;
    const assignmentsRef = readField('assignments');
    if (!assignmentsRef) return null;
    const examAssignmentRef = [...assignmentsRef].sort((a, b) => {
      const firstDetailRef = readField('detail', a);
      const lastDetailRef = readField('detail', b);
      return new Date(readField('startDate', firstDetailRef)) - new Date(readField('startDate', lastDetailRef));
    }).find(a => {
      const detailRef = readField('detail', a);
      const configRef = readField('config', detailRef);
      const type = readField('type', configRef);
      return type === 'EXAM' || type === 'PRESENTIAL_EXAM' || type === 'SEMESTRAL_EXAM_ONLINE';
    });
    const detailRef = readField('detail', examAssignmentRef);
    const examStartDate = readField('startDate', detailRef);
    const examEndDate = readField('dueDate', detailRef);
    return examStartDate && examEndDate ? Interval.fromDateTimes(DateTime.fromISO(examStartDate), DateTime.fromISO(examEndDate)) : null;
  }
};
const assignmentStartEndInterval = {
  read(cached, _ref5) {
    let {
      readField
    } = _ref5;
    if (cached) return cached;
    const assignmentsRef = readField('assignments');
    if (!assignmentsRef) return null;
    const AssignmentRef = assignmentsRef.find(a => {
      const detailRef = readField('detail', a);
      const configRef = readField('config', detailRef);
      const type = readField('type', configRef);
      return type === 'ASSIGNMENT';
    });
    const detailRef = readField('detail', AssignmentRef);
    const assignmentStartDate = readField('startDate', detailRef);
    const assignmentEndDate = readField('dueDate', detailRef);
    return assignmentStartDate && assignmentEndDate ? Interval.fromDateTimes(DateTime.fromISO(assignmentStartDate), DateTime.fromISO(assignmentEndDate).minus({
      days: 10
    })) : null;
  }
};
export default {
  fields: {
    startDate: dateField,
    endDate: dateField,
    closingDate: dateField,
    startEndInterval: intervalField,
    hasCompletedExams,
    subjectExamWeight,
    subjectExamDurationInMinutes,
    finishedExams,
    examStartEndInterval,
    assignmentStartEndInterval
  }
};