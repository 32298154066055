import { ISubjectEnrollment } from '../../../shared/contexts/course-enrollments-context.types';
import {
  EActiveEnrollmentStatuses,
  TFilterActiveEnrollmentsReturn,
} from './enrollmentStatus.types';

export const filterActiveEnrollments = (
  enrollments: ISubjectEnrollment[]
): TFilterActiveEnrollmentsReturn => {
  if (!enrollments) {
    throw new Error('Enrollments is required');
  }

  return enrollments.filter((subj) =>
    EActiveEnrollmentStatuses.includes(subj.status)
  );
};
