import { useDeleteAnnotation } from '@ampli/services';
import { MutateNoteHookProps } from './types';

const useDeleteNote = ({
  onCancel,
  onComplete,
  ...options
}: MutateNoteHookProps): [(noteId: string) => void, boolean] => {
  const [updateMutate, { loading: deleteNoteLoading }] = useDeleteAnnotation(
    null,
    {
      onCompleted: () => {
        onComplete();
        if (onCancel) onCancel();
      },
      ...options,
    }
  );

  const updateNote = (noteId: string) => {
    updateMutate({
      variables: {
        annotationId: noteId,
      },
    });
  };

  return [updateNote, deleteNoteLoading];
};

export default useDeleteNote;
