import React from 'react';

import { Skeleton } from '../../skeleton';

import * as Styled from './course-selection-skeleton-styles';

export function CardCourseSelectionSkeleton() {
  return (
    <Styled.ContainerCard>
      <Skeleton height={280} borderRadius={10} />
    </Styled.ContainerCard>
  );
}

export function TitleCourseSelectionSkeleton() {
  return (
    <Styled.Container tabIndex={-1}>
      <Styled.ContainerTitleCourse>
        <Skeleton height={30} />
      </Styled.ContainerTitleCourse>
    </Styled.Container>
  );
}

export function NumberOfCoursesSkeleton() {
  return (
    <Styled.ContainerNumberOfCourse>
      <Skeleton height={25} />
    </Styled.ContainerNumberOfCourse>
  );
}
