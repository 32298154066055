import React from 'react';

import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

type Options = { display?: boolean };

export const withToggleFeature = (
  children: React.ReactNode,
  replaceContent: React.ReactNode,
  { display }: Options
): EmotionJSX.Element => (display ? <>{children}</> : <>{replaceContent}</>);
