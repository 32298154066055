import { useGetCalendarEvents, useServiceClient } from '@ampli/services';
import { useGetState } from '../state';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import logout from '../lib/logout';
import { generatePath } from 'react-router-dom';
import { routePath } from '../routes';
import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';
import { useMeContext } from '../modules/shared/contexts/me-context';

const INITIAL_VALUES = {
  startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  endDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
};

const useCalendarPageData = (
  startDate = INITIAL_VALUES.startDate,
  endDate = INITIAL_VALUES.endDate
) => {
  const [
    { selectedCourseEnrollmentId, selectedCourseEnrollmentType },
    { selectedCourseVersionId },
  ] = useGetState();
  const { states: statesMe } = useMeContext();
  const { me } = statesMe.getMe;

  const client = useServiceClient('privateClient');

  const { states } = useCourseEnrollmentsContext();

  const { data: courseEnrollment, loading: enrollmentPageLoading } =
    states.courseEnrollments;

  const handleLogout = () => logout({ client, history });
  const handleLogo = () =>
    history.push(
      generatePath(routePath.home, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
      })
    );

  const subjectEnrollmentsInProgress =
    courseEnrollment?.subjectEnrollmentsInProgress || [];
  const subjectEnrollmentsTaken =
    courseEnrollment?.subjectEnrollmentsTaken || [];
  const subjectEnrollmentsToBeTaken =
    courseEnrollment?.subjectEnrollmentsToBeTaken || [];

  const {
    loading: calendarPageLoading,
    data,
    ...rest
  } = useGetCalendarEvents(
    selectedCourseEnrollmentId,
    startDate,
    endDate,
    courseEnrollment?.course?.courseType?.degree
  );

  const loading = enrollmentPageLoading || calendarPageLoading;

  return {
    data,
    loading,
    ...rest,
    me,
    handleLogo,
    handleLogout,
    courseEnrollment,
    subjectEnrollmentsInProgress,
    subjectEnrollmentsTaken,
    subjectEnrollmentsToBeTaken,
  };
};

export default useCalendarPageData;
