import { useEffect, useState, useRef } from 'react';
import { Duration, workerTimers, round } from '@ampli/utils';
import useEffectOnce from './use-effect-once';
const useDurationRef = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    timer = {
      seconds: 1
    },
    start = {
      seconds: 0
    },
    end = {
      minutes: 1
    }
  } = options;
  const timerDurationRef = useRef(Duration.fromObject(timer));
  const timerDuration = timerDurationRef.current;
  const startDurationRef = useRef(Duration.fromObject(start));
  const startDuration = startDurationRef.current;
  const tempEndDuration = startDuration.plus(end);
  const endDurationRef = useRef(tempEndDuration);
  if (!tempEndDuration.equals(endDurationRef.current)) {
    endDurationRef.current = tempEndDuration;
  }
  const endDuration = endDurationRef.current;
  return {
    timerDuration,
    startDuration,
    endDuration
  };
};
const useDuration = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    type: ''
  };
  let onTimeout = arguments.length > 1 ? arguments[1] : undefined;
  const {
    progress = {
      precision: 0
    },
    type,
    onAlteredClockTime
  } = options;
  const {
    timerDuration,
    endDuration,
    startDuration
  } = useDurationRef(options);
  const [duration, setDuration] = useState(startDuration);
  const isMounted = useRef(false);
  let calculatedProgress = round(duration.plus(timerDuration).as('milliseconds') / endDuration.as('milliseconds') * 100, progress.precision);
  if (calculatedProgress > 100) {
    calculatedProgress = 100;
  }
  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);
  useEffectOnce(() => {
    let timer;
    let countTimeForward = 0;
    let diffDuration = 0;
    let startTime = Duration.fromObject({
      milliseconds: Date.now()
    });
    let adjustedStartTime = false;
    const clearTimer = () => {
      console.debug('EXAM TIMER', 'clear');
      timer && workerTimers.clearInterval(timer);
      timer = undefined;
    };
    timer = workerTimers.setInterval(() => {
      if (isMounted.current) {
        setDuration(duration => {
          console.debug('EXAM TIMER', duration.as('seconds'));
          const now = Duration.fromObject({
            milliseconds: Date.now()
          });
          if (countTimeForward === 1 && !adjustedStartTime) {
            startTime = startTime.plus({
              milliseconds: diffDuration
            });
            adjustedStartTime = true;
          }
          let newDuration = Duration.fromObject({
            milliseconds: now - startTime
          });
          if (type === 'take-exam-timer') {
            // the machine clock went back in time
            if (duration.minus({
              seconds: 30
            }) > newDuration) {
              onAlteredClockTime('device-clock-back', true);
              return duration;
            }

            // the machine clock went forward in time
            if (duration.plus({
              minute: 1
            }) < newDuration) {
              diffDuration = newDuration.minus({
                milliseconds: 500
              }).minus(duration).as('milliseconds');
              countTimeForward++;
              onAlteredClockTime('device-clock-forward', countTimeForward >= 2);
              return duration;
            }
          }
          return newDuration;
        });
      } else {
        clearTimer();
      }
    }, timerDuration.as('milliseconds'));
  }, true);
  useEffectOnce(() => {
    onTimeout && onTimeout();
  }, duration >= endDuration);
  return {
    progress: calculatedProgress,
    duration
  };
};
export default useDuration;