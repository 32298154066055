import { Theme } from '@emotion/react';
import { MAX_LENGTH_NOTE } from '../../../constants';

const getCounterColor = (theme: Theme, counter: number): string => {
  return theme.colors.text[
    counter >= 0 && counter < 920
      ? 'lighterBlack'
      : counter <= MAX_LENGTH_NOTE - 1
      ? 'warning'
      : 'danger'
  ];
};

export default getCounterColor;
