import React from 'react';
import { useTheme } from '@emotion/react';
import { ButtonPrimary, SvgTimesCircle } from '@sofia/ui';

import { IModalsProps } from './types';
import useDiscardPublicationLogic from './base-modal.logic';
import Icon from './icon-wrapper';
import BaseModal from './base-modal.presentation';
import * as Styles from './modals.styles';

const ErrorOnPublishing: React.FC<IModalsProps> = ({
  modal,
  publishType = 'publication',
}: IModalsProps) => {
  const { actions } = useDiscardPublicationLogic({ modal });
  const theme = useTheme();

  const description = {
    comment:
      'Ocorreu um erro do nosso lado ao tentar publicar seu comentário. Tente novamente!',
    publication:
      'Ocorreu um erro do nosso lado ao tentar fazer sua publicação. Tente novamente!',
  }[publishType];

  const Footer: React.FC = () => (
    <Styles.Footer>
      <ButtonPrimary
        onClick={() => actions.handleModal('retry')}
        width="100%"
        background={theme.colors.button.primary}
        color={theme.colors.button.secondary}
      >
        Tentar novamente
      </ButtonPrimary>
    </Styles.Footer>
  );

  const ModalIcon = () => (
    <Icon background={theme.colors.alerts.background.tertiary}>
      <SvgTimesCircle fill={theme.colors.background.icon.danger} size="30" />
    </Icon>
  );

  return (
    <BaseModal
      Footer={Footer}
      Icon={ModalIcon}
      title="Ops, tivemos um problema"
      description={description}
      handleModal={modal}
    />
  );
};

export default ErrorOnPublishing;
