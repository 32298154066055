import styled from '@emotion/styled';
export const Container = styled.div`
  border-radius: ${_ref => {
  let {
    round,
    size
  } = _ref;
  return round ? size : 0;
}}px;
  display: inline-block;
  height: ${_ref2 => {
  let {
    size
  } = _ref2;
  return size;
}}px;
  width: ${_ref3 => {
  let {
    size
  } = _ref3;
  return size;
}}px;
`;
export const Text = styled.div`
  width: ${_ref4 => {
  let {
    size
  } = _ref4;
  return size;
}}px;
  height: ${_ref5 => {
  let {
    size
  } = _ref5;
  return size;
}}px;
  background: ${_ref6 => {
  let {
    background
  } = _ref6;
  return background;
}};
  font-size: ${_ref7 => {
  let {
    size
  } = _ref7;
  return Math.floor(size / 2.5);
}}px;
  border-radius: ${_ref8 => {
  let {
    round,
    size
  } = _ref8;
  return round ? size : 0;
}}px;
  color: ${_ref9 => {
  let {
    textColor
  } = _ref9;
  return textColor;
}};
  line-height: ${_ref10 => {
  let {
    size
  } = _ref10;
  return size + 'px';
}};
  text-align: center;
  text-transform: uppercase;
`;
export const Image = styled.div`
  width: ${_ref11 => {
  let {
    size
  } = _ref11;
  return size;
}}px;
  height: ${_ref12 => {
  let {
    size
  } = _ref12;
  return size;
}}px;
  max-width: 100%;
  border-radius: ${_ref13 => {
  let {
    round,
    size
  } = _ref13;
  return round ? size : 0;
}}px;
  background-image: ${_ref14 => {
  let {
    src
  } = _ref14;
  return `url(${src})`;
}};
  background-size: cover;
  background-position: center;
`;