import styled from '@emotion/styled';

import { convertPXToREM, mediaQuery } from '@sofia/ui';

export const TalkTutorButtonContent = styled.button<{ isActive: boolean }>`
  svg {
    margin: 10px;
  }

  padding: 0;
  ${mediaQuery('desktop')} {
    margin-right: 40px;
    flex-direction: row;
    padding: 8px 16px;
    svg {
      margin: 0 10px 0 0;
    }
  }

  span {
    font-size: ${convertPXToREM(16)};
    font-weight: 400;
    line-height: 150%;
  }

  &:hover,
  &[aria-expanded='true'] {
    background: #ebf0f5;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: solid 1px transparent;
  border-radius: 8px;
  background: transparent;

  ${({ theme, isActive }) =>
    isActive ? `color: ${theme.colors.text.primary};` : ''}
`;

export const Signal = styled.div<{ size?: 'large' | 'small' }>`
  width: ${({ size = 'small' }) => (size === 'small' ? '12px' : '24px')};
  height: ${({ size = 'small' }) => (size === 'small' ? '12px' : '24px')};
  background: ${({ theme }) => theme.colors.button.danger};
  border-radius: 999px;
  margin-left: 8px;
`;
