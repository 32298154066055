import styled from '@emotion/styled';

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 4px;
`;

export const Icon = styled.div`
  padding: 18px;
  margin-right: 8px;
`;

export const Button = styled.button<{
  color: string;
  background: string;
  hover: string;
}>`
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  border: none;
  width: 100%;
  padding: 16px 0;
  text-align: left;
  position: sticky;
  top: 0;

  :hover {
    background: ${({ hover }) => hover};
  }
`;
