import { Icon, IconButton } from '@ampli/ui';
import { convertPXToREM } from '@sofia/ui';

import styled from '@emotion/styled';

interface MenuHandleProps {
  themeColor: string;
}

export const FrameHeader = styled.div`
  &:not(.closed-frame) {
    padding: 24px 32px;
    margin-bottom: -1px;
  }

  border-bottom: 1px solid #dee2e6;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MenuHandle = styled('div', {
  shouldForwardProp: (propName) => propName !== 'themeColor',
})<MenuHandleProps>`
  display: inline-block;
  line-height: 0;
  margin-bottom: auto;
  background-color: #dbe3ec;
  border-radius: 50%;
  cursor: pointer;

  &:not(.closed-frame) {
    padding: 14px 12px;
  }
  &.closed-frame {
    padding: 13px 12px;
  }
  svg {
    color: ${({ themeColor }) => themeColor};
    box-sizing: content-box;
    pointer-events: none;
  }
  div[role='tooltip'] {
    font-size: ${convertPXToREM(16)};
  }
  :hover {
    background-color: #c5d2e0;
  }
`;

export const IconX = styled(Icon.X)`
  padding: 10px;
`;

export const IconBtn = styled(IconButton)`
  background-color: #dbe3ec;
  border-radius: 50%;
  svg {
    color: #212529;
    box-sizing: content-box;
    pointer-events: none;
  }
  :hover {
    background-color: #c5d2e0;
  }
`;
