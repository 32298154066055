import styled from '@emotion/styled';
import { convertPXToREM } from '../../utils';
export const Tag = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 4px;
  background: ${_ref => {
  let {
    background
  } = _ref;
  return background;
}};
`;
export const TagContent = styled.span`
  font-size: ${_ref2 => {
  let {
    size = 16
  } = _ref2;
  return convertPXToREM(size);
}};
  font-weight: 700;
  line-height: 115%;
  color: ${_ref3 => {
  let {
    color
  } = _ref3;
  return color;
}};
`;