import { generatePath } from 'react-router-dom';
import { routePath } from '../../../../../routes';
import { HandleCloseProps } from '../types';
import { pushAmpliEvent } from '../../../../../lib/ga';

export const createHandleClose: (closeProps: HandleCloseProps) => () => void =
  ({
    history,
    fromSubjectPage,
    backIterations,
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
    subjectEnrollmentId,
  }) =>
  () => {
    pushAmpliEvent('event', 'object_close');
    if (fromSubjectPage) {
      history.go(backIterations * -1);
    } else {
      console.log(subjectEnrollmentId);
      history.replace(
        generatePath(routePath[subjectEnrollmentId ? 'subject' : 'home'], {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
          ...(subjectEnrollmentId ? { subjectEnrollmentId } : {}),
        })
      );
    }
  };

export default createHandleClose;
