import {
  HOME_CHAT_COPILOT_VIEWED,
  HOME_CHAT_COPILOT_VIEWED_DESCRIPTION,
  onboardingIds,
} from '../../../utils';
import { useNewFeature } from './new-feature.hooks';

export const useNewChatCopilotHomeFeature = (
  studentId: string,
  additionalConditionsToShowFeature: boolean
): void => {
  useNewFeature({
    communicationKey: HOME_CHAT_COPILOT_VIEWED,
    communicationDescription: HOME_CHAT_COPILOT_VIEWED_DESCRIPTION,
    localStorageItem: 'showedNewChatPresentation',
    tourId: onboardingIds.newFeature.id.newChatHome,
    studentId,
    additionalConditionsToShowFeature,
    creativeType: 'chat-copilot',
  });
};
