const mockSimpleSections = [
  {
    id: '87750afd-3013-8302-e75c-031a27b505ec',
    title: 'Aula 1',
    order: 1,
    pathUrl: '05aad2a7-9f4d-4020-7dbd-084a09e875ea',
  },
  {
    id: '4eeba4bc-86a6-a849-fe02-8c08892b3988',
    title: 'Aula 2',
    order: 2,
    pathUrl: '05aad2a7-9f4d-4020-7dbd-084a09e875ea',
  },
];

const mockSimpleLearningUnit = {
  id: '1b2c0edd-be2e-4468-7b47-d777f6567654',
  order: 1,
  title: 'Unidade 1',
  learningUnitId: '0a4ca7e3-2856-63d5-7cbb-397d634f55f6',
  learningUnitVersionId: '8c5fdb99-2a50-cf3d-5e68-05c6ccf08730',
  sections: mockSimpleSections,
};

const [mockSimpleSection, secondMockSimpleSection] = mockSimpleSections;

const mockNoteObject = {
  id: '892a6874-25ce-0a6a-1db3-db9ef54026ef',
  courseEnrollmentId: 'e0f1156f-8a24-4fee-24ea-27fc0ad745a3',
  subjectId: '1de04741-6bbe-116e-7265-4d21f522f465',
  sectionId: mockSimpleSection.id,
  learningObjectId: '8788ded8-9034-1b6e-11e3-bd9e9603611a',
  learningUnitVersionId: '4876b103-03e9-e53c-1134-4752105ac5b4',
  content: 'Teste 1',
  createdDate: '2023-11-23',
};

const secondMockNoteObject = {
  ...mockNoteObject,
  id: '16e9a273-add8-7d84-2682-d16190e4edc0',
  sectionId: secondMockSimpleSection.id,
  content: 'Teste 2',
};

const mockNotesBySections = {
  [mockSimpleSection.id]: [mockNoteObject],
  [secondMockSimpleSection.id]: [secondMockNoteObject],
};

const mockLearningObjectEnrollments = [
  {
    id: '6b7de9f0-8c00-9ba5-9a25-fcf266fcd066',
    title: 'Introdução da unidade',
  },
  {
    id: mockNoteObject.learningObjectId,
    title: 'Introdução da aula',
  },
];

const mockAllNotesList = [mockNoteObject, secondMockNoteObject];

const mockSimpleSubjectEnrollment = {
  subjectId: '1b2c0edd-be2e-4468-7b47-d777f6567654',
  name: 'Física Geral',
};

export {
  mockSimpleSections,
  mockSimpleLearningUnit,
  mockNoteObject,
  mockSimpleSection,
  mockNotesBySections,
  mockLearningObjectEnrollments,
  mockAllNotesList,
  mockSimpleSubjectEnrollment,
};
