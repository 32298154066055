import * as Types from '../../../shared/graphql/types';

import * as Operations from './queries';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@ampli/services';
import { TLazyQueryHook, TMutationHook, TQueryHook } from '../../../shared';
const defaultOptions = {} as const;
export type CreateContentEvaluationMutationVariables = Types.Exact<{
  contentEvaluation: Types.ContentEvaluationInput;
}>;

export type CreateContentEvaluationMutation = {
  __typename?: 'Mutation';
  data: { __typename?: 'ContentEvaluation'; id: string };
};

export type GetLastContentEvaluationByContentAndStudentQueryVariables =
  Types.Exact<{
    studentId: Types.Scalars['String']['input'];
    learningObjectId: Types.Scalars['String']['input'];
  }>;

export type GetLastContentEvaluationByContentAndStudentQuery = {
  __typename?: 'Query';
  data?: {
    __typename?: 'ContentEvaluation';
    id: string;
    studentId: string;
    courseId: string;
    courseTypeCode: string;
    courseVersionId: string;
    courseEnrollmentId: string;
    subjectId: string;
    subjectEnrollmentId: string;
    learningUnitId: string;
    learningUnitVersionId: string;
    learningUnitEnrollmentId: string;
    sectionId: string;
    learningObjectId: string;
    rating: number;
    comment?: string | null;
    client: string;
  } | null;
};

export type CreateContentEvaluationMutationFn = Apollo.MutationFunction<
  CreateContentEvaluationMutation,
  CreateContentEvaluationMutationVariables
>;

/**
 * __useCreateContentEvaluationMutation__
 *
 * To run a mutation, you first call `useCreateContentEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentEvaluationMutation, { data, loading, error }] = useCreateContentEvaluationMutation({
 *   variables: {
 *      contentEvaluation: // value for 'contentEvaluation'
 *   },
 * });
 */
export function useCreateContentEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContentEvaluationMutation,
    CreateContentEvaluationMutationVariables
  >
): Apollo.MutationTuple<
  CreateContentEvaluationMutation,
  CreateContentEvaluationMutationVariables
> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useMutation as TMutationHook)<
    CreateContentEvaluationMutation,
    CreateContentEvaluationMutationVariables
  >(Operations.CreateContentEvaluation, options);
}
export type CreateContentEvaluationMutationHookResult = ReturnType<
  typeof useCreateContentEvaluationMutation
>;
export type CreateContentEvaluationMutationResult =
  Apollo.MutationResult<CreateContentEvaluationMutation>;
export type CreateContentEvaluationMutationOptions = Apollo.BaseMutationOptions<
  CreateContentEvaluationMutation,
  CreateContentEvaluationMutationVariables
>;

/**
 * __useGetLastContentEvaluationByContentAndStudentQuery__
 *
 * To run a query within a React component, call `useGetLastContentEvaluationByContentAndStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastContentEvaluationByContentAndStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastContentEvaluationByContentAndStudentQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      learningObjectId: // value for 'learningObjectId'
 *   },
 * });
 */
export function useGetLastContentEvaluationByContentAndStudentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastContentEvaluationByContentAndStudentQuery,
    GetLastContentEvaluationByContentAndStudentQueryVariables
  >
): Apollo.QueryResult<
  GetLastContentEvaluationByContentAndStudentQuery,
  GetLastContentEvaluationByContentAndStudentQueryVariables
> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useQuery as TQueryHook)<
    GetLastContentEvaluationByContentAndStudentQuery,
    GetLastContentEvaluationByContentAndStudentQueryVariables
  >(Operations.GetLastContentEvaluationByContentAndStudent, options);
}
export function useGetLastContentEvaluationByContentAndStudentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastContentEvaluationByContentAndStudentQuery,
    GetLastContentEvaluationByContentAndStudentQueryVariables
  >
): Apollo.QueryTuple<
  GetLastContentEvaluationByContentAndStudentQuery,
  GetLastContentEvaluationByContentAndStudentQueryVariables
> {
  const options = { ...defaultOptions, ...baseOptions };
  return (ApolloReactHooks.useLazyQuery as TLazyQueryHook)<
    GetLastContentEvaluationByContentAndStudentQuery,
    GetLastContentEvaluationByContentAndStudentQueryVariables
  >(Operations.GetLastContentEvaluationByContentAndStudent, options);
}
export type GetLastContentEvaluationByContentAndStudentQueryHookResult =
  ReturnType<typeof useGetLastContentEvaluationByContentAndStudentQuery>;
export type GetLastContentEvaluationByContentAndStudentLazyQueryHookResult =
  ReturnType<typeof useGetLastContentEvaluationByContentAndStudentLazyQuery>;
export type GetLastContentEvaluationByContentAndStudentQueryResult =
  Apollo.QueryResult<
    GetLastContentEvaluationByContentAndStudentQuery,
    GetLastContentEvaluationByContentAndStudentQueryVariables
  >;
