// see: https://github.com/tc39/proposal-global
export const getGlobal = (): Window | NodeJS.Global | undefined => {
  if (typeof self !== 'undefined') {
    return self as Window;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global as NodeJS.Global;
  }
  throw new Error('Unable to locate global object.');
};
