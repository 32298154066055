import React from 'react';
import {
  Typography,
  Accordion,
  SvgArrowCircleDown,
  SvgArrowRight,
  Card,
} from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { object, string, func, bool } from 'prop-types';
import { linkDownload } from '../../../../../utils';
import * as Styled from '../mandatory-internship-subject-content.styles';
import { clickByKey } from '../../../../../modules';

const MandatoryInternshipSubjectContentAccordions = React.forwardRef(
  ({ config, tagComponent, pdfUrl, sendDocument, open }, ref) => {
    const theme = useTheme();

    return (
      <Accordion
        title={config?.name}
        markerColor={theme.colors.text.primary}
        iconColor={theme.colors.text.primary}
        contentMaxHeight="100rem"
        stateHandler={open}
      >
        <Styled.AccordionContent>
          {pdfUrl && (
            <Styled.FlexLink
              onClick={() =>
                linkDownload({
                  fileUrl: pdfUrl,
                  documentName: config?.name,
                  documentExtension: 'pdf',
                })
              }
              hoverColor={theme.colors.button.darkPrimary}
            >
              <SvgArrowCircleDown />
              <Typography
                size={16}
                weight={700}
                color={theme.colors.text.primary}
              >
                Baixar PDF
              </Typography>
            </Styled.FlexLink>
          )}
          {tagComponent}
        </Styled.AccordionContent>

        <Styled.SendWrapper ref={ref}>
          <Styled.CardContainer>
            <Card
              hover={theme.colors.card.color.hover}
              borderColor={theme.colors.card.border.primary}
              onClick={sendDocument}
              onKeyDown={(event) => clickByKey(event, sendDocument)}
              tabIndex={0}
            >
              <Styled.FlexSpaceBetween>
                <Typography size={16} color={theme.colors.text.black}>
                  Enviar {config?.name}
                </Typography>
                <SvgArrowRight fill={theme.colors.text.primary} aria-hidden />
              </Styled.FlexSpaceBetween>
            </Card>
          </Styled.CardContainer>
        </Styled.SendWrapper>
      </Accordion>
    );
  }
);

MandatoryInternshipSubjectContentAccordions.displayName =
  'MandatoryInternshipSubjectContentAccordions';

MandatoryInternshipSubjectContentAccordions.defaultProps = {
  open: null,
};

MandatoryInternshipSubjectContentAccordions.propTypes = {
  config: object,
  tagComponent: Node,
  pdfUrl: string,
  sendDocument: func | null,
  open: bool | null,
};

export default MandatoryInternshipSubjectContentAccordions;
