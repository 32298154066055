import React from 'react';
import {
  SvgDownloadAlt,
  Typography,
  convertPXToVH,
  convertPXToVW,
} from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { clickByKey } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import { useGenerateContentPDF } from '@ampli/services';

import { pushEvent } from '../../../lib/ga';
import { linkDownload } from '../../../utils';
import * as Styled from './styles';
import { DownloadFile, useDownloadFileAnimation } from '../../../modules';

interface ContentDownloadProps {
  subjectEnrollmentId?: string;
  subjectName?: string;
}

const ContentDownload = ({
  subjectEnrollmentId,
  subjectName,
}: ContentDownloadProps): React.ReactElement => {
  const theme = useTheme();
  const isMobile = useBreakpoint() === 'mobile';

  const downloadFile = () => {
    linkDownload({
      fileUrl: data.data.pdfUrl,
      documentName: `disciplina_${subjectName}`,
      documentExtension: 'pdf',
      target: '_self',
    })
      .then(() => setIsDownloading(false))
      .catch(() => setIsDownloading(false));
  };

  const { loading, error, getData, data } = useGenerateContentPDF(
    subjectEnrollmentId,
    {
      context: { autoHandle: true },
      onCompleted: () => {
        if (!loading && !error && data?.data?.pdfUrl) {
          downloadFile();
        }
      },
      onError: () => setIsDownloading(false),
    }
  );

  const handleDownload = () => {
    if (!isFinished && isDownloading === null) {
      setIsDownloading(true);
      pushEvent('event', 'subject-content-download');
      if (data?.data?.pdfUrl) {
        downloadFile();
      } else {
        getData();
      }
    }
  };

  const { isFinished, isDownloading, setIsDownloading } =
    useDownloadFileAnimation({
      error,
    });

  return (
    <Styled.Wrapper data-testid="beta-content-download">
      <Typography data-testid="beta-content-download-desc" weight={700}>
        {`${isMobile ? 'Disponível' : 'Conteúdo disponível'} em PDF`}
      </Typography>
      <Styled.ButtonDownload
        onClick={handleDownload}
        onKeyDown={(event: KeyboardEvent) => clickByKey(event, handleDownload)}
        borderBoxColor={theme.colors.button.primary}
        color={theme.colors.button.primary}
        width="auto"
        data-testid="beta-content-download-btn"
        padding={`${convertPXToVH(16)} ${convertPXToVW(16)}`}
        paddingMob={`${convertPXToVH(16)} ${convertPXToVW(16)}`}
      >
        <DownloadFile isDownloading={isDownloading} isFinished={isFinished}>
          <Typography as="span">Baixar PDF</Typography>
          <SvgDownloadAlt fill={theme.colors.button.primary} />
        </DownloadFile>
      </Styled.ButtonDownload>
    </Styled.Wrapper>
  );
};

ContentDownload.defaultProps = {
  subjectName: '',
};

export default ContentDownload;
