import React from 'react';

import { useBreakpoint } from '../../../../../..';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import * as Illustrations from './assets';
import * as Styles from './before-start.styles';

const margin = (isDesktop: boolean) =>
  isDesktop ? '8px 0px 0px' : '16px 0px 0px';

export const EstudeOndeQuiser: React.FC = () => {
  const theme = useTheme();

  const { isDesktop } = useBreakpoint();

  return (
    <Styles.Grid>
      <Illustrations.Notebook />

      <Styles.IllustrationBody>
        <Typography
          as="p"
          size={isDesktop ? 18 : 28}
          weight={700}
          lineHeight={'normal'}
        >
          Estude de onde quiser
        </Typography>
        <Typography
          as="p"
          size={isDesktop ? 14 : 18}
          color={theme.colors.text.lightBlack}
          margin={margin(isDesktop)}
        >
          Continue estudando de onde preferir e em diferentes horários.
        </Typography>
      </Styles.IllustrationBody>
    </Styles.Grid>
  );
};

export const AprendizadoDinamico: React.FC = () => {
  const theme = useTheme();

  const { isDesktop } = useBreakpoint();

  return (
    <Styles.Grid>
      <Illustrations.Book />

      <Styles.IllustrationBody>
        <Typography
          as="p"
          size={isDesktop ? 18 : 28}
          weight={700}
          lineHeight={'normal'}
        >
          Aprendizado dinâmico
        </Typography>
        <Typography
          as="p"
          size={isDesktop ? 14 : 18}
          color={theme.colors.text.lightBlack}
          margin={margin(isDesktop)}
        >
          Metodologia pensada para ensinar de forma dinâmica e prática.
        </Typography>
      </Styles.IllustrationBody>
    </Styles.Grid>
  );
};

export const PraticidadeNaRotina: React.FC = () => {
  const theme = useTheme();

  const { isDesktop } = useBreakpoint();

  return (
    <Styles.Grid>
      <Illustrations.Mobile />

      <Styles.IllustrationBody>
        <Typography
          as="p"
          size={isDesktop ? 18 : 28}
          weight={700}
          lineHeight={'normal'}
        >
          Praticidade na rotina
        </Typography>

        <Typography
          as="p"
          size={isDesktop ? 14 : 18}
          color={theme.colors.text.lightBlack}
          margin={margin(isDesktop)}
        >
          Acesse todas as funcionalidades a qualquer momento.
        </Typography>
      </Styles.IllustrationBody>
    </Styles.Grid>
  );
};

export const ConhecaPlataforma: React.FC = () => {
  const theme = useTheme();

  const { isDesktop } = useBreakpoint();

  return (
    <Styles.Grid>
      <Illustrations.Airplane />

      <Styles.IllustrationBody>
        <Typography as="p" size={28} weight={700} lineHeight={'normal'}>
          Conheça a plataforma
        </Typography>

        <Styles.Subtitle
          as="p"
          size={18}
          color={theme.colors.text.lightBlack}
          margin={margin(isDesktop)}
        >
          Faça um tour pelo AVA (Ambiente Virtual de Aprendizagem) e conheça as
          principais funcionalidades.
        </Styles.Subtitle>
      </Styles.IllustrationBody>
    </Styles.Grid>
  );
};
