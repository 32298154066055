import React from 'react';
import { Text } from './typography.styles';
import { string, func } from '@ampli/utils';

const Typography = ({
  color,
  size,
  weight,
  children,
  margin,
  cursor,
  onClick,
}) => {
  return (
    <Text
      onClick={onClick}
      margin={margin}
      color={color}
      weight={weight}
      size={size}
      cursor={cursor}
    >
      {children}
    </Text>
  );
};

export default Typography;

Typography.defaultProps = {
  onClick: () => {},
};

Typography.propTypes = {
  color: string,
  margin: string,
  weight: string,
  cursor: string,
  size: string,
  children: string,
  onClick: func,
};
