/* eslint-disable prettier/prettier */
import React from 'react';

import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';

import { useGetLastContentEvaluationByContentAndStudent } from '../../../../services/content-evaluation';
import { ContentEvaluationResponseProps, FooterProps } from '../../types';
import { NavigationFooter, Skeleton } from '../../../../../shared';
import { CommunitySectionButton } from '../../../../../community/features/discuss-on-community';
import { useSectionContext } from '../../section-provider';
import { pushAmpliEvent } from '../../../../../../lib/ga';
import { useBreakpoint } from '../../../../../';
import { useGetState } from '../../../../../../state';
import { routePath } from '../../../../../../routes';

import {
  sectionFooterButtonsAriaLabel,
  sectionFooterButtonsLabel,
  findLearningObjectIndex,
  SectionParams,
} from '../../../../shared';

import ContentEvaluation from './content-evaluation/content-evaluation';
import FeedbackSent from './content-evaluation/feedback-sent/feedback-sent';
import routesPath from '../../../../routes/routes-path';

import * as Styled from './footer-styles';

const Footer = React.forwardRef(
  (
    { learningObjects }: FooterProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const { params } = useRouteMatch<SectionParams>();
    const { isDesktop } = useBreakpoint();
    const history = useHistory();

    const {
      me,
      learningObject,
      learningUnit,
      course,
      subjectEnrollment,
      section,
      loading,
      isFirstLearningObject,
      isLastLearningObject,
      previousLearningObject,
      nextLearningObject,
    } = useSectionContext();

    const [
      {
        selectedCourseEnrollmentType,
        selectedCourseEnrollmentId,
        selectedCourseVersionId,
      },
    ] = useGetState();

    const [newReviewSent, setReviewSent] = React.useState(false);

    const { getData, contentEvaluationLoading, reload } =
      useGetLastContentEvaluationByContentAndStudent({
        studentId: me?.personId,
        learningObjectId: learningObject?.id,
      });

    const contentEvaluation =
      !contentEvaluationLoading &&
      !loading &&
      getData({
        me,
        learningObject,
        learningUnit,
        subjectId: subjectEnrollment?.subject?.id || '',
        subjectEnrollmentId: params.subjectEnrollmentId,
        courseId: course?.id || '',
        section,
        selectedCourseEnrollmentType,
        selectedCourseEnrollmentId,
        selectedCourseVersionId,
      });

    const isLastSection = React.useMemo(
      () => learningUnit?.sections?.length <= section?.order,
      [learningUnit, section]
    );

    const getNextSection = () => {
      if (isLastSection) {
        return generatePath(routePath.subject, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
          subjectEnrollmentId: params.subjectEnrollmentId,
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { learningObjectPath, ...rest } = params;
        const newParams = { ...rest };
        const nextSection =
          learningUnit.sections.find(
            (sectionObject) => sectionObject.order === section.order + 1
          ) || learningUnit.sections[section.order - 1];
        newParams.sectionPath = nextSection.pathUrl || '';
        return generatePath(routesPath.subjectSection, newParams);
      }
    };

    const indexForPreviousLearningObject = findLearningObjectIndex(
      previousLearningObject,
      section?.learningObjects
    );

    const indexForNextLearningObject = findLearningObjectIndex(
      nextLearningObject,
      section?.learningObjects
    );

    return (
      <Styled.RatingSection>
        <Styled.Footer>
          {!contentEvaluationLoading ? (
            contentEvaluation ? (
              contentEvaluation?.rating !== 0 && !newReviewSent ? (
                <FeedbackSent onClickReviewSent={() => setReviewSent(true)} />
              ) : contentEvaluation ? (
                <ContentEvaluation
                  sendFeedback={() => setReviewSent(false)}
                  contentEvaluation={
                    contentEvaluation as ContentEvaluationResponseProps
                  }
                  onNewContentEvaluation={() => {
                    setReviewSent(true);
                    reload();
                  }}
                />
              ) : null
            ) : null
          ) : (
            <Styled.SkeletonWrapper>
              <Skeleton height="24px" width="30%" />
              <Skeleton height="44px" width="30%" />
            </Styled.SkeletonWrapper>
          )}

          <Styled.Hr />

          {!isDesktop ? <CommunitySectionButton /> : null}

          <NavigationFooter
            {...sectionFooterButtonsLabel[isDesktop ? 'desktop' : 'mobile']}
            {...sectionFooterButtonsAriaLabel({
              prevIndex: indexForPreviousLearningObject,
              nextIndex: indexForNextLearningObject,
              isLastLearningObject,
              learningObjects: learningObjects.length,
            })}
            isFirstStep={isFirstLearningObject}
            isLastStep={isLastLearningObject}
            lastStepLabel={isLastSection ? 'Concluir aula' : 'Próxima aula'}
            onPreviousClick={
              previousLearningObject
                ? () => {
                    pushAmpliEvent('event', 'object_footer_previous');
                    history.push(previousLearningObject.pathUrl || '', {
                      fromSubjectPage: false,
                    });
                  }
                : null
            }
            onNextClick={() => {
              pushAmpliEvent(
                'event',
                nextLearningObject
                  ? 'object_footer_next'
                  : 'object_footer_finish'
              );
              history.push(
                nextLearningObject?.pathUrl
                  ? nextLearningObject.pathUrl
                  : getNextSection(),
                { fromSubjectPage: false }
              );
            }}
            ref={ref}
          />
        </Styled.Footer>
      </Styled.RatingSection>
    );
  }
);

Footer.displayName = 'Footer';

export default Footer;
