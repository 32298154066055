import { gql } from 'graphql.macro';

export const GET_EXTERNAL_PAYMENT = gql`
  query GetExternalPayment($courseEnrollmentId: String!, $invoiceId: String!) {
    data: getExternalPayment(
      courseEnrollmentId: $courseEnrollmentId
      invoiceId: $invoiceId
    ) {
      externalPaymentUrl
    }
  }
`;
