import {
  ESubjectEnrollmentStatus,
  ISubjectEnrollment,
} from '../../../shared/contexts/course-enrollments-context.types';

export type TFilterActiveEnrollmentsReturn = ISubjectEnrollment[] | null;

export const EActiveEnrollmentStatuses = [
  ESubjectEnrollmentStatus.AWAITING_CHOICE,
  ESubjectEnrollmentStatus.IN_PROGRESS,
  ESubjectEnrollmentStatus.IN_RECOVERY,
  ESubjectEnrollmentStatus.PAUSED,
];
