import React from 'react';

const ImageIlustracaoSuccess = (): React.ReactElement => {
  return (
    <svg
      width="190"
      height="169"
      viewBox="0 0 190 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M112.049 169C143.882 169 169.688 143.305 169.688 111.608C169.688 79.9116 143.882 54.2164 112.049 54.2164C80.216 54.2164 54.4102 79.9116 54.4102 111.608C54.4102 143.305 80.216 169 112.049 169Z"
        fill="#E0F0ED"
      />
      <path
        d="M49.951 119.815C47.6792 119.812 45.4378 119.295 43.3958 118.304L23.2613 108.502C22.1349 107.948 21.17 107.116 20.459 106.085C19.7479 105.053 19.3145 103.858 19.2002 102.612L18.4727 93.8284L119.788 51.0613L123.827 53.0303L124.781 53.4918C127.115 54.6291 128.727 56.9394 129.319 59.9969C129.921 63.1069 129.398 66.6403 127.847 69.9464C125.344 75.2765 120.693 78.8582 116.273 78.859C115.21 78.865 114.158 78.6379 113.193 78.1938L113.009 78.1089L58.6668 117.019C56.1302 118.842 53.0797 119.821 49.951 119.815Z"
        fill="white"
      />
      <path
        d="M119.776 51.4395L123.675 53.3403L124.626 53.8004C126.862 54.8893 128.407 57.1129 128.977 60.0617C129.565 63.1009 129.052 66.5595 127.531 69.8C126.308 72.4056 124.559 74.616 122.472 76.1922C120.462 77.7111 118.318 78.5139 116.271 78.5139C115.258 78.5198 114.256 78.3037 113.337 77.8809L112.97 77.7109L112.641 77.9462L58.4628 116.738C55.9852 118.52 53.0053 119.476 49.9491 119.47C47.7299 119.467 45.5405 118.963 43.5458 117.994L23.4119 108.192C22.3371 107.668 21.4164 106.877 20.7387 105.894C20.061 104.912 19.6493 103.773 19.5434 102.586L18.8363 94.048L119.776 51.4395ZM119.797 50.6827L18.1055 93.6083L18.8537 102.642C18.9695 103.948 19.4219 105.202 20.1671 106.283C20.9123 107.363 21.9251 108.234 23.1075 108.811L43.242 118.613C45.7225 119.821 48.4855 120.337 51.2369 120.105C53.9883 119.873 56.6252 118.903 58.8667 117.298L113.045 78.5055C114.056 78.9708 115.158 79.2088 116.271 79.2028C120.695 79.2028 125.537 75.6752 128.158 70.0917C131.438 63.0992 129.949 55.6268 124.93 53.1818L123.977 52.7208L119.796 50.6826L119.797 50.6827Z"
        fill="#212529"
      />
      <path
        d="M58.3748 106.786C56.1125 108.857 53.2755 110.199 50.2346 110.639C47.1937 111.079 44.0903 110.596 41.3298 109.252L22.3547 100.012C18.4801 98.1262 15.9922 91.8692 20.4596 87.6585L72.4661 38.6393C74.725 36.5092 77.5834 35.1169 80.6588 34.6487C83.7342 34.1806 86.8799 34.659 89.6747 36.0199L124.927 53.1824L58.3748 106.786Z"
        fill="#66CAAE"
      />
      <path
        d="M110.175 52.9347C109.568 50.1021 107.86 40.4259 113.104 30.0564C118.831 18.7302 128.889 14.4121 131.405 13.4151L105.445 0.775425C104.35 0.240558 103.142 -0.0247453 101.923 0.00181488C100.703 0.0283751 99.5083 0.346023 98.4378 0.928058C94.2546 3.21033 87.9518 7.72498 83.8596 15.8176C78.6161 26.187 80.3231 35.8631 80.9306 38.6958L110.175 52.9347Z"
        fill="#FFE6C9"
      />
      <path
        d="M35.6328 91.9L50.8154 77.5886"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeMiterlimit="10"
      />
      <path
        d="M46.6875 97.284L61.87 82.9727"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeMiterlimit="10"
      />
      <path
        d="M41.6992 78.8746L62.6145 89.0582"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeMiterlimit="10"
      />
      <path
        d="M34.6289 85.533L55.5442 95.7165"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeMiterlimit="10"
      />
      <path
        d="M110.684 53.1804L80.1094 38.2937"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeMiterlimit="10"
      />
      <path
        d="M46.1585 38.7595C44.7355 40.7906 39.7601 39.3781 37.8785 40.7957C35.9284 42.2651 35.9199 47.3976 33.6567 48.176C31.3708 48.9622 28.1729 44.9227 25.755 44.9709C23.3609 45.0187 20.3239 49.1881 17.9841 48.48C15.7266 47.7965 15.5265 42.6744 13.4866 41.2576C11.4467 39.8408 6.55006 41.4227 5.12633 39.5492C3.6505 37.6073 6.53148 33.3294 5.75008 31.0759C4.96044 28.7998 0.048734 27.2065 0.000355233 24.7989C-0.0476611 22.4152 4.78686 20.6347 5.49823 18.305C6.1845 16.0571 3.13501 11.8956 4.55801 9.86449C5.98102 7.83336 10.9563 9.24576 12.8379 7.82813C14.7881 6.35891 14.7966 1.22642 17.0597 0.448011C19.3456 -0.338245 22.5436 3.70128 24.9615 3.65302C27.3555 3.6053 30.3925 -0.564127 32.7323 0.143918C34.9899 0.827337 35.1899 5.94936 37.2298 7.36627C39.2697 8.78317 44.1664 7.20109 45.5901 9.07463C47.0657 11.0165 44.1848 15.2943 44.9666 17.5479C45.7561 19.824 50.6678 21.4173 50.7163 23.8249C50.7642 26.2086 45.9297 27.9891 45.2184 30.3188C44.5322 32.5672 47.5817 36.7283 46.1585 38.7595Z"
        fill="#008566"
      />
      <path
        d="M34.819 17.884L23.2913 29.3624L18.6523 24.7434"
        stroke="#E0F0ED"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.525 103.027C138.143 104.962 133.917 111.058 134.275 118.269C134.335 119.474 134.506 122.332 135.941 122.858C137.702 123.504 140.441 120.325 141.445 118.992C144.672 114.706 152.747 99.7933 162.748 79.1548C157.1 83.7213 147.112 92.3981 139.525 103.027Z"
        fill="#212529"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.009 87.3709L146.281 113.375C137.89 108.078 130.643 105.115 125.631 103.394C118.504 100.94 111.763 99.5848 104.612 93.7365C98.076 88.386 98.6962 85.4168 98.8531 84.829C99.2627 83.2754 100.672 82.4114 101.329 82.0098C102.014 81.596 102.757 81.2866 103.534 81.092C100.383 79.5744 97.9013 77.463 97.4675 75.0757C97.2238 73.5353 97.5354 71.9587 98.347 70.6249C99.7688 68.3395 102.287 67.7157 102.974 67.5658C101.365 65.9343 100.353 64.1768 100.371 62.5213C100.391 61.6742 100.647 60.8494 101.112 60.1398C103.468 56.6667 110.287 58.0224 110.883 58.1483C108.775 55.2991 107.751 51.97 108.775 49.2769C110.263 45.354 115.576 44.1183 116.763 43.8784C128.251 41.551 138.215 54.8193 138.691 55.4731C138.088 49.0249 139.293 44.1783 140.119 41.5991C142.088 35.4508 147.558 24.5698 154.86 24.318C155.601 24.2879 158.245 24.306 159.679 26.0395C162.083 28.9486 159.27 34.5031 157.752 38.51C153.24 50.3926 153.788 65.1965 158.908 74.1939C160.673 77.3015 165.118 82.4954 173.009 87.3709Z"
        fill="#C5D2E0"
      />
      <path
        d="M140.118 41.6001C139.113 44.735 137.555 51.2067 139.341 59.8258M140.841 89.1534C138.914 87.1533 137.399 84.7976 136.38 82.2187C134.34 77.0305 134.922 72.4073 135.316 70.3411"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeMiterlimit="10"
      />
      <path
        d="M109.238 92.7001C110.909 92.6985 115.599 92.6939 117.118 89.8141C117.831 88.461 117.533 87.0641 117.496 86.9029C116.433 82.2469 106.673 78.739 101.333 82.0094C100.782 82.3468 99.2797 83.2053 98.8552 84.8289C98.2951 86.9708 99.9755 88.9785 100.422 89.5107C103.096 92.7062 107.583 92.7017 109.238 92.7001Z"
        fill="#C5D2E0"
      />
      <path
        d="M118.291 74.1157C118.847 74.5738 119.297 75.1457 119.611 75.7927C120.587 77.8643 119.948 80.5692 118.962 81.7794C115.403 86.1488 98.6736 81.6549 97.4743 75.0759C97.2796 74.0075 97.3686 72.1941 98.351 70.6245C99.7316 68.419 102.202 67.7716 102.985 67.5664C108.945 66.0044 114.547 70.8663 118.291 74.1157Z"
        fill="#C5D2E0"
      />
      <path
        d="M121.563 64.4243C122.711 65.8389 124.216 67.7496 123.809 69.9372C123.469 71.7575 121.973 72.8724 121.697 73.069C116.111 77.0545 100.295 69.0153 100.371 62.5206C100.387 61.6715 100.645 60.8444 101.117 60.1365C103.31 56.9259 109.509 57.9264 110.892 58.15C111.385 58.2295 117.397 59.2912 121.563 64.4243Z"
        fill="#C5D2E0"
      />
      <path
        d="M118.911 49.4763C119.72 49.6217 124.521 50.5631 126.813 54.8104C127.068 55.2824 129.407 59.7571 127.215 62.5447C125.226 65.0737 120.943 64.389 120.251 64.2783C113.347 63.1748 106.657 54.8618 108.778 49.2749C108.989 48.7248 109.278 48.208 109.638 47.7406C110.168 47.0614 110.805 46.471 111.522 45.9917"
        fill="#C5D2E0"
      />
      <path
        d="M109.23 92.7013C112.902 93.0982 115.981 91.9924 117.11 89.8152C117.203 89.6366 117.828 88.3881 117.489 86.9041C117.198 85.63 116.256 84.4414 114.931 83.4551"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeMiterlimit="10"
      />
      <path
        d="M118.283 74.1171C118.838 74.5761 119.288 75.1479 119.602 75.7941C120.622 77.9261 119.909 80.6072 118.953 81.7809C116.22 85.1375 105.713 83.2634 100.445 79.2316"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeMiterlimit="10"
      />
      <path
        d="M121.557 64.4253C121.927 64.7785 124.345 67.1496 123.803 69.9382C123.455 71.7264 122.062 72.8056 121.691 73.07C117.071 76.3661 105.455 71.4373 101.617 65.9603"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeMiterlimit="10"
      />
      <path
        d="M118.901 49.4775C119.71 49.6229 124.511 50.5643 126.803 54.8116C127.058 55.2837 129.397 59.7583 127.205 62.5459C125.216 65.0749 120.933 64.3901 120.241 64.2795C115.744 63.5606 111.338 59.7834 109.398 55.6932"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeMiterlimit="10"
      />
      <path
        d="M189.999 99.9972L158.821 145.153C151.286 137.345 145.709 132.251 142.094 128.813C139.906 126.729 137.721 124.671 135.539 122.636C135.557 122.648 135.611 122.685 135.67 122.719C135.716 122.751 135.765 122.779 135.815 122.804C135.855 122.825 135.897 122.843 135.94 122.857C137.696 123.503 140.437 120.327 141.442 118.991C144.669 114.707 152.745 99.7962 162.748 79.1567L189.999 99.9972Z"
        fill="#698AAE"
      />
      <path
        d="M141.488 128.236C145.51 126.677 148.881 123.805 151.049 120.09L171.174 85.6007"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeMiterlimit="10"
      />
      <path
        d="M134.277 118.269C134.337 119.474 134.508 122.332 135.943 122.858C137.704 123.504 140.443 120.325 141.447 118.992C144.674 114.706 152.749 99.7933 162.75 79.1548"
        stroke="#212529"
        strokeWidth="0.69224"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImageIlustracaoSuccess;
