import React from 'react';

import { useGenerateTutorToken } from '../../messaging/components/talk-tutor-frame/services/hooks';
import { getTutorPreviewPath } from '../../messaging/components/talk-tutor-frame/components/ptk-frame/config';
import { SectionParams } from '../../section/shared';
import { useParams } from 'react-router-dom';

import {
  UseGenerateTutorPreviewTokenReturn,
  UseGenerateTutorPreviewTokenProps,
} from '../..';

export const useGenerateTutorPreviewToken = ({
  talkTutorInfos,
}: UseGenerateTutorPreviewTokenProps): UseGenerateTutorPreviewTokenReturn => {
  const { learningObjectPath } = useParams<SectionParams>();

  const [generateTutorToken] = useGenerateTutorToken<{
    data: { data: string };
  }>(talkTutorInfos?.learningUnitEnrollmentId, talkTutorInfos?.classID);

  const getObjectLearningPath = React.useCallback(async () => {
    if (!talkTutorInfos?.learningUnitEnrollmentId) return '';

    const { data: token } = await generateTutorToken();

    return getTutorPreviewPath(
      learningObjectPath,
      talkTutorInfos?.learningUnitEnrollmentId,
      talkTutorInfos?.classID,
      token.data
    );
  }, [
    generateTutorToken,
    learningObjectPath,
    talkTutorInfos?.classID,
    talkTutorInfos?.learningUnitEnrollmentId,
  ]);

  return { getObjectLearningPath };
};
