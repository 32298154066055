import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 56px;

  > button {
    width: auto;
  }

  > button:not(:last-of-type) {
    margin-top: 0px;
    margin-right: 24px;
  }

  ${mediaQuery('mobile')} {
    margin-top: 40px;
    flex-direction: column;
    justify-content: center;

    > button:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`;
