import styled from '@emotion/styled';
import {
  ButtonGhost,
  convertPXToVW,
  convertPXToVH,
  mediaQuery,
} from '@sofia/ui';

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexColumn = styled(FlexCenter)`
  flex-direction: column;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled(FlexCenter)`
  column-gap: ${convertPXToVW(24)};

  & > button {
    min-width: auto;
  }

  ${mediaQuery('tablet-max')} {
    padding: ${convertPXToVH(8)} ${convertPXToVW(16)};
    width: 100%;
    justify-content: center;
    background: ${({ theme }) => theme.colors.background.primary};
    column-gap: ${convertPXToVW(20)};
  }
`;

export const QuizButton = styled(ButtonGhost)`
  display: flex;
  align-items: center;
  column-gap: ${convertPXToVW(12)};
  padding: ${convertPXToVH(8)} ${convertPXToVW(10.5)};
  margin: ${convertPXToVH(4)} 0;

  ${mediaQuery('tablet-max')} {
    column-gap: ${convertPXToVW(8)};
    padding: ${convertPXToVH(8)} 0;
    margin: 0;
  }
`;
