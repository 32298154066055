import React from 'react';

import {
  SvgCommentMessage,
  SvgCommentNotes,
  SvgArrowRight,
  convertPXToVH,
  Typography,
} from '@sofia/ui';

import { FormType, PageConversationInfo } from '../../types';
import { CustomButtonOutline } from './form-type-selection.styles';
import { ListWrapperCore } from '../../../../../../../shared/components/section-right-frame/section-right-frame.styles';
import { usePtkFrame } from '../../contexts';
import { BackButton } from '../../components';

import { useQueryClient } from 'react-query';
import { useTheme } from '@emotion/react';
import { Heart } from '@ampli/icon';

interface ButtonProps {
  formType: typeof FormType[number];
  title: string;
  icon: 'message' | 'notes' | 'heart';
}

const Button: React.FC<ButtonProps> = ({
  formType,
  title,
  icon,
}: ButtonProps) => {
  const theme = useTheme();

  const { dispatch } = usePtkFrame();

  const Icon = React.useMemo(
    () =>
      ({
        message: SvgCommentMessage,
        notes: SvgCommentNotes,
        heart: Heart,
      }[icon]),
    [icon]
  );

  return (
    <CustomButtonOutline
      type="button"
      onClick={() => dispatch({ page: 'FORM', formType })}
      borderBoxColor={theme.colors.card.border.primary}
    >
      <span>
        <Icon fill={theme.colors.icon.primary} />
        <Typography
          margin={`0px 0px 0px ${convertPXToVH(18)}`}
          space="nowrap"
          color={theme.colors.text.secondary}
        >
          {title}
        </Typography>
      </span>

      <span>
        <SvgArrowRight fill={theme.colors.icon.primary} />
      </span>
    </CustomButtonOutline>
  );
};

const FormTypeSelectionSection: React.FC = () => {
  const queryClient = useQueryClient();

  const { queryKeys, scrollType } = usePtkFrame();

  const studentCachedList = React.useMemo(
    () => queryClient.getQueryData<PageConversationInfo>(queryKeys.home),
    [queryClient, queryKeys.home]
  );

  const tutorCachedList = React.useMemo(
    () => queryClient.getQueryData<PageConversationInfo>(queryKeys.tutor),
    [queryClient, queryKeys.tutor]
  );

  const showBackButton = React.useMemo(
    () =>
      Boolean(
        studentCachedList?.content?.length || tutorCachedList?.content?.length
      ),
    [studentCachedList?.content?.length, tutorCachedList?.content?.length]
  );

  return (
    <ListWrapperCore scroll={scrollType}>
      {showBackButton ? <BackButton /> : null}

      <Typography weight="700" size={20}>
        Sobre o que gostaria de falar com o tutor?
      </Typography>

      <Button
        formType="DUVIDA"
        title="Enviar dúvida de estudo"
        icon="message"
      />

      <Button formType="RECLAMACAO" title="Enviar crítica" icon="notes" />
      <Button formType="ELOGIO" title="Enviar elogio" icon="heart" />
    </ListWrapperCore>
  );
};

export default FormTypeSelectionSection;
