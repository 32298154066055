import React from 'react';

import { useCourseEnrollmentsContext } from '../../../../modules/shared/contexts/course-enrollments-context';
import { styleOptions } from '../../helpers';
import { DirectLine } from '../../types';

export const useRenderWebChat = (directLine: DirectLine): void => {
  const { states } = useCourseEnrollmentsContext();

  const { loading: loadingEnrollment } = states.courseEnrollments;

  const WebChat = React.useMemo(() => window?.WebChat, []);

  const locale = document.documentElement.lang || 'pt-BR';

  React.useEffect(() => {
    if (window && directLine && !loadingEnrollment) {
      WebChat?.renderWebChat(
        { directLine, locale, styleOptions },
        document.getElementById('webchat')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WebChat, directLine, loadingEnrollment]);
};
