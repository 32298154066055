import React, { useMemo } from 'react';

import Menu from '../../menu';
import NotesMenuHeader from './header/notes-menu-header';
import { useGetState } from '../../../../../../../state';
import { useGetAnnotationsByCourseEnrollmentAndSubject } from '@ampli/services';
import NotesMenuObjectList from './list/object/notes-menu-object-list';
import NotesMenuSectionList from './list/section/notes-menu-section-list';
import { pushAmpliEvent } from '../../../../../../../lib/ga';
import {
  NotesOnboarding,
  NoteType,
  useGetNotesOnboarding,
} from '../../../../../../notes/shared';
import { NotesMenuProps, SectionType } from './types';

const NotesMenu = ({
  open,
  currentSection,
  sections,
  learningUnitId,
  learningUnitOrder,
  learningUnitTitle,
  learningObjectId,
  learningObjects,
  subjectId,
  onToggleMenuClick,
  loading,
  learningUnitVersionId,
}: NotesMenuProps): React.ReactElement => {
  const [{ selectedCourseEnrollmentId }, { setHasShownNotesOnboarding }] =
    useGetState();
  const { showNotesOnboarding, onConcludeOnboarding } = useGetNotesOnboarding();

  const [listState, setListState] = React.useState('object');
  const [notesSection, setNotesSection] = React.useState(currentSection);
  const checkListState = () => listState === 'object';

  const { getData, subjectAnnotationsLoading } =
    useGetAnnotationsByCourseEnrollmentAndSubject(
      selectedCourseEnrollmentId,
      subjectId
    );

  const subjectNotes = useMemo(
    () => (!loading ? getData() : []),
    [getData, loading]
  );

  const allSubjectNotes: NoteType[] = useMemo(() => {
    return [...(subjectNotes || [])].sort(
      (firstNote: NoteType, secondNote: NoteType) =>
        Number(new Date(firstNote.createdDate)) -
        Number(new Date(secondNote.createdDate))
    );
  }, [subjectNotes]);

  const isCurrentSection = () => {
    return currentSection?.id === notesSection?.id;
  };

  const onSelectSection = (section: SectionType) => {
    setListState('object');
    setNotesSection(section);
    pushAmpliEvent('event', `notes-menu-selected-section-${section?.order}`);
  };

  const onReturnCurrentSectionNotes = () => {
    pushAmpliEvent('event', `notes-menu-return-current-section`);
    onSelectSection(currentSection);
  };

  React.useEffect(() => {
    setHasShownNotesOnboarding(!showNotesOnboarding);
  }, [showNotesOnboarding, setHasShownNotesOnboarding]);

  React.useEffect(() => {
    setNotesSection(currentSection);
  }, [currentSection]);

  return (
    <Menu
      open={open}
      fromRight
      absolute
      closedSize={0}
      header={<NotesMenuHeader onToggleMenuClick={onToggleMenuClick} />}
      content={
        showNotesOnboarding ? (
          <NotesOnboarding onConcludeOnboarding={onConcludeOnboarding} />
        ) : (
          <>
            {checkListState() ? (
              <NotesMenuObjectList
                loading={loading || subjectAnnotationsLoading}
                subjectNotes={allSubjectNotes}
                learningUnitId={learningUnitId}
                learningUnitOrder={learningUnitOrder}
                learningUnitTitle={learningUnitTitle}
                learningObjectId={learningObjectId}
                learningObjects={learningObjects}
                learningUnitVersionId={learningUnitVersionId}
                section={notesSection}
                subjectId={subjectId}
                onReturnToSectionList={() => {
                  pushAmpliEvent('event', 'notes-menu-section-list-return');
                  setListState('Section');
                }}
                isCurrentSection={isCurrentSection()}
                onReturnCurrentSectionNotes={onReturnCurrentSectionNotes}
              />
            ) : (
              <NotesMenuSectionList
                loading={loading || subjectAnnotationsLoading}
                learningUnitId={learningUnitId || ''}
                learningUnitOrder={learningUnitOrder}
                learningUnitTitle={learningUnitTitle}
                sections={sections}
                subjectNotes={allSubjectNotes}
                currentSection={currentSection}
                onSectionClick={onSelectSection}
                onReturnCurrentSectionNotes={onReturnCurrentSectionNotes}
              />
            )}
          </>
        )
      }
    />
  );
};

NotesMenu.defaultProps = {
  notes: [],
  loading: false,
};

export default NotesMenu;
