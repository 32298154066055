import React, { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '@ampli/hooks';
import { string, oneOf, func, object } from 'prop-types';
import {
  PopUp,
  Typography,
  useModal,
  ButtonGhost,
  ButtonPrimary,
} from '@sofia/ui';
import { INTERNSHIP_STATUS } from '../../../../constants';
import * as Styled from './mandatory-internship-subject-messages.styles';
import { useGetState } from '../../../../state';

const MandatoryInternshipSubjectMessages = ({
  status,
  rejectionReason,
  reprovedSignatureFeedback,
  onPendingApproval,
  onFirstNotNow,
  releasedModal,
  onStartTour,
}) => {
  const [, { setAccessInternshipSubject }] = useGetState();

  const pendingReleaseModal = useModal();
  const reproveDocumentModal = useModal();
  const reproveSignatureModal = useModal();

  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const onRedoInternshipOnboarding = () => setAccessInternshipSubject(false);

  const responsiveButton = (buttonText, buttonClick) => {
    return (
      <Styled.ButtonWrapper>
        <ButtonGhost
          hoverColor={theme.colors.button.darkPrimary}
          color={theme.colors.button.primary}
          aria-label={buttonText}
          onClick={buttonClick}
        >
          {buttonText}
        </ButtonGhost>
      </Styled.ButtonWrapper>
    );
  };

  useEffect(() => {
    const modalByStatus = {
      PENDING_RELEASE: pendingReleaseModal,
      NOT_RELEASED: reproveDocumentModal,
      REPROVED: reproveSignatureModal,
      RELEASED: releasedModal,
    };

    if (status && modalByStatus[status]) {
      modalByStatus[status].show();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      {/* RELEASED */}
      {releasedModal ? (
        <PopUp
          shouldCloseOnClickOutside={false}
          shouldCloseOnEsc={false}
          handlePopUp={releasedModal}
          footer={
            <Styled.ButtonsWrapper>
              <ButtonGhost
                hoverColor={theme.colors.button.darkPrimary}
                color={theme.colors.button.primary}
                aria-label="Dispensar passo a passo"
                onClick={() => {
                  releasedModal.hide();
                  onFirstNotNow();
                }}
              >
                Agora não
              </ButtonGhost>
              <ButtonPrimary
                color={theme.colors.text.white}
                background={theme.colors.button.primary}
                aria-label="Iniciar passo a passo"
                onClick={onStartTour}
              >
                Iniciar passo a passo!
              </ButtonPrimary>
            </Styled.ButtonsWrapper>
          }
          isBottomSheet={!isDesktop}
          header={{
            headerText: 'Seu cadastro foi aprovado',
          }}
          ariaLabel="Modal informando a aprovação do estágio e indicando o início do tutorial"
        >
          <Typography size={16} color={theme.colors.text.lightBlack}>
            Agora você precisa realizar o download do seu{' '}
            <strong>termo de estágio</strong> para coletar as assinaturas.
            Somente após o seu termo ser <strong>Aprovado</strong>, você poderá
            iniciar seu estágio.
          </Typography>
        </PopUp>
      ) : null}

      {/* PENDING */}
      <PopUp
        hideCloseIcon
        shouldCloseOnClickOutside={false}
        shouldCloseOnEsc={false}
        handlePopUp={pendingReleaseModal}
        footer={
          isDesktop
            ? {
                buttonText: 'Entendi',
                background: theme.colors.button.primary,
                buttonClick: onPendingApproval,
              }
            : responsiveButton('Entendi', onPendingApproval)
        }
        isBottomSheet={!isDesktop}
        header={{
          headerText: 'Seu termo de compromisso ainda não foi liberado',
        }}
        ariaLabel="Modal informando a não liberação do termo de compromisso do estágio"
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          Seu cadastro de estágio ainda está em análise.
        </Typography>
      </PopUp>

      {/* REPROVED */}
      <PopUp
        hideCloseIcon
        shouldCloseOnClickOutside={false}
        shouldCloseOnEsc={false}
        handlePopUp={reproveDocumentModal}
        isBottomSheet={!isDesktop}
        footer={
          isDesktop
            ? {
                buttonText: 'Refazer cadastro',
                background: theme.colors.button.primary,
                buttonClick: onRedoInternshipOnboarding,
              }
            : responsiveButton('Refazer cadastro', onRedoInternshipOnboarding)
        }
        header={{
          headerText: 'Seu termo de compromisso não foi aprovado',
        }}
        ariaLabel="Modal informando a reprova no termo de compromisso do estágio"
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          {rejectionReason}
        </Typography>
      </PopUp>

      {/* REPROVED SIGNATURE */}
      <PopUp
        hideCloseIcon
        handlePopUp={reproveSignatureModal}
        footer={{
          buttonText: 'Entendi',
          background: theme.colors.button.primary,
          buttonClick: reproveSignatureModal.hide,
        }}
        isBottomSheet={!isDesktop}
        header={{
          headerText:
            'A assinatura do seu termo de compromisso não foi aprovada',
        }}
        ariaLabel="Modal informando a reprova na assinatura do termo de compromisso do estágio"
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          {reprovedSignatureFeedback ||
            'Verifique as assinaturas do seu documento e envie novamente'}
        </Typography>
      </PopUp>
    </>
  );
};

MandatoryInternshipSubjectMessages.defaultProps = {
  releasedModal: null,
};

MandatoryInternshipSubjectMessages.propTypes = {
  status: oneOf(Object.keys(INTERNSHIP_STATUS)).isRequired,
  rejectionReason: string,
  reprovedSignatureFeedback: string,
  onPendingApproval: func.isRequired,
  onFirstNotNow: func.isRequired,
  releasedModal: object || null,
  onStartTour: func,
};

export default MandatoryInternshipSubjectMessages;
