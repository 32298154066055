type KeyCode = Record<'space' | 'enter' | 'esc', { keyCode: number }>;

export const KEY_CODES: KeyCode = {
  space: {
    keyCode: 32,
  },
  enter: {
    keyCode: 13,
  },
  esc: {
    keyCode: 27,
  },
};
