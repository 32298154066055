import React from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from '@ampli/utils';

import { initialValues, PtkPages, reducer, State } from './ptk-frame-state';
import { useGenerateTutorPreviewToken } from '../../../../../../shared';
import { useDynamicsLogic } from '../hooks/use-dynamics-logic';
import { GetConversation } from '../types';

import {
  getFrameFormSchema,
  SharedContextProps,
  FormValuesProps,
  UploadedFile,
  useFrame,
  Formik,
} from '../../../shared';

export type ProviderProps = {
  children: React.ReactNode;
};

export type ContextProps = Partial<SharedContextProps> &
  Omit<State, 'onCloseClick' | 'onError'> & {
    conversationsTutorQueries: Partial<GetConversation> | Record<string, never>;
    conversationsHomeQueries: Partial<GetConversation> | Record<string, never>;
    conversationsHistoricQueries:
      | Partial<GetConversation>
      | Record<string, never>;
    isSubmiting: boolean;
    queryKeys: { [key: string]: unknown[] };
    dispatch: React.Dispatch<Partial<State>>;
    formik: Formik;
    onUploadFile: (label: string, value: UploadedFile[]) => Promise<void>;
    onSubmit: (e: React.FormEvent) => void;
    onClose: () => void;
    onBack: (page?: PtkPages, callback?: () => void) => void;
  };

export const PtkFrameContext = React.createContext<ContextProps>({
  ...initialValues('HOME'),
  conversationsHistoricQueries: {},
  conversationsTutorQueries: {},
  conversationsHomeQueries: {},
  isSubmiting: false,
  scrollType: '',
  queryKeys: {},
  isAmpli: true,
  formik: {},
  tutor: {},
  me: {},
  onUploadFile: async () => {},
  dispatch: () => {},
  onSubmit: () => {},
  onClose: () => {},
  onBack: () => {},
});

export const PtkFrameProvider: React.FC<ProviderProps> = ({
  children,
}: ProviderProps) => {
  let formik: Formik = React.useMemo(() => ({}), []);

  const {
    isCourseEnrollmentBlocked,
    subjectEnrollment,
    courseData,
    scrollType,
    subject,
    isAmpli,
    tutor,
    me,
  } = useFrame();

  const initialPtkPage: PtkPages = isCourseEnrollmentBlocked
    ? 'BLOCKED'
    : 'HOME';

  const [state, dispatch] = React.useReducer(reducer, {
    ...initialValues(initialPtkPage),
  });

  const { createConversation, replyConversation } = useDynamicsLogic();

  const { getObjectLearningPath } = useGenerateTutorPreviewToken({
    talkTutorInfos: state.infos,
  });

  const queryClient = useQueryClient();

  const conversationsHistoricQueries: Partial<GetConversation> = React.useMemo(
    () => ({
      ...state.pageable,
      courseEnrollmentID: courseData?.id,
      subjectEnrollmentID:
        subjectEnrollment?.id || state.infos?.subjectEnrollmentID,
      typeList: ['QUESTION', 'REMOTE'],
    }),
    [
      state.infos?.subjectEnrollmentID,
      subjectEnrollment?.id,
      courseData?.id,
      state.pageable,
    ]
  );

  const conversationsHomeQueries: Partial<GetConversation> = React.useMemo(
    () => ({
      ...conversationsHistoricQueries,
      classDescription: state.infos?.class,
      typeList: ['QUESTION'],
    }),
    [conversationsHistoricQueries, state.infos?.class]
  );

  const conversationsTutorQueries: Partial<GetConversation> = React.useMemo(
    () => ({
      ...conversationsHistoricQueries,
      typeList: ['REMOTE'],
    }),
    [conversationsHistoricQueries]
  );

  const queryKeys = React.useMemo(
    () => ({
      chat: ['conversation-chat', state.conversation?.id],
      home: ['conversations-home', conversationsHomeQueries],
      historic: ['conversations-historic', conversationsHistoricQueries],
      tutor: ['conversations-tutor', conversationsTutorQueries],
    }),
    [
      state.conversation?.id,
      conversationsHomeQueries,
      conversationsHistoricQueries,
      conversationsTutorQueries,
    ]
  );

  const onError = React.useCallback(
    (error: unknown) => {
      dispatch({ page: 'ERROR' });
      formik.resetForm();
      console.error('Erro:', error);
    },
    [formik]
  );

  const onSuccess = React.useCallback(
    (success: boolean, type: 'CREATION' | 'REPLY') => {
      if (success) {
        Promise.all([
          queryClient.invalidateQueries(queryKeys.chat),
          queryClient.invalidateQueries(queryKeys.home),
          queryClient.invalidateQueries(queryKeys.historic),
        ])
          .then(() =>
            dispatch({
              page: state.page === 'HISTORIC' ? 'HISTORIC' : 'SUCCESS',
              conversation: null,
              isSubmiting: false,
              ...(state.isRespondingByHistory && {
                isRespondingByHistory: false,
              }),
            })
          )
          .catch((error) => console.error(error));
      } else {
        throw new Error(
          type === 'CREATION'
            ? 'Failed to create conversation'
            : 'Failed to reply conversation'
        );
      }
    },
    [
      queryClient,
      queryKeys.chat,
      queryKeys.historic,
      queryKeys.home,
      state.isRespondingByHistory,
      state.page,
    ]
  );

  const createConversationMutation = useMutation(createConversation, {
    onError: (error) => onError(error),
    onSuccess: ({ ok }) => onSuccess(ok, 'CREATION'),
  });

  const replyConversationMutation = useMutation(replyConversation, {
    onError: (error) => onError(error),
    onSuccess: ({ ok }) => onSuccess(ok, 'REPLY'),
  });

  const handleSubmit = React.useCallback(
    async (values: FormValuesProps) => {
      dispatch({ isSubmiting: true });

      const teachingObject = await getObjectLearningPath();

      const complimentMessage = (message: string) =>
        state.formType === 'ELOGIO' ? `❤️ ${message}` : message;

      if (state.conversation?.id)
        return replyConversationMutation.mutateAsync({
          systemCorrelationID: state.conversation.id,
          attachment: values.attachment,
          message: complimentMessage(values.description),
        });

      return createConversationMutation.mutateAsync({
        attachment: values.attachment,
        message: complimentMessage(values.description),
        classDescription: state.infos?.class,
        courseDescription: courseData?.course?.name,
        courseEnrollmentID: courseData?.id,
        subjectEnrollmentID:
          subjectEnrollment?.id || state.infos?.subjectEnrollmentID,
        subjectID: subject?.id || state.infos?.subjectID,
        subjectName: subject?.name || state.infos?.subject,
        teachingObject,
        type: state.formType,
      });
    },
    [
      state.infos,
      state.conversation?.id,
      state.formType,
      courseData,
      subjectEnrollment,
      subject,
      getObjectLearningPath,
      replyConversationMutation,
      createConversationMutation,
    ]
  );

  formik = useFormik({
    validationSchema: getFrameFormSchema(),
    validateOnChange: false,
    validateOnBlur: true,
    validateOnMount: false,
    initialErrors: {},
    onSubmit: handleSubmit,
    initialValues: { description: '' },
  });

  const onUploadFile = React.useCallback(
    async (label: string, value: UploadedFile[]) => {
      await formik.setFieldValue(label, value);
      await formik.setFieldTouched(label, true);

      if (value === null) {
        formik.setErrors({ attachment: formik.errors.attachment });
      }
    },
    [formik]
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      formik.handleSubmit();
    },
    [formik]
  );

  const onClose = React.useCallback(() => {
    state?.onCloseClick();
    formik.resetForm();
    dispatch({ page: initialPtkPage, conversation: null });
  }, [formik, initialPtkPage, state]);

  const onBack = React.useCallback(
    (page: PtkPages = initialPtkPage, callback?: () => void) => {
      formik.resetForm();
      dispatch({ page });
      callback && callback();
    },
    [formik, initialPtkPage]
  );

  return (
    <PtkFrameContext.Provider
      value={{
        ...state,
        conversationsHistoricQueries,
        conversationsTutorQueries,
        conversationsHomeQueries,
        onUploadFile,
        scrollType,
        queryKeys,
        dispatch,
        onSubmit,
        onClose,
        isAmpli,
        formik,
        onBack,
        tutor,
        me,
      }}
    >
      {children}
    </PtkFrameContext.Provider>
  );
};

export const usePtkFrame = (): ContextProps =>
  React.useContext(PtkFrameContext);
