import styled from '@emotion/styled';
import { convertPXToREM } from '../../utils';
import SvgClock from '../Icons/clock';
export const Flex = styled.div`
  display: flex;
`;
export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;
export const FlexAlignCenter = styled(Flex)`
  align-items: center;
`;
export const FooterIcon = styled(SvgClock)`
  margin-right: 10px;
`;
export const CardIcon = styled.div`
  background: #dbe3ec;
  padding: 16px;
  border-radius: 50%;
  height: fit-content;
  margin-right: 16px;
  line-height: 0;

  @media (max-width: 769px) {
    display: none;
  }
`;
export const DashedCard = styled(FlexColumn)`
  padding: 32px;
  border: dashed 2px #b1c2d5;
  background: #f3f6f9;
  border-radius: 8px;
  align-items: flex-start;

  @media (max-width: 769px) {
    padding: 24px;
  }

  ${_ref => {
  let {
    onClick
  } = _ref;
  return onClick && `
    &:hover {
      border-style: solid;
      cursor: pointer;
    }
  `;
}}

  ${_ref2 => {
  let {
    disabled
  } = _ref2;
  return disabled && `
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
  `;
}}
`;
export const SubText = styled.span`
  ${_ref3 => {
  let {
    margin
  } = _ref3;
  return `margin: ${margin};`;
}}
  ${_ref4 => {
  let {
    color
  } = _ref4;
  return `color: ${color};`;
}}
  ${_ref5 => {
  let {
    weight
  } = _ref5;
  return weight && `font-weight: ${weight};`;
}}
  font-size: ${convertPXToREM(20)};

  @media (max-width: 769px) {
    font-size: ${convertPXToREM(16)};
  }
`;
export const TitleText = styled.span`
  ${_ref6 => {
  let {
    color
  } = _ref6;
  return `color: ${color};`;
}}
  margin: 0 0 16px 0;
  font-weight: 700;
  line-height: 120%;
  font-size: ${convertPXToREM(32)};

  @media (max-width: 769px) {
    font-size: ${convertPXToREM(20)};
  }
`;