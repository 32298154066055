import React from 'react';
import {
  FreeTrialTitle,
  FreeTrialText,
  PayButton,
  FreeTrialContainer,
  Arrow,
} from './styles';
import { useHistory } from 'react-router-dom';
import { FreeTrialMessageProps } from '../types';

const FreeTrialMessage = ({
  freeTrialDaysLeft,
  selectedCourseEnrollmentType,
  selectedCourseVersionId,
}: FreeTrialMessageProps): React.ReactElement => {
  const history = useHistory();
  return (
    <FreeTrialContainer data-testid="free-trial-message">
      <FreeTrialTitle>
        Quer continuar estudando? Faça sua matricula!
      </FreeTrialTitle>
      <FreeTrialText>
        Você tem mais {freeTrialDaysLeft} dias de teste, após isso não terá mais
        acesso as lições. Evite isso realizando o pagamento.
      </FreeTrialText>
      <PayButton
        data-testid="free-trial-pay-button"
        onClick={() =>
          history.replace(
            `/${selectedCourseEnrollmentType}/${selectedCourseVersionId}/pagamento?utm_source=ava-sofia&utm_medium=aviso-conteudo`
          )
        }
      >
        Ir para pagamento
        <Arrow />
      </PayButton>
    </FreeTrialContainer>
  );
};

export default FreeTrialMessage;
