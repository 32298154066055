import React from 'react';

import { useQuery, useQueryClient } from 'react-query';
import { Typography, TagList } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { isNull } from '@ampli/utils';

import { ConversationChatSkeleton, MessageCard } from './components';
import { ConversationChatButtons } from '../../components/conversation-chat-buttons';
import { BackButton, Evaluation } from '../../components';
import { RatingIllustration } from '../rating/rating.styles';
import { useDynamicsLogic } from '../../hooks/use-dynamics-logic';
import { ListWrapperCore } from '../../../../../../../shared/components/section-right-frame/section-right-frame.styles';
import { LoadingSpinner } from '../../../../../../../../components';
import { getDateAndTime } from '../../../../shared';
import { Flex, Wrapper } from './conversation-chat.styles';
import { usePtkFrame } from '../../contexts';
import { FormSection } from '../form';

import {
  evaluateConversationDescription,
  closeConversationDescription,
} from './conversation-chat.constants';

const ConversationChatSection: React.FC = () => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const {
    isRespondingByHistory,
    conversation,
    scrollType,
    queryKeys,
    dispatch,
    page,
  } = usePtkFrame();

  const { getConversationMessages } = useDynamicsLogic();

  const queryClient = useQueryClient();

  const isHistoricPage = React.useMemo(() => page === 'HISTORIC', [page]);

  const isClosed = React.useMemo(
    () => conversation?.status === 'FINALIZADA',
    [conversation?.status]
  );

  const { data, isLoading, isRefetching, isFetching } = useQuery(
    queryKeys.chat,
    () => getConversationMessages({ id: conversation?.id }),
    {
      staleTime: 1000 * 60 * 1, // 1 minutes
      onError: (error) => console.error(error),
    }
  );

  const date = React.useMemo(
    () => getDateAndTime(conversation?.creationDateTime),
    [conversation?.creationDateTime]
  );

  const cached = React.useMemo(
    () => queryClient.getQueryData(queryKeys.chat),
    [queryClient, queryKeys.chat]
  );

  const showConversationChatButtons = React.useMemo(
    () =>
      isHistoricPage &&
      !isClosed &&
      conversation?.agent === 'STUDENT' &&
      !isRespondingByHistory,
    [conversation?.agent, isClosed, isHistoricPage, isRespondingByHistory]
  );

  const isTutorAgent = React.useMemo(
    () => conversation?.agent === 'TUTOR',
    [conversation?.agent]
  );

  const showTutorTag = React.useMemo(
    () => isHistoricPage && isTutorAgent,
    [isHistoricPage, isTutorAgent]
  );

  const tagText = React.useMemo(
    () => (showTutorTag ? 'MENSAGEM DO TUTOR' : conversation?.status),
    [conversation?.status, showTutorTag]
  );

  const tagColor = React.useMemo(
    () =>
      showTutorTag
        ? 'standard'
        : {
            FINALIZADA: 'standard',
            RESPONDIDA: 'warning',
            ENVIADA: 'success',
            DEFAULT: 'standard',
          }[conversation?.status || 'DEFAULT'],
    [conversation?.status, showTutorTag]
  );

  const getTagsList = React.useCallback(() => {
    const tags = [];

    tags.push({ text: tagText, type: tagColor });

    return tags;
  }, [tagColor, tagText]);

  React.useEffect(() => {
    if (cached) queryClient.refetchQueries(queryKeys.chat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading)
    return (
      <ListWrapperCore scroll={scrollType}>
        <ConversationChatSkeleton />
      </ListWrapperCore>
    );

  return (
    <ListWrapperCore
      padding={isHistoricPage ? '0px' : '24px'}
      scroll={scrollType}
      position="relative"
    >
      {!isHistoricPage ? (
        <BackButton
          page="HOME"
          callback={() => dispatch({ conversation: null })}
        />
      ) : null}

      {!isDesktop && isHistoricPage && conversation?.id ? (
        <BackButton
          page="HISTORIC"
          callback={() => dispatch({ conversation: null })}
        />
      ) : null}

      {isHistoricPage && conversation?.id ? (
        <Flex>
          <Wrapper>
            <TagList tagList={getTagsList()} />

            {isRefetching || isFetching ? (
              <LoadingSpinner
                color={theme.colors.text.primary}
                height={18}
                width={18}
              />
            ) : null}
          </Wrapper>

          {conversation?.class ? (
            <Typography
              size={20}
              weight={700}
              transform="uppercase"
              margin="8px 0px"
              color={theme.colors.text.black}
              spacing="0.25px"
            >
              {conversation?.class}
            </Typography>
          ) : null}

          <Typography
            size={14}
            color={theme.colors.text.lightBlack}
            margin={!conversation.subject ? '8px 0px 0px' : '0px'}
          >
            Iniciada em: {date}
          </Typography>
        </Flex>
      ) : null}

      {data?.content?.map((message) => (
        <MessageCard key={message.updateDateTime} message={message} />
      ))}

      {isRespondingByHistory ? <FormSection /> : null}

      {showConversationChatButtons ? <ConversationChatButtons /> : null}

      {isHistoricPage && isClosed && conversation?.id ? (
        <React.Fragment>
          <RatingIllustration />

          <Typography
            textAlign="center"
            weight="700"
            size={24}
            margin="16px 0px 8px 0px"
          >
            Dúvida finalizada!
          </Typography>

          <Typography textAlign="center" size={16} margin="0px 16px 24px">
            {!isNull(conversation.closureRatingEvaluation)
              ? closeConversationDescription
              : evaluateConversationDescription}
          </Typography>

          {isNull(conversation.closureRatingEvaluation) ? <Evaluation /> : null}
        </React.Fragment>
      ) : null}
    </ListWrapperCore>
  );
};

export default ConversationChatSection;
