import styled from '@emotion/styled';
import { mediaQuery } from '@sofia/ui';

export const Flex = styled.div`
  display: flex;
  ${({ margin }: { margin?: string }) => (margin ? `margin: ${margin}` : '')}
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

export const CardContainer = styled.div`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    margin-top: 32px;

    ${mediaQuery('desktop')} {
      margin-top: 56px;
    }
  }
`;

export const TextContainer = styled(Flex)`
  width: 100%;

  ${mediaQuery('mobile')} {
    flex-direction: column;
  }

  > svg {
    margin-right: 24px;
  }
`;

export const ActionContainer = styled.div`
  ${mediaQuery('mobile')} {
    margin-top: 16px;
    margin-left: unset;
  }

  margin-top: auto;
  margin-left: auto;
  margin-bottom: auto;
`;
