import React from 'react';
import { useTheme } from '@emotion/react';
import { bool } from 'prop-types';
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import { ButtonGhost, ButtonPrimary, convertPXToVW } from '@sofia/ui';

import { routePath } from '../../../../routes';
import { useGetState } from '../../../../state';

import * as Styled from './elective-subject-confirmation.styles';
import { useBreakpoint } from '@ampli/hooks';

const ElectiveSubjectConfirmation = ({
  isSubjectSelected,
  noInterestSelected,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const [loadingBtn, setLoadingBtn] = React.useState(false);
  const [selectStatus, setSelectStatus] = React.useState(isSubjectSelected);
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();
  const { state } = useLocation();
  const { subjectEnrollmentId } = useParams();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const handleReturnClick = () =>
    history.replace(
      generatePath(routePath.electiveSubjectChoices, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
        subjectEnrollmentId: subjectEnrollmentId,
      }),
      state && selectStatus
        ? {
            selectedSubject: state.subject,
          }
        : !selectStatus && isSubjectSelected
        ? { removedSubject: state.subject }
        : {}
    );

  const onSelect = (status) => {
    setLoadingBtn(true);
    setTimeout(() => {
      setLoadingBtn(false);
      setSelectStatus(status);
    }, 1200);
  };

  const Loading = () => (
    <Styled.LoadingCircle
      progress={75}
      indicator={{
        stroke: theme.colors.button.primary,
        strokeWidth: 3,
      }}
      track={{
        strokeWidth: 3,
      }}
      size={35}
      hasAnimation={false}
      hasProgressText={false}
    />
  );

  return (
    <Styled.ButtonWrapper>
      {!selectStatus ? (
        <>
          {isDesktop ? (
            <ButtonGhost
              color={theme.colors.button.primary}
              hoverColor={theme.colors.button.darkPrimary}
              onClick={handleReturnClick}
            >
              Ver outra disciplina
            </ButtonGhost>
          ) : null}
          <ButtonPrimary
            color={theme.colors.button.secondary}
            background={theme.colors.button.primary}
            hover={theme.colors.button.darkPrimary}
            onClick={() => onSelect(true)}
            minWidth={convertPXToVW(204)}
            disabled={loadingBtn || noInterestSelected}
          >
            {loadingBtn ? <Loading /> : 'Selecionar disciplina'}
          </ButtonPrimary>
        </>
      ) : (
        <>
          <ButtonPrimary
            color={theme.colors.button.secondary}
            background={theme.colors.button.primary}
            hover={theme.colors.button.darkPrimary}
            onClick={handleReturnClick}
          >
            Continuar
          </ButtonPrimary>
          <ButtonGhost
            color={theme.colors.button.danger}
            hoverColor={theme.colors.button.danger}
            hoverBackground={theme.colors.button.lightBlack}
            onClick={() => onSelect(false)}
            minWidth={convertPXToVW(204)}
            disabled={loadingBtn || noInterestSelected}
          >
            {loadingBtn ? <Loading /> : 'Remover seleção'}
          </ButtonGhost>
        </>
      )}
    </Styled.ButtonWrapper>
  );
};

ElectiveSubjectConfirmation.propTypes = {
  noInterestSelected: bool,
  isSubjectSelected: bool,
};

export default ElectiveSubjectConfirmation;
