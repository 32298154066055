import React from 'react';
import * as Styled from './live-access-card-action.styles';
import { Typography, SvgArrowRight } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { ELiveState } from '../../../types';

interface ILiveAccessCardAction {
  state: ELiveState;
}

const LiveAccessCardAction = ({
  state,
}: ILiveAccessCardAction): React.ReactElement => {
  const theme = useTheme();
  const isLive = state === ELiveState.LIVE;
  const color = isLive ? theme.colors.live.danger : theme.colors.text.primary;

  return (
    <Styled.Container isLive={isLive} data-testid="beta-live-card-btn">
      <Typography
        color={color}
        weight={700}
        data-testid="beta-live-card-btn-txt"
      >
        {isLive ? 'Assistir aula ao vivo' : 'Aguardar transmissão'}
        <SvgArrowRight fill={color} />
      </Typography>
    </Styled.Container>
  );
};

export default LiveAccessCardAction;
