import React from 'react';

import { convertPXToVH, Typography } from '@sofia/ui';
import { generatePath, useHistory } from 'react-router-dom';
import { bool, func, node, string } from 'prop-types';
import { useTheme } from '@emotion/react';
import { Loader } from '@ampli/ui';

import { brandsInfos } from '../../modules';
import { routePath } from '../../routes';

import SideScreen from '../side-screen/side-screen';
import BackButton from './back-button';

import * as Styled from './login-styles';

const LoginForm = ({
  registerRedirect,
  content,
  onSubmit,
  loading,
  description,
  title,
  children,
  footer,
  footerBox,
  onBackClick,
  titleSize,
  titleWeight,
  help,
  showLogo,
  register,
  titleId,
}) => {
  const theme = useTheme();
  const history = useHistory();

  const logo = (marginB) => {
    return (
      <Styled.LogoHC marginB={marginB}>
        <img
          id="logo-img"
          data-testid="logo-img"
          height={71}
          src={brandsInfos.logo}
          alt="institution icon"
        />
      </Styled.LogoHC>
    );
  };

  const onRegisterClick = (e) => {
    e.preventDefault();
    registerRedirect
      ? window.open(registerRedirect)
      : window.open(brandsInfos.registerLink);
  };

  const registerButton = ({ color, title, content, padding }) => (
    <Styled.ButtonGhostLong
      onClick={onRegisterClick}
      content={content}
      padding={padding}
    >
      <Typography
        aria-label={title}
        color={color || theme.colors.text.lightBlack}
        weight={700}
        size={16}
        margin="0 5px 0 0"
        id="still-not-matriculate"
        data-testid="still-not-matriculate"
      >
        {title}
      </Typography>
      <Typography
        id="txtSubscribe"
        data-testid="txtSubscribe"
        target="_blank"
        as="a"
        weight={700}
        size={16}
        color={theme.colors.text.primary}
      >
        Inscreva-se
      </Typography>
    </Styled.ButtonGhostLong>
  );

  const form = () => (
    <Styled.Form onSubmit={onSubmit}>
      <Styled.FormWrapper>
        {onBackClick ? (
          <BackButton theme={theme} onBackClick={onBackClick} />
        ) : (
          logo()
        )}

        {showLogo && logo('15px')}

        {loading ? (
          <Loader size={64} />
        ) : (
          <Styled.ContentWrapper content={content}>
            <Styled.LoginFieldsWrapper content={content}>
              <div>
                <Typography
                  color={theme.colors.text.black}
                  size={titleSize || 32}
                  weight={titleWeight || 700}
                  margin={`${convertPXToVH(36)} 0px 16px 0px`}
                  space="break-spaces"
                  id={titleId}
                  data-testid={titleId}
                >
                  {title}
                </Typography>

                {description && (
                  <Typography
                    margin="0px 0px 24px 0px"
                    color={theme.colors.text.lightBlack}
                    size={20}
                    weight={400}
                    space="break-spaces"
                    id="title-description"
                    data-testid="title-description"
                  >
                    {description}
                  </Typography>
                )}
              </div>

              <div>{children}</div>
            </Styled.LoginFieldsWrapper>

            {footer}

            {register === 'button'
              ? registerButton({
                  color: theme.colors.text.primary,
                  title: 'Ainda não é aluno?',
                })
              : null}
          </Styled.ContentWrapper>
        )}
      </Styled.FormWrapper>
    </Styled.Form>
  );

  return (
    <SideScreen
      side={<Styled.LoginImage data-testid="imgLogin" id="imgLogin" />}
    >
      {form()}

      {help || register ? (
        <Styled.LoginFooterWrapper
          show={footerBox}
          background={theme.colors.background.secondary}
        >
          {help ? (
            <Styled.HelpButtonWrapper marginTop="9px">
              <Typography
                margin="0 5px 0 0"
                weight={700}
                size={16}
                id="need-help"
                data-testid="need-help"
                color={theme.colors.text.lightBlack}
              >
                Dúvidas sobre o acesso:
              </Typography>
              <Styled.HelpButton
                id="txtTalkToUs"
                data-testid="txtTalkToUs"
                as="a"
                weight="bold"
                color={theme.colors.text.primary}
                onClick={() =>
                  history.push(generatePath(routePath.talkToUs), {
                    data: 'talkToUs',
                  })
                }
              >
                Fale Conosco
              </Styled.HelpButton>
            </Styled.HelpButtonWrapper>
          ) : null}

          {register === 'footer'
            ? registerButton({
                padding: '0px',
                content: 'start',
                color: theme.colors.text.light,
                title: 'Você ainda não é aluno?',
              })
            : null}
        </Styled.LoginFooterWrapper>
      ) : null}
    </SideScreen>
  );
};

LoginForm.propTypes = {
  title: string.isRequired,
  description: string,
  children: node.isRequired,
  footer: node,
  onSubmit: func.isRequired,
  loading: bool,
  onBackClick: func,
  footerBox: string,
  help: bool,
  register: string,
  titleSize: string,
  showLogo: bool,
  titleWeight: string,
  titleId: string,
  content: string,
  registerRedirect: string,
};

LoginForm.defaultProps = {
  footerBox: 'block',
};

export default LoginForm;
