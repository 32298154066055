import { useLazyQuery, useQuery } from '@ampli/services';
import {
  GET_ADDRESS,
  GET_STUDENT_EMAIL,
  GET_ME,
  GET_STUDENT_ADDRESS,
} from './queries';
import {
  GetAddressHook,
  GetMeHook,
  GetStudentAddressHook,
  GetStudentEmailHook,
  LazyGetAddress,
  LazyGetMeHook,
} from './types';

export const useGetMe: GetMeHook = ({ options }) => {
  const { loading, data, error, ...rest } = useQuery(GET_ME, { ...options });
  const me = loading || !data ? {} : data.data;

  return {
    data: me,
    loading,
    error,
    ...rest,
  };
};

export const useGetMeLazy: LazyGetMeHook = ({ options }) => {
  const [query, { data, loading, error }] = useLazyQuery(GET_ME, {
    ...options,
    fetchPolicy: 'no-cache',
  });

  const me = loading || !data ? {} : data.data;

  return [
    query,
    {
      data: me,
      loading,
      error,
    },
  ];
};

export const useGetAddress: GetAddressHook = ({ postalCode, options }) => {
  const { data, loading, ...rest } = useQuery(GET_ADDRESS, {
    ...options,
    variables: {
      postalCode,
    },
    skip: !postalCode,
    fetchPolicy: 'no-cache',
  });
  const address = loading || !data ? null : data.data;

  return {
    data: address,
    loading,
    ...rest,
  };
};

export const useLazyGetAddress: LazyGetAddress = ({ options = {} }) => {
  const [query, { data, loading, error }] = useLazyQuery(GET_ADDRESS, {
    ...options,
    fetchPolicy: 'no-cache',
  });
  const address = loading || !data ? null : data.data;

  return [query, { data: address, loading, error }];
};

export const useGetStudentAddress: GetStudentAddressHook = ({
  studentId,
  options,
}) => {
  const { data, loading, ...rest } = useQuery(GET_STUDENT_ADDRESS, {
    ...options,
    variables: {
      studentId,
    },
    fetchPolicy: 'no-cache',
  });
  const studentAddress = loading || !data ? null : data.data;

  return {
    data: studentAddress,
    loading,
    ...rest,
  };
};

export const useGetStudentEmail: GetStudentEmailHook = ({
  enrollmentId,
  options,
}) => {
  const { data, loading, ...rest } = useQuery(GET_STUDENT_EMAIL, {
    ...options,
    variables: {
      enrollmentId,
    },
    fetchPolicy: 'no-cache',
  });
  const studentEmail = loading || !data ? null : data.data;

  return {
    data: studentEmail,
    loading,
    ...rest,
  };
};
