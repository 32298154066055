import React from 'react';

import { RatingIllustration } from './rating.styles';
import { ListWrapperCore } from '../../../../../../../shared/components/section-right-frame/section-right-frame.styles';
import { usePtkFrame } from '../../contexts';
import { Evaluation } from '../../components';
import { Typography } from '@sofia/ui';

const RatingSection: React.FC = () => {
  const { scrollType } = usePtkFrame();

  return (
    <ListWrapperCore scroll={scrollType}>
      <RatingIllustration />

      <Typography
        textAlign="center"
        weight="700"
        size={24}
        margin="16px 0px 8px 0px"
      >
        Dúvida finalizada!
      </Typography>

      <Typography textAlign="center" size={16} margin="0px 16px 24px">
        Sua dúvida foi finalizada com sucesso! Gostaríamos de saber sua opinião
        sobre o atendimento. Por favor, como você avalia essa experiência?
      </Typography>

      <Evaluation />
    </ListWrapperCore>
  );
};

export default RatingSection;
