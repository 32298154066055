import { isString } from '@ampli/utils';
export { name, version, homepage as basename } from '../../package.json';

const env = process?.env || {};

export const ampli =
  window.__AMPLI__ || JSON.parse(env.REACT_APP_AMPLI || '{}');

export const graphqlUrl = ampli.graphqlUrl[ampli.squad] || ampli.graphqlUrl;
export const avaUrl = ampli.avaUrl[ampli.squad] || ampli.avaUrl;
export const isLocal =
  typeof location !== 'undefined' &&
  (location.hostname === 'localhost' || location.hostname === '127.0.0.1');

export const subscriptionUrl =
  ampli.subscriptionUrl[ampli.squad] || ampli.subscriptionUrl;
export const entranceExamUrl =
  ampli.entranceExamUrl[ampli.squad] || ampli.entranceExamUrl;

if (
  !isString(graphqlUrl) ||
  !isString(subscriptionUrl) ||
  !isString(entranceExamUrl)
) {
  console.debug({ ampli, graphqlUrl, subscriptionUrl, entranceExamUrl });
  throw new Error('The URL must be a string.');
}

export const isProduction = env.NODE_ENV === 'production';

export const isAmpliProduction = ampli.env === 'production';

export const isStaging = ampli.env === 'staging';

export const isIntegration = ampli.env === 'integration';

export const isDebug = !isProduction || isStaging || isIntegration;

export const enableAPM = !isAmpliProduction;

export const apmUrl = ampli.apmUrl;

export const APMEventMapping = [];

export const logLevel = isDebug ? 'debug' : 'error';

export const watsonChatbot = {
  integrationID: 'fc6ee812-2952-4bd2-853e-69ddc5f1eb5f',
  region: 'us-south',
  serviceInstanceID: '459e66ee-0068-4d2c-8f99-a9a3280b5c5b',
};

// export const recaptchaSiteKey = isProduction
// ? '6Ldb0cYUAAAAAN-q85BZljFbiyBdwRGOZRhSdQUM'
// : '6Le82sYUAAAAALqHTsmQ8zWaTZqXgq40uUc4QUHD';
// FIXME re-enable development site key
export const recaptchaSiteKey = '6Ldb0cYUAAAAAN-q85BZljFbiyBdwRGOZRhSdQUM';

export const zendeskApiToken =
  'cd4c47d4b6ce68600e8492ae083e731ef84dca1136a270aca791fd9689edaab5';
export const zendeskUrl = 'https://ampli.zendesk.com/api/v2';
export const zendeskKey = '6ef0a3fc-bba4-46f8-bdfb-bff242b0cc51';
export const zendeskBotKey = '9cac0090-fca8-4e0e-bdb1-8b12f8c22c60';

// Copilot

export const copilotAdaptiveLearningToken =
  'https://0ae73bc92c06e2c59c79d2fbd6769a.e1.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cogna_adaptiveLearningAgent/directline/token?api-version=2022-03-01-preview';

export const copilotTokenDevelopment =
  'https://907b53339849ec6b9430e75f15d5c0.e9.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cogna_adaptiveLearningAgent/directline/token?api-version=2022-03-01-preview';

export const copilotTokenProduction =
  'https://0ae73bc92c06e2c59c79d2fbd6769a.e1.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cogna_adaptiveLearningAgent/directline/token?api-version=2022-03-01-preview';

export const copilotUrls = {
  PRODUCTION: {
    token: copilotTokenProduction,
    secretaryAzureFunction: {
      URL: 'https://fn-cognaai-edu-prd.azurewebsites.net/api/agt_edu_secretary',
      KEY: 'pLYLjlT-r9YcdHILlcT5RWoAjv46Y9rUBe-JX9f7ePTTAzFuF5C3LQ==',
    },
  },
  HOMOLOG: {
    token: copilotTokenDevelopment,
    secretaryAzureFunction: {
      URL: 'https://fn-cognaai-edu-hml.azurewebsites.net/api/agt_edu_secretary',
      KEY: 'gQXbl8Sc9sV3PiBIPR5U3z5LTJek1JT3JSASbnGoohhQAzFu3xC5TA==',
    },
  },
}[isAmpliProduction ? 'PRODUCTION' : 'HOMOLOG'];

// "/sk" uri path
export const skPath = [47, 115, 107];

// HLS Key
// this key must be merged with the key below and the char 53 must be added
export const hlsKey1 = [
  55, 56, 49, 102, 51, 48, 57, 97, 101, 52, 97, 100, 51, 51, 56, 51,
];

export const hlsKey2 = [
  54, 56, 97, 51, 48, 52, 57, 102, 98, 97, 50, 51, 102, 57, 53,
  // add char 53
];

// expose @ampli/utils
if (isDebug) {
  window.AMPLI_UTILS = require('@ampli/utils');
}

// Feature toggle
export const features = {
  exam: true,
  examInFullscreen: true,
  grade: true,
  quiz: true,
  semestral_exam: true,
  assignment: true,
};

// WorkAround to disable free trial on PTK environment. Remove when implemented the definitive solution
export const isFreeTrialEnabled = avaUrl.includes('ptk');
