import React from 'react';

import {
  TruncateText,
  CallToAction,
  CardWrapper,
  IconWrapper,
  Card,
} from './notification-card.styles';

import { convertPXToREM, Typography } from '@sofia/ui';
import { ArrowRight } from '@ampli/icon';
import { clickByKey } from '@ampli/utils';
import { useTheme } from '@emotion/react';

import { CategoryType, parsedCategory } from '../../../../types';
import { analyticsCreativesEvents } from '../../../../../shared/utils/communication-constants';
import { useNotifications } from '../../contexts/notification-context';
import { pushEvent } from '../../../../../../lib/ga';

interface NotificationCardProps {
  categoryType: CategoryType;
  title: string;
  read: boolean;
  body: string;
  id: string;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  categoryType,
  read = false,
  title,
  body,
  id,
}: NotificationCardProps) => {
  const theme = useTheme();

  const { dispatch } = useNotifications();

  const cardProps = {
    hoverBorderColor: 'transparent',
    borderColor: 'transparent',
    borderWidth: '2px',
    background: read ? 'transparent' : theme.colors.card.color.hover,
    minHeight: 'unset',
    hover: theme.colors.card.color.hover,
    read,
  };

  const category = parsedCategory(categoryType);
  const Icon = category?.icon;

  const onClick = () => {
    dispatch({ content: 'details', notificationId: id });
    pushEvent(
      'event',
      analyticsCreativesEvents.notificationsCenter
        .Com_central_notificacoes_clique_leia_mais.event,
      {
        item: analyticsCreativesEvents.notificationsCenter.Com_central_notificacoes_clique_leia_mais.param(
          category?.title
        ),
      }
    );
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) =>
    clickByKey(event, onClick);

  return (
    <Card
      {...cardProps}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="link"
      tabIndex={0}
      aria-label={`${title} - Notificação`}
    >
      <CardWrapper>
        {category ? (
          <IconWrapper>
            <Icon
              width="24px"
              height="24px"
              {...(categoryType === 'TUTOR'
                ? {
                    stroke: theme.colors.text.black,
                  }
                : {
                    fill: theme.colors.text.black,
                    color: theme.colors.text.black,
                  })}
            />
          </IconWrapper>
        ) : null}

        <div>
          <TruncateText
            aria-label={title}
            size={16}
            weight={700}
            color={theme.colors.text.black}
            maxLines={2}
          >
            {title}
          </TruncateText>

          <TruncateText
            color={theme.colors.text.lightBlack}
            margin={`${convertPXToREM(8)} 0px`}
          >
            {body}
          </TruncateText>

          <CallToAction>
            <Typography margin={`0px ${convertPXToREM(8)} 0px 0px`}>
              Ler mais
            </Typography>
            <ArrowRight color={theme.colors.button.primary} />
          </CallToAction>
        </div>
      </CardWrapper>
    </Card>
  );
};

export default NotificationCard;
