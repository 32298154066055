/* eslint-disable prettier/prettier */
import React from 'react';

import { addTourEventListener } from '../../../../../../../components/tour/utils';

import * as Styles from './before-start.styles';
import * as Steps from './before-start.steps';

import '@splidejs/react-splide/dist/css/splide-core.min.css';

export const goSlide = (control: string | number): void => {
  window.dispatchEvent(new CustomEvent('goSlider', { detail: { control } }));
};

type BeforeStartModalContextType = {
  beforeStartModalStep: number;
  setBeforeStartModalStep: React.Dispatch<React.SetStateAction<number>>;
  goSlide: (control: string | number) => void;
};

export const BeforeStartModalContext =
  React.createContext<BeforeStartModalContextType>({
    beforeStartModalStep: 0,
    setBeforeStartModalStep: () => {},
    goSlide: () => {},
  });

export const BeforeStartModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }: React.PropsWithChildren) => {
  const [beforeStartModalStep, setBeforeStartModalStep] = React.useState(0);

  React.useEffect(() => {
    goSlide(beforeStartModalStep);
  }, [beforeStartModalStep]);

  return (
    <BeforeStartModalContext.Provider
      value={{
        beforeStartModalStep,
        setBeforeStartModalStep,
        goSlide,
      }}
    >
      {children}
    </BeforeStartModalContext.Provider>
  );
};

export const useBeforeStartModal = (): BeforeStartModalContextType =>
  React.useContext(BeforeStartModalContext);

const BeforeStartMobile: React.FC = () => {
  const { beforeStartModalStep, setBeforeStartModalStep } =
    useBeforeStartModal();

  return beforeStartModalStep === 3 ? (
    <Styles.Illustrations>
      <Styles.Illustration>
        <Steps.ConhecaPlataforma />
      </Styles.Illustration>
    </Styles.Illustrations>
  ) : (
    <Styles.Slide
      options={{ arrows: false, classes: { pagination: 'hidden' } }}
      onPaginationUpdated={(e) =>
        beforeStartModalStep !== 3 && setBeforeStartModalStep(e.index)
      }
      onMounted={(e) =>
        addTourEventListener('goSlider', (control: any) =>
          e.go(control.detail.control)
        )
      }
    >
      <React.Fragment>
        <Styles.SlideIllustration>
          <Steps.EstudeOndeQuiser />
        </Styles.SlideIllustration>

        <Styles.SlideIllustration>
          <Steps.AprendizadoDinamico />
        </Styles.SlideIllustration>

        <Styles.SlideIllustration>
          <Steps.PraticidadeNaRotina />
        </Styles.SlideIllustration>
      </React.Fragment>
    </Styles.Slide>
  );
};

export const SliderElipsis: React.FC = () => {
  const { beforeStartModalStep } = useBeforeStartModal();

  return beforeStartModalStep !== 3 ? (
    <Styles.EllipsesWrapper
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="tablist"
      className="splide__pagination splide__pagination--ltr"
    >
      {Array.from({ length: 3 }).map((_, i) => (
        <li key={i} role="presentation">
          <Styles.Ellipsis
            className="splide__pagination__page is-active"
            role="tab"
            isActive={beforeStartModalStep === i}
            aria-selected={beforeStartModalStep === i}
            tabIndex={beforeStartModalStep === i ? -1 : 0}
          ></Styles.Ellipsis>
        </li>
      ))}
    </Styles.EllipsesWrapper>
  ) : null;
};

export default BeforeStartMobile;
