import React from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { routePath } from '../../../routes';
import { EntitiesLabels, INTERNSHIP_STATUS } from '../../../constants';
import { LayoutContent } from '../../layout';
import { useGetState } from '../../../state';
import { SubjectBanner } from '../../subject';
import { object, oneOf, string } from 'prop-types';
import { useModal, SvgQuestionCircle } from '@sofia/ui';
import * as Styled from './mandatory-internship-subject.styles';
import MandatoryInternshipSubjectHeader from './header/mandatory-internship-subject-header';
import MandatoryInternshipSubjectContent from './content/mandatory-internship-subject-content';
import MandatoryInternshipSubjectMessages from './messages/mandatory-internship-subject-messages';
import { NewFeatureCommunication } from '../../../modules';
import { useTheme } from '@emotion/react';
import useGetTutorialOnboardingTrack from './use-get-tutorial-onboarding-track';

const MandatoryInternshipSubject = ({
  status,
  rejectionReason,
  assignmentStatus,
  subjectEnrollment,
}) => {
  const history = useHistory();
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();
  const entitiesLabelsDictionary = EntitiesLabels(selectedCourseEnrollmentType);

  const theme = useTheme();
  const [showMessage, setMessageStatus] = React.useState(false);
  const [startTour, setTourStatus] = React.useState(false);
  const releasedModal = useModal();

  const onGoBack = () => {
    if (history.location.key === 'default') {
      history.push(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })
      );
    } else {
      history.goBack();
    }
  };

  const assignments = subjectEnrollment?.assignments;

  const termAssignmentDetail = assignments?.find(
    (assignment) =>
      assignment.detail.config.typeConfig?.modelSubType ===
      'MANDATORY_INTERNSHIP_TERM'
  )?.detail;
  const reprovedSignatureFeedback =
    termAssignmentDetail.assignmentFiles.find((file) => file.feedback)
      ?.feedback || '';

  const [highlightFeature, setHighlightFeatureState] = React.useState(false);
  const [commsAnchorEl, setCommsAnchorEl] = React.useState();
  const tutorialBtnRef = React.useRef();
  const { loading, error, showTutorialMessage, onConcludeTutorialOnboarding } =
    useGetTutorialOnboardingTrack();

  const onStartTour = () => {
    setTourStatus(true);
    setHighlightFeatureState(true);
    releasedModal.hide();
  };

  return (
    <>
      <SubjectBanner>
        <Link
          to={generatePath(routePath.home, {
            courseType: selectedCourseEnrollmentType,
            courseVersionId: selectedCourseVersionId,
          })}
        >
          <span>Início</span>
        </Link>
        <b>{entitiesLabelsDictionary?.mySubjectTitle}</b>
      </SubjectBanner>

      <Styled.LayoutWrapper>
        <LayoutContent>
          <MandatoryInternshipSubjectHeader
            subjectEnrollment={subjectEnrollment}
            status={status}
            ref={tutorialBtnRef}
          >
            {!highlightFeature ? (
              <Styled.IconWrapper
                onClick={onStartTour}
                ref={(ref) => commsAnchorEl !== null && setCommsAnchorEl(ref)}
              >
                <SvgQuestionCircle fill={theme.colors.text.primary} />
              </Styled.IconWrapper>
            ) : null}
            {commsAnchorEl && showMessage && !loading && !error ? (
              <NewFeatureCommunication
                anchorEl={commsAnchorEl}
                shouldCloseOnEsc={false}
                shouldCloseOnBackdrop={false}
                shouldCloseOnClickOutside={false}
                arrowPosition="right"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                backdrop
                actions={{
                  close: false,
                  understood: true,
                }}
                handleUnderstood={() => {
                  setCommsAnchorEl(null);
                  onConcludeTutorialOnboarding();
                }}
                title="Ficou com dúvidas?"
                message="Clique aqui e acompanhe o passo a passo novamente"
              />
            ) : null}
          </MandatoryInternshipSubjectHeader>

          <Styled.LearningUnitCardWrapper>
            <MandatoryInternshipSubjectContent
              startTour={startTour}
              ref={tutorialBtnRef}
              showMessage={() => {
                setTourStatus(false);
                setHighlightFeatureState(false);
                releasedModal.show();
              }}
              highlightFeature={highlightFeature}
              onCloseHighlightFeature={() => {
                setHighlightFeatureState(false);
                setTourStatus(false);
              }}
              status={status}
              assignments={assignments}
              assignmentStatus={assignmentStatus}
            />
          </Styled.LearningUnitCardWrapper>
        </LayoutContent>
      </Styled.LayoutWrapper>

      <MandatoryInternshipSubjectMessages
        status={status}
        onPendingApproval={onGoBack}
        onFirstNotNow={() => setMessageStatus(true)}
        releasedModal={showTutorialMessage && !loading ? releasedModal : null}
        rejectionReason={rejectionReason}
        reprovedSignatureFeedback={reprovedSignatureFeedback}
        onStartTour={onStartTour}
      />
    </>
  );
};

MandatoryInternshipSubject.propTypes = {
  subjectEnrollment: object.isRequired,
  status: oneOf(Object.keys(INTERNSHIP_STATUS)).isRequired,
  rejectionReason: string,
  assignmentStatus: string,
};

export default MandatoryInternshipSubject;
