import styled from '@emotion/styled';
import { mediaQuery } from '@ampli/ui';

export const TagTutorialWrapper = styled.div`
  > div {
    height: auto;
    position: relative;
    top: 6.1rem;
    left: 58.5rem;
  }
`;

export const TutorialAccordionWrapper = styled.div`
  > div,
  #accordionContent {
    margin: 0;
    padding: 0;
  }
`;

export const Container = styled.div`
  margin-bottom: 16px;
  ${mediaQuery('desktop')} {
    margin-bottom: 24px;
  }

  > div {
    padding-bottom: 0px;
    #accordionContent {
      margin: 0px;
    }
  }
`;

export const FlexLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    p {
      color: ${({ hoverColor }) => hoverColor};
    }
    svg path {
      fill: ${({ hoverColor }) => hoverColor};
    }
  }
  svg {
    margin-right: 10px;
  }
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const AccordionContent = styled(FlexSpaceBetween)`
  background: white;
  padding: 0 16px 16px;
  ${mediaQuery('desktop')} {
    padding: 0 32px 32px;
  }
`;

export const SendWrapper = styled.div`
  padding: 16px;
  background: #dbe3ec;
  border-radius: 0px 0px 8px 8px;
  border-top: 2px solid #b1c2d5;
  ${mediaQuery('desktop')} {
    padding: 32px;
  }
`;

export const CardContainer = styled.div`
  > div {
    display: flex;
    align-items: center;
    padding: 16px;
    min-height: auto;
    flex-direction: column;
    ${mediaQuery('desktop')} {
      padding: 24px;
      flex-direction: row;
    }
  }
`;

export const IconWrapper = styled.div`
  ${mediaQuery('desktop')} {
    right: 64px;
  }
  padding: 12px;
  display: flex;
  border-radius: 50%;

  svg {
    position: relative;
    left: 64.1rem;
  }
`;
