import { RefObject } from 'react';
import {
  ContentEvaluationDataProps,
  ContentEvaluationResponseProps,
} from '../types';

const showTooltip = (
  inputRef: RefObject<HTMLInputElement>,
  newContentEvaluation: ContentEvaluationDataProps
): string => {
  return inputRef.current &&
    inputRef.current?.scrollWidth > inputRef.current?.offsetWidth
    ? newContentEvaluation?.comment || ''
    : '';
};

const getNewContentEvaluationInput: (
  content: ContentEvaluationResponseProps
) => ContentEvaluationDataProps = (contentEvaluationMutation) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...rest } = contentEvaluationMutation;
  return rest;
};

export { showTooltip, getNewContentEvaluationInput };
