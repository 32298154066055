import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const Flex = styled.div`
  display: flex;
`;

export const Header = styled(Flex)`
  padding: ${convertPXToREM(24)};
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px
    ${({ theme }) => theme.colors.card.background.inactive};
`;

export const IconBtn = styled.div`
  background-color: ${({ theme }) => theme.colors.background.icon.secondary};
  border-radius: 50%;
  padding: ${convertPXToREM(14)} ${convertPXToREM(14)};
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.icon.black};
    box-sizing: content-box;
    pointer-events: none;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.scrollbar.border};
  }
`;

export const Content = styled.div`
  display: grid;
  padding: ${convertPXToREM(24)};
  grid-gap: ${convertPXToREM(16)};
`;
