import styled from '@emotion/styled';
import {
  convertPXToVW,
  mediaQuery,
  convertPXToVH,
  ButtonGhost,
} from '@sofia/ui';
import { NavigationFooter } from '../../../../modules/shared';

const mediaTablet = mediaQuery('tablet-max');

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexBetween = styled(FlexCenter)`
  justify-content: space-between;
`;

export const QuizTopBar = styled(FlexCenter)`
  ${mediaTablet} {
    padding: ${convertPXToVH(16)} ${convertPXToVW(24)};
    width: 100%;
    background: ${({ theme }) => theme.colors.separator.lightColor};
  }
`;

export const QuizCorrectionContent = styled.div`
  width: 100%;

  ${mediaQuery('desktop')} {
    margin-right: ${convertPXToVW(48)};
  }

  ${mediaTablet} {
    padding: 0 ${convertPXToVW(16)};
  }
`;

export const QuizCorrectionMenu = styled.div`
  position: relative;

  & > div {
    ${({ sticky }) => (sticky ? `position: sticky; top: 115px;` : '')}
  }
`;

export const QuizNavigationButtons = styled(NavigationFooter)`
  & > div {
    column-gap: ${convertPXToVW(16)};

    & > div {
      width: ${convertPXToVW(140)};

      & > button {
        max-height: 56px;
        width: 100%;
      }
    }
  }

  ${mediaTablet} {
    padding: ${convertPXToVH(16)} ${convertPXToVW(16)};
    border-top: 1px solid ${({ theme }) => theme.colors.card.border.primary};
    background-color: ${({ theme }) => theme.colors.card.background.secondary};

    & > div > div {
      width: ${convertPXToVW(156)};
      margin: 0;
    }
  }
`;

export const HelpButton = styled(ButtonGhost)`
  margin: 0 0 ${convertPXToVH(16)};

  ${mediaTablet} {
    width: 100%;
    padding: ${convertPXToVH(19)} 0;
    margin: ${convertPXToVH(20)} 0 ${convertPXToVH(32)};
  }
`;
