export const serviceRequestSteps = {
  default: 'default',
  loading: 'loading',
  ready: 'ready',
};

export const serviceRequestKeys = {
  matricula: 'isRegistred',
  atestado: 'isAttestationOfBond',
  matriz_curricular: 'isCurriculum',
  carteirinha: 'isStudentCard',
  historico_escolar: 'isAcademicTranscript',
};

export const analyticsEvents = {
  isStudentCard: {
    copyProtocol: 'Sec_carteirinha_copiar_protocolo',
    downloadDocument: 'Sec_carteirinha_download',
    requestNewDocument: 'Sec_carteirinha_nova_solic',
  },
  isAcademicTranscript: {
    requestDocument: 'Sec_historico_escolar_solicitar',
    copyProtocol: 'Sec_historico_escolar_copiar_protocolo',
    downloadDocument: 'Sec_historico_escolar_download',
    requestNewDocument: 'Sec_historico_escolar_nova_solic',
  },
};

export const serviceRequestStatus = {
  NOT_FOUND: serviceRequestSteps.default,
  REQUESTED: serviceRequestSteps.loading,
  PROCESSING: serviceRequestSteps.loading,
  FINISHED: serviceRequestSteps.ready,
  EXPIRED: serviceRequestSteps.default,
  CANCELLED: serviceRequestSteps.default,
  FINISHED_NOT_REOPEN: serviceRequestSteps.ready,
};

export const serviceRequestContentTexts = {
  isRegistred: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    titleText: 'Para que serve esse documento?',
    textDesc:
      'Esse documento é válido para comprovar seu vínculo acadêmico com a entidade de ensino, além de usá-lo como substituto paliativo de sua carteirinha de estudante podendo obter descontos em cinemas, teatros, museus e etc.',
    titleCard: 'Solicitar comprovante',
    textDescCard:
      'Faça a solicitação do seu Comprovante de Matrícula e acompanhe o seu andamento por aqui.',
    expirationDate:
      'foi o último dia de validade do seu comprovante de matrícula. Faça novamente solicitação do seu documento e acompanhe o seu andamento por aqui.',
  },
  isAttestationOfBond: {
    titleText: 'Para que serve esse documento?',
    textDesc:
      'Esse documento é válido para comprovar seu vínculo acadêmico com a entidade de ensino.',
    titleCard: 'Solicitar atestado',
    textDescCard:
      'Faça a solicitação do seu Atestado de Vínculo e acompanhe o seu andamento por aqui.',
    expirationDate:
      'foi o último dia de validade do seu atestado de vínculo. Faça novamente solicitação do seu documento e acompanhe o seu andamento por aqui.',
  },
  isCurriculum: {
    titleText: 'Para que serve esse documento?',
    textDesc:
      'Este documento estabelece as metas, teorias e conceitos que nortearão os seus trabalhos ao longo do ano como, nomes das disciplinas, carga horária e o semestre que você irá cursar.',
    titleCard: 'Solicitar Matriz curricular',
    textDescCard:
      'Faça a solicitação da sua matriz currricular e acompanhe o seu andamento por aqui.',
    expirationDate:
      'foi o último dia de validade do documento de matriz curricular. Faça novamente solicitação do seu documento e acompanhe o seu andamento por aqui.',
  },
  isAcademicTranscript: {
    titleText: 'Para que serve esse documento?',
    textDesc:
      'O Histórico Escolar é o documento oficial que comprova a situação acadêmica do aluno referente ao seu rendimento escolar, no qual estão descritas as disciplinas do curso até a série em que o/a aluno/a está matriculado/a ou esteve, e qual a nota e a situação referente a cada disciplina.',
    titleCard: 'Solicitar Histórico escolar',
    textDescCard:
      'Faça a solicitação do seu Histórico escolar e acompanhe o seu andamento por aqui.',
    expirationDate:
      'foi o último dia de validade do documento de matriz curricular. Faça novamente solicitação do seu documento e acompanhe o seu andamento por aqui.',
  },
};

export const serviceRequestTexts = {
  isRegistred: {
    breadcrumbs: 'Comprovante de matrícula',
    bannerTitle: 'Comprovante de matrícula',
    orderSubject: 'CERTIFICATE_OF_ENROLLMENT',
  },
  isAttestationOfBond: {
    breadcrumbs: 'Atestado de vínculo',
    bannerTitle: 'Atestado de vínculo',
    orderSubject: 'ENROLLMENT_LINK',
  },
  isCurriculum: {
    breadcrumbs: 'Matriz curricular',
    bannerTitle: 'Matriz curricular',
    orderSubject: 'CURRICULUM',
  },
  isStudentCard: {
    breadcrumbs: 'Carteirinha',
    bannerTitle: 'Carteirinha',
    orderSubject: 'LITTLE_WALLET',
  },
  isAcademicTranscript: {
    breadcrumbs: 'Histórico escolar',
    bannerTitle: 'Histórico escolar',
    orderSubject: 'TRANSCRIPT',
  },
};

export const serviceRequestLoading = {
  isRegistred: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    titleText: 'Solicitação enviada com sucesso!',
    textDesc:
      'Estamos processando seus dados e em alguns momentos você poderá baixar seu comprovante de matrícula. Não é preciso sair dessa tela.',
  },
  isAttestationOfBond: {
    titleText: 'Solicitação enviada com sucesso!',
    textDesc:
      'Estamos processando seus dados e em alguns momentos você poderá baixar seu atestado de vínculo. Não é preciso sair dessa tela.',
  },
  isCurriculum: {
    titleText: 'Solicitação enviada com sucesso!',
    textDesc:
      'Estamos processando seus dados e em alguns momentos você poderá baixar sua matriz curricular. Não é preciso sair dessa tela.',
  },
  isStudentCard: {
    titleText: 'Solicitação enviada com sucesso!',
    textDesc:
      'Estamos processando seus dados e logo você poderá baixar sua carteirinha. Não é preciso sair dessa tela.',
  },
  isAcademicTranscript: {
    titleText: 'Solicitação enviada com sucesso!',
    textDesc:
      'Estamos processando seus dados e em alguns momentos você poderá baixar seu Histórico escolar. Não é preciso sair dessa tela.',
  },
};

export const secretaryCertificateOfEnrollmentReady = {
  isRegistred: {
    titleText: 'Solicitação enviada com sucesso!',
    textDesc:
      'Este documento é importante e comprova que você é um aluno regular desta instituição, guarde com cuidado! Bons estudos 😄',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    titleCard: 'Documento pronto 😍',
  },
  isAttestationOfBond: {
    titleText: 'Solicitação enviada com sucesso!',
    textDesc:
      'Esse documento é válido para comprovar seu vínculo acadêmico com a entidade de ensino.',
    titleCard: 'Documento pronto 😍',
  },
  isCurriculum: {
    titleText: 'Solicitação enviada com sucesso!',
    textDesc:
      'Este documento é importante para você ter conhecimento de qual disciplina irá cursar ao longo do curso. Guarde-o com cuidado! Bons estudos 😄',
    titleCard: 'Documento pronto 😍',
  },
  isStudentCard: {
    titleText: 'Sua carteirinha está pronta',
    textDesc:
      'Este documento é importante e comprova que você é um aluno regular desta instituição e poderá utilizar o beneficio da meia entrada, guarde com cuidado! Bons estudos 😄',
    titleCard: 'Documento pronto 😍',
  },
  isAcademicTranscript: {
    titleText: 'Seu documento está pronto!',
    textDesc:
      'Este documento é importante pois é referente ao seu rendimento escolar, guarde com cuidado! Bons estudos 😄',
    titleCard: 'Documento pronto 😍',
  },
};
