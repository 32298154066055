import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import { Card, Typography, SvgArrowRight } from '@sofia/ui';
import { string, func } from 'prop-types';
import { useTheme } from '@emotion/react';
import * as Styled from './mandatory-internship-onboarding.styles';

const MandatoryInternshipOnboarding = ({
  courseName,
  onHasInternshipClick,
  onDoesNotHasInternshipClick,
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <>
      <Styled.Title>
        <Typography
          weight={700}
          size={isDesktop ? 48 : 32}
          color={theme.colors.text.black}
        >
          {isDesktop ? 'Estágio obrigatório' : 'Sobre o estágio'}
        </Typography>
        <Typography
          weight={700}
          size={16}
          margin="8px 0 0 0"
          color={theme.colors.text.black}
        >
          {courseName}
        </Typography>
      </Styled.Title>

      <Typography
        size={isDesktop ? 20 : 16}
        margin={`0 0 ${isDesktop ? 56 : 40}px 0`}
      >
        O estágio supervisionado é uma disciplina obrigatória para sua formação,
        entretanto para cursar precisamos que você complete algumas etapas:
        <ol>
          <li>
            Você precisa ser selecionado para um estágio, caso ainda não possua
            um, clique no botão abaixo “
            <strong>Ainda não possuo um estágio</strong>” para mais informações;
          </li>

          <li>
            Uma vez que você foi selecionado para um estágio, você precisa
            <strong>cadastrar seu estágio</strong>, basta seguir as etapas
            abaixo para cadastrar o local em que você irá estagiar;
          </li>
        </ol>
      </Typography>

      <Styled.ButtonsWrapper>
        <Styled.CardContainer>
          <Card
            hover={theme.colors.card.color.hover}
            borderColor={theme.colors.card.border.primary}
            focusColor={theme.colors.card.border.third}
            tabIndex={0}
            onClick={onHasInternshipClick}
          >
            <Typography
              weight={700}
              size={isDesktop ? 24 : 16}
              margin="0 0 16px 0"
            >
              Cadastrar meu estágio
            </Typography>
            <Styled.Access color={theme.colors.text.primary}>
              Acessar
              <SvgArrowRight fill={theme.colors.text.primary} />
            </Styled.Access>
          </Card>
        </Styled.CardContainer>

        <Styled.CardContainer>
          <Card
            hover={theme.colors.card.color.hover}
            borderColor={theme.colors.card.border.primary}
            focusColor={theme.colors.card.border.third}
            tabIndex={0}
            onClick={onDoesNotHasInternshipClick}
          >
            <Typography
              weight={700}
              size={isDesktop ? 24 : 16}
              margin="0 0 16px 0"
            >
              Ainda não tenho um estágio
            </Typography>
            <Styled.Access color={theme.colors.text.primary}>
              Acessar
              <SvgArrowRight fill={theme.colors.text.primary} />
            </Styled.Access>
          </Card>
        </Styled.CardContainer>
      </Styled.ButtonsWrapper>
    </>
  );
};

MandatoryInternshipOnboarding.propTypes = {
  courseName: string.isRequired,
  onHasInternshipClick: func.isRequired,
  onDoesNotHasInternshipClick: func.isRequired,
};

export default MandatoryInternshipOnboarding;
