import styled from '@emotion/styled';
import { convertPXToVW, convertPXToVH, convertPXToREM } from '@sofia/ui';
import { mediaQuery } from '@ampli/ui';

export const ButtonContainer = styled.div`
  height: ${convertPXToVH(56)};
  margin: ${convertPXToVW(40)} ${convertPXToVH(32)};
  ${mediaQuery('desktop')} {
    margin: ${convertPXToVW(10)} ${convertPXToVH(32)} ${convertPXToVW(40)};
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AlertBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: #ffe6c9;
`;

export const IconContainer = styled.div`
  display: flex;
  width: ${convertPXToVW(28)};
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0 0 ${convertPXToREM(16)};
`;
