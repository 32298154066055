interface ILogFn {
  (message?: unknown, ...optionalParams: unknown[]): void;
}

interface ILogger {
  log: ILogFn;
  warn: ILogFn;
  error: ILogFn;
}

export enum ELogType {
  log = 'log',
  warn = 'warn',
  error = 'error',
}

type TLogMessage = (
  type: ELogType,
  message?: unknown,
  ...optionalParams: unknown[]
) => void;

class ConsoleLogger implements ILogger {
  log(message?: unknown, ...optionalParams: unknown[]): void {
    console.log(message, ...optionalParams);
  }

  warn(message?: unknown, ...optionalParams: unknown[]): void {
    console.warn(message, ...optionalParams);
  }

  error(message?: unknown, ...optionalParams: unknown[]): void {
    console.error(message, ...optionalParams);
  }
}

const logMessage: TLogMessage = (type, message, ...optionalParams) => {
  const logger = new ConsoleLogger();
  const timestamp = new Date().toISOString();
  const logMessage = `[${timestamp}] ${message}`;
  logger[type](logMessage, ...optionalParams);
};

export default logMessage;
