import { lazy } from 'react';

export const MessagingHistoricPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Messaging" */ '../pages/messaging-historic/messaging-historic'
    )
);

const MessagingModule = lazy(
  () => import(/* webpackChunkName: "Messaging module" */ './module')
);

export default MessagingModule;
