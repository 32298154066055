import React from 'react';
import { Typography, ButtonPrimary } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '@ampli/hooks';
import { useHistory } from 'react-router-dom';
import * as Styled from './mandatory-internship-guide-content.styles';

const MandatoryInternshipGuideContent = () => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const isDesktop = breakpoint === 'desktop';

  return (
    <>
      {/* ABOUT */}
      <Typography
        weight={700}
        color={theme.colors.text.black}
        size={isDesktop ? 48 : 32}
      >
        Sobre o estágio
      </Typography>
      <Typography
        as="div"
        color={theme.colors.text.black}
        size={isDesktop ? 20 : 16}
        margin="0 0 40px 0"
      >
        <p>
          Com relação ao Estágio Curricular Obrigatório, primeiramente, o aluno
          deve verificar na grade do seu curso se consta a disciplina Estágio
          Curricular Obrigatório. Somente neste caso, é que será obrigatória a
          realização do estágio para conclusão do curso.
        </p>
        <p>
          O Estágio Curricular Obrigatório é uma disciplina no curso, a qual é
          necessário o cumprimento para completar sua grade curricular. A
          atividade não cria vínculo de emprego, de acordo com o artigo 3º da
          Lei 11.788/08. O Campo de Estágio integra entidades de direito
          privado, os órgãos da administração pública, as instituições de ensino
          e/ou pesquisa, as próprias unidades da Instituição de Ensino, e a
          Comunidade em geral, desde que apresentem condições para:
        </p>
        <ul>
          <li>Planejamento e execução conjuntos das atividades de estágio;</li>
          <li>
            Aprofundamento dos conhecimentos teórico-práticos de campo
            específico de trabalho;
          </li>
          <li>
            Orientação e acompanhamento por parte de profissional com
            qualificações adequadas ao curso;
          </li>
          <li>
            Vivência efetiva de situações reais da vida e trabalho num campo
            profissional.
          </li>
          <li>Avaliação.</li>
        </ul>
        <p>
          Os Profissionais liberais de nível superior devidamente registrados em
          seus respectivos conselhos de fiscalização profissional podem oferecer
          estágio, observados os dispositivos legais.
        </p>
      </Typography>

      {/* HOW */}
      <Typography
        weight={700}
        color={theme.colors.text.black}
        size={isDesktop ? 48 : 32}
      >
        Como conseguir um estágio?
      </Typography>
      <Typography
        as="div"
        color={theme.colors.text.black}
        size={isDesktop ? 20 : 16}
        margin="0 0 40px 0"
      >
        <p>
          Além dos meios tradicionais e grupos locais onde as empresas anunciam
          oportunidades, existem alguns convênios que operam como intermediário
          entre o aluno e a empresa, e possuem uma grande quantidade de vagas
          cadastradas:
        </p>
        <Styled.Text>CIEE: </Styled.Text>
        <a target="_blank" href="https://portal.ciee.org.br/" rel="noreferrer">
          https://portal.ciee.org.br/
        </a>
        <Styled.Text>IEL: </Styled.Text>
        <a target="_blank" href="https://ielsc.org.br/" rel="noreferrer">
          https://ielsc.org.br/
        </a>
      </Typography>

      {/* WHAT NOW */}
      <Typography
        weight={700}
        color={theme.colors.text.black}
        size={isDesktop ? 48 : 32}
      >
        Consegui um estágio e agora?
      </Typography>
      <Typography
        as="div"
        color={theme.colors.text.black}
        size={isDesktop ? 20 : 16}
        margin="0 0 40px 0"
      >
        Se seu estágio atender a todas as recomendações citadas acima, você
        poderá voltar para o início desse fluxo e vincular seu estágio a você.
        Ao final desse fluxo você receberá seu termo de compromisso do estágio e
        a partir daí poderá iniciar o seu período de estágio obrigatório.
      </Typography>

      <Styled.ButtonWrapper>
        <ButtonPrimary
          color={theme.colors.text.white}
          background={theme.colors.button.primary}
          aria-label="Voltar"
          onClick={() => history.goBack()}
        >
          Voltar
        </ButtonPrimary>
      </Styled.ButtonWrapper>
    </>
  );
};

MandatoryInternshipGuideContent.propTypes = {};

export default MandatoryInternshipGuideContent;
