import { format } from 'date-fns';

const formatterPattern = 'dd/MM';

const formatSubjectCalendarDate = ({ s: startDate, e: endDate }) => {
  if (!startDate || !endDate) {
    throw new Error('Invalid interval: start and end dates are required.');
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  return `${format(start, formatterPattern)} a ${format(
    end,
    formatterPattern
  )}`;
};

export default formatSubjectCalendarDate;
