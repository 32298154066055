import React from 'react';
import LogoEdu from '../../../../assets/logo-edu.png';

import { LoadingSpinner } from '../../../loading-spinner';
import { WebChatProps } from '../../types';
import { useWebChat } from './webchat.logic';

import { SvgClose, SvgUploadClip } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import * as Styles from '../../styles';
import useClickOutsideChatCopilot from '../../use-click-outside-chat-copilot';

const WebChat: React.FC<WebChatProps> = ({
  answerContainerRef,
  questionContainerRef,
  setIsCopilotOpen,
  talkTutorInfos,
  containerRef,
  directLineURL,
  token,
  quizPagePayload,
  isQuizPageRendered,
}: WebChatProps) => {
  const theme = useTheme();

  const {
    getFileBlob,
    isUploadButtonAllowed,
    isChatInteractionAllowed,
    shouldChatBeBlocked,
    isUploading,
  } = useWebChat({
    talkTutorInfos,
    directLineURL,
    quizPagePayload,
    isQuizPageRendered,
    token,
  });

  useClickOutsideChatCopilot(
    containerRef,
    setIsCopilotOpen,
    questionContainerRef,
    answerContainerRef
  );

  const sendboxTextInput = '.webchat__send-box-text-box__input';
  const sendboxButtonAlignStretch = '.webchat__send-box__button--align-stretch';
  const chatInput = document?.querySelectorAll(
    sendboxTextInput
  )[0] as HTMLInputElement;
  const chatSendButton = document?.querySelectorAll(
    sendboxButtonAlignStretch
  )[0] as HTMLInputElement;

  const getContextLocation = (contextPath: string): boolean => {
    return window.location.pathname.includes(contextPath);
  };

  const handleClose = () => {
    containerRef.current?.classList.add('leaving');
    setTimeout(() => setIsCopilotOpen(false), 100);
    if (sessionStorage.getItem('serviceCreated')) {
      sessionStorage.setItem('chatClosed', 'true');
      sessionStorage.removeItem('serviceCreated');
    }
  };

  const handleChatInputExhibition = React.useCallback(() => {
    if (!chatInput || !chatSendButton) return;
    if (isChatInteractionAllowed && !shouldChatBeBlocked) {
      chatInput.disabled = false;
      chatInput.placeholder = `Digite sua dúvida${
        getContextLocation('quiz')
          ? ' do exercício'
          : getContextLocation('aula')
          ? ' da aula'
          : ''
      } aqui...`;
      chatSendButton.style.display = 'block';
      return;
    }
    chatInput.placeholder = '';
    chatInput.disabled = true;
    chatSendButton.style.display = 'none';
  }, [
    chatInput,
    chatSendButton,
    isChatInteractionAllowed,
    shouldChatBeBlocked,
  ]);

  React.useEffect(() => {
    if (isQuizPageRendered) {
      handleChatInputExhibition();
    }
  }, [
    chatInput,
    chatSendButton,
    isQuizPageRendered,
    handleChatInputExhibition,
  ]);

  return (
    <Styles.CopilotContainer ref={containerRef}>
      <Styles.ChatHeader>
        <Styles.CloseButton type="button" onClick={handleClose}>
          <SvgClose size="24px" fill={theme.colors.text.primary} />
        </Styles.CloseButton>

        <Styles.LogoEdu src={LogoEdu} alt="Logo Edu" />
      </Styles.ChatHeader>

      <Styles.WebChat
        id="webchat"
        isUploadButtonAllowed={isUploadButtonAllowed}
      />

      {isUploadButtonAllowed ? (
        <Styles.UploadButton id="attachment-edu-label" htmlFor="attachment-edu">
          <input
            type="file"
            name="attachment"
            id="attachment-edu"
            multiple={false}
            onChange={(e) =>
              e.target.files ? getFileBlob(e.target.files[0]) : null
            }
          />

          {isUploading ? (
            <LoadingSpinner width={24} height={24} />
          ) : (
            <SvgUploadClip />
          )}
        </Styles.UploadButton>
      ) : null}
    </Styles.CopilotContainer>
  );
};

export default WebChat;
