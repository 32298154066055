import React from 'react';
import { Typography, SvgTrashAlt, SvgRedo } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import NotesObjectModal from '../modal';
import NotesObjectModalFooter from '../modal-footer';
import { useTheme } from '@emotion/react';
import { useDeleteNote } from '../../../hooks';
import { pushAmpliEvent } from '../../../../../../lib/ga';
import { NotesObjectModalDeleteProps, NotesModal } from '../types';

const NotesObjectModalDelete = React.forwardRef(
  (
    {
      onDelete,
      onCancel,
      note,
      queryOptions,
      ...props
    }: NotesObjectModalDeleteProps,
    ref: React.Ref<NotesModal>
  ) => {
    const theme = useTheme();
    const breakpoint = useBreakpoint();
    const isDesktop = breakpoint === 'desktop';

    const [deleteNote, deleteNoteLoading] = useDeleteNote({
      onCancel,
      onComplete: () => {
        if (note?.id) onDelete(note.id);
      },
      ...(queryOptions ? queryOptions : {}),
    });

    const onDeleteNote = () => {
      pushAmpliEvent('event', 'notes-modal-delete-note-save');
      if (note?.id) deleteNote(note.id);
    };

    const onCancelDelete = () => {
      pushAmpliEvent('event', 'notes-modal-delete-note-cancel');
      (ref as React.RefObject<NotesModal>).current?.onClose();
    };

    return (
      <NotesObjectModal
        {...props}
        ref={ref}
        headerText="Você realmente quer excluir a anotação?"
        ariaLabel="Mensagem de confirmação de exclusão de anotação"
        footer={
          <NotesObjectModalFooter
            secondaryBtn={{
              borderBoxColor: theme.colors.text.primary,
              color: theme.colors.text.primary,
              onClick: onCancelDelete,
              children: 'Cancelar',
            }}
            primaryBtn={{
              onClick: onDeleteNote,
              background: theme.colors.button.danger,
              disabled: deleteNoteLoading,
              children: (
                <>
                  {isDesktop ? (
                    <SvgTrashAlt fill={theme.colors.text.white} />
                  ) : null}
                  <Typography
                    color={
                      isDesktop
                        ? theme.colors.text.white
                        : theme.colors.button.danger
                    }
                  >
                    {deleteNoteLoading ? (
                      <SvgRedo id="rotate" />
                    ) : (
                      'Excluir anotação'
                    )}
                  </Typography>
                </>
              ),
            }}
          />
        }
      >
        <Typography color={theme.colors.text.black} size={16}>
          Após excluída, a anotação não poderá ser acessada novamente.
        </Typography>
      </NotesObjectModal>
    );
  }
);

NotesObjectModalDelete.displayName = 'NotesObjectModalDelete';

export default NotesObjectModalDelete;
