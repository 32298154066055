type TThrottle = (
  mainFunction: (...args: unknown[]) => void,
  delay?: number
  // eslint-disable-next-line
) => any;

const throttle: TThrottle = (mainFunction, delay): ReturnType<TThrottle> => {
  let timerFlag: NodeJS.Timeout | null = null;

  // Retorna uma versão atrasada
  return (...args: unknown[]) => {
    if (timerFlag === null) {
      // Se não houver um timer correndo
      mainFunction(...args); // Execute a função principal
      timerFlag = setTimeout(() => {
        // Cria um timer para limpar quando acabar o delay
        timerFlag = null; // Limpa o timerFlag para possibilitar a função principal ser executada novamente
      }, delay);
    }
  };
};

export default throttle;
