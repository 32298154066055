import React, { cloneElement } from 'react';
import {
  cx,
  css,
  ColorBackground,
  SizeMaxWidthScreenTablet,
  centralized,
} from '@ampli/ui';
import { string, node } from '@ampli/utils';

const OnboardingSide = ({ children, breadcrumb, ...props }) => (
  <div
    {...props}
    className={cx(
      'onboarding-side',
      css`
        background-color: ${ColorBackground};
        position: relative;

        @media (max-width: ${SizeMaxWidthScreenTablet}) {
          display: none;
        }
      `,
      props.className
    )}
  >
    {breadcrumb}
    {cloneElement(children, {
      ...props,
      className: cx(centralized(), props.className),
    })}
  </div>
);
OnboardingSide.propTypes = {
  children: node.isRequired,
  breadcrumb: node,
  className: string,
};

export default OnboardingSide;
