import { isAmpliProduction, isStaging } from '../config';
import { analyticsMapper } from '../constants';

const initializeGoogleAnalytics = () => {
  const brand = window.location.origin;
  const gaId = analyticsMapper[brand];

  const analyticsScript = document.createElement('script');

  if (gaId) {
    window.head = window.head || document.getElementsByTagName('head')[0];
    window.gaId = analyticsMapper[brand];
    window.dataLayer = window.dataLayer || [];

    analyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
    analyticsScript.async = true;

    window.gtag = function () {
      window.dataLayer.push(arguments);
    };

    window.gtag('js', new Date());
    window.gtag('config', gaId);

    window.head?.appendChild(analyticsScript);

    window.gtag('config', gaId, {
      debug_mode: !isAmpliProduction,
      traffic_type: isStaging ? 'internal' : undefined,
    });
  }
};

export default initializeGoogleAnalytics;
