import { loadScript } from '@ampli/utils';
import { handleCloseWebWidget } from './zendesk';

let element = null;
const chatbotCssId = 'df-messenger';

const getWrapperElement = () => {
  return document.body
    .querySelector(chatbotCssId)
    ?.shadowRoot?.querySelector('df-messenger-chat')
    ?.shadowRoot?.querySelector('div.chat-wrapper');
};

export const initializeDialogflowChat = () => {
  loadScript(
    'https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1'
  )
    .then(() => {
      element = document.createElement(chatbotCssId);
      element.setAttribute('intent', 'WELCOME');
      element.setAttribute('chat-title', 'AMPLI');
      element.setAttribute('agent-id', 'b3d3154a-75bd-4b17-9b94-7a271444124a');
      element.setAttribute('language-code', 'pt-br');
      element.setAttribute('wait-open', true);
      document.body.appendChild(element);
      element.addEventListener('df-messenger-loaded', function () {
        let wrapper = element?.shadowRoot
          ?.querySelector('df-messenger-chat')
          ?.shadowRoot?.querySelector('div.chat-wrapper');
        let dfIcon = element?.shadowRoot?.querySelector('#widgetIcon');
        dfIcon.style.display = 'none';
        dfIcon.onclick = () => {
          wrapper.getAttribute('opened') == 'true' || null
            ? (dfIcon.style.display = 'none')
            : (dfIcon.style.display = 'block');
        };
      });
      return true;
    })
    .catch((error) => console.error(error));
};

export const closeChatbot = () => {
  const element = getWrapperElement();
  element?.removeAttribute('opened');
};

export const handleOpen = () => {
  handleCloseWebWidget();
  element?.shadowRoot?.querySelector('#widgetIcon').click();
};
