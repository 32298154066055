import React, { FC } from 'react';
import { Wrapper } from './card-details.styles';
import { ICardDetailsProps } from './card-details.types';
import { SvgCalender, SvgDollarSign, Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

const CardDetails: FC<ICardDetailsProps> = ({
  period,
  value,
}: ICardDetailsProps) => {
  const theme = useTheme();

  const optionsTypography = {
    size: 20,
    color: theme.colors.text.black,
  };

  const optionsSvg = {
    fill: theme.colors.text.lighterBlack,
  };

  return (
    <Wrapper.Content>
      <Wrapper.ItemDescription>
        <Wrapper.Svg>
          <SvgCalender {...optionsSvg} />
        </Wrapper.Svg>
        <Typography {...optionsTypography}>
          Período de prova: {period}
        </Typography>
      </Wrapper.ItemDescription>
      <Wrapper.ItemDescription>
        <Wrapper.Svg>
          <SvgDollarSign {...optionsSvg} />
        </Wrapper.Svg>
        <Typography {...optionsTypography}>Valor: {value}</Typography>
      </Wrapper.ItemDescription>
    </Wrapper.Content>
  );
};

export default CardDetails;
