import styled from '@emotion/styled';

export const Flex = styled.div`
  display: flex;
  width: 100%;
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;
