import React from 'react';
import LayoutWrapper from './layout-wrapper';

import { node } from 'prop-types';
import { Flex } from './exam/desktop/exam-layout-desktop.styles';

const SimpleLayout = ({ children, talkTutorFrame, ...props }) => {
  return (
    <Flex>
      <LayoutWrapper padding="none" {...props}>
        {children}
      </LayoutWrapper>

      {talkTutorFrame}
    </Flex>
  );
};

SimpleLayout.propTypes = {
  children: node,
  talkTutorFrame: node,
};

export default SimpleLayout;
