import { generatePath } from 'react-router-dom';
import { routePath } from '../routes';

const notesGuard = async (to, _from, next, courseEnrollmentResponse) => {
  const state = to.meta?.state;

  try {
    const selectedCourseEnrollment = courseEnrollmentResponse?.data || {};

    const blockCourseEnrollmentContentAccess =
      !selectedCourseEnrollment?.roles?.includes(
        'ALLOW_COURSE_ENROLLMENT_CONTENT_ACCESS'
      );

    const subjectEnrollmentId = to.match?.params?.subjectEnrollmentId;
    if (
      Object.keys(selectedCourseEnrollment).length > 0 &&
      subjectEnrollmentId
    ) {
      const awaitingChoice =
        selectedCourseEnrollment.subjectEnrollmentsInProgress.find(
          (enrollment) => enrollment.status === 'AWAITING_CHOICE'
        );

      let subjects = [
        ...selectedCourseEnrollment.subjectEnrollmentsInProgress,
        ...selectedCourseEnrollment.subjectEnrollmentsTaken,
      ];
      if (awaitingChoice) {
        subjects = [...subjects, ...awaitingChoice.choices];
      }
      const selectedSubject = subjects.find(
        (courseSubject) =>
          courseSubject?.subject?.pathUrl === subjectEnrollmentId ||
          courseSubject?.id === subjectEnrollmentId
      );

      if (!selectedSubject) {
        console.warn('Could not find subject path in course subject list');
        next.redirect(
          generatePath(routePath.home, {
            courseType: state?.selectedCourseEnrollmentType,
            courseVersionId: state?.selectedCourseVersionId,
          })
        );
      }
    }

    if (blockCourseEnrollmentContentAccess)
      throw new Error('User has no access to subject');

    if (!courseEnrollmentResponse?.error) {
      next();
    } else {
      throw courseEnrollmentResponse.error[0];
    }
  } catch (error) {
    console.warn(error);
    next.redirect('/');
  }
};

export default notesGuard;
