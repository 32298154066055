import React from 'react';
import { node, string, func, shape } from 'prop-types';
import {
  DialogBackdrop,
  DialogAlert,
  DialogHeader,
  DialogCloseButton,
  Paragraph,
  DialogFooter,
  Text,
  Flex,
  css,
  px2grid,
  OutlinedButton,
  IconButton,
  FlexItem,
} from '@ampli/ui';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { WithoutAccessImage } from '../image';
import { Wrapper } from './without-acess-alert.styles';

const WithoutAccessAlert = ({
  courseEnrollmentSettings,
  onActionClick,
  ...props
}) => {
  const theme = useTheme();

  return (
    <>
      <DialogBackdrop {...props} />
      <DialogAlert {...props} aria-labelledby="withoutAccessAlertTitle">
        <DialogHeader
          className={css`
            padding-bottom: ${px2grid(8)} !important;
          `}
        >
          <FlexItem flex={1}>
            <Flex direction="column" alignItems="center">
              <div>
                <Paragraph as="div">
                  <WithoutAccessImage />
                </Paragraph>
                <Text id="withoutAccessAlertTitle" color="text-dark">
                  Você não tem mais acesso
                </Text>
              </div>
            </Flex>
          </FlexItem>
          <DialogCloseButton
            onClick={props.hide}
            className={css`
              top: ${px2grid(16)};
              right: ${px2grid(24)};
              position: absolute;
            `}
          />
        </DialogHeader>
        <Wrapper>
          <Typography
            textAlign="center"
            color={theme.colors.text.lightBlack}
            size={16}
          >
            {courseEnrollmentSettings.alert?.message}
          </Typography>
        </Wrapper>
        <Flex justifyContent="center">
          <DialogFooter>
            {courseEnrollmentSettings.button ? (
              <OutlinedButton
                as={IconButton}
                icon={courseEnrollmentSettings.button?.icon}
                iconSize="xx-large"
                onClick={onActionClick}
              >
                {courseEnrollmentSettings.button?.label}
              </OutlinedButton>
            ) : null}
          </DialogFooter>
        </Flex>
      </DialogAlert>
    </>
  );
};

WithoutAccessAlert.propTypes = {
  hide: func.isRequired,
  onActionClick: func.isRequired,
  courseEnrollmentSettings: shape({
    alert: shape({
      messages: string,
    }).isRequired,
    button: shape({
      icon: string,
      label: node.isRequired,
    }).isRequired,
  }).isRequired,
};

export default WithoutAccessAlert;
