import starRateAnimation from '../../../../lotties/star-rating.json';
import lottie from 'lottie-web';
import React from 'react';

import {
  starActionEvents,
  starKeyDownEvent,
  starSelectEvents,
} from './rating-stars.events';
import { dataURI, makeId, makeIdAnimation } from './helpers';

import * as Styled from './rating-stars.styles';
import { RatingStarsProps } from './types';

const RatingStars: React.FC<RatingStarsProps> = ({
  getAriaLabel,
  onStarClick,
  newRating,
  idPrefix,
  disabled,
  rating,
}: RatingStarsProps) => {
  const stars = [];
  const starFuncOptions = { idPrefix, disabled };

  for (let i = 1; i < 6; i++) {
    stars.push(
      <Styled.RateStar
        tabIndex={i === 1 ? 0 : -1}
        id={makeId(idPrefix, i)}
        data-testid={makeId(idPrefix, i)}
        key={i}
        role="radio"
        dataURI={dataURI}
        aria-label={getAriaLabel(i)}
        onMouseEnter={() => starSelectEvents(i, 'mouseenter', starFuncOptions)}
        onMouseLeave={() => starSelectEvents(i, 'mouseleave', starFuncOptions)}
        onFocus={() => starSelectEvents(i, 'focus', starFuncOptions)}
        onBlur={() => starSelectEvents(i, 'blur', starFuncOptions)}
        onClick={() => starActionEvents(onStarClick, i, starFuncOptions)}
        onKeyDown={(e: KeyboardEvent) =>
          starKeyDownEvent(onStarClick, e, i, starFuncOptions)
        }
        {...(rating
          ? {
              className:
                i <= (newRating > 0 ? rating : newRating) ? 'rated' : '',
            }
          : null)}
      />
    );
  }

  React.useEffect(() => {
    for (let i = 1; i < 6; i++) {
      const container = document.querySelector(`#${makeIdAnimation(idPrefix)}`);

      if (container)
        lottie.loadAnimation({
          container, // the dom element that will contain the animation
          animationData: starRateAnimation, // the path to the animation json
          renderer: 'svg', // "canvas", "html"
          loop: false,
          autoplay: false,
          name: makeId(idPrefix, i),
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled.AdjacentStars newRating={newRating} data-testid="rating-stars">
      {stars}
      <Styled.StarsAnimation id={makeIdAnimation(idPrefix)} />
    </Styled.AdjacentStars>
  );
};

export default RatingStars;
