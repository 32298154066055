import { useQuery, useLazyQuery, useMutation } from '@ampli/services';
import {
  DISABLE_CREDIT_CARD_RECURRENCE,
  GET_CREDIT_CARD_LIST_BY_ENROLLMENT_ID,
  GET_CREDIT_CARD_RECURRENCE,
  PAY_INVOICE_BY_CREDIT_CARD,
  PAY_INVOICE_BY_CREDIT_CARD_RESULT,
  PAY_INVOICE_BY_EXISTING_CREDIT_CARD,
  REMOVE_CREDIT_CARD,
} from './queries';
import {
  GetLazyCreditCardListByEnrollmentIdHook,
  GetCreditCardRecurrenceHook,
  GetPayInvoiceByCreditCardResultHook,
  MutationDisableCreditCardRecurrenceHook,
  MutationPayInvoiceByCreditCardHook,
  MutationPayInvoiceByExistingCreditCardHook,
  MutationRemoveCreditCardHook,
} from './types';

export const useGetCreditCardRecurrence: GetCreditCardRecurrenceHook = ({
  courseEnrollmentId,
  options = {},
}) => {
  const { data, loading, error, refetch } = useQuery(
    GET_CREDIT_CARD_RECURRENCE,
    {
      ...options,
      variables: {
        courseEnrollmentId,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const creditCardRecurrence = loading || !data ? [] : data.data;

  return {
    data: creditCardRecurrence,
    loading,
    error,
    refetch,
  };
};

export const useMutationRemoveCreditCard: MutationRemoveCreditCardHook = ({
  options,
}) => {
  const [mutate, metadata] = useMutation(REMOVE_CREDIT_CARD, {
    ...options,
    context: {
      autoHandle: true,
    },
  });

  return [mutate, metadata];
};

export const useMutationPayInvoiceByExistingCreditCard: MutationPayInvoiceByExistingCreditCardHook =
  ({ options }) => {
    const [mutate, metadata] = useMutation(
      PAY_INVOICE_BY_EXISTING_CREDIT_CARD,
      {
        ...options,
        context: {
          autoHandle: true,
        },
      }
    );
    return [mutate, metadata];
  };

export const useMutationPayInvoiceByCreditCard: MutationPayInvoiceByCreditCardHook =
  ({ options }) => {
    const [mutate, metadata] = useMutation(PAY_INVOICE_BY_CREDIT_CARD, {
      ...options,
      context: {
        autoHandle: true,
      },
    });
    return [mutate, metadata];
  };

export const useMutationDisableCreditCardRecurrence: MutationDisableCreditCardRecurrenceHook =
  ({ options }) => {
    const [mutate, { loading, error, data, ...rest }] = useMutation(
      DISABLE_CREDIT_CARD_RECURRENCE,
      { ...options }
    );

    const DisableCreditCardRecurrence = loading || !data ? {} : data.data;

    return [
      mutate,
      { loading, error, data: DisableCreditCardRecurrence, ...rest },
    ];
  };

export const useGetPayInvoiceByCreditCardResult: GetPayInvoiceByCreditCardResultHook =
  ({ courseEnrollmentId, invoiceId, options = {} }) => {
    const { data, loading, error, refetch } = useQuery(
      PAY_INVOICE_BY_CREDIT_CARD_RESULT,
      {
        ...options,
        variables: {
          courseEnrollmentId,
          invoiceId,
        },
        fetchPolicy: 'no-cache',
      }
    );

    const creditCardResult = loading || !data ? null : data.data;

    return {
      data: creditCardResult,
      loading,
      error,
      refetch,
    };
  };

export const useLazyGetCreditCardListByEnrollmentId: GetLazyCreditCardListByEnrollmentIdHook =
  ({ options = {} }) => {
    const [query, { data, loading, error, ...rest }] = useLazyQuery(
      GET_CREDIT_CARD_LIST_BY_ENROLLMENT_ID,
      { ...options, fetchPolicy: 'no-cache' }
    );

    const creditCardResult = loading || !data ? null : data;

    return [
      query,
      {
        data: creditCardResult,
        loading,
        error,
        ...rest,
      },
    ];
  };
