import { gql } from 'graphql.macro';

export const GET_CAPABILITIES_BY_STUDENT_ID = gql`
  query GetCapabilitiesByStudentId {
    data: getCapabilitiesByStudentId
  }
`;

export const GET_CAPABILITIES_BY_COURSE_ENROLLMENT_ID = gql`
  query GetCapabilitiesByCourseEnrollmentId($course_enrollment_id: String!) {
    data: getCapabilitiesByCourseEnrollmentId(
      course_enrollment_id: $course_enrollment_id
    )
  }
`;
