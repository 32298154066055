import React from 'react';

import { useHistoricConversations } from '../ptk-frame/hooks/use-historic-conversations';
import { useBreakpoint } from '../../../../../shared';
import { useGetState } from '../../../../../../state';
import { useFrame } from '../../shared';

import { SvgCommentMessage } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import * as Styled from './talk-tutor-button.styles';

export interface TalkTutorButtonProps {
  onClick?: () => void;
}

const TalkTutorButton: React.FC<TalkTutorButtonProps> = ({
  onClick,
}: TalkTutorButtonProps) => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();

  const [{ openTalkTutorFrame }] = useGetState();
  const { data: historic } = useHistoricConversations();
  const { isAmpli } = useFrame();

  const showNotification = React.useMemo(
    () =>
      Boolean(
        !isAmpli &&
          historic?.content?.some?.(({ readStatus }) => readStatus === 'UNREAD')
      ),
    [historic?.content, isAmpli]
  );

  return (
    <Styled.TalkTutorButtonContent
      onClick={onClick}
      aria-label="Falar com o tutor"
      isActive={openTalkTutorFrame}
      data-testid="communication-talk-to-tutor-btn"
    >
      <SvgCommentMessage
        {...(openTalkTutorFrame && {
          fill: theme.colors.text.primary,
        })}
      />

      {isDesktop ? 'Falar com o tutor' : null}
      {showNotification ? <Styled.Signal /> : null}
    </Styled.TalkTutorButtonContent>
  );
};

export default TalkTutorButton;
