const whitelabelPalette = {
  primary: {
    900: '#001F50',
    800: '#002871',
    700: '#003491',
    600: '#0041AF',
    500: '#004ECC',
    400: '#3969EB',
    300: '#7389FF',
    200: '#ACB1FF',
    100: '#E5EDFA',
  },
  neutral: {
    900: '#212529',
    800: '#343A40',
    700: '#495057',
    600: '#868E96',
    500: '#ADB5BD',
    400: '#CED4DA',
    300: '#DEE2E6',
    200: '#F1F3F5',
    100: '#FFFFFF',
  },
  surface: {
    900: '#698AAE',
    800: '#7996B7',
    700: '#8AA3C0',
    600: '#9DB2CA',
    500: '#B1C2D5',
    400: '#C5D2E0',
    300: '#DBE3EC',
    200: '#EBF0F5',
    100: '#F3F6F9',
  },
  feedback: {
    success: {
      900: '#00392B',
      800: '#004D3A',
      700: '#006049',
      600: '#007358',
      500: '#008566',
      400: '#33AB8C',
      300: '#66CAAE',
      200: '#99E2CD',
      100: '#E0F0ED',
    },
    warning: {
      900: '#804500',
      800: '#9F5600',
      700: '#BF6800',
      600: '#DF7900',
      500: '#FF8A00',
      400: '#FFA433',
      300: '#FFBC66',
      200: '#FFD399',
      100: '#FFE6C9',
    },
    error: {
      900: '#5F1903',
      800: '#761F03',
      700: '#8D2504',
      600: '#A52C05',
      500: '#BC3206',
      400: '#DC5233',
      300: '#F27666',
      200: '#FF9F99',
      100: '#F6E4DF',
    },
  },
};

export default whitelabelPalette;
