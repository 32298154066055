import styled from '@emotion/styled';
import { mediaQuery } from '../../utils';
export const Wrapper = styled.div`
  background: ${_ref => {
  let {
    background
  } = _ref;
  return background;
}};
  padding: ${_ref2 => {
  let {
    padding
  } = _ref2;
  return padding;
}};
  margin: ${_ref3 => {
  let {
    margin = '0px 0px 8px'
  } = _ref3;
  return margin;
}};
  border-bottom: ${_ref4 => {
  let {
    borderBottom
  } = _ref4;
  return borderBottom;
}};
  box-shadow: ${_ref5 => {
  let {
    boxShadow
  } = _ref5;
  return boxShadow;
}};

  ${mediaQuery('desktop')} {
    margin: ${_ref6 => {
  let {
    margin = '0px 0px 20px'
  } = _ref6;
  return margin;
}};
  }
`;