import React from 'react';
import { useTheme } from '@emotion/react';
import { Tooltip } from '@material-ui/core';
import { Typography } from '@sofia/ui';

import * as Styled from './section-menu-list-item-text-styles';
import { SubjectMenuListItemTextProps } from '../../types';

const SubjectMenuListItemText = ({
  title,
  subTitle,
  active,
  titleColor,
}: SubjectMenuListItemTextProps): React.ReactElement => {
  const theme = useTheme();
  const txtRef = React.useRef<HTMLDivElement>(null);
  const [allowTooltip, setAllowTooltip] = React.useState(false);

  const showTooltip = () => {
    return allowTooltip ? `${title}` : '';
  };

  React.useEffect(() => {
    if (
      txtRef.current &&
      txtRef.current.scrollHeight > txtRef.current.offsetHeight
    ) {
      setAllowTooltip(true);
    }
  }, []);

  return (
    <>
      <Styled.FlexColumn
        active={active}
        data-testid="section-menu-list-item-text"
      >
        <Tooltip arrow title={showTooltip()} placement="right">
          <div ref={txtRef}>
            <Typography
              color={titleColor}
              size={16}
              margin={0}
              data-testid="menu-list-item-title"
            >
              {title}
            </Typography>
          </div>
        </Tooltip>
        {subTitle ? (
          <Typography
            data-testid="menu-list-item-subtitle"
            color={theme.colors.text.lighterBlack}
            size={16}
            margin="4px 0 0 0"
          >
            {subTitle}
          </Typography>
        ) : null}
      </Styled.FlexColumn>
      {active ? (
        <Styled.ActiveMarkContainer data-testid="menu-list-item-active-marker">
          <Styled.ActiveMark background={theme.colors.text.primary} />
        </Styled.ActiveMarkContainer>
      ) : null}
    </>
  );
};

SubjectMenuListItemText.defaultProps = {
  titleColor: '#495057',
};

export default SubjectMenuListItemText;
