import styled from '@emotion/styled';
import { convertPXToREM, convertPXToVH, convertPXToVW } from '../../utils';
export const Wrapper = styled.div`
  background: white;
  cursor: pointer;
  border-radius: 8px;
  grid-column: ${_ref => {
  let {
    grid
  } = _ref;
  return grid;
}};
  border: 2px solid ${_ref2 => {
  let {
    borderColor
  } = _ref2;
  return borderColor;
}};
  border-style: ${_ref3 => {
  let {
    borderType
  } = _ref3;
  return borderType;
}};
  box-shadow: ${_ref4 => {
  let {
    boxShadow
  } = _ref4;
  return boxShadow ? `0px 2px 7px rgb(0 0 0 / 14%);` : `null`;
}};

  &: focus {
    border: 4px solid #004ecc;
    background-color: #f3f6f9;
  }

  &: hover {
    background-color: ${_ref5 => {
  let {
    hover
  } = _ref5;
  return hover;
}};
    border-color: #698aae;
  }

  @media (max-width: 769px) {
    grid-column: ${_ref6 => {
  let {
    mobileGrid
  } = _ref6;
  return mobileGrid;
}};
  }
`;
export const Container = styled.div`
  padding: ${convertPXToVH(32)} ${convertPXToVW(32)};
`;
export const LinkButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: ${_ref7 => {
  let {
    color
  } = _ref7;
  return color;
}};
  svg {
    margin-left: 12px;
  }
`;
export const Title = styled.p`
  margin: ${convertPXToVH(16)} 0px;
  font-weight: 700;
  font-size: ${convertPXToREM(24)};
  color: ${_ref8 => {
  let {
    color
  } = _ref8;
  return color;
}};
`;
export const Description = styled.p`
  margin: 0px 0px ${convertPXToVH(35)} 0px;
  font-weight: 400;
  font-size: ${convertPXToREM(20)};
  color: ${_ref9 => {
  let {
    color
  } = _ref9;
  return color;
}};
`;