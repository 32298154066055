import styled from '@emotion/styled';

import { mediaQuery, convertPXToVW } from '@sofia/ui';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: ${convertPXToVW(230)};
`;

export const IconWrapper = styled.div`
  ${mediaQuery('desktop')} {
    right: 64px;
  }
  padding: 12px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.button.focus.primary};
    svg > path {
      fill: ${({ theme }) => theme.colors.button.darkPrimary};
    }
  }
`;
