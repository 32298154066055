import { firstLetterUpperCase } from '../../../shared';
import convertDateTimeTZ from './convert-date-time-tz';

const toExtensiveDate = ({ startDate }: { startDate: string }): string => {
  if (startDate) {
    const date = convertDateTimeTZ(startDate);
    return `${firstLetterUpperCase(
      date.toLocaleString('pt-BR', {
        weekday: 'long',
      })
    )}, ${date.getDate()} de ${firstLetterUpperCase(
      date.toLocaleString('pt-BR', {
        month: 'long',
      })
    )}`;
  }
  return '';
};

export default toExtensiveDate;
