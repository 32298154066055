import * as React from 'react';
import { getColor } from '@ampli/ui';

function SvgRequestFullscreenImage(props) {
  const brandBase = getColor('brand-base');

  return (
    <svg width={214} height={169} fill="none" {...props}>
      <g clipPath="url(#request-fullscreen-image_svg__clip0)">
        <path
          opacity={0.18}
          d="M61.56 16.192c-3.793 3.934-6.932 8.446-10.252 12.775-12.53 16.233-27.972 30.175-38.93 47.525-10.96 17.35-16.923 39.875-8.163 58.41 5.907 12.517 17.686 21.386 30.324 26.912A83.745 83.745 0 0068.868 169c24.696-.292 47.593-12.414 71.766-17.631 10.714-2.29 21.701-3.269 32.295-6.075 7.507-1.981 14.854-4.93 21.074-9.562 17.252-12.792 23.352-37.218 18.26-58.158-5.092-20.94-19.735-38.507-36.885-51.447C146.33 4.139 91.235-14.62 61.56 16.192z"
          fill={brandBase}
        />
        <path
          d="M18.415 144.024a1.777 1.777 0 00-.878.292 1.448 1.448 0 00-.433 1.299c.223 2.365 2.894 3.556 5.172 4.203a133.069 133.069 0 0039.153 5.056c.797-1.958-.376-4.266-2.085-5.497-1.709-1.231-3.839-1.683-5.872-2.238-2.848-.791-5.44-2.142-8.22-3.115-2.488-.871-5.416-.779-8.076-.905a97.473 97.473 0 00-18.762.905z"
          fill="url(#request-fullscreen-image_svg__paint0_linear)"
        />
        <path
          d="M119.576 98.8l7.729 18.272 24.019 3.991-7.729-21.42-24.019-.843z"
          fill={brandBase}
        />
        <path
          d="M119.576 98.8l7.729 18.272 24.019 3.991-7.729-21.42-24.019-.843z"
          fill="url(#request-fullscreen-image_svg__paint1_linear)"
        />
        <path
          d="M127.305 117.072l-15.458 3.991 31.646 9.133 7.831-9.133-24.019-3.991z"
          fill="url(#request-fullscreen-image_svg__paint2_linear)"
        />
        <path
          d="M127.305 117.072l-15.458 3.991 27.886 13.513 11.591-13.513-24.019-3.991z"
          fill={brandBase}
        />
        <path
          d="M127.305 117.072l-15.458 3.991 27.886 13.513 11.591-13.513-24.019-3.991z"
          fill="url(#request-fullscreen-image_svg__paint3_linear)"
        />
        <path
          d="M115.037 98.8l7.723 18.272 24.025 3.991-7.729-21.42-24.019-.843z"
          fill={brandBase}
        />
        <path
          d="M120.835 112.508l21.177-4.666-2.956-8.2-24.019-.842 5.798 13.708z"
          fill="url(#request-fullscreen-image_svg__paint4_linear)"
        />
        <path
          d="M122.76 117.072l-15.453 3.991 31.64 9.133 7.837-9.133-24.024-3.991z"
          fill={brandBase}
        />
        <path
          d="M122.76 117.072l-15.453 3.991 31.64 9.133 7.837-9.133-24.024-3.991z"
          fill="url(#request-fullscreen-image_svg__paint5_linear)"
        />
        <path
          d="M138.948 130.196l.786 4.38-33.212-10.049.786-3.464 31.64 9.133z"
          fill={brandBase}
        />
        <path
          d="M138.948 130.196l.786 4.38-33.212-10.049.786-3.464 31.64 9.133z"
          fill="url(#request-fullscreen-image_svg__paint6_linear)"
        />
        <path
          d="M177.382 22.463v82a3.496 3.496 0 01-1.044 2.443 3.456 3.456 0 01-2.453.993h-.353l-91.45-10.22a3.463 3.463 0 01-2.19-1.131A3.497 3.497 0 0179 94.243V31.83a3.485 3.485 0 01.9-2.316 3.45 3.45 0 012.21-1.12l91.456-9.413a3.447 3.447 0 012.54.764 3.476 3.476 0 011.276 2.718z"
          fill={brandBase}
        />
        <path
          d="M177.382 22.463v82a3.496 3.496 0 01-1.044 2.443 3.456 3.456 0 01-2.453.993h-.353l-91.45-10.22a3.463 3.463 0 01-2.19-1.131A3.497 3.497 0 0179 94.243V31.83a3.485 3.485 0 01.9-2.316 3.45 3.45 0 012.21-1.12l91.456-9.413a3.447 3.447 0 012.54.764 3.476 3.476 0 011.276 2.718z"
          fill="url(#request-fullscreen-image_svg__paint7_linear)"
        />
        <path
          d="M175.087 22.463v82a3.5 3.5 0 01-1.044 2.443 3.456 3.456 0 01-2.453.993h-.353l-91.451-10.25a3.462 3.462 0 01-2.19-1.13 3.497 3.497 0 01-.892-2.305V31.802a3.486 3.486 0 01.9-2.317 3.45 3.45 0 012.21-1.119l91.457-9.385a3.442 3.442 0 012.54.764 3.476 3.476 0 011.276 2.718z"
          fill={brandBase}
        />
        <path d="M81 87.7V34.608L168 26v65.5l-87-3.8z" fill="#fff" />
        <path
          d="M162 46V31l-18 1.5m18 41V87l-18-1M98.5 37L87 38.36V49m11.5 34.75H87V73.5"
          stroke="#8E9294"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M175.087 94.185v10.307a3.5 3.5 0 01-1.044 2.443c-.655.643-1.537 1-2.453.993h-.353l-91.451-10.25a3.463 3.463 0 01-2.19-1.13 3.497 3.497 0 01-.892-2.305v-5.285l98.383 5.227z"
          fill="url(#request-fullscreen-image_svg__paint8_linear)"
        />
        <path
          d="M37.068 142.546a2.23 2.23 0 00-.194 2.508 2.054 2.054 0 002.279.911.967.967 0 00.398-.212c.245-.235.28-.636.507-.888.241-.193.527-.321.832-.372a5.681 5.681 0 001.503-.813.481.481 0 00.262-.452 2.133 2.133 0 00-.216-1.483 1.709 1.709 0 00-.496-.407 2.404 2.404 0 00-1.196-.361 3.209 3.209 0 00-.95.218 7.28 7.28 0 00-2.399 1.208"
          fill={brandBase}
        />
        <path
          d="M38.754 138.532L37.3 143.56s.274 1.265 1.811.733c1.538-.533 2.848-4.495 2.848-4.495l-3.206-1.266z"
          fill="#A67C52"
        />
        <path
          d="M54.092 149.045a6.94 6.94 0 011.196-.08 1.472 1.472 0 011.06.51 1.545 1.545 0 01-.29 1.958 3.557 3.557 0 01-1.92.802c-.437.103-.89.103-1.327 0-.536-.155-1.054-.607-1.59-.441a2.558 2.558 0 00-.637.4 1.784 1.784 0 01-2.438-.309 2.442 2.442 0 01-.353-.761c-.222-.871-.126-1.947.57-2.462.848-.613 1.509-.195 2.323.091 1.1.346 2.263.445 3.406.292z"
          fill={brandBase}
        />
        <path
          d="M53.386 144.173l-2.125 5.233s-1.219.733-2.084.407l-.866-.327.746-7.318 4.329 2.005zM70.109 74.437c-.029-.235-.04-.516.142-.653.182-.138.28-.103.353-.223a.414.414 0 00.034-.264 6.63 6.63 0 01.131-1.42c.023-.317.127-.623.302-.887a1.14 1.14 0 011.208-.315 1.91 1.91 0 011.02.859c.223.394.39.818.495 1.26.094.308.15.627.165.95 0 .99-.666 1.81-.985 2.737a.765.765 0 01-.148.286.568.568 0 01-.541.08 3.25 3.25 0 01-1.555-.755 2.872 2.872 0 01-.621-1.655z"
          fill="#A67C52"
        />
        <path
          d="M41.34 109.302s.324 5.52.916 8.216c.593 2.697.946 5.766.946 5.766l-5.428 15.695s3.303 2.21 5.428 1.351c2.125-.858 7.495-12.912 7.495-12.912l2.045-13.702-5.41-6.252-5.992 1.838z"
          fill="#473F47"
        />
        <path
          d="M56.337 108.197s3.873 12.757 3.873 16.067c0 3.309-4.642 19.622-4.642 19.622s-5.035 1.718-6.214 0l-1.18-1.718 2.848-16.433s-3.987-8.589-4.721-10.793a23.174 23.174 0 01-.917-4.169l10.953-2.576z"
          fill="#473F47"
        />
        <path
          d="M54.69 73.28a8.59 8.59 0 01.855 2.897 2.91 2.91 0 01-1.14 2.646c-.243.12-.472.268-.683.44-.723.745.108 2.102-.313 3.07a1.428 1.428 0 01-1.794.67 2.421 2.421 0 01-1.35-1.564 7.479 7.479 0 01-.245-2.158l-.074-4.329a5.754 5.754 0 01.433-2.828 2.796 2.796 0 012.586-1.409c1.054.132 1.361 1.718 1.726 2.565z"
          fill="#A67C52"
        />
        <path
          d="M68.77 92.021c-.633.174-1.278.296-1.93.367a41.784 41.784 0 00-9.04 2.359 1.432 1.432 0 00-.54.314c-.143.2-.235.432-.268.676-.82 4.037-.57 8.349-.673 12.465a25.319 25.319 0 01-3.178 1.581c-1.788.738-4.05 1.42-6.02 1.237-3.987-.367-5.781-1.718-5.781-1.718l2.466-24.85s4.676-3.385 6.265-2.864c1.144.2 2.314.2 3.458 0 2.312-.309 4.67 2.863 4.67 2.863.17 2.159 6.835 1.838 8.413 1.718.235.004.468-.05.677-.16.2-.166.352-.381.445-.624 1.253-2.674 2.534-5.474 2.483-8.435 0-.458 0-1.03.439-1.208.158-.05.326-.059.49-.028.852.063 1.696.216 2.517.458-.638 2.227.148 4.58-.046 6.917a16.282 16.282 0 01-1.264 4.506c-.615 1.58-1.333 3.287-2.848 4.1-.235.13-.481.238-.735.326z"
          fill="#fff"
        />
        <path
          d="M54.417 68.62c.051-.482.233-.94-.217-1.318a.97.97 0 00-1.332.15 1.466 1.466 0 00-.986-.837c-.336-.08-.786.04-.831.39a1.843 1.843 0 00-3.024-.235l-1.032-.344a1.542 1.542 0 00-1.059-.103c-.342.132-.535.641-.256.87a.925.925 0 00-.92.654.938.938 0 00.384 1.064.812.812 0 00-.312.856.815.815 0 00.136.29.77.77 0 00.233.217 1.07 1.07 0 00-.667.458c-.154.234-.211.52-.159.796.055.267.207.504.428.664a1.455 1.455 0 00-.416 1.998c.122.185.283.34.472.453a2.652 2.652 0 00-.193 1.569 1.296 1.296 0 001.082 1.03 1.58 1.58 0 00-.991 1.375 1.42 1.42 0 00.962 1.357c.126.034.274.063.342.171a.528.528 0 01.057.281 1.114 1.114 0 00.699.956 1.1 1.1 0 001.158-.223.62.62 0 00.572.588.613.613 0 00.647-.502c-.182.859 1.236.131 1.435 0 .57-.435.809-1.145 1.31-1.644.746-.715 1.806-.973 1.709-2.29a4.185 4.185 0 00-.894-1.867 2.17 2.17 0 01-.41-1.98c.239-.527.797-.79 1.213-1.146a3.325 3.325 0 00.923-2.45 8.28 8.28 0 01-.063-1.249z"
          fill="#774828"
        />
        <path
          d="M45.515 71.133a1.607 1.607 0 011.139.618c.262.347.468.734.61 1.145.152.34.223.71.204 1.083a4.147 4.147 0 01-.438 1.18 5.489 5.489 0 01-.353.766.908.908 0 01-.678.459.97.97 0 01-.912-.67 9.827 9.827 0 01-.267-1.145 5.309 5.309 0 01-.268-.98c.011-.347.057-.692.137-1.03.045-.287-.108-.848 0-1.082.108-.235.547-.344.826-.344z"
          fill={brandBase}
        />
        <path
          d="M38.68 65.235a1.564 1.564 0 00-1.544.31 2.426 2.426 0 00-2.443-.756.903.903 0 00-.422.2.861.861 0 000 1.117c.27.308.61.546.991.692a1.81 1.81 0 00-.723.247.572.572 0 00-.25.67c.061.138.17.25.307.314.66.338 1.435 0 2.073.424-.416.166-1.6 3.012-1.264 3.241-.65.396-1.227.9-1.71 1.489a2.342 2.342 0 00-.432 2.158 1.445 1.445 0 00.736.82 1.43 1.43 0 001.098.056 1.285 1.285 0 00-.21 1.294 5.862 5.862 0 002.278 0 .823.823 0 00.262 1.111.8.8 0 001.082-.338c-.2.516.49 1.014 1.02.865a2.227 2.227 0 001.138-1.145c0 .424.604.607.963.395.334-.257.572-.62.672-1.03a.98.98 0 001.18-.573c.165-.456.215-.946.147-1.426.24.338.74-.04.94-.4.363-.666.668-1.361.911-2.08.149-.347.205-.728.166-1.104-.16-.934-1.202-.87-1.498-1.535-.382-.859.444-1.357-.57-2.256a8.838 8.838 0 00-2.569-1.609 1.004 1.004 0 00-.655-.103c-.176.052-.359.195-.524.12-.165-.074-.165-.2-.21-.32a1.523 1.523 0 00-.94-.848z"
          fill="#774828"
        />
      </g>
      <defs>
        <linearGradient
          id="request-fullscreen-image_svg__paint0_linear"
          x1={49.103}
          y1={106.313}
          x2={37.412}
          y2={157.808}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.08} stopOpacity={0.69} />
          <stop offset={0.21} stopOpacity={0.32} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="request-fullscreen-image_svg__paint1_linear"
          x1={9811.01}
          y1={-3135.24}
          x2={4624.35}
          y2={791.433}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.08} stopOpacity={0.69} />
          <stop offset={0.21} stopOpacity={0.32} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="request-fullscreen-image_svg__paint2_linear"
          x1={12493.8}
          y1={-1628.66}
          x2={9635.92}
          y2={2935.15}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.08} stopOpacity={0.69} />
          <stop offset={0.21} stopOpacity={0.32} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="request-fullscreen-image_svg__paint3_linear"
          x1={12527.8}
          y1={-2163.35}
          x2={8358.52}
          y2={2828.45}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.08} stopOpacity={0.69} />
          <stop offset={0.21} stopOpacity={0.32} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="request-fullscreen-image_svg__paint4_linear"
          x1={6221.52}
          y1={-2025}
          x2={6442.4}
          y2={-849.413}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.08} stopOpacity={0.69} />
          <stop offset={0.21} stopOpacity={0.32} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="request-fullscreen-image_svg__paint5_linear"
          x1={8136.16}
          y1={-1778.14}
          x2={8599.56}
          y2={277.734}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.08} stopOpacity={0.69} />
          <stop offset={0.21} stopOpacity={0.32} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="request-fullscreen-image_svg__paint6_linear"
          x1={7145.09}
          y1={-1642.56}
          x2={7306.64}
          y2={-495.08}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.08} stopOpacity={0.69} />
          <stop offset={0.21} stopOpacity={0.32} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="request-fullscreen-image_svg__paint7_linear"
          x1={38775.7}
          y1={21676.6}
          x2={-2546.15}
          y2={5301.56}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.08} stopOpacity={0.69} />
          <stop offset={0.21} stopOpacity={0.32} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="request-fullscreen-image_svg__paint8_linear"
          x1={14449.4}
          y1={5166.47}
          x2={15553.2}
          y2={-3276.62}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.08} stopOpacity={0.69} />
          <stop offset={0.21} stopOpacity={0.32} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <clipPath id="request-fullscreen-image_svg__clip0">
          <path fill="#fff" d="M0 0h214v169H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgRequestFullscreenImage;
