import React from 'react';

import EvaluationOnboardingContent from './content/evaluation-onboarding-content';

import {
  ImageNoConnection,
  Layout,
  LayoutContent,
} from '../../../../../components';

import { EvaluationOnboardingProps } from '../types';
import { useBreakpoint } from '../../../hooks';

import * as Styled from './evaluation-onboarding.styles';

const EvaluationOnboarding = ({
  subjectName,
  weight,
  items,
  button,
  buttonBack,
  userFirstName,
  onLogoClick,
  withLoading,
  title,
}: EvaluationOnboardingProps): React.ReactElement => {
  const { isDesktop } = useBreakpoint();

  return (
    <Layout
      userFirstName={userFirstName}
      showNavigation={isDesktop}
      onLogoClick={onLogoClick}
    >
      {isDesktop && <Styled.SplitScreen />}
      <Styled.Wrapper data-testid="evaluation-onboarding">
        <LayoutContent padding={['small']}>
          {isDesktop && (
            <Styled.ImageSide>
              <ImageNoConnection width={376} height={458} />
            </Styled.ImageSide>
          )}
          <Styled.TextSide>
            <EvaluationOnboardingContent
              title={title}
              subjectName={subjectName}
              weight={weight}
              items={items}
              button={button}
              buttonBack={buttonBack}
              withLoading={withLoading}
            />
          </Styled.TextSide>
        </LayoutContent>
      </Styled.Wrapper>
    </Layout>
  );
};

export default EvaluationOnboarding;
