import React from 'react';

import { useServiceClient } from '@ampli/services';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import {
  SvgAngleDown,
  SvgAngleUp,
  Typography,
  SvgArrowRight,
  Separator,
  SvgUserCircle,
  SvgIconRight,
} from '@sofia/ui';

import {
  Wrapper,
  AvatarCircle,
  AvatarDropDownWrapper,
  FlexBox,
  TextWrapper,
} from './avatar.styles';

import { object, arrayOf, string, bool, func } from 'prop-types';
import { locationPathname } from '../../constants';
import { useMeContext } from '../../modules/shared/contexts/me-context';
import { routePath } from '../../routes';

import logout from '../../lib/logout';

const Avatar = ({
  roles,
  ra,
  shouldRedirect,
  redirectionBlockAction,
  setLastNavigationRoute,
}) => {
  const theme = useTheme();

  const buttonRef = React.useRef(null);
  const dialogRef = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  const { states: statesMe } = useMeContext();
  const { me } = statesMe.getMe;

  const name = me?.socialName || me?.firstName || '';
  const firstLetter = name.substring(0, 1);

  const icon = open ? (
    <SvgAngleUp
      data-testid="angle-up-profile"
      id="angle-up-profile"
      height={6}
      fill={theme.colors.text.secondary}
    />
  ) : (
    <SvgAngleDown
      data-testid="angle-down-profile"
      id="angle-down-profile"
      height={6}
      fill={theme.colors.text.secondary}
    />
  );

  const handleClickOutside = (event) => {
    if (
      !dialogRef.current?.contains(event.target) &&
      !buttonRef.current?.contains(event.target)
    )
      setOpen(false);
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <>
      <Wrapper
        ref={buttonRef}
        onClick={() => setOpen((old) => !old)}
        id="profile-desktop"
        data-testid="profile-desktop"
      >
        <AvatarCircle id="user-letter" data-testid="user-letter">
          {firstLetter}
        </AvatarCircle>

        {icon}
      </Wrapper>

      {open ? (
        <AvatarDropDownContainer
          dialogRef={dialogRef}
          me={me?.email}
          name={name}
          ra={ra}
          theme={theme}
          roles={roles}
          shouldRedirect={shouldRedirect}
          redirectionBlockAction={redirectionBlockAction}
          setLastNavigationRoute={setLastNavigationRoute}
        />
      ) : null}
    </>
  );
};

Avatar.propTypes = {
  roles: arrayOf(string),
  ra: string,
  shouldRedirect: bool,
  redirectionBlockAction: func,
  setLastNavigationRoute: func,
};

const AvatarDropDownContainer = ({
  theme,
  email,
  ra,
  name,
  dialogRef,
  shouldRedirect,
  redirectionBlockAction,
  setLastNavigationRoute,
}) => {
  const client = useServiceClient('privateClient');
  const history = useHistory();

  const homePath = routePath.courseSelection;
  const profilePath = routePath.profile;
  const actualPath = locationPathname();

  const handleLogout = () => {
    const logoutAction = () => logout({ client, history });

    if (shouldRedirect) {
      logoutAction();
    }

    if (setLastNavigationRoute && redirectionBlockAction) {
      setLastNavigationRoute(() => logoutAction);
      redirectionBlockAction();
    }
  };

  const handleGoToProfile = () => {
    const pushToProfile = () => history.push(routePath.profile);

    if (shouldRedirect) {
      pushToProfile();
    }

    if (setLastNavigationRoute && redirectionBlockAction) {
      setLastNavigationRoute(() => pushToProfile);
      redirectionBlockAction();
    }
  };

  return (
    <AvatarDropDownWrapper ref={dialogRef}>
      <TextWrapper>
        <Typography data-testid="username" id="username" weight="bold">
          {name}
        </Typography>
        {ra ? (
          <Typography
            data-testid="email"
            id="email"
            margin="0px 0px 16px 0px"
            size={14}
          >
            Matrícula - {ra}
          </Typography>
        ) : (
          <Typography
            data-testid="email"
            id="email"
            margin="0px 0px 16px 0px"
            size={14}
          >
            {email}
          </Typography>
        )}
      </TextWrapper>
      <Separator />
      {[homePath, profilePath].find((el) => el === actualPath) ? (
        <></>
      ) : (
        <FlexBox
          data-testid="profile"
          id="profile"
          cursor="pointer"
          justify="space-between"
          margin={'16px 0'}
          onClick={handleGoToProfile}
        >
          <FlexBox cursor="pointer">
            <SvgUserCircle color={theme.colors.text.lightBlack} />
            <Typography
              weight={700}
              color={theme.colors.text.secondary}
              margin="0px 16px 0px 16px"
              size={14}
            >
              Perfil
            </Typography>
          </FlexBox>
          <SvgArrowRight />
        </FlexBox>
      )}
      <FlexBox
        data-testid="logout"
        id="logout"
        onClick={handleLogout}
        justify="space-between"
        margin="16px 0"
      >
        <FlexBox>
          <SvgIconRight />
          <Typography
            id="logout-txt"
            data-testid="logout-txt"
            margin="0px 16px 0px 16px"
            size={14}
          >
            Sair
          </Typography>
        </FlexBox>
        <SvgArrowRight />
      </FlexBox>
    </AvatarDropDownWrapper>
  );
};

AvatarDropDownContainer.propTypes = {
  theme: object,
  dialogRef: object,
  email: string,
  ra: string,
  name: string,
  shouldRedirect: bool,
  redirectionBlockAction: func,
  setLastNavigationRoute: func,
};

export default Avatar;
