import React from 'react';

import { SvgTimes, Typography } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { Tooltip } from '@material-ui/core';
import { useTheme } from '@emotion/react';

import ObjectCustomizationItems from './object-customization-items';
import Menu from '../../menu';

import * as Styled from './object-customization-menu.styles';
import {
  CustomizationTemplateType,
  ObjectCustomizationMenuProps,
} from './types';
import { TEMPLATE_CUSTOMIZATION_COLOR } from './constants';

const ObjectCustomizationMenu = ({
  handle,
  template,
  onApply,
  onClose,
  studentId,
}: ObjectCustomizationMenuProps): React.ReactElement => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  React.useEffect(() => {
    const feedbackContainer = document.getElementsByClassName(
      '_hj_feedback_container'
    ) as HTMLCollectionOf<HTMLElement>;
    if (!isDesktop && feedbackContainer && feedbackContainer.length > 0) {
      feedbackContainer[0].style.display = handle ? 'none' : 'block';
    }
  }, [isDesktop, handle]);

  return (
    <Menu
      data-testid="object-customization-menu"
      open={handle}
      fromRight
      absolute
      closedSize={0}
      content={
        <Styled.Container>
          <ObjectCustomizationItems
            studentId={studentId}
            onApply={(selectedCustomization) => onApply(selectedCustomization)}
            defaultTemplate={
              template
                ? TEMPLATE_CUSTOMIZATION_COLOR[
                    template?.name as keyof CustomizationTemplateType
                  ]
                : null
            }
          />
        </Styled.Container>
      }
      header={
        <Styled.Header>
          <Typography size={24} weight={700} color={theme.colors.text.black}>
            Customização
          </Typography>
          <Tooltip arrow title={'Fechar customização'}>
            <Styled.IconBtn
              data-testid="customization-menu-close"
              onClick={onClose}
              aria-label={'Fechar customização'}
            >
              <SvgTimes />
            </Styled.IconBtn>
          </Tooltip>
        </Styled.Header>
      }
    />
  );
};

ObjectCustomizationMenu.defaultProps = {
  handle: false,
};

export default ObjectCustomizationMenu;
