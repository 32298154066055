import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW, mediaQuery } from '@sofia/ui';

export const Container = styled.div`
  min-width: ${convertPXToVW(378)};
  min-height: ${convertPXToVH(284)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${convertPXToVH(32)} ${convertPXToVW(32)};
  border-radius: 8px;
  border: ${({ noBorder, theme }) =>
    noBorder ? 'none' : `2px solid ${theme.colors.card.border.primary}`};
  background: ${({ theme }) => theme.colors.card.background.secondary};

  ${mediaQuery('mobile')} {
    min-width: ${convertPXToVW(340)};
  }
`;
