import React from 'react';
import Skeleton from '../../../../skeleton/skeleton';
import PageLoaderLayout from '../layout/page-loader-layout';
import * as Styled from './practical-activities-page-loader.styles';

const PracticalActivitiesPageLoader = (): React.ReactElement => {
  return (
    <PageLoaderLayout data-testid="practical-activities-page-loader">
      <Skeleton height="36px" borderRadius="8px" width="30%" />
      <Skeleton height="72px" borderRadius="8px" width="50%" margin="8px 0 0" />

      <Skeleton height="104px" borderRadius="8px" margin="56px 0" />
      <Skeleton
        width="185px"
        height="28px"
        borderRadius="8px"
        margin="0 0 24px"
      />

      <Styled.SimulatorsWrapper>
        {Array.from({ length: 4 }, (_, index) => (
          <Skeleton
            key={index}
            height="172px"
            borderRadius="8px"
            margin="32px 0"
          />
        ))}
      </Styled.SimulatorsWrapper>
    </PageLoaderLayout>
  );
};

export default PracticalActivitiesPageLoader;
