import React from 'react';

import { useTheme } from '@emotion/react';

import * as Styles from './before-start.styles';
import * as Steps from './before-start.steps';

const BeforeStartDesktop: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <Styles.Title
        as="h3"
        size={36}
        weight={700}
        padding="16px"
        margin="16px 0 0 0"
      >
        Boas-vindas!
      </Styles.Title>

      <Styles.Subtitle
        as="p"
        size={18}
        weight={700}
        color={theme.colors.text.lightBlack}
      >
        Antes de iniciar seus estudos, gostaria de conhecer a plataforma?
      </Styles.Subtitle>

      <Styles.Subtitle
        as="p"
        size={16}
        weight={400}
        color={theme.colors.text.lightBlack}
      >
        Faça um tour pelo AVA (Ambiente Virtual de Aprendizagem) e conheça as
        principais funcionalidades
      </Styles.Subtitle>

      <Styles.Illustrations>
        <Styles.Illustration>
          <Steps.EstudeOndeQuiser />
        </Styles.Illustration>

        <Styles.Illustration>
          <Steps.AprendizadoDinamico />
        </Styles.Illustration>

        <Styles.Illustration>
          <Steps.PraticidadeNaRotina />
        </Styles.Illustration>
      </Styles.Illustrations>
    </>
  );
};

export default BeforeStartDesktop;
