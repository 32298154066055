import styled from '@emotion/styled';

import { ButtonOutlineLong, convertPXToVH, convertPXToVW } from '@sofia/ui';

export const CustomButtonOutline = styled(ButtonOutlineLong)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${convertPXToVH(26)} ${convertPXToVW(26)};
  margin-top: ${convertPXToVH(16)};

  > span {
    display: flex;
    align-items: center;
  }

  @media (max-width: 769px) {
    padding: ${convertPXToVH(26)} ${convertPXToVW(26)};
  }
`;
