import { useEffect } from 'react';
import { useGetAssignment, useGetExam } from '@ampli/services';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useSteps } from '@ampli/hooks';
import { find } from '@ampli/utils';
import { useMeContext } from '../modules/shared/contexts/me-context';
import { useGetState } from '../state';
import { routePath } from '../routes';
import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';

const useQuizCorrectionPageData = () => {
  const history = useHistory();
  const params = useParams();
  const { quizId, attemptId } = params;

  const { states: statesMe } = useMeContext();
  const { me, loading: meLoading } = statesMe.getMe;

  const { states: statesCourseEnrollment } = useCourseEnrollmentsContext();
  const { data: courseEnrollment, loading: courseEnrollmentLoading } =
    statesCourseEnrollment.courseEnrollments;

  const { subjectEnrollmentId } = useParams();
  const subjectEnrollment = courseEnrollment?.subjectEnrollments?.find(
    (subject) => subject?.id === subjectEnrollmentId
  );

  const learningUnits = subjectEnrollment?.learningUnits;

  const { data: quiz, loading: quizLoading } = useGetExam(quizId);
  const { data: assignment, loading: assignmentLoading } = useGetAssignment(
    quiz?.assignmentId
  );

  const questions = quiz?.questions || [];
  const numberOfQuestions = questions.length || 0;
  const attempt = find(assignment?.attempts || [], { id: attemptId });
  const stepsControl = useSteps(numberOfQuestions);

  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const loading =
    meLoading || quizLoading || assignmentLoading || courseEnrollmentLoading;

  useEffect(() => {
    if (!loading && !quiz) {
      console.warn('Quiz not found.');
      history.replace(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })
      );
    }
  }, [
    history,
    loading,
    quiz,
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
  ]);

  return {
    me,
    quiz,
    course: courseEnrollment?.course,
    subjectEnrollment,
    loading,
    attempt,
    assignment,
    stepsControl,
    courseEnrollment,
    learningUnits,
    subjectEnrollmentId,
  };
};

export default useQuizCorrectionPageData;
