import { useCourseSelection } from '../../hooks';
import CourseCard from './card-course';
import React from 'react';
import { courseEnrollmentLabelByStatus } from '../../constants';
import { clickByKey } from '@ampli/utils';

interface IStudentFreeTrialDataProps {
  id: string;
  freeTrialSituation: string;
  freeTrialDaysLeft: number;
  courseId: string;
  roles: string[];
}

interface ICourseEnrollmentLabelByStatus {
  ATTENDING: string;
  FINISHED: string;
  SUSPENDED: string;
  CANCELLED: string;
  TRANSFERRED: string;
  ABANDONED: string;
  DROPPED: string;
}

interface ICourseByDegreeCardProps {
  id: string;
  index: number;
  course: {
    id: string;
    name: string;
    versionId: string;
    courseType: {
      degree: string;
      code: string;
    };
  };
  progress: {
    totalCourseNumber: string;
    passedCourseNumber: string;
  };
  status: keyof ICourseEnrollmentLabelByStatus;
  roles: string[];
  studentFreeTrialData: { data: IStudentFreeTrialDataProps[] };
  onCourseEnrollmentClick: (data: {
    id: string;
    courseId: string;
    versionId: string;
    type: string;
    degree: string;
  }) => void;
  handleModal: {
    show: () => void;
  };
  hasPendingContract: boolean;
}

const CourseByDegreeCard: React.FC<ICourseByDegreeCardProps> = React.memo(
  ({
    id,
    index,
    course,
    progress,
    status,
    roles,
    studentFreeTrialData,
    onCourseEnrollmentClick,
    handleModal,
    hasPendingContract,
  }: ICourseByDegreeCardProps) => {
    const {
      refetchCourseEnrollments,
      setCourseEnrollmentId,
      setCourseId,
      setCourseVersionId,
      setCourseEnrollmentType,
    } = useCourseSelection();

    const filterStudentFreeTrialData = (
      studentFreeTrial: {
        data: IStudentFreeTrialDataProps[];
      },
      enrollmentId: string
    ) => {
      return studentFreeTrial?.data?.find(
        (studentFreeTrial: IStudentFreeTrialDataProps) =>
          studentFreeTrial.id === enrollmentId
      );
    };

    const onClick = async () => {
      const isAnhanguera = !roles.includes('CHAT_ENABLED');

      localStorage.removeItem('termsModal');

      if (hasPendingContract) {
        setCourseEnrollmentId(id);
        setCourseId(course.id);
        setCourseVersionId(course.versionId);
        setCourseEnrollmentType(course.courseType?.code);
        return handleModal.show();
      }

      if (!isAnhanguera || !hasPendingContract)
        return onCourseEnrollmentClick({
          id,
          versionId: course.versionId,
          courseId: course.id,
          type: course.courseType?.code,
          degree: course.courseType?.degree,
        });
    };

    const handleRefetch = () => {
      refetchCourseEnrollments();
    };

    const courseCardProps = {
      roles,
      degree: course.courseType?.degree,
      courseName: course.name,
      numberOfSubjects: progress.totalCourseNumber,
      numberOfCompletedSubjects: progress.passedCourseNumber,
      freeTrialDaysLeft: filterStudentFreeTrialData(studentFreeTrialData, id)
        ?.freeTrialDaysLeft,
      freeTrialSituation: filterStudentFreeTrialData(studentFreeTrialData, id)
        ?.freeTrialSituation,
      statusLabel: courseEnrollmentLabelByStatus[status],
      onLoadCard: handleRefetch,
      onKeyDown: (evt: Event) => clickByKey(evt, onClick),
      onClick,
      index: index,
      courseType: course.courseType?.code,
    } as React.ComponentProps<typeof CourseCard>;

    return <CourseCard {...courseCardProps} />;
  }
);

CourseByDegreeCard.displayName = 'CourseByDegreeCard';

export default CourseByDegreeCard;
