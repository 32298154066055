import React from 'react';

import { SvgWordSpacing, SvgLetterSpacing } from '@sofia/ui';

import CustomizationSection from '../../section/customization-section';
import BetweenLines from './between-lines/between-lines';
import BetweenWordsAndLetters from './between-words-and-letters/between-words-and-letters';
import { SpacingSectionProps } from '../../../types';
import { pushAmpliEvent } from '../../../../../../../../../../lib/ga';

const SpacingSection = ({
  selectedCustomization,
  reset,
  onClickEvent,
}: SpacingSectionProps): React.ReactElement => {
  return (
    <CustomizationSection title="Espaçamentos">
      <BetweenLines
        reset={reset}
        defaultValue={selectedCustomization?.betweenLines}
        onChange={(selectedBetweenLines) => {
          pushAmpliEvent(
            'event',
            `kindle-space-between-lines-${selectedBetweenLines}%`
          );
          onClickEvent('betweenLines', selectedBetweenLines);
        }}
      />
      <BetweenWordsAndLetters
        reset={reset}
        label="palavras"
        icon={<SvgWordSpacing />}
        defaultValue={selectedCustomization?.betweenWords}
        onChange={(selectedBetweenWords) => {
          pushAmpliEvent(
            'event',
            `kindle-space-between-words-${selectedBetweenWords}%`
          );
          onClickEvent('betweenWords', selectedBetweenWords);
        }}
      />
      <BetweenWordsAndLetters
        reset={reset}
        label="letras"
        icon={<SvgLetterSpacing />}
        defaultValue={selectedCustomization?.betweenLetters}
        onChange={(selectedBetweenLetters) => {
          pushAmpliEvent(
            'event',
            `kindle-space-between-letters-${selectedBetweenLetters}%`
          );
          onClickEvent('betweenLetters', selectedBetweenLetters);
        }}
      />
    </CustomizationSection>
  );
};

export default SpacingSection;
