import { css, px2grid, mediaQuery } from '@ampli/ui';

import styled from '@emotion/styled';

export const title = css`
  margin-top: ${px2grid(32)};
  margin-bottom: ${px2grid(40)};
`;

export const titleText = css`
  margin: 0 ${px2grid(16)};
`;

export const layoutContentTop = css`
  padding-bottom: 0;
`;

export const layoutContentBottom = css`
  padding-bottom: ${px2grid(80)};
`;

export const Hr = styled.hr`
  margin: 0px;
`;

export const tab = css`
  ${mediaQuery('desktop')} {
    padding: ${px2grid(8)} ${px2grid(82)};
  }
`;

export const bannerLayout = css`
  padding-top: ${px2grid(56)};
  padding-bottom: ${px2grid(56)};
`;

export const coursesByDegree = css`
  &:not(:last-child) {
    margin-bottom: ${px2grid(56)};
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: baseline;
`;

export const IconWrapper = styled.div`
  ${mediaQuery('desktop')} {
    right: 64px;
  }
  padding: 12px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
    svg > path {
      fill: white;
    }
  }
`;
