import React from 'react';
import { func } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import {
  DialogBackdrop,
  DialogAlert,
  DialogHeader,
  DialogCloseButton,
  DialogSection,
  Text,
  FlexItem,
  Paragraph,
  DialogFooter,
  GradientButton,
} from '@ampli/ui';
import { ExamDoubtsImage } from '../image';

const ExamDoubtsAlert = ({ onActionClick, ...props }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <>
      <DialogBackdrop aria-hidden {...props} />
      <DialogAlert {...props}>
        <DialogHeader>
          <DialogCloseButton onClick={props.hide} />
          <Paragraph textAlign="center">
            <ExamDoubtsImage />
          </Paragraph>
          <Paragraph textAlign="center">
            <Text
              as="strong"
              color="text-dark"
              size={isDesktop ? 'x-large' : 'medium'}
            >
              Dúvidas sobre a prova?
            </Text>
          </Paragraph>
        </DialogHeader>
        <FlexItem flex={1}>
          <DialogSection>
            <Paragraph textAlign="center" marginSize="large">
              <Text size={isDesktop ? 'medium' : 'small'}>
                Estamos aqui para esclarecer as suas dúvidas, mas lembre-se, não
                podemos receber documentos ou capturas de telas durante a prova
                por uma questão de segurança.
              </Text>
            </Paragraph>
          </DialogSection>
        </FlexItem>
        <DialogFooter>
          <GradientButton fluid onClick={onActionClick}>
            Entendi
          </GradientButton>
        </DialogFooter>
      </DialogAlert>
    </>
  );
};

ExamDoubtsAlert.propTypes = {
  hide: func.isRequired,
  onActionClick: func.isRequired,
};

export default ExamDoubtsAlert;
