import { useHistory, useRouteMatch } from 'react-router-dom';
import { convertDateTimeTZ, isSameDate } from '../../../../../section/shared';
import { ELiveState } from '../../types';
import { useGetLiveData } from '../../hooks';
import {
  IAccessCardLogic,
  IGetLiveAccessCardHook,
  TCanShowDate,
  TGetAriaLabel,
  TNavigateToLive,
} from './types';
import { pushEvent } from '../../../../../../lib/ga';

export const useGetLiveAccessCard = ({
  courseVersionId,
  subjectId,
}: IAccessCardLogic): IGetLiveAccessCardHook => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const navigateToLive: TNavigateToLive = (url, live) => {
    history.push(`${url}/live/${live?.id}`, {
      live,
    });
  };

  const canShowDate: TCanShowDate = (startDate) => {
    const startDateTime = convertDateTimeTZ(startDate);
    const today = convertDateTimeTZ(new Date());
    if (startDateTime && today) {
      return startDateTime > today;
    }

    return false;
  };

  const getAriaLabel: TGetAriaLabel = () => {
    const startDate = convertDateTimeTZ(live?.startDateTime || '') || '';
    const title = live?.title || '';
    const finishingTime = '';

    const formattedDate = new Date(startDate)?.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });

    const nextLive = isSameDate(startDate) ? 'Próxima hoje!' : 'Próxima em ';

    return {
      scheduled: `Aulas ao vivo.
    ${
      startDate
        ? `${nextLive} ${formattedDate}, das ${beginningTime} horas até as ${finishingTime} horas. Horário de Brasília.`
        : 'A próxima data estará disponível em breve.'
    }`,
      live: `
    Aula ao vivo acontecendo agora!
    Tema: ${title}.
    Horário: das ${beginningTime} horas até as ${finishingTime} horas. Horário de Brasília.
    Assista ao vivo agora!`,
    };
  };

  const { live, loading, state } = useGetLiveData({
    courseVersionId,
    subjectId,
  });

  const startDateTZ = convertDateTimeTZ(live?.startDateTime || '');
  const isLive = state === ELiveState.LIVE;
  const beginningTime = startDateTZ
    ? `${('0' + startDateTZ.getHours()).slice(-2)}:${(
        '0' + startDateTZ.getMinutes()
      ).slice(-2)}`
    : '';
  const showThumbnail = isLive;
  const cardClick = () => {
    pushEvent('event', 'home-live-access');

    navigateToLive(url, live);
  };

  return {
    canShowDate,
    getAriaLabel,
    isLive,
    showThumbnail,
    cardClick,
    loading,
    live: live || null,
    beginningTime,
    state,
  };
};
