import { TFindLearningObjectEnrollmentsHook, TLearningObject } from './types';
import { useFindLearningUnitEnrollmentsForSectionPageQuery } from './hooks.gql';
import { TLearningUnitEnrollment, TSectionEnrollment } from '../../shared';

export const useFindLearningObjectEnrollments: TFindLearningObjectEnrollmentsHook =
  (subjectEnrollmentId, learningUnitEnrollmentId, sectionPath, options) => {
    const { data, loading, ...rest } =
      useFindLearningUnitEnrollmentsForSectionPageQuery({
        skip: !subjectEnrollmentId,
        ...options,
        variables: {
          subjectEnrollmentId: subjectEnrollmentId || '',
        },
      });

    let learningObjectEnrollments: TLearningObject[] = [];
    let learningUnitEnrollment: TLearningUnitEnrollment | null = null;
    let sectionEnrollment: TSectionEnrollment | null = null;
    if (!loading && data) {
      const learningUnitEnrollments = data.data || [];
      learningUnitEnrollment =
        learningUnitEnrollments.find(
          (learningUnit) => learningUnit.id === learningUnitEnrollmentId
        ) || null;
      sectionEnrollment =
        learningUnitEnrollment?.sections?.find(
          (section) => section.pathUrl === sectionPath
        ) || null;
      learningObjectEnrollments = sectionEnrollment?.learningObjects || [];
    }

    return {
      learningObjectEnrollments,
      learningUnitEnrollment,
      sectionEnrollment,
      loading,
      ...rest,
    };
  };
