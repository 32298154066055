import NotificationService from '../../../lib/notification-service';

const handleCopyToClipboard = (value: string, successText: string): void => {
  const textField = document.createElement('textarea');

  textField.innerText = value;
  document.body.appendChild(textField);

  textField.select();
  document.execCommand('copy');

  textField.remove();

  NotificationService.success(successText);
};

export default handleCopyToClipboard;
