import { isProduction } from '../config';
import { QUIZ_TIME_MINUTES, QUIZ_TIME_LEFT_MINUTES } from '../constants';

const useQuizTimes = (quiz) => {
  const maxDurationInMinutes =
    (isProduction && quiz?.maxDurationInMinutes) || QUIZ_TIME_MINUTES;
  const timeRunningOutMinutes = maxDurationInMinutes - QUIZ_TIME_LEFT_MINUTES;

  return {
    maxDurationInMinutes,
    timeRunningOutMinutes,
  };
};

export default useQuizTimes;
