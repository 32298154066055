import React from 'react';

import CustomizationSection from '../../section/customization-section';
import FormatAlign from './format-align/format-align';
import FormatFont from './format-font/format-font';
import FontSizeSlider from './font-size-slider/font-size-slider';
import { TextSectionProps } from '../../../types';
import { pushAmpliEvent } from '../../../../../../../../../../lib/ga';

const TextSection = ({
  selectedCustomization,
  reset,
  onClickEvent,
}: TextSectionProps): React.ReactElement => {
  return (
    <CustomizationSection title="Configurações de Texto">
      <FormatAlign
        reset={reset}
        defaultValue={selectedCustomization?.formatAlign}
        onChange={(selectedFormatAlign) => {
          pushAmpliEvent('event', `kindle-format-align-${selectedFormatAlign}`);
          onClickEvent('formatAlign', selectedFormatAlign);
        }}
      />
      <FormatFont
        reset={reset}
        defaultValue={selectedCustomization?.formatFont}
        onChange={(selectedFormatFont) => {
          pushAmpliEvent('event', `kindle-font-${selectedFormatFont}`);
          onClickEvent('formatFont', selectedFormatFont);
        }}
      />
      <FontSizeSlider
        reset={reset}
        defaultValue={selectedCustomization?.fontSize}
        onChange={(selectedFontSize) => {
          pushAmpliEvent('event', `kindle-font-size-${selectedFontSize}`);
          onClickEvent('fontSize', selectedFontSize);
        }}
      />
    </CustomizationSection>
  );
};

export default TextSection;
