import {
  getClientRect,
  getElement,
  getElementPosition,
  hasPosition,
} from './dom';

export const addTourEventListener = (eventName, callback) => {
  window.addEventListener(eventName, callback);
};

export const elementExists = (selector) => {
  return document.querySelector(selector) !== null;
};

export const waitForElement = (selector) => {
  let interval = null;
  return new Promise((resolve) => {
    interval = setInterval(() => {
      if (elementExists(selector)) {
        clearInterval(interval);
        resolve();
      }
    }, 100);
  });
};

export const checkRedirect = (route) => {
  return route && route !== window.location.pathname;
};

const spotlightStyles = (
  target,
  spotlightPadding = 10,
  disableScrollParentFix = false
) => {
  const element = getElement(target);
  const elementRect = getClientRect(element);
  const isFixedTarget = hasPosition(element);
  const top = getElementPosition(
    element,
    spotlightPadding,
    disableScrollParentFix
  );

  return {
    height: Math.round(elementRect.height + spotlightPadding * 2) + 'px',
    left: Math.round(elementRect.left - spotlightPadding) + 'px',
    opacity: 1,
    pointerEvents: 'none',
    cursor: 'default',
    position: isFixedTarget ? 'fixed' : 'absolute',
    top: top + 'px',
    transition: 'opacity 0.2s',
    width: Math.round(elementRect.width + spotlightPadding * 2) + 'px',
    backgroundColor: 'gray',
    borderRadius: '4px',
  };
};

export const toggleHighlightElements = (elements = []) => {
  Array.from(document.getElementsByClassName('extra-highlights')).forEach(
    (el) => el?.remove()
  );
  elements.forEach((selector) => {
    document.querySelectorAll(selector).forEach((el) => {
      let styles = spotlightStyles(el);
      let elem = document.createElement('div');
      elem.setAttribute('class', 'react-joyride__spotlight extra-highlights');
      Object.keys(styles).forEach((property) => {
        elem.style[property] = styles[property];
      });
      document.querySelector('.react-joyride__overlay')?.appendChild(elem);
    });
  });
};
