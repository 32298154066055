import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useLazyQuery, useQuery } from '../hooks';
import { GET_CALENDAR_EVENTS, GET_ENROLLMENT_CALENDAR_EVENTS, GET_AVAILABLE_EVENTS } from './queries';
export const useGetCalendarEvents = function (courseEnrollmentId, startDate, endDate, modality) {
  let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  return useQuery(GET_CALENDAR_EVENTS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId,
      startDate,
      endDate,
      modality
    },
    skip: !courseEnrollmentId || !startDate || !endDate || !modality
  }));
};
export const useGetEnrollmentCalendar = _ref => {
  let {
    courseEnrollmentId,
    date,
    eventCategories,
    onCompleted,
    options = {}
  } = _ref;
  const [query, {
    data,
    error,
    loading
  }] = useLazyQuery(GET_ENROLLMENT_CALENDAR_EVENTS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId,
      date,
      eventCategories
    },
    onCompleted,
    skip: !courseEnrollmentId || !date || !eventCategories,
    fetchPolicy: 'network-only'
  }));
  return {
    query,
    data,
    error,
    loading
  };
};
export const useGetAvailableEvents = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useQuery(GET_AVAILABLE_EVENTS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId
    },
    skip: !courseEnrollmentId
  }));
};