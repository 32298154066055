import { DateTime, Interval } from 'luxon';

interface LegalAgeComparatorSettings {
  dateFormat?: string;
  legalAge?: number;
}

export const legalAgeComparator = (
  birthDate: string | undefined,
  settings: LegalAgeComparatorSettings = {}
): boolean => {
  const { dateFormat = 'dd/MM/yyyy', legalAge = 18 } = settings;

  return (
    !!birthDate &&
    Interval.fromDateTimes(
      DateTime.fromFormat(birthDate, dateFormat),
      DateTime.local()
    ).length('years') >= legalAge
  );
};

export const includesValues = <T>(origin: T[], contain: T[]): boolean => {
  return origin.some((r) => contain.indexOf(r) >= 0);
};

export const matchPathRegex = (
  path: string,
  regex: RegExp
): string[] | null => {
  if (typeof path !== 'string' || !(regex instanceof RegExp)) {
    throw new Error(
      'Os argumentos passados para matchPathRegex são inválidos.'
    );
  }
  return path.match(regex);
};
