import { useTheme } from '@emotion/react';
import { Typography } from '@sofia/ui';
import React from 'react';
import { ListItemProps } from './reenrollment-period-modal.types';

const ListItem: React.FC<ListItemProps> = ({ text }: ListItemProps) => {
  const theme = useTheme();

  return (
    <li>
      <Typography color={theme.colors.text.black}>{text}</Typography>
    </li>
  );
};

export default ListItem;
