import { ConversationInfo } from '../../types';
import { State } from './historic-section.hook';

export const filtersLabels = {
  ALL: 'Todas as mensagens:',
  STUDENT: 'Minhas mensagens',
  TUTOR: 'Mensagens do tutor',
  NOT_READ: 'Apenas não lidas',
};

export const initialState: State = {
  activeFilterChip: 'ALL',
  activeFilterTab: 'TUTOR',
};

export const emptyStudentConversationMock: ConversationInfo = {
  id: '1',
  MECCode: '00000000',
  personalDocument: '00000000000',
  preview:
    'Esse cantinho é todo seu para perguntas e dúvidas ao tutor. Estou aqui para guiar e transformar essa tela em branco em conversas que vão fazer seu aprendizado decolar!',
  agent: 'STUDENT',
  status: 'RESPONDIDA',
  readStatus: 'READ',
  class: 'Você ainda não tem mensagens enviadas!',
  conversationType: 'QUESTION',
  closureRatingEvaluation: null,
};

export const emptyTutorConversationMock: ConversationInfo = {
  id: '1',
  MECCode: '00000000',
  personalDocument: '00000000000',
  preview:
    'Você ainda não recebeu nenhuma mensagem do seu tutor. Fique tranquilo, assim que seu tutor enviar informações relevantes para seu progresso no curso, elas aparecerão aqui.',
  agent: 'STUDENT',
  status: 'RESPONDIDA',
  readStatus: 'READ',
  class: 'Você ainda não tem mensagens recebidas!',
  conversationType: 'QUESTION',
  closureRatingEvaluation: null,
};
