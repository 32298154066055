import React from 'react';

import { ButtonPrimaryLong, Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { useHistoricConversations } from '../../hooks/use-historic-conversations';
import { useTutorConversations } from '../../hooks/use-tutor-conversations';
import { useHomeConversations } from '../../hooks/use-home-conversations';
import { ListWrapperCore } from '../../../../../../../shared/components/section-right-frame/section-right-frame.styles';
import { usePtkFrame } from '../../contexts';
import { useFrame } from '../../../../shared';

import * as Styled from './blocked-section.styles';

const BlockedSection: React.FC = () => {
  const theme = useTheme();

  const { dispatch, onClose } = usePtkFrame();
  const { scrollType } = useFrame();

  const { data: historic } = useHistoricConversations();
  const { data: tutor } = useTutorConversations();
  const { data: home } = useHomeConversations();

  const hasHistoric = React.useMemo(
    () => Boolean(historic?.content?.length),
    [historic?.content?.length]
  );

  const hasHomeList = React.useMemo(
    () => Boolean(home?.content?.length),
    [home?.content?.length]
  );

  const hasTutorList = React.useMemo(
    () => Boolean(tutor?.content?.length),
    [tutor?.content?.length]
  );

  const { title, onClick } = React.useMemo(
    () => ({
      title:
        hasHistoric || hasHomeList || hasTutorList
          ? 'Ver minhas mensagens com tutor'
          : 'Entendi',
      onClick:
        hasHistoric || hasHomeList || hasTutorList
          ? () =>
              dispatch({
                page: !hasHomeList || !hasTutorList ? 'HISTORIC' : 'HOME',
              })
          : onClose,
    }),
    [dispatch, hasHistoric, hasTutorList, hasHomeList, onClose]
  );

  return (
    <ListWrapperCore scroll={scrollType}>
      <Styled.BlockedIllustration />

      <Typography textAlign="center" weight={700} size={24} margin="16px 0px">
        Não é possível mandar mensagem para o tutor
      </Typography>

      <Typography textAlign="center" size={16} margin="8px 16px">
        Sua matrícula não está mais ativa. Isso traz algumas mudanças no acesso,
        incluindo a interrupção do contato com o tutor durante esse período.
      </Typography>

      <ButtonPrimaryLong
        type="button"
        color={theme.colors.text.white}
        background={theme.colors.button.primary}
        margin="24px 0px 0px"
        onClick={onClick}
      >
        <Typography size={16} space="nowrap">
          {title}
        </Typography>
      </ButtonPrimaryLong>
    </ListWrapperCore>
  );
};

export default BlockedSection;
