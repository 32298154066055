import styled from '@emotion/styled';

export const Input = styled.input`
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #d6e1eb;
  font-size: 1rem;
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 8%);
  padding: 1.5vh 1.2vw;
  width: 100%;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: 2px solid #018296;
  }
`;
