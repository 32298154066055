import { default as routePathPayment } from '../modules/payment/routes/routes-path';

const routePath = {
  login: '/login',
  loginPassword: '/login/senha',
  loginValidateAccount: '/login/validar-conta',
  loginChooseType: '/login/escolha-cod',
  loginNewPassword: '/login/crie-uma-senha',
  loginDynamics: '/login/sso-dynamics',
  talkToUs: '/login/fale-conosco',
  profile: '/perfil',
  security: '/seguranca',
  candidate: '/login/candidate',
  communicationPreferences: '/preferencias-de-comunicacao',
  legacy: '/login/legado',
  courseInformation: '/informacoes-do-curso',

  logout: '/logout',
  onboardingWelcome: '/boas-vindas',
  onboardingCompromise: '/conte-com-a-gente',
  onboardingDocuments: '/envio-de-documentos',
  mobileWatsonChatbot: '/watson-chatbot',
  courseSelection: '/meus-cursos',
  home: '/:courseType/:courseVersionId',
  tutor: '/:courseType/:courseVersionId/tutor',
  student: '/:courseType/:courseVersionId/aluno',
  aco: '/:courseType/:courseVersionId/atividades-complementares',
  donwloadCoupon:
    '/:courseType/:courseVersionId/baixar-comprovante-cupom/:base64',
  courseCancellation: '/:courseType/:courseVersionId/cancelamento',
  courseSuspensionCancellation:
    '/:courseType/:courseVersionId/trancamento-e-cancelamento',
  academicCalendar: '/:courseType/:courseVersionId/calendario-academico',
  syllabus: '/:courseType/:courseVersionId/minhas-disciplinas',
  syllabusDocument:
    '/:courseType/:courseVersionId/minhas-disciplinas/matriz-curricular',

  subject: '/:courseType/:courseVersionId/enrollment/:subjectEnrollmentId',

  subjectPresentation:
    '/:courseType/:courseVersionId/enrollment/:subjectEnrollmentId/apresentacao',

  electiveSubjectPresentation:
    '/:courseType/:courseVersionId/escolha-de-disciplina/:subjectEnrollmentId/apresentacao/:subjectPath',
  electiveSubjectChoices:
    '/:courseType/:courseVersionId/escolha-de-disciplina/:subjectEnrollmentId',
  electiveSubjectOnboarding:
    '/:courseType/:courseVersionId/escolha-de-disciplina/:subjectEnrollmentId/orientacoes',

  exam: '/:courseType/:courseVersionId/prova/:subjectEnrollmentId',
  takeExam:
    '/:courseType/:courseVersionId/prova/:subjectEnrollmentId/em-andamento/:examId',
  examInAnalysis:
    '/:courseType/:courseVersionId/prova/:subjectEnrollmentId/em-analise/:examId',
  examResult:
    '/:courseType/:courseVersionId/prova/:subjectEnrollmentId/resultado/:examId',
  examCorrection:
    '/:courseType/:courseVersionId/prova/:examId/correcao/:attemptId',

  takeQuiz:
    '/:courseType/:courseVersionId/exercicio/:subjectEnrollmentId/:learningUnitEnrollmentId/em-andamento/:quizId',
  quizInAnalysis:
    '/:courseType/:courseVersionId/exercicio/:subjectEnrollmentId/:learningUnitEnrollmentId/em-analise/:quizId',
  quiz: '/:courseType/:courseVersionId/exercicio/:subjectEnrollmentId/:learningUnitEnrollmentId',
  resultsAttemptsQuizPage:
    '/:courseType/:courseVersionId/exercicio/:subjectEnrollmentId/:learningUnitEnrollmentId/resultado-tentativas/:quizId',
  quizCorrection:
    '/:courseType/:courseVersionId/exercicio/:quizId/correcao/:attemptId/:subjectEnrollmentId',

  //Payment
  payment: routePathPayment,

  grade: '/:courseType/:courseVersionId/notas-e-correcoes',
  subjectGrade:
    '/:courseType/:courseVersionId/notas-e-correcoes/:subjectEnrollmentId',

  secretaryStudent: '/:courseType/:courseVersionId/aluno-secretaria',
  secretaryProgramaticContent:
    '/:courseType/:courseVersionId/aluno-secretaria/historico-de-solicitações',
  secretaryStudentRegistration:
    '/:courseType/:courseVersionId/aluno-secretaria/comprovante/:typeOfStatus',
  studentCard: '/:courseType/:courseVersionId/aluno-secretaria/carteirinha',
  secretarySendingDocuments:
    '/:courseType/:courseVersionId/aluno-secretaria/envio-documentos',
  secretarySendingDocumentsUpload:
    '/:courseType/:courseVersionId/aluno-secretaria/envio-documentos/formulario',
  updateDataPage:
    '/:courseType/:courseVersionId/aluno-secretaria/atualizar-dados',
  secretaryStudentRequestHistory:
    '/:courseType/:courseVersionId/aluno-secretaria/historico-de-solicitacoes',
  secretaryInternalTransfer:
    '/:courseType/:courseVersionId/aluno-secretaria/troca-de-curso-ou-polo',
  termsAndContracts:
    '/:courseType/:courseVersionId/aluno-secretaria/termos-e-contratos',
  termsAndContractsDetails:
    '/:courseType/:courseVersionId/aluno-secretaria/termos-e-contratos/:contractId',
  requestDetails:
    '/:courseType/:courseVersionId/aluno-secretaria/historico-de-solicitacoes/:requestId',
  academicTranscript:
    '/:courseType/:courseVersionId/aluno-secretaria/historico-escolar',
  /* INTERNSHIP */
  mandatoryInternshipPage:
    '/:courseType/:courseVersionId/estagio/:subjectEnrollmentId',
  mandatoryInternshipCompanyInputPage:
    '/:courseType/:courseVersionId/estagio/:subjectEnrollmentId/vinculo',
  mandatoryInternshipConfirmCompanyPage:
    '/:courseType/:courseVersionId/estagio/:subjectEnrollmentId/confirmar-dados',
  mandatoryInternshipInfoPage:
    '/:courseType/:courseVersionId/estagio/:subjectEnrollmentId/informacoes',
  mandatoryInternshipGuidePage:
    '/:courseType/:courseVersionId/estagio/:subjectEnrollmentId/guia',
  mandatoryInternshipSubjectPresentationPage:
    '/:courseType/:courseVersionId/estagio/:subjectEnrollmentId/apresentacao',
};

export default routePath;
