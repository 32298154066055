export const tabs = [
  {
    name: 'plan',
    label: 'Plano de Ensino',
  },
  {
    name: 'teacher',
    label: 'Professor(a)',
  },
];
