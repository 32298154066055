import React, { useRef, useState } from 'react';
import { string, object } from 'prop-types';

import * as Accessibility from '../../accessibility';
import * as Style from './card-styles';
import CardModal from '../card-modal/card-modal';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

function Card({
  id,
  name,
  endHour,
  startHour,
  description,
  redirectLink,
  type,
  subType,
  info,
  startDate,
  endDate,
  infoType,
  address,
  redirect,
  timer,
  eventDate,
}) {
  let typeColor = '';

  switch (type) {
    case 'ACADEMIC_DATES':
      typeColor = '#0047FF';
      break;
    case 'SECRETARIAT':
      typeColor = '#8000FF';
      break;
    case 'FINANCIAL':
      typeColor = '#FF7A00';
      break;
    case 'UNIQUE_EVENTS':
      typeColor = '#BC00CC';
      break;
  }

  const theme = useTheme();
  const today = new Date().toISOString().split('T')[0];
  const CardModalRef = useRef(null);
  const [openCardModal, setOpenCardModal] = useState(false);

  const subTypesDictionary = {
    STUDY_PERIOD: 'Período de aulas',
    EXAM_PERIOD: 'Período de provas',
    PRESENTIAL_EXAM: 'Prova presencial',
    PRESENTIAL_CLASS: 'Aula presencial',
    WEEKLY_ENCOUNTER: 'Encontro semanal',
    RE_ENROLLMENT: 'Rematrícula',
    PAYMENT: 'Pagamento',
    LIVE_CLASS: 'Aula ao vivo',
    ACADEMIC_HOLIDAYS: 'Férias',
    HOLIDAY: 'Feriado',
  };

  const startDateLabelFilter = [
    'STUDY_PERIOD',
    'EXAM_PERIOD',
    'RE_ENROLLMENT',
    'ACADEMIC_HOLIDAYS',
  ];

  const witchType = subTypesDictionary[subType];

  const handleCardModal = () => {
    if (witchType === 'Feriado') {
      return;
    }

    CardModalRef.current?.onOpen();

    setOpenCardModal(!openCardModal);
  };

  const getTag = () => {
    if (startDate === eventDate && startDateLabelFilter.includes(subType)) {
      return <Style.Tag tagType={type}>Inicio</Style.Tag>;
    } else if (endDate === eventDate && startDate) {
      return <Style.Tag tagType={type}>Fim</Style.Tag>;
    }
  };

  const showEmptyDay = () => {
    if (eventDate === today && !name) {
      return (
        <Typography as="span" color={theme.colors.text.lighterBlack}>
          Nenhum evento no dia de hoje.
        </Typography>
      );
    }
  };

  return (
    <>
      <Style.Container
        isHoliday={witchType === 'Feriado'}
        isEmptyToday={eventDate === today && !name}
        id={id}
        data-testid={id}
        typeColor={typeColor}
        tabIndex={0}
        aria-label={Accessibility.TextCard(
          name,
          startHour,
          endHour,
          description,
          redirectLink,
          type,
          subType,
          info,
          startDate,
          endDate
        )}
        onClick={() => handleCardModal()}
      >
        {subType ? (
          <Style.Badge type={witchType} aria-hidden="true">
            {getTag()}

            {witchType === 'Feriado' ? (
              <Typography margin="8px 0">{`${witchType} - ${name}.`}</Typography>
            ) : (
              <>
                <p>{witchType}</p>
                <Style.Temporizer
                  type={witchType}
                  id={`temporizador-name-${id}`}
                  aria-hidden="true"
                >
                  {timer}
                </Style.Temporizer>
              </>
            )}
          </Style.Badge>
        ) : (
          showEmptyDay()
        )}

        {name && witchType !== 'Feriado' ? (
          <Style.ContaierTitle aria-hidden="true">
            <h1 id={`title-name-${id}`}>{name}</h1>
          </Style.ContaierTitle>
        ) : null}
      </Style.Container>

      <CardModal
        redirect={redirect}
        infoType={infoType}
        startDate={startDate}
        endDate={endDate}
        description={description}
        name={name}
        address={address}
        color={typeColor}
        ref={CardModalRef}
        message={info}
        timer={timer}
        subType={subType}
        subTypesDictionary={subTypesDictionary}
      />
    </>
  );
}

Card.propTypes = {
  id: string,
  badge: string,
  name: string,
  endHour: string,
  startHour: string,
  description: string,
  redirectLink: string,
  type: string,
  subType: string,
  info: string,
  infoType: string,
  endDate: string,
  startDate: string,
  redirect: object,
  address: string,
  timer: string,
  eventDate: string,
};

export default Card;
