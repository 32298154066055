import React from 'react';
import { useMeContext } from '../../../contexts/me-context';
import { useHandleTrack } from '../../../..';
import EduAvatar from '../../../../../assets/avatar-edu-onboarding-aprendizagem.png';
import { NewFeatureProps } from '../../../../communication/types/onboarding';
import {
  onboardingIds,
  SECTION_CHAT_COPILOT_VIEWED_DESCRIPTION,
  SECTION_CHAT_COPILOT_VIEWED,
} from '../../../utils/communication-constants';
import TourStep from '../tour-step';
import { useRolesContext } from '../../../contexts/communication-roles-context';
import * as Styles from './new-chat-copilot.styles';

export const useNewChatCopilotSectionTooltip = (): NewFeatureProps => {
  const { states } = useMeContext();

  const studentId = states?.getMe?.me?.personId;
  const { appendCommunicationOnTrack } = useHandleTrack({
    studentId: studentId,
    input: {
      key: SECTION_CHAT_COPILOT_VIEWED,
      desc: SECTION_CHAT_COPILOT_VIEWED_DESCRIPTION,
    },
  });

  const { communicationCapabilities } = useRolesContext();
  const showTooltip = communicationCapabilities.course.CHAT_COPILOT_ENABLED;

  const setNewProfileShown = () => {
    appendCommunicationOnTrack();
    localStorage.setItem('showedNewChatInsideSection', 'true');
  };

  const chatCopilotSection = showTooltip
    ? {
        id: onboardingIds.newFeature.id.newChatSection,
        steps: [
          {
            target: onboardingIds.newFeature.target.newChatSection,
            hideFooter: true,
            content: (
              <TourStep
                image={EduAvatar}
                imageStyles={Styles.EduAvatar}
                imageWidth="119"
                imageHeight="168"
                title={`Junto com você no seu
              momento de aprendizagem!`}
                titleStyles={Styles.Title}
                description={
                  <>
                    Você terá o meu apoio também enquanto estuda. A partir de
                    agora serei o intermediário entre você e o seu tutor. Para
                    enviar uma mensagem para o tutor basta selecionar a opção
                    ”Assuntos acadêmicos”.
                  </>
                }
              />
            ),
            styles: {
              tooltip: {
                padding: 24,
              },
              tooltipContent: {
                paddingTop: 0,
              },
            },
          },
        ],
        onEndTour: setNewProfileShown,
        onCloseTour: setNewProfileShown,
        hideTitle: true,
        hideCloseButton: false,
      }
    : null;

  return { Tour: chatCopilotSection };
};
