import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["requestAuthorization"];
var _getGlobal$navigator, _mediaDevices$getUser;
import { useEffect, useState } from 'react';
import { getGlobal } from '@ampli/utils';
const mediaDevices = (_getGlobal$navigator = getGlobal().navigator) === null || _getGlobal$navigator === void 0 ? void 0 : _getGlobal$navigator.mediaDevices;
const getUserMedia = mediaDevices === null || mediaDevices === void 0 || (_mediaDevices$getUser = mediaDevices.getUserMedia) === null || _mediaDevices$getUser === void 0 ? void 0 : _mediaDevices$getUser.bind(mediaDevices);
const useUserMedia = _ref => {
  let {
      requestAuthorization = false
    } = _ref,
    media = _objectWithoutProperties(_ref, _excluded);
  const [stream, setStream] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    if (requestAuthorization && getUserMedia && !stream && !error) {
      getUserMedia(media).then(setStream).catch(setError);
    } else if ((stream || error) && requestAuthorization === false) {
      setStream();
      setError();
    }
  }, [requestAuthorization, media, error, stream]);
  return [stream, error];
};
export default useUserMedia;