import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["onReady", "onTimeUpdate", "onLoadedData", "onBeforeRequest"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useEffect, useState } from 'react';
import { noop, isMSESupported } from '@ampli/utils';
import { useInitialFocusRef, useBreakpoint } from '@ampli/hooks';
import videojs from 'video.js';
// videojs plugins
// import 'videojs-markers';
import 'videojs-errors';
import 'videojs-errors/dist/videojs-errors.css';
import 'videojs-hotkeys';
export const useVideoJs = _ref => {
  let {
      onReady = noop,
      onTimeUpdate = noop,
      onLoadedData = noop,
      onBeforeRequest
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const [player, setPlayer] = useState();
  const [duration, setDuration] = useState();
  const [bufferedPercent, setBufferedPercent] = useState(0);
  const [paused, setPaused] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentTimePercent, setCurrentTimePercent] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [userActive, setUserActive] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [ready, setReady] = useState(false);
  const videoRef = useInitialFocusRef();
  const isDesktopOrFullscreen = isDesktop || isFullscreen;
  const isInteractive = paused || userActive;
  const isControllable = isInteractive && !waiting;
  const isOverlayVisible = !hasStarted || isInteractive || waiting;
  const isBigButtonVisible = isControllable || !hasStarted;
  const isControlBarVisible = hasStarted && isControllable;
  const isInfoVisible = isControlBarVisible && isDesktopOrFullscreen;
  const isMoreButtonVisible = !isDesktop && isControlBarVisible;
  useEffect(() => {
    if (isMSESupported()) {
      const vjsPlayer = videojs(videoRef.current, _objectSpread(_objectSpread({
        playbackRates: [0.5, 0.75, 1, 1.5, 2]
      }, props), {}, {
        controlBar: false
      }), function (e) {
        var _this$tech;
        this.hotkeys({
          volumeStep: 0.10001,
          // keep float math precision in last digit
          seekStep: 10,
          enableVolumeScroll: false
        });
        if (onBeforeRequest && (_this$tech = this.tech(true)) !== null && _this$tech !== void 0 && (_this$tech = _this$tech.vhs) !== null && _this$tech !== void 0 && _this$tech.xhr) {
          this.tech(true).vhs.xhr.beforeRequest = onBeforeRequest;
        }
        onReady(e, this);
      });
      vjsPlayer.on('loadeddata', function (e) {
        onLoadedData(e, this);
        setReady(true);
      });
      vjsPlayer.on(['pause', 'timeupdate'], function (e) {
        setBufferedPercent(this.bufferedPercent());
        setCurrentTime(this.currentTime());
        setCurrentTimePercent(this.currentTime() / this.duration());
        onTimeUpdate(e, this);
      });
      vjsPlayer.on(['pause', 'play'], function () {
        setPaused(this.paused());
      });
      vjsPlayer.one('play', function () {
        setDuration(this.duration());
        setHasStarted(true);
      });
      vjsPlayer.on('ratechange', function () {
        setPlaybackRate(this.playbackRate());
      });
      vjsPlayer.on(['useractive', 'userinactive'], function () {
        setUserActive(this.userActive());
      });
      vjsPlayer.on('volumechange', function () {
        if (this.volume() <= 0 && !this.muted()) {
          this.muted(true);
        } else if (this.volume() > 0 && this.muted()) {
          this.muted(false);
        }
        setVolume(this.volume());
        setMuted(this.muted());
      });
      vjsPlayer.on('fullscreenchange', function () {
        var _window$ScreenOrienta;
        const isFullscreen = this.isFullscreen();
        if (!isDesktop && (_window$ScreenOrienta = window.ScreenOrientation) !== null && _window$ScreenOrienta !== void 0 && _window$ScreenOrienta.lock) {
          if (isFullscreen) {
            window.ScreenOrientation.lock('landscape');
          } else {
            window.ScreenOrientation.unlock();
          }
        }
        setIsFullscreen(isFullscreen);
      });
      vjsPlayer.on('waiting', function () {
        setWaiting(true);

        // see: https://github.com/videojs/video.js/blob/v7.9.2/src/js/player.js#L1709-L1717
        const timeWhenWaiting = this.currentTime();
        const timeUpdateListener = () => {
          if (timeWhenWaiting !== this.currentTime()) {
            setWaiting(false);
            this.off('timeupdate', timeUpdateListener);
          }
        };
        this.on('timeupdate', timeUpdateListener);
      });
      vjsPlayer.on(['playing', 'canplay', 'canplaythrough'], function () {
        setWaiting(false);
      });
      setPlayer(vjsPlayer);
      return () => vjsPlayer.dispose();
    }
  }, []);
  useEffect(() => {
    if (player && !isDesktop) {
      player.volume(1);
    }
  }, [player, isDesktop]);
  return [videoRef, player, {
    bufferedPercent,
    duration,
    paused,
    currentTime,
    currentTimePercent,
    playbackRate,
    volume,
    muted,
    isFullscreen,
    hasStarted,
    userActive,
    waiting,
    ready,
    visibility: {
      overlay: isOverlayVisible,
      bigButton: isBigButtonVisible,
      info: isInfoVisible,
      moreButton: isMoreButtonVisible,
      controlBar: isControlBarVisible
    }
  }];
};