import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW, convertPXToREM } from '../../utils';
export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BreadcrumbWrapper = styled('div', {
  shouldForwardProp: propName => propName !== 'secondColor' && propName !== 'transform' && propName !== 'firstColor' && propName !== 'shadow'
})`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  p {
    margin: 0;
  }

  a {
    color: ${_ref => {
  let {
    secondColor
  } = _ref;
  return secondColor;
}};
  }

  svg {
    ${_ref2 => {
  let {
    transform
  } = _ref2;
  return transform ? 'transform: rotate(180deg);' : '';
}}
    margin: 0 ${convertPXToVW(22)};
  }

  #iconWrapper {
    svg {
      margin: 0 ${convertPXToVW(14)} 0 0px;
    }
  }

  & p:first-of-type {
    color: ${_ref3 => {
  let {
    firstColor
  } = _ref3;
  return firstColor;
}};
    font-size: ${convertPXToREM(16)};
    margin: 0 ${convertPXToVW(4)};
    position: relative;
  }

  & p:last-of-type {
    color: ${_ref4 => {
  let {
    firstColor
  } = _ref4;
  return firstColor;
}};
    text-decoration: none;
    font-weight: 700;
  }

  @media (max-width: 930px) {
    padding: ${convertPXToVH(16)} ${convertPXToVW(24)} ${convertPXToVH(16)} 0;
    &:not(last-of-type) {
      padding-right: ${convertPXToVW(44)};
    }
  }

  ${_ref5 => {
  let {
    shadow
  } = _ref5;
  return shadow ? `
      &:child {
        filter: drop-shadow(0 0 4px #000);
      }
    ` : '';
}}
`;