import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaQuery, convertPXToREM } from '@sofia/ui';

const LiveAnimation = ({ bgcolor }) => keyframes`
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(3, 3);
    background-color: ${bgcolor};
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  margin-bottom: auto;
  margin-right: 8px;
  svg path {
    stroke: ${({ stroke }) => stroke};
  }
`;

export const Title = styled.span`
  font-size: ${convertPXToREM(16)};
  line-height: 150%;
  color: ${({ color }) => color};

  ${mediaQuery('desktop')} {
    font-size: ${convertPXToREM(20)};
    line-height: 140%;
  }
`;

export const Desc = styled.span`
  margin-top: 8px;
  color: ${({ color }) => color};
  font-size: ${convertPXToREM(16)};
  line-height: 150%;

  @media (min-width: 1012px) {
    white-space: nowrap;
  }
`;

export const NextActivityWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  white-space: nowrap;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  > p:first-of-type {
    margin-right: 16px;
  }

  ${mediaQuery('desktop')} {
    margin-top: 0px;
    flex-direction: column;
    justify-content: flex-end;

    > p:first-of-type {
      margin-right: 0px;
      margin-bottom: 8px;
    }
  }
`;

export const RecordIcon = styled.div`
  display: inline-block;
  position: relative;
  top: calc(50% - 5px);
  background-color: ${({ bgcolor }) => bgcolor};
  width: 8px;
  height: 8px;
  margin: 8px;
  border: 1px solid #00000010;
  border-radius: 50%;
  z-index: 1;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: ${({ bgcolor }) => bgcolor}60;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: ${({ bgcolor }) => LiveAnimation(bgcolor)} 2s ease-in-out
      infinite;
    z-index: -1;
  }
`;
