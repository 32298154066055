import React from 'react';
import { useModal, Typography } from '@sofia/ui';
import { ModalInterface, PopUp, useBreakpoint } from '../../../../modules';

interface PopUpInvalidFrequencyProps {
  content?: string;
}

const PopUpInvalidFrequency = React.forwardRef(
  ({ content }: PopUpInvalidFrequencyProps, ref: React.Ref<ModalInterface>) => {
    const modal = useModal();
    const { isDesktop } = useBreakpoint();

    const handleShowModal = () => modal.show();
    const handleHideModal = () => modal.hide();

    React.useImperativeHandle(ref, () => ({
      onOpen() {
        handleShowModal();
      },
      onClose() {
        handleHideModal();
      },
    }));

    return (
      <PopUp
        ariaLabel="Assista as aulas incompletas"
        headerText="Assista as aulas incompletas"
        footerText="Voltar aos estudos"
        isDesktop={isDesktop}
        popUpState={modal}
        width={456}
      >
        <>{content ? <Typography size="16">{content}</Typography> : null}</>
      </PopUp>
    );
  }
);

PopUpInvalidFrequency.displayName = 'PopUpInvalidFrequency';

export default PopUpInvalidFrequency;
