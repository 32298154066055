const duration = 0.5;
const max_messages = 5;
const close = elem => {
  elem.className = 'business-error';
  setTimeout(() => {
    elem.remove();
  }, duration * 1000);
};
const createCloseButton = elem => {
  const closeBtn = document.createElement('a');
  closeBtn.className = 'close-button';
  closeBtn.appendChild(document.createTextNode('X'));
  closeBtn.onclick = () => {
    close(elem);
  };
  elem.appendChild(closeBtn);
};
const createMessage = (elem, message) => {
  const container = document.createElement('div');
  container.className = 'message';
  container.appendChild(document.createTextNode(message));
  elem.appendChild(container);
};
const restrain = wrapper => {
  if (wrapper.children.length < max_messages) {
    return;
  }
  const overflow = wrapper.children.length - max_messages + 1;
  const toRemove = [];
  for (let i = 0; i < overflow; i++) {
    toRemove.push(wrapper.children[i]);
  }
  toRemove.forEach(value => close(value));
};
export const createCss = () => {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = '.business-error { background-color: red; color: white; padding: 20px 40px; border-radius: 5px; font-weight: bold; margin-bottom: 10px; opacity: 0; position: relative; } \n' + `.business-error { -webkit-transition: opacity ${duration}s ease-in; -moz-transition: opacity ${duration}s ease-in; -ms-transition: opacity ${duration}s ease-in; -o-transition: opacity ${duration}s ease-in; transition: opacity ${duration}s ease-in; } \n` + '.business-error-wrapper { position: fixed; bottom: 0; left: 50%; transform: translate(-50%, -0%); z-index: 9999; } \n' + '.business-error-loaded { opacity: 1;} \n' + '.business-error > .close-button { position: absolute; top: 0px; right: 2px; font-size: 13px; padding: 5px;} \n';
  document.getElementsByTagName('head')[0].appendChild(style);
  const container = document.createElement('div');
  container.className = 'business-error-wrapper';
  document.getElementsByTagName('body')[0].appendChild(container);
};
export const showError = message => {
  if (window.errorNotifier) {
    window.errorNotifier(message);
    return;
  }
  try {
    const container = document.createElement('div');
    container.className = 'business-error';
    createCloseButton(container);
    createMessage(container, message);
    const wrapper = document.getElementsByClassName('business-error-wrapper')[0];
    restrain(wrapper);
    wrapper.appendChild(container);
    setTimeout(() => {
      container.className += ' business-error-loaded';
      setTimeout(() => {
        close(container);
      }, 3000);
    }, 100);
  } catch (e) {
    console.error(e);
    alert(message);
  }
};