import { useTheme } from '@emotion/react';
import { useBreakpoint } from '../../hooks';
import { useGetState } from '../../../../state';
import { IReadingRulerLogic } from './types';
import { useMeContext } from '../../contexts/me-context';
import { useHandleTrack } from '../section';
import {
  READING_RULER_COMMUNICATION_COMPLETED_DESC,
  READING_RULER_COMMUNICATION_COMPLETED_KEY,
  SECTION_EDU_COMMUNICATION_COMPLETED_KEY,
} from '../../../../constants';
import { useEffect, useState } from 'react';
import { useCourseEnrollmentsContext } from '../../contexts/course-enrollments-context';
import { useRolesContext } from '../../contexts/communication-roles-context';
import { SECTION_CHAT_COPILOT_VIEWED } from '../../utils';
import { pushEvent } from '../../../../lib/ga';

const useReadingRulerBtnLogic = (): IReadingRulerLogic => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();
  const [commsAnchorEl, setComms] = useState<
    HTMLButtonElement | undefined | null
  >();
  const [
    { hasShownSectionEduCommunication, isReadingRulerEnabled },
    { setReadingRuler },
  ] = useGetState();
  const btnColor = isReadingRulerEnabled
    ? theme.colors.button.primary
    : theme.colors.text.light;

  const { states } = useMeContext();
  const { track, loading, withoutTrack, appendCommunicationOnTrack } =
    useHandleTrack({
      studentId: states?.getMe?.me?.personId,
      input: {
        key: READING_RULER_COMMUNICATION_COMPLETED_KEY,
        desc: READING_RULER_COMMUNICATION_COMPLETED_DESC,
      },
    });

  const onRulerClick = () => {
    pushEvent(
      'event',
      'reading_ruler',
      isReadingRulerEnabled ? 'disable' : 'enable'
    );
    setReadingRuler(!isReadingRulerEnabled);
  };

  const onRulerClose = () => {
    setComms(null);
    appendCommunicationOnTrack();
  };

  /* COMMUNICATION EDU TOUR CHECK */
  const { communicationCapabilities } = useRolesContext();
  const showTooltip = communicationCapabilities.course.CHAT_COPILOT_ENABLED;
  const commmunicationEduTrack =
    track?.inputs?.length > 0 && !loading
      ? track.inputs.find(
          ({ key }: { key: string }) => key === SECTION_CHAT_COPILOT_VIEWED
        )
      : null;

  /* LEARNING EDU TOUR CHECK */
  const {
    states: {
      courseEnrollments: { data, loading: loadingEnrollment },
    },
  } = useCourseEnrollmentsContext();
  const hasLearningEduRole =
    !loadingEnrollment && data?.roles?.includes('CONTENT_EDU_ENABLED');
  const learningEduTrack =
    track?.inputs?.length > 0 && !loading
      ? track.inputs.find(
          ({ key }: { key: string }) =>
            key === SECTION_EDU_COMMUNICATION_COMPLETED_KEY
        )
      : null;

  /* RULER CONFIG ORDER OF COMMUNICATION PRESENTATION */
  const [canShowCommunication, setShowCommunication] = useState<boolean>(false);
  useEffect(() => {
    if (
      (showTooltip &&
        (commmunicationEduTrack?.value === 'TRUE' ||
          localStorage.getItem('showedNewChatInsideSection') === 'true')) ||
      (hasLearningEduRole &&
        (hasShownSectionEduCommunication ||
          learningEduTrack?.value === 'TRUE')) ||
      (!showTooltip && !hasLearningEduRole && !canShowCommunication)
    ) {
      setShowCommunication(true);
    }
  }, [
    canShowCommunication,
    commmunicationEduTrack?.value,
    hasLearningEduRole,
    hasShownSectionEduCommunication,
    learningEduTrack?.value,
    showTooltip,
  ]);

  return {
    theme,
    isDesktop,
    onRulerClick,
    onRulerClose,
    btnColor,
    commsAnchorEl,
    setComms,
    shouldShowComms:
      Boolean(commsAnchorEl && withoutTrack) && canShowCommunication,
  };
};

export default useReadingRulerBtnLogic;
