import { lazy } from 'react';

export const SectionProvider = lazy(
  () =>
    import(
      /* webpackChunkName: "Section page with provider" */ '../pages/learning-object/section-provider'
    )
);

const SectionModule = lazy(
  () => import(/* webpackChunkName: "wisdom" */ './module')
);
export default SectionModule;
