import React from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { EvaluateConversation } from '../../types';
import { useDynamicsLogic } from '../../hooks/use-dynamics-logic';
import { usePtkFrame } from '../../contexts';

type State = {
  showInputButton: boolean;
  buttonDisabled: boolean;
  inputDisabled: boolean;
  comment: string;
  rating: number | null;
};

type UseEvaluation = State & {
  commentEvaluation: (values: EvaluateConversation) => Promise<Response>;
  ratingEvaluation: (values: EvaluateConversation) => Promise<Response>;
  commentFinished: boolean;
  ratingFinished: boolean;
  dispatch: React.Dispatch<Partial<State>>;
};

const reducer: React.Reducer<State, Partial<State>> = (state, action) => ({
  ...state,
  ...action,
});

export const useEvaluation = (): UseEvaluation => {
  const [state, dispatch] = React.useReducer(reducer, {
    showInputButton: false,
    buttonDisabled: true,
    inputDisabled: true,
    comment: '',
    rating: null,
  });

  const queryClient = useQueryClient();

  const { ratingEvaluation, commentEvaluation } = useDynamicsLogic();
  const { queryKeys } = usePtkFrame();

  const ratingEvaluationMutation = useMutation(ratingEvaluation, {
    onSuccess: () =>
      dispatch({
        inputDisabled: false,
        buttonDisabled: false,
        showInputButton: true,
      }),
  });

  const commentEvaluationMutation = useMutation(commentEvaluation, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.historic);
      queryClient.invalidateQueries(queryKeys.chat);
      dispatch({
        buttonDisabled: true,
        inputDisabled: true,
        comment: '',
      });

      setTimeout(() => {
        document.getElementById('evaluation')?.classList.add('leaving');
      }, 1000);

      setTimeout(() => {
        dispatch({
          showInputButton: false,
        });
      }, 1300);
    },
  });

  return {
    ...state,
    ratingEvaluation: ratingEvaluationMutation.mutateAsync,
    commentEvaluation: commentEvaluationMutation.mutateAsync,
    ratingFinished: ratingEvaluationMutation.isSuccess,
    commentFinished: commentEvaluationMutation.isSuccess,
    dispatch,
  };
};
