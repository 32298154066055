import { useEffect, useMemo, useRef } from 'react';
import {
  TDirection,
  TUseAutoScrollOnResizeProps,
  TUseAutoScrollOnResizeReturn,
} from './use-auto-scroll-on-resize.types';
import {
  scrollToBottom,
  scrollToLeft,
  scrollToRight,
  scrollToTop,
} from '../..';

const useAutoScrollOnResize = ({
  direction = 'bottom',
  isLoading = false,
}: TUseAutoScrollOnResizeProps): TUseAutoScrollOnResizeReturn => {
  const ref = useRef<HTMLDivElement | null>(null);

  const scrollFunctions: Record<TDirection, () => void> = useMemo(
    () => ({
      top: scrollToTop,
      bottom: scrollToBottom,
      left: scrollToLeft,
      right: scrollToRight,
    }),
    []
  );

  useEffect(() => {
    const element = ref.current;
    if (!element || isLoading) return;

    let previousSize = {
      width: element.offsetWidth,
      height: element.offsetHeight,
    };

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        if (width > previousSize.width || height > previousSize.height) {
          scrollFunctions[direction]();
        }

        previousSize = { width, height };
      }
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [direction, scrollFunctions, isLoading]);

  return { ref };
};

export default useAutoScrollOnResize;
