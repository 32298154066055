import styled from '@emotion/styled';

import { ButtonGhost } from '@sofia/ui';

export const BackButtonWrapper = styled(ButtonGhost)`
  display: flex;
  align-items: center;
  margin: 0px 0px 24px;
  padding: 4px;
`;
