import React from 'react';

import { CircularProgressBar, Typography } from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { string, bool } from 'prop-types';
import { useTheme } from '@emotion/react';

import { EntitiesLabels } from '../../../constants';
import { useGetState } from '../../../state';
import { Skeleton } from '../../skeleton';

import * as Styled from './syllabus-header.styles';

const SyllabusHeader = ({
  totalOfSubjectsCompleted,
  courseEnrollmentLoading,
  coursePercentage,
  totalOfSubjects,
  progressLoading,
  courseName,
}) => {
  const [{ selectedCourseEnrollmentType }] = useGetState();
  const entitiesLabelsDictionary = EntitiesLabels(selectedCourseEnrollmentType);

  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <Styled.Wrapper>
      <Styled.HeaderWrapper>
        <Typography
          margin="0 0 8px 0"
          color={theme.colors.text.black}
          size={isDesktop ? 48 : 32}
          weight={700}
          lineHeight="120%"
          id={`title-${entitiesLabelsDictionary.mySubjectsTitle.replaceAll(
            ' ',
            ''
          )}`}
          data-testid={`title-${entitiesLabelsDictionary.mySubjectsTitle.replaceAll(
            ' ',
            ''
          )}`}
        >
          {entitiesLabelsDictionary.mySubjectsTitle}
        </Typography>

        {courseEnrollmentLoading ? (
          <Styled.SkeletonHeaderWrapper>
            <Skeleton height={24} borderRadius={8} />
          </Styled.SkeletonHeaderWrapper>
        ) : (
          <Typography
            margin={0}
            color={theme.colors.text.black}
            size={isDesktop ? 20 : 16}
            weight={700}
            lineHeight="140%"
            id="courseName"
            data-testid="courseName"
          >
            {courseName}
          </Typography>
        )}
      </Styled.HeaderWrapper>

      {!progressLoading ? (
        <Styled.InfoWrapper>
          <CircularProgressBar
            id="progressPercent"
            data-testid="progressPercent"
            progress={parseInt(coursePercentage)}
            progressText={`${coursePercentage}%`}
          />

          <Styled.InfoText>
            <Typography
              id="courseProgress"
              data-testid="courseProgress"
              margin="0 0 8px 0"
              color={theme.colors.text.black}
            >
              Progresso no curso
            </Typography>

            <Typography
              id="progressNumbers"
              data-testid="progressNumbers"
              margin={0}
              color={theme.colors.text.black}
              weight={700}
            >
              {entitiesLabelsDictionary.finishedSubjects(
                totalOfSubjectsCompleted,
                totalOfSubjects
              )}
            </Typography>
          </Styled.InfoText>
        </Styled.InfoWrapper>
      ) : (
        <Styled.SkeletonInfoWrapper>
          <Skeleton height={80} borderRadius={8} />
        </Styled.SkeletonInfoWrapper>
      )}
    </Styled.Wrapper>
  );
};

SyllabusHeader.propTypes = {
  courseName: string.isRequired,
  totalOfSubjects: string.isRequired,
  totalOfSubjectsCompleted: string.isRequired,
  coursePercentage: string.isRequired,
  courseEnrollmentLoading: bool,
  progressLoading: bool,
};

export default SyllabusHeader;
