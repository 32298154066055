const checkSumOfCnpjNumbers = function (tamanho) {
  let cnpj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  let numeros = cnpj.substring(0, tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  return soma % 11 < 2 ? 0 : 11 - soma % 11;
};
const CNPJvalidator = function () {
  let cnpj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  const replacedCnpj = cnpj.replace(/[^\d]+/g, '');
  const invalidCNPJError = {
    val: false,
    message: 'CNPJ inválido'
  };
  if (!cnpj || replacedCnpj == '') return {
    val: false,
    message: 'CNPJ não pode ser vazio'
  };
  if (replacedCnpj.length != 14) return {
    val: false,
    message: 'CNPJ deve conter 14 dígitos'
  };

  // Elimina CNPJs invalidos conhecidos
  if ([...replacedCnpj].every(number => number == replacedCnpj[0])) return invalidCNPJError;

  // Valida DVs
  const tamanho = replacedCnpj.length - 2;
  const digitos = replacedCnpj.substring(tamanho);
  let resultado = checkSumOfCnpjNumbers(tamanho, replacedCnpj);
  if (resultado != digitos.charAt(0)) return invalidCNPJError;
  resultado = checkSumOfCnpjNumbers(tamanho + 1, replacedCnpj);
  if (resultado != digitos.charAt(1)) return invalidCNPJError;
  return {
    val: true,
    message: ''
  };
};
export default CNPJvalidator;