const useDraggableScroll = ref => {
  const position = {
    scrollLeft: 0,
    x: 0
  };
  const onMouseMove = event => {
    if (ref.current) {
      ref.current.scrollLeft = position.scrollLeft - (event.clientX - position.x);
    }
  };
  const onMouseUp = () => {
    // stops dragging when user stops holding the mouse click
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };
  const onMouseDown = event => {
    if (ref.current) {
      position.scrollLeft = ref.current.scrollLeft;
      position.x = event.clientX;
      ref.current.style.userSelect = 'none';
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    }
  };
  return {
    onMouseDown
  };
};
export default useDraggableScroll;