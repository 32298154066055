import { useParams } from 'react-router-dom';
import { useGetSubjectEnrollment } from '@ampli/services';
import useSharedPageData from './use-shared-page-data';

const useElectiveSubjectChoicesPageData = () => {
  const { subjectEnrollmentId } = useParams();
  const { loading: sharedPageLoading, ...sharedPageData } = useSharedPageData();

  const { data: subjectEnrollment, loading: subjectEnrollmentLoading } =
    useGetSubjectEnrollment(subjectEnrollmentId);

  const loading = sharedPageLoading || subjectEnrollmentLoading;

  return {
    ...sharedPageData,
    loading,
    subjectEnrollment,
  };
};

export default useElectiveSubjectChoicesPageData;
