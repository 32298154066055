import React from 'react';

function SvgDriverLicenseImage(props) {
  return (
    <svg viewBox="0 0 49 56" width={49} height={56} fill="none" {...props}>
      <path
        d="M14.627 8.711a1.09 1.09 0 011.334-.77l8.297 2.223c.581.156.926.753.77 1.334l-2.874 10.728a1.09 1.09 0 01-1.334.77l-8.297-2.223a1.09 1.09 0 01-.77-1.334l2.874-10.728z"
        fill="#fff"
      />
      <path
        d="M14.627 8.711a1.09 1.09 0 011.334-.77l8.297 2.223c.581.156.926.753.77 1.334l-2.874 10.728a1.09 1.09 0 01-1.334.77l-8.297-2.223a1.09 1.09 0 01-.77-1.334l2.874-10.728z"
        fill="#fff"
      />
      <path
        d="M18.232 17.603a3.96 3.96 0 101.593-7.76 3.96 3.96 0 00-1.593 7.76z"
        fill="#003D51"
      />
      <path
        d="M16.972 16.55s-.034 1.491-.331 1.612c-.298.12 1.27 2.669 1.27 2.669l.98-.572.61-1.646s-.56-1.251-.015-2.139c.545-.887-2.514.075-2.514.075z"
        fill="#9F616A"
      />
      <path
        d="M18.365 17.29a2.848 2.848 0 101.145-5.58 2.848 2.848 0 00-1.145 5.58z"
        fill="#9F616A"
      />
      <path
        d="M17.55 19.711s-.666-1.454-.985-2.064c-.584-.256-1.168-.286-1.526-.314-.358-.028-1.33-.14-2.09.022-.76.162-1.792 3.252-1.792 3.252s.04-.247.148 1.002c3.05 1.24 6.005 2.313 10.005 2.117.455-.225.822-1.246.822-1.246l-.213-2.145s-.021-1.003-.928-1.235c-.906-.231-1.625-.88-1.625-.88l-1.337 1.59-.48-.099z"
        fill="#FFB800"
      />
      <path
        d="M18.95 13.888c1.372.282 2.6-.056 2.744-.754.143-.698-.854-1.493-2.226-1.774-1.372-.282-2.601.056-2.744.754-.144.698.853 1.492 2.225 1.774z"
        fill="#003D51"
      />
      <path
        d="M14.394.282a1.09 1.09 0 00-1.334.77l-5.734 21.4a1.09 1.09 0 00.77 1.334l32.881 8.81a1.09 1.09 0 001.335-.77l5.733-21.4a1.09 1.09 0 00-.77-1.334L14.395.282zm1.567 7.659l8.297 2.223c.581.155.926.753.77 1.334l-2.874 10.728a1.09 1.09 0 01-1.334.77l-8.297-2.223a1.09 1.09 0 01-.77-1.334l2.874-10.728a1.09 1.09 0 011.334-.77z"
        fill="#99CAD2"
      />
      <path
        d="M45.693 10.673a.272.272 0 01.193.333l-5.155 19.238a.272.272 0 01-.333.193l-30.72-8.231a.272.272 0 01-.193-.334L14.64 2.634a.272.272 0 01.334-.192l30.72 8.23zm-30.508-9.02a1.09 1.09 0 00-1.334.77L8.696 21.66a1.09 1.09 0 00.77 1.334l30.72 8.231a1.09 1.09 0 001.334-.77l5.155-19.238a1.09 1.09 0 00-.77-1.334l-30.72-8.232z"
        fill="#fff"
      />
      <path
        d="M15.64 4.929a1.09 1.09 0 011.335-.77l26.127 7c.581.156.926.753.77 1.334l-.087.327a1.09 1.09 0 01-1.335.77l-26.127-7a1.09 1.09 0 01-.77-1.334l.088-.327z"
        fill="#000"
        fillOpacity={0.1}
      />
      <path
        d="M41.806 16.153a.504.504 0 01-.261.974l-14.049-3.764a.504.504 0 11.261-.974l14.049 3.764zm-13.908-4.29a1.049 1.049 0 00-.543 2.026l14.049 3.765a1.049 1.049 0 00.542-2.027l-14.048-3.764zM40.9 19.53a.504.504 0 11-.26.974L26.592 16.74a.504.504 0 11.26-.974l14.049 3.764zm-13.906-4.29a1.049 1.049 0 10-.543 2.026l14.048 3.765a1.049 1.049 0 00.543-2.027L26.994 15.24zM39.957 22.897c.29.078.463.376.385.667l-.74 2.758a.545.545 0 01-.666.386l-13.97-3.744a.545.545 0 01-.385-.667l.739-2.758a.545.545 0 01.667-.385l13.97 3.743zm-13.83-4.27a1.09 1.09 0 00-1.333.77l-.74 2.76a1.09 1.09 0 00.77 1.333l13.97 3.744a1.09 1.09 0 001.335-.77l.739-2.76a1.09 1.09 0 00-.77-1.333l-13.97-3.743z"
        fill="#000"
        fillOpacity={0.15}
      />
      <path
        d="M7.642 23.688a1 1 0 011.225-.707l32.29 8.652a1 1 0 01.708 1.225l-5.874 21.92a1 1 0 01-1.225.708l-32.29-8.653a1 1 0 01-.708-1.224l5.874-21.92z"
        fill="#99CAD2"
      />
      <path
        d="M8.401 25.003a1 1 0 011.225-.707l30.216 8.097a1 1 0 01.708 1.224l-5.318 19.847a1 1 0 01-1.225.707L3.79 46.074a1 1 0 01-.707-1.225l5.318-19.846z"
        fill="#99CAD2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.584 33.358L9.367 25.262 4.049 45.108l30.217 8.097 5.318-19.846zM9.626 24.297a1 1 0 00-1.225.707L3.083 44.85a1 1 0 00.707 1.225l30.217 8.097a1 1 0 001.225-.707l5.318-19.847a1 1 0 00-.708-1.224L9.626 24.296z"
        fill="#fff"
      />
      <path
        d="M36.19 35.312c.29.078.462.377.384.667l-3.326 12.415a.545.545 0 01-.667.385l-13.97-3.744a.545.545 0 01-.385-.667l3.326-12.414a.545.545 0 01.667-.385l13.97 3.743zm-13.83-4.27a1.09 1.09 0 00-1.334.771L17.7 44.227a1.09 1.09 0 00.77 1.335l13.97 3.743a1.09 1.09 0 001.334-.77L37.1 36.12a1.09 1.09 0 00-.77-1.334l-13.97-3.743z"
        fill="#000"
        fillOpacity={0.15}
      />
    </svg>
  );
}

export default SvgDriverLicenseImage;
