import { useGetState } from '../../../../../../state';
import useHandleTrack from '../use-handle-track';

const useHandleCopilotCommunication = ({
  studentId,
  input,
}: {
  studentId: string;
  input: {
    key: string;
    desc: string;
  };
}): {
  onCloseCommunication: () => void;
  canShowCommunication: boolean;
  loading: boolean;
} => {
  const [
    { hasShownSectionEduCommunication },
    { setHasShownSectionEduCommunication },
  ] = useGetState();

  const { loading, withoutTrack, appendCommunicationOnTrack } = useHandleTrack({
    studentId: studentId || '',
    input: {
      key: input.key,
      desc: input.desc,
    },
  });

  const canShowCommunication = withoutTrack && !hasShownSectionEduCommunication;

  return {
    onCloseCommunication: () => {
      if (canShowCommunication) {
        setHasShownSectionEduCommunication(true);
        appendCommunicationOnTrack();
      }
    },
    canShowCommunication,
    loading,
  };
};

export default useHandleCopilotCommunication;
