import React from 'react';
import { number, shape, func, bool, arrayOf } from 'prop-types';
import { Typography, Card, convertPXToVH } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { Legend, QuestionBullets } from '../bullets';

import * as Styled from './quiz-correction-navigation.styles';

const QuizCorrectionNavigation = ({
  numberOfQuestions,
  bulletQuestions,
  maxPunctuation,
  isResponsive,
  punctuation,
  toStep,
  step,
}) => {
  const theme = useTheme();
  const activeQuestion = React.useMemo(() => step + 1, [step]);

  return (
    <>
      <Card
        noPointer
        minHeight="auto"
        borderColor={theme.colors.card.border.primary}
        hoverBorderColor={theme.colors.card.border.primary}
        margin={
          isResponsive ? `${convertPXToVH(24)} 0` : `0 0 ${convertPXToVH(16)} `
        }
      >
        <Styled.PunctuationWrapper>
          <Typography weight={700}>Pontuação</Typography>
          <Typography weight={700}>
            {punctuation}/{maxPunctuation}
          </Typography>
        </Styled.PunctuationWrapper>
      </Card>

      <Card
        noPointer
        minHeight="auto"
        borderColor={theme.colors.card.border.primary}
        hoverBorderColor={theme.colors.card.border.primary}
        margin={`0 0 ${convertPXToVH(isResponsive ? 50 : 16)}`}
      >
        <Styled.CardWrapper>
          <Typography textAlign="center">
            Questões realizadas: <b>{activeQuestion}</b> de {numberOfQuestions}
          </Typography>

          <QuestionBullets
            complete
            active={step}
            onBulletClick={toStep}
            bulletQuestions={bulletQuestions}
          />

          <Styled.LegendsWrapper>
            <Legend dotActive color={theme.colors.button.success}>
              Acertou
            </Legend>
            <Legend dotActive color={theme.colors.button.danger}>
              Errou
            </Legend>
          </Styled.LegendsWrapper>
        </Styled.CardWrapper>
      </Card>
    </>
  );
};

QuizCorrectionNavigation.propTypes = {
  numberOfQuestions: number.isRequired,
  punctuation: number.isRequired,
  maxPunctuation: number.isRequired,
  isResponsive: bool.isRequired,
  step: number.isRequired,
  toStep: func.isRequired,
  bulletQuestions: arrayOf(
    shape({
      isCorrect: bool.isRequired,
      bullet: number.isRequired,
    })
  ).isRequired,
};
export default QuizCorrectionNavigation;
