import { formatRelative, format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const secondsToMinutes = (seconds: number): string =>
  new Date(seconds * 1000)
    .toLocaleDateString(navigator.language, {
      minute: '2-digit',
      second: '2-digit',
    })
    .substring(12, 20);

export const getDateAndTime = (value?: string | number | Date): string | null =>
  value
    ? format(parseISO(value.toString()), "dd/MM/yyyy 'às' hh:mm a", {
        locale: { ...ptBR },
      }).toString()
    : null;

const defaultFormater = 'dd/MM/yyyy';

const formatRelativeLocale = {
  today: 'hh:mm a',
  lastWeek: defaultFormater,
  yesterday: defaultFormater,
  tomorrow: defaultFormater,
  nextWeek: defaultFormater,
  other: defaultFormater,
};

export const getRelativeDate = (date?: string | number | Date): string | null =>
  date
    ? formatRelative(new Date(date), new Date(), {
        locale: {
          ...ptBR,
          formatRelative: (token: never) => formatRelativeLocale[token],
        },
      })
    : null;
