const ampliPalette = {
  primary: {
    700: '#009A98',
    600: '#01A4BB',
    500: '#01B6D0',
    400: '#00B7D1',
    300: '#09BBCD',
    200: '#4DCDDF',
    100: '#CCF0F6',
    A500: '#3BDBB8',
    A400: '#8CEFFE',
  },
  neutral: {
    900: '#212121',
    800: '#444444',
    700: '#495057',
    600: '#8E9294',
    500: '#868E96',
    400: '#ADB5BD',
    300: '#DEE2E6',
    200: '#F3F6F9',
    100: '#FFFFFF',
    A500: '#E5E5E5',
    A400: '#F5F7FB',
  },
  surface: {
    600: '#698AAE',
    500: '#B1C2D5',
    400: '#DEE2E6',
    300: '#F3F6F9',
    A500: '#5CAAC7',
    A400: '#7389FF',
  },
  feedback: {
    success: {
      600: '#3BA86B',
      500: '#47D474',
      400: '#91E5AC',
      300: '#D1F4DC',
    },
    warning: {
      500: '#FF7A00',
      400: '#FFAF66',
      300: '#FFEBD9',
      200: '#FFD399',
      100: '#FFE6C9',
    },
    error: {
      500: '#EC1C40',
      400: '#F68EA0',
      300: '#FAC6CF',
    },
  },
};

export default ampliPalette;
