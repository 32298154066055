import { useTheme } from '@emotion/react';
import { Tab, TabPanel, Tabs } from '@sofia/ui';
import { array, func } from 'prop-types';
import React from 'react';
import SyllabusListSubjects from '../syllabus-list-subjects';
import * as Styled from './syllabus-concluded-future-subjects.styles';

const SyllabusConcludedFutureSubjects = ({
  failed,
  passed,
  future,
  onSubjectClick,
}) => {
  const theme = useTheme();
  const tabsData = [
    {
      key: 'approved',
      label: 'Aprovadas',
      data: passed,
      type: 'approved',
      disabled: false,
    },
    {
      key: 'failed',
      label: 'Reprovadas',
      data: failed,
      type: 'failed',
      disabled: false,
    },
    {
      key: 'future',
      label: 'Futuras',
      data: future,
      type: 'future',
      disabled: true,
    },
  ];

  return (
    <Styled.TabsWrapper>
      <Tabs
        tabs={tabsData.map(({ key, label }) => (
          <Tab
            selectedBorderColor={theme.colors.text.primary}
            selectedColor={theme.colors.text.primary}
            key={key}
          >
            {label}
          </Tab>
        ))}
        tabPanels={tabsData.map(({ key, data, type, disabled }) => (
          <TabPanel key={key} padding={0}>
            <SyllabusListSubjects
              subjectEnrollments={data}
              type={type}
              disabled={disabled}
              onSubjectClick={onSubjectClick}
            />
          </TabPanel>
        ))}
      />
    </Styled.TabsWrapper>
  );
};

SyllabusConcludedFutureSubjects.propTypes = {
  passed: array,
  failed: array,
  future: array,
  onSubjectClick: func,
};

export default SyllabusConcludedFutureSubjects;
