import { format, isToday } from 'date-fns';

const FORMATE_DATE_FULL = 'yyyy-MM-dd';
const FORMATE_DATE_YEAR_MONTH = 'yyyy-MM';
const FORMATE_DATE_MONTH = 'MM';

const thisMonth = format(new Date(), FORMATE_DATE_MONTH);

export const formatingAddZero = (i) => (i < 10 ? `0${i}` : i);

export const isThisMonth = ({ date }) => {
  return date.getMonth() === thisMonth - 1;
};

export const goToThisMonth = ({
  setDate,
  date,
  forwardAndBackMonth,
  setCalendarDate,
  setSelectedDay,
}) => {
  setDate(new Date(date.setMonth(thisMonth - 1)));
  forwardAndBackMonth(date);
  setCalendarDate(format(new Date(), 'yyyy-MM-dd'));
  setSelectedDay('');
};

export const prevMonth = ({ setDate, date, forwardAndBackMonth }) => {
  setDate(new Date(date.setMonth(date.getMonth() - 1)));
  forwardAndBackMonth(date);
};

export const nextMonth = ({ setDate, date, forwardAndBackMonth }) => {
  setDate(new Date(date.setMonth(date.getMonth() + 1)));
  forwardAndBackMonth(date);
};

export const prevWeek = ({
  daysList,
  week,
  setWeek,
  date,
  setDate,
  forwardAndBackMonth,
}) => {
  const thisWeekIndex = daysList.findIndex((weeks) => {
    return weeks.some((day) => day.day === week[0].day);
  });

  if (thisWeekIndex !== 0) {
    setWeek(daysList[thisWeekIndex - 1]);
  } else {
    setDate(new Date(date.setMonth(date.getMonth() + 1)));
    forwardAndBackMonth(date);
  }
};

export const nextWeek = ({
  daysList,
  week,
  setWeek,
  date,
  setDate,
  forwardAndBackMonth,
}) => {
  const thisWeekIndex = daysList.findIndex((weeks) => {
    return weeks.some((day) => day.day === week[0].day);
  });
  if (thisWeekIndex !== daysList.length - 1) {
    setWeek(daysList[thisWeekIndex + 1]);
  } else {
    setDate(new Date(date.setMonth(date.getMonth() + 1)));
    forwardAndBackMonth(date);
  }
};

export const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

export const validateCurrentDay = (i, date) => {
  const formatingDate = `${format(
    date,
    FORMATE_DATE_YEAR_MONTH
  )}-${formatingAddZero(i)}`;
  return formatingDate === format(new Date(), FORMATE_DATE_FULL);
};

export const navigateUsingKeyboard = (event, id) => {
  const AK_TAB = 9;
  const AK_LEFT = 37;
  const AK_RIGHT = 39;

  let getElement = document.getElementById(id);
  const handleAddingAttributes = (sibling) => {
    if (sibling !== null) {
      getElement.focus();
      sibling.focus();
      getElement = sibling;
    }
  };

  const previousElementSibling = getElement.previousElementSibling;
  const nextElementSibling = getElement.nextElementSibling;
  const getElementLegends = document.getElementById('legends');

  if (event.keyCode === AK_LEFT) {
    // left arrow
    handleAddingAttributes(previousElementSibling);
  } else if (event.keyCode === AK_RIGHT) {
    // right arrow
    handleAddingAttributes(nextElementSibling);
  } else if (event.keyCode === AK_TAB) {
    // tab keyboard
    event.preventDefault();
    handleAddingAttributes(getElementLegends);
  }
};

export const isSunday = (date) => {
  const formatingDate = new Date(date.replaceAll('-', '/'));
  return formatingDate.getDay() === 0;
};

export const getCalendarInWeeks = (calendarDays, availableEvents) => {
  calendarDays = addEventsToDay(availableEvents?.availableEvents, calendarDays);

  return Array.from({ length: 5 }, (_, index) => {
    const start = index * 7;
    return calendarDays?.slice(start, start + 7);
  });
};

export const addEventsToDay = (events, days) => {
  const eventsSet = new Set(events?.map((day) => day.date));

  days.forEach((day) => {
    if (eventsSet.has(day.dayFull)) {
      day.hasEvent = true;
    } else {
      day.hasEvent = false;
    }
  });

  return days;
};

export const FindTodayWeek = (month, setWeek, setThisWeek) => {
  const todayIndex = month
    .map((weeks, index) => {
      return weeks.some((day) =>
        isToday(new Date(day?.dayFull.replaceAll('-', '/')))
      )
        ? index
        : -1;
    })
    .findIndex((index) => index !== -1);

  if (todayIndex !== -1) {
    setWeek(month[todayIndex]);
    setThisWeek(month[todayIndex]);
  } else {
    setWeek(month[0]);
  }
};

export const validadeAddEvent = ({ data, events }) => {
  const getIndexEvent = events?.events?.findIndex((e) => e?.date === data);
  if (getIndexEvent > -1) {
    return events?.events[getIndexEvent];
  }
  return null;
};

export const getHolliday = (date) => {
  const getIndexHolliday = date?.dateEvents?.findIndex(
    (e) => e?.type === 'NATIONAL_STATE_HOLIDAYS'
  );
  return date?.dateEvents?.[getIndexHolliday];
};

export const selectColorEvent = (data, events, theme) => {
  const getIndexEvent = events?.events?.findIndex((e) => e?.date === data);
  let color = '';
  if (getIndexEvent > -1) {
    events?.events[getIndexEvent].dateEvents.forEach((event) => {
      switch (event.type) {
        case 'ACADEMIC_DATES':
          color = theme.colors.calendar.primary;
          break;
        case 'COURSE_EVENTS':
          color = theme.colors.text.lightSuccess;
          break;
        case 'NATIONAL_STATE_HOLIDAYS':
          color = theme.colors.tag.danger;
          break;
        case 'VACATION':
          color = theme.colors.calendar.warning;
          break;
      }
      return color;
    });
    return color;
  }
  return null;
};

export const getDataCalendar = ({
  lastDayIndex,
  firstDayIndex,
  subMonths,
  date,
  prevLastDay,
  events,
  lastDay,
  addMonths,
  setDaysList,
  setWeek,
  theme,
  setThisWeek,
  availableEvents,
}) => {
  const nextDays = 7 - lastDayIndex - 1;
  let leftDays = [];
  let daysOfMonth = [];
  let rightDays = [];

  for (let x = firstDayIndex; x > 0; x--) {
    const formatedStringFullDay = `${format(
      subMonths(date, 1),
      FORMATE_DATE_YEAR_MONTH
    )}-${formatingAddZero(prevLastDay - x + 1)}`;

    leftDays.push({
      day: prevLastDay - x + 1,
      dayFull: formatedStringFullDay,
      outMonth: true,
      events: validadeAddEvent({ formatedStringFullDay, events }),
      holliday: getHolliday(
        validadeAddEvent({ formatedStringFullDay, events })
      ),
    });
  }

  for (let i = 1; i <= lastDay; i++) {
    const formatedStringFullDay = `${format(
      date,
      FORMATE_DATE_YEAR_MONTH
    )}-${formatingAddZero(i)}`;

    daysOfMonth.push({
      day: i,
      dayFull: formatedStringFullDay,
      events: validadeAddEvent({ formatedStringFullDay, events }),
      color: selectColorEvent(formatedStringFullDay, events, theme),
      holliday: getHolliday(
        validadeAddEvent({ formatedStringFullDay, events })
      ),
    });
  }

  for (let i = 1; i <= nextDays; i++) {
    const formatedStringFullDay = `${format(
      addMonths(date, 1),
      FORMATE_DATE_YEAR_MONTH
    )}-${formatingAddZero(i)}`;

    rightDays.push({
      day: i,
      outMonth: true,
      dayFull: formatedStringFullDay,
      events: validadeAddEvent({ formatedStringFullDay, events }),
      holliday: getHolliday(
        validadeAddEvent({ formatedStringFullDay, events })
      ),
    });
  }

  setDaysList(
    getCalendarInWeeks(
      [...leftDays, ...daysOfMonth, ...rightDays],
      availableEvents
    )
  );
  FindTodayWeek(
    getCalendarInWeeks(
      [...leftDays, ...daysOfMonth, ...rightDays],
      availableEvents
    ),
    setWeek,
    setThisWeek
  );
};
