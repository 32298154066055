import { useTheme } from '@emotion/react';
import { generatePath } from 'react-router-dom';
import { pushAnhangueraEvent } from '../../lib/ga';
import {
  IUseNavigationItemsLogic,
  IUseNavigationItemsLogicParams,
} from './navigation-items.types';
import { useGetState } from '../../state';
import { routePath } from '../../routes';
const useNavigationItemsLogic = ({
  shouldRedirect,
  setLastNavigationRoute,
  redirectionBlockAction,
}: IUseNavigationItemsLogicParams): IUseNavigationItemsLogic => {
  const theme = useTheme();
  const [
    {
      selectedCourseEnrollmentType: courseType,
      selectedCourseVersionId: courseVersionId,
    },
  ] = useGetState();

  const handleGetLinkColor = (isActualPathActive: boolean): string => {
    return isActualPathActive
      ? theme.colors.navBar.active
      : theme.colors.navBar.color;
  };

  const checkActualPathTo = (
    actualPath: string,
    pathToCheck: string
  ): boolean => {
    return actualPath === getPath(pathToCheck);
  };

  const getPath = (routePath: string): string => {
    return generatePath(routePath, {
      courseType: courseType,
      courseVersionId: courseVersionId,
    });
  };

  const handleNavigateOrBlock = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    routePath: string
  ): void => {
    if (!shouldRedirect) {
      event.preventDefault();

      setLastNavigationRoute(routePath);
      redirectionBlockAction();
    }
  };

  const handleClickSecretaryLink = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    pushAnhangueraEvent('event', 'Sec_home');
    handleNavigateOrBlock(event, getPath(routePath.secretaryStudent));
  };

  return {
    actions: {
      handleClickSecretaryLink,
      handleNavigateOrBlock,
      getPath,
      checkActualPathTo,
      handleGetLinkColor,
    },
  };
};

export default useNavigationItemsLogic;
