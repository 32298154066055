import { useEffect, useState } from 'react';
const useEffectOnce = function (effect, onceCondition) {
  let deps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  const [executed, setExecuted] = useState(false);
  useEffect(() => {
    if (!executed && onceCondition) {
      setExecuted(true);
      effect();
    }
  }, [executed, effect, onceCondition, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
};
export default useEffectOnce;