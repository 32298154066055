import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const Spin = keyframes`
  to { transform: rotate(360deg);}
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${({ color }) => color};
  border-radius: 50%;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  animation: ${Spin} 1.2s linear infinite;
`;
