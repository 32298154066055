import { useGetLastOrderStudent } from '@ampli/services';

const useGetLastOrder = ({ courseEnrollmentId, orderSubject }) => {
  const {
    loading,
    data: { data } = {},
    error,
  } = useGetLastOrderStudent(courseEnrollmentId, orderSubject);

  return {
    loading,
    data,
    error,
  };
};

export default useGetLastOrder;
