import styled from '@emotion/styled';
import { convertPXToVW } from '@sofia/ui';

export const LayoutWrapper = styled.div(
  ({ isDesktop, title }) => `
  > div {
    padding-top: 0;

    ${title ? 'padding-bottom: 0px;' : ''}

    ${
      !isDesktop &&
      `
      padding-right: 0px;
      padding-left: 0px;
      button {
        min-width: ${convertPXToVW(136)};
      }
    `
    }
  }
`
);
