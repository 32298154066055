import React, { useState, useEffect } from 'react';
import { func, object, bool, string } from 'prop-types';
import {
  format,
  endOfMonth,
  subMonths,
  getDay,
  addMonths,
  startOfMonth,
  parseISO,
} from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '../../../modules';

import * as Style from './calendar.styles';
import CalendarSkeleton from './skeleton/calendar-skeleton';
import {
  SvgAngleRight,
  SvgArrowRight,
  SvgLine,
  Typography,
  SvgArrowLeft,
} from '@sofia/ui';
import {
  isThisMonth,
  goToThisMonth,
  prevMonth,
  nextMonth,
  nextWeek,
  prevWeek,
  capitalize,
  navigateUsingKeyboard,
  getDataCalendar,
} from './calendar.logic';

function Calendar({
  forwardAndBackMonth,
  events,
  loading,
  availableEvents,
  setCalendarDate,
  setCardsData,
  selectedDay,
  setSelectedDay,
  setCollapseCalendar,
  collapseCalendar,
}) {
  const FORMATE_DATE_MONTH_NAME = 'MMMM';
  const FORMATE_DATE_DAY = 'dd';
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();

  const [date, setDate] = useState(startOfMonth(new Date()));
  const [daysList, setDaysList] = useState([]);

  const [week, setWeek] = useState();
  const [, setThisWeek] = useState();

  const lastDay = format(endOfMonth(date), FORMATE_DATE_DAY);
  const prevLastDay = format(endOfMonth(subMonths(date, 1)), FORMATE_DATE_DAY);
  const firstDayIndex = getDay(date);
  const lastDayIndex = endOfMonth(date).getDay();

  const toggleCollapseCalendar = () => {
    setCollapseCalendar(!collapseCalendar);
  };

  useEffect(() => {
    const shouldFetchData = !loading;

    if (shouldFetchData) {
      getDataCalendar({
        lastDayIndex,
        firstDayIndex,
        subMonths,
        date,
        prevLastDay,
        events,
        lastDay,
        addMonths,
        setDaysList,
        setWeek,
        theme,
        setThisWeek,
        availableEvents,
      });
    }
  }, [
    date,
    firstDayIndex,
    lastDay,
    lastDayIndex,
    prevLastDay,
    events,
    theme,
    availableEvents,
    loading,
  ]);

  const today = new Date().toISOString().split('T')[0];

  const handleEventClick = (date) => {
    const dateIso = parseISO(date);

    setSelectedDay(date);

    setCalendarDate(format(dateIso, 'yyyy-MM-dd'));
    setCardsData((prev) => {
      const newData = prev;

      newData.shift();

      return newData;
    });
  };

  const handleClickToday = () => {
    goToThisMonth({
      date: startOfMonth(new Date()),
      setDate,
      forwardAndBackMonth,
      setCalendarDate,
      setSelectedDay,
    });

    if (!isDesktop) setCollapseCalendar(true);
  };

  const getDayColor = ({ daysOfMonth }) => {
    // não tem essa cor sendo utilizada no tema do whitelabel
    if (daysOfMonth.outMonth) return '#CED4DA';
    if (daysOfMonth.dayFull === today) return theme.colors.text.white;

    return theme.colors.text.black;
  };

  const getDayLabelColor = ({ daysOfMonth }) => {
    if (daysOfMonth.dayFull === today) return theme.colors.button.primary;
    if (daysOfMonth.dayFull === selectedDay)
      return theme.colors.background.icon.secondary;

    return 'transparent';
  };

  const getEventDateLabelColor = ({ daysOfMonth }) => {
    if (daysOfMonth.hasEvent) return theme.colors.text.lightBlack;

    return 'transparent';
  };

  const getCalendarDays = ({ daysOfMonth, i }) => {
    return (
      <Style.DaysContainer
        key={i}
        data-testid={('day-' + i).toString()}
        id={('day-' + i).toString()}
        onClick={() =>
          daysOfMonth.hasEvent && handleEventClick(daysOfMonth.dayFull)
        }
        onKeyDown={(e) =>
          !daysOfMonth.outMonth &&
          navigateUsingKeyboard(e, ('day-' + i).toString())
        }
      >
        <Style.DayButton>
          <Style.DayLabel>
            <Style.DayLabelContainer
              background={getDayLabelColor({ daysOfMonth })}
            >
              <Typography color={getDayColor({ daysOfMonth })}>
                {daysOfMonth?.day}
              </Typography>
            </Style.DayLabelContainer>

            <Style.EventDateContainer>
              <Style.EventDateLabel
                color={getEventDateLabelColor({ daysOfMonth })}
              />
            </Style.EventDateContainer>
          </Style.DayLabel>
        </Style.DayButton>
      </Style.DaysContainer>
    );
  };

  const showCollapseWeekCalendar = week && collapseCalendar;

  return (
    <Style.Container isCollapsed={collapseCalendar}>
      {!loading ? (
        <>
          <Style.ContainerCalendar
            collapsed={collapseCalendar}
            tabIndex={0}
            id={'container-calendar'}
            data-testid="container-calendar"
          >
            <Style.ContainerButton>
              <Style.Month color={theme.colors.text.black}>
                <h1
                  aria-hidden="true"
                  data-testid="calendar-month-title"
                  id="calendar-month-title"
                >
                  {capitalize(format(date, "MMMM 'de' yyyy", { locale: pt }))}
                </h1>
              </Style.Month>
              <Style.FlexWrapper
                visibility={!isThisMonth({ date }) || selectedDay !== ''}
                onClick={() => handleClickToday()}
              >
                {selectedDay < today ? (
                  <SvgArrowRight fill={theme.colors.text.primary} />
                ) : (
                  <SvgArrowLeft fill={theme.colors.text.primary} />
                )}

                <Style.TodayButton>Hoje</Style.TodayButton>
              </Style.FlexWrapper>
              <Style.ButtonNav
                aria-label={`Ir para o mês anterior ${format(
                  subMonths(date, 1),
                  FORMATE_DATE_MONTH_NAME,
                  { locale: pt }
                )}`}
                id="month-back"
                data-testid="month-back"
                type="submit"
                onClick={() => {
                  collapseCalendar
                    ? prevWeek({
                        daysList,
                        week,
                        setWeek,
                        date,
                        setDate,
                        forwardAndBackMonth,
                      })
                    : prevMonth({ date, setDate, forwardAndBackMonth });
                }}
                color={theme.colors.text.primary}
              >
                <Style.ContainerSmallIcon transform="rotate(180deg)">
                  <SvgAngleRight fill={theme.colors.text.primary} />
                </Style.ContainerSmallIcon>
              </Style.ButtonNav>
              <Style.ButtonNav
                color={theme.colors.text.primary}
                aria-label={`Ir para o próximo mês ${format(
                  addMonths(date, 1),
                  FORMATE_DATE_MONTH_NAME,
                  { locale: pt }
                )}`}
                id="month-forward"
                data-testid="month-forward"
                type="submit"
                onClick={() => {
                  collapseCalendar
                    ? nextWeek({
                        daysList,
                        week,
                        setWeek,
                        date,
                        setDate,
                        forwardAndBackMonth,
                      })
                    : nextMonth({ date, setDate, forwardAndBackMonth });
                }}
              >
                <Style.ContainerSmallIcon>
                  <SvgAngleRight fill={theme.colors.text.primary} />
                </Style.ContainerSmallIcon>
              </Style.ButtonNav>
            </Style.ContainerButton>
            <Style.Weekdays aria-hidden="true">
              <div>D</div>
              <div>S</div>
              <div>T</div>
              <div>Q</div>
              <div>Q</div>
              <div>S</div>
              <div>S</div>
            </Style.Weekdays>
            <Style.Days>
              {showCollapseWeekCalendar
                ? week.map((daysOfMonth, i) =>
                    getCalendarDays({ daysOfMonth, i })
                  )
                : null}
              {!showCollapseWeekCalendar &&
                daysList.map((week) =>
                  week.map((daysOfMonth, i) =>
                    getCalendarDays({ daysOfMonth, i })
                  )
                )}
            </Style.Days>
          </Style.ContainerCalendar>
          {!isDesktop ? (
            <Style.SwipeMobile onClick={toggleCollapseCalendar}>
              <SvgLine />
            </Style.SwipeMobile>
          ) : null}
        </>
      ) : (
        <CalendarSkeleton />
      )}
    </Style.Container>
  );
}

Calendar.propTypes = {
  forwardAndBackMonth: func,
  events: object,
  loading: bool,
  availableEvents: object,
  setCalendarDate: func,
  setCardsData: func,
  selectedDay: string,
  setSelectedDay: func,
  setCollapseCalendar: func,
  collapseCalendar: bool,
};

export default Calendar;
