import React from 'react';
import { string, number } from '@ampli/utils';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import * as Accessibility from '../../accessibility';
import * as S from './separator-styles';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

function Separator({ date, id, ...rest }) {
  const theme = useTheme();

  const formatDayAndMonth = format(
    new Date(date.replaceAll('-', '/')),
    "d 'de' MMM",
    {
      locale: pt,
    }
  );

  const isToday = format(new Date(), 'yyyy-MM-dd') === id;

  const todayInText = () => {
    let date = new Date(id);
    date.setDate(date.getDate() + 1);
    return format(date, 'EEEE', {
      locale: pt,
    });
  };

  return (
    <S.Container
      isToday={isToday}
      id={id}
      data-testid={id}
      {...rest}
      tabIndex={0}
      aria-label={Accessibility.TextSeparator(date)}
    >
      <S.DayContainer>
        <Typography
          color={isToday ? theme.colors.text.primary : theme.colors.text.black}
          weight={700}
        >
          {formatDayAndMonth}.
        </Typography>

        {isToday ? (
          <Typography color={theme.colors.text.primary}>hoje</Typography>
        ) : (
          <Typography color={theme.colors.text.lighterBlack}>
            {todayInText()}
          </Typography>
        )}
      </S.DayContainer>
    </S.Container>
  );
}

Separator.propTypes = {
  date: string,
  id: string,
  totalEvents: number,
};

export default Separator;
