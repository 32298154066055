import React from 'react';
import { string, bool } from 'prop-types';
import Tour from './single';

const TourProvider = ({ data = [] }) => {
  return (
    <>{data.map((tour) => (tour ? <Tour key={tour.id} {...tour} /> : null))}</>
  );
};

TourProvider.propTypes = {
  data: [
    {
      id: string,
      resetOnOverlayClick: bool,
      steps: [
        {
          target: string,
          content: string,
          focusElement: bool,
          waitForElement: bool,
          highlight: [string],
          redirect: string,
        },
      ],
    },
  ],
};

export default TourProvider;
