import styled from '@emotion/styled';
import { convertPXToVH, convertPXToVW } from '../../utils';
export const Wrapper = styled.div`
  background: ${_ref => {
  let {
    background
  } = _ref;
  return background;
}};
  padding: ${_ref2 => {
  let {
    padding
  } = _ref2;
  return padding ? padding : `24px 0 24px 495px`;
}};

  margin: ${_ref3 => {
  let {
    margin
  } = _ref3;
  return margin;
}};
  ${_ref4 => {
  let {
    lastChildren
  } = _ref4;
  return !lastChildren && `margin-bottom: ${convertPXToVH(8)};`;
}}

  @media (max-width: 1400px) {
    padding: ${_ref5 => {
  let {
    padding
  } = _ref5;
  return padding ? padding : `${convertPXToVH(24)} ${convertPXToVW(0)} ${convertPXToVH(24)} ${convertPXToVW(230)}`;
}};
  }

  @media (max-width: 936px) {
    padding: ${convertPXToVH(8)} ${convertPXToVW(16)};
  }

  @media (max-width: 360px) {
    padding: ${convertPXToVH(8)} ${convertPXToVW(40)};
  }
`;