/* eslint-disable prettier/prettier */
import React from 'react';
import * as Styled from './navigation.styles';
import { generatePath } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Creatives, useBreakpoint, wisdomRoutesPath } from '../../modules';
import { Icon } from '@ampli/ui';
import NavLinkItem from './nav-link-item';
import { SvgQuestionCircle } from '@sofia/ui';
import { THelpOrProfileButtontProps } from './navigation.types';
import { routePath } from '../../routes';

const HelpOrProfileButton: React.FC<THelpOrProfileButtontProps> = ({
  hasChatEnabled,
  isPathHome,
  firstName,
  shouldShowNotificationBell,
  handleClickHelp,
  courseType,
  courseVersionId,
  actualPath,
  shouldRedirect,
  redirectionBlockAction,
  setLastNavigationRoute,
}: THelpOrProfileButtontProps) => {
  const { isDesktop } = useBreakpoint();
  const theme = useTheme();

  const handleNavigateOrBlock = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    routePath: string
  ): void => {
    if (!shouldRedirect) {
      event.preventDefault();

      setLastNavigationRoute(routePath);
      redirectionBlockAction();
    }
  };

  if (!isDesktop && !isPathHome) {
    return (
      <NavLinkItem
        to={generatePath(routePath.profile)}
        onClick={(event) =>
          handleNavigateOrBlock(event, generatePath(routePath.profile))
        }
        id="profile-mobile"
        data-testid="profile-mobile"
        exact
        activeClassName="actived"
        icon={
          <Styled.AvatarCircle background={theme.colors.card.border.primary}>
            {firstName}
            {shouldShowNotificationBell ? <Creatives.Signal isBadge /> : null}
          </Styled.AvatarCircle>
        }
        text="Perfil"
      />
    );
  }

  if (hasChatEnabled) {
    return (
      <NavLinkItem
        id="help"
        data-testid="help"
        onClick={handleClickHelp}
        text="Ajuda"
        to="#"
        icon={<Icon.SpeechBalloon color={theme.colors.navBar.color} />}
      />
    );
  }

  return (
    <NavLinkItem
      id="help"
      data-testid="help"
      to={generatePath(wisdomRoutesPath.home, {
        courseType: courseType,
        courseVersionId: courseVersionId,
      })}
      onClick={(event) =>
        handleNavigateOrBlock(
          event,
          generatePath(wisdomRoutesPath.home, {
            courseType: courseType,
            courseVersionId: courseVersionId,
          })
        )
      }
      activeClassName="actived"
      text="Ajuda"
      icon={
        <SvgQuestionCircle
          fill={
            actualPath ===
              generatePath(wisdomRoutesPath.home, {
                courseType: courseType,
                courseVersionId: courseVersionId,
              })
              ? theme.colors.navBar.active
              : theme.colors.navBar.color
          }
        />
      }
    />
  );
};

export default HelpOrProfileButton;
