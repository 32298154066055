export const scrollToBottom = (): void => {
  window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
};

export const scrollToTop = (): void => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const scrollToLeft = (): void => {
  window.scrollTo({ left: 0, behavior: 'smooth' });
};

export const scrollToRight = (): void => {
  window.scrollTo({ left: document.body.scrollWidth, behavior: 'smooth' });
};
