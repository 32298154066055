import { object, arrayOf, func, string, shape, bool } from 'prop-types';
import { Header, Typography, Breadcrumbs, Banner } from '@sofia/ui';
import { generatePath, Link } from 'react-router-dom';
import { Grid, GridItem } from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { includes } from 'lodash';

import { EntitiesLabels, freeTrialEnabledRole } from '../../constants';
import { useFreeTrialRegressiveCounter } from '../../hooks';
import { FreeTrialRegressiveCounter, Skeleton } from '../../components';
import { LayoutContent } from '../layout';
import { useGetState } from '../../state';
import { routePath } from '../../routes';

import SyllabusConcludedFutureSubjects from './list-subjects/concluded-future/syllabus-concluded-future-subject';
import SyllabusListSubjects from './list-subjects/syllabus-list-subjects';
import SyllabusHeader from './header/syllabus-header';
import SyllabusInfo from './info/syllabus-info';
import React from 'react';

import * as Styled from './syllabus.styles';
import { SyllabusEmptyState } from '../../modules/academic-program/shared/components';

const Syllabus = ({
  subjectEnrollmentsLoading,
  courseEnrollmentLoading,
  progressLoading,
  studentFreeTrialDataLoading,
  courseName,
  courseEndDate,
  courseProgress,
  courseStatus,
  subjectEnrollmentsToBeTaken,
  subjectEnrollmentsTakenFailed,
  subjectEnrollmentsTakenPassed,
  onSubjectClick,
  onInauguralSubjectClick,
  studentFreeTrialCourseData,
  activeEnrollments,
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const gridColumns = isDesktop ? 4 : 2;

  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const regressiveCounter = useFreeTrialRegressiveCounter(
    studentFreeTrialCourseData
  );

  const showEmptyState = !activeEnrollments?.length && !courseEnrollmentLoading;

  const entitiesLabelsDictionary = React.useMemo(
    () => EntitiesLabels(selectedCourseEnrollmentType),
    [selectedCourseEnrollmentType]
  );

  const entitiesLabelsDictionaryId = React.useMemo(
    () =>
      entitiesLabelsDictionary.currentSubjects
        .replaceAll(' ', '-')
        .toLowerCase(),
    [entitiesLabelsDictionary.currentSubjects]
  );

  const isFreeTrialEnabled = React.useMemo(
    () =>
      regressiveCounter.showFreeTrialCounter &&
      includes(studentFreeTrialCourseData?.roles, freeTrialEnabledRole),
    [regressiveCounter.showFreeTrialCounter, studentFreeTrialCourseData]
  );

  const handleSubject = React.useCallback(
    (subject) => {
      onSubjectClick(subject);
    },
    [onSubjectClick]
  );

  const breadCrumbs = (
    <Breadcrumbs
      secondColor={theme.colors.breadCrumbs.secondary}
      firstColor={theme.colors.breadCrumbs.primary}
      fill={theme.colors.breadCrumbs.primary}
      backClick={() => {
        history.go(-1);
      }}
      shadow={false}
      margin="0px"
    >
      <Link
        data-testid="inicial-breadcrumbs"
        to={generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })}
      >
        <span>Início</span>
      </Link>
      <b>{entitiesLabelsDictionary.mySubjectsTitle}</b>
    </Breadcrumbs>
  );

  return (
    <>
      <Banner background={theme.colors.banner.primary}>
        <LayoutContent padding="small">
          <Header
            id="syllabus"
            data-testid="syllabus"
            breadCrumbs={breadCrumbs}
            titleColor={theme.colors.text.secondary}
            descriptionColor={theme.colors.text.light}
            background="none"
            padding="0px"
            lastChildren={
              !studentFreeTrialDataLoading && isFreeTrialEnabled ? (
                <FreeTrialRegressiveCounter
                  endDate={regressiveCounter.freeTrialDaysLeft}
                  freeTrialSituation={regressiveCounter.freeTrialSituation}
                  selectedCourseEnrollmentType={selectedCourseEnrollmentType}
                  selectedCourseVersionId={selectedCourseVersionId}
                />
              ) : (
                true
              )
            }
          />
        </LayoutContent>
      </Banner>

      <LayoutContent>
        {courseProgress ? (
          <SyllabusHeader
            progressLoading={progressLoading}
            courseEnrollmentLoading={courseEnrollmentLoading}
            courseName={courseName}
            totalOfSubjects={courseProgress.totalCourseNumber}
            totalOfSubjectsCompleted={courseProgress.passedCourseNumber}
            coursePercentage={courseProgress.conclusionPercentage}
          />
        ) : null}

        <SyllabusInfo
          courseEndDate={courseEndDate}
          onInauguralSubjectClick={onInauguralSubjectClick}
        />

        {subjectEnrollmentsLoading ? (
          <LayoutContent padding={!['huge', 'small']}>
            <Grid
              columns={gridColumns}
              columnGap={isDesktop ? 24 : 0}
              rowGap={isDesktop ? 24 : 16}
            >
              <div>
                <GridItem column={isDesktop ? 'span 4' : 'span 2'}>
                  <Styled.SkeletonWraper>
                    {new Array(4).fill(undefined).map((i) => (
                      <Skeleton key={i} height={170} borderRadius={6} />
                    ))}
                  </Styled.SkeletonWraper>
                </GridItem>
              </div>
            </Grid>
          </LayoutContent>
        ) : null}

        <Styled.InProgressWrapper>
          <Typography
            size={isDesktop ? 40 : 20}
            color={theme.colors.text.black}
            weight={700}
            margin="0 0 24px 0"
            id={`title-${entitiesLabelsDictionaryId}`}
            data-testid={`title-${entitiesLabelsDictionaryId}`}
          >
            {entitiesLabelsDictionary.currentSubjects}
          </Typography>

          {showEmptyState ? (
            <SyllabusEmptyState status={courseStatus} />
          ) : (
            <SyllabusListSubjects
              subjectEnrollments={activeEnrollments}
              onSubjectClick={handleSubject}
            />
          )}
        </Styled.InProgressWrapper>

        {!studentFreeTrialDataLoading ? (
          <SyllabusConcludedFutureSubjects
            future={subjectEnrollmentsToBeTaken}
            failed={subjectEnrollmentsTakenFailed}
            passed={subjectEnrollmentsTakenPassed}
            onSubjectClick={handleSubject}
          />
        ) : null}
      </LayoutContent>
    </>
  );
};

Syllabus.propTypes = {
  courseName: string.isRequired,
  courseEndDate: object,
  courseStatus: string,
  courseProgress: shape({
    totalCourseNumber: string,
    passedCourseNumber: string,
    conclusionPercentage: string,
    maskedConclusionPercentage: string,
  }).isRequired,
  activeEnrollments: arrayOf(object.isRequired).isRequired,
  subjectEnrollmentsTakenFailed: arrayOf(object.isRequired).isRequired,
  subjectEnrollmentsTakenPassed: arrayOf(object.isRequired).isRequired,
  subjectEnrollmentsToBeTaken: arrayOf(object.isRequired).isRequired,
  onInauguralSubjectClick: func.isRequired,
  onSubjectClick: func,
  studentFreeTrialCourseData: object,
  courseEnrollmentLoading: bool,
  subjectEnrollmentsLoading: bool,
  progressLoading: bool,
  studentFreeTrialDataLoading: bool,
};

export default Syllabus;
