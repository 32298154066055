import { lazy } from 'react';

export const LiveSectionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Section live page" */ '../features/pages/live-section-page.presentation'
    )
);

export const LiveFrameSectionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Section live page" */ '../features/pages/frame/live-frame-section-page.presentation'
    )
);

export const SubjectLiveModule = lazy(
  () => import(/* webpackChunkName: "wisdom" */ './modules/subject-live-module')
);

export const CourseLiveModule = lazy(
  () => import(/* webpackChunkName: "wisdom" */ './modules/course-live-module')
);
