import React from 'react';

import { useTheme } from '@emotion/react';
import { SvgArrowRight, Typography, SvgCalender } from '@sofia/ui';
import {
  InformationSection,
  Container,
  StyledCard,
  MobileInformationSection,
  Information,
  TextContainer,
} from './subject-calendar-card.styles';
import { func, string } from 'prop-types';
import { useBreakpoint } from '../../../../modules';
import formatSubjectCalendarDate from './formatSubjectCalendarDate';

const SubjectCalendarCard = ({ onClick, currentPeriod }) => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();

  const subjectCalendarText = 'Calendário da disciplina';

  return (
    <Container id="subject-calendar-card">
      <StyledCard
        borderColor={theme.colors.card.border.primary}
        focusColor={theme.colors.card.border.third}
        aria-label={subjectCalendarText}
        onClick={onClick}
        tabIndex={0}
      >
        {isDesktop ? (
          <>
            <InformationSection>
              <SvgCalender fill={theme.colors.icon.primary} />

              <Typography size={20}>{subjectCalendarText}</Typography>
            </InformationSection>

            <InformationSection>
              <Typography color={theme.colors.text.lightBlack}>
                Período de estudo: {formatSubjectCalendarDate(currentPeriod)}
              </Typography>

              <SvgArrowRight fill={theme.colors.icon.primary} />
            </InformationSection>
          </>
        ) : (
          <MobileInformationSection>
            <Information>
              <SvgCalender fill={theme.colors.icon.primary} />

              <TextContainer>
                <Typography size={16}>{subjectCalendarText}</Typography>

                <Typography color={theme.colors.text.lightBlack}>
                  Período de estudo: {formatSubjectCalendarDate(currentPeriod)}
                </Typography>
              </TextContainer>
            </Information>

            <SvgArrowRight fill={theme.colors.icon.primary} />
          </MobileInformationSection>
        )}
      </StyledCard>
    </Container>
  );
};

SubjectCalendarCard.propTypes = {
  onClick: func,
  currentPeriod: string,
};

export default SubjectCalendarCard;
