import React from 'react';

import {
  cx,
  css,
  px2grid,
  FlexItem,
  Flex,
  Paragraph,
  Text,
  TextButton,
  mediaQuery,
} from '@ampli/ui';

import { string, node, func, bool } from 'prop-types';
import { ButtonPrimary } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { Wrapper } from './onboarding.styles';

const ImageWrapper = (props) => (
  <div
    {...props}
    className={cx(
      css`
        margin-bottom: ${px2grid(32)};
        padding: 0 ${px2grid(26)};
        width: 100%;

        & > svg {
          width: 100%;
          height: auto;
        }

        ${mediaQuery('desktop')} {
          display: none;
        }
      `,
      props.className
    )}
  />
);
ImageWrapper.propTypes = {
  className: string,
};

const OnboardingContentWrapper = (props) => (
  <div
    {...props}
    className={cx(
      'onboarding-content',
      css`
        padding: ${px2grid(24)} ${px2grid(16)};
        margin: 0 auto;
        max-width: ${px2grid(364)};

        ${mediaQuery('desktop')} {
          padding: ${px2grid(56)} ${px2grid(16)};
        }
      `,
      props.className
    )}
  />
);
OnboardingContentWrapper.propTypes = {
  className: string,
};

const OnboardingContent = ({
  title,
  children,
  image,
  onPrimaryClick,
  onSecondaryClick,
  isDesktop,
  loading,
  secondaryButtonLabel = 'Voltar',
  primaryButtonLabel = 'Avançar',
  ...props
}) => {
  const theme = useTheme();

  return (
    <FlexItem flex="1">
      <Flex direction="column">
        <OnboardingContentWrapper {...props}>
          <FlexItem flex="1">
            <Flex direction="column" justifyContent="flex-start">
              <Wrapper>
                {image ? <ImageWrapper>{image}</ImageWrapper> : null}

                <Paragraph marginSize={isDesktop ? 'small' : 'xx-small'}>
                  <Text
                    as="strong"
                    size={isDesktop ? 'xxx-large' : 'x-large'}
                    color="text-dark"
                  >
                    {title}
                  </Text>
                </Paragraph>
                <FlexItem flex={1}>
                  <Flex direction="column">
                    <div>{children}</div>
                  </Flex>
                </FlexItem>
              </Wrapper>
            </Flex>
          </FlexItem>
          <Flex
            alignItems="center"
            justifyContent={isDesktop ? 'space-between' : 'flex-end'}
            className={css`
              margin-top: ${px2grid(32)};
            `}
          >
            <div>
              <FlexItem>
                <div>
                  {onSecondaryClick && (
                    <TextButton
                      onClick={onSecondaryClick}
                      marginSize="none"
                      underline
                      className={css`
                        margin-right: ${px2grid(12)};
                      `}
                    >
                      {secondaryButtonLabel}
                    </TextButton>
                  )}
                </div>
              </FlexItem>
              <FlexItem>
                <div>
                  <ButtonPrimary
                    color={theme.colors.button.text.primary}
                    background={theme.colors.button.primary}
                    loading={loading}
                    onClick={onPrimaryClick}
                  >
                    {primaryButtonLabel}
                  </ButtonPrimary>
                </div>
              </FlexItem>
            </div>
          </Flex>
        </OnboardingContentWrapper>
      </Flex>
    </FlexItem>
  );
};

OnboardingContent.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
  image: node,
  onPrimaryClick: func.isRequired,
  onSecondaryClick: func,
  className: string,
  isDesktop: bool,
  loading: bool,
  secondaryButtonLabel: string,
  primaryButtonLabel: string,
};

export default OnboardingContent;
