import React from 'react';
import {
  number,
  bool,
  arrayOf,
  func,
  any,
  oneOfType,
  elementType,
  shape,
} from 'prop-types';
import { useBreakpoint } from '@ampli/hooks';
import { Grid } from '@ampli/ui';

import QuestionBullet from './question-bullet';

import { QuestionBulletWrap } from './question-bullets.styles';

const QuestionBullets = ({
  refFirstQuestionBullet,
  complete,
  active,
  onBulletClick,
  bulletQuestions,
  ...props
}) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  return (
    <Grid
      columns={isDesktop ? 5 : bulletQuestions.length}
      columnGap={isDesktop ? 16 : 8}
      rowGap={16}
      alignItems="center"
    >
      <QuestionBulletWrap {...props}>
        {bulletQuestions.map(({ bullet, theme }) => (
          <div
            key={`question-bullet_${bullet}`}
            ref={bullet === 0 ? refFirstQuestionBullet : null}
            tabIndex={-1}
          >
            <QuestionBullet
              size={40}
              complete={complete}
              active={active === bullet}
              bullet={bullet}
              onClick={onBulletClick}
              darkColor={theme.borderColor}
              lightColor={theme.background}
            />
          </div>
        ))}
      </QuestionBulletWrap>
    </Grid>
  );
};

QuestionBullets.propTypes = {
  complete: oneOfType([arrayOf(any), bool]).isRequired,
  active: number.isRequired,
  onBulletClick: func,
  bulletQuestions: arrayOf(
    shape({
      isCorrect: bool.isRequired,
      bullet: number.isRequired,
    })
  ).isRequired,
  refFirstQuestionBullet: shape({ current: elementType }),
};

export default QuestionBullets;
