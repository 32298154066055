import React from 'react';

function SvgPersonDocumentBackImage(props) {
  return (
    <svg viewBox="0 0 54 40" width={54} height={40} fill="none" {...props}>
      <path
        d="M.483 7.319a1.504 1.504 0 011.33-1.659L48.527.54a1.504 1.504 0 011.659 1.33l3.332 30.402a1.504 1.504 0 01-1.33 1.659L5.473 39.05a1.504 1.504 0 01-1.66-1.331L.484 7.319z"
        fill="#99CAD2"
      />
      <path
        d="M47.282 3.365a.376.376 0 01.415.332l2.996 27.331a.376.376 0 01-.333.415L6.718 36.226a.376.376 0 01-.415-.332L3.307 8.563a.376.376 0 01.333-.415l43.642-4.783zM3.517 7.027a1.504 1.504 0 00-1.33 1.659l2.995 27.33c.09.826.833 1.422 1.658 1.331l43.643-4.783a1.504 1.504 0 001.33-1.659l-2.995-27.33a1.504 1.504 0 00-1.658-1.332L3.517 7.027z"
        fill="#fff"
      />
      <path
        d="M5.924 11.037a1.504 1.504 0 011.331-1.659l1.121-.123a1.504 1.504 0 011.659 1.331l2.335 21.301a1.504 1.504 0 01-1.331 1.659l-1.121.123a1.504 1.504 0 01-1.66-1.331L5.925 11.037z"
        fill="#000"
        fillOpacity={0.1}
      />
      <path
        d="M45.89 20.271a.752.752 0 01.163 1.495l-29.895 3.277a.752.752 0 01-.164-1.495l29.895-3.277zm-29.978 2.53a1.504 1.504 0 10.328 2.99l29.895-3.278a1.504 1.504 0 00-.328-2.99L15.912 22.8zM46.422 25.13a.752.752 0 11.164 1.494L16.69 29.9a.752.752 0 01-.164-1.495l29.896-3.277zm-29.978 2.529a1.504 1.504 0 10.328 2.989l29.896-3.277a1.504 1.504 0 00-.328-2.99l-29.896 3.277zM44.374 6.444a.752.752 0 01.83.666l.409 3.737a.752.752 0 01-.666.83l-29.895 3.276a.752.752 0 01-.83-.666l-.41-3.737a.752.752 0 01.666-.829l29.896-3.277zm-29.978 2.53a1.504 1.504 0 00-1.33 1.658l.409 3.737c.09.826.833 1.422 1.659 1.331l29.895-3.276a1.504 1.504 0 001.331-1.66l-.41-3.736a1.504 1.504 0 00-1.658-1.331L14.396 8.974z"
        fill="#000"
        fillOpacity={0.15}
      />
    </svg>
  );
}

export default SvgPersonDocumentBackImage;
