import { useMutation } from '@ampli/services';
import { PAY_INVOICE_BY_PIX } from './queries';
import { MutationPayInvoiceByPixHook } from './types';

export const useMutationPayInvoiceByPix: MutationPayInvoiceByPixHook = ({
  options,
}) => {
  const [mutate, metadata] = useMutation(PAY_INVOICE_BY_PIX, {
    ...options,
    context: {
      autoHandle: true,
    },
  });

  return [mutate, metadata];
};
