import styled from '@emotion/styled';
import {
  convertPXToREM,
  convertPXToVH,
  convertPXToVW,
  mediaQuery,
} from '@sofia/ui';

export const SkeletonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Hr = styled.hr`
  border-width: 0;
  height: 1px;
  background: ${({ theme }) => theme.colors.separator.color};
  width: 100%;
  margin: 32px auto;

  ${mediaQuery('desktop')} {
    margin: 56px auto;
  }
`;

export const Footer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
  margin: 0 auto;
  max-width: ${convertPXToREM(712)};
  ${mediaQuery('desktop')} {
    padding: 0px 16px;
  }
`;

export const RatingSection = styled.div`
  padding: ${convertPXToVH(32)} ${convertPXToVW(16)};
  background-color: ${({ theme }) => theme.colors.background.primary};
  z-index: 0;

  ${mediaQuery('desktop')} {
    padding: ${convertPXToVH(56)} 0;
  }
`;

export const Container = styled.div`
  margin-top: ${convertPXToVH(50)};
  padding: 0px;

  ${mediaQuery('desktop')} {
    margin: ${convertPXToVH(35)} ${convertPXToVW(35)};
  }
`;

export const CardContainer = styled.div`
  padding: 0 ${convertPXToVW(57)};

  ${mediaQuery('desktop')} {
    padding: 0 ${convertPXToVW(15)};
  }
`;
