import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { NotesRoutesPath as routePath } from '../../../../modules';
import { Typography, SvgArrowRight, Card } from '@sofia/ui';
import { clickByKey } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import { pushAmpliEvent } from '../../../../lib/ga';
import * as Styled from './subject-notes-card-styles';

interface SubjectNotesCardProps {
  subjectEnrollmentId: string;
  courseType: string;
  courseVersionId: string;
}

const SubjectNotesCard = React.forwardRef(
  (
    { courseType, courseVersionId, subjectEnrollmentId }: SubjectNotesCardProps,
    ref: React.ForwardedRef<HTMLElement>
  ) => {
    const history = useHistory();
    const theme = useTheme();
    const breakpoint = useBreakpoint();
    const isDesktop = breakpoint === 'desktop';

    const onClick = () => {
      pushAmpliEvent('event', 'subject_notes_card');
      history.push(
        generatePath(routePath.subjectNotes, {
          courseType,
          courseVersionId,
          subjectEnrollmentId,
        })
      );
    };

    return (
      <Card
        ref={ref}
        hover={theme.colors.card.color.hover}
        borderColor={theme.colors.card.border.primary}
        minHeight="initial"
        onClick={onClick}
        onKeyDown={(event: KeyboardEvent) => clickByKey(event, onClick)}
        tabIndex={0}
        padding="21px 24px"
        id="subject-notes-card"
      >
        <Styled.Wrapper>
          <Styled.TitleContent>
            <Styled.PrefixIcon fill={theme.colors.text.primary} />
            <Typography
              id="subject-notes-title"
              color={theme.colors.text.black}
              size={isDesktop ? 20 : 16}
              margin={0}
            >
              Minhas anotações
            </Typography>
          </Styled.TitleContent>

          <SvgArrowRight fill={theme.colors.text.primary} aria-hidden />
        </Styled.Wrapper>
      </Card>
    );
  }
);

SubjectNotesCard.displayName = 'SubjectNotesCard';

export default SubjectNotesCard;
