import { useTheme } from '@emotion/react';
import { Typography } from '@sofia/ui';
import React from 'react';

import { Container, Counter } from './character-counter.styles';
import { ICharacterCouterProps } from './character-counter.types';
import useCharacterLogic from './character-counter.logic';

const CharacterCounter: React.FC<ICharacterCouterProps> = ({
  currentCharactersCount = 0,
  charactersLimit = 0,
  minCharacters,
}: ICharacterCouterProps): React.ReactElement => {
  const theme = useTheme();
  const { actions } = useCharacterLogic();

  return (
    <Container>
      <Counter>
        <Typography
          size={12}
          weight={600}
          color={actions.getCounterTextColor({
            currentCharactersCount,
            charactersLimit,
          })}
        >
          {`${currentCharactersCount}/${charactersLimit}`}
        </Typography>
      </Counter>

      {minCharacters ? (
        <Typography
          size={12}
          weight={600}
          color={theme.colors.text.lighterBlack}
        >
          {`(Mínimo ${minCharacters} caracteres)`}
        </Typography>
      ) : null}
    </Container>
  );
};

export default CharacterCounter;
