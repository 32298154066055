import React from 'react';
import { cx, css, mediaQuery, getSpaceSize } from '@ampli/ui';
import { string, number, oneOfType } from '@ampli/utils';

const LayoutWrapper = ({ padding = 'huge', ...props }) => (
  <div
    {...props}
    className={cx(
      'layout-wrapper',
      css`
        box-sizing: content-box;
        display: flex;
        flex: 1;
        flex-direction: column;
        position: relative;

        ${mediaQuery('tablet-max')} {
          padding-bottom: ${getSpaceSize(padding)};
        }
      `,
      props.className
    )}
  />
);

LayoutWrapper.propTypes = {
  className: string,
  padding: oneOfType([string, number]),
};

export default LayoutWrapper;
