import styled from '@emotion/styled';
import { convertPXToREM, mediaQuery } from '@sofia/ui';

export const ReadingRuler = styled.div`
  margin-top: -48px;
  position: relative;
  height: 48px;
  width: 100%;
  background: ${({
    isCollingForbiddenEl,
  }: {
    isCollingForbiddenEl?: boolean;
  }) => (isCollingForbiddenEl ? 'transparent' : '#bbbbbb30')}}
`;

export const RulerBtn = styled('button')(
  ({ theme }) => `
    svg {
      margin: 10px;
      height: 100%;
    }
    ${mediaQuery('desktop')} {
      margin-right: 24px;
      flex-direction: row;
      padding: 8px 16px;
      svg {
        margin: 0 10px 0 0;
      }
    }
    span {
      font-size: ${convertPXToREM(16)};
      font-weight: 400;
      line-height: 150%;
    }
    :hover, &[aria-expanded='true'] {
      background: ${theme.colors.background.secondary};
    }

    display: flex;
    align-items: center;
    flex-direction: column;
    background: transparent;
    border: solid 1px transparent;
    border-radius: 8px;
`
);
