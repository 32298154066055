import styled from '@emotion/styled';
import { Typography } from '@sofia/ui';

export const Footer = styled.div`
  margin-top: 32px;
`;

export const TitleModal = styled(Typography)`
  position: relative;
  font-weight: bold;
  padding: 4px 4px 4px 12px;
  margin: 16px 0;
  line-height: 120%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 1px;
    width: 3px;
    height: 100%;
    border-radius: 10px;
    background-color: ${({ Bcolor }) => Bcolor};
  }
`;

export const MessageBox = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: ${({ type, theme }) =>
    type === 'INFO'
      ? theme.colors.icon.background
      : theme.colors.card.background.warning};

  margin-bottom: 24px;

  > p {
    margin: 0;
  }
`;

export const DescriptionModal = styled(Typography)``;

export const CardModalWrapper = styled.div`
  display: flex;
  padding: 8px;
`;

export const CardModalLocationWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;

  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 8px;
  padding: 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.card.background.secondary};
  }
`;

export const ModalFooterWrapper = styled(CardModalWrapper)`
  margin: 32px 0;
  justify-content: center;

  button {
    margin: 0 24px;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;

  > button {
    margin: 0;
  }
`;

export const ModalCard = styled(Card)`
  margin: 16px 0;
`;

export const CardRow = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  margin: 8px 0;

  p {
    white-space: nowrap;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    width: 4px;
    height: 100%;
    background-color: ${({ color }) => color};
  }
`;

export const MobileWrapper = styled.div`
  background: white;
  display: ${({ isModalOpen }) => (isModalOpen ? 'block' : 'none')};
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100000000000000000;
  padding: 0px 32px 32px 32px;
  inset: 0;
`;

export const MobileHeaderWrapper = styled.div`
  background: white;
  cursor: pointer;
  position: relative;
  top: 0;
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
`;

export const MobileHeaderText = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 8px;
  width: 100%;
`;
