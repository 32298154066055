import React from 'react';
import { History } from 'history';

export type FirstLearningObjectHook = (props: {
  learningObjectPath: string;
  firstLearningObjectPath: string;
  baseUrl: string;
  locationState: { backIterations?: number };
  history: History;
}) => void;

const useFirstLearningObject: FirstLearningObjectHook = ({
  learningObjectPath,
  firstLearningObjectPath,
  baseUrl,
  locationState,
  history,
}) => {
  React.useEffect(() => {
    if (!learningObjectPath && firstLearningObjectPath) {
      history.replace(`${baseUrl}/${firstLearningObjectPath}`, locationState);
    }
  }, [
    learningObjectPath,
    firstLearningObjectPath,
    history,
    baseUrl,
    locationState,
  ]);
};

export default useFirstLearningObject;
