import styled from '@emotion/styled';
import { convertPXToREM, mediaQuery } from '@sofia/ui';

export const ActionButtonsNavigationContainer = styled.div<{
  isFirstStep?: boolean;
  isDesktop: boolean;
}>`
  display: flex;
  font-size: ${convertPXToREM(16)};
  align-items: ${({ isDesktop }) => (isDesktop ? 'center' : 'auto')};
  justify-content: ${({ isFirstStep }) =>
    isFirstStep ? 'flex-end' : 'space-between'};
  button {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    &[type='button'] {
      font-weight: 500;
      border-radius: 8px;
      height: 56px;
    }
    svg {
      margin: 0;
    }
  }
`;

export const ActionButtonsPreview = styled.div`
  button {
    margin-right: 16px;
    background: linear-gradient(#f3f6f9, #f3f6f9) padding-box,
      ${({ theme }) => theme.colors.button.primary} border-box;
    span {
      margin-left: 10px;
    }
    ${mediaQuery('tablet-max')} {
      max-height: 56px;
    }
  }
`;

export const ActionButtonsNext = styled.div`
  button {
    :not(:disabled):not([type='button']):hover {
      background-color: ${({ theme }) => theme.colors.button.darkPrimary};
    }
    span {
      margin-right: 10px;
    }
  }
`;
