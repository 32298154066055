import { useEffect } from 'react';
import { string } from '@ampli/utils';
const ScrollToTop = _ref => {
  let {
    pathname,
    action
  } = _ref;
  useEffect(() => {
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [pathname, action]);
  return null;
};
ScrollToTop.propTypes = {
  pathname: string.isRequired,
  action: string.isRequired
};
export default ScrollToTop;