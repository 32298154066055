import React from 'react';

import { Typography, ButtonPrimary, Modal, ButtonGhost } from '@sofia/ui';
import { string, func, node, bool, object } from 'prop-types';
import { useTheme } from '@emotion/react';

import * as Styles from './modal.styles';

const TourModal = ({
  modalHandler,
  content = null,
  modalStyles = {},
  locale = { confirm: 'Confirmar', cancel: 'Cancelar', isButtonLong: false },
  hideCancel = false,
  onConfirm = () => {},
  onCancel = () => {},
  shouldCloseOnClickOut = false,
  renderBeforeButtons,
}) => {
  const theme = useTheme();

  return (
    <Modal
      handleModal={modalHandler}
      style={modalStyles}
      hideCloseIcon
      shouldCloseOnClickOutside={shouldCloseOnClickOut}
      shouldCloseOnEsc={shouldCloseOnClickOut}
      footer={
        <Styles.FooterModal>
          {renderBeforeButtons && renderBeforeButtons()}
          <ButtonPrimary
            {...(locale.isButtonLong && { width: '100%' })}
            margin="0px"
            color={theme.colors.text.white}
            background={theme.colors.button.primary}
            onClick={onConfirm}
          >
            {locale.confirm}
            {locale.icon && locale.icon()}
          </ButtonPrimary>
          {!hideCancel ? (
            <ButtonGhost color={theme.colors.text.primary} onClick={onCancel}>
              <Typography margin="3px 0">{locale.cancel}</Typography>
            </ButtonGhost>
          ) : null}
        </Styles.FooterModal>
      }
    >
      <Styles.Content>{content}</Styles.Content>
    </Modal>
  );
};

TourModal.propTypes = {
  modalHandler: { hide: func, show: func },
  modalStyles: object,
  content: node,
  locale: { confirm: string, cancel: string, icon: func, isButtonLong: bool },
  onConfirm: func,
  onCancel: func,
  hideCancel: bool,
  shouldCloseOnClickOut: bool,
  renderBeforeButtons: func,
};

export default TourModal;
