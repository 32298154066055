import { GET_INTERNSHIP } from './queries';
import { useQuery } from '@ampli/services';

export const useGetInternship = (subjectEnrollmentId) => {
  const { data, loading, error } = useQuery(GET_INTERNSHIP, {
    variables: {
      subjectEnrollmentId,
    },
    fetchPolicy: 'no-cache',
    context: {
      autoHandle: true,
    },
  });
  return { data: data?.data, loading, error };
};
