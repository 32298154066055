/* eslint-disable */
(function () {
  var b = function () {
    function b(c, a) {
      var b, k;
      null == a && (a = {});
      this._isPlaying = this.playerDestroyed = this._isReady = !1;
      var f = [];
      document.getElementById(c) || f.push('ID must be a valid DOM Element');
      /^[0-9a-fA-F]{24}$/.test(a.id) || this._getYouTubeID(a.id) || f.push('Options.id must be a valid Platform Video ID or a YouTube Video ID');
      'media' !== (b = a.type) && 'live' !== b && 'dvr' !== b && f.push('Options.type must be "media", "live" or "dvr"');
      isNaN(parseInt(a.width)) && f.push('Options.width must be a positive integer');
      isNaN(parseInt(a.height)) && f.push('Options.height must be a positive integer');
      if ('http' === (k = a.protocol) || 'https' === k) this._protocol = a.protocol;
      0 < f.length ? this._error(f) : this._init(c, a);
    }
    b.prototype._init = function (c, a) {
      var b, k, f, q, r, t, p, u, v, w, x, y, z, A, B, C, D, E, F, G, l, H, m, I, J, n, K, L, M, N, O, P, Q;
      this._callbacks = {};
      this._bindEvent(window, 'message', function (a) {
        return function (c) {
          if (!a.playerDestroyed && (null != c ? c.source : void 0) === a._IFrameContent) return a._receiveMessage(c);
        };
      }(this));
      this._events = {};
      var d = 'onAdsAdBreakReady onAdsAdMetadata onAdsAllAdsCompleted onAdsClick onAdsComplete onAdsContentPauseRequested onAdsContentResumeRequested onAdsDurationChange onAdsFirstQuartile onAdsImpression onAdsLinearChanged onAdsLoaded onAdsLog onAdsMidpoint onAdsPaused onAdsResumed onAdsSkippableStateChanged onAdsSkipped onAdsStarted onAdsThirdQuartile onAdsUserClose onAdsVolumeChanged onAdsVolumeMuted'.split(' ');
      var h = 0;
      for (k = d.length; h < k; h++) {
        var e = d[h];
        'function' === typeof (null != (f = a.events) ? f[e] : void 0) && (this._events[e] = a.events[e]);
      }
      'function' === typeof (null != (q = a.events) ? q.onPlayerMounted : void 0) && (this._events.onPlayerMounted = a.events.onPlayerMounted);
      'function' === typeof (null != (B = a.events) ? B.onPlayerReady : void 0) && (this._events.onPlayerReady = a.events.onPlayerReady);
      'function' === typeof (null != (H = a.events) ? H.onVideoEnd : void 0) && (this._events.onVideoEnd = a.events.onVideoEnd);
      'function' === typeof (null != (L = a.events) ? L.onVideoStop : void 0) && (this._events.onVideoStop = a.events.onVideoStop);
      'function' === typeof (null != (M = a.events) ? M.onVideoError : void 0) && (this._events.onVideoError = a.events.onVideoError);
      'function' === typeof (null != (N = a.events) ? N.onVolumeChange : void 0) && (this._events.onVolumeChange = a.events.onVolumeChange);
      'function' === typeof (null != (O = a.events) ? O.onPlay : void 0) && (this._events.onPlay = a.events.onPlay);
      'function' === typeof (null != (P = a.events) ? P.onSeeked : void 0) && (this._events.onSeeked = a.events.onSeeked);
      'function' === typeof (null != (Q = a.events) ? Q.onSeeking : void 0) && (this._events.onSeeking = a.events.onSeeking);
      'function' === typeof (null != (r = a.events) ? r.onReplay : void 0) && (this._events.onReplay = a.events.onReplay);
      'function' === typeof (null != (t = a.events) ? t.onProgramDateTime : void 0) && (this._events.onProgramDateTime = a.events.onProgramDateTime);
      'function' === typeof (null != (p = a.events) ? p.onTimeUpdate : void 0) && (this._events.onTimeUpdate = a.events.onTimeUpdate);
      'function' === typeof (null != (u = a.events) ? u.onFullscreenChange : void 0) && (this._events.onFullscreenChange = a.events.onFullscreenChange);
      'function' === typeof (null != (v = a.events) ? v.onBuffering : void 0) && (this._events.onBuffering = a.events.onBuffering);
      'function' === typeof (null != (w = a.events) ? w.onFragChanged : void 0) && (this._events.onFragChanged = a.events.onFragChanged);
      'function' === typeof (null != (x = a.events) ? x.onCastConnected : void 0) && (this._events.onCastConnected = a.events.onCastConnected);
      'function' === typeof (null != (y = a.events) ? y.onCastDisconnected : void 0) && (this._events.onCastDisconnected = a.events.onCastDisconnected);
      'function' === typeof (null != (z = a.events) ? z.onCastRemoteDevicePaused : void 0) && (this._events.onCastRemoteDevicePaused = a.events.onCastRemoteDevicePaused);
      'function' === typeof (null != (A = a.events) ? A.oncastRemoteDevicePlaying : void 0) && (this._events.oncastRemoteDevicePlaying = a.events.oncastRemoteDevicePlaying);
      'function' === typeof (null != (C = a.events) ? C.onPlaylistStatusChange : void 0) && (this._events.onPlaylistStatusChange = a.events.onPlaylistStatusChange);
      'function' === typeof (null != (D = a.events) ? D.onChangeLocation : void 0) && (this._events.onChangeLocation = a.events.onChangeLocation);
      'function' === typeof (null != (E = a.events) ? E.custom : void 0) && (this._events.custom = a.events.custom);
      this._IFrameURLOptions = [];
      this._IFrameURLOptions.push('jsapi\x3dtrue');
      null != a.loop && this._IFrameURLOptions.push('loop\x3d' + encodeURIComponent(a.loop));
      null != a.pause_ad_on_click && this._IFrameURLOptions.push('pause_ad_on_click\x3d' + encodeURIComponent(a.pause_ad_on_click));
      null != a.skip_ad_on_touch && this._IFrameURLOptions.push('skip_ad_on_touch\x3d' + encodeURIComponent(a.skip_ad_on_touch));
      null != a.show_controls_on_ad && this._IFrameURLOptions.push('show_controls_on_ad\x3d' + encodeURIComponent(a.show_controls_on_ad));
      null != a.pause_on_screen_click && this._IFrameURLOptions.push('pause_on_screen_click\x3d' + encodeURIComponent(a.pause_on_screen_click));
      null != a.autoplay && this._IFrameURLOptions.push('autoplay\x3d' + encodeURIComponent(a.autoplay));
      null != a.pip && this._IFrameURLOptions.push('pip\x3d' + encodeURIComponent(a.pip));
      null != a.controls && this._IFrameURLOptions.push('controls\x3d' + encodeURIComponent(a.controls));
      null != a.useMobileNativeControls && this._IFrameURLOptions.push('useMobileNativeControls\x3d' + encodeURIComponent(a.useMobileNativeControls));
      null != a.volume && this._IFrameURLOptions.push('volume\x3d' + encodeURIComponent(a.volume));
      a.player && this._IFrameURLOptions.push('player\x3d' + encodeURIComponent(a.player));
      a.skin && this._IFrameURLOptions.push('skin\x3d' + encodeURIComponent(a.skin));
      a.ref && this._IFrameURLOptions.push('ref\x3d' + encodeURIComponent(a.ref));
      null != a.startTime && this._IFrameURLOptions.push('starttime\x3d' + encodeURIComponent(a.startTime));
      null != a.endTime && this._IFrameURLOptions.push('endtime\x3d' + encodeURIComponent(a.endTime));
      null != a.title && this._IFrameURLOptions.push('title\x3d' + encodeURIComponent(a.title));
      null != a.show_title && this._IFrameURLOptions.push('show_title\x3d' + encodeURIComponent(a.show_title));
      null != a.show_timeline_time && this._IFrameURLOptions.push('show_timeline_time\x3d' + encodeURIComponent(a.show_timeline_time));
      null != a.access_token && this._IFrameURLOptions.push('access_token\x3d' + encodeURIComponent(a.access_token));
      null != a.admin_token && this._IFrameURLOptions.push('admin_token\x3d' + encodeURIComponent(a.admin_token));
      null != a.acc_token && this._IFrameURLOptions.push('acc_token\x3d' + encodeURIComponent(a.acc_token));
      null != a.source && this._IFrameURLOptions.push('source\x3d' + encodeURIComponent(a.source));
      null != a.poster && this._IFrameURLOptions.push('poster\x3d' + encodeURIComponent(a.poster));
      null != a.customer && this._IFrameURLOptions.push('c\x3d' + encodeURIComponent(a.customer));
      null != a.distributor && this._IFrameURLOptions.push('ds\x3d' + encodeURIComponent(a.distributor));
      null != a.analyticsCustom && this._IFrameURLOptions.push('ac\x3d' + encodeURIComponent(a.analyticsCustom));
      null != a.rendition_rule && this._IFrameURLOptions.push('rendition_rule\x3d' + encodeURIComponent(a.rendition_rule));
      null != a.dnt && this._IFrameURLOptions.push('dnt\x3d' + encodeURIComponent(a.dnt));
      null != a.show_previews && this._IFrameURLOptions.push('show_previews\x3d' + encodeURIComponent(a.show_previews));
      null != a.playlistId && this._IFrameURLOptions.push('playlistId\x3d' + encodeURIComponent(a.playlistId));
      null != a.player_skin && this._IFrameURLOptions.push('player_skin\x3d' + encodeURIComponent(a.player_skin));
      null != a.subtitles && this._IFrameURLOptions.push('subtitles\x3d' + encodeURIComponent(a.subtitles));
      null != a.mse_buffer_length && this._IFrameURLOptions.push('mse_buffer_length\x3d' + encodeURIComponent(a.mse_buffer_length));
      null != a.mse_buffer_size && this._IFrameURLOptions.push('mse_buffer_size\x3d' + encodeURIComponent(a.mse_buffer_size));
      null != a.maxProfile && this._IFrameURLOptions.push('max_profile\x3d' + encodeURIComponent(a.maxProfile));
      null != a.firstProfile && this._IFrameURLOptions.push('first_profile\x3d' + encodeURIComponent(a.firstProfile));
      null != (null != (F = a.style) ? F.basecolor : void 0) && this._IFrameURLOptions.push('style[basecolor]\x3d' + encodeURIComponent(a.style.basecolor));
      null != (null != (G = a.style) ? G.backgroundcolor : void 0) && this._IFrameURLOptions.push('style[backgroundcolor]\x3d' + encodeURIComponent(a.style.backgroundcolor));
      !0 === a.mse && this._IFrameURLOptions.push('mse\x3dtrue');
      !0 === a.rtsp && this._IFrameURLOptions.push('rtsp\x3dtrue');
      !0 === a.debug && this._IFrameURLOptions.push('debug\x3dtrue');
      !0 === a.ima_debug && this._IFrameURLOptions.push('ima_debug\x3dtrue');
      !0 === a.no_ad && this._IFrameURLOptions.push('no_ad\x3dtrue');
      'dvr' === a.type && this._IFrameURLOptions.push('type\x3ddvr');
      null != a.start && this._IFrameURLOptions.push('start\x3d' + new Date(a.start).toISOString());
      null != a.end && this._IFrameURLOptions.push('end\x3d' + new Date(a.end).toISOString());
      null != a.without_cookies && this._IFrameURLOptions.push('without_cookies\x3d' + encodeURIComponent(a.without_cookies));
      h = a.style || {};
      for (b in h) if (d = h[b], 'object' !== typeof d) this._IFrameURLOptions.push('style[' + b + ']\x3d' + encodeURIComponent(d));else for (g in d) e = d[g], this._IFrameURLOptions.push('style[' + b + '][' + g + ']\x3d' + encodeURIComponent(e));
      e = a.ads || {};
      for (b in e) d = e[b], this._IFrameURLOptions.push('ads[' + b + ']\x3d' + encodeURIComponent(d));
      e = a.custom || {};
      for (b in e) d = e[b], this._IFrameURLOptions.push('custom.' + b + '\x3d' + encodeURIComponent(d));
      e = (null != (l = a.youbora) ? l.extra : void 0) || {};
      for (b in e) d = e[b], this._IFrameURLOptions.push('youbora[extra][' + b + ']\x3d' + encodeURIComponent(d));
      l = a.watermark || {};
      for (b in l) d = l[b], this._IFrameURLOptions.push('watermark[' + b + ']\x3d' + encodeURIComponent(d));
      null != (null != (m = a.playlist) ? m.template : void 0) && this._IFrameURLOptions.push('playlist[template]\x3d' + encodeURIComponent(a.playlist.template));
      null != (null != (I = a.playlist) ? I.showControlBarButton : void 0) && this._IFrameURLOptions.push('playlist[showControlBarButton]\x3d' + encodeURIComponent(a.playlist.showControlBarButton));
      null != (null != (J = a.playlist) ? J.autoplay : void 0) && this._IFrameURLOptions.push('playlist[autoplay]\x3d' + encodeURIComponent(a.playlist.autoplay));
      m = (null != (n = a.playlist) ? n.items : void 0) || [];
      for (b in m) for (g in n = (d = m[b]) || {}, n) e = n[g], this._IFrameURLOptions.push('playlist[items][' + b + '][' + g + ']\x3d' + encodeURIComponent(e));
      var g = a.custom_related || [];
      for (b in g) d = g[b], this._IFrameURLOptions.push('customrelated[][' + b + ']\x3d' + encodeURIComponent(d));
      this._IFrameURL = [];
      this._IFrameURL.push(this.protocol() + '//' + (window.EMBED_HOST || 'mdstrm.com') + '/');
      this._IFrameURL.push('live' === (K = a.type) || 'dvr' === K ? 'live-stream' : 'embed');
      this._IFrameURL.push('/');
      this._getYouTubeID(a.id) && this._IFrameURL.push('y/');
      this._IFrameURL.push(this._getYouTubeID(a.id) || a.id);
      this._IFrameURL.push('?');
      this._IFrameURL.push(this._IFrameURLOptions.join('\x26'));
      this._playerContainer = document.getElementById(c);
      this._IFrameContainer = document.createElement('IFRAME');
      a.className && (this._IFrameContainer['class'] = [].concat.apply([], [a.className]).join(' '));
      this._IFrameContainer.src = this._IFrameURL.join('');
      a.width && (this._IFrameContainer.width = parseInt(a.width));
      a.height && (this._IFrameContainer.height = parseInt(a.height));
      this._IFrameContainer.frameBorder = 0;
      this._IFrameContainer.setAttribute('allow', 'autoplay; fullscreen; encrypted-media');
      this._IFrameContainer.setAttribute('allowfullscreen', '');
      this._IFrameContainer.setAttribute('allowscriptaccess', 'always');
      this._IFrameContainer.setAttribute('scrolling', 'no');
      this._playerContainer.innerHTML = '';
      this._playerContainer.appendChild(this._IFrameContainer);
      return this._IFrameContent = this._IFrameContainer.contentWindow;
    };
    b.prototype.protocol = function () {
      return this._protocol ? this._protocol + ':' : /^http/i.test(location.protocol) ? location.protocol : 'https:';
    };
    b.prototype._bindEvent = function (c, a, b) {
      if (null != c) return c.addEventListener ? c.addEventListener(a, b, !1) : c.attachEvent('on' + a, b);
    };
    b.prototype.destroy = function () {
      this._playerContainer.innerHTML = '';
      return this.playerDestroyed = !0;
    };
    b.prototype._receiveMessage = function (c) {
      var a, b, k, f, q, r, t, p, u, v, w, x, y, z, A, B, C, D, E, F, G, l, H, m, I, J, n, K, L, M, N, O, P, Q, d, h, e, g, S, T, U, V, W, X, Y, Z, aa, ba, ca, da, ea, fa, ha, ia, ja, ka, la, ma, na, oa, pa, qa, ra, sa, ta;
      c = null != c ? c.data : void 0;
      switch (null != c ? c.eventName : void 0) {
        case 'onPlayerMounted':
          return this._isReady = !0, null != (f = this._events) ? null != (q = f.onPlayerMounted) ? q.apply(this, [null != (A = c.eventData) ? A : {}]) : void 0 : void 0;
        case 'onPlayerReady':
          return this._isReady = !0, null != (J = this._events) ? null != (e = J.onPlayerReady) ? e.apply(this, [null != (ba = c.eventData) ? ba : {}]) : void 0 : void 0;
        case 'onVideoEnd':
          return this._isPlaying = !1, null != (na = this._events) ? null != (ra = na.onVideoEnd) ? ra.apply(this, [null != (sa = c.eventData) ? sa : {}]) : void 0 : void 0;
        case 'onVideoStop':
          return this._isPlaying = !1, null != (ta = this._events) ? null != (r = ta.onVideoStop) ? r.apply(this, [null != (t = c.eventData) ? t : {}]) : void 0 : void 0;
        case 'onVideoError':
          var R = null != (b = c.eventData) ? b : {};
          try {
            R = JSON.parse(R);
          } catch (va) {}
          this._isPlaying = !1;
          return null != (p = this._events) ? null != (u = p.onVideoError) ? u.apply(this, [R]) : void 0 : void 0;
        case 'onVolumeChange':
          return null != (v = this._events) ? null != (w = v.onVolumeChange) ? w.apply(this, [null != (x = c.eventData) ? x : {}]) : void 0 : void 0;
        case 'onPlay':
          return this._isPlaying = !0, null != (y = this._events) ? null != (z = y.onPlay) ? z.apply(this, [null != (B = c.eventData) ? B : {}]) : void 0 : void 0;
        case 'onSeeked':
          return null != (C = this._events) ? null != (D = C.onSeeked) ? D.apply(this, [null != (E = c.eventData) ? E : {}]) : void 0 : void 0;
        case 'onSeeking':
          return null != (F = this._events) ? null != (G = F.onSeeking) ? G.apply(this, [null != (l = c.eventData) ? l : {}]) : void 0 : void 0;
        case 'onReplay':
          return null != (H = this._events) ? null != (m = H.onReplay) ? m.apply(this, [null != (I = c.eventData) ? I : {}]) : void 0 : void 0;
        case 'onProgramDateTime':
          return null != (n = this._events) ? null != (K = n.onProgramDateTime) ? K.apply(this, [null != (L = c.eventData) ? L : {}]) : void 0 : void 0;
        case 'onTimeUpdate':
          return null != (M = this._events) ? null != (N = M.onTimeUpdate) ? N.apply(this, [null != (O = c.eventData) ? O : {}]) : void 0 : void 0;
        case 'onFullscreenChange':
          return null != (P = this._events) ? null != (Q = P.onFullscreenChange) ? Q.apply(this, [null != (d = c.eventData) ? d : {}]) : void 0 : void 0;
        case 'onBuffering':
          return null != (h = this._events) ? null != (g = h.onBuffering) ? g.apply(this, [null != (S = c.eventData) ? S : {}]) : void 0 : void 0;
        case 'onFragChanged':
          return null != (T = this._events) ? null != (U = T.onFragChanged) ? U.apply(this, [null != (V = c.eventData) ? V : {}]) : void 0 : void 0;
        case 'onCastConnected':
          return null != (W = this._events) ? null != (X = W.onCastConnected) ? X.apply(this, [null != (Y = c.eventData) ? Y : {}]) : void 0 : void 0;
        case 'onCastDisconnected':
          return null != (Z = this._events) ? null != (aa = Z.onCastDisconnected) ? aa.apply(this, [null != (ca = c.eventData) ? ca : {}]) : void 0 : void 0;
        case 'onCastRemoteDevicePaused':
          return null != (da = this._events) ? null != (ea = da.onCastRemoteDevicePaused) ? ea.apply(this, [null != (fa = c.eventData) ? fa : {}]) : void 0 : void 0;
        case 'onCastRemoteDevicePlaying':
          return null != (ha = this._events) ? null != (ia = ha.onCastRemoteDevicePlaying) ? ia.apply(this, [null != (ja = c.eventData) ? ja : {}]) : void 0 : void 0;
        case 'onPlaylistStatusChange':
          return null != (ka = this._events) ? null != (la = ka.onPlaylistStatusChange) ? la.apply(this, [null != (ma = c.eventData) ? ma : {}]) : void 0 : void 0;
        case 'onChangeLocation':
          return b = c.eventData, null != (p = this._events) && null != (R = p.onChangeLocation) && R.apply(this, [null != b ? b : {}]), null != b && b.parent ? window.parent.location = b.url : this._IFrameContainer.src = b.url;
        default:
          return null != (oa = this._events) && oa[null != c ? c.eventName : void 0] ? this._events[c.eventName].apply(this, [null != (pa = c.eventData) ? pa : {}]) : 'function' === typeof (a = this._callbacks)[k = null != c ? c.eventName : void 0] ? a[k](null != (qa = c.eventData) ? qa : null) : void 0;
      }
    };
    b.prototype._postMessage = function (b) {
      var a;
      return this._isReady && !this.playerDestroyed ? null != (a = this._IFrameContent) ? a.postMessage(b, this.protocol() + '//' + (window.EMBED_HOST || 'mdstrm.com')) : void 0 : void 0;
    };
    b.prototype._getMessage = function (b, a) {
      for (var c; null == c || null != this._callbacks[c];) c = Math.random().toString(32).substr(2);
      this._callbacks[c] = function (b) {
        return function () {
          'function' === typeof a && a.apply(null, arguments);
          return delete b._callbacks[c];
        };
      }(this);
      return this._postMessage({
        cb: c,
        eventName: b.action || b,
        data: b.data
      });
    };
    b.prototype._getYouTubeID = function (b) {
      var a;
      null == b && (b = '');
      return null != (a = b.match(/^youtube:(.+)/i)) ? a[1] : void 0;
    };
    b.prototype._log = function (b) {
      null == b && (b = []);
      return 'undefined' !== typeof console && null !== console ? console.log('[MDSTRM.Player] ' + b.join('. ')) : void 0;
    };
    b.prototype._error = function (b) {
      null == b && (b = []);
      return 'undefined' !== typeof console && null !== console ? console.error('[MDSTRM.Player] ' + b.join('. ')) : void 0;
    };
    b.prototype.isReady = function () {
      return this._isReady && !this.playerDestroyed;
    };
    b.prototype.isPlaying = function () {
      return this._isPlaying;
    };
    b.prototype.requestAds = function (b) {
      return b ? (this._postMessage({
        action: 'requestAds',
        data: b
      }), !0) : !1;
    };
    b.prototype.clickCustomPlaylist = function (b, a) {
      null == a && (a = function () {});
      return this._getMessage({
        action: 'clickCustomPlaylist',
        data: b
      }, a);
    };
    b.prototype.seekTo = function (b, a) {
      null == b && (b = 0);
      null == a && (a = function () {});
      return this._getMessage({
        action: 'seekTo',
        data: b
      }, a);
    };
    b.prototype.videoPlay = function (b) {
      null == b && (b = function () {});
      return this._getMessage('videoPlay', b);
    };
    b.prototype.videoStop = function (b) {
      null == b && (b = function () {});
      return this._getMessage('videoStop', b);
    };
    b.prototype.getCurrentTime = function (b) {
      return this._getMessage('getCurrentTime', b);
    };
    b.prototype.getVideoMetrics = function (b) {
      return this._getMessage('getVideoMetrics', b);
    };
    b.prototype.setVolume = function (b, a) {
      null == a && (a = function () {});
      return this._getMessage({
        action: 'setVolume',
        data: b
      }, a);
    };
    b.prototype.setSrc = function (b, a) {
      null == a && (a = function () {});
      return this._getMessage({
        action: 'setSrc',
        data: b
      }, a);
    };
    b.prototype.setAdsVolume = function (b, a) {
      null == a && (a = function () {});
      return this._getMessage({
        action: 'setAdsVolume',
        data: b
      }, a);
    };
    b.prototype.getDuration = function (b) {
      return this._getMessage('getDuration', b);
    };
    b.prototype.getSnapshot = function (b, a, ua) {
      return this._getMessage({
        action: 'getSnapshot',
        data: {
          height: a,
          width: b
        }
      }, ua);
    };
    b.prototype.playbackLevel = function (b, a) {
      null == a && (a = function () {});
      return this._getMessage({
        action: 'playbackLevel',
        data: b
      }, a);
    };
    b.prototype.playbackLevels = function (b) {
      return this._getMessage('playbackLevels', b);
    };
    b.prototype.getPlaylist = function (b) {
      return this._getMessage('getPlaylist', b);
    };
    b.prototype.toggleFullScreen = function (b) {
      return this._getMessage('toggleFullScreen', b);
    };
    b.prototype.addMarker = function (b, a) {
      null == a && (a = function () {});
      return this._getMessage({
        action: 'addMarker',
        data: b
      }, a);
    };
    b.prototype.clearMarkers = function (b) {
      return this._getMessage('clearMarkers', b);
    };
    b.prototype.stopCast = function (b) {
      null == b && (b = function () {});
      return this._getMessage('stopCast', b);
    };
    return b;
  }();
  window.MediastreamPlayer = b;
  b.prototype.isReady = b.prototype.isReady;
  b.prototype.isPlaying = b.prototype.isPlaying;
  b.prototype.requestAds = b.prototype.requestAds;
  b.prototype.seekTo = b.prototype.seekTo;
  b.prototype.videoPlay = b.prototype.videoPlay;
  b.prototype.videoStop = b.prototype.videoStop;
  b.prototype.getCurrentTime = b.prototype.getCurrentTime;
  b.prototype.getVideoMetrics = b.prototype.getVideoMetrics;
  b.prototype.setVolume = b.prototype.setVolume;
  b.prototype.setSrc = b.prototype.setSrc;
  b.prototype.setAdsVolume = b.prototype.setAdsVolume;
  b.prototype.getDuration = b.prototype.getDuration;
  b.prototype.getSnapshot = b.prototype.getSnapshot;
  b.prototype.destroy = b.prototype.destroy;
  b.prototype.playbackLevel = b.prototype.playbackLevel;
  b.prototype.playbackLevels = b.prototype.playbackLevels;
  b.prototype.toggleFullScreen = b.prototype.toggleFullScreen;
  b.prototype.getPlaylist = b.prototype.getPlaylist;
  b.prototype.addMarker = b.prototype.addMarker;
  b.prototype.clearMarkers = b.prototype.clearMarkers;
  b.prototype.clickCustomPlaylist = b.prototype.clickCustomPlaylist;
  b.prototype.stopCast = b.prototype.stopCast;
}).call(this);