import styled from '@emotion/styled';
import convertPXToREM from '../../utils/convertPXToREM';
const getBrowserThumbStyle = _ref => {
  let {
    browser,
    thumbColor,
    hoverShadowColor
  } = _ref;
  const thumbStyle = browser == 'firefox' ? '::-moz-range-thumb' : '::-webkit-slider-thumb';
  const trackStyle = browser == 'firefox' ? '::-moz-range-track' : '::-webkit-slider-runnable-track';
  return `
    ${thumbStyle} {
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: ${thumbColor || '#0041af'};
      cursor: pointer;
      transition: background 0.3s ease-in-out;
      position: relative;
      z-index: 2;
    }

    ${thumbStyle}:hover {
      border-radius: 50%;
      box-shadow: 0 0 0 8px
        ${hoverShadowColor || '#0041af25'};
    }

    ${thumbStyle}:active,
    ${thumbStyle}:focus {
      border-radius: 50%;
      box-shadow: 0 0 0 12px
        ${hoverShadowColor || '#0041af25'};
    }

    :focus {
      outline: none;
      ${thumbStyle} {
        border-radius: 50%;
        box-shadow: 0 0 0 12px
          ${hoverShadowColor || '#0041af25'};
      }
    }

    ${trackStyle} {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
      border-radius: 8px;
    }
`;
};
export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: #b1c2d5;
  border-radius: 8px;

  ${_ref2 => {
  let {
    thumbColor,
    hoverShadowColor
  } = _ref2;
  return `${getBrowserThumbStyle({
    thumbColor,
    hoverShadowColor
  })}
    ${getBrowserThumbStyle({
    browser: 'firefox',
    thumbColor,
    hoverShadowColor
  })}`;
}}
`;
export const Progress = styled.span`
  width: ${_ref3 => {
  let {
    size
  } = _ref3;
  return size;
}}%;
  background: ${_ref4 => {
  let {
    backgroundColor
  } = _ref4;
  return backgroundColor || '#004ecc';
}};
  height: 6px;
  border-radius: 8px;
  display: flex;
  position: absolute;
  left: 0;
  z-index: 0;
`;
export const Marks = styled.div`
  display: flex;
  width: calc(100% - 12px);
  justify-content: space-between;
  position: absolute;
  z-index: 1;
`;
export const Dot = styled.span`
  width: 2px;
  height: 2px;
  background: ${_ref5 => {
  let {
    color
  } = _ref5;
  return color || '#E5EDFA';
}};
  border-radius: 50%;
`;
export const Label = styled.label`
  font-size: ${convertPXToREM(12)};
  left: ${_ref6 => {
  let {
    left
  } = _ref6;
  return left || '50%';
}};
  color: ${_ref7 => {
  let {
    color
  } = _ref7;
  return color || '#495057';
}};
  line-height: 140%;
  padding: 4px 12px;
  border-radius: 4px;

  position: absolute;
  top: 10px;
  transform: translateX(-50%);

  ::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 2px;
    top: -1px;
    left: 50%;
  }
`;