import { TicketCustomFields } from '../types/zendesk';
import { zendeskUrl } from '../../../../../../../config';

export const ticketFormId = 360001804631;
export const createTicketUrl = `${zendeskUrl}/tickets`;

/**
 * field - Field name passed in the function parameters
 * id - Id of the field in the zendesk dashboard
 */

export const zendeskCustomFieldsIds: TicketCustomFields[] = [
  {
    field: 'requesterCpf',
    id: 360031428471,
  },
  {
    field: 'contactReason',
    id: 360044134611,
  },
  {
    field: 'course',
    id: 360033598231,
  },
  {
    field: 'discipline',
    id: 360033582452,
  },
  {
    field: 'unity',
    id: 360033582752,
  },
  {
    field: 'learningObject',
    id: 360033582812,
  },
  {
    field: 'brand',
    id: 18009147144852,
  },
  {
    field: 'internCategory',
    id: 360032943251,
  },
];
