import React, { Suspense } from 'react';

import initializeGoogleAnalytics from './lib/gaInit';
import initializeAxeCore from './lib/axe-core';
import App from './app';

import { initializeStorages } from '@ampli/utils';
import { FullscreenLoader } from '@ampli/ui';
import { hydrate, render } from 'react-dom';

import { initializeDialogflowChat } from './lib/dialogFlowAssistantChat';
import { installAdobe } from './lib/adobe';

import * as serviceWorker from './serviceWorker';

import '@ampli/ui/dist/global-styles';
import './lib/recaptcha';
import './root-styles';
import './lib/logger';

installAdobe();
initializeAxeCore();
initializeDialogflowChat();
initializeGoogleAnalytics();
initializeStorages('student');

const Root = () => (
  <Suspense fallback={<FullscreenLoader />}>
    <App />
  </Suspense>
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
