import { TLearningObject } from '../../../shared';
import { LearningObjectDataReturnType } from '../types';

type ExtractLearningObjectDataType = (
  learningObjects: TLearningObject[],
  learningObjectPath: string
) => LearningObjectDataReturnType;

const extractLearningObjectData: ExtractLearningObjectDataType = (
  learningObjects = [],
  learningObjectPath
) => {
  const learningObjectsToComplete = learningObjects?.filter(
    (learningObject) => !learningObject.completed
  );
  const firstLearningObject =
    (learningObjectsToComplete?.length > 0
      ? learningObjectsToComplete
      : learningObjects)[0] || {};

  const learningObjectIndex = learningObjects.findIndex(
    (learningObject) => learningObject.pathUrl === learningObjectPath
  );
  const learningObject = learningObjects[learningObjectIndex] || {};

  const isLastLearningObjectToComplete =
    learningObjectsToComplete.length === 1 &&
    learningObjectsToComplete[0].id === learningObject?.id;

  const isFirstLearningObject = learningObjectIndex === 0;
  const isLastLearningObject =
    learningObjectIndex === learningObjects.length - 1;

  const previousLearningObject = isFirstLearningObject
    ? null
    : learningObjects[learningObjectIndex - 1];
  const nextLearningObject = isLastLearningObject
    ? null
    : learningObjects[learningObjectIndex + 1];

  return {
    firstLearningObject,
    learningObject,
    learningObjectIndex,
    previousLearningObject,
    nextLearningObject,
    isLastLearningObject,
    isFirstLearningObject,
    isLastLearningObjectToComplete,
  };
};

export default extractLearningObjectData;
