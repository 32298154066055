import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import { useModal } from '@sofia/ui';
import { PopUp } from '../../../../../shared';
import { NotesModal, NotesObjectModalProps } from '../types';
import { Wrapper } from './modal.styles';

const NotesObjectModal = React.forwardRef(
  (
    {
      children,
      footer,
      headerText,
      headerMargin,
      ariaLabel,
      ...props
    }: NotesObjectModalProps,
    ref: React.Ref<NotesModal>
  ) => {
    const breakpoint = useBreakpoint();
    const isDesktop = breakpoint === 'desktop';

    const modal = useModal();

    React.useImperativeHandle(ref, () => ({
      onOpen() {
        modal.show();
      },
      onClose() {
        modal.hide();
      },
    }));

    return (
      <Wrapper>
        <PopUp
          {...props}
          popUpState={modal}
          isDesktop={isDesktop}
          headerText={headerText}
          headerMargin={headerMargin}
          footer={footer}
          ariaLabel={ariaLabel}
        >
          {children}
        </PopUp>
      </Wrapper>
    );
  }
);

NotesObjectModal.displayName = 'NotesObjectModal';

export default NotesObjectModal;
