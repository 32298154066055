import React from 'react';
import { Switch } from 'react-router-dom';
import { GuardedRoute } from 'react-router-guards';
import { useServiceClient } from '@ampli/services';

import {
  QuizCorrectionPage,
  QuizInAnalysisPage,
  QuizOnboardingPage,
  QuizResultsAttemptsPage,
  TakeQuizPage,
} from './lazy-routes';
import { useGetState } from '../state';
import { contentGuard } from '../guards';
import { useQueryParams } from '../hooks';
import { features } from '../config';
import routePath from './routes-path';
import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';

const Routes = () => {
  const { states } = useCourseEnrollmentsContext();
  const response = states.courseEnrollments;

  const handleGuardedRoute = async (to, from, next) => {
    await contentGuard(to, from, next, response);
  };
  const clients = useServiceClient('all');
  const queryParams = useQueryParams();

  const [state, actions] = useGetState();

  const meta = { clients, actions, state, queryParams };
  const authMeta = { ...meta, auth: { redirect: routePath.login } };
  const examMeta = { ...authMeta, toggle: features.exam };
  const quizMeta = { ...authMeta, toggle: features.quiz };

  return (
    <Switch>
      <GuardedRoute
        exact
        path={routePath.quiz}
        meta={quizMeta}
        guards={[handleGuardedRoute]}
      >
        <QuizOnboardingPage />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={routePath.takeQuiz}
        meta={quizMeta}
        guards={[handleGuardedRoute]}
      >
        <TakeQuizPage />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={routePath.resultsAttemptsQuizPage}
        meta={examMeta}
        guards={[handleGuardedRoute]}
      >
        <QuizResultsAttemptsPage />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={routePath.quizInAnalysis}
        meta={quizMeta}
        guards={[handleGuardedRoute]}
      >
        <QuizInAnalysisPage />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={routePath.quizCorrection}
        meta={quizMeta}
        guards={[handleGuardedRoute]}
      >
        <QuizCorrectionPage />
      </GuardedRoute>
    </Switch>
  );
};

export default Routes;
