import { format, isToday } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

export const TextCalendar = (events) => {
  if (events) {
    const getIndexDayOne = events.findIndex((event) => event.day == 1);
    const event = events[getIndexDayOne];
    const qtnEvents = event?.events?.date ? event?.events?.dateEventsSize : 0;
    const formatingDate = new Date(event?.dayFull.replaceAll('-', '/'));
    const month = format(formatingDate, 'MMMM', { locale: pt });
    const year = format(formatingDate, 'yyyy', { locale: pt });
    const week = format(formatingDate, 'EEEE', { locale: pt });
    return `Entrando em tabela de ${month} de ${year} dia selecionado, 1 de ${month} de ${year} ${week}, ${qtnEvents} eventos`;
  }
  return '';
};

export const TextCalendarDay = (event) => {
  const formatingDate = new Date(event?.dayFull.replaceAll('-', '/'));
  const date = format(formatingDate, "d 'de' MMMM 'de' yyyy", { locale: pt });
  const today = isToday(formatingDate) ? 'hoje' : '';
  const qtnEvents = parseInt(event?.events?.dateEventsSize);
  const qtnEventsLabel = qtnEvents
    ? `${qtnEvents} ${qtnEvents > 1 ? 'eventos' : 'evento'} na sua agenda`
    : `nenhum evento`;

  const holliday = event?.holliday?.name
    ? `feriado de ${event?.holliday?.name}`
    : '';

  return `${qtnEventsLabel} ${today} ${date} ${holliday}`;
};

export const TextLegends = (day) => {
  return `Legendas ${day} dias letivos`;
};

export const TextCard = (
  name,
  startHour,
  endHour,
  description,
  redirectLink,
  type,
  subType,
  info,
  startdate
) => {
  const formatingDate = format(
    new Date(startdate.replaceAll('-', '/')),
    "EEEE d 'de' MMMM 'de' yyyy ",
    {
      locale: pt,
    }
  );

  const valDateEvent = `Evento de ${formatingDate}`;
  const valTypeActive =
    type === 'COURSE_EVENTS' ? 'Atividade extracurricular ' : subType ?? '';

  const valHours =
    startHour && endHour ? `das ${startHour} até as ${endHour}` : '';
  const valBtn = redirectLink ? 'Acessar link do evento Botão' : '';
  const valDescription = description ? description : '';
  const valInfo = info ? info : '';
  const valName = name ? name : '';

  return `${valDateEvent} ${valTypeActive} ${valName} ${valHours} ${valInfo} ${valDescription} ${valBtn}`;
};

export const TextSeparator = (date) => {
  const formatingDate = new Date(date?.replaceAll('-', '/'));
  const formatDayAndMonth = format(
    new Date(date.replaceAll('-', '/')),
    "d 'de' MMMM",
    {
      locale: pt,
    }
  );
  const formatDayWeek = format(new Date(date.replaceAll('-', '/')), 'EEEE', {
    locale: pt,
  });

  const formatDayYear = format(new Date(date.replaceAll('-', '/')), 'yyyy', {
    locale: pt,
  });
  const today = isToday(formatingDate) ? 'hoje' : '';

  return `${today} ${formatDayAndMonth} de ${formatDayYear}, ${formatDayWeek}`;
};
