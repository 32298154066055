import { mediaQuery, convertPXToVW, convertPXToVH } from '@sofia/ui';
import styled from '@emotion/styled';
import { Icon, getColor } from '@ampli/ui';

export const TimerComponentContent = styled.div`
  width: 100%;
  background: ${({ currentComponentsColor }) =>
    getColor(currentComponentsColor)};
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: ${convertPXToVH(5)} ${convertPXToVW(5)};
  ${mediaQuery('mobile')} {
    padding: ${convertPXToVH(16)} ${convertPXToVW(16)};
    height: auto;
    flex-direction: column;
  }
`;

export const TimerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px ${convertPXToVW(14)};
  width: ${convertPXToVW(38)};
`;

export const TimeDescriptionText = styled.span`
  font-weight: 400;
  text-align: center;
  color: ${({ currentComponentColor }) => getColor(currentComponentColor)};
  font-size: 16px;
`;

export const TimeText = styled.span`
  font-weight: 500;
  text-align: center;
  color: ${({ currentComponentColor }) => getColor(currentComponentColor)};
  font-size: 32px;
  height: 40px;
`;

export const TimeTitle = styled.p`
  font-weight: 400;
  text-align: center;
  color: ${({ currentComponentsColor }) => getColor(currentComponentsColor)};
  font-size: 16px;
  font-weight: 700;
  margin: 0px ${convertPXToVW(32)} 0px ${convertPXToVW(18)};
  ${mediaQuery('mobile')} {
    margin: ${convertPXToVH(16)} 0px;
  }
`;

export const TimeContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StudiesButton = styled.button`
  border: none;
  background: ${({ currentComponentColor }) => getColor(currentComponentColor)};
  border-radius: 8px;
  padding: ${convertPXToVH(11)} ${convertPXToVW(32)};

  font-weight: 700;
  font-size: 16px;
  color: white;

  justify-content: center;
  align-items: center;
  display: flex;

  svg {
    margin-left: ${convertPXToVW(5)};
    height: ${convertPXToVH(18)};
    width: ${convertPXToVW(18)};
  }
  ${({ defaultButton }) =>
    defaultButton &&
    `
  border: 2px solid ${getColor('primary-500')};
  color: ${getColor('primary-500')};
`};

  ${mediaQuery('mobile')} {
    width: 100%;
  }
`;

export const ClockIcon = styled(Icon.Clock)`
  height: ${convertPXToVH(33)};
  width: ${convertPXToVW(33)};
`;

export const TimeSeparator = styled.span`
  font-weight: 700;
  font-size: 24px;
  color: ${({ currentComponentsColor }) => getColor(currentComponentsColor)};
`;
