import React from 'react';

import {
  SvgCommentAltExclamation,
  SvgCommentQuestion,
  SvgCommentMessage,
  SvgEnvelope,
  Typography,
} from '@sofia/ui';

import * as Styled from '../../historic-section.styles';

export type FilterType = 'ALL' | 'TUTOR' | 'STUDENT' | 'NOT_READ';

export type OnFilterParams =
  | {
      activeFilterChip?: FilterType;
    }
  | { activeFilterTab?: FilterType };

interface FiltersProps {
  onFilterChange: (params: OnFilterParams) => void;
  activeFilter: FilterType;
  isRefetching: boolean;
  filters: FilterType[];
  type: 'chip' | 'tab';
}

interface FilterChipProps extends FiltersProps {
  filterType: FilterType;
}

const FilterChip: React.FC<FilterChipProps> = ({
  onFilterChange,
  activeFilter,
  isRefetching,
  filterType,
  type = 'chip',
}: FilterChipProps) => {
  const active = React.useMemo(
    () => activeFilter === filterType,
    [activeFilter, filterType]
  );

  const handleClick = React.useCallback(
    (type: FilterType) =>
      onFilterChange({
        ...(['STUDENT', 'TUTOR'].includes(type)
          ? {
              activeFilterTab: type,
            }
          : {
              activeFilterChip: type,
            }),
      }),
    [onFilterChange]
  );

  const { title, Icon, onClick } = React.useMemo(
    () =>
      ({
        ALL: {
          title: 'Todas',
          Icon: SvgCommentMessage,
          onClick: () => handleClick('ALL'),
        },
        STUDENT: {
          title: 'Minhas mensagens',
          Icon: SvgCommentQuestion,
          onClick: () => handleClick('STUDENT'),
        },
        TUTOR: {
          title: 'Mensagens do tutor',
          Icon: SvgCommentAltExclamation,
          onClick: () => handleClick('TUTOR'),
        },
        NOT_READ: {
          title: 'Apenas não lidas',
          Icon: SvgEnvelope,
          onClick: () => handleClick('NOT_READ'),
        },
      }[filterType]),
    [handleClick, filterType]
  );

  const chip = (
    <Styled.FilterChip
      aria-label={`${title} filtro`}
      active={active}
      disabled={isRefetching}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <Icon width="22px" height="22px" {...(active && { fill: '#FFFFFF' })} />
      <Typography
        space="nowrap"
        size={14}
        weight={700}
        margin="0px 0px 0px 8px"
      >
        {title}
      </Typography>
    </Styled.FilterChip>
  );

  const tab = (
    <Styled.FilterTab
      aria-label={`${title} filtro`}
      active={active}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <Typography space="nowrap" size={20} weight={700}>
        {title}
      </Typography>
    </Styled.FilterTab>
  );

  return type === 'chip' ? chip : tab;
};

const Filters: React.FC<FiltersProps> = (props: FiltersProps) => (
  <Styled.FiltersWrapper type={props.type}>
    {props?.filters?.map((filter) => (
      <FilterChip key={filter} filterType={filter} {...props} />
    ))}
  </Styled.FiltersWrapper>
);

export default Filters;
