import * as React from 'react';
import { getColor } from '@ampli/ui';

function SvgSubjectExamCalendarImage(props) {
  const brandBase = getColor('brand-base');
  const brandLight = getColor('brand-light');

  return (
    <svg width={170} height={138} fill="none" {...props}>
      <path
        d="M36.914 29.482C26.87 34.812 16.52 40.395 9.52 49.322c-4.205 5.366-7.006 11.755-8.482 18.402-2.526 11.347-.656 24.501 8.037 32.304 3.025 2.728 6.725 4.653 10.54 6.133a56.899 56.899 0 0024.876 3.663c6.95-.538 13.889-2.357 20.804-1.491 8.435 1.054 15.799 5.954 23.656 9.181a62.13 62.13 0 0030.879 4.184c2.643-.319 5.319-.83 7.644-2.12 2.759-1.532 4.805-4.035 6.98-6.324a60.852 60.852 0 0117.093-12.641c3.357-1.669 6.915-3.053 9.83-5.401 7.679-6.172 8.993-17.216 8.547-27.01-.447-9.792-2.669-20.603-10.559-26.55-1.762-1.327-3.744-2.355-5.452-3.754-5.696-4.664-7.399-12.472-11.048-18.854C134.683 4.774 116.43-1.474 99.996.292c-23.55 2.53-42.836 18.45-63.082 29.19z"
        fill="#F5F7FB"
      />
      <path
        d="M160.297 68.302c-.214 1.965-2.655 5.263-6.031 8.97-5.534 6.066-13.573 13.22-18.408 17.387-2.494 2.152-4.134 3.509-4.134 3.509V60.829s5.854-7.447 10.107-7.925c4.254-.479 12.837 4.463 12.837 4.463s6.345 4.103 5.629 10.935z"
        fill="url(#subject-exam-calendar-image_svg__paint0_linear)"
      />
      <path
        d="M154.266 77.273c-5.533 6.065-13.572 13.219-18.407 17.386-.361-.57-.433-1.347-.466-2.05-.128-3.511-.097-7.027-.065-10.54.03-3.412.061-6.821.277-10.226.205-3.17.596-6.414 2.057-9.245.33.293.602.644.803 1.035 1.84 3.142 3.004 6.606 4.167 10.046a2175.33 2175.33 0 011.726 5.105 12.364 12.364 0 003.405-2.739c.884-.973 1.672-2.024 2.485-3.055.567-.721 1.675-2.774 2.559-2.002 1.433 1.241 1.55 4.082 1.459 6.285z"
        fill="url(#subject-exam-calendar-image_svg__paint1_linear)"
      />
      <path
        d="M136.022 11.668H40.719v109.646h95.303V11.669z"
        fill={brandLight}
      />
      <path
        d="M136.022 11.668H40.719v109.646h95.303V11.669z"
        fill="url(#subject-exam-calendar-image_svg__paint2_linear)"
      />
      <path
        d="M122.924 16.968l-82.205 7.32c.37 0 0-10.17 0-12.62h86.673v.86a4.425 4.425 0 01-1.309 3.14 4.484 4.484 0 01-3.159 1.3z"
        fill="url(#subject-exam-calendar-image_svg__paint3_linear)"
      />
      <path
        d="M136.022 24.289H40.719v109.645h95.303V24.289z"
        fill={brandLight}
      />
      <path
        d="M62.5 44.782H46.038v16.353H62.5V44.782zM79.876 44.782H63.414v16.353h16.462V44.782zM97.255 44.782H80.793v16.353h16.462V44.782zM114.631 44.782H98.169v16.353h16.462V44.782zM62.5 61.853H46.038v16.354H62.5V61.854zM79.876 61.853H63.414v16.354h16.462V61.854zM97.255 61.853H80.793v16.354h16.462V61.854zM114.631 61.853H98.169v16.354h16.462V61.854zM62.5 78.925H46.038V95.28H62.5V78.926zM79.876 78.925H63.414V95.28h16.462V78.926zM97.255 78.925H80.793V95.28h16.462V78.926zM114.631 78.925H98.169V95.28h16.462V78.926zM62.5 95.997H46.038v16.353H62.5V95.997zM79.876 95.997H63.414v16.353h16.462V95.997zM97.255 95.997H80.793v16.353h16.462V95.997zM114.631 95.997H98.169v16.353h16.462V95.997zM62.5 113.069H46.038v16.353H62.5v-16.353zM79.876 113.069H63.414v16.353h16.462v-16.353zM97.255 113.069H80.793v16.353h16.462v-16.353zM131.716 44.782h-16.462v16.353h16.462V44.782zM131.716 27.71h-16.462v16.353h16.462V27.71zM114.631 27.71H98.169v16.353h16.462V27.71zM131.716 61.853h-16.462v16.354h16.462V61.854zM131.716 78.925h-16.462V95.28h16.462V78.926zM131.716 95.997h-16.462v16.353h16.462V95.997z"
        fill="#fff"
      />
      <path
        d="M48.101 7.235v8.867c0 .478.612.867 1.364.867.753 0 1.363-.389 1.363-.867V7.235c0-.478-.61-.866-1.363-.866-.752 0-1.364.388-1.364.866zM61.136 7.235v8.867c0 .478.61.867 1.363.867.754 0 1.364-.389 1.364-.867V7.235c0-.478-.61-.866-1.364-.866-.754 0-1.363.388-1.363.866zM74.17 7.235v8.867c0 .478.61.867 1.364.867.754 0 1.364-.389 1.364-.867V7.235c0-.478-.61-.866-1.364-.866-.754 0-1.363.388-1.363.866zM87.206 7.235v8.867c0 .478.61.867 1.363.867.752 0 1.364-.389 1.364-.867V7.235c0-.478-.612-.866-1.364-.866-.754 0-1.363.388-1.363.866zM100.238 7.235v8.867c0 .478.612.867 1.366.867.751 0 1.363-.389 1.363-.867V7.235c0-.478-.612-.866-1.363-.866-.754 0-1.366.388-1.366.866zM113.272 7.235v8.867c0 .478.612.867 1.363.867.754 0 1.366-.389 1.366-.867V7.235c0-.478-.612-.866-1.366-.866-.751 0-1.363.388-1.363.866zM126.028 7.235v8.867c0 .478.612.867 1.363.867.754 0 1.364-.389 1.364-.867V7.235c0-.478-.61-.866-1.364-.866-.751 0-1.363.388-1.363.866zM138.192 11.668h-2.169v122.266h2.169V11.668z"
        fill={brandLight}
      />
      <path
        d="M138.192 11.668h-2.169v122.266h2.169V11.668z"
        fill="url(#subject-exam-calendar-image_svg__paint4_linear)"
      />
      <path
        d="M160.141 46.621c-.366 1.484.123 3.541-.571 4.903-1.163 2.288-4.565 3.807-5.067 6.32-.031.145-3.816 1.957-5.768 1.337-1.022-.32-1.541-1.31-.754-3.547.246-.694 1.628-.953 2.001-1.598l-.077-.057c-1.889-1.417-2.094-4.42-1.703-6.424.391-2.004 1.286-3.93 2.247-5.76.74-1.41 2.411-2.175 4.012-2.15 2.012.035 4.137.835 5.184 2.543.8 1.301.858 2.95.496 4.433z"
        fill="url(#subject-exam-calendar-image_svg__paint5_linear)"
      />
      <path
        d="M150.843 35.827c-1.031.541-2.127 1.179-3.281 1.006a1.548 1.548 0 00.656 1.433l-.716.16a.196.196 0 00-.149.082.184.184 0 00.016.158c.176.408.466.759.835 1.01-.128.272-.591.212-.749.462a.56.56 0 000 .462c.16.431.433.812.791 1.103-.428.462-.16 1.317.431 1.528.303.084.628.05.908-.096.279-.145.492-.392.595-.688.026.267-.036.536-.177.765 1.952.305 4.039.592 5.835-.231-.084.185-.13.384-.135.587.434.135.894.163 1.341.083a7.66 7.66 0 01-.254 2.834c-.16.573-.391 1.128-.493 1.713a2.3 2.3 0 00.295 1.729c.31-.028.529-.317.647-.601.086-.297.215-.578.384-.837.465-.54 1.433-.342 1.808.257.375.598.233 1.403-.126 2.006a3.08 3.08 0 01-2.01 1.387c.067.645.137 1.29.205 1.932 1.226-1.156 2.599-2.147 3.702-3.412 1.103-1.264 1.938-2.882 1.796-4.55-.086-1.03-.538-2.007-.572-3.04-.017-.463.053-.936.034-1.403a3.783 3.783 0 00-.754-2.056 3.825 3.825 0 00-1.77-1.303c-.593-.2-1.247-.259-1.773-.598-.654-.421-.994-1.205-1.643-1.635-.698-.462-1.782-.651-2.594-.88-1.057-.291-2.155.15-3.083.633z"
        fill="#3F3D56"
      />
      <path
        d="M136.205 57.698a.69.69 0 00-.321.462.464.464 0 00.375.411c.199.035.403.026.598-.027l1.182-.232a4.04 4.04 0 00-1.533 1.503.437.437 0 00.02.474c.159.198.426.12.64.037l1.026-.407-.698.673a3.211 3.211 0 00-.563.66 1.355 1.355 0 00-.149.368.322.322 0 00.396.384 3.17 3.17 0 001.163-.61 7.62 7.62 0 01-.84.986 1.652 1.652 0 00-.484 1.156.233.233 0 00.045.13.206.206 0 00.128.06c.379.062.723-.213 1.009-.463l1.054-.95c.17-.138.32-.299.445-.478a.67.67 0 00.114-.349.464.464 0 00-.712-.35c.03-.2.109-.387.158-.584a.827.827 0 00-.037-.587.405.405 0 00-.512-.192c.123-.24.202-.501.233-.77a.745.745 0 00-.342-.693.588.588 0 01-.173-.108c-.093-.114-.018-.276.021-.417a.675.675 0 00-.022-.514.676.676 0 00-.676-.385c-.6.063-1.1.437-1.545.812z"
        fill="url(#subject-exam-calendar-image_svg__paint6_linear)"
      />
      <path
        d="M155.296 66.382c2.676-2.349 5.989-.57 4.826 2.785-2.515 7.255-7.781 23.879-12.781 23.879-6.515 0-9.149-11.476-9.149-11.476l3.008-6.05s4.256 9.276 6.399 6.766 3.409-12.02 7.574-15.796l.123-.108z"
        fill="url(#subject-exam-calendar-image_svg__paint7_linear)"
      />
      <path
        d="M140.215 75.12a14.32 14.32 0 01-.028-1.617 12.137 12.137 0 00-2.015-6.622.951.951 0 00-.165.67l.034 6.14a7.047 7.047 0 01-.102 1.52c-.079.365-.214.714-.319 1.075-.221.75-.3 1.533-.232 2.311.02.418.133.826.332 1.195a1.34 1.34 0 001.006.675c.137 0 .698-1.156.779-1.313.25-.462.482-.928.698-1.398.161-.356.503-.88.442-1.28-.067-.45-.375-.863-.43-1.355z"
        fill="url(#subject-exam-calendar-image_svg__paint8_linear)"
      />
      <path
        d="M152.648 55.603c-.563.63-1.121 1.26-1.684 1.891a6.64 6.64 0 01-1.517 1.387 3.094 3.094 0 01-.712.305c-1.022-.321-1.541-1.31-.754-3.548.246-.693 1.628-.952 2.001-1.597l-.077-.058c.267.126.55.217.84.273.605.09 2.094-.377 2.278.513.065.307-.154.596-.375.834z"
        fill="url(#subject-exam-calendar-image_svg__paint9_linear)"
      />
      <path
        d="M30.42 113.24h.721l.36 16.194-.36-2.667h-.721V113.24z"
        fill={brandLight}
      />
      <path
        d="M31.142 119.144v.018c0 .506-.182 1.013-.375 1.618-1.27.365-2.117.659-3.364-.622-1.247-1.28-2.534-3.756-2.127-5.006 1.27-.365 3.746.964 4.996 2.244.646.654.865 1.202.87 1.748zM34.862 120.168c-1.145 1.102-1.95.954-3 .621v5.978h-.72V113.24h.72v4.302c.068-.074.14-.148.22-.231 1.289-1.239 3.78-2.52 5.04-2.113.37 1.269-.97 3.729-2.26 4.97z"
        fill={brandLight}
      />
      <path
        d="M34.862 120.168c-1.145 1.102-1.95.954-3 .621v5.978h-.72V113.24h.72v4.302c.068-.074.14-.148.22-.231 1.289-1.239 3.78-2.52 5.04-2.113.37 1.269-.97 3.729-2.26 4.97z"
        fill="url(#subject-exam-calendar-image_svg__paint10_linear)"
      />
      <path
        d="M31.141 109.974h-3.22c0 2.272 1.44 4.115 3.218 4.115l.758-2.018-.756-2.097z"
        fill={brandLight}
      />
      <path
        d="M31.197 110.763l-.055.039v-.231l.055.192zM25.046 105.34c-.408 1.25.879 3.728 2.128 5.006 1.25 1.279 2.095.99 3.363.622.407-1.25.754-2.103-.496-3.384-1.25-1.28-3.725-2.609-4.995-2.244z"
        fill={brandLight}
      />
      <path
        d="M34.632 110.363a4.65 4.65 0 01-.314.279c-.25 1.956-1.578 3.447-3.176 3.447v-3.287l.055-.039-.055-.195v.232a1.558 1.558 0 01-.233.134c-1.145-.659-2.071-2.756-1.997-4.538.075-1.782.978-4.417 2.169-4.99 1.145.659 1.892 3.352 1.824 5.136v.132c1.32-.899 3.025-1.588 3.995-1.276.36 1.266-.982 3.723-2.268 4.965z"
        fill={brandLight}
      />
      <path
        d="M34.632 110.363a4.65 4.65 0 01-.314.279c-.25 1.956-1.578 3.447-3.176 3.447v-3.287l.055-.039-.055-.195v.232a1.558 1.558 0 01-.233.134c-1.145-.659-2.071-2.756-1.997-4.538.075-1.782.978-4.417 2.169-4.99 1.145.659 1.892 3.352 1.824 5.136v.132c1.32-.899 3.025-1.588 3.995-1.276.36 1.266-.982 3.723-2.268 4.965z"
        fill="url(#subject-exam-calendar-image_svg__paint11_linear)"
      />
      <path
        d="M31.197 110.763l-.055.039v-.231l.055.192zM24.777 124.703l1.136 9.116h5.251l2.146-5.187-2.146-4.184-6.387.255z"
        fill={brandLight}
      />
      <path
        d="M37.554 124.703l-1.135 9.116h-5.254v-9.371l6.39.255z"
        fill={brandLight}
      />
      <path
        d="M33.505 124.511l-.058.027-1.782.879-.486.245h-7.095a.801.801 0 01-.807-.802v-2.039a.72.72 0 01.716-.726h.068l7.118.014 2.326 2.402z"
        fill={brandLight}
      />
      <path
        d="M33.505 124.511l-.058.027-1.782.879-.486.245h-7.095a.801.801 0 01-.807-.802v-2.039a.72.72 0 01.716-.726h.068l7.118.014 2.326 2.402z"
        fill="url(#subject-exam-calendar-image_svg__paint12_linear)"
      />
      <path
        d="M38.248 125.662h-7.083v-3.643h7.083c.214 0 .42.085.57.235.152.15.237.354.237.567v2.039a.801.801 0 01-.807.802z"
        fill={brandLight}
      />
      <path
        d="M31.167 125.662v8.157h-5.254l-1.017-8.157h6.271z"
        fill="url(#subject-exam-calendar-image_svg__paint13_linear)"
      />
      <path
        d="M37.435 125.662l-1.016 8.157h-5.252v-8.157h6.268z"
        fill="url(#subject-exam-calendar-image_svg__paint14_linear)"
      />
      <path
        d="M39.055 122.914v2.038a.808.808 0 01-.807.802h-7.07v-3.642h7.081a.806.806 0 01.796.802z"
        fill="url(#subject-exam-calendar-image_svg__paint15_linear)"
      />
      <path
        opacity={0.18}
        d="M26.525 133.83l-13.346 2.543a.831.831 0 00-.597.264.823.823 0 00-.212.614.804.804 0 00.308.573.833.833 0 00.631.167h150.783a.822.822 0 00.578-.206.805.805 0 00.08-1.128.812.812 0 00-.544-.284l-19.454-2.432h-.112l-117.945-.115a.803.803 0 00-.17.004z"
        fill="#1D2741"
      />
      <defs>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint0_linear"
          x1={131.724}
          y1={98.168}
          x2={163.958}
          y2={95.438}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BDBB8" />
          <stop offset={1} stopColor={brandBase} />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint1_linear"
          x1={148.328}
          y1={65.517}
          x2={108.955}
          y2={140.098}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.91} stopOpacity={0.8} />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint2_linear"
          x1={38714.6}
          y1={11040.9}
          x2={36316.9}
          y2={39657.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.91} stopOpacity={0.8} />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint3_linear"
          x1={83.201}
          y1={4.845}
          x2={84.237}
          y2={20.984}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.1} stopOpacity={0.77} />
          <stop offset={0.99} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint4_linear"
          x1={1676.86}
          y1={36214.6}
          x2={663.776}
          y2={36217.4}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.91} stopOpacity={0.8} />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint5_linear"
          x1={163.61}
          y1={53.812}
          x2={142.048}
          y2={45.348}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7" />
          <stop offset={0.16} stopColor="#EDCBD5" />
          <stop offset={0.42} stopColor="#EFD4D1" />
          <stop offset={0.7} stopColor="#F2EAC9" />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint6_linear"
          x1={135.884}
          y1={60.309}
          x2={139.824}
          y2={60.309}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7" />
          <stop offset={0.42} stopColor="#EFD4D1" />
          <stop offset={1} stopColor="#F2EAC9" />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint7_linear"
          x1={138.192}
          y1={93.046}
          x2={163.047}
          y2={90.383}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BDBB8" />
          <stop offset={1} stopColor={brandBase} />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint8_linear"
          x1={2078.43}
          y1={6707.08}
          x2={2124.89}
          y2={6707.08}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7" />
          <stop offset={0.42} stopColor="#EFD4D1" />
          <stop offset={1} stopColor="#F2EAC9" />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint9_linear"
          x1={3620.24}
          y1={2232.87}
          x2={3421.35}
          y2={2476.87}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7" />
          <stop offset={0.42} stopColor="#EFD4D1" />
          <stop offset={1} stopColor="#F2EAC9" />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint10_linear"
          x1={32.668}
          y1={127.354}
          x2={34.092}
          y2={114.986}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.1} stopOpacity={0.77} />
          <stop offset={0.99} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint11_linear"
          x1={1348.08}
          y1={1761.69}
          x2={1090.44}
          y2={1777.62}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.1} stopOpacity={0.77} />
          <stop offset={0.99} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint12_linear"
          x1={28.624}
          y1={108.668}
          x2={28.371}
          y2={125.172}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.36} stopOpacity={0.77} />
          <stop offset={0.99} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint13_linear"
          x1={777.881}
          y1={694.688}
          x2={789.864}
          y2={385.568}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.1} stopOpacity={0.77} />
          <stop offset={0.99} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint14_linear"
          x1={1027.15}
          y1={649.907}
          x2={801.757}
          y2={325.479}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.1} stopOpacity={0.77} />
          <stop offset={0.99} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="subject-exam-calendar-image_svg__paint15_linear"
          x1={1293.42}
          y1={477.426}
          x2={1267.67}
          y2={285.203}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.1} stopOpacity={0.77} />
          <stop offset={0.99} stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgSubjectExamCalendarImage;
