import { useState } from 'react';
import { clone, times, constant } from '@ampli/utils';

const useQuestionChoices = (numberOfQuestions) => {
  const [questionChoices, _setQuestionChoices] = useState(
    times(numberOfQuestions, constant(false))
  );

  const setQuestionChoices = (question, choice = true) => {
    const qa = clone(questionChoices);
    qa[question] = choice;
    _setQuestionChoices(qa);
  };

  return [questionChoices, setQuestionChoices];
};

export default useQuestionChoices;
