import React from 'react';
import { string } from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Typography } from '@sofia/ui';
import BreadcrumbsWrapper from '../breadcrumbs-wrapper/breadcrumbs-wrapper';
import { isMobile } from '@ampli/utils';
import { routePath } from '../../routes';

const BreadCrumbs = ({ courseName }) => {
  const history = useHistory();
  const goToMyCourses = () => history.push(routePath.courseSelection);

  return (
    <BreadcrumbsWrapper
      margin="0px"
      padding="0px"
      shadow={false}
      backClick={goToMyCourses}
    >
      <Link
        id="my-courses-breadcrumbs"
        data-testid="my-courses-breadcrumbs"
        to={routePath.courseSelection}
      >
        <span>{isMobile() ? courseName : 'Meus cursos'}</span>
      </Link>
      <Typography
        id={`${courseName}-breadcrumbs`}
        data-testid={`${courseName}-breadcrumbs`}
        size={16}
        weight="bold"
      >
        {courseName}
      </Typography>
    </BreadcrumbsWrapper>
  );
};

BreadCrumbs.propTypes = {
  courseName: string,
};

export default BreadCrumbs;
