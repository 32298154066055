import styled from '@emotion/styled';
import { convertPXToREM } from '../../utils';
export const Wrapper = styled.div`
  display: flex;
  opacity: ${_ref => {
  let {
    disabled
  } = _ref;
  return disabled && 0.5;
}};

  flex-direction: ${_ref2 => {
  let {
    positionLabel
  } = _ref2;
  return positionLabel === 'bottom' ? 'column' : 'row';
}};
  align-items: center;
  ${_ref3 => {
  let {
    positionLabel
  } = _ref3;
  return positionLabel === 'bottom' && `gap: 4px;`;
}};
`;
export const SwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 0;

  &:focus,
  :focus-visible {
    outline-color: #004ecc;
    outline-style: auto;
    outline-width: 1px;
  }
`;
export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  &:checked + span {
    background-color: ${_ref4 => {
  let {
    activeColor
  } = _ref4;
  return activeColor;
}};
    border-color: ${_ref5 => {
  let {
    activeColor
  } = _ref5;
  return activeColor;
}};
  }
  &:focus + span {
    border: 1px solid #004ecc;
  }
  &:checked + span:before {
    background-color: #fff;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
`;
export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid ${_ref6 => {
  let {
    inactiveColor
  } = _ref6;
  return inactiveColor;
}};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  margin: 0;

  &:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    border-radius: 50%;
    left: 2px;
    bottom: 2px;
    background-color: ${_ref7 => {
  let {
    inactiveColor
  } = _ref7;
  return inactiveColor;
}};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;
export const Label = styled.p`
  margin: ${_ref8 => {
  let {
    margin = '0 0 0 8px'
  } = _ref8;
  return `${margin}`;
}};
  font-weight: 400;
  font-size: ${_ref9 => {
  let {
    fontSize = 16
  } = _ref9;
  return `${convertPXToREM(fontSize)}`;
}};
  color: ${_ref10 => {
  let {
    colorLabel
  } = _ref10;
  return colorLabel;
}};
`;