import { useQuery } from '@ampli/services';
import { GET_PAYMENT_STATEMENT } from './queries';
import { GetPaymentStatementHook } from './types';

export const useGetPaymentStatement: GetPaymentStatementHook = ({
  courseEnrollmentId,
  options = {},
}) => {
  const { data, loading, error, refetch } = useQuery(GET_PAYMENT_STATEMENT, {
    ...options,
    variables: {
      courseEnrollmentId: courseEnrollmentId,
    },
    context: {
      autoHandle: true,
    },
  });

  const paymentStatement = loading || !data ? [] : data.data;

  return {
    data: paymentStatement,
    loading,
    error,
    refetch,
  };
};
