import React from 'react';

import { SvgCheckCircle, SvgBookOpen, SvgPlay } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import SectionMenuListItem from './item/section-menu-list-item';
import SectionMenuHeader from './header/section-menu-header';
import Menu from '../../menu';
import Skeleton from '../../../../skeleton/skeleton';

import * as Styled from './section-learning-object-menu-styles';
import { SectionLearningObjectTemplateMenuProps } from './types';
import { LearningObjectType } from '../../../../../graphql/types';

const SectionLearningObjectMenu = ({
  pageTitle,
  sectionTitle,
  sectionProgress,
  sectionOrder,
  learningObjects,
  open,
  onToggleMenuClick,
  onMenuItemClick,
  activeLearningObjectPath,
  isInauguralSection,
  loading,
}: SectionLearningObjectTemplateMenuProps): React.ReactElement => {
  const theme = useTheme();
  const activeListItemRef = React.useRef<HTMLDivElement>(null);
  const closedClass = !open ? 'closed' : '';

  React.useEffect(() => {
    if (activeListItemRef?.current) {
      activeListItemRef.current.scrollIntoView();
    }
  }, []);

  return (
    <div id="section-learning-object-menu">
      <Menu
        data-testid="section-learning-object-menu"
        open={open}
        header={
          <SectionMenuHeader
            open={open}
            loading={loading}
            sectionProgress={sectionProgress}
            sectionOrder={sectionOrder}
            sectionTitle={sectionTitle}
            learningObjects={learningObjects}
            pageTitle={pageTitle}
            onToggleMenuClick={onToggleMenuClick}
            hideProgressBar={isInauguralSection}
          />
        }
        content={
          loading
            ? Array.from({ length: 8 }, (_, index) => {
                return (
                  <Styled.ListItemSkeleton
                    key={index}
                    data-testid="section-learning-object-loading"
                  >
                    {open ? (
                      <Skeleton
                        height="20px"
                        width="20px"
                        margin="0 16px 0 0"
                      />
                    ) : null}
                    <Skeleton height="24px" />
                  </Styled.ListItemSkeleton>
                );
              })
            : learningObjects.map((learningObject, index) => {
                const { title, duration, completed, type, pathUrl } =
                  learningObject;
                const active = pathUrl === activeLearningObjectPath;
                const getAriaLabelText = (
                  order: number,
                  sectionsSize: number
                ) => {
                  const status = completed ? 'Completo' : 'Incompleto';

                  return `${
                    type === LearningObjectType.Text ? 'Texto' : 'Video aula'
                  }: ${title}. Tempo de duração: ${duration} minutos. Status: ${status}. Conteúdo: ${order} de ${sectionsSize}`;
                };
                return (
                  <SectionMenuListItem
                    key={index}
                    index={index}
                    onMenuItemClick={onMenuItemClick}
                    active={active}
                    activeListItemRef={activeListItemRef}
                    title={title}
                    pathUrl={pathUrl || ''}
                    open={open}
                    ariaLabelText={getAriaLabelText(
                      index + 1,
                      learningObjects?.length
                    )}
                    icon={
                      <Styled.IconContainer
                        data-testid={`icon-container-${index}`}
                        className={closedClass}
                        completed={completed}
                        color={
                          active
                            ? theme.colors.text.black
                            : theme.colors.text.lightBlack
                        }
                      >
                        {completed ? (
                          <SvgCheckCircle />
                        ) : type === LearningObjectType.Text ? (
                          <SvgBookOpen />
                        ) : (
                          <SvgPlay />
                        )}
                      </Styled.IconContainer>
                    }
                  />
                );
              })
        }
      />
    </div>
  );
};

SectionLearningObjectMenu.defaultProps = {
  isInauguralSection: false,
  loading: false,
  open: false,
};

export default SectionLearningObjectMenu;
