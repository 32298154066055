import { useBreakpoint } from '@ampli/hooks';
import { useModal } from '@sofia/ui';
import { InitAlertHook } from '../types';

const useInitAlert = (alertName: string): InitAlertHook => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const modal = useModal();

  const onClick = () => {
    modal.show();
  };
  const handleCancel = () => {
    modal.hide();
  };

  return {
    [`${alertName}`]: {
      modal: modal,
      handleCancel: handleCancel,
      onClick: onClick,
      isDesktop: isDesktop,
    },
  };
};

export default useInitAlert;
