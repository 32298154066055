export const startTour = (tourId) => {
  window.dispatchEvent(
    new CustomEvent(`action-tourStart-${tourId}`, { detail: { tourId } })
  );
};
export const stopTour = (tourId) => {
  window.dispatchEvent(
    new CustomEvent(`action-tourStop-${tourId}`, { detail: { tourId } })
  );
};
export const switchTour = (currentTour, nextTour) => {
  stopTour(currentTour);
  startTour(nextTour);
};
export const resetTour = (tourId) => {
  window.dispatchEvent(
    new CustomEvent(`action-tourReset-${tourId}`, { detail: { tourId } })
  );
};
export const nextTour = (tourId) => {
  window.dispatchEvent(
    new CustomEvent(`action-tourNextStep-${tourId}`, { detail: { tourId } })
  );
};
export const backTour = (tourId) => {
  window.dispatchEvent(
    new CustomEvent(`action-tourPrevStep-${tourId}`, { detail: { tourId } })
  );
};
export const goToTour = (tourId, stepIndex) => {
  window.dispatchEvent(
    new CustomEvent(`action-tourGoToStep-${tourId}`, {
      detail: { tourId, stepIndex },
    })
  );
};
