import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { routePath } from '../routes';
import {
  logout,
  useGetSubjectEnrollment,
  useServiceClient,
} from '@ampli/services';
import { useGetState } from '../state';
import { useMeContext } from '../modules/shared/contexts/me-context';

const useElectiveSubjectPresentationPageData = () => {
  const { subjectEnrollmentId, subjectPath } = useParams();
  const { state: locationState } = useLocation();
  const history = useHistory();
  const client = useServiceClient('privateClient');
  const [{ selectedCourseEnrollmentType }] = useGetState();
  const { states: statesMe } = useMeContext();
  const { me, loading: meLoading } = statesMe.getMe;

  const handleLogout = () => logout({ client, history });
  const handleLogo = () =>
    history.push(
      generatePath(routePath.home, {
        courseType: selectedCourseEnrollmentType,
      })
    );

  const { data: subjectEnrollment, loading: subjectEnrollmentLoading } =
    useGetSubjectEnrollment(
      locationState?.subject ? null : subjectEnrollmentId
    );

  const loading = subjectEnrollmentLoading || meLoading;

  const getSubject = () => {
    let subject;
    if (locationState?.subject) {
      subject = locationState.subject;
    } else if (subjectEnrollment?.choices?.length > 0) {
      subject = subjectEnrollment.choices.filter(
        (choice) => choice.subjectPath === subjectPath
      )[0];
    }

    const learningUnits = subject.learningUnits.map((learningUnit) => {
      return {
        sections:
          learningUnit?.learningUnit?.currentLearningUnitVersion.sections,
        title: learningUnit?.learningUnit?.title,
        order: learningUnit.order,
      };
    });

    return {
      subject,
      learningUnits,
    };
  };

  return {
    me,
    handleLogo,
    handleLogout,
    loading,
    subjectEnrollment: getSubject(),
  };
};

export default useElectiveSubjectPresentationPageData;
