import React from 'react';

const ImageHighFive = ({
  width = 75,
  height = 83,
  title = '',
  desc = '',
  ...props
}: {
  width?: number;
  height?: number;
  title?: string;
  desc?: string;
}): React.ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(title || desc
        ? { 'aria-labelledby': `${title || ''} ${desc || ''}` }
        : {})}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4972 2.29533C57.4387 -3.33907 72.6538 9.51582 68.2043 38.7495C66.5267 49.7643 57.7156 57.3658 46.7058 58.0888C39.4419 58.5644 31.6633 59.1506 24.6991 57.1965C11.0182 53.3566 5.26251 42.7848 6.55893 29.754C8.04428 14.8082 14.3147 5.67922 30.4972 2.29533Z"
        fill="#99E2CD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.4087 79.8516C47.6367 70.6086 41.35 50.8654 39.7734 42.9284C41.8647 36.5124 55.881 35.8219 57.1872 38.0431C61.5488 50.4192 64.7606 56.0015 71.8844 66.8176C70.7541 75.2594 56.0276 80.5877 55.4087 79.8516Z"
        fill="#99E2CD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1484 82.8382C25.5589 74.696 31.0214 55.2427 33.6469 45.8238C32.5035 39.1733 18.7315 36.4701 17.1191 38.4893C11.6858 50.1457 6.69557 62.7726 0.858398 68.8336C5.85191 79.907 16.067 83.8869 17.1484 82.8382Z"
        fill="#99E2CD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.7743 12.7832C69.4974 15.9847 67.6603 16.9227 66.0283 17.2549C69.0772 17.3428 69.429 17.7793 70.2629 21.0068C69.9176 18.5902 69.9371 16.825 73.1424 15.7242C72.5072 15.8479 69.3541 17.2647 68.7743 12.7832Z"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4452 11.8613C17.1683 15.0628 15.3312 16.0008 13.6992 16.333C16.7481 16.421 17.0999 16.8574 17.9338 20.0849C17.5885 17.6683 17.608 15.9031 20.8133 14.8023C20.1748 14.9326 17.0217 16.346 16.4452 11.8613Z"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2754 1.47461C30.9529 2.34745 31.4089 2.80016 31.7966 3.84887"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.0186 29.0539C68.0153 28.5751 68.5495 28.2234 69.657 28.0605"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.2598 37.5996C68.3282 37.883 68.9634 37.9676 69.9113 38.5767"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.123 2.45117C26.8006 3.32401 27.2566 3.77672 27.6442 4.82543"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.2432 13.8108C58.0445 13.0487 58.4484 12.5504 59.4549 12.0586"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4805 12.3264C56.2818 11.5643 56.6857 11.066 57.6922 10.5742"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.783 29.5422C9.78623 29.0634 9.25203 28.7117 8.14453 28.5488"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5489 38.0879C9.48053 38.3712 8.84535 38.4559 7.89746 39.065"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.4087 79.8516C47.6367 70.6086 41.35 50.8654 39.7734 42.9284C41.8647 36.5124 55.881 35.8219 57.1872 38.0431C61.5488 50.4192 64.7606 56.0015 71.8844 66.8176C70.7541 75.2594 56.0276 80.5877 55.4087 79.8516Z"
        fill="#008566"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.5812 25.0025C40.6984 18.0784 38.8222 14.9518 36.9948 16.0559C33.8971 17.9253 37.073 22.7911 40.7017 28.9661C35.9264 25.1198 31.9752 23.3676 30.7179 25.3477C28.9589 28.1128 31.4768 28.8554 38.4378 34.7308C38.972 37.6262 39.3271 39.4565 42.4867 40.629C43.0209 40.8277 42.9427 41.7038 42.9883 42.5538C45.4216 42.0066 49.4444 41.9839 54.0014 37.4112C54.376 36.8445 59.7604 24.3837 56.5487 22.2309C55.7506 21.7 52.4542 21.8792 50.9232 27.8458C50.1089 17.9286 47.9199 13.4113 45.9134 13.6328C44.4574 13.7923 43.9395 17.9416 44.5812 25.0025Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.7743 12.7832C69.4974 15.9847 67.6603 16.9227 66.0283 17.2549C69.0772 17.3428 69.429 17.7793 70.2629 21.0068C69.9176 18.5902 69.9371 16.825 73.1424 15.7242C72.5072 15.8479 69.3541 17.2647 68.7743 12.7832Z"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4452 11.8613C17.1683 15.0628 15.3312 16.0008 13.6992 16.333C16.7481 16.421 17.0999 16.8574 17.9338 20.0849C17.5885 17.6683 17.608 15.9031 20.8133 14.8023C20.1748 14.9326 17.0217 16.346 16.4452 11.8613Z"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2754 1.47461C30.9529 2.34745 31.4089 2.80016 31.7966 3.84887"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.0186 29.0539C68.0153 28.5751 68.5495 28.2234 69.657 28.0605"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.2598 37.5996C68.3282 37.883 68.9634 37.9676 69.9113 38.5767"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.123 2.45117C26.8006 3.32401 27.2566 3.77672 27.6442 4.82543"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.2432 13.8108C58.0445 13.0487 58.4484 12.5504 59.4549 12.0586"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4805 12.3264C56.2818 11.5643 56.6857 11.066 57.6922 10.5742"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.783 29.5422C9.78623 29.0634 9.25203 28.7117 8.14453 28.5488"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5489 38.0879C9.48053 38.3712 8.84535 38.4559 7.89746 39.065"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1484 82.8382C25.5589 74.696 31.0214 55.2427 33.6469 45.8238C32.5035 39.1733 18.7315 36.4701 17.1191 38.4893C11.6858 50.1457 6.69557 62.7726 0.858398 68.8336C5.85191 79.907 16.067 83.8869 17.1484 82.8382Z"
        fill="#008566"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.48 27.2697C36.3204 20.9807 38.6299 18.1504 40.2748 19.5118C43.0696 21.8112 39.226 26.1656 34.7373 31.7512C40.0175 28.6343 44.1836 27.4716 45.1413 29.6114C46.4833 32.6045 43.884 32.9725 36.1478 37.7829C35.2031 40.5708 34.5842 42.3425 31.291 43.0362C30.734 43.1567 30.6819 44.0361 30.5158 44.8666C28.1868 43.9742 24.2096 43.3717 20.3594 38.19C20.0727 37.5777 16.5418 24.459 20.0336 22.8013C20.9001 22.3909 24.1347 23.0423 24.7926 29.1685C27.0304 19.4727 29.848 15.317 31.8024 15.8153C33.2194 16.1963 33.1347 20.3782 31.48 27.2697Z"
        fill="white"
      />
      <path
        d="M30.8647 43.2672C30.8061 43.645 30.4413 44.5895 30.4217 44.8143M58.4642 42.694C54.0929 48.1819 45.7378 50.3705 41.2003 49.5856M53.7411 37.8608C54.5684 37.0499 59.2199 24.2243 56.6499 22.7457C52.6043 20.4137 50.259 30.37 49.9658 30.4254C47.4772 30.9139 43.8974 35.7732 45.4055 38.32M50.6173 27.9762C51.2687 15.8444 42.5097 4.44203 44.5879 25.26C44.8778 28.1912 39.1742 11.5322 36.0374 17.2643C35.6041 18.0558 35.881 19.4921 36.4836 21.1335M38.9788 26.3641L39.0406 26.478C39.5846 27.4551 40.1025 28.3442 40.4967 29.0184M40.3045 29.0835C39.8876 28.7579 39.1807 28.1684 38.324 27.5463L38.184 27.4453M33.2979 24.9408C32.9111 24.855 32.5133 24.8297 32.1188 24.8659H32.0211M38.7214 36.2356C38.7627 36.4028 38.804 36.5635 38.8452 36.7177M42.7833 41.007C42.7833 41.3913 43.0114 42.3781 42.9983 42.6028M54.474 37.3918C49.2622 42.668 43.3469 42.7852 41.7117 42.9285M41.4967 40.88C41.1026 41.1014 39.3631 42.1469 39.8354 43.8828C41.2752 52.025 49.2166 72.869 55.8323 79.7801M20.2034 37.4049C15.3499 20.2248 22.0992 19.3292 25.008 28.5136C26.565 16.8865 36.4087 7.30808 31.4738 27.0415C30.7637 29.8847 38.8126 14.2387 41.0863 20.3649C41.969 22.7489 36.64 29.2627 34.7214 31.676C34.4478 32.0213 43.0114 26.0058 45.1905 29.3767C47.3697 32.7475 36.3957 37.0727 36.1416 37.6589C34.8028 40.9158 34.5325 42.1208 30.907 42.9253M19.8321 37.9976C24.2393 43.9707 30.0634 44.9413 31.6628 45.3223M15.1122 42.6745C18.6464 48.7388 26.5976 52.1194 31.1872 51.9826M19.7311 37.7957C20.0894 38.0693 17.0177 37.47 16.3011 39.0984C14.1284 45.6513 7.48345 60.7306 0.958984 68.9282M38.8387 36.6851C39.425 38.9128 39.9983 39.942 42.8518 41.0135C40.0016 39.9615 39.4283 38.9454 38.8419 36.7177L38.8387 36.6851Z"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImageHighFive;
