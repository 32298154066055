import styled from '@emotion/styled';
import { convertPXToVW, convertPXToREM, mediaQuery } from '@sofia/ui';

export const Title = styled.div`
  margin-bottom: 40px;
  ${mediaQuery('desktop')} {
    margin-bottom: 56px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${mediaQuery('mobile')} {
    flex-direction: column;
  }

  > div {
    :first-of-type {
      margin-right: ${convertPXToVW(24)};
    }
    :last-of-type {
      margin-left: ${convertPXToVW(24)};
    }

    ${mediaQuery('mobile')} {
      :first-of-type {
        margin-bottom: ${convertPXToVW(20)};
      }
      :last-of-type {
        margin-left: 0;
      }
    }
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  > div {
    min-height: auto;
    ${mediaQuery('desktop')} {
      padding: 31px 24px;
    }

    ${mediaQuery('mobile')} {
      padding: 21px 24px;
      height: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Access = styled.span`
  color: ${({ color }) => color};
  font-size: ${convertPXToREM(16)};
  font-weight: 700;
  svg {
    margin-left: 8px;
  }
`;
