import { GET_TUTOR_SECTION_GUARD } from '../modules/tutor-preview/services/queries';
import { GuardFunction } from 'react-router-guards';

const tutorAuthGuard: GuardFunction = async (to, _from, next) => {
  const privateClient = to.meta?.clients?.privateClient;

  const successfulAuth = next;
  const unsuccessfulAuth = () => next.redirect('/');

  const params = to.match?.params;

  try {
    const response = await privateClient.query({
      query: GET_TUTOR_SECTION_GUARD,
      variables: {
        learningUnitEnrollmentId: params.learningUnitEnrollmentId,
        sectionId: params.sectionId,
        secret: params.token,
      },
    });

    if (!response?.errors && response?.data) {
      dispatchEvent(
        new CustomEvent('apmContextChange', {
          detail: {
            route: to?.match?.path,
            url: to?.match?.url,
          },
        })
      );
      successfulAuth();
    } else {
      dispatchEvent(
        new CustomEvent('apmContextChange', {
          detail: {
            route: to?.match?.path,
            url: to?.match?.url,
          },
        })
      );
      throw response.errors[0];
    }
  } catch (error) {
    dispatchEvent(
      new CustomEvent('apmContextChange', {
        detail: {
          route: to?.match?.path,
          url: to?.match?.url,
        },
      })
    );
    console.warn(error);
    unsuccessfulAuth();
  }
};

export default tutorAuthGuard;
