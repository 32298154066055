import React from 'react';
import { useMeContext } from '../../../contexts/me-context';
import { useHandleTrack } from '../../../..';
import EduAvatar from '../../../../../assets/avatar-edu-onboarding-geral.png';
import { NewFeatureProps } from '../../../../communication/types/onboarding';
import {
  onboardingIds,
  HOME_CHAT_COPILOT_VIEWED_DESCRIPTION,
  HOME_CHAT_COPILOT_VIEWED,
} from '../../../utils/communication-constants';
import TourStep from '../tour-step';
import { useRolesContext } from '../../../contexts/communication-roles-context';
import * as Styles from './new-chat-copilot.styles';
import { useCreativeContext } from '../../../../communication/creatives/creatives-exhibition-controller/creatives-exhibition-controller';

export const useNewChatCopilotHomeTooltip = (): NewFeatureProps => {
  const { states } = useMeContext();

  const studentId = states?.getMe?.me?.personId;
  const { appendCommunicationOnTrack } = useHandleTrack({
    studentId: studentId,
    input: {
      key: HOME_CHAT_COPILOT_VIEWED,
      desc: HOME_CHAT_COPILOT_VIEWED_DESCRIPTION,
    },
  });

  const { markCurrentAsDone } = useCreativeContext();

  const { communicationCapabilities } = useRolesContext();
  const showTooltip = communicationCapabilities.course.CHAT_COPILOT_ENABLED;

  const setNewProfileShown = () => {
    appendCommunicationOnTrack();
    localStorage.setItem('showedNewChatPresentation', 'true');
    markCurrentAsDone('chat-copilot');
  };

  const chatCopilotHome = showTooltip
    ? {
        id: onboardingIds.newFeature.id.newChatHome,
        steps: [
          {
            target: onboardingIds.newFeature.target.newChatHome,
            hideFooter: true,
            content: (
              <TourStep
                image={EduAvatar}
                imageStyles={Styles.EduAvatar}
                imageWidth="94"
                imageHeight="168"
                title="Olá, eu sou o Edu!"
                titleStyles={Styles.Title}
                description={
                  <>
                    Sou o novo assistente virtual aqui da plataforma de
                    aprendizagem. O meu papel é te apoiar a qualquer momento,
                    tirando dúvidas e ajudando em eventuais problemas do seu dia
                    a dia como aluno.
                  </>
                }
              />
            ),
            styles: {
              tooltip: {
                padding: 24,
              },
              tooltipContent: {
                paddingTop: 0,
              },
            },
          },
        ],
        onEndTour: setNewProfileShown,
        onCloseTour: setNewProfileShown,
        hideTitle: true,
        hideCloseButton: false,
      }
    : null;

  return { Tour: chatCopilotHome };
};
