import styled from '@emotion/styled';
import { convertPXToREM, convertPXToVH, convertPXToVW } from '../../utils';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${_ref => {
  let {
    disabled
  } = _ref;
  return disabled && `
   textarea {
    border-color: #868e96;
    color: #868e96;
    background: #e5e5e5;
   }
   label {
    color: #868e96;
   }
  `;
}}
`;
export const Textarea = styled.textarea`
  border: 2px solid ${_ref2 => {
  let {
    borderColor
  } = _ref2;
  return borderColor;
}};
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  height: ${_ref3 => {
  let {
    height
  } = _ref3;
  return height || convertPXToVH(75);
}};
  padding: ${convertPXToVH(16)} ${convertPXToVW(16)};

  &:focus-visible {
    outline: none;
  }

  &:focus {
    border: 2px solid #004ecc;
    background-color: #f3f6f9;
  }

  &:hover {
    background-color: ${_ref4 => {
  let {
    hover
  } = _ref4;
  return hover;
}};
    border-color: #698aae;
  }

  ${_ref5 => {
  let {
    error
  } = _ref5;
  return error && `
    border: 2px solid #bc3206;
  `;
}}
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Label = styled.label`
  font-size: ${_ref6 => {
  let {
    size
  } = _ref6;
  return convertPXToREM(size);
}};
  font-weight: ${_ref7 => {
  let {
    weight
  } = _ref7;
  return weight;
}};
  color: ${_ref8 => {
  let {
    color
  } = _ref8;
  return color;
}};
  margin-bottom: ${_ref9 => {
  let {
    marginBottom
  } = _ref9;
  return marginBottom || '8px';
}};
  align-self: flex-start;
`;
export const LabelCount = styled.label`
  margin: 8px 0px 0px;
  font-weight: 700;
  font-size: ${convertPXToREM(14)};
  color: #adb5bd;
  text-align: right;
  flex: 1;
`;