import React from 'react';

import { ButtonPrimary, Typography } from '@sofia/ui';
import { clickByKey } from '@ampli/utils';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import { ImageWomanShockedBySomething } from '../../image';
import { routePath } from '../../../routes';

import * as Styled from './unexpected-error.styles';

const UnexpectedError = () => {
  const theme = useTheme();
  const history = useHistory();

  const onBackToTheBeginning = () => {
    history.replace(routePath.courseSelection);
    window.location.reload();
  };

  return (
    <Styled.Card>
      <ImageWomanShockedBySomething aria-hidden />
      <Styled.TextCol>
        <Typography size={32} weight={700} color={theme.colors.text.black}>
          Ops! Algo de errado aconteceu.{' '}
        </Typography>
        <Typography
          size={20}
          margin="24px 0 0 0"
          color={theme.colors.text.black}
        >
          Já estamos atuando na solução, tente novamente mais tarde.
        </Typography>
        <Styled.ButtonWrapper
          borderBoxColor={theme.colors.button.backgroundColor}
        >
          <ButtonPrimary
            background={theme.colors.button.primary}
            color={theme.colors.button.secondary}
            hover={theme.colors.button.darkPrimary}
            size={16}
            tabIndex={0}
            onKeyDown={() => clickByKey(onBackToTheBeginning)}
            onClick={onBackToTheBeginning}
          >
            Voltar ao início
          </ButtonPrimary>
        </Styled.ButtonWrapper>
      </Styled.TextCol>
    </Styled.Card>
  );
};

UnexpectedError.propTypes = {};

export default UnexpectedError;
