const downloadDocument = async ({ documentUrl, documentName }) => {
  const anchor = document.createElement('a');
  anchor.download = documentName;
  anchor.target = '_blank';

  anchor.href = documentUrl;
  document.body.appendChild(anchor);
  anchor.click();

  document.body.removeChild(anchor);
};

export default downloadDocument;
