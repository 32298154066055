import React from 'react';

import { generatePath, useHistory } from 'react-router-dom';
import { SvgUsersAlt, Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { useSectionContext } from '../../../../../section/pages/learning-object/section-provider';
import { useBreakpoint } from '../../../../..';
import { CommunityPath } from '../../../../routes';
import { useGetState } from '../../../../../../state';

import * as Styled from './community-section-button.styles';

const CommunitySectionButton: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();

  const { isDesktop } = useBreakpoint();

  const [
    {
      selectedCourseEnrollmentType,
      selectedCourseVersionId,
      openCommunitySection,
    },
  ] = useGetState();

  const { subjectEnrollment, learningUnit, learningObject, section } =
    useSectionContext();

  const buttonTitle = 'Explorar aula na comunidade';

  return (
    <Styled.CommunitySectionButton
      aria-label={buttonTitle}
      isActive={openCommunitySection}
      data-testid="community-btn"
      onClick={() =>
        history.push(
          generatePath(CommunityPath.sectionPublications, {
            courseType: selectedCourseEnrollmentType,
            courseVersionId: selectedCourseVersionId,
            subjectEnrollmentId: subjectEnrollment?.id,
            learningUnitEnrollmentId: learningUnit?.id,
            learningObjectPath: learningObject?.pathUrl as string,
            sectionPath: section?.pathUrl as string,
          })
        )
      }
    >
      <SvgUsersAlt
        fill={
          openCommunitySection || !isDesktop
            ? theme.colors.text.primary
            : theme.colors.text.secondary
        }
      />

      <Typography
        weight={!isDesktop ? 700 : 400}
        color={
          openCommunitySection || !isDesktop
            ? theme.colors.text.primary
            : theme.colors.text.secondary
        }
      >
        {buttonTitle}
      </Typography>
    </Styled.CommunitySectionButton>
  );
};

export default CommunitySectionButton;
