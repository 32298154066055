import React from 'react';
import { Switch } from 'react-router-dom';
import { GuardedRoute } from 'react-router-guards';
import { useServiceClient } from '@ampli/services';

import {
  ElectiveSubjectChoicesPage,
  ElectiveSubjectOnBoardingPage,
  ElectiveSubjectPresentationPage,
} from './lazy-routes';
import { useGetState } from '../state';
import { contentGuard } from '../guards';
import { useQueryParams } from '../hooks';
import routePath from './routes-path';
import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';

const Routes = () => {
  const { states } = useCourseEnrollmentsContext();
  const response = states.courseEnrollments;

  const handleGuardedRoute = async (to, from, next) => {
    await contentGuard(to, from, next, response);
  };
  const clients = useServiceClient('all');

  const queryParams = useQueryParams();

  const [state, actions] = useGetState();

  const meta = { clients, actions, state, queryParams };
  const authMeta = { ...meta, auth: { redirect: routePath.login } };

  return (
    <Switch>
      <GuardedRoute
        exact
        path={routePath.electiveSubjectOnboarding}
        meta={authMeta}
      >
        <ElectiveSubjectOnBoardingPage />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={routePath.electiveSubjectPresentation}
        meta={authMeta}
        guards={[handleGuardedRoute]}
      >
        <ElectiveSubjectPresentationPage />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={routePath.electiveSubjectChoices}
        meta={authMeta}
        guards={[handleGuardedRoute]}
      >
        <ElectiveSubjectChoicesPage />
      </GuardedRoute>
    </Switch>
  );
};

export default Routes;
