import {
  SectionLearningObjectTemplateMenu,
  SectionContent,
  SectionLayout,
  SectionBar,
  ObjectCustomizationMenu,
  NotesMenu,
} from '../../../shared/components/section';

import { UnsupportedMSEModal } from '../../../../components';
import { TalkTutorFrame } from '../../../messaging';
import Footer from './components/footer/footer';
import React from 'react';
import '../../../../assets/styles/fonts.css';
import useSectionPageLogic from './section-page.logic';
import { pushEvent } from '../../../../lib/ga';
import { useDialogState } from '@ampli/ui';
import { useUnsupportedMSE } from '@ampli/hooks';
import { logMessage } from '../../../shared';
import { ELogType } from '../../../shared/utils/log-message';
import { CommunityMenu } from '../../../community/features/discuss-on-community';
import { Toast } from '@sofia/ui';

const SectionPagePresentation = (): React.ReactElement => {
  const {
    selectedCustomization,
    openTalkTutorFrame,
    sectionNavigationRef,
    learningObjects,
    subjectId,
    loading,
    pageTitle,
    talkTutorInfos,
    section,
    learningUnit,
    learningObject,
    studentId,
    learningObjectPath,
    subjectEnrollmentId,
    dispatch,
    onCloseCustomization,
    handleMenuItemClick,
    persistData,
    openNotes,
    openMenu,
    openCustomization,
    setOpenMenu,
    setOpenCustomization,
    setOpenNotes,
    setOpenTalkTutorFrame,
    attendanceErrorMsg,
  } = useSectionPageLogic();

  const unsupportedMSEDialog = useDialogState();
  useUnsupportedMSE(unsupportedMSEDialog.show);

  return (
    <SectionLayout
      topBar={
        <SectionBar
          showNotesButton
          studentId={studentId}
          learningObjects={learningObjects}
          subjectEnrollmentId={subjectEnrollmentId}
          hideCloseButton={openTalkTutorFrame}
          talkTutorInfos={talkTutorInfos}
          onHelpClick={() => {
            setOpenMenu(false);
            onCloseCustomization();
            setOpenTalkTutorFrame(true);
            dispatch({
              openTalkTutorFrameDate: new Date().toISOString(),
            });
            setOpenNotes(false);
          }}
          onOpenMenuClick={() => {
            setOpenMenu(true);
            onCloseCustomization();
            setOpenTalkTutorFrame(false);
            setOpenNotes(false);
          }}
          onOpenCustomizationClick={() => {
            setOpenCustomization(true);
            setOpenMenu(false);
            setOpenTalkTutorFrame(false);
            setOpenNotes(false);
          }}
          onOpenNotesClick={() => {
            pushEvent('event', 'note-menu-open');
            setOpenMenu(false);
            onCloseCustomization();
            setOpenTalkTutorFrame(false);
            setOpenNotes(true);
          }}
        />
      }
      leftMenus={
        section ? (
          <SectionLearningObjectTemplateMenu
            loading={loading}
            open={openMenu}
            pageTitle={pageTitle}
            sectionTitle={section.title}
            sectionProgress={section.progress?.percentage || 0}
            sectionOrder={section.order}
            onToggleMenuClick={() => {
              setOpenMenu(!openMenu);
              setOpenTalkTutorFrame(false);
            }}
            learningObjects={learningObjects}
            activeLearningObjectPath={learningObjectPath}
            onMenuItemClick={handleMenuItemClick}
          />
        ) : null
      }
      rightMenus={
        <>
          <CommunityMenu />
          <TalkTutorFrame infos={talkTutorInfos} />
          <ObjectCustomizationMenu
            studentId={studentId}
            handle={openCustomization}
            template={learningUnit?.template}
            onClose={onCloseCustomization}
            onApply={(selectedCustomization) => {
              dispatch({ selectedCustomization: selectedCustomization });
              pushEvent(
                'event',
                `kindle_theme_${selectedCustomization?.theme?.title}`
              );
              pushEvent(
                'event',
                `kindle_font_${selectedCustomization?.formatFont}`
              );
              pushEvent(
                'event',
                `kindle_font_size_${selectedCustomization?.fontSize}`
              );
            }}
          />
          <NotesMenu
            loading={loading}
            learningUnitOrder={learningUnit?.order}
            learningUnitTitle={learningUnit?.title}
            learningObjectId={learningObject?.id}
            learningUnitId={learningUnit?.id}
            learningUnitVersionId={learningUnit?.learningUnitVersionId}
            sections={learningUnit?.sections}
            learningObjects={learningObjects}
            subjectId={subjectId}
            currentSection={section}
            open={openNotes}
            onToggleMenuClick={() => {
              setOpenNotes(!openNotes);
              pushEvent('event', 'notes-menu-close');
            }}
          />
        </>
      }
    >
      {learningObject?.type === 'video' && (
        <UnsupportedMSEModal {...unsupportedMSEDialog} />
      )}

      <Toast
        text="Encontramos um problema ao salvar a presença. Mantenha-se conectado enquanto corrigimos."
        type="warning"
        show={Boolean(attendanceErrorMsg.current)}
        aria-live="assertive"
      />

      <SectionContent
        loading={loading}
        pageTitle={pageTitle}
        learningObject={learningObject}
        customization={
          Object.keys(persistData || {})?.length > 0
            ? persistData
            : selectedCustomization
        }
        setIsVideoViewCompleted={(value) => {
          logMessage(ELogType.log, 'chamada para setar video como completo');
          dispatch({ isVideoViewCompleted: value });
        }}
        template={
          Object.keys(persistData || {})?.length === 0 ||
          persistData?.theme?.id === 5
            ? learningUnit?.template
            : null
        }
      />
      <Footer learningObjects={learningObjects} ref={sectionNavigationRef} />
    </SectionLayout>
  );
};

export default SectionPagePresentation;
