import React from 'react';

interface SvgElogioSuccessIllustrationProps {
  title?: string;
  desc?: string;
}

const SvgElogioSuccessIllustration: React.FC<
  SvgElogioSuccessIllustrationProps
> = ({ title, desc, ...props }: SvgElogioSuccessIllustrationProps) => {
  return (
    <svg
      width="245"
      height="276"
      viewBox="0 0 245 276"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={`${title ? 'iconElogioSuccessIllustrationTitle' : ''} ${
        desc ? 'iconElogioSuccessIllustrationDesc' : ''
      }`}
      {...props}
    >
      {title && <title id="iconElogioSuccessIllustrationTitle">{title}</title>}
      {desc && <desc id="iconElogioSuccessIllustrationDesc">{desc}</desc>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.137 4.35991C190.708 -14.6048 241.857 28.6632 226.899 127.06C221.26 164.135 191.639 189.72 154.627 192.154C130.207 193.755 104.058 195.728 80.6458 189.15C34.6542 176.226 15.3048 140.642 19.6631 96.7826C24.6565 46.4768 45.7359 15.7497 100.137 4.35991Z"
        fill="#E5EDFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.886 265.404C157.758 234.293 136.624 167.84 131.324 141.125C138.354 119.529 185.474 117.205 189.865 124.682C204.528 166.338 215.325 185.127 239.273 221.533C235.473 249.947 185.967 267.881 183.886 265.404Z"
        fill="#FFAB15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.2629 275.455C83.5367 248.05 101.901 182.572 110.727 150.869C106.883 128.484 60.5847 119.386 55.1643 126.182C36.8991 165.416 20.1231 207.917 0.5 228.318C17.2869 265.589 51.6273 278.985 55.2629 275.455Z"
        fill="#FFAB15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.816 39.6582C231.247 50.4341 225.071 53.5912 219.584 54.7094C229.834 55.0053 231.017 56.4743 233.82 67.3379C232.659 59.2039 232.725 53.2623 243.5 49.5571C241.365 49.9737 230.765 54.7422 228.816 39.6582Z"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.8977 36.5557C55.3287 47.3315 49.1526 50.4887 43.6665 51.6068C53.9161 51.9028 55.0987 53.3717 57.902 64.2353C56.7413 56.1013 56.807 50.1598 67.5821 46.4546C65.4359 46.8931 54.8359 51.6507 52.8977 36.5557Z"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.3931 1.59766C101.671 4.53554 103.204 6.05929 104.507 9.58913"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.913 94.4255C226.264 92.8141 228.06 91.6301 231.783 91.082"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M223.724 123.19C227.315 124.144 229.451 124.429 232.637 126.479"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.4312 4.88574C87.7088 7.82362 89.2419 9.34738 90.545 12.8772"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.051 43.1223C192.745 40.5571 194.103 38.8799 197.487 37.2246"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.127 38.1233C186.821 35.5581 188.179 33.8809 191.562 32.2256"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.8659 96.0691C30.5151 94.4576 28.7192 93.2737 24.9961 92.7256"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.0772 124.835C29.4854 125.789 27.3501 126.074 24.1636 128.124"
        stroke="black"
        strokeWidth="2.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.886 265.404C157.758 234.293 136.624 167.84 131.324 141.125C138.354 119.529 185.474 117.205 189.865 124.682C204.528 166.338 215.325 185.127 239.273 221.533C235.473 249.947 185.967 267.881 183.886 265.404Z"
        fill="#004ECC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.487 80.7884C134.434 57.4827 128.127 46.959 121.983 50.6752C111.57 56.9675 122.246 73.3451 134.445 94.1295C118.392 81.1831 105.109 75.2854 100.882 81.9504C94.9689 91.2574 103.434 93.7568 126.835 113.533C128.63 123.278 129.824 129.439 140.446 133.385C142.242 134.054 141.979 137.003 142.132 139.864C150.312 138.022 163.836 137.946 179.155 122.555C180.415 120.647 198.516 78.7056 187.719 71.4596C185.036 69.6727 173.954 70.2757 168.807 90.3585C166.07 56.9785 158.711 41.7738 151.966 42.5193C147.071 43.0564 145.33 57.0223 147.487 80.7884Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.816 39.6582C231.247 50.4341 225.071 53.5912 219.584 54.7094C229.834 55.0053 231.017 56.4743 233.82 67.3379C232.659 59.2039 232.725 53.2623 243.5 49.5571C241.365 49.9737 230.765 54.7422 228.816 39.6582Z"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.8977 36.5557C55.3287 47.3315 49.1526 50.4887 43.6665 51.6068C53.9161 51.9028 55.0987 53.3717 57.902 64.2353C56.7413 56.1013 56.807 50.1598 67.5821 46.4546C65.4359 46.8931 54.8359 51.6507 52.8977 36.5557Z"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.3931 1.59766C101.671 4.53554 103.204 6.05929 104.507 9.58913"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.913 94.4255C226.264 92.8141 228.06 91.6301 231.783 91.082"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M223.724 123.19C227.315 124.144 229.451 124.429 232.637 126.479"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.4312 4.88574C87.7088 7.82362 89.2419 9.34738 90.545 12.8772"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.051 43.1223C192.745 40.5571 194.103 38.8799 197.487 37.2246"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.127 38.1233C186.821 35.5581 188.179 33.8809 191.562 32.2256"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.8659 96.0691C30.5151 94.4576 28.7192 93.2737 24.9961 92.7256"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.0772 124.835C29.4854 125.789 27.3501 126.074 24.1636 128.124"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.2629 275.455C83.5367 248.05 101.901 182.572 110.727 150.869C106.883 128.484 60.5847 119.386 55.1643 126.182C36.8991 165.416 20.1231 207.917 0.5 228.318C17.2869 265.589 51.6273 278.985 55.2629 275.455Z"
        fill="#B1C2D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.445 88.4179C119.717 67.2498 127.481 57.7236 133.011 62.3059C142.406 70.0452 129.485 84.7017 114.395 103.502C132.146 93.0111 146.151 89.0976 149.371 96.2998C153.882 106.374 145.144 107.613 119.137 123.804C115.961 133.188 113.88 139.151 102.81 141.486C100.937 141.892 100.762 144.852 100.203 147.647C92.3739 144.643 79.0035 142.615 66.0601 125.174C65.0965 123.113 53.2263 78.9575 64.9651 73.3777C67.8779 71.9965 78.7516 74.1889 80.9636 94.8089C88.4865 62.1743 97.9586 48.1865 104.529 49.8637C109.292 51.1463 109.008 65.2218 103.445 88.4179Z"
        fill="white"
      />
      <path
        d="M101.375 142.265C101.178 143.536 99.9513 146.715 99.8856 147.472M194.157 140.335C179.462 158.807 151.374 166.173 136.12 163.531M178.279 124.067C181.061 121.338 196.698 78.1683 188.058 73.1914C174.458 65.3425 166.573 98.8541 165.588 99.0404C157.222 100.685 145.187 117.04 150.257 125.613M167.778 90.7968C169.968 49.9625 140.522 11.5837 147.509 81.6543C148.483 91.5203 129.309 35.4485 118.764 54.742C117.308 57.4058 118.238 62.2402 120.264 67.7651M128.652 85.3705L128.86 85.7542C130.689 89.0428 132.43 92.0355 133.755 94.3047M133.109 94.524C131.707 93.4277 129.331 91.4436 126.451 89.3498L125.98 89.01M109.555 80.58C108.254 80.2912 106.917 80.2062 105.591 80.3279H105.262M127.787 118.597C127.926 119.16 128.065 119.701 128.203 120.219M141.442 134.657C141.442 135.95 142.209 139.272 142.165 140.028M180.743 122.489C163.223 140.247 143.337 140.642 137.84 141.124M137.117 134.229C135.792 134.975 129.944 138.494 131.532 144.336C136.372 171.742 163.069 241.9 185.309 265.162M65.5342 122.532C49.2181 64.7067 71.9073 61.692 81.686 92.6056C86.9203 53.4704 120.012 21.2304 103.423 87.6506C101.035 97.2207 128.094 44.5581 135.737 65.178C138.705 73.2024 120.79 95.1269 114.34 103.25C113.42 104.412 142.209 84.1647 149.535 95.5106C156.86 106.856 119.969 121.414 119.114 123.388C114.614 134.35 113.705 138.406 101.517 141.113M64.2859 124.528C79.1017 144.632 98.681 147.899 104.058 149.182M48.4188 140.269C60.2999 160.681 87.0298 172.06 102.459 171.599M63.9464 123.848C65.1509 124.769 54.8247 122.752 52.4156 128.233C45.1117 150.289 22.773 201.044 0.839355 228.636M128.181 120.11C130.152 127.608 132.08 131.072 141.672 134.679C132.091 131.138 130.163 127.718 128.192 120.219L128.181 120.11Z"
        stroke="black"
        strokeWidth="1.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgElogioSuccessIllustration;
