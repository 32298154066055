import { gql } from 'graphql.macro';

export const GET_TUTOR_SECTION = gql`
  query GetSection(
    $learningUnitEnrollmentId: ID!
    $sectionId: ID!
    $secret: ID!
  ) {
    data: getSection(
      learningUnitEnrollmentId: $learningUnitEnrollmentId
      sectionId: $sectionId
      secret: $secret
    ) {
      id
      title
      order
      learningObjects {
        id
        title
        subtitle
        duration
        assets {
          ... on Asset {
            id
            type
          }
          ... on VideoAsset {
            mediastreamId
            captionLink
            size
            videoLink
          }
          ... on ImageAsset {
            caption
            imageId
            image {
              formats
              baseUrl
              descriptionText
            }
            imageUrls @client
          }
          ... on FileAsset {
            altText
            description
            id
            mimeType
            size
            type
            url
          }
          ... on TextAsset {
            content
          }
          ... on TableAsset {
            content
          }
          ... on FormulaAsset {
            content
          }
        }
        type @client
        pathUrl @client
      }
    }
  }
`;

export const GET_TUTOR_SECTION_GUARD = gql`
  query GetSectionGuard(
    $learningUnitEnrollmentId: ID!
    $sectionId: ID!
    $secret: ID!
  ) {
    data: getSection(
      learningUnitEnrollmentId: $learningUnitEnrollmentId
      sectionId: $sectionId
      secret: $secret
    ) {
      id
      title
    }
  }
`;
