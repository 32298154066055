import React from 'react';

import {
  courseTypeLabelByDegree,
  locationPathname,
} from '../../../../constants';

import {
  UnsubscribeType,
  SubscriptonType,
  DirectLine,
  ICopilotQuizPayload,
} from '../../types';
import { useCourseEnrollmentsContext } from '../../../../modules/shared/contexts/course-enrollments-context';
/* TODO: REVER PAYLOAD */
import { ISectionCopilotPayload } from '../../../../modules/section/pages/learning-object/types';

export const useStartConversation = (
  directLine: DirectLine,
  quizPagePayload?: ISectionCopilotPayload | ICopilotQuizPayload,
  isQuizPageRendered?: boolean
): void => {
  const actualPath = locationPathname();

  const { states } = useCourseEnrollmentsContext();

  const { data: selectedEnrollment, loading: loadingEnrollment } =
    states.courseEnrollments;

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const locale = document.documentElement.lang || 'pt-BR';

  React.useEffect(() => {
    if (directLine && !loadingEnrollment) {
      // Inicia o chat passando os parametros iniciais
      let subscription: SubscriptonType;

      if (isQuizPageRendered) {
        const quizSubscription = directLine.connectionStatus$.subscribe({
          next: async (value: number) => {
            try {
              if (value === 2) {
                directLine
                  .postActivity({
                    localTimezone: localTimezone,
                    locale,
                    name: 'pvaSetContext',
                    value: quizPagePayload as
                      | { [key: string]: string }
                      | undefined,
                    type: 'event',
                  })
                  .subscribe({
                    complete: () => {
                      directLine
                        .postActivity({
                          localTimezone,
                          locale,
                          name: 'startConversation',
                          type: 'event',
                        })
                        .subscribe();
                    },
                  });

                (quizSubscription as unknown as UnsubscribeType).unsubscribe();
              }
            } catch (error) {
              console.error(
                'Erro ao enviar atividades para o Direct Line:',
                error
              );
            }
          },
        });
        return;
      }

      // eslint-disable-next-line prefer-const
      subscription = directLine?.connectionStatus$?.subscribe({
        next: async (value: number) => {
          try {
            if (value === 2) {
              await directLine
                .postActivity({
                  localTimezone,
                  locale,
                  name: 'pvaSetContext',
                  type: 'event',
                  value: {
                    businessKeyProgramEnrollment:
                      selectedEnrollment?.externalId,
                    academicLevel:
                      courseTypeLabelByDegree[
                        selectedEnrollment?.course?.courseType
                          ?.degree as keyof typeof courseTypeLabelByDegree
                      ],
                    studentMoment: actualPath.includes('/aula/')
                      ? 'AULA'
                      : 'GERAL',
                  },
                })
                .subscribe({
                  complete: async () =>
                    await directLine
                      .postActivity({
                        localTimezone,
                        locale,
                        name: 'startConversation',
                        type: 'event',
                      })
                      .subscribe(),
                });
            }
          } catch (error) {
            console.error(
              'Erro ao enviar atividades para o Direct Line:',
              error
            );
          } finally {
            if (subscription)
              (subscription as unknown as UnsubscribeType).unsubscribe();
          }
        },
      });

      return () => {
        if (subscription)
          (subscription as unknown as UnsubscribeType).unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directLine]);
};
