import { gql } from 'graphql.macro';

export const STATUS_BILLING_DAY_FRAGMENT = gql`
  fragment StatusBillingDayFragment on StatusBillingDay {
    status
    contract {
      htmlDocumentUrl
    }
    options
    current
    newSelected
    warning
    message
    requestData {
      createdDate
      serviceForecast
      orderId
      title
      ticketNumber
      orderStatus
    }
    messageSuccess
  }
`;

export const GET_CURRENT_STATUS_CHANGE_BILLING_DAY = gql`
  query GetCurrentStatusChangeBillingDay($courseEnrollmentId: ID!) {
    data: getCurrentStatusChangeBillingDay(
      courseEnrollmentId: $courseEnrollmentId
    ) {
      ...StatusBillingDayFragment
    }
  }
  ${STATUS_BILLING_DAY_FRAGMENT}
`;

export const SELECT_DAY_CHANGE_BILLING_DAY = gql`
  mutation SelectDayChangeBillingDay(
    $courseEnrollmentId: ID!
    $selectedDay: String!
  ) {
    data: selectDayChangeBillingDay(
      courseEnrollmentId: $courseEnrollmentId
      selectedDay: $selectedDay
    ) {
      ...StatusBillingDayFragment
    }
  }
  ${STATUS_BILLING_DAY_FRAGMENT}
`;

export const ACCEPT_CONTRACT_CHANGE_BILLING_DAY = gql`
  mutation AcceptContractChangeBillingDay($courseEnrollmentId: ID!) {
    data: acceptContractChangeBillingDay(
      courseEnrollmentId: $courseEnrollmentId
    ) {
      ...StatusBillingDayFragment
    }
  }
  ${STATUS_BILLING_DAY_FRAGMENT}
`;

export const CANCEL_BILLING_DAY_REQUEST = gql`
  mutation CancelBillingDayRequest($courseEnrollmentId: ID!) {
    data: cancelBillingDayRequest(courseEnrollmentId: $courseEnrollmentId) {
      ...StatusBillingDayFragment
    }
  }
  ${STATUS_BILLING_DAY_FRAGMENT}
`;

export const CONFIRM_CHANGE_BILLING_DAY = gql`
  mutation ConfirmChangeBillingDay($courseEnrollmentId: ID!) {
    data: confirmChangeBillingDay(courseEnrollmentId: $courseEnrollmentId) {
      ...StatusBillingDayFragment
    }
  }
  ${STATUS_BILLING_DAY_FRAGMENT}
`;

export const CLEAR_SELECTED_DAY = gql`
  mutation ClearSelectedDay($courseEnrollmentId: ID!) {
    data: clearSelectedDay(courseEnrollmentId: $courseEnrollmentId) {
      ...StatusBillingDayFragment
    }
  }
  ${STATUS_BILLING_DAY_FRAGMENT}
`;
