import React from 'react';
import {
  useRouteMatch,
  useLocation,
  useHistory,
  useParams,
} from 'react-router-dom';
import { useHandleTrackAppend } from '../../../shared/components/section';
import { SectionParams, useFirstLearningObject } from '../../shared';
import { useCreateAttendance, useVisibilityElement } from './hooks';
import { useSectionContext } from './section-provider';
import { createPageTitle } from './utils';
import { createReducer, isNil, useBreakpoint } from '../../../shared';
import { sectionReducerInitialState } from './constants';
import {
  SectionPageReducerType,
  THandleMenuItemClick,
  TSectionPageLogic,
} from './types';
import '../../../../assets/styles/fonts.css';
import {
  NEW_TEMPLATE_COMMUNICATION_COMPLETED_DESCRIPTION,
  NEW_TEMPLATE_COMMUNICATION_COMPLETED_KEY,
} from '../../../../constants';
import { pushEvent } from '../../../../lib/ga';
import { useGetState } from '../../../../state';
import { SubjectEnrollmentStatus } from '../../../shared/graphql/types';

const useSectionPageLogic: TSectionPageLogic = () => {
  const reducer = createReducer<SectionPageReducerType>();
  const attendanceErrorMsg = React.useRef(false);
  const [
    { selectedCustomization, isVideoViewCompleted, openTalkTutorFrameDate },
    dispatch,
  ] = React.useReducer(reducer, sectionReducerInitialState);

  const [
    {
      openNotes,
      openMenu,
      openCustomization,
      openTalkTutorFrame,
      selectedCourseEnrollmentType,
      persistData,
    },
    {
      setOpenCommunitySection,
      setOpenTalkTutorFrame,
      setOpenCustomization,
      setOpenNotes,
      setOpenMenu,
    },
  ] = useGetState();

  const history = useHistory();
  const { learningObjectPath, subjectEnrollmentId } =
    useParams<SectionParams>();
  const { url: baseUrl } = useRouteMatch<SectionParams>();
  const { isDesktop } = useBreakpoint();
  const { state: locationState } = useLocation<{ backIterations?: number }>();

  const [isSectionNavigationVisible, sectionNavigationRef] =
    useVisibilityElement();

  const backIterations = locationState?.backIterations || 1;

  const {
    isLastLearningObjectToComplete,
    firstLearningObject,
    learningObject,
    learningUnit,
    section,
    learningObjects,
    subjectEnrollment,
    loading,
    me,
  } = useSectionContext();

  const subjectId = subjectEnrollment?.subject?.id || '';

  const pageTitle = createPageTitle({
    section,
    learningUnit,
    selectedCourseEnrollmentType,
  });

  const talkTutorInfos = React.useMemo(
    () => ({
      unity: `Unidade ${learningUnit?.order} - ${learningUnit?.title}`,
      unityID: learningUnit?.id,
      learningObject: learningObject?.title,
      learningObjectID: learningObject?.id,
      learningUnitEnrollmentId: learningUnit?.id,
      class: section?.title,
      classID: section?.id,
      subjectEnrollmentID: subjectEnrollmentId,
      subjectID: subjectId || '',
      openTalkTutorFrameDate: openTalkTutorFrameDate || '',
    }),
    [
      openTalkTutorFrameDate,
      subjectEnrollmentId,
      learningObject,
      learningUnit,
      section,
      subjectId,
    ]
  );

  useFirstLearningObject({
    learningObjectPath,
    firstLearningObjectPath: firstLearningObject?.pathUrl || '',
    baseUrl,
    locationState,
    history,
  });

  useCreateAttendance({
    attendance: {
      subjectEnrollmentId,
      learningUnitId: learningUnit.id,
      sectionId: section.id,
      learningObjectId: learningObject.id,
    },
    isSectionNavigationVisible,
    isVideoType: learningObject.type === 'video',
    isVideoViewCompleted,
    isLearningObjectCompleted: learningObject.completed,
    isLastLearningObjectToComplete,
    attendanceErrorMsg,
    isSubjectEnrollmentEnabled:
      subjectEnrollment?.status === SubjectEnrollmentStatus.InProgress ||
      subjectEnrollment?.status === SubjectEnrollmentStatus.InRecovery,
  });

  const { onCloseCustomization } = useHandleTrackAppend({
    studentId: me.id,
    input: {
      key: NEW_TEMPLATE_COMMUNICATION_COMPLETED_KEY,
      desc: NEW_TEMPLATE_COMMUNICATION_COMPLETED_DESCRIPTION,
    },
  });

  const handleMenuItemClick: THandleMenuItemClick = (_event, { pathUrl }) => {
    pushEvent('event', 'object_menu_navigation');
    if (!isDesktop) {
      setOpenMenu(false);
    }
    history.push(pathUrl, {
      ...locationState,
      backIterations: backIterations + 1,
      fromSubjectPage: false,
    });
  };

  React.useEffect(() => {
    if (isNil(openMenu)) {
      setOpenMenu(isDesktop);
    }
    if (openMenu) {
      setOpenCustomization(false);
    }
    if (isNil(openNotes)) {
      setOpenNotes(isDesktop);
    }
    if (openMenu && openNotes) {
      setOpenNotes(false);
    }
  }, [
    openMenu,
    isDesktop,
    setOpenMenu,
    openCustomization,
    setOpenCustomization,
    openNotes,
    setOpenNotes,
  ]);

  return {
    selectedCustomization,

    sectionNavigationRef,
    learningObjects,
    subjectId,
    loading,
    pageTitle,
    talkTutorInfos,
    section,
    learningObject,
    learningUnit,
    studentId: me.id,
    learningObjectPath,
    subjectEnrollmentId,
    dispatch,
    onCloseCustomization,
    handleMenuItemClick,
    openNotes,
    openMenu,
    openCustomization,
    selectedCourseEnrollmentType,
    setOpenMenu,
    setOpenCustomization,
    setOpenNotes,
    persistData,
    openTalkTutorFrame,
    setOpenTalkTutorFrame,
    setOpenCommunitySection,
    attendanceErrorMsg,
  };
};

export default useSectionPageLogic;
