import styled from '@emotion/styled';

export const Wrapper = styled.div`
  margin-top: 50px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const DialogSuccessHeader = styled.div`
  padding: 64px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
`;
