import { keyframes, Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { SvgCheck } from '@sofia/ui';

interface ICurtain {
  isFinished: boolean;
  isPrimary: boolean;
  theme: Theme;
}

const Bouncing = keyframes`
  0% { transform: none; }
  33% { transform: translateY(-.5em); }
  66% { transform: none; }
`;

const Curtain = ({ isFinished, isPrimary, theme }: ICurtain) => `
  height: 100%;
  position: absolute;
  background: ${
    isPrimary && !isFinished ? theme.colors.text.primary : 'transparent'
  };
  z-index: 1;
  transition-property: width;
  transition-timing-function: ease-in-out;
  transition-duration: 75ms;
  ${isFinished ? 'width: 50%;' : 'width: 0px;'}

`;

export const Check = styled(SvgCheck)`
  margin: 0 !important;
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 25%;
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Ellipsis = styled.div`
  display: flex;
  margin-right: 12px;
  margin-bottom: 6px;
  align-items: end;

  > div:not(:last-of-type) {
    margin-right: 4px;
  }
`;

export const EllipsisDot = styled.div(
  ({
    isPrimary,
    isFinished,
    theme,
  }: {
    isPrimary: boolean;
    isFinished: boolean;
    theme: Theme;
  }) => css`
    background-color: ${isFinished
      ? 'transparent'
      : theme.colors.text[isPrimary ? 'white' : 'primary']};
    width: 4px;
    height: 4px;
    border-radius: 50%;

    animation-name: ${Bouncing};
    animation-duration: 700ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    &:nth-child(2) {
      animation-delay: 125ms;
    }
    &:nth-child(3) {
      animation-delay: 250ms;
    }
  `
);

export const LeftCurtain = styled.div`
  left: 0;
  ${({ isFinished, isPrimary, theme }: ICurtain) =>
    Curtain({ isFinished, isPrimary, theme })}
`;

export const RightCurtain = styled.div`
  right: 0;
  ${({ isFinished, isPrimary, theme }: ICurtain) =>
    Curtain({ isFinished, isPrimary, theme })}
`;
