import styled from '@emotion/styled';
import { convertPXToREM, Card } from '@sofia/ui';

export const Container = styled.div`
  display: flex;

  width: 100%;
`;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  padding: 16px 24px !important;
  width: 100%;
  min-height: ${convertPXToREM(76)} !important;
`;

export const InformationSection = styled.section`
  display: flex;
  flex-direction: row;

  gap: 16px;

  align-items: center;
`;

export const MobileInformationSection = styled.section`
  display: flex;

  align-items: center;

  width: 100%;
  gap: 16px;

  justify-content: space-between;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: row;

  gap: 16px;

  align-items: center;
`;
