import { arrayOf, number, object, shape, string } from 'prop-types';
import { useTheme } from '@emotion/react';
import {
  Typography,
  SvgNotebookPen,
  ProgressBar,
  SvgInfoCircle,
  SvgExamPen,
  SvgAssignmentNote,
} from '@sofia/ui';
import * as Styled from './subject-progress-card-info-content-styles';
import React from 'react';

const SubjectProgressCardInfoContent = ({
  details,
  progress,
  entitiesLabelsDictionary,
}) => {
  const theme = useTheme();

  const detailIconByType = {
    EXAM: <SvgExamPen />,
    SEMESTRAL_EXAM_ONLINE: <SvgExamPen />,
    ASSIGNMENT: <SvgAssignmentNote />,
    QUIZ: <SvgNotebookPen />,
  };

  const detailLabelByType = {
    EXAM: 'Prova',
    SEMESTRAL_EXAM_ONLINE: 'Prova semestral',
    ASSIGNMENT: 'Trabalho',
    QUIZ: 'Exercício das unidades',
  };

  const resolvedLabel = (type) => {
    return (
      entitiesLabelsDictionary?.learningUnitQuizReplace?.(
        detailLabelByType[type]
      ) || detailLabelByType[type]
    );
  };

  return (
    <>
      <Typography
        id="modal-progress-text"
        size={16}
        color={theme.colors.text.lightBlack}
      >
        Assista as aulas para aumentar o seu progresso em aula.
      </Typography>

      <Styled.ArcContainer>
        <ProgressBar
          progressBar={{
            background: '#0041AF',
            height: 13,
          }}
          isTextVisible
          progress={progress}
        />
      </Styled.ArcContainer>
      <Styled.WarningContainer>
        <div>
          <SvgInfoCircle />
        </div>
        <Typography
          style={{ marginBottom: 16 }}
          weight={700}
          color={'#212529'}
          size={14}
        >
          É importante que você estude pelo menos 75% de sua disciplina para
          melhorar seu desempenho acadêmico e sua nota.
        </Typography>
      </Styled.WarningContainer>

      <Typography
        style={{ marginBottom: 16 }}
        id="modal-evaluative-objects-text"
        weight={700}
        size={24}
        color={theme.colors.text.black}
      >
        Objetos avaliativos
      </Typography>

      {details
        ?.filter(({ type }) => type !== 'ATTENDANCE')
        .map(({ type, quantity, completed }, index) => {
          return (
            <Styled.FlexSpaceBetween key={index}>
              <Styled.Flex id={`subject-progress${index}`}>
                {detailIconByType[type]}
                <Typography
                  margin="0 0 0 10px"
                  size={16}
                  color={theme.colors.text.lightBlack}
                >
                  {resolvedLabel(type)}
                </Typography>
              </Styled.Flex>
              <Typography
                margin="0 0 0 8px"
                id={`subject-progress${index}-value`}
                size={16}
                color={theme.colors.text.black}
              >
                {completed || 0}/{quantity}
              </Typography>
            </Styled.FlexSpaceBetween>
          );
        })}
    </>
  );
};

SubjectProgressCardInfoContent.propTypes = {
  details: arrayOf(
    shape({
      type: string.isRequired,
      quantity: number.isRequired,
      completed: number,
      percentage: number.isRequired,
    }).isRequired
  ).isRequired,
  progress: number,
  entitiesLabelsDictionary: object,
};

export default SubjectProgressCardInfoContent;
