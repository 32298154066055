import React from 'react';

import { generatePath, useHistory } from 'react-router-dom';
import { bool, func, object } from 'prop-types';
import { useBreakpoint } from '@ampli/hooks';
import { cx, GridItem } from '@ampli/ui';
import { useTheme } from '@emotion/react';
import { includes } from 'lodash';

import {
  convertPXToVH,
  Typography,
  SvgBookmarkFull,
  SvgBookOpen,
  SvgCalendarAlt,
  SvgPen,
  SvgBookReader,
  SvgFileAlt,
  SvgFolderCheck,
  SvgFileLandscapeAlt,
} from '@sofia/ui';

import {
  MessagingHistoricQuickAccess,
  NotesRoutesPath,
} from '../../../modules';

import { pushAmpliEvent } from '../../../lib/ga';
import { PaywallBanner } from '../../../components';
import { useGetState } from '../../../state';
import { SimpleCard } from '../simple-card';
import { routePath } from '../../../routes';
import { features } from '../../../config';

import {
  VIRTUAL_LIBRARY,
  freeTrialEnabledRole,
  EntitiesLabels,
} from '../../../constants';

import * as Styled from './home-quick-access.styles';

const HomeQuickAccess = ({
  course,
  courseEnrollmentData,
  blockCourseEnrollmentContentAccess,
  onAcademicCalendarClick,
  subjectsClick,
  studentFreeTrialCourseData,
  withoutAccessDialog,
  onGradeClick,
  selectExamDialog,
  loading,
}) => {
  const theme = useTheme();
  const history = useHistory();

  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const gridItemColumn = isDesktop ? 'span 2' : 'span 4';
  const [
    {
      selectedCourseEnrollmentType,
      selectedCourseEnrollmentId,
      selectedCourseVersionId,
    },
  ] = useGetState();

  const entitiesLabelsDictionary = EntitiesLabels(selectedCourseEnrollmentType);

  const roles = React.useMemo(
    () => courseEnrollmentData?.roles,
    [courseEnrollmentData?.roles]
  );

  const isAmpli = roles?.includes('CHAT_ENABLED');

  const isFreeTrialEnabled = React.useMemo(
    () => includes(roles, freeTrialEnabledRole),
    [roles]
  );

  const examClick = React.useCallback(() => {
    if (blockCourseEnrollmentContentAccess) {
      withoutAccessDialog.show();
    } else {
      selectExamDialog.show();
    }
  }, [
    blockCourseEnrollmentContentAccess,
    selectExamDialog,
    withoutAccessDialog,
  ]);

  const gradeClick = React.useCallback(() => {
    if (blockCourseEnrollmentContentAccess) {
      withoutAccessDialog.show();
    } else {
      onGradeClick();
    }
  }, [blockCourseEnrollmentContentAccess, onGradeClick, withoutAccessDialog]);

  const virtualLibrary = React.useCallback(() => {
    if (blockCourseEnrollmentContentAccess) {
      withoutAccessDialog.show();
    } else {
      window.open(VIRTUAL_LIBRARY.url);
    }
  }, [blockCourseEnrollmentContentAccess, withoutAccessDialog]);

  const calendarClick = React.useCallback(() => {
    if (blockCourseEnrollmentContentAccess) {
      withoutAccessDialog.show();
    } else {
      onAcademicCalendarClick();
    }
  }, [
    blockCourseEnrollmentContentAccess,
    onAcademicCalendarClick,
    withoutAccessDialog,
  ]);

  const complementActivitiesClick = React.useCallback(() => {
    if (blockCourseEnrollmentContentAccess) {
      withoutAccessDialog.show();
    } else {
      history.push(
        generatePath(routePath.aco, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
          courseEnrollmentId: selectedCourseEnrollmentId,
        })
      );
    }
  }, [
    history,
    blockCourseEnrollmentContentAccess,
    selectedCourseEnrollmentId,
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
    withoutAccessDialog,
  ]);

  const tutorsClick = React.useCallback(() => {
    history.push(
      generatePath(routePath.tutor, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
      }),
      {
        courseId: courseEnrollmentData.courseId,
        courseVersionId: courseEnrollmentData.courseVersionId,
      }
    );
  }, [
    courseEnrollmentData.courseId,
    courseEnrollmentData.courseVersionId,
    history,
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
  ]);

  const myNotesClick = () => {
    pushAmpliEvent('event', 'home-quick-access-notes');
    history.push(
      generatePath(NotesRoutesPath.notes, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
      })
    );
  };

  return (
    <>
      <Typography
        color={theme.colors.text.dark}
        weight="bold"
        margin={`${convertPXToVH(16)} 0px 0px 0px`}
        grid="span 4"
        size={isDesktop ? '40' : '24'}
        data-testid="quick-access"
        id="quick-access"
      >
        Acessos rápidos
        {isFreeTrialEnabled ? (
          <PaywallBanner
            studentFreeTrialCourseData={
              studentFreeTrialCourseData?.freeTrialSituation
            }
            selectedCourseEnrollmentId={selectedCourseEnrollmentId}
            selectedCourseEnrollmentType={selectedCourseEnrollmentType}
          />
        ) : null}
      </Typography>

      {/* CARDS LIST */}
      <GridItem column={!isAmpli && !loading ? gridItemColumn : 'span 4'}>
        <Styled.GridWrapper>
          <SimpleCard
            onClick={myNotesClick}
            compact={!isDesktop}
            icon={<SvgFileLandscapeAlt fill={theme.colors.icon.black} />}
            title="Minhas anotações"
            description={entitiesLabelsDictionary.noteButtonDescription}
            id="myNotes"
            minHeight="initial"
          />
        </Styled.GridWrapper>
      </GridItem>

      {!isAmpli && !loading ? (
        <GridItem column={gridItemColumn}>
          <Styled.GridWrapper>
            <MessagingHistoricQuickAccess />
          </Styled.GridWrapper>
        </GridItem>
      ) : null}

      {features.exam ? (
        <GridItem
          column={gridItemColumn}
          aria-label="Provas finais disponíveis"
        >
          <Styled.GridWrapper>
            <SimpleCard
              data-testid="finalExam"
              id="finalExam"
              onClick={examClick}
              compact={!isDesktop}
              title="Fazer prova"
              icon={<SvgFileAlt fill={theme.colors.icon.black} />}
              description="Selecione e faça as provas disponíveis das suas disciplinas."
            />
          </Styled.GridWrapper>
        </GridItem>
      ) : null}
      {features.grade ? (
        <GridItem column={gridItemColumn} aria-label="Notas e correções">
          <Styled.GridWrapper>
            <SimpleCard
              data-testid="notesCorrections"
              id="notesCorrections"
              onClick={gradeClick}
              compact={!isDesktop}
              title="Notas e correções"
              icon={<SvgFolderCheck fill={theme.colors.icon.black} />}
              description="Confira suas notas, correções e desempenho nas disciplinas."
            />
          </Styled.GridWrapper>
        </GridItem>
      ) : null}
      <GridItem column={gridItemColumn}>
        <Styled.GridWrapper>
          <SimpleCard
            isExternalLink
            onClick={virtualLibrary}
            compact={!isDesktop}
            id="library"
            data-testid="library"
            title="Biblioteca"
            icon={<SvgBookOpen fill={theme.colors.icon.black} />}
            description="Conheça nossa biblioteca virtual e acesse nosso acervo digital."
          />
        </Styled.GridWrapper>
      </GridItem>
      <GridItem column={gridItemColumn}>
        <Styled.GridWrapper>
          <SimpleCard
            onClick={subjectsClick}
            compact={!isDesktop}
            id="mySubjects"
            data-testid="mySubjects"
            title="Minhas disciplinas"
            icon={<SvgBookmarkFull fill={theme.colors.icon.black} />}
            description="Confira sua grade de disciplinas e sua matriz curricular completa."
          />
        </Styled.GridWrapper>
      </GridItem>

      {course?.complementaryWorkload > 0 ? (
        <GridItem column={gridItemColumn}>
          <Styled.GridWrapper>
            <SimpleCard
              onClick={complementActivitiesClick}
              compact={!isDesktop}
              id="complementariesActivities"
              data-testid="complementariesActivies"
              title="Atividades complementares"
              icon={<SvgPen fill={'#212529'} />}
              description="Confira suas horas e envie suas atividades complementares."
            />
          </Styled.GridWrapper>
        </GridItem>
      ) : null}
      <GridItem column={gridItemColumn}>
        <Styled.GridWrapper>
          <SimpleCard
            onClick={calendarClick}
            compact={!isDesktop}
            title="Calendário"
            data-testid="calendar"
            id="calendar"
            icon={<SvgCalendarAlt fill={theme.colors.icon.black} />}
            description="Confira as datas relacionadas às suas atividades acadêmicas."
          />
        </Styled.GridWrapper>
      </GridItem>
      <GridItem column={gridItemColumn}>
        <Styled.GridWrapper>
          <SimpleCard
            className={cx(blockCourseEnrollmentContentAccess && 'disabled')}
            onClick={tutorsClick}
            compact={!isDesktop}
            icon={<SvgBookReader color={theme.colors.icon.black} />}
            aria-label="Conheça seus tutores, botão"
            title="Tutores"
            description="Conheça quem são seus Tutores e Coordenadores."
            id="tutors"
            data-testid="tutors"
          />
        </Styled.GridWrapper>
      </GridItem>
    </>
  );
};

HomeQuickAccess.propTypes = {
  loading: bool,
  course: object,
  courseEnrollmentData: object,
  blockCourseEnrollmentContentAccess: bool,
  onAcademicCalendarClick: func,
  withoutAccessDialog: object,
  studentFreeTrialCourseData: object,
  onGradeClick: func.isRequired,
  subjectsClick: func.isRequired,
  selectExamDialog: object.isRequired,
};

export default HomeQuickAccess;
