const filterFreeTrialDataFromByEnrollmentId = (
  studentFreeTrialData,
  enrollmentId
) => {
  return studentFreeTrialData?.find(
    (studentFreeTrial) => studentFreeTrial.id === enrollmentId
  );
};

export default filterFreeTrialDataFromByEnrollmentId;
