import { useListAvailableExams } from '@ampli/services';
import { useFindInauguralSubject } from '../modules/inaugural-subject/services/inaugural-subject';

import useSharedPageData from './use-shared-page-data';

const useHomePageData = () => {
  const {
    loading: sharedPageLoading,
    me,
    courseEnrollment,
    ...sharedPageData
  } = useSharedPageData();

  const { data: availableExams, loading: availableExamsLoading } =
    useListAvailableExams(courseEnrollment?.id);

  const { data: inauguralSubject, loading: inauguralSubjectLoading } =
    useFindInauguralSubject(courseEnrollment?.id);

  const loading =
    sharedPageLoading || availableExamsLoading || inauguralSubjectLoading;

  return {
    ...sharedPageData,
    subjectEnrollmentAvailableExams:
      availableExams?.subjectEnrollmentAvailableExams,
    me,
    loading,
    courseEnrollment,
    inauguralSubject,
  };
};

export default useHomePageData;
