import * as Yup from 'yup';
import { isValidDocumentTest, isValidMimeType } from '../schema-tests';
const requiredMsg = 'Preenchimento obrigatório.';
const requiredMsgDocumentType = 'Selecione o documento que será enviado.';
const requiredMsgDocumentFile = 'Você não selecionou um documento.';
const requiredMsgUploadFile = 'Você não fez upload de nenhum arquivo';
const unspecifiedDocumentType = 'UNSPECIFIED_DOCUMENT_TYPE';
const documentTypes = [unspecifiedDocumentType, 'CPF', 'SELFIE', 'RG', 'RG_BACK', 'DRIVER_LICENSE', 'PASSPORT', 'ACADEMIC_TRANSCRIPT'];
const VALID_PHONE_NUMBER_REGEX = /^((\d{2,3}|\+\d{2})[( .-]*([1-9]\d)[-. )]*|\([1-9]\d\)|[1-9]\d[ -.]*)\s*([9]{0,1}\d{4}[ -.]*\d{4})$/g;
const REQUIRED_FIELD_ERROR_MESSAGE_PHONE = 'Celular principal preenchimento obrigatório.';
const INVALID_FIELD_ERROR_MESSAGE_MAIN_PHONE = 'Número do celular principal inválido.';
const INVALID_FIELD_ERROR_MESSAGE_SECOND_PHONE = 'Número do celular secundário inválido.';
const INVALID_FIELD_ERROR_MESSAGE_HOME_PHONE = 'Número do telefone fixo inválido.';
const REQUIRED_FIELD_ERROR_MESSAGE_EMAIL = 'E-mail principal preenchimento obrigatório.';
const INVALID_EMAIL_ERROR_MESSAGE = 'E-mail inválido.';
const assignmentDocumentTypes = [unspecifiedDocumentType, 'ASSIGNMENT'];
export const getStudentUserLoginInfoSchema = () => Yup.object({
  username: Yup.string().required(requiredMsg).test(isValidDocumentTest())
});
export const uploadAssignmentDocumentSchema = () => Yup.object({
  assignmentFileType: Yup.string().required(requiredMsgDocumentType).oneOf(assignmentDocumentTypes),
  assignmentFile: Yup.mixed().required(requiredMsgUploadFile)
});
export const uploadStudentDocumentSchema = () => Yup.object({
  documentType: Yup.string().required(requiredMsgDocumentType).oneOf(documentTypes),
  documentFile: Yup.mixed().required(requiredMsgDocumentFile).test(isValidMimeType([/image\/(jpeg|png)/, /application\/pdf/], 'Selecione um arquivo de imagem ou PDF.')),
  documentFileBack: Yup.mixed().when('documentType', {
    is: documentType => documentTypes.includes(`${documentType}_BACK`),
    then: Yup.mixed().required(requiredMsgDocumentFile).test(isValidMimeType([/image\/(jpeg|png)/, /application\/pdf/], 'Selecione um arquivo de imagem ou PDF.'))
  })
});
export const getUpdateStudentContactSchema = () => Yup.object({
  data: Yup.object({
    mainPhone: Yup.string().length(14, INVALID_FIELD_ERROR_MESSAGE_MAIN_PHONE).matches(VALID_PHONE_NUMBER_REGEX, INVALID_FIELD_ERROR_MESSAGE_MAIN_PHONE).required(REQUIRED_FIELD_ERROR_MESSAGE_PHONE),
    secondPhone: Yup.string().nullable().length(14, INVALID_FIELD_ERROR_MESSAGE_SECOND_PHONE).matches(VALID_PHONE_NUMBER_REGEX, INVALID_FIELD_ERROR_MESSAGE_SECOND_PHONE),
    homePhone: Yup.string().nullable().length(13, INVALID_FIELD_ERROR_MESSAGE_HOME_PHONE).matches(VALID_PHONE_NUMBER_REGEX, INVALID_FIELD_ERROR_MESSAGE_HOME_PHONE),
    mainEmail: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE_EMAIL).email(INVALID_EMAIL_ERROR_MESSAGE),
    secondEmail: Yup.string().nullable().email(INVALID_EMAIL_ERROR_MESSAGE)
  })
});