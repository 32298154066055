type ConvertDateTimeTZ = (date: string | Date) => Date;

/* returns date */
const convertDateTimeTZ: ConvertDateTimeTZ = (date) => {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date)?.toLocaleString(
      'en-US',
      {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hour12: false,
      }
    )
  );
};

export default convertDateTimeTZ;
