import React from 'react';

import { SvgProps } from '../types';

export const SvgComment: React.FC<SvgProps> = ({
  title,
  desc,
  fill = '#212529',
  ...props
}: SvgProps) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={`${title ? 'iconCommentTitle' : ''} ${
      desc ? 'iconCommentDesc' : ''
    }`}
    {...props}
  >
    {title && <title id="iconCommentTitle">{title}</title>}
    {desc && <desc id="iconCommentDesc">{desc}</desc>}
    <path
      d="M12.5001 2C11.1869 2 9.88653 2.25866 8.67328 2.7612C7.46002 3.26375 6.35763 4.00035 5.42904 4.92893C3.55368 6.8043 2.50011 9.34784 2.50011 12C2.49137 14.3091 3.2909 16.5485 4.76011 18.33L2.76011 20.33C2.62135 20.4706 2.52736 20.6492 2.48998 20.8432C2.45261 21.0372 2.47353 21.2379 2.55011 21.42C2.63317 21.5999 2.76781 21.7511 2.93696 21.8544C3.1061 21.9577 3.30211 22.0083 3.50011 22H12.5001C15.1523 22 17.6958 20.9464 19.5712 19.0711C21.4465 17.1957 22.5001 14.6522 22.5001 12C22.5001 9.34784 21.4465 6.8043 19.5712 4.92893C17.6958 3.05357 15.1523 2 12.5001 2ZM12.5001 20H5.91011L6.84011 19.07C7.02636 18.8826 7.1309 18.6292 7.1309 18.365C7.1309 18.1008 7.02636 17.8474 6.84011 17.66C5.5307 16.352 4.71528 14.6305 4.5328 12.7888C4.35032 10.947 4.81205 9.09901 5.83934 7.55952C6.86662 6.02004 8.3959 4.88436 10.1666 4.34597C11.9374 3.80759 13.84 3.8998 15.5503 4.60691C17.2607 5.31402 18.673 6.59227 19.5465 8.22389C20.4201 9.85551 20.701 11.7395 20.3412 13.555C19.9815 15.3705 19.0034 17.005 17.5736 18.1802C16.1439 19.3554 14.3509 19.9985 12.5001 20Z"
      fill={fill}
    />
  </svg>
);
