import { useQuery } from '@ampli/services';
import {
  FIND_INAUGURAL_SUBJECT,
  FIND_INAUGURAL_SUBJECT_LEARNING_UNITS,
  GET_INAUGURAL_SUBJECT_SECTION,
} from './queries';
import { filter } from '@ampli/utils';
import {
  FindInauguralSubjectHook,
  FindInauguralSubjectLearningUnitsHook,
  GetInauguralSubjectSectionHook,
  SectionType,
} from './types';

export const useFindInauguralSubjectLearningUnits: FindInauguralSubjectLearningUnitsHook =
  (courseEnrollmentId, options = {}) => {
    const { data, ...rest } = useQuery(FIND_INAUGURAL_SUBJECT_LEARNING_UNITS, {
      skip: !courseEnrollmentId,
      ...options,
      variables: {
        courseEnrollmentId,
      },
    });

    return {
      data: data?.data,
      ...rest,
    };
  };

export const useFindInauguralSubject: FindInauguralSubjectHook = (
  courseEnrollmentId,
  options = {}
) => {
  const { data, ...rest } = useQuery(FIND_INAUGURAL_SUBJECT, {
    skip: !courseEnrollmentId,
    ...options,
    variables: {
      courseEnrollmentId,
    },
  });

  return {
    data: data?.data,
    ...rest,
  };
};

export const useGetInauguralSubjectSection: GetInauguralSubjectSectionHook = (
  courseEnrollmentId,
  sectionPathUrl,
  options = {}
) => {
  const { data, ...rest } = useQuery(GET_INAUGURAL_SUBJECT_SECTION, {
    skip: !courseEnrollmentId,
    ...options,
    variables: {
      courseEnrollmentId,
    },
  });

  const inauguralSubject = data?.data;
  const subjectLearningUnits = inauguralSubject?.subjectLearningUnits || [];
  const subjectLearningUnit = filter(subjectLearningUnits, {
    learningUnit: {
      currentLearningUnitVersion: {
        sections: [{ pathUrl: sectionPathUrl }],
      },
    },
  })[0];

  const section =
    subjectLearningUnit?.learningUnit.currentLearningUnitVersion.sections.find(
      (section: SectionType) => section.pathUrl === sectionPathUrl
    );

  return {
    data: section,
    ...rest,
  };
};
