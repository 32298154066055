import { gql } from 'graphql.macro';

export const CHECK_CNPJ = gql`
  query CheckCnpj($cnpj: String!) {
    data: checkCnpj(cnpj: $cnpj) {
      cnpj
      companyName
      tradingName
      zipCode
      city
      state
      address
      neighbourhood
      phone
    }
  }
`;

export const SUBJECT_ENROLLMENT_FRAGMENT = gql`
  fragment subjectEnrollmentFragment on SubjectEnrollment {
    id
    status
    finalGrade
    subject {
      id
      name
    }
    courseSubject {
      subType
    }
    mandatoryInternship {
      id
      status
      assignmentStatus
      consolidatedStatus
      rejectionReason
    }
    assignments {
      id
      detail {
        id
        assignmentFiles {
          feedback
        }
        config {
          id
          name
          typeConfig {
            id
            modelType
            modelSubType
            url
            presignedUrl
          }
        }
      }
    }
  }
`;

export const GET_INTERNSHIP_COURSE_ENROLLMENT = gql`
  query GetCourseEnrollmentInternshipPage($courseEnrollmentId: ID!) {
    data: getCourseEnrollment(courseEnrollmentId: $courseEnrollmentId) {
      id
      course {
        id
        code
        name
      }
      subjectEnrollments: subjects(full: true) {
        ...subjectEnrollmentFragment
      }
    }
  }
  ${SUBJECT_ENROLLMENT_FRAGMENT}
`;

export const FIND_INTERNSHIP_PRESENTATION_SUBJECT_ENROLLMENT = gql`
  query GetSubjectEnrollmentInternshipPresentationPage(
    $subjectEnrollmentId: ID!
  ) {
    data: getSubjectEnrollment(subjectEnrollmentId: $subjectEnrollmentId) {
      subject {
        id
        name
        goals {
          id
          name
          description
          order
        }
        introduction
      }
    }
  }
`;
