export const ELOGIO = {
  label: 'Descreva com detalhes o seu elogio.',
  placeholder: 'Escreva o seu elogio aqui',
};

export const DUVIDA = {
  label: 'Descreva com detalhes a sua dúvida.',
  placeholder: 'Escreva a sua dúvida aqui',
};

export const RECLAMACAO = {
  label: 'Descreva com detalhes a sua crítica.',
  placeholder: 'Escreva a sua crítica aqui',
};
