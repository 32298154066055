import styled from '@emotion/styled';
import { convertPXToREM } from '../../utils';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  background: ${_ref => {
  let {
    backgroundColor
  } = _ref;
  return backgroundColor;
}};

  border-radius: 8px;
  border: 2px solid ${_ref2 => {
  let {
    borderColor
  } = _ref2;
  return borderColor;
}};
  border-style: ${_ref3 => {
  let {
    borderType
  } = _ref3;
  return borderType;
}};
  margin: ${_ref4 => {
  let {
    margin
  } = _ref4;
  return margin;
}};
  box-shadow: ${_ref5 => {
  let {
    boxShadow
  } = _ref5;
  boxShadow ? `0px 2px 7px rgb(0 0 0 / 14%);` : `null`;
}};

  ${_ref6 => {
  let {
    onClick,
    hoverColor
  } = _ref6;
  return onClick ? `&:focus {
    border: 4px solid #004ecc;
    background-color: ${hoverColor};
  }

  cursor: pointer;

  &:hover {
    background-color: ${hoverColor};
    border-color: #698aae;
  }` : `
    cursor: default;
  `;
}}
`;
export const ContainerTitle = styled.div`
  p {
    font-size: ${convertPXToREM(16)};
    color: ${_ref7 => {
  let {
    titleColor
  } = _ref7;
  return titleColor;
}};
    font-weight: 700;
    position: relative;
    margin-bottom: 8px;
  }
`;
export const ContainerDescription = styled.div`
  p {
    font-size: ${convertPXToREM(16)};
    color: ${_ref8 => {
  let {
    descColor
  } = _ref8;
  return descColor;
}};
    font-style: normal;
    font-weight: 400;
    margin: 0;
    position: relative;
  }
`;
export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ContainerIcon = styled.div`
  margin-right: 26px;
`;
export const ContainerIconEnd = styled.div`
  margin-left: 26px;
`;
export const ContainerRadioButton = styled.div`
  margin-right: 26px;
`;
export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;