import React from 'react';
import {
  SubjectGradeCard,
  SubjectPresentationCard,
  SubjectTitle,
} from '../../../subject';
import { object, oneOf } from 'prop-types';
import { EntitiesLabels, INTERNSHIP_STATUS } from '../../../../constants';
import { useGetState } from '../../../../state';
import { DateTime } from '@ampli/utils';
import * as Styled from './mandatory-internship-subject-header.styles';
import MandatoryInternshipSubjectHeaderSituationCard from './situation-card/mandatory-internship-subject-header-situation-card';

const MandatoryInternshipSubjectHeader = React.forwardRef(
  ({ subjectEnrollment, status, children }, ref) => {
    const [
      { selectedCourseEnrollmentType: courseType, selectedCourseVersionId },
    ] = useGetState();
    const entitiesLabelsDictionary = EntitiesLabels(courseType);

    return subjectEnrollment ? (
      <>
        <Styled.TitleWrapper ref={ref}>
          <SubjectTitle
            subjectName={subjectEnrollment.subject?.name}
            subTitle={
              <>
                {entitiesLabelsDictionary.subjectFinalDate}:{' '}
                <strong>
                  {subjectEnrollment.endDate?.toLocaleString(
                    DateTime.DATE_FULL
                  )}
                </strong>
              </>
            }
          />
          {children}
        </Styled.TitleWrapper>

        <Styled.Container>
          <Styled.FlexCard>
            <MandatoryInternshipSubjectHeaderSituationCard status={status} />
            <SubjectPresentationCard
              entitiesLabelsDictionary={entitiesLabelsDictionary}
            />
          </Styled.FlexCard>

          <SubjectGradeCard
            courseType={courseType}
            courseVersionId={selectedCourseVersionId}
            subjectEnrollmentId={subjectEnrollment.id}
            finalGrade={subjectEnrollment.finalGrade}
          />
        </Styled.Container>
      </>
    ) : null;
  }
);

MandatoryInternshipSubjectHeader.displayName =
  'MandatoryInternshipSubjectHeader';

MandatoryInternshipSubjectHeader.propTypes = {
  subjectEnrollment: object.isRequired,
  children: Node,
  status: oneOf(Object.keys(INTERNSHIP_STATUS)).isRequired,
};

export default MandatoryInternshipSubjectHeader;
