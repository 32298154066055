import { CircleProgress } from '@ampli/ui';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { convertPXToREM } from '@sofia/ui';

export const ProgressContainer = styled.div`
  z-index: 0;
  display: flex;
  align-items: center;

  &:not(.closed) {
    margin-top: 16px;
  }
  &.closed {
    margin-top: 32px;
  }
`;

const loadCircle = () => keyframes`
  from {
    stroke-dashoffset: -160;
  }
`;

export const ProgressCircle = styled(CircleProgress)`
  circle:not([stroke-linecap='round']) {
    stroke: #ebf0f5;
  }

  circle[stroke-linecap='round'] {
    animation: ${loadCircle()} 0.3s ease-out forwards;
  }
`;

export const PercentageText = styled.span`
  font-size: ${convertPXToREM(14)};
  line-height: 140%;
  color: ${({ color }) => color};
  &:not(.closed) {
    margin-left: 16px;
  }
  &.closed {
    font-weight: 700;
  }
`;
