import React, { useEffect } from 'react';
import { isEmpty, string, object } from '@ampli/utils';
import {
  css,
  px2grid,
  Paragraph,
  Text,
  Select,
  Field,
  FileInput,
  Icon,
  Flex,
  Thumbnail,
} from '@ampli/ui';

import {
  PersonDocumentFrontImage,
  PersonDocumentBackImage,
  DriverLicenseImage,
} from '../image';

const OnboardingPersonDocument = ({ formik, ...props }) => {
  const documentType = formik?.values?.documentType;
  const isRG = documentType === 'RG';
  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    if (documentType) {
      setFieldValue('documentFile', null);
      setFieldValue('documentFileBack', null);
    }
  }, [setFieldValue, documentType]);

  return (
    <div {...props}>
      <Paragraph marginSize="large">
        <Text>
          Escolha um local iluminado para tirar as fotos de seu RG ou CNH.
        </Text>
      </Paragraph>
      <form>
        <Paragraph as="div" marginSize={isRG ? 'medium' : 'xx-small'}>
          <Field
            as={Select}
            name="documentType"
            placeholder="Selecione o documento"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.documentType}
            error={formik.touched.documentType && formik.errors.documentType}
          >
            <option value="RG">RG</option>
            <option value="DRIVER_LICENSE">CNH</option>
          </Field>
        </Paragraph>
        {isEmpty(documentType) ? null : (
          <>
            {!isRG && (
              <Flex>
                <Paragraph marginSize="medium">
                  <Text
                    color="brand-base"
                    className={css`
                      margin-right: ${px2grid(8)};
                    `}
                  >
                    <Icon.AlertCircleBold />
                  </Text>
                  <Text size="x-small">
                    Lembre-se de retirar da capa de plástico e abrir o documento
                  </Text>
                </Paragraph>
              </Flex>
            )}
            <Paragraph as="div" marginSize="xx-small">
              <Field
                as={FileInput}
                name="documentFile"
                onChange={(e) => {
                  const file = e.target.files[0];
                  formik.setFieldValue('documentFile', file);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.documentFile}
                error={
                  formik.touched.documentFile && formik.errors.documentFile
                }
                accept="image/jpeg,image/png,application/pdf"
              >
                {formik.values.documentFile ? (
                  <Thumbnail
                    file={formik.values.documentFile}
                    className={css`
                      margin-right: ${px2grid(16)};
                    `}
                  />
                ) : isRG ? (
                  <PersonDocumentFrontImage
                    className={css`
                      margin-right: ${px2grid(16)};
                    `}
                  />
                ) : (
                  <DriverLicenseImage
                    className={css`
                      margin-right: ${px2grid(16)};
                    `}
                  />
                )}
                <Text size="small">
                  {isRG ? 'Frente do documento' : 'Foto de sua CNH'}
                </Text>
              </Field>
            </Paragraph>
          </>
        )}
        {isRG && !isEmpty(documentType) && (
          <>
            <input type="hidden" name="documentTypeBack" value="RG_BACK" />
            <Paragraph as="div" marginSize="large">
              <Field
                as={FileInput}
                name="documentFileBack"
                onChange={(e) => {
                  const file = e.target.files[0];
                  formik.setFieldValue('documentFileBack', file);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.documentFileBack}
                error={
                  formik.touched.documentFileBack &&
                  formik.errors.documentFileBack
                }
                accept="image/jpeg,image/png,application/pdf"
              >
                {formik.values.documentFileBack ? (
                  <Thumbnail
                    file={formik.values.documentFileBack}
                    className={css`
                      margin-right: ${px2grid(16)};
                    `}
                  />
                ) : (
                  <PersonDocumentBackImage
                    className={css`
                      margin-right: ${px2grid(16)};
                    `}
                  />
                )}
                <Text size="small">Verso do documento</Text>
              </Field>
            </Paragraph>
          </>
        )}
      </form>
    </div>
  );
};

OnboardingPersonDocument.propTypes = {
  className: string,
  formik: object.isRequired,
};

export default OnboardingPersonDocument;
