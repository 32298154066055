import { mediaQuery } from '@ampli/ui';
import styled from '@emotion/styled';

export const HalfItemRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  > div {
    width: 100%;
  }
  ${mediaQuery('desktop')} {
    gap: 24px;
    > div:not([id|='electiveWrapper']) {
      width: calc(50% - 12px);
    }
  }
`;
