import { gql } from '@apollo/client';

export const GET_INTERNSHIP = gql`
  query GetSubjectEnrollment($subjectEnrollmentId: ID!) {
    data: getSubjectEnrollment(subjectEnrollmentId: $subjectEnrollmentId) {
      assignmentStartEndInterval @client
      examStartEndInterval @client
      startEndInterval @client
      endDate
      finalGrade
      id
      subject {
        id
        name
        introduction
        methodologicalProposal
        bibliography
        goals {
          id
          name
          description
          order
        }
        image {
          id
          baseUrl
          formats
          descriptionText
        }
        imageUrls @client
        pathUrl @client
        simulators {
          code
        }
      }
      assignments {
        id
        status
        detail {
          id
          startDate
          dueDate
          highestGradeAttemptId
          assignmentFiles {
            feedback
          }
          specialization {
            id
            assignmentId
            ... on SpecializationQuestionList {
              id
              assignmentId
              currentFlow
              substituteAssignmentId
            }
            ... on SpecializationSchoolWork {
              id
              assignmentId
              lessonPlanId
            }
          }
          display {
            name
            periodTitle
            periodDates
          }
          config {
            id
            name
            type
            numberOfAttempts
            typeConfig {
              id
              modelType
              modelSubType
              url
              presignedUrl
            }
          }
          attempts {
            id
            grade
            referenceId
          }
        }
      }
      mandatoryInternship {
        id
        status
        assignmentStatus
        consolidatedStatus
        rejectionReason
      }
    }
  }
`;
