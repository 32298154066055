import React from 'react';
import { useTheme } from '@emotion/react';
import { SvgClockThicker } from '@sofia/ui';

import { IModalIcon, IModalsProps } from './types';
import { LoadingSpinner } from '../../../../../../components';
import Icon from './icon-wrapper';
import BaseModal from './base-modal.presentation';
import * as Styles from './modals.styles';

const WaitingPublication: React.FC<IModalsProps> = ({
  modal,
  publishType = 'publication',
}: IModalsProps) => {
  const theme = useTheme();
  const title = {
    comment: 'Aguarde, estamos validando seu comentário',
    publication: 'Aguarde, estamos validando sua publicação',
  }[publishType];

  const CustomComponent = () => (
    <Styles.SpinnerWrapper>
      <LoadingSpinner
        width={96}
        height={96}
        color={theme.colors.icon.primary}
      />
    </Styles.SpinnerWrapper>
  );

  const ModalIcon: React.FC<IModalIcon> = () => (
    <Icon background={theme.colors.icon.background}>
      <SvgClockThicker />
    </Icon>
  );

  return (
    <BaseModal
      Icon={ModalIcon}
      title={title}
      CustomComponent={CustomComponent}
      // TODO: criar função que espera o loading finalizar para abrir
      // a próxima modal quando o backend estiver pronto
      handleModal={modal}
    />
  );
};

export default WaitingPublication;
