import React from 'react';

import {
  useGetCourseEnrollmentRolesAndStatus,
  useGetCompanyName,
  useGetSubjectName,
  useGetTutor,
} from '../../services/hooks';

import {
  SubjectEnrollment,
  CourseEnrollment,
  Subject,
  Company,
  Tutor,
  Me,
} from '../types';

import { courseEnrollmentInactiveStatus } from '../../../../../../constants';
import { GlobalParams } from '../../../../../shared';
import { useGetState } from '../../../../../../state';

import { useParams } from 'react-router-dom';
import { useMeContext } from '../../../../../../modules/shared/contexts/me-context';

const scrollType =
  navigator?.userAgent?.indexOf('Firefox') !== -1 ? 'auto' : 'overlay';

export type ProviderProps = {
  children: React.ReactNode;
};

export type SharedContextProps = {
  subjectEnrollment: SubjectEnrollment;
  courseData: CourseEnrollment;
  isCourseEnrollmentBlocked: boolean;
  scrollType: string;
  isFetching: boolean;
  company: Company;
  isAmpli: boolean;
  subject?: Subject;
  tutor?: Tutor;
  me: Me;
};

export const FrameContext = React.createContext<SharedContextProps>({
  subjectEnrollment: {},
  isCourseEnrollmentBlocked: false,
  isFetching: false,
  courseData: {},
  scrollType: '',
  isAmpli: true,
  subject: {},
  company: {},
  tutor: {},
  me: {},
});

export const FrameProvider: React.FC<ProviderProps> = ({
  children,
}: ProviderProps) => {
  const [{ selectedCourseEnrollmentId }] = useGetState();
  const { subjectEnrollmentId } = useParams<GlobalParams>();

  const { states: statesMe } = useMeContext();
  const { me, loading: meLoading } = statesMe.getMe;

  const { data: company, loading: companyLoading } = useGetCompanyName<Company>(
    selectedCourseEnrollmentId
  );

  const { data: courseEnrollment, loading: courseEnrollmentLoading } =
    useGetCourseEnrollmentRolesAndStatus<CourseEnrollment>(
      selectedCourseEnrollmentId
    );

  const { data: subjectEnrollment, loading: subjectEnrollmentLoading } =
    useGetSubjectName<SubjectEnrollment>(subjectEnrollmentId);

  const { data: tutors, loading: tutorLoading } = useGetTutor<Tutor[]>(
    selectedCourseEnrollmentId
  );

  const isCourseEnrollmentBlocked = React.useMemo(
    () => courseEnrollmentInactiveStatus.includes(courseEnrollment?.status),
    [courseEnrollment?.status]
  );

  const subject = React.useMemo(
    () => subjectEnrollment?.subject,
    [subjectEnrollment?.subject]
  );

  const tutor = React.useMemo(
    () =>
      tutors?.length
        ? tutors?.find(
            (tutor) => tutor?.subjectName === subjectEnrollment?.subject?.name
          )
        : undefined,
    [subjectEnrollment?.subject?.name, tutors]
  );

  const isAmpli = React.useMemo(
    () => courseEnrollment?.roles?.includes('CHAT_ENABLED'),
    [courseEnrollment?.roles]
  );

  const isFetching = React.useMemo(
    () =>
      subjectEnrollmentLoading ||
      courseEnrollmentLoading ||
      companyLoading ||
      tutorLoading ||
      meLoading,
    [
      subjectEnrollmentLoading,
      courseEnrollmentLoading,
      companyLoading,
      tutorLoading,
      meLoading,
    ]
  );

  return (
    <FrameContext.Provider
      value={{
        subjectEnrollment,
        isCourseEnrollmentBlocked,
        scrollType,
        isFetching,
        company,
        isAmpli,
        courseData: courseEnrollment,
        subject,
        tutor,
        me,
      }}
    >
      {children}
    </FrameContext.Provider>
  );
};

export const useFrame = (): SharedContextProps =>
  React.useContext(FrameContext);
