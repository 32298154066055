import { TalkTutorFrameProps } from '../../modules/messaging/components/talk-tutor-frame/talk-tutor-frame';
/* TODO: REVER PAYLOAD */
import { ISectionCopilotPayload } from '../../modules/section/pages/learning-object/types';

export type UnsubscribeType = { unsubscribe: () => void };

export type SubscriptonType = (callback: (status: string) => void) => void;

export type DirectLine = {
  connectionStatus$: {
    subscribe: (value: unknown) => SubscriptonType;
  };
  activity$: {
    subscribe: (value: unknown) => SubscriptonType;
  };
  postActivity: (activity: {
    type: string;
    name: string;
    localTimezone: string;
    locale: string;
    value?: {
      [key: string]: string;
    };
  }) => {
    subscribe: (options?: {
      complete: () => Promise<void> | void;
    }) => Promise<void>;
  };
};

declare global {
  interface Window {
    WebChat: {
      createDirectLine: (options: {
        token?: string;
        domain: URL;
      }) => DirectLine;

      createStore: (
        _: unknown,
        params: (
          store: WebChatStore
        ) => (
          next: (action: WebChatAction) => void
        ) => (action: WebChatAction) => Promise<void>
      ) => WebChatStore;

      renderWebChat: (
        options: {
          directLine: unknown;
          locale: string;
          store?: WebChatStore;
          styleOptions: unknown;
        },
        element: HTMLElement | null
      ) => void;
    };
  }
}

export enum ActivityNames {
  OPEN_SERVICE = 'openService',
  ATTACH_FILE = 'attachFile',
  STAR_TUTOR_CONVERSATION = 'startConversationTutor',
  ENABLE_CHAT = 'enableChat',
}

export enum WebChatActionTypes {
  SET_SEND_BOX_ATTACHMENTS = 'WEB_CHAT/SET_SEND_BOX_ATTACHMENTS',
  INCOMING_ACTIVITY = 'DIRECT_LINE/INCOMING_ACTIVITY',
}

export interface WebChatAction {
  type: string;
  name: string;
  value?: string | { [key: string]: string };
  payload: {
    attachments: { blob: Blob & { name: string } }[];
    activity: { attachments: Blob[] };
  };
}

export interface WebChatDispatchOptions {
  type: string;
  payload: {
    name: string;
    value: { [key: string]: string };
  };
}

export interface WebChatStore {
  dispatch: (options: WebChatDispatchOptions) => void;
}

export interface ICopilotQuizPayload {
  studentMoment: string;
  academicRegistration: string;
  subjectName: string;
  studentName: string;
  courseName: string;
  exerciseQuestion: string | undefined;
  questionDescription: string | undefined;
  initialInteraction: boolean;
  classID: string;
  dictSugestions: string;
  modalityCourse: string;
  studentBirthDate: string;
}

export type UseWebChatProps = {
  talkTutorInfos?: TalkTutorFrameProps['infos'];
  directLineURL?: string;
  token?: string;
  quizPagePayload?: ISectionCopilotPayload | ICopilotQuizPayload;
  isQuizPageRendered?: boolean;
};

export type UseWebChatReturn = {
  isUploadButtonAllowed: boolean;
  isChatInteractionAllowed?: boolean;
  shouldChatBeBlocked?: boolean;
  loading: boolean;
  getFileBlob: (fileBlob: Blob & { name: string }) => Promise<void>;
  isUploading: boolean;
};

export type UseChatCopilotReturn = {
  isCourseEnrollmentBlocked: boolean;
  isCopilotOpen: boolean;
  setIsCopilotOpen: React.Dispatch<React.SetStateAction<boolean>>;
  token?: string;
  directLineURL?: string;
  containerRef: React.RefObject<HTMLDivElement>;
  eduButtonRef: React.RefObject<HTMLButtonElement>;
  isHoverOrFocus: boolean;
};

export interface ChatCopilotProps {
  answerContainerRef?: React.RefObject<HTMLDivElement>;
  questionContainerRef?: React.RefObject<HTMLDivElement>;
  isQuizCopilotOpen?: boolean;
  talkTutorInfos?: TalkTutorFrameProps['infos'];
  isNotFixed?: boolean;
  quizPagePayload?: ISectionCopilotPayload | ICopilotQuizPayload;
  isQuizPageRendered?: boolean;
  isCopilotOnQuiz?: boolean;
  isChatCopilotOnpenOnRender?: boolean;
  componentId?: string;
}

export interface WebChatProps {
  answerContainerRef?: React.RefObject<HTMLDivElement>;
  questionContainerRef?: React.RefObject<HTMLDivElement>;
  setIsCopilotOpen: React.Dispatch<React.SetStateAction<boolean>>;
  containerRef: React.RefObject<HTMLDivElement>;
  directLineURL?: string;
  token?: string;
  talkTutorInfos?: TalkTutorFrameProps['infos'];
  quizPagePayload?: ISectionCopilotPayload | ICopilotQuizPayload;
  isQuizPageRendered?: boolean;
}
