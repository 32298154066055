import React from 'react';
import { useTheme } from '@emotion/react';
import { BottomSheet, Modal } from '@sofia/ui';
import { PopUpProps } from './types';

const PopUp = ({
  popUpState,
  isDesktop,
  header,
  headerText,
  showFooter,
  headerMargin,
  ariaLabel,
  children,
  footerText,
  footer,
  ...rest
}: PopUpProps): React.ReactElement => {
  const theme = useTheme();
  const props = {
    header: header || { headerText, margin: headerMargin },
    footer: showFooter
      ? footer || {
          buttonText: footerText,
          background: theme.colors.button.primary,
          buttonClick: popUpState?.hide,
        }
      : null,
    'aria-label': ariaLabel,
    children: children,
    ...rest,
  };

  return isDesktop ? (
    <Modal handleModal={popUpState} data-testid="pop-up-modal" {...props} />
  ) : (
    <BottomSheet
      handleBottomSheet={popUpState}
      data-testid="pop-up-bottom-sheet"
      {...props}
    />
  );
};

PopUp.defaultProps = {
  footerText: 'Entendi',
  showFooter: true,
  header: null,
};

export default PopUp;
