import styled from '@emotion/styled';

export const Flex = styled.div`
  position: sticky;
  top: 0;
  background: #ffffff;
  padding-bottom: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
