import styled from '@emotion/styled';

import { mediaQuery } from '@sofia/ui';

interface CommonProps {
  className?: string;
  isFullWidth?: boolean;
}

interface ListWrapperProps extends CommonProps {
  scroll?: string;
  position?: string;
  padding?: string;
  height?: string;
}

const openMenuSize = '360px';

export const FrameWrapper = styled.div`
  ${mediaQuery('mobile')}, ${mediaQuery('tablet')} {
    display: flex;
    flex-direction: column;
  }
`;

export const FrameMenu = styled.div<CommonProps>`
  transition: padding-left 200ms ease;

  ${mediaQuery('desktop')} {
    padding-left: ${({ isFullWidth }) =>
      isFullWidth ? 'calc(100% - 72px)' : openMenuSize};

    &.closed-frame {
      padding-left: 0px;
    }
  }
`;

export const SideBarWrapper = styled.div<CommonProps>`
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 1002;

  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #dee2e6;
  background: #fff;
  transition: max-width 200ms ease;

  &.closed-frame {
    max-width: 0px;
  }

  ${mediaQuery('desktop')} {
    z-index: 1002;
    max-width: ${({ isFullWidth }) =>
      isFullWidth ? 'calc(100% - 72px)' : openMenuSize};

    &.closed-frame {
      display: flex;
      max-width: 0px;
    }
  }
`;

export const ListWrapperCore = styled.div<ListWrapperProps>`
  padding: ${({ padding = '24px' }) => padding};
  height: ${({ height = '100%' }) => height};
  overflow: ${({ scroll }) => scroll};

  position: ${({ position }) => position};

  &:hover::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 2px;
  }
`;

export const ListWrapper = styled.form<ListWrapperProps>`
  padding: ${({ padding = '24px' }) => padding};
  height: ${({ height = '100%' }) => height};
  overflow: ${({ scroll }) => scroll};

  position: ${({ position }) => position};

  &:hover::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 2px;
  }
`;

export const BackgroundLayer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 24px;

  ${mediaQuery('desktop-max')} {
    flex-direction: column-reverse;
  }
`;
