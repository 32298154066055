import { useState } from 'react';
import { useGetTrack, useAppendOnTrack, GET_TRACK } from '@ampli/services';
import {
  MANDATORY_INTERNSHIP_TUTORIAL_ONBOARDING_COMPLETED_DESCRIPTION,
  MANDATORY_INTERNSHIP_TUTORIAL_ONBOARDING_COMPLETED_KEY,
} from '../../../constants';
import { useMeContext } from '../../../modules/shared/contexts/me-context';

const useGetTutorialOnboardingTrack = () => {
  const [showTutorialMessage, setTutorialMessageState] = useState(true);
  const { states: statesMe } = useMeContext();
  const { me, loading: meLoading, error: meError } = statesMe.getMe;

  const [
    appendOnTrack,
    { loading: appendLoading, error: appendError, called },
  ] = useAppendOnTrack({
    refetchQueries: [{ query: GET_TRACK, variables: { referenceId: me?.id } }],
    onCompleted: () => {
      if (called) {
        setTutorialMessageState(false);
      }
    },
  });

  const {
    data: track,
    loading: loadingTrack,
    called: isTrackCalled,
  } = useGetTrack(me?.id, {
    onCompleted: () => {
      if (isTrackCalled && track) {
        const hasTutorialOnboardingKey = track.inputs?.some(
          (input) =>
            input.key ===
              MANDATORY_INTERNSHIP_TUTORIAL_ONBOARDING_COMPLETED_KEY &&
            input.value === 'TRUE'
        );
        setTutorialMessageState(!hasTutorialOnboardingKey);
      }
    },
  });

  const appendOnboardingOnTrack = () => {
    try {
      appendOnTrack({
        variables: {
          request: {
            referenceId: me?.id,
            inputs: [
              {
                key: MANDATORY_INTERNSHIP_TUTORIAL_ONBOARDING_COMPLETED_KEY,
                description:
                  MANDATORY_INTERNSHIP_TUTORIAL_ONBOARDING_COMPLETED_DESCRIPTION,
                value: 'TRUE',
              },
            ],
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const loading = meLoading || loadingTrack || appendLoading;
  const error = Boolean(meError || appendError);

  return {
    loading,
    error,
    showTutorialMessage,
    onConcludeTutorialOnboarding: appendOnboardingOnTrack,
  };
};

export default useGetTutorialOnboardingTrack;
