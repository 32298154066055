import React from 'react';

import { shape, string, number, func, bool, arrayOf, node } from 'prop-types';
import { map, find, filter, size } from '@ampli/utils';

import {
  css,
  px2grid,
  Text,
  Flex,
  Paragraph,
  Card,
  px2rem,
  CardContent,
  Separator,
} from '@ampli/ui';

import { SectionNavigation, Question } from '../../modules/shared';
import { ExamLayoutDesktop } from '../layout';

import QuestionCorrection from './question-correction';
import QuestionBullets from './question-bullets';
import Legend from './question-bullet-legend';
import { useLocation } from 'react-router-dom';

const ExamCorrectionDesktop = ({
  exam,
  attempt,
  assignment,
  fontSize = 'medium',
  onHelpClick,
  onFontSizeClick,
  onFinishExamCorrectionClick,
  onCloseExamCorrectionClick,
  stepsControl,
  talkTutorFrame,
}) => {
  const { step, toStep, isLastStep, isFirstStep, previousStep, nextStep } =
    stepsControl;

  const question = exam.questions[step];

  const answers = exam.answerSheet.answers;

  const numberOfQuestions = size(exam.questions);

  const numberOfCorrectAnswers = size(filter(answers, { isCorrect: true }));

  const answer = find(answers, { questionId: question.id });

  const choice = find(question.choices, { id: answer.choiceId });

  const bulletDarkColors = map(exam.questions, (q) => {
    return find(answers, { questionId: q.id }).isCorrect
      ? 'semantic-success'
      : 'semantic-error';
  });

  const bulletLightColors = bulletDarkColors.map((b) => b + '-lighter');

  const { state } = useLocation();

  return (
    <ExamLayoutDesktop
      title={
        <Text as="strong" color="text-dark">
          Correção da {state?.title}
        </Text>
      }
      onFontSizeClick={onFontSizeClick}
      onHelpClick={onHelpClick}
      onCloseClick={onCloseExamCorrectionClick}
      talkTutorFrame={talkTutorFrame}
    >
      <div style={{ marginRight: px2grid(48) }}>
        <Question
          question={question}
          step={step}
          questionAnswered={true}
          answerStatus={answer.isCorrect ? 'Correta' : 'Errada'}
          answerStatusColor={
            answer.isCorrect ? 'semantic-success' : 'semantic-error'
          }
          fontSize={fontSize}
          questionChoices={question.choices}
        />
        <QuestionCorrection
          fontSize={fontSize}
          choice={choice}
          answer={answer}
        />
      </div>
      <div>
        <Card
          padding="none"
          overflow="hidden"
          sticky={88}
          className={css`
            width: ${px2grid(288)};
          `}
        >
          <CardContent>
            <Flex justifyContent="space-between">
              <Paragraph marginSize="xx-small">
                <Text size="small">Prova final</Text>
                <Text size="small" color="text-dark">
                  {exam.subject.name}
                </Text>
              </Paragraph>
            </Flex>
            <Flex justifyContent="space-between">
              <Paragraph marginSize="xx-small">
                <Text size="small">Acertos</Text>
                <Text size="small" color="text-dark">
                  {numberOfCorrectAnswers} de {numberOfQuestions}
                </Text>
              </Paragraph>
            </Flex>
            <Flex justifyContent="space-between">
              <Paragraph marginSize="xx-small">
                <Text size="small">Nota</Text>
                <Text size="small" color="text-dark">
                  {Math.ceil((attempt.grade * assignment.config.weight) / 100)}{' '}
                  pontos
                </Text>
              </Paragraph>
            </Flex>
          </CardContent>
          <Separator margin="none" />
          <CardContent>
            <Paragraph as="div" textAlign="center">
              <Legend dotActive color="semantic-success">
                Corretas
              </Legend>
              <Legend dotActive color="semantic-error">
                Erradas
              </Legend>
            </Paragraph>
            <QuestionBullets
              bullets={numberOfQuestions}
              active={step}
              complete={true}
              onBulletClick={toStep}
              darkColor={bulletDarkColors}
              lightColor={bulletLightColors}
            />
          </CardContent>
          <SectionNavigation
            className={css`
              margin: ${px2rem(-1)};
            `}
            buttonSize="small"
            isLastStep={isLastStep}
            onPreviousClick={isFirstStep ? null : () => previousStep()}
            onNextClick={() =>
              isLastStep ? onFinishExamCorrectionClick() : nextStep()
            }
            lastStepLabel="Concluir correção"
          />
        </Card>
      </div>
    </ExamLayoutDesktop>
  );
};

ExamCorrectionDesktop.propTypes = {
  exam: shape({
    id: string.isRequired,
    subject: shape({
      name: string.isRequired,
    }).isRequired,
    questions: arrayOf(
      shape({
        id: string.isRequired,
        baseText: string.isRequired,
        statement: string.isRequired,
        choices: arrayOf(
          shape({
            id: string.isRequired,
            description: string.isRequired,
            feedback: string.isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired
    ).isRequired,
    answerSheet: shape({
      answers: arrayOf(
        shape({
          questionId: string.isRequired,
          choiceId: string,
          isCorrect: bool.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
  attempt: shape({
    grade: number.isRequired,
  }).isRequired,
  assignment: shape({
    config: shape({
      weight: number.isRequired,
    }).isRequired,
  }).isRequired,
  stepsControl: shape({
    step: number.isRequired,
    toStep: func.isRequired,
    isLastStep: bool.isRequired,
    isFirstStep: bool.isRequired,
    previousStep: func.isRequired,
    nextStep: func.isRequired,
  }).isRequired,
  fontSize: string,
  onFontSizeClick: func.isRequired,
  onHelpClick: func.isRequired,
  onFinishExamCorrectionClick: func.isRequired,
  onCloseExamCorrectionClick: func.isRequired,
  talkTutorFrame: node,
};

export default ExamCorrectionDesktop;
