import React, { FC, ReactNode } from 'react';

interface IProviderComponentProps {
  children: ReactNode;
}

type TComposeProviderProps = FC<IProviderComponentProps>;

const composeProviders = (
  Providers: TComposeProviderProps[]
): TComposeProviderProps => {
  return Providers.reduce(
    (Prev, Curr) => {
      const ComposedProviders: TComposeProviderProps = ({
        children,
      }: IProviderComponentProps) => (
        <Prev>
          <Curr>{children}</Curr>
        </Prev>
      );
      ComposedProviders.displayName = 'ComposedProviders';
      return ComposedProviders;
    },
    ({ children }) => <>{children}</>
  );
};

export default composeProviders;
