import React from 'react';
import { SkeletonProps } from './types';
import { Wrapper } from './styles';

const Skeleton = ({
  width = '100%',
  height = '20px',
  borderRadius = '20px',
  margin = 'none',
  ...rest
}: SkeletonProps): React.ReactElement => {
  return (
    <Wrapper.Content
      data-testid="skeleton"
      width={width}
      height={height}
      borderRadius={borderRadius}
      margin={margin}
      {...rest}
    />
  );
};

export default Skeleton;
