import React from 'react';
import * as Styles from './after-end.styles';
import { Typography, SvgGirlThumbsUpIllustration } from '@sofia/ui';

const Learning: React.FC = () => {
  return (
    <Styles.OnboardingModal>
      <div className="onboarding">
        <SvgGirlThumbsUpIllustration
          className="onboarding__illustration"
          aria-hidden="true"
        />
        <Typography className="onboarding__title">
          Seu tour foi concluído!
        </Typography>
        <Typography margin="0 0 16px 0" className="onboarding__description">
          Parabéns! Agora você pode usufruir da plataforma e seguir com seus
          estudos.
        </Typography>
      </div>
    </Styles.OnboardingModal>
  );
};

export default Learning;
