import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
  Tag,
  useModal,
  HighlightFeature,
  SvgArrowRight,
  Typography,
  Card,
  SvgQuestionCircle,
} from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { oneOf, array, bool, func } from 'prop-types';
import {
  internshipStatusTheme,
  INTERNSHIP_ASSIGNMENT_STATUS,
  INTERNSHIP_STATUS,
} from '../../../../constants';
import {
  AssignmentRoutesPath as routePath,
  useBreakpoint,
} from '../../../../modules';
import { useGetState } from '../../../../state';
import * as Styled from './mandatory-internship-subject-content.styles';
import getTutorialSteps from './helper';
import MandatoryInternshipSubjectContentAccordions from './accordions/mandatory-internship-subject-content-accordions';
import MandatoryInternshipSubjectContentMessages from './messages/mandatory-internship-subject-content-messages';

const MandatoryInternshipSubjectContent = React.forwardRef(
  (
    {
      assignments,
      startTour,
      status,
      assignmentStatus,
      highlightFeature,
      onCloseHighlightFeature,
      showMessage,
    },
    ref
  ) => {
    const history = useHistory();
    const [selectedDocument, setSelectedDocument] = React.useState(null);
    const { subjectEnrollmentId } = useParams();
    const breakpoint = useBreakpoint();
    const isDesktop = breakpoint === 'desktop';
    const unableToSendmodal = useModal();
    const alreadySentModal = useModal();
    const theme = useTheme();
    const statusTheme = internshipStatusTheme(theme);
    const termRef = React.useRef();
    const pdfRef = React.useRef();
    const [termAccordion, setTermAccordion] = React.useState();
    const [tutorialStep, setTutorialStep] = React.useState('first');
    const [stepsArray, setStepsArray] = React.useState(null);

    const [
      {
        selectedCourseEnrollmentType: courseType,
        selectedCourseVersionId: courseVersionId,
      },
    ] = useGetState();

    const sendOtherDocuments = React.useMemo(
      () => assignmentStatus === 'DONE',
      [assignmentStatus]
    );

    const sendTerm = React.useMemo(
      () => status === 'PENDING_APPROVAL' || status === 'APPROVED',
      [status]
    );

    const onSendDocument = ({ documentType, documentName, assignmentId }) => {
      setSelectedDocument(documentName);
      if (documentType === 'MANDATORY_INTERNSHIP_TERM' && sendTerm) {
        alreadySentModal.show();
      } else if (
        documentType !== 'MANDATORY_INTERNSHIP_TERM' &&
        !sendOtherDocuments
      ) {
        unableToSendmodal.show();
      } else {
        history.push(
          generatePath(routePath.onboardingAssignmentDocument, {
            courseType,
            courseVersionId,
            subjectEnrollmentId,
            assignmentId,
          }),
          { activitiesPath: null }
        );
      }
    };

    const sortedAssignments = React.useCallback(() => {
      const internshipTermIndex = assignments.findIndex(
        (assignment) =>
          assignment.detail.config.typeConfig?.modelSubType ===
          'MANDATORY_INTERNSHIP_TERM'
      );

      if (internshipTermIndex === -1) {
        return assignments;
      } else {
        const assignmentsCopy = [...assignments];
        assignmentsCopy[internshipTermIndex] = assignmentsCopy.splice(
          0,
          1,
          assignmentsCopy[internshipTermIndex]
        )[0];

        return assignmentsCopy;
      }
    }, [assignments]);

    const tag = React.useMemo(
      () => (
        <Tag theme={statusTheme[status]} text={INTERNSHIP_STATUS[status]} />
      ),
      [status, statusTheme]
    );

    React.useEffect(() => {
      if (startTour && highlightFeature) {
        setTermAccordion(false);
      }
    }, [startTour, highlightFeature]);

    React.useEffect(() => {
      if (!stepsArray) {
        const term = sortedAssignments()?.[0]?.detail;
        setStepsArray(
          highlightFeature
            ? getTutorialSteps({
                refs: { termRef, pdfRef, tutorialRef: ref },
                elements: {
                  accordion: (
                    <MandatoryInternshipSubjectContentAccordions
                      config={term?.config}
                      tagComponent={tag}
                    />
                  ),
                  openedAccordion: (
                    <Styled.TutorialAccordionWrapper>
                      <MandatoryInternshipSubjectContentAccordions
                        config={term?.config}
                        tagComponent={tag}
                        open
                      />
                    </Styled.TutorialAccordionWrapper>
                  ),
                  releasedTag: (
                    <Styled.TagTutorialWrapper>
                      <Tag
                        theme={statusTheme['RELEASED']}
                        text={INTERNSHIP_STATUS['RELEASED']}
                      />
                    </Styled.TagTutorialWrapper>
                  ),
                  sendPdf: (
                    <Styled.SendWrapper>
                      <Styled.CardContainer>
                        <Card
                          hover={theme.colors.card.color.hover}
                          borderColor={theme.colors.card.border.primary}
                          tabIndex={0}
                        >
                          <Styled.FlexSpaceBetween>
                            <Typography
                              size={16}
                              color={theme.colors.text.black}
                            >
                              Enviar termo de estágio
                            </Typography>
                            <SvgArrowRight
                              fill={theme.colors.text.primary}
                              aria-hidden
                            />
                          </Styled.FlexSpaceBetween>
                        </Card>
                      </Styled.CardContainer>
                    </Styled.SendWrapper>
                  ),
                  approvedTag: (
                    <Styled.TagTutorialWrapper>
                      <Tag
                        theme={statusTheme['APPROVED']}
                        text={INTERNSHIP_STATUS['APPROVED']}
                      />
                    </Styled.TagTutorialWrapper>
                  ),
                  tutorialBtn: (
                    <Styled.IconWrapper>
                      <SvgQuestionCircle fill={theme.colors.text.primary} />
                    </Styled.IconWrapper>
                  ),
                },
                onInit: showMessage,
                onClose: onCloseHighlightFeature,
                setTermAccordion,
                setTutorialStep,
                isDesktop,
              })
            : null
        );
      }
    }, [
      onCloseHighlightFeature,
      sortedAssignments,
      highlightFeature,
      statusTheme,
      showMessage,
      stepsArray,
      isDesktop,
      theme,
      tag,
      ref,
    ]);

    return (
      <>
        {sortedAssignments().map(({ id, detail: { config } }, index) => {
          if (!config) return null;
          const pdfUrl = config.typeConfig?.presignedUrl;
          const documentType = config.typeConfig?.modelSubType;
          const sendDocument = () =>
            onSendDocument({
              documentType,
              assignmentId: id,
              documentName: config.name,
            });

          return (
            <Styled.Container
              key={index}
              {...(index === 0
                ? {
                    ref: termRef,
                  }
                : {})}
            >
              <MandatoryInternshipSubjectContentAccordions
                config={config}
                tagComponent={index === 0 && !termAccordion ? tag : null}
                pdfUrl={pdfUrl}
                sendDocument={sendDocument}
                open={termAccordion}
                ref={index === 0 && termAccordion ? pdfRef : null}
              />
            </Styled.Container>
          );
        })}

        {highlightFeature && tutorialStep && stepsArray ? (
          <HighlightFeature
            {...stepsArray[tutorialStep]}
            direction="top"
            handleClickOut={() => {
              setTutorialStep('first');
              onCloseHighlightFeature();
              setTermAccordion(null);
            }}
          />
        ) : null}

        <MandatoryInternshipSubjectContentMessages
          unableToSendmodal={unableToSendmodal}
          alreadySentModal={alreadySentModal}
          selectedDocument={selectedDocument}
        />
      </>
    );
  }
);

MandatoryInternshipSubjectContent.displayName =
  'MandatoryInternshipSubjectContent';

MandatoryInternshipSubjectContent.defaultProps = {
  assignments: [],
  startTour: false,
  highlightFeature: false,
};

MandatoryInternshipSubjectContent.propTypes = {
  assignments: array,
  status: oneOf(Object.keys(INTERNSHIP_STATUS)).isRequired,
  assignmentStatus: oneOf(INTERNSHIP_ASSIGNMENT_STATUS),
  highlightFeature: bool,
  startTour: bool,
  onCloseHighlightFeature: func,
  showMessage: func,
};

export default MandatoryInternshipSubjectContent;
