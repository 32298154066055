import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import { ButtonPrimary } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { RedoIcon } from '@ampli/icon';
import { FlexWrapper } from './login-styles';

const LoginFooter = ({
  loading,
  page,
  primaryActionLabel,
  disabled,
  ButtonFooterRedirect,
}) => {
  const handleKey = (e) => {
    const keyCode = e.keyCode;

    if (keyCode === 27) {
      e.target.blur();
    }
  };

  const [buttonValue, setButtonValue] = useState(primaryActionLabel);

  useEffect(() => {
    if (loading) {
      setButtonValue(<RedoIcon />);
    } else {
      setButtonValue(primaryActionLabel);
    }
  }, [loading, primaryActionLabel]);

  const theme = useTheme();

  return (
    <>
      <FlexWrapper content="space-between">
        {page !== 'validatePage' && (
          <ButtonPrimary
            id="btnForward"
            onClick={(e) => {
              if (ButtonFooterRedirect) {
                e.preventDefault();
                window.open(ButtonFooterRedirect);
              }
            }}
            data-testid="btnForward"
            color={theme.colors.text.white}
            background={theme.colors.button.primary}
            onKeyUp={handleKey}
            aria-label="Avançar para próxima etapa"
            width="100%"
            type="submit"
            loading={loading}
            disabled={disabled}
          >
            {buttonValue}
          </ButtonPrimary>
        )}
      </FlexWrapper>
    </>
  );
};

LoginFooter.propTypes = {
  loading: bool,
  primaryActionLabel: string,
  page: string,
  disabled: bool,
  ButtonFooterRedirect: string,
};

LoginFooter.defaultProps = {
  primaryActionLabel: 'Avançar',
};

export default LoginFooter;
