import React from 'react';

import CustomizationSlider from '../../../slider/customization-slider';

import { Typography, SvgLineSpacing } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { LINE_SPACING_SLIDER } from '../../../../constants';
import {
  FlexGap as StyledFlexGap,
  SliderWrapper as StyledSliderWrapper,
} from '../../customization-section.styles';
import { BetweenLinesProps, LineSpacingType } from '../../../../types';

const BetweenLines = ({
  reset,
  defaultValue,
  onChange,
}: BetweenLinesProps): React.ReactElement => {
  const theme = useTheme();
  const [value, setValue] = React.useState<LineSpacingType>(defaultValue);

  React.useEffect(() => {
    if (reset) {
      setValue(defaultValue);
    }
  }, [defaultValue, reset]);

  return (
    <StyledFlexGap margin="0 0 16px 0" data-testid="between-lines">
      <Typography size={16} color={theme.colors.text.black} weight={700}>
        Entre linhas
      </Typography>
      <StyledSliderWrapper>
        <SvgLineSpacing />
        <CustomizationSlider
          config={LINE_SPACING_SLIDER}
          ariaLabel="Espaçamento entre linhas"
          value={value}
          sufix="%"
          onChange={(value) => {
            setValue(value);
            onChange(value);
          }}
        />
      </StyledSliderWrapper>
    </StyledFlexGap>
  );
};

export default BetweenLines;
