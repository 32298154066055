import React, { forwardRef } from 'react';
import { Card, Typography, convertPXToVW, SvgArrowRight } from '@sofia/ui';
import { number, string, bool, func, object } from 'prop-types';
import { isNil } from '@ampli/utils';
import { useTheme } from '@emotion/react';

import {
  Img,
  Wrapper,
  MarginWrapper,
  FlexBox,
  RelativeContainer,
  EllipsisText,
  NewDiscipline,
} from './card.styles';
import { Chip } from '@ampli/ui';

const SubjectCard = forwardRef(
  (
    {
      subjectName,
      url = true,
      duration,
      progress,
      status,
      compact = false,
      started,
      onClick,
      examPeriod,
      assignPeriod,
      studyingDurationAriaLabel,
      examPeriodAriaLabel,
      tabIndex,
      image,
      disabled,
      id,
      startDate,
    },
    ref
  ) => {
    const hasProgress = isNil(progress);
    const theme = useTheme();
    const newDisciplineLabel = startDate
      .plus({ days: 5 })
      .diffNow('days')
      .toObject();

    return (
      <RelativeContainer id={id}>
        {url && (
          <Card
            minWidth={convertPXToVW(300)}
            borderColor={theme.colors.card.border.primary}
            focusColor={theme.colors.card.border.third}
            shadow={theme.colors.card.border.shadow}
            tabIndex={tabIndex}
            hover={theme.colors.card.color.hover}
            onClick={onClick}
            disabled={disabled}
            ref={ref}
          >
            {newDisciplineLabel.days >= 0 ? (
              <NewDiscipline
                id={`${id}-new-subject`}
                aria-label={`Nova disciplina`}
              >
                Nova disciplina
              </NewDiscipline>
            ) : null}
            <Img id={`${id}-image`} src={image} />
            <Wrapper>
              <MarginWrapper>
                <EllipsisText
                  margin="0"
                  weight="bold"
                  aria-label={`Disciplina: ${subjectName}.`}
                  color={theme.colors.text.black}
                  id={`titleSubject-${id}`}
                >
                  {subjectName}
                </EllipsisText>
                {hasProgress ? null : (
                  <Chip
                    id={`progress-${id}`}
                    aria-label={`Progresso em ${progress}%.`}
                  >
                    Progresso {progress}%
                  </Chip>
                )}
              </MarginWrapper>
              <div>
                <FlexBox id={`situation-${id}`}>
                  <Typography
                    margin="0px 3px 0px 0px"
                    aria-label={`Situação: ${started ? status : `Em espera`}.`}
                    size={14}
                    color={theme.colors.text.lightBlack}
                    id={`situation-${id}`}
                  >
                    Situação:
                  </Typography>
                  <Typography
                    margin="0px"
                    weight="bold"
                    size={14}
                    color={theme.colors.text.black}
                    id={`status-situation-${id}`}
                  >
                    {started ? status : `Em espera`}
                  </Typography>
                  {!compact && started && (
                    <Typography
                      margin={'0px 0px 0px 600px'}
                      weight="700"
                      color={theme.colors.button.primary}
                      size={16}
                    >
                      Continuar estudos <SvgArrowRight />
                    </Typography>
                  )}
                </FlexBox>
                <FlexBox id={`studies-${id}`}>
                  <Typography
                    margin="0px"
                    aria-label={`Período de estudos de ${studyingDurationAriaLabel}.`}
                    size={14}
                    color={theme.colors.text.lightBlack}
                    id={`study-period-${id}`}
                  >
                    Período de estudos:
                  </Typography>
                  <Typography
                    margin="0px"
                    color={theme.colors.text.black}
                    size={14}
                    id={`study-period-duration-${id}`}
                  >
                    {duration}
                  </Typography>
                </FlexBox>
                {started && examPeriod && (
                  <FlexBox id={`exam-${id}`}>
                    <Typography
                      margin="0px"
                      aria-label={`Período de provas de ${examPeriodAriaLabel}.`}
                      color={theme.colors.text.lightBlack}
                      size={14}
                      id={`exam-period-${id}`}
                    >
                      Período de provas:
                    </Typography>
                    <Typography
                      margin="0px"
                      color={theme.colors.text.black}
                      size={14}
                      id={`exam-period-duration-${id}`}
                    >
                      {examPeriod()}
                    </Typography>
                  </FlexBox>
                )}
                {started && assignPeriod && (
                  <FlexBox id={`task-{id}`}>
                    <Typography id={`work-time-${id}`} size={14}>
                      Período de trabalho:
                    </Typography>{' '}
                    <Typography
                      id={`work-time-duration-${id}`}
                      color={theme.colors.text.lightBlack}
                      size={14}
                    >
                      {assignPeriod}
                    </Typography>
                  </FlexBox>
                )}
              </div>
            </Wrapper>
          </Card>
        )}
      </RelativeContainer>
    );
  }
);

SubjectCard.propTypes = {
  className: string,
  subjectName: string.isRequired,
  url: string,
  id: string,
  progress: number.isRequired,
  status: string,
  duration: string.isRequired,
  examPeriod: func,
  assignPeriod: string,
  started: bool,
  compact: bool,
  onClick: func,
  disabled: bool,
  studyingDurationAriaLabel: string,
  image: string,
  examPeriodAriaLabel: string,
  tabIndex: number,
  startDate: object,
};

SubjectCard.displayName = 'SubjectCard';

export default SubjectCard;
