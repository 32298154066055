import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const HeaderDescription = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;
  margin-bottom: 16px;
`;

export const PeriodAssignments = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Assignment = styled.div(
  ({ warning, theme }) => `
  display: flex;
  flex-direction: column;

  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: ${
      warning ? 'none' : `1px dotted ${theme.colors.text.lightBlack}`
    };
  }
`
);

export const AssignmentTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  justify-content: space-between;
`;

export const AssignmentDescription = styled.div`
  display: flex;
  margin-bottom: 5px;

  text-align: left;
`;

export const WaringContainer = styled.div`
  display: flex;
  padding: 20px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.card.background.danger};
`;
