import React from 'react';

interface SvgTourIllustrationProps {
  title?: string;
  desc?: string;
  width?: string;
  height?: string;
  scale?: number;
  marginBottom?: number;
}

const SvgTourIllustrationAirplane: React.FC<SvgTourIllustrationProps> = ({
  title,
  desc,
  ...props
}: SvgTourIllustrationProps) => {
  return (
    <svg
      width="196"
      height="142"
      viewBox="0 0 196 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={`${title ? 'iconTourIllustrationTitle' : ''} ${
        desc ? 'iconTourIllustrationDesc' : ''
      }`}
      {...props}
    >
      {title ? <title id="iconTourIllustrationTitle">{title}</title> : null}
      {desc ? <desc id="iconTourIllustrationDesc">{desc}</desc> : null}
      <g clipPath="url(#clip0_3561_4491)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.7444 32.1776C29.1001 37.9504 25.892 46.5664 15.9343 56.4075C16.3612 51.9064 13.1531 39.1756 22.9307 34.0552C28.6133 31.0789 38.6377 34.3282 45.1406 30.6395C49.5292 28.1492 46.9614 24.3406 45.0072 21.624C43.193 25.3261 35.2762 23.3352 35.2762 18.0818C35.2748 17.268 35.4601 16.4648 35.818 15.7337C36.1759 15.0025 36.6968 14.363 37.3408 13.8641C37.9847 13.3653 38.7346 13.0203 39.5329 12.8557C40.3312 12.6912 41.1566 12.7114 41.9458 12.9148C41.8591 5.87026 52.037 -4.82312 59.7204 4.88482C58.093 6.17655 53.5443 5.80368 50.6364 2.95389C52.6372 8.50699 46.8947 14.0934 47.4483 15.6048C53.1375 24.3606 49.936 26.8442 53.7444 32.1776Z"
          fill="#212529"
          stroke="#212529"
          strokeWidth="0.173941"
          strokeMiterlimit="22.93"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.8989 34.2886C51.1835 30.2936 51.6504 27.6302 51.1635 23.0026C50.8767 20.2727 46.9149 15.9247 47.3351 15.0125C48.2889 13.015 52.2373 9.17313 50.6699 2.84766C55.8722 7.74158 60.8344 4.37243 60.0074 4.73864C61.1479 5.85725 63.1421 10.8577 63.4223 10.8777C70.8656 11.3904 72.6864 11.9563 65.0697 15.5785C66.2102 18.7546 69.8118 28.2295 71.039 30.9328C70.4187 34.0955 64.2026 36.5391 55.8989 34.2886Z"
          fill="white"
        />
        <path
          d="M62.9553 35.301C57.9864 36.2066 55.5453 34.2823 55.0518 33.7163C48.9757 26.7184 54.0313 25.7263 47.7152 16.1448C45.9477 13.4815 52.9108 10.4919 50.5364 3.13434C50.1096 1.80266 55.1185 7.70199 59.8005 4.88549"
          stroke="#212529"
          strokeWidth="1.68406"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.6408 26.7045C10.9121 23.8747 -28.4721 117.325 27.5995 132.826C28.6399 134.71 28.86 132.999 28.7533 138.006C78.9689 147.561 99.6114 128.019 135.207 122.246C140.089 91.2577 139.576 29.5077 75.6408 26.7045Z"
          fill="#7389FF"
        />
        <path
          d="M74.833 52.3741C75.1424 52.1614 75.1867 51.6895 74.9319 51.32C74.6771 50.9505 74.2198 50.8233 73.9104 51.0359C73.6009 51.2485 73.5566 51.7205 73.8114 52.09C74.0662 52.4595 74.5235 52.5867 74.833 52.3741Z"
          fill="#212529"
        />
        <path
          d="M72.4053 40.8093C72.6717 40.545 72.631 40.0727 72.3143 39.7546C71.9976 39.4364 71.5248 39.3928 71.2583 39.6571C70.9919 39.9215 71.0326 40.3937 71.3493 40.7119C71.6661 41.03 72.1388 41.0737 72.4053 40.8093Z"
          fill="#212529"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.901 54.3638C108.862 54.4304 112.571 58.1192 107.002 64.4513C107.255 65.9761 109.196 67.887 107.108 72.5213C112.251 82.2226 95.1029 97.8898 92.4351 87.8356C90.7743 89.8331 88.2732 92.31 86.339 94.2476C80.3092 85.0111 76.6005 74.4586 75.5276 63.4858C87.3728 52.5394 96.9438 45.7745 112.211 42.665C127.11 42.8315 111.103 51.9535 105.901 54.3638Z"
          fill="white"
        />
        <path
          d="M53.6109 12.4352C54.0597 12.1462 54.1436 11.4786 53.7982 10.9439C53.4528 10.4093 52.8089 10.2102 52.3601 10.4992C51.9112 10.7882 51.8274 11.4559 52.1728 11.9905C52.5181 12.5251 53.162 12.7242 53.6109 12.4352Z"
          fill="#212529"
        />
        <path
          d="M59.6171 10.1339C59.9328 9.93063 59.9913 9.46026 59.7477 9.08327C59.5042 8.70629 59.0508 8.56545 58.7352 8.7687C58.4195 8.97195 58.361 9.44233 58.6046 9.81931C58.8481 10.1963 59.3014 10.3371 59.6171 10.1339Z"
          fill="#212529"
        />
        <path
          d="M90.2671 45.1612C92.9878 43.8804 95.8897 43.0248 98.8709 42.6244M90.4272 41.1063C91.4729 40.7831 92.5471 40.5602 93.6353 40.4404"
          stroke="#212529"
          strokeWidth="0.790639"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.2 20.6393C173.071 19.8803 194.414 15.4391 194.914 17.5631C195.527 20.1865 177.513 27.5507 169.356 36.2066L164.06 32.0984L157.857 35.6673L158.571 27.8637L149.2 20.6393Z"
          fill="#FFA433"
        />
        <path
          d="M120.961 30.6261C124.249 29.1679 138.929 24.3272 147.273 23.1221"
          stroke="#212529"
          strokeWidth="1.12271"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M143.164 33.1965C147.166 31.9447 151.675 30.107 155.51 29.1016"
          stroke="#212529"
          strokeWidth="0.996206"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M157.937 35.4274L158.771 27.8036L190.058 19.4473M149.88 21.0719C156.594 25.6272 163.074 30.5162 169.296 35.7204"
          stroke="#212529"
          strokeWidth="1.12271"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M162.546 38.9624C164.227 37.9138 166.016 37.0477 167.882 36.3789"
          stroke="#212529"
          strokeWidth="0.577167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M120.741 97.1301C121.408 100.659 122.742 116.033 121.975 124.43M62.4549 21.7638C58.7533 23.6481 55.6519 21.0979 53.0641 17.1029M75.7408 64.3775C77.1881 81.2299 84.2112 91.304 85.8453 93.8875M107.001 53.8905C126.75 43.3303 116.872 41.446 108.149 43.6166C81.5234 50.275 61.2677 79.6652 48.4821 84.7722M85.0583 61.268C94.5958 53.3912 117.559 49.6158 106.875 64.6771M89.8537 68.0529C108.936 56.7869 108.775 67.5003 107.041 72.7538M97.8106 74.5316C118.606 63.3388 103.293 96.0914 93.9556 90.1055C91.2877 88.3743 100.625 81.2232 95.8564 85.3914C92.4015 88.401 88.7933 92.0498 86.2321 94.3602C84.0778 96.3245 77.3549 103.735 74.4136 106.199C62.6217 116.06 51.6034 120.015 49.7359 120.221M63.2219 73.9123C62.2815 85.3182 69.0912 98.1622 74.2735 105.613M84.4247 102.444C86.4856 108.043 88.2597 113.556 88.5265 118.257M27.9396 49.5226C31.141 52.0595 42.5794 63.6318 42.6128 72.1612M65.2362 31.7247C68.0707 38.6495 70.3651 49.6425 71.0654 57.5793M74.1534 29.0947C75.0157 31.1095 75.703 33.1946 76.2077 35.3269C75.0872 34.488 64.6159 30.5728 64.5825 30.6661C60.4874 40.8734 59.0668 42.1451 51.5568 31.2387C51.3836 31.0476 51.256 30.82 51.1833 30.5728M68.5243 25.1063C67.4438 22.5029 66.2099 18.7409 65.0761 15.6181M63.3953 11.0371C62.638 8.77659 61.4914 6.66531 60.0072 4.79819C54.8982 -2.33295 43.88 0.630033 42.0725 13.3742C37.824 11.1503 32.5016 17.7221 36.2566 22.0301C38.1708 24.2407 42.8395 23.7147 44.6003 21.5973C46.6012 23.7014 49.5225 26.5578 45.3807 30.7326C38.6444 37.5242 7.8507 19.9327 10.3585 54.1768M57.8195 9.98508C59.3202 11.5032 77.7684 10.844 66.2099 15.3118C63.8289 16.224 58.2464 15.7113 55.8453 14.4196"
          stroke="#212529"
          strokeWidth="1.12271"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3561_4491">
          <rect
            width="194.635"
            height="141.875"
            fill="white"
            transform="translate(0.460938 0.0625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgTourIllustrationAirplane;
