import React from 'react';

import {
  useLocation,
  useHistory,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';

import { GuardedRoute, GuardProvider } from 'react-router-guards';
import { FullscreenLoader, ScrollToTop } from '@ampli/ui';
import { useServiceClient } from '@ampli/services';

import {
  AcademicCalendarPage,
  AcoPage,
  CourseCancellationPage,
  CourseSelectionPage,
  HomePage,
  MobileWatsonChatbotPage,
  OnboardingCompromisePage,
  OnboardingDocumentsPage,
  OnboardingWelcomePage,
  StudentPage,
  TutorPage,
  UnexpectedErrorPage,
  ProfilePage,
  SecurityPage,
  CourseInformationPage,
  CommunicationPreferencesPage,
} from './lazy-routes';

import {
  mobileWatsonChatbotGuard,
  courseSelectionGuard,
  onboardingPageGuard,
  featureToggleGuard,
  isFreetrialGuard,
  authGuard,
  tutorAuthGuard,
  termsGuard,
} from '../guards';

import {
  InauguralSubjectRoutesPath,
  InauguralSubjectModule,
} from '../modules/inaugural-subject';

import {
  ScreenFallbackLoader,
  MessagingRoutesPath,
  MessagingModule,
  NotesRoutesPath,
  NotesModule,
  ToursContainer,
} from '../modules';

import {
  TutorViewSectionPage,
  TutorViewRoutesPath,
} from '../modules/tutor-preview';

import { AssignmentModule, AssignmentRoutesPath } from '../modules/assignment';
import { BeforeStartModalProvider } from '../modules/shared/components/tours/welcome-tour/modals/before-start/before-start.mobile';
import { withRolesProvider } from '../modules/shared/contexts/communication-roles-context';
import { wisdomRoutesPath } from '../modules/wisdom';
import { locationPathname } from '../constants';
import { useQueryParams } from '../hooks';
import { ErrorBoundary } from '../components';
import { ChatCopilot } from '../components/chat-copilot';
import { useGetState } from '../state';
import { features } from '../config';

import SuspensionCancellationPage from '../pages/suspension-cancellation/suspension-cancellation-page';
import MandatoryInternshipRoutes from './internship-routes';
import SecretaryRoutes from './secretary-routes';
import SyllabusRoutes from './syllabus-routes';
import ElectiveRoutes from './elective-routes';
import SubjectRoutes from './subject-routes';
import PaymentRoutes from './payment-routes';
import LoginRoutes from './login-routes';
import GradeRoutes from './grade-routes';
import ExamRoutes from './exam-routes';
import QuizRoutes from './quiz-routes';
import routePath from './routes-path';
import logout from '../lib/logout';
import CommunicationPreferencesProvider from '../modules/communication/contexts/communication-preferences-context';
import { MeProvider } from '../modules/shared/contexts/me-context';
import { CreativeProvider } from '../modules/communication/creatives/creatives-exhibition-controller/creatives-exhibition-controller';
import { CourseLiveModule, LiveRoutesPath } from '../modules/live';
import { CommunityModule, CommunityPath } from '../modules/community';
import { CourseEnrollmentsProvider } from '../modules/shared/contexts/course-enrollments-context';
import PublicationFormProvider from '../modules/community/contexts/publication-form-context';

const WisdomModule = React.lazy(() =>
  import(/* webpackChunkName: "wisdom" */ '../modules/wisdom/module')
);

const NotFound = () => <Redirect to="/" />;

const Logout = () => {
  const client = useServiceClient('privateClient');
  const history = useHistory();

  logout({ client, history });

  return <FullscreenLoader />;
};

const Routes = () => {
  const clients = useServiceClient('all');

  const { action } = useHistory();
  const { pathname } = useLocation();

  const queryParams = useQueryParams();

  const [state, actions] = useGetState();

  const meta = {
    clients,
    actions,
    state,
    queryParams,
  };
  const authMeta = { ...meta, auth: { redirect: routePath.login } };
  const studentMeta = { ...authMeta, isCourseOptional: true };
  const acoMeta = { ...authMeta, isCourseOptional: true };
  const cancellationMeta = { ...authMeta, isCourseOptional: true };
  const suspensionCancellationMeta = { ...authMeta, isCourseOptional: false };
  const onboardingMeta = { ...authMeta, isOnboardingPage: true };
  const assignmentMeta = { ...authMeta, toggle: features.assignment };

  const homeMeta = {
    ...authMeta,
    isOnboardingTrigger: true,
  };

  const noAuthMeta = {
    ...meta,
    auth: { noAuth: true, redirect: routePath.courseSelection },
  };

  const loginPaths = routePath.login;
  const actualPath = locationPathname();

  const showChatCopilot = !['login', 'aula', 'meus-cursos', 'exercicio'].some(
    (el) => actualPath.includes(el)
  );

  const FallbackLoader = () => <ScreenFallbackLoader pathname={pathname} />;

  return (
    <ErrorBoundary FallbackComponent={UnexpectedErrorPage}>
      <React.Suspense fallback={<FallbackLoader />}>
        <ScrollToTop pathname={pathname} action={action} />

        {!actualPath.includes(loginPaths)
          ? withRolesProvider(
              <CourseEnrollmentsProvider>
                <React.Fragment>
                  <BeforeStartModalProvider>
                    <CreativeProvider>
                      <ToursContainer />
                    </CreativeProvider>
                  </BeforeStartModalProvider>

                  <MeProvider>
                    {showChatCopilot ? (
                      <ChatCopilot componentId="virtual-assistant-chat" />
                    ) : null}
                  </MeProvider>
                </React.Fragment>
              </CourseEnrollmentsProvider>
            )
          : null}

        <GuardProvider
          guards={[
            authGuard,
            onboardingPageGuard,
            featureToggleGuard,
            courseSelectionGuard,
            termsGuard,
          ]}
          loading={FallbackLoader}
          error={NotFound}
        >
          <Switch>
            <Route path={routePath.login}>
              <LoginRoutes />
            </Route>
            <CourseEnrollmentsProvider>
              {withRolesProvider(
                <MeProvider>
                  <CreativeProvider>
                    <Switch>
                      <Route path={routePath.syllabus}>
                        <SyllabusRoutes />
                      </Route>
                      <Route path={routePath.subject}>
                        <SubjectRoutes />
                      </Route>
                      <Route path={routePath.electiveSubjectChoices}>
                        <ElectiveRoutes />
                      </Route>
                      <Route path={InauguralSubjectRoutesPath.inauguralSubject}>
                        <InauguralSubjectModule
                          routesSettings={{ guardedRouteMeta: authMeta }}
                        />
                      </Route>
                      <Route path={routePath.profile}>
                        <ProfilePage />
                      </Route>
                      <Route path={routePath.communicationPreferences}>
                        <CommunicationPreferencesProvider>
                          <CommunicationPreferencesPage />
                        </CommunicationPreferencesProvider>
                      </Route>

                      <Route path={routePath.security}>
                        <SecurityPage />
                      </Route>

                      <Route path={routePath.courseInformation}>
                        <CourseInformationPage />
                      </Route>

                      <GuardedRoute
                        exact
                        path={TutorViewRoutesPath.tutorViewSectionPage}
                        guards={[tutorAuthGuard]}
                        meta={{
                          ...meta,
                          auth: { noAuth: true, redirect: '/' },
                        }}
                      >
                        <TutorViewSectionPage />
                      </GuardedRoute>

                      <Route path={MessagingRoutesPath.historic}>
                        <MessagingModule
                          routesSettings={{ guardedRouteMeta: authMeta }}
                        />
                      </Route>

                      <Route path={CommunityPath.community}>
                        <PublicationFormProvider>
                          <CommunityModule
                            routesSettings={{ guardedRouteMeta: authMeta }}
                          />
                        </PublicationFormProvider>
                      </Route>

                      <Route path={NotesRoutesPath.notes}>
                        <NotesModule
                          routesSettings={{ guardedRouteMeta: authMeta }}
                        />
                      </Route>

                      <Route path={LiveRoutesPath.courseLive}>
                        <CourseLiveModule
                          routesSettings={{ guardedRouteMeta: authMeta }}
                        />
                      </Route>
                      <Route path={LiveRoutesPath.courseLive}>
                        <CourseLiveModule
                          routesSettings={{ guardedRouteMeta: authMeta }}
                        />
                      </Route>

                      <Route path="/:courseType/:courseVersionId/prova/">
                        <ExamRoutes />
                      </Route>
                      <Route path="/:courseType/:courseVersionId/exercicio/">
                        <QuizRoutes />
                      </Route>
                      <Route path={routePath.payment.home}>
                        <PaymentRoutes />
                      </Route>
                      <Route path={routePath.grade}>
                        <GradeRoutes />
                      </Route>
                      <Route path={routePath.secretaryStudent}>
                        <SecretaryRoutes />
                      </Route>
                      {/* Internship */}
                      <Route path={routePath.mandatoryInternshipPage}>
                        <MandatoryInternshipRoutes />
                      </Route>
                      {/* TODO: Change to guarded route when it has access from course page */}
                      {/* <GuardedRoute
              exact
              path={routePath.mandatoryInternshipPage}
              meta={authMeta}
            >
              <MandatoryInternshipRoutes />
            </GuardedRoute> */}

                      <GuardedRoute
                        exact
                        path={routePath.logout}
                        meta={authMeta}
                      >
                        <Logout />
                      </GuardedRoute>
                      {/* Wisdom */}
                      <Route path={wisdomRoutesPath.home}>
                        <WisdomModule
                          routesSettings={{ guardedRouteMeta: authMeta }}
                        />
                      </Route>
                      <GuardedRoute
                        exact
                        path={routePath.logout}
                        meta={authMeta}
                      >
                        <Logout />
                      </GuardedRoute>
                      {/* Onboarding */}
                      <GuardedRoute
                        exact
                        path={routePath.onboardingWelcome}
                        meta={onboardingMeta}
                      >
                        <OnboardingWelcomePage />
                      </GuardedRoute>
                      <GuardedRoute
                        exact
                        path={routePath.onboardingCompromise}
                        meta={onboardingMeta}
                      >
                        <OnboardingCompromisePage />
                      </GuardedRoute>
                      <GuardedRoute
                        exact
                        path={routePath.onboardingDocuments}
                        meta={onboardingMeta}
                      >
                        <OnboardingDocumentsPage />
                      </GuardedRoute>
                      <Route
                        path={[
                          AssignmentRoutesPath.onboardingAssignment,
                          AssignmentRoutesPath.onboardingAssignmentDocument,
                        ]}
                      >
                        <AssignmentModule
                          routesSettings={{ guardedRouteMeta: assignmentMeta }}
                        />
                      </Route>
                      <GuardedRoute
                        exact
                        path={routePath.academicCalendar}
                        meta={authMeta}
                      >
                        <AcademicCalendarPage />
                      </GuardedRoute>
                      {/* Watson mobile webview */}
                      <GuardedRoute
                        exact
                        path={routePath.mobileWatsonChatbot}
                        meta={noAuthMeta}
                        guards={[mobileWatsonChatbotGuard]}
                      >
                        <MobileWatsonChatbotPage />
                      </GuardedRoute>
                      {/* Course Selection */}
                      <GuardedRoute
                        exact
                        path={routePath.courseSelection}
                        meta={authMeta}
                      >
                        <CourseSelectionPage />
                      </GuardedRoute>
                      {/* Student */}
                      <GuardedRoute
                        exact
                        path={routePath.student}
                        meta={studentMeta}
                      >
                        <StudentPage />
                      </GuardedRoute>
                      <GuardedRoute
                        exact
                        path={routePath.tutor}
                        meta={authMeta}
                        component={TutorPage}
                      >
                        <TutorPage />
                      </GuardedRoute>
                      {/* Aco */}
                      <GuardedRoute exact path={routePath.aco} meta={acoMeta}>
                        <AcoPage />
                      </GuardedRoute>
                      {/* Course Cancellation */}
                      <GuardedRoute
                        exact
                        path={routePath.courseCancellation}
                        meta={cancellationMeta}
                        guards={[isFreetrialGuard]}
                      >
                        <CourseCancellationPage />
                      </GuardedRoute>
                      {/* Course Suspension and Cancellation */}
                      <GuardedRoute
                        exact
                        path={routePath.courseSuspensionCancellation}
                        meta={suspensionCancellationMeta}
                      >
                        <SuspensionCancellationPage />
                      </GuardedRoute>
                      {/* Another */}
                      <GuardedRoute exact path={'/'} meta={homeMeta}>
                        <HomePage />
                      </GuardedRoute>
                      <GuardedRoute exact path={routePath.home} meta={homeMeta}>
                        <HomePage />
                      </GuardedRoute>
                      {/* NotFound: must be the last route */}
                      <GuardedRoute path="*">
                        <NotFound />
                      </GuardedRoute>
                    </Switch>
                  </CreativeProvider>
                </MeProvider>
              )}
            </CourseEnrollmentsProvider>
          </Switch>
        </GuardProvider>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default Routes;
