import React from 'react';

import { ButtonPrimaryLong, Typography } from '@sofia/ui';
import { generatePath, useHistory } from 'react-router-dom';
import { RichText, cx, css } from '@ampli/ui';
import { useTheme } from '@emotion/react';

import {
  analyticsCreativesEvents,
  useInternLinksCTA,
  INTERNAL_LINKS,
} from '../../../../../shared/utils/communication-constants';

import { useUpdateReadCreative } from '../../../../services/hooks';
import { useNotifications } from '../../contexts/notification-context';
import { CtaIconToggler } from '../../../components/cta-icon-toggler';
import { parsedCategory } from '../../../../types';
import { useGetState } from '../../../../../../state';
import { pushEvent } from '../../../../../../lib/ga';
import { routePath } from '../../../../../../routes';

import * as Styles from './notification-details.styles';

const NotificationDetails: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();

  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const { notificationId, notification, courseEnrollmentId } =
    useNotifications();

  const [updateRead] = useUpdateReadCreative(
    courseEnrollmentId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    notificationId!,
    {},
    true
  );

  const notificationToDisplay = React.useMemo(
    () => notification?.creative?.find(({ id }) => id === notificationId),
    [notificationId, notification]
  );

  const category = parsedCategory(notificationToDisplay?.categoryType);

  const callToAction = useInternLinksCTA(
    notificationToDisplay?.ctaLink as INTERNAL_LINKS
  );

  const setNotificationAsRead = React.useCallback(() => {
    if (notificationToDisplay?.read) return;

    return updateRead();
  }, [notificationToDisplay?.read, updateRead]);

  const onCallToAction = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      pushEvent(
        'event',
        analyticsCreativesEvents.notificationsCenter
          .Com_central_notificacoes_clique_cta.event,
        {
          item: analyticsCreativesEvents.notificationsCenter.Com_central_notificacoes_clique_cta.param(
            category?.title
          ),
        }
      );

      if (notificationToDisplay?.ctaLink === 'Fazer prova final') {
        sessionStorage.setItem('finalExamModal', 'true');
        return history.push({
          pathname: generatePath(routePath.home, {
            courseType: selectedCourseEnrollmentType,
            courseVersionId: selectedCourseVersionId,
          }),
        });
      }

      if (typeof callToAction === 'string') {
        window.open(callToAction);
      } else {
        callToAction();
      }
    },
    [
      notificationToDisplay?.ctaLink,
      selectedCourseEnrollmentType,
      selectedCourseVersionId,
      category?.title,
      callToAction,
      history,
    ]
  );

  React.useLayoutEffect(() => {
    setNotificationAsRead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styles.NotificationDetailsWrapper>
      {notificationToDisplay?.image ? (
        <Styles.Image src={notificationToDisplay.image} alt="" />
      ) : null}

      <Typography color={theme.colors.text.black} size={24} weight={700}>
        {notificationToDisplay?.title}
      </Typography>

      <RichText
        className={cx(
          css(
            Styles.BodyDetails({
              color: theme.colors.text.blackSecondary,
            })
          )
        )}
      >
        {notificationToDisplay?.body}
      </RichText>

      {notificationToDisplay?.ctaText ? (
        <ButtonPrimaryLong
          background={theme.colors.button.primary}
          onClick={onCallToAction}
          color="#FFFFFF"
        >
          {notificationToDisplay?.ctaText}
          <CtaIconToggler ctaLink={notificationToDisplay?.ctaLink} />
        </ButtonPrimaryLong>
      ) : null}
    </Styles.NotificationDetailsWrapper>
  );
};

export default NotificationDetails;
