export const GET_ME = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "sofiaGetMe"
    },
    "variableDefinitions": [],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "data"
        },
        "name": {
          "kind": "Name",
          "value": "sofiaGetMe"
        },
        "arguments": [],
        "directives": [],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "grantedAuthorities"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "username"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "fullName"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "socialName"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "personId"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "forcePasswordReset"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "phoneNumber"
            },
            "arguments": [],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "countryCode"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "areaCode"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "number"
                },
                "arguments": [],
                "directives": []
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "firstName"
            },
            "arguments": [],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              },
              "arguments": []
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastName"
            },
            "arguments": [],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              },
              "arguments": []
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "phoneNumberString"
            },
            "arguments": [],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              },
              "arguments": []
            }]
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 347,
    "source": {
      "body": "\n  query sofiaGetMe {\n    data: sofiaGetMe {\n      grantedAuthorities\n      email\n      username\n      fullName\n      socialName\n      id\n      personId\n      forcePasswordReset\n      phoneNumber {\n        countryCode\n        areaCode\n        number\n      }\n      firstName @client\n      lastName @client\n      phoneNumberString @client\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};