import BreadcrumbWrapper from '../breadcrumbs-wrapper/breadcrumbs-wrapper';
import React, { useState, useRef, useEffect } from 'react';

import pt from 'date-fns/locale/pt-BR';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import {
  Banner,
  Header,
  Typography,
  Separator,
  SvgFilter,
  SvgAngleLeft,
} from '@sofia/ui';
import { generatePath, Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import {
  useGetEnrollmentCalendar,
  useGetAvailableEvents,
} from '@ampli/services';
import { useCalendarPageData } from '../../hooks';
import { LayoutContent } from '../layout';
import { useGetState } from '../../state';
import { routePath } from '../../routes';
import { Calendar } from './calendar';
import { Cards } from './cards';
import FilterModal from './calendar/filterModal/filter-modal';
import * as Style from './academic-calendar-styles';
import * as S from './cards/cards-styles';
import { useBreakpoint } from '../../modules';

function AcademicCalendar() {
  const FORMATE_DATE = 'yyyy-MM-dd';
  const theme = useTheme();
  const { isDesktop } = useBreakpoint();

  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();
  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), FORMATE_DATE)
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), FORMATE_DATE)
  );
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [cardsData, setCardsData] = useState();
  const [calendarDate, setCalendarDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isFilterReturnEmpty, setIsFilterReturnEmpty] = useState(false);
  const [selectedDay, setSelectedDay] = useState('');
  const [isReachingEnd, setIsReachingEnd] = useState(false);

  const [collapseCalendar, setCollapseCalendar] = useState(!isDesktop);

  const initialFilters = [
    'ACADEMIC_DATES',
    'UNIQUE_EVENTS',
    'SECRETARIAT',
    'FINANCIAL',
    'HOLIDAYS',
  ];

  const FilterModalRef = useRef(null);

  const forwardAndBackMonth = (date) => {
    setStartDate(format(startOfMonth(date), FORMATE_DATE));
    setEndDate(format(endOfMonth(date), FORMATE_DATE));
  };

  const { data: resCalendarDataEvents } = useCalendarPageData(
    startDate,
    endDate
  );

  const handleIsFilterReturnEmpty = (dataLenght) => {
    if (dataLenght === 0 && selectedFilters.length) {
      setIsFilterReturnEmpty(true);
    } else {
      setIsFilterReturnEmpty(false);
    }
  };

  const handleIsReachingEnd = (dataLenght) => {
    if (dataLenght === 0) {
      setIsReachingEnd(true);
    } else {
      setIsReachingEnd(false);
    }
  };

  const onCompleted = (data) => {
    const today = new Date().toISOString().split('T')[0];
    let tempData = [...data.data];

    setCardsData([]);

    handleIsFilterReturnEmpty(data?.data.length);
    handleIsReachingEnd(data?.data.length);

    if (tempData[0]?.eventDate !== today && !selectedDay) {
      tempData.unshift({
        category: '',
        subCategory: '',
        title: '',
        description: '',
        speaker: null,
        address: null,
        timer: '',
        eventDate: today,
        startDate: today,
        endDate: today,
        startTime: null,
        endTime: null,
        redirect: null,
      });
    }

    const calendarByEventDate = Object.groupBy(
      tempData,
      ({ eventDate }) => eventDate
    );
    const formattedData = Object.entries(calendarByEventDate);
    setCardsData(formattedData);
  };

  const onCompletedMoreDates = (data) => {
    const calendarByEventDate = Object.groupBy(
      data?.data,
      ({ eventDate }) => eventDate
    );

    handleIsReachingEnd(data?.data.length);

    const formattedData = Object.entries(calendarByEventDate);

    setCardsData([...cardsData, ...formattedData]);
  };

  const [{ selectedCourseEnrollmentId }] = useGetState();

  const getDateForQuery = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);

      date.setDate(date.getDate() + 1);

      return date.toISOString().split('T')[0];
    }
  };

  const { query: queryEnrollmentCalendar, loading } = useGetEnrollmentCalendar({
    courseEnrollmentId: selectedCourseEnrollmentId,
    date: calendarDate,
    eventCategories: selectedFilters.length ? selectedFilters : initialFilters,
    onCompleted,
  });

  const { query: queryMoreDates, loading: loadingMoreDates } =
    useGetEnrollmentCalendar({
      courseEnrollmentId: selectedCourseEnrollmentId,
      date: getDateForQuery(cardsData?.[cardsData.length - 1]?.[0]),
      eventCategories: selectedFilters,
      onCompleted: onCompletedMoreDates,
    });

  const { data: availableEvents, loading: loadingEvents } =
    useGetAvailableEvents(selectedCourseEnrollmentId);

  useEffect(() => {
    queryEnrollmentCalendar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const breadCrumbs = (
    <BreadcrumbWrapper
      secondColor={theme.colors.breadCrumbs.secondary}
      firstColor={theme.colors.breadCrumbs.primary}
      stroke={theme.colors.breadCrumbs.primary}
      fill={theme.colors.breadCrumbs.primary}
      backClick={() => {
        history.go(-1);
      }}
      shadow={false}
    >
      <Link
        id="initial"
        data-testid="initial"
        to={generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })}
      >
        <span>Início</span>
      </Link>
      <Typography data-testid="calendar" id="calendar" size={16} weight="bold">
        Calendário
      </Typography>
    </BreadcrumbWrapper>
  );

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  const titleCard = capitalize(
    format(new Date(startDate.replaceAll('-', '/')), "MMMM 'de' yyyy", {
      locale: pt,
    })
  );

  const handleOpenFilter = () => {
    FilterModalRef.current?.onOpen();
    setOpenFilterModal(!openFilterModal);
  };

  React.useEffect(() => {
    if (!isDesktop) {
      const handleScroll = () => {
        const scrollPosition = window.scrollY + window.innerHeight;
        const pageHeight = document.documentElement.scrollHeight;

        if (scrollPosition === 970) {
          window.scrollTo(0, 0);
        }

        if (
          scrollPosition >= pageHeight &&
          !loadingMoreDates &&
          !isReachingEnd
        ) {
          queryMoreDates();
        }

        if (window.scrollY > 0) {
          setCollapseCalendar(true);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isDesktop, isReachingEnd, loadingMoreDates, queryMoreDates]);

  React.useEffect(() => {
    if (!isDesktop && selectedDay !== '') {
      setCollapseCalendar(true);
    }
  }, [isDesktop, selectedDay]);

  return (
    <>
      {isDesktop ? (
        <Banner background={theme.colors.banner.primary}>
          <LayoutContent>
            <Header
              id="titleCalendar"
              breadCrumbs={breadCrumbs}
              background="none"
              padding="0px"
              lastChildren
            />
          </LayoutContent>
        </Banner>
      ) : (
        <Style.MobileHeaderWrapper
          data-testid="mobile-header"
          id="mobile-header"
        >
          <Style.MobileHeader onClick={() => history.go(-1)}>
            <SvgAngleLeft />
            <Typography
              size={16}
              color={theme.colors.text.secondary}
              weight={700}
              data-testid="mobile-title-security"
              id="mobile-title-security"
            >
              Calendário acadêmico
            </Typography>
          </Style.MobileHeader>
          <Separator />
        </Style.MobileHeaderWrapper>
      )}
      <LayoutContent padding={!isDesktop && '0'}>
        {isDesktop ? (
          <>
            <Typography
              data-testid="title-calendar"
              id="title-calendar"
              weight="bold"
              size="28px"
              margin="24px 0 0 0"
            >
              Calendário
            </Typography>
            <Typography
              size="16px"
              data-testid="description-calendar"
              id="description-calendar"
              margin="5px 0 24px 0"
            >
              Confira as datas relacionadas as suas atividades acadêmicas.
            </Typography>
            <S.ContainerHeader>
              <Typography
                size={24}
                weight={700}
                data-testid="title-month"
                id="title-month"
                tabIndex={0}
              >
                {titleCard}
              </Typography>
              <S.ButtonGhostFilter onClick={handleOpenFilter}>
                <SvgFilter />
                <Typography color={theme.colors.text.primary} weight={700}>
                  Filtrar
                </Typography>
              </S.ButtonGhostFilter>
            </S.ContainerHeader>
            <Separator />
          </>
        ) : null}
        <Style.Container>
          {!isDesktop ? (
            <Style.FilterButtonMobileWrapper
              onClick={() => {
                FilterModalRef.current?.onOpen();
                setOpenFilterModal(!openFilterModal);
              }}
            >
              <SvgFilter />
              <Style.FilterButtonMobile
                data-testId="action-button-card-modal"
                id="action-button-card-modal"
                color={theme.colors.text.primary}
              >
                Filtrar
              </Style.FilterButtonMobile>
            </Style.FilterButtonMobileWrapper>
          ) : null}
          <Style.ColCards isCollapsed={collapseCalendar}>
            <Cards
              loading={loading}
              loadingMoreDates={loadingMoreDates}
              startDate={startDate}
              enrollmentCalendarData={cardsData}
              queryEnrollmentCalendar={queryMoreDates}
              isFilterReturnEmpty={isFilterReturnEmpty}
              setSelectedFilters={setSelectedFilters}
              isReachingEnd={isReachingEnd}
            />
          </Style.ColCards>
          <Calendar
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            setCalendarDate={setCalendarDate}
            forwardAndBackMonth={forwardAndBackMonth}
            events={resCalendarDataEvents?.data}
            loading={loadingEvents}
            availableEvents={availableEvents?.getAvailableEvents}
            setCardsData={setCardsData}
            setCollapseCalendar={setCollapseCalendar}
            collapseCalendar={collapseCalendar}
            isReachingEnd={isReachingEnd}
          />
        </Style.Container>
        <FilterModal
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          ref={FilterModalRef}
        />
      </LayoutContent>
    </>
  );
}

export default AcademicCalendar;
