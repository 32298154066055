import React from 'react';

import { bool, clickByKey, func, string, object } from '@ampli/utils';
import { Container, SpinnerContent } from './styles';
import { useGetCompanyInformation } from '../../hooks';
import { LoadingSpinner } from '../../components/loading-spinner';
import { useGetState } from '../../state';

import logoAmpli from '../../assets/LogoDuplo.png';

function LogoWrapper({
  onClick,
  ariaLabel,
  disabled,
  className,
  logo,
  ...rest
}) {
  const [{ selectedCourseEnrollmentId }] = useGetState();

  const { company, loading } = useGetCompanyInformation(
    selectedCourseEnrollmentId,
    { skip: logo }
  );

  const logoUrl =
    company?.name === 'Ampli Educacional' ? logoAmpli : company?.logo || null;

  return logo || !loading ? (
    <Container
      tabIndex="0"
      aria-label={ariaLabel}
      onKeyDown={(evt) => {
        if (!disabled) clickByKey(evt, onClick);
      }}
      {...rest}
      role={onClick ? 'button' : undefined}
      clickable={!!onClick}
      className={className}
    >
      {logo || logoUrl ? (
        <img
          src={logo || logoUrl}
          className="imgLogo"
          id="logo-header"
          data-testid="logo-header"
          alt=""
          aria-hidden
        />
      ) : null}

      {logo || company?.responsiveLogo ? (
        <img
          src={logo || company?.responsiveLogo}
          alt=""
          id="logo-header-responsive"
          data-testid="logo-header-responsive"
          className="imgLogoResponsive"
          aria-hidden
        />
      ) : null}
    </Container>
  ) : (
    <SpinnerContent>
      <LoadingSpinner width={20} height={20} />
    </SpinnerContent>
  );
}

LogoWrapper.propTypes = {
  className: string,
  ariaLabel: string,
  onClick: func,
  disabled: bool,
  logoAriaLabel: string,
  logo: object,
};

export default LogoWrapper;
