import {
  CustomizationDataProps,
  CustomizationThemeType,
  FormatFontItemType,
  FontAlignItemType,
  CustomizationTemplateType,
} from './types';
import React from 'react';

import { SvgAlignJustify, SvgAlignLeft } from '@sofia/ui';

const FONT_ALIGN_VALUES: FontAlignItemType[] = [
  {
    type: 'left',
    label: 'Esquerda',
    ariaLabel: 'Alinhamento de texto. Alinhado a esquerda',
    icon: <SvgAlignLeft />,
  },
  {
    type: 'justify',
    label: 'Justificado',
    ariaLabel: 'Alinhamento Justificado',
    icon: <SvgAlignJustify />,
  },
];

const FORMAT_FONT_LIST: FormatFontItemType[] = [
  {
    id: 1,
    value: 'Roboto Slab',
    title: 'Padrão',
    ariaLabel: `Fonte do texto. Fonte padrão`,
  },
  {
    id: 2,
    value: 'Helvetica',
    title: 'Helvética',
    ariaLabel: `Fonte do texto. Fonte helvetica`,
  },
  {
    id: 3,
    value: 'Verdana',
    title: 'Verdana',
    ariaLabel: `Fonte do texto. Fonte verdana`,
  },
  {
    id: 4,
    value: 'Georgia',
    title: 'Georgia',
    ariaLabel: `Fonte do texto. Fonte georgia`,
  },
  {
    id: 5,
    value: 'Times New Roman',
    title: 'Times New Roman',
    ariaLabel: `Fonte do texto. Fonte times new roman`,
  },
  {
    id: 6,
    value: 'opendyslexic',
    title: 'Dyslexic',
    ariaLabel: `Fonte do texto. Fonte dyslexic`,
  },
];

enum FONT_SIZE_SLIDER {
  MAX = 32,
  MIN = 16,
  STEP = 4,
}

enum LINE_SPACING_SLIDER {
  MAX = 252,
  MIN = 140,
  STEP = 28,
}

enum LETTER_AND_WORD_SPACING_SLIDER {
  MAX = 20,
  MIN = 0,
  STEP = 5,
}

const THEME_PICKER_OPTIONS: CustomizationThemeType[] = [
  {
    id: 1,
    background: '#ffffff',
    color: '#212529',
    title: 'Claro',
    ariaLabel: `Cor de fundo padrão`,
  },
  {
    id: 2,
    background: '#fff8ea',
    color: '#3d3025',
    title: 'Sépia',
    ariaLabel: `Cor de fundo sépia`,
  },
  {
    id: 3,
    background: '#3f3f41',
    color: '#f2f2f2',
    title: 'Cinza',
    ariaLabel: `Cor de fundo cinza`,
  },
  {
    id: 4,
    background: '#0d0d0d',
    color: '#e6e6e6',
    title: 'Escuro',
    ariaLabel: `Cor de fundo escuro`,
  },
];

export const TEMPLATE_CUSTOMIZATION_COLOR: CustomizationTemplateType = {
  Template01: {
    id: 5,
    background: '#353062',
    color: '#E6E6E6',
    title: 'Padrão',
    ariaLabel: `Tema padrão`,
  },
  Template02: {
    id: 5,
    background: '#27272D',
    color: '#E6E6E6',
    title: 'Padrão',
    ariaLabel: `Tema padrão`,
  },
  Template03: {
    id: 5,
    background: '#F7B565',
    color: '#212529',
    title: 'Padrão',
    ariaLabel: `Tema padrão`,
  },
  Template04: {
    id: 5,
    background: '#E5E3EE',
    color: '#212529',
    title: 'Padrão',
    ariaLabel: `Tema padrão`,
  },
};

const defaultCustomization = (
  isDesktop: boolean,
  defaultTemplate?: CustomizationThemeType | null
): CustomizationDataProps => {
  return {
    theme: defaultTemplate || THEME_PICKER_OPTIONS[0],
    fontSize: isDesktop ? 20 : 16,
    formatAlign: 'left',
    formatFont: FORMAT_FONT_LIST[0].value,
    betweenLines: LINE_SPACING_SLIDER.MIN,
    betweenWords: LETTER_AND_WORD_SPACING_SLIDER.MIN,
    betweenLetters: LETTER_AND_WORD_SPACING_SLIDER.MIN,
  };
};

export {
  FORMAT_FONT_LIST,
  FONT_SIZE_SLIDER,
  THEME_PICKER_OPTIONS,
  LINE_SPACING_SLIDER,
  LETTER_AND_WORD_SPACING_SLIDER,
  defaultCustomization,
  FONT_ALIGN_VALUES,
};
