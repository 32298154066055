import { useFormik, intervalInHours } from '@ampli/utils';
import { useCreateInternship } from '@ampli/services';
import { useMandatoryInternshipContext } from '../routes/internship-routes';
import { useEffect } from 'react';

const reachedDailyWorkloadLimit = (weeklySchedule) =>
  Object.values(weeklySchedule).some(({ start, finish }) => {
    const difference = intervalInHours(start, finish);
    return difference > 6;
  });

const useRegisterInternship = ({
  subjectEnrollmentId,
  onError,
  onCompleted,
  schemaOptions = {},
}) => {
  const { company } = useMandatoryInternshipContext();
  const [registerInternship, { data, error, loading, schema }] =
    useCreateInternship();

  useEffect(() => {
    const errors = error?.networkError?.result?.errors || error?.graphQLErrors;
    if (errors) {
      onError(errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data) {
      onCompleted(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = async (internshipData) => {
    if (reachedDailyWorkloadLimit(internshipData.weeklySchedule)) {
      const error = new Error('Atingiu o limite diário de 6 horas', {
        cause: 'dailyOvertimeError',
      });
      return onError(error);
    }

    await registerInternship({
      variables: {
        subjectEnrollmentId,
        companyData: company,
        ...internshipData,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      companyResponsible: { name: '', role: '' },
      fieldSupervisor: { name: '', academicDegree: '' },
      startDate: '',
      endDate: '',
      weeklySchedule: {
        mon: { finish: '', start: '' },
        tue: { finish: '', start: '' },
        wed: { finish: '', start: '' },
        thu: { finish: '', start: '' },
        fri: { finish: '', start: '' },
        sat: { finish: '', start: '' },
        sun: { finish: '', start: '' },
      },
    },
    validationSchema: schema(schemaOptions),
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    initialErrors: false,
    onSubmit: handleSubmit,
  });

  return { formik, loading };
};

export default useRegisterInternship;
