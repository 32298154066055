import React from 'react';

import { generatePath, Link, useHistory } from 'react-router-dom';
import { useBreakpoint } from '@ampli/hooks';
import { array, func, object } from '@ampli/utils';
import { useTheme } from '@emotion/react';

import {
  useModal,
  Breadcrumbs,
  Header,
  Typography,
  SvgArrowRight,
  SvgComputerWoman,
  Banner,
} from '@sofia/ui';

import { TipsForYouWrapper, TipsTextWrapper, FlexGrid } from './grade-styles';
import { GradeSubjectResumeCard } from './index';
import { Layout, LayoutContent } from '../layout';
import { EntitiesLabels } from '../../constants';
import { useGetState } from '../../state';
import { routePath } from '../../routes';

import HowIsTheGradeCalculatedModal from './how-is-the-grade-calculated-modal';
import GradeSubjectListGrades from './grade-subject-list-grades';
import ListCorrections from './list-corrections';
import SubjectCalendarModal from '../subject/header/subject-calendar-modal/subject-calendar-modal.presentation';

const GradeSubject = ({
  subjectEnrollment,
  onShowCorrectionClick,
  lastDoneAssignments,
  ...props
}) => {
  const theme = useTheme();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const howGradeCalculatedModal = useModal();
  const calendarModal = useModal();

  const entitiesLabelsDictionary = EntitiesLabels(selectedCourseEnrollmentType);

  const isMandatoryInternship = React.useMemo(
    () => subjectEnrollment?.courseSubject.subType === 'MANDATORY_INTERNSHIP',
    [subjectEnrollment?.courseSubject.subType]
  );

  const handleSubjectClick = React.useCallback(() => {
    const pathUrl =
      routePath[isMandatoryInternship ? 'mandatoryInternshipPage' : 'subject'];

    history.push(
      generatePath(pathUrl, {
        courseType: selectedCourseEnrollmentType,
        courseVersionId: selectedCourseVersionId,
        subjectEnrollmentId: subjectEnrollment?.id,
      })
    );
  }, [
    history,
    isMandatoryInternship,
    selectedCourseEnrollmentType,
    selectedCourseVersionId,
    subjectEnrollment?.id,
  ]);

  const breadcrumbsComponent = (
    <Breadcrumbs
      secondColor={theme.colors.breadCrumbs.secondary}
      firstColor={theme.colors.breadCrumbs.primary}
      fill={theme.colors.breadCrumbs.primary}
      stroke={theme.colors.breadCrumbs.primary}
      shadow={false}
      backClick={() => history.goBack()}
    >
      <Link
        to={generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })}
      >
        <span>Início</span>
      </Link>
      <Link
        to={generatePath(routePath.grade, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })}
      >
        <span>Notas e correções</span>
      </Link>
      <p>{entitiesLabelsDictionary.subjectGradeTitle}</p>
    </Breadcrumbs>
  );

  return (
    <Layout {...props}>
      <Banner background={theme.colors.banner.primary}>
        <LayoutContent>
          <Header
            description=""
            title=""
            titleColor={theme.colors.text.secondary}
            descriptionColor={theme.colors.text.light}
            background="none"
            breadCrumbs={breadcrumbsComponent}
            padding="0px"
            lastChildren
          />
        </LayoutContent>
      </Banner>

      <LayoutContent>
        <GradeSubjectResumeCard
          subjectEnrollment={subjectEnrollment}
          onHowIsTheGradeCalculatedClick={howGradeCalculatedModal.show}
          onSubjectEnrollmentPeriodClick={calendarModal.show}
        />

        <GradeSubjectListGrades
          isMandatoryInternship={isMandatoryInternship}
          subjectEnrollment={subjectEnrollment}
          handleSubjectClick={handleSubjectClick}
        />

        <div>
          <div>
            <Typography
              lineHeight="27.6px"
              margin="80px 0 16px 0"
              weight={700}
              size={isDesktop ? '32' : '24'}
            >
              Últimas correções
            </Typography>
          </div>
          <FlexGrid>
            <ListCorrections
              corrections={lastDoneAssignments}
              onShowCorrectionClick={onShowCorrectionClick}
            />
          </FlexGrid>
        </div>
      </LayoutContent>

      {!isMandatoryInternship ? (
        <LayoutContent>
          <TipsForYouWrapper>
            <div>
              <SvgComputerWoman />
            </div>
            <TipsTextWrapper>
              <Typography
                margin="0 0 16px 0"
                size={16}
                weight={700}
                color={theme.colors.text.lightBlack}
              >
                Dica pra você
              </Typography>
              <Typography
                margin="0 0 16px 0"
                size={32}
                weight={700}
                color={theme.colors.text.black}
              >
                Exercícios de unidade ajudam a complementar sua pontuação final.
              </Typography>
              <Typography
                color={theme.colors.text.anchor}
                as="a"
                margin="43px 10px 16px 0"
                size={16}
                weight={700}
                onClick={handleSubjectClick}
              >
                Ir para os exercícios de unidade
              </Typography>
              <SvgArrowRight fill={theme.colors.text.anchor} />
            </TipsTextWrapper>
          </TipsForYouWrapper>
        </LayoutContent>
      ) : null}

      <HowIsTheGradeCalculatedModal
        modal={howGradeCalculatedModal}
        gradingMethod={subjectEnrollment?.gradingMethod}
      />

      <SubjectCalendarModal
        isDesktop={isDesktop}
        modal={calendarModal}
        startEndInterval={subjectEnrollment.startEndInterval}
        examStartEndInterval={subjectEnrollment.examStartEndInterval}
      />
    </Layout>
  );
};

GradeSubject.propTypes = {
  subjectEnrollment: object.isRequired,
  onShowCorrectionClick: func.isRequired,
  lastDoneAssignments: array.isRequired,
};

export default GradeSubject;
