import React from 'react';
import { string, number, func } from 'prop-types';
import { useTheme } from '@emotion/react';
import { Typography, Card, ProgressBar } from '@sofia/ui';
import { Wrapper, FlexWrapper } from './grade-styles';

const GradeSubjectListPunctuationCard = ({
  title,
  points,
  grade,
  maxGrade,
  progress,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <Card
      minWidthMobile="auto"
      onClick={onClick}
      padding="32px"
      minHeight="0px"
      style={{ height: '100%' }}
    >
      <Wrapper>
        <Typography
          as="span"
          weight={700}
          color={theme.colors.text.lightBlack}
          size={24}
        >
          {title}
        </Typography>

        <div>
          <Typography
            weight={700}
            color={theme.colors.text.black}
            size={32}
            margin="56px 0 24px 0"
          >
            {points} pontos
          </Typography>
          <ProgressBar
            progressBackground={{ background: theme.colors.background.info }}
            progressBar={{ background: theme.colors.card.border.secondary }}
            progress={progress}
          />
          <FlexWrapper>
            <Typography
              weight={700}
              color={theme.colors.text.lightBlack}
              size={16}
              margin="16px 0 0 0"
            >
              {grade} pontos
            </Typography>
            <Typography
              weight={700}
              color={theme.colors.text.lightBlack}
              size={16}
              margin="16px 0 0 0"
            >
              {maxGrade} pontos
            </Typography>
          </FlexWrapper>
        </div>
      </Wrapper>
    </Card>
  );
};

GradeSubjectListPunctuationCard.defaultProps = {
  grade: 0,
};

GradeSubjectListPunctuationCard.propTypes = {
  title: string.isRequired,
  points: number.isRequired,
  grade: number,
  maxGrade: number.isRequired,
  progress: number.isRequired,
  onClick: func.isRequired,
};

export default GradeSubjectListPunctuationCard;
