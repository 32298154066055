import React from 'react';

import {
  ButtonPrimaryLong,
  ButtonOutlineLong,
  convertPXToVH,
  convertPXToVW,
  Typography,
} from '@sofia/ui';

import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@emotion/react';

import { PageConversationInfo } from '../../types';
import { useDynamicsLogic } from '../../hooks/use-dynamics-logic';
import { usePtkFrame } from '../../contexts';
import { useFrame } from '../../../../shared';
import { Flex } from './conversation-chat-buttons.styles';

const ConversationChatButtons: React.FC = () => {
  const theme = useTheme();

  const { conversation, queryKeys, dispatch, page } = usePtkFrame();
  const { isCourseEnrollmentBlocked } = useFrame();
  const { closeConversation } = useDynamicsLogic();

  const queryClient = useQueryClient();

  const isHistoricPage = page === 'HISTORIC';

  const closeConversationMutation = useMutation(closeConversation, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.home);
      queryClient.invalidateQueries(queryKeys.chat);
      queryClient
        .invalidateQueries(queryKeys.historic)
        .then(() => {
          if (isHistoricPage) {
            const cachedList = queryClient.getQueryData<PageConversationInfo>(
              queryKeys.historic
            );

            dispatch({ conversation: cachedList?.content?.[0] });
          }

          return null;
        })
        .catch((err) => console.error(err));

      if (!isHistoricPage) dispatch({ page: 'RATING' });
    },
  });

  return !isCourseEnrollmentBlocked ? (
    <Flex isHistoricPage={isHistoricPage}>
      <ButtonPrimaryLong
        type="button"
        background={theme.colors.button.primary}
        padding={`${convertPXToVH(16)} ${convertPXToVW(16)}`}
        onClick={() =>
          !isHistoricPage
            ? dispatch({ page: 'FORM' })
            : dispatch({ isRespondingByHistory: true, backTo: 'HISTORIC' })
        }
        margin={`0px 0px ${convertPXToVH(24)}`}
        color={theme.colors.text.white}
        size={16}
      >
        Responder
      </ButtonPrimaryLong>

      <ButtonOutlineLong
        type="button"
        borderBoxColor={theme.colors.button.primary}
        onClick={() => closeConversationMutation.mutateAsync(conversation?.id)}
        color={theme.colors.button.primary}
        padding={`${convertPXToVH(16)} ${convertPXToVW(16)}`}
        width="100%"
        size={16}
      >
        <Typography space="nowrap">Finalizar conversa</Typography>
      </ButtonOutlineLong>
    </Flex>
  ) : null;
};

export default ConversationChatButtons;
