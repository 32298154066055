import React from 'react';

import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

const queryClient = new QueryClient();

export const withQueryClient = (
  children: React.ReactNode
): EmotionJSX.Element => (
  <QueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools />
  </QueryClientProvider>
);
