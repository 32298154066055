import { useTheme } from '@emotion/react';

const useGetSubjectCalendarTourStyles = () => {
  const theme = useTheme();

  return {
    tooltip: {
      borderRadius: 8,
      padding: 22,
    },
    tooltipTitle: {
      paddingLeft: 20,
      paddingRight: 10,
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: 20,
      color: theme.colors.text.black,
    },
    tooltipContainer: {
      textAlign: 'left',
    },
    tooltipContent: {
      paddingLeft: 20,
      fontFamily: 'Roboto',
      fontSize: 16,
      color: theme.colors.text.black,
    },
    buttonNext: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: 16,
      width: '100%',
      borderRadius: 8,
      backgroundColor: 'transparent',
      color: theme.colors.text.primary,
    },
  };
};

export default useGetSubjectCalendarTourStyles;
