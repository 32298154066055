import React from 'react';

import { arrayOf, bool, object, string, sumBy } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import { Tabs } from '@sofia/ui';

import { EntitiesLabels } from '../../../constants';
import { LayoutContent } from '../../layout';
import { useGetState } from '../../../state';

import SubjectPresentationHeader from './header/subject-presentation-header';
import SubjectPresentationPanel from './content/subject-presentation-panel';
import SubjectPresentationTabs from './tabs/subject-presentation-tabs';

import * as Styled from './subject-presentation-styles';

const SubjectPresentation = ({
  subject,
  learningUnits,
  selectedTab,
  noRoute,
  hideStartButton,
  hideTeacherTab,
  isElective,
  isElectiveSelected,
  teacher,
  studentId,
}) => {
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();
  const entitiesLabelsDictionary = EntitiesLabels(selectedCourseEnrollmentType);

  const breakpoint = useBreakpoint();
  const isDesktop = !noRoute && breakpoint === 'desktop';

  const firstLearningUnit = learningUnits?.[0] ? learningUnits[0] : {};
  const firstSection = firstLearningUnit
    ? firstLearningUnit?.sections?.[0]
    : {};

  return (
    <>
      {!noRoute ? (
        <SubjectPresentationHeader
          subject={subject}
          sectionCount={
            learningUnits.length
              ? sumBy(
                  learningUnits,
                  (learningUnit) => learningUnit?.sections.length,
                  0
                )
              : null
          }
          unitCount={learningUnits.length || null}
          selectedCourseEnrollmentType={selectedCourseEnrollmentType}
          selectedCourseVersionId={selectedCourseVersionId}
          entitiesLabelsDictionary={entitiesLabelsDictionary}
          isElective={isElective}
          isElectiveSelected={isElectiveSelected}
          studentId={studentId}
        />
      ) : null}
      <Styled.LayoutWrapper isDesktop={isDesktop}>
        <LayoutContent>
          <Tabs
            defaultSelectedTabId={'plan' || selectedTab}
            tabs={[
              ...SubjectPresentationTabs({
                hideTeacherTab: hideTeacherTab,
              }),
            ]}
            tabPanels={[
              ...SubjectPresentationPanel({
                subject: subject,
                teacher: teacher,
                dictionary: entitiesLabelsDictionary,
                learningUnitId: firstLearningUnit?.id,
                pathUrl: firstSection?.pathUrl,
                hideStartButton: hideStartButton,
                hideTeacherTab: hideTeacherTab,
                learningUnits: learningUnits,
              }),
            ]}
            tabListProps={{
              'aria-label': entitiesLabelsDictionary.subjectPresentation,
            }}
          />
        </LayoutContent>
      </Styled.LayoutWrapper>
    </>
  );
};

SubjectPresentation.defaultProps = {
  selectedTab: 'objetivos',
  noRoute: false,
  hideStartButton: false,
  hideTeacherTab: false,
  isElective: false,
  learningUnits: [],
};

SubjectPresentation.propTypes = {
  subject: object.isRequired,
  learningUnits: arrayOf(object),
  selectedTab: string,
  noRoute: bool,
  hideStartButton: bool,
  hideTeacherTab: bool,
  teacher: object,
  isElective: bool,
  studentId: string,
  isElectiveSelected: bool,
};

export default SubjectPresentation;
