import { trim, toLower, fromPairs, map } from 'lodash';
let headers;
const CRLF_REGEX = /[\r\n]+/;
const parseRawHeaders = raw => {
  const lines = trim(raw).split(CRLF_REGEX);
  return fromPairs(map(lines, line => {
    const parts = line.split(':');
    const key = parts.shift();
    const value = parts.join(':');
    return [toLower(trim(key)), trim(value)];
  }));
};
export const getAllHeaders = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    location = '/'
  } = options;
  if (headers) return Promise.resolve(headers);
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', location, true);
    xhr.send(null);
    xhr.addEventListener('load', () => {
      headers = parseRawHeaders(xhr.getAllResponseHeaders());
      resolve(headers);
    });
    xhr.addEventListener('error', reject);
    xhr.addEventListener('abort', reject);
  });
};
export const getHeader = (name, options) => getAllHeaders(options).then(headers => headers[toLower(name)]);