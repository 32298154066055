export const selectInputDateValue = (reachMonth, reachYear) => {
  const month = [...Array(reachMonth).keys()].map(i => ({
    value: i + 1,
    label: new Number(i + 1).toString()
  }));
  const currentYear = new Date().getFullYear();
  const year = [...Array(reachYear).keys()].map(i => ({
    value: i + currentYear,
    label: new Number(i + currentYear).toString()
  }));
  return {
    month,
    year
  };
};