import { gql } from 'graphql.macro';

export const GET_SECOND_CALL_INFO = gql`
  query GetSecondCallInfo($subjectEnrollmentId: ID!) {
    agreedSecondCall(subjectEnrollmentId: $subjectEnrollmentId)
    secondCallInformation(subjectEnrollmentId: $subjectEnrollmentId) {
      examEndDate
      examStartDate
      sanitizedValue
      info
    }
  }
`;

export const MUTATION_ACCEPT_SECOND_CALL = gql`
  mutation AcceptSecondCall($subjectEnrollmentId: ID!) {
    acceptSecondCall(subjectEnrollmentId: $subjectEnrollmentId)
  }
`;
