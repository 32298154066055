import {
  useFindLearningUnitEnrollments,
  useFindStudentCourseEnrollmentsWithFreeTrialInfo,
  useServiceClient,
} from '@ampli/services';

import { generatePath, useHistory, useParams } from 'react-router-dom';

import { filterFreeTrialDataFromByEnrollmentId } from '../utils';
import { useGetState } from '../state';
import { routePath } from '../routes';

import logout from '../lib/logout';
import React from 'react';
import { useMeContext } from '../modules/shared/contexts/me-context';
import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';

const useSubjectPageData = () => {
  const history = useHistory();
  const client = useServiceClient('privateClient');

  const { subjectEnrollmentId } = useParams();
  const { states: statesMe } = useMeContext();
  const { me } = statesMe.getMe;

  const [firstName, setFirstName] = React.useState();

  const [
    {
      selectedCourseEnrollmentId,
      selectedCourseEnrollmentType,
      selectedCourseVersionId,
    },
  ] = useGetState();

  const { states } = useCourseEnrollmentsContext();
  const {
    data: courseEnrollment,
    loading: courseEnrollmentLoading,
    refetch: refetchCourseEnrollments,
  } = states.courseEnrollments;

  const subjectEnrollment = courseEnrollment.subjectEnrollments?.find(
    (subject) => subject.id === subjectEnrollmentId
  );

  const { data: learningUnitEnrollments, loading: learningUnitsLoading } =
    useFindLearningUnitEnrollments(subjectEnrollment?.id);

  const { data: studentFreeTrialData, loading: studentFreeTrialDataLoading } =
    useFindStudentCourseEnrollmentsWithFreeTrialInfo(
      courseEnrollment.studentId
    );

  const studentFreeTrialCourseData = React.useMemo(
    () =>
      filterFreeTrialDataFromByEnrollmentId(
        studentFreeTrialData?.data,
        selectedCourseEnrollmentId
      ),
    [selectedCourseEnrollmentId, studentFreeTrialData?.data]
  );

  const loading = React.useMemo(
    () =>
      courseEnrollmentLoading ||
      learningUnitsLoading ||
      studentFreeTrialDataLoading,
    [courseEnrollmentLoading, learningUnitsLoading, studentFreeTrialDataLoading]
  );

  const handleLogout = React.useCallback(
    () => logout({ client, history }),
    [client, history]
  );

  const handleLogo = React.useCallback(
    () =>
      history.push(
        generatePath(routePath.home, {
          courseType: selectedCourseEnrollmentType,
          courseVersionId: selectedCourseVersionId,
        })
      ),
    [history, selectedCourseEnrollmentType, selectedCourseVersionId]
  );

  React.useEffect(() => {
    setFirstName(me.firstName);
  }, [me.firstName]);

  //TODO: Rafatorar isso pra chamar nos finais dos fluxos de provas
  //useEffect para garantir que as infos sempre estarão atualizadas
  React.useEffect(() => {
    refetchCourseEnrollments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    firstName,
    courseId: courseEnrollment?.course?.id,
    subjectEnrollment,
    learningUnitEnrollments,
    studentFreeTrialCourseData,
    handleLogout,
    handleLogo,
    loading,
  };
};

export default useSubjectPageData;
