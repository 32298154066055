import {
  unavailableFreeTrialStatus,
  courseTypeLabelByDegree,
  courseCardImageByDegree,
  freeTrialEnabledRole,
  EntitiesLabels,
  coursesTypes,
} from '../../../constants';

import { convertPXToREM, convertPXToVH, Typography, Card } from '@sofia/ui';
import { array, func, node, number, string } from 'prop-types';
import { pluralizeString } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import { ArrowRight } from '@ampli/icon';
import { includes } from 'lodash';
import { useTheme } from '@emotion/react';

import React from 'react';

import * as Styled from './styles';

const CourseCard = React.forwardRef(
  (
    {
      numberOfCompletedSubjects,
      freeTrialSituation,
      freeTrialDaysLeft,
      numberOfSubjects,
      statusLabel,
      courseName,
      onLoadCard,
      courseType,
      degree,
      roles,
      index,
      ...props
    },
    ref
  ) => {
    const entitiesLabelsDictionary = EntitiesLabels(courseType);
    const theme = useTheme();

    const { isDesktop } = useBreakpoint(true);

    const courseTypeId = `${courseType.replace(/ /g, '-')}-${index}`;

    const makeId = (prefix, sufix) =>
      `${prefix}-${courseTypeId}${sufix ? `-${sufix}` : ''}`;

    const label =
      courseTypeLabelByDegree[courseType === 'tecnico' ? 'TECHNICAL' : degree];

    const showDefaultImageDegree =
      !coursesTypes.includes(degree) || courseType === 'tecnico';

    const image = {
      src: courseCardImageByDegree[showDefaultImageDegree ? 'DEFAULT' : degree],
      alt: `${courseTypeLabelByDegree[degree]} image`,
      id: makeId('course-img'),
    };

    const isFreeTrialEnabled = React.useMemo(
      () =>
        freeTrialSituation &&
        !includes(unavailableFreeTrialStatus, freeTrialSituation) &&
        includes(roles, freeTrialEnabledRole),
      [freeTrialSituation, roles]
    );

    const freeTrialDaysText = React.useMemo(
      () =>
        `Periodo grátis ${
          freeTrialSituation === 'NOT_STARTED'
            ? ''
            : `: ${freeTrialDaysLeft}` +
              ' ' +
              pluralizeString({
                value: freeTrialDaysLeft || 0,
                word: 'dia',
              })
        }`,
      [freeTrialDaysLeft, freeTrialSituation]
    );

    const freeTrialLabelText = React.useMemo(
      () =>
        freeTrialSituation === 'EXPIRED'
          ? 'Fim do período grátis'
          : freeTrialDaysText,
      [freeTrialDaysText, freeTrialSituation]
    );

    React.useEffect(() => {
      onLoadCard();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Card
        id={makeId('card-enter-course')}
        data-testid={makeId('card-enter-course-id')}
        className={'card-enter-course'}
        ref={ref}
        borderColor={theme.colors.card.border.primary}
        hover={theme.colors.card.color.hover}
        minHeight={convertPXToVH(256)}
        tabIndex={0}
        role="link"
        {...props}
      >
        <Styled.FlexBox>
          <Styled.Img
            id={image.id}
            data-testid={image.id}
            src={image.src}
            alt={image.alt}
          />

          <Styled.PaddingBox>
            <Styled.Wrapper padding="0px">
              <Typography
                size={16}
                weight="700"
                color={theme.colors.text.lightBlack}
                id={label}
                data-testid={label}
              >
                {label}
              </Typography>

              <Typography
                id={makeId('course-title')}
                data-testid={makeId('course-title')}
                size={isDesktop ? 32 : 20}
                weight="700"
                color={theme.colors.text.black}
              >
                {courseName}
              </Typography>

              {isFreeTrialEnabled ? (
                <Styled.Tag
                  id={makeId('course-tag', '0')}
                  data-testid={makeId('course-tag-id', '0')}
                >
                  {freeTrialLabelText}
                </Styled.Tag>
              ) : null}

              {statusLabel && !isFreeTrialEnabled ? (
                <Styled.Tag
                  id={makeId('course-tag-id', '0')}
                  data-testid={makeId('course-tag', '0')}
                >
                  {statusLabel}
                </Styled.Tag>
              ) : null}

              <Typography
                id={makeId('number-completed-subjects')}
                data-testid={makeId('number-completed-subjects')}
                size={16}
                color={theme.colors.text.lightBlack}
                lineHeight={3}
              >
                {entitiesLabelsDictionary.finishedSubjects(
                  numberOfCompletedSubjects,
                  numberOfSubjects
                )}
              </Typography>
            </Styled.Wrapper>

            <Styled.FlexWrapper>
              <Styled.FlexWrapper
                alignItems="center"
                id={makeId('enter-course')}
                data-testid={makeId('enter-course')}
              >
                <Typography
                  margin={`0px ${convertPXToREM(16)} 0px 0px`}
                  color={theme.colors.button.primary}
                  size={16}
                  weight={700}
                  id={makeId('txt-enter-course')}
                  data-testid={makeId('txt-enter-course')}
                >
                  Acessar
                </Typography>
                <ArrowRight color={theme.colors.button.primary} />
              </Styled.FlexWrapper>
            </Styled.FlexWrapper>
          </Styled.PaddingBox>
        </Styled.FlexBox>
      </Card>
    );
  }
);

CourseCard.propTypes = {
  className: string,
  courseName: string.isRequired,
  courseType: string,
  degree: string.isRequired,
  statusLabel: node,
  courseSummary: string,
  onClick: func,
  onLoadCard: func,
  numberOfSubjects: string.isRequired,
  numberOfCompletedSubjects: string.isRequired,
  freeTrialDaysLeft: number,
  freeTrialSituation: string,
  roles: array,
  index: number,
};

CourseCard.displayName = 'CourseCard';

export default CourseCard;
