import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { GuardedRoute } from 'react-router-guards';
import { useServiceClient } from '@ampli/services';

import { SubjectPage, SubjectPresentationPage } from './lazy-routes';
import { useGetState } from '../state';
import { contentGuard } from '../guards';
import { useQueryParams } from '../hooks';
import routePath from './routes-path';
import { SectionModule } from '../modules/section';
import { PracticalActivitiesModule } from '../modules/practical-activities';
import { useCourseEnrollmentsContext } from '../modules/shared/contexts/course-enrollments-context';

import { object } from 'prop-types';
import { SubjectLiveModule } from '../modules/live';

const ModuleWrapper = ({ authMeta }) => (
  <>
    <SectionModule routesSettings={{ guardedRouteMeta: authMeta }} />
    <SubjectLiveModule routesSettings={{ guardedRouteMeta: authMeta }} />
    <PracticalActivitiesModule
      routesSettings={{ guardedRouteMeta: authMeta }}
    />
  </>
);

ModuleWrapper.propTypes = {
  authMeta: object,
};

const Routes = () => {
  const { states } = useCourseEnrollmentsContext();
  const response = states.courseEnrollments;

  const handleGuardedRoute = async (to, from, next) => {
    await contentGuard(to, from, next, response);
  };

  const clients = useServiceClient('all');

  const queryParams = useQueryParams();

  const [state, actions] = useGetState();

  const meta = { clients, actions, state, queryParams };
  const authMeta = { ...meta, auth: { redirect: routePath.login } };

  return (
    <Switch>
      <GuardedRoute
        path={routePath.subject}
        exact
        meta={authMeta}
        guards={[handleGuardedRoute]}
      >
        <SubjectPage />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={routePath.subjectPresentation}
        meta={authMeta}
        guards={[handleGuardedRoute]}
      >
        <SubjectPresentationPage />
      </GuardedRoute>

      <ModuleWrapper authMeta={authMeta} />

      <Redirect
        exact
        from="/:courseType/:courseVersionId/disciplina/:subjectPath/apresentacao"
        to="/:courseType/:courseVersionId/disciplina/:subjectPath/apresentacao/objetivos"
      />
    </Switch>
  );
};

export default Routes;
