import React from 'react';

import { Typography } from '@sofia/ui';

import {
  emptyStudentConversationMock,
  emptyTutorConversationMock,
} from './historic-section.constants';

import { FilterType } from './components';

import * as Styled from './historic-section.styles';

interface HistoricSectionEmptyStateProps {
  activeFilterTab: FilterType;
}

const HistoricSectionEmptyState: React.FC<HistoricSectionEmptyStateProps> = ({
  activeFilterTab,
}: HistoricSectionEmptyStateProps) => (
  <Styled.ConversationView>
    <Styled.Flex align="center" justify="center" direction="column">
      <Styled.EmptyIllustration />

      <Typography
        textAlign="center"
        size={24}
        weight={700}
        margin="16px 0px 8px"
      >
        {activeFilterTab === 'STUDENT'
          ? emptyStudentConversationMock.class
          : emptyTutorConversationMock.class}
      </Typography>

      <Styled.Flex maxWidth="620px">
        <Typography textAlign="center">
          {activeFilterTab === 'STUDENT'
            ? emptyStudentConversationMock.preview
            : emptyTutorConversationMock.preview}
        </Typography>
      </Styled.Flex>
    </Styled.Flex>
  </Styled.ConversationView>
);

export default HistoricSectionEmptyState;
