import React from 'react';
import {
  object,
  shape,
  arrayOf,
  func,
  string,
  number,
  boolean,
} from 'prop-types';
import { Box, Grid, GridItem } from '@ampli/ui';
import { Terms } from '../../modules';
import { useModal } from '@sofia/ui';
import CourseByDegreeCard from './courses-by-degree-card';

import * as styles from './styles';

const CoursesByDegree = ({
  courseEnrollments,
  gridCarouselProps = {},
  onCourseEnrollmentClick,
  studentFreeTrialData,
  hasPendingContract,
  ...props
}) => {
  const handleModal = useModal();

  return (
    <>
      <Box {...props} className={styles.coursesByDegree}>
        <Grid columns={'span 1'} {...gridCarouselProps}>
          <>
            {courseEnrollments.map((props, index) => (
              <GridItem key={index}>
                <CourseByDegreeCard
                  key={index}
                  index={index}
                  {...props}
                  studentFreeTrialData={studentFreeTrialData}
                  onCourseEnrollmentClick={onCourseEnrollmentClick}
                  handleModal={handleModal}
                  hasPendingContract={hasPendingContract}
                />
              </GridItem>
            ))}
          </>
        </Grid>
      </Box>
      <Terms.Modal handleModal={handleModal} />
    </>
  );
};

CoursesByDegree.propTypes = {
  degree: string.isRequired,
  courseEnrollments: arrayOf(
    shape({
      id: string.isRequired,
      course: shape({
        name: string.isRequired,
        courseType: shape({
          degree: string.isRequired,
        }).isRequired,
      }).isRequired,
      numberOfSubjects: number,
      numberOfCompletedSubjects: number,
    }).isRequired
  ).isRequired,
  onCourseEnrollmentClick: func.isRequired,
  gridCarouselProps: object,
  studentFreeTrialData: object,
  hasPendingContract: boolean,
};

export default CoursesByDegree;
