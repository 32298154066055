import styled from '@emotion/styled';

export const Header = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #dee2e6;
`;

export const IconBtn = styled.div`
  background-color: #dbe3ec;
  border-radius: 50%;
  padding: 14px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    color: #212529;
    box-sizing: content-box;
    pointer-events: none;
  }
  :hover {
    background-color: #c5d2e0;
  }
`;

export const Container = styled.div`
  border-radius: 16px;
  background-color: #fff;
  max-height: 85vh;
  overflow-y: auto;
  padding: 24px;
  ::-webkit-scrollbar {
    width: 8px;
    z-index: 2;
  }
  ::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    margin: 10px 0;
  }

  > :not(:last-child) {
    margin-bottom: 32px;
  }
`;
