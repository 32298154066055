import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  gap: 4px;
  align-items: center;
`;

export const Counter = styled.div`
  display: flex;
`;
