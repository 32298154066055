import styled from '@emotion/styled';
export const Wrapper = styled.div`
  ${_ref => {
  let {
    fillBox
  } = _ref;
  return fillBox && `width: 100%;`;
}}

  ${_ref2 => {
  let {
    error
  } = _ref2;
  return error && `
    input {
      border-color: #bc3206;
    }
    &  :last-child {
      color:#bc3206;
    }
   `;
}}
  ${_ref3 => {
  let {
    disabled
  } = _ref3;
  return disabled && `
   input {
    border-color: #868e96;
    color: #868e96;
    background: #e5e5e5;
   }
   p {
    color: #868e96;
   }
  `;
}}
`;
export const StyledInput = styled.input`
  background: #ffffff;
  border: 2px solid ${_ref4 => {
  let {
    borderColor
  } = _ref4;
  return borderColor;
}};
  padding: 19px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  outline: 0;
  text-align: ${_ref5 => {
  let {
    alignText
  } = _ref5;
  return alignText;
}};

  ${_ref6 => {
  let {
    hasLeftIcon
  } = _ref6;
  return hasLeftIcon && `padding-left: 56px;`;
}}

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${_ref7 => {
  let {
    placeholderColor
  } = _ref7;
  return placeholderColor;
}};
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${_ref8 => {
  let {
    placeholderColor
  } = _ref8;
  return placeholderColor;
}};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${_ref9 => {
  let {
    placeholderColor
  } = _ref9;
  return placeholderColor;
}};
  }

  &: focus {
    border-color: ${_ref10 => {
  let {
    focusColor
  } = _ref10;
  return focusColor;
}};
  }

  &: hover {
    border-color: ${_ref11 => {
  let {
    hoverColor
  } = _ref11;
  return hoverColor;
}};
  }
`;
export const RightIconWrapper = styled.div`
  position: absolute;
  right: 16px;
  bottom: 19px;

  ${_ref12 => {
  let {
    onClick
  } = _ref12;
  return !!onClick && `cursor: pointer;`;
}}
  svg {
    width: ${_ref13 => {
  let {
    rightIconWidth
  } = _ref13;
  return `${rightIconWidth}px`;
}};
    height: ${_ref14 => {
  let {
    rightIconHeight
  } = _ref14;
  return `${rightIconHeight}px`;
}};
  }
`;
export const LeftIconWrapper = styled.div`
  position: absolute;
  left: 16px;
  bottom: 19px;

  ${_ref15 => {
  let {
    onClick
  } = _ref15;
  return !!onClick && `cursor: pointer;`;
}}

  svg {
    width: 24px;
    height: 24px;
  }
`;
export const RelativeWrapper = styled.div`
  position: relative;
`;