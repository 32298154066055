import React from 'react';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '@ampli/hooks';
import { func, shape, string } from 'prop-types';
import { ButtonGhost, ButtonPrimary, PopUp, Typography } from '@sofia/ui';
import * as Styled from './mandatory-internship-info-form-messages.styles';

const MandatoryInternshipInfoFormMessages = ({
  cancelModal,
  onCancel,
  successModal,
  onSuccess,
  workloadErrorModal,
  periodErrorModal,
  overtimeErrorModal,
  overtimeErrorText,
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <>
      <PopUp
        handlePopUp={cancelModal}
        footer={
          <Styled.ButtonsWrapper>
            <ButtonPrimary
              color={theme.colors.text.white}
              background={theme.colors.button.primary}
              aria-label="Continuar cadastro dos dados de estágio"
              width="100%"
              onClick={cancelModal.hide}
            >
              Continuar
            </ButtonPrimary>
            <ButtonGhost
              hoverColor={theme.colors.button.darkPrimary}
              color={theme.colors.button.primary}
              aria-label="Cancelar cadastro dos dados de estágio"
              onClick={onCancel}
            >
              Sair do cadastro
            </ButtonGhost>
          </Styled.ButtonsWrapper>
        }
        isBottomSheet={!isDesktop}
        header={{
          headerText:
            'Você tem certeza que quer cancelar seu cadastro do estágio?',
        }}
        ariaLabel="Modal de confirmação de cancelamento de cadastro de estágio"
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          Você terá que iniciar o fluxo novamente da próxima vez.
        </Typography>
      </PopUp>

      <PopUp
        hideCloseIcon
        handlePopUp={successModal}
        footer={{
          buttonText: 'Entendi',
          background: theme.colors.button.primary,
          buttonClick: () => {
            successModal.hide();
            onSuccess();
          },
        }}
        isBottomSheet={!isDesktop}
        header={{
          headerText: 'Sua solicitação foi enviada',
        }}
        shouldCloseOnClickOutside={false}
        shouldCloseOnEsc={false}
        ariaLabel="Modal informando que seu cadastro foi enviado com sucesso"
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          Iremos analisar os dados inseridos e se estiver tudo certo, você
          poderá baixar seu termo de estágio. Caso seja reprovado entraremos em
          contato no seu e-mail.
        </Typography>
      </PopUp>

      <PopUp
        handlePopUp={workloadErrorModal}
        isBottomSheet={!isDesktop}
        footer={{
          buttonText: 'Entendi',
          background: theme.colors.button.primary,
          buttonClick: workloadErrorModal.hide,
        }}
        header={{
          headerText:
            'Carga horária insuficiente para o cumprimento do estágio',
        }}
        ariaLabel="Modal informando carga horária insuficiente para o cumprimento do estágio"
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          A carga horária semanal que você colocou, não será suficiente para
          cumprir a carga horária do estágio durante o período escolhido. Revise
          a data de inicio e fim do seu estágio ou os horários em que você irá
          estagiar durante a semana.
        </Typography>
      </PopUp>

      <PopUp
        handlePopUp={periodErrorModal}
        isBottomSheet={!isDesktop}
        footer={{
          buttonText: 'Entendi',
          background: theme.colors.button.primary,
          buttonClick: periodErrorModal.hide,
        }}
        header={{
          headerText: 'O período do seu estágio não é válido',
        }}
        ariaLabel="Modal informando que o período do estágio não é válido"
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          Verifique a data de início e fim do estágio e tente novamente.
        </Typography>
      </PopUp>

      <PopUp
        handlePopUp={overtimeErrorModal}
        isBottomSheet={!isDesktop}
        footer={{
          buttonText: 'Entendi',
          background: theme.colors.button.primary,
          buttonClick: overtimeErrorModal.hide,
        }}
        header={{
          headerText: 'Carga horária passou do limite permitido',
        }}
        ariaLabel="Modal informando que a carga horária foi ultrapassada"
      >
        <Typography size={16} color={theme.colors.text.lightBlack}>
          {overtimeErrorText}
        </Typography>
      </PopUp>
    </>
  );
};

MandatoryInternshipInfoFormMessages.propTypes = {
  cancelModal: shape({
    show: func,
    hide: func,
  }),
  successModal: shape({
    show: func,
    hide: func,
  }),
  workloadErrorModal: shape({
    show: func,
    hide: func,
  }),
  periodErrorModal: shape({
    show: func,
    hide: func,
  }),
  overtimeErrorModal: shape({
    show: func,
    hide: func,
  }),
  overtimeErrorText: string,
  onCancel: func.isRequired,
  onSuccess: func.isRequired,
};

export default MandatoryInternshipInfoFormMessages;
