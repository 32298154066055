import React from 'react';
import { intervalInDays, formatIsoDate, DateTime } from '@ampli/utils';
import { object, oneOfType, number, string } from 'prop-types';
import { DashedCard, SvgLock } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { generatePath, useHistory } from 'react-router-dom';
import { routePath } from '../../../../routes';
import { useGetState } from '../../../../state';
import { getTruncatedDateTime } from '../../../../utils';
import { subjectChooseCard } from '../../../../constants';
import * as Styled from './syllabus-elective-subject.styles';

const SyllabusElectiveSubject = ({ electiveSubject, id }) => {
  const history = useHistory();
  const theme = useTheme();
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const endDateForChoice = electiveSubject?.endDateForChoice
    ? electiveSubject.endDateForChoice
    : '';
  const endDate = DateTime.fromISO(endDateForChoice);
  const startDate = DateTime.fromISO(electiveSubject?.startDateForChoice);
  const endDateFormatted = formatIsoDate(endDateForChoice);
  const actualDate = getTruncatedDateTime();
  const daysRemaining = intervalInDays(actualDate, endDate);
  const getPlural = () => (daysRemaining > 1 ? 's' : '');
  const started = startDate <= actualDate && endDate >= actualDate;
  const electiveTypeChooseTitle = electiveSubject
    ? subjectChooseCard[electiveSubject.electiveType || 'OPTIONAL'].title
    : 'Módulo Optativo';

  return (
    <Styled.ElectiveWrapper
      id={`electiveWrapper${!electiveSubject && id ? `-${id}` : ''}`}
    >
      <DashedCard
        id="elective-card-internal"
        title={{
          text: electiveTypeChooseTitle,
          color: theme.colors.text.black,
        }}
        content={
          electiveSubject
            ? {
                text: 'Prazo para escolha: ',
                boldText: endDateFormatted,
              }
            : 'Em espera'
        }
        footer={
          electiveSubject && {
            text:
              daysRemaining < 1
                ? 'Até hoje'
                : `${daysRemaining} dia${getPlural()} restante${getPlural()}`,
            color:
              daysRemaining < 1
                ? theme.colors.card.color.warning
                : theme.colors.text.primary,
          }
        }
        disabled={!electiveSubject}
        cardIcon={
          electiveSubject
            ? {
                color: theme.colors.text.primary,
              }
            : {
                icon: <SvgLock fill={theme.colors.text.primary} />,
              }
        }
        onClick={() =>
          started
            ? history.push(
                generatePath(routePath.electiveSubjectOnboarding, {
                  courseType: selectedCourseEnrollmentType,
                  courseVersionId: selectedCourseVersionId,
                  subjectEnrollmentId: electiveSubject?.id,
                })
              )
            : {}
        }
      />
    </Styled.ElectiveWrapper>
  );
};

SyllabusElectiveSubject.propTypes = {
  electiveSubject: object,
  id: oneOfType([string, number]),
};

SyllabusElectiveSubject.displayName = 'SyllabusElectiveSubject';

export default SyllabusElectiveSubject;
