export enum ELiveState {
  LIVE = 'live',
  SCHEDULED = 'scheduled',
}

export interface ILive {
  __typename?: 'Live';
  id: string;
  title: string;
  teacherName: string;
  eventId: string;
  description: string;
  startDateTime: string;
  endDateTime: string;
  channelId: string;
}

export interface IGetLive {
  loading: boolean;
  state: ELiveState;
  live: ILive | null | undefined;
  isDesktop?: boolean;
}
