import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import { ButtonGhost, ButtonOutline, ButtonPrimary } from '@sofia/ui';
import { NotesObjectModalFooterProps } from '../types';
import * as Styled from './styles';

const NotesObjectModalFooter = ({
  secondaryBtn,
  primaryBtn,
  reverse,
}: NotesObjectModalFooterProps): React.ReactElement => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <Styled.MessageActionsWrapper reverse={reverse}>
      {isDesktop ? (
        <>
          <ButtonOutline {...secondaryBtn} />
          <ButtonPrimary {...primaryBtn} />
        </>
      ) : (
        <>
          <ButtonGhost {...secondaryBtn} />
          <ButtonGhost {...primaryBtn} />
        </>
      )}
    </Styled.MessageActionsWrapper>
  );
};

NotesObjectModalFooter.defaultProps = {
  reverse: false,
};

export default NotesObjectModalFooter;
