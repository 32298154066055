import React from 'react';

import {
  SvgQuestionCircle,
  convertPXToREM,
  SvgLightBulb,
  Typography,
} from '@sofia/ui';

import { PUBLICATION_CATEGORY, ICategoryTypeProps } from '../../../../../types';
import { useTheme } from '@emotion/react';

import * as Styles from './category-step.styles';

const CategoryType: React.FC<ICategoryTypeProps> = ({
  activeTab,
  setActiveTab,
}: ICategoryTypeProps) => {
  const theme = useTheme();
  const cardStyleProps = (isActive: boolean) => {
    return {
      background: isActive ? theme.colors.icon.background : null,
      borderColor: isActive
        ? theme.colors.card.border.third
        : theme.colors.card.border.primary,
      minHeight: '88px',
      borderWidth: '1px',
      padding: `${convertPXToREM(16)} ${convertPXToREM(24)} ${convertPXToREM(
        16
      )}`,
    };
  };

  return (
    <Styles.FlexWrapper>
      <Styles.CategoryCard
        isActive={activeTab === PUBLICATION_CATEGORY.DOUBT}
        {...cardStyleProps(activeTab === PUBLICATION_CATEGORY.DOUBT)}
        onClick={() => setActiveTab(PUBLICATION_CATEGORY.DOUBT)}
      >
        <Styles.Icon>
          <SvgQuestionCircle
            fill={
              activeTab === PUBLICATION_CATEGORY.DOUBT
                ? theme.colors.icon.primary
                : theme.colors.text.lightBlack
            }
          />
        </Styles.Icon>
        <Typography
          weight={activeTab === PUBLICATION_CATEGORY.DOUBT ? 600 : null}
        >
          Dúvida
        </Typography>
      </Styles.CategoryCard>
      <Styles.CategoryCard
        isActive={activeTab === PUBLICATION_CATEGORY.TIP}
        {...cardStyleProps(activeTab === PUBLICATION_CATEGORY.TIP)}
        onClick={() => setActiveTab(PUBLICATION_CATEGORY.TIP)}
      >
        <Styles.Icon>
          <SvgLightBulb
            fill={
              activeTab === PUBLICATION_CATEGORY.TIP
                ? theme.colors.icon.primary
                : theme.colors.text.lightBlack
            }
          />
        </Styles.Icon>
        <Typography
          weight={activeTab === PUBLICATION_CATEGORY.TIP ? 600 : null}
        >
          Dica
        </Typography>
      </Styles.CategoryCard>
    </Styles.FlexWrapper>
  );
};

export default CategoryType;
