import styled from '@emotion/styled';
import { TAutoGrowingTextareaStyled } from './auto-growing-textarea.types';

export const BaseTextarea = styled.textarea<TAutoGrowingTextareaStyled>`
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${({ fontFamily = 'Roboto' }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, color = theme.colors.text.black }) => color};

  padding: 0;
  border: none;
  resize: none;
  overflow: hidden;
  background: transparent;
  outline: none;
  caret-color: ${({ theme }) => theme.colors.text.primary};

  height: auto;
  max-height: 30px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.lighterBlack};
  }

  &:focus-visible {
    outline: none;
  }

  &:focus {
    border: none;
    background-color: none;
  }

  &:hover {
    background-color: none;
    border-color: none;
  }
`;
