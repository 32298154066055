import React from 'react';
import { useTheme } from '@emotion/react';
import { useBreakpoint } from '@ampli/hooks';
import { Typography, Breadcrumbs, Header } from '@sofia/ui';
import { generatePath, Link, useParams, useHistory } from 'react-router-dom';
import { routePath } from '../../../routes';
import { useGetState } from '../../../state';
import MandatoryInternshipCompanyInputForm from './form/mandatory-internship-company-input-form';
import { LayoutContent } from '../../layout';

const MandatoryInternshipCompanyInput = () => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const { subjectEnrollmentId } = useParams();
  const history = useHistory();
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();

  const routeParams = {
    courseType: selectedCourseEnrollmentType,
    courseVersionId: selectedCourseVersionId,
  };

  const onSubmit = () => {
    history.push(
      generatePath(routePath.mandatoryInternshipConfirmCompanyPage, {
        ...routeParams,
        subjectEnrollmentId: subjectEnrollmentId,
      })
    );
  };

  const breadcrumbsComponent = (
    <Breadcrumbs
      secondColor={theme.colors.breadCrumbs.secondary}
      firstColor={theme.colors.breadCrumbs.primary}
      fill={theme.colors.breadCrumbs.primary}
      stroke={theme.colors.breadCrumbs.primary}
      shadow={false}
      backClick={() => history.goBack()}
    >
      <Link to={generatePath(routePath.home, routeParams)}>
        <span>Início</span>
      </Link>
      <Link
        to={generatePath(routePath.mandatoryInternshipPage, {
          ...routeParams,
          subjectEnrollmentId: subjectEnrollmentId,
        })}
      >
        <span>Estágio obrigatório</span>
      </Link>
      <Typography as="span" weight={700}>
        Cadastrar meu estágio
      </Typography>
    </Breadcrumbs>
  );

  return (
    <>
      <Header
        titleColor={theme.colors.text.secondary}
        descriptionColor={theme.colors.text.light}
        background={theme.colors.banner.primary}
        breadCrumbs={breadcrumbsComponent}
      />
      <LayoutContent>
        <Typography
          weight={700}
          size={isDesktop ? 48 : 32}
          color={theme.colors.text.black}
        >
          Vamos vincular seu estágio
        </Typography>
        <Typography
          margin={`${isDesktop ? 56 : 40}px 0 ${isDesktop ? 56 : 24}px 0`}
          size={isDesktop ? 20 : 16}
        >
          Insira abaixo o CNPJ da empresa que você gostaria de vincular o
          estágio *
        </Typography>

        <MandatoryInternshipCompanyInputForm onSubmit={onSubmit} />
      </LayoutContent>
    </>
  );
};

MandatoryInternshipCompanyInput.propTypes = {};

export default MandatoryInternshipCompanyInput;
