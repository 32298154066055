import styled from '@emotion/styled';
import { convertPXToREM, Card } from '@sofia/ui';

export const Header = styled.div`
  margin-bottom: ${convertPXToREM(24)};
`;

export const Content = styled.div``;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const CategoryCard = styled(Card)<{ isActive: boolean }>`
  width: 100%;
  ${({ isActive, theme }) =>
    isActive
      ? `
    color: ${theme.colors.text.primary};
    border-color: ${theme.colors.card.border.third};
    background: ${theme.colors.icon.background};
 `
      : `
  border-color: ${theme.colors.card.border.primary};

  &:hover {
    border-color: ${theme.colors.card.border.third};
    background: ${theme.colors.icon.background}
  }
 `};
`;

export const CategoryHintCard = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.separator.lightColor};
  padding: ${convertPXToREM(16)};
  margin-top: ${convertPXToREM(24)};
`;

export const CategoryHintCardHeader = styled.div`
  background: ${({ theme }) => theme.colors.card.background.secondary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: ${convertPXToREM(-16)} ${convertPXToREM(-16)} ${convertPXToREM(16)};
  padding: ${convertPXToREM(16)};
`;

export const Icon = styled.div`
  margin-bottom: ${convertPXToREM(8)};
`;

export const Tag = styled.div<{ background: string }>`
  display: flex;
  gap: ${convertPXToREM(4)};
  align-items: center;
  width: fit-content;
  padding-right: ${convertPXToREM(6)};
  padding-left: ${convertPXToREM(4)};
  margin-bottom: ${convertPXToREM(8)};
  border-radius: 24px;
  background: ${({ background }) => background};
`;
