import React from 'react';
import { stripTags as _stripTags } from '@ampli/utils';
import { Text, RichText, css } from '@ampli/ui';
import { TextAssetProps } from './types';

const TextAsset = ({
  data,
  stripTags,
  nlToBr,
  childNodeStyle,
  ...props
}: TextAssetProps): React.ReactElement => {
  const content = nlToBr
    ? data.content.replace(/(\r\n|\r|\n)/g, '<br />')
    : data.content;

  return (
    <Text
      {...props}
      data-testid="text-asset"
      as="div"
      serif
      className={css`
        word-break: break-word;

        * {
          max-width: 100% !important;
          height: auto !important;
        }

        img {
          max-width: min(1920px, 100%) !important;
        }

        ${childNodeStyle}
      `}
    >
      <RichText>{_stripTags(content, stripTags)}</RichText>
    </Text>
  );
};

TextAsset.defaultProps = {
  stripTags: false,
  nlToBr: false,
};

export default TextAsset;
