import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useGetLocalExam, useFinishExam } from '@ampli/services';
import { useDuration, useSteps } from '@ampli/hooks';
import { size, compact, map, Duration } from '@ampli/utils';
import { FINISHBY_WEB } from '../constants';
import { handleExitFullscreen } from '../lib/fullscreen';
import useExamPageData from './use-exam-page-data';
import useQuestionChoices from './use-question-choices';
import useExamTimes from './use-exam-times';

const useTakeExamPageData = ({
  onFinishExamCompleted,
  onFinishExamError,
  onTimeout,
  onAlteredClockTime,
  onRetryFinishExam,
}) => {
  const history = useHistory();
  const { examId } = useParams();
  const { loading, me, ...examPageData } = useExamPageData();
  const exam = useGetLocalExam(!loading && examId);
  const [attemptsSend, setAttemptsSend] = useState(0);
  const [finishedReason, setFinishedReason] = useState();
  const [finishedBy, setFinishedBy] = useState();
  const [finishExam, finishExamMetadata] = useFinishExam({
    onCompleted: onFinishExamCompleted,
  });

  const { maxDurationInMinutes, timeRunningOutMinutes } = useExamTimes(exam);
  const questions = exam?.questions || [];
  const numberOfQuestions = questions.length || 0;
  const [questionChoices, setQuestionChoices] =
    useQuestionChoices(numberOfQuestions);

  const sendFinishExam = (_finishedBy, _finishedReason) => {
    const answers = map(questions, (question, index) => ({
      questionId: question.id,
      choiceId: questionChoices[index] || null,
    }));
    console.log('finishedBy1', _finishedBy);
    console.log('finishedReason1', _finishedReason);
    setFinishedBy(_finishedBy);
    setFinishedReason(_finishedReason);
    return finishExam({
      variables: {
        finishExamInput: {
          answers,
          examId,
          finishedBy: _finishedBy,
          finishedReason: _finishedReason,
        },
      },
    });
  };

  const handleFinishExam = (
    _finishedBy = 'UNSPECIFIED',
    _finishedReason = ''
  ) => {
    sendFinishExam(_finishedBy, _finishedReason);
  };

  const handleTimeout = () => {
    onTimeout(handleFinishExam, {
      finishedBy: FINISHBY_WEB,
      finishedReason: 'Time out',
    });
  };

  const { duration, progress } = useDuration(
    {
      progress: { precision: 1 },
      timer: { milliseconds: 500 },
      end: { minutes: maxDurationInMinutes },
      type: 'take-exam-timer',
      onAlteredClockTime,
    },
    handleTimeout
  );
  const isTimeRunningOut = duration.as('minutes') > timeRunningOutMinutes;
  const incompleteExam = size(compact(questionChoices)) < numberOfQuestions;
  const stepsControl = useSteps(numberOfQuestions);
  const durationInteger = Duration.fromObject({
    minutes: Math.floor(duration.as('minutes')),
  });
  const examTimeLeftInteger = Duration.fromObject({
    minutes: maxDurationInMinutes - durationInteger.as('minutes'),
  });

  useEffect(() => {
    if (!loading && !exam) {
      console.warn('Exam not found.');
      handleExitFullscreen();
      history.goBack();
    }
  }, [history, loading, exam]);

  useEffect(() => {
    const interval = attemptsSend <= 5 ? 1000 : 3000;
    if (!finishExamMetadata.loading && finishExamMetadata.error) {
      if (attemptsSend <= 10) {
        setTimeout(() => {
          onRetryFinishExam();
          setAttemptsSend(attemptsSend + 1);
          sendFinishExam(finishedBy, finishedReason);
        }, interval);
      } else {
        onFinishExamError && onFinishExamError();
        setAttemptsSend(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishExamMetadata.error]);

  return {
    ...examPageData,
    me,
    loading,
    exam,
    duration,
    progress,
    isTimeRunningOut,
    questionChoices,
    setQuestionChoices,
    incompleteExam,
    stepsControl,
    numberOfQuestions,
    finishExam,
    finishExamMetadata,
    handleFinishExam,
    maxDurationInMinutes,
    timeRunningOutMinutes,
    attemptsSend,
    examTimeLeftInteger,
    durationInteger,
  };
};

export default useTakeExamPageData;
