import React from 'react';

import { useTheme } from '@emotion/react';
import {
  ButtonPrimaryLong,
  convertPXToVH,
  convertPXToVW,
  SvgInfoCircle,
  Typography,
  Modal,
} from '@sofia/ui';

import { CommunicationModalProps } from './modal.types';
import { useCommunicationModal } from './modal.hook';
import { CtaIconToggler } from '../components/cta-icon-toggler';

import * as Styled from './modal.styles';

const CommunicationModal = ({
  selectExamDialog,
}: CommunicationModalProps): React.ReactElement | null => {
  const theme = useTheme();

  const { modal, handleModal, onCallToAction, category, isModalVisible } =
    useCommunicationModal({
      selectExamDialog,
    });

  const Icon = category?.icon;

  const ModalHeader = (
    <Styled.Header>
      {category ? (
        <React.Fragment>
          <Icon
            width="24px"
            height="24px"
            {...(modal?.categoryType === 'TUTOR'
              ? {
                  stroke: theme.colors.text.black,
                }
              : {
                  fill: theme.colors.text.black,
                  color: theme.colors.text.black,
                })}
          />

          <Typography size={16} weight={700} margin={`0px 0px 0px 8px`}>
            {category?.title}
          </Typography>
        </React.Fragment>
      ) : null}
    </Styled.Header>
  );

  const ModalFooter = (
    <Styled.Footer>
      {modal?.ctaText && modal.ctaLink ? (
        <ButtonPrimaryLong
          type="button"
          onClick={onCallToAction}
          background={theme.colors.button.primary}
          color="#FFFFFF"
          focusBorder="#000000"
          padding={`${convertPXToVH(13)} ${convertPXToVW(90)}`}
        >
          <Typography space="nowrap">{modal?.ctaText}</Typography>
          <CtaIconToggler ctaLink={modal.ctaLink} />
        </ButtonPrimaryLong>
      ) : null}
    </Styled.Footer>
  );

  const ModalInfoText = (
    <Styled.InfoTextContainer>
      <div>
        <SvgInfoCircle fill={theme.colors.text.primary} />
      </div>
      <Typography margin="0px 0px 0px 16px">{modal?.infoText}</Typography>
    </Styled.InfoTextContainer>
  );

  if (!isModalVisible || !handleModal.state) return null;

  return (
    <Modal
      aria-labelledby="dialog_label"
      aria-describedby="dialog_desc"
      handleModal={handleModal}
      header={ModalHeader}
      footer={ModalFooter}
      shouldCloseOnClickOutside={false}
      shouldCloseOnEsc={false}
      width={486}
      padding="0px 24px"
    >
      {!modal?.infoText && modal?.image ? (
        <Styled.Image src={modal.image} alt="" />
      ) : null}

      <Typography
        margin={!modal?.image ? '16px 0px 0px' : '0px'}
        weight={700}
        size={24}
        id="dialog_label"
      >
        {modal?.title}
      </Typography>

      <Typography id="dialog_desc" margin="16px 0px" space="pre-wrap">
        {modal?.body}
      </Typography>

      {modal?.infoText ? ModalInfoText : null}
    </Modal>
  );
};

export default CommunicationModal;
