import styled from '@emotion/styled';
import { Dialog } from '@ampli/ui';
import { mediaQuery } from '@sofia/ui';

export const DialogContainer = styled(Dialog)`
  padding: 32px;

  ${mediaQuery('mobile')} {
    section {
      height: unset !important;
    }
  }
`;

export const DialogHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DialogActionSection = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;

  .add-photo {
    padding: 16px 24px;
    border: 2px solid ${({ theme }) => theme.colors.button.primary};
  }

  .button {
    max-height: 56px;
  }
`;
