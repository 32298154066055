import React from 'react';

import { ChildrenWrapper, SideWrapper, Wrapper } from './side-screen-styles';
import { useTheme } from '@emotion/react';
import { node } from '@ampli/utils';

const SideScreen = ({ side, children }) => {
  const theme = useTheme();

  return (
    <Wrapper background={theme.colors.background.light}>
      <ChildrenWrapper>{children}</ChildrenWrapper>

      {side && <SideWrapper id="imgLogin">{side}</SideWrapper>}
    </Wrapper>
  );
};

SideScreen.propTypes = {
  side: node.isRequired,
  children: node.isRequired,
};

export default SideScreen;
