export enum CONTENT_TYPES {
  HOME = 'HOME',
  CREATE = 'CREATE',
}

export interface ContentSectionProps {
  setContent: React.Dispatch<React.SetStateAction<CONTENT_TYPES>>;
}

export interface ActionCardButtonProps {
  icon: React.FC<{ color: string; size: number }>;
  title: string;
  onClick: () => void;
  hasChevron?: boolean;
}

export interface CommunityMenuHeaderProps {
  onToggleMenuClick: () => void;
  title: string;
}
