import { injectGlobal } from '@ampli/ui';

injectGlobal`
.grecaptcha-badge { visibility: hidden; }

html,
body {
  height: 100vh;
  width: 100vw;
}

body {
  background-color: white;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 99vh;
}
`;
