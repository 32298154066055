import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import {
  Text,
  Dialog,
  DialogCloseButton,
  DialogHeader,
  DialogSection,
  DialogBackdrop,
  DialogFooter,
  Paragraph,
  FlexItem,
  OutlinedButton,
  IconButton,
} from '@ampli/ui';

import { func } from '@ampli/utils';
import { Refresh } from '@ampli/icon';
import { SearchingInCellphone } from '../../components/image';

const AlteredClockTimeAlert = ({ onCloseAlteredClockTime, ...props }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  return (
    <>
      <DialogBackdrop aria-hidden {...props} />
      <Dialog {...props} hideOnEsc={false} hideOnClickOutside={false}>
        <DialogHeader>
          <DialogCloseButton onClick={onCloseAlteredClockTime} />
          <Paragraph textAlign="center">
            <SearchingInCellphone aria-hidden />
          </Paragraph>
          <Paragraph textAlign="center">
            <Text
              id="alteredClockTimeModalId"
              as="strong"
              color="text-dark"
              size={isDesktop ? 'large' : 'medium'}
            >
              Ops, houve um problema!
            </Text>
          </Paragraph>
        </DialogHeader>
        <FlexItem flex={1}>
          <DialogSection>
            <Paragraph textAlign="center" marginSize="large">
              <Text size="small">
                Parece que o horário do seu dispositivo foi alterado, por
                segurança iremos finalizar esta tentativa.
                <br />
                <br />
                Mas não se preocupe você poderá realizar outra tentativa a
                qualquer momento.
              </Text>
            </Paragraph>
          </DialogSection>
        </FlexItem>
        <DialogFooter>
          <OutlinedButton
            as={IconButton}
            icon={<Refresh aria-hidden />}
            fluid
            size={isDesktop ? 'small' : 'micro'}
            iconSize={isDesktop ? 'large' : 'medium'}
            onClick={onCloseAlteredClockTime}
          >
            Tentar Novamente
          </OutlinedButton>
        </DialogFooter>
      </Dialog>
    </>
  );
};

AlteredClockTimeAlert.propTypes = {
  hide: func.isRequired,
  onCloseAlteredClockTime: func.isRequired,
};

export default AlteredClockTimeAlert;
