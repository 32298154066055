import styled from '@emotion/styled';
import {
  convertPXToVW,
  convertPXToVH,
  SizeMinWidthScreenDesktop,
} from '@sofia/ui';

interface TimelineProps {
  isShowingTimelineDetails?: boolean;
}

interface MarkerProps {
  color: string;
  isActive: boolean;
}

const SIZES = {
  VW_12: convertPXToVW(12),
  VH_16: convertPXToVH(16),
  VW_16: convertPXToVW(16),
  VH_20: convertPXToVH(20),
  VW_20: convertPXToVW(20),
  VW_30: convertPXToVW(30),
  VH_32: convertPXToVH(32),
  VW_32: convertPXToVW(32),
  DESK_MIN: SizeMinWidthScreenDesktop,
  WIDTH_SCREEN_LARGE: '950px',
};

export const Wrapper = {
  Content: styled.div`
    margin: 0;
    height: fit-content;
    padding: ${convertPXToVH(32)} ${convertPXToVW(32)};
    max-width: ${convertPXToVW(456)};
    background: ${({ theme }) => theme.colors.background.primary};
    border-radius: ${SIZES.VW_16};
    @media (max-width: ${SIZES.DESK_MIN}) {
      padding: ${convertPXToVH(16)} ${convertPXToVW(16)};
    }
  `,

  Timeline: styled.ul<TimelineProps>`
    line-height: 1.4em;
    list-style: none;
    width: 100%;
    max-height: ${convertPXToVH(200)};
    padding: 0;
    margin: 0;
    overflow: auto;

    @media (max-width: ${SIZES.DESK_MIN}) {
      max-height: ${({ isShowingTimelineDetails }) =>
        !isShowingTimelineDetails ? 'none' : 'unset'};

      li:nth-of-type(1n + 3) {
        display: ${({ isShowingTimelineDetails }) =>
          !isShowingTimelineDetails ? 'none' : 'flex'};
      }
    }

    @media (max-width: ${SIZES.WIDTH_SCREEN_LARGE}) {
      width: 100%;
    }
  `,

  Item: styled.li`
    padding: 0 ${SIZES.VW_30};
    position: relative;
    &:last-child {
      padding-bottom: 0;
    }
  `,

  Marker: styled.div<MarkerProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 13px;
    &:before {
      background: ${({ color }) => color};
      border-radius: 100%;
      content: '';
      display: block;
      height: ${({ isActive }) => (!isActive ? '8px' : '13px')};
      width: ${({ isActive }) => (!isActive ? '8px' : '13px')};
      position: absolute;
      top: 0;
      left: ${({ isActive }) => (!isActive ? '0.15em' : '0')};
      z-index: 1;
    }
    &:after {
      content: '';
      width: 2px;
      background: ${({ color }) => color};
      display: block;
      position: absolute;
      top: ${({ isActive }) => (!isActive ? '0.5em' : '0.75em')};
      bottom: 0;
      left: 0.3125em;
      z-index: 0;
    }
  `,

  Info: styled.div`
    padding-bottom: ${SIZES.VH_16};
  `,

  MoreDetails: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: ${SIZES.VW_12};
  `,

  PlusButton: styled.span`
    width: ${SIZES.VW_20};
    height: ${SIZES.VH_20};
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${({ theme }) => theme.colors.text.primary};
    border-radius: 100%;
  `,
};
