import React from 'react';
import { Input } from '@sofia/ui';
import { object } from 'prop-types';

const MandatoryInternshipInfoFormSupervisor = ({ formik }) => {
  return (
    <>
      <Input
        id="fieldSupervisor.name"
        label="Supervisor de campo*"
        aria-label="Nome do supervisor"
        placeholder="Nome do supervisor"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.fieldSupervisor?.name || ''}
        error={Boolean(
          formik.touched.fieldSupervisor?.name &&
            formik.errors.fieldSupervisor?.name
        )}
        infoText={
          formik.touched.fieldSupervisor?.name &&
          formik.errors.fieldSupervisor?.name
        }
        labelWeight="700"
        width="100%"
        required
      />
      <Input
        maxLength={250}
        id="fieldSupervisor.academicDegree"
        label="Formação Acadêmica*"
        aria-label="Formação acadêmica do supervisor"
        placeholder="Formação acadêmica do supervisor"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.fieldSupervisor?.academicDegree || ''}
        error={Boolean(
          formik.touched.fieldSupervisor?.academicDegree &&
            formik.errors.fieldSupervisor?.academicDegree
        )}
        infoText={
          formik.touched.fieldSupervisor?.academicDegree &&
          formik.errors.fieldSupervisor?.academicDegree
        }
        labelWeight="700"
        width="100%"
        required
      />
    </>
  );
};

MandatoryInternshipInfoFormSupervisor.propTypes = {
  formik: object.isRequired,
};

export default MandatoryInternshipInfoFormSupervisor;
