import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import { SvgLeftArrowFromLeft, SvgArrowToRight } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { Tooltip } from '@material-ui/core';
import SectionMenuProgress from './progress/section-menu-progress';
import Skeleton from '../../../../../skeleton/skeleton';

import * as Styled from './section-menu-header-styles';
import { SectionMenuHeaderProps } from '../types';

const SectionMenuHeader = ({
  open,
  sectionProgress,
  sectionOrder,
  sectionTitle,
  learningObjects,
  pageTitle,
  onToggleMenuClick,
  children,
  hideProgressBar,
  loading,
}: SectionMenuHeaderProps): React.ReactElement => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const closedClass = !open ? 'closed' : '';

  return (
    <Styled.SectionMenuHeader
      className={closedClass}
      data-testid="section-menu-header"
    >
      <Styled.FlexWrapper>
        {open ? (
          <Styled.FlexTitleContainer data-testid="section-menu-header-title">
            {!loading ? (
              pageTitle ? (
                <Styled.PageTitle
                  color={theme.colors.text.black}
                  data-testid="section-menu-header-page-title"
                >
                  {pageTitle}
                </Styled.PageTitle>
              ) : null
            ) : (
              <Skeleton height="18px" width="50%" margin="0 0 8px 0" />
            )}
            {!loading ? (
              sectionTitle ? (
                <Styled.SectionTitle
                  color={theme.colors.text.black}
                  data-testid="section-menu-header-section-title"
                >
                  {sectionTitle}
                </Styled.SectionTitle>
              ) : null
            ) : (
              <Skeleton height="32px" margin="8px 0 16px 0" />
            )}
          </Styled.FlexTitleContainer>
        ) : null}
        {(!isDesktop && open) || isDesktop ? (
          <Tooltip
            arrow
            title={`${open ? 'Reduzir' : 'Expandir'} menu`}
            placement={open ? 'bottom' : 'right'}
            data-testid="section-menu-header-tooltip"
          >
            <Styled.MenuHandle
              data-testid="section-menu-header-handle"
              onClick={onToggleMenuClick}
              className={closedClass}
              /* styled props */
              themeColor={theme.colors.text.black}
            >
              {open ? <SvgLeftArrowFromLeft /> : <SvgArrowToRight />}
            </Styled.MenuHandle>
          </Tooltip>
        ) : null}
      </Styled.FlexWrapper>
      {loading && open ? (
        <Styled.FlexWrapper data-testid="section-menu-header-loading">
          <Skeleton height="20px" margin="0 16px 0 0" />
          <Skeleton height="20px" width="32px" />
        </Styled.FlexWrapper>
      ) : sectionProgress !== 0 && !hideProgressBar ? (
        <SectionMenuProgress
          open={open}
          sectionProgress={sectionProgress}
          sectionOrder={sectionOrder}
          sectionTitle={sectionTitle}
          learningObjects={learningObjects}
        />
      ) : null}
      {children}
    </Styled.SectionMenuHeader>
  );
};

SectionMenuHeader.defaultProps = {
  hideProgressBar: false,
  loading: false,
};

SectionMenuHeader.propTypes = {};

export default SectionMenuHeader;
