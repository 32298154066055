import React from 'react';
import { useTheme } from '@emotion/react';
import { CardButton, Typography } from '@sofia/ui';
import { NotesMenuObjectCardProps } from '../../../types';
import * as Styled from './notes-menu-object-card.styles';

const NotesMenuObjectCard = ({
  title,
  content,
  onEdit,
}: NotesMenuObjectCardProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <Styled.Column>
      <CardButton
        borderColor={theme.colors.card.border.primary}
        hoverColor={theme.colors.background.primary}
        onClick={onEdit}
      >
        <Typography
          color={theme.colors.text.black}
          size={20}
          weight={700}
          margin="0 0 8px 0"
        >
          {title}
        </Typography>
        <Styled.NoteWrapper>
          <Styled.NoteContent color={theme.colors.text.lightBlack} size={16}>
            {content}
          </Styled.NoteContent>
        </Styled.NoteWrapper>
        <Styled.EditTextWrapper>
          <Typography color={theme.colors.text.primary} size={16} weight={700}>
            Editar
          </Typography>
          <Styled.EditIcon
            color={theme.colors.text.primary}
            width="20"
            height="20"
          />
        </Styled.EditTextWrapper>
      </CardButton>
    </Styled.Column>
  );
};

export default NotesMenuObjectCard;
