import React from 'react';

import { useTheme } from '@emotion/react';
import { ButtonOutline } from '@sofia/ui';
import { ResetProps } from '../../types';

const Reset = ({ onResetClick }: ResetProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <ButtonOutline
      data-testid="reset-button"
      hover={theme.colors.card.color.hover}
      borderBoxColor={theme.colors.text.primary}
      color={theme.colors.text.primary}
      width="100%"
      onClick={onResetClick}
    >
      Redefinir
    </ButtonOutline>
  );
};

export default Reset;
