import React from 'react';

import { Question as _Question } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import { TextAsset } from '../../../../../components/asset';
import { childNodeStyle } from '../../../../../components/quiz/correction/helper';

import {
  LINE_BETWEEN_TAGS_REGEX,
  questionStripTags,
} from '../../../../../constants';
import { QuestionProps } from '../types';
import { AssetType } from '../../../graphql/types';

const Question = ({
  questionChoices,
  customization,
  onChoiceClick,
  onHelpClick,
  isDesktop,
  question,
  step,
}: QuestionProps): React.ReactElement => {
  const theme = useTheme();
  const selectedChoice = React.useRef<string>();

  const choices = question?.choices?.map((choice) => {
    if (questionChoices[step]?.length) {
      selectedChoice.current = questionChoices[step];
    }

    return {
      ...choice,
      description: (
        <TextAsset
          size={'medium'}
          color="text-dark"
          data={{
            id: '',
            __typename: 'TextAsset',
            type: AssetType.Text,
            content: choice.description.replace(LINE_BETWEEN_TAGS_REGEX, '><'),
          }}
          stripTags={questionStripTags}
          nlToBr
          childNodeStyle={childNodeStyle}
        />
      ),
      selectedBgColor: theme.colors.button.primary,
      selectedBorderColor: theme.colors.button.darkPrimary,
    };
  });

  const help = isDesktop
    ? {
        text: 'Questão com problema?',
        color: theme.colors.text.primary,
        click: onHelpClick,
      }
    : null;

  const wording = {
    text: (
      <>
        {question?.baseText ? (
          <TextAsset
            size={'medium'}
            color="text-dark"
            data={{
              id: '',
              __typename: 'TextAsset',
              type: AssetType.Text,
              content: question.baseText.replace(LINE_BETWEEN_TAGS_REGEX, '><'),
            }}
            stripTags={questionStripTags}
            nlToBr
            childNodeStyle={childNodeStyle}
          />
        ) : null}
        {question?.statement ? (
          <TextAsset
            size={'medium'}
            color="text-dark"
            data={{
              id: '',
              __typename: 'TextAsset',
              type: AssetType.Text,
              content: question.statement.replace(
                LINE_BETWEEN_TAGS_REGEX,
                '><'
              ),
            }}
            stripTags={questionStripTags}
            nlToBr
            childNodeStyle={childNodeStyle}
          />
        ) : null}
      </>
    ),
  };

  const tag = {
    text: questionChoices[step] ? 'Respondida' : 'Sem resposta',
    type: questionChoices[step] ? 'success' : 'standard',
  };

  return (
    <_Question
      data-testid="question"
      title={`Questão ${step + 1}`}
      color={customization?.theme?.color}
      backgroundColor={customization?.theme?.background}
      help={help}
      wording={wording}
      tag={tag}
      choices={choices}
      onChoiceClick={(_id: string, choiceId: string) => onChoiceClick(choiceId)}
      choiceIdSelected={selectedChoice.current}
    />
  );
};

Question.defaultProps = {
  isDesktop: false,
};

export default Question;
