import { useBreakpoint } from '@ampli/hooks';
import { Typography } from '@sofia/ui';
import { useTheme } from '@emotion/react';

import { useGetEnrollmentCancellation } from '../hooks/use-get-enrollment-cancellation';

import SuspensionCancellationBulletPoints from './bullet-points/bullet-points';
import SuspensionCancellationSkeleton from './skeleton/suspension-cancellation-skeleton';
import SuspensionCancellationCards from './cards/cards';
import React from 'react';

export default function SuspensionCancellation() {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const [chosenAction, setChosenAction] = React.useState(null);
  const [enrollmentCancellation, setEnrollmentCancellation] =
    React.useState(null);

  const { getData, loading } = useGetEnrollmentCancellation();

  React.useEffect(() => {
    setEnrollmentCancellation(chosenAction ? getData(chosenAction) : null);
  }, [chosenAction, getData]);

  return loading ? (
    <SuspensionCancellationSkeleton />
  ) : !chosenAction ? (
    <>
      <Typography
        weight="700"
        color={theme.colors.text.black}
        size={isDesktop ? 32 : 24}
        margin="0 0 8px 0"
        id="suspension-cancellation-title"
      >
        Pausa nos estudos
      </Typography>
      <Typography
        margin={`0 0 ${isDesktop ? 56 : 24}px 0`}
        color={theme.colors.text.lightBlack}
        size={16}
        id="suspension-cancellation-description"
      >
        Confira as opções para fazer uma pausa nos seus estudos
      </Typography>
      <SuspensionCancellationCards
        onChoose={(action) => setChosenAction(action)}
        isDesktop={isDesktop}
      />
    </>
  ) : (
    enrollmentCancellation && (
      <SuspensionCancellationBulletPoints
        action={chosenAction}
        warnings={enrollmentCancellation.warnings}
        recipent={enrollmentCancellation.recipent}
        recipentType={enrollmentCancellation.recipentType}
        onGoBack={() => setChosenAction(null)}
      />
    )
  );
}

SuspensionCancellation.propTypes = {};
