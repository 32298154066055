import { generatePath, useHistory } from 'react-router-dom';

import { isMobile } from '@ampli/utils';
import { matchPathRegex, MessagingRoutesPath, wisdomRoutesPath } from '../..';
import { routePath } from '../../../routes';
import { openZendeskBot } from '../../../lib/zendesk';
import { useGetState } from '../../../state';
import { useGetCourseRoles } from '../../communication/services/hooks';

const regexScholarshipWithFollowing = /\/beneficios\/([^/]+)/;

export const INTERNAL_LINKS = [
  '/aluno-secretaria/atualizar-dados',
  '/aluno-secretaria/envio-documentos',
  '/aluno-secretaria/transferencia-interna',
  '/atividades-complementares',
  '/beneficios',
  '/calendario-academico',
  '/central-de-ajuda',
  '/fazer-prova-final',
  '/historico-de-mensagens',
  '/minhas-disciplinas',
  '/notas-e-correcoes',
  '/pagamento',
  '/tutor',
] as const;

export type INTERNAL_LINKS = typeof INTERNAL_LINKS[number];

export type InternalLinksParams = {
  onFinalExameModal?: () => void;
};

export type GetInternLinksCTAReturn = string | (() => void);

export type RolesType = { roles: string[] };

export const useInternLinksCTA = (
  key: INTERNAL_LINKS,
  options?: InternalLinksParams
): GetInternLinksCTAReturn => {
  const history = useHistory();

  const [
    {
      selectedCourseEnrollmentType: courseType,
      selectedCourseVersionId: courseVersionId,
      selectedCourseEnrollmentId,
    },
  ] = useGetState();

  const { data } = useGetCourseRoles<RolesType>(selectedCourseEnrollmentId);

  const isAmpli = data?.roles?.includes('CHAT_ENABLED');

  const redirect = <P, S>(path: string, paramsPath?: P, paramsState?: S) =>
    history.push(
      generatePath(
        INTERNAL_LINKS.includes(key)
          ? path
          : '/:courseType/:courseVersionId'.concat(path),
        { courseType, courseVersionId, ...paramsPath }
      ),
      { ...paramsState }
    );

  const getInternalLinks = (key: INTERNAL_LINKS) => {
    const handleInternalLink =
      INTERNAL_LINKS.includes(key) &&
      {
        '/aluno-secretaria/atualizar-dados': () =>
          redirect(routePath.updateDataPage),
        '/aluno-secretaria/envio-documentos': () =>
          redirect(routePath.secretarySendingDocuments),
        '/aluno-secretaria/transferencia-interna': () =>
          redirect(routePath.secretaryInternalTransfer),
        '/atividades-complementares': () => redirect(routePath.aco),
        '/beneficios': () => redirect(routePath.payment.scholarship),
        '/calendario-academico': () => redirect(routePath.academicCalendar),
        '/central-de-ajuda': isAmpli
          ? () => openZendeskBot()
          : () => redirect(wisdomRoutesPath.home),
        '/fazer-prova-final': () => options?.onFinalExameModal?.(),
        '/historico-de-mensagens': () => redirect(MessagingRoutesPath.historic),
        '/minhas-disciplinas': () => redirect(routePath.syllabus),
        '/notas-e-correcoes': () => redirect(routePath.grade),
        '/pagamento': () => redirect(routePath.payment.home),
        '/tutor': () => redirect(routePath.tutor),
      }[key];

    if (handleInternalLink) return handleInternalLink;

    const getIdScholarship = key
      ? matchPathRegex(key, regexScholarshipWithFollowing)
      : null;

    if (getIdScholarship) {
      () => redirect(routePath.payment.scholarshipDetails, getIdScholarship[1]);
    }

    return key;
  };

  return getInternalLinks(key);
};

const parseProp = (prop: string) => prop?.replace(/ |-/g, '_').toLowerCase();

const parseTourStep = (step: number, tourType: string) => {
  if (!tourType || (tourType !== 'boas_vindas' && tourType !== 'aprendizagem'))
    return '';

  return tourType === 'boas_vindas'
    ? {
        1: 'home',
        2: 'secretaria',
        3: 'pagamento',
        4: isMobile() ? 'perfil' : 'central_de_ajuda',
        5: 'perfil',
      }[step] || ''
    : {
        1: 'card_disciplina',
        2: 'resumo_disciplina',
        3: 'aulas',
      }[step] || '';
};

export const STUDENT_PROFILE_VIEWED = 'STUDENT_PROFILE_VIEWED';
export const STUDENT_PROFILE_VIEWED_DESCRIPTION =
  'Used to control if the user already saw the new student profile';
export const HOME_CHAT_COPILOT_VIEWED = 'HOME_CHAT_COPILOT_VIEWED';
export const HOME_CHAT_COPILOT_VIEWED_DESCRIPTION =
  'Used to control if the user already saw the new chat virtual assistant';
export const SECTION_CHAT_COPILOT_VIEWED = 'SECTION_CHAT_COPILOT_VIEWED';
export const SECTION_CHAT_COPILOT_VIEWED_DESCRIPTION =
  'Used to control if the user already saw the new chat virtual assistant inside section';

export const analyticsCreativesEvents = {
  // Central de notificações

  notificationsCenter: {
    Com_central_notificacoes_visualizacao: {
      event: 'Com_central_notificacoes_visualizacao',
    },

    Com_central_notificacoes_clique_fechar: {
      event: 'Com_central_notificacoes_clique_fechar',
    },

    Com_central_notificacoes_clique_leia_mais: {
      event: 'Com_central_notificacoes_clique_leia_mais',
      param: (prop: string): string => `leia_mais_${parseProp(prop)}`,
    },

    Com_central_notificacoes_clique_filtro: {
      event: 'Com_central_notificacoes_clique_filtro',
      param: (prop: string): string => `filtro_${parseProp(prop)}`,
    },

    Com_central_notificacoes_clique_cta: {
      event: 'Com_central_notificacoes_clique_cta',
      param: (prop: string): string => `notificacao_cta_${parseProp(prop)}`,
    },
  },

  // Banner

  banner: {
    Com_banner_visualizacao: {
      event: 'Com_banner_visualizacao',
    },

    Com_banner_clique_transicao: {
      event: 'Com_banner_clique_transicao',
    },

    Com_banner_clique_cta: {
      event: 'Com_banner_clique_cta',
      param: (prop: string): string => `banner_cta_${parseProp(prop)}`,
    },
  },

  // Modal

  modal: {
    Com_modal_visualizacao: {
      event: 'Com_modal_visualizacao',
      param: (prop: string): string => `modal_${parseProp(prop)}`,
    },

    Com_modal_clique_fechar: {
      event: 'Com_modal_clique_fechar',
      param: (prop: string): string => `modal_fechar_${parseProp(prop)}`,
    },

    Com_modal_clique_cta: {
      event: 'Com_modal_clique_cta',
      param: (prop: string): string => `modal_cta_${parseProp(prop)}`,
    },
  },

  // Sliding

  sliding: {
    Com_deslizante_visualizacao: {
      event: 'Com_deslizante_visualizacao',
      param: (prop: string): string => `deslizante_${parseProp(prop)}`,
    },

    Com_deslizante_clique_fechar: {
      event: 'Com_deslizante_clique_fechar',
      param: (prop: string): string => `deslizante_fechar_${parseProp(prop)}`,
    },

    Com_deslizante_clique_cta: {
      event: 'Com_deslizante_clique_cta',
      param: (prop: string): string => `deslizante_cta_${parseProp(prop)}`,
    },
  },

  // Central de ajuda

  helpCenter: {
    Com_central_ajuda_visualizacao: {
      event: 'Com_central_ajuda_visualizacao',
    },
    Com_central_ajuda_artigo: {
      event: 'Com_central_ajuda_artigo',
      param: (prop: string): string => `artigo_${parseProp(prop)}`,
    },

    Com_central_ajuda_pesquisar: {
      event: 'Com_central_ajuda_pesquisar',
      param: (prop: string): string => `resultado_${parseProp(prop)}`,
    },

    Com_central_ajuda_feedback_resolvida: {
      event: 'Com_central_ajuda_feedback_resolvida',
      param: (prop: string): string => `artigo_${parseProp(prop)}`,
    },

    Com_central_ajuda_feedback_nao_resolvida: {
      event: 'Com_central_ajuda_feedback_nao_resolvida',
      param: (prop: string): string => `artigo_${parseProp(prop)}`,
    },
    Com_central_ajuda_minhas_solicitacoes: {
      event: 'Com_central_ajuda_minhas_solicitacoes',
    },
    Com_central_ajuda_minha_instituicao: {
      event: 'Com_central_ajuda_minha_instituicao',
    },
    Com_central_ajuda_canais_atendimento: {
      event: 'Com_central_ajuda_canais_atendimento',
    },
  },

  // Banner rematrícula

  rematBanner: {
    Com_contrato_rematricula_visualizacao: {
      event: 'Com_contrato_rematricula_visualizacao',
    },

    Com_contrato_rematricula_clique: {
      event: 'Com_contrato_rematricula_clique',
      param: (prop: 'fechar' | 'cta'): string => `clique_${prop}`,
    },
  },

  // Modal termos e contrato prestação de serviços

  termsModal: {
    Com_contrato_prestservicos_visualizacao: {
      event: 'Com_contrato_prestservicos_visualizacao',
    },

    Com_contrato_prestservicos_clique: {
      event: 'Com_contrato_prestservicos_clique',
      param: (prop: 'fechar' | 'cta'): string => `clique_${prop}`,
    },
  },

  // Onboarding

  onboarding: {
    Com_tour_boas_vindas_iniciar: {
      event: 'Com_tour_boas_vindas_iniciar',
    },
    Com_tour_boas_vindas_pular: {
      event: 'Com_tour_boas_vindas_pular',
    },
    Com_tour_boas_vindas_etapa_fechar: {
      event: 'Com_tour_boas_vindas_etapa_fechar',
      param: (prop: number): string =>
        `etapa_${parseTourStep(prop, 'boas_vindas')}`,
    },
    Com_tour_boas_vindas_concluir: {
      event: 'Com_tour_boas_vindas_concluir',
    },
    Com_inicie_jornada_iniciar: {
      event: 'Com_inicie_jornada_iniciar',
    },
    Com_inicie_jornada_pular: {
      event: 'Com_inicie_jornada_pular',
    },
    Com_tour_aprendizagem_iniciar: {
      event: 'Com_tour_aprendizagem_iniciar',
    },
    Com_tour_aprendizagem_pular: {
      event: 'Com_tour_aprendizagem_pular',
    },
    Com_tour_aprendizagem_etapa_fechar: {
      event: 'Com_tour_aprendizagem_etapa_fechar',
      param: (prop: number): string =>
        `etapa_${parseTourStep(prop, 'aprendizagem')}`,
    },
    Com_tour_aprendizagem_concluir: {
      event: 'Com_tour_aprendizagem_concluir',
    },
  },
};

export const onboardingIds = {
  welcome: {
    id: {
      welcomeTour: 'welcomeTour',
      welcomeTourInfo: 'welcomeTourInfo',
    },
    target: {
      questionIconCourseSelection: '#question-icon-course-selection',
      help: '#help',
      topBar: '.top-bar',
      profileMobile: '#profile-mobile',
      profileDesktop: '#profile-desktop',
      myCoursesHeader: '#my-courses-header',
      cardEnterCourse: '.card-enter-course',
      secretary: '#secretary',
      payment: '#payment',
    },
  },
  subject: {
    id: {
      subjectTourInfo: 'subjectTourInfo',
      subjectTour: 'subjectTour',
    },
    target: {
      questionIconHome: '#question-icon-home',
      carouselActives: '.carousel-actives-0',
      situationCard: '#situation-card',
      learningUnitCard: '#learning-unit-card-0',
      cardLearningUnitSectionItem: '.card-learning-unit-section-item',
      progressCard: '#progress-card',
      subjectPresentationCard: '#subject-presentation-card',
      subjectPontuationCard: '#subject-pontuation-card',
      sectionMenuListItem: '.section-menu-list-item',
      sectionLearningObjectMenu: '#section-learning-object-menu',
      sectionActionsContainer: '#section-actions-container',
    },
  },
  newFeature: {
    id: {
      newProfile: 'newProfile',
      newChatHome: 'newChatHome',
      newChatSection: 'newChatSection',
    },
    target: {
      newChatHome: '#virtual-assistant-chat',
      newChatSection: '#virtual-assistant-chat-section',
    },
  },
};
