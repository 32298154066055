import styled from '@emotion/styled';
import { SvgClock, mediaQuery, Typography } from '@sofia/ui';

export const Flex = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 16px;
  > :not(:last-of-type) {
    margin-bottom: 16px;
  }
  ${mediaQuery('desktop')} {
    flex-direction: row;
    align-items: center;
    > :not(:last-of-type) {
      margin-bottom: 0px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: -2px 0 0 0;
  ${mediaQuery('desktop')} {
    margin: -2px 16px 0 0;
  }
  > svg {
    margin-right: 8px;
  }
`;

export const Clock = styled(SvgClock)`
  margin-top: 2px;
`;

export const StartAndEndTimeText = styled(Typography)`
  white-space: nowrap;
  margin-top: -1px;
`;
