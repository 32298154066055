import styled from '@emotion/styled';
import { convertPXToVH, convertPXToREM, mediaQuery } from '@sofia/ui';

export const Figure = styled.figure`
  ${mediaQuery('tablet-max')} {
    margin-bottom: ${convertPXToREM(16)};
    margin-left: ${convertPXToREM(-16)};
    margin-right: ${convertPXToREM(-16)};
    &.asset {
      padding: 0 16px;
    }
  }
  &.asset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Image = styled.img`
  ${({ banner }: { banner: boolean | undefined }) =>
    banner ? 'width: 100%;' : 'max-width: 100%;'}
`;

export const Banner = styled.div<{
  url: string | null | undefined;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: ${convertPXToVH(200)};
  ${({ url }) => `background-image: url("${url}");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;`}

  ${mediaQuery('desktop')} {
    height: ${convertPXToVH(340)};
  }
`;
