import React from 'react';

import { localStorage, DateTime } from '@ampli/utils';

import {
  useModal,
  Typography,
  ButtonGhost,
  SvgQuestionCircle,
} from '@sofia/ui';

import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { cx, Grid, GridItem, useDialogState } from '@ampli/ui';
import { func, arrayOf, bool, object, string } from 'prop-types';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { includes } from 'lodash';

import {
  notActiveFreeTrialCourse,
  freeTrialEnabledRole,
} from '../../constants';

import { FreeTrialExpiredBanner, Skeleton } from '../../components';

import {
  useGetStudentSubjectTour,
  useGetStudentWelcomeTour,
} from '../../modules/communication/services/hooks';

import {
  CommunicationWrapper,
  SubjectsListRow,
  IconWrapper,
  TitleFlexWrapper,
  WrapperSyllabusEmptyState,
} from './home-styles';

import { FreeTrialExpirationModal } from '../free-trial-expiration-modal';
import { getTruncatedDateTime } from '../../utils';
import { ElectiveSubjectCard } from '../card';
import { LayoutContent } from '../layout';
import { useGetState } from '../../state';
import { routePath } from '../../routes';

import { Creatives } from '../../modules';
import { useRolesContext } from '../../modules/shared/contexts/communication-roles-context';
import { useNewChatCopilotHomeFeature } from '../../modules/shared/components/tours/new-feature/new-chat-copilot-home.hook';
import { useNewProfileFeature } from '../../modules/shared/components/tours/new-feature/new-profile-feature.hooks';
import { startTour } from '../tour';

import ElectiveSubjectSuccessMessage from '../subject/elective/success/elective-subject-success-message';
import ReenrollmentPeriodModal from '../reenrollment-period-modal/reenrollment-period-modal.presentation';
import SemestralExamBanner from './semester-exam/semester-exam';
import WithoutAccessAlert from '../without-access-alert/without-access-alert';
import CommunicationCard from './communication-card';
import SelectExamModal from './select-exam-modal';
import SyllabusSubject from '../syllabus/list-subjects/subject/syllabus-subject';
import HomeQuickAccess from './quick-access/home-quick-access';
import HomeBanner from './home-banner';
import { LiveAccessCard } from '../../modules/live';
import { SyllabusEmptyState } from '../../modules/academic-program/shared/components';

const Home = ({
  courseEnrollmentLoading,
  subjectEnrollmentsLoading,
  subjectEnrollments,
  subjectEnrollmentAvailableExams,
  course,
  blockCourseEnrollmentContentAccess,
  onSubjectClick,
  onMandatoryInternshipClick,
  onSyllabusClick,
  onReactivateEnrollmentClick,
  onStartExam,
  courseEnrollmentSettings,
  onGradeClick,
  onAcademicCalendarClick,
  courseEnrollmentData,
  courseEnrollmentStatus,
  studentFreeTrialCourseData,
  me,
  activeEnrollments,
}) => {
  let electiveSubject = [];

  const reenrollmentPeriodModal = useModal();
  const freeTrialExpirationModalState = useModal();
  const withoutAccessDialog = useDialogState();
  const selectExamDialog = useDialogState();
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const theme = useTheme();

  const subjectTour = useGetStudentSubjectTour(courseEnrollmentData?.id);

  const shouldPresentSubjectTour = subjectTour?.data?.data?.shouldPresent;
  const alreadyReadSubjectTour = subjectTour?.data?.data?.alreadyRead;
  const welcomeTour = useGetStudentWelcomeTour();
  const alreadyReadWelcomeTour = welcomeTour?.data?.data?.alreadyRead;

  const { hasSubjectOnboardingTourEnabled } = useRolesContext();

  const enableSubjectTour = React.useMemo(
    () => shouldPresentSubjectTour && hasSubjectOnboardingTourEnabled,
    [shouldPresentSubjectTour, hasSubjectOnboardingTourEnabled]
  );

  const showSubjectTour = React.useMemo(
    () => enableSubjectTour && !alreadyReadSubjectTour,
    [enableSubjectTour, alreadyReadSubjectTour]
  );

  const [hasActiveTour, setHasActiveTour] = React.useState(false);
  const showNewProfileCommunication =
    enableSubjectTour && !courseEnrollmentLoading && alreadyReadWelcomeTour;

  useNewChatCopilotHomeFeature(me?.id, !courseEnrollmentLoading);
  useNewProfileFeature(me?.id, showNewProfileCommunication);

  const { state: locationState } = useLocation();
  const [{ selectedCourseEnrollmentType, selectedCourseVersionId }] =
    useGetState();
  const isDesktop = breakpoint === 'desktop';
  const gridColumns = isDesktop ? 4 : 2;

  const semestralExamSubjectEnrollment = React.useMemo(
    () =>
      subjectEnrollments?.find((subjectEnrollment) =>
        subjectEnrollment?.assignments?.some(
          (subjectAssignment) =>
            subjectAssignment?.detail?.config?.type === 'SEMESTRAL_EXAM_ONLINE'
        )
      ),
    [subjectEnrollments]
  );

  const showEmptyState =
    !subjectEnrollmentsLoading && !activeEnrollments?.length;

  const showCommunication = React.useMemo(
    () =>
      blockCourseEnrollmentContentAccess
        ? courseEnrollmentSettings?.title ||
          courseEnrollmentSettings?.description
        : null,
    [
      blockCourseEnrollmentContentAccess,
      courseEnrollmentSettings?.description,
      courseEnrollmentSettings?.title,
    ]
  );

  const isFreeTrialEnabled = React.useMemo(
    () => includes(courseEnrollmentData?.roles, freeTrialEnabledRole),
    [courseEnrollmentData?.roles]
  );

  const handleSubjectClick = React.useCallback(
    ({ subjectEnrollment, subject }) => {
      if (
        includes(
          notActiveFreeTrialCourse,
          studentFreeTrialCourseData?.freeTrialSituation
        ) &&
        isFreeTrialEnabled
      ) {
        freeTrialExpirationModalState.show();
      } else if (blockCourseEnrollmentContentAccess) {
        withoutAccessDialog.show();
      } else if (
        subjectEnrollment.courseSubject?.subType === 'MANDATORY_INTERNSHIP'
      ) {
        onMandatoryInternshipClick({
          subjectEnrollmentId: subjectEnrollment.id,
        });
      } else {
        onSubjectClick({
          ...subject,
          subjectEnrollmentId: subjectEnrollment.id,
        });
      }
    },
    [
      studentFreeTrialCourseData?.freeTrialSituation,
      isFreeTrialEnabled,
      blockCourseEnrollmentContentAccess,
      freeTrialExpirationModalState,
      withoutAccessDialog,
      onMandatoryInternshipClick,
      onSubjectClick,
    ]
  );

  const subjectsClick = React.useCallback(() => {
    if (
      includes(
        notActiveFreeTrialCourse,
        studentFreeTrialCourseData?.freeTrialSituation
      ) &&
      isFreeTrialEnabled
    ) {
      freeTrialExpirationModalState.show();
    } else if (blockCourseEnrollmentContentAccess) {
      withoutAccessDialog.show();
    } else {
      onSyllabusClick();
    }
  }, [
    freeTrialExpirationModalState,
    blockCourseEnrollmentContentAccess,
    isFreeTrialEnabled,
    onSyllabusClick,
    studentFreeTrialCourseData?.freeTrialSituation,
    withoutAccessDialog,
  ]);

  const electiveSubjectMapValidation = (subjectEnrollment) => {
    const subject = subjectEnrollment.subject;
    const actualDate = getTruncatedDateTime();
    const endDate = DateTime.fromISO(subjectEnrollment.endDateForChoice);
    const startDate = DateTime.fromISO(subjectEnrollment.startDateForChoice);

    if (
      !subject &&
      subjectEnrollment.isElective &&
      subjectEnrollment.status === 'AWAITING_CHOICE' &&
      actualDate <= endDate &&
      startDate <= actualDate
    ) {
      electiveSubject.push(subjectEnrollment);
    }
  };

  React.useEffect(() => {
    localStorage.setItem(
      'courseEnrollmentData',
      JSON.stringify(courseEnrollmentData)
    );
  }, [courseEnrollmentData]);

  React.useEffect(() => {
    const showFinalExamModal = sessionStorage.getItem('finalExamModal');
    if (showFinalExamModal === 'true') {
      selectExamDialog.show();
      sessionStorage.removeItem('finalExamModal');
    }
  }, [selectExamDialog]);

  React.useEffect(() => {
    if (showSubjectTour) startTour('subjectTour');
  }, [showSubjectTour]);

  const newEnrolledSubjectRef = React.useRef();

  React.useEffect(() => {
    window.addEventListener('hasActiveTour', () => {
      setHasActiveTour(true);
    });

    if (newEnrolledSubjectRef.current) {
      newEnrolledSubjectRef.current.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }

    return () => {
      window.removeEventListener('hasActiveTour', () => {
        setHasActiveTour(false);
      });
    };
  }, [enableSubjectTour]);

  return (
    <>
      <HomeBanner
        courseName={course.name}
        selectedCourseEnrollmentType={selectedCourseEnrollmentType}
        selectedCourseVersionId={selectedCourseVersionId}
        studentFreeTrialCourseData={studentFreeTrialCourseData}
        isFreeTrialEnabled={isFreeTrialEnabled}
      />

      {showCommunication ? (
        <LayoutContent padding={['none', 'small']}>
          <CommunicationWrapper>
            <CommunicationCard
              id="headerCommunication"
              data-testid="headerCommunication"
              blockCourseEnrollmentContentAccess={
                blockCourseEnrollmentContentAccess
              }
              onReactivateEnrollmentClick={onReactivateEnrollmentClick}
              courseEnrollmentSettings={courseEnrollmentSettings}
              disableTab={false}
            />
          </CommunicationWrapper>
        </LayoutContent>
      ) : null}
      {locationState?.successElectiveSubjects ? (
        <ElectiveSubjectSuccessMessage
          manySubjects={locationState?.successElectiveSubjects?.length > 1}
        />
      ) : null}

      {Creatives.StickyBanner && !hasActiveTour ? (
        <Creatives.StickyBanner />
      ) : null}

      {Creatives.Modal && !hasActiveTour ? (
        <Creatives.Modal selectExamDialog={selectExamDialog} />
      ) : null}

      {Creatives.Banner ? (
        <Creatives.Banner selectExamDialog={selectExamDialog} />
      ) : null}

      <LayoutContent
        padding={isDesktop ? ['small', 'huge'] : ['small', 'small', 'huge']}
      >
        {isFreeTrialEnabled ? (
          <FreeTrialExpiredBanner
            freeTrialStatus={studentFreeTrialCourseData?.freeTrialSituation}
          />
        ) : null}

        <FreeTrialExpirationModal
          {...freeTrialExpirationModalState}
          selectedCourseEnrollmentType={selectedCourseEnrollmentType}
          selectedCourseVersionId={selectedCourseVersionId}
          handleModal={freeTrialExpirationModalState}
        />

        <ReenrollmentPeriodModal
          {...reenrollmentPeriodModal}
          handleModal={reenrollmentPeriodModal}
        />

        <TitleFlexWrapper>
          <Typography
            id="title-mySubjects"
            weight="bold"
            data-testid="title-mySubjects"
            size={isDesktop ? 48 : 32}
            color="#444444"
          >
            Disciplinas em andamento
          </Typography>
          {enableSubjectTour ? (
            <IconWrapper
              id="question-icon-home"
              data-testid="question-icon-home"
              hoverColor={theme.colors.card.background.hover}
              onClick={() => {
                startTour('subjectTour');
              }}
            >
              <SvgQuestionCircle fill={theme.colors.breadCrumbs.icon} />
            </IconWrapper>
          ) : null}
        </TitleFlexWrapper>

        {semestralExamSubjectEnrollment ? (
          <SemestralExamBanner
            subjectEnrollment={semestralExamSubjectEnrollment}
          />
        ) : null}

        <LiveAccessCard courseVersionId={selectedCourseVersionId} />

        <Grid
          columns={gridColumns}
          columnGap={isDesktop ? 24 : 0}
          rowGap={isDesktop ? 24 : 16}
        >
          <div>
            {subjectEnrollmentsLoading ? (
              <GridItem column="span 4">
                <SubjectsListRow>
                  {new Array(4).fill(undefined).map((i) => (
                    <Skeleton key={i} height={170} borderRadius={6} />
                  ))}
                </SubjectsListRow>
              </GridItem>
            ) : null}

            {showEmptyState ? (
              <GridItem column="span 4">
                <WrapperSyllabusEmptyState>
                  <SyllabusEmptyState status={courseEnrollmentStatus} />
                </WrapperSyllabusEmptyState>
              </GridItem>
            ) : (
              <GridItem column={isDesktop ? 'span 4' : 'span 2'}>
                <SubjectsListRow>
                  {activeEnrollments.map((subjectEnrollment, key) => {
                    if (!subjectEnrollment) return null;
                    electiveSubjectMapValidation(subjectEnrollment);

                    const subject = subjectEnrollment.subject;

                    const onSyllabusSubjectClick = () => {
                      if (subjectEnrollment.status !== 'PAUSED') {
                        handleSubjectClick({ subjectEnrollment, subject });
                      } else {
                        reenrollmentPeriodModal.show();
                      }
                    };

                    const successElectiveSubjectId =
                      locationState?.successElectiveSubject?.selectedSubjects.find(
                        subjectEnrollment.id
                      ) || null;

                    return subject ? (
                      <SyllabusSubject
                        blockCourseEnrollmentContentAccess={
                          blockCourseEnrollmentContentAccess
                        }
                        data-testid={`carousel-actives-${key}`}
                        id={`carousel-actives-${key}`}
                        key={key}
                        name={subject.name}
                        onClick={onSyllabusSubjectClick}
                        subjectEnrollment={subjectEnrollment}
                        {...(successElectiveSubjectId
                          ? { ref: newEnrolledSubjectRef }
                          : {})}
                      />
                    ) : null;
                  })}
                </SubjectsListRow>
              </GridItem>
            )}

            {electiveSubject.map((elective, index) => {
              return (
                <GridItem column="span 4" key={index}>
                  <div>
                    <ElectiveSubjectCard
                      order={index + 1}
                      degree={course?.courseType?.degree}
                      electiveSubject={electiveSubject}
                      electiveType={elective?.electiveType}
                      id={electiveSubject.id}
                      data-testid={electiveSubject.id}
                      className={cx(
                        blockCourseEnrollmentContentAccess && 'disabled'
                      )}
                      onClick={() => {
                        history.push(
                          generatePath(routePath.electiveSubjectOnboarding, {
                            courseType: selectedCourseEnrollmentType,
                            courseVersionId: selectedCourseVersionId,
                            subjectEnrollmentId: elective.id,
                          })
                        );
                      }}
                      startDateForChoice={elective.startDateForChoice}
                      endDateForChoice={elective.endDateForChoice}
                    />
                  </div>
                </GridItem>
              );
            })}

            {!subjectEnrollmentsLoading && (
              <GridItem column="span 4">
                <ButtonGhost
                  data-testId="see-all-subjects"
                  id="see-all-subjects"
                  color={theme.colors.text.primary}
                  onClick={subjectsClick}
                  minWidth="100%"
                >
                  Ver todas as disciplinas
                </ButtonGhost>
              </GridItem>
            )}

            <HomeQuickAccess
              course={course}
              studentFreeTrialCourseData={studentFreeTrialCourseData}
              courseEnrollmentData={courseEnrollmentData}
              onAcademicCalendarClick={onAcademicCalendarClick}
              withoutAccessDialog={withoutAccessDialog}
              blockCourseEnrollmentContentAccess={
                blockCourseEnrollmentContentAccess
              }
              subjectsClick={subjectsClick}
              onGradeClick={onGradeClick}
              selectExamDialog={selectExamDialog}
              loading={courseEnrollmentLoading}
            />
          </div>
        </Grid>
      </LayoutContent>

      {blockCourseEnrollmentContentAccess ? (
        <WithoutAccessAlert
          {...withoutAccessDialog}
          courseEnrollmentSettings={courseEnrollmentSettings}
          onActionClick={() => {
            withoutAccessDialog.hide();
            onReactivateEnrollmentClick();
          }}
        />
      ) : null}

      <SelectExamModal
        {...selectExamDialog}
        subjectEnrollments={subjectEnrollmentAvailableExams}
        onSelectSubjectEnrollment={onStartExam}
      />
    </>
  );
};

Home.propTypes = {
  courseEnrollmentLoading: bool,
  subjectEnrollmentsLoading: bool,
  subjectEnrollments: arrayOf(object),
  courseEnrollmentData: object,
  courseEnrollmentStatus: string,
  subjectEnrollmentAvailableExams: arrayOf(object),
  course: object,
  blockCourseEnrollmentContentAccess: bool,
  onSubjectClick: func,
  onMandatoryInternshipClick: func,
  onSyllabusClick: func,
  onReactivateEnrollmentClick: func,
  onStartExam: func.isRequired,
  courseEnrollmentSettings: object,
  showTutorial: bool,
  onCloseTutorialClick: func,
  onAcademicCalendarClick: func,
  onGradeClick: func.isRequired,
  onVirtualLibraryClick: func,
  studentFreeTrialCourseData: object,
  me: object,
  activeEnrollments: arrayOf(object),
};

Home.defaultProps = {
  courseEnrollmentData: {},
};

export default Home;
