import styled from '@emotion/styled';
import { ButtonOutline, convertPXToVH, mediaQuery } from '@sofia/ui';

export const Flex = styled.div`
  display: flex;
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const TelCol = styled(Column)`
  margin-top: 16px;
  align-items: center;
  ${mediaQuery('desktop')} {
    margin-top: 0;
  }
`;

export const TextCol = styled(Column)`
  ${mediaQuery('desktop')} {
    align-items: center;
    margin-left: 40px;
  }
`;

export const Card = styled(Flex)`
  border-radius: 16px;
  padding: 24px;
  margin-top: 0;
  margin-bottom: 0;
  flex-direction: column;
  align-items: center;
  > svg {
    min-width: 100px;
  }
  ${mediaQuery('desktop')} {
    flex-direction: row;
    padding: 40px;
    margin-top: ${convertPXToVH(144)};
    margin-bottom: ${convertPXToVH(144)};
    > svg {
      min-width: 170px;
    }
  }
`;

export const ButtonWrapper = styled(Flex)`
  margin-top: 24px;
  flex-direction: column;
  button {
    height: 56px;
    width: 100%;
    padding-top: unset;
    padding-bottom: unset;
  }
  ${mediaQuery('desktop')} {
    flex-direction: row;
    button {
      width: auto;
      :first-of-type {
        margin-right: 16px;
      }
    }
  }
`;

export const HelpBtn = styled(ButtonOutline)`
  margin-top: 16px;
  background: linear-gradient(#f3f6f9, #f3f6f9) padding-box,
    ${({ borderBoxColor }) => borderBoxColor} border-box;
  svg {
    margin-right: 8px;
  }
  ${mediaQuery('desktop')} {
    margin-top: 0;
  }
`;

export const Ellipsis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
