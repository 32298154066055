import { CSSProperties } from 'react';

export const EduAvatar: CSSProperties = {
  position: 'relative',
  top: '-112px',
  marginBottom: '-112px',
};

export const Title: CSSProperties = {
  margin: '8px 0',
  fontSize: '20px',
  whiteSpace: 'pre-line',
};
