import styled from '@emotion/styled';
export const DialogBackdrop = styled.div`
  background: ${_ref => {
  let {
    isBackdrop
  } = _ref;
  return isBackdrop ? '#21252970' : 'transparent';
}};
  position: ${_ref2 => {
  let {
    position
  } = _ref2;
  return position;
}};
  z-index: 1001;
  inset: 0;
  overflow: hidden;
`;