import React from 'react';

import { SkeletonBar } from './skeleton.styles';

export interface SkeletonProps {
  borderRadius?: number;
  margin?: string;
  height?: number;
}

const Skeleton = React.forwardRef<never, SkeletonProps>(
  ({ height, borderRadius, margin }: SkeletonProps, ref) => (
    <SkeletonBar
      ref={ref}
      height={height}
      borderRadius={borderRadius}
      margin={margin}
    />
  )
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
