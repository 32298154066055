import styled from '@emotion/styled';
import { ButtonOutline, convertPXToREM, mediaQuery } from '@sofia/ui';

export const Flex = styled.div`
  display: flex;
  width: 100%;

  ${mediaQuery('mobile')} {
    flex-direction: column;
  }
`;

export const WarningTitle = styled.span`
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
  line-height: 115%;
  margin-bottom: 24px;

  ${mediaQuery('desktop')} {
    margin-bottom: 8px;
  }
`;

export const Text = styled.span`
  color: ${({ color }) => color};
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

export const ContactCard = styled.div`
  background: #f3f6f9;
  border-radius: 8px;
  padding: 24px;
  max-width: 457px;

  ${mediaQuery('desktop')} {
    margin-left: 24px;
  }
`;

export const ButtonWrapper = styled(ButtonOutline)`
  background: linear-gradient(#f3f6f9, #f3f6f9) padding-box,
    ${({ borderBoxColor }) => borderBoxColor} border-box;
`;
