import React from 'react';

function IlustrationDocumentsTypes(): React.ReactElement {
  return (
    <svg
      width="119"
      height="62"
      viewBox="0 0 119 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.117 21.6974V17.0508H22.2343C21.8336 17.0509 21.4494 17.2101 21.1661 17.4934C20.8828 17.7768 20.7236 18.161 20.7236 18.5617V43.4398C20.7236 43.8405 20.8828 44.2248 21.1661 44.5081C21.4494 44.7915 21.8336 44.9507 22.2343 44.9508H40.7633C41.164 44.9506 41.5482 44.7914 41.8316 44.5081C42.115 44.2248 42.2743 43.8405 42.2745 43.4398V23.2083H37.6279C37.2272 23.2083 36.8429 23.0491 36.5595 22.7658C36.2762 22.4824 36.117 22.0981 36.117 21.6974ZM26.4825 34.1519C26.4397 34.3916 26.2534 34.5583 25.9896 34.5583H25.9837C25.6988 34.5583 25.4924 34.341 25.4924 34.045C25.4924 33.7381 25.6988 33.526 25.9946 33.526C26.285 33.526 26.4859 33.7325 26.4915 34.045C26.4918 34.0816 26.4887 34.1175 26.4825 34.1519ZM29.4498 32.7727C29.159 33.0406 28.7238 33.1689 28.2216 33.1689C28.11 33.1689 28.0093 33.1637 27.9311 33.1469V34.4973H27.0882V30.7853C27.3508 30.7407 27.7188 30.7072 28.238 30.7072C28.7629 30.7072 29.1426 30.8077 29.3937 31.0141C29.634 31.1986 29.7899 31.511 29.7902 31.8796C29.7902 32.2535 29.673 32.5663 29.4498 32.7727ZM33.5012 34.4973H32.6078L31.8037 33.0455C31.5801 32.6382 31.3297 32.158 31.1511 31.7172H31.1291C31.1573 32.2203 31.1678 32.7501 31.1678 33.3642V34.4973H30.3866L30.3863 34.497V30.7348H31.3796V30.7351L31.9769 31.7885L32.1611 32.1134C32.3846 32.5096 32.6025 32.9786 32.7749 33.4027H32.7864C32.7361 32.906 32.7191 32.3986 32.7191 31.8294V30.7348H33.5012V34.4973ZM37.4345 34.324C37.1719 34.4136 36.6753 34.5366 36.1784 34.5366C35.492 34.5366 34.9951 34.364 34.6491 34.0344C34.3081 33.7049 34.1187 33.2142 34.1187 32.6608C34.1243 31.4109 35.0342 30.6964 36.2677 30.6964C36.7531 30.6964 37.1273 30.7912 37.3114 30.8749L37.1329 31.5613C36.9264 31.4664 36.675 31.3998 36.2621 31.3998C35.5531 31.3998 35.0118 31.8015 35.0118 32.6165C35.0118 33.398 35.5029 33.85 36.2007 33.85C36.3904 33.85 36.5467 33.828 36.6139 33.8001V33.0071H36.0333V32.3425H37.4345V34.324Z"
        fill="#001F50"
      />
      <path
        d="M28.3772 32.5049C28.7334 32.4559 28.9473 32.2392 28.9473 31.9069C28.9473 31.8207 28.9328 31.7429 28.9039 31.6747C28.8323 31.5048 28.6727 31.396 28.4349 31.3644C28.3845 31.3578 28.3338 31.3546 28.283 31.3548C28.1097 31.3548 27.9925 31.3657 27.9312 31.3827V32.4931C28.0034 32.5102 28.0927 32.5154 28.216 32.5154C28.2725 32.5154 28.3264 32.5117 28.3772 32.5049ZM36.117 17.0508V21.6974C36.117 22.098 36.2762 22.4823 36.5594 22.7657C36.8427 23.049 37.227 23.2082 37.6276 23.2083H42.2745L36.117 17.0508Z"
        fill="#001F50"
      />
      <path
        opacity="0.6"
        d="M36.1172 17.0508V21.6974C36.1172 22.098 36.2763 22.4823 36.5596 22.7657C36.8429 23.049 37.2271 23.2082 37.6278 23.2083H42.2747L36.1172 17.0508Z"
        fill="white"
      />
      <path
        d="M26.5892 34.8682V34.4962V34.1699L26.4838 34.1509L26.5892 34.1697V34.0436V34.0419V33.2136M26.5892 34.8682L26.9929 34.0436L26.9927 34.0347C26.988 33.7726 26.8992 33.5157 26.7114 33.3205C26.6732 33.2809 26.6324 33.2452 26.5892 33.2136M26.5892 34.8682C26.4251 34.9879 26.2199 35.0573 25.9909 35.0573H25.985C25.4025 35.0573 24.9937 34.5942 24.9937 34.0439C24.9937 33.7764 25.0854 33.5168 25.2749 33.3213C25.4654 33.1248 25.7243 33.025 25.9959 33.025C26.2104 33.025 26.4177 33.0877 26.5892 33.2136M26.5892 34.8682V34.9962H27.0892H27.9321H28.4321V34.4962V33.6613C28.936 33.6289 29.4248 33.4754 29.7896 33.1395L29.7901 33.139C29.8246 33.1071 29.8572 33.0738 29.8879 33.0394V34.4962V34.9962H30.3879H31.1691H31.6691V34.4962V33.8312L32.1717 34.7385L32.3145 34.9962H32.6091H33.5025H34.0025V34.4962V34.027C34.0882 34.1593 34.1883 34.2821 34.3028 34.3928L34.3028 34.3928L34.3054 34.3953C34.7726 34.8407 35.4105 35.0356 36.1797 35.0356C36.7468 35.0356 37.2996 34.8981 37.5977 34.796L37.9358 34.6802V34.3229V32.3414V31.8414H37.5776L37.618 31.6861L37.7966 30.9998L37.9025 30.5927L37.5196 30.4187C37.2571 30.2994 36.8094 30.1953 36.2689 30.1953C35.5587 30.1953 34.897 30.4014 34.4046 30.8327C34.2487 30.9692 34.1141 31.1247 34.0025 31.2968V30.7338V30.2338H33.5025H32.721H32.221V30.7338V31.2018L31.8162 30.4875L31.6725 30.2341H31.3812H30.3879H29.8879V30.7341V30.7906C29.8324 30.7293 29.7716 30.6727 29.7058 30.6216C29.3251 30.3121 28.8098 30.2062 28.239 30.2062C27.6994 30.2062 27.3023 30.2409 27.0054 30.2914L26.5892 30.3621V30.7843V33.2136M35.5131 32.6155C35.5131 32.5308 35.521 32.4573 35.5346 32.3932V32.8418C35.5209 32.7756 35.5131 32.7005 35.5131 32.6155ZM28.437 31.964C28.4365 31.9645 28.435 31.966 28.4321 31.9682V31.8722C28.4383 31.8743 28.443 31.8762 28.4466 31.8778C28.4476 31.884 28.4486 31.8933 28.4486 31.9062C28.4486 31.9351 28.444 31.9502 28.442 31.9556C28.4401 31.9605 28.4387 31.9622 28.437 31.964Z"
        fill="#001F50"
        stroke="#001F50"
      />
      <path
        d="M24.5318 30.58H27.5018C28.4818 30.58 28.9718 30.9933 28.9718 31.82V33.66C28.9718 34.4867 28.4818 34.9 27.5018 34.9H26.2818V37H24.5318V30.58ZM27.2218 33.43V32.05C27.2218 31.9033 27.1385 31.83 26.9718 31.83H26.2818V33.65H26.9718C27.1385 33.65 27.2218 33.5767 27.2218 33.43ZM29.6709 30.58H32.6209C33.6009 30.58 34.0909 30.9933 34.0909 31.82V35.76C34.0909 36.5867 33.6009 37 32.6209 37H29.6709V30.58ZM32.3409 35.63V31.95C32.3409 31.8033 32.2576 31.73 32.0909 31.73H31.4209V35.85H32.0909C32.2576 35.85 32.3409 35.7767 32.3409 35.63ZM38.7139 31.83H36.6139V33.39H38.4339V34.54H36.6139V37H34.8639V30.58H38.7139V31.83Z"
        fill="white"
      />
      <path
        d="M64.117 21.6974V17.0508H50.2343C49.8336 17.0509 49.4494 17.2101 49.1661 17.4934C48.8828 17.7768 48.7236 18.161 48.7236 18.5617V43.4398C48.7236 43.8405 48.8828 44.2248 49.1661 44.5081C49.4494 44.7915 49.8336 44.9507 50.2343 44.9508H68.7633C69.164 44.9506 69.5482 44.7914 69.8316 44.5081C70.115 44.2248 70.2743 43.8405 70.2745 43.4398V23.2083H65.6279C65.2272 23.2083 64.8429 23.0491 64.5595 22.7658C64.2762 22.4824 64.117 22.0981 64.117 21.6974ZM54.4825 34.1519C54.4397 34.3916 54.2534 34.5583 53.9896 34.5583H53.9837C53.6988 34.5583 53.4924 34.341 53.4924 34.045C53.4924 33.7381 53.6988 33.526 53.9946 33.526C54.285 33.526 54.4859 33.7325 54.4915 34.045C54.4918 34.0816 54.4887 34.1175 54.4825 34.1519ZM57.4498 32.7727C57.159 33.0406 56.7238 33.1689 56.2216 33.1689C56.11 33.1689 56.0093 33.1637 55.9311 33.1469V34.4973H55.0882V30.7853C55.3508 30.7407 55.7188 30.7072 56.238 30.7072C56.7629 30.7072 57.1426 30.8077 57.3937 31.0141C57.634 31.1986 57.7899 31.511 57.7902 31.8796C57.7902 32.2535 57.673 32.5663 57.4498 32.7727ZM61.5012 34.4973H60.6078L59.8037 33.0455C59.5801 32.6382 59.3297 32.158 59.1511 31.7172H59.1291C59.1573 32.2203 59.1678 32.7501 59.1678 33.3642V34.4973H58.3866L58.3863 34.497V30.7348H59.3796V30.7351L59.9769 31.7885L60.1611 32.1134C60.3846 32.5096 60.6025 32.9786 60.7749 33.4027H60.7864C60.7361 32.906 60.7191 32.3986 60.7191 31.8294V30.7348H61.5012V34.4973ZM65.4345 34.324C65.1719 34.4136 64.6753 34.5366 64.1784 34.5366C63.492 34.5366 62.9951 34.364 62.6491 34.0344C62.3081 33.7049 62.1187 33.2142 62.1187 32.6608C62.1243 31.4109 63.0342 30.6964 64.2677 30.6964C64.7531 30.6964 65.1273 30.7912 65.3114 30.8749L65.1329 31.5613C64.9264 31.4664 64.675 31.3998 64.2621 31.3998C63.5531 31.3998 63.0118 31.8015 63.0118 32.6165C63.0118 33.398 63.5029 33.85 64.2007 33.85C64.3904 33.85 64.5467 33.828 64.6139 33.8001V33.0071H64.0333V32.3425H65.4345V34.324Z"
        fill="#004ECC"
      />
      <path
        d="M56.3772 32.5049C56.7334 32.4559 56.9473 32.2392 56.9473 31.9069C56.9473 31.8207 56.9328 31.7429 56.9039 31.6747C56.8323 31.5048 56.6727 31.396 56.4349 31.3644C56.3845 31.3578 56.3338 31.3546 56.283 31.3548C56.1097 31.3548 55.9925 31.3657 55.9312 31.3827V32.4931C56.0034 32.5102 56.0927 32.5154 56.216 32.5154C56.2725 32.5154 56.3264 32.5117 56.3772 32.5049ZM64.117 17.0508V21.6974C64.117 22.098 64.2762 22.4823 64.5594 22.7657C64.8427 23.049 65.227 23.2082 65.6276 23.2083H70.2745L64.117 17.0508Z"
        fill="#004ECC"
      />
      <path
        opacity="0.6"
        d="M64.1172 17.0508V21.6974C64.1172 22.098 64.2763 22.4823 64.5596 22.7657C64.8429 23.049 65.2271 23.2082 65.6278 23.2083H70.2747L64.1172 17.0508Z"
        fill="white"
      />
      <path
        d="M54.5892 34.8682V34.4962V34.1699L54.4838 34.1509L54.5892 34.1697V34.0436V34.0419V33.2136M54.5892 34.8682L54.9929 34.0436L54.9927 34.0347C54.988 33.7726 54.8992 33.5157 54.7114 33.3205C54.6732 33.2809 54.6324 33.2452 54.5892 33.2136M54.5892 34.8682C54.4251 34.9879 54.2199 35.0573 53.9909 35.0573H53.985C53.4025 35.0573 52.9937 34.5942 52.9937 34.0439C52.9937 33.7764 53.0854 33.5168 53.2749 33.3213C53.4654 33.1248 53.7243 33.025 53.9959 33.025C54.2104 33.025 54.4177 33.0877 54.5892 33.2136M54.5892 34.8682V34.9962H55.0892H55.9321H56.4321V34.4962V33.6613C56.936 33.6289 57.4248 33.4754 57.7896 33.1395L57.7901 33.139C57.8246 33.1071 57.8572 33.0738 57.8879 33.0394V34.4962V34.9962H58.3879H59.1691H59.6691V34.4962V33.8312L60.1717 34.7385L60.3145 34.9962H60.6091H61.5025H62.0025V34.4962V34.027C62.0882 34.1593 62.1883 34.2821 62.3028 34.3928L62.3028 34.3928L62.3054 34.3953C62.7726 34.8407 63.4105 35.0356 64.1797 35.0356C64.7468 35.0356 65.2996 34.8981 65.5977 34.796L65.9358 34.6802V34.3229V32.3414V31.8414H65.5776L65.618 31.6861L65.7966 30.9998L65.9025 30.5927L65.5196 30.4187C65.2571 30.2994 64.8094 30.1953 64.2689 30.1953C63.5587 30.1953 62.897 30.4014 62.4046 30.8327C62.2487 30.9692 62.1141 31.1247 62.0025 31.2968V30.7338V30.2338H61.5025H60.721H60.221V30.7338V31.2018L59.8162 30.4875L59.6725 30.2341H59.3812H58.3879H57.8879V30.7341V30.7906C57.8324 30.7293 57.7716 30.6727 57.7058 30.6216C57.3251 30.3121 56.8098 30.2062 56.239 30.2062C55.6994 30.2062 55.3023 30.2409 55.0054 30.2914L54.5892 30.3621V30.7843V33.2136M63.5131 32.6155C63.5131 32.5308 63.521 32.4573 63.5346 32.3932V32.8418C63.5209 32.7756 63.5131 32.7005 63.5131 32.6155ZM56.437 31.964C56.4365 31.9645 56.435 31.966 56.4321 31.9682V31.8722C56.4383 31.8743 56.443 31.8762 56.4466 31.8778C56.4476 31.884 56.4486 31.8933 56.4486 31.9062C56.4486 31.9351 56.444 31.9502 56.442 31.9556C56.4401 31.9605 56.4387 31.9622 56.437 31.964Z"
        fill="#004ECC"
        stroke="#004ECC"
      />
      <path
        d="M52.2904 34.43H54.0404V35.53C54.0404 35.6767 54.1238 35.75 54.2904 35.75H54.4004C54.5671 35.75 54.6504 35.6767 54.6504 35.53V30.58H56.4004V35.76C56.4004 36.5867 55.9104 37 54.9304 37H53.7604C52.7804 37 52.2904 36.5867 52.2904 35.76V34.43ZM57.1607 30.58H60.1307C61.1107 30.58 61.6007 30.9933 61.6007 31.82V33.66C61.6007 34.4867 61.1107 34.9 60.1307 34.9H58.9107V37H57.1607V30.58ZM59.8507 33.43V32.05C59.8507 31.9033 59.7674 31.83 59.6007 31.83H58.9107V33.65H59.6007C59.7674 33.65 59.8507 33.5767 59.8507 33.43ZM66.5098 32.78H64.7598V31.95C64.7598 31.8033 64.6765 31.73 64.5098 31.73H64.1498C63.9831 31.73 63.8998 31.8033 63.8998 31.95V35.63C63.8998 35.7767 63.9831 35.85 64.1498 35.85H64.5098C64.6765 35.85 64.7598 35.7767 64.7598 35.63V34.56H64.2598V33.45H66.5098V35.76C66.5098 36.5867 66.0198 37 65.0398 37H63.6198C62.6398 37 62.1498 36.5867 62.1498 35.76V31.82C62.1498 30.9933 62.6398 30.58 63.6198 30.58H65.0398C66.0198 30.58 66.5098 30.9933 66.5098 31.82V32.78Z"
        fill="white"
      />
      <path
        d="M92.117 21.6974V17.0508H78.2343C77.8336 17.0509 77.4494 17.2101 77.1661 17.4934C76.8828 17.7768 76.7236 18.161 76.7236 18.5617V43.4398C76.7236 43.8405 76.8828 44.2248 77.1661 44.5081C77.4494 44.7915 77.8336 44.9507 78.2343 44.9508H96.7633C97.164 44.9506 97.5482 44.7914 97.8316 44.5081C98.115 44.2248 98.2743 43.8405 98.2745 43.4398V23.2083H93.6279C93.2272 23.2083 92.8429 23.0491 92.5595 22.7658C92.2762 22.4824 92.117 22.0981 92.117 21.6974ZM82.4825 34.1519C82.4397 34.3916 82.2534 34.5583 81.9896 34.5583H81.9837C81.6988 34.5583 81.4924 34.341 81.4924 34.045C81.4924 33.7381 81.6988 33.526 81.9946 33.526C82.285 33.526 82.4859 33.7325 82.4915 34.045C82.4918 34.0816 82.4887 34.1175 82.4825 34.1519ZM85.4498 32.7727C85.159 33.0406 84.7238 33.1689 84.2216 33.1689C84.11 33.1689 84.0093 33.1637 83.9311 33.1469V34.4973H83.0882V30.7853C83.3508 30.7407 83.7188 30.7072 84.238 30.7072C84.7629 30.7072 85.1426 30.8077 85.3937 31.0141C85.634 31.1986 85.7899 31.511 85.7902 31.8796C85.7902 32.2535 85.673 32.5663 85.4498 32.7727ZM89.5012 34.4973H88.6078L87.8037 33.0455C87.5801 32.6382 87.3297 32.158 87.1511 31.7172H87.1291C87.1573 32.2203 87.1678 32.7501 87.1678 33.3642V34.4973H86.3866L86.3863 34.497V30.7348H87.3796V30.7351L87.9769 31.7885L88.1611 32.1134C88.3846 32.5096 88.6025 32.9786 88.7749 33.4027H88.7864C88.7361 32.906 88.7191 32.3986 88.7191 31.8294V30.7348H89.5012V34.4973ZM93.4345 34.324C93.1719 34.4136 92.6753 34.5366 92.1784 34.5366C91.492 34.5366 90.9951 34.364 90.6491 34.0344C90.3081 33.7049 90.1187 33.2142 90.1187 32.6608C90.1243 31.4109 91.0342 30.6964 92.2677 30.6964C92.7531 30.6964 93.1273 30.7912 93.3114 30.8749L93.1329 31.5613C92.9264 31.4664 92.675 31.3998 92.2621 31.3998C91.5531 31.3998 91.0118 31.8015 91.0118 32.6165C91.0118 33.398 91.5029 33.85 92.2007 33.85C92.3904 33.85 92.5467 33.828 92.6139 33.8001V33.0071H92.0333V32.3425H93.4345V34.324Z"
        fill="#7389FF"
      />
      <path
        d="M84.3772 32.5049C84.7334 32.4559 84.9473 32.2392 84.9473 31.9069C84.9473 31.8207 84.9328 31.7429 84.9039 31.6747C84.8323 31.5048 84.6727 31.396 84.4349 31.3644C84.3845 31.3578 84.3338 31.3546 84.283 31.3548C84.1097 31.3548 83.9925 31.3657 83.9312 31.3827V32.4931C84.0034 32.5102 84.0927 32.5154 84.216 32.5154C84.2725 32.5154 84.3264 32.5117 84.3772 32.5049ZM92.117 17.0508V21.6974C92.117 22.098 92.2762 22.4823 92.5594 22.7657C92.8427 23.049 93.227 23.2082 93.6276 23.2083H98.2745L92.117 17.0508Z"
        fill="#7389FF"
      />
      <path
        opacity="0.6"
        d="M92.1172 17.0508V21.6974C92.1172 22.098 92.2763 22.4823 92.5596 22.7657C92.8429 23.049 93.2271 23.2082 93.6278 23.2083H98.2747L92.1172 17.0508Z"
        fill="white"
      />
      <path
        d="M82.5892 34.8682V34.4962V34.1699L82.4838 34.1509L82.5892 34.1697V34.0436V34.0419V33.2136M82.5892 34.8682L82.9929 34.0436L82.9927 34.0347C82.988 33.7726 82.8992 33.5157 82.7114 33.3205C82.6732 33.2809 82.6324 33.2452 82.5892 33.2136M82.5892 34.8682C82.4251 34.9879 82.2199 35.0573 81.9909 35.0573H81.985C81.4025 35.0573 80.9937 34.5942 80.9937 34.0439C80.9937 33.7764 81.0854 33.5168 81.2749 33.3213C81.4654 33.1248 81.7243 33.025 81.9959 33.025C82.2104 33.025 82.4177 33.0877 82.5892 33.2136M82.5892 34.8682V34.9962H83.0892H83.9321H84.4321V34.4962V33.6613C84.936 33.6289 85.4248 33.4754 85.7896 33.1395L85.7901 33.139C85.8246 33.1071 85.8572 33.0738 85.8879 33.0394V34.4962V34.9962H86.3879H87.1691H87.6691V34.4962V33.8312L88.1717 34.7385L88.3145 34.9962H88.6091H89.5025H90.0025V34.4962V34.027C90.0882 34.1593 90.1883 34.2821 90.3028 34.3928L90.3028 34.3928L90.3054 34.3953C90.7726 34.8407 91.4105 35.0356 92.1797 35.0356C92.7468 35.0356 93.2996 34.8981 93.5977 34.796L93.9358 34.6802V34.3229V32.3414V31.8414H93.5776L93.618 31.6861L93.7966 30.9998L93.9025 30.5927L93.5196 30.4187C93.2571 30.2994 92.8094 30.1953 92.2689 30.1953C91.5587 30.1953 90.897 30.4014 90.4046 30.8327C90.2487 30.9692 90.1141 31.1247 90.0025 31.2968V30.7338V30.2338H89.5025H88.721H88.221V30.7338V31.2018L87.8162 30.4875L87.6725 30.2341H87.3812H86.3879H85.8879V30.7341V30.7906C85.8324 30.7293 85.7716 30.6727 85.7058 30.6216C85.3251 30.3121 84.8098 30.2062 84.239 30.2062C83.6994 30.2062 83.3023 30.2409 83.0054 30.2914L82.5892 30.3621V30.7843V33.2136M91.5131 32.6155C91.5131 32.5308 91.521 32.4573 91.5346 32.3932V32.8418C91.5209 32.7756 91.5131 32.7005 91.5131 32.6155ZM84.437 31.964C84.4365 31.9645 84.435 31.966 84.4321 31.9682V31.8722C84.4383 31.8743 84.443 31.8762 84.4466 31.8778C84.4476 31.884 84.4486 31.8933 84.4486 31.9062C84.4486 31.9351 84.444 31.9502 84.442 31.9556C84.4401 31.9605 84.4387 31.9622 84.437 31.964Z"
        fill="#7389FF"
        stroke="#7389FF"
      />
      <path
        d="M79.7096 30.58H82.6796C83.6596 30.58 84.1496 30.9933 84.1496 31.82V33.66C84.1496 34.4867 83.6596 34.9 82.6796 34.9H81.4596V37H79.7096V30.58ZM82.3996 33.43V32.05C82.3996 31.9033 82.3162 31.83 82.1496 31.83H81.4596V33.65H82.1496C82.3162 33.65 82.3996 33.5767 82.3996 33.43ZM87.6786 30.58H89.2786V37H87.7786L86.3486 33.63V37H84.7486V30.58H86.2486L87.6786 34.01V30.58ZM94.3907 32.78H92.6407V31.95C92.6407 31.8033 92.5573 31.73 92.3907 31.73H92.0307C91.864 31.73 91.7807 31.8033 91.7807 31.95V35.63C91.7807 35.7767 91.864 35.85 92.0307 35.85H92.3907C92.5573 35.85 92.6407 35.7767 92.6407 35.63V34.56H92.1407V33.45H94.3907V35.76C94.3907 36.5867 93.9007 37 92.9207 37H91.5007C90.5207 37 90.0307 36.5867 90.0307 35.76V31.82C90.0307 30.9933 90.5207 30.58 91.5007 30.58H92.9207C93.9007 30.58 94.3907 30.9933 94.3907 31.82V32.78Z"
        fill="white"
      />
    </svg>
  );
}

export default IlustrationDocumentsTypes;
