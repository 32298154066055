import styled from '@emotion/styled';
import { ArrowRight, Close } from '@ampli/icon';
import { getColor, Popover } from '@ampli/ui';
import { convertPXToREM, convertPXToVH, convertPXToVW } from '@sofia/ui';

export const Title = styled.h1`
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
  line-height: 115%;
  color: ${getColor('neutral-900')};
`;

export const Description = styled.p`
  font-weight: 400;
  text-align: start;
  font-size: ${convertPXToREM(16)};
  line-height: 150%;
  color: ${getColor('neutral-900')};
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: ${convertPXToREM(16)};
  line-height: 115%;
  color: ${getColor('primary-500')};
  border: none;
  margin: 0;
  padding: 0;
  background: none;
`;

export const ArrowRightIcon = styled(ArrowRight)`
  width: ${convertPXToREM(18)};
  height: ${convertPXToREM(18)};
  margin: 0 !important;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  position: absolute;
  right: ${convertPXToREM(21)};
`;

export const StyledPopover = styled(Popover)`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: ${convertPXToVW(345)};
  box-shadow: 0 ${convertPXToREM(8)} ${convertPXToREM(60)}
    rgba(33, 37, 41, 0.12);
  border-radius: ${convertPXToREM(8)};
  padding: ${convertPXToVH(24)};
`;
