import { useState, useEffect } from 'react';
import { includes } from 'lodash';
import { notActiveFreeTrialStatus } from '../constants';

const useFreeTrialRegressiveCounter = (studentFreeTrialCourseData) => {
  const [showFreeTrialCounter, setShowFreeTrialCounter] = useState(false);

  useEffect(() => {
    if (studentFreeTrialCourseData) {
      setShowFreeTrialCounter(
        (studentFreeTrialCourseData.freeTrialDaysLeft &&
          !includes(
            notActiveFreeTrialStatus,
            studentFreeTrialCourseData.freeTrialSituation
          )) ||
          studentFreeTrialCourseData.freeTrialSituation === 'EXPIRED'
      );
    }
  }, [studentFreeTrialCourseData]);

  return {
    showFreeTrialCounter,
    freeTrialDaysLeft: studentFreeTrialCourseData?.freeTrialDaysLeft,
    freeTrialSituation: studentFreeTrialCourseData?.freeTrialSituation,
  };
};

export default useFreeTrialRegressiveCounter;
