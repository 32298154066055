import React from 'react';
import { Typography, Backdrop, ButtonGhost, SvgTimes } from '@sofia/ui';
import { useTheme } from '@emotion/react';
import * as Styled from './new-feature-communication-styles';
import { NewFeatureCommunicationProps } from './types';
import { trapFocus } from '@ampli/utils';

const NewFeatureCommunication = ({
  anchorEl,
  handleClose,
  handleUnderstood,
  shouldCloseOnEsc,
  shouldCloseOnBackdrop,
  shouldCloseOnClickOutside,
  backdrop,
  title,
  message,
  actions,
  anchorOrigin,
  transformOrigin,
  arrowPosition,
  closeXBtn,
  absoluteTopSpace,
}: NewFeatureCommunicationProps): React.ReactElement => {
  const theme = useTheme();
  const popUpRef = React.useRef(null);
  const dialogRef = React.useRef<HTMLDivElement>(null);
  const ESC_KEY = 'Escape';
  const handleClickOutside = (event: MouseEvent) => {
    if (
      !dialogRef.current?.contains(event.target as HTMLDivElement) &&
      shouldCloseOnClickOutside
    )
      handleClose();
  };

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (shouldCloseOnEsc && event.key === ESC_KEY) {
        handleClose();
      }
      trapFocus(popUpRef.current, event);
    };

    if (!shouldCloseOnBackdrop) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      if (!shouldCloseOnBackdrop) {
        document.addEventListener('mousedown', handleClickOutside);
      }
    };
  });

  return (
    <Backdrop
      ref={popUpRef}
      data-testid="backdrop"
      isBackdrop={backdrop}
      id="modalBackdrop"
      stopPropagation={true}
      {...(shouldCloseOnBackdrop &&
        shouldCloseOnClickOutside && {
          onClick: handleClickOutside,
        })}
    >
      <Styled.MuiPopover
        open
        data-testid="new-feature-communication"
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        ref={dialogRef}
        transformOrigin={transformOrigin}
        absoluteTopSpace={absoluteTopSpace || 0}
        arrowPosition={arrowPosition}
      >
        <Styled.AbsoluteTopSpace height={absoluteTopSpace || 0} />
        <Styled.Container role="alert">
          {closeXBtn ? (
            <Styled.XBtnWrapper onClick={handleClose}>
              <SvgTimes fill={theme.colors.text.lightBlack} />
            </Styled.XBtnWrapper>
          ) : null}
          {typeof title === 'string' ? (
            <Typography size={16} weight={700} color={theme.colors.text.black}>
              {title}
            </Typography>
          ) : (
            title || null
          )}
          {message ? (
            <Typography
              size={14}
              color={theme.colors.text.black}
              margin={`${title ? '8px' : 0} 0 0 0`}
            >
              {message}
            </Typography>
          ) : null}
          {actions ? (
            <Styled.ActionsContainer data-testid="new-feature-communication-actions">
              {actions?.close ? (
                <ButtonGhost
                  aria-label={`Fechar notificação`}
                  data-testid="new-feature-communication-close"
                  onClick={handleClose}
                  color={theme.colors.text.black}
                  hoverColor={theme.colors.button.darkPrimary}
                  padding="8px"
                >
                  <Typography weight={700}>
                    <Styled.InnerText>Fechar</Styled.InnerText>
                  </Typography>
                </ButtonGhost>
              ) : null}
              {actions?.understood ? (
                <ButtonGhost
                  aria-label={`Entendi, fechar notificação`}
                  data-testid="new-feature-communication-understood"
                  onClick={handleUnderstood}
                  color={theme.colors.text.primary}
                  hoverColor={theme.colors.button.darkPrimary}
                  padding="8px"
                >
                  <Typography weight={700}>
                    <Styled.InnerText>Entendi</Styled.InnerText>
                  </Typography>
                </ButtonGhost>
              ) : null}
            </Styled.ActionsContainer>
          ) : null}
        </Styled.Container>
      </Styled.MuiPopover>
    </Backdrop>
  );
};

NewFeatureCommunication.defaultProps = {
  actions: {
    close: true,
    understood: true,
  },
  closeXBtn: false,
  shouldCloseOnEsc: true,
  shouldCloseOnBackdrop: true,
  shouldCloseOnClickOutside: true,
  backdrop: false,
  arrowPosition: 'center',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export default NewFeatureCommunication;
