export enum ScholarshipDocumentStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REFUSED = 'REFUSED',
  REVIEW = 'REVIEW',
}

export enum ScholarshipDocumentRequestStatusEnum {
  APPROVED = 'APPROVED',
  REPROVED = 'REFUSED',
  PENDING = 'PENDING',
  UNDEFINED = 'UNDEFINED',
  SENT = 'SENT',
}
