import styled from '@emotion/styled';
import { TabList as _TabList } from '@sofia/ui';

export const TabList = styled(_TabList)`
  display: flex;
  flex-wrap: 50%;
  > :not(:last-of-type) {
    margin-right: 16px;
  }
`;
