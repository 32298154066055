export const convertAndApplyMaskNumber = (value, mask) => {
  if (!value) {
    return;
  }
  const MAX_LENGTH = clear(mask).length;
  const newValue = clear(value);
  const nextLength = newValue.length;
  if (nextLength > MAX_LENGTH) return value.slice(0, mask.length);
  return applyMask(newValue, mask);
  function clear(value) {
    return value.replace(/[^0-9]/g, '');
  }
  function applyMask(value, mask) {
    let result = '';
    let inc = 0;
    Array.from(value).forEach((letter, index) => {
      if (!mask[index + inc].match(/[0-9]/)) {
        result += mask[index + inc];
        inc++;
      }
      result += letter;
    });
    return result;
  }
};
export default convertAndApplyMaskNumber;