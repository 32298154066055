const handleIgnoreSpaceKeyPress = (event: {
  key: string;
  preventDefault: () => void;
}): void => {
  if (event.key === ' ') {
    event.preventDefault();
  }
};

export default handleIgnoreSpaceKeyPress;
