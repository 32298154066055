import React from 'react';

function SvgPersonDocumentFrontImage(props) {
  return (
    <svg viewBox="0 0 56 41" width={56} height={41} fill="none" {...props}>
      <path
        d="M8.812 12.393a1.504 1.504 0 011.699-1.279l11.741 1.657a1.504 1.504 0 011.279 1.699l-2.142 15.182a1.504 1.504 0 01-1.7 1.279L7.95 29.274a1.504 1.504 0 01-1.28-1.699l2.143-15.182z"
        fill="#fff"
      />
      <path
        d="M8.812 12.393a1.504 1.504 0 011.699-1.279l11.741 1.657a1.504 1.504 0 011.279 1.699l-2.142 15.182a1.504 1.504 0 01-1.7 1.279L7.95 29.274a1.504 1.504 0 01-1.28-1.699l2.143-15.182z"
        fill="#fff"
      />
      <path
        d="M15.24 23.974a5.468 5.468 0 10.883-10.9 5.468 5.468 0 00-.882 10.9z"
        fill="#003D51"
      />
      <path
        d="M13.337 22.74s.204 2.051-.183 2.266c-.387.215 2.187 3.444 2.187 3.444l1.247-.947.561-2.358s-.978-1.62-.38-2.928c.6-1.307-3.432.524-3.432.524z"
        fill="#9F616A"
      />
      <path
        d="M15.37 23.521a3.932 3.932 0 10.634-7.838 3.932 3.932 0 00-.634 7.838z"
        fill="#9F616A"
      />
      <path
        d="M14.658 26.977s-1.154-1.88-1.695-2.664c-.842-.253-1.648-.195-2.143-.174-.495.022-1.846.03-2.86.38-1.015.349-1.911 4.756-1.911 4.756s.014-.345.37 1.348c4.388 1.189 8.617 2.165 14.065 1.225.585-.384.918-1.844.918-1.844L20.75 27.1s-.197-1.372-1.478-1.538c-1.28-.165-2.374-.933-2.374-.933l-1.566 2.402-.674-.055z"
        fill="#FFB800"
      />
      <path
        d="M15.601 18.763c1.928.156 3.555-.512 3.634-1.493.08-.98-1.419-1.902-3.346-2.058-1.928-.156-3.555.512-3.635 1.493-.079.98 1.42 1.902 3.347 2.058z"
        fill="#003D51"
      />
      <path
        d="M7.08.882a1.504 1.504 0 00-1.699 1.279L1.11 32.444a1.504 1.504 0 001.279 1.7l46.531 6.565a1.504 1.504 0 001.7-1.28L54.89 9.147a1.504 1.504 0 00-1.279-1.699L7.081.882zm3.43 10.232l11.742 1.657a1.504 1.504 0 011.279 1.699l-2.142 15.182a1.504 1.504 0 01-1.7 1.279L7.95 29.274a1.504 1.504 0 01-1.28-1.699l2.143-15.182a1.504 1.504 0 011.699-1.279z"
        fill="#99CAD2"
      />
      <path
        d="M51.71 9.878c.205.029.348.22.32.425l-3.842 27.224a.376.376 0 01-.425.32L4.29 31.714a.376.376 0 01-.32-.425L7.812 4.064a.376.376 0 01.424-.32L51.71 9.878zM8.393 2.628a1.504 1.504 0 00-1.7 1.278l-3.84 27.225a1.504 1.504 0 001.279 1.7l43.473 6.133a1.504 1.504 0 001.699-1.279l3.84-27.225a1.504 1.504 0 00-1.278-1.699L8.394 2.628z"
        fill="#fff"
      />
      <path
        d="M9.567 7.04a1.504 1.504 0 011.7-1.278l36.973 5.217a1.504 1.504 0 011.279 1.699l-.066.462a1.504 1.504 0 01-1.699 1.28L10.781 9.202a1.504 1.504 0 01-1.28-1.7l.066-.462z"
        fill="#000"
        fillOpacity={0.1}
      />
      <path
        d="M47.3 18.039a.696.696 0 11-.194 1.378l-19.88-2.805a.696.696 0 01.194-1.378l19.88 2.805zm-19.775-3.55a1.448 1.448 0 10-.405 2.868L47 20.162a1.448 1.448 0 00.405-2.868l-19.88-2.805zM46.626 22.818a.696.696 0 11-.195 1.378l-19.88-2.805a.696.696 0 01.195-1.378l19.88 2.805zm-19.775-3.55a1.448 1.448 0 10-.405 2.868l19.88 2.805a1.448 1.448 0 00.405-2.868l-19.88-2.805zM45.896 27.59a.752.752 0 01.64.849l-.551 3.903a.752.752 0 01-.85.64l-19.77-2.79a.752.752 0 01-.639-.85l.551-3.903a.752.752 0 01.85-.64l19.77 2.79zm-19.665-3.535a1.504 1.504 0 00-1.699 1.28l-.55 3.903a1.504 1.504 0 001.279 1.699l19.77 2.79a1.504 1.504 0 001.698-1.28l.551-3.903a1.504 1.504 0 00-1.279-1.7l-19.77-2.789z"
        fill="#000"
        fillOpacity={0.15}
      />
    </svg>
  );
}

export default SvgPersonDocumentFrontImage;
