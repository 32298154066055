import styled from '@emotion/styled';
import { convertPXToVH, mediaQuery } from '@sofia/ui';

const Flex = styled.div`
  display: flex;
`;

export const HomePage = {
  Heading: styled.div`
    margin-bottom: ${convertPXToVH(32)};

    ${mediaQuery('desktop')} {
      margin-bottom: ${convertPXToVH(56)};
    }
  `,
  ContentWrapper: styled.div`
    ${mediaQuery('desktop')} {
      margin-bottom: ${convertPXToVH(80)};
    }
  `,
};

export const SubjectPage = {
  Heading: styled(Flex)`
    align-items: center;
    margin-bottom: ${convertPXToVH(32)};
    ${mediaQuery('desktop')} {
      margin-bottom: ${convertPXToVH(56)};
      column-gap: 8px;
    }
  `,
  HeadingText: styled.div`
    flex: 1;
  `,
  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${convertPXToVH(24)};

    ${mediaQuery('desktop')} {
      margin-bottom: ${convertPXToVH(80)};
    }
  `,
};

export const LearningUnitPage = {
  Heading: styled(Flex)`
    margin-bottom: ${convertPXToVH(8)};
    flex-direction: column;
    row-gap: 8px;

    ${mediaQuery('desktop')} {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  `,
  HeadingButtons: styled(Flex)`
    align-items: center;
    justify-content: flex-end;
  `,
  ContentWrapper: styled.div`
    margin-top: ${convertPXToVH(56)};

    ${mediaQuery('desktop')} {
      margin-top: ${convertPXToVH(80)};
    }
  `,
};
