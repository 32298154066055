import styled from '@emotion/styled';
import SvgTimes from '../Icons/times';
import { convertPXToVW } from '../../index';
export const DialogBackdrop = styled.div`
  background-color: ${_ref => {
  let {
    backdrop
  } = _ref;
  return backdrop ? 'rgba(0, 0, 0, 0.5)' : 'transparent';
}};
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;
export const DialogWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
export const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  margin: auto;
  overflow-x: hidden;
  max-height: 90%;
  width: ${_ref2 => {
  let {
    width = 456
  } = _ref2;
  return convertPXToVW(width);
}};

  position: relative;
  z-index: 1001;

  ${_ref3 => {
  let {
    open
  } = _ref3;
  return open ? `opacity: 1; visibility: visible;` : `opacity: 0; visibility: hidden;`;
}}

  transition: all 150ms ease-in-out;
`;
export const BottomSheet = styled.div`
  width: 100%;
  z-index: 1001;
  background: white;
  border-radius: 16px 16px 0px 0px;
  overflow-x: hidden;
  overflow-y: auto;

  bottom: 0;
  position: fixed;

  ${_ref4 => {
  let {
    open
  } = _ref4;
  return open ? `max-height: 90vh;` : `max-height: 0;`;
}}
  transition: all 150ms ease-in-out;
`;
export const HeaderTitle = styled.span`
  p {
    margin: ${_ref5 => {
  let {
    margin = '32px'
  } = _ref5;
  return margin;
}};
    font-weight: 700;
    line-height: 120%;
  }
`;
export const CloseIcon = styled(SvgTimes)`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
`;
export const IconWrapper = styled.div`
  padding: 14px;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: calc(100% - 48px);
  :hover {
    background: #f3f6f9;
  }
`;
export const ContentWrapper = styled.div`
  padding: ${_ref6 => {
  let {
    padding = '0 32px'
  } = _ref6;
  return padding;
}};
  overflow-y: auto;
`;
export const FooterWrapper = styled.div`
  height: 56px;
  margin: 40px 32px 32px;
  button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;