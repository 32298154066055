import { MeType } from '../../../../shared';
import { TLearningObject, TSectionEnrollment } from '../../../shared';
import {
  TFindLearningUnitEnrollmentType,
  ContentEvaluationDataProps,
} from '../../../types';

export type CreateDefaultContentEvaluationProps = {
  me: MeType | Record<string, never>;
  learningObject: TLearningObject | Record<string, never>;
  learningUnit: TFindLearningUnitEnrollmentType | Record<string, never>;
  subjectEnrollmentId: string;
  subjectId: string;
  courseId: string;
  section: TSectionEnrollment | Record<string, never>;
  selectedCourseEnrollmentId: string;
  selectedCourseEnrollmentType: string;
  selectedCourseVersionId: string;
};

type CreateDefaultContentEvaluation = (
  props: CreateDefaultContentEvaluationProps
) => ContentEvaluationDataProps;

const createDefaultContentEvaluation: CreateDefaultContentEvaluation = ({
  me,
  learningObject,
  learningUnit,
  subjectId,
  subjectEnrollmentId,
  courseId,
  section,
  selectedCourseEnrollmentId,
  selectedCourseEnrollmentType,
  selectedCourseVersionId,
}) => {
  return (
    learningUnit && {
      studentId: me.personId,
      courseId,
      learningUnitEnrollmentId: learningUnit.id,
      sectionId: section.id,
      learningObjectId: learningObject.id,
      courseTypeCode: selectedCourseEnrollmentType,
      courseVersionId: selectedCourseVersionId,
      courseEnrollmentId: selectedCourseEnrollmentId,
      subjectId,
      subjectEnrollmentId,
      learningUnitId: learningUnit.tempLearningUnitId || '',
      learningUnitVersionId: learningUnit.learningUnitId,
      rating: 0,
      comment: '',
      client: 'web',
      createdDate: '',
    }
  );
};

export default createDefaultContentEvaluation;
