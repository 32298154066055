import React from 'react';
import {
  useModal,
  Typography,
  SvgCalendarAlt,
  SvgClock,
  SvgMapMaker,
  ButtonGhost,
  SvgInfoCircle,
  Separator,
  SvgAngleLeft,
} from '@sofia/ui';
import {
  handleCopyToClipboard,
  PopUp,
  useBreakpoint,
  useInternLinksCTA,
} from '../../../../modules';
import {
  CardModalWrapper,
  TitleModal,
  ButtonWrapper,
  MessageBox,
  MobileWrapper,
  MobileHeaderWrapper,
  MobileHeader,
  MobileHeaderText,
  DescriptionModal,
  CardModalLocationWrapper,
  Footer,
} from './card-modal.styles';
import { useTheme } from '@emotion/react';
import { string, object } from 'prop-types';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

const CardModal = React.forwardRef(
  (
    {
      content,
      name,
      description,
      startDate,
      endDate,
      color,
      message,
      address,
      infoType,
      redirect,
      timer,
      subType,
      subTypesDictionary,
    },
    ref
  ) => {
    const FORMAT_DATE = "dd 'de' MMM'.' 'de' yyyy";
    const modal = useModal();
    const { isDesktop } = useBreakpoint();
    const theme = useTheme();

    const [mobileModalState, setMobileModalState] = React.useState(false);

    const handleShowModal = () => modal.show();
    const handleHideModal = () => modal.hide();

    React.useImperativeHandle(ref, () => ({
      onOpen() {
        handleShowModal();
      },
      onClose() {
        handleHideModal();
      },
    }));

    const goToRedirectLink = useInternLinksCTA(redirect?.redirectLink);

    const handleAction = () => {
      if (redirect.isExternal) {
        window.open(redirect.redirectLink, '_blank')?.focus();
      } else {
        goToRedirectLink();
      }
    };

    const baseComponent = () => {
      return (
        <>
          {isDesktop ? (
            <Typography size={32} weight={700} margin="0 0 16px 0">
              {subTypesDictionary[subType]}
            </Typography>
          ) : null}

          <TitleModal Bcolor={color} weight="bold" size="24">
            {name}
          </TitleModal>

          {description ? (
            <Typography color={theme.colors.text.light} margin="12px 16px">
              {description}
            </Typography>
          ) : null}

          <CardModalWrapper>
            <SvgCalendarAlt />
            {endDate ? (
              <Typography margin="0 0 0 16px">
                {format(parseISO(startDate), FORMAT_DATE, {
                  locale: pt,
                })}{' '}
                a{' '}
                {format(parseISO(endDate), FORMAT_DATE, {
                  locale: pt,
                })}
              </Typography>
            ) : (
              <Typography margin="0 0 0 16px" size="16">
                {format(parseISO(startDate), FORMAT_DATE, {
                  locale: pt,
                })}
              </Typography>
            )}
          </CardModalWrapper>

          {timer ? (
            <CardModalWrapper>
              <SvgClock
                stroke={theme.colors.text.secondary}
                fill={theme.colors.text.secondary}
              />
              <Typography margin="0 0 0 16px" size="16">
                {timer}
              </Typography>
            </CardModalWrapper>
          ) : null}

          {address ? (
            <CardModalLocationWrapper
              onClick={() =>
                handleCopyToClipboard(
                  address,
                  'Endereço copiado para a sua área de transferencia.'
                )
              }
            >
              <SvgMapMaker />
              <Typography margin="0 0 0 16px" size="16">
                {address}
              </Typography>
            </CardModalLocationWrapper>
          ) : null}

          {message ? (
            <MessageBox type={infoType}>
              <SvgInfoCircle
                fill={
                  infoType === 'INFO'
                    ? theme.colors.text.primary
                    : theme.colors.text.warning
                }
              />
              <Typography margin="0">{message}</Typography>
            </MessageBox>
          ) : null}
          {redirect ? (
            <ButtonWrapper>
              <ButtonGhost
                data-testId="action-button-card-modal"
                id="action-button-card-modal"
                color={theme.colors.text.primary}
                onClick={handleAction}
              >
                {redirect.label}
              </ButtonGhost>
            </ButtonWrapper>
          ) : null}
        </>
      );
    };

    React.useEffect(() => {
      if (!isDesktop) {
        setMobileModalState(modal.state);
      }
    }, [isDesktop, modal.state]);

    if (isDesktop) {
      return (
        <PopUp
          ariaLabel={description}
          isDesktop={isDesktop}
          popUpState={modal}
          footer={<Footer />}
        >
          {content || baseComponent()}
        </PopUp>
      );
    }

    return (
      <MobileWrapper isModalOpen={mobileModalState}>
        <MobileHeaderWrapper>
          <MobileHeader onClick={handleHideModal}>
            <SvgAngleLeft />
            <MobileHeaderText>
              <DescriptionModal
                size={16}
                color={theme.colors.text.secondary}
                weight={700}
                data-testid="mobile-title-security"
                id="mobile-title-security"
              >
                {subTypesDictionary[subType]}
              </DescriptionModal>
            </MobileHeaderText>
          </MobileHeader>
          <Separator />
        </MobileHeaderWrapper>

        {baseComponent()}
      </MobileWrapper>
    );
  }
);

CardModal.displayName = 'CardModal';

CardModal.propTypes = {
  content: string,
  name: string,
  description: string,
  startDate: string,
  endDate: string,
  message: string,
  infoType: string,
  color: string,
  address: string,
  redirect: object,
  timer: string,
  subType: string,
  subTypesDictionary: object,
};

export default CardModal;
