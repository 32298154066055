import { Interval } from '@ampli/utils';
const durationInMinutes = {
  read(originalValue, _ref) {
    let {
      readField
    } = _ref;
    const finishedDate = readField('finishedDate');
    if (finishedDate) {
      const startedDate = readField('startedDate');
      const interval = Interval.fromISO(`${startedDate}/${finishedDate}`);
      return parseInt(interval.length('minutes'), 10);
    }
    return originalValue;
  }
};
export default {
  fields: {
    durationInMinutes
  }
};