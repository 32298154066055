import { useQuery, useMutation } from '@ampli/services';

import * as queries from './queries';

type QueryResult<T> = {
  loading: boolean;
  error: boolean;
  data: T;
};

export const useGetCourseEnrollmentRolesAndStatus = <T>(
  courseEnrollmentId: string,
  options = {}
): QueryResult<T> => {
  const { data, loading, ...rest } = useQuery(
    queries.GET_COURSE_ENROLLMENT_ROLES_AND_STATUS,
    {
      ...options,
      variables: { courseEnrollmentId },
    }
  );

  return {
    data: loading || !data?.data ? {} : data?.data,
    loading,
    ...rest,
  };
};

export const useGetSubjectName = <T>(
  subjectEnrollmentId: string,
  options = {}
): QueryResult<T> => {
  const { loading, data, ...rest } = useQuery(queries.GET_SUBJECT_NAME, {
    ...options,
    variables: { subjectEnrollmentId },
  });

  return {
    data: loading || !data ? {} : data.data,
    loading,
    ...rest,
  };
};

export const useGetCompanyName = <T>(
  courseEnrollmentId: string,
  options = {}
): QueryResult<T> => {
  const { loading, data, ...rest } = useQuery(queries.GET_COMPANY_NAME, {
    ...options,
    variables: { courseEnrollmentId },
  });

  return {
    data: loading || !data ? {} : data.data,
    loading,
    ...rest,
  };
};

export const useGetTutor = <T>(
  courseEnrollmentId: string,
  options = {}
): QueryResult<T> => {
  const { data, loading, ...rest } = useQuery(queries.GET_TUTOR, {
    variables: {
      ...options,
      courseEnrollmentId,
    },
  });

  return {
    data: loading || !data ? {} : data.data.tutors,
    ...rest,
  };
};

export const useGenerateTutorToken = <T>(
  learningUnitEnrollmentId?: string,
  sectionId?: string,
  options = {}
): [func: () => Promise<T>, options: QueryResult<T>] => {
  return useMutation(queries.GENERATE_TUTOR_TOKEN, {
    ...options,
    variables: { learningUnitEnrollmentId, sectionId },
    skip: !learningUnitEnrollmentId || !sectionId,
  });
};
