import React from 'react';
import { func, node, bool, oneOf, string } from 'prop-types';
import { clickByKey } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

import * as Styled from './subject-exams-assignments-button-styles';
import { useTheme } from '@emotion/react';
import {
  Card,
  SvgEditAlt,
  SvgArrowRight,
  SvgFileBookmarkAlt,
  SvgTelescope,
  Typography,
} from '@sofia/ui';

const SubjectExamsAssignmentsButton = ({
  text,
  description,
  completed,
  buttonType,
  onClick,
  disabled,
  iconColor,
}) => {
  const breakpoint = useBreakpoint();
  const isDestkop = breakpoint === 'desktop';
  const theme = useTheme();

  const selectedIcon = {
    exam: <SvgEditAlt aria-hidden />,
    assignment: <SvgFileBookmarkAlt aria-hidden />,
    simulators: <SvgTelescope aria-hidden width="28" height="28" />,
  }[buttonType];
  return (
    <Styled.CardContainer>
      {onClick && (
        <Card
          id="subject-exam-final-card"
          hover={theme.colors.card.color.hover}
          borderColor={theme.colors.card.border.primary}
          focusColor={theme.colors.card.border.third}
          onClick={onClick}
          onKeyDown={(event) => clickByKey(event, onClick)}
          tabIndex={0}
          disabled={disabled}
        >
          <Styled.IconContainer completed={completed} iconColor={iconColor}>
            {selectedIcon}
          </Styled.IconContainer>

          <Styled.CardLabel id="subject-exam-final-text">
            {text}
          </Styled.CardLabel>

          {isDestkop && description && (
            <Styled.CardDesc id="subject-exam-final-description">
              {description}
            </Styled.CardDesc>
          )}
          {onClick && (
            <Styled.FlexFlexEnd>
              {completed ? (
                <Styled.WrapperTextButton>
                  <Typography
                    size={16}
                    weight="bold"
                    color={theme.colors.text.primary}
                  >
                    Ver correção
                  </Typography>
                  <SvgArrowRight fill={theme.colors.text.primary} aria-hidden />
                </Styled.WrapperTextButton>
              ) : (
                <SvgArrowRight fill={theme.colors.text.primary} aria-hidden />
              )}
            </Styled.FlexFlexEnd>
          )}
        </Card>
      )}
    </Styled.CardContainer>
  );
};

SubjectExamsAssignmentsButton.defaultProps = {
  buttonType: 'exam',
};

SubjectExamsAssignmentsButton.propTypes = {
  text: node.isRequired,
  description: node.isRequired,
  onClick: func,
  completed: bool,
  buttonType: oneOf(['exam', 'assignment', 'simulators']),
  disabled: bool,
  iconColor: string,
};

export default SubjectExamsAssignmentsButton;
