import React from 'react';

import { AmpliFrameProvider } from './components/ampli-frame/contexts';
import { withToggleFeature } from './hocs/withToggleFeature';
import { PtkFrameProvider } from './components/ptk-frame/contexts';
import { useGetState } from '../../../../state';
import { InfosProps } from './shared/types';
import { AmpliFrame } from './components/ampli-frame/ampli-frame';
import { PtkFrame } from './components/ptk-frame/ptk-frame';
import { useFrame } from './shared';

export interface TalkTutorFrameProps {
  hideAttachmentField?: boolean;
  infos: InfosProps;
}

export const TalkTutorFrame: React.FC<TalkTutorFrameProps> = ({
  hideAttachmentField = false,
  ...props
}: TalkTutorFrameProps) => {
  const { isAmpli, isFetching } = useFrame();

  const [{ openTalkTutorFrame }, { setOpenTalkTutorFrame }] = useGetState();

  const data = {
    hideAttachmentField,
    isOpen: openTalkTutorFrame,
    onCloseClick: () => setOpenTalkTutorFrame(false),
    ...props,
  };

  if (isFetching) return null;

  const ampli = (
    <AmpliFrameProvider>
      <AmpliFrame {...data} />
    </AmpliFrameProvider>
  );

  const ptk = (
    <PtkFrameProvider>
      <PtkFrame {...data} />
    </PtkFrameProvider>
  );

  const ptkWithToggleValidation = withToggleFeature(ptk, ampli, {
    display: true,
  });

  return isAmpli ? ampli : ptkWithToggleValidation;
};
