import ampliPalette from './palettes/ampli';
import whitelabelPalette from './palettes/whitelabel';

export const themeAmpli = {
  type: 'ampli',
  colors: {
    background: {
      primary: ampliPalette.neutral.A500,
      secondary: ampliPalette.neutral.A400,
      light: ampliPalette.neutral[100],
      white: ampliPalette.neutral[100],
      icon: {
        primary: ampliPalette.primary[100],
        secondary: ampliPalette.primary[100],
        warning: ampliPalette.feedback.warning[900],
        danger: whitelabelPalette.feedback.error[700],
      },
    },
    text: {
      primary: ampliPalette.primary[500],
      secondary: ampliPalette.neutral[100],
      black: ampliPalette.neutral[800],
      blackSecondary: ampliPalette.neutral[800],
      lightBlack: ampliPalette.neutral[600],
      light: ampliPalette.neutral[100],
      white: ampliPalette.neutral[100],
      lighterBlack: ampliPalette.neutral[500],
      danger: ampliPalette.feedback.error[500],
      success: ampliPalette.feedback.success[500],
      lighterSuccess: ampliPalette.feedback.success[300],
      lightSuccess: ampliPalette.feedback.success[400],
      warning: ampliPalette.feedback.warning[500],
      darkerWarning: ampliPalette.feedback.warning[900],
      disabled: ampliPalette.neutral[600],
    },
    fileUpload: {
      border: {
        primary: ampliPalette.surface[500],
        danger: ampliPalette.feedback.error[500],
      },
      background: {
        primary: ampliPalette.neutral[200],
      },
    },
    navBar: {
      color: ampliPalette.neutral[600],
      button: ampliPalette.neutral[600],
      active: ampliPalette.primary[200],
      border: ampliPalette.neutral[500],
    },
    avatar: {
      border: ampliPalette.neutral[500],
      text: ampliPalette.neutral[900],
      background: {
        primary: ampliPalette.surface[500],
        secondary: ampliPalette.surface[300],
        white: ampliPalette.neutral[100],
      },
    },
    slide: {
      dots: {
        active: ampliPalette.primary[500],
        inactive: ampliPalette.neutral[300],
      },
    },
    banner: {
      primary: `linear-gradient(82.37deg, ${ampliPalette.primary.A500} 0%, ${ampliPalette.primary[500]} 100%);`,
    },
    icon: {
      primary: ampliPalette.neutral[800],
      secondary: ampliPalette.primary[500],
      black: ampliPalette.neutral[900],
      background: ampliPalette.primary[100],
      warning: ampliPalette.feedback.warning[500],
      success: ampliPalette.feedback.success[500],
    },
    tab: {
      primary: ampliPalette.primary[500],
    },
    button: {
      primary: ampliPalette.primary[200],
      secondary: ampliPalette.neutral[100],
      darkPrimary: ampliPalette.primary[600],
      darkerPrimary: ampliPalette.primary[800],
      lightBlack: ampliPalette.neutral[200],
      backgroundColor: `linear-gradient(57.25deg, ${ampliPalette.primary.A500} 0%, ${ampliPalette.primary[500]} 100%)`,
      success: ampliPalette.feedback.success[500],
      danger: ampliPalette.feedback.error[500],
      text: {
        primary: ampliPalette.primary.A400,
      },
      focus: {
        primary: ampliPalette.primary[200],
        secondary: ampliPalette.surface[300],
      },
    },
    breadCrumbs: {
      primary: ampliPalette.neutral[100],
      secondary: ampliPalette.neutral[100],
      black: ampliPalette.neutral[900],
      icon: ampliPalette.neutral[100],
    },
    calendar: {
      primary: ampliPalette.surface.A500,
      secondary: ampliPalette.neutral[900],
      lightBlack: ampliPalette.neutral[300],
      warning: ampliPalette.feedback.warning[400],
    },
    card: {
      border: {
        primary: ampliPalette.surface[500],
        secondary: ampliPalette.surface[600],
        third: ampliPalette.primary[500],
        success: ampliPalette.feedback.success[500],
        danger: ampliPalette.feedback.error[500],
        shadow: true,
        borderType: 'dotted',
      },
      background: {
        primary: ampliPalette.primary[500],
        secondary: ampliPalette.neutral[200],
        hover: ampliPalette.primary[600],
        warning: ampliPalette.feedback.warning[300],
        success: ampliPalette.feedback.success[300],
        danger: ampliPalette.feedback.error[300],
        disabled: ampliPalette.neutral[200],
        hoverDisabled: ampliPalette.neutral[200],
        inactive: ampliPalette.neutral[300],
        white: ampliPalette.neutral[100],
      },
      color: {
        background: ampliPalette.neutral[100],
        primary: ampliPalette.neutral[100],
        secondary: ampliPalette.primary[500],
        third: '#ebf0f5',
        hover: ampliPalette.neutral[200],
        warning: ampliPalette.feedback.warning[500],
      },
    },
    creditCard: {
      background: {
        primary: ampliPalette.surface[600],
      },
      color: {
        primary: ampliPalette.neutral[100],
      },
    },
    communication: {
      border: {
        primary: ampliPalette.feedback.warning[200],
      },
      background: {
        primary: ampliPalette.feedback.warning[100],
      },
      color: {
        primary: ampliPalette.neutral[900],
        secondary: ampliPalette.neutral[900],
      },
    },
    presentation: {
      secondary: ampliPalette.neutral[100],
      header: {
        primary: ampliPalette.neutral[800],
        secondary: ampliPalette.neutral[600],
      },
    },
    kindle: {
      primary: ampliPalette.primary[500],
      secondary: ampliPalette.neutral[200],
      ternary: ampliPalette.surface[500],
      lightPrimary: ampliPalette.primary[200],
      black: ampliPalette.neutral[900],
      focus: ampliPalette.surface.A400,
      lightBlack: ampliPalette.neutral[700],
      border: {
        secondary: ampliPalette.neutral[400],
      },
    },
    live: {
      primary: ampliPalette.primary[500],
      danger: ampliPalette.feedback.error[500],
      lightDanger: ampliPalette.feedback.error[500],
      secondary: ampliPalette.surface[500],
    },
    page: {
      background: {
        primary: ampliPalette.neutral[100],
        secondary: ampliPalette.surface[300],
      },
    },
    input: {
      border: {
        primary: ampliPalette.surface[500],
      },
      focus: {
        primary: ampliPalette.primary[500],
      },
    },
    checkbox: {
      border: {
        primary: ampliPalette.surface[500],
      },
      color: {
        primary: ampliPalette.primary[500],
      },
    },
    radiobutton: {
      border: {
        primary: ampliPalette.surface[500],
      },
      color: {
        primary: ampliPalette.primary[500],
      },
    },
    separator: {
      lightColor: ampliPalette.surface[300],
      color: ampliPalette.surface[500],
    },
    tag: {
      primary: ampliPalette.neutral[300],
      lightSuccess: ampliPalette.feedback.success[400],
      success: ampliPalette.feedback.success[500],
      lighterSuccess: ampliPalette.feedback.success[300],
      danger: ampliPalette.feedback.error[500],
      darkDanger: ampliPalette.feedback.error[500],
      warning: ampliPalette.feedback.warning[400],
    },
    wrapper: {
      color: ampliPalette.surface[300],
    },
    scrollbar: {
      background: ampliPalette.neutral[200],
      border: ampliPalette.surface[400],
    },
    alerts: {
      background: {
        primary: ampliPalette.primary[100],
        secondary: ampliPalette.feedback.warning[100],
        tertiary: ampliPalette.feedback.error[500],
      },
      icon: {
        primary: ampliPalette.feedback.warning[500],
      },
    },
  },
};

export const themeWhiteLabel = {
  type: 'whitelabel',
  colors: {
    background: {
      primary: whitelabelPalette.surface[100],
      secondary: whitelabelPalette.surface[200],
      light: whitelabelPalette.surface[100],
      white: whitelabelPalette.neutral[100],
      icon: {
        primary: whitelabelPalette.surface[100],
        secondary: whitelabelPalette.surface[300],
        warning: whitelabelPalette.feedback.warning[900],
        danger: whitelabelPalette.feedback.error[700],
      },
    },
    text: {
      primary: whitelabelPalette.primary[500],
      secondary: whitelabelPalette.neutral[900],
      black: whitelabelPalette.neutral[900],
      blackSecondary: whitelabelPalette.neutral[800],
      lightBlack: whitelabelPalette.neutral[700],
      light: whitelabelPalette.neutral[700],
      white: whitelabelPalette.neutral[100],
      lighterBlack: whitelabelPalette.neutral[600],
      danger: whitelabelPalette.feedback.error[500],
      dangerBold: whitelabelPalette.feedback.error[700],
      success: whitelabelPalette.feedback.success[500],
      lighterSuccess: whitelabelPalette.feedback.success[300],
      lightSuccess: whitelabelPalette.feedback.success[400],
      warning: whitelabelPalette.feedback.warning[500],
      darkerWarning: whitelabelPalette.feedback.warning[900],
      disabled: whitelabelPalette.neutral[600],
    },
    fileUpload: {
      border: {
        primary: whitelabelPalette.surface[500],
        danger: whitelabelPalette.feedback.error[500],
      },
      background: {
        primary: whitelabelPalette.surface[100],
      },
    },
    navBar: {
      color: whitelabelPalette.neutral[700],
      button: whitelabelPalette.neutral[600],
      active: whitelabelPalette.primary[500],
      border: whitelabelPalette.neutral[500],
    },
    avatar: {
      border: whitelabelPalette.neutral[500],
      text: whitelabelPalette.neutral[900],
      background: {
        primary: whitelabelPalette.surface[500],
        secondary: whitelabelPalette.surface[200],
        white: whitelabelPalette.neutral[100],
      },
    },
    slide: {
      dots: {
        active: whitelabelPalette.primary[500],
        inactive: whitelabelPalette.neutral[300],
      },
    },
    banner: {
      primary: whitelabelPalette.surface[200],
    },
    icon: {
      primary: whitelabelPalette.primary[500],
      secondary: whitelabelPalette.primary[600],
      black: whitelabelPalette.neutral[900],
      background: whitelabelPalette.primary[100],
      warning: whitelabelPalette.feedback.warning[600],
      success: whitelabelPalette.feedback.success[500],
      danger: whitelabelPalette.feedback.error[900],
    },
    tab: {
      primary: whitelabelPalette.neutral[900],
    },
    button: {
      primary: whitelabelPalette.primary[500],
      secondary: whitelabelPalette.neutral[100],
      darkPrimary: whitelabelPalette.primary[900],
      darkerPrimary: whitelabelPalette.primary[800],
      lightBlack: whitelabelPalette.neutral[200],
      backgroundColor: whitelabelPalette.primary[500],
      success: whitelabelPalette.feedback.success[500],
      danger: whitelabelPalette.feedback.error[500],
      text: {
        primary: whitelabelPalette.neutral[100],
      },
      focus: {
        primary: whitelabelPalette.primary[200],
        secondary: whitelabelPalette.surface[200],
      },
    },
    card: {
      border: {
        primary: whitelabelPalette.surface[500],
        secondary: whitelabelPalette.surface[900],
        third: whitelabelPalette.primary[500],
        success: whitelabelPalette.feedback.success[500],
        danger: whitelabelPalette.feedback.error[500],
        shadow: false,
        borderType: 'dotted',
      },
      background: {
        primary: whitelabelPalette.primary[500],
        secondary: whitelabelPalette.surface[100],
        hover: whitelabelPalette.primary[600],
        warning: whitelabelPalette.feedback.warning[100],
        success: whitelabelPalette.feedback.success[100],
        danger: whitelabelPalette.feedback.error[100],
        disabled: whitelabelPalette.surface[100],
        hoverDisabled: whitelabelPalette.primary[100],
        inactive: whitelabelPalette.neutral[300],
        white: whitelabelPalette.neutral[100],
      },
      color: {
        primary: whitelabelPalette.neutral[100],
        background: whitelabelPalette.surface[100],
        secondary: whitelabelPalette.surface[100],
        third: whitelabelPalette.surface[200],
        hover: whitelabelPalette.surface[100],
        warning: whitelabelPalette.feedback.warning[500],
      },
    },
    creditCard: {
      background: {
        primary: whitelabelPalette.surface[900],
      },
      color: {
        primary: whitelabelPalette.neutral[100],
      },
    },
    communication: {
      border: {
        primary: whitelabelPalette.feedback.warning[200],
      },
      background: {
        primary: whitelabelPalette.feedback.warning[100],
      },
      color: {
        primary: whitelabelPalette.neutral[900],
        secondary: whitelabelPalette.neutral[900],
      },
    },
    breadCrumbs: {
      primary: whitelabelPalette.neutral[900],
      secondary: whitelabelPalette.neutral[700],
      black: whitelabelPalette.neutral[900],
      icon: whitelabelPalette.primary[500],
    },
    kindle: {
      primary: whitelabelPalette.primary[500],
      secondary: whitelabelPalette.surface[200],
      ternary: whitelabelPalette.surface[500],
      lightPrimary: whitelabelPalette.primary[100],
      black: whitelabelPalette.neutral[900],
      focus: whitelabelPalette.primary[300],
      lightBlack: whitelabelPalette.neutral[700],
      border: {
        secondary: whitelabelPalette.neutral[500],
      },
    },
    calendar: {
      primary: whitelabelPalette.primary[400],
      secondary: whitelabelPalette.neutral[900],
      lightBlack: whitelabelPalette.neutral[300],
      warning: whitelabelPalette.feedback.warning[400],
    },
    page: {
      background: {
        primary: whitelabelPalette.neutral[100],
        secondary: whitelabelPalette.surface[100],
      },
    },
    input: {
      border: {
        primary: whitelabelPalette.surface[500],
      },
      focus: {
        primary: whitelabelPalette.primary[500],
      },
    },
    checkbox: {
      border: {
        primary: whitelabelPalette.surface[500],
      },
      color: {
        primary: whitelabelPalette.primary[500],
      },
    },
    radiobutton: {
      border: {
        primary: whitelabelPalette.surface[500],
      },
      color: {
        primary: whitelabelPalette.primary[500],
      },
    },
    separator: {
      lightColor: whitelabelPalette.surface[300],
      color: whitelabelPalette.surface[500],
    },
    presentation: {
      secondary: whitelabelPalette.surface[100],
      header: {
        primary: whitelabelPalette.neutral[900],
        secondary: whitelabelPalette.neutral[700],
      },
    },
    live: {
      primary: whitelabelPalette.primary[500],
      danger: whitelabelPalette.feedback.error[500],
      lightDanger: whitelabelPalette.feedback.error[100],
      secondary: whitelabelPalette.surface[500],
    },
    tag: {
      primary: whitelabelPalette.surface[300],
      lightSuccess: whitelabelPalette.feedback.success[200],
      success: whitelabelPalette.feedback.success[500],
      lighterSuccess: whitelabelPalette.feedback.success[300],
      danger: whitelabelPalette.feedback.error[400],
      darkDanger: whitelabelPalette.feedback.error[500],
      warning: whitelabelPalette.feedback.warning[200],
    },
    wrapper: {
      color: whitelabelPalette.surface[100],
    },
    scrollbar: {
      background: whitelabelPalette.neutral[200],
      border: whitelabelPalette.surface[400],
    },
    alerts: {
      background: {
        primary: whitelabelPalette.primary[100],
        secondary: whitelabelPalette.feedback.warning[100],
        tertiary: whitelabelPalette.feedback.error[100],
      },
      icon: {
        primary: ampliPalette.feedback.warning[900],
      },
    },
  },
};
