import React, { lazy } from 'react';
import { useServiceClient } from '@ampli/services';

import { useGetState } from '../state';
import { useQueryParams } from '../hooks';
import routePath from './routes-path';

const SecretaryModule = lazy(() =>
  import(/* webpackChunkName: "secretary" */ '../modules/secretary/module')
);

const Routes = () => {
  const clients = useServiceClient('all');
  const queryParams = useQueryParams();
  const [state, actions] = useGetState();

  const meta = { clients, actions, state, queryParams };
  const authMeta = { ...meta, auth: { redirect: routePath.login } };
  const secretaryStudentMeta = { ...authMeta, isCourseOptional: true };

  return (
    <SecretaryModule
      routesSettings={{ guardedRouteMeta: secretaryStudentMeta }}
    />
  );
};

export default Routes;
