import React from 'react';

import { Typography, SvgClose } from '@sofia/ui';
import { useNotifications } from '../../contexts/notification-context';
import { ChevronLeft } from '@ampli/icon';
import { useTheme } from '@emotion/react';

import * as Styles from './notifications-popover.styles';

interface NotificationsPopoverHeaderProps {
  onClose: () => void;
}

const NotificationsPopoverHeader: React.FC<NotificationsPopoverHeaderProps> = ({
  onClose,
}: NotificationsPopoverHeaderProps) => {
  const theme = useTheme();

  const { notificationId, dispatch } = useNotifications();

  return (
    <Styles.Header>
      {notificationId ? (
        <Styles.BackButton
          aria-label="Voltar"
          tabIndex={0}
          onClick={() => dispatch({ content: 'list', notificationId: null })}
        >
          <ChevronLeft />
          <Typography
            margin="0px 0px 0px 16px"
            color={theme.colors.text.black}
            size={20}
            weight={700}
          >
            Notificações
          </Typography>
        </Styles.BackButton>
      ) : (
        <Typography color={theme.colors.text.black} size={20} weight={700}>
          Notificações
        </Typography>
      )}

      <Styles.CloseButton
        aria-label="Fechar área de notificações"
        onClick={onClose}
      >
        <SvgClose size="24px" />
      </Styles.CloseButton>
    </Styles.Header>
  );
};

export default NotificationsPopoverHeader;
