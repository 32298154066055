const mobileWatsonChatbotGuard = async (to, from, next) => {
  try {
    if (!from.location.search) {
      throw new Error('Missing query params.');
    }

    next();
  } catch (error) {
    console.warn(error);

    next.redirect('/');
  }
};

export default mobileWatsonChatbotGuard;
