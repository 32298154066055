import React from 'react';

import {
  UnsubscribeType,
  WebChatAction,
  ActivityNames,
  SubscriptonType,
  DirectLine,
} from '../../types';

import { useUploadCommunicationBase64File } from '../../../../modules/communication/services/hooks';
import { useGenerateTutorPreviewToken } from '../../../../modules/shared';
import { useCourseEnrollmentsContext } from '../../../../modules/shared/contexts/course-enrollments-context';
import { courseTypeLabelByDegree } from '../../../../constants';
import { useDynamicsLogic } from '../../../../modules/messaging/components/talk-tutor-frame/components/ptk-frame/hooks/use-dynamics-logic';
import { blobToBase64 } from '../../helpers';
import { copilotUrls } from '../../../../config';

import { TalkTutorFrameProps } from '../../../../modules/messaging/components/talk-tutor-frame/talk-tutor-frame';

export type UseChatEventsHandlerProps = {
  talkTutorInfos?: TalkTutorFrameProps['infos'];
  directLine: DirectLine;
  isQuizPageRendered?: boolean;
};

export type UseChatEventsHandlerReturn = {
  isUploadButtonAllowed: boolean;
  isChatInteractionAllowed?: boolean;
  shouldChatBeBlocked?: boolean;
  isUploading: boolean;
  getFileBlob: (fileBlob: Blob & { name: string }) => Promise<void>;
};

export type FileData = {
  file: string;
  fileName: string;
};

export const useChatEventsHandler = ({
  talkTutorInfos,
  directLine,
  isQuizPageRendered,
}: UseChatEventsHandlerProps): UseChatEventsHandlerReturn => {
  const [fileData, setFileData] = React.useState<FileData | null>(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [filesToAttach, setFilesToAttach] = React.useState<string[]>([]);
  const [isUploadButtonAllowed, setIsUploadButtonAllowed] =
    React.useState(false);

  const [isChatInteractionAllowed, setIsChatInteractionAllowed] =
    React.useState<boolean>(false);

  const [shouldChatBeBlocked, setShouldChatBeBlocked] =
    React.useState<boolean>(true);

  const shouldBlockChatInteraction = React.useRef<boolean>(false);

  const filesToAttachRef = React.useRef(filesToAttach);

  const { states } = useCourseEnrollmentsContext();
  const { data: selectedEnrollment } = states.courseEnrollments;

  const [uploadBase64File] = useUploadCommunicationBase64File(fileData);

  const { createConversation } = useDynamicsLogic();

  const { getObjectLearningPath } = useGenerateTutorPreviewToken({
    talkTutorInfos,
  });

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const locale = document.documentElement.lang || 'pt-BR';

  const getFileBlob = React.useCallback(
    async (fileBlob: Blob & { name: string }) => {
      try {
        await blobToBase64(fileBlob).then((response) =>
          setFileData({
            file: response as string,
            fileName: fileBlob?.name,
          })
        );
      } catch (error) {
        throw new Error('Erro ao carregar arquivo!' + error);
      }
    },
    []
  );

  const sendTutorMessage = React.useCallback(
    async (message: string, onSuccess: () => void) => {
      try {
        await createConversation({
          type: 'DUVIDA',
          message,
          classDescription: talkTutorInfos?.class,
          courseDescription: selectedEnrollment?.course?.name,
          courseEnrollmentID: selectedEnrollment?.id,
          subjectEnrollmentID: talkTutorInfos?.subjectEnrollmentID,
          subjectID: talkTutorInfos?.subjectID,
          subjectName:
            selectedEnrollment?.subjectEnrollments?.find(
              (enrollment) =>
                enrollment?.id === talkTutorInfos?.subjectEnrollmentID
            )?.subject?.name ?? talkTutorInfos?.subject,
          teachingObject: await getObjectLearningPath(),
        }).then((response) => {
          if (response.ok) onSuccess();

          return response;
        });
      } catch (error) {
        throw new Error('Erro ao enviar mensagem para o tutor!' + error);
      }
    },
    [
      createConversation,
      getObjectLearningPath,
      selectedEnrollment?.course?.name,
      selectedEnrollment?.id,
      selectedEnrollment?.subjectEnrollments,
      talkTutorInfos?.class,
      talkTutorInfos?.subject,
      talkTutorInfos?.subjectEnrollmentID,
      talkTutorInfos?.subjectID,
    ]
  );

  const openServiceAzureFunction = React.useCallback(
    async (activity: WebChatAction, onSuccess: () => void) => {
      try {
        if (sessionStorage.getItem('chatClosed')) return;
        if (!activity) return;

        await directLine
          .postActivity({
            localTimezone,
            locale,
            name: 'openingService',
            type: 'event',
          })
          .subscribe();

        await fetch(copilotUrls.secretaryAzureFunction.URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-functions-key': copilotUrls.secretaryAzureFunction.KEY,
          },
          body: JSON.stringify({
            ...(typeof activity?.value !== 'string'
              ? {
                  serviceDescription: activity?.value?.serviceDescription,
                  serviceType: activity?.value?.serviceType,
                  serviceSubject: activity?.value?.serviceSubject,
                }
              : {}),
            files: filesToAttachRef.current,
            businessKeyProgramEnrollment: selectedEnrollment?.externalId,
            academicLevel:
              courseTypeLabelByDegree[
                selectedEnrollment?.course?.courseType
                  ?.degree as keyof typeof courseTypeLabelByDegree
              ],
          }),
        })
          .then(() => onSuccess())
          .then(() => sessionStorage.setItem('serviceCreated', 'true'));
      } catch (error) {
        throw new Error('Erro ao abrir o serviço!' + error);
      } finally {
        setFilesToAttach([]);
        setIsUploadButtonAllowed(false);
        sessionStorage.removeItem('chatClosed');
        sessionStorage.setItem('serviceCreated', 'true');
      }
    },
    [
      selectedEnrollment?.course?.courseType?.degree,
      selectedEnrollment?.externalId,
      localTimezone,
      directLine,
      locale,
    ]
  );

  const onUploadFile = React.useCallback(async () => {
    setIsUploading(true);

    await uploadBase64File()
      .then(async (response) => {
        setFilesToAttach([...filesToAttach, response.data.data]);
        return await directLine
          .postActivity({
            localTimezone,
            locale,
            name: 'fileSent',
            type: 'event',
          })
          .subscribe();
      })
      .finally(() => {
        setIsUploadButtonAllowed(false);
        setIsUploading(false);
        setFileData(null);
      });
  }, [directLine, filesToAttach, localTimezone, locale, uploadBase64File]);

  React.useEffect(() => {
    filesToAttachRef.current = filesToAttach;
  }, [filesToAttach]);

  React.useEffect(() => {
    if (fileData?.file) onUploadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData?.file]);

  React.useEffect(() => {
    if (directLine) {
      // eslint-disable-next-line prefer-const
      let eventsSubscription: SubscriptonType;

      const handleAttachFile = () => {
        setIsUploadButtonAllowed(true);
      };

      const handleEnableChat = () => {
        if (isQuizPageRendered) {
          setShouldChatBeBlocked(false);
          shouldBlockChatInteraction.current = true;
          setIsChatInteractionAllowed(true);
        }
      };

      const handleStartTutorConversation = async (activity: WebChatAction) => {
        await sendTutorMessage(activity?.value as string, async () => {
          await directLine
            .postActivity({
              type: 'event',
              name: 'conversationStartedTutor',
              locale,
              localTimezone,
            })
            .subscribe();

          if (eventsSubscription)
            (eventsSubscription as unknown as UnsubscribeType)?.unsubscribe();
        });
      };

      const handleDefault = () => {
        if (shouldBlockChatInteraction.current === true && isQuizPageRendered) {
          setShouldChatBeBlocked(true);
          shouldBlockChatInteraction.current = false;
        }
      };

      const handleOpenService = async (activity: WebChatAction) => {
        try {
          await openServiceAzureFunction(
            activity,
            async () =>
              await directLine
                .postActivity({
                  localTimezone,
                  locale,
                  name: 'serviceOpened',
                  type: 'event',
                })
                .subscribe()
          );

          if (eventsSubscription)
            (eventsSubscription as unknown as UnsubscribeType)?.unsubscribe();
        } catch (error) {
          console.error('Erro ao abrir o serviço!', error);
        }
      };

      eventsSubscription = directLine.activity$.subscribe({
        async next(activity: WebChatAction) {
          if (activity.type === 'event') {
            switch (activity.name) {
              case ActivityNames.OPEN_SERVICE:
                await handleOpenService(activity);
                break;

              case ActivityNames.ATTACH_FILE:
                handleAttachFile();
                break;

              case ActivityNames.ENABLE_CHAT:
                handleEnableChat();
                break;

              case ActivityNames.STAR_TUTOR_CONVERSATION:
                await handleStartTutorConversation(activity);
                break;

              default:
                handleDefault();
                break;
            }
          }
        },
      });

      return () => {
        if (eventsSubscription)
          (eventsSubscription as unknown as UnsubscribeType)?.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directLine]);

  return {
    isUploadButtonAllowed,
    isChatInteractionAllowed,
    shouldChatBeBlocked,
    getFileBlob,
    isUploading,
  };
};
