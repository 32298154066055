import mePolicies from './me';
import imageAssetPolicies from './image-asset';
import videoAssetPolicies from './video-asset';
import sectionEnrollmentPolicies from './section-enrollment';
import learningObjectEnrollmentPolicies from './learning-object-enrollment';
import courseEnrollmentPolicies from './course-enrollment';
import subjectPolicies from './subject';
import subjectEnrollmentPolicies from './subject-enrollment';
import subjectEnrollmentProgressPolicies from './subject-enrollment-progress';
import subjectEnrollmentProgressDetailPolicies from './subject-enrollment-progress-detail';
import instalmentPolicies from './instalment';
import boletoPolicies from './boleto';
import addressPolicies from './address';
import examPolicies from './exam';
import sectionPolicies from './section';
import trackInputPolicies from './track-input';
import doneAssignmentDataPolicies from './done-assignment-data';
import gradingMethodPolicies from './grading-method';
import coursePolicies from './course';
import livesPolicies from './lives';
export default {
  Me: mePolicies,
  SofiaGetMeResponse: mePolicies,
  CourseEnrollment: courseEnrollmentPolicies,
  Subject: subjectPolicies,
  SubjectEnrollment: subjectEnrollmentPolicies,
  SubjectEnrollmentProgress: subjectEnrollmentProgressPolicies,
  SubjectEnrollmentProgressDetail: subjectEnrollmentProgressDetailPolicies,
  Instalment: instalmentPolicies,
  Boleto: boletoPolicies,
  Address: addressPolicies,
  Exam: examPolicies,
  TrackInput: trackInputPolicies,
  DoneAssignmentData: doneAssignmentDataPolicies,
  GradingMethod: gradingMethodPolicies,
  Course: coursePolicies,
  Live: livesPolicies,
  ImageAsset: imageAssetPolicies,
  TextAsset: {
    keyFields: ['id']
  },
  TableAsset: {
    keyFields: ['id']
  },
  FormulaAsset: {
    keyFields: ['id']
  },
  VideoAsset: videoAssetPolicies,
  SectionEnrollment: sectionEnrollmentPolicies,
  Section: sectionPolicies,
  LearningObjectEnrollment: learningObjectEnrollmentPolicies,
  LearningObject: learningObjectEnrollmentPolicies,
  LearningUnitEnrollment: {
    keyFields: ['id']
  }
};