import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
const _excluded = ["data", "loading"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useState } from 'react';
import { sortBy } from '@ampli/utils';
import { useQuery, useLazyQuery } from '../hooks';
import { FIND_STATES, FIND_CITIES, GET_ADDRESS, FIND_ALL_COUNTRIES } from './queries';
export const useFindStates = options => {
  const {
    loading,
    data
  } = useQuery(FIND_STATES, options);
  const [state, setState] = useState({});
  const states = loading || !data ? [] : sortBy(data.list, ['name']);
  return {
    states,
    state,
    setState,
    loading
  };
};
export const useFindCities = function (stateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    loading,
    data
  } = useQuery(FIND_CITIES, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      stateId
    },
    skip: !stateId
  }));
  const [city, setCity] = useState({});
  const cities = loading || !data ? [] : sortBy(data.list, ['name']);
  return {
    cities,
    city,
    setCity,
    loading
  };
};
export const useGetAddress = function (postalCode) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery = useQuery(GET_ADDRESS, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        postalCode
      },
      skip: !postalCode,
      fetchPolicy: 'no-cache'
    })),
    {
      data,
      loading
    } = _useQuery,
    rest = _objectWithoutProperties(_useQuery, _excluded);
  const address = loading || !data ? null : data.data;
  return _objectSpread({
    data: address,
    loading
  }, rest);
};
export const useGetAddressLazy = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useLazyQuery(GET_ADDRESS, options);
};
export const useFindAllCountries = () => {
  const {
    loading,
    data
  } = useQuery(FIND_ALL_COUNTRIES);
  const countries = loading || !data ? [] : data.list;
  return {
    countries,
    loading
  };
};