import { EntitiesLabels } from '../../../../../constants';
import {
  TSectionForLearningUnitType,
  TFindLearningUnitEnrollmentType,
} from '../../../types';

type CreatePageTitleType = (props: {
  section: TSectionForLearningUnitType | Record<string, never>;
  learningUnit: TFindLearningUnitEnrollmentType | Record<string, never>;
  selectedCourseEnrollmentType: string;
}) => string;

const createPageTitle: CreatePageTitleType = ({
  section,
  learningUnit,
  selectedCourseEnrollmentType,
}) => {
  const entitiesLabelsDictionary = EntitiesLabels(selectedCourseEnrollmentType);

  return entitiesLabelsDictionary.learningUnitAndSection(
    learningUnit?.order,
    section?.order
  );
};

export default createPageTitle;
