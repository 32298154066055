import styled from '@emotion/styled';

export const Flex = styled.div`
  display: flex;
`;

export const Header = styled(Flex)`
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #dee2e6;
`;

export const IconBtn = styled.div`
  background-color: #dbe3ec;
  border-radius: 50%;
  padding: 14px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    color: #212529;
    box-sizing: content-box;
    pointer-events: none;
  }
  :hover {
    background-color: #c5d2e0;
  }
`;
