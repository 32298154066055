import styled from '@emotion/styled';

export const MenuListItem = styled.div`
  display: flex;
  position: relative;
  z-index: 0;
  padding: 24px;
  border-bottom: 1px solid #dee2e6;

  &.hover:hover {
    box-shadow: none;
  }
  &:hover {
    background-color: #f3f6f9;
    box-shadow: none;
    cursor: pointer;
  }
  &:focus {
    z-index: 1;
  }
  &.active {
    background: #ebf0f5;
    cursor: default;
    padding: 24px 16px 24px 24px;
    &:hover {
      background-color: #dbe3ec;
      box-shadow: none;
    }
  }

  &.closed {
    padding: 14px;
    justify-content: center;
  }
`;
