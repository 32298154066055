import { Typography } from '@sofia/ui';
import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import * as Styled from './evaluation-in-analysis.styles';
import { useTheme } from '@emotion/react';
import { ImageError404 } from '../../../../../components';
import { EvaluationInAnalysisProps } from '../types';

const EvaluationInAnalysis = ({
  type,
}: EvaluationInAnalysisProps): React.ReactElement => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  return (
    <Styled.Bg data-testid="evaluation-in-analysis">
      <Styled.Center>
        <Typography
          weight={700}
          margin="0 0 8px 0"
          textAlign="center"
          size={isDesktop ? 32 : 20}
          color={theme.colors.text.black}
        >
          Estamos analisando suas respostas
        </Typography>

        <ImageError404 width={172} height={200} />

        <Typography
          margin="55px 0 8px 0"
          color={theme.colors.text.black}
          textAlign="center"
          weight={700}
          size={isDesktop ? 24 : 20}
        >
          Em breve
        </Typography>

        <Typography
          as="span"
          color={theme.colors.text.lightBlack}
          textAlign="center"
          size={isDesktop ? 20 : 16}
        >
          você poderá conferir suas correções de {type}s em Notas e Correções.
        </Typography>
      </Styled.Center>
    </Styled.Bg>
  );
};

EvaluationInAnalysis.defaultProps = {
  type: 'prova',
};

export default EvaluationInAnalysis;
