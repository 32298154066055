import { useGetMe } from '@ampli/services';

const useLoginVendor = (options = {}) => {
  const { skip = false, deviceWhiteListed = false, onCompleted } = options;

  useGetMe({
    skip,
    fetchPolicy: 'network-only',
    onCompleted: skip
      ? null
      : (data) => {
          const me = data?.data;
          if (me) {
            onCompleted && onCompleted({ ...me, deviceWhiteListed });
          }
        },
  });
};

export default useLoginVendor;
