import { useCreateAnnotation } from '@ampli/services';
import { NoteType } from '../types';
import { MutateNoteHookProps } from './types';

const useCreateNote = ({
  onCancel,
  onComplete,
  ...options
}: MutateNoteHookProps): [(note: NoteType) => void, boolean] => {
  const [createMutate, { loading: createNoteLoading }] = useCreateAnnotation(
    null,
    {
      onCompleted: () => {
        onComplete();
        if (onCancel) onCancel();
      },
      ...options,
    }
  );

  const createNote = (note: NoteType) => {
    createMutate({
      variables: {
        input: note,
      },
    });
  };

  return [createNote, createNoteLoading];
};

export default useCreateNote;
