import { useState } from 'react';
import { useGetTrack, useAppendOnTrack, GET_TRACK } from '@ampli/services';
import {
  NOTES_ONBOARDING_COMPLETED_KEY,
  NOTES_ONBOARDING_COMPLETED_DESCRIPTION,
} from '../../../../constants';
import { GetNotesOnboardingHookResult } from './types';
import { useMeContext } from '../../../../modules/shared/contexts/me-context';

const useGetNotesOnboarding = (): GetNotesOnboardingHookResult => {
  const [showNotesOnboarding, setShowNotesOnboarding] = useState(false);
  const { states: statesMe } = useMeContext();
  const { me, loading: meLoading, error: meError } = statesMe.getMe;

  const [
    appendOnTrack,
    { loading: appendLoading, error: appendError, called },
  ] = useAppendOnTrack({
    refetchQueries: [{ query: GET_TRACK, variables: { referenceId: me?.id } }],
    onCompleted: () => {
      if (called) {
        setShowNotesOnboarding(false);
      }
    },
  });

  const {
    data: track,
    loading: loadingTrack,
    called: isTrackCalled,
  } = useGetTrack(me?.id, {
    onCompleted: () => {
      if (isTrackCalled && track) {
        const hasOnboardingKey = track.inputs?.some(
          (input: { key: string }) =>
            input.key === NOTES_ONBOARDING_COMPLETED_KEY
        );
        setShowNotesOnboarding(!hasOnboardingKey);
      }
    },
  });

  const appendOnboardingOnTrack = () => {
    try {
      appendOnTrack({
        variables: {
          request: {
            referenceId: me?.id,
            inputs: [
              {
                key: NOTES_ONBOARDING_COMPLETED_KEY,
                description: NOTES_ONBOARDING_COMPLETED_DESCRIPTION,
                value: 'TRUE',
              },
            ],
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const loading = meLoading || loadingTrack || appendLoading;
  const error = Boolean(meError || appendError);

  return {
    loading,
    error,
    showNotesOnboarding,
    onConcludeOnboarding: appendOnboardingOnTrack,
  };
};

export default useGetNotesOnboarding;
