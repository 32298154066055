import React from 'react';
import { TopBar, Separator, IconButton } from '@ampli/ui';
import { SvgTextSize } from '@sofia/ui';
import { node, func } from 'prop-types';
import { LayoutContent, SettingButton } from '../../../../components';
import * as Styled from './exam-layout-desktop.styles';

const ExamLayoutDesktop = ({
  title,
  children,
  onCloseClick,
  talkTutorFrame,
  changeFontSizeRef,
  onFontSizeClick,
}) => {
  return (
    <Styled.FlexColumn data-testid="exam-layout-desktop">
      <Styled.Flex>
        <Styled.Flex>
          <Styled.FlexColumn>
            <TopBar as="header" sticky>
              <LayoutContent>
                <Styled.FlexAlignItemsCenter spaceBetween>
                  <span>{title}</span>

                  <Styled.FlexAlignItemsCenter>
                    <SettingButton
                      ref={changeFontSizeRef}
                      icon={<SvgTextSize aria-hidden />}
                      onClick={onFontSizeClick}
                      aria-label="Alterar o tamanho da fonte"
                    >
                      Tamanho da fonte
                    </SettingButton>

                    {onCloseClick && (
                      <>
                        <Separator orientation="vertical" transparent />
                        <IconButton
                          icon="X"
                          color="text-dark"
                          size="xx-large"
                          aria-label="Fechar correção"
                          onClick={onCloseClick}
                        />
                      </>
                    )}
                  </Styled.FlexAlignItemsCenter>
                </Styled.FlexAlignItemsCenter>
              </LayoutContent>
            </TopBar>

            <Styled.LayoutContainer>
              <LayoutContent padding={['huge', 'small']}>
                {children}
              </LayoutContent>
            </Styled.LayoutContainer>
          </Styled.FlexColumn>
        </Styled.Flex>
        {talkTutorFrame}
      </Styled.Flex>
    </Styled.FlexColumn>
  );
};

ExamLayoutDesktop.propTypes = {
  title: node.isRequired,
  children: node.isRequired,
  onHelpClick: func.isRequired,
  onCloseClick: func,
  talkTutorFrame: node,
  changeFontSizeRef: node,
  onFontSizeClick: func,
};

export default ExamLayoutDesktop;
