import { useGetState } from '../../../../../state';
import useHandleTrack from './use-handle-track';

const useHandleTrackAppend = ({
  studentId,
  input,
}: {
  studentId: string;
  input: {
    key: string;
    desc: string;
  };
}): {
  onCloseCustomization: () => void;
} => {
  const [{ hasShownNewTemplateCommunication }, { setOpenCustomization }] =
    useGetState();

  const { withoutTrack, appendCommunicationOnTrack } = useHandleTrack({
    studentId: studentId || '',
    input: {
      key: input.key,
      desc: input.desc,
    },
  });

  const onCloseCustomization = () => {
    if (withoutTrack && !hasShownNewTemplateCommunication) {
      appendCommunicationOnTrack();
    }
    setOpenCustomization(false);
  };

  return { onCloseCustomization };
};

export default useHandleTrackAppend;
