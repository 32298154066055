import styled from '@emotion/styled';
import { convertPXToREM, convertPXToVH, convertPXToVW } from '../../utils';
export const Label = styled.label`
  margin: 0 ${convertPXToVW(10)};
  font-weight: 400;
  font-size: ${_ref => {
  let {
    textSize
  } = _ref;
  return convertPXToREM(textSize);
}};
  color: ${_ref2 => {
  let {
    textColor
  } = _ref2;
  return textColor;
}};
  pointer-events: none;
`;
export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${convertPXToVH(16)} ${convertPXToVW(16)};
  border: 2px solid ${_ref3 => {
  let {
    borderColor
  } = _ref3;
  return borderColor;
}};
  min-height: ${_ref4 => {
  let {
    minHeight
  } = _ref4;
  return minHeight;
}};
  cursor: pointer;
  border-radius: 8px;
  background-color: #fff;

  ${_ref5 => {
  let {
    disabled
  } = _ref5;
  return disabled && `
    border-color: #868e96;
    color: #868e96;
    background: #e5e5e5;
    label {
      color: #868e96;
    }
    legend {
      color: #868e96;
    }
  `;
}}

  &:focus {
    border: 4px solid #004ecc;
    background-color: #f3f6f9;
  }

  &:hover {
    background-color: ${_ref6 => {
  let {
    hover
  } = _ref6;
  return hover;
}};
    border-color: #698aae;
  }

  @media (max-width: 769px) {
    min-width: ${_ref7 => {
  let {
    minWidthMobile
  } = _ref7;
  return minWidthMobile;
}};
    grid-column: ${_ref8 => {
  let {
    mobileGrid
  } = _ref8;
  return mobileGrid;
}};
  }
`;