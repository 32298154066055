import React from 'react';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { ButtonPrimaryLong, SvgPlus, Typography } from '@sofia/ui';
import { StatusColors, StatusEnum, TimeLineInterface } from './helper';
import { Wrapper } from './styles';

const Timeline: React.FC<TimeLineInterface> = ({
  isShowingTimelineDetails,
  items,
  onClick,
  onClickMobile,
  textButton,
  textButtonMobile,
}: TimeLineInterface) => {
  const theme = useTheme();
  const isMobile = useBreakpoint() === 'mobile';
  const getColorStandard = StatusColors.filter(
    (status) => status.name === StatusEnum.STANDARD
  )[0];

  const getColor = (status?: string) =>
    StatusColors.find((st) => st.name === status) ?? getColorStandard;

  return (
    <Wrapper.Content data-testid="timeline">
      <Wrapper.Timeline isShowingTimelineDetails={isShowingTimelineDetails}>
        {items.map((item, index) => (
          <Wrapper.Item key={Math.random().toString(36)}>
            <Wrapper.Marker
              color={getColor(item?.status).value}
              isActive={index === 0}
              data-testid={`timeline-item-${index}-marker`}
            />
            <Wrapper.Info>
              <Typography
                color={theme.colors.text.black}
                weight={700}
                size={16}
              >
                {item.title}
              </Typography>

              {item.subTitle ? (
                <Typography
                  color={theme.colors.text.black}
                  weight={400}
                  size={16}
                  margin="0 0 8px 0"
                  data-testid={`timeline-item-${index}-subtitle`}
                >
                  {item.subTitle}
                </Typography>
              ) : null}

              <Typography
                color={theme.colors.text.lightBlack}
                weight={400}
                size={16}
              >
                {item.description}
              </Typography>

              {item.showButton ? (
                <ButtonPrimaryLong
                  margin="4px 0 0 0"
                  color={theme.colors.text.white}
                  background={theme.colors.button.primary}
                  onClick={onClick}
                  data-testid={`timeline-item-${index}-button`}
                >
                  {textButton}
                </ButtonPrimaryLong>
              ) : null}
            </Wrapper.Info>
          </Wrapper.Item>
        ))}
      </Wrapper.Timeline>
      {isMobile && !isShowingTimelineDetails ? (
        <Wrapper.MoreDetails
          onClick={onClickMobile}
          data-testid="timeline-button-details-mobile"
        >
          <Typography weight={700} size={16} color={theme.colors.text.primary}>
            {textButtonMobile}
          </Typography>
          <Wrapper.PlusButton>
            <SvgPlus />
          </Wrapper.PlusButton>
        </Wrapper.MoreDetails>
      ) : null}
    </Wrapper.Content>
  );
};

Timeline.defaultProps = {
  isShowingTimelineDetails: false,
  textButton: 'Mais detalhes',
  textButtonMobile: 'Mais detalhes',
};

export default Timeline;
