import styled from '@emotion/styled';

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  justify-content: flex-start;
`;

export const IconWrapper = styled.div`
  border-radius: 50%;
  background: #dbe3ec;
  display: flex;
  padding: 12px;
  margin-right: 16px;
`;
