import styled from '@emotion/styled';
import { convertPXToVW, convertPXToVH } from '@sofia/ui';

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexBetween = styled(FlexCenter)`
  justify-content: space-between;
`;

export const QuestionWrapper = styled.div`
  margin-bottom: ${convertPXToVH(16)};
`;

export const QuestionHeading = styled(FlexCenter)`
  column-gap: ${convertPXToVW(28)};
  margin-bottom: ${convertPXToVH(16)};
`;

export const QuestionCardWrapper = styled.div`
  padding: ${convertPXToVH(24)} ${convertPXToVW(24)};
  & > p {
    margin-bottom: ${convertPXToVH(16)};
  }
`;

export const QuestionCorrectionContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${convertPXToVH(20)};
`;
