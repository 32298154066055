import React from 'react';
import { useModal, Typography } from '@sofia/ui';
import { ModalInterface, PopUp, useBreakpoint } from '../../../../modules';

interface PopUpInvalidPeriodProps {
  content?: string;
  header: string;
}

const PopUpInvalidPeriod = React.forwardRef(
  (
    { content, header }: PopUpInvalidPeriodProps,
    ref: React.Ref<ModalInterface>
  ) => {
    const modal = useModal();
    const { isDesktop } = useBreakpoint();

    const handleShowModal = () => modal.show();
    const handleHideModal = () => modal.hide();

    React.useImperativeHandle(ref, () => ({
      onOpen() {
        handleShowModal();
      },
      onClose() {
        handleHideModal();
      },
    }));

    return (
      <PopUp
        ariaLabel={header}
        headerText={header}
        isDesktop={isDesktop}
        popUpState={modal}
      >
        <>{content ? <Typography size="16">{content}</Typography> : null}</>
      </PopUp>
    );
  }
);

PopUpInvalidPeriod.displayName = 'PopUpInvalidPeriod';

export default PopUpInvalidPeriod;
