const FULL_WIDTH_WITH_MARGIN = 'calc(100% + 10px)';
export const positionStyles = {
  left: {
    right: FULL_WIDTH_WITH_MARGIN,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  right: {
    left: FULL_WIDTH_WITH_MARGIN,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  top: {
    bottom: FULL_WIDTH_WITH_MARGIN,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  bottom: {
    top: FULL_WIDTH_WITH_MARGIN,
    left: '50%',
    transform: 'translateX(-50%)'
  }
};