import React from 'react';
import { useGetStudentPreferences } from '../services/hooks';
import {
  CommunicationPreferencesContextType,
  CommunicationPreferencesProviderProps,
  InitialPreferencesType,
  InitialPreferenceType,
} from '../types';

export const CommunicationPreferencesContext =
  React.createContext<CommunicationPreferencesContextType>({
    states: {
      loading: false,
      initialPreferences: [],
      isInitialState: true,
      setIsInitialState: () => {},
      currentState: [],
      setCurrentState: () => {},
    },
  } as CommunicationPreferencesContextType);

const CommunicationPreferencesProvider: React.FC<
  CommunicationPreferencesProviderProps
> = ({ children }: CommunicationPreferencesProviderProps) => {
  const [isInitialState, setIsInitialState] = React.useState(true);

  const { data, loading } = useGetStudentPreferences<InitialPreferencesType>();
  const [initialPreferences, setInitialPreferences] = React.useState(
    {} as InitialPreferenceType[]
  );
  const [currentState, setCurrentState] = React.useState(initialPreferences);

  const value = React.useMemo(() => {
    return {
      states: {
        loading,
        initialPreferences,
        isInitialState,
        setIsInitialState,
        currentState,
        setCurrentState,
      },
    };
  }, [loading, initialPreferences, isInitialState, currentState]);

  React.useEffect(() => {
    if (!loading) {
      const initialCategories = data?.categories;
      setInitialPreferences(initialCategories);
      setCurrentState(initialCategories);
    }
  }, [data, loading]);

  return (
    <CommunicationPreferencesContext.Provider value={value}>
      {children}
    </CommunicationPreferencesContext.Provider>
  );
};

export default CommunicationPreferencesProvider;
