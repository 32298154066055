import { loadScript } from '@ampli/utils';
import { ampli } from '../config';

const adobeTagUrl = {
  integration:
    'https://assets.adobedtm.com/9fe9cc3d585d/f568200cdedc/launch-566eeecd52e3-development.min.js',
  staging:
    'https://assets.adobedtm.com/9fe9cc3d585d/f568200cdedc/launch-9052e9f02b06-staging.min.js',
  production:
    'https://assets.adobedtm.com/9fe9cc3d585d/f568200cdedc/launch-a7839242faef.min.js',
};

export const installAdobe = () =>
  loadScript(adobeTagUrl[ampli.env], { id: 'adobe-tag', async: true }, 'head');
