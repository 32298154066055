import React from 'react';
import {
  ButtonPrimary,
  Separator,
  ButtonGhost,
  useModal,
  SvgRedo,
  Toast,
} from '@sofia/ui';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { func, string } from 'prop-types';
import MandatoryInternshipInfoFormSupervisor from './supervisor/mandatory-internship-info-form-supervisor';
import MandatoryInternshipInfoFormResponsible from './responsible/mandatory-internship-info-form-responsible';
import MandatoryInternshipInfoFormDates from './dates/mandatory-internship-info-form-date';
import MandatoryInternshipInfoFormWorkLoad from './work-load/mandatory-internship-info-form-work-load';
import MandatoryInternshipInfoFormMessages from './messages/mandatory-internship-info-form-messages';
import { useRegisterInternship } from '../../../../hooks';
import * as Styled from './mandatory-internship-info-form.styles';
import { useGetState } from '../../../../state';

const MandatoryInternshipInfoForm = ({
  subjectEnrollmentId,
  onCancel,
  onSuccess,
}) => {
  const [overtimeErrorText, setOvertimeErrorText] = React.useState(null);
  const [, { setAccessInternshipSubject }] = useGetState();
  const [genericErrorText, setGenericErrorText] = React.useState(null);
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const cancelModal = useModal();
  const successModal = useModal();
  const workloadErrorModal = useModal();
  const periodErrorModal = useModal();
  const overtimeErrorModal = useModal();

  const applyGenericError = (error) => {
    setGenericErrorText(
      error?.status === 406 && error.message
        ? error.message
        : 'Tivemos um erro inesperado, por favor tente novamente.'
    );

    setTimeout(() => {
      setGenericErrorText(null);
    }, 5000);
  };

  const onError = (errors) => {
    if (errors?.cause === 'dailyOvertimeError') {
      setOvertimeErrorText(
        'A carga horária que você colocou não poderá passar de 6h diárias.'
      );
      return overtimeErrorModal.show();
    } else {
      const error = errors?.length > 0 ? errors[0] : errors;
      const errorUseCase = {
        ITS_BUS_004: periodErrorModal.show,
        ITS_BUS_005: () => {
          setOvertimeErrorText(
            'A carga horária semanal que você colocou, não poderá passar do limite de 30h semanais.'
          );
          overtimeErrorModal.show();
        },
        ITS_BUS_006: workloadErrorModal.show,
      }[error.internal_code];

      return errorUseCase ? errorUseCase() : applyGenericError(error);
    }
  };

  const onCompleted = (data) => {
    if (data?.createInternship?.success) {
      setAccessInternshipSubject(null);
      successModal.show();
    }
  };

  const { formik, loading } = useRegisterInternship({
    subjectEnrollmentId,
    onError,
    onCompleted,
  });

  return (
    <>
      <Styled.FormSection>
        <MandatoryInternshipInfoFormResponsible formik={formik} />
      </Styled.FormSection>

      <Separator
        margin={`${isDesktop ? 32 : 16}px 0 ${isDesktop ? 32 : 16}px 0`}
      />

      <Styled.FormSection>
        <MandatoryInternshipInfoFormSupervisor formik={formik} />
      </Styled.FormSection>

      <Styled.RowFormSection>
        <MandatoryInternshipInfoFormDates formik={formik} />
      </Styled.RowFormSection>

      <Styled.FormSection>
        <MandatoryInternshipInfoFormWorkLoad formik={formik} />
      </Styled.FormSection>

      <Styled.ButtonsWrapper>
        <ButtonGhost
          hoverColor={theme.colors.button.darkPrimary}
          color={theme.colors.button.primary}
          aria-label="Cancelar cadastro de informações de estágio"
          onClick={cancelModal.show}
        >
          Cancelar
        </ButtonGhost>
        <ButtonPrimary
          color={theme.colors.text.white}
          background={theme.colors.button.primary}
          aria-label="Enviar cadastro de informações de estágio"
          width="100%"
          type="submit"
          disabled={
            loading ||
            !formik.isValid ||
            Object.keys(formik.touched).length === 0 ||
            Object.keys(formik.errors).length > 0
          }
          onClick={formik.handleSubmit}
        >
          {loading ? <SvgRedo id="rotate" /> : 'Enviar'}
        </ButtonPrimary>
      </Styled.ButtonsWrapper>

      <MandatoryInternshipInfoFormMessages
        cancelModal={cancelModal}
        onCancel={onCancel}
        successModal={successModal}
        onSuccess={onSuccess}
        workloadErrorModal={workloadErrorModal}
        periodErrorModal={periodErrorModal}
        overtimeErrorModal={overtimeErrorModal}
        overtimeErrorText={overtimeErrorText}
      />

      <Toast
        text={genericErrorText}
        type="error"
        show={!!genericErrorText}
        aria-live="assertive"
      />
    </>
  );
};

MandatoryInternshipInfoForm.propTypes = {
  subjectEnrollmentId: string,
  onCancel: func.isRequired,
  onSuccess: func.isRequired,
};

export default MandatoryInternshipInfoForm;
