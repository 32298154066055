import styled from '@emotion/styled';

import { Typography, mediaQuery } from '@sofia/ui';
import { Splide, SplideSlide } from '@splidejs/react-splide';

interface IllustrationSliderProps {
  isShow: boolean;
}

export const Title = styled(Typography)`
  text-align: center;
`;

export const Subtitle = styled(Typography)`
  color: #212529;
  text-align: center;
`;

export const Illustrations = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px 0px 0px;

  ${mediaQuery('desktop')} {
    flex-wrap: nowrap;
  }
`;

export const Illustration = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1 1 230px;
  padding-top: 32px;
`;

export const IllustrationsSlider = styled.div`
  text-align: center;
  display: flex;
  height: 700px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const IllustrationSlider = styled.div<IllustrationSliderProps>`
  text-align: center;
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
`;

export const IllustrationBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-content: center;
  height: fit-content;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 24px 24px;
  margin-top: 24px;
  ${mediaQuery('desktop')} {
    margin-top: 8px;
  }
  align-items: center;
`;

export const WrapperButtons = styled.div`
  max-width: 240px;
  text-align: center;
`;

export const ModalFullScreen = styled.div<IllustrationSliderProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  background: #fff;
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
`;

export const EllipsesWrapper = styled.ul`
  margin: 16px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 0px;
`;

export const Ellipsis = styled.div<{ isActive: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ isActive, theme }) =>
    isActive
      ? theme.colors.slide.dots.active
      : theme.colors.slide.dots.inactive};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr 0fr;
  grid-gap: 24px;

  ${mediaQuery('desktop')} {
    grid-gap: 8px;
  }
`;

export const Slide = styled(Splide)`
  .hidden {
    display: none;
  }
`;

export const SlideIllustration = styled(SplideSlide)`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
