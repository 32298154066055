import React from 'react';

import { ButtonOutline, ButtonPrimary } from '@sofia/ui';
import { NavigationFooterProps } from './types';
import { useBreakpoint } from '@ampli/hooks';
import { useTheme } from '@emotion/react';
import { RedoIcon } from '@ampli/icon';
import { Icon } from '@ampli/ui';

import * as Styled from './navigation-styles';

const NavigationFooter = React.forwardRef(
  (
    {
      onPreviousClick,
      onNextClick,
      isLastStep,
      lastStepLabel,
      isFirstStep,
      ariaLabelPreviousButton,
      ariaLabelNextButton,
      nextButtonLabel,
      previousButtonLabel,
      nextLoading,
      isTutorPreview,
      ...props
    }: NavigationFooterProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const theme = useTheme();
    const breakpoint = useBreakpoint();
    const isDesktop = breakpoint === 'desktop';

    return (
      <div ref={ref} {...props} data-testid="navigation">
        <Styled.ActionButtonsNavigationContainer
          isFirstStep={isFirstStep}
          isDesktop={isDesktop}
          data-testid="navigation-action-buttons"
        >
          {!isFirstStep && (
            <Styled.ActionButtonsPreview>
              <ButtonOutline
                disabled={isFirstStep}
                onClick={onPreviousClick}
                color={theme.colors.button.primary}
                borderBoxColor={theme.colors.text.primary}
                aria-label={ariaLabelPreviousButton}
                data-testid="navigation-previous-button"
              >
                <Icon.ArrowLeftCircle
                  aria-hidden="true"
                  color={theme.colors.text.primary}
                  width="20px"
                  height="20px"
                />
                <span>{previousButtonLabel}</span>
              </ButtonOutline>
            </Styled.ActionButtonsPreview>
          )}

          <Styled.ActionButtonsNext>
            {(isTutorPreview && !isLastStep) || !isTutorPreview ? (
              <ButtonPrimary
                background={theme.colors.button.primary}
                disabled={!onNextClick}
                onClick={onNextClick}
                color="#fff"
                aria-label={ariaLabelNextButton}
                loading={nextLoading}
                data-testid="navigation-next-button"
              >
                {nextLoading ? (
                  <RedoIcon />
                ) : isLastStep ? (
                  lastStepLabel
                ) : (
                  <>
                    <span>{nextButtonLabel}</span>
                    <Icon.ArrowRightCircle
                      aria-hidden="true"
                      color="#fff"
                      width="20px"
                      height="20px"
                    />
                  </>
                )}
              </ButtonPrimary>
            ) : null}
          </Styled.ActionButtonsNext>
        </Styled.ActionButtonsNavigationContainer>
      </div>
    );
  }
);

NavigationFooter.defaultProps = {
  isLastStep: false,
  lastStepLabel: 'Concluir',
  isFirstStep: false,
  nextButtonLabel: 'Próxima',
  previousButtonLabel: 'Anterior',
  isTutorPreview: false,
};

NavigationFooter.displayName = 'NavigationFooter';

export default NavigationFooter;
