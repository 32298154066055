import React from 'react';
import { object } from 'prop-types';
import * as Styled from './mandatory-internship-info-form-date.styles';

const MandatoryInternshipInfoFormDates = ({ formik }) => {
  return (
    <>
      <Styled.InputDateWrapper>
        <Styled.InputDate
          id="startDate"
          label="Data de início do estágio*"
          aria-label="Data de início do estágio"
          placeholder="dd/mm/aaaa"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik?.values?.startDate || ''}
          error={Boolean(formik.touched.startDate && formik.errors.startDate)}
          infoText={formik.touched.startDate && formik.errors.startDate}
          hasValue={!!formik?.values?.startDate}
          type="date"
          labelWeight="700"
          width="100%"
          required
        />
      </Styled.InputDateWrapper>
      <Styled.InputDateWrapper>
        <Styled.InputDate
          id="finishDate"
          label="Data do fim do estágio*"
          aria-label="Data do fim do estágio"
          placeholder="dd/mm/aaaa"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik?.values?.finishDate || ''}
          error={Boolean(formik.touched.finishDate && formik.errors.finishDate)}
          infoText={formik.touched.finishDate && formik.errors.finishDate}
          hasValue={!!formik?.values?.finishDate}
          type="date"
          labelWeight="700"
          width="100%"
          required
        />
      </Styled.InputDateWrapper>
    </>
  );
};

MandatoryInternshipInfoFormDates.propTypes = {
  formik: object.isRequired,
};

export default MandatoryInternshipInfoFormDates;
