import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetExam } from '@ampli/services';
import useQuizAttemptsData from './use-quiz-attempts-data';

import { routePath } from '../routes';
import useHomePageData from './use-home-page-data';
import useQuizPageData from './use-quiz-page-data';

const useQuizResultAttemptsPageData = () => {
  const history = useHistory();
  const params = useParams();
  const { quizId } = params;
  const { me, ...homePageData } = useHomePageData();
  const {
    learningUnit,
    loading: homeLoading,
    subjectEnrollment,
  } = useQuizPageData();
  const {
    assignmentQuizConfig,
    assignmentQuizAttempts,
    validAttempts,
    assignmentQuiz,
  } = useQuizAttemptsData({ learningUnit });

  const {
    data: exam,
    loading: examLoading,
    maxDurationInMinutes,
    timeRunningOutMinutes,
  } = useGetExam(quizId);
  const loading = homeLoading || examLoading;

  useEffect(() => {
    if (!loading && !exam) {
      console.warn('Exam not found.');
      history.replace(routePath.home);
    }
  }, [history, loading, exam]);

  return {
    ...homePageData,
    me,
    loading,
    subjectEnrollment,
    exam,
    maxDurationInMinutes,
    timeRunningOutMinutes,
    assignmentQuizAttempts,
    assignmentQuizConfig,
    validAttempts,
    learningUnit,
    assignmentQuiz,
  };
};

export default useQuizResultAttemptsPageData;
