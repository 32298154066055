import { CategoryType, categories, parsedCategory } from '../../../../types';
import { State, useNotifications } from '../../contexts/notification-context';
import { Notification } from '../../types';

export type Filters = {
  title: string;
  key: string[];
};

interface UseNotificationsFilters {
  getFiltersByCategories: (notification: Notification) => Filters[];
  notification: Notification;
  dispatch: React.Dispatch<Partial<State>>;
  loading: boolean;
  filters: Filters[];
}

const validateJoinSecretary = (
  category: CategoryType,
  categories: CategoryType[]
) =>
  categories.includes('SECRETARIA') &&
  categories.includes('REMATRICULA') &&
  (category === 'SECRETARIA' || category === 'REMATRICULA');

const validateJoinNews = (category: CategoryType, categories: CategoryType[]) =>
  categories.includes('NOVIDADESEPARCERIAS') &&
  categories.includes('EMPREGABILIDADE') &&
  (category === 'NOVIDADESEPARCERIAS' || category === 'EMPREGABILIDADE');

export const useNotificationsFilters = (): UseNotificationsFilters => {
  const { notification, dispatch, loading } = useNotifications();

  const getFiltersByCategories = (): Filters[] => {
    const nonEmptyCategories = categories.filter((category) =>
      notification?.creative?.some((item) => item?.categoryType === category)
    );

    const parseJoinCategories = nonEmptyCategories.map((category) => {
      if (validateJoinSecretary(category, nonEmptyCategories))
        return {
          title: parsedCategory(category).title,
          key: ['SECRETARIA', 'REMATRICULA'],
        };

      if (validateJoinNews(category, nonEmptyCategories))
        return {
          title: parsedCategory(category).title,
          key: ['NOVIDADESEPARCERIAS', 'EMPREGABILIDADE'],
        };

      return {
        title: parsedCategory(category).title,
        key: [category],
      };
    });

    const removeDuplicateds = parseJoinCategories.map((val) =>
      JSON.stringify(val)
    );

    const final = removeDuplicateds
      .filter((value, ind) => removeDuplicateds.indexOf(value) == ind)
      .map((val) => JSON.parse(val));

    return notification?.creative?.some(({ read }) => !read)
      ? [{ title: 'Não lidas', key: ['unread'] }].concat(final)
      : final;
  };

  const filters = getFiltersByCategories();

  return { getFiltersByCategories, notification, filters, dispatch, loading };
};
