import React from 'react';
import { shape, string, number } from 'prop-types';
import { Typography, Tag } from '@sofia/ui';

import { TextAsset } from '../../../asset';

import {
  LINE_BETWEEN_TAGS_REGEX,
  questionStripTags,
} from '../../../../constants';

import { childNodeStyle } from '../helper';
import {
  FlexBetween,
  QuestionHeading,
  QuestionWrapper,
} from './quiz-correction-question.styles';

const QuizCorrectionQuestion = ({
  question,
  step,
  answerStatusTheme,
  fontSize,
}) => {
  return (
    <QuestionWrapper>
      <FlexBetween>
        <QuestionHeading>
          <Typography size={24} weight={700}>
            Questão {step + 1}
          </Typography>
          <Tag {...answerStatusTheme} />
        </QuestionHeading>
      </FlexBetween>

      <Typography size={20}>
        <TextAsset
          size={fontSize}
          color="text-dark"
          data={{
            content: question.baseText.replace(LINE_BETWEEN_TAGS_REGEX, '><'),
          }}
          stripTags={questionStripTags}
          nlToBr
          childNodeStyle={childNodeStyle}
        />
      </Typography>

      <Typography size={20}>
        <TextAsset
          size={fontSize}
          color="text-dark"
          data={{
            content: question.statement.replace(LINE_BETWEEN_TAGS_REGEX, '><'),
          }}
          stripTags={questionStripTags}
          nlToBr
          childNodeStyle={childNodeStyle}
        />
      </Typography>
    </QuestionWrapper>
  );
};

QuizCorrectionQuestion.defaultProps = {
  fontSize: 'medium',
};

QuizCorrectionQuestion.propTypes = {
  question: shape({
    baseText: string.isRequired,
    statement: string.isRequired,
  }).isRequired,
  step: number.isRequired,
  answerStatusTheme: shape({
    text: string.isRequired,
    theme: shape({
      color: string.isRequired,
      background: string.isRequired,
    }).isRequired,
  }).isRequired,
  fontSize: string,
};

export default QuizCorrectionQuestion;
