import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
const _excluded = ["loading", "data"],
  _excluded2 = ["data", "loading"],
  _excluded3 = ["data", "loading"],
  _excluded4 = ["data", "loading"],
  _excluded5 = ["data", "loading"],
  _excluded6 = ["data", "loading"],
  _excluded7 = ["data", "loading"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useState } from 'react';
import { sortBy } from '@ampli/utils';
import { useQuery, useMutation } from '../hooks';
import { FIND_COURSES_STUB, GET_COURSE, GET_COURSE_BY_URL_CODE, GET_COURSE_ENROLLMENT, GET_COURSE_PRICE_STUB, GET_COURSE_PRICE_STUB_BY_ID, GET_COURSE_ID_AND_PRICE_BY_URL_CODE, GET_COURSE_STUB, GET_DEFAULT_COURSE_PRICE_BY_COURSE_ID, GET_MINOR_COURSE_PRICE_BY_DEGREE, LIST_COURSES_BY_COURSE_DEGREE, LIST_FIELD_OF_STUDY, LIST_POPULAR_COURSES_BY_DEGREE, EXISTS_COURSE_ENROLLMENT_BY_COURSE_ID, CANCEL_COURSE_ENROLLMENT, STUDENT_FREE_TRIAL_INFO, LIST_VISIBLE_COURSES_BY_COURSE_DEGREE, LIST_VISIBLE_COURSES_CATEGORIES_BY_DEGREE, GET_COURSE_ENROLLMENT_FOR_TUTOR_PAGE, GET_COURSE_ENROLLMENT_FOR_SYLLABUS_PAGE, GET_COURSE_ENROLLMENT_HOME, GET_SUBJECT_ENROLLMENTS_FOR_SYLLABUS_PAGE, GET_COURSE_ENROLLMENT_FOR_SECRETARY_PAGE, GET_COURSE_ENROLLMENT_FOR_PAGES } from './queries';
const cacheFetchPolicy = 'cache-first';
export const useFindCourses = () => {
  const {
    loading,
    data
  } = useQuery(FIND_COURSES_STUB);
  const [course, setCourse] = useState({});
  const courses = loading || !data ? [] : sortBy(data.list, 'name');
  return {
    courses,
    course,
    setCourse,
    loading
  };
};
export const useFindCoursesByCourseDegree = function (degree) {
  let pagination = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    page: 1,
    perPage: 50
  };
  let sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    field: 'name',
    order: 'ASC'
  };
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const {
    loading,
    data
  } = useQuery(LIST_COURSES_BY_COURSE_DEGREE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      degree,
      pagination,
      sort
    },
    skip: !degree,
    fetchPolicy: (options === null || options === void 0 ? void 0 : options.fetchPolicy) || 'no-cache'
  }));
  const courses = loading || !data ? {} : data.list.data;
  return {
    courses,
    loading
  };
};
export const useFindVisibleCoursesByCourseDegree = function (degree) {
  let pagination = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    page: 1,
    perPage: 50
  };
  let sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    field: 'name',
    order: 'ASC'
  };
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const {
    loading,
    data
  } = useQuery(LIST_VISIBLE_COURSES_BY_COURSE_DEGREE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      degree,
      pagination,
      sort
    },
    skip: !degree,
    fetchPolicy: (options === null || options === void 0 ? void 0 : options.fetchPolicy) || 'no-cache'
  }));
  const courses = loading || !data ? {} : data.list.data;
  return {
    courses,
    loading
  };
};
export const useGetCourseStub = function (courseId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    loading,
    data
  } = useQuery(GET_COURSE_STUB, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseId
    },
    skip: !courseId
  }));
  const course = loading || !data ? {} : data.data;
  return {
    course,
    loading
  };
};
export const useGetCoursePrice = function (cityId, courseId) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const {
    loading,
    data
  } = useQuery(GET_COURSE_PRICE_STUB, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      cityId: parseInt(cityId, 10),
      courseId
    },
    skip: !cityId || !courseId,
    fetchPolicy: 'no-cache'
  }));
  const coursePrice = loading || !data ? {} : data.data;
  return {
    coursePrice,
    loading
  };
};
export const useGetCoursePriceById = function (coursePriceId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery = useQuery(GET_COURSE_PRICE_STUB_BY_ID, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        coursePriceId
      },
      skip: !coursePriceId
    })),
    {
      loading,
      data
    } = _useQuery,
    rest = _objectWithoutProperties(_useQuery, _excluded);
  const coursePrice = loading || !data ? {} : data.data;
  return _objectSpread({
    data: coursePrice,
    loading
  }, rest);
};
export const useFindPopularCoursesByDegree = function () {
  let degree = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'UNDERGRADUATE';
  let perPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const {
    loading,
    data
  } = useQuery(LIST_POPULAR_COURSES_BY_DEGREE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      degree,
      pagination: {
        page: 1,
        perPage: 1000
      },
      sort: {
        field: 'name',
        order: 'ASC'
      }
    },
    skip: !degree,
    fetchPolicy: 'no-cache'
  }));
  const popularCourses = loading || !data ? {} : data.list.data.sort((a, b) => (a === null || a === void 0 ? void 0 : a.candidateCount) > (b === null || b === void 0 ? void 0 : b.candidateCount)).slice(0, perPage);
  return {
    popularCourses,
    loading
  };
};
export const useFindListFieldOfStudy = function () {
  var _data$list;
  let filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let pagination = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    page: 1,
    perPage: 1000
  };
  let sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    field: 'name',
    order: 'ASC'
  };
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const {
    loading,
    data
  } = useQuery(LIST_FIELD_OF_STUDY, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      filter,
      pagination,
      sort
    }
  }));
  const fieldsOfStudy = loading || !data ? {} : (data === null || data === void 0 || (_data$list = data.list) === null || _data$list === void 0 ? void 0 : _data$list.data) || [];
  return {
    fieldsOfStudy,
    loading
  };
};
export const useGetCourseIdAndPriceByUrlCode = function (urlCode) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    loading,
    data
  } = useQuery(GET_COURSE_ID_AND_PRICE_BY_URL_CODE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      urlCode
    },
    skip: !urlCode
  }));
  const courseInfo = loading || !data ? {} : data.data;
  return {
    courseInfo,
    loading
  };
};
export const useGetCourseByUrlCode = function (urlCode) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    loading,
    data
  } = useQuery(GET_COURSE_BY_URL_CODE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      urlCode
    },
    skip: !urlCode
  }));
  const course = loading || !data ? {} : data.data;
  return {
    course,
    loading
  };
};
export const useGetCourse = function (courseId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    loading,
    data
  } = useQuery(GET_COURSE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseId
    },
    skip: !courseId
  }));
  const course = loading || !data ? {} : data.data;
  return {
    course,
    loading
  };
};
export const useGetDefaultCoursePriceByCourseId = function (courseId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    loading,
    data
  } = useQuery(GET_DEFAULT_COURSE_PRICE_BY_COURSE_ID, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseId
    },
    skip: !courseId
  }));
  const defaultCoursePrice = loading || !data ? {} : data.data;
  return {
    defaultCoursePrice,
    loading
  };
};
export const useGetMinorCoursePriceByDegree = function (degree) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    loading,
    data
  } = useQuery(GET_MINOR_COURSE_PRICE_BY_DEGREE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      degree
    },
    skip: !degree
  }));
  const minorCoursePrice = loading || !data ? {} : data.data;
  return {
    minorCoursePrice,
    loading
  };
};
export const useGetCourseEnrollmentHome = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery2 = useQuery(GET_COURSE_ENROLLMENT_HOME, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        courseEnrollmentId
      },
      skip: !courseEnrollmentId,
      fetchPolicy: cacheFetchPolicy
    })),
    {
      data,
      loading
    } = _useQuery2,
    rest = _objectWithoutProperties(_useQuery2, _excluded2);
  const courseEnrollment = loading || !data ? {} : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading
  }, rest);
};
export const useGetCourseEnrollment = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery3 = useQuery(GET_COURSE_ENROLLMENT, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        courseEnrollmentId
      },
      skip: !courseEnrollmentId,
      fetchPolicy: cacheFetchPolicy
    })),
    {
      data,
      loading
    } = _useQuery3,
    rest = _objectWithoutProperties(_useQuery3, _excluded3);
  const courseEnrollment = loading || !data ? {} : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading
  }, rest);
};
export const useGetCourseEnrollmentForTutorPage = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery4 = useQuery(GET_COURSE_ENROLLMENT_FOR_TUTOR_PAGE, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        courseEnrollmentId
      },
      skip: !courseEnrollmentId,
      fetchPolicy: cacheFetchPolicy
    })),
    {
      data,
      loading
    } = _useQuery4,
    rest = _objectWithoutProperties(_useQuery4, _excluded4);
  const courseEnrollment = loading || !data ? {} : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading
  }, rest);
};
export const useGetCourseEnrollmentForSyllabusPage = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery5 = useQuery(GET_COURSE_ENROLLMENT_FOR_SYLLABUS_PAGE, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        courseEnrollmentId
      },
      skip: !courseEnrollmentId,
      fetchPolicy: cacheFetchPolicy
    })),
    {
      data,
      loading
    } = _useQuery5,
    rest = _objectWithoutProperties(_useQuery5, _excluded5);
  const courseEnrollment = loading || !(data !== null && data !== void 0 && data.data) ? {} : data === null || data === void 0 ? void 0 : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading
  }, rest);
};
export const useGetSubjectEnrollmentsForSyllabusPage = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery6 = useQuery(GET_SUBJECT_ENROLLMENTS_FOR_SYLLABUS_PAGE, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        courseEnrollmentId
      },
      skip: !courseEnrollmentId,
      fetchPolicy: cacheFetchPolicy
    })),
    {
      data,
      loading
    } = _useQuery6,
    rest = _objectWithoutProperties(_useQuery6, _excluded6);
  const courseEnrollment = loading || !(data !== null && data !== void 0 && data.data) ? {} : data === null || data === void 0 ? void 0 : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading
  }, rest);
};
export const useCancelCourseEnrollment = function (courseEnrollmentId, reason, elaboration) {
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const [mutation, metadata] = useMutation(CANCEL_COURSE_ENROLLMENT, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId,
      reason,
      elaboration
    },
    context: {
      autoHandle: true
    }
  }));
  return [mutation, metadata];
};
export const useFindStudentCourseEnrollmentsWithFreeTrialInfo = function (studentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useQuery(STUDENT_FREE_TRIAL_INFO, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      studentId
    },
    skip: !studentId
  }));
};
export const useExistsCourseEnrollmentByCourseId = function (courseId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useQuery(EXISTS_COURSE_ENROLLMENT_BY_COURSE_ID, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseId
    },
    skip: !courseId
  }));
};
export const useFindVisibleCourseCategoriesByDegree = function (courseDegree) {
  let pagination = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    page: 1,
    perPage: 50
  };
  let sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    field: 'name',
    order: 'ASC'
  };
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const {
    loading,
    data
  } = useQuery(LIST_VISIBLE_COURSES_CATEGORIES_BY_DEGREE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseDegree,
      pagination,
      sort
    },
    skip: !courseDegree,
    fetchPolicy: (options === null || options === void 0 ? void 0 : options.fetchPolicy) || 'no-cache'
  }));
  const categories = loading || !data ? {} : data.list.data;
  return {
    categories,
    loading
  };
};
export const useGetCourseEnrollmentForSecretaryPage = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _useQuery7 = useQuery(GET_COURSE_ENROLLMENT_FOR_SECRETARY_PAGE, _objectSpread(_objectSpread({}, options), {}, {
      variables: {
        courseEnrollmentId
      },
      skip: !courseEnrollmentId
    })),
    {
      data,
      loading
    } = _useQuery7,
    rest = _objectWithoutProperties(_useQuery7, _excluded7);
  const courseEnrollment = loading || !data ? {} : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading
  }, rest);
};
export const useGetCourseEnrollmentForPages = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    data,
    loading,
    error,
    refetch,
    client
  } = useQuery(GET_COURSE_ENROLLMENT_FOR_PAGES, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId
    },
    skip: !courseEnrollmentId
  }));
  const courseEnrollment = loading || !data ? {} : data.data;
  return {
    data: courseEnrollment,
    loading,
    error,
    refetch,
    client
  };
};