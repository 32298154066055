import {
  useCreateExamByAssignment,
  useGetLearningUnitTitle,
} from '@ampli/services';

import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { routePath } from '../routes';

import useHomePageData from './use-home-page-data';

const useQuizPageData = (options = {}) => {
  const { onCreateQuizCompleted, onCreateQuizError } = options;
  const history = useHistory();

  const { subjectEnrollmentId, learningUnitEnrollmentId } = useParams();
  const { subjectEnrollments, loading, ...homePageData } = useHomePageData();

  /**
   * @todo Deve ser chamado na estória de integração. (exam-onboarding)
   */

  const [createQuiz, createQuizMetadata] = useCreateExamByAssignment({
    onError: onCreateQuizError,
    onCompleted: onCreateQuizCompleted,
  });

  const subjectEnrollment = subjectEnrollments.find(
    (subject) => subject.id === subjectEnrollmentId
  );

  const learningUnit = subjectEnrollment?.learningUnits.find(
    (unit) => unit.id === learningUnitEnrollmentId
  );

  const { data: learningUnitsTitles } =
    useGetLearningUnitTitle(subjectEnrollmentId);

  const learningUnitTitle = learningUnitsTitles.find(
    (unit) => unit.id === learningUnitEnrollmentId
  );

  useEffect(() => {
    if (!loading && (!subjectEnrollment || !learningUnit)) {
      console.warn('SubjectEnrollment or learningUnit not found.');
      history.replace(routePath.home);
    }
  }, [history, loading, subjectEnrollment, learningUnit]);

  return {
    ...homePageData,
    loading,
    subjectEnrollment,
    learningUnit,
    createQuiz,
    createQuizMetadata,
    learningUnitTitle: learningUnitTitle?.title,
  };
};

export default useQuizPageData;
