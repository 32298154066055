import React, { useState } from 'react';
import { node } from '@ampli/utils';

const StudentContext = React.createContext(null);

const StudentProvider = ({ children }) => {
  const [studentState, setStudentState] = useState({});

  const clear = () => {
    setStudentState({});
  };

  return (
    <StudentContext.Provider
      value={{
        studentState: studentState,
        updateStudentContext: setStudentState,
        clear,
      }}
    >
      {children}
    </StudentContext.Provider>
  );
};

StudentProvider.propTypes = {
  children: node,
};

export { StudentContext, StudentProvider };
